<div class="newCommentContent" [ngClass]="{focused: focused, error : commentInvalid}">
  <div class="theme-file-drop-area-glass" *ngIf="attachments">{{'common_drop_here' | i18n}}</div>

  <div class="commentInputContainer">
    <adjusting-text-input
      class="newComment theme-input"
      ngDefaultControl
      [placeholder]="'comments_NewCommentPlaceholder' | i18n"
      [model]="newComment.commentText"
      (modelChange)="newComment.changeComment($event)"
      (focus)="activated = true; focused = true"
      (blur)="onBlur(); focused = false;"
      (accepted)="acceptComment()"
    ></adjusting-text-input>

    <div class="sendButtonContainer">
      <div class="attachmentAndNotifications">
        <div *ngIf="attachments" #uploadFile class="theme-button-icon-action-flat theme-align-start"
             [my-tooltip]="{i18n: 'comments_AddFile', position: 'top'}">
          <i class="commentActionIcon mi-attach"></i>
        </div>

        <button class="addNotification theme-button-icon-action-flat theme-align-start" *ngIf="notifications"
                (click)="toggleAddRecipient()"
                [my-tooltip]="{i18n: 'comments_AddNotification', position: 'top'}">
          <i class="commentActionIcon mi-notifications"></i>
        </button>
      </div>

      <div class="commentActions" [ngClass]="{hidden: !activated}">
        <button class="theme-button-cancel-icon-medium" (click)="cancelComment()"><i
          class="mi-delete"></i>{{'comments_Cancel' | i18n}}</button>
        <button class="theme-button-confirm-with-icon-medium sendButton"
                (click)="sendComment()">
          <i class="mi-paper-plane"></i>
          {{'comments_Send' | i18n}}
        </button>
      </div>
    </div>
  </div>

  <hr *ngIf="attachmentsVisible() || newComment.extraRecipients.length > 0"
      class="attachmentsAndPersonsSeparator theme-content-horizontal-separator-no-margin"/>

  <div *ngIf="attachmentsVisible()" class="additionalCommentInfo">
    <my-comment-attachments-list [attachments]="newComment.attachmentsInfo.get()"
                                 (showAttachment)="onShowAttachments($event)"
                                 [readOnly]="false" [onDelete]="onAttachmentDeleted"/>
  </div>

  <div class="personsContainer" #Recipients [class.empty]="newComment.extraRecipients.length === 0">
    <div *ngFor="let person of newComment.extraRecipients" class="extraRecipient">
      <my-person-summary [personId]="person" [showName]="true" [avatar]="true" [small]="true"/>
      <button type="button" class="remove theme-button-icon-action-flat-small" (click)="deleteRecipient(person)"><i
        class="mi-delete"></i></button>
    </div>
  </div>

  <my-drop-menu *ngIf="notifications" cssClass="organizationNodeSelectSearchBox"
                [(visible)]="notificationSelectorVisible"
                [anchor]="Recipients" [contentChangeNotifier]="notifeeResultsChanged">
    <my-organization-nodes-search-box *ngIf="notificationSelectorVisible"
                                      newEntryPlaceholder="Find a person"
                                      (resultsChanged)="onNotifeeResultsChanged()"
                                      (nodeSelected)="onNotifeeSelected($event)" (nodeCleared)="onNotifeeCleared()"
                                      [persons]="true" [unselectEnabled]="false"
                                      [alwaysShowResults]="true"/>
  </my-drop-menu>
</div>

<div class="errorMessage" *ngIf="errorMessage">{{errorMessage}}</div>
