export function toUrlParams(params: object) {
  // previously implemnted as jQuery.param
  throw new Error("Not yet implemented");
}

export function removeHashFromUrl(url: string) {
  const hashIndex = url.indexOf("#");
  if (hashIndex === -1) {
    return url;
  } else {
    return url.substring(0, hashIndex);
  }
}
