import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {DataTableFormEventBus} from "./data-table-form.event-bus";
import {I18nText, required} from "@utils";
import {FormColumnModel} from "./FormColumnModel";
import {
  AttributeTypeViewModelFactory,
  BusinessVariable,
  NamedAttributeViewModel,
  ObjectVariable,
  VariablePath
} from "@shared-model";

@Component({
  selector: "my-data-table-form",
  templateUrl: "./data-table-form.component.html"
})

export class DataTableFormComponent implements OnInit {
  @Input({required: true}) eventBus!: DataTableFormEventBus;

  @Output() onClose = new EventEmitter<void>();

  rowIndex: number = 0;
  rowData?: ObjectVariable;
  rowAttributes: Array<NamedAttributeViewModel> = [];
  @Input() editMode: boolean = false;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {

    requestAnimationFrame(() => {
      this.eventBus.formShown();
    });


    this.eventBus.on(this.eventBus.rowSelected, (rowIndex: number, tableFormData: Array<FormColumnModel>, rowPureData: ObjectVariable) => {
      this.rowIndex = rowIndex;
      this.rowData = rowPureData;
      this.rowAttributes = tableFormData.map(c => {
        return new NamedAttributeViewModel(c.name, c.label.map(l => I18nText.ofCurrent(l)).getOrElse(I18nText.empty()),
          AttributeTypeViewModelFactory.ofBusinessVariableType(c.dataType));
      });
    });

    this.eventBus.on(this.eventBus.rowDeselected, () => {
      this.rowData = undefined;
      this.rowAttributes = [];
    });



  }


  onValueChanged(path: VariablePath, value: BusinessVariable | null) {
    this.eventBus.formValueChanged(this.rowIndex, required(this.rowData, "rowData").id, path, value);
  }
}
