import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApplicationId, None, Option, Some, toastr, Trilean} from "@utils";
import {
  BooleanVariable,
  BusinessVariable,
  BusinessVariableInputServerModel, BusinessVariableType, BusinessVariableTypeName,
  NumberVariable,
  StringVariable,
  VariablePath
} from "@shared-model";

export class BusinessVariableInputViewModel {

    previewText: string|null = null;
    internalModel: any|null = null;
    internalModelBackup: any|null = null;

    constructor(readonly path: VariablePath,
                public value: BusinessVariable|null,
                public valueTypeName: string,
                public readOnly: boolean,
                public preview: boolean,
                private readonly onChange: (value: BusinessVariable|null, path: VariablePath) => void) {
      this.updatePreviewText();
      this.initInternalModel();
    }

    private updatePreviewText() {
      this.previewText = this.value === null ? "-" : this.value.valueToSimpleString();
    }

    initInternalModel() {
      if (this.value == null) {
        switch (this.valueTypeName) {
          case "Boolean":this.internalModel = Trilean.UNKNOWN; break;
          default: this.internalModel = null;
        }
      } else {
        switch (this.valueTypeName) {
          case "String": this.internalModel = (<StringVariable>this.value).value; break;
          case "Number": this.internalModel = (<NumberVariable>this.value).value; break;
          case "Boolean": this.internalModel = Trilean.ofBoolean((<BooleanVariable>this.value).value); break;
          default: this.internalModel = this.value.valueToSimpleString();
        }
      }
      this.internalModelBackup = this.internalModel;
    }

    private changeVariableValue(value: BusinessVariable|null) {
      this.value = value;
      this.updatePreviewText();
      this.initInternalModel();
      this.onChange(value, this.path);
    }

    onInternalChange() {
      if(this.internalModelChanged()) {
        if (this.internalModel === null) {
          this.onChange(null, this.path);
        } else {
          switch (this.valueTypeName) {
            case "String": this.changeVariableValue(new StringVariable(this.internalModel.trim())); break;
            case "Number": this.changeVariableValue(new NumberVariable(this.internalModel)); break;
            case "Boolean": this.changeVariableValue(Option.of((<Trilean>this.internalModel).booleanOrNull()).map(v => BooleanVariable.of(v)).getOrNull()); break;
            default: throw new Error("Saving '" + this.valueTypeName + "' not yet supported");
          }
        }
        this.internalModelBackup = this.internalModel;
      }
    }

    private internalModelChanged() {
      if(this.internalModel === null && this.internalModelBackup !== null) {
        return true;
      } else if(this.internalModel !== null && this.internalModelBackup === null) {
        return true;
      } else if(this.internalModel == null && this.internalModelBackup === null) {
        return false;
      } else if(typeof this.internalModel !== typeof this.internalModelBackup) {
        return true;
      }  else if (typeof this.internalModel === 'number' || typeof this.internalModel === 'string' || typeof this.internalModel === 'boolean')  { // basic types
        return this.internalModel !== this.internalModelBackup;
      } if (this.internalModel instanceof Trilean)  {
        return !this.internalModel.equals(this.internalModelBackup);
      } else {
        toastr.error("Comparing of those types not yet implemented '"+ typeof this.internalModel+"'");
        return false;
      }
    }

    updateValue(value: BusinessVariable|null, valueTypeName: string) {
      this.value = value;
      this.valueTypeName = valueTypeName;
      this.initInternalModel();
    }

    updateMode(readOnly: boolean, preview: boolean) {
      this.readOnly = readOnly;
      this.preview = preview;
    }

    backupInternalValue() {
      this.internalModelBackup = this.internalModel;
    }
  }

@Component({
  selector: "my-business-variable-input",
  templateUrl: "./business-variable-input.component.html"
})
export class BusinessVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}
  @Output() valueChange = new EventEmitter<BusinessVariable|null>();

  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path: VariablePath = VariablePath.empty();

  private _valueType?: BusinessVariableType;
  @Input() set valueType(valueType: BusinessVariableType) {
    this._valueType = valueType;
    this.valueTypeName = valueType.typeName();
  }
  get valueType(): BusinessVariableType|undefined {return this._valueType;}

  @Input() valueTypeName!: string;
  @Output() variableChange = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();
  @Output() previewRequested = new EventEmitter<BusinessVariable>();
  @Input({required: true}) serverModel?: BusinessVariableInputServerModel;

  @Input() applicationId?: ApplicationId;

  viewModel!: BusinessVariableInputViewModel;

  private onValueChanged() {
    if(this.viewModel) {
      this.viewModel.updateValue(this._value, this.valueTypeName);
    }
  }

  private onModeChanged() {
    if(this.viewModel) {
      this.viewModel.updateMode(this._readOnly, this._preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new BusinessVariableInputViewModel(this.path, this._value, this.valueTypeName, this._readOnly, this._preview, (value, path) => {
      this.variableChange.emit({value, path});
      this.valueChange.emit(value);
    });
  }


}
