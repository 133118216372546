import {ScreenRuntimeEventBus} from "../services/screen-runtime.event-bus";
import {SkinPrototype} from "@screen-common";

export class ScreenSharedViewModel {
  mobile: boolean = true;
  tablet: boolean = true;
  desktop: boolean = true;
  constructor(
    readonly eventBus: ScreenRuntimeEventBus,
    readonly skins: Array<SkinPrototype>) {
  }
}
