import {Duration} from "../data-types/Time";
import {i18nLanguage} from "../I18n";
import {Language} from "../data-types/Language";


export class DurationInputFormatter {

  constructor(public hoursInDays: number) {}
  public daysMarker: string = 'd';
  public minutesMarker: string = 'm';
  public secondsMarker: string = 's';
  public separator: string = ' ';

  parseModel(model: string|null): Duration|null {

    const hoursMarker = i18nLanguage() === Language.PL ? ['g', 'h'] : ['h'];

    if (model === undefined || model === null || model.trim().length === 0) {
      return null;
    } else {
      return parseDurationInputString(model, {
        daysMarker: this.daysMarker,
        hoursMarker: hoursMarker,
        minutesMarker: this.minutesMarker,
        secondsMarker: this.secondsMarker,
        separator: this.separator,
        hoursInDay: this.hoursInDays
      });
    }
  }

  format(duration: Duration): string {

    const hoursMarker = i18nLanguage() === Language.PL ? ['g', 'h'] : ['h'];

    return duration.format(this.daysMarker, hoursMarker, this.minutesMarker, this.secondsMarker, this.separator, this.hoursInDays);
  }
}


export interface FormattedDurationParserSettings {
  daysMarker: string;
  hoursMarker: Array<string>; // allows 'h' and localized one, e.g. 'g' in Polish
  minutesMarker: string;
  secondsMarker: string;
  hoursInDay: number;
  separator: string;
}

/**
 * Creates a new Date object, but maintains the year value, even if it is less than 100.
 * Original Date constructor considers that as the year is above 1900.
 */
export function createDateWithStrictYear(year: number = 0, month: number = 0, day: number = 0, hours: number = 0, minutes: number = 0, seconds: number = 0, milliseconds: number = 0): Date {
  const date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(milliseconds);
  return date;
}

export function stripNonDigits(str: string): string {
  return String(str).replace(/\D/g, String())
}

export function hasDigits(str: string): boolean {
  return str != null && str.match(/\d/g) != null;
}

export function hasOneOfCharacters(str: string, chars: string): boolean {
  return str != null && str.match(new RegExp(`.*[${chars}].*`, "i")) != null;
}

export function parseDateTimeInputString(dateTime: string): Date {

  const date_parser = DateInputParser.parse(dateTime);
  const time_parser = TimeInputParser.parse(date_parser.getLeftoverDigits());

  const date = date_parser.asDate();
  const time = time_parser.asDate();

  return createDateWithStrictYear(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}

export function parseDateInputString(date: string): Date {
  return DateInputParser.parse(date).asDate();
}

export function parseTimeInputString(time: string): Date {
  return TimeInputParser.parse(time).asDate();
}

export function parseDurationInputString(duration: string, settings: FormattedDurationParserSettings): Duration {

  if (hasDigits(duration)) {
    const markers = settings.daysMarker + settings.hoursMarker + settings.minutesMarker + settings.secondsMarker;

    if (hasOneOfCharacters(duration, markers)) {
      return FormattedDurationInputParser.parse(duration, settings).asDuration();
    } else if (hasOneOfCharacters(duration, ':')) {
      return ColonDurationInputParser.parse(duration, settings.hoursInDay).asDuration();
    } else {
      return DurationInputParser.parse(duration).asDuration();
    }

  }

  return Duration.ZERO;
}

export abstract class DigitsInputParser {

  protected _input!: string;
  protected digits!: string;

  protected constructor() {
  }

  public setDigits(digits: string): void {
    this._input = digits;
    this.digits = stripNonDigits(digits);
  }

  public getLeftoverDigits(): string {
    return this.digits;
  }

  public hasDigits(): boolean {
    return Number(this.digits) > 0;
  }

  public digitsLength(): number {
    return this.digits.length;
  }

  protected firstDigits(count: number): number {
    return Number(this.digits.slice(0, count));
  }

  protected lastDigits(count: number): number {
    return Number(this.digits.slice(-count));
  }

  protected sliceDigitsRight(count: number): string {
    return this.digits = this.digits.slice(count)
  }

  protected sliceDigitsLeft(count: number): string {
    return this.digits = this.digits.slice(0, this.digits.length - count);
  }

  protected shiftDigits(count: number): number {
    const shift = this.firstDigits(count);
    this.sliceDigitsRight(count);
    return shift;
  }

  protected popDigits(count: number): number {
    const pop = this.lastDigits(count);
    this.sliceDigitsLeft(count);
    return pop;
  }

  protected extractDigitsRight(count: number, max: number = Number.MAX_VALUE, or: number = 0): number {
    if (this.hasDigits()) {
      return this.firstDigits(count) <= max ? this.shiftDigits(count) : this.shiftDigits(count - 1);
    }
    return or;
  }

  protected extractDigitsLeft(count: number, max: number = Number.MAX_VALUE, or: number = 0): number {
    if (this.hasDigits()) {
      return this.lastDigits(count) <= max ? this.popDigits(count) : this.popDigits(count - 1);
    }
    return or;
  }

}

export class DateInputParser extends DigitsInputParser {

  private date: Date = new Date();
  private day: number = 0;
  private month: number = 0;
  private year: number = 0;

  constructor() {
    super();
  }

  public parse(digits: string): void {
    this.reset();
    this.setDigits(digits);
    this.day = this.extractDigitsRight(2, 31, this.day);
    this.month = this.extractDigitsRight(2, 12, this.month);
    const fix_year = this.hasDigits() && this.digitsLength() < 4; // Fix year value if user did not input full year value
    this.year = this.extractDigitsRight(4, Number.MAX_VALUE, this.year);
    // eg. year 21 => 2021, year 98 => 1998
    if (fix_year) this.year += this.year <= 50 ? 2000 : 1900;
  }

  private reset(): void {
    this.date = new Date();
    this.day = this.date.getDate();
    this.month = this.date.getMonth() + 1;
    this.year = this.date.getFullYear();
  }

  public asDate(): Date {
    return createDateWithStrictYear(this.year, this.month - 1, this.day);
  }

  static parse(digits: string): DateInputParser {
    const parser = new DateInputParser();
    parser.parse(digits);
    return parser;
  }

}

export class TimeInputParser extends DigitsInputParser {

  private date: Date = new Date();
  private hours: number = 0;
  private minutes: number = 0;
  private seconds: number = 0;
  private milliseconds: number = 0;

  constructor() {
    super();
  }

  public parse(digits: string): void {
    this.reset();
    this.setDigits(digits);
    if (this.digitsLength() === 3) {
      this.minutes = this.extractDigitsLeft(2);
      this.hours = this.extractDigitsLeft(2, 24);
    } else {
      this.hours = this.extractDigitsRight(2, 24);
      this.minutes = this.extractDigitsRight(2);
    }
  }

  private reset(): void {
    this.date = new Date();
    this.hours = this.date.getHours();
    this.minutes = this.date.getMinutes();
    this.seconds = this.date.getSeconds();
    this.milliseconds = this.date.getMilliseconds();
  }

  public asDate(): Date {
    return createDateWithStrictYear(0, 0, 0, this.hours, this.minutes, this.seconds, this.milliseconds);
  }

  static parse(digits: string): TimeInputParser {
    const parser = new TimeInputParser();
    parser.parse(digits);
    return parser;
  }

}

export class DurationInputParser extends DigitsInputParser {
  private seconds: number = 0;

  constructor() {
    super();
  }

  public parse(digits: string): void {
    this.reset();
    this.setDigits(digits);
    this.seconds += this.extractDigitsLeft(2) * 60; // minutes
    this.seconds += this.extractDigitsLeft(Number.MAX_VALUE) * 60 * 60; // hours
  }

  protected reset(): void {
    this.seconds = 0;
  }

  public asDuration() {
    return new Duration(this.seconds, 0);
  }

  public asMilliseconds() {
    return this.seconds * 1000;
  }

  static parse(digits: string): DurationInputParser {
    const parser = new DurationInputParser();
    parser.parse(digits);
    return parser;
  }

}

class FormattedDurationInputParser {
  private seconds: number|undefined;

  constructor(private settings: FormattedDurationParserSettings) {
  }

  private matchMarked(str: string, mark: string|ReadonlyArray<string>): RegExpMatchArray|null {
    const markArray = Array.isArray(mark) ? mark : [mark];
    return str && mark ? str.match(new RegExp("\\d+(?=[" + markArray.join() + "])", "i")) : null;
  }

  private firstOr(arr: Array<string>|null, or: number) {
    return arr !== null && arr.length > 0 ? parseInt(arr[0]) : or;
  }

  public parse(duration: string): FormattedDurationInputParser {

    const days = this.firstOr(this.matchMarked(duration, this.settings.daysMarker), 0);
    const hours = this.firstOr(this.matchMarked(duration, this.settings.hoursMarker), 0);
    const minutes = this.firstOr(this.matchMarked(duration, this.settings.minutesMarker), 0);
    this.seconds = this.firstOr(this.matchMarked(duration, this.settings.secondsMarker), 0);

    this.seconds += minutes * 60;
    this.seconds += hours * 3600;
    this.seconds += days * 3600 * this.settings.hoursInDay;

    return this;
  }

  public asDuration(){
    if(this.seconds === undefined) {
      throw new Error("seconds is not defined");
    } else {
      return new Duration(this.seconds, 0);
    }
  }

  public asMilliseconds(): number {
    if(this.seconds === undefined) {
      throw new Error("seconds is not defined");
    } else {
      return this.seconds * 1000;
    }
  }

  static parse(digits: string, settings: FormattedDurationParserSettings): FormattedDurationInputParser {
    const parser = new FormattedDurationInputParser(settings);
    parser.parse(digits);
    return parser;
  }

}

export class ColonDurationInputParser {

  private days = 0;
  private hours = 0;
  private minutes = 0;
  private seconds = 0;

  constructor(private hours_in_day: number) {
  }

  public parse(duration: string): void {

    const duration_parts = duration.split(':', 4).map(v => parseInt(stripNonDigits(v)));

    // seconds
    if (duration_parts.length > 0) {
      this.seconds += duration_parts.pop()!;
    }

    // minutes
    if (duration_parts.length > 0) {
      this.seconds += duration_parts.pop()! * 60;
    }

    // hours
    if (duration_parts.length > 0) {
      this.seconds += duration_parts.pop()! * 3600;
    }

    // days
    if (duration_parts.length > 0) {
      this.seconds += duration_parts.pop()! * this.hours_in_day * 3600;
    }

    if (this.seconds >= 60) {
      this.minutes += Math.floor(this.seconds / 60);
      this.seconds = this.seconds % 60;
    }

    if (this.minutes >= 60) {
      this.hours += Math.floor(this.minutes / 60);
      this.minutes = this.minutes % 60;
    }

    if (this.hours >= this.hours_in_day) {
      this.days += Math.floor(this.hours / this.hours_in_day);
      this.hours = this.hours % this.hours_in_day;
    }

  }

  public asDuration(): Duration {
    return new Duration(this.seconds, 0);
  }

  // public asMilliseconds(): number {
  //   return this.seconds * 1000;
  // }

  static parse(digits: string, hours_in_day: number): ColonDurationInputParser {
    const parser = new ColonDurationInputParser(hours_in_day);
    parser.parse(digits);
    return parser;
  }

}
