import {i18n, None, Some} from "@utils";
import {DurationMethod, ExpectedDuration} from "./ExpectedDuration";
import {ActionIneffectiveness} from "./ActionIneffectiveness";

export class NodeMetadata {

  constructor(readonly valueAdded:ValueAdded,
              readonly automatic:boolean,
              readonly averageRealizationDurationMillis: number,
              readonly expectedMaximumDuration: ExpectedDuration,
              readonly ineffectiveness: Array<ActionIneffectiveness>) {}

  static copy(metadata:NodeMetadata) {
    return new NodeMetadata(
      ValueAdded.copy(metadata.valueAdded),
      metadata.automatic,
      metadata.averageRealizationDurationMillis,
      ExpectedDuration.copy(metadata.expectedMaximumDuration),
      metadata.ineffectiveness.map(i => ActionIneffectiveness.copy(i)));
  }

  static newEmpty() {
      return new NodeMetadata(ValueAdded.valueAdded, false, 0, new ExpectedDuration(DurationMethod.onActionAppear, Some(0), None(), None()), []);
    }

  static newEmptyForFinish() {
    return new NodeMetadata(ValueAdded.valueAdded, false, 0, new ExpectedDuration(DurationMethod.onCaseStart, Some(0), None(), None()), []);
  }
}

export class ValueAdded {

  constructor(readonly name: string) {}

  static valueAdded = new ValueAdded("VA");
  static noValueEssential = new ValueAdded("NVA");
  static noValueNonEssential = new ValueAdded("NVANE");

  static copy(other: ValueAdded) {
    switch(other.name) {
      case ValueAdded.valueAdded.name:
        return ValueAdded.valueAdded;
      case ValueAdded.noValueEssential.name:
        return ValueAdded.noValueEssential;
      case ValueAdded.noValueNonEssential.name:
        return ValueAdded.noValueNonEssential;
      default:
        throw new Error("Unsupported value added type: " + other.name);
    }
  }
}



export class ValueAddedNames {

  constructor(readonly name: string,
              readonly abbreviation: string,
              readonly comment: string) {}

  private static generateNames() {
    const names: {[name:string]: ValueAddedNames} = {};
    names[ValueAdded.valueAdded.name] = new ValueAddedNames("designMap_Box_ActionProperties_CustomerValueAdded", "VA", " ");
    names[ValueAdded.noValueEssential.name] = new ValueAddedNames("designMap_Box_ActionProperties_NoValueAddedEssential", "NVA", "designMap_Box_ActionProperties_NoValueAdded_Essential");
    names[ValueAdded.noValueNonEssential.name] = new ValueAddedNames("designMap_Box_ActionProperties_NoValueAddedNonEssential", "NVA", "designMap_Box_ActionProperties_NoValueAdded_NonEssential");
    return names;
  }

  private static names: {[name:string]: ValueAddedNames} = ValueAddedNames.generateNames();

  static nameFor(va: ValueAdded) {
    return ValueAddedNames.names[va.name].name;
  }

  static abbreviationFor(va: ValueAdded) {
    return ValueAddedNames.names[va.name].abbreviation;
  }

  static commentFor(va: ValueAdded) {
    return i18n(ValueAddedNames.names[va.name].comment);
  }
}
