import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {ViewableFile} from "@shared";
import {$$, None, Some} from "@utils";
import {CommentAttachmentViewModel} from "./comments.component";

@Component({
  selector: 'my-comment-attachments-list',
  templateUrl: './comment-attachments-list.component.html'
})
export class CommentAttachmentsListComponent implements OnInit {
  @Input() attachments: Array<CommentAttachmentViewModel> = [];
  @Input() readOnly: boolean = false;
  @Input() onDelete?: (attachmentInfo: CommentAttachmentViewModel) => void;
  @Output() showAttachment = new EventEmitter<{viewableFiles: Array<ViewableFile>, fileIndex: number}>();

  viewableFiles: Array<ViewableFile> = [];

  constructor(private readonly viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.onAttachmentsChanged();
  }

  onAttachmentsChanged() {
    this.viewableFiles = this.attachments.map(a => new ViewableFile(a.name, a.size, Some(a.version), a.url, false, a.modified, a.uri.getOrError("No uri"),  false, None(), None(), None(), None(), false));
  }

  clickUpload() {
    $$(this.viewContainerRef).findOrError('.uploadNewFile').click();
  };

  removeEntry(attachmentInfo: CommentAttachmentViewModel) {
    if(this.onDelete !== undefined) {
      this.onDelete(attachmentInfo);
    }
  };

  toggleFileViewerVisibility(index: number) {
    this.onAttachmentsChanged();
    this.showAttachment.emit({viewableFiles: this.viewableFiles, fileIndex: index});
  };

}
