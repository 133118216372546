import {BusinessVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {DropListComponentDefinition} from "@screen-common";

export class DropListComponentState extends LabeledScreenComponentState {
  static className = "DropListComponentState";
  className() {
    return DropListComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalModelProperty(DropListComponentDefinition.MODEL);
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get options() {
    return this.properties.labeledValuesProperty("options");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case DropListComponentDefinition.MODEL:
        if(value.isDefined()) {
          this.properties.putValue(DropListComponentDefinition.MODEL, value.get());
        } else {
          this.properties.clearValue(DropListComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: DropListComponentState) {
    return new DropListComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class DropListComponentRefState extends ScreenComponentRefState {
  static className = "DropListComponentRefState";
  className() {
    return DropListComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: DropListComponentRefState) {
    return new DropListComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
