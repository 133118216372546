import {Language} from "./Language";
import {Option} from "./Option";
import {i18n, i18nLanguage} from "../I18n";
import {__} from "../__";

export class I18nText {
  constructor(public value: Array<[string, string]>) {
  }

  static pl(text: string) {
    const value: Array<[string, string]> = [];
    value.push([Language.PL.name, text]);
    return new I18nText(value);
  }

  static en(text: string) {
    const value: Array<[string, string]> = [];
    value.push([Language.EN.name, text]);
    return new I18nText(value);
  }

  static ofCurrent(text: string) {
    const value: Array<[string, string]> = [];
    if (text.trim().length > 0) {
      value.push([i18nLanguage().name, text]);
    }
    return new I18nText(value);
  }

  static ofLanguage(language: Language, text: string) {
    const value: Array<[string, string]> = [];
    value.push([language.name, text]);
    return new I18nText(value);
  }

  getCurrentWithFallback(): string {
    return this.getWithFallback(i18nLanguage())
  }

  getCurrent(): Option<string> {
    return this.get(i18nLanguage());
  }

  getWithFallback(language: Language): string {
    return this.get(language).getOrElseLazy(() => {
      if (this.value.length === 0) {
        return "";
      } else {
        let fallback: string | null = this.get(Language.EN).getOrNull();
        if (fallback == null) {
          fallback = this.get(Language.PL).getOrNull();
        }
        if (fallback == null) {
          if (this.value[0][1]) {
            return this.value[0][1];
          } else {
            return "";
          }
        } else {
          return fallback;
        }
      }
    });
  }

  get(language: Language): Option<string> {
    return __(this.value).find(e => e[0] === language.name).filter(a => a[1].trim().length > 0).map(a => a[1]);
  }

  static empty() {
    return new I18nText([]);
  }

  static copy(other: I18nText) {
    return new I18nText(other.value.map(v => <[string, string]>[v[0], v[1]]));
  }

  set(language: Language, text: string) {
    this.value = __(this.value).filterNot(e => e[0] === language.name);
    if (text.trim().length > 0) {
      this.value.push(<[string, string]>[language.name, text.trim()]);
    }
  }

  deleteText(language: Language) {
    this.value = __(this.value).filterNot(e => e[0] === language.name);
  }

  ofPL(): string {
    return this.getWithFallback(Language.PL);
  }

  ofEN(): string {
    return this.getWithFallback(Language.EN);
  }

  notEmpty() {
    return __(this.value).exists(e => e[1].length > 0);
  }


  isEmpty() {
    return __(this.value).all(e => e[1].length === 0);
  }

  fallbackLanguage(): Language {
    if (this.get(Language.PL).isDefined()) {
      return Language.PL;
    } else if (this.get(Language.EN).isDefined()) {
      return Language.EN;
    } else {
      return Language.EN;
    }
  }

  static of(plText: Option<string>, enText: Option<string>): I18nText {
    const text = new I18nText([]);
    if (plText.isDefined()) {
      text.set(Language.PL, plText.get());
    }
    if (enText.isDefined()) {
      text.set(Language.EN, enText.get());
    }
    return text;
  }

  static create(value: string) {
    return undefined;
  }


  notEqual(other: I18nText): boolean {
    return !this.isEqual(other);
  }

  isEqual(other: I18nText): boolean {
    if (other.value.length !== this.value.length) {
      return false;
    } else if (other === this) {
      return true;
    } else {
      return __(other.value).all(entry => {
        return this.value.filter(v => v[0] === entry[0] && v[1] === entry[1]).length === 1;
      });
    }
  }

  trim() {
    return new I18nText(this.value.map((v: [string, string]) => <[string, string]>[v[0], v[1].trim()]));
  }

  setCurrent(text: string) {

    const currentLanguage = i18nLanguage();

    if (text.trim().length > 0) {
      this.set(currentLanguage, text);
    } else {
      const index = __(this.value).findIndexOf(a => a[0] == currentLanguage.name);
      if (index.isDefined()) {
        this.value.splice(index.get(), 1);
      }
    }
  }

  languages(): Array<Language> {
    return this.value.map(l => Language.byName(l[0]));
  }

  toJsonValue() {
    const obj: {[language: string]: string} = {};
    this.value.forEach(n => {
      obj[n[0]] = n[1];
    });
    return JSON.stringify(obj);
  }

  private escapeCurlyBrackets(text: string) {
    return text.replace("{", "#123;").replace("}", "#125;")
  }

  getEn() {
    return this.get(Language.EN);
  }

  toComparableString() {
    return __(this.value).sortBy(v => v[0]).map(v => "["+v[0].toUpperCase()+"] "+v[1]).join(", ");
  }

  changeCurrent(newText: string) {
    const currentLanguage = i18nLanguage();
    this.set(currentLanguage, newText);
  }

  containsAnyCase(name: string) {
    const lowercase = name.toLowerCase();
    return __(this.value).exists(v => v[1].toLowerCase() === lowercase);
  }
}
