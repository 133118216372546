import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {ToastrViewModel} from "./ToastrViewModel";
import {ToastrService} from "./ToastrService";

@Component({
  selector: 'my-toastr-overlay',
  templateUrl: './toastr-overlay.component.html',
  // styleUrls: ['./adjusting-text-input.component.scss']
})
export class ToastrOverlayComponent implements OnInit {

  viewModel: ToastrViewModel;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              readonly toastrService: ToastrService) {
    this.viewModel = new ToastrViewModel(toastrService.eventBus);
  }

  ngOnInit() {
    this.viewContainerRef.element.nativeElement.classList.toggle("bottom", false)
  }
}

