import {Injectable} from "@angular/core";
import {Theme, ThemeFontSize} from "./user-settings.state-service";
import {environment} from "@environments";
import {Language} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class UserSettingsController {

  onThemeChanged(theme: Theme, languageTheme: "default-ltr"|"default-rtl"|"arabic") {
    const head = document.getElementsByTagName("head")[0];

    let oldTheme =document.getElementById("currentTheme");

    const link = document.createElement("link");
    link.id = "currentTheme";
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", "styles/main-"+theme.name+"-"+languageTheme+".css?no-cache="+environment.buildStamp);
    head.append(link);

    link.onload = () => {
      if(oldTheme) {
        oldTheme.remove();
      }
    };
  }

  private getRelativeLocation(url: string): string {
    let rootLocation = window.location.protocol+"//"+window.location.host+"/";
    if(url.indexOf(rootLocation) == 0) {
      return url.substring(rootLocation.length);
    } else {
      throw new Error(`url '${url}' does not start with root '${rootLocation}'`);
    }
  }

  onFontSizeChanged(fontSize: ThemeFontSize) {
    document.getElementsByTagName("html")[0].className = "fontSize"+fontSize.name.toUpperCase();
  }

  onLanguageChanged(language: Language) {
    const htmlTag = document.getElementsByTagName("html")[0];
    // htmlTag.dir = Language.directionOfLanguage(language);
    htmlTag.lang = language.name.toLowerCase();
  }
}
