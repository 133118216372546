import {Injectable} from '@angular/core';
import {AggregateId, AggregateVersion, FileUri, Option, Typed} from "@utils";
import {asSuccess, AuthenticatedHttp, CommandResponse, commandResponseHandler, isSuccess, nonSuccessMessages} from "@shared-model";
import {ChangeOrganizationAccentColor, ChangeOrganizationLanguage, ChangeOrganizationTimezone, UpdateOrganizationInfo} from "../commands/OrganizationCommands";

@Injectable({
  providedIn: 'root',
})
export class OrganizationCommandService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  updateOrganizationInfo(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                         address: string, phone: string, fax: string, email: string, website: string, industry: string, logo: Option<FileUri>, showInfoOnLoginPage: boolean, description: string,
                         onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    this.authenticatedHttp.post("organization/update-organization-info", new UpdateOrganizationInfo(aggregateId, expectedVersion, address, phone, fax, email, website, industry, logo, showInfoOnLoginPage, description),
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  loadIndustries(onSuccess: (industries: Array<string>) => void): void {
    this.authenticatedHttp.get("categories-projection/industries",
      (industries: Array<string>) => {
        onSuccess(industries);
      }
    );
  }

  changeOrganizationAccentColor(aggregateId: AggregateId, expectedVersion: AggregateVersion, accentColor: Option<string>,
                                onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    this.authenticatedHttp.post("organization/change-accent-color", new ChangeOrganizationAccentColor(aggregateId, expectedVersion, accentColor),  (data: Typed<CommandResponse>) => {
      if (isSuccess(data)) {
        const success = asSuccess(data);
        onSuccess(success.aggregateId, success.aggregateVersion);
      } else {
        onFailure([nonSuccessMessages(data)]);
      }
    });

  }

  changeLanguage(aggregateId: AggregateId, expectedVersion: AggregateVersion, language: string,
                 onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                 onFailure: (exception: string[]) => void) {
    this.authenticatedHttp.post(
      "organization/change-language",
      new ChangeOrganizationLanguage(aggregateId, expectedVersion, language),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess(onSuccess).onFailure(onFailure).handle();
      }
    )
  }

  changeTimezone(aggregateId: AggregateId, expectedVersion: AggregateVersion, timezone: string,
                 onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                 onFailure: (exception: string[]) => void) {
    this.authenticatedHttp.post(
      "organization/change-timezone",
      new ChangeOrganizationTimezone(aggregateId, expectedVersion, timezone),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess(onSuccess).onFailure(onFailure).handle();
      }
    )
  }

}
