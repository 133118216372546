import {None, Option, Some, toastr} from "@utils";
import {EntriesLayoutType, LayoutType, TextAlign, TextVerticalAlign} from "@screen-common";

export class CssUtils {
  static colorRegex = /^#([a-fA-F0-9]{3,4}){1,2}$/;
  static classRegex = /^\$[a-zA-Z0-9_-]+$/;

  static isColor(cssColor: string): boolean {
    return cssColor.match(CssUtils.colorRegex) !== null;
  }

  static isCssClass(cssClass: string): boolean {
    return cssClass.match(CssUtils.classRegex) !== null;
  }

  private static cssProp(propName: string, propValue: Option<string|number>): string {
    return propValue.map(v => propName + ": " + v + ";").getOrElse("");
  }

  private static cssPropNoOption(propName: string, propValue: string|number): string {
    return propName + ": " + propValue + ";";
  }


  static childrenPlainMinHeight(cssBuilder: CssBuilder, contentMinHeightValue: Option<string>, headerMinHeightValue: Option<string>, headerEnabled: boolean, childrenPanelDeltaHeight: Option<string>){
    const defaultHeaderHeightValue = headerEnabled ? "2.625rem" : "0rem";
    const headerHeightValue = headerEnabled ? headerMinHeightValue.getOrElse(defaultHeaderHeightValue) : defaultHeaderHeightValue;
    const value = contentMinHeightValue.getOrElse("2.625rem")
      + " - " +  headerHeightValue
      + " + " + childrenPanelDeltaHeight.getOrElse("0rem");
    cssBuilder.addProperty(this.cssProp('min-height', Some(value).map(v => `calc(${v})`)));
  }

  static desiredHeightValue(desiredHeight: Option<string>, contentMinHeight: Option<string>, defaultValue: string): number {
    return parseFloat(desiredHeight.orElse(contentMinHeight).getOrElse(defaultValue));
  }

  static flexGrow(cssBuilder: CssBuilder, desiredWidth: Option<number>, contentMinWidth: Option<number>): void {
    cssBuilder.addProperty(this.cssProp("flex-grow", desiredWidth.orElse(contentMinWidth).orElse(Some(1))));
  }

  static flexBasis(cssBuilder: CssBuilder, parentLayout: LayoutType, desiredWidth: Option<string>, contentMinWidth: Option<string>, desiredHeight: Option<string>, contentMinHeight: Option<string>): void {
    if (parentLayout.isHorizontal()) {
      cssBuilder.addProperty(this.cssProp("flex-basis", desiredWidth.orElse(contentMinWidth)));
    } else if (parentLayout.isVertical()) {
      cssBuilder.addProperty(this.cssProp("flex-basis", desiredHeight.orElse(contentMinHeight)));
    }
  }

  static internalMinSizes(cssBuilder: CssBuilder, parentLayout: LayoutType, desiredWidth: Option<string>, contentMinWidth: Option<string>, desiredHeight: Option<string>, contentMinHeight: Option<string>): void {
    if(parentLayout.isStatic()) {
      cssBuilder
        .addProperty(this.cssProp("min-width", desiredWidth.orElse(contentMinWidth)))
        .addProperty(this.cssProp("min-height", desiredHeight.orElse(contentMinHeight)));
    } else if(parentLayout.isHorizontal() || parentLayout.isVertical()) {
      cssBuilder
        .addProperty(this.cssProp("min-width", contentMinWidth)) // desired height is applied by flex-basis so no need to set it here
        .addProperty(this.cssProp("min-height", desiredHeight.orElse(contentMinHeight)));
    }
  }

  static maxSizes(cssBuilder: CssBuilder, maxWidthValue: Option<string|number>, maxHeightValue: Option<string|number>): void {
    cssBuilder
      .addProperty(this.cssProp("max-width", maxWidthValue))
      .addProperty(this.cssProp("max-height", maxHeightValue));
  }

  static minSizes(cssBuilder: CssBuilder, minWidthValue: Option<string|number>, minHeightValue: Option<string|number>): void {
    cssBuilder
      .addProperty(this.cssProp("min-width", minWidthValue))
      .addProperty(this.cssProp("min-height", minHeightValue));
  }

  static margins(cssBuilder: CssBuilder, marginTop: Option<string>, marginRight: Option<string>, marginBottom: Option<string>, marginLeft: Option<string>): void {
    cssBuilder
      .addProperty(this.cssProp("margin-top", marginTop))
      .addProperty(this.cssProp("margin-right", marginRight))
      .addProperty(this.cssProp("margin-bottom", marginBottom))
      .addProperty(this.cssProp("margin-left", marginLeft));
  }

  static paddings(cssBuilder: CssBuilder, paddingTop: Option<string>, paddingRight: Option<string>, paddingBottom: Option<string>, paddingLeft: Option<string>): void {
    cssBuilder
      .addProperty(this.cssProp('padding-top', paddingTop))
      .addProperty(this.cssProp('padding-right', paddingRight))
      .addProperty(this.cssProp('padding-bottom', paddingBottom))
      .addProperty(this.cssProp('padding-left', paddingLeft));
  }

  static staticPosition(cssBuilder: CssBuilder,top: Option<string>, right: Option<string>, bottom: Option<string>, left: Option<string>): void {
    cssBuilder
      .addProperty(this.cssProp("top", top))
      .addProperty(this.cssProp("right", right))
      .addProperty(this.cssProp("bottom", bottom))
      .addProperty(this.cssProp("left", left));
  }

  static gridPosition(cssBuilder: CssBuilder, gridRowStart: Option<number>, gridRowEnd: number, gridColumnStart: Option<number>, gridColumnEnd: number): void {
    cssBuilder
      .addProperty(this.cssProp('grid-row-start', gridRowStart.map(toString).orElse(Some('auto'))))
      .addProperty(this.cssProp('grid-row-end', Some(gridRowEnd).orElse(Some(1)).map(v => 'span ' + v)))
      .addProperty(this.cssProp('grid-column-start', gridColumnStart.map(toString).orElse(Some('auto'))))
      .addProperty(this.cssProp('grid-column-end', Some(gridColumnEnd).orElse(Some(1)).map(v => 'span ' + v)));
  }

  static borders(cssBuilder: CssBuilder,
                 borderTopWidth: Option<string>, borderRightWidth: Option<string>, borderBottomWidth: Option<string>, borderLeftWidth: Option<string>,
                 borderTopColor: Option<string>, borderRightColor: Option<string>, borderBottomColor: Option<string>, borderLeftColor: Option<string>,
                 borderRadius: Option<string>): void {
    let widths: Array<string> = [];
    widths.push(this.cssProp('border-top-width', borderTopWidth));
    widths.push(this.cssProp('border-right-width', borderRightWidth));
    widths.push(this.cssProp('border-bottom-width', borderBottomWidth));
    widths.push(this.cssProp('border-left-width', borderLeftWidth));
    widths = widths.filter(v => v.length > 0);

    cssBuilder.addProperties(widths);

    let colors: Array<string> = [];
    let colorClasses: Array<string> = [];
    if(borderTopColor.exists(CssUtils.isCssClass)) {
      colorClasses.push(CssUtils.toBorderClass("top", borderTopColor.get()));
    } else {
      colors.push(this.cssProp('border-top-color', borderTopColor));
    }
    if(borderRightColor.exists(CssUtils.isCssClass)) {
      colorClasses.push(CssUtils.toBorderClass("end", borderRightColor.get()));
    } else {
      colors.push(this.cssProp('border-top-color', borderRightColor));
    }
    if(borderBottomColor.exists(CssUtils.isCssClass)) {
      colorClasses.push(CssUtils.toBorderClass("bottom", borderBottomColor.get()));
    } else {
      colors.push(this.cssProp('border-top-color', borderBottomColor));
    }
    if(borderLeftColor.exists(CssUtils.isCssClass)) {
      colorClasses.push(CssUtils.toBorderClass("start", borderLeftColor.get()));
    } else {
      colors.push(this.cssProp('border-top-color', borderLeftColor));
    }

    colors = colors.filter(v => v.length > 0);
    colorClasses = colorClasses.filter(v => v.length > 0);

    cssBuilder.addProperties(colors);
    cssBuilder.addClasses(colorClasses);

    const borderStyle = (widths.length > 0 || colors.length > 0)? Some('solid'): None();
    cssBuilder.addProperty(this.cssProp('border-style', borderStyle));

    cssBuilder.addProperty(this.cssProp('border-radius', borderRadius));

  }

  static shadows(cssBuilder: CssBuilder, outerShadow: Option<string>, innerShadow: Option<string>): void {
    innerShadow.map(v => "inset " + v);
    if(outerShadow.isDefined() && innerShadow.isDefined()) {
      cssBuilder.addProperty(this.cssProp('box-shadow', Some(outerShadow.get() + ", " + innerShadow.get())));
    } else {
      cssBuilder.addProperty(this.cssProp('box-shadow', outerShadow.orElse(innerShadow)));
    }
  }

  static sectionContentOverflow(cssBuilder: CssBuilder, componentMaxWidth: Option<string>, componentMaxHeight: Option<string>){
    // If content there is max size value on axis set overflow to auto on that axis
    cssBuilder.addProperty(this.cssProp('overflow-x', componentMaxWidth.map(v => 'auto')));
    cssBuilder.addProperty(this.cssProp('overflow-y', componentMaxHeight.map(v => 'auto')));
  }

  static backgroundColor(cssBuilder: CssBuilder, color: Option<string>): void {
    color.forEach(c => {
      if(CssUtils.isColor(c)) {
        cssBuilder.addProperty(CssUtils.cssPropNoOption('background-color', c) + this.cssPropNoOption('background-image', 'none'));
      } else {
        const backgroundClass = CssUtils.toBackgroundClass(c);
        if (backgroundClass) {
          cssBuilder.addClass(backgroundClass);
        } else {
          console.error("Unsupported background color value ["+c+"]");
          toastr.error("Unsupported background color value ["+c+"]");
        }
      }
    })
  }

  static toBorderClass(position: "top"|"end"|"bottom"|"start", propertyValue: string): string {
    switch(propertyValue.substring(1)) {
      case "content-background-color": return `brd-${position}-content-background`;
      case "background-color": return `brd-${position}-background`;
      case "accent-color": return `brd-${position}-accent-color`;
      case "main-text-color": return `brd-${position}-main-text-color`;
      case "lighter-text-color": return `brd-${position}-lighter-text-color`;
      case "error-color": return `brd-${position}-error`;
      case "warning-color": return `brd-${position}-warning`;
      case "info-color": return `brd-${position}-info`;
      case "success-color": return `brd-${position}-success`;
      case "transparent": return `brd-${position}-transparent`;
      default: return "";
    }
  }

  static toBackgroundClass(propertyValue: string): string|null {
    switch(propertyValue.substring(1)) {
      case "content-background-color": return "bg-content-background-color";
      case "background-color": return "bg-background-color";
      case "accent-color": return "bg-accent-color";
      case "main-text-color": return "bg-main-text-color";
      case "lighter-text-color": return "bg-lighter-text-color";
      case "error-color": return "bg-error";
      case "warning-color": return "bg-warning";
      case "info-color": return "bg-info";
      case "success-color": return "bg-success";
      case "transparent": return "bg-transparent";
      default: return null;
    }
  }


  static toFontColorClass(propertyValue: string): string {
    switch(propertyValue.substring(1)) {
      case "content-background-color": return "fc-content-background-color";
      case "background-color": return "fc-background-color";
      case "accent-color": return "fc-accent-color";
      case "main-text-color": return "fc-main-text-color";
      case "lighter-text-color": return "fc-lighter-text-color";
      case "error-color": return "fc-error";
      case "warning-color": return "fc-warning";
      case "info-color": return "fc-info";
      case "success-color": return "fc-success";
      case "transparent": return "fc-transparent";
      default: return "";
    }
  }

  static gaps(cssBuilder: CssBuilder, rowGap:Option<string>, columnGap: Option<any>) {
    cssBuilder.addProperty(this.cssProp('row-gap', rowGap));
    cssBuilder.addProperty(this.cssProp('column-gap', columnGap));
  }

  static fontCss(cssBuilder: CssBuilder, fontFamily: Option<string>, fontSize: Option<string>, bold: boolean, italic: boolean, underlined: boolean, lineThrough: boolean, color: Option<string>): void {
    const fontWeight = bold ? Some("500") : None();
    const fontStyle = italic ? Some("italic") : None();
    let decoration = "";
    if(underlined){ decoration += "underline " }
    if(lineThrough){ decoration += "line-through" }
    decoration = decoration.trim();
    const textDecoration = decoration.length > 0 ? Some(decoration) : None();

    cssBuilder.addProperty(this.cssProp('font-family', fontFamily));
    cssBuilder.addProperty(this.cssProp('font-size', fontSize));
    cssBuilder.addProperty(this.cssProp('font-weight', fontWeight));
    cssBuilder.addProperty(this.cssProp('font-style', fontStyle));
    cssBuilder.addProperty(this.cssProp('text-decoration', textDecoration));
    color.forEach(c => {
      if(CssUtils.isColor(c)) {
        cssBuilder.addProperty(CssUtils.cssPropNoOption('color', c));
      } else {
        const fontColorClass = CssUtils.toFontColorClass(c);
        if (fontColorClass) {
          cssBuilder.addClass(fontColorClass);
        } else {
          console.error("Unsupported background color value ["+c+"]");
          toastr.error("Unsupported background color value ["+c+"]");
        }
      }
    })
  }

  static childrenPlainMinWidth(cssBuilder: CssBuilder, contentMinWidth: Option<string>) {
    return cssBuilder.addProperty(this.cssProp("min-width", contentMinWidth));
  }



  static verticalAlign(cssBuilder: CssBuilder, align: string) {
    switch(align) {
      case TextVerticalAlign.top.name: cssBuilder.addProperty("align-items: start;"); break;
      case TextVerticalAlign.center.name: cssBuilder.addProperty("align-items: center;"); break;
      case TextVerticalAlign.bottom.name: cssBuilder.addProperty("align-items: end;"); break;
      default: throw new Error("Unsupported vertical align value ["+align+"]");
    }
  }


  static horizontalAlign(cssBuilder: CssBuilder, align: string): void {
    switch(align) {
      case "left": cssBuilder.addProperties(["justify-content: left;", "text-align: left;"]); break; // deprecated
      case TextAlign.start.name: cssBuilder.addProperties(["justify-content: left;", "text-align: left;"]); break;
      case TextAlign.center.name: cssBuilder.addProperties(["justify-content: center;", "text-align: center;"]); break;
      case "right": cssBuilder.addProperties(["justify-content: right;", "text-align: right;"]); break; // deprecate]d
      case TextAlign.end.name: cssBuilder.addProperties(["justify-content: right;", "text-align: right;"]); break;
      case TextAlign.justify.name: cssBuilder.addProperties(["justify-content: center;", "text-align: justify;"]); break;
      default: throw new Error("Unsupported align value ["+align+"]");
    }
  }

  static entriesMaxSizes(cssBuilder: CssBuilder, maxWidthValue: Option<string>, maxHeightValue: Option<string>, headerHeightValue: Option<string>, headerEnabled: boolean) {
    const headerHeight = headerEnabled ? headerHeightValue : None();
    const maxHeightCalc = maxHeightValue.map(v => v + " - " + headerHeight.getOrElse("0rem"));

    cssBuilder.addProperty(this.cssProp("max-width", maxWidthValue));
    cssBuilder.addProperty(this.cssProp('max-height', maxHeightCalc.map(v => `calc(${v})`)));
  }

  static repeatableEntryLayout(innerLayout: LayoutType, entryLayout: EntriesLayoutType, minWidthValue: Option<string>, minHeightValue: Option<string>, childrenPanelDeltaHeightValue: Option<string>) {
    const entryWidth = this.cssProp("min-width", minWidthValue);

    const entryHeight = innerLayout.isStatic() ?
      this.cssProp('min-height', minHeightValue.map(v => `calc(${v} + ${childrenPanelDeltaHeightValue.getOrElse('0rem')})`)) :
      this.cssProp('min-height', minHeightValue);

    let css = "";
    if(entryLayout.isHorizontal()){
      css += entryWidth + entryHeight + 'display: inline-block';
    } else if (entryLayout.isVertical()){
      css += entryHeight + 'display: block';
    }

    return css;
  }

  static columnLabelWidth(minWidth:Option<string>, maxWidth: Option<string>) {
    let css = "";
    css += this.cssProp("min-width", minWidth.orElse(Some('2.625rem')));
    css += this.cssProp("max-width", maxWidth);
    return css;
  }

}


export class CssBuilder {
  static create() {
    return new CssBuilder();
  }

  private properties: Array<string> = [];
  private classes: Array<string> = [];

  addProperty(property: string) {
    if (property.length > 0) {
      this.properties.push(property);
    }
    return this;
  }

  addClass(cssClass: string) {
    this.classes.push(cssClass);
    return this;
  }

  addProperties(properties: Array<string>) {
    properties.forEach(w => this.addProperty(w));
    return this;
  }

  toCss(): string {
    return this.properties.map(p => p.endsWith(";") ? p : (p+";")).join("");
  }

  toCssClasses() {
    return this.classes.join(" ");
  }

  addClasses(colorClasses: Array<string>) {
    this.classes.push(...colorClasses);
    return this;
  }

  hasAnyCssProperty() {
    return this.properties.length > 0;
  }
}
