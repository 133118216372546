import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  I18nTextProperty,
  OptionalStringProperty,
  TrileanProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";

export class SingleCheckboxComponentRef extends InputComponentRef {
  static className = "SingleCheckboxComponentRef";

  className() {
    return SingleCheckboxComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: SingleCheckboxComponentRef): SingleCheckboxComponentRef {
    return new SingleCheckboxComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("SingleCheckboxRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = SingleCheckboxComponentRef.DEFAULT_PROPERTIES;
}

export class SingleCheckboxComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "SingleCheckboxComponentDefinition";
  className() {
    return SingleCheckboxComponentDefinition.className;
  }
  typeName() {
    return "SingleCheckbox";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules, SingleCheckboxComponentDefinition.DEFAULT_PROPERTIES);
  }

  get valueLabel(): I18nTextProperty {return this.properties.getI18nTextProperty("valueLabel", this.defaultPropertiesProvider)}

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  get threeStates(): BooleanProperty {return this.properties.getBooleanProperty("threeStates", this.defaultPropertiesProvider)};

  iconBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("iconBackgroundColor", defaultProvider);
  }

  static copy(other: SingleCheckboxComponentDefinition): SingleCheckboxComponentDefinition {
    return new SingleCheckboxComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [SingleCheckboxComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("SingleCheckboxComponentDefinition",
    (name: string) => {switch (name) {
      case "threeStates": return BooleanProperty.ofFalse();
      case "defaultValue": return TrileanProperty.ofFalse();
      case "iconBackgroundColor": return OptionalStringProperty.disabled("#000000ff");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
