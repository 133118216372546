import {
  AggregateId,
  AggregateIdWithVersion,
  AggregateVersion,
  Failure,
  htmlEscape,
  Success,
  toastr,
  Try,
  Typed
} from "@utils";
import {AuthenticatedHttp} from "./AuthenticatedHttp";
import {commandResponseHandler} from "./ResponsesHandler";
import {CommandResponse} from "./StandardResponses";


export class AggregateCommandOrderer {

    private commandsQueue: {[aggregateId: string]: (() => void)[]} = {};
    private aggregateVersions: {[aggregateId: string]: AggregateVersion} = {};

    ordered(aggregateId: AggregateId, expectedVersion: AggregateVersion,
            command: (aggregateId: AggregateId, currentExpectedVersion: AggregateVersion, onFinish: (newVersion: AggregateVersion) => void) => void) {

      if (!this.commandsQueue[aggregateId.id]) {
        this.commandsQueue[aggregateId.id] = [];
      }

      const queue = this.commandsQueue[aggregateId.id];

      queue.push(() => {
        let expVersion = expectedVersion;
        if (this.aggregateVersions[aggregateId.id]) {
          expVersion = new AggregateVersion(Math.max(expectedVersion.asInt, this.aggregateVersions[aggregateId.id].asInt))
        }

        command(aggregateId, expVersion, (newVersion: AggregateVersion) => {
          this.aggregateVersions[aggregateId.id] = newVersion;
          queue.shift();

          if (queue.length > 0) {
            queue[0]();
          }
        });
      });

      if (queue.length === 1) {
        queue[0]();
      }

    }
  }


  export class AggregateCommandBus {

    protected authenticatedHttp: AuthenticatedHttp;

    private commandsQueue: {[aggregateId: string]: (() => void)[]} = {};
    private aggregateVersions: {[aggregateId: string]: AggregateVersion} = {};
    private aggregateCallPending: {[aggregateId: string]: boolean} = {};

    constructor(authenticatedHttp: AuthenticatedHttp) {
      this.authenticatedHttp = authenticatedHttp;
    }

    protected command(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion}, onSuccess: (version: AggregateVersion) => void, onFailure: () => void) {

      this.customResponseCommand(url, command, (response: Typed<CommandResponse>) => {
        let version: Try<AggregateVersion> = Failure("command failure");
        commandResponseHandler(response).onSuccess((aggregateId:AggregateId, aggregateVersion:AggregateVersion) => {
          version = Success(aggregateVersion);
          onSuccess(aggregateVersion);
        }).onFailure((exceptions: Array<string>) => {
          toastr.error(htmlEscape(exceptions.join(", ")));
          onFailure();
        }).handle();
        return version;
      }, () => {
        toastr.error("Problem communicating with server");
        onFailure();
      });

    }

    protected customResponseCommand<RESPONSE>(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion},
                                onSuccess: (response: RESPONSE) => Try<AggregateVersion>, onFailure: () => void) {

      const aggregateId = command.aggregateId.id;

      const queue = this.queueForAggregate(command.aggregateId);

      queue.push(() => {
        let expVersion = command.expectedVersion;
        if (this.aggregateVersions[aggregateId]) {
          expVersion = new AggregateVersion(Math.max(command.expectedVersion.asInt, this.aggregateVersions[aggregateId].asInt))
        }

        command.expectedVersion = expVersion;

        this.authenticatedHttp.post(url, command, (data:RESPONSE) => {
          const result = onSuccess(data);
          if(result.isSuccess()) {
            this.aggregateVersions[aggregateId] = result.result;
          }

          if (queue.length > 0) {
            let queued = queue.shift();
            if(queued === undefined) {
              throw new Error("Unexpected undefined")
            } else {
              queued();
            }
          } else {
            this.aggregateCallPending[aggregateId] = false;
          }

        }, () => {
          this.aggregateCallPending[aggregateId] = false;
          onFailure();
        });
      });

      if (queue.length > 0 && !this.aggregateCallPending[aggregateId]) {
        this.aggregateCallPending[aggregateId] = true;
        let queued = queue.shift();
        if(queued === undefined) {
          throw new Error("Unexpected undefined")
        } else {
          queued();
        }
      }
    }


    protected firstCommand(url: string, command: {}, onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: () => void) {

      this.firstCommandCustomResponse(url, command, (response: Typed<CommandResponse>) => {
        let idWithVersion: Try<AggregateIdWithVersion> = Failure("command failure");
        commandResponseHandler(response).onSuccess((aggregateId:AggregateId, aggregateVersion:AggregateVersion) => {
          idWithVersion = Success(new AggregateIdWithVersion(aggregateId, aggregateVersion));
          onSuccess(aggregateId, aggregateVersion);
        }).onFailure((exceptions: Array<string>) => {
          onFailure();
          toastr.error(htmlEscape(exceptions.join(", ")));
        }).handle();
        return idWithVersion;
      }, () => {
        toastr.error("Problem communicating with server");
        onFailure();
      });

    }

    protected firstCommandCustomResponse<RESPONSE>(url: string, command: {}, onSuccess: (response: RESPONSE) => Try<AggregateIdWithVersion>, onFailure: () => void) {
      this.authenticatedHttp.post(url, command, (data:RESPONSE) => {
        const result = onSuccess(data);
        if(result.isSuccess()) {
          this.aggregateVersions[result.result.id.id] = result.result.version;
        }
      }, () => {
        onFailure();
      });
    }

    protected deleteCommand(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion}, onSuccess: () => void, onFailure: () => void) {
      this.deleteCommandCustomResponse(url, command, (response: Typed<CommandResponse>) => {
        let version: Try<AggregateVersion> = Failure("command failure");
        commandResponseHandler(response).onSuccess((aggregateId:AggregateId, aggregateVersion:AggregateVersion) => {
          version = Success(aggregateVersion);
          onSuccess();
        }).onFailure((exceptions: Array<string>) => {
          toastr.error(htmlEscape(exceptions.join(", ")));
          onFailure();
        }).handle();
        return version;
      }, () => {
        toastr.error("Problem communicating with server");
        onFailure();
      });

    }

    protected deleteCommandCustomResponse<RESPONSE>(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion},
                                onSuccess: (response: RESPONSE) => void, onFailure: () => void) {

      const aggregateId = command.aggregateId.id;

      const queue = this.queueForAggregate(command.aggregateId);

      queue.push(() => {
        let expVersion = command.expectedVersion;
        if (this.aggregateVersions[aggregateId]) {
          expVersion = new AggregateVersion(Math.max(command.expectedVersion.asInt, this.aggregateVersions[aggregateId].asInt))
        }

        command.expectedVersion = expVersion;

        this.authenticatedHttp.post(url, command, (data: RESPONSE) => {
          onSuccess(data);

          if (queue.length > 0) {
            throw new Error("Cannot handle more commands, after deletion");
          } else {
            this.aggregateCallPending[aggregateId] = false;
          }

        }, () => {
          this.aggregateCallPending[aggregateId] = false;
          onFailure();
        });
      });

      if (queue.length > 0 && !this.aggregateCallPending[aggregateId]) {
        this.aggregateCallPending[aggregateId] = true;
        let queued = queue.shift();
        if(queued === undefined) {
          throw new Error("Unexpected undefined")
        } else {
          queued();
        }
      }
    }



    private queueForAggregate(aggregateId: AggregateId): (() => void)[] {
      if (!this.commandsQueue[aggregateId.id]) {
        this.commandsQueue[aggregateId.id] = [];
      }
      return this.commandsQueue[aggregateId.id];
    }

  }

