import {Directive, ElementRef, Input, OnDestroy, OnInit} from "@angular/core";
import {Theme, UserSettingsStateService} from "@shared";
import {$$, $$Element} from "@utils";
import {t} from "i18next";
import {Subscription} from "rxjs";

@Directive({
  selector: '[dark-mode-class]'
})
export class DarkModeClassDirective implements OnInit, OnDestroy {

  private _darkModeClass: string = "";

  private $$element: $$Element;

  private lastClassSet: string = "";
  private themeSubscription: Subscription;

  @Input("dark-mode-class") set myDarkModeClass(t: string) {
    this._darkModeClass = t;
    this.updateClass();
  };

  constructor(private readonly elementRef: ElementRef,
              private readonly userSettingsService: UserSettingsStateService) {
    this.$$element = $$(this.elementRef.nativeElement);

    this.themeSubscription = userSettingsService.getThemeObservable().subscribe(themeFromSettings => {
      this.updateClass();
    });


  }

  ngOnInit(): void {
    this.updateClass();
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }



  private updateClass() {
    if(this.lastClassSet.length > 0 && this.lastClassSet !== this._darkModeClass) {
      this.$$element.removeClass(this.lastClassSet);
      this.lastClassSet = "";
    }

    const darkMode = this.userSettingsService.getEffectiveTheme() === Theme.dark;

    if(darkMode) {
      this.$$element.addClass(this._darkModeClass);
      this.lastClassSet = this._darkModeClass;
    } else {
      this.$$element.removeClass(this._darkModeClass);
      this.lastClassSet = "";
    }
  }


}
