import {Component, Input} from "@angular/core";
import {RepeatableContainerViewModel} from "./RepeatableContainerViewModel";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-repeatable-container',
  templateUrl: './repeatable-container.component.html',
  host: {
    "[class]":"'componentBody RepeatableContainer ' + viewModel.customCssClass + ' ' + viewModel.cssClasses + ' entriesLayout_'+viewModel.entriesLayoutClass+' entriesLayoutWrap_'+viewModel.entriesLayoutWrapClass",
    "[style]":"viewModel.css",
  }
})
export class RepeatableContainerComponent {
  @Input({required:true}) viewModel!: RepeatableContainerViewModel;

  readonly children = new ContainerChildrenHandler();

}
