<div [ngSwitch]="nodeType">
  <my-tooltip *ngIf="showTooltip" [delay]="100" position="top" cssClass="nodeSummaryTooltip">
    <ng-container *ngIf="prefixTooltipInfo || suffixTooltipInfo">
      <div class="additionalInfo" *ngIf="prefixTooltipInfo">{{prefixTooltipInfo}}</div>
      <div class="name">{{name}}</div>
      <div class="additionalInfo" *ngIf="suffixTooltipInfo">{{suffixTooltipInfo}}</div>
    </ng-container>
    <ng-container *ngIf="!prefixTooltipInfo && !suffixTooltipInfo">
      {{name}}
    </ng-container>
  </my-tooltip>
  <ng-container *ngIf="nodeType === 'person'">
    <div class="avatarContainer" *ngIf="showIcon">
      <div class="avatar">
        <span class="initials">{{initials}}</span>
        <img *ngIf="!avatarUrl && gravatarBlobUrl" class="gravatar i$spaceBetweenColumnsmg-circle"  onerror='this.style.display = "none"' [src]="gravatarBlobUrl" draggable="false"/>
        <img *ngIf="avatarUrl" class="gravatar i$spaceBetweenColumnsmg-circle" onerror='this.style.display = "none"' [src]="avatarUrl" draggable="false" />
      </div>
    </div>
    <div *ngIf="showName">
      {{name}}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'application'">
    <div [class.theme-application-icon-medium]="smallIcon" [class.theme-application-icon]="!smallIcon"
      class="theme-application-icon{{applicationIcon.colorNo}}" *ngIf="showIcon">
      <i class="{{applicationIcon.icon}}"></i>
    </div>
    <div *ngIf="showName">
      {{name}}
    </div>
  </ng-container>
  <ng-container *ngIf="nodeType !== 'person' && nodeType !== 'application'">
    <div class="{{iconClass}} theme-initials-icon" *ngIf="!showName && showIcon">
      {{initials}}
    </div>
    <div class="{{iconClass}}" *ngIf="showName && showIcon">
      <i [class]="icon"></i>
    </div>
    <div *ngIf="showName" [class]="nameClass">
      {{name}}
    </div>
  </ng-container>
</div>
