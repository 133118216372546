<button class="track" (click)="viewModel.onClick()" tabindex="0"
        [disabled]="viewModel.disabled"
        [style.background-color]="(viewModel.value === true ? viewModel.yesBackgroundColorCss : viewModel.noBackgroundColorCss) | sanitizeStyle"
        [class.confirm]="viewModel.value === true"
        [class.deny]="viewModel.value === false"
        [class.unknown]="viewModel.value === undefined">
  <div class="yesLabel">{{viewModel.yesLabel}}</div>
  <div class="noLabel" [style.color]="viewModel.noTextColorCss | sanitizeStyle">{{viewModel.noLabel}}</div>
  <div class="handle" [style.background-color]="viewModel.knobCssBackgroundColor"></div>
</button>
