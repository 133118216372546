import {
  BooleanVariable,
  BooleanVariableType, BusinessVariableTypeName, ComparisonOperator,
  DateTimeVariable,
  DateTimeVariableType, DateVariable,
  DateVariableType,
  NullVariable, NumberVariable,
  NumberVariableType, StringVariable,
  StringVariableType
} from "@shared-model";

export class SearchAttribute {
  constructor(readonly identifier: string,
              readonly name: string,
              readonly typeName: BusinessVariableTypeName|"",
              readonly mock: boolean) {}
}

export class SelectedSearchAttribute {

  static numericOperators = ["==", "<", "<=", ">", ">=", "!="];
  static textOperators = ["==", "like", "<", "<=", ">", ">=", "!="];
  static otherOperators = ["==", "!="];
  private lastType: string|null = null;

  public operators: Array<string>;
  constructor(public attribute: SearchAttribute,
              public options: Array<SearchAttribute>,
              public operator: string,
              public value: any|null) {
    this.operators = SelectedSearchAttribute.otherOperators;
  }

  attributeChanged() {


    if(!this.attribute.mock && this.attribute.typeName == StringVariableType.typeName) {
      this.operators = SelectedSearchAttribute.textOperators;
    } else if (!this.attribute.mock && (this.attribute.typeName == NumberVariableType.typeName ||
      this.attribute.typeName == DateVariableType.typeName ||
      this.attribute.typeName == DateTimeVariableType.typeName)) {
      this.operators = SelectedSearchAttribute.numericOperators;
    } else {
      this.operators = SelectedSearchAttribute.otherOperators;
    }

    if(this.operators.indexOf(this.operator) == -1) {
      this.operator = "==";
    }

    let newLastType: string|null = null;
    if(!this.attribute.mock) {
      newLastType = this.attribute.typeName;
    }

    if(newLastType != this.lastType) {
      this.lastType = newLastType;
      this.value = null;
    }

  }

  toBusinessVariable() {
    if(this.attribute.mock || this.value == null) {
      return NullVariable.instance;
    } else {
      switch (this.attribute.typeName) {
        case StringVariableType.typeName: return new StringVariable(this.value);
        case NumberVariableType.typeName: return new NumberVariable(this.value);
        case DateVariableType.typeName: return new DateVariable(this.value);
        case DateTimeVariableType.typeName: return new DateTimeVariable(this.value);
        case BooleanVariableType.typeName: return new BooleanVariable(this.value == true);
        default: return NullVariable.instance;
      }
    }
  }

  toComparisonOperator() {
    switch (this.operator) {
      case "==": return ComparisonOperator.E;
      case "like": return ComparisonOperator.LIKE;
      case "<": return ComparisonOperator.LT;
      case "<=": return ComparisonOperator.LTE;
      case ">": return ComparisonOperator.GT;
      case ">=": return ComparisonOperator.GTE;
      case "!=": return ComparisonOperator.NE;
      default: throw new Error("Incorrect operator ["+this.operator+"]");
    }
  }

  notEmpty() {
    return !this.attribute.mock;
  }
}
