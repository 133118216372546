import {
  __,
  AggregateVersion,
  AnyPersonId,
  AnyPersonIdFactory, AnyPersonIdHelper, DocumentTypeId,
  EmailId,
  FileUri,
  FlowId,
  LocalDateTime,
  MailboxId, None,
  Option, OrganizationId, OrganizationNodeId, PersonId, Some,
  Typed
} from "@utils";
import {
  BusinessVariable,
  BusinessVariableFactory,
  EmailBasicInfo,
  FieldQueryExpression,
  TimeConstraint
} from "@shared-model";
import {EmailAttachmentSummary, EmailComment, EmailUID} from "./emails.common-model";

export class GetEmailsSummaries {
  constructor(readonly uris: Array<FileUri>) {}
}



export class FoundEmail {
  constructor(readonly id: EmailId,
              readonly created: LocalDateTime) {}

  static copy(other: FoundEmail) {
    return new FoundEmail(
      EmailId.copy(other.id),
      LocalDateTime.copy(other.created));
  }
}

export class EmailSearchResult {
  constructor(readonly emails: Array<FoundEmail>,
              readonly moreAvailable: boolean,
              readonly timeoutError: string) {}

  static copy(other: EmailSearchResult) {
    return new EmailSearchResult(
      other.emails.map(FoundEmail.copy),
      other.moreAvailable,
      other.timeoutError);
  }
}

export class EmailsSummaries {
  constructor(readonly summaries: Array<[EmailId, EmailBasicInfo]>) {}

  static copy(other: EmailsSummaries) {
    return new EmailsSummaries(other.summaries.map(s => <[EmailId, EmailBasicInfo]>[EmailId.copy(s[0]), EmailBasicInfo.copy(s[1])]));
  }
}


export class IncomingEmailInfo {
  constructor(readonly id: EmailId,
              readonly version: AggregateVersion,
              readonly email: IncomingEmail,
              readonly sanitizedHtmlContent: Option<string>,
              readonly htmlContent: Option<string>,
              readonly textContent: Option<string>) {}

  static copy(other: IncomingEmailInfo) {
    return new IncomingEmailInfo(EmailId.copy(other.id),
      AggregateVersion.copy(other.version),
      IncomingEmail.copy(other.email),
      Option.copy(other.sanitizedHtmlContent),
      Option.copy(other.htmlContent),
      Option.copy(other.textContent))
  }
}

export class EmailFileInfo {
  constructor(readonly uri: FileUri,
              readonly size: number) {}

  static copy(other: EmailFileInfo) {
    return new EmailFileInfo(FileUri.copy(other.uri), other.size);
  }
}

export class IncomingEmail {
  constructor(readonly emailData: EmailData,
              readonly emailFile: Option<FileUri>,
              readonly organizationId: OrganizationId,
              readonly mailboxId: MailboxId,
              readonly flows: Array<FlowId>,
              readonly documentTypeId: Option<DocumentTypeId>,
              readonly properties: Array<[string, Typed<BusinessVariable>]>,
              readonly comments: Array<EmailComment>,
              readonly sendBy: Option<OrganizationNodeId>,
              readonly seen: Array<Typed<AnyPersonId>>,
              readonly labels: Array<string>,
              readonly deleted: boolean
  ) {}

  seenUnwrapped(): Array<AnyPersonId> {
    return this.seen.map(s => Typed.value(s));
  }

  seenBy(personId: PersonId): boolean {
    return __(this.seenUnwrapped()).exists(p => AnyPersonIdHelper.equals(p, personId));
  }

  static copy(other: IncomingEmail) {
    return new IncomingEmail(EmailData.copy(other.emailData),
      Option.copy(other.emailFile, FileUri.copy),
      OrganizationId.copy(other.organizationId),
      MailboxId.copy(other.mailboxId),
      other.flows.map(FlowId.copy),
      Option.copy(other.documentTypeId),
      other.properties.map(p => <[string,  Typed<BusinessVariable>]>[p[0], BusinessVariableFactory.copyTyped(p[1])]),
      other.comments.map(EmailComment.copy),
      Option.copy(other.sendBy, OrganizationNodeId.copy),
      other.seen.map(AnyPersonIdFactory.copyTyped),
      other.labels, other.deleted)
  }
}

export class EmailAddress {
  constructor(readonly address: string,
              readonly personal: Option<string>
  ) {}

  static copy(other: EmailAddress) {
    return new EmailAddress(other.address, Option.copy(other.personal))
  }
}

export class EmailData {
  constructor(readonly uid: Option<EmailUID>,
              readonly subject: string,
              readonly contentSummary: string,
              readonly received: boolean, //received or sent
              readonly created: LocalDateTime,
              readonly receivedDate: Option<LocalDateTime>,
              readonly sentDate: Option<LocalDateTime>,
              readonly from: EmailAddress,
              readonly to: Array<EmailAddress>,
              readonly cc: Array<EmailAddress>,
              readonly bcc: Array<EmailAddress>,
              readonly messageId: Option<string>, readonly inReplyTo: Option<string>, readonly references: Array<string>, // message-id, in-reply-to, references headers in message headers
              readonly attachments: Array<EmailAttachmentSummary>,
              readonly size: number
  ) {}

  static copy(other: EmailData) {
    return new EmailData(Option.copy(other.uid, EmailUID.copy), other.subject, other.contentSummary,
      other.received,
      LocalDateTime.copy(other.created),
      Option.copy(other.receivedDate, LocalDateTime.copy),
      Option.copy(other.sentDate, LocalDateTime.copy),
      EmailAddress.copy(other.from), other.to.map(EmailAddress.copy), other.cc.map(EmailAddress.copy), other.bcc.map(EmailAddress.copy),
      Option.copy(other.messageId), Option.copy(other.inReplyTo), other.references.slice(),
      other.attachments.map(EmailAttachmentSummary.copy), other.size)
  }
}




export class EmailSearchQuery {

  constructor(public textQuery: string,
              public resultsAfter: Option<FoundEmail>,
              public results: Option<number>,
              public received: boolean,
              public sent: boolean,
              public created: Option<TimeConstraint>,
              public documentType: Array<string>,
              public includeDeleted: boolean,
              public fieldQuery: Option<Typed<FieldQueryExpression>>) {}

  static empty(): EmailSearchQuery {
    return new EmailSearchQuery("", None(), Some(1),true, true, None(), [], false, None());
  }

  notEmpty() {
    return this.textQuery.trim().length > 0 ||
      this.created.exists(c => c.notEmpty()) ||
      !this.received ||
      !this.sent ||
      this.documentType.length > 0 ||
      this.fieldQuery.isDefined();
  }

}


export class FindEmailsByQueryRest {
  constructor(readonly query: EmailSearchQuery) {}
}
