<ng-container *ngIf="!labelOnStart">
  <div class="outer">
    <div class="track"><span class="yes" *ngIf="yesLabel">{{yesLabel}}</span><span *ngIf="noLabel" class="no">{{noLabel}}</span></div>
    <div class="handle"></div>
  </div>
  <span class="switchLabel" *ngIf="label">{{label}}</span>
</ng-container>

<ng-container *ngIf="labelOnStart">
  <span class="switchLabel" *ngIf="label">{{label}}</span>
  <div class="outer">
    <div class="track">
      <span class="yes" *ngIf="yesLabel">{{yesLabel}}</span>
      <span  class="no"*ngIf="noLabel">{{noLabel}}</span>
    </div>
    <div class="handle"></div>
  </div>
</ng-container>
