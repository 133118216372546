import {Component, Input, OnInit} from "@angular/core";
import {ThemeFontSize, UserSettingsStateService} from "@shared";

@Component({
  selector: 'my-font-size-selector',
  templateUrl: './font-size-selector.component.html'
})
export class FontSizeSelectorComponent implements OnInit {

  m: boolean = false;
  l: boolean = false;
  xl: boolean = false;

  constructor(readonly userSettingsService: UserSettingsStateService) {

  }

  ngOnInit(): void {
    const fontSize = this.userSettingsService.getFontSize();
    this.m = fontSize == ThemeFontSize.M;
    this.l = fontSize == ThemeFontSize.L;
    this.xl = fontSize == ThemeFontSize.XL;
  }

  changeFontSize(fontSize: string) {

    this.m = fontSize == ThemeFontSize.M.name;
    this.l = fontSize == ThemeFontSize.L.name;
    this.xl = fontSize == ThemeFontSize.XL.name;
    this.userSettingsService.changeFontSize(ThemeFontSize.byName(fontSize));
  }


}
