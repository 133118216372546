import {BusinessVariable, CaseVariable, EmailVariable, FileVariableV2} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {SingleAttachmentInputComponentDefinition} from "@screen-common";

export class SingleAttachmentInputComponentState extends LabeledScreenComponentState {
  static className = "SingleAttachmentInputComponentState";
  className() {
    return SingleAttachmentInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalBusinessVariableProperty(SingleAttachmentInputComponentDefinition.MODEL);
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get allowedExtensions() {
    return this.properties.optionalStringArrayProperty("allowedExtensions");
  }

  get maxFileSize() {
    return this.properties.optionalNumberProperty("maxFileSize");
  }

  get displayMode() {
    return this.properties.stringProperty("displayMode");
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get allowDir() {
    return this.properties.booleanProperty("allowDir");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case SingleAttachmentInputComponentDefinition.MODEL:
        if(value.isDefined() && (
          value.get().className() === FileVariableV2.className ||
          value.get().className() === CaseVariable.className ||
          value.get().className() === EmailVariable.className)) {
          this.properties.putValue(SingleAttachmentInputComponentDefinition.MODEL, value.get());
        } else if(value.isDefined()) {
          throw Error("Unable to set value, expected File, Email or Case but was ["+value.get().className()+"]");
        } else {
          this.properties.clearValue(SingleAttachmentInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: SingleAttachmentInputComponentState) {
    return new SingleAttachmentInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class SingleAttachmentInputComponentRefState extends ScreenComponentRefState {
  static className = "SingleAttachmentInputComponentRefState";
  className() {
    return SingleAttachmentInputComponentRefState.className;
  }

  get addRemoveEnabled() {
    return this.properties.booleanProperty("addRemoveEnabled");
  }


  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: SingleAttachmentInputComponentRefState) {
    return new SingleAttachmentInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
