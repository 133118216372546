export class RGBA {
  constructor(
    public r:number,
    public g:number,
    public b:number,
    public a:number) {}
}

export class Color {
  constructor(private rgba: string) {}

  asRgba(): RGBA {
    return new RGBA( this.getRed(), this.getGreen(), this.getBlue(), this.getAlpha() / 255);
  }

  getRgba() {
    return this.rgba;
  }

  getRed(): number {
    const hashIndex = this.rgba.indexOf("#");
    if(hashIndex == 0 && this.rgba.length === 7 || this.rgba.length === 9) {
      return parseInt(this.rgba.substring(1, 3), 16)
    } else if(hashIndex < 0 && this.rgba.length === 6 || this.rgba.length === 8) {
      return parseInt(this.rgba.substring(0, 2), 16)
    } else {
      throw new Error("Incorrect rgba format ["+this.rgba+"]");
    }
  }

  getGreen(): number {
    const hashIndex = this.rgba.indexOf("#");
    if(hashIndex == 0 && this.rgba.length === 7 || this.rgba.length === 9) {
      return parseInt(this.rgba.substring(3, 5), 16)
    } else if(hashIndex < 0 && this.rgba.length === 6 || this.rgba.length === 8) {
      return parseInt(this.rgba.substring(2, 4), 16)
    } else {
      throw new Error("Incorrect rgba format ["+this.rgba+"]");
    }
  }

  getBlue(): number {
    const hashIndex = this.rgba.indexOf("#");
    if(hashIndex == 0 && this.rgba.length === 7 || this.rgba.length === 9) {
      return parseInt(this.rgba.substring(5, 7), 16)
    } else if(hashIndex < 0 && this.rgba.length === 6 || this.rgba.length === 8) {
      return parseInt(this.rgba.substring(4, 6), 16)
    } else {
      throw new Error("Incorrect rgba format ["+this.rgba+"]");
    }
  }


  getAlpha(): number {
    const hashIndex = this.rgba.indexOf("#");
    if(hashIndex == 0 && this.rgba.length === 7) {
      return 255;
    } else if(hashIndex == 0 && this.rgba.length === 9) {
      return parseInt(this.rgba.substring(7, 9), 16);
    } else if(hashIndex < 0 && this.rgba.length === 6) {
      return 255;
    } else if(hashIndex < 0 && this.rgba.length === 8) {
      return parseInt(this.rgba.substring(6, 8), 16)
    } else {
      throw new Error("Incorrect rgba format ["+this.rgba+"]");
    }
  }

  private static colorToHex(color: number) {
    const hex = color.toString(16);
    if(color < 16) {
      return "0" + hex;
    } else {
      return hex;
    }
  }

  rgbaNoHash() {
    if(this.rgba.indexOf("#") === 0) {
      return this.rgba.substring(1);
    } else {
      return this.rgba;
    }
  }


  static ofRgbaString(rgba: string): Color {
    const lowerCase = rgba.toLowerCase();
    if(this.validateColorHashCode(lowerCase)) {
      return new Color(lowerCase);
    } else {
      throw new Error("Incorrect rgba format ["+rgba+"]");
    }
  }

  static validateColorHashCode(rgba: string): boolean {
    return rgba.match(/^#?([0-9a-f][0-9a-f]){3,4}$/i) !== null;
  }

  static ofRgba(red: number, green: number, blue: number, alpha: number) {
    const rgba = "#"+Color.colorToHex(red)+Color.colorToHex(green)+Color.colorToHex(blue)+Color.colorToHex(alpha);
    return new Color(rgba);
  }

  static empty(): Color {
    return new Color("");
  }
}
