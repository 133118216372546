import {
  Component,
  Input, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import {HtmlComponentViewModel} from "./HtmlComponentViewModel";
import {DomSanitizer} from "@angular/platform-browser";
import {$$, htmlEscape, toastr} from "@utils";
import {LibrariesService} from "@shared";
import {Subscription} from "rxjs";

declare const DOMPurify: any;

@Component({
  selector: 'my-html-component',
  templateUrl: './html-component.component.html',
  host: {
    "[class]": "'componentBody HtmlComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css"
  }
})
export class HtmlComponentComponent implements OnInit, OnDestroy {
  @Input({required:true}) viewModel!: HtmlComponentViewModel;

  private shadowRoot?: ShadowRoot;

  private subscriptions: Array<Subscription> = [];
  private initScheduled: number = -1;

  constructor(private readonly sanitizer: DomSanitizer,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly librariesService: LibrariesService) {}

  ngOnInit() {
    this.initShadowDom();

    this.subscriptions.push(this.viewModel.customCssObservable.subscribe(() => {
      if(this.initScheduled === -1) {
        this.initScheduled = requestAnimationFrame(() => {
          this.initScheduled = -1;
          this.initShadowDom();
        });
      }
    }));

    this.subscriptions.push(this.viewModel.htmlObservable.subscribe(() => {
      if(this.initScheduled === -1) {
        this.initScheduled = requestAnimationFrame(() => {
          this.initScheduled = -1;
          this.initShadowDom();
        })
      }
    }));
  }

  private initShadowDom() {
    if(!this.shadowRoot) {
      this.shadowRoot = $$(this.viewContainerRef).findOrError(".htmlContent").getAsHtmlElement().attachShadow({mode: 'open'});
    }
    this.sanitizeHtml(this.viewModel.html).then((html) => {
      if(this.shadowRoot) {
        this.shadowRoot.innerHTML = `<style>${htmlEscape(this.viewModel.customCss)}</style>` + html;
      }
    });
  }


  private sanitizeHtml(html: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.librariesService.loadDompurify(() => {
        const purified = DOMPurify.sanitize(html);
        resolve(purified);
      });
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.initShadowDom();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
