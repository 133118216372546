import {AuthenticatedHttp} from "./AuthenticatedHttp";
import {AggregateId, AggregateVersion, toastr, Typed} from "@utils";
import {
  asSuccess,
  CommandResponse,
  CustomSuccessResponse,
  isCustomSuccess,
  isSuccess,
  nonSuccessMessages
} from "./StandardResponses";
import {customCommandResponseHandler} from "./ResponsesHandler";
import {AggregateCommandOrderer} from "./AggregateCommandOrderer";

export abstract class CommandService {

  private orderer = new AggregateCommandOrderer();

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  protected createAggregate(url: string, command: any, onSuccess: (aggregateId: AggregateId, version: AggregateVersion) => void, onFailure: () => void = () => {}) {
    this.authenticatedHttp.post(url, command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
          onFailure();
        }
      });
  }


  protected deleteAggregate(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion}, onSuccess: () => void) {
    this.orderer.ordered(command.aggregateId, command.expectedVersion,
      (aggregateId: AggregateId, currentExpectedVersion: AggregateVersion, onFinish: (newVersion: AggregateVersion) => void) => {

        command.expectedVersion = currentExpectedVersion;
        this.authenticatedHttp.post(url, command,
          (data: Typed<CommandResponse>) => {
            if (isSuccess(data)) {
              onSuccess();
            } else {
              toastr.error(nonSuccessMessages(data));
            }
            onFinish(asSuccess(data).aggregateVersion);
          });
      });
  }

  protected concurrentDeleteAggregate(url: string, command: {aggregateId: AggregateId}, onSuccess: () => void) {
    this.authenticatedHttp.post(url, command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          onSuccess();
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      });
  }

  protected command(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion},
                    onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: () => void = () => {}) {
    this.failingCommand(url, command, onSuccess, (errors: Array<string>) => {
      toastr.error(errors.join(", "));
      onFailure();
    });
  }

  protected concurrentCommand(url: string, command: {aggregateId: AggregateId},
                              onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: () => void = () => {}) {
    this.failingConcurrentCommand(url, command, onSuccess, (errors: Array<string>) => {
      toastr.error(errors.join(", "));
      onFailure();
    });
  }

  protected commandCustomResponse<INFO>(url: string, command: {aggregateId: AggregateId},
                                        onSuccess: (id: AggregateId, version: AggregateVersion, info: INFO) => void, onFailure: () => void = () => {}) {
    this.authenticatedHttp.post(url, command,
      (data: Typed<CustomSuccessResponse<INFO>>) => {
        if (isCustomSuccess(data)) {
          customCommandResponseHandler<INFO>(data).onSuccess((id: AggregateId, version: AggregateVersion, info: INFO) => {
            onSuccess(id, version, info);
          }).handle();
        } else {
          toastr.error(nonSuccessMessages(data));
          onFailure()
        }
      }, (status: number, data) => {
        toastr.error("Error while sending command " + status+": "+data);
      });
  }

  protected failingCommand(url: string, command: {aggregateId: AggregateId, expectedVersion: AggregateVersion}, onSuccess: (id: AggregateId, version: AggregateVersion) => void,
                           onError: (errors: Array<string>) => void) {
    if(command.expectedVersion.asInt > 0) {
      this.orderer.ordered(command.aggregateId, command.expectedVersion,
        (aggregateId: AggregateId, currentExpectedVersion: AggregateVersion, onFinish: (newVersion: AggregateVersion) => void) => {
          command.expectedVersion = currentExpectedVersion;
          this.authenticatedHttp.post(url, command,
            (data: Typed<CommandResponse>) => {
              if (isSuccess(data)) {
                const success = asSuccess(data);
                onSuccess(success.aggregateId, success.aggregateVersion);
                onFinish(asSuccess(data).aggregateVersion);
              } else {
                onError([nonSuccessMessages(data)]);
                onFinish(currentExpectedVersion);
              }
            }, (status: number, data) => {
              onError(["Error while sending command " + status+": "+data]);
            });
        });
    } else {
      throw new Error("Aggregate not initialized correctly");
    }
  }

  protected failingConcurrentCommand(url: string, command: {aggregateId: AggregateId}, onSuccess: (id: AggregateId, version: AggregateVersion) => void,
                                     onError: (errors: Array<string>) => void) {
    this.authenticatedHttp.post(url, command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onError([nonSuccessMessages(data)]);
        }
      });
  }
}
