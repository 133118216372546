import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {$$, AggregateId, global, i18n, None, Option, toastr} from "@utils";
import {SessionServiceProvider} from "../../services/session/session-service-provider.service";
import {NavigationService} from "../../services/navigation/navigation.service";

@Component({
  selector: "my-login-form",
  templateUrl: "./login-form.component.html"
})
export class LoginFormComponent implements OnInit {

  @Input() rememberMeEnabled: boolean = null!;
  @Input() defaultLogin: string = null!;
  @Input() identityServiceUserToken: string|undefined = null!;

  @Input() label = i18n("login_form_title");

  public login: string = "";
  public password: string = "";
  public rememberMe: boolean = false;

  loginFormVisible = true;
  mfaFormVisible = false;
  mfaToken = "";
  loginAttemptId = "";

  invalidUrl = false;


  loginReadonly = false;
  internalAuthenticationEnabled = global.config.internalAuthentication;
  usernameLoginEnabled = global.config.loginWithUsername;

  initialLogin: string = "";

  @Input() onSuccessfulLogin: () => void = () => {

    this.sessionServiceProvider.getSessionService(sessionService => {
      if (sessionService.lastRequestedUrl.getOrElse("").indexOf("/admin") >= 0) {
        throw new Error("Logging to admin not yet implemented");
        // this.navigationService.navigateToLastRequestedPageOrMainPage();
        // $location.url("/admin/organizations");
      } else {
        this.navigationService.navigateToLastRequestedPageOrMainPage(["/login"]);
        sessionService.lastRequestedUrl = None();
      }
    });

  };

  constructor(
    readonly sessionServiceProvider: SessionServiceProvider,
    private readonly navigationService: NavigationService,
    readonly viewContainerRef: ViewContainerRef
  ) {
  }


  ngOnInit() {

    if (this.rememberMeEnabled == undefined) {
      this.rememberMeEnabled = true;
    }
    this.initialLogin = this.defaultLogin === null ? "" : this.defaultLogin;
    this.login = this.initialLogin;

    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.getOrganization(
        (id: AggregateId) => {
          this.invalidUrl = false;
        }, () => {
          this.invalidUrl = true;
        });
    });

  }

  loginUserInternal() {
    if (!this.rememberMe) { // TODO it's used in legacy UI, figure out why?
      localStorage.setItem("rememberMeDisabled", "true");
    } else {
      localStorage.removeItem("rememberMeDisabled");
    }

    this.sessionServiceProvider.getSessionService(sessionService => {

      if (global.config.organizationId.isDefined()) {
        sessionService.loginToOrganization(this.login, this.password, this.rememberMe, Option.of(this.identityServiceUserToken), () => {
          // success
          this.loginFormVisible = false;
          this.onSuccessfulLogin();
        }, (attemptId: string) => {
          this.loginFormVisible = false;
          this.mfaFormVisible = true;
          this.loginAttemptId = attemptId;
          // mfa
        }, () => {
          this.password = "";
          toastr.error(i18n("login_form_error"));
        }, (minutes: number) => {
          this.password = "";
          toastr.error(i18n("login_form_disabled", {duration: minutes}));
        });
      } else {
        sessionService.loginToApplication(this.login, this.password, () => {
          // success
          this.loginFormVisible = false;
          this.onSuccessfulLogin();
        }, () => {
          this.password = "";
          toastr.error(i18n("login_form_error"));
        });
      }

    });
  };

  mfaTokenProvided() {
    if (this.mfaToken.trim().length > 0) {
      this.sessionServiceProvider.getSessionService(sessionService => {
        sessionService.continueMFALogin(this.loginAttemptId, this.mfaToken, Option.of(this.identityServiceUserToken), () => {
          // success
          this.mfaFormVisible = false;
          this.onSuccessfulLogin();
        }, () => {
          toastr.error(i18n("login_mfa_token_incorrect"));
        });

      });
    }
  }


  focusOnPassword() {
    $$(this.viewContainerRef).find("input.password").forEach(e => e.focus());
  }

  protected readonly undefined = undefined;
}
