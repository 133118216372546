import {NumberInputComponentViewModel} from "./NumberInputComponentViewModel";
import {decimalSeparator, nbspToSpace, thousandSeparator} from "@utils";
import {ChangeDetectorRef} from "@angular/core";

export class NumberInputComponentController {

  previous = "";

  constructor(readonly $input: HTMLElement,
              readonly viewModel: NumberInputComponentViewModel,
              readonly changeDetectorRef: ChangeDetectorRef) {

    $input.addEventListener("input", this.onInput);

  }


  readonly onInput = (e: Event) => {
    const trimmed = this.getInputValue().trim();
    if(trimmed.trim().length == 0) {
      this.viewModel.internalValue = "";
      this.changeDetectorRef.detectChanges();
    } else if (trimmed === "-") {
      // do nothing
    } else  if (!this.isNumeric(trimmed) || !this.isValid(parseFloat(this.toParsableNumber(this.getInputValue().trim())))) {
      this.setInputValue(this.previous);
      this.changeDetectorRef.detectChanges();
    }

    this.previous = this.viewModel.internalValue;
  }

  private emptyIfNull(value: string|null): string {
    if(value === null) {
      return "";
    } else {
      return value;
    }
  }

  private setInputValue(value: string) {
    if(this.$input.tagName.toUpperCase() === "INPUT") {
      (<HTMLInputElement>this.$input).value = value;
    } else {
      this.$input.innerText = value;
    }
  }

  private getInputValue() {
    if(this.$input.tagName.toUpperCase() === "INPUT") {
      return this.emptyIfNull((<HTMLInputElement>this.$input).value).trim();
    } else {
      return this.emptyIfNull(this.$input.innerText);
    }
  }


  isNumeric(value: string) {
    let dotOnEnd = false;
    if(this.viewModel.maxPrecision.exists(p => p <= 0)) {
      dotOnEnd = value.indexOf(decimalSeparator) === value.length - 1;
    }
    const noE = this.toParsableNumber(value);
    return !dotOnEnd && !isNaN(parseFloat(noE)) && isFinite(<any>noE);
  }

  toParsableNumber(value: string) {
    const val = decimalSeparator !== '.' ? value.replace(/\./g, "?") : value;
    return nbspToSpace(val).replace(this.escapeRegExp(thousandSeparator), "").replace(this.escapeRegExp(decimalSeparator), ".").replace("e", "@")
  }

  escapeRegExp(value: string) {
    return value !== '.' ? new RegExp(value, "g") : /\./g;
  }

  isValid(value: number) {
    if(this.viewModel.maxPrecision.isDefined()) {
      const maxPrecision = this.viewModel.maxPrecision.get();
      const rounded = Math.round(value * Math.pow(10, maxPrecision)) / Math.pow(10, maxPrecision);
      return rounded === value;
    } else {
      return true;
    }
  }

  destroy() {
    this.$input.removeEventListener("input", this.onInput);
  }

}
