import {Component, OnInit} from '@angular/core';
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {__, i18n, Option, TimeHoursFormat} from "@utils";

class TimeHoursFormatOption implements DropDownSelectorOption {
  nameOnList: string = "";
  constructor(readonly value: null|TimeHoursFormat,
              readonly name: string) {}
}

@Component({
  selector: 'my-time-hours-format-selector',
  templateUrl: './time-hours-format-selector.component.html'
})
export class TimeHoursFormatSelectorComponent implements OnInit {

  defaultTimeHoursFormat: TimeHoursFormatOption = null!;
  timeHoursFormat: TimeHoursFormat|null = null;
  timeHoursFormatOptions: Array<TimeHoursFormatOption>;
  selectedTimeHoursFormat: TimeHoursFormatOption = null!;

  constructor(readonly userSettingsService: UserSettingsStateService) {

    this.userSettingsService.getTimeHoursFormatObservable().forEach(timeHoursFormat => {
      this.timeHoursFormat = timeHoursFormat.getOrNull();
    });

    this.defaultTimeHoursFormat = new TimeHoursFormatOption(null, this.userSettingsService.getDefaultTimeHoursFormat().toString());
    this.defaultTimeHoursFormat.nameOnList = i18n("common_default_male");

    this.timeHoursFormatOptions = [
      this.defaultTimeHoursFormat,
      new TimeHoursFormatOption(TimeHoursFormat.h12, "12"),
      new TimeHoursFormatOption(TimeHoursFormat.h24, "24"),
    ]

  }

  ngOnInit(): void {
    this.timeHoursFormat = this.userSettingsService.getTimeHoursFormat().getOrNull();
    this.selectedTimeHoursFormat = __(this.timeHoursFormatOptions).find(th => th.value === this.timeHoursFormat).getOrElse(this.defaultTimeHoursFormat);
  }

  timeHoursFormatChanged(timeHours: TimeHoursFormatOption|DropDownSelectorOption) {
    this.timeHoursFormat = timeHours.value;
    this.userSettingsService.changeTimeHoursFormat(Option.of(this.timeHoursFormat));
  }
}
