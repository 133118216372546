import {I18nText, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenComponentRef,
  TextsProperties
} from "@screen-common";
import {BooleanProperty, I18nTextProperty, StringProperty} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";


export class LinkComponentRef extends ScreenComponentRef {
  static className = "LinkComponentRef";
  className() {
    return LinkComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: LinkComponentRef): LinkComponentRef {
    return new LinkComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("LinkComponentRef", DefaultPropertyProvider.ref);
  override defaultPropertiesProvider = LinkComponentRef.DEFAULT_PROPERTIES;
}

export class LinkComponentDefinition extends ScreenComponentDefinition {

  static className = "LinkComponentDefinition";
  className() {
    return LinkComponentDefinition.className;
  }
  typeName() {
    return "Link";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,LinkComponentDefinition.DEFAULT_PROPERTIES);
  }

  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  get text(): I18nTextProperty {return this.properties.getI18nTextProperty("text", this.defaultPropertiesProvider)};
  get url(): StringProperty {return this.properties.getStringProperty("url", this.defaultPropertiesProvider)};

  static copy(other: LinkComponentDefinition): LinkComponentDefinition {
    return new LinkComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }


  getModelNames(): Array<string> {
    return [];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("LinkComponentDefinition",
    (name: string) => {switch (name) {
      case "text": return I18nTextProperty.of(I18nText.ofCurrent("?"));  // fills missing text iif deprecated label was set
      case "underline": return BooleanProperty.ofTrue();
      default: return null;
    }},
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
