import {ToastrEventBus} from "./ToastrEventBus";
import {clearArray, mySetTimeout, Option} from "@utils";

export class ToastrMessage {
  constructor(readonly message: string,
              readonly level: string,
              readonly tag: string,
              readonly timestamp: number,
              readonly icon: string) {}
}

export class ToastrViewModel {
  readonly messages: Array<ToastrMessage> = [];
  constructor(readonly eventBus: ToastrEventBus) {

    this.eventBus.on(this.eventBus.infoAdded, (message: string, tag: string, timeout: Option<number>) => {
      const toastrMessage= new ToastrMessage(message, "info", tag, Date.now(), "mi-info");
      this.messages.push(toastrMessage);
      timeout.forEach(t => mySetTimeout( () => {
        this.closeToastr(toastrMessage);
      }, t));
    });

    this.eventBus.on(this.eventBus.warningAdded, (message: string, tag: string, timeout: Option<number>) => {
      const toastrMessage= new ToastrMessage(message, "warning", tag, Date.now(), "mi-caution");
      this.messages.push(toastrMessage);
      timeout.forEach(t => mySetTimeout(() => {
        this.closeToastr(toastrMessage);
      }, t));
    });

    this.eventBus.on(this.eventBus.errorAdded, (message: string, tag: string, timeout: Option<number>) => {
      const toastrMessage= new ToastrMessage(message, "error", tag, Date.now(), "mi-sensor-alert");
      this.messages.push(toastrMessage);
      timeout.forEach(t => mySetTimeout(() => {
        this.closeToastr(toastrMessage);
      }, t));
    });

    this.eventBus.on(this.eventBus.successAdded, (message: string, tag: string, timeout: Option<number>) => {
      const toastrMessage= new ToastrMessage(message, "success", tag, Date.now(), "mi-check");
      this.messages.push(toastrMessage);
      timeout.forEach(t => mySetTimeout(() => {
        this.closeToastr(toastrMessage);
      }, t));
    });

    this.eventBus.on(this.eventBus.clearOldMessagesSent, () => {
      this.clearOldMessages();
    });

    this.eventBus.on(this.eventBus.clearMessagesWithTagSent, (tag: string) => {
      this.clearMessagesWithTag(tag);
    });
  }

  closeToastr(toastr: ToastrMessage) {
    let messagesCopy = this.messages.slice();
    clearArray(this.messages);
    messagesCopy.filter(m => m != toastr).forEach(m => this.messages.push(m));
  }

  clearOldMessages(){
    let currentTimestamp = Date.now();
    let messagesCopy = this.messages.slice();
    clearArray(this.messages);
    messagesCopy.filter(m => m.timestamp + 3000 >= currentTimestamp).forEach(m => this.messages.push(m))
  }

  clearMessagesWithTag(tag: string){
    let messagesCopy = this.messages.slice();
    clearArray(this.messages);
    messagesCopy.filter(m => m.tag.indexOf(tag) == -1).forEach(m => this.messages.push(m))
  }

}
