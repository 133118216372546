<div class="passwordPreview" *ngIf="viewModel.readOnly && viewModel.preview">{{viewModel.internalModel}}</div>

<ng-container *ngIf="(!viewModel.readOnly || !viewModel.preview)">

  <div class="text">
    <textarea type="text" class="theme-input mask"
              [(ngModel)]="viewModel.mask" [disabled]="viewModel.readOnly"></textarea>
    <textarea type="text" class="theme-input editor"
              (ngModelChange)="viewModel.updateMask()" (change)="viewModel.updateMask()"
              [(ngModel)]="viewModel.internalModel" (blur)="viewModel.onInternalChange()" [disabled]="viewModel.readOnly"></textarea>
  </div>
</ng-container>

