import {Injectable} from "@angular/core";
import {SessionServiceProvider} from "@shared";
import {SavedTasksFilters} from "../../../modules/tasks.module/components/tasks-filters.view-model";

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private readonly _LocalStorageService: undefined; // force TypeScript to check types (workaround for duck typing)

  constructor(readonly sessionServiceProvider: SessionServiceProvider) {}

  setBoolean(key: string, value: boolean) {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      localStorage.setItem(sessionInfo.personId.id+"|"+key, value ? "t" : "f");
    });
  }

  getBoolean(key: string, defaultValue: boolean = false): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
        const item = localStorage.getItem(sessionInfo.personId.id+"|"+key);
        if (item == "t") {
            resolve(true);
        } else if (item == "f") {
            resolve(false);
        } else {
            resolve(defaultValue);
        }
      });
    });
  }

  setString<T>(key: string, value: string) {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      localStorage.setItem(sessionInfo.personId.id+"|"+key, value);
    });
  }

  getString(key: string): Promise<string|null> {
    return new Promise<string|null>((resolve, reject) => {
      this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
        resolve(localStorage.getItem(sessionInfo.personId.id+"|"+key));
      });
    });
  }

  getObject<T extends object>(key: string, copy: (obj: T) => T): Promise<T|null> {
    return new Promise<T|null>((resolve, reject) => {
      this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
        const fromStorage = localStorage.getItem(sessionInfo.personId.id+"|"+key);
        if(fromStorage) {
          try {
            resolve(copy(JSON.parse(fromStorage) as T));
          } catch(e) {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    });
  }

  getObjectIfExists<T extends object>(key: string, copy: (obj: T) => T, onSuccess: (value: T) => void): void {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      const fromStorage = localStorage.getItem(sessionInfo.personId.id+"|"+key);
      if(fromStorage) {
        try {
          onSuccess(copy(JSON.parse(fromStorage) as T));
        } catch(e) {
          // ignore
        }
      }
    });
  }

  setObject<T extends object>(key: string, value: T) {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      localStorage.setItem(sessionInfo.personId.id+"|"+key, JSON.stringify(value));
    });
  }

  removeItem<T>(key: string) {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      localStorage.removeItem(sessionInfo.personId.id+"|"+key);
    });
  }

}
