<button class="dropListValue {{viewModel.cssClasses}}" [style]="viewModel.css" *ngIf="!viewModel.disabled" tabindex="0" (focus)="onFocus()" (click)="onClick()" [ngClass]="{notSelected: viewModel.notSelected}" (blur)="onBlur()">
  {{viewModel.value?.label}}
</button>
<button class="dropListValue disabled  {{viewModel.cssClasses}}" disabled="disabled" *ngIf="viewModel.disabled" [style]="viewModel.css" tabindex="-1">
  <span *ngIf="viewModel.value">{{viewModel.value.label}}</span><span *ngIf="!viewModel.value">-</span>
</button>
<i class="expandArrow mi-navigate-down theme-expand-arrow-small" *ngIf="!viewModel.disabled" [class.theme-expanded]="open"></i>

<my-drop-menu cssClass="theme-drop-menu" [(visible)]="open" [anchor]="inputElement" (opened)="onDropMenuOpened($event)" (closed)="onDropMenuClosed()">
  <ng-container *ngIf="open">
    <div class="theme-drop-menu-filter" *ngIf="viewModel.filterVisible">
      <i class="mi-search"></i>
      <input type="text" [(ngModel)]="viewModel.filter" tabindex="-1" (ngModelChange)="viewModel.filterUpdated()" [focus-on-show]="true"/>
      <button class="theme-clear-filter" (click)="viewModel.clearFilter()" *ngIf="viewModel.filter.length > 0"><i class="mi-delete"></i></button>
    </div>
    <div class="theme-entries-scroll">
      <div *ngFor="let option of viewModel.visibleOptions; let index = index"
           (click)="optionSelected(option)"
           [class.theme-drop-menu-entry]="option.value === undefined"
           [class.theme-drop-menu-entry-strong]="option.value !== undefined"
           [class.active]="index == viewModel.activeIndex">{{option.label}}</div>
    </div>
  </ng-container>
</my-drop-menu>
