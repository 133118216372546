import {
  AggregateId,
  AggregateVersion,
  AnyPersonId,
  AnyPersonIdFactory,
  AnyPersonIdHelper,
  FileUri,
  gravatarHash, Language, LocalDateTime, None,
  Option,
  OrganizationId, OrganizationNodeId,
  PersonId, RemotePersonId, Some,
  Typed
} from "@utils";
import {OrganizationSessionInfoClientSide} from "@shared";

export interface PersonAvatarInfo {
  avatar: Option<FileUri>;
  initials(): string;
  gravatarHash(): string;
}

export class PersonSummary {
  constructor(readonly id: AnyPersonId,
              readonly organizationId: OrganizationId,
              readonly firstName: string,
              readonly lastName: string) {}

  is(person: AnyPersonId) {
    return AnyPersonIdHelper.equals(person, this.id);
  }

  isAggregate(id: AggregateId) {
    return AnyPersonIdHelper.equals(PersonId.of(id), this.id);
  }

}

export class TokenResponse {

  static className = "TokenResponse";

  constructor(readonly id: number,
              readonly token: string,
              readonly tokenShort: string){}

  className():string {
    return TokenResponse.className
  }
}

export class BasicPersonInfo implements PersonAvatarInfo {
  constructor(public id: Typed<AnyPersonId>,
              public version: AggregateVersion,
              public organizationId: OrganizationId,
              public apiName: Option<string>,
              public firstName: string,
              public lastName: string,
              public position: string,
              public email: string,
              public avatar: Option<FileUri>,
              public deleted: boolean) {}


  is(person: AnyPersonId) {
    return AnyPersonIdHelper.equals(person, this.idUnwrapped());
  }

  isAggregate(id: AggregateId) {
    return AnyPersonIdHelper.equals(PersonId.of(id), this.idUnwrapped());
  }

  localIdUnwrapped() {
    return this.idUnwrapped().asPersonId();
  }

  idUnwrapped() {
    return Typed.value(this.id);
  }

  matchable() {
    return this.firstName.toLowerCase() + " " + this.lastName.toLowerCase() + " " + this.email.toLowerCase() + " " + this.position.toLowerCase();
  }

  static copy(other: BasicPersonInfo) {
    return new BasicPersonInfo(AnyPersonIdFactory.copyTyped(other.id), AggregateVersion.copy(other.version), OrganizationId.of(other.organizationId),
      Option.copy(other.apiName), other.firstName, other.lastName, other.position,
      other.email, Option.copy(other.avatar, FileUri.copy), other.deleted);
  }

  static of(other: PersonInfo) {
    return new BasicPersonInfo(
      other.id,
      other.version,
      other.organizationId,
      other.apiInfo.map(a => a.name),
      other.firstName,
      other.lastName,
      other.position,
      other.email,
      other.avatar,
      other.deleted
    )
  }

  toPersonSummary() {
    return new PersonSummary(this.idUnwrapped(), this.organizationId, this.firstName, this.lastName);
  }

  simpleName() {
    return this.apiName.getOrElseLazy(() => this.firstName+" "+this.lastName);
  }

  initials() {
    return this.apiName.map(a => a.charAt(0)).getOrElseLazy(() => this.firstName.charAt(0) + this.lastName.charAt(0));
  }

  gravatarHash(): string {
    return gravatarHash(this.email);
  }

  isAnonymized(): boolean {
    return this.email.indexOf("anonymized@anonymized") >= 0;
  }

  static compare(a: BasicPersonInfo, b: BasicPersonInfo): number {
    const cmp = a.firstName.localeCompare(b.firstName);
    if (cmp === 0) {
      return a.lastName.localeCompare(b.lastName);
    } else {
      return cmp;
    }
  }

  isApi() {
    return this.apiName.isDefined();
  }

}

export class SimplePersonAvatarInfo implements PersonAvatarInfo {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly email: string,
              public avatar: Option<FileUri>) {}

  initials() {
    return this.firstName.charAt(0) + this.lastName.charAt(0);
  }

  gravatarHash(): string {
    return gravatarHash(this.email);
  }

  static fromOrganizationSessionInfo(organizationSessionInfo: OrganizationSessionInfoClientSide) {
    return new SimplePersonAvatarInfo(organizationSessionInfo.userFirstName,
      organizationSessionInfo.userLastName, organizationSessionInfo.userEmail, organizationSessionInfo.avatar);
  }
}

export class TokenBasicInfo {
  public token = "";
  constructor(readonly id: number,
              public tokenShort: string,
              readonly validUntil: Option<LocalDateTime>,
              readonly generated: LocalDateTime){}

  static copy(other: TokenBasicInfo) {
    return new TokenBasicInfo(other.id, other.tokenShort, Option.copy(other.validUntil, LocalDateTime.copy), LocalDateTime.copy(other.generated));
  }
}

export class APIBasicInfo {
  public token = "";

  constructor(public name: string,
              public tokens: Array<TokenBasicInfo>){}

  static copy(other: APIBasicInfo) {
    return new APIBasicInfo(other.name, other.tokens.map(TokenBasicInfo.copy));
  }
}

export class ExternalPersonInfo {
  constructor(readonly externalId: string, readonly login: string) {}

  static copy(other: ExternalPersonInfo): ExternalPersonInfo {
    return new ExternalPersonInfo(other.externalId, other.login);
  }
}

export class MultiFactorAuthenticationMode {
  constructor(readonly name: string) {}

  static DEFAULT = new MultiFactorAuthenticationMode("default");
  static ALWAYS = new MultiFactorAuthenticationMode("always");
  static NEVER = new MultiFactorAuthenticationMode("never");

  static copy(other: MultiFactorAuthenticationMode): MultiFactorAuthenticationMode {
    switch(other.name) {
      case MultiFactorAuthenticationMode.DEFAULT.name: return MultiFactorAuthenticationMode.DEFAULT;
      case MultiFactorAuthenticationMode.ALWAYS.name: return MultiFactorAuthenticationMode.ALWAYS;
      case MultiFactorAuthenticationMode.NEVER.name: return MultiFactorAuthenticationMode.NEVER;
      default: throw new Error("Unsupported MFA mode ["+other.name+"]");
    }
  }

  static byName(name: string): MultiFactorAuthenticationMode {
    switch(name) {
      case MultiFactorAuthenticationMode.DEFAULT.name: return MultiFactorAuthenticationMode.DEFAULT;
      case MultiFactorAuthenticationMode.ALWAYS.name: return MultiFactorAuthenticationMode.ALWAYS;
      case MultiFactorAuthenticationMode.NEVER.name: return MultiFactorAuthenticationMode.NEVER;
      default: throw new Error("Unsupported MFA mode ["+name+"]");
    }
  }

}

export class HoursFormat {
  constructor(readonly name: string) {}

  static format12 = new HoursFormat("12");
  static format24 = new HoursFormat("24");

  is12() {
    return this.name === HoursFormat.format12.name;
  }

  is24() {
    return this.name === HoursFormat.format24.name;
  }

  static copy(format: HoursFormat) {
    return HoursFormat.of(format.name);
  }

  static of(name: string) {
    switch(name) {
      case HoursFormat.format12.name: return HoursFormat.format12;
      case HoursFormat.format24.name: return HoursFormat.format24;
      default: throw new Error("Unsupported hours format ["+name+"]");
    }
  }
}


export class PersonInfo {

  constructor(
    readonly id: Typed<AnyPersonId>,
    public version: AggregateVersion,
    readonly organizationId: OrganizationId,
    readonly parentId: OrganizationNodeId,
    readonly apiInfo: Option<APIBasicInfo>,
    readonly firstName: string,
    readonly lastName: string,
    readonly position: string,
    readonly supervisorId: Option<Typed<AnyPersonId>>,
    readonly email: string,
    readonly phone: string,
    readonly avatar: Option<FileUri>,
    public active: boolean,
    public emailConfirmed: boolean,
    readonly passwordSet: boolean,
    readonly deleted: boolean,
    readonly sendEmailNotifications: boolean,
    readonly timezone: Option<string>,
    readonly language: Option<Language>,
    readonly locale: Option<string>,
    readonly hoursFormat: Option<HoursFormat>,
    readonly firstDayOfWeek: Option<number>,
    readonly weekend: Option<Array<number>>,
    readonly customTheme: Option<string>,
    readonly externalInfo: Option<ExternalPersonInfo>,
    readonly internalAuthenticationEnabled: boolean,
    readonly externalAuthenticationEnabled: boolean,
    readonly mfaMode: MultiFactorAuthenticationMode
  ) {}

  gravatarHash(): string {
    return gravatarHash(this.email);
  }

  static copy(other: PersonInfo): PersonInfo {
    return new PersonInfo(AnyPersonIdFactory.copyTyped(other.id), AggregateVersion.copy(other.version), other.organizationId, OrganizationNodeId.copy(other.parentId), Option.copy(other.apiInfo, APIBasicInfo.copy), other.firstName, other.lastName,
      other.position, Option.copy(other.supervisorId, c => AnyPersonIdFactory.copyTyped(c)), other.email, other.phone, Option.copy(other.avatar, FileUri.copy), other.active, other.emailConfirmed,
      other.passwordSet, other.deleted, other.sendEmailNotifications, Option.copy(other.timezone),
      Option.copy(other.language, Language.copy),
      Option.copy(other.locale),
      Option.copy(other.hoursFormat, HoursFormat.copy),
      Option.copy(other.firstDayOfWeek),
      Option.copy<Array<number>>(other.weekend, a => a.slice()),
      Option.copy(other.customTheme),
      Option.copy(other.externalInfo, ExternalPersonInfo.copy), other.internalAuthenticationEnabled, other.externalAuthenticationEnabled, MultiFactorAuthenticationMode.copy(other.mfaMode));
  }

  idUnwrapped() {
    return Typed.value(this.id);
  }

  localIdUnwrapped() {
    return this.idUnwrapped().asPersonId();
  }

  supervisorIdUnwrapped() {
    return this.supervisorId.map(s => Typed.value(s));
  }

  supervisorUnwrappedToOrganizationNodeId() {
    return this.supervisorId.map(s => OrganizationNodeId.fromPersonId(Typed.value(s).asPersonId()));
  }

  simpleName() {
    return this.firstName+" "+this.lastName;
  }

  initials() {
    return this.firstName.charAt(0) + this.lastName.charAt(0);
  }

  static compare(a: PersonInfo, b: PersonInfo): number {
    const cmp = a.firstName.localeCompare(b.firstName);
    if (cmp === 0) {
      return a.lastName.localeCompare(b.lastName);
    } else {
      return cmp;
    }
  }
}
