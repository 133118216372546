import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {
  LocalDate,
  LocalDateTime,
  LocalTime,
  mySetTimeout,
  parseDateTimeInputString,
  TimezonedLocalDateTime, toLocalDateTimeFromTimezoned
} from "@utils";
import {I18nService} from "@shared";

@Component({
  selector: 'my-date-time',
  templateUrl: './date-time.component.html',
  host: {
    "[class.pureLook]" : "pureLook"
  }
})
export class DateTimeComponent implements OnInit, OnDestroy {
  @Input() value: LocalDateTime|null = null;
  @Input() useSmartParser: boolean = true;
  @Input() clearButton: boolean = false;
  @Input() nowButton: boolean = false;
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<LocalDateTime|null>();

  @Input() pureLook: boolean = false; // remove all visual decorations, it will be handled externally

  @ViewChild("InputElement") private inputElement: ElementRef<HTMLInputElement> | undefined;

  focused: boolean = false;
  dateValue:LocalDate|null = null;
  timeValue:LocalTime|null = null;
  lastValue:TimezonedLocalDateTime|null = null;
  model: string|null = null;

  @Input() placeholder = "dd-mm-yyyy hh:mm";

  private dateSelected:boolean = false;
  private timeSelected:boolean = false;

  @Input() openOnShow: boolean = false;

  constructor(private readonly i18nService: I18nService) {}

  ngOnInit(): void {
    const timezoned = this.value ? this.i18nService.toTimezonedLocalDateTimeFromUTC(this.value) : null;
    this.lastValue = timezoned;
    this.dateValue = timezoned ? timezoned.date : null;
    this.timeValue = timezoned ? timezoned.time : null;
    this.model = this.formatValue(this.dateValue, this.timeValue);
    if(this.openOnShow) {
      this.onFocus();
    }
  }

  ngOnDestroy(): void {
  }

  isEqual(value: LocalDateTime|null): boolean {
    const thisValue = (this.dateValue && this.timeValue) ? new LocalDateTime(this.dateValue, this.timeValue) : null;
    if(value && thisValue){
      return value.isEqual(thisValue);
    } else if(!value && !thisValue){
      return true;
    } else {
      return false;
    }
  }

  onFocus(): void {
    if(!this.focused && !this.disabled) {
      this.focused = true;
      this.updateModel();
      this.selectInput();
    }
  }

  onUnfocus():void {
    if(this.focused){
      this.focused = false;
      this.updateModel();
    }
  }

  onInputValueChange(): void {

  }

  onInputValueConfirm(): void {
    this.updateValue();
  }

  onPickerValueSelected(): void {
    // this.updateModel();
    if(this.dateValue && this.timeValue){
      const formatted = new LocalDateTime(this.dateValue, this.timeValue).isoSimpleFormattedToMinutes();
      const parsed = this.i18nService.parseDateTime(formatted);
      this.setValue(parsed.result);
    }
    this.onUnfocus();
  }

  onPickerDateValueSelected(): void {
    this.dateSelected = true;
    if(this.dateValue && this.timeValue){
      const formatted = new LocalDateTime(this.dateValue, this.timeValue).isoSimpleFormattedToMinutes();
      const parsed = this.i18nService.parseDateTime(formatted);
      this.setValue(parsed.result);
    }
    this.updateModel();
    if(this.timeSelected){
      this.dateSelected = false;
      this.timeSelected = false;
      this.onPickerValueSelected();
    }
  }

  onPickerTimeValueSelected(): void {
    this.timeSelected = true;
    if(this.dateValue && this.timeValue){
      const formatted = new LocalDateTime(this.dateValue, this.timeValue).isoSimpleFormattedToMinutes();
      const parsed = this.i18nService.parseDateTime(formatted);
      this.setValue(parsed.result);
    }
    this.updateModel();
    if(this.dateSelected){
      this.dateSelected = false;
      this.timeSelected = false;
      this.onPickerValueSelected();
    }
  }

  onPickerClosed(): void {
    if(!this.areValueAndModelInSync()) {
      this.updateValue();
    }
    this.onUnfocus();
  }

  setNow(): void {
    this.setValue(LocalDateTime.now());
    this.updateModel();
  }

  setEmpty(): void {
    this.setValue(null);
    //this.updateModel();
  }

  private setValue(value:LocalDateTime|null){
    if(value){
      this.value = value;
      const timezoned = this.value ? this.i18nService.toTimezonedLocalDateTimeFromUTC(this.value) : null;
      this.dateValue = timezoned ? timezoned.date : null;
      this.timeValue = timezoned ? timezoned.time : null;
    } else {
      this.value = null;
      this.dateValue = null;
      this.timeValue = null;
    }
    this.updateModel();
  }

  private areValueAndModelInSync(): boolean {
    return (this.dateValue && this.timeValue) ?
      this.formatValue(this.dateValue, this.timeValue) == this.model : this.model == null;
  }

  private updateModel(): void {
    this.model = this.formatValue(this.dateValue, this.timeValue);
    this.handleVariableChange();
  }

  private updateValue():void {
    if(this.model && this.model.trim() != ""){
      const value = this.useSmartParser ?
        this.smartParseModel(this.model) : this.defaultParseModel(this.model);
      this.setValue(value);
    } else {
      this.setValue(null);
    }
  }

  private formatValue(dateValue:LocalDate|null, timeValue:LocalTime|null): string|null {
    if(dateValue && timeValue){
      return this.focused ?
        new LocalDateTime(dateValue, timeValue).formattedToMinutes() :
        new LocalDateTime(dateValue, timeValue).formattedShortWords();
    }
    else if(dateValue) {
      return this.focused ? dateValue.formatted() : dateValue.formattedShortWords();
    } else if(timeValue) {
      return timeValue.formattedToMinutes();
    }
    return null;
  }

  private defaultParseModel(model:string):LocalDateTime|null {
    const parseTry = LocalDateTime.ofUTC(model);
    return parseTry.isSuccess()? parseTry.result : null;
  }

  private smartParseModel(model:string):LocalDateTime|null {
    const parsed = parseDateTimeInputString(model);
    return LocalDateTime.fromLocalDate(parsed);
  }

  private selectInput(){
    mySetTimeout(() => this.inputElement?.nativeElement.select());
  }

  private handleVariableChange() {
    const timezoned = this.value ? this.i18nService.toTimezonedLocalDateTimeFromUTC(this.value) : null;
    if((timezoned === null && this.lastValue === null) || (timezoned !== null && this.lastValue !== null && timezoned.isEqual(this.lastValue))){
      return;
    } else {
      this.lastValue = timezoned;
      this.valueChange.emit(this.value);
    }
  }

}
