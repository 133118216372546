<my-login-page-layout>

    <div *ngIf="viewModel.loginVisible">
      <my-login-form [onSuccessfulLogin]="viewModel.onSuccessfulLogin" [identityServiceUserToken]="viewModel.identityServiceUserToken" [rememberMeEnabled]="false" [label]="'login_authenticate' | i18n" />
    </div>
    <div *ngIf="viewModel.confirmedVisible" class="theme-box info">
      {{'login_authenticated' | i18n}}
    </div>
    <div *ngIf="viewModel.linkNotValidVisible" class="theme-box info">
      {{'login_authenticate_incorrect' | i18n}}
    </div>

</my-login-page-layout>
