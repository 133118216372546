import {
  getElementPositionAndSize,
  getElementPosition,
  getREMSize,
  getElementSize,
  getWindowSize,
  global,
  mySetIntervalNoAngular,
  mySetTimeoutNoAngular,
  None,
  Option,
  PositionXY,
  RectXY,
  Size,
  Some
} from "@utils";

export type TooltipPosition = "top"|"left"|"bottom"|"right";

export class TooltipCommon {
  static top = "top";
  static left = "left";
  static bottom = "bottom";
  static right = "right";
  private static tooltipId = 0;

  static nextId() {
    return TooltipCommon.tooltipId++;
  }
}


export class TooltipController {
  static lastToggledTimestamp = 0;
  static DEFAULT_DELAY = 200;

  private readonly tooltipId = TooltipCommon.nextId();

  private visible = false;
  private shown = false;

  private hideTimeout: Option<number> = None();
  private showTimeout: Option<number> = None();

  private verifyHoverInterval: Option<number> = None();

  private position: TooltipCommon = TooltipCommon.top;
  private delay: number =  TooltipController.DEFAULT_DELAY;
  private mobileVisibility?: boolean;
  private enabled: boolean = true;
  private alwaysVisible: boolean = false;
  private error: boolean = false;
  private contentAlign: "left" | "center" | "right" = "center";

  readonly overlay: HTMLElement;

  private realPosition: string = TooltipCommon.top;

  private tooltipContainer: Option<HTMLElement> = None();
  private displayedContent: Array<Node> = [];

  constructor(private readonly element: HTMLElement,
              readonly getContentToDisplay: () => Array<Node>,
              readonly getCssClass: () => string,
              readonly onNodesReturned: (nodes: Array<Node>) => void) {

    let overlay = document.getElementById("tooltips-overlay");
    if(overlay === null) {
      throw new Error("No tooltip overlay found 'tooltips-overlay'");
    }
    this.overlay = overlay;

  }

  private previousAlwaysVisible = false;

  updateParams(position: TooltipCommon | undefined,
               delay: number | undefined,
               mobileVisibility: boolean | undefined,
               enabled: boolean | undefined,
               error: boolean | undefined,
               alwaysVisible: boolean | undefined,
               contentAlign: "left" | "center" | "right") {
    this.position = position ? position : TooltipCommon.top;
    this.delay = delay ? delay : TooltipController.DEFAULT_DELAY;
    this.mobileVisibility = mobileVisibility;
    this.enabled = enabled === undefined ? true : enabled;
    this.error = error === undefined ? false : error;
    this.alwaysVisible = alwaysVisible === undefined ? false : alwaysVisible;
    this.contentAlign = contentAlign;

    if(this.alwaysVisible) {
      this.showTooltipInAMoment();
    } else if(this.previousAlwaysVisible) {
      this.hideTooltip();
    }
    this.previousAlwaysVisible = this.alwaysVisible;
  }


  init() {
    global.zone.runOutsideAngular(() => {
      if (this.mobileVisibility) {
        this.element.addEventListener("touchstart", this.showTooltipInAMoment, true);
        this.element.addEventListener("touchend", this.hideTooltip, true);
      }

      this.element.addEventListener("mouseenter", this.showTooltipInAMoment);
      this.element.addEventListener("mouseleave", this.hideTooltip);
      this.element.addEventListener("click", () => {
        this.hideTooltip();
        TooltipController.lastToggledTimestamp = 0;
      });
      this.element.addEventListener("contextmenu", () => {
        this.hideTooltip();
        TooltipController.lastToggledTimestamp = 0;
      });
      if(this.alwaysVisible) {
        this.showTooltip();
      }
    });
  }


  private showTooltipInAMoment = () => {

    if(this.enabled && this.getContentToDisplay().length > 0) {
      this.visible = true;

      this.hideTimeout.forEach(clearTimeout);
      this.hideTimeout = None();
      if(this.displayedContent.length > 0) {
        this.onNodesReturned(this.displayedContent);
        this.displayedContent = [];
      }


      const lastToggleTimestamp = Date.now() - TooltipController.lastToggledTimestamp;

      this.showTimeout = Some(mySetTimeoutNoAngular(() => {
        this.showTooltip();
      }, lastToggleTimestamp > 500 ? this.delay : 0));
    }
  }

  private changeTooltipPositionIfNeeded(tooltipSize: Size, elementPosition: RectXY) {
    switch (this.position) {
      case TooltipCommon.top:
        if (tooltipSize.height + 30 > elementPosition.y) { // 30 - triangle size plus padding
          this.realPosition = TooltipCommon.bottom;
        } else {
          this.realPosition = TooltipCommon.top;
        }
        break;
      case TooltipCommon.bottom:
        if (tooltipSize.height + 30 > getWindowSize().height - elementPosition.y - elementPosition.height) { // 30 - triangle size plus padding
          this.realPosition = TooltipCommon.top;
        } else {
          this.realPosition = TooltipCommon.bottom;
        }
        break;
      case TooltipCommon.left:
        if (tooltipSize.width + 10 > elementPosition.y) { // 10 - padding
          this.realPosition = TooltipCommon.right;
        } else {
          this.realPosition = TooltipCommon.left;
        }
        break;
      case TooltipCommon.right:
        if (tooltipSize.width + 10 > getWindowSize().width - elementPosition.x - elementPosition.width) { // 30 - triangle size plus padding
          this.realPosition = TooltipCommon.left;
        } else {
          this.realPosition = TooltipCommon.right;
        }
        break;
      default:
        throw new Error("Wrong position of tooltip.");
    }
  }


  private checkTooltipSize(tooltipContentToDisplay: Array<Node>, customCssClass: Array<string>): Size {

    const tooltipContentSize = document.createElement("div");
    tooltipContentSize.classList.add("tooltipSize");
    tooltipContentSize.classList.add(...customCssClass);


    tooltipContentToDisplay.forEach(c => tooltipContentSize.appendChild(c));

    this.overlay.appendChild(tooltipContentSize);

    const tooltipSize = getElementSize(tooltipContentSize);
    tooltipContentSize.remove();
    return tooltipSize;
  }


  private showTooltip() {

    if(this.visible) {

      const contentToDisplay = this.getContentToDisplay();
      const customCssClass = this.getCssClass().split(/\s+/).filter(c => c.length > 0);

      const mobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i);

      const visibility = this.enabled && (!mobile || this.mobileVisibility);

      if (contentToDisplay.length > 0 && visibility) {

        if (this.tooltipContainer.isEmpty()) {

          const container = document.createElement("span");
          container.classList.add("tooltipContainer");
          container.classList.add("tooltipContainer" + this.tooltipId);
          container.classList.toggle("disabled", !this.enabled);
          container.classList.toggle("error", this.error);
          container.classList.toggle("contentLeft", this.contentAlign === "left");
          container.classList.toggle("contentCenter", this.contentAlign === "center");
          container.classList.toggle("contentRight", this.contentAlign === "right");

          this.overlay.appendChild(container);

          this.tooltipContainer = Some(container);
        }



        const elementPosition = getElementPositionAndSize(this.element);
        const tooltipSize = this.checkTooltipSize(contentToDisplay, customCssClass);

        this.changeTooltipPositionIfNeeded(tooltipSize, elementPosition);

        const position = this.tooltipPosition(tooltipSize, elementPosition);

        let container = this.tooltipContainer.get();
        container.style.top = position.y + "px";
        container.style.left = position.x + "px";
        container.style.width = tooltipSize.width + "px";
        container.style.height = tooltipSize.height + "px";
        container.classList.add(this.realPosition)


        const tooltipContent = document.createElement("span");
        this.tooltipContainer.get().innerHTML = "";
        this.tooltipContainer.get().appendChild(tooltipContent);
        tooltipContent.classList.add("tooltipContent");


        this.displayedContent = contentToDisplay;

        contentToDisplay.forEach(c => tooltipContent.appendChild(c));

        tooltipContent.classList.add(...customCssClass);

        this.addTriangle(this.tooltipContainer.get(), elementPosition);

        mySetTimeoutNoAngular(() => {
          if (this.visible) {
            this.tooltipContainer.forEach(c => c.classList.add("minimized"));
            mySetTimeoutNoAngular(() => {
              if (this.visible) {
                this.tooltipContainer.forEach(c => c.classList.add("shown"));
              }
            }, 100);
          }
        });

        this.shown = true;
        TooltipController.lastToggledTimestamp = Date.now();
      }

      this.verifyHoverInterval = Some(mySetIntervalNoAngular(() => {
        this.verifyHover()
      }, 50));
    }
  }


  private updateDisabled() {
    this.tooltipContainer.forEach(container => {
      container.classList.toggle("disabled", !this.enabled);
    })
  }

  private verifyHover() {
    if(!this.element.matches(":hover")) {
      this.hideTooltip();
    }
    this.updateDisabled();
  }

  private addTriangle(tooltip: HTMLElement, elementPosition: RectXY) {
    const distance = getREMSize() * 0.3;
    const triangleHeight = 1.7 * distance;
    const triangleWidth = 1.4 * distance;
    const tooltipPosition = getElementPosition(tooltip);

    const svgNamespace = "http://www.w3.org/2000/svg";
    const triangleContainer = document.createElementNS(svgNamespace, "svg");
    triangleContainer.classList.add("tooltipTriangle");
    tooltip.appendChild(triangleContainer);

    const polyline = document.createElementNS(svgNamespace, "polyline");
    triangleContainer.appendChild(polyline);

    switch(this.realPosition) {
      case TooltipCommon.top :
        triangleContainer.style.top = elementPosition.y - triangleHeight - tooltipPosition.y - 1 + "px"; // -1 to close gap between triangle and tooltip
        triangleContainer.style.left = elementPosition.x + 0.5 * elementPosition.width - triangleWidth - tooltipPosition.x + "px";
        polyline.setAttribute("points", 2 * triangleWidth + "," + 0 + " " + triangleWidth + "," + triangleHeight + " " + 0 + "," + 0);
        break;

      case TooltipCommon.right :

        triangleContainer.style.top = elementPosition.y + 0.5 * elementPosition.height - triangleWidth - tooltipPosition.y + "px";
        triangleContainer.style.left = elementPosition.x + elementPosition.width - tooltipPosition.x + 0.5 + 1 + "px"; // // +1 to close gap between triangle and tooltip
        polyline.setAttribute("points", triangleHeight + "," + 2 * triangleWidth + " " + 0 + "," + triangleWidth + " " + triangleHeight + "," + 0);
        break;

      case TooltipCommon.bottom :

        triangleContainer.style.top = elementPosition.y + elementPosition.height - tooltipPosition.y + 1 + "px"; // +1 to close gap between triangle and tooltip
        triangleContainer.style.left = elementPosition.x + 0.5 * elementPosition.width - triangleWidth - tooltipPosition.x - 1 + "px";
        polyline.setAttribute("points", 2 * triangleWidth + "," + triangleHeight + " " + triangleWidth + "," + 0 + " " + 0 + "," + triangleHeight);
        break;

      case TooltipCommon.left :
        triangleContainer.style.top = elementPosition.y + 0.5 * elementPosition.height - triangleWidth - tooltipPosition.y + "px";
        triangleContainer.style.left = elementPosition.x - triangleHeight - tooltipPosition.x - 0.5 - 1 + "px"; // -1 to close gap between triangle and tooltip
        polyline.setAttribute("points", 0 + "," + 2 * triangleWidth + " " + triangleHeight + "," + triangleWidth + " " + 0 + "," + 0);
        break;

      default :
        throw new Error("Wrong position of tooltip.");
    }
  }

  private hideTooltip = () => {
    this.showTimeout.forEach(clearTimeout);
    this.showTimeout = None();

    this.verifyHoverInterval.forEach(int => clearInterval(int));
    this.verifyHoverInterval = None();

    if(this.visible && !this.alwaysVisible) {
      this.visible = false;
      this.tooltipContainer.forEach(container => container.classList.remove("shown"));
      this.hideTimeout = Some(mySetTimeoutNoAngular(() => {
        this.tooltipContainer.forEach(c => c.remove());
        this.tooltipContainer = None();
        this.hideTimeout = None();
        this.onNodesReturned(this.displayedContent);
        this.displayedContent = [];
      }, 150));

      if(this.shown) {
        TooltipController.lastToggledTimestamp = Date.now();
        this.shown = false;
      }
    }
  }

  private tooltipPosition(tooltipRect: Size, elementPosition: RectXY): PositionXY {

    const distance = getREMSize() * 0.5;

    switch(this.realPosition) {
      case TooltipCommon.top:
        return TooltipController.tooltipPositionConstraints(new PositionXY(elementPosition.x + 0.5 * elementPosition.width - tooltipRect.width * 0.5,
          elementPosition.y - tooltipRect.height - distance), tooltipRect);

      case TooltipCommon.right:
        return TooltipController.tooltipPositionConstraints(new PositionXY(elementPosition.x + elementPosition.width + distance,
          elementPosition.y - 0.5 * tooltipRect.height + 0.5 * elementPosition.height), tooltipRect);

      case TooltipCommon.bottom:
        return TooltipController.tooltipPositionConstraints(new PositionXY(elementPosition.x + 0.5 * elementPosition.width - 0.5 * tooltipRect.width,
          elementPosition.y + elementPosition.height + distance), tooltipRect);

      case TooltipCommon.left:
        return TooltipController.tooltipPositionConstraints(new PositionXY(elementPosition.x - distance - tooltipRect.width,
          elementPosition.y - 0.5 * tooltipRect.height + 0.5 * elementPosition.height), tooltipRect);

      default :
        throw new Error("Wrong position of tooltip.");
    }
  }

  private static tooltipPositionConstraints(position: PositionXY, tooltip: Size): PositionXY {
    const distance = getREMSize() * 0.5;
    const windowSize = getWindowSize();
    let x = position.x;
    let y = position.y;

    if(x > windowSize.width - tooltip.width) {
      x = windowSize.width - tooltip.width - distance;
    } else if(x < distance) {
      x = distance;
    }

    if(y > windowSize.height - tooltip.height) {
      y = windowSize.height - tooltip.height - distance
    } else if(y < distance) {
      y = distance;
    }

    return new PositionXY(x, y);
  }

  destroy() {
    this.alwaysVisible = false;
    this.hideTooltip();
  }


}
