import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {createNgModule, Injectable, Injector} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ApplicationResolverService implements Resolve<void> {

  constructor(private readonly injector:Injector) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<void>|Promise<void> {
    return new Promise<void>((resolve) => {

      import('../../modules/application.module/application.module').then(({ApplicationModule}) => {

        const moduleRef = createNgModule(ApplicationModule, this.injector)
        moduleRef.instance.getApplicationPreloadService().preload(state.url).then(() => {
          resolve();
        });

      });
    });
  }
}
