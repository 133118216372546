import {createNgModule, Injectable, Injector} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ModulesService {

  constructor(private readonly injector:Injector) {}

  initScreenModule(): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      import('../modules/screen.module/screen.module').then(({ScreenModule}) => {
        const screenModuleRef = createNgModule(ScreenModule, this.injector)
        screenModuleRef.instance.getScreenCommonQueryService().loadSkins().then(() => {
          resolve();
        });
      });
    });
  }

}
