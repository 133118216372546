import {__, AggregateId, GridXY, LocalDateTime} from "@utils";
import {BasicProcessInfo, NodeType, ProcessEdgeId, ProcessNodeId} from "@shared-model";
import {TaskDistributionMethod} from "./TaskDistribution";

export class BasicEdgeInfo {

  constructor(readonly id: number,
              readonly fromNodeId: ProcessNodeId,
              readonly toNodeId: ProcessNodeId) {}

  static copy(other: BasicEdgeInfo) {
    return new BasicEdgeInfo(
      other.id,
      other.fromNodeId,
      other.toNodeId);
  }
}

export class BasicNodeInfo {

  constructor(readonly id: number,
              readonly gridXY: GridXY,
              readonly name: string,
              readonly nodeType: NodeType,
              readonly averageRealizationDurationMillis: number) {
  }

  static copy(other: BasicNodeInfo) {
    return new BasicNodeInfo(
      other.id,
      GridXY.copy(other.gridXY),
      other.name,
      NodeType.copy(other.nodeType),
      other.averageRealizationDurationMillis);
  }
}

export class BasicActorInfo {
  constructor(readonly id: number,
              readonly gridY: number,
              readonly name: string,
              readonly rowsCount: number,
              readonly taskDistributionMethod: TaskDistributionMethod) {
  }

  static copy(other: BasicActorInfo) {
    return new BasicActorInfo(
      other.id,
      other.gridY,
      other.name,
      other.rowsCount,
      other.taskDistributionMethod);
  }
}

export class BasicModelInfo {
  constructor(readonly process: BasicProcessInfo,
              readonly release: BasicProcessReleaseInfo) {}

  static copy(other: BasicModelInfo) {
    return new BasicModelInfo(
      BasicProcessInfo.copy(other.process),
      BasicProcessReleaseInfo.copy(other.release));
  }
}


export class BasicProcessReleaseInfo {
  constructor(readonly id: AggregateId,
              readonly releaseNumber: number,
              readonly name: string,
              readonly code: string,
              readonly created: LocalDateTime,
              readonly nodes: Array<BasicNodeInfo>,
              readonly edges: Array<BasicEdgeInfo>,
              readonly actors: Array<BasicActorInfo>) {}

  findNodeById(nodeId: ProcessNodeId) {
    return this.findOptionNodeById(nodeId).get();
  }

  findOptionNodeById(nodeId: ProcessNodeId) {
    return __(this.nodes).find(n => n.id === nodeId);
  }

  findEdgeById(edgeId: ProcessEdgeId) {
    return this.findOptionEdgeById(edgeId).get();
  }

  findOptionEdgeById(edgeId: ProcessEdgeId) {
    return __(this.edges).find(e => e.id === edgeId);
  }

  findActorForNode(nodeId: ProcessNodeId) {
    const node = this.findNodeById(nodeId);
    return __(this.actors).find(a => a.gridY <= node.gridXY.gridY && a.gridY + a.rowsCount > node.gridXY.gridY);
  }

  findOutEdgesForNode(nodeId: ProcessNodeId) {
    return __(this.edges).filter(edge => edge.fromNodeId === nodeId);
  }

  static copy(other: BasicProcessReleaseInfo): BasicProcessReleaseInfo {
    return new BasicProcessReleaseInfo(other.id,
      other.releaseNumber,
      other.name,
      other.code,
      LocalDateTime.copy(other.created),
      other.nodes.map(BasicNodeInfo.copy),
      other.edges.map(BasicEdgeInfo.copy),
      other.actors.map(BasicActorInfo.copy));
  }
}
