<div class="simplePage fontsTestPage">
  <h2>Fonts test page</h2>

  <textarea class="sampleText" [(ngModel)]="sampleText" rows="5" (ngModelChange)="sampleTextChanged()"></textarea>

  <ng-container *ngFor="let fontStyle of fontStyles">
    <ng-container *ngFor="let weight of weights">
      <ng-container *ngFor="let size of sizes">
        <div class="label">Weight: {{weight.name}}, size: {{size.name}}, Style: {{fontStyle.name}}</div>
        <div class="sample" [style.font-weight]="weight.style" [style.font-size]="size.style" [style.font-style]="fontStyle.style">{{sampleText}}</div>
      </ng-container>
    </ng-container>
  </ng-container>


</div>
