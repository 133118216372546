<div class="simplePage pageNotFound">
  <header>
<!--    <my-draw-in-logo [withName]="true"/>-->
  </header>
  <main>
    <div class="notFoundContent">
      <my-draw-in-logo [large]="true"></my-draw-in-logo>
      <div class="theme-form-label notFoundLabel">{{'common_page_not_found' | i18n}}</div>
      <div class="message">{{ 'common_page_not_found_message_line1' | i18n }}<br/>{{ 'common_page_not_found_message_line2' | i18n }}</div>
      <a routerLink="/" class="theme-button-link-icon"><i class="mi-home"></i> {{ 'common_goto_homepage' | i18n }}</a>
    </div>
  </main>
  <footer>
    <div class="settings">
      <button
        #viewParamsButton
        (click)="viewParamsMenuVisible = !viewParamsMenuVisible"
        class="theme-button-flat-small">{{'common_accessibility' | i18n}}
      </button>

      <div class="theme-action-button-separator"></div>
      <my-language-list></my-language-list>
    </div>
  </footer>
</div>
<my-drop-menu cssClass="theme-drop-menu viewParamsMenu"
              [anchor]="viewParamsButton"
              [(visible)]="viewParamsMenuVisible"
              horizontalPosition="end">
  <div *ngIf="viewParamsMenuVisible" class="viewParamsMenuContainer">
    <i class="viewParamsIcon mi-eclipse-alt"></i>
    <span class="viewParamsEntryText  theme-label">{{'common_site_theme' | i18n}}</span>
    <my-theme-selector class="alignEnd"/>

    <i class="viewParamsIcon mi-text"></i>
    <span class="viewParamsEntryText theme-label">{{'common_site_fontSize' | i18n}}</span>
    <my-font-size-selector class="alignEnd"/>

  </div>
</my-drop-menu>
