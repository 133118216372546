import {DocumentTypeId} from "@utils";

export class DocumentTypeSummary {
  constructor(readonly id: DocumentTypeId,
              readonly name: string,
              readonly identifier: string) {}


  static copy(other: DocumentTypeSummary) {
    return new DocumentTypeSummary(DocumentTypeId.copy(other.id), other.name, other.identifier);
  }
}
