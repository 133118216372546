
  export function prettySize(value: number) {
    const units = ["B", "KB", "MB"];
    let i = 1;
    let n = value;
    while (i < units.length && n >= 1024) {
      n = n / 1024;
      i++;
    }

    if(i > 1) {
      return (Math.round(n * 100) / 100).toFixed(2) +" "+units[i - 1];
    } else {
      return n +" "+units[i - 1];
    }


  }

  export function fromPrettySize(value: string) {
    const i = value.length - 1;
    const last = value.charAt(i);
    if (last == 'B' || last == 'b') {
      let inc = 1;
      let suffix = 2;
      switch(value.charAt(i - 1)) {
        case 'k': inc = 1024;
          break;
        case 'K': inc = 1024;
          break;
        case 'm': inc = 1024 * 1024;
          break;
        case 'M': inc = 1024 * 1024;
          break;
        case 'g': inc = 1024 * 1024 * 1024;
          break;
        case 'G': inc = 1024 * 1024 * 1024;
          break;
        default: suffix = 1;
          break;
      }
      return parseFloat(value.slice(0, -suffix)) * inc;
    } else {
      return undefined;
    }
  }

