import {OrganizationRegistrationService} from "../organization-register/organization-registration.service";
import {validateEmail} from "@utils";

export class EmailValidator {
  emailCorrect: boolean = false;
  emailChecked: boolean = false;
  emailRegistered: boolean = false;
  validationInProgress: boolean = false;

  constructor(readonly organizationRegistrationService: OrganizationRegistrationService) {}

  validate = (email: string) => {
    this.emailChecked = true;
    this.validationInProgress = true;
    if (email && email.length > 0 && validateEmail(email)) {
      this.emailCorrect = true;
      this.organizationRegistrationService.checkIfEmailExists(email, (data: boolean) => {
        this.emailRegistered = data;
        this.validationInProgress = false;
      })
    } else {
      this.validationInProgress = false;
      this.emailRegistered = false;
    }
  };


}
