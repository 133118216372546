import {AggregateId, FileUri, FlowId, LocalDateTime, Option, Typed} from "@utils";
import {FileLockInfo} from "./FileViewerSharedModel";
import {ProcessNodeId} from "@shared-model";

export class GetFilesInfo {
    constructor(readonly uris: Array<FileUri>) {}
  }

  export class GetFilesInfoFromFlow {
    constructor(readonly flowId: AggregateId,
                readonly uris: Array<FileUri>) {}
  }

  export class GetFilesInfoFromTask {
    constructor(readonly flowId: FlowId,
                readonly nodeId: ProcessNodeId,
                readonly uris: Array<FileUri>) {}
  }

  export interface FileBasicInfo {
    className(): string;
    uri: FileUri;
  }



  export class FileInfo implements FileBasicInfo {
    static className = "FileInfo";

    className() {
      return FileInfo.className
    }
    constructor(readonly uri: FileUri,
                readonly version: number,
                readonly name: string,
                readonly size: number,
                readonly modified: LocalDateTime,
                readonly lock: Option<FileLockInfo>,
                readonly watchedByMe: boolean,
                readonly commentsCount: number,
                readonly path: string,
                readonly deleted: boolean) {}

    static copy(other: FileInfo) {
      return new FileInfo(FileUri.copy(other.uri), other.version, other.name, other.size, LocalDateTime.copy(other.modified),
        Option.copy(other.lock, FileLockInfo.copy), other.watchedByMe, other.commentsCount, other.path, other.deleted);
    }
  }

  export class DirInfo implements FileBasicInfo {
    static className = "DirInfo";

    className() {
      return DirInfo.className
    }
    constructor(readonly uri: FileUri,
                readonly name: string,
                readonly modified: LocalDateTime,
                readonly path: string) {}

    static copy(other: DirInfo) {
      return new DirInfo(FileUri.copy(other.uri), other.name, LocalDateTime.copy(other.modified), other.path);
    }
  }

  export class FileNotFound implements FileBasicInfo {
    static className = "FileNotFound";

    className() {
      return FileNotFound.className
    }
    constructor(readonly uri: FileUri) {}

    static copy(other: FileNotFound) {
      return new FileNotFound(FileUri.copy(other.uri));
    }
  }

  export class FileBasicInfoFactory {
    static copyUnwrapped(typed: Typed<FileBasicInfo>): FileBasicInfo {
      switch (Typed.className(typed)) {
        case FileInfo.className: return FileInfo.copy(<FileInfo>Typed.value(typed));
        case DirInfo.className: return DirInfo.copy(<DirInfo>Typed.value(typed));
        case FileNotFound.className: return FileNotFound.copy(<FileNotFound>Typed.value(typed));
        default: throw new Error("Unsupported FileBasicInfo type ["+Typed.className(typed)+"]");
      }
    }

    static copyTyped(typed: Typed<FileBasicInfo>): Typed<FileBasicInfo> {
      switch (Typed.className(typed)) {
        case FileInfo.className: return Typed.of(FileInfo.copy(<FileInfo>Typed.value(typed)));
        case DirInfo.className: return Typed.of(DirInfo.copy(<DirInfo>Typed.value(typed)));
        case FileNotFound.className: return Typed.of(FileNotFound.copy(<FileNotFound>Typed.value(typed)));
        default: throw new Error("Unsupported FileBasicInfo type ["+Typed.className(typed)+"]");
      }
    }
  }

