export function compareNumbers(a: number, b: number): number {

  if (a > b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

export function formatTruncated(a: number): string {
  return a.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 10})
}

function trunc(v: number): number {
  v = +v;
  return (v - v % 1) || (!isFinite(v) || v === 0 ? v : v < 0 ? -0 : 0);
}
