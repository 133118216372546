import {
  FileMessage,
  FlowMessage,
  MailboxMessage,
  PersonNotification,
  SystemMessage,
  TaskMessage
} from "./notifications.model";
import {htmlEscape, required, toastr} from "@utils";

type NotificationPermission = "default" | "denied" | "granted";

type NotificationDirection = "auto" | "ltr" | "rtl";

interface NotificationOptions {
  dir?: NotificationDirection;
  lang?: string;
  body?: string;
  tag?: string;
  icon?: string;
}

class NotificationOption implements NotificationOptions {
  constructor(readonly body: string, readonly icon: string, readonly tag: string) {}
}

declare class Notification extends EventTarget {
  constructor(title: string, options?: NotificationOptions);

  static readonly permission: NotificationPermission;

  static requestPermission(deprecatedCallback: (permission: NotificationPermission) => void): void;


  onclick: EventListenerOrEventListenerObject;
  onerror: EventListenerOrEventListenerObject;

  close(): void;

  readonly title: string;
  readonly lang: string;
  readonly body: string;
  readonly tag: string;
  readonly icon: string;
}

/**
 * Used to show system notifications to the user (outside of the browser)
 */
export class SystemNotification {

  static notify(personNotification: PersonNotification) {

    if ("Notification" in window && Notification.permission === "granted") {
      this.showNotification(personNotification);
    } else if ("Notification" in window && Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if(Notification.permission === "granted") {
          this.showNotification(personNotification);
        } else {
          SystemNotification.showToastr(personNotification);
        }
      });
    } else {
      SystemNotification.showToastr(personNotification);
    }
  }

  private static showToastr(personNotification: PersonNotification) {
    if(personNotification.isNormalSeverity()) {
      toastr.info(htmlEscape(personNotification.subject)+"\n"+htmlEscape(personNotification.unWrappedMessage().value));
    } else {
      toastr.warning(htmlEscape(personNotification.subject)+"\n"+htmlEscape(personNotification.unWrappedMessage().value));
    }
  }

  private static showNotification(personNotification: PersonNotification) {
    if (personNotification.isFlowMessage()) {
      const personNotificationMessage = <FlowMessage>personNotification.unWrappedMessage();

      SystemNotification.showNotificationDetails(personNotification.subject, personNotificationMessage.value, personNotificationMessage.flowCode,
        '/flow/' + personNotificationMessage.flowCode);

    } else if (personNotification.isTaskMessage()) {
      const personNotificationMessage = <TaskMessage>personNotification.unWrappedMessage();

      SystemNotification.showNotificationDetails(personNotification.subject, personNotificationMessage.value, personNotificationMessage.flowCode,
        '/tasks/?f=' + personNotificationMessage.flowIdUnwrapped().urlSerialized() + "&n="+personNotificationMessage.nodeId);

    } else if (personNotification.isFileMessage()) {
      const personNotificationMessage = <FileMessage>personNotification.unWrappedMessage();

      SystemNotification.showNotificationDetails(personNotification.subject, personNotificationMessage.value, personNotificationMessage.value,
        '/documents/' + personNotificationMessage.path);

    } else if (personNotification.isMailboxMessage()) {
      const personNotificationMessage = <MailboxMessage>personNotification.unWrappedMessage();

      SystemNotification.showNotificationDetails(personNotification.subject, personNotificationMessage.value, personNotificationMessage.value,
        '/mailbox/' + personNotificationMessage.mailboxId.id);

    } else if (personNotification.isSystemMessage()) {
      const personNotificationMessage = <SystemMessage>personNotification.unWrappedMessage();

      SystemNotification.showNotificationDetails(personNotification.subject, personNotificationMessage.value, personNotificationMessage.value,
        '/');
    }
  }


  private static showNotificationDetails(subject: string, body: string, tag: string, url: string) {


    navigator.serviceWorker.ready.then(function(registration) {
      registration.showNotification("Neula - " + subject,
         new NotificationOption(body, 'images/notification-icon.png', tag));
    });

    // Deprecated

    // const notification = new Notification("Neula - " + subject,
    //   new NotificationOption(body, 'images/notification-icon.png', tag));
    //
    // notification.onclick = () => {
    //   required(event, "event").preventDefault();
    //   window.open(url);
    // }
  }

}
