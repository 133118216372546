import {BusinessVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {RadioButtonComponentDefinition} from "@screen-common";

export class RadioButtonComponentState extends LabeledScreenComponentState {
  static className = "RadioButtonComponentState";
  className() {
    return RadioButtonComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalModelProperty(RadioButtonComponentDefinition.MODEL);
  }

  get options() {
    return this.properties.labeledValuesProperty("options");
  }

  get entriesLayout() {
    return this.properties.stringProperty("entriesLayout")
  }

  get iconBackgroundColor() {return this.properties.optionalStringProperty("iconBackgroundColor")}

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case RadioButtonComponentDefinition.MODEL:
        if(value.isDefined()) {
          this.properties.putValue(RadioButtonComponentDefinition.MODEL, value.get());
        } else {
          this.properties.clearValue(RadioButtonComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: RadioButtonComponentState) {
    return new RadioButtonComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class RadioButtonComponentRefState extends ScreenComponentRefState {
  static className = "RadioButtonComponentRefState";
  className() {
    return RadioButtonComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: RadioButtonComponentRefState) {
    return new RadioButtonComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
