import {Option, VariableId} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  HeaderPropertiesState,
  LayoutsPropertiesState,
  OptionalContextComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState
} from "../..";


export class SectionContainerState extends OptionalContextComponentState {
  static className = "SectionContainerState";

  className() {
    return SectionContainerState.className;
  }

  constructor(public override innerContext: Option<VariableId>,
              override readonly properties: PropertiesStateHolder,
              override readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get contentTextSize() {
    return this.properties.optionalStringProperty("textSize")
  }

  get contentTextColor() {
    return this.properties.optionalStringProperty("textColor")
  }

  get contentTextFont() {
    return this.properties.optionalStringProperty("textFont")
  }

  get header() {
    return this.properties.optionalI18nTextProperty("header")
  }

  get headerVisible() {
    return this.properties.booleanProperty("headerVisible")
  }

  readonly layoutsState = new LayoutsPropertiesState("", this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);
  readonly headerState = new HeaderPropertiesState(this.properties);

  static copy(other: SectionContainerState) {
    return new SectionContainerState(Option.copy(other.innerContext).map(VariableId.copy), PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

  updateInnerContext(innerContext: Option<VariableId>): void {
    this.innerContext = innerContext;
  }
}

export class SectionContainerRefState extends ScreenComponentRefState {
  static className = "SectionContainerRefState";

  className() {
    return SectionContainerRefState.className;
  }

  constructor(override readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: SectionContainerRefState) {
    return new SectionContainerRefState(PropertiesStateHolder.copy(other.properties));
  }
}
