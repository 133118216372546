import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {LabelComponentViewModel} from "./LabelComponentViewModel";
import {convertLinksToTags} from "@utils";
import {Subscription} from "rxjs";

@Component({
  selector: 'label-component',
  templateUrl: './label-component.component.html',
  host: {
    "[class]": "'theme-selectable-text componentBody LabelComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[my-tooltip]": "{text: viewModel.tooltipText, mobileVisibility: true, enabled: viewModel.tooltipEnabled}"
  }
})
export class LabelComponentComponent implements OnDestroy, AfterViewInit{

  @Input({required:true}) viewModel!: LabelComponentViewModel;

  @ViewChild("Content") private inputElement: ElementRef<HTMLInputElement> | undefined;

  textSubscription?: Subscription;

  private lastText: string = "";

  ngAfterViewInit() {

    this.textSubscription = this.viewModel.textObservable.subscribe((newText) => {
      this.updateText(newText);
    });

    this.updateText(this.viewModel.text);
  }

  private updateText(newText: string) {
    if (this.inputElement && this.lastText !== newText) {
      this.lastText = newText;
      const element = this.inputElement.nativeElement;
      element.innerText = newText;
      element.innerHTML = convertLinksToTags(element.innerHTML);
    }
  }

  ngOnDestroy(): void {
    this.textSubscription?.unsubscribe();
  }
}
