<div class="sectionHeader {{viewModel.headerCssClasses}}" *ngIf="viewModel.headerVisible" [style]="viewModel.headerCss">{{viewModel.header}}</div>
<div class="repeatableEntriesScroll">
  <div class="repeatableEntries" [style]="viewModel.entriesCss" [class.deleteEnabled]="viewModel.possiblyCanDeleteEntries" [class.reorderEnabled]="viewModel.possiblyCanMoveEntries">
    <my-sortable class="sortableEntries" [disabled]="!viewModel.canMoveEntries" dragHandleSelector=".dragHandle"
                 elementSelector=".repeatableEntry" [reinitDrag]="viewModel.reinitDrag" (moved)="viewModel.moveEntry($event.fromIndex, $event.toIndex)"
                 [setDraggedElementBackground]="true">
      <div class="repeatableEntry" [style]="entry.css" *ngFor="let entry of viewModel.entries"
           [ngClass]="{clickable: viewModel.clickableEntry}" (click)="viewModel.onEntryClick(entry)">
        <div class="clickableGlass" *ngIf="viewModel.clickableEntry"></div>
        <div
          class="childrenPlain {{viewModel.childrenPlainCssClasses}} layout_{{viewModel.layoutClass}} layoutAlign_{{viewModel.layoutAlignClass}} layoutStretch_{{viewModel.layoutStretchClass}} layoutWrap_{{viewModel.layoutWrapClass}}"
          [style]="viewModel.childrenPlainCss">

          <div class="dragHandle" *ngIf="viewModel.canMoveEntries"><i class="mi-move-alt"></i></div>

          <ng-container *ngFor="let component of entry.children">
            <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
                 [class.nonVisibleComponent]="component.nonVisibleComponent"
                 [style]="component.positionCss">
              <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
                <!--              SHARED -->
                <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />

                <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />

                <div class="errorsInfo" *ngIf="component.error">
                  <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
                </div>

                <div class="inProgressGlass" *ngIf="component.inProgress"></div>
                <!--              SHARED -->
              </div>
            </div>
          </ng-container>

          <button class="deleteButton" *ngIf="viewModel.canDeleteEntries" (click)="viewModel.deleteEntry(entry)"><i
            class="mi-trash"></i></button>

        </div>

      </div>
    </my-sortable>
    <div class="addButtonPanel" *ngIf="viewModel.canAddEntry">
      <button class="addButton" (click)="viewModel.addEntry()"><i class="mi-plus"></i>{{viewModel.addLabel}}</button>
    </div>
  </div>
</div>
