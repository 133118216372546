import {createArrayFrom0ToNExclusive, LocalTime, Option, toastr} from "@utils";
import {Component, EventEmitter, Input, Output} from "@angular/core";


export enum TimePickerValueType {m, h}


@Component({
  selector: 'my-time-picker-board',
  templateUrl: './time-picker-board.component.html',
})
export class TimePickerBoardComponent {

  private _time: LocalTime|null = null;
  get time(): LocalTime | null {return this._time;}
  @Input() set time(value: LocalTime | null) {
    if(this._time != value) {
      this._time = value;
      this.onValueChanged();
    }
  }
  @Output() timeChange = new EventEmitter<LocalTime | null>;

  @Output() sizeChanged = new EventEmitter<void>();

  @Output() picked = new EventEmitter<LocalTime>();

  hours: Array<string>;
  minutes: Array<string>;
  allMinutes: Array<string>;
  allMinutesMode: boolean;
  hourSelected: string|undefined;
  minuteSelected: string|undefined;

  hourClicked = false;
  minuteClicked = false;

  constructor() {
    this.hours = createArrayFrom0ToNExclusive(24).map(t => t+"");
    this.minutes = createArrayFrom0ToNExclusive(12).map(t => TimePickerBoardComponent.formatMinute(t*5));
    this.allMinutes = createArrayFrom0ToNExclusive(60).map(t => TimePickerBoardComponent.formatMinute(t));

    this.allMinutesMode = false;
  }

  private static formatMinute(m: number): string {
    if(m < 10) {
      return "0"+m;
    } else {
      return ""+m;
    }
  }

  private onValueChanged() {
    if(this._time == null) {
      this.hourSelected = undefined;
      this.minuteSelected = undefined;
      this.allMinutesMode = false;
    } else {
      this.hourSelected = this._time.hour+"";
      this.minuteSelected = TimePickerBoardComponent.formatMinute(this._time.minute);
      const allMinutesMode = this._time.minute % 5 !== 0;
      if(allMinutesMode !== this.allMinutesMode) {
        this.allMinutesMode = allMinutesMode;
        this.sizeChanged.emit();
      }
    }
  }

  selectHour = (hour: string) => {
    this.hourSelected = hour;
    this.hourClicked = true;

    // If only hour is selected we assume full hour
    this._time = new LocalTime(parseInt(this.hourSelected), parseInt(Option.of(this.minuteSelected).getOrElse(this.allMinutes[0])), 0, 0);
    this.timeChange.emit(this._time);
    if(this.minuteClicked) {
      this.picked.emit(this._time);
    }
  };

  selectMinute = (minute: string) => {
    this.minuteSelected = minute;
    this.minuteClicked = true;
    if(this.hourSelected !== undefined) {
      this._time = new LocalTime(parseInt(this.hourSelected), parseInt(this.minuteSelected), 0, 0);
      this.timeChange.emit(this._time);
      if(this.hourClicked) {
        this.picked.emit(this._time);
      }
    }
  };

  toggleMinutesMode = () => {
    this.allMinutesMode = !this.allMinutesMode;
    this.sizeChanged.emit();
  }
}

