import {Pipe, PipeTransform} from "@angular/core";
import {I18nService} from "..";
import {I18nText} from "@utils";


@Pipe({name: 'i18n'})
export class I18nPipe implements PipeTransform {

  constructor(private readonly i18nService: I18nService) {}

  transform(input: string|I18nText): string {
    if (input == undefined) {
      return "no input";
    } else if(input instanceof I18nText) {
      return input.getCurrentWithFallback();
    } else {
      return this.i18nService.translate(input);
    }
  }

}

@Pipe({name: 'i18nDynamic', pure: false})
export class NonPureI18nPipe extends I18nPipe {
  constructor(i18nService: I18nService) {
    super(i18nService);
  }
}
