import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NotificationsLocalService} from "../../services/notifications/notifications.local-service";
import {PersonNotification} from "../../services/notifications/notifications.model";
import {LocalDate} from "@utils";

@Component({
  selector: 'my-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {

  allNotifications: Array<PersonNotification> = [];
  visibleNotifications: Array<PersonNotification> = [];
  todayNotifications: Array<PersonNotification> = [];
  olderNotifications: Array<PersonNotification> = [];
  recentNotifications: Array<PersonNotification> = [];

  private _showUnreadOnly = false;
  private initialized = false;

  @Input() recentOnly = false;

  @Input() set showUnreadOnly(value: boolean) {
    this._showUnreadOnly = value;
    if(this.initialized) {
      this.updateVisibleNotifications();
    }
  }

  @Output() sizeChanged = new EventEmitter<void>();

  constructor(readonly notificationsLocalService: NotificationsLocalService) {}

  ngOnInit(): void {
    this.allNotifications = this.notificationsLocalService.getNotifications();
    this.updateVisibleNotifications();
    this.notificationsLocalService.notificationsObservable.subscribe(notifications => {
      this.allNotifications = notifications;
      this.updateVisibleNotifications();
    });
    this.initialized = true;
  }


  private updateVisibleNotifications() {
    const nowDate = LocalDate.nowDate();
    if(this._showUnreadOnly) {
      this.visibleNotifications = this.allNotifications.filter(n => !n.read);
    } else {
      this.visibleNotifications = this.allNotifications;
    }
    this.todayNotifications = this.visibleNotifications.filter(n => n.createdDate.date.isEqual(nowDate));
    this.olderNotifications = this.visibleNotifications.filter(n => !n.createdDate.date.isEqual(nowDate));
    this.recentNotifications = this.visibleNotifications.slice(0, 10);
    this.sizeChanged.emit()
  }
}
