import {__, AggregateId, AggregateVersion, ApplicationId, I18nText, Option, Typed} from "@utils";
import {BusinessVariableType, ProcessInfo, ProcessNodeId, RootVariableType, SharedService} from "@shared-model";
import {ElementsFactory} from "./ElementsFactory";
import {DesignAttachment, NodeActivity} from "./NodeProperties";
import {FormElement, FormModel, FormSectionInfo} from "./FormModel";
import {GridProcessModel} from "./GridProcessModel";
import {FormSectionId, ScreenComponentRefIdentifier} from "@shared";

export interface DropListOption {
  label: string;
  value: any;
}



export class TasksVisibility {
  constructor(readonly name: string) {}

  static assignedOrInQueue = new TasksVisibility("assignedOrInQueue");
  static allInRole = new TasksVisibility("allInRole");
  static allInCase = new TasksVisibility("allInCase");

  static copy(other: TasksVisibility) {
    switch (other.name) {
      case TasksVisibility.assignedOrInQueue.name : return TasksVisibility.assignedOrInQueue;
      case TasksVisibility.allInRole.name : return TasksVisibility.allInRole;
      case TasksVisibility.allInCase.name : return TasksVisibility.allInCase;
      default: throw new Error("Invalid tasks visibility type: " + other.name);
    }
  }
}





export class NodeSummary {
  constructor(public actorId: number, public nodeName: I18nText) {}

  static copy(other: NodeSummary) {
    return new NodeSummary(other.actorId, I18nText.copy(other.nodeName));
  }
}

export class NodeInfo {

  constructor( readonly nodeId: ProcessNodeId,
               readonly releaseId: AggregateId,
               readonly nodeName: I18nText,
               readonly roleName: I18nText,
               readonly nodeDescription: I18nText,
               readonly actorId: number,
               readonly form: FormModel,
               readonly formVariables: Array<RootVariableType<BusinessVariableType>>,
               readonly formSections: Array<FormSectionInfo>,
               readonly formElements: Array<Typed<FormElement>>,
               readonly visibleFormSections: Array<FormSectionId>,
               readonly activities: Array<NodeActivity>,
               readonly attachments: Array<DesignAttachment>,
               readonly automatic: boolean,
               readonly defaultEdgeName: Option<string>) {}

  static copy(other: NodeInfo) {
    return new NodeInfo(other.nodeId, other.releaseId, I18nText.copy(other.nodeName), I18nText.copy(other.roleName), I18nText.copy(other.nodeDescription), other.actorId, FormModel.copy(other.form),
      other.formVariables.map(fv => RootVariableType.copy(fv)), other.formSections.map(fs => FormSectionInfo.copy(fs)), other.formElements.map(ElementsFactory.copyTyped),
      other.visibleFormSections.slice(), other.activities.map(a => NodeActivity.copy(a)),
      other.attachments.map(DesignAttachment.copy), other.automatic, Option.copy(other.defaultEdgeName));
  }
}

export class ProcessReleaseNodesSummary {
  processReleaseId: AggregateId;
  nodeIdToSummary: [number, NodeSummary][];
  nodeIdToSummaryMap: {[nodeId: number]: NodeSummary}; //client side only
  constructor(processReleaseId: AggregateId, nodeIdToSummary:[number, NodeSummary][]) {
    this.processReleaseId = processReleaseId;
    this.nodeIdToSummary = nodeIdToSummary;
    this.nodeIdToSummaryMap = {};
    this.nodeIdToSummary.forEach(n2a => {
      this.nodeIdToSummaryMap[n2a[0]] = n2a[1];
    })
  }

  static copy(other: ProcessReleaseNodesSummary) {
    return new ProcessReleaseNodesSummary(other.processReleaseId, other.nodeIdToSummary.map(kv => <[number, NodeSummary]>[kv[0], NodeSummary.copy(kv[1])]));
  }
}

export class ModelInfo {
  constructor(readonly id: AggregateId,
              readonly version: number,
              readonly model: GridProcessModel,
              readonly processInfo: ProcessInfo) {}

  static copy(other:ModelInfo):ModelInfo {
    return new ModelInfo(new AggregateId(other.id.id), other.version, GridProcessModel.copy(other.model), ProcessInfo.copy(other.processInfo));
  }
}

export class CompetenciesInfo {
  aggregateId: AggregateId|undefined = undefined;
  aggregateVersion: AggregateVersion|undefined = undefined;
  competencies: Competencies;

  constructor(personId: AggregateId) {
    this.competencies = new Competencies(personId, []);
  }
}

export class Competencies {
  constructor(readonly personId:AggregateId,
              readonly competencies: Array<Competency>) {}
}

export class Competency {
  constructor(readonly roleId:number,
              readonly processId:AggregateId,
              readonly process:string,
              readonly value:number) {}
}

export class PersonCompetency {

  constructor(readonly processId: AggregateId,
              readonly roleId: number,
              readonly person: AggregateId,
              readonly value: number) {}

  static copy(other: PersonCompetency) {
    return new PersonCompetency(other.processId, other.roleId, other.person, other.value);
  }

}

export class InstanceRoles {
  instanceToRole: [AggregateId, number[]][] = [];
  instanceToRoleMap: { [key: string]: number[] } = {};

  static copy(other: InstanceRoles) {
    const newInstanceRoles = new InstanceRoles();
    newInstanceRoles.instanceToRole = other.instanceToRole.map((entry: [AggregateId, number[]]): [AggregateId, number[]] => [entry[0], entry[1].slice()]);
    newInstanceRoles.instanceToRoleMap = {};
    newInstanceRoles.instanceToRole.forEach((entry: [AggregateId, number[]]) => {
      newInstanceRoles.instanceToRoleMap[entry[0].id] = entry[1];
    });
    return newInstanceRoles;
  }
}

export class SharedServiceInfo {

  constructor(readonly applicationId: Option<ApplicationId>,
              readonly processId: AggregateId,
              readonly processName: string,
              readonly service: SharedService) {}

  static copy(other: SharedServiceInfo): SharedServiceInfo {
    return new SharedServiceInfo(Option.copy(other.applicationId).map(ApplicationId.of), other.processId, other.processName, SharedService.copy(other.service));
  }
}

export class CustomProcessInfoData {
  constructor(readonly controlMechanisms: Array<ControlMechanism>,
              readonly areasOfData: Array<string>,
              readonly documentTypes: Array<ProcessDocumentTemplate>,
              readonly occurrenceResponse: Array<string>,
              readonly authorizationTypes: Array<string>,
              readonly measuresFrequencies: Array<string>,
              readonly processCardTitle: string) {}

  getControlMechanismListFlatten(): Array<DropListOption> {
    return this.controlMechanisms.map(cM => ({label: cM.name, value: {id: cM.id, array: __(cM.mechanisms)
      .flatMap(m => CustomProcessInfoData.generateSelectSingleOptionRecursive(m.name, m.name, m.mechanisms, []))}}));
  }

  static generateSelectSingleOptionRecursive(originName: string, name: string, controlMechanisms: Array<ControlMechanism>, options: Array<DropListOption>): Array<DropListOption> {
    if(controlMechanisms.length > 0) {
      return __(controlMechanisms).flatMap(m => CustomProcessInfoData.generateSelectSingleOptionRecursive(m.name, name + " - " + m.name, m.mechanisms, options));
    } else {
      return options.concat({label: name, value: originName});
    }
  }

  static copy(other: CustomProcessInfoData) {
    return new CustomProcessInfoData(other.controlMechanisms, other.areasOfData, other.documentTypes,
      other.occurrenceResponse, other.authorizationTypes, other.measuresFrequencies, other.processCardTitle)
  }
}

export class ProcessDocumentTemplate{
  constructor(readonly documentType: string, readonly template: string) {}
}

export class ControlMechanism {
  constructor(readonly id: number, readonly name: string, readonly mechanisms: Array<ControlMechanism>) {}
}

export class FlowPreviewScreen {
  constructor(readonly screenComponentRef: ScreenComponentRefIdentifier,
              readonly name: string) {}

  static copy(other: FlowPreviewScreen) {
    return new FlowPreviewScreen(other.screenComponentRef, other.name);
  }
}

