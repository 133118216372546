<!-- MONTHLY -->
<div *ngIf="monthlyType">
  <div class="calendarHeader">
    <div class="calendarNavigation">
      <button (click)="monthly.previousMonth()"><i class="mi-navigate-left"></i></button>
      <button (click)="monthly.nextMonth()"><i class="mi-navigate-right"></i></button>
    </div>
    {{monthly.monthName}}
    <div class="calendarConfiguration">
      <my-drop-down-selector [options]="typeOptions"
                             [(value)]="calendarType"
                             (valueChange)="calendarTypeChanged($event)">
        <i prefix class="mi-calendar"></i>
      </my-drop-down-selector>
    </div>
  </div>
  <div class="monthlyCalendar">
    <div class="calendarWeekDays">
      <div class="dayHeader" *ngFor="let day of monthly.daysHeader">{{day.name}}</div>
    </div>
    <div class="calendarWeek" *ngFor="let week of monthly.weeks">
      <div *ngFor="let day of week.days" class="calendarDay selectablePeriod id{{day.id}}"
           [class.today]="day.today"
           [class.otherMonth]="day.otherMonth"
           [class.selected]="day.selected"
           [class.off]="day.off">
        <div class="dayNumber">{{day.date.day}}</div>
        <div class="calendarEntry" *ngFor="let entry of day.entries"
             [style.width]="entry.cssWidth"
             [class.inverted]="entry.inverted"
             [class.placeholder]="entry.placeholder"
             [class.selected]="entry.selected"
             [class.openLeft]="entry.openLeft"
             [class.openRight]="entry.openRight">
          <button (click)="monthly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
                  [style.background-color]="entry.color"
                  [style.border-color]="entry.color">
            {{entry.name}}
            <my-tooltip position="top" cssClass="calendarEntryTooltip" [alwaysVisible]="entry.selected">
              <ng-container *ngIf="entry.model">
                <div *ngIf="entry.model.value">
                  <my-variable-text-preview [model]="entry.model.value" />
                </div>
                <div *ngIf="entry.model.resource">
                  Resource: <my-variable-text-preview [model]="entry.model.resource" />
                </div>
                <div *ngIf="entry.model.entryType">
                  Type: <my-variable-text-preview [model]="entry.model.entryType" />
                </div>
                <div *ngIf="entry.model.from">
                  From: <my-time-summary [time]="entry.model.from" [detailed]="true" />
                </div>
                <div *ngIf="entry.model.to">
                  To: <my-time-summary [time]="entry.model.to" [detailed]="true" />
                </div>

              </ng-container>
            </my-tooltip>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- WEEKLY -->
<div *ngIf="weeklyType">
  <div class="calendarHeader">
    <div class="calendarNavigation">
      <button (click)="weekly.previousWeek()"><i class="mi-navigate-left"></i></button>
      <button (click)="weekly.nextWeek()"><i class="mi-navigate-right"></i></button>
    </div>
    {{weekly.weekName}}
    <div class="calendarConfiguration">
      <my-drop-down-selector [options]="typeOptions"
                             [(value)]="calendarType"
                             (valueChange)="calendarTypeChanged($event)">
        <i prefix class="mi-calendar"></i>
      </my-drop-down-selector>
    </div>
  </div>
  <div class="weeklyCalendar">
    <div class="weeklyCalendarHeader">
      <div class="headerSpace"></div>
      <div class="dayHeader" *ngFor="let day of weekly.dayHeader">
        <div class="dayName"><span class="dayNumber">{{day.day}}</span>{{day.name}}</div>
        <div class="entries">
          <div class="calendarEntry" *ngFor="let entry of day.entries"
               [style.width]="entry.cssWidth"
               [class.inverted]="entry.inverted"
               [class.placeholder]="entry.placeholder"
               [class.selected]="entry.selected"
               [class.openLeft]="entry.openLeft"
               [class.openRight]="entry.openRight">
            <button (click)="monthly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
                    [style.background-color]="entry.color"
                    [style.border-color]="entry.color">
              {{entry.name}}
              <my-tooltip position="top" cssClass="calendarEntryTooltip" [alwaysVisible]="entry.selected">
                <ng-container *ngIf="entry.model">
                  <div *ngIf="entry.model.value">
                    <my-variable-text-preview [model]="entry.model.value" />
                  </div>
                  <div *ngIf="entry.model.resource">
                    Resource: <my-variable-text-preview [model]="entry.model.resource" />
                  </div>
                  <div *ngIf="entry.model.entryType">
                    Type: <my-variable-text-preview [model]="entry.model.entryType" />
                  </div>
                  <div *ngIf="entry.model.from">
                    From: <my-time-summary [time]="entry.model.from" [detailed]="true" />
                  </div>
                  <div *ngIf="entry.model.to">
                    To: <my-time-summary [time]="entry.model.to" [detailed]="true" />
                  </div>

                </ng-container>
              </my-tooltip>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="weekContent">
      <my-scroll contentClass="weekScrollContent">
        <div class="hoursHeader">
          <div class="hourHeader" *ngFor="let hour of weekly.hourHeader">{{hour.name}}</div>
        </div>
        <div class="calendarDay" *ngFor="let day of weekly.days">
          <div class="calendarHalfHour calendarHalfHour{{halfHour.columns}} selectablePeriod id{{halfHour.id}}"
               *ngFor="let halfHour of day.halfHours"
               [class.working]="halfHour.working"
               [class.selected]="halfHour.selected"
               [class.endOfFullHour]="halfHour.endOfFullHour">
            <div class="calendarEntry" *ngFor="let entry of halfHour.visibleEntries"
                 [style.height]="entry.cssHeight"
                 [class.inverted]="entry.inverted"
                 [class.placeholder]="entry.placeholder"
                 [class.selected]="entry.selected">
              <button (click)="weekly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
                      [style.background-color]="entry.color"
                      [style.border-color]="entry.color">
                {{entry.name}}
                <my-tooltip position="top" cssClass="calendarEntryTooltip" [alwaysVisible]="entry.selected">
                  <ng-container *ngIf="entry.model">
                    <div *ngIf="entry.model.value">
                      <my-variable-text-preview [model]="entry.model.value" />
                    </div>
                    <div *ngIf="entry.model.resource">
                      Resource: <my-variable-text-preview [model]="entry.model.resource" />
                    </div>
                    <div *ngIf="entry.model.entryType">
                      Type: <my-variable-text-preview [model]="entry.model.entryType" />
                    </div>
                    <div *ngIf="entry.model.from">
                      From: <my-time-summary [time]="entry.model.from" [detailed]="true" />
                    </div>
                    <div *ngIf="entry.model.to">
                      To: <my-time-summary [time]="entry.model.to" [detailed]="true" />
                    </div>

                  </ng-container>
                </my-tooltip>
              </button>
            </div>
          </div>
        </div>
      </my-scroll>
    </div>
  </div>
</div>

<!-- YEARLY -->
<div *ngIf="yearlyType">
  <div class="calendarHeader">
    <div class="calendarNavigation">
      <button (click)="yearly.previousYear()"><i class="mi-navigate-left"></i></button>
      <button (click)="yearly.nextYear()"><i class="mi-navigate-right"></i></button>
    </div>
    {{yearly.year}}
    <div class="calendarConfiguration">
      <my-drop-down-selector [options]="typeOptions"
                             [(value)]="calendarType"
                             (valueChange)="calendarTypeChanged($event)">
        <i prefix class="mi-calendar"></i>
      </my-drop-down-selector>
    </div>
  </div>
  <div class="yearlyCalendar">
    <div class="yearlyHeader">
      <div class="headerSpace"></div>
      <div class="dayHeader" *ngFor="let day of yearly.daysHeader">{{day.name}}</div>
    </div>
    <div class="month" *ngFor="let month of yearly.months">
      <div class="monthName">{{month.name}}</div>
      <div class="monthDay monthDay{{day.rows}} selectablePeriod id{{day.id}}" *ngFor="let day of month.days"
           [class.mock]="day.mock"
           [class.off]="day.off"
           [class.selected]="day.selected">
        <span class="monthEntries" *ngIf="day.visibleEntries.length > 3">{{day.entries.length}}</span>
        <div class="calendarEntry" *ngFor="let entry of day.visibleEntries"
             [style.width]="entry.cssSize"
             [class.inverted]="entry.inverted"
             [class.placeholder]="entry.placeholder"
             [class.selected]="entry.selected">
          <button (click)="yearly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
                  [style.background-color]="entry.color"
                  [style.border-color]="entry.color"
                  [my-tooltip]="{text: entry.name, position: 'top'}">
            {{entry.name}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- RESOURCES -->
<div *ngIf="resourceType">
  <div class="calendarHeader">
    <div class="calendarNavigation">
      <button (click)="resource.previousPeriod()"><i class="mi-navigate-left"></i></button><!--
        -->
      <button (click)="resource.nextPeriod()"><i class="mi-navigate-right"></i></button>
    </div>
    {{resource.periodName}}
    <div class="calendarConfiguration">
      <my-drop-down-selector [options]="resource.periodOptions"
                             [(value)]="resource.periodLength"
                             (valueChange)="resource.periodLengthChanged($event)">
        <i prefix class="mi-calendar"></i>
      </my-drop-down-selector>
      <my-drop-down-selector [options]="typeOptions"
                             [(value)]="calendarType"
                             (valueChange)="calendarTypeChanged($event)">
        <i prefix class="mi-calendar"></i>
      </my-drop-down-selector>
    </div>
  </div>
  <div class="resourceCalendar resourceCalendar{{resource.columns}}">

    <div class="resourcesHeader">
      <div class="headerSpacer"></div>
      <div class="resourceHeader" *ngFor="let resource of resource.resources">{{resource.name}}</div>
    </div>

    <div class="timeColumn">

      <div class="timeHeader">
        <div class="timeEntry" *ngFor="let time of resource.timeHeader">{{time.name}}</div>
      </div>
      <div class="periodContent">
        <my-scroll>
          <div class="resource" *ngFor="let resource of resource.resources">
            <div class="period period{{period.rows}} selectablePeriod id{{period.id}}"
                 *ngFor="let period of resource.periods"
                 [class.off]="period.off"
                 [class.selected]="period.selected">
              <div class="calendarEntry" *ngFor="let entry of period.visibleEntries"
                   [style.width]="entry.cssSize"
                   [class.inverted]="entry.inverted"
                   [class.placeholder]="entry.placeholder"
                   [class.selected]="entry.selected">
                <button
                  (pointerdown)="$event.stopPropagation()" (click)="resource.entrySelected(entry)"
                  [style.background-color]="entry.color"
                  [style.border-color]="entry.color">
                  {{entry.name}}
                  <my-tooltip position="top" cssClass="calendarEntryTooltip" [alwaysVisible]="entry.selected">
                    <ng-container *ngIf="entry.model">
                      <div *ngIf="entry.model.value">
                        <my-variable-text-preview [model]="entry.model.value" />
                      </div>
                      <div *ngIf="entry.model.resource">
                        Resource: <my-variable-text-preview [model]="entry.model.resource" />
                      </div>
                      <div *ngIf="entry.model.entryType">
                        Type: <my-variable-text-preview [model]="entry.model.entryType" />
                      </div>
                      <div *ngIf="entry.model.from">
                        From: <my-time-summary [time]="entry.model.from" [detailed]="true" />
                      </div>
                      <div *ngIf="entry.model.to">
                        To: <my-time-summary [time]="entry.model.to" [detailed]="true" />
                      </div>

                    </ng-container>
                  </my-tooltip>
                </button>
              </div>
            </div>
          </div>
        </my-scroll>
      </div>
    </div>
  </div>
</div>
<!--<div class="selectedPopup" *ngIf="selectedPopup.isDefined() && selectedPopupVisible">-->
<!--  <button class="closeButton" (click)="entryUnselected()"><i class="mi-delete"></i></button>-->
<!--  TODO Child component-->

<!--  &lt;!&ndash;    <div class="child" style="{{child.positionCss}}" *ngFor="let child of selectedPopup.get().children | filter: { uncoveredAndVisible: true } track by $index">&ndash;&gt;-->
<!--  &lt;!&ndash;      <my-view-component view-model="child.component"></my-view-component>&ndash;&gt;-->
<!--  &lt;!&ndash;    </div>&ndash;&gt;-->
<!--</div>-->
