import {AggregateId, FileUri, Language, None, Option, PersonId, Typed, WeekDay} from "@utils";
import {HoursFormat, OperatingHours, OperatingHoursException} from "@shared-model";

export class ContinueMFALogin {
  constructor(readonly loginAttemptId: string,
              readonly mfaToken: string,
              readonly password: Option<string>,
              readonly identityServiceUserToken: Option<string>) {}
}

export class OrganizationLoginRequest {

  constructor(readonly email: string,
              readonly password: string,
              readonly rememberMe: boolean,
              readonly identityServiceUserToken: Option<string>) {}
}

export class OrganizationLoginAsRequest {
  constructor(readonly credentials: string) {}
}

export class LoginLink {
  constructor(readonly link: string) {}
}

// Reflects server side
export class ApplicationLoginRequest {
  constructor(readonly email: string,
              readonly password: string) {}
}

export class ImposterInfo {
  constructor(readonly personId: PersonId, readonly firstName: string, readonly lastName: string) {}

  static copy(other: ImposterInfo) {
    return new ImposterInfo(PersonId.of(other.personId), other.firstName, other.lastName);
  }
}

// Reflects server side
export class OrganizationSessionInfoClientSide {

  constructor(readonly sessionToken: string,
              readonly userFirstName: string,
              readonly userLastName: string,
              readonly userEmail: string,
              readonly avatar: Option<FileUri>,
              readonly personId: AggregateId,
              readonly organization: string,
              readonly organizationId: AggregateId,
              readonly organizationUrlAlias: string,
              readonly outsourcer: boolean,
              readonly calendar: DepartmentCalendar,
              readonly imposter: Option<ImposterInfo>,
              readonly internalAuthenticationEnabled: boolean,
              readonly loggedExternally: boolean,
              readonly paymentsBasicInfo: Option<PaymentsBasicInfo>,
              readonly loginByLink: boolean) {
  }

  static isImpersonated(that: OrganizationSessionInfoClientSide) {
    return Option.copy(that.imposter).isDefined();
  }

  getPersonId() {
    return PersonId.of(this.personId);
  }

  isAuthenticated() {
    return this.sessionToken !== undefined;
  }

  userSimpleName() {
    return this.userFirstName + " " + this.userLastName;
  }

  static copy(other: OrganizationSessionInfoClientSide) {
    return new OrganizationSessionInfoClientSide(
      other.sessionToken,
      other.userFirstName,
      other.userLastName,
      other.userEmail,
      Option.copy(other.avatar, FileUri.copy),
      AggregateId.copy(other.personId),
      other.organization,
      AggregateId.copy(other.organizationId),
      other.organizationUrlAlias,
      other.outsourcer,
      DepartmentCalendar.copy(other.calendar),
      Option.copy(other.imposter, ImposterInfo.copy),
      other.internalAuthenticationEnabled,
      other.loggedExternally,
      Option.copy(other.paymentsBasicInfo, PaymentsBasicInfo.copy),
      other.loginByLink,
    );

  }
}

export class WeeklyOperatingHours {
  constructor(public monday:Option<OperatingHours>,
              public tuesday:Option<OperatingHours>,
              public wednesday:Option<OperatingHours>,
              public thursday:Option<OperatingHours>,
              public friday:Option<OperatingHours>,
              public saturday:Option<OperatingHours>,
              public sunday:Option<OperatingHours>) {}

  static copy(other: WeeklyOperatingHours): WeeklyOperatingHours {
    return new WeeklyOperatingHours(
      Option.copy(other.monday).map(OperatingHours.copy),
      Option.copy(other.tuesday).map(OperatingHours.copy),
      Option.copy(other.wednesday).map(OperatingHours.copy),
      Option.copy(other.thursday).map(OperatingHours.copy),
      Option.copy(other.friday).map(OperatingHours.copy),
      Option.copy(other.saturday).map(OperatingHours.copy),
      Option.copy(other.sunday).map(OperatingHours.copy)
    )
  }
}

export class DepartmentCalendar {
  constructor(public weekly:WeeklyOperatingHours,
              public exceptions: Array<OperatingHoursException>) {}

  static copy(other: DepartmentCalendar): DepartmentCalendar {
    return new DepartmentCalendar(WeeklyOperatingHours.copy(other.weekly), other.exceptions.map(OperatingHoursException.copy))
  }

  static empty() {
    return new DepartmentCalendar(new WeeklyOperatingHours(None(),None(),None(),None(),None(),None(),None()), [])
  }
}

// MOCK
export class PaymentsBasicInfo {
  static copy(other: PaymentsBasicInfo): PaymentsBasicInfo {
    return new PaymentsBasicInfo();
  }
}

export class ApplicationSessionInfoClientSide {

  constructor(readonly sessionToken: string,
              readonly userFirstName: string,
              readonly userLastName: string,
              readonly userEmail: string) {}

  static copy(other: ApplicationSessionInfoClientSide): ApplicationSessionInfoClientSide {
    return new ApplicationSessionInfoClientSide(other.sessionToken, other.userFirstName, other.userLastName, other.userEmail);
  }
}



export class OrganizationLoginResponseFactory {
  static copy(response: OrganizationLoginResponse): OrganizationLoginResponse {
    return OrganizationLoginResponseFactory.copyByType(response, response.className());
  }

  static copyTyped(response: Typed<OrganizationLoginResponse>): Typed<OrganizationLoginResponse> {
    return Typed.of(OrganizationLoginResponseFactory.copyByType(Typed.value(response), Typed.className(response)));
  }

  static copyByType(response: OrganizationLoginResponse, className: string): OrganizationLoginResponse {
    switch (className) {
      case OrganizationLoginSuccess.className: return OrganizationLoginSuccess.copy(<OrganizationLoginSuccess>response);
      case OrganizationAuthenticationOnlySuccess.className: return OrganizationAuthenticationOnlySuccess.copy(<OrganizationAuthenticationOnlySuccess>response);
      case OrganizationLoginMFATokenRequired.className: return OrganizationLoginMFATokenRequired.copy(<OrganizationLoginMFATokenRequired>response);
      case OrganizationLoginFailure.className: return OrganizationLoginFailure.copy(<OrganizationLoginFailure>response);
      case OrganizationLoginTemporaryDisabled.className: return OrganizationLoginTemporaryDisabled.copy(<OrganizationLoginTemporaryDisabled>response);
      default: throw new Error("Unsupported response class: " + className + ", response: " + JSON.stringify(response));
    }
  }
}

export interface OrganizationLoginResponse {
  className(): string;
}

export class OrganizationLoginSuccess implements OrganizationLoginResponse {
  static className = "OrganizationLoginSuccess";
  className() {
    return OrganizationLoginSuccess.className;
  }
  constructor(readonly sessionInfo: OrganizationSessionInfoClientSide,
              readonly seriesId: string,
              readonly token: string) {}

  static copy(other: OrganizationLoginSuccess): OrganizationLoginSuccess {
    return new OrganizationLoginSuccess(OrganizationSessionInfoClientSide.copy(other.sessionInfo), other.seriesId, other.token);
  }
}

export class OrganizationAuthenticationOnlySuccess {
  static className = "OrganizationAuthenticationOnlySuccess";
  className() {
    return OrganizationAuthenticationOnlySuccess.className;
  }
  constructor(readonly personId: string,
              readonly firstName: string,
              readonly lastName: string) {}
  static copy(other: OrganizationAuthenticationOnlySuccess): OrganizationAuthenticationOnlySuccess {
    return new OrganizationAuthenticationOnlySuccess(other.personId, other.firstName, other.lastName);
  }
}

export class OrganizationLoginMFATokenRequired implements OrganizationLoginResponse {
  static className = "OrganizationLoginMFATokenRequired";
  className() {
    return OrganizationLoginMFATokenRequired.className;
  }
  constructor(readonly loginAttemptId: string) {}

  static copy(other: OrganizationLoginMFATokenRequired): OrganizationLoginMFATokenRequired {
    return new OrganizationLoginMFATokenRequired(other.loginAttemptId);
  }
}

export class OrganizationLoginFailure implements OrganizationLoginResponse {
  static className = "OrganizationLoginFailure";
  className() {
    return OrganizationLoginFailure.className;
  }
  constructor() {}

  static copy(other: OrganizationLoginFailure): OrganizationLoginFailure {
    return new OrganizationLoginFailure();
  }
}


export class OrganizationLoginTemporaryDisabled implements OrganizationLoginResponse {
  static className = "OrganizationLoginTemporaryDisabled";
  className() {
    return OrganizationLoginTemporaryDisabled.className;
  }
  constructor(readonly minutes: number) {}

  static copy(other: OrganizationLoginTemporaryDisabled): OrganizationLoginTemporaryDisabled {
    return new OrganizationLoginTemporaryDisabled(other.minutes);
  }
}

export class ApplicationLoginResponse {
  constructor(
    readonly success: boolean,
    readonly sessionInfo: Option<ApplicationSessionInfoClientSide>) {}

  static copy(other: ApplicationLoginResponse) {
    return new ApplicationLoginResponse(other.success, Option.copy(other.sessionInfo, ApplicationSessionInfoClientSide.copy));
  }
}

export class BasicAuthenticatedUserInfo {
  constructor(readonly personId: string,
              readonly firstName: string,
              readonly lastName: string) {}

  static copy(other: BasicAuthenticatedUserInfo) {
    return new BasicAuthenticatedUserInfo(other.personId, other.firstName, other.lastName);
  }
}


export class UserVerificationResult {
  constructor(
    readonly status: string, //verified,notVerified or incorrect
    readonly info: Option<BasicAuthenticatedUserInfo>) {}

  static copy(other: UserVerificationResult) {
    return new UserVerificationResult(other.status, Option.copy(other.info));
  }

  isVerified() {
    return this.status == "verified";
  }

  isNotVerified() {
    return this.status == "notVerified";
  }

  isIncorrect() {
    return this.status == "incorrect";
  }
}
