import {
  __,
  LocalDate,
  LocalDateTime,
  LocalTime,
  None,
  Option,
  Some,
  TimezonedLocalDateTime,
  toastr,
  toLocalDateTimeFromTimezoned, toTimezonedLocalDateTimeFromLocal, toTimezonedLocalDateTimeFromUTC
} from "@utils";
import {DepartmentCalendar} from "@shared";

export class OperatingHours {
  constructor(public from:LocalTime,
              public to:LocalTime) {}

  static copy(other: OperatingHours): OperatingHours {
    return new OperatingHours(LocalTime.copy(other.from), LocalTime.copy(other.to));
  }

  asUTC(forDate: LocalDate, timezone: string) {

    const from = toLocalDateTimeFromTimezoned(toTimezonedLocalDateTimeFromLocal(new LocalDateTime(forDate, this.from), timezone)).time;
    const to = toLocalDateTimeFromTimezoned(toTimezonedLocalDateTimeFromLocal(new LocalDateTime(forDate, this.to), timezone)).time;
    return new OperatingHours(from, to);
  }

  isValid(): boolean {
    return this.from.isValid() && this.to.isValid();
  }

  copy(): OperatingHours {
    return OperatingHours.copy(this);
  }

  equals(other: OperatingHours): boolean {
    return this.from.isEqual(other.from) && this.to.isEqual(other.to);
  }

  static empty(): OperatingHours {
    return new OperatingHours(new LocalTime(9, 0, 0, 0), new LocalTime(17, 0, 0, 0)); // default operating hours
  }
}

export class OperatingHoursException {
  constructor(public id: number|null,
              public name:Option<string>,
              public date:LocalDate,
              public hoursMarked: boolean,
              public hours: OperatingHours) {}

  static copy(other: OperatingHoursException): OperatingHoursException {
    return new OperatingHoursException(other.id, Option.copy(other.name), LocalDate.copy(other.date), other.hoursMarked, OperatingHours.copy(other.hours))
  }

}



export function getWeeklyHours(from: LocalDateTime, calendar: DepartmentCalendar, calendarTimezone: string): Option<OperatingHours> {
  const date: Date = new Date(from.asMillis());
  let hours: Option<OperatingHours> = None();
  switch(date.getDay()) {
    case 0: hours = calendar.weekly.sunday; break;
    case 1: hours = calendar.weekly.monday; break;
    case 2: hours = calendar.weekly.tuesday; break;
    case 3: hours = calendar.weekly.wednesday; break;
    case 4: hours = calendar.weekly.thursday; break;
    case 5: hours = calendar.weekly.friday; break;
    case 6: hours = calendar.weekly.saturday; break;
    default: throw new Error("Incorrect day of week");
  }
  return hours.map(h => h.asUTC(from.date, calendarTimezone));
}

export function getHours(from: LocalDateTime, calendar: DepartmentCalendar, calendarTimezone: string): Option<OperatingHours> {
  const exception: OperatingHoursException|undefined = __(calendar.exceptions).find(e => e.date.isEqual(from.date)).getOrUndefined();
  if (exception !== undefined) {
    if (exception.hoursMarked) {
      return Some(exception.hours.asUTC(from.date, calendarTimezone));
    } else {
      return None();
    }
  } else {
    return getWeeklyHours(from, calendar, calendarTimezone);
  }
}

export function nextInterval(start: LocalDateTime, calendar: DepartmentCalendar, calendarTimezone: string): [LocalDateTime, LocalDateTime] {
  const hours = getHours(start, calendar, calendarTimezone);
  if (hours.isDefined() && start.time.isBefore(hours.get().to)) {
    if (hours.get().from.isBefore(start.time)) {
      return [start, new LocalDateTime(start.date, hours.get().to)];
    } else {
      return [new LocalDateTime(start.date, hours.get().from), new LocalDateTime(start.date, hours.get().to)];
    }
  } else {
    const dayMillis = 24 * 60 * 60 * 1000;
    return nextInterval(new LocalDateTime(start.plusMillis(dayMillis).date, LocalTime.MIDNIGHT), calendar, calendarTimezone);
  }
}

export function computeTimeByCalendar(from: LocalDateTime, to: LocalDateTime, calendar: DepartmentCalendar, calendarTimezone: string): number {
  if (from.isAfter(to)) {
    return -computeTimeByCalendar(to, from, calendar, calendarTimezone);
  }

  const difference = to.asMillis() - from.asMillis();
  if(difference > 2592000000) {// 2592000000 - milliseconds in a month
    return difference * 0.238; // 23.8% estimated work time in a week
  } else {
    let start = from;
    let total = 0;

    while (start.isBefore(to)) {
      const next: [LocalDateTime, LocalDateTime] = nextInterval(start, calendar, calendarTimezone);
      if (to.isAfter(next[0])) {
        const end = to.isBefore(next[1]) ? to : next[1];
        total += end.differenceMillis(next[0]);
      }
      start = next[1];
    }
    return total;
  }
}

