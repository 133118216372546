import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {$$} from "@utils";

@Directive({
  selector: '[my-datum]'
})
export class DatumDirective implements OnInit {
  private _datum?: any;

  @Input("my-datum") set datum(datum: any) {
    this._datum = datum;
    this.setDatum();
  };

  constructor(readonly elementRef: ElementRef) {
  }

  ngOnInit() {
    if(this._datum !== undefined) {
      this.setDatum();
    }
  }

  private setDatum() {
    $$(this.elementRef).datum(this._datum);
  }
}
