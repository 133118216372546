import {__, mySetTimeout, restUrl, toastr, UploadFormFileResponse} from "@utils";
import {i18n} from "../I18n";

import { FineUploader } from 'fine-uploader';
import {FineUploaderBasic, PromiseOptions} from "fine-uploader/lib/core";

export class SimpleFileUploaderController<RESPONSE> {

  fineUploaderBasicInstance!: any;

   constructor(
      public uploadFileUrl: string,
      public uploadButtonSelection: HTMLElement,
      public sessionToken: string,
      public allowedExtensions: Array<string>,
      public maxFileSize: number,
      public chunking: boolean,
      public autoUpload: boolean,
      public onSubmitted: (file: File) => void,
      public onComplete: (id: number, name: string, responseJSON: RESPONSE) => void,
      public onUpload: (id: number, name: string, instance: any) => void,
      public onValidate: (file: {name: string, size: number}) => boolean,
      public onError: (id: any, name: any, reason: any, request: any) => void,
      public onProgress: (id: number, name: string, uploadedBytes: number, totalBytes: number) => void,
  ){
      //https://fineuploader.com/
     this.init();
   }

  private init() {

    mySetTimeout(() => {
      let input = <HTMLElement>this.uploadButtonSelection.querySelector("input");
      if (input !== null) {
        input.setAttribute("title", "");
      }

      /*
      * Prevent FineUploader from setting button "direction" property to "ltr"
      *
      * FineUploader sets the direction to "ltr" for InternetExplorer compatibility.
      * FineUploader button.js code fragment:
          // Make button suitable container for input
          qq(options.element).css({
              position: "relative",
              overflow: "hidden",
              // Make sure browse button is in the right side in Internet Explorer
              direction: "ltr"
          });
      * */
      (<HTMLElement>this.uploadButtonSelection).style.direction = "unset";
    }, 100);


    this.fineUploaderBasicInstance = new FineUploaderBasic({
      autoUpload: this.autoUpload,
      button: this.uploadButtonSelection,
      debug: false,
      maxConnections: 3, // default is 3
      //folders: false, // TODO NTH
      //multiple: false, //TODO NTH
      chunking: {
        enabled: this.chunking,
        partSize: 1024 * 1024
      },
      messages: {},
      request: {
        endpoint: restUrl(this.uploadFileUrl),
        params: {
        }, // params to send with request
        paramsInBody: false, // otherwise in url
        customHeaders: {
          'Authorization': this.sessionToken
        }, // add authorization headers
      },
      cors: {
        expected: true,
        sendCredentials: true
      },
      validation: {
        allowedExtensions: []
      },
      callbacks: {
        onValidate: (<any>((file: any): boolean => {
          const extensionIsValid = this.allowedExtensions.length > 0 && !__(this.allowedExtensions).exists(e => file.name.toLocaleLowerCase().indexOf("."+e.toLocaleLowerCase()) + 1 === file.name.length - e.length);
          if(extensionIsValid) {
            if(file.name.indexOf(".") > 0) {
              toastr.error(i18n("fileUploader_wrong_type_msg", {filetype: file.name.substring(file.name.lastIndexOf(".") + 1), allowedFiles: this.allowedExtensions.join(", ")}));
            } else {
              toastr.error(i18n("fileUploader_unknown_type_msg", {allowedFiles: this.allowedExtensions.join(", ")}));
            }
            return false;
          } else if(file.size == 0) {
            toastr.error(i18n("fileUploader_empty_file"));
            return false;
          } else if(file.size > this.maxFileSize) {
            toastr.error(i18n("fileUploader_too_large_file", {size: this.maxFileSize}));// TODO Format formatter.prettySize(appConfig.config.maxFileSize)}));
            return false;
          } else {
            return this.onValidate(file);
          }
        })),
        onSubmitted: (id, name) => {
          try {
            this.onSubmitted(this.fineUploaderBasicInstance.getFile(id));
          } catch(e) {
            console.error(e);
            throw e;
          }
        },
        onUpload: (id: number, fileName: string) => {
          if(this.onUpload){
            try {
              this.onUpload(id, fileName, this.fineUploaderBasicInstance)
            } catch(e) {
              console.error(e);
              throw e;
            }
          }
        },
        onComplete: (id: number, name: string, responseJSON: RESPONSE) => {
          if(this.onComplete) {
            try {
              if((<any>responseJSON).hasOwnProperty("success") && (<any>responseJSON).success === false) {
                // it is not a success and will be handled by onError
              } else {
                this.onComplete(id, name, responseJSON)
              }
            } catch(e) {
              console.error(e);
              throw e;
            }
          }
        },
        onError: (id: number, name: string, reason: string, request: XMLHttpRequest) => {
          if(request.status !== 200 || reason.length > 0) {
            if (this.onError) {
              try {
                this.onError(id, name, reason, request)
              } catch(e) {
                console.error(e);
                throw e;
              }
            } else {
              if(request.status == 401) {
                toastr.error(i18n("fileUploader_unauthorized"));
              } else {
                toastr.error(reason);
              }
            }
          }
        },
        onProgress: (id: number, name: string, uploadedBytes: number, totalBytes: number) => {
          if(this.onProgress) {
            this.onProgress(id, name, uploadedBytes, totalBytes)
          }
        }
      },
    });
  }

  uploadStoredFiles() {
     this.fineUploaderBasicInstance.uploadStoredFiles();
  }

  cancelUpload(uploadId: number) {
    this.fineUploaderBasicInstance.cancel(uploadId);
  }

}
