<div class="tabs theme-tabs" *ngIf="otherModesAllowed">
  <button class="theme-tab" [class.theme-active]="assignedMode" (click)="changeToAssignedMode()">{{'flow_persons_assigned_to_role' | i18n}}</button>
  <button class="theme-tab" [class.theme-active]="allMode" (click)="changeToAllMode()">{{'flow_persons_all' | i18n}}</button>
</div>

<div class="theme-drop-menu-filter">
  <i class="mi-search"></i>
  <input class="query" [(ngModel)]="query" (ngModelChange)="queryChanged()" [focus-on-show]="true"
         [placeholder]="'tasks_search_person' | i18n"
         (keydown)="keyPressed($event)"/>
</div>

<hr class="theme-drop-menu-separator" />

<div class="theme-drop-menu-entry" *ngIf="foundPersons.length == 0">
  {{"tasks_person_assigned_no_assignable_person" | i18n}}
</div>

<div class="searchResults">
  <div class="searchResult theme-drop-menu-entry" *ngFor="let person of foundPersons; let index = index" (click)="personSelected(person)"
       (pointerdown)="setAnyPersonPressed(true)" (pointerup)="setAnyPersonPressed(false)"
       [class.active]="index === activeSuggestionIndex"
        [class.selected]="person.selected">
    <div class="nodeIcon">
      <my-avatar [person]="person.basicInfo" *ngIf="person.personIdUnwrapped().asPersonId().id.id !== ''" [smallIcon]="true"/>
      <i class="noOneIcon mi-user" *ngIf="person.personIdUnwrapped().asPersonId().id.id === ''"></i>
    </div>
    <div class="nodeDescription">
      {{person.simpleName()}}
    </div>
    <button *ngIf="person.selected" class="theme-button-icon-action-flat" (click)="personClicked(person)"><i class="mi-check"></i></button>
  </div>
</div>
