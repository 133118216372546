import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewContainerRef
} from "@angular/core";
import {TooltipController} from "./tooltip.controller";
import {toastr} from "@utils";

@Component({
  selector: 'my-tooltip',
  templateUrl: './tooltip.component.html',
})
export class TooltipComponent implements OnDestroy, OnChanges, AfterViewInit {

  private controller!: TooltipController;

  @Input("cssClass") cssClass: string = "";
  @Input() position: "top" | "left" | "bottom" | "right" = "top";
  @Input() delay?: number = undefined;
  @Input() enabled: boolean = true;
  @Input() error: boolean = false;
  @Input() alwaysVisible: boolean = false;
  @Input() mobileVisibility: boolean = true;

  @Input() skipRootContent: boolean = false; // this is needed e.g. when tooltip is used from within svg
  @Input() contentAlign: "left" | "center" | "right" = "center";

  constructor(private readonly viewContainerRef: ViewContainerRef) {
  }

  private getContentToDisplay = () => {
    if(this.skipRootContent) {
      return Array.from(Array.from((<HTMLElement>this.viewContainerRef.element.nativeElement).childNodes)[0].childNodes);
    } else {
      return Array.from((<HTMLElement>this.viewContainerRef.element.nativeElement).childNodes);
    }
  }

  ngAfterViewInit(): void {
    this.controller = new TooltipController(<HTMLElement>(<HTMLElement>this.viewContainerRef.element.nativeElement).parentElement, this.getContentToDisplay, () => this.cssClass, this.onNodesReturned);

    this.controller.init();
    this.controller.updateParams(this.position, this.delay, this.mobileVisibility, this.enabled, this.error, this.alwaysVisible, this.contentAlign);
  }

  ngOnDestroy(): void {
    this.controller.destroy();
  }

  ngOnChanges(): void {
    if(this.controller) {
      this.controller.updateParams(this.position, this.delay, this.mobileVisibility, this.enabled, this.error, this.alwaysVisible, this.contentAlign);
    }
  }

  private onNodesReturned = (nodes: Array<Node>) => {
    if(this.skipRootContent) {
      const element = Array.from((<HTMLElement>this.viewContainerRef.element.nativeElement).childNodes)[0];
      nodes.forEach(n => {
        element.appendChild(n);
      });
    } else {
      nodes.forEach(n => {
        this.viewContainerRef.element.nativeElement.appendChild(n);
      });
    }
  }

}
