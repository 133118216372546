import {AggregateId, AggregateVersion, ApplicationId, FileUri, FunctionsId, GroupId, i18n, Language, LocalDateTime, None, Option, OrganizationId, OrganizationNodeId, OrganizationNodeType, organizationsLogoDownloadUrl, PersonId, RemoteOrganizationId, ReportId, ScreenId, Some, Typed} from "@utils";
import {BasicPersonInfo, PersonInfo} from "./PersonModel";
import {BasicDepartmentInfo, DepartmentInfo} from "./DepartmentModel";
import {BasicGroupInfo, GroupBasicInfoWithApplicationInfo} from "./GroupModel";
import {BasicRemoteOrganizationInfo} from "../remote-organization/RemoteOrganizationModel";
import {ReportSummary, ReportSummaryWithApplicationInfo} from "../report/ReportModel";
import {FunctionsSummary, FunctionsSummaryWithApplicationInfo} from "../functions/FunctionsModel";
import {ScreenSummary, ScreenSummaryWithApplicationInfo} from "../screen/ScreenModel";
import {BasicProcessInfo, BasicProcessInfoWithApplicationInfo} from "../process/ProcessModel";
import {ApplicationSummary} from "../application/application-model";

export class OrganizationInfo {


  constructor(readonly id: AggregateId,
              public version: number,
              public name: string,
              public urlAlias: string,
              public industry: string,
              readonly contactInfo: OrganizationContactInfo,
              public logo: Option<FileUri>,
              readonly creationDate: LocalDateTime,
              readonly rootNodeId: Option<AggregateId>,
              public active: boolean,
              readonly settings: OrganizationSettings,
              readonly ownerId: PersonId,
              readonly distributor: string,
              readonly accentColor: Option<string>) {
  }

  static copy(other: OrganizationInfo) {
    return new OrganizationInfo(other.id,
      other.version,
      other.name,
      other.urlAlias,
      other.industry,
      OrganizationContactInfo.copy(other.contactInfo),
      Option.copy(other.logo).map(FileUri.copy),
      LocalDateTime.copy(other.creationDate),
      Option.copy(other.rootNodeId),
      other.active,
      OrganizationSettings.copy(other.settings),
      other.ownerId,
      other.distributor,
      Option.copy(other.accentColor))
  }

}

export class OrganizationContactInfo {
  constructor(public address: string, public phone: string, public fax: string, public email: string, public website: string) {}

  static copy(other: OrganizationContactInfo) {
    return new OrganizationContactInfo(other.address, other.phone, other.fax, other.email, other.website);
  }

  isEmpty() {
    return this.address === "" && this.phone === "" && this.fax === "" && this.email === "" && this.website === ""
  }
}

export class OrganizationSettings {
  constructor(public language: Language, public timezone: string, public customTheme: Option<string>, public showInfoOnLoginPage: boolean, public description: string) {}

  static copy(other: OrganizationSettings) {
    return new OrganizationSettings(Language.copy(other.language), other.timezone, Option.copy(other.customTheme), other.showInfoOnLoginPage, other.description ? other.description : "");
  }
}

export interface OrganizationNode {
    id: AggregateId;
    organizationId: AggregateId;

    ancestorsIds: OrganizationNodeId[];
    parentId: Option<OrganizationNodeId>;

    groups: GroupId[];
    children: OrganizationNodeId[];
    className(): string;

    isPerson(): boolean;
    isDepartment(): boolean;
    isOrganization(): boolean;
    isGroup(): boolean;

    asPerson(): PersonNode;
    asGroup(): GroupNode;
    asDepartment(): DepartmentNode;

    deleted: boolean;
  }

export class PersonNode implements OrganizationNode {
  static className = "PersonNode";

  deleted: boolean;

  isPerson() {
    return true;
  }

  isDepartment() {
    return false;
  }

  isOrganization() {
    return false;
  }

  isGroup() {
    return false;
  }

  className() {
    return PersonNode.className;
  }

  asPerson(): PersonNode {
    return this;
  }

  asGroup(): GroupNode {
    throw new Error("Cannot get Person as Group");
  }

  asDepartment(): DepartmentNode {
    throw new Error("Cannot get Person as Department");
  }


  constructor(readonly id: AggregateId,
              readonly organizationId: AggregateId,
              readonly ancestorsIds: OrganizationNodeId[],
              readonly supervisorId: Option<OrganizationNodeId>,
              readonly children: OrganizationNodeId[],
              readonly parentId: Option<OrganizationNodeId>,
              readonly groups: GroupId[],
              deleted: boolean) {
    this.deleted = deleted;
  }

  static copy(other: PersonNode): PersonNode {
    return new PersonNode(other.id, other.organizationId, other.ancestorsIds.slice(), Option.copy(other.supervisorId),
      other.children.slice(), Option.copy(other.parentId), other.groups.slice(), other.deleted);
  }

}


export class DepartmentNode implements OrganizationNode {
  static className = "DepartmentNode";

  deleted: boolean;

  className() {
    return DepartmentNode.className;
  }

  isPerson() {
    return false;
  }

  isDepartment() {
    return this.parentId.isDefined();
  }

  isOrganization() {
    return this.parentId.isEmpty();
  }

  isGroup() {
    return false;
  }

  asPerson(): PersonNode {
    throw new Error("Cannot get Department as Person");
  }

  asGroup(): GroupNode {
    throw new Error("Cannot get Department as Group");
  }

  asDepartment(): DepartmentNode {
    return this;
  }

  constructor(readonly id: AggregateId,
              public organizationId: AggregateId,
              public ancestorsIds: OrganizationNodeId[],
              public children: OrganizationNodeId[],
              public parentId: Option<OrganizationNodeId>,
              public groups: GroupId[],
              public directorId: Option<AggregateId>,
              deleted: boolean) {
      this.deleted = deleted;
  }

  static copy(other: DepartmentNode): DepartmentNode {
    return new DepartmentNode(other.id, other.organizationId, other.ancestorsIds.slice(), other.children.slice(),
      Option.copy(other.parentId), other.groups.slice(), Option.copy(other.directorId), other.deleted);
  }
}

export class GroupNode implements OrganizationNode {

  static className = "GroupNode";

  deleted: boolean;
  ancestorsIds: OrganizationNodeId[];
  parentId: Option<OrganizationNodeId>;

  className() {
    return GroupNode.className;
  }

  isPerson() {
    return false;
  }

  isDepartment() {
    return false;
  }

  isOrganization() {
    return false;
  }

  isGroup() {
    return true;
  }

  asPerson(): PersonNode {
    throw new Error("Cannot get Group as Person");
  }

  asGroup(): GroupNode {
    return this;
  }

  asDepartment(): DepartmentNode {
    throw new Error("Cannot get Group as Department");
  }


  constructor(readonly id: AggregateId,
              readonly organizationId: AggregateId,
              readonly children: OrganizationNodeId[],
              readonly groups: GroupId[],
              deleted: boolean) {
    this.deleted = deleted;
    this.parentId = None();
    this.ancestorsIds = [];
  }

  static copy(other: GroupNode): GroupNode {
    return new GroupNode(other.id, other.organizationId, other.children.slice(), other.groups.slice(), other.deleted);
  }
}

export class BasicOrganizationNodeInfo {

  organizationNodeId: OrganizationNodeId;
  aggregateId: AggregateId;

  constructor(readonly person: Option<BasicPersonInfo>,
              readonly department: Option<BasicDepartmentInfo>,
              readonly group: Option<GroupBasicInfoWithApplicationInfo>,
              readonly remoteOrganization: Option<BasicRemoteOrganizationInfo>,
              readonly process: Option<BasicProcessInfoWithApplicationInfo>,
              readonly report: Option<ReportSummaryWithApplicationInfo>,
              readonly application: Option<ApplicationSummary>,
              readonly screen: Option<ScreenSummaryWithApplicationInfo>,
              readonly functions: Option<FunctionsSummaryWithApplicationInfo>,
              readonly deleted: boolean) {

    if(this.isPerson()) {
      this.aggregateId = this.person.get().idUnwrapped().asPersonId().id;
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.person);
    } else if(this.isDepartment()) {
      this.aggregateId = this.department.get().id;
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.department);
    } else if(this.isGroup()) {
      this.aggregateId = this.group.get().group.id;
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.group);
    } else if(this.isRemoteOrganization()) {
      this.aggregateId = this.remoteOrganization.get().id.toAggregateId();
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.remoteOrganization);
    } else if(this.isProcess()) {
      this.aggregateId = this.process.get().process.id;
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.process);
    } else if(this.isReport()) {
      this.aggregateId = this.report.get().report.id.id;
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.report);
    } else if(this.isApplication()) {
      this.aggregateId = this.application.get().id.toAggregateId();
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.application);
    } else if(this.isScreen()) {
      this.aggregateId = this.screen.get().screen.id.toAggregateId();
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.screen);
    } else if(this.isFunctions()) {
      this.aggregateId = this.functions.get().functions.id.toAggregateId();
      this.organizationNodeId = new OrganizationNodeId(this.aggregateId, OrganizationNodeType.functions);
    } else {
      throw new Error("Illegal node info state");
    }

    if(!this.deleted) {
      if(this.person.isDefined()) {
        this.deleted = this.person.exists(p => p.deleted);
      } else if(this.department.isDefined()) {
        this.deleted = this.department.exists(p => p.deleted);
      } else if(this.group.isDefined()) {
        this.deleted = this.group.exists(g => g.group.deleted);
      } else if(this.remoteOrganization.isDefined()) {
        this.deleted = this.remoteOrganization.exists(p => p.deleted);
      } else if(this.process.isDefined()) {
        this.deleted = this.process.exists(p => p.process.deleted);
      } else if(this.report.isDefined()) {
        this.deleted = false;
      } else if(this.application.isDefined()) {
        this.deleted = false;
      } else if(this.screen.isDefined()) {
        this.deleted = false;
      } else if(this.functions.isDefined()) {
        this.deleted = false;
      } else {
        throw new Error("Illegal node info state");
      }
    }

  }


  contextApplicationId(): Option<ApplicationId> {
    if(this.person.isDefined()) {
      return None();
    } else if(this.department.isDefined()) {
      return None();
    } else if(this.group.isDefined()) {
      return this.group.get().applicationId;
    } else if(this.remoteOrganization.isDefined()) {
      return None();
    } else if(this.process.isDefined()) {
      return this.process.get().applicationId;
    } else if(this.report.isDefined()) {
      return this.report.get().applicationId;
    } else if(this.application.isDefined()) {
      return None();
    } else if(this.screen.isDefined()) {
      return Some(this.screen.get().applicationId);
    } else if(this.functions.isDefined()) {
      return this.functions.get().applicationId;
    } else {
      throw new Error("Illegal node info state");
    }
  }

  private static notExistingInfo(nodeId: OrganizationNodeId, label: string) {
    switch (nodeId.nodeType.name) {
      case OrganizationNodeType.person.name:return new BasicOrganizationNodeInfo(Some(new BasicPersonInfo(Typed.of(PersonId.of(nodeId.id)), AggregateVersion.ZERO, OrganizationId.of(""), None(), label + " Person " + nodeId.id.id, "", "", "", None(), true)), None(), None(), None(), None(), None(), None(), None(), None(), true);
      case OrganizationNodeType.department.name: return new BasicOrganizationNodeInfo(None(), Some(new BasicDepartmentInfo(nodeId.id, AggregateVersion.ZERO, label + " Department " + nodeId.id.id, None(), false, true)), None(), None(), None(), None(), None(), None(), None(), true);
      case OrganizationNodeType.group.name: return new BasicOrganizationNodeInfo(None(), None(), Some(new GroupBasicInfoWithApplicationInfo(None(), "", new BasicGroupInfo(nodeId.id, AggregateVersion.ZERO, None(), label + " Group " + nodeId.id.id, "", "Group " + nodeId.id.id, true))), None(),None(), None(), None(), None(), None(), true);
      case OrganizationNodeType.remoteOrganization.name: return new BasicOrganizationNodeInfo(None(), None(), None(), Some(new BasicRemoteOrganizationInfo(RemoteOrganizationId.of(nodeId.id.id), label + " Organization " + nodeId.id.id, true)), None(), None(), None(), None(), None(), true);
      case OrganizationNodeType.process.name: return new BasicOrganizationNodeInfo(None(), None(), None(), None(), Some(new BasicProcessInfoWithApplicationInfo(None(), "", new BasicProcessInfo(nodeId.id, None(), label + " Process " + nodeId.id.id, true))), None(), None(), None(), None(), true);
      case OrganizationNodeType.report.name: return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), Some(new ReportSummaryWithApplicationInfo(None(), "", new ReportSummary(new ReportId(nodeId.id), AggregateVersion.ZERO, None(), label + " Report " + nodeId.id.id, false))), None(), None(), None(), true);
      case OrganizationNodeType.application.name: return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), Some(new ApplicationSummary(ApplicationId.of(nodeId.id), AggregateVersion.ZERO, label + " application " + nodeId.id.id, "", 0, PersonId.of(new AggregateId("")), "", 0, "", false)), None(), None(), true);
      case OrganizationNodeType.screen.name: return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), None(), Some(new ScreenSummaryWithApplicationInfo(ApplicationId.EMPTY, "", new ScreenSummary(new ScreenId(nodeId.id.id), AggregateVersion.ZERO,OrganizationId.of(""), label + " screen " + nodeId.id.id, ApplicationId.EMPTY, label + " screen " + nodeId.id.id))), None(), true);
      case OrganizationNodeType.functions.name: return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), None(), None(), Some(new FunctionsSummaryWithApplicationInfo(None(), "", new FunctionsSummary(new FunctionsId(nodeId.id.id), label + " functions " + nodeId.id.id, true))), true);
      default: throw new Error("Unsupported organization node type ["+nodeId.nodeType.name+"]");
    }
  }

  static deletedInfo(nodeId: OrganizationNodeId) {
    return BasicOrganizationNodeInfo.notExistingInfo(nodeId, "Deleted");
  }

  static unavailableInfo(nodeId: OrganizationNodeId) {
    return BasicOrganizationNodeInfo.notExistingInfo(nodeId, "Unavailable");
  }

  static fromPerson(person: BasicPersonInfo) {
    return new BasicOrganizationNodeInfo(Some(person), None(), None(), None(), None(), None(), None(), None(), None(), person.deleted);
  }

  static fromDepartment(department: BasicDepartmentInfo) {
    return new BasicOrganizationNodeInfo(None(), Some(BasicDepartmentInfo.copy(department)), None(), None(), None(), None(), None(), None(), None(), department.deleted);
  }

  static fromGroup(group: GroupBasicInfoWithApplicationInfo) {
    return new BasicOrganizationNodeInfo(None(), None(), Some(GroupBasicInfoWithApplicationInfo.copy(group)), None(), None(), None(), None(), None(), None(), group.group.deleted);
  }

  static fromRemoteOrganization(organization: BasicRemoteOrganizationInfo) {
    return new BasicOrganizationNodeInfo(None(), None(), None(), Some(BasicRemoteOrganizationInfo.copy(organization)), None(), None(), None(), None(), None(), organization.deleted);
  }

  static fromProcess(process: BasicProcessInfo, applicationId: Option<ApplicationId> = None(), applicationName: string = "") {
    return new BasicOrganizationNodeInfo(None(), None(), None(), None(), Some(new BasicProcessInfoWithApplicationInfo(applicationId, applicationName, BasicProcessInfo.copy(process))), None(), None(), None(), None(), process.deleted);
  }

  static fromReport(report: ReportSummary, applicationId: Option<ApplicationId> = None(), applicationName: string = "") {
    return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), Some(new ReportSummaryWithApplicationInfo(applicationId, applicationName, ReportSummary.copy(report))), None(), None(), None(), false);
  }

  static fromApplication(application: ApplicationSummary) {
    return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), Some(ApplicationSummary.copy(application)), None(), None(), false);
  }

  static fromScreen(screen: ScreenSummary, applicationName: string = "") {
    return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), None(), Some(new ScreenSummaryWithApplicationInfo(screen.applicationId, applicationName, ScreenSummary.copy(screen))), None(), false);
  }

  static fromFunctions(functionsSummary: FunctionsSummary, applicationId: Option<ApplicationId> = None(), applicationName: string = "") {
    return new BasicOrganizationNodeInfo(None(), None(), None(), None(), None(), None(), None(), None(), Some(new FunctionsSummaryWithApplicationInfo(applicationId, applicationName, FunctionsSummary.copy(functionsSummary))), false);
  }

  static createAnonymous() {
    return BasicOrganizationNodeInfo.fromPerson(new BasicPersonInfo(Typed.of(PersonId.ANONYMOUS), AggregateVersion.ZERO, OrganizationId.of(""), None(), i18n("common_anonymous_users"), "", "", "", None(), false));
  }

  simpleName() {
    if(this.isPerson()) {
      return this.person.get().simpleName();
    } else if(this.isDepartment()) {
      return this.department.get().name;
    } else if(this.isGroup()) {
      return this.group.get().group.name;
    } else if(this.isProcess()) {
      return this.process.get().process.name;
    } else if(this.isRemoteOrganization()) {
      return this.remoteOrganization.get().name;
    } else if(this.isReport()) {
      return this.report.get().report.name;
    } else if(this.isApplication()) {
      return this.application.get().name;
    } else if(this.isScreen()) {
      return this.screen.get().screen.name;
    } else if(this.isFunctions()) {
      return this.functions.get().functions.name;
    } else {
      throw new Error("Incorrect state");
    }
  }

  asPerson() {
    return this.person.get();
  }


  asDepartment() {
    return this.department.get();
  }

  asGroup() {
    return this.group.get();
  }

  asRemoteOrganization() {
    return this.remoteOrganization.get();
  }

  asProcess() {
    return this.process.get();
  }

  asReport() {
    return this.report.get();
  }

  asApplication() {
    return this.application.get();
  }

  asScreen() {
    return this.screen.get();
  }

  asFunctions() {
    return this.functions.get();
  }

  isPerson() {
    return this.person.isDefined();
  }

  isDepartment() {
    return this.department.isDefined();
  }

  isGroup() {
    return this.group.isDefined();
  }

  isRemoteOrganization() {
    return this.remoteOrganization.isDefined();
  }

  isProcess() {
    return this.process.isDefined();
  }

  isReport() {
    return this.report.isDefined();
  }

  isApplication() {
    return this.application.isDefined();
  }

  isScreen() {
    return this.screen.isDefined();
  }


  isFunctions() {
    return this.functions.isDefined();
  }

  sortValue(currentUser: PersonId) {
    if(this.person.isDefined() && this.person.get().idUnwrapped().asPersonId().id.id == currentUser.id.id) {
      return "01|";
    } else if(this.person.isDefined() && this.person.get().idUnwrapped().asPersonId().id.id == PersonId.ANONYMOUS.id.id) {
      return "02|";
    } else if(this.person.isDefined()) {
      return "03|" + this.person.get().lastName.toLowerCase() + " "+this.person.get().firstName.toLowerCase();
    } else if(this.group.isDefined()) {
      return "04|" + this.simpleName().toLowerCase();
    } else if(this.department.isDefined() || this.remoteOrganization.isDefined()) {
      return "05|" + this.simpleName().toLowerCase();
    } else if(this.process.isDefined()) {
      return "06|" + this.simpleName().toLowerCase();
    } else if(this.report.isDefined()) {
      return "07|" + this.simpleName().toLowerCase();
    } else if(this.application.isDefined()) {
      return "08|" + this.simpleName().toLowerCase();
    } else if(this.screen.isDefined()) {
      return "09|" + this.simpleName().toLowerCase();
    } else if(this.functions.isDefined()) {
      return "10|" + this.simpleName().toLowerCase();
    } else {
      throw new Error("Not supported type");
    }
  }

  static copy(other: BasicOrganizationNodeInfo) {
    return new BasicOrganizationNodeInfo(
      Option.copy(other.person).map(BasicPersonInfo.copy),
      Option.copy(other.department).map(BasicDepartmentInfo.copy),
      Option.copy(other.group).map(GroupBasicInfoWithApplicationInfo.copy),
      Option.copy(other.remoteOrganization).map(BasicRemoteOrganizationInfo.copy),
      Option.copy(other.process).map(BasicProcessInfoWithApplicationInfo.copy),
      Option.copy(other.report).map(ReportSummaryWithApplicationInfo.copy),
      Option.copy(other.application).map(ApplicationSummary.copy),
      Option.copy(other.screen).map(ScreenSummaryWithApplicationInfo.copy),
      Option.copy(other.functions).map(FunctionsSummaryWithApplicationInfo.copy),
      other.deleted);
  }
}

export class OrganizationStructureNodeInfo {

  constructor(readonly personInfo: Option<PersonInfo>,
              readonly departmentInfo: Option<DepartmentInfo>) {}


  static copy(other: OrganizationStructureNodeInfo) {
    return new OrganizationStructureNodeInfo(
      Option.copy(other.personInfo).map(PersonInfo.copy),
      Option.copy(other.departmentInfo).map(DepartmentInfo.copy));
  }
}

