import {Injectable} from "@angular/core";
import {SwUpdate, VersionEvent} from "@angular/service-worker";
import {Subject} from "rxjs";
import {mySetTimeout} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {

  private detectionTimestamp = 0;
  private willUpdate = false;
  private willUpdateSubject: Subject<boolean> = new Subject();

  private static MINIMUM_RELOAD_MILLIS = 700; // to present readable info to user

  constructor(private readonly updates: SwUpdate) {
    setTimeout(() => {
      updates.checkForUpdate();
    }, 500)
  }

  getWillUpdateObservable() {
    return this.willUpdateSubject.asObservable();
  }

  init() {
    this.updates.versionUpdates.subscribe((event) => {
      this.showAppUpdateAlert(event);
    });
  }

  private showAppUpdateAlert(event: VersionEvent) {
    console.log("Version Event", event);
    if(event.type === "VERSION_DETECTED") {
      this.detectionTimestamp = new Date().getTime();
      this.willUpdate = true;
      this.willUpdateSubject.next(this.willUpdate);
    } else if(event.type === "VERSION_READY") {
      this.doAppUpdate();
    }


  }

  private doAppUpdate() {
    this.updates.activateUpdate().then(() => {

      const now = new Date().getTime();
      if(now - this.detectionTimestamp < AppUpdateService.MINIMUM_RELOAD_MILLIS) {
        mySetTimeout(() => {
          document.location.reload();
        }, AppUpdateService.MINIMUM_RELOAD_MILLIS - (now - this.detectionTimestamp));
      } else {
        document.location.reload();
      }
    });
  }
}
