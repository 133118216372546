import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {
  $$,
  $$Element,
  getREMSize,
  myRequestAnimationFrameNoAngular,
  mySetIntervalNoAngular,
  mySetTimeoutNoAngular, toastr
} from "@utils";

interface ResponsiveClass {
  minWidth: number;
  maxWidth: number;
  className: string;
}


@Directive({
  selector: '[responsive-class],[responsive-mobile-class],(responsive-mobile)',
})
export class ResponsiveDirective implements OnInit, OnDestroy {

  private _myResponsiveParams: Array<number|string> = [];
  private $$element: $$Element;

  private lastWidth: number = -1;
  private classes: Array<ResponsiveClass> = [];

  private mobile: boolean = false;
  private _responsiveMobileClass?: string;

  constructor(private elementRef: ElementRef) {
    this.$$element = $$(this.elementRef.nativeElement);
  }

  @Input("responsive-class") set responsiveClass(t: Array<number|string>) {
    this._myResponsiveParams = t;
    this.initData();
  };

  @Input("responsive-mobile-class") set responsiveMobileClass(clazz: string|undefined) {
    this._responsiveMobileClass = clazz;
  }

  @Output("responsive-mobile") responsiveMobileChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private initData() {

    let lastNumber: number|null = null;

    this.classes = [];

    this._myResponsiveParams.forEach((current: number|string) => {
      if(typeof current === "number") {
        if(this.classes.length > 0) {
          this.classes[this.classes.length - 1].maxWidth = current;
        }
        lastNumber = current;
      } else {
        this.classes.push({
          minWidth: lastNumber === null ? 0 : lastNumber,
          maxWidth: 1000000,
          className: current
        });
      }
    });

    this.update();
    myRequestAnimationFrameNoAngular(this.update);
    mySetTimeoutNoAngular(this.update);
    mySetTimeoutNoAngular(this.update, 100);
  }


  readonly update = () => {
    const width = this.$$element.width() / getREMSize();

    if(width !== this.lastWidth) {

      const mobile = width < 48;

      this.classes.forEach((current: ResponsiveClass) => {
        this.$$element.toggleClass(current.className, width >= current.minWidth && width < current.maxWidth);
      });

      if(mobile !== this.mobile) {
        this.mobile = mobile;
        this.responsiveMobileChange.emit(mobile);
      }

      if(this._responsiveMobileClass) {
        this.$$element.toggleClass(this._responsiveMobileClass, mobile);
      }

      this.lastWidth = width;
    }


  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.update);
  }

  ngOnInit(): void {
    window.addEventListener("resize", this.update);
    this.update();
    myRequestAnimationFrameNoAngular(() => {
      this.update();
    })
  }
}
