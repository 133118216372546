<ng-container *ngIf="!popupMode">
  <hr class="theme-content-horizontal-separator-small-margin" />

  <div class="persons" *ngIf="assignedPersons.length > 0">
    <div class="person" *ngFor="let person of assignedPersons">
      <my-person-summary [personId]="person"></my-person-summary>
      <button *ngIf="enabled && canChangeAssignee" (click)="unassignPerson(person)" class="removePerson">
        <i class="mi-delete"></i>
      </button>
    </div>
  </div>

  <button *ngIf="enabled && canAssignSelf && !assignedToCurrentUser && assignedPersons.length === 0" (click)="assignSelf()" class="theme-button-confirm-with-icon-small">
    <i class="mi-user-add"></i>{{'dashboard_Form_AssignToMe' | i18n}}
  </button>

  <hr class="theme-content-horizontal-separator-small-margin" />

  <div #DropMenuAnchor>
   <button *ngIf="enabled && canChangeAssignee && personsAvailable" class="theme-button-link-icon-small" (click)="toggleAddPersonPopup($event)">
     <i class="mi-user-add"></i>
     <ng-container *ngIf="assignedPersons.length === 0 && assigneeLimitNotReached()">{{'tasks_person_assigned_assign_another' | i18n}}</ng-container>
     <ng-container *ngIf="assignedPersons.length === 1 && assigneeLimit === 1">{{'tasks_person_assigned_another_assign' | i18n}}</ng-container>
     <ng-container *ngIf="assignedPersons.length > 0 && assigneeLimitNotReached()">{{'tasks_person_assigned_add' | i18n}}</ng-container>
   </button>
  </div>
  <my-drop-menu cssClass="theme-selector-drop-menu" [anchor]="DropMenuAnchor" [(visible)]="personPopupVisible" [asModalOnMobile]="'top'">
    <my-persons-assigned-search *ngIf="personPopupVisible" (personAssigned)="onPersonAssigned($event)" [assignedPersons]="assignedPersons" [taskIdentifier]="taskIdentifier"></my-persons-assigned-search>
  </my-drop-menu>

</ng-container>

<div *ngIf="popupMode" class="popup">

<!--  <div class="title">-->
<!--    {{'tasks_assignee' | i18n}}-->
<!--  </div>-->

<!--  <div class="persons" *ngIf="assignedPersons.length > 0">-->
<!--    <div class="person" *ngFor="let person of assignedPersons">-->
<!--      <my-person-summary [personId]="person.personIdUnwrapped()"></my-person-summary>-->
<!--      <button *ngIf="enabled && canChangeAssignee" (click)="unassignPerson(person)" class="removePerson">-->
<!--        <i class="mi-delete"></i>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->

<!--  <button *ngIf="enabled && canAssignSelf && !assignedToCurrentUser && assignablePersons.length === 0" (click)="assignSelf()" class="theme-button-confirm-with-icon-small">-->
<!--    <i class="mi-user-add"></i>{{'dashboard_Form_AssignToMe' | i18n}}-->
<!--  </button>-->

  <div class="actions" *ngIf="enabled && canChangeAssignee && personsAvailable && !personPopupVisible &&
                              (assigneeLimitNotReached() || assignedPersons.length === 1 && assigneeLimit === 1)">
    <button class="theme-button-link-icon-small" (click)="toggleAddPersonPopup($event)">
      <i class="mi-user-add"></i>
      <ng-container *ngIf="assignedPersons.length === 0 && assigneeLimitNotReached()">{{'tasks_person_assigned_assign_another' | i18n}}</ng-container>
      <ng-container *ngIf="assignedPersons.length === 1 && assigneeLimit === 1">{{'tasks_person_assigned_another_assign' | i18n}}</ng-container>
      <ng-container *ngIf="assignedPersons.length > 0 && assigneeLimitNotReached()">{{'tasks_person_assigned_add' | i18n}}</ng-container>
    </button>
  </div>

  <my-persons-assigned-search class="theme-selector-drop-menu-content-only" *ngIf="personPopupVisible"
                              (personAssigned)="onPersonAssigned($event)"
                              (personUnassigned)="onPersonUnassigned($event)"
                              [showAssignedPersons]="true"
                              [assignedPersons]="assignedPersons" [taskIdentifier]="taskIdentifier"></my-persons-assigned-search>

</div>


