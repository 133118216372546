import {Component, EventEmitter, Input, Output} from "@angular/core";
import {__, myRequestAnimationFrame} from "@utils";

@Component({
  selector: 'my-keywords',
  templateUrl: './keywords.component.html',
})
export class KeywordsComponent {
  @Input() readOnly = false;
  @Input() keywords: ReadonlyArray<string> = [];
  @Output() keywordsChange: EventEmitter<ReadonlyArray<string>> = new EventEmitter<ReadonlyArray<string>>();

  newKeyword = "";
  addInputVisible = false;

  @Input() addEnabled = true;
  @Input() deleteEnabled = true;

  deleteKeyword(keyword: string) {
    this.keywords = this.keywords.filter(k => k !== keyword);
    this.keywordsChange.emit(this.keywords);
  }

  addKeyword() {
    this.addInputVisible = true;
    this.newKeyword = "";
  }

  acceptNewKeyWord() {
    const trimmed = this.newKeyword.trim();
    if(trimmed.length > 0) {
      if (this.keywords.indexOf(trimmed) === -1) {
        this.keywords = __(this.keywords.concat([trimmed])).sortByAlphanumeric(k => k);
        this.keywordsChange.emit(this.keywords);
      }
      this.addInputVisible = false;
      myRequestAnimationFrame(() => {
        this.addKeyword();
      });
    }
  }

  cancelNewKeyword() {
    this.addInputVisible = false;
    this.newKeyword = "";
  }
}
