<i *ngIf="viewModel.startIcon" class="start" [ngClass]="viewModel.startIcon"></i>

<adjusting-text-input *ngIf="viewModel.multiLine"
                         ngDefaultControl class="input {{viewModel.cssClasses}}"
                         [disabled]="viewModel.disabled" [class.disabled]="viewModel.disabled"
                         [style]="viewModel.css"
                         [attr.padding-right]="viewModel.paddingRightCssValue"
                         [placeholder]="viewModel.placeholder"
                         [(model)]="viewModel.internalValue"
                         (blur)="viewModel.onBlur()" (focus)="viewModel.onFocus()"></adjusting-text-input>

<input *ngIf="!viewModel.multiLine" [attr.padding-right]="viewModel.paddingRightCssValue"
        type="text"
        class="input {{viewModel.cssClasses}}"
        [style]="viewModel.css"
        [placeholder]="viewModel.placeholder"
        [disabled]="viewModel.disabled" [class.disabled]="viewModel.disabled"
        [(ngModel)]="viewModel.internalValue"
        (blur)="viewModel.onBlur()"
        (keydown.enter)="viewModel.onBlur()"
        (focus)="viewModel.onFocus()" />

<span class="unitName" *ngIf="viewModel.unitVisible" style="{{viewModel.unitCss}}" [ngClass]="{singleline: !viewModel.multiLine}">{{viewModel.unit}}</span>

<i *ngIf="viewModel.endIcon" class="end" [ngClass]="viewModel.endIcon"></i>
