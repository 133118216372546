import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from "@angular/core";
import {__, AnyPersonId, AnyPersonIdFactory, AnyPersonIdHelper, FileUri, None, Option, toastr, Typed} from "@utils";
import {
  BasicPersonInfo,
  FlowsSharedService,
  PersonsSharedService,
  SimplePersonAvatarInfo,
  TaskIdentifier
} from "@shared-model";
import {SessionServiceProvider} from "@shared";

export class AssignablePersonInfo {

  readonly basicInfo: SimplePersonAvatarInfo;
  selected: boolean = false;

  constructor(readonly personId: Typed<AnyPersonId>,
              readonly firstName: string,
              readonly lastName: string,
              readonly position: string,
              readonly avatar: Option<FileUri>,
              readonly email: string) {
    this.basicInfo = new SimplePersonAvatarInfo(this.firstName, this.lastName, this.email, this.avatar);

  }

  personIdUnwrapped(): AnyPersonId {
    return Typed.value(this.personId);
  }

  simpleName() {
    return this.firstName + " " + this.lastName;
  }

  sortName() {
    return this.lastName.toLowerCase() + " " + this.firstName.toLowerCase();
  }

  matchable() {
    return this.firstName.toLowerCase() + " " + this.lastName.toLowerCase() + " " + this.email.toLowerCase() + " " + this.position.toLowerCase();
  }

  static fromBasicInfo(basicInfo: BasicPersonInfo) {
    if (basicInfo.isApi()) {
      return new AssignablePersonInfo(basicInfo.id, basicInfo.simpleName(), "", "", None(), "");
    } else {
      return new AssignablePersonInfo(basicInfo.id, basicInfo.firstName, basicInfo.lastName, basicInfo.position, Option.copy(basicInfo.avatar, FileUri.copy), basicInfo.email);
    }
  }

  static copy(other: AssignablePersonInfo) {
    return new AssignablePersonInfo(AnyPersonIdFactory.copyTyped(other.personId), other.firstName, other.lastName, other.position, Option.copy(other.avatar, FileUri.copy), other.email);
  }

  markSelected() {
    return this.selected = true;
  }
}

@Component({
  selector: "my-persons-assigned",
  templateUrl: "./persons-assigned.component.html",
})
export class PersonsAssignedComponent implements OnInit {

  @Input() taskIdentifier?: TaskIdentifier;

  private _personsAssigned: Array<AnyPersonId> = [];
  @Input() set personsAssigned(personsAssigned: Array<AnyPersonId>) {
    this._personsAssigned = personsAssigned;
    this.onAssignedPersonsChanged();
  };

  @Output() personAssigned = new EventEmitter<AnyPersonId>();
  @Output() personUnassigned = new EventEmitter<AnyPersonId>();


  @Input({required: true}) assigneeLimit = 1;
  @Input({required: true}) enabled = true;
  @Input({required: true}) canChangeAssignee = true;
  @Input({required: true}) canAssignOther = true;
  @Input({required: true}) canAssignSelf = true;

  @Input() popupMode: boolean = false;


  assignedPersons: Array<AnyPersonId> = [];
  personPopupVisible = false;

  assignedToCurrentUser = false;

  personsAvailable: boolean = false;

  lastAssignablePersonsCheck: number = 0;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              private readonly personsSharedService: PersonsSharedService,
              private readonly sessionServiceProvider: SessionServiceProvider,
              private readonly flowSharedService: FlowsSharedService
  ) {
  }

  ngOnInit(): void {
    this.onAssignedPersonsChanged();

    this.checkAssignablePersons(() => {
      if (this.popupMode) {
        this.personPopupVisible = true;
      }
    });
  }

  checkAssignablePersons(onDone: () => void = () => {}) {
    const minimumCheckInterval = 5000;
    if (this.taskIdentifier && this.lastAssignablePersonsCheck < Date.now() - minimumCheckInterval) {
      this.lastAssignablePersonsCheck = Date.now();
      this.flowSharedService.checkPersonsAssignableToTask(this.taskIdentifier, this._personsAssigned, result => {
        this.lastAssignablePersonsCheck = Date.now();
        this.personsAvailable = result;
        onDone();
      });
    }
  }

  toggleAddPersonPopup = (event: MouseEvent) => {
    this.personPopupVisible = !this.personPopupVisible;
  };

  unassignPerson = (person: AnyPersonId) => {
    this.personUnassigned.emit(person);
    this.personsAvailable = true;
  };

  onAssignedPersonsChanged() {
    this.personPopupVisible = false;
    this.assignedPersons = this._personsAssigned;
    this.updateAssignedToCurrentUser();
    this.checkAssignablePersons();
  }

  assigneeLimitNotReached() {
    return this.assignedPersons.length < this.assigneeLimit;
  }

  assignSelf() {
    this.sessionServiceProvider.getOrganizationSessionInfo((sessionInfo) => {
      this.personAssigned.emit(sessionInfo.getPersonId());
      this.checkAssignablePersons();
    });
  }

  updateAssignedToCurrentUser() {
    this.sessionServiceProvider.getOrganizationSessionInfo((sessionInfo) => {
      const currentUser = sessionInfo.getPersonId();
      this.assignedToCurrentUser = __(this.assignedPersons).exists(p => AnyPersonIdHelper.equals(p, currentUser));
    });
  }

  onPersonAssigned(person: AnyPersonId) {
    this.personAssigned.emit(person);

    if (this.assignedPersons.length === 1 && this.assigneeLimit === 1) {
      // do not chnage flag when we are replacing person
    } else {
      this.personsAvailable = false;
    }
    this.checkAssignablePersons();
    this.personPopupVisible = false;
  }

  onPersonUnassigned(person: AnyPersonId) {
    this.personUnassigned.emit(person);

    this.checkAssignablePersons();
    this.personPopupVisible = false;
  }

}
