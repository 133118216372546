import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {LabeledValuesProperty, OptionalStringProperty, StringProperty} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class SelectEntriesLayoutType {
  static horizontal = new SelectEntriesLayoutType("horizontal");
  static vertical = new SelectEntriesLayoutType("vertical");

  constructor(readonly name: string) {}

  static of(name: string) {
    switch (name) {
      case SelectEntriesLayoutType.horizontal.name: return SelectEntriesLayoutType.horizontal;
      case SelectEntriesLayoutType.vertical.name: return SelectEntriesLayoutType.vertical;
      default: throw new Error("Incorrect entries layout value ["+name+"]");
    }
  }

  isHorizontal() {
    return this.name === SelectEntriesLayoutType.horizontal.name;
  }

  isVertical() {
    return this.name === SelectEntriesLayoutType.vertical.name;
  }

  toCssClass() {
    switch (this.name) {
      case SelectEntriesLayoutType.horizontal.name: return SelectEntriesLayoutType.horizontal.name;
      case SelectEntriesLayoutType.vertical.name: return SelectEntriesLayoutType.vertical.name;
      default: throw new Error("Incorrect entries layout value ["+this.name+"]");
    }
  }
}


export class MultiCheckboxComponentRef extends InputComponentRef {
  static className = "MultiCheckboxComponentRef";

  className() {
    return MultiCheckboxComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: MultiCheckboxComponentRef): MultiCheckboxComponentRef {
    return new MultiCheckboxComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("MultiCheckboxRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = MultiCheckboxComponentRef.DEFAULT_PROPERTIES;
}

export class MultiCheckboxComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "MultiCheckboxComponentDefinition";
  className() {
    return MultiCheckboxComponentDefinition.className;
  }
  typeName() {
    return "MultiCheckbox";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,MultiCheckboxComponentDefinition.DEFAULT_PROPERTIES);
  }

  get options(): LabeledValuesProperty {return this.properties.getLabeledValuesProperty("options", this.defaultPropertiesProvider)};

  get entriesLayout(): StringProperty {return this.properties.getStringProperty("entriesLayout", this.defaultPropertiesProvider)}

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  iconBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("iconBackgroundColor", defaultProvider);
  }

  static copy(other: MultiCheckboxComponentDefinition): MultiCheckboxComponentDefinition {
    return new MultiCheckboxComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [MultiCheckboxComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("MultiCheckboxComponentDefinition",
    (name: string) => {switch (name) {
      case "options": return LabeledValuesProperty.of(["ABC", "DEF", "GHI"]);
      case "iconBackgroundColor": return OptionalStringProperty.disabled("#000000ff");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionEntriesLayout,
    DefaultPropertyProvider.definitionBorders, DefaultPropertyProvider.definitionPaddings);
}
