import {Component, OnInit} from "@angular/core";
import {__, global, i18n, Language, None, Option, Some, toastr, trim} from "@utils";
import {RegisterOrganizationAndUser} from "@shared";
import {I18nService, LocalSettingsService, SessionServiceProvider, UserSettingsStateService} from "@shared";
import {PasswordValidatorService} from "@shared";
import {
  OrganizationRegistrationService
} from "@shared";
import {EmailValidator} from "@shared";
import {latinizeLowercase} from "../../../utils/Latinize";

export enum RegistrationStepForm {
  ORGANIZATION,
  USER,
  PASSWORD
}

@Component({
  selector: 'my-register-organization-form',
  templateUrl: './register-organization-form.component.html'
})
export class RegisterOrganizationFormComponent implements OnInit {

  public organizationName: string = null!;
  public organizationAlias: string = null!;

  public userFirstName: string = null!;
  public userLastName: string = "";
  public userEmail: string = null!;
  public userPassword: string = null!;
  public userPasswordRepeat: string = null!;
  public termsAndPolicyAcceptance: boolean = null!;

  public currentAliasOption: Option<string> = None();

  public registrationDisabled: boolean = false;
  public organizationRegistered: boolean = false;
  public registrationDisabledChecked: boolean = true;
  public termsAndPolicyVisible: boolean = false;
  public singleOrganizationMode: boolean = global.config.singleOrganization;
  public form: RegisterOrganizationAndUser = new RegisterOrganizationAndUser();
  public registrationStepForm: RegistrationStepForm = RegistrationStepForm.ORGANIZATION;

  public currentApplicationProtocol!: string;
  public currentApplicationDomainWithPort!: string;
  public organizationNameAlreadyExists: boolean = false;
  public urlAlreadyInUse: boolean = false;
  public urlTooShort: boolean = false;
  public passwordExceptions: Array<string> = [];
  public repeatPasswordExceptions: Array<string> = [];
  emailValidator: EmailValidator;

  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly localSettings: LocalSettingsService,
              readonly userSettingsService: UserSettingsStateService,
              private readonly i18nService: I18nService,
              private readonly organizationRegistrationService: OrganizationRegistrationService,
              readonly passwordValidatorService: PasswordValidatorService) {

    this.emailValidator = new EmailValidator(organizationRegistrationService);

    organizationRegistrationService.getRegistrationToken();
    this.currentApplicationProtocol = location.protocol + "//";
    this.currentApplicationDomainWithPort = location.hostname + (parseInt(location.port) !== 80 && location.port !== "" ? ":" + location.port : "");
  }

  ngOnInit() {
    this.organizationRegistrationService.getRegistrationToken();
  }

  toggleShowingTermsAndPolicy() {
    this.termsAndPolicyVisible = !this.termsAndPolicyVisible;
  }

  closeTermsAndPolicy() {
    this.termsAndPolicyVisible = false;
  }

  validateOrganizationForm() {
    this.validateName();
    this.validateAlias();
    if(this.organizationName.trim().length > 0 && this.organizationAlias.trim().length > 0 && !this.organizationNameAlreadyExists && !this.urlAlreadyInUse && !this.urlTooShort) {
      this.registrationStepForm = RegistrationStepForm.USER;
    }
  }

  validateName() {
    if(this.organizationName) {
      this.organizationRegistrationService.checkIfOrganizationNameExists(this.organizationName, (organizationNameExists: boolean) => {
        this.organizationNameAlreadyExists = organizationNameExists;
      });
    }
  }

  currentAlias() {
    let organizationAlias: string;
    if (this.currentAliasOption.isDefined()) {
      organizationAlias = this.currentAliasOption.get();
    } else if (this.organizationName && this.organizationName.length > 0) {
      organizationAlias = this.organizationName;
    } else {
      organizationAlias = "";
    }
    return latinizeLowercase(organizationAlias.toLowerCase()).replace(/[^a-z0-9]/g, '');
  }

  updateAlias = () => {
    this.organizationAlias = this.currentAlias();
    if (this.urlAlreadyInUse || this.urlTooShort) {
      this.validateAlias();
    }
  };

  modifyAlias = () => {
    this.organizationAlias = this.organizationAlias.trim().toLowerCase();
    if(this.organizationAlias.length > 0 ){
      this.currentAliasOption = Some(this.organizationAlias);
    } else {
      this.currentAliasOption = None();
      this.organizationAlias = this.currentAlias();
    }
    this.validateAlias();
  };

  validateAlias() {
    if(this.organizationAlias) {
      if (this.organizationAlias === "") {
        this.urlTooShort = false;
      } else if (this.organizationAlias.length < 2) {
        this.urlTooShort = true;
        this.urlAlreadyInUse = false;
      } else {
        this.urlTooShort = false;
        this.organizationRegistrationService.checkIfURLInUse(this.organizationAlias, (data:boolean) => {
          this.urlAlreadyInUse = data;
        });
      }
    }
  };

  validateUserForm() {
    this.emailValidator.validate(this.userEmail);
    if(this.userFirstName.trim().length > 0 && this.emailValidator.emailCorrect) {
      this.registrationStepForm = RegistrationStepForm.PASSWORD;
    }
  }

  validateEmail() {
    this.emailValidator.validate(this.userEmail);
  }

  checkIfPasswordsMatchNow = () => {
    if(this.repeatPasswordExceptions.length > 0) {
      this.validatePasswords();
    }
  };

  validatePassword() {
    this.passwordValidatorService.validatePassword(trim(this.userPassword), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });
  };

  validatePasswords() {
    this.passwordValidatorService.validatePasswords(trim(this.userPassword), trim(this.userPasswordRepeat), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });
  };

  registerOrganization() {
    this.form.alias = this.currentAlias();
    this.form.organizationName = this.organizationName;
    this.form.firstName = this.userFirstName.trim();
    this.form.lastName = this.userLastName.trim();
    this.form.password = this.userPassword;
    this.form.repeatPassword = this.userPasswordRepeat;
    this.form.licenseAccepted = this.termsAndPolicyAcceptance;
    this.form.email = this.userEmail.trim().toLowerCase();
    this.form.language = this.localSettings.getLanguage().map(Language.byName).getOrElse(Language.EN);
    this.form.timezone = this.form.timezone = this.userSettingsService.getEffectiveTimeZone();
    this.form.industry = "";

    this.passwordValidatorService.validatePasswords(trim(this.form.password), trim(this.form.repeatPassword), () => {
      this.registerOrganizationPasswordValid();
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      toastr.error(passwordExceptions.concat(repeatPasswordExceptions).join(", "));
    });
  };

  registerOrganizationPasswordValid() {
    this.organizationRegistrationService.registerOrganizationAndUser(this.form, () => {
      this.organizationRegistered = true;
    }, function (exceptions: Array<string>) {
      toastr.error(i18n('register_form_error_prefix') + " " + exceptions);
    });
  }

  protected readonly RegistrationStepForm = RegistrationStepForm;
}
