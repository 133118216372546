<button
  #themeSelector
  (click)="themeDropMenuVisible = !themeDropMenuVisible"
  class="theme-button-link-icon-end-small theme-align-end">{{displayedSelectedTheme.name}}
   <i class="mi-caret-down" *ngIf="!themeDropMenuVisible"></i>
   <i class="mi-caret-up" *ngIf="themeDropMenuVisible"></i>
</button>

<my-drop-menu cssClass="theme-drop-menu languagesDropMenu"
              [anchor]="themeSelector"
              [(visible)]="themeDropMenuVisible"
              horizontalPosition="end"
              verticalPosition="top">
  <div *ngIf="themeDropMenuVisible" class="languageOptions">
    <button *ngFor="let themeOption of themeOptions"
            (click)="themeSelected(themeOption)"
            class="languageButton theme-button-flat-small">
    <span class="languageButtonInnerContainer">
      <span>{{themeOption.name}}</span>
      <i *ngIf="themeOption.selected" class="mi-check"></i>
    </span>
    </button>
  </div>
</my-drop-menu>
