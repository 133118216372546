import {ExpressionWithAst} from "@shared-model";
import {Option} from "@utils";

export class DurationMethod {
  constructor(readonly name: string) {}

  static onCaseStart = new DurationMethod("OnCaseStarted");
  static onActionAppear = new DurationMethod("OnActionAppeared");
  static onFormField = new DurationMethod("OnFormField");
  static onExpression = new DurationMethod("OnExpression");

  static copy(other: DurationMethod) {
    switch (other.name) {
      case this.onActionAppear.name:
        return this.onActionAppear;
      case this.onCaseStart.name:
        return this.onCaseStart;
      case this.onFormField.name:
        return this.onFormField;
      case this.onExpression.name:
        return this.onExpression;
      default:
        throw new Error("Unsupported duration method: " + other.name);
    }
  }
}

export class ExpectedDuration {

  constructor(readonly method: DurationMethod,
              readonly durationMillis: Option<number>,
              readonly expressionWithAst: Option<ExpressionWithAst>,
              readonly field: Option<string>,
              readonly editableForCaseRole: Array<number> = [],
              readonly editableForInstanceRole: Array<number> = []) {
  }

  isOnCaseStarted() {
    return this.method === DurationMethod.onCaseStart;
  }

  isOnActionAppeared() {
    return this.method === DurationMethod.onActionAppear;
  }

  isOnFormField() {
    return this.method === DurationMethod.onFormField;
  }

  isOnExpression() {
    return this.method === DurationMethod.onExpression;
  }

  static copy(other: ExpectedDuration) {
    return new ExpectedDuration(
      DurationMethod.copy(other.method),
      Option.copy(other.durationMillis),
      Option.copy(other.expressionWithAst).map(ExpressionWithAst.copy),
      Option.copy(other.field),
      other.editableForCaseRole,
      other.editableForInstanceRole);
  }
}

