import {Component, NgZone} from "@angular/core";
import {AppUpdateService, SystemSettingsService} from "@shared";
import {TaskListManagerService} from "@shared-model";
import {global} from "@utils";
import {OfflineService} from "./shared/services/offline/offline.service";
import {of} from "rxjs";
import {ChatbotService} from "./shared/services/chatbot/chatbot.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  offline = false;
  updateInProgress: boolean = false;

  constructor(private readonly appUpdateService: AppUpdateService,
              readonly appSettingsService: SystemSettingsService, // added here so it will be initialized),
              readonly offlineService: OfflineService, // added here so it will be initialized),
              readonly taskListManagerService: TaskListManagerService, // added here so it will be initialized),
              private chatbotService: ChatbotService,
              zone: NgZone
  ) {
    global.zone = zone;
    appUpdateService.init();
    offlineService.init();

    this.offline = offlineService.offline;
    offlineService.getOfflineObservable().subscribe(offline => {
      this.offline = offline;
    });

    appUpdateService.getWillUpdateObservable().subscribe(willUpdate => {
      this.updateInProgress = willUpdate;
    });


    setTimeout(() => {
      this.chatbotService.initChatbot();
    }, 1000);
  }

}
