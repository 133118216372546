import {ObjectId, Option} from "@utils";
import {VariablePath} from "./VariablePath";

export class ContextPath {
  constructor(
    readonly context: Option<ObjectId>,
    readonly location: VariablePath
  ) {}

  static copy(other: ContextPath) {
    return new ContextPath(Option.copy(other.context), VariablePath.copy(other.location));
  }

  isEqual(other: ContextPath) {
    return this.context.equals(other.context, (a, b) => a.id === b.id) && this.location.isEqual(other.location);
  }

  isRoot() {
    return this.context.isEmpty() && this.location.isRoot();
  }

  nonRoot() {
    return this.context.isDefined() || this.location.nonRoot();
  }

}
