  <!-- MONTHLY -->
  <div *ngIf="viewModel.monthlyType">
    <div class="calendarHeader">
      <div class="calendarNavigation">
        <button (click)="viewModel.monthly.previousMonth()"><i class="mi-navigate-left"></i></button><!--
        --><button (click)="viewModel.monthly.nextMonth()"><i class="mi-navigate-right"></i></button>
      </div>
      {{viewModel.monthly.monthName}}
      <div class="calendarConfiguration">
        <my-drop-down-selector [options]="viewModel.typeOptions"
                               [(selectedOption)]="viewModel.calendarType"
                               (selectedOptionChange)="viewModel.calendarTypeChanged($event)">
          <i prefix class="mi-calendar"></i>
        </my-drop-down-selector>
      </div>
    </div>
    <div class="monthlyCalendar">
      <div class="calendarWeekDays">
        <div class="dayHeader" *ngFor="let day of viewModel.monthly.daysHeader">{{day.name}}</div>
      </div>
      <div class="calendarWeek" *ngFor="let week of viewModel.monthly.weeks">
        <div *ngFor="let day of week.days" class="calendarDay selectablePeriod id{{day.id}}"
             [class.today]="day.today"
              [class.otherMonth]="day.otherMonth"
              [class.selected]="day.selected"
              [class.off]="day.off">
          <div class="dayNumber">{{day.date.day}}</div>
          <div class="calendarEntry" *ngFor="let entry of day.entries"
               [style.width]="entry.cssWidth"
               [class.inverted]="entry.inverted"
                [class.placeholder]="entry.placeholder"
                [class.selected]="entry.selected"
                [class.openLeft]="entry.openLeft"
                [class.openRight]="entry.openRight"
          ><button (click)="viewModel.monthly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
                   [style.background-color]="entry.color"
                   [style.border-color]="entry.color"
                   [my-tooltip]="{text: entry.name, position: 'top'}">{{entry.name}}</button></div>
        </div>
      </div>
    </div>
  </div>

  <!-- WEEKLY -->
  <div *ngIf="viewModel.weeklyType">
    <div class="calendarHeader">
      <div class="calendarNavigation">
        <button (click)="viewModel.weekly.previousWeek()"><i class="mi-navigate-left"></i></button><!--
        --><button (click)="viewModel.weekly.nextWeek()"><i class="mi-navigate-right"></i></button>
      </div>
      {{viewModel.weekly.weekName}}
      <div class="calendarConfiguration">
        <my-drop-down-selector [options]="viewModel.typeOptions"
                               [(value)]="viewModel.calendarType"
                               (valueChange)="viewModel.calendarTypeChanged($event)">
          <i prefix class="mi-calendar"></i>
        </my-drop-down-selector>
      </div>
    </div>
    <div class="weeklyCalendar"><!--
      --><div class="weeklyCalendarHeader"><!--
        --><div class="headerSpace"></div><!--
        --><div class="dayHeader" *ngFor="let day of viewModel.weekly.dayHeader"><!--
          --><div class="dayName"><span class="dayNumber">{{day.day}}</span>{{day.name}}</div><!--
          --><div class="entries"><!--
      --><div class="calendarEntry" *ngFor="let entry of day.entries"
              [style.width]="entry.cssWidth"
              [class.inverted]="entry.inverted"
              [class.placeholder]="entry.placeholder"
              [class.selected]="entry.selected"
              [class.openLeft]="entry.openLeft"
              [class.openRight]="entry.openRight"
    ><button (click)="viewModel.monthly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
              [style.background-color]="entry.color"
              [style.border-color]="entry.color"
             [my-tooltip]="{text: entry.name, position: 'top'}">{{entry.name}}</button></div><!--
    --></div><!--
        --></div><!--
      --></div><!--
      --><div class="weekContent"><!--
        --><my-scroll contentClass="weekScrollContent"><!--
          --><div class="hoursHeader"><!--
            --><div class="hourHeader" *ngFor="let hour of viewModel.weekly.hourHeader">{{hour.name}}</div><!--
          --></div><!--
          --><div class="calendarDay" *ngFor="let day of viewModel.weekly.days"><!--
            --><div class="calendarHalfHour calendarHalfHour{{halfHour.columns}} selectablePeriod id{{halfHour.id}}" *ngFor="let halfHour of day.halfHours"
                    [class.working]="halfHour.working"
                    [class.selected]="halfHour.selected"
                    [class.endOfFullHour]="halfHour.endOfFullHour"><!--
              --> <div class="calendarEntry" *ngFor="let entry of halfHour.visibleEntries"
                       [style.height]="entry.cssHeight"
                       [class.inverted]="entry.inverted"
                        [class.placeholder]="entry.placeholder"
                        [class.selected]="entry.selected"
    ><button (click)="viewModel.weekly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
             [style.background-color]="entry.color"
              [style.border-color]="entry.color"
             [my-tooltip]="{text: entry.name, position: 'top'}">{{entry.name}}</button></div><!--
      --></div><!--
          --></div><!--
        --></my-scroll><!--
      --></div><!--
    --></div><!--
  --></div>

  <!-- YEARLY -->
  <div *ngIf="viewModel.yearlyType">
    <div class="calendarHeader">
      <div class="calendarNavigation">
        <button (click)="viewModel.yearly.previousYear()"><i class="mi-navigate-left"></i></button><!--
        --><button (click)="viewModel.yearly.nextYear()"><i class="mi-navigate-right"></i></button>
      </div>
      {{viewModel.yearly.year}}
      <div class="calendarConfiguration">
        <my-drop-down-selector [options]="viewModel.typeOptions"
                               [(selectedOption)]="viewModel.calendarType"
                               (selectedOptionChange)="viewModel.calendarTypeChanged($event)">
          <i prefix class="mi-calendar"></i>
        </my-drop-down-selector>
      </div>
    </div>
    <div class="yearlyCalendar"><!--
      --><div class="yearlyHeader"><div class="headerSpace"></div><div class="dayHeader" *ngFor="let day of viewModel.yearly.daysHeader">{{day.name}}</div></div><!--
      --><div class="month" *ngFor="let month of viewModel.yearly.months"><!--
        --><div class="monthName">{{month.name}}</div><!--
        --><div class="monthDay monthDay{{day.rows}} selectablePeriod id{{day.id}}" *ngFor="let day of month.days"
                [class.mock]="day.mock"
                [class.off]="day.off"
                [class.selected]="day.selected"><!--
      --><span class="monthEntries" *ngIf="day.visibleEntries.length > 3">{{day.entries.length}}</span><!--
      --><div class="calendarEntry" *ngFor="let entry of day.visibleEntries"
              [style.width]="entry.cssSize"
              [class.inverted]="entry.inverted"
              [class.placeholder]="entry.placeholder"
              [class.selected]="entry.selected"
    ><button (click)="viewModel.yearly.entrySelected(entry)" (pointerdown)="$event.stopPropagation()"
             [style.background-color]="entry.color"
             [style.border-color]="entry.color"
             [my-tooltip]="{text: entry.name, position: 'top'}">{{entry.name}}</button></div><!--
      --></div><!--
      --></div><!--
    --></div>
  </div>

  <!-- RESOURCES -->
  <div *ngIf="viewModel.resourceType">
    <div class="calendarHeader">
      <div class="calendarNavigation">
        <button (click)="viewModel.resource.previousPeriod()"><i class="mi-navigate-left"></i></button><!--
        --><button (click)="viewModel.resource.nextPeriod()"><i class="mi-navigate-right"></i></button>
      </div>
      {{viewModel.resource.periodName}}
      <div class="calendarConfiguration">
        <my-drop-down-selector [options]="viewModel.resource.periodOptions"
                               [(selectedOption)]="viewModel.resource.periodLength"
                               (selectedOptionChange)="viewModel.resource.periodLengthChanged($event)">
          <i prefix class="mi-calendar"></i>
        </my-drop-down-selector>
        <my-drop-down-selector [options]="viewModel.typeOptions"
                               [(selectedOption)]="viewModel.calendarType"
                               (selectedOptionChange)="viewModel.calendarTypeChanged($event)">
          <i prefix class="mi-calendar"></i>
        </my-drop-down-selector>
      </div>
    </div>
    <div class="resourceCalendar resourceCalendar{{viewModel.resource.columns}}"><!--
      --><div class="resourcesHeader"><!--
        --><div class="headerSpacer"></div><!--
        --><div class="resource" *ngFor="let resource of viewModel.resource.resources">{{resource.name}}</div><!--
      --></div><!--
      --><div class="timeColumn"><!--
        --><div class="timeHeader"><!--
          --><div class="timeEntry" *ngFor="let time of viewModel.resource.timeHeader">{{time.name}}</div><!--
        --></div><!--
        --><div class="periodContent"><!--
          --><my-scroll><!--
            --><div class="resource" *ngFor="let resource of viewModel.resource.resources"><!--
              --><div class="period period{{period.rows}} selectablePeriod id{{period.id}}" *ngFor="let period of resource.periods"
                      [class.off]="period.off"
                      [class.selected]="period.selected"><!--
      --><div class="calendarEntry" *ngFor="let entry of period.visibleEntries"
              [style.width]="entry.cssSize"
              [class.inverted]="entry.inverted"
              [class.placeholder]="entry.placeholder"
              [class.selected]="entry.selected"><button
        (pointerdown)="$event.stopPropagation()" (click)="viewModel.resource.entrySelected(entry)"
        [style.background-color]="entry.color"
        [style.border-color]="entry.color"
        [my-tooltip]="{text: entry.name, position: 'top'}">{{entry.name}}</button></div><!--
      --></div></div><!--
          --></my-scroll><!--
        --></div><!--
      --></div><!--
    --></div>
  </div>
  <div class="selectedPopup" *ngIf="viewModel.selectedPopup.isDefined() && viewModel.selectedPopupVisible">
    <button class="closeButton" (click)="viewModel.entryUnselected()"><i class="mi-delete"></i></button>
    TODO Child component

<!--    <div class="child" style="{{child.positionCss}}" *ngFor="let child of viewModel.selectedPopup.get().children | filter: { uncoveredAndVisible: true } track by $index">-->
<!--      <my-view-component view-model="child.component"></my-view-component>-->
<!--    </div>-->
  </div>
