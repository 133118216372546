import {Component} from "@angular/core";
import {PageLayout, PageLayoutService} from "../../resolvers/page-layout.service";
import {AppUpdateService, PreloaderService} from "@shared";
import {MainMenuService} from "../main-menu/main-menu.service";
import {OfflineService} from "../../shared/services/offline/offline.service";
import {global, LocalDate} from "@utils";


@Component({
  selector: 'my-page-layout',
  templateUrl: './page-layout.component.html'
})
export class PageLayoutComponent {

  menuNavigationPage: boolean = false;
  simplePage: boolean = false;
  preloaderVisible: boolean = false;
  panelBackgroundVisible: boolean = false;

  warningsEnabled: boolean = false;
  licenceExpirationSoon: boolean = false;
  licenceExpiration: LocalDate = LocalDate.nowDate();
  licenceExpired: boolean = false;
  usersLimitExceeded: boolean = false;
  usersLimitExceededBy = 0;

  constructor(private readonly pageLayoutService: PageLayoutService,
              private readonly preloaderService: PreloaderService,
              private readonly mainMenuService: MainMenuService) {
    this.onLayoutChanged(pageLayoutService.layout);
    pageLayoutService.getLayoutObservable().forEach(layout => this.onLayoutChanged(layout));
    preloaderService.getPreloaderVisibleObservable().forEach(preloaderVisible => this.onPreloaderVisibilityChanged(preloaderVisible));


    const licenseExpirationInfo = global.config.licenseExpirationInfo;
    this.licenceExpired = licenseExpirationInfo.expired === true;
    this.licenceExpirationSoon = licenseExpirationInfo.expiresOn !== undefined;
    this.licenceExpiration = licenseExpirationInfo.expiresOn ?? LocalDate.nowDate();

    const usersLimitInfo = global.config.usersLimitInfo;
    this.usersLimitExceeded = usersLimitInfo.exceededBy !== undefined && usersLimitInfo.exceededBy > 0;
    this.usersLimitExceededBy = usersLimitInfo.exceededBy ?? 0;

    const systemWarningsHiddenByUser = localStorage.getItem("systemWarningsHiddenByUser") === LocalDate.nowDate().formatted();

    this.warningsEnabled = (this.licenceExpired || this.licenceExpirationSoon || this.usersLimitExceeded) && !systemWarningsHiddenByUser;

  }

  onPreloaderVisibilityChanged(visible: boolean) {
    this.preloaderVisible = visible;
  }

  onLayoutChanged(layout: PageLayout) {
    this.menuNavigationPage = layout == PageLayout.mainMenu;
    this.simplePage = layout == PageLayout.simple;
  }

  hideNotificationsPanel() {
    this.mainMenuService.hideNotificationsPanel();
  }

  closeSystemWarnings() {
    localStorage.setItem("systemWarningsHiddenByUser", LocalDate.nowDate().formatted());
    this.warningsEnabled = false;
  }
}
