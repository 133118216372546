import {Injectable} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Subject} from "rxjs";
import {mySetTimeout} from "@utils";

const ROUTER_CHANGE = 0;

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private changeIdGenerator = 1;
  private changeIds: Array<number> = [];
  private preloaderVisible: boolean = false;
  private readonly preloaderVisibleSubject = new Subject<boolean>();

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute) {
    router.events.subscribe((event) => {
      if(!this.hasPreloaderControl(activatedRoute.snapshot)) {
        if (event instanceof NavigationStart) {
          this.reset();
          this.changeIds.push(ROUTER_CHANGE);
        } else if (event instanceof NavigationEnd) {
          const index = this.changeIds.indexOf(ROUTER_CHANGE);
          if(index > -1) {
            this.changeIds.splice(index, 1)
          }
        }
        this.updatePreloaderState();
      }
    });
  }

  private removeChange(changeId: number) {
    const index = this.changeIds.indexOf(changeId);
    if(index > -1) {
      this.changeIds.splice(index, 1)
    }
  }

  private updatePreloaderState() {
    if(this.preloaderVisible && this.changeIds.length === 0) {
      this.preloaderVisible = false;
      this.preloaderVisibleSubject.next(this.preloaderVisible);
    } else if(!this.preloaderVisible && this.changeIds.length > 0) {
      this.preloaderVisible = true;
      this.preloaderVisibleSubject.next(this.preloaderVisible);
    }
  }

  private hasPreloaderControl(snapshot: ActivatedRouteSnapshot): boolean {
    if(snapshot.data['preloaderControl'] === true) {
      return true;
    } else if(snapshot.firstChild) {
      return this.hasPreloaderControl(snapshot.firstChild);
    } else {
      return false;
    }
  }

  showPreloader(): number {
    this.changeIdGenerator++;
    this.changeIds.push(this.changeIdGenerator);
    this.updatePreloaderState();
    return this.changeIdGenerator;
  }

  // Only last entity that requested visible preloader can make it disappear
  hidePreloader(id: number) {
    this.removeChange(id);
    this.updatePreloaderState();
  }

  hideRoutingPreloader() {
    this.removeChange(ROUTER_CHANGE);
    mySetTimeout(() => { // so it won't be called before during rendering
      this.updatePreloaderState();
    });
  }


  getPreloaderVisibleObservable() {
    return this.preloaderVisibleSubject.asObservable();
  }

  private reset() {
    this.changeIds = [];
    this.preloaderVisible = false;
  }
}
