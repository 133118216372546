import {ApplicationId, FunctionsId, Option} from "@utils";

export class FunctionsSummary {
  constructor(
    readonly id: FunctionsId,
    readonly name: string,
    readonly published: boolean
  ) {}

  static copy(other: FunctionsSummary){
    return new FunctionsSummary(FunctionsId.copy(other.id), other.name, other.published);
  }
}

export class FunctionsSummaryWithApplicationInfo {
  constructor(readonly applicationId: Option<ApplicationId>,
              readonly applicationName: string,
              readonly functions: FunctionsSummary) {}

  static copy(other: FunctionsSummaryWithApplicationInfo) {
    return new FunctionsSummaryWithApplicationInfo(Option.copy(other.applicationId, ApplicationId.of),
      other.applicationName,
      FunctionsSummary.copy(other.functions));
  }
}
