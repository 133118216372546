import {
  AggregateVersion,
  ApplicationId,
  ApplicationIdentifier,
  Either,
  Left,
  Option,
  Right,
  ScreenId,
  ScreenInstanceId
} from "@utils";
import {Injectable} from "@angular/core";
import {
  CreateScreenInstance,
  CreateScreenWorkingReleaseInstance,
  GetScreenInstanceState,
  ScreenComponentRefIdentifier,
  ScreenInitResponse,
  TerminateScreen
} from "./ScreenMessagesApi";
import {ApplicationComponentRef, ScreenInstanceSummary} from "./ScreenApi";
import {AuthenticatedHttp} from "@shared-model";


@Injectable({
  providedIn: 'root',
})
  export class ScreenInstanceSharedService {

    constructor(readonly authenticatedHttp: AuthenticatedHttp) {}


    run(applicationIdentifier: string, screenIdentifier: string, params: Array<[string, string]>, onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void, onError: (error: string) => void) {
      this.authenticatedHttp.post("screen/run", new CreateScreenInstance(Right(applicationIdentifier), ApplicationComponentRef.ofIdentifier(screenIdentifier), params), (data: Either<string, ScreenInitResponse>) => {
        const result = Either.copy(data, l => l, ScreenInitResponse.copy);
        if (result.isRight()) {
          onSuccess(result.getRight().screenInstanceId, result.getRight().volatileScreen,  result.getRight().multipleInstancesAllowed);
        } else {
          onError(result.getLeft());
        }
      })
    }


    runWorkingRelease(applicationId: ApplicationId, screenId: ScreenId, requestedRootComponent: ScreenComponentRefIdentifier, params: Array<[string, string]>, version: AggregateVersion, onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean) => void, onError: (error: string) => void) {
      this.authenticatedHttp.post("screen/run-working-release", new CreateScreenWorkingReleaseInstance(Left(applicationId), ApplicationComponentRef.ofScreenId(screenId), params, version, requestedRootComponent), (data: Either<string, ScreenInitResponse>) => {
        const result = Either.copy(data, l => l, r => ScreenInitResponse.copy(r));
        if (result.isRight()) {
          onSuccess(result.getRight().screenInstanceId, result.getRight().volatileScreen);
        } else {
          onError(result.getLeft());
        }
      })
    }


  instanceExits(id: ScreenInstanceId, onSuccess: (exists: boolean) => void) {
    this.authenticatedHttp.post("screen/get-instance", new GetScreenInstanceState(id), (data: Option<any>) => {
      let copied = Option.copy(data);
      onSuccess(copied.isDefined());
    })
  };

    terminate(screenInstanceId: ScreenInstanceId, onSuccess: () => void) {
      this.authenticatedHttp.post("screen/terminate", new TerminateScreen(screenInstanceId), (data: boolean) => {
        onSuccess();
      })
    }

  loadActiveInstancesInApplication(applicationIdentifier: string, onSuccess: (instance: Array<ScreenInstanceSummary>) => void) {
    this.authenticatedHttp.get("screen/active-instances-in-application-by-identifier/"+applicationIdentifier, (data: Array<ScreenInstanceSummary>) => {
      onSuccess(data.map(ScreenInstanceSummary.copy));
    })
  }

}
