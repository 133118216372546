import {
  __,
  AggregateId,
  AnyFlowId,
  AnyFlowIdFactory,
  AnyFlowIdHelper,
  AnyInstanceId,
  AnyInstanceIdFactory,
  AnyPersonId,
  AnyPersonIdFactory,
  AnyPersonIdHelper,
  ApplicationId,
  I18nText,
  InstanceId,
  LocalDate,
  LocalDateTime,
  Option,
  PersonId,
  Size,
  Typed
} from "@utils";
import {
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariableType,
  FlowCursorId,
  FlowCursorVersion, ProcessEdgeId, ProcessNodeId,
  RootVariableWithType
} from "..";
import {Constants} from "@utils";

export class TaskStatus {
  constructor(readonly name: string) {}
  static new = new TaskStatus("new"); // virtual status
  static todo = new TaskStatus("todo");
  static inProgress = new TaskStatus("inProgress");
  static waiting = new TaskStatus("waiting");
  static completed = new TaskStatus("completed");
  static terminated = new TaskStatus("terminated");

  static copy(other: TaskStatus) {
    switch (other.name) {
      case TaskStatus.new.name: return TaskStatus.new;
      case TaskStatus.todo.name: return TaskStatus.todo;
      case TaskStatus.inProgress.name: return TaskStatus.inProgress;
      case TaskStatus.waiting.name: return TaskStatus.waiting;
      case TaskStatus.completed.name: return TaskStatus.completed;
      case TaskStatus.terminated.name: return TaskStatus.terminated;
      default: throw new Error("Unsupported status ["+other.name+"]");
    }
  }

  static sortValue(other: TaskStatus) {
    switch (other.name) {
      case TaskStatus.new.name: return "0";
      case TaskStatus.todo.name: return "1";
      case TaskStatus.waiting.name: return "2";
      case TaskStatus.inProgress.name: return "3";
      case TaskStatus.completed.name: return "4";
      case TaskStatus.terminated.name: return "5";
      default: throw new Error("Unsupported status ["+other.name+"]");
    }
  }


  static sortPriorityValue(other: TaskStatus): number {
    switch (other.name) {
      case TaskStatus.new.name: return 0;
      case TaskStatus.inProgress.name: return 1;
      case TaskStatus.waiting.name: return 2;
      case TaskStatus.todo.name: return 3;
      case TaskStatus.completed.name: return 4;
      case TaskStatus.terminated.name: return 5;
      default: throw new Error("Unsupported status ["+other.name+"]");
    }
  }

  static all = [TaskStatus.todo,
    TaskStatus.inProgress,
    TaskStatus.waiting,
    TaskStatus.completed,
    TaskStatus.terminated];

}


export class TaskInfoForUser {
  constructor(
    readonly flowId: Typed<AnyFlowId>,
    readonly nodeId: ProcessNodeId,
    readonly processId: AggregateId,
    readonly instanceId: InstanceId,
    readonly created: LocalDateTime,
    readonly started: Option<LocalDateTime>,
    readonly completed: Option<LocalDateTime>,
    readonly processName: string,
    readonly nodeName: string,
    readonly assignableToMe: boolean,
    readonly importance: number,
    readonly urgency: number,
    readonly personsAssigned: Array<Typed<AnyPersonId>>,
    readonly deadline: Option<LocalDateTime>,
    readonly commentsCount: number,
    readonly taskStatus: TaskStatus) {}

  static copy(other: TaskInfoForUser) {
    return new TaskInfoForUser(
      AnyFlowIdFactory.copyTyped(other.flowId),
      other.nodeId,
      AggregateId.copy(other.processId),
      InstanceId.of(other.instanceId),
      LocalDateTime.copy(other.created),
      Option.copy(other.started, LocalDateTime.copy),
      Option.copy(other.completed, LocalDateTime.copy),
      other.processName,
      other.nodeName,
      other.assignableToMe,
      other.importance,
      other.urgency,
      other.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      Option.copy(other.deadline).map(LocalDateTime.copy),
      other.commentsCount,
      TaskStatus.copy(other.taskStatus)
    );
  }

  personsAssignedUnwrapped(): Array<AnyPersonId> {
    return this.personsAssigned.map(p => Typed.value(p));
  }

  flowIdUnwrapped(): AnyFlowId {
    return Typed.value(this.flowId);
  }
}

export class NodeMode {
  constructor(readonly name: string) {}
  static normal = new NodeMode("normal");
  static sequential = new NodeMode("sequential");
  static parallel = new NodeMode("parallel");

  static copy(other: NodeMode) {
    switch (other.name) {
      case NodeMode.normal.name: return NodeMode.normal;
      case NodeMode.sequential.name: return NodeMode.sequential;
      case NodeMode.parallel.name: return NodeMode.parallel;
      default: throw new Error("Unsupported mode ["+other.name+"]");
    }
  }

  isSequential() {
    return this.name === "sequential";
  }

  isParallel() {
    return this.name === "parallel";
  }
}

export class NodeType {
  constructor(readonly name: string) {}

  static external = new NodeType("External");
  static externalFinish = new NodeType("ExternalFinish");
  static condition = new NodeType("Condition");
  static finish = new NodeType("Finish");
  static comment = new NodeType("Comment");
  static annotation = new NodeType("Annotation");
  static delay = new NodeType("Delay");
  static wait = new NodeType("Wait");

  static startForm = new NodeType("StartForm");
  static startAutomatic = new NodeType("StartAutomatic");
  static actionForm = new NodeType("ActionForm");
  static actionAutomatic = new NodeType("ActionAutomatic");

  static subProcess = new NodeType("SubProcess");



  static crateNodeSize() {
    const nodeSize: {[nodeType: string]: Size} = {};
    nodeSize[NodeType.actionForm.name] = new Size(138, 82);
    nodeSize[NodeType.actionAutomatic.name] = new Size(138, 82);
    nodeSize[NodeType.subProcess.name] = new Size(138, 82);
    nodeSize[NodeType.external.name] = new Size(138, 82);
    nodeSize[NodeType.externalFinish.name] = new Size(75, 75);
    nodeSize[NodeType.condition.name] = new Size(136, 91);
    nodeSize[NodeType.finish.name] = new Size(75, 75);
    nodeSize[NodeType.startForm.name] = new Size(75, 75);
    nodeSize[NodeType.startAutomatic.name] = new Size(75, 75);
    nodeSize[NodeType.delay.name] = new Size(72, 72);
    nodeSize[NodeType.wait.name] = new Size(72, 72);
    return nodeSize;
  }

  static nodeSize: {[nodeType: string]: Size} = NodeType.crateNodeSize();

  isExternal() {
    return this.name === "External"
  }

  isExternalFinish() {
    return this.name === "ExternalFinish"
  }

  isCondition() {
    return this.name === "Condition"
  }

  isFinish() {
    return this.name === "Finish"
  }

  isComment() {
    return this.name === "Comment"
  }

  isAnnotation() {
    return this.name === "Annotation"
  }

  hasForm() {
    return this.isActionForm() || this.isStartForm();
  }

  static copy(other: NodeType, automatic: boolean = false): NodeType {
    return NodeType.byName(other.name, automatic);
  }

  static byName(name: string, automatic: boolean): NodeType {
    switch(name) {
      case "Start": return automatic ? NodeType.startAutomatic : NodeType.startForm;
      case "Action": return automatic ? NodeType.actionAutomatic : NodeType.actionForm;
      case "External": return NodeType.external;
      case "ExternalFinish": return NodeType.externalFinish;
      case "Condition": return NodeType.condition;
      case "Finish": return NodeType.finish;
      case "Delay": return NodeType.delay;
      case "Wait": return NodeType.wait;
      case "StartForm": return NodeType.startForm;
      case "StartAutomatic": return NodeType.startAutomatic;
      case "ActionForm": return NodeType.actionForm;
      case "ActionAutomatic": return NodeType.actionAutomatic;
      case "SubProcess": return NodeType.subProcess;
      default: throw new Error("Unsupported node type: " + name);
    }
  }

  canHaveOutEdge() {
    return !this.isFinish() && !this.isComment() && !this.isAnnotation()
  }

  canHaveOutAlternativeEdge() {
    return this.canHaveOutEdge() || this.isFinish();
  }

  isAnyAutomatic() {
    return this.isStartAutomatic() || this.isActionAutomatic();
  }

  isAnyForm() {
    return this.isStartForm() || this.isActionForm();
  }


  isStartAutomatic() {
    return this.name === "StartAutomatic";
  }

  isActionAutomatic() {
    return this.name === "ActionAutomatic";
  }

  isStartForm() {
    return this.name === "StartForm";
  }

  isActionForm() {
    return this.name === "ActionForm";
  }

  isAnyActionRectangle() {
    return this.isActionForm() || this.isActionAutomatic() || this.isExternal() || this.isSubProcess();
  }

  isAnyStartOrFinishCircle() {
    return this.isStartForm() || this.isStartAutomatic() || this.isFinish() || this.isExternalFinish();
  }

  isAnyStart() {
    return this.isStartForm() || this.isStartAutomatic();
  }

  isAnyAction() {
    return this.isActionForm() || this.isActionAutomatic();
  }

  isSubProcess() {
    return this.name === "SubProcess";
  }

  isIntermediateSignal() {
    return this.isWait() || this.isDelay();
  }

  isWait() {
    return this.name === "Wait";
  }

  isDelay() {
    return this.name === "Delay";
  }

  canHaveNoLabel() {
    return this.isCondition() || this.isWait() || this.isDelay() || this.isExternal() || this.isExternalFinish() || this.isSubProcess();
  }
}


export class TaskEdge {

  constructor(
    readonly edgeId: ProcessEdgeId,
    readonly edgeName: Option<I18nText>,
    readonly toNodeId: ProcessNodeId,
    readonly toNodeType: NodeType,
    readonly toNodeName: I18nText
  ) {}

  static copy(other: TaskEdge) {
    return new TaskEdge(other.edgeId, Option.copy(other.edgeName, I18nText.copy), other.toNodeId, NodeType.copy(other.toNodeType), I18nText.copy(other.toNodeName));
  }
}

export class TaskPullAction {
  constructor(
    readonly edgeId: ProcessEdgeId,
    readonly edgeName: Option<I18nText>,
    readonly toNodeId: ProcessNodeId,
    readonly toNodeType: NodeType,
    readonly toNodeName: I18nText,
    readonly cursorId: FlowCursorId
  ) {}

  static copy(other: TaskPullAction) {
    return new TaskPullAction(other.edgeId,
      Option.copy(other.edgeName, I18nText.copy),
      other.toNodeId,
      NodeType.copy(other.toNodeType),
      I18nText.copy(other.toNodeName),
      other.cursorId);
  }

}


export class TaskTrackedTime {
  constructor (
    readonly id: number,
    readonly person: Typed<AnyPersonId>,
    readonly from: LocalDateTime,
    readonly to: Option<LocalDateTime>) {}

  static copy(other: TaskTrackedTime) {
    return new TaskTrackedTime(
      other.id,
      AnyPersonIdFactory.copyTyped(other.person),
      LocalDateTime.copy(other.from),
      Option.copy(other.to, LocalDateTime.copy));
  }
}

export class CursorInfo {
  constructor(readonly cursorId: FlowCursorId,
              readonly cursorVersion: FlowCursorVersion) {}

  static copy(other: CursorInfo) {
    return new CursorInfo(other.cursorId, other.cursorVersion);
  }
}

export class TaskIdentifierUnwrapped {
  constructor(readonly flowId: AnyFlowId,
              readonly nodeId: ProcessNodeId) {}

  static of(taskIdentifier: TaskIdentifier): TaskIdentifierUnwrapped {
    return new TaskIdentifierUnwrapped(taskIdentifier.flowIdUnwrapped(), taskIdentifier.nodeId);
  }
}

export class FlowWithCursor {
  constructor(readonly flowId: AnyFlowId,
              readonly cursor: CursorInfo) {}
}

export class TaskIdentifier {

  // public serializedId: string;
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId) {
    // const id: AnyFlowId = Typed.value(flowId);
    // this.serializedId = id.urlSerialized() +"|" +nodeId;
  }

  static of(flowId: AnyFlowId, nodeId: ProcessNodeId): TaskIdentifier {
    return new TaskIdentifier(Typed.of(flowId), nodeId);
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  equals(other: TaskIdentifier) {
    return AnyFlowIdHelper.equals(this.flowIdUnwrapped(), other.flowIdUnwrapped()) && this.nodeId === other.nodeId;
  }

  static copy(other: TaskIdentifier) {
    return new TaskIdentifier(AnyFlowIdFactory.copyTyped(other.flowId), other.nodeId)
  }

  serialize() {
    return this.flowIdUnwrapped().urlSerialized() + "|" + this.nodeId;
  }
}

export class FormElementSummaryHelper {
  static extractFieldName(i: FormElementSummaryInterface) {
    const label: I18nText = Typed.value(i.elementRef).label;
    return label ? I18nText.copy(label).getCurrentWithFallback() : "";
  }
}

export interface FormElementSummaryInterface {
  // constructor(
  //   readonly elementRef: Typed<FormElementRef>,
  //   readonly element: Typed<FormElement>,
  //   readonly formSectionId: FormSectionId,
  readonly elementRef: Typed<any>,
    readonly variable: RootVariableWithType<BusinessVariable, BusinessVariableType>,
  //   readonly state: Option<CachedFormFieldState>,
  //   readonly readOnly: boolean
  // ) {
  // }
}

export class TaskModelSummary {

  public serializedId: string;

  constructor(
    public flowId: Typed<AnyFlowId>,
    public applicationId: Option<ApplicationId>,
    public processId: AggregateId,
    public instanceId: AggregateId,
    public nodeId: ProcessNodeId,
    public nodeType: NodeType,
    public automatic: boolean,
    public roleId: number,
    public cursorInfo: Option<CursorInfo>,
    public created: LocalDateTime,
    public started: Option<LocalDateTime>,
    public completed: Option<LocalDateTime>,
    public canceled: boolean,
    public deadline: Option<LocalDateTime>,
    public labels: Array<string>,
    public systemLabels: Array<Typed<BusinessVariable>>,
    public seen: boolean,
    public taskDescription: string, // used for task filtering
    public flowCode: Option<string>,
    public flowDescription: string,
    public processName: string,
    public nodeName: string,
    public flowContinued: boolean,
    public initialPersons: Array<Typed<AnyPersonId>>,
    public personsAssigned: Array<Typed<AnyPersonId>>,
    public assigneeLimit: number,
    public substitution: boolean,
    public mainEdges: Array<TaskEdge>,
    public availableAlternativeEdges: Array<TaskEdge>,
    public pullActions: Array<TaskPullAction>,
    public assignableToMe: boolean,
    public canAssignOther: boolean,
    public trackedTime: Array<TaskTrackedTime>,
    public estimatedDurationSeconds: Option<number>,
    public taskStatus: TaskStatus,
    public instanceColor: Option<string>,
    public flowColor: Option<string>,
    public colorOverride: Option<Option<string>>,
    public importance: number,
    public importanceOverride: Option<number>,
    public urgency: number,
    public urgencyOverride: Option<number>,
    public commentsCount: number,
    public formElementsVisibleInTaskBox: Array<FormElementSummaryInterface>, // TODO this should not be used as it exposes too much information
    public fieldsStateCachedValues: Array<[number, Typed<BusinessVariable>]>,
    public canChangeImportance: boolean,
    public canChangeUrgency: boolean,
    public canChangeLabels: boolean,
    public commentsAccess: boolean,
    public searchSummary: string,
    public working: boolean,
    readonly screenBased: boolean,
    readonly quickSubmitAllowed: boolean,
    public deadlineEditable: boolean
  ) {

    this.serializedId = Typed.value(flowId).urlSerialized() +"|" +nodeId;

  }

  fieldsStateCacheMapUnwrapped(): {[id: number]: BusinessVariable} {
    const cacheMap: {[id: number]: BusinessVariable} = {};
    this.fieldsStateCachedValues.forEach((entry: [number, Typed<BusinessVariable>]) => {
      cacheMap[entry[0]] = Typed.value(entry[1]);
    });
    return cacheMap;
  }

  currentImportance() {
    return this.importanceOverride.getOrElse(this.importance);
  }

  currentUrgency() {
    return this.urgencyOverride.getOrElse(this.urgency);
  }

  personsAssignedUnwrapped(): Array<AnyPersonId> {
    return this.personsAssigned.map(p => Typed.value(p));
  }

  initialPersonsUnwrapped(): Array<AnyPersonId> {
    return this.initialPersons.map(p => Typed.value(p));
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  update(other: TaskModelSummary) {
    this.flowId = other.flowId;
    this.applicationId = other.applicationId;
    this.processId = other.processId;
    this.instanceId = other.instanceId;
    this.nodeId = other.nodeId;
    this.nodeType = other.nodeType;
    this.automatic = other.automatic;
    this.roleId = other.roleId;
    this.cursorInfo = other.cursorInfo;
    this.created = other.created;
    this.completed = other.completed;
    this.canceled = other.canceled;
    this.deadline = other.deadline;
    this.labels = other.labels;
    this.systemLabels = other.systemLabels;
    this.seen = other.seen;
    this.taskDescription = other.taskDescription;
    this.flowCode = other.flowCode;
    this.flowDescription = other.flowDescription;
    this.processName = other.processName;
    this.nodeName = other.nodeName;
    this.flowContinued = other.flowContinued;
    this.personsAssigned = other.personsAssigned;
    this.assigneeLimit = other.assigneeLimit;
    this.substitution = other.substitution;
    this.mainEdges = other.mainEdges;
    this.availableAlternativeEdges = other.availableAlternativeEdges;
    this.pullActions = other.pullActions;
    this.assignableToMe = other.assignableToMe;
    this.canAssignOther = other.canAssignOther;
    this.trackedTime = other.trackedTime;
    this.estimatedDurationSeconds = other.estimatedDurationSeconds;
    this.taskStatus = other.taskStatus;
    this.instanceColor = other.instanceColor;
    this.flowColor = other.flowColor;
    this.colorOverride = other.colorOverride;
    this.importance = other.importance;
    this.importanceOverride = other.importanceOverride;
    this.urgency = other.urgency;
    this.urgencyOverride = other.urgencyOverride;
    this.commentsCount = other.commentsCount;
    this.formElementsVisibleInTaskBox = other.formElementsVisibleInTaskBox;
    this.fieldsStateCachedValues = other.fieldsStateCachedValues;
    this.searchSummary = other.searchSummary;
    this.deadlineEditable = other.deadlineEditable;
  }



  is(identifier: TaskIdentifier) {
    return AnyFlowIdHelper.equals(this.flowIdUnwrapped(), identifier.flowIdUnwrapped()) && this.nodeId === identifier.nodeId;
  }


  isAvailable() {
    return this.personsAssigned.length === 0 && this.started.isEmpty();
  }

  isInProgress() {
    return this.started.isDefined() && this.completed.isEmpty();
  }


  isInQueue() {
    return this.started.isEmpty() && this.completed.isEmpty();
  }

  isDone() {
    return this.completed.isDefined() && !this.canceled;
  }

  isDoneNDays(days: number): boolean {
    switch (days) {
      case 0: return this.completed.isEmpty();
      case 1: return this.isDoneToday();
      case 3: return this.isDone3Days();
      case 7: return this.isDone7Days();
      default: throw new Error("Unsupported days count [" + days + "]");
    }
  }

  isDoneToday() {
    return this.completed.isDefined() && this.completed.get().date.isEqual(LocalDate.nowDate());
  }

  isDone3Days() {
    if(this.completed.isDefined()) {
      const now = LocalDate.nowDate();
      const daysBetween = this.completed.get().date.daysBetween(now);
      switch(now.weekDay()) {
        case 0: return daysBetween < 5;
        case 1: return daysBetween < 5;
        case 2: return daysBetween < 5;
        case 3: return daysBetween < 3;
        case 4: return daysBetween < 3;
        case 5: return daysBetween < 3;
        case 6: return daysBetween < 4;
        case 7: return daysBetween < 5;
        default: throw new Error("Incorrect week day");
      }
    } else {
      return false;
    }
  }

  isDone7Days() {
    if(this.completed.isDefined()) {
      const now = LocalDate.nowDate();
      const daysBetween = this.completed.get().date.daysBetween(now);
      return daysBetween < 7;
    } else {
      return false;
    }
  }


  isTodo() {
    return this.started.isEmpty();
  }

  isEnded() {
    return this.completed.isDefined() || this.canceled;
  }

  isMaterialized() {
    return Typed.value(this.flowId).id.charAt(0) !== "_";
  }

  static copy(other: TaskModelSummary) {
    return new TaskModelSummary(
      AnyFlowIdFactory.copyTyped(other.flowId),
      Option.copy(other.applicationId, ApplicationId.of),
      AggregateId.copy(other.processId),
      AggregateId.copy(other.instanceId),
      other.nodeId,
      NodeType.copy(other.nodeType, other.automatic),
      other.automatic,
      other.roleId,
      Option.copy(other.cursorInfo),
      LocalDateTime.copy(other.created),
      Option.copy(other.started).map(LocalDateTime.copy),
      Option.copy(other.completed).map(LocalDateTime.copy),
      other.canceled,
      Option.copy(other.deadline).map(LocalDateTime.copy),
      other.labels.slice(),
      other.systemLabels.map(BusinessVariableFactory.copyTyped),
      other.seen,
      other.taskDescription,
      Option.copy(other.flowCode),
      other.flowDescription,
      other.processName,
      other.nodeName,
      other.flowContinued,
      other.initialPersons.map(AnyPersonIdFactory.copyTyped),
      other.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      other.assigneeLimit,
      other.substitution,
      other.mainEdges.map(TaskEdge.copy),
      other.availableAlternativeEdges.map(TaskEdge.copy),
      other.pullActions.map(TaskPullAction.copy),
      other.assignableToMe,
      other.canAssignOther,
      other.trackedTime.map(TaskTrackedTime.copy),
      Option.copy(other.estimatedDurationSeconds),
      TaskStatus.copy(other.taskStatus),
      Option.copy(other.instanceColor),
      Option.copy(other.flowColor),
      Option.copy(other.colorOverride, (c: Option<string>) => Option.copy(c)),
      other.importance,
      Option.copy(other.importanceOverride),
      other.urgency,
      Option.copy(other.urgencyOverride),
      other.commentsCount,
      other.formElementsVisibleInTaskBox, // TODO this is not copied, becaused it should not be exposed in task summary as whole model
      other.fieldsStateCachedValues.map((entry: [number, Typed<BusinessVariable>]) =>
        <[number, Typed<BusinessVariable>]>[entry[0], BusinessVariableFactory.copyTyped(entry[1])]),
      other.canChangeImportance,
      other.canChangeUrgency,
      other.canChangeLabels,
      other.commentsAccess,
      other.searchSummary,
      other.working,
      other.screenBased,
      other.quickSubmitAllowed,
      other.deadlineEditable
    );
  }

  toTaskIdentifier() {
    return new TaskIdentifier(this.flowId, this.nodeId);
  }


  systemLabelsUnwrapped(): Array<BusinessVariable> {
    return this.systemLabels.map(l => Typed.value(l));
  }
}

export class AssignableTask {
  constructor(readonly task: TaskModelSummary,
              readonly assignablePersons: Array<PersonId>) {}

  static copy(other: AssignableTask): AssignableTask {
    return new AssignableTask(TaskModelSummary.copy(other.task), other.assignablePersons.map(PersonId.of));
  }

  isAssigned() {
    return this.task.personsAssigned.length > 0;
  }

  isPersonAssignable(personId: PersonId) {
    return __(this.assignablePersons).exists((p: PersonId) => AnyPersonIdHelper.equals(p, personId));
  }

  isAssignedToPerson(personId: PersonId) {
    return this.isAssigned() && __(this.task.personsAssignedUnwrapped()).exists(p => AnyPersonIdHelper.equals(p, personId));
  }

}

export class TasksChanged {

  constructor(readonly added: Array<TaskModelSummary>,
              readonly updated: Array<TaskModelSummary>,
              readonly removed: Array<TaskIdentifier>) {}

  containsTask(flowId: AnyFlowId, nodeId: ProcessNodeId) {
    return this.taskRemoved(flowId, nodeId) || this.taskAdded(flowId, nodeId) || this.taskUpdated(flowId, nodeId);
  }

  static copy(other: TasksChanged) {
    return new TasksChanged(other.added.map(TaskModelSummary.copy), other.updated.map(TaskModelSummary.copy), other.removed.map(TaskIdentifier.copy));
  }

  taskRemoved(flowId: AnyFlowId, nodeId: ProcessNodeId) {
    return __(this.removed).exists((task: TaskIdentifier) => AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId) && task.nodeId === nodeId);
  }

  taskAdded(flowId: AnyFlowId, nodeId: ProcessNodeId) {
    return __(this.added).exists((task: TaskModelSummary) => AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId) && task.nodeId === nodeId);
  }

  taskUpdated(flowId: AnyFlowId, nodeId: ProcessNodeId) {
    return __(this.updated).exists((task: TaskModelSummary) => AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId) && task.nodeId === nodeId);
  }

}


export class LaunchableSummary {
  constructor(readonly processName: I18nText,
              readonly instanceName: I18nText,
              readonly startNodeName: I18nText,
              readonly startLabel: I18nText,
              readonly startNodeId: ProcessNodeId,
              readonly instanceId: Typed<AnyInstanceId>,
              readonly processReleaseId: AggregateId,
              readonly processId: AggregateId,
              readonly applicationId: Option<ApplicationId>,
              readonly color: Option<string>) {}

  static copy(other: LaunchableSummary) {
    return new LaunchableSummary(
      I18nText.copy(other.processName),
      I18nText.copy(other.instanceName),
      I18nText.copy(other.startNodeName),
      I18nText.copy(other.startLabel),
      other.startNodeId,
      AnyInstanceIdFactory.copyTyped(other.instanceId),
      AggregateId.copy(other.processReleaseId),
      AggregateId.copy(other.processId),
      Option.copy(other.applicationId).map(ApplicationId.of),
      Option.copy(other.color)
    );
  }

  instanceIdUnwrapped() {
    return Typed.value(this.instanceId);
  }

  isTaskFlow() {
    return this.instanceIdUnwrapped().id == Constants.taskProcessInstanceId.id;
  }

}


// Used for semi-type check between modules
export interface ProcessFlowDetailsInterface {}

// Used for semi-type check between modules
export interface TaskHistoryDetailsInterface {}
