import {None, Option, Some} from "@utils";
import {CssBuilder, CssUtils} from "@screen-common";

export class ComponentsCommon {
  static innerShadowCss(cssBuilder: CssBuilder, innerShadow: Option<boolean>): void {
    const innerShadowValue = innerShadow.map(v => v ? "inset 0 0.0875rem 0.25rem rgba(0, 0, 0, 0.05)" : "inset 0 0 0 black");
    CssUtils.shadows(cssBuilder, None(), innerShadowValue);
  }
}

export class HorizontalAlign {
  constructor(readonly name: string) {}

  static left = new HorizontalAlign("left");
  static center = new HorizontalAlign("center");
  static right = new HorizontalAlign("right");
  static justify = new HorizontalAlign("justify");

  static byName(name: string) {
    switch (name) {
      case HorizontalAlign.left.name: return HorizontalAlign.left;
      case HorizontalAlign.center.name: return HorizontalAlign.center;
      case HorizontalAlign.right.name: return HorizontalAlign.right;
      case HorizontalAlign.justify.name: return HorizontalAlign.justify;
      default: throw new Error("Unsupported value ["+name+"]");
    }
  }

  static copy(other: HorizontalAlign) {
    return HorizontalAlign.byName(other.name);
  }
}

