import {
  AuthenticatedHttp, PlannedTask,
  TaskIdentifier,
  UserFavoriteApplications,
  UserPinnedApplications,
  UserTasksPlan
} from "@shared-model";
import {Injectable} from "@angular/core";
import {ApplicationId, LocalDate, Option, Some} from "@utils";


@Injectable({
  providedIn: 'root'
})
export class UserVolatileSettingsSharedService {

  private tasksPlan?: Promise<Option<UserTasksPlan>>;
  private pinnedApplications?: Promise<Array<ApplicationId>>;
  private favoriteApplications?: Promise<Array<ApplicationId>>;

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  getUserTasksPlan(): Promise<Option<UserTasksPlan>> {
    if(!this.tasksPlan) {
      this.tasksPlan = this.authenticatedHttp.getPromise<Option<UserTasksPlan>>("user-volatile-settings/tasks-plan").then((data: Option<UserTasksPlan>) => {
        return Option.copy(data, UserTasksPlan.copy);
      });
    }
    return this.tasksPlan;
  }

  updateUserTasksPlan(plan: UserTasksPlan, onSuccess: () => void) {
    this.tasksPlan = Promise.resolve<Option<UserTasksPlan>>(Some(plan));
    this.authenticatedHttp.post("user-volatile-settings/update-tasks-plan", plan, (data: boolean) => {
      onSuccess();
    });
  }

  getUserPinnedApplications(): Promise<Array<ApplicationId>> {
    if(!this.pinnedApplications) {
      this.pinnedApplications = this.authenticatedHttp.getPromise<Option<UserPinnedApplications>>("user-volatile-settings/pinned-applications").then((data: Option<UserPinnedApplications>) => {
        return Option.copy(data, UserPinnedApplications.copy).map(a => a.applications).getOrElse([]);
      });
    }
    return this.pinnedApplications;
  }

  updateUserPinnedApplications(pinnedApplications: Array<ApplicationId>, onSuccess: () => void) {
    this.pinnedApplications = new Promise<Array<ApplicationId>>(resolve =>
      resolve(pinnedApplications));
    this.authenticatedHttp.post("user-volatile-settings/update-pinned-applications", new UserPinnedApplications(pinnedApplications), (data: boolean) => {
      onSuccess();
    });

  }


  getUserFavoriteApplications(): Promise<Array<ApplicationId>> {
    if(!this.favoriteApplications) {
      this.favoriteApplications = this.authenticatedHttp.getPromise<Option<UserFavoriteApplications>>("user-volatile-settings/favorite-applications").then((data: Option<UserFavoriteApplications>) => {
        return Option.copy(data, UserFavoriteApplications.copy).map(a => a.applications).getOrElse([]);
      });
    }
    return this.favoriteApplications;
  }

  updateUserFavoriteApplications(favoriteApplications: Array<ApplicationId>, onSuccess: () => void) {
    this.favoriteApplications = new Promise<Array<ApplicationId>>(resolve =>
      resolve(favoriteApplications));
    this.authenticatedHttp.post("user-volatile-settings/update-favorite-applications", new UserFavoriteApplications(favoriteApplications), (data: boolean) => {
      onSuccess();
    });
  }

}
