import {ApplicationId, DocumentationId, i18nLanguage} from "@utils";

export class DocumentationSummary {
  constructor(readonly id: DocumentationId,
              readonly name: string,
              readonly identifier: string,
              readonly applicationId: ApplicationId,
              readonly published: boolean,
              readonly languages: Array<string>) {}

  static copy(other: DocumentationSummary) {
    return new DocumentationSummary(
      DocumentationId.copy(other.id),
      other.name,
      other.identifier,
      ApplicationId.copy(other.applicationId),
      other.published,
      other.languages.slice()
    )
  }

  identifierOrId() {
    return this.identifier.length === 0 ? ("@" + this.id.id) : this.identifier;
  }

  getDefaultLanguage() {
    if(this.languages.indexOf(i18nLanguage().name) >= 0) {
      return i18nLanguage().name;
    } else if(this.languages.indexOf("en") >= 0) {
      return "en";
    } else if(this.languages.indexOf("pl") >= 0) {
      return "pl";
    } else if(this.languages.length > 0) {
      return this.languages[0];
    } else {
      return "en";
    }
  }
}
