import {BusinessVariable, TimeVariable} from "@shared-model";
import {TimeInputComponent} from "@screen-common";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";

export class TimeInputComponentState extends LabeledScreenComponentState {
  static className = "TimeInputComponentState";
  className() {
    return TimeInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalTimeProperty(TimeInputComponent.model);
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }


  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case TimeInputComponent.model:
        if(value.isDefined() && value.get() instanceof TimeVariable) {
          this.properties.putValue(TimeInputComponent.model, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Time but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(TimeInputComponent.model);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: TimeInputComponentState) {
    return new TimeInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class TimeInputComponentRefState extends ScreenComponentRefState {
  static className = "TimeInputComponentRefState";
  className() {
    return TimeInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: TimeInputComponentRefState) {
    return new TimeInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
