import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";

@Pipe({name: 'sanitizeStyle'})
export class SanitizeStylePipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {
  }

  transform(input: string|null): SafeStyle {
    if (input === null) {
      return this.sanitizer.bypassSecurityTrustStyle("");
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(input);
    }
  }

}
