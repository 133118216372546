<div class="my-terms-and-policy">

  <div class="termsAndPolicyHeader">

    <div class="title">{{'register_organization_form_terms_and_policy_label' | i18n}}</div>

    <div class="date">{{'register_organization_form_terms_and_policy_last_update' | i18n}}</div>

  </div>

  <div class="termsAndPolicyContent">

    <div class="title">{{'terms_and_conditions_I' | i18n}}</div>

    <div class="content">

      <div class="section1">
        <div class="item1">{{'terms_and_conditions_I_userAccount' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_client' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_serviceTerms' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_registration' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_service' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_platform' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_provider' | i18n}}</div>
        <div class="item1">{{'terms_and_conditions_I_user' | i18n}}</div>
      </div>

    </div>

    <div class="title">{{'terms_and_conditions_II' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_II_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_3' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_4' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_5' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_6' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_7' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_8' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_II_9' | i18n}}</li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_III' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_III_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_III_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_III_3' | i18n}}
          <ol type="1" class="section2">
            <li>{{'terms_and_conditions_III_3_a' | i18n}}</li>
            <li>{{'terms_and_conditions_III_3_b' | i18n}}</li>
            <li>{{'terms_and_conditions_III_3_c' | i18n}}</li>
            <li>{{'terms_and_conditions_III_3_d' | i18n}}</li>
          </ol>
        </li>
        <li class="item1">{{'terms_and_conditions_III_4' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_III_5' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_III_6' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_III_7' | i18n}}</li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_IV' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_IV_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_3' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_4' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_5' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_6' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_7' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_8' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_9' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_9_10' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_10' | i18n}}
          <ol type="1" class="section2">
            <li>{{'terms_and_conditions_IV_10_a' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_b' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_c' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_d' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_e' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_f' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_g' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_10_h' | i18n}}</li>
          </ol>
        </li>
        <li class="item1">{{'terms_and_conditions_IV_11' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_12' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_13_14' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_13' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_IV_14' | i18n}}
          <ol type="1" class="section2">
            <li>{{'terms_and_conditions_IV_14_a' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_14_b' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_14_c' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_14_d' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_14_e' | i18n}}</li>
          </ol>
        </li>
        <li class="item1">{{'terms_and_conditions_IV_14' | i18n}}
          <ol type="1" class="section2">
            <li>{{'terms_and_conditions_IV_15_a' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_15_b' | i18n}}</li>
            <li>{{'terms_and_conditions_IV_15_c' | i18n}}</li>
          </ol>
        </li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_V' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_V_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_V_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_V_3' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_V_4' | i18n}}</li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_VI' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_VI_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_3' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_4' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_5' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_6' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_7' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VI_8' | i18n}}</li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_VII' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_VII_1' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VII_2' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VII_3' | i18n}}</li>
        <li class="item1">{{'terms_and_conditions_VII_4' | i18n}}</li>
      </ol>

    </div>

    <div class="title">{{'terms_and_conditions_VIII' | i18n}}</div>

    <div class="content">

      <ol class="section1">
        <li class="item1">{{'terms_and_conditions_VIII_1' | i18n}}
          <ol type="1" class="section2">
            <li>{{'terms_and_conditions_VIII_1_a' | i18n}}</li>
            <li>{{'terms_and_conditions_VIII_1_b' | i18n}}</li>
            <li>{{'terms_and_conditions_VIII_1_c' | i18n}}</li>
            <li>{{'terms_and_conditions_VIII_1_d' | i18n}}</li>
            <li>{{'terms_and_conditions_VIII_1_e' | i18n}}</li>
            <li>{{'terms_and_conditions_VIII_1_f' | i18n}}</li>
          </ol>
        </li>
      </ol>

    </div>


  </div>

</div>
