import {Injectable} from "@angular/core";
import {AuthenticatedHttp} from "@shared-model";
import {SuggestionsApi} from "./expression-functions.model";
import {AggregateId, AnyFlowId, BusinessEntityTypeId, MailboxId, ScreenId} from "@utils";

@Injectable({providedIn: 'root'})
export class ExpressionFunctionsService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  private cachedSuggestions: { [url: string]: Promise<SuggestionsApi> } = {};

  getAvailableSuggestionsForReportFilter(reportId: AggregateId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-report/" + reportId.id);
  }

  getAvailableSuggestionsForMailboxTrigger(mailboxId: MailboxId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-mailbox-trigger/" + mailboxId.id);
  }

  getAvailableSuggestionsForFunctions(): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-functions");
  }

  getAvailableSuggestionsForProcess(processId: AggregateId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-process/" + processId.id);
  }

  getAvailableSuggestionsForScreen(screenId: ScreenId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-screen/" + screenId.id);
  }

  getAvailableSuggestionsForBusinessEntity(businessEntityType: BusinessEntityTypeId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-business-entity/" + businessEntityType.id);
  }

  getAvailableSuggestionsForFlowFilter(flowId: AnyFlowId): Promise<SuggestionsApi> {
    return this.getAvailableSuggestions("expression-functions/available-suggestions-for-flow/" + flowId.urlSerialized());
  }

  private getAvailableSuggestions(url: string): Promise<SuggestionsApi> {

    if (this.cachedSuggestions[url] !== undefined) {
      return this.cachedSuggestions[url];
    } else {

      const promise = this.authenticatedHttp.getPromise<SuggestionsApi>(url).then((data: SuggestionsApi) => {
        return SuggestionsApi.copy(data);
      });

      this.cachedSuggestions[url] = promise;
      return promise;
    }
  }

}
