import {MapComponentDefinition} from "@screen-common";
import {ArrayVariable, BusinessVariable, ObjectVariable} from "@shared-model";
import {None, Option} from "@utils";
import {
  BordersPropertiesState,
  LabeledScreenComponentState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";

export class MapComponentState extends LabeledScreenComponentState {

  static className = "MapComponentState";
  className() {
    return MapComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get selected() {
    return this.properties.optionalBusinessVariableProperty(MapComponentDefinition.SELECTED);
  }

  get mapBoundaries() {
    return this.properties.optionalBusinessVariableProperty(MapComponentDefinition.MAP_BOUNDARIES);
  }

  get mapCenter() {
    return this.properties.optionalBusinessVariableProperty(MapComponentDefinition.MAP_CENTER);
  }

  get mapCenterVisible() {
    return this.properties.optionalBooleanProperty(MapComponentDefinition.MAP_CENTER_VISIBLE);
  }

  get markers(): ArrayVariable<ObjectVariable> {
    const opt = <Option<ArrayVariable<ObjectVariable>>>this.properties.optionalObjectsArrayProperty(MapComponentDefinition.MARKERS).valueOrDefault(None());
    return opt.getOrElseLazy(() => new ArrayVariable<ObjectVariable>([]));
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case MapComponentDefinition.MARKERS:
        if(value.isDefined() && value.get() instanceof ArrayVariable) {
          this.properties.putValue(MapComponentDefinition.MARKERS, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Array[Object] but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(MapComponentDefinition.MARKERS);
        }
        break;
      case MapComponentDefinition.SELECTED:
        if(value.isDefined()) {
          this.properties.putValue(MapComponentDefinition.SELECTED, value.get());
        } else {
          this.properties.clearValue(MapComponentDefinition.SELECTED);
        }
        break;
      case MapComponentDefinition.MAP_BOUNDARIES:
        if(value.isDefined()) {
          this.properties.putValue(MapComponentDefinition.MAP_BOUNDARIES, value.get());
        } else {
          this.properties.clearValue(MapComponentDefinition.MAP_BOUNDARIES);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: MapComponentState) {
    return new MapComponentState(
      PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}

export class MapComponentRefState extends ScreenComponentRefState {
  static className = "MapComponentRefState";
  className() {
    return MapComponentRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  static copy(other: MapComponentRefState) {
    return new MapComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}

