import {None, Option, Some} from "./data-types/Option";

export class __Array<T> {

  private _value: ReadonlyArray<T>;

  constructor(value: ReadonlyArray<T>) {
    this._value = value;
  }

  isEmpty(): boolean {
    return this._value.length === 0;
  }

  value(): Array<T> {
    return <Array<T>>this._value;
  }

  find(predicate: (value: T, index: number) => boolean, fromIndex: number = 0, beforeIndex: number = this._value.length): Option<T> {
    for (let i = fromIndex; i < beforeIndex; i++) {
      if (predicate(this._value[i], i)) {
        return Some(this._value[i]);
      }
    }
    return None();
  }

  findIndexOf(predicate: (value: T) => boolean, fromIndex: number = 0, beforeIndex: number = this._value.length): Option<number> {
    for (let i = fromIndex; i < beforeIndex; i++) {
      if (predicate(this._value[i])) {
        return Some(i);
      }
    }
    return None();
  }

  findLastIndexOf(predicate: (value: T) => boolean, fromIndex: number = 0, beforeIndex: number = this._value.length): Option<number> {
    for (let i = beforeIndex - 1; i >= fromIndex; i--) {
      if (predicate(this._value[i])) {
        return Some(i);
      }
    }
    return None();
  }

  all(predicate: (value: T, index: number) => boolean): boolean {
    return this._value.every((v, i) => predicate(v, i));
  }

  exists(predicate: (value: T, index: number) => boolean): boolean {
    for (let i = 0; i < this._value.length; i++) {
      if (predicate(this._value[i], i)) {
        return true;
      }
    }
    return false;
  }

  notExists(predicate: (value: T, index: number) => boolean): boolean {
    return !this.exists(predicate);
  }

  contains(value: T): boolean {
    return this.find(v => v === value).isDefined();
  }

  notContains(value: T): boolean {
    return !this.contains(value);
  }

  count(predicate: (value: T, index: number) => boolean): number {
    let count = 0;
    for (let i = 0; i < this._value.length; i++) {
      if (predicate(this._value[i], i)) {
        count++;
      }
    }
    return count;
  }

  filter(predicate: (value: T, index: number) => boolean): Array<T> {
    return this._value.filter((v, i) => predicate(v, i));
  }

  filterNot(predicate: (value: T, index: number) => boolean): Array<T> {
    return this._value.filter((v, i) => !predicate(v, i));
  }

  remove(value: T): Array<T> {
    return this._value.filter(v => v !== value);
  }

  sum(): number {
    if (this._value.length === 0) {
      return 0;
    } else {
      let sum = 0;
      this._value.forEach(v => sum += <number>v);
      return sum;
    }
  }

  min(): number {
    if (this._value.length === 0) {
      throw new Error("Unable to find min in empty array");
    } else {
      let min = Number.MAX_VALUE;
      this._value.forEach(v => {
        if (<number>v < min) {
          min = <number>v;
        }
      });
      return min;
    }
  }

  max(): number {
    if (this._value.length === 0) {
      throw new Error("Unable to find max in empty array");
    } else {
      let max = -Number.MAX_VALUE;
      this._value.forEach(v => {
        if (<number>v > max) {
          max = <number>v;
        }
      });
      return max;
    }
  }

  minOrDefault(defaultMax: number): number {
    if (this._value.length === 0) {
      return defaultMax;
    } else {
      let min = Number.MAX_VALUE;
      this._value.forEach(v => {
        if (<number>v < min) {
          min = <number>v;
        }
      });
      return min;
    }
  }

  maxOrZero(): number {
    return this.maxOrDefault(0);
  }

  maxOrDefault(defaultMax: number): number {
    return this.maxOfOrDefault(v => <number>v, defaultMax);
  }

  maxOfOrDefault(transform: (value: T) => number, defaultMax: number): number {
    if (this._value.length === 0) {
      return defaultMax;
    } else {
      let max = -Number.MAX_VALUE;
      this._value.forEach(v => {
        const transformed = transform(v);
        if (transformed > max) {
          max = transformed;
        }
      });
      return max;
    }
  }

  maxOfOrZero(transform: (value: T) => number): number {
    return this.maxOfOrDefault(transform, 0);
  }


  forEach<R>(callback: (value: T) => R): void {
    return this._value.forEach(v => callback(v));
  }

  map<R>(transform: (value: T) => R): Array<R> {
    return this._value.map(v => transform(v));
  }

  flatMap<R>(transform: (value: T, index: number) => Array<R>): Array<R> {
    // TODO polifil
    return this._value.flatMap((v, index) => transform(v, index));
  }

  flatten<R>(): Array<R> {
    return <Array<R>>this._value.flat(Infinity);
  }


  /**
   * Returns stable sorted copy of array. Strings are compared content aware.
   */
  sortBy(value: (element: T) => any, second: ((element: T) => any)|undefined = undefined, third: ((element: T) => any)|undefined = undefined): Array<T> {
    const copy = this._value.slice();
    this.performSortInPlace(copy, value, second, third);
    return copy;
  }

  sortByInPlace(value: (element: T) => any, second: ((element: T) => any)|undefined = undefined, third: ((element: T) => any)|undefined = undefined): void {
    this.performSortInPlace(<Array<T>>this._value, value, second, third);
  }

  private performSortInPlace(arr: Array<T>, value: (element: T) => any, second: ((element: T) => any)|undefined = undefined, third: ((element: T) => any)|undefined = undefined): void {
    arr.sort((a, b) => {
      let vA = value(a);
      let vB = value(b);
      let compared = this.compare(vA, vB);
      if (compared == 0) {
        if (second) {
          vA = second(a);
          vB = second(b);
          compared = this.compare(vA, vB);
          if (compared == 0) {
            if (third) {
              vA = third(a);
              vB = third(b);
              return this.compare(vA, vB);
            } else {
              return 0;
            }
          } else {
            return compared;
          }
        } else {
          return 0;
        }
      } else {
        return compared;
      }
    });
  }

  private compare(a: any, b: any): number {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
    } else if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  sortByAlphanumeric(value: (element: T) => string, order: "asc"|"desc" = "asc"): Array<T> {
    return this.sortGivenArrayAlphanumeric(this._value.slice(), value, order);
  }


  sortByAlphanumericInPlace(value: (element: T) => string, order: "asc"|"desc" = "asc"): Array<T> {
    return this.sortGivenArrayAlphanumeric(<Array<T>>this._value, value, order);
  }

  private sortGivenArrayAlphanumeric(arr: Array<T>, value: (element: T) => string, order: "asc"|"desc" = "asc"): Array<T> {
    return arr.sort(
      (a, b) =>
        order === "asc"
          ? value(a).localeCompare(value(b), [], {numeric: true})
          : value(b).localeCompare(value(a), [], {numeric: true}));
  }

  sumOf<R>(transform: (value: T) => number, defaultSum: number = 0): number {

    if (this._value.length === 0) {
      return defaultSum;
    } else {
      let sum = 0;
      this._value.forEach(v => sum += transform(v));
      return sum;
    }
  }

  first(): T {
    if (this._value.length > 0) {
      return this._value[0];
    } else {
      throw new Error("Unable to get first element from empty array!");
    }
  }

  rest(): Array<T> {
    if (this._value.length > 0) {
      return this._value.slice(1);
    } else {
      throw new Error("Unable to get rest from empty array!");
    }
  }

  init(): Array<T> {
    if (this._value.length > 0) {
      return this._value.slice(0, this._value.length - 1);
    } else {
      throw new Error("Unable to get init from empty array!");
    }
  }

  firstOption(): Option<T> {
    if (this._value.length > 0) {
      return Some(this._value[0]);
    } else {
      return None();
    }
  }

  firstOrUndefined(): T|undefined {
    if (this._value.length > 0) {
      return this._value[0];
    } else {
      return undefined;
    }
  }

  last(): T {
    if (this._value.length > 0) {
      return this._value[this._value.length - 1];
    } else {
      throw new Error("Unable to get last element from empty array!");
    }
  }

  lastOption(): Option<T> {
    if (this._value.length > 0) {
      return Some(this._value[this._value.length - 1]);
    } else {
      return None();
    }
  }

  lastOrUndefined(): T|undefined {
    if (this._value.length > 0) {
      return this._value[this._value.length - 1];
    } else {
      return undefined;
    }
  }

  unique(): Array<T> {
    return [...new Set(this._value)];
  }

  /*
   * Returns array with unique elements by given value, takes first element with given value.
   */
  uniqueBy(value: (element: T) => string|number): Array<T> {
    const seen: {[key: string|number]: boolean} = {};

    return this._value.filter(v => {
      const by = value(v);
      if (seen[by]) {
        return false;
      } else {
        seen[by] = true;
        return true;
      }
    });
  }

  zipWithIndex(): Array<[T, number]> {
    return this._value.map((value, index) => <[T, number]>[value, index]);
  }

  partition(predicate: (element: T) => boolean): [Array<T>, Array<T>] {
    const truthy: Array<T> = [];
    const falsy: Array<T> = [];
    for (const element of this._value) {
      if (predicate(element)) {
        truthy.push(element);
      } else {
        falsy.push(element);
      }
    }
    return [truthy, falsy];
  }

  reduce<R>(initialValue: R, reducer: (accumulator: R, value: T) => R): R {
    return this._value.reduce((acc, value) => reducer(acc, value), initialValue);
  }

  groupByNumber<G extends number>(by: (c: T) => number): {[key: number]: Array<T>} {
    const result: {[key: number]: Array<T>} = {};
    this._value.forEach(v => {
      const key = by(v);
      if (result[key]) {
        result[key].push(v);
      } else {
        result[key] = [v];
      }
    });
    return result;
  }

  groupByString<G extends string>(by: (c: T) => string): Map<string, Array<T>> {
    const result = new Map<string, Array<T>>();
    this._value.forEach(v => {
      const key = by(v);
      if (result.has(key)) {
        result.get(key)!.push(v);
      } else {
        result.set(key, [v]);
      }
    });
    return result;
  }

  groupBy<G>(by: (c: T) => G): Map<G, Array<T>> {
    const result = new Map<G, Array<T>>();
    this._value.forEach(v => {
      const key = by(v);
      if (result.has(key)) {
        result.get(key)!.push(v);
      } else {
        result.set(key, [v]);
      }
    });
    return result;
  }
}

class WrappingFunctionalExtensions<T> {

  private _value: Array<T>;
  private wrapped: __Array<T>;

  constructor(value: Array<T>) {
    this._value = value;
    this.wrapped = new __Array(value);
  }

  /**
   * Returns stable sorted copy of array.
   */
  sortBy(value: (element: T) => any): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.sortBy(value));
  }

  sortByAlphanumeric(value: (element: T) => string): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.sortByAlphanumeric(value));
  }

  sortByAlphanumericInPlace(value: (element: T) => string): WrappingFunctionalExtensions<T> {
    this.wrapped.sortByAlphanumericInPlace(value);
    return this;
  }

  uniqueBy(value: (element: T) => string): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.uniqueBy(value));
  }

  value(): Array<T> {
    return this._value;
  }

  sum(): number {
    return this.wrapped.sum();
  }

  min(): number {
    return this.wrapped.min();
  }

  max(): number {
    return this.wrapped.max();
  }

  minOrDefault(defaultValue: number): number {
    return this.wrapped.minOrDefault(defaultValue);
  }

  maxOrZero() {
    return this.wrapped.maxOrZero();
  }

  maxOfOrZero(transform: (value: T) => number): number {
    return this.wrapped.maxOfOrZero(transform);
  }

  maxOrDefault(defaultValue: number): number {
    return this.wrapped.maxOrDefault(defaultValue);
  }

  sumOf<R>(transform: (value: T) => number, defaultSum: number = 0): number {
    return this.wrapped.sumOf(t => transform(t), defaultSum);
  }

  forEach(callback: (value: T) => void): WrappingFunctionalExtensions<T> {
    this.wrapped.forEach(v => callback(v));
    return this;
  }

  map<R>(transform: (value: T) => R): WrappingFunctionalExtensions<R> {
    return ___(this.wrapped.map(v => transform(v)));
  }

  flatMap<R>(transform: (value: T) => Array<R>): WrappingFunctionalExtensions<R> {
    return ___(this.wrapped.flatMap(v => transform(v)));
  }

  flatten<R>(): WrappingFunctionalExtensions<R> {
    return ___(this.wrapped.flatten());
  }

  first(): T {
    return this.wrapped.first();
  }

  rest(): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.rest());
  }

  init(): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.init());
  }

  last(): T {
    return this.wrapped.last();
  }

  firstOption(): Option<T> {
    return this.wrapped.firstOption();
  }

  lastOption(): Option<T> {
    return this.wrapped.lastOption();
  }

  find(predicate: (value: T) => boolean): Option<T> {
    return this.wrapped.find(v => predicate(v));
  }

  findIndexOf(predicate: (value: T) => boolean): Option<number> {
    return this.wrapped.findIndexOf(v => predicate(v));
  }

  all(predicate: (value: T) => boolean): boolean {
    return this.wrapped.all(v => predicate(v));
  }

  exists(predicate: (value: T) => boolean): boolean {
    return this.wrapped.exists(v => predicate(v));
  }

  notExists(predicate: (value: T) => boolean): boolean {
    return this.wrapped.notExists(v => predicate(v));
  }

  contains(value: T): boolean {
    return this.wrapped.contains(value);
  }

  notContains(value: T): boolean {
    return this.wrapped.notContains(value);
  }

  count(predicate: (value: T) => boolean): number {
    return this.wrapped.count(v => predicate(v));
  }

  filter(predicate: (value: T) => boolean): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.filter(v => predicate(v)));
  }

  filterNot(predicate: (value: T) => boolean): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.filterNot(v => predicate(v)));
  }

  remove(value: T): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.remove(value));
  }

  unique(): WrappingFunctionalExtensions<T> {
    return ___(this.wrapped.unique());
  }

  zipWithIndex(): WrappingFunctionalExtensions<[T, number]> {
    return ___(this.wrapped.zipWithIndex());
  }

  partition(predicate: (element: T) => boolean) {
    return this.wrapped.partition(predicate);
  }

  reduce<R>(initialValue: R, reducer: (accumulator: R, value: T) => R): R {
    return this.wrapped.reduce(initialValue, reducer);
  }

  size() {
    return this._value.length;
  }

  groupByNumber<G extends number>(by: (c: T) => number): {[key: number]: Array<T>} {
    return this.wrapped.groupByNumber(by);
  }
}

export function __<T>(wrapped: ReadonlyArray<T>): __Array<T> {
  return new __Array<T>(wrapped);
}

export function ___<T>(wrapped: Array<T>): WrappingFunctionalExtensions<T> {
  return new WrappingFunctionalExtensions<T>(wrapped);
}
