import {htmlEscape} from "./Html";
import {i18n} from "./I18n";
import {toastr} from "./toastr";

export function handleError(reason: any) {
  if(reason.status === 0 || reason.status === -1) {
    toastr.error(i18n("restCodes_0"));
  } else if (reason.status === 500) {
    toastr.error(i18n("restCodes_500"));
  } else if (reason.status === 404) {
    toastr.error(i18n("restCodes_404"));
  } else if (reason.status === 403) {
    toastr.error(i18n("restCodes_403"));
  } else if (reason.status === 400) {
    toastr.error(i18n("restCodes_400"));
    console.error("Incorrect request", reason.data);
  } else {
    toastr.error(i18n("restCodes_unknown") + "(" + htmlEscape(reason.status) + ")");
  }
}
