import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {__, AnyFlowId, AnyPersonId, AnyPersonIdHelper, None, Search, Typed} from "@utils";
import {
  BasicPersonInfo,
  FlowsSharedService,
  OrganizationSharedService,
  PersonsSharedService,
  ProcessRoleId,
  TaskIdentifier
} from "@shared-model";
import {AssignablePersonInfo} from "./persons-assigned.component";
import {SessionServiceProvider} from "@shared";

export class RoleInFlow {
  constructor(readonly flowId: AnyFlowId,
              readonly roleId: ProcessRoleId,) {}
}

@Component({
  selector: "my-persons-assigned-search",
  templateUrl: "./persons-assigned-search.component.html",
})
export class PersonsAssignedSearchComponent implements OnInit {

    @Input() taskIdentifier?: TaskIdentifier;
    @Input() roleInFlow?: RoleInFlow;

    foundPersons: Array<AssignablePersonInfo> = [];

    query = "";

    activeSuggestionIndex = -1;
    private anyPersonPressed = false;
    @Input() assignedPersons: Array<AnyPersonId> = [];

    @Output() personAssigned = new EventEmitter<AnyPersonId>();
    @Output() personUnassigned = new EventEmitter<AnyPersonId>();

    @Input() showAssignedPersons = false;

    allMode = false;
    assignedMode = true;

    @Input() otherModesAllowed = false;

    constructor(private readonly flowSharedService: FlowsSharedService,
                private readonly personsSharedService: PersonsSharedService,
                private readonly sessionServiceProvider: SessionServiceProvider,
                private readonly organizationSharedService: OrganizationSharedService) {
    }

    ngOnInit(): void {
      this.activeSuggestionIndex = -1;
      this.queryChanged();
    }
    keyPressed = ($event: KeyboardEvent) => {
        if ($event.keyCode == 38) { // up arrow
            this.previousSuggestion();
            $event.preventDefault();
        } else if ($event.keyCode == 40) { // down arrow
            this.nextSuggestion();
            $event.preventDefault();
        } else if ($event.keyCode === 13) { // enter
            if (this.foundPersons.length > 0 && this.activeSuggestionIndex >= 0) {
                this.personClicked(this.foundPersons[this.activeSuggestionIndex]);
            }
            $event.preventDefault();
        }
    };

    private previousSuggestion() {
        this.activeSuggestionIndex--;
        this.activeSuggestionIndex = Math.max(-1, Math.min(this.activeSuggestionIndex, this.foundPersons.length - 1));
    }

    private nextSuggestion() {
        this.activeSuggestionIndex++;
        this.activeSuggestionIndex = Math.max(-1, Math.min(this.activeSuggestionIndex, this.foundPersons.length - 1));
    }

    queryChanged = () => {

      if(!this.taskIdentifier && !this.roleInFlow) {
          throw new Error("taskIdentifier nor roleInFLow is not set");
      } else {


        let persons: Promise<Array<AnyPersonId>>;

        if (this.allMode) {
          persons = this.organizationSharedService.findPersonsByTextQuery(this.query.trim(), None(), 50).then(r => r.persons);
        } else if (this.taskIdentifier) {
          persons = this.flowSharedService.findPersonsAssignableToTask(this.taskIdentifier, this.query.trim());
        } else if (this.roleInFlow) {
          persons = this.flowSharedService.findPersonsAssignableToRole(this.roleInFlow.flowId, this.roleInFlow.roleId, this.query.trim());
        } else {
          persons = Promise.reject("roleInFlow nor taskIdentifier is not set");
        }

        persons.then((foundPersons: Array<AnyPersonId>) => {

          this.personsSharedService.findPersonBasicInfo(foundPersons.concat(this.assignedPersons), (personInfo: {
            [personId: string]: BasicPersonInfo
          }) => {

            this.sessionServiceProvider.getOrganizationSessionInfo((sessionInfo) => {

              this.foundPersons = foundPersons.filter(p => p.isPersonId()).map(pId => {
                const found = personInfo[pId.asPersonId().id.id];
                if (found != null) {
                  return AssignablePersonInfo.fromBasicInfo(found);
                } else {
                  return new AssignablePersonInfo(Typed.of(pId), "Person", pId.serialize(), "", None(), "");
                }
              });

              const foundAssignedPersons = (this.showAssignedPersons
                ? Search.filter<BasicPersonInfo>(this.assignedPersons.map(p => personInfo[p.asPersonId().id.id]), p => p.matchable(), this.query.trim())
                : []).map(p => AssignablePersonInfo.fromBasicInfo(p));

              foundAssignedPersons.forEach(p => p.markSelected())

              this.foundPersons = this.foundPersons.filter(p => !__(this.assignedPersons).exists(pp => AnyPersonIdHelper.equals(p.personIdUnwrapped(), pp)));


              if (this.query.trim().length > 0) {
                const words = this.query.trim().split(/\s/);
                this.foundPersons = this.foundPersons.filter(p => {
                  const matchable = p.matchable();
                  return __(words).all(w => matchable.indexOf(w) >= 0);
                })
              }

              this.foundPersons = __(this.foundPersons).sortByAlphanumeric(p => {
                if (sessionInfo.personId.id == p.personIdUnwrapped().asPersonId().id.id) {
                  return "000" + p.sortName();
                } else {
                  return p.sortName();
                }
              });

              if (this.foundPersons.length > 20) {
                this.foundPersons = this.foundPersons.slice(0, 20);
              }


              this.foundPersons = foundAssignedPersons.concat(this.foundPersons);


            });
          });
        });
      }
  };


  personSelected = (person: AssignablePersonInfo) => {
    this.personAssigned.emit(person.personIdUnwrapped());
    this.query = "";
  }

  personClicked = (person: AssignablePersonInfo) => {
    if(person.selected) {
      this.personUnassigned.emit(person.personIdUnwrapped());
    } else {
      this.personAssigned.emit(person.personIdUnwrapped());
    }
  };

  setAnyPersonPressed = (pressed: boolean) => {
      this.anyPersonPressed = pressed;
  };

  changeToAssignedMode = () => {
    this.assignedMode = true;
    this.allMode = false;
    this.queryChanged();
  };

  changeToAllMode = () => {
    this.assignedMode = false;
    this.allMode = true;
    this.queryChanged();
  }
}
