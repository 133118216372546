import {Component} from '@angular/core';

interface Property {
  name: string,
  style: string
}


@Component({
  templateUrl: './fonts-test-page.component.html'
})
export class FontsTestPageComponent {

  sampleText = this.defaultIfEmpty(localStorage.getItem("testFontSampleText"), "Lipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nisl eget");

  weights: Array<Property> = [
    {name: "100", style: "100"},
    {name: "200", style: "200"},
    {name: "300", style: "300"},
    {name: "400 (normal)", style: "400"},
    {name: "500", style: "500"},
    {name: "600", style: "600"},
    {name: "700 (bold)", style: "700"},
    {name: "800", style: "800"},
  ];

  sizes: Array<Property> = [
    {name: "10px", style: "10px"},
    {name: "11px", style: "11px"},
    {name: "12px", style: "12px"},
    {name: "13px", style: "13px"},
    {name: "14px", style: "14px"},
    {name: "15px", style: "15px"},
    {name: "16px", style: "16px"},
  ];

  fontStyles: Array<Property> = [
    {name: "normal", style: "normal"},
    {name: "italic", style: "italic"},
  ];

  sampleTextChanged() {
    if(this.sampleText.trim().length > 0) {
      localStorage.setItem("testFontSampleText", this.sampleText.trim());
    } else {
      localStorage.removeItem("testFontSampleText");
    }
  }

  private defaultIfEmpty(value: string|null, def: string): string {
    if(value === null || value === undefined) {
      return def;
    } else {
      return value;
    }
  }
}
