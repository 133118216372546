<ng-container *ngIf="searchBarMode">
  <button class="toggle theme-highlighted-button" #Button (click)="toggleMenu()">
    <div class="theme-button-highlight"></div>
    <ng-container *ngIf="selectedList.length === 0"><i class="applicationIcon mi-apps"></i>{{'tasks_all_applications' | i18n}}</ng-container>

    <ng-container *ngIf="selectedList.length > 0">
      <div class="selected color{{selectedList[0].applicationIcon.colorNo}}">
        <i class="applicationIcon {{selectedList[0].applicationIcon.icon}}"></i>{{selectedList[0].name}}
        <span *ngIf="selectedList.length > 1" class="theme-count-badge-small">+{{selectedList.length - 1}}</span>
        <button class="clearFilter" *ngIf="selectedList.length > 0" (click)="clearSelection(); $event.stopPropagation(); false;"><i class="mi-delete"></i></button>
      </div>
    </ng-container>
  </button>
  <my-drop-menu [anchor]="Button" [(visible)]="opened" cssClass="applicationFilterSelector theme-selector-drop-menu" horizontalPosition="end">
    <my-scroll *ngIf="opened" [adjustHeight]="true" [contentSizeChanged]="contentSizeChanged" [horizontal]="false" [adjustWidth]="true">

      <div class="theme-drop-menu-filter">
        <i class="mi-search"></i>
        <input type="text" [(ngModel)]="query" (ngModelChange)="queryChanged()" [focus-on-show]="true" [placeholder]="'tasks_search_application' | i18n"/>
      </div>

      <hr class="theme-drop-menu-separator" />

      <ng-container *ngIf="allApplicationsVisible">

        <button (click)="clearSelection()" class="theme-drop-menu-entry specialEntry"
                [class.selected]="selectedList.length === 0">
          <i class="mi-apps innerApplicationIcon"></i>
          <span class="name">{{'tasks_all_applications' | i18n}}</span>
          <button class="selector" (click)="clearSelection(); $event.stopPropagation();">
            <i class="mi-check"></i>
          </button>
        </button>

        <hr class="theme-drop-menu-separator additional-separator" />
      </ng-container>
  <!--    <div class="theme-content-horizontal-separator></div>-->
      <button *ngFor="let selector of filteredSelectors; let index = index"
              class="color{{selector.applicationIcon.colorNo}} theme-drop-menu-entry" (click)="selectApplication(selector)"
              [class.selected]="selector.selected" [class.first]="index == 0">
        <i class="{{selector.applicationIcon.icon}} innerApplicationIcon"></i>
        <span class="name">{{selector.name}}</span>
        <span class="count">{{selector.count}}</span>
        <button class="selector" (click)="toggleSelected(selector); $event.stopPropagation();">
          <i class="mi-check"></i>
        </button>
      </button>
    </my-scroll>
  </my-drop-menu>
</ng-container>

<div *ngIf="!searchBarMode" class="applicationFilterSelectorSidePanel">
  <div class="filterEntry color{{selector.applicationIcon.colorNo}}" *ngFor="let selector of filteredSelectors; let index = index" [class.first]="index == 0">
    <my-checkbox [checked]="selector.selected" (checkedChange)="toggleSelected(selector)">
      <i class="{{selector.applicationIcon.icon}} innerApplicationIcon"></i>
      <span class="name">{{selector.name}}</span>
    </my-checkbox>
    <span class="count">{{selector.count}}</span>
  </div>
</div>
