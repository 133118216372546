import {AggregateVersion, ApplicationId, ApplicationIdentifier, I18nText, OrganizationId, PersonId} from "@utils";
import {ProcessInfo} from "../process/ProcessModel";
import {ApplicationAuthorizations} from "../../modules/applications.module/model/ApplicationModel";
import {Constants} from "@utils";

export class ApplicationSummary {
  constructor(readonly id: ApplicationId,
              readonly version: AggregateVersion,
              readonly name: string,
              readonly description: string,
              readonly categoryId: number,
              readonly ownerId: PersonId,
              readonly identifier: string,
              readonly colorId: number,
              readonly iconCode: string,
              readonly active: boolean) {
  }

  static copy(other: ApplicationSummary) {
    return new ApplicationSummary(
      ApplicationId.of(other.id),
      AggregateVersion.copy(other.version),
      other.name,
      other.description,
      other.categoryId,
      PersonId.of(other.ownerId),
      other.identifier,
      other.colorId,
      other.iconCode,
      other.active);
  }
}

export class ApplicationName {
  static global: ApplicationName = new ApplicationName(ApplicationId.of("global"), "Processes", "", 0, "globe");

  constructor(readonly id: ApplicationId,
              readonly name: string,
              readonly identifier: ApplicationIdentifier,
              readonly colorId: number,
              readonly iconCode: string) {
  }


  static copy(other: ApplicationName) {
    return new ApplicationName(
      ApplicationId.of(other.id),
      other.name, other.identifier, other.colorId, other.iconCode);
  }

}


export class ProcessesAndApplications {
  constructor(readonly processes: Array<ProcessInfo>, readonly applications: Array<ApplicationSummary>) {}

  isEmpty(): boolean {
    return this.processes.length === 0
      && this.applications.length === 0;
  }

  static copy(other: ProcessesAndApplications): ProcessesAndApplications {
    return new ProcessesAndApplications(
      other.processes.map(ProcessInfo.copy),
      other.applications.map(ApplicationSummary.copy));
  }
}


export class ApplicationInfo {
  constructor(
    readonly id: ApplicationId,
    readonly version: AggregateVersion,
    readonly organizationId: OrganizationId,
    readonly name: I18nText,
    readonly codeName: string,
    readonly description: I18nText,
    readonly applicationCategoryId: number,
    readonly ownerId: PersonId,
    readonly authorizations: ApplicationAuthorizations,
    readonly colorId: number,
    readonly iconCode: string,
    readonly active: boolean
  ) {
  }

  static copy(other: ApplicationInfo) {
    return new ApplicationInfo(
      ApplicationId.of(other.id),
      AggregateVersion.copy(other.version),
      OrganizationId.of(other.organizationId),
      I18nText.copy(other.name),
      other.codeName,
      I18nText.copy(other.description),
      other.applicationCategoryId,
      PersonId.of(other.ownerId),
      ApplicationAuthorizations.copy(other.authorizations),
      other.colorId,
      other.iconCode,
      other.active
      );
  }

  static global() {
    return new ApplicationInfo(Constants.globalApplicationId, AggregateVersion.ZERO, OrganizationId.of(""),
      I18nText.ofCurrent("Global components"), "", I18nText.empty(), 0, Constants.platformPersonId, ApplicationAuthorizations.empty(), 0, "", true);
  }
}
