import {Component, EventEmitter, Input, Output} from "@angular/core";
import {__, ___, AnyPersonId, AnyPersonIdHelper, i18n, PersonId} from "@utils";
import {SessionServiceProvider} from "@shared";
import {BasicOrganizationNodeInfo} from "../../../organization-structure/OrganizationNodesModel";
import {PersonsSharedService} from "../../../organization-structure/persons.shared-service";
import {BasicPersonInfo} from "../../../organization-structure/PersonModel";
import {Search} from "../../../../utils/Search";

export interface PersonOption {
  id: AnyPersonId;
  count: string;
}

export interface PersonSelector {
  id: AnyPersonId;
  count: string;
  selected: boolean;
  name: string;
}


@Component({
  selector: 'my-person-filter-selector',
  templateUrl: './person-filter-selector.component.html',
})
export class PersonFilterSelectorComponent {
  private _available: Array<PersonOption> = [];
  private _selected: Array<AnyPersonId> = [];

  allSelectors: Array<PersonSelector> = [];
  filteredSelectors: Array<PersonSelector> = [];
  selectedList: Array<AnyPersonId> = [];

  selectedCount = 0;

  availableMode: boolean = true;
  allMode: boolean = false;

  opened: boolean = false;

  query: string = "";

  currentUser: Promise<PersonId>;
  currentUserName: Promise<string>;


  assignedToNoOneCount: string = "";
  assignedToNoOneVisible: boolean = true;
  assignedToNoOneSelected: boolean = false;

  assignedToMeCount: string = "";
  assignedToMeVisible: boolean = true;
  assignedToMeSelected: boolean = false;

  @Input() searchBarMode: boolean = true;

  @Input({required: true}) anyoneLabel: string = "";
  @Input({required: true}) meLabel: string = "";
  @Input({required: true}) mainLabel: string = "";
  @Input({required: true}) noOneLabel: string = "";

  @Input() set mode(mode: "available"|"all") {
    this.availableMode = mode === "available";
    this.allMode = mode === "all";
  }

  @Input() set selected(selected: Array<AnyPersonId>) {
    this._selected = selected;
    this.updateSelectors();
    this.updateSelectedFlags();
    if(this.allMode) {
      this.selectedList = selected;
    } else {
      this.selectedList = this.available.filter(assignedTo => __(this._selected).exists(a => AnyPersonIdHelper.equals(a, assignedTo.id))).map(a => a.id);
    }

  };
  @Output() selectedChange = new EventEmitter<Array<AnyPersonId>>();
  contentSizeChanged = new EventEmitter<void>;

  @Input() set available(available: Array<PersonOption>) {
    if(this.availableMode && available.length > 0) {
      this.currentUser.then(currentUser => {

        this._available = available.filter(a => !AnyPersonIdHelper.equals(a.id, PersonId.NO_ONE) && !AnyPersonIdHelper.equals(a.id, currentUser));

        this.assignedToNoOneCount = __(available).find(a => AnyPersonIdHelper.equals(a.id, PersonId.NO_ONE)).getOrError("No NO_ONE available").count;
        this.assignedToMeCount = __(available).find(a => AnyPersonIdHelper.equals(a.id, currentUser)).getOrError("No Current user available").count;


        this.selectedList = this.available.filter(assignedTo => __(this._selected).exists(a => AnyPersonIdHelper.equals(a, assignedTo.id))).map(a => a.id);
        this.updateSelectors();
        this.updateSelectedFlags();

      });
    }
  };



  constructor(sessionService: SessionServiceProvider,
              readonly personsSharedService: PersonsSharedService) {

    const sessionInfo = sessionService.getOrganizationSessionInfoPromise();

    this.currentUser = sessionInfo.then(sessionInfo => PersonId.of(sessionInfo.personId));
    this.currentUserName = sessionInfo.then(sessionInfo => sessionInfo.userSimpleName());

  }

  toggleMenu() {
    this.opened = !this.opened;
    this.query = "";
    this.filterByQuery();
  }


  get selected(): Array<AnyPersonId> {return this._selected;}




  get available(): Array<PersonOption> {
    return this._available;
  }



  private updateSelectors() {

    if(this.availableMode) {
      this.personsSharedService.findPersonBasicInfo(this._available.map(a => a.id), (info: { [personId: string]: BasicPersonInfo }) => {


        this.selectedList = ___(this._available).filter(person => __(this._selected).exists(a => a.equals(person.id))).map(p => p.id).value();
        this.allSelectors = ___(this._available).map(a => {

          const personInfo:BasicPersonInfo|undefined = info[a.id.serialize()];
          const name = personInfo ? personInfo.firstName + " " + personInfo.lastName : "Deleted " + a.id.serialize();
          return {
            id: a.id,
            name: name,
            count: a.count,
            selected: __(this._selected).exists(s => s === a.id),
          }
        }).sortByAlphanumeric(a => a.name).value();
        this.filterByQuery();


      });
    }
  }

  selectAssignedToNoOne() {
    this.selectPerson(PersonId.NO_ONE);
  }

  selectPerson(assignedTo: AnyPersonId) {
    this.selectedChange.emit([assignedTo]);
    this._selected = [assignedTo];
    this.opened = false;
    this.updateSelectedFlags();
  }

  clearSelection() {
    this.selectedChange.emit([]);
    this._selected = [];
    this.opened = false;
  }

  selectAssignedToMe() {
    this.currentUser.then(currentUser => {
      this.selectPerson(currentUser);
    });
  }

  toggleAssignedToNoOne() {
    this.allSelectors.forEach(s => {
      if(s.id.equals(PersonId.NO_ONE)) {
        s.selected = !s.selected;
      }
    });
    this.assignedToNoOneSelected = !this.assignedToNoOneSelected;
    this.updateSelected();
    this.updateSelectedFlags();
  }

  toggleAssignedToMe() {
    this.currentUser.then(currentUser => {
      this.allSelectors.forEach(s => {
        if(s.id.equals(currentUser)) {
          s.selected = !s.selected;
        }
      });
      this.assignedToMeSelected = !this.assignedToMeSelected;
      this.updateSelected();
      this.updateSelectedFlags();
    });
  }

  updateSelectedFlags() {
    this.currentUser.then(currentUser => {
      this.assignedToNoOneSelected =  __(this._selected).exists(a => AnyPersonIdHelper.equals(a, PersonId.NO_ONE));
      this.assignedToMeSelected = __(this._selected).exists(a => AnyPersonIdHelper.equals(a, currentUser));
      if(this.allMode) {
        this.selectedCount = this._selected.length;
      } else {
        this.selectedCount = this.selectedList.length + (this.assignedToNoOneSelected ? 1 : 0) + (this.assignedToMeSelected ? 1 : 0);
      }

    });
  }


  newAssignedToSelected(node: BasicOrganizationNodeInfo) {
    const personId = node.organizationNodeId.asPerson();
    this._selected = [personId];
    this.opened = false;
    this.selectedChange.emit([personId]);
    this.selectedList = [personId];
    this.updateSelectedFlags();
  }

  newAssignedToCleared() {
    this._selected = [];
    this.opened = false;
    this.selectedChange.emit([]);
    this.updateSelectedFlags();
  }

  toggleSelected(person: PersonSelector) {
    person.selected = !person.selected;
    this.selectedList = ___(this.allSelectors).filter(a => a.selected).sortByAlphanumeric(a => a.name).map(e => e.id).value();
    this.updateSelected();
  }

  updateSelected() {
    this.currentUser.then(currentUser => {
      this._selected = this.allSelectors.filter(a => a.selected).map(a => a.id);
      if(this.assignedToNoOneSelected) {
        this._selected.push(PersonId.NO_ONE);
      }
      if(this.assignedToMeSelected) {
        this._selected.push(currentUser);
      }
      this.selectedChange.emit(this._selected);
    });
  }

  queryChanged() {
    this.filterByQuery();
  }

  filterByQuery() {
    if(this.query === "") {
      this.filteredSelectors = this.allSelectors;
      this.assignedToMeVisible = true;
      this.assignedToNoOneVisible = true;
    } else {
      this.filteredSelectors = Search.filter(this.allSelectors, a => a.name, this.query);


      this.assignedToNoOneVisible = Search.matches(i18n('tasks_unassigned'), this.query);

      this.currentUserName.then(currentUserName => {
        this.assignedToMeVisible = Search.anyMatches([i18n('tasks_me'), currentUserName], this.query);
      });
    }
    this.contentSizeChanged.emit();
  }
}
