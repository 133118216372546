import {None, Option, Some} from "./Option";

export class Either<L, R> {

  constructor(private readonly left: L|undefined,
              private readonly right: R|undefined) {
  }

  isLeft() {
    return this.left !== undefined;
  }

  isRight() {
    return this.right !== undefined;
  }

  getRight(): R {
    if(this.right !== undefined) {
      return this.right;
    } else {
      throw new Error("Not right");
    }
  }

  getLeft(): L {
    if(this.left !== undefined) {
      return this.left;
    } else {
      throw new Error("Not left");
    }
  }

  map<LR, RR>(leftConverter: (left:L) => LR, rightConverter: (right: R) => RR): Either<LR, RR> {
    if(this.left !== undefined) {
      return new Either<LR, RR>(leftConverter(this.left), undefined);
    } else if (this.right !== undefined) {
      return new Either<LR, RR>(undefined, rightConverter(this.right));
    } else {
      throw new Error("No left, nor right");
    }
  }

  get leftOrRight(): L|R {
    if(this.left !== undefined) {
      return this.left;
    } else if(this.right !== undefined) {
      return this.right;
    } else {
      throw new Error("No left, nor right");
    }
  }

  static left<L, R>(l: L) {
    return new Either<L, R>(l, undefined);
  }

  static right<L, R>(r: R) {
    return new Either<L, R>(undefined, r);
  }

  static copy<L, R>(other: Either<L, R>, copyLeft: (left: L) => L, copyRight: (right: R) => R): Either<L, R> {
    if(other.left !== undefined) {
      return new Either<L, R>(copyLeft(other.left), undefined);
    } else if(other.right !== undefined) {
      return new Either<L, R>(undefined, copyRight(other.right));
    } else {
      throw new Error("No left, nor right");
    }
  }

  equals(other: Either<L, R>, equalsL: (a: L, b: L) => boolean, equalsR: (a: R, b: R) => boolean) {
    if(this.left !== undefined && other.left !== undefined) {
      return equalsL(this.left, other.left);
    } else if(this.right !== undefined && other.right !== undefined) {
      return equalsR(this.right, other.right);
    } else {
      return false;
    }
  }

  leftOption(): Option<L> {
    if(this.left !== undefined) {
      return Some(this.left);
    } else {
      return None();
    }
  }

  rightOption(): Option<R> {
    if(this.right !== undefined) {
      return Some(this.right);
    } else {
      return None();
    }
  }
}

export const Left = Either.left;
export const Right = Either.right;
