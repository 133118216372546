<input type="text" class="theme-input dateTimeInput" #InputElement
       [(ngModel)]="model"
       (focus)="onFocus()"
       [disabled]="disabled"
       [placeholder]="placeholder"
       (ngModelChange)="onInputValueChange()"
       (keydown.enter)="onInputValueConfirm()"/>

<my-drop-menu cssClass="dateTimeInputPopup" [(visible)]="focused" [anchor]="InputElement" (closed)="onPickerClosed()">
  <ng-container *ngIf="focused">
    <div class="actions pickerActions">
      <button *ngIf="nowButton" class="actionButton setNow" (click)="setNow()">{{'common_now' | i18n}}</button>
      <button *ngIf="clearButton" class="actionButton setEmpty" (click)="setEmpty()">{{'common_clear' | i18n}}</button>
    </div>
    <div class="board">
      <my-date-picker-board [(date)]="dateValue" (picked)="onPickerDateValueSelected()" />
      <my-time-picker-board [(time)]="timeValue" (picked)="onPickerTimeValueSelected()" />
    </div>
  </ng-container>
</my-drop-menu>
