<!--<svg viewBox="-16 -16 64 64">-->
<svg viewBox="-1 -1 34 34" [dark-mode-class]="'darkMode'" [class.large]="large">
  <defs>
    <linearGradient id="gradientA" x1="26.5" y1="9.50008" x2="-3.44474" y2="21.1324" gradientUnits="userSpaceOnUse">
      <stop class="stopA1" stop-color="#1ABC9C"/>
      <stop class="stopA2" offset="1" stop-color="#1DD7B2"/>
    </linearGradient>
    <linearGradient id="gradientB" x1="18.0002" y1="15.5" x2="36.5002" y2="-3.5" gradientUnits="userSpaceOnUse">
      <stop class="stopB1" stop-color="#1F1646"/>
      <stop class="stopB2" offset="1" stop-color="#0F347D"/>
    </linearGradient>
  </defs>
  <path class="b" d="M0 18.9158C0 25.7076 4.16731 30.0001 10.4183 30.0001C16.6693 30.0001 20.8366 23.5613 20.8366 23.5613C20.8366 23.5613 5.35037 7.82201 0 2.09863V18.9158Z"
        style="fill:url(#gradientA);" />
  <path class="a" d="M30.0001 11.0843C30.0001 4.29253 25.8328 0 19.5819 0C13.3309 0 9.16357 6.4388 9.16357 6.4388C9.16357 6.4388 24.6498 22.1781 30.0001 27.9015V11.0843Z"
        style="fill:url(#gradientB);" />
</svg>

<span class="logoName" [class.large]="large" *ngIf="withName">neula</span>
