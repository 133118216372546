// export function onEscapeKeyOnce(callback: () => void): any { //returns cancel function
//   const escapeListener = function() {
//     if((<any>event).keyCode === 27) {
//       callback();
//       document.removeEventListener("keydown", escapeListener);
//     }
//   };
//
//   document.addEventListener("keydown", escapeListener);
//
//   // Call this to cancel listening function
//   return function() {
//     document.removeEventListener("keydown", escapeListener);
//   }
// }

import {$$, $$Element} from "../$$";
import {__} from "../__";
import {mySetTimeout} from "../Scheduler";

export function onFormFocusLost(selections: HTMLElement|$$Element|string, callback: (eventTarget?: EventTarget) => void): () => void;
export function onFormFocusLost(selections: Array<HTMLElement|$$Element|string>, callback: (eventTarget?: EventTarget) => void): () => void;
export function onFormFocusLost(selections: Array<HTMLElement|$$Element|string>|HTMLElement|$$Element|string, callback: (eventTarget?: EventTarget) => void): () => void { //returns cancel function

  const selectionsArray = Array.isArray(selections) ? selections : [selections];

  const blurTimeout:number|null = null;

  const pointerDownListener = function (e: PointerEvent) {
    const target = e.target || e.srcElement;
    if(target !== null) {
      if (isNotInSelections(selectionsArray, <Node>target)) {
        callback(target);
        document.removeEventListener("pointerdown", pointerDownListener, true);
        document.removeEventListener("focus", focusListener, true);
        // document.removeEventListener("blur", blurListener, true);
      }
      if (blurTimeout != null) {
        clearTimeout(blurTimeout);
      }
    }
  };


  // **** MP - for now we try to listen only on mouse events, this might be sufficient.
  // **** Old code remains here in case we need to handle keyboard based focus loss
  //

  const focusListener = function (e: FocusEvent) {
    const target = e.target || e.srcElement;
    if(target !== null) {
      if (isNotInSelections(selectionsArray, <Node>target)) {
        callback();
        document.removeEventListener("pointerdown", pointerDownListener, true);
        document.removeEventListener("focus", focusListener, true);
        // document.removeEventListener("blur", blurListener, true);
      }
      if (blurTimeout != null) {
        clearTimeout(blurTimeout);
      }
    }
  };

  mySetTimeout(function() {
    document.addEventListener("pointerdown", pointerDownListener, true);
    document.addEventListener("focus", focusListener, true);
    //document.addEventListener("blur", blurListener, true);
  }, 20);

  // Call this to cancel listening function
  return function() {
    if(blurTimeout!==null) {
      clearTimeout(blurTimeout);
    }
    document.removeEventListener("pointerdown", pointerDownListener, true);
    document.removeEventListener("focus", focusListener, true);
    //document.removeEventListener("blur", blurListener, true);
  }

}
//
export function isNotInSelections(selections: Array<HTMLElement|$$Element|string>, node: Node) {
  return !__(selections).exists((selection: HTMLElement|$$Element|string) => {
    if(typeof selection  == "string") {
      const containers = document.querySelectorAll(selection);
      for(let i = 0; i < containers.length; i++) {
        if(isIn(node, containers.item(i))) {
          return true;
        }
      }
      return false;
    } else {
      return isIn(node, selection);
    }
  })
}
//
export function isIn(element: Node|$$Element, container: Node|$$Element): boolean {
  const finalElement = element instanceof $$Element ? element.getAsHtmlElement() : element;
  const finalContainer = container instanceof $$Element ? container.getAsHtmlElement() : container;
  let parent: Node|null = finalElement;
  while(parent !== null) {
    if(parent === finalContainer) {
      return true;
    }

    // this is used by popup elements that are moved around DOM and need to be able to find their original parent
    const remoteFromData: HTMLElement|null = $$(parent).prop("remoteParentElement");

    if(remoteFromData === null) {
      parent = parent.parentNode;
    } else {
      parent = remoteFromData;
    }
  }
  return false;
}
//
// export function removeAllNodeChildren(node: Node) {
//   while (node.firstChild) {
//     node.removeChild(node.firstChild);
//   }
// }

export function isRTL() {
  return document.body.dir === "rtl";
}
