<router-outlet />
<my-toastr-overlay />
<div id="tooltips-overlay"></div>
<div id="will-update-overlay" *ngIf="updateInProgress">
  <div class="message">
    <my-draw-in-logo [large]="true"></my-draw-in-logo>
    {{'will_update' | i18n}}
  </div>
</div>
<div id="offline-warning-overlay" *ngIf="offline">
  <div class="message">
    <my-draw-in-logo [large]="true"></my-draw-in-logo>
    <i class="mi-globe icon"></i>
    Offline
    <div class="info">Waiting for connection...</div>
  </div>
</div>
