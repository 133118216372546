import {
  BindableVariable, BusinessEntitySuggestionEntry,
  ExpressionSuggestionsProvider, ReadableVariable,
  SuggestionEntries,
  SuggestionEntry
} from "./expression-suggestions-provider";
import {Injectable} from "@angular/core";
import {ExpressionFunctionsService} from "./expression-functions.service";
import {VariableTypePath} from "@shared-model";

@Injectable({providedIn: 'root'})
export class GenericExpressionSuggestionProviderService implements ExpressionSuggestionsProvider {

  constructor(readonly expressionFunctionsService: ExpressionFunctionsService) {}

  getSuggestions(): Promise<Array<SuggestionEntry>> {

    return this.expressionFunctionsService.getAvailableSuggestionsForFunctions().then((suggestionsApi) => {
      return SuggestionEntries.of(suggestionsApi).entries;
    });

  }

  getBindableVariables(): Promise<Array<BindableVariable>> {
    return Promise.resolve([]);
  }

  getReadableVariables(): Promise<Array<ReadableVariable>> {
    return Promise.resolve([]);
  }

  validateFunction(functionName: string): boolean {
    return false;
  }

  validateVariable(variable: VariableTypePath): boolean {
    return false;
  }

  businessEntities(): Array<BusinessEntitySuggestionEntry> {
    return [];
  }

}
