import {
  BasicOrganizationNodeInfo,
  BasicPersonInfo,
  OrganizationNodeSearchResult,
  OrganizationSharedService
} from "@shared-model";
import {
  __,
  AggregateId,
  AggregateVersion,
  ApplicationId,
  None,
  Option, OrganizationId,
  OrganizationNodeId,
  PersonId,
  Some,
  Typed
} from "@utils";
import {EventEmitter, OnInit} from "@angular/core";
import {SessionServiceProvider} from "@shared";

enum ViewMode {prompt, noResults, results}


export class OrganizationNodesSearchBoxViewModel {

  static LIMIT = 50;

  query: string = "";

  currentUserId!: PersonId;
  foundNodes: Array<BasicOrganizationNodeInfo> = [];
  resultsActive = false;
  highlightedResult = None();

  viewMode: ViewMode;

  isAllModeClasses: boolean = false;
  isProcessModeClasses: boolean = false;
  isPersonAndDepartmentModeClasses: boolean = false;
  isPersonModeClasses: boolean = false;

  showPrompt: boolean = false;
  showNoResults: boolean = false;
  showResults: boolean = false;

  personNamesCounter: {[name: string]: number} = {};
  moreAvailable: boolean = false;

  constructor(readonly organizationStructureQueryService: OrganizationSharedService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly applicationId: Option<ApplicationId>,
              private excludeNodes: Array<OrganizationNodeId>,
              readonly unselectEnabled: boolean,
              readonly newEntryPlaceholder: string|undefined,
              readonly nodesInternal: Array<BasicOrganizationNodeInfo>,
              readonly persons: boolean,
              readonly includeDeletedNodes: boolean,
              readonly departments: boolean,
              readonly groups: boolean,
              readonly services: boolean,
              readonly processes: boolean,
              readonly reports: boolean,
              readonly applications: boolean,
              readonly screens: boolean,
              readonly functions: boolean,
              readonly anonymous: boolean,
              readonly includeCurrentUser: boolean,
              readonly currentUserLabel: string|undefined,
              readonly alwaysShowResults: boolean,
              readonly inAllApplications: boolean,
              readonly withinNodes: Option<Array<OrganizationNodeId>>,
              readonly fromGlobal: boolean,
              readonly nodeCleared: EventEmitter<void>,
              readonly nodeSelected: EventEmitter<BasicOrganizationNodeInfo>,
              readonly canceled: EventEmitter<void>,
              readonly onCustomValue: ((value: string) => void)|null,
              readonly resultsChanged: () => void) {


    this.resultsActive = this.alwaysShowResults;
    this.viewMode = this.alwaysShowResults ? ViewMode.results : ViewMode.prompt;
    this.isAllModeClasses = groups || reports;
    this.isProcessModeClasses = processes && !departments && !groups && !persons && !reports;
    this.isPersonAndDepartmentModeClasses = persons && departments && !groups && !processes && !reports;
    this.isPersonModeClasses = persons && !departments && !groups && !processes && !reports;
  }

  queryChanged = () => {
    this.organizationStructureQueryService.findNodesByTextQuery(this.persons, this.services, this.departments, this.groups, this.processes, this.reports,
      this.applications, this.screens, this.functions, this.query.trim(), this.applicationId, this.inAllApplications, this.fromGlobal, this.withinNodes, OrganizationNodesSearchBoxViewModel.LIMIT).then(
      (resut: OrganizationNodeSearchResult) => {
        this.handleSearchResults(resut.nodes, resut.moreAvailable);
      });
  };

  updateVisibilityFlags() {

    const lastShowPrompt = this.showPrompt;
    const lastShowNoResults = this.showNoResults;
    const lastShowResults = this.showResults;

    this.showPrompt = this.viewMode === ViewMode.prompt;
    this.showNoResults = this.viewMode === ViewMode.noResults;
    this.showResults = this.viewMode === ViewMode.results;

    // if something changed
    if(lastShowPrompt !== this.showPrompt || lastShowNoResults !== this.showNoResults || lastShowResults !== this.showResults) {
      this.resultsChanged();
    }

  }

  makeResultsVisible() {
    this.resultsActive = true;
  }

  private handleResults(nodes: Array<BasicOrganizationNodeInfo>, moreAvailable: boolean) {

    this.moreAvailable = moreAvailable;
    const nodesToShow = nodes.length > OrganizationNodesSearchBoxViewModel.LIMIT ? nodes.slice(0, OrganizationNodesSearchBoxViewModel.LIMIT) : nodes;

    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      this.currentUserId = sessionInfo.getPersonId();
      const currentUser = OrganizationNodeId.fromPersonId(sessionInfo.personId);

      this.foundNodes = nodesToShow.filter(n => !__(this.nodesInternal).exists(ni => n.aggregateId.id === ni.aggregateId.id) &&
        !__(this.excludeNodes).exists(rn => n.organizationNodeId.equals(rn)) && (this.includeDeletedNodes || !n.deleted));


      if (this.includeCurrentUser) {
        if (this.query.trim().length === 0 || __(this.foundNodes).exists(n => n.aggregateId.id === currentUser.id.id)) {
          this.foundNodes = this.foundNodes.filter(n => n.aggregateId.id !== currentUser.id.id);

          this.foundNodes.push(BasicOrganizationNodeInfo.fromPerson(new BasicPersonInfo(Typed.of(sessionInfo.getPersonId()), AggregateVersion.ZERO, OrganizationId.of(sessionInfo.organizationId),
            None(), sessionInfo.userFirstName, sessionInfo.userLastName, "", sessionInfo.userEmail, sessionInfo.avatar, false)))
        }
      }

      if (this.anonymous) {
        this.foundNodes.push(BasicOrganizationNodeInfo.createAnonymous());
      }

      this.foundNodes = __(this.foundNodes).sortBy(n => n.sortValue(this.currentUserId));
      this.findDuplicatesInPersonNames();
      this.highlightedResult = None();
      if (this.foundNodes.length > 0) {
        this.viewMode = ViewMode.results;
      } else {
        this.viewMode = ViewMode.noResults;
      }
    }

    )}


  handleSearchResults(nodes: Array<BasicOrganizationNodeInfo>, moreAvailable: boolean) {
    this.handleResults(nodes, moreAvailable);
    this.makeResultsVisible();
    this.updateVisibilityFlags();
    this.resultsChanged();
  }

  findDuplicatesInPersonNames() {
    this.personNamesCounter = {};

    this.foundNodes.filter(n => n.isPerson()).forEach(p => {
      if(this.personNamesCounter[p.asPerson().simpleName()] == undefined) {
        this.personNamesCounter[p.asPerson().simpleName()] = 1
      } else {
        this.personNamesCounter[p.asPerson().simpleName()] = this.personNamesCounter[p.asPerson().simpleName()]+1;
      }
    });
  }

  onNodeClicked = (node: BasicOrganizationNodeInfo) => {
    this.nodeSelected.emit(node);
    this.query = "";
    this.hideResults();
  };

  onUnselectClicked = () => {
    this.nodeCleared.emit();
    this.query = "";
    this.hideResults();
  };

  onNoneClicked = () => {
    this.nodeSelected.emit(BasicOrganizationNodeInfo.fromPerson(new BasicPersonInfo(Typed.of(PersonId.NO_ONE), AggregateVersion.ZERO, OrganizationId.of(""), None(), "", "", "", "" , None(), false)));
    this.query = "";
    this.hideResults();
  }

  triggerSearch() {
    this.makeResultsVisible();
    this.queryChanged();
  }

  onFocus = () => {
    this.makeResultsVisible();
    this.queryChanged();
  };

  onBlur = () => {
    this.hideResults();
  };

  hideResults() {
    if(!this.alwaysShowResults) {
      this.resultsActive = false;
      this.foundNodes = [];
      this.resultsChanged();
    }
  }

  queryIsEmpty = () => {
    return this.query.trim().length === 0;
  };

  onKeyDownUp = (event: Event) => {
    event.preventDefault();
    if(this.foundNodes.length > 0) {
      if(this.highlightedResult.isEmpty()) {
        this.highlightedResult = Some(this.foundNodes.length - 1);
      } else {
        this.highlightedResult = Some((this.highlightedResult.get() - 1 + this.foundNodes.length) % this.foundNodes.length)
      }
    }
  }

  onKeyDownDown = (event: Event) => {
    event.preventDefault();
    if(this.foundNodes.length > 0) {
      if(this.highlightedResult.isEmpty()) {
        this.highlightedResult = Some(0);
      } else {
        this.highlightedResult = Some((this.highlightedResult.get() + 1) % this.foundNodes.length)
      }
    }
  }

  onKeyDownEnter = (event: Event) => {
    event.preventDefault();
    if(this.highlightedResult.isDefined()) {
      this.onNodeClicked(this.foundNodes[this.highlightedResult.get()]);
    } else if(this.onCustomValue !== null) {
      this.onCustomValue(this.query);
      this.query = "";
      this.hideResults();
    }
  }


  onKeyDownEscape() {
    this.canceled.emit();
  }

  setExcludeNodes(excludeNodes: Array<OrganizationNodeId>) {
    this.excludeNodes = excludeNodes;
    this.foundNodes = this.foundNodes.filter(n => !__(this.excludeNodes).exists(rn => n.organizationNodeId.equals(rn)));
  }
}
