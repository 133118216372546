import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState,
  TextPropertiesState
} from "../..";
import {Option} from "@utils";


export class ButtonComponentState extends ScreenComponentState {
  static className = "ButtonComponentState";

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  className() {
    return ButtonComponentState.className;
  }

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  readonly textState = new TextPropertiesState(this.properties);

  get text() {
    return this.properties.i18nTextProperty("text");
  }

  get tooltip() {
    return this.properties.optionalI18nTextProperty("tooltip");
  }

  get icon() {
    return this.properties.optionalStringProperty("icon");
  }

  get iconPosition() {
    return this.properties.stringProperty("iconPosition");
  }

  get startIcon() {
    return this.properties.optionalStringProperty("startIcon");
  }

  get endIcon() {
    return this.properties.optionalStringProperty("endIcon");
  }

  static copy(other: ButtonComponentState) {
    return new ButtonComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}

export class ButtonComponentRefState extends ScreenComponentRefState {
  static className = "ButtonComponentRefState";
  className() {
    return ButtonComponentRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: ButtonComponentRefState) {
    return new ButtonComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}

