import {$$Element, DragBehavior, Nothing, PositionXY} from "@utils";
import {DesignCanvasViewPort} from "./ProcessMapCommonViewModel";
import {ProcessMapController} from "./ProcessMapController";

export class DesignCanvasDrag extends DragBehavior<DesignCanvasViewPort> {

  constructor(readonly dragHandlerSelection: $$Element,
              readonly controller: ProcessMapController) {
    super(dragHandlerSelection);
  }

  dragOrigin(element: $$Element, eventPosition: PositionXY, model: DesignCanvasViewPort): any {
    const x = element.attr("x");
    const y = element.attr("y");
    if (x === null || y === null) {
      throw new Error("dragged element has no x or y attribute");
    } else {
      return {
        x: parseInt(x),
        y: parseInt(y)
      };
    }
  }

  dragStarted(element: $$Element, eventPosition: PositionXY, model: DesignCanvasViewPort): void {
    element.classed("dragged", true);
  }

  dragged(element: $$Element, eventPosition: PositionXY, model: DesignCanvasViewPort): void {
    this.controller.setPosition(eventPosition, true);
  }

  dragEnded(element: $$Element, eventPosition: PositionXY, model: DesignCanvasViewPort): void {
    element.classed("dragged", false);
    if (this.isInPlaceClick()) {
      // this is nasty, but we want any field to blur and react top this blur event before we chnage active node model
      // toastr.info("In place clicked");
      //this.designMapInterfaceEventBus.elementDeactivated();
    }
  }

  clicked(element: $$Element, eventPosition: PositionXY, model: Nothing): void {
  }
}
