<i class="icon"
   [class.mi-checkbox]="notification.isTaskMessage()"
   [class.mi-process]="notification.isFlowMessage()"
   [class.mi-globe]="notification.isSystemMessage()"
   [class.mi-file]="notification.isFileMessage()"
   [class.mi-mail]="notification.isMailboxMessage()"
></i>
<my-time-summary class="date" [time]="notification.createdDate" />
<div class="title" [class.important]="notification.isImportantSeverity()" (click)="navigateByNotification(); $event.stopPropagation()">{{notification.subject}}</div>
<div class="content" [my-tooltip]="{text: notification.unWrappedMessage().value, position: 'right', enabled: trimmed}">{{notificationMessage}}</div>
