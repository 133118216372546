import {
  __,
  AggregateId,
  AggregateVersion,
  ApplicationId,
  GridXY,
  I18nText,
  LocalDate,
  LocalDateTime,
  Option,
  OrganizationId,
  OrganizationNodeId,
  PersonId,
  ProcessId,
  ProcessReleaseId,
  ScreenId,
  ScreenReleaseId,
  Typed,
  valueOrDefault
} from "@utils";
import {ProcessesNamingResponse} from "./process-naming-query.service";
import {BasicInstanceInfo} from "./InstanceModel";
import {BusinessVariableType, BusinessVariableTypeFactory} from "../variables/BusinessVariables";
import {ProcessNodeId} from "../flow-and-task/flows.shared-service";

export class BasicProcessInfo {
  constructor(readonly id: AggregateId,
              readonly applicationId: Option<ApplicationId>,
              readonly name: string,
              readonly deleted: boolean) {}

  static copy(other: BasicProcessInfo) {
    return new BasicProcessInfo(other.id, Option.copy(other.applicationId, ApplicationId.of), other.name, other.deleted);
  }
}

export class BasicProcessInfoWithApplicationInfo {
  constructor(readonly applicationId: Option<ApplicationId>,
              readonly applicationName: string,
              readonly process: BasicProcessInfo) {}

  static copy(other: BasicProcessInfoWithApplicationInfo) {
    return new BasicProcessInfoWithApplicationInfo(Option.copy(other.applicationId, ApplicationId.of), other.applicationName,
      BasicProcessInfo.copy(other.process))
  }
}

export interface ProcessReleaseNames {
  processReleaseId: AggregateId;
  processReleaseVersion: AggregateVersion;
  name: Option<string>;
  nodesNames: Array<[number, string]>;
  rolesNames: Array<[number, string]>;
}


export class ProcessReleaseNamesTransformed {

  constructor(readonly name: Option<string>,
              readonly nodesNames: {[id: number]: string},
              readonly rolesNames: {[id: number]: string}) {
  }

  static from(other: ProcessReleaseNames) {
    const nodesNames: {[id: number]: string} = {};
    other.nodesNames.forEach((entry: [number, string]) => nodesNames[entry[0]] = entry[1]);

    const rolesNames: {[id: number]: string} = {};
    other.rolesNames.forEach((entry: [number, string]) => rolesNames[entry[0]] = entry[1]);

    return new ProcessReleaseNamesTransformed(Option.copy(other.name), nodesNames, rolesNames);
  }
}

export class ProcessesNamingResponseTransformed {

  constructor(readonly instances: {[id: string]: string},
              readonly processes: {[id: string]: string},
              readonly releases: {[id: string]: ProcessReleaseNamesTransformed},
              readonly processesApplications: {[id: string]: Option<ApplicationId>}) {
  }

  static from(other: ProcessesNamingResponse) {

    const instances: {[id: string]: string} = {};
    const processes: {[id: string]: string} = {};
    const processesApplications: {[id: string]: Option<ApplicationId>} = {};

    const releases: {[id: string]: ProcessReleaseNamesTransformed} = {};

    other.instances.forEach((entry: [AggregateId, string]) => instances[entry[0].id] = entry[1]);
    other.processes.forEach((entry: [AggregateId, string]) => processes[entry[0].id] = entry[1]);
    other.releases.forEach((entry: [AggregateId, ProcessReleaseNames]) => releases[entry[0].id] = ProcessReleaseNamesTransformed.from(entry[1]));
    other.processesApplications.forEach((entry: [AggregateId, Option<ApplicationId>]) => processesApplications[entry[0].id] = Option.copy(entry[1], ApplicationId.of));


    return new ProcessesNamingResponseTransformed(instances, processes, releases, processesApplications);
  }

  getAllApplications(): Array<ApplicationId> {
    return Object.values(this.processesApplications).filter(a => a.isDefined()).map(a => a.get());
  }

  getProcessName(id: ProcessId) {
    return this.processes[id.id.id];
  }

  getProcessApplication(processId: ProcessId) {
    return this.processesApplications[processId.id.id];
  }

  getNodeName(releaseId: AggregateId, nodeId: ProcessNodeId): Option<string> {
    return Option.of(this.releases[releaseId.id]).map(r => r.nodesNames[nodeId]);
  }
}

export class ProcessInfo {

  constructor(readonly processId: AggregateId,
              readonly processVersion: AggregateVersion,
              readonly applicationId: Option<ApplicationId>,
              readonly ownerId: PersonId,
              readonly organizationId: AggregateId,
              readonly authorization: Array<[ProcessAuthorization, Array<OrganizationNodeId>]>,
              readonly name: I18nText,
              readonly processCategoryId: number,
              readonly screenId: Option<ScreenId>,
              readonly code: string,
              readonly workingReleaseId: Option<AggregateId>,
              readonly lastUpdated: LocalDateTime,
              readonly created: LocalDateTime,
              readonly instances: Array<BasicInstanceInfo>,
              readonly sharedServices: Array<SharedService>,
              readonly nextCommentId: number,
              readonly comments: Array<ProcessMapComments>,
              readonly archived: boolean,
              readonly deleted: boolean,
              readonly customProcessVersions: Array<CustomProcessVersion>) {}

  sharedServiceById(serviceId: number) {
    return Option.of(this.sharedServices.filter(s => s.serviceId === serviceId)[0]);
  }

  maxSharedServiceId(): number {
    return __(this.sharedServices).reduce(0, (acc: number, s: SharedService) => Math.max(acc, s.serviceId));
  }

  // static empty() {
  //   return new ProcessInfo(undefined, undefined, undefined, undefined, undefined, [], I18nText.empty(),
  //     undefined, "", None(), undefined, undefined, None(),
  //     undefined, false, [], [], 1, [], false, false, []);
  // }

  static copy(other:ProcessInfo):ProcessInfo {
    return new ProcessInfo(AggregateId.copy(other.processId),
      AggregateVersion.copy(other.processVersion),
      Option.copy(other.applicationId, ApplicationId.of),
      PersonId.of(other.ownerId),
      other.organizationId,
      other.authorization.map((authorization: [ProcessAuthorization, Array<OrganizationNodeId>]) => {
        const result: [ProcessAuthorization, Array<OrganizationNodeId>] = [ProcessAuthorization.copy(authorization[0]), authorization[1].map(OrganizationNodeId.copy)];
        return result;
      }), I18nText.copy(other.name),
      other.processCategoryId,
      Option.copy(other.screenId, ScreenId.copy),
      other.code,
      Option.copy(other.workingReleaseId, AggregateId.copy),
      LocalDateTime.copy(other.lastUpdated),
      LocalDateTime.copy(other.created),
      other.instances.map(BasicInstanceInfo.copy),
      other.sharedServices.map(SharedService.copy),
      other.nextCommentId,
      other.comments.map(ProcessMapComments.copy),
      other.archived,
      other.deleted,
      other.customProcessVersions.map(CustomProcessVersion.copy))
  }

  findCommentsGroupById(commentGroupId: number): ProcessMapComments {
    return __(this.comments).find(c => c.id == commentGroupId).getOrError("Comments for id "+commentGroupId+" not found");
  }

  getAuthorizations(authorization: ProcessAuthorization) {
    return valueOrDefault(this.authorization.find(a => a[0].name === authorization.name), [ProcessAuthorization.edit, []])[1];
  }
}


export class ProcessReleaseBasicInfo {
  constructor(readonly id: ProcessReleaseId,
              readonly lastUpdated: LocalDateTime,
              readonly version: AggregateVersion,
              readonly releaseCode: string,
              readonly releaseNumber: number,
              readonly releaseComment: string,
              readonly processId: ProcessId,
              readonly organizationId: OrganizationId,
              readonly screenReleaseId: Option<ScreenReleaseId>,
              readonly closed: boolean,
              readonly closedTimestamp: Option<LocalDateTime>,
              readonly published: boolean,
              readonly deleted: boolean,
              readonly lastUpdatedBy: PersonId) {}

  static copy(other: ProcessReleaseBasicInfo) {
    return new ProcessReleaseBasicInfo(
      ProcessReleaseId.of(other.id),
      LocalDateTime.copy(other.lastUpdated),
      AggregateVersion.copy(other.version),
      other.releaseCode,
      other.releaseNumber,
      other.releaseComment,
      ProcessId.of(other.processId),
      OrganizationId.of(other.organizationId),
      Option.copy(other.screenReleaseId, ScreenReleaseId.copy),
      other.closed,
      Option.copy(other.closedTimestamp, LocalDateTime.copy),
      other.published,
      other.deleted,
      PersonId.copy(other.lastUpdatedBy)
    )
  }
}

export class CustomProcessVersion {
  constructor(public name: string,
              public description: string,
              public changeDate: Option<LocalDate>,
              public createdBy: string,
              public acceptedBy: string,
              public validFrom: Option<LocalDate>,
              public processRelease: Option<AggregateId>) {}

  static copy(other: CustomProcessVersion) {
    return new CustomProcessVersion(
      other.name,
      other.description,
      Option.copy(other.changeDate).map(o => LocalDate.copy(o)),
      other.createdBy,
      other.acceptedBy,
      Option.copy(other.validFrom).map(o => LocalDate.copy(o)),
      Option.copy(other.processRelease).map(o => AggregateId.copy(o)))
  }
}

export class ProcessAuthorization {
  constructor(readonly name: string) {}

  static edit = new ProcessAuthorization("edit");
  static preview = new ProcessAuthorization("preview");
  static newInstanceCreation = new ProcessAuthorization("newInstanceCreation");

  static copy(other: ProcessAuthorization) {
    switch (other.name) {
      case ProcessAuthorization.edit.name: return ProcessAuthorization.edit;
      case ProcessAuthorization.preview.name: return ProcessAuthorization.preview;
      case ProcessAuthorization.newInstanceCreation.name: return ProcessAuthorization.newInstanceCreation;
      default: throw new Error("Unknown process authorization: '"+other.name+"'");
    }
  }
}

export class ProcessMapComment {
  constructor(
    readonly id: number,
    readonly author: OrganizationNodeId,
    readonly message: string,
    readonly created: LocalDateTime,
    readonly changed: Option<LocalDateTime>,
    readonly deleted: boolean
  ) {}

  static copy(other: ProcessMapComment): ProcessMapComment {
    return new ProcessMapComment(other.id, OrganizationNodeId.copy(other.author), other.message.trim(), LocalDateTime.copy(other.created),
      Option.copy(other.changed, LocalDateTime.copy), other.deleted);
  }
}

export class ProcessMapComments {
  static chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  constructor(
    readonly id: number,
    readonly gridXY: GridXY,
    readonly xShift: number,
    readonly yShift: number,
    readonly comments: Array<ProcessMapComment>
  ) {}

  static copy(other: ProcessMapComments): ProcessMapComments {
    return new ProcessMapComments(other.id, GridXY.copy(other.gridXY), other.xShift, other.yShift, other.comments.map(ProcessMapComment.copy));
  }

  charName() {
    if(this.id <= ProcessMapComments.chars.length) {
      return ProcessMapComments.chars.charAt(this.id - 1);
    } else {
      const a = Math.floor((this.id - 1) / ProcessMapComments.chars.length);
      const b = (this.id - 1) % ProcessMapComments.chars.length;
      return ProcessMapComments.chars.charAt(a - 1) + ProcessMapComments.chars.charAt(b);
    }
  }

  lastComment() {
    if(this.comments.length == 0) {
      throw new Error("Cannot get last comment from empty list");
    } else {
      return this.comments[this.comments.length - 1];
    }
  }
}


export class SharedService {
  constructor(readonly serviceId: number,
              readonly enabled: boolean,
              readonly serviceName: string,
              readonly interfaces: Array<SharedServiceInterface>) {
  }

  static copy(other: SharedService) {
    return new SharedService(
      other.serviceId,
      other.enabled,
      other.serviceName,
      other.interfaces.map(SharedServiceInterface.copy));
  }
}

export class SharedServiceInterface {
  constructor(public id: number,
              public label: string,
              public input: Array<SharedServiceInputField>,
              public output: Array<SharedServiceOutputField>) {
  }

  static copy(other: SharedServiceInterface) {
    return new SharedServiceInterface(
      other.id,
      other.label,
      other.input.map(SharedServiceInputField.copy),
      other.output.map(SharedServiceOutputField.copy));
  }
}

export class SharedServiceOutputField {
  constructor(readonly name: string,
              readonly variableType: Typed<BusinessVariableType>) {}

  unwrappedType() {
    return Typed.value<BusinessVariableType>(this.variableType);
  }

  static equals(a: SharedServiceOutputField, b: SharedServiceOutputField): boolean {
    return a.name === b.name && a.unwrappedType().typeName() === b.unwrappedType().typeName();
  }

  static copy(other: SharedServiceOutputField) {
    return new SharedServiceOutputField(other.name, BusinessVariableTypeFactory.copyTyped(other.variableType));
  }

  variableTypeName() {
    return this.unwrappedType().typeName();
  }
}

export class SharedServiceInputField {
  constructor(readonly name: string,
              readonly variableType: Typed<BusinessVariableType>,
              readonly required: boolean) {}

  unwrappedType() {
    return Typed.value<BusinessVariableType>(this.variableType);
  }

  static equals(a: SharedServiceInputField, b: SharedServiceInputField): boolean {
    return a.name === b.name && a.unwrappedType().typeName() === b.unwrappedType().typeName() && a.required === b.required;
  }

  static copy(other: SharedServiceInputField) {
    return new SharedServiceInputField(other.name, BusinessVariableTypeFactory.copyTyped(other.variableType), other.required);
  }

  variableTypeName() {
    return this.unwrappedType().typeName();
  }
}


// Used for semi-type check between modules
export interface GridProcessModelInterface {}
