import {Injectable} from "@angular/core";
import {None, Option, Some} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class GravatarService {


  private cache: Map<string, Promise<Option<string>>> = new Map<string, Promise<Option<string>>>();

  private toDataURL(url: string, callback: (result: string|ArrayBuffer|null) => void) {
    const xhr = new XMLHttpRequest();
    xhr.onload = ()=> {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getImageBlobUrl(gravatarHash: string, size: number = 50): Promise<Option<string>> {
    const fromCache = this.cache.get(gravatarHash);
    if (fromCache) {
      return fromCache;
    } else {
      const promise = new Promise<Option<string>>((resolve, reject) => {
        // &d=null - do not return default image
        this.toDataURL("https://www.gravatar.com/avatar/" + gravatarHash + "?s=50&d=null", (result) => {
          if(typeof result === "string" && result.length > 50) { // sometimes it returns something like "data:text/xml;base64," so 50 is to make sure it has some data
            resolve(Some(result as string));
          } else {
            resolve(None());
          }
        });
      });
      this.cache.set(gravatarHash, promise);
      return promise;
    }

  }

}
