import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {
  ApplicationId,
  i18n,
  myRequestAnimationFrame,
  None,
  nullIfUndefined,
  Option,
  OrganizationNodeId,
  toastr
} from "@utils";
import {OrganizationSharedService} from "@shared-model";
import {OrganizationNodeSelectViewModel} from "./organization-node-select.view-model";

@Component({
  selector: 'my-organization-node-select',
  templateUrl: './organization-node-select.component.html',
  host: {
    "[class.readOnly]": "viewModel.readOnly",
    "[class.preview]": "viewModel.preview && viewModel.readOnly",
    "[class.expanded]": "viewModel.searchBoxVisible",
    "[class.pureLook]" : "pureLook"
  }
})
export class OrganizationNodeSelectComponent implements OnInit {

  public viewModel!: OrganizationNodeSelectViewModel;

  private _node: Option<OrganizationNodeId> = None();
  @Output() nodeChange = new EventEmitter<Option<OrganizationNodeId>>();
  @Input() set node(n: Option<OrganizationNodeId>) {
    if(this.viewModel !== undefined) {
      this.viewModel.setNode(n);
    }
    this._node = n;
  };

  @ViewChild("SelectNode") selectNodeButton: ElementRef<HTMLElement>|undefined;

  private _preview: boolean = false;
  @Input() set preview(p: boolean) {
    this._preview = p;
    if(this.viewModel !== undefined) {
      this.viewModel.setPreview(p);
    }
  }

  private _readOnly: boolean = false;
  @Input() set readOnly(p: boolean) {
    this._readOnly = p;
    if(this.viewModel !== undefined) {
      this.viewModel.setReadOnly(p);
    }
  }

  @Input() smallIcon: boolean = false;
  @Input() mediumIcon: boolean = false;
  @Input() pureLook: boolean = false; // remove all visual decorations, it will be handled externally

  @Input() persons: boolean = false;
  @Input() includeDeletedNodes: boolean = false;
  @Input() departments: boolean = false;
  @Input() groups: boolean = false;
  @Input() services: boolean = false;
  @Input() processes: boolean = false;
  @Input() reports: boolean = false;
  @Input() applications: boolean = false;
  @Input() screens: boolean = false;
  @Input() functions: boolean = false;
  @Input() anonymous: boolean = false;

  @Input() withinNodes: Option<Array<OrganizationNodeId>> = None();

  @Input() openOnShow: boolean = false;

  private _fromApplication: ApplicationId|undefined = undefined;

  @Input() fromGlobal: boolean = true;

  @Input() set fromApplication(value: ApplicationId | undefined) {
    this._fromApplication = value;
    if(this.viewModel) {
      this.viewModel.setApplicationId(nullIfUndefined(value));
    }
  }

  private _inAllApplications: boolean = false;

  @Input() set inAllApplications(value: boolean) {
    this._inAllApplications = value;
    if(this.viewModel) {
      this.viewModel.setInAllApplications(value);
    }
  }

  private _excludeNodes: Array<OrganizationNodeId> = [];
  @Input() set excludeNodes(value: Array<OrganizationNodeId>) {
    this._excludeNodes = value;
    if(this.viewModel) {
      this.viewModel.setExcludeNodes(value);
    }
  }


  @Input() placeholder: string = i18n("common_select");

  contentChangeNotifier = new EventEmitter<void>();

  constructor(readonly organizationStructureQueryService: OrganizationSharedService) {}

  private readonly focusMainButton = () => {
    if(this.selectNodeButton === undefined) {
      throw new Error("selectNodeButton is undefined");
    } else {
      return this.selectNodeButton.nativeElement.focus();
    }
  };

  ngOnInit() {

    this.viewModel = new OrganizationNodeSelectViewModel(this._node.getOrNull(), this.organizationStructureQueryService,
      this._readOnly, this._preview,
      this.persons, this.includeDeletedNodes, this.departments, this.groups, this.processes, this.reports, this.applications, this.screens, this.functions, this.anonymous,
      nullIfUndefined(this._fromApplication), this._inAllApplications, this.fromGlobal, this._excludeNodes,
      this.focusMainButton,
      () => {
      let newValue = Option.of(this.viewModel.node);
      this.nodeChange.emit(newValue);
    });

    if(this.openOnShow) {
      if(!this.readOnly) {
        myRequestAnimationFrame(() => {
          this.viewModel.openSearchBox()
        });
      }
    }

  }


}
