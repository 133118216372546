import {Injectable} from "@angular/core";
import {global, Option} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class LocalSettingsService {

  constructor() { }


  setLanguage(language: string) {
    localStorage.setItem("language", language);
  }

  getLanguage(): Option<string> {
    return Option.of(localStorage.getItem("language"));
  }

  setCustomTheme(customTheme: string) {
    if(customTheme === null) {
      localStorage.removeItem(global.config.organizationName + "customTheme");
    } else {
      localStorage.setItem(global.config.organizationName + "customTheme", customTheme);
    }
  }

  getCustomTheme(): Option<string> {
    return Option.of(localStorage.getItem(global.config.organizationName + "customTheme"));
  }

  setFontSize(fontSize: number) {
    localStorage.setItem("fontSize", fontSize.toString());
  }

  getFontSize(): Option<number> {
    let item = localStorage.getItem("fontSize");
    if(item === null) {
      throw new Error("No locally saved fontSize");
    } else {
      return Option.of(parseInt(item));
    }
  }

  getNavigationHeaderExpanded(): boolean {
    return localStorage.getItem("navigationHeaderExpanded") === "true";
  }

  setNavigationHeaderExpanded(value: boolean) {
    localStorage.setItem("navigationHeaderExpanded", value+"");
  }

  getMocked(): boolean {
    return localStorage.getItem("mocked") === "true";
  }

  setMocked(value: boolean) {
    localStorage.setItem("mocked", value+"");
  }

  deleteMocked() {
    localStorage.removeItem("mocked");
  }

  setSuggestCategories(suggestCategories: boolean) {
    localStorage.setItem("suggestCategories", suggestCategories+"");
  }

  getSuggestCategories(): boolean {
    return localStorage.getItem("suggestCategories") === "true";
  }

  setCategoriesEditMode(categoriesEditMode: boolean) {
    localStorage.setItem("categoriesEditMode", categoriesEditMode+"");
  }

  getCategoriesEditMode(): boolean {
    return localStorage.getItem("categoriesEditMode") === "true";
  }
}
