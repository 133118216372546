import {None, Option, Typed} from "@utils";
import {ExpressionWithAst} from "../Expressions";

export interface BusinessVariablesValidation {
  className(): string;
}

export class StringValidationConfiguration {
  constructor(public title: string, public mask: string, public regex: string) {}
}

export class StringVariableValidationType {
  constructor(public name: string) {}

  static AnyValidationType = new StringVariableValidationType("AnyValidationType");
  static CustomType = new StringVariableValidationType("CustomType");
  static PeselType = new StringVariableValidationType("PeselType");
  static PostalCodeType = new StringVariableValidationType("PostalCodeType");
  static EmailType = new StringVariableValidationType("EmailType");
  static NIPCodeType = new StringVariableValidationType("NIPCodeType");
  static BankAccountNumberType = new StringVariableValidationType("BankAccountNumberType");


  static validationTypes: StringVariableValidationType[] = [
    StringVariableValidationType.AnyValidationType,
    StringVariableValidationType.CustomType,
    StringVariableValidationType.PeselType,
    StringVariableValidationType.PostalCodeType,
    StringVariableValidationType.EmailType,
    StringVariableValidationType.NIPCodeType,
    StringVariableValidationType.BankAccountNumberType
  ];

  static ofName(name: string) {
    switch (name) {
      case StringVariableValidationType.AnyValidationType.name: return StringVariableValidationType.AnyValidationType;
      case StringVariableValidationType.CustomType.name: return StringVariableValidationType.CustomType;
      case StringVariableValidationType.PeselType.name: return StringVariableValidationType.PeselType;
      case StringVariableValidationType.PostalCodeType.name: return StringVariableValidationType.PostalCodeType;
      case StringVariableValidationType.EmailType.name: return StringVariableValidationType.EmailType;
      case StringVariableValidationType.NIPCodeType.name: return StringVariableValidationType.NIPCodeType;
      case StringVariableValidationType.BankAccountNumberType.name: return StringVariableValidationType.BankAccountNumberType;
      default: throw new Error("Incorrect validation type name [" + name + "]");
    }
  }

  static configurationFor(name: string): StringValidationConfiguration {
    switch (name) {
      case StringVariableValidationType.CustomType.name: return new StringValidationConfiguration("formsEditor_string_custom", "", "");
      case StringVariableValidationType.AnyValidationType.name: return new StringValidationConfiguration("formsEditor_string_any", "", ""); // \s\S instead of dot to match multiline strings);
      case StringVariableValidationType.PeselType.name: return new StringValidationConfiguration("formsEditor_string_pesel", "99999999999", "");
      case StringVariableValidationType.PostalCodeType.name: return new StringValidationConfiguration("formsEditor_string_postal_code", "99-999", "");
      case StringVariableValidationType.EmailType.name: return new StringValidationConfiguration("formsEditor_string_postal_email", "", "");
      case StringVariableValidationType.NIPCodeType.name: return new StringValidationConfiguration("formsEditor_string_nip", "", "");
      case StringVariableValidationType.BankAccountNumberType.name: return new StringValidationConfiguration("formsEditor_string_bank_account_number", "","");
      default: throw new Error("Incorrect validation type ["+name+"]");
    }
  }

  static copy(other: StringVariableValidationType): StringVariableValidationType {
    return new StringVariableValidationType(other.name);
  }
}

export class StringVariableValidation implements BusinessVariablesValidation {

  static className = "StringVariableValidation";

  className() {
    return StringVariableValidation.className;
  }

  constructor(public validationType: StringVariableValidationType = StringVariableValidationType.AnyValidationType,
              public regex: Option<string> = None(),
              public mask: Option<string> = None()) {}

  static copy(other: StringVariableValidation) {
    return new StringVariableValidation(other.validationType, Option.copy(other.regex), Option.copy(other.mask));
  }
}

export class NumberVariableValidation implements BusinessVariablesValidation {
  static className = "NumberVariableValidation";
  className() {
    return NumberVariableValidation.className;
  }

  constructor(public minValue: Option<number>, public maxValue: Option<number>) {}

  static copy(other: NumberVariableValidation) {
    return new NumberVariableValidation(Option.copy(other.minValue), Option.copy(other.maxValue));
  }
}


export class BooleanVariableValidation implements BusinessVariablesValidation {
  static className = "BooleanVariableValidation";
  className() {
    return BooleanVariableValidation.className;
  }

  constructor() {}

  static copy(other: BooleanVariableValidation) {
    return new BooleanVariableValidation();
  }
}

export class DateVariableValidation implements BusinessVariablesValidation {
  static className = "DateVariableValidation";
  className() {
    return DateVariableValidation.className;
  }

  constructor(public minValue: Option<ExpressionWithAst>, public maxValue: Option<ExpressionWithAst>) {}

  static copy(other: DateVariableValidation) {
    return new DateVariableValidation(ExpressionWithAst.copyOptional(other.minValue),
      ExpressionWithAst.copyOptional(other.maxValue)
    );
  }
}

export class DateTimeVariableValidation implements BusinessVariablesValidation {
  static className = "DateTimeVariableValidation";
  className() {
    return DateTimeVariableValidation.className;
  }

  constructor(public minValue: Option<ExpressionWithAst>, public maxValue: Option<ExpressionWithAst>) {}


  static copy(other: DateTimeVariableValidation) {
    return new DateTimeVariableValidation(ExpressionWithAst.copyOptional(other.minValue),
      ExpressionWithAst.copyOptional(other.maxValue));
  }
}

export class TimeVariableValidation implements BusinessVariablesValidation {
  static className = "TimeVariableValidation";
  className() {
    return TimeVariableValidation.className;
  }

  constructor(public minValue: Option<ExpressionWithAst>, public maxValue: Option<ExpressionWithAst>) {}

  static copy(other: TimeVariableValidation) {
    return new TimeVariableValidation(ExpressionWithAst.copyOptional(other.minValue),
      ExpressionWithAst.copyOptional(other.maxValue));
  }
}

export class IntervalVariableValidation implements BusinessVariablesValidation {
  static className = "IntervalVariableValidation";
  className() {
    return IntervalVariableValidation.className;
  }

  constructor() {}

  static copy(other: IntervalVariableValidation) {
    return new IntervalVariableValidation();
  }
}


export class FileArrayValidation implements BusinessVariablesValidation {
  static className = "FileArrayValidation";
  className() {
    return FileArrayValidation.className;
  }

  constructor(public fileValidation: FileVariableValidation, public arrayValidation: ArrayVariableValidation) {}

  static copy(other: FileArrayValidation) {
    return new FileArrayValidation(FileVariableValidation.copy(other.fileValidation), ArrayVariableValidation.copy(other.arrayValidation));
  }
}

export class FileVariableValidation implements BusinessVariablesValidation {
  static className = "FileVariableValidation";
  className() {
    return FileVariableValidation.className;
  }

  constructor(public maxSize: number, public allowedExtensions: Array<string>) {}

  static copy(other: FileVariableValidation) {
    return new FileVariableValidation(other.maxSize, other.allowedExtensions.slice());
  }
}

export class ArrayVariableValidation implements BusinessVariablesValidation {

  static className = "ArrayVariableValidation";
  className() {
    return ArrayVariableValidation.className;
  }

  constructor(public minimumLength: number, public maximumLength: number) {}

  static copy(other: ArrayVariableValidation) {
    return new ArrayVariableValidation(other.minimumLength, other.maximumLength);
  }
}

export class BusinessVariableValidationFactory {

  static copy(validation: BusinessVariablesValidation): BusinessVariablesValidation {
    return BusinessVariableValidationFactory.copyByType(validation, validation.className());
  }

  static copyTyped(validation: Typed<BusinessVariablesValidation>): Typed<BusinessVariablesValidation> {
    return Typed.of(BusinessVariableValidationFactory.copyByType(Typed.value(validation), Typed.className(validation)));
  }

  static copyByType(validation: BusinessVariablesValidation, className: string): BusinessVariablesValidation {
    switch (className) {

      case StringVariableValidation.className: return StringVariableValidation.copy(<StringVariableValidation>validation);
      case NumberVariableValidation.className: return NumberVariableValidation.copy(<NumberVariableValidation>validation);
      case BooleanVariableValidation.className: return BooleanVariableValidation.copy(<BooleanVariableValidation>validation);
      case DateVariableValidation.className: return DateVariableValidation.copy(<DateVariableValidation>validation);
      case DateTimeVariableValidation.className: return DateTimeVariableValidation.copy(<DateTimeVariableValidation>validation);
      case TimeVariableValidation.className: return TimeVariableValidation.copy(<TimeVariableValidation>validation);
      case IntervalVariableValidation.className: return IntervalVariableValidation.copy(<IntervalVariableValidation>validation);
      case FileVariableValidation.className: return FileVariableValidation.copy(<FileVariableValidation>validation);
      case ArrayVariableValidation.className: return ArrayVariableValidation.copy(<ArrayVariableValidation>validation);

      case FileArrayValidation.className: return FileArrayValidation.copy(<FileArrayValidation>validation);

      default:
        throw new Error("Unsupported variable validation class: " + className + ", variable: " + JSON.stringify(validation));
    }
  }
}
