import {ZoomBehavior} from "../../../utils/ZoomBehavior";
import {ProcessMapController} from "./ProcessMapController";
import {$$Element} from "@utils";

export class DesignMapEditorZoom extends ZoomBehavior {

    constructor(readonly dragHandlerSelection:$$Element,
                readonly controller: ProcessMapController) {
      super(dragHandlerSelection, 0.3, 2.5);
    }


    zoomStart(zoomedElement:$$Element):void {
    }

    zoomed(zoomedElement: $$Element, scale: number, centerX: number,
              centerY: number): void {
      this.controller.setZoom(scale, centerX, centerY);
    }

    zoomEnd(zoomedElement:$$Element):void {
      // do nothing
    }

  }
