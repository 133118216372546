import {BooleanVariable, BusinessVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {
  SingleCheckboxComponentDefinition
} from "@screen-common";

export class SingleCheckboxComponentState extends LabeledScreenComponentState {
  static className = "SingleCheckboxComponentState";
  className() {
    return SingleCheckboxComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalBooleanProperty(SingleCheckboxComponentDefinition.MODEL);
  }

  get valueLabel() {
    return this.properties.i18nTextProperty("valueLabel");
  }

  get options() {
    return this.properties.labeledValuesProperty("options");
  }

  get threeStates() {
    return this.properties.booleanProperty("threeStates");
  }

  get iconBackgroundColor() {return this.properties.optionalStringProperty("iconBackgroundColor")}

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case SingleCheckboxComponentDefinition.MODEL:
        if(value.isDefined() && value.get().className() === BooleanVariable.className) {
          this.properties.putValue(SingleCheckboxComponentDefinition.MODEL, value.get());
        } else if(value.isDefined()) {
          throw Error("Unable to set value, expected Boolean but was ["+value.get().className()+"]");
        } else {
          this.properties.clearValue(SingleCheckboxComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: SingleCheckboxComponentState) {
    return new SingleCheckboxComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class SingleCheckboxComponentRefState extends ScreenComponentRefState {
  static className = "SingleCheckboxComponentRefState";
  className() {
    return SingleCheckboxComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: SingleCheckboxComponentRefState) {
    return new SingleCheckboxComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}


