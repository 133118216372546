declare const buildStamp: string;

export const environment = {
  production: true,
  buildStamp: buildStamp,
  buildTimestamp: "{{ BUILD_TIMESTAMP }}",
  restCallsToOrigin: true,
  restServicePort: 444,
  restServiceProtocol: "https",
};
