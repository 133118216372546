import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MainMenuPage, MainMenuService} from "./main-menu.service";

@Injectable({ providedIn: 'root' })
export class MainMenuResolver implements Resolve<boolean> {
  constructor(readonly mainMenuService: MainMenuService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|any {
    return new Promise((resolve, reject) => {
      let mainMenuPage: MainMenuPage = route.data["mainMenuPage"];
      this.mainMenuService.setPage(mainMenuPage);
      resolve(true);
    });
  }
}
