import {AggregateId, AggregateVersion, I18nText, Option} from "@utils";

export class BasicInstanceInfo {

  constructor(readonly instanceId: AggregateId,
              readonly instanceVersion: AggregateVersion,
              readonly label: I18nText,
              readonly releaseId: Option<AggregateId>,
              readonly releaseLabel: Option<string>,
              readonly isActive: boolean,
              readonly color: Option<string>,
              readonly codeName: string) {}

  static copy(other: BasicInstanceInfo) {
    return new BasicInstanceInfo(other.instanceId,
      other.instanceVersion,
      I18nText.copy(other.label),
      Option.copy(other.releaseId),
      Option.copy(other.releaseLabel),
      other.isActive,
      Option.copy(other.color),
      other.codeName);
  }
}


