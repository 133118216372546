import {BusinessVariableType} from "@shared-model";
import {None, Option} from "@utils";



export class DataTableColumnModel {

  static DEFAULT_COLUMN_WIDTH_REM = 12;

  constructor(public name: string,
              public dataType: BusinessVariableType,
              readonly previewLabel: Option<string> = None(),
              public remWidth: number = DataTableColumnModel.DEFAULT_COLUMN_WIDTH_REM
  ) {}
}
