<div *ngIf="loginFormVisible" class="loginForm">

  <div class="titleContainer">
    <div class="title">{{label}}</div>
  </div>

  <div *ngIf="!usernameLoginEnabled" class="formField theme-input-box-with-color-icon-large">
    <i class="mi-mail"></i>
    <input type="email" class="emailInput" placeholder="{{'login_form_email_placeholder' | i18n}}" [(ngModel)]="login"
           [readonly]="loginReadonly" [focus-on-show]="true"
           (keydown.enter)="focusOnPassword()">
  </div>

  <div *ngIf="usernameLoginEnabled" class="formField theme-input-box-with-color-icon-large">
    <i class="mi-mail"></i>
    <input type="text" placeholder="{{'login_form_login_placeholder' | i18n}}" [(ngModel)]="login"
           [readonly]="loginReadonly" [focus-on-show]="true"
           (keydown.enter)="focusOnPassword()">
  </div>

  <div class="formField theme-input-box-with-color-icon-large">
    <i class="mi-lock"></i>
    <input class="password" type="password" placeholder="{{'login_form_password_placeholder' | i18n}}"
           [(ngModel)]="password"
           (keydown.enter)="loginUserInternal()">
  </div>

  <hr class="loginFormSeparator theme-content-horizontal-separator-no-margin"/>

  <my-switch *ngIf="rememberMeEnabled" [(marked)]="rememberMe" [label]="'login_form_remember_me' | i18n" class="rememberMe"/>

  <button class="loginButton theme-button-confirm"
          (click)="loginUserInternal()">{{'login_form_submit_button' | i18n}}</button>

  <div class="underButtonSection" *ngIf="internalAuthenticationEnabled">
    <span>{{'login_forgot_password' | i18n}}</span>
    <a class="linkButton" routerLink="/recover-password">{{'login_forgot_password_remind' | i18n}}</a>
  </div>

</div>

<div *ngIf="mfaFormVisible" class="loginForm">

  <div class="titleContainer">
    <div class="title">{{'login_enter_mfa_token_title' | i18n}}</div>
    <div class="description">{{'login_enter_mfa_token_description' | i18n}}</div>
  </div>

  <input class="theme-input-box token" type="text" autocomplete="false" required="required" [focus-on-show]="true" [(ngModel)]="mfaToken" (keydown.enter)="mfaTokenProvided()">

  <button class="loginButton theme-button-confirm"
          (click)="mfaTokenProvided()">{{'login_enter_mfa_token_verify' | i18n}}</button>

  <div class="underButtonSection" *ngIf="internalAuthenticationEnabled">
    <span>{{'login_enter_mfa_token_codeNotReceived' | i18n}}&nbsp;</span>
    <button class="theme-button-link no-padding" (click)="loginUserInternal()">{{'login_enter_mfa_token_sendCodeAgain' | i18n}}</button>
  </div>

</div>

<div *ngIf="usernameLoginEnabled">

</div>
