import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {I18nService} from "../..";
import {Language, Option} from "@utils";
import {getLanguageOptions} from "./language-options";


export class LanguageOption implements DropDownSelectorOption {

  selected = false;
  nameOnList: string = "";

  constructor(
    readonly value: Language|null,
    readonly name: string
  ) {
    this.nameOnList = name;
  }
}

@Component({
  selector: "my-language-selector",
  templateUrl: "./language-selector.component.html"
})
export class LanguageSelectorComponent implements OnInit {

  language: Language|null = null;
  languageOptions: Array<LanguageOption>;

  @Output() languageChange = new EventEmitter<Option<Language>>();

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly i18nService: I18nService) {
    this.languageOptions = getLanguageOptions();

    this.userSettingsService.getLanguageObservable().forEach(language => {
      this.language = language.getOrNull();
    });
  }

  ngOnInit(): void {
    this.language = this.userSettingsService.getLanguage().getOrNull();

  }

  onLanguageChanged() {
    this.languageChange.emit(Option.of(this.language));
  }
}
