import {DurationPickerEventBus} from "./DurationPickerEventBus";
import {Duration, DurationInputFormatter} from "@utils";
import {EventEmitter} from "@angular/core";

export class DurationPickerViewModel {

  public focused = false;
  public keyboardInput = false;

  public textModel: string|null;

  private parser: DurationInputFormatter;

  constructor(readonly eventBus: DurationPickerEventBus,
              public value: Duration|null,
              readonly placeholder: string|null,
              readonly hoursInDays: number,
              readonly useSecondsInPicker: boolean,
              public disabled: boolean,
              readonly valueChange: EventEmitter<Duration|null>,
              readonly onChange: ((duration: Duration | null) => void)|undefined) {
    this.parser = new DurationInputFormatter(this.hoursInDays);
    this.textModel = this.value ? this.parser.format(this.value) : null;
  }


  updateValue(value: Duration|null) {
    this.value = value;
    this.textModel = this.value ? this.parser.format(this.value) : null;
  }

  updateDisabled(disabled: boolean) {
    this.disabled = disabled;
  }

  onPopupValueChange = (newDuration: Duration|null) => {
    this.updateValue(newDuration);
    this.textModel = this.value ? this.parser.format(this.value) : null;
    this.keyboardInput = false;
  }

  onFocus = () => {
    this.focused = true;
    this.eventBus.focused();
  }

  onBlur = () => {
    this.focused = false;
    this.value = this.parser.parseModel(this.textModel);
    this.valueChange.emit(this.value);
    if(this.onChange !== undefined) {
      this.onChange(this.value);
    }
    this.eventBus.blurred();
  }

  onKeyboardInput() {
    this.keyboardInput = true;
  }

  clear() {
    this.updateValue(null);
    this.onBlur();
  }
}
