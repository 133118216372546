<div class="avatarContainer" *ngIf="(avatar && personInfo)">
  <div class="avatar" [my-tooltip]="{position: tooltipPosition, text: showTooltip ? personInfo.firstName + ' ' + personInfo.lastName: ''}">
    <span class="initials">{{personInfo.initials()}}</span>
    <img *ngIf="personInfo.avatar.isEmpty() && gravatarBlobUrl" class="gravatar i$spaceBetweenColumnsmg-circle" [src]="gravatarBlobUrl" draggable="false" />
    <img *ngIf="personInfo.avatar.isDefined() && avatarUrl" class="gravatar i$spaceBetweenColumnsmg-circle" onerror='this.style.display = "none"' [src]="avatarUrl" draggable="false" />
<!--    <div class="activityIndicatorAureola"><div class="activityIndicator"></div></div></div>-->
  </div>
</div>
<div class="avatarContainer" *ngIf="incomingEmailPerson">
  <div class="icon">
    <i class="mi-mail"></i>
  </div>
</div>

<span *ngIf="showName && personInfo" class="personName">
  <span class="remoteOrganization" *ngIf="remoteOrganizationInfo.length > 0">{{remoteOrganizationInfo}}</span>
  <ng-container *ngIf="!shortCurrentUserLabel || !currentUser">
     {{personInfo.firstName}} {{personInfo.lastName}}
  </ng-container>
  <ng-container *ngIf="shortCurrentUserLabel && currentUser">
    {{shortCurrentUserLabel}}
  </ng-container>

</span>
