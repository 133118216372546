import {
  Component,
  ComponentRef,
  createNgModule,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {AggregateId, FileUri, ScreenInstanceId} from "@utils";
import {ProcessEdgeId, ScreenExternalEventBus} from "@shared-model";
import {StyleSet, StylesResolver, Translation, TranslationsResolver, ViewableFile} from "@shared";
import {Observable} from "rxjs";

export class FilePreviewRequest {
  constructor(
    readonly files: Array<ViewableFile>,
    readonly index: number) {}
}

export class EmailPreviewRequest {
  constructor(
    readonly emailUri: FileUri) {}
}

@Component({
  selector: 'my-shared-screen',
  templateUrl: './shared-screen.component.html'
})
export class SharedScreenComponent implements OnInit {

  private _screenInstanceId!: ScreenInstanceId;
  private instance?: ComponentRef<any>;
  @Input() set screenInstanceId(value: ScreenInstanceId) {this._screenInstanceId = value; this.updateParams()}


  get screenInstanceId(): ScreenInstanceId {
    return this._screenInstanceId;
  }

  @Input() eventBus?: ScreenExternalEventBus;

  _readOnly: boolean = false;
  @Input() set readOnly(value: boolean) {this._readOnly = value; this.updateReadOnly()}

  @Input() terminateOnDestroy: "always"|"never"|"ifNotKeepAlive" = "ifNotKeepAlive";

  @Input() submitRequest?: Observable<ProcessEdgeId>;

  @ViewChild(TemplateRef, { read: ViewContainerRef })
  private templateViewContainerRef: ViewContainerRef|undefined = undefined;

  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();

  @Output() filePreviewRequest: EventEmitter<FilePreviewRequest> = new EventEmitter<FilePreviewRequest>();
  @Input() filePreviewHandledExternally: boolean = false;

  @Input() focusOnFirstInput: boolean = true;

  @Output() screenExternalContextIdChanged: EventEmitter<AggregateId> = new EventEmitter<AggregateId>();

  @Input() allowDebug = false;

  @Output() onNotFound = new EventEmitter<void>();

  @Input() externalDebugButton: boolean = false;

  @Input() checkResponsive?: Observable<void>;

  constructor(private injector: Injector,
              private stylesResolver: StylesResolver,
              private translationsResolver: TranslationsResolver) {}

  ngOnInit(): void {
    this.createScreenComponent(this._screenInstanceId);
  }


  private createScreenComponent(screenInstanceId: ScreenInstanceId) {

    const module = import('../../modules/screen.module/screen.module');
    const styles = this.stylesResolver.loadStyles(StyleSet.screenCommon, StyleSet.screen);
    const translations = this.translationsResolver.loadTranslations(Translation.screen);


    Promise.all([module, styles, translations]).then(values => {

      const ScreenModule = values[0].ScreenModule;

      if (this.templateViewContainerRef === undefined) {
        throw new Error("templateViewContainerRef is undefined");
      } else {

        const moduleRef = createNgModule(ScreenModule, this.injector)

        this.templateViewContainerRef.clear();
        const instance = this.templateViewContainerRef.createComponent(moduleRef.instance.getScreenComponent(), {ngModuleRef: moduleRef});
        this.instance = instance;

        if (instance === undefined) {
          throw new Error("Failed to create component");
        } else {
          instance.instance.terminateOnDestroy = this.terminateOnDestroy;
          instance.instance.screenInstanceId = screenInstanceId;
          instance.instance.readOnly = this._readOnly;
          instance.instance.submitRequest = this.submitRequest;
          instance.instance.submitted = this.submitted;
          instance.instance.filePreviewRequest = this.filePreviewRequest;
          instance.instance.filePreviewHandledExternally = this.filePreviewHandledExternally;
          instance.instance.focusOnFirstInput = this.focusOnFirstInput;
          instance.instance.screenExternalContextIdChanged = this.screenExternalContextIdChanged;
          instance.instance.allowDebug = this.allowDebug;
          instance.instance.onNotFound = this.onNotFound;
          instance.instance.checkResponsive = this.checkResponsive;
          if(this.eventBus !== undefined) {
            instance.instance.eventBus = this.eventBus;
          }
          instance.instance.externalDebugButton = this.externalDebugButton;
        }
      }

    });
  }

  private updateParams() {
    if (this.instance !== undefined) {
      this.instance.instance.screenInstanceId = this._screenInstanceId;
      this.instance.instance.readOnly = this._readOnly;
    }
  }

  private updateReadOnly() {
    if (this.instance !== undefined) {
      this.instance.instance.readOnly = this._readOnly;
    }
  }
}
