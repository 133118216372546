import { Component } from '@angular/core';
import {global} from "@utils";

@Component({
  selector: 'my-about-page',
  templateUrl: './about-page.component.html',
})
export class AboutPageComponent {

  aboutParams= {
    version: global.config.applicationVersion,
    buildTimestamp: global.config.buildTimestamp,
    licenseOwner: global.config.licenseOwner
  }

}
