import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: "my-search-bar",
  templateUrl: "./search-bar.component.html",
  host: {
    "[class.theme-flat]": "flat"
  }
})
export class SearchBarComponent {
  @Input() placeholder = "";
  @Output() queryChange = new EventEmitter<string>();
  @Output() applied = new EventEmitter<string>();

  @Output() searchFocused = new EventEmitter<void>();

  @Input() flat = false;

  private _query: string = "";
  @Input() focusOnShow: boolean = true;

  get query(): string {
    return this._query;
  }

  @Input()
  set query(value: string) {
    this._query = value;
    this.search();
  }

  search() {
    this.queryChange.emit(this._query);
  }

  clear() {
    this._query = "";
    this.queryChange.emit("");
    this.applied.emit("");
  }
}
