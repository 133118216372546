import {Component, OnInit} from '@angular/core';
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {__, i18n, Option, WeekDay, WeekDayList} from "@utils";

class FirstDayOfWeekOption implements DropDownSelectorOption {
  nameOnList: string = "";
  constructor(readonly value: WeekDay|null,
              readonly name: string) {}
}

@Component({
  selector: 'my-first-day-of-week-selector',
  templateUrl: './first-day-of-week-selector.component.html'
})
export class FirstDayOfWeekSelectorComponent implements OnInit {

  firstDayOfWeek: WeekDay|null = null;
  firstDayOfWeekOptions: Array<FirstDayOfWeekOption>;
  defaultFirstDayOfWeek: FirstDayOfWeekOption = null!;
  selectedFirstDayOfWeek: FirstDayOfWeekOption = null!;

  constructor(readonly userSettingsService: UserSettingsStateService) {
    let options = WeekDayList.map(day => new FirstDayOfWeekOption(day, i18n("calendar_weekday" + day)));

    this.defaultFirstDayOfWeek = new FirstDayOfWeekOption(null,  options[0].name);
    this.defaultFirstDayOfWeek.nameOnList = i18n("common_default_male");

    this.firstDayOfWeekOptions = [this.defaultFirstDayOfWeek, ...options]

    this.userSettingsService.getFirstDayOfWeekObservable().forEach(firstDayOfWeek => {
      this.firstDayOfWeek = firstDayOfWeek.getOrNull();
    })
  }

  ngOnInit(): void {
    this.firstDayOfWeek = this.userSettingsService.getFirstDayOfWeek().getOrNull();
    this.selectedFirstDayOfWeek = __(this.firstDayOfWeekOptions).find(fd => fd.value === this.firstDayOfWeek).getOrElse(this.defaultFirstDayOfWeek);
  }

  firstDayOfWeekChanged(firstDayOfWeek: FirstDayOfWeekOption|DropDownSelectorOption) {
    this.firstDayOfWeek = firstDayOfWeek.value;
    this.userSettingsService.changeFirstDayOfWeek(Option.of(this.firstDayOfWeek));
  }
}
