  <div class="fileUploadDropZone">

    <div class="container" *ngIf="viewModel.entries.length > 0">

      <div class="attachment"
           *ngFor="let entry of viewModel.entries"
           [class.contextMenu]="contextMenuVisible"
           (contextmenu)="showContextMenu($event); $event.stopPropagation(); false;">

        <div class="entryPlaceholder" *ngIf="entry.placeholder"></div>

        <div class="fileInfo" *ngIf="!entry.placeholder && entry.entryType == 'file'">

          <div class="uploadProgress" *ngIf="entry.uploadInProgress" [style.width]="entry.uploadProgressPercentage"></div>

          <div *ngIf="!entry.uploadInProgress" class="fileDetails">

            <a *ngIf="entry.exists" onclick="return false;" [href]="entry.downloadUrl"
               (click)="viewModel.toggleFileViewerVisibility()" class="fileName">
              <i class="mi-file fileIcon"></i><span class="nameOnly" [my-tooltip]="{position: 'top', text: entry.tooltipName}">{{entry.visibleName}}</span><span class="fileExtension" *ngIf="entry.visibleExtension">{{entry.visibleExtension}}</span>
            </a>

            <span *ngIf="!entry.exists" class="fileName">
              <i class="mi-file fileIcon"></i><span class="nameOnly" [my-tooltip]="{position: 'top', text: entry.tooltipName}">{{entry.visibleName}}</span><span class="fileExtension" *ngIf="entry.visibleExtension">{{entry.visibleExtension}}</span>
            </span>

            <span *ngIf="entry.exists && viewModel.detailed" class="details">
              <span>{{entry.size | myFileSizeFormat}}</span>
              <span class="theme-content-vertical-separator"></span>
              <span *ngIf="entry.version > 1">{{'screen_file_version' | i18n}} {{entry.version}}</span>
              <span *ngIf="entry.version > 1" class="theme-content-vertical-separator"></span>
              <my-time-summary [time]="entry.modified" />
            </span>

          </div>


          <div class="uploadProgressInfo" *ngIf="entry.uploadInProgress">

            <span *ngIf="entry.exists" class="fileName">
              <i class="mi-file fileIcon"></i>{{entry.name}}
            </span>

            <span *ngIf="entry.exists" class="entry">
              <span *ngIf="entry.size > 0">
                <span class="uploadedSize">{{entry.uploadedSize | myFileSizeFormat}}</span> / {{entry.size | myFileSizeFormat}}
              </span>
            </span>

            <button class="cancel" (click)="cancelUpload(entry)"><i class="mi-delete"></i></button>

          </div>

        </div>

        <div class="directoryInfo" *ngIf="!entry.placeholder && entry.entryType == 'directory'">
          <i class="mi-directory"></i>
          Directory
          <span>{{entry.name}}</span>
        </div>

        <div class="businessEntityInfo" *ngIf="!entry.placeholder && entry.entryType == 'businessEntity'">
          <button class="namePart" (click)="entry.toggleNavigationVisibility()">
            <div class="entitySummary">{{entry.summary}}</div>
          </button>
        </div>

        <div class="flowInfo" *ngIf="!entry.placeholder && entry.entryType == 'flow'">
          <button class="namePart" (click)="entry.toggleNavigationVisibility()">
            <span class="flowCode" [my-tooltip]="{text: entry.processName + ' ' +entry.caseName, enabled: viewModel.minimal}">
                <i class="mi-right flowIcon"></i>
                <span class="code">{{entry.code}}</span>
              </span>
            <span class="processName" *ngIf="viewModel.detailed">{{entry.processName}}</span>
            <span class="caseName" *ngIf="viewModel.detailed">{{entry.caseName}}</span>
          </button>
          <span class="details" *ngIf="viewModel.detailed">
            <span class="processStatus" *ngIf="entry.inProgress">{{'screen_flow_in_progress' | i18n}}</span>
            <span class="processStatus" *ngIf="entry.completed">{{'screen_flow_completed' | i18n}}</span>
            <span class="processStatus" *ngIf="entry.terminated">{{'screen_flow_terminated' | i18n}}</span>
          </span>

        </div>

        <!--TODO FLOW NAVIGATION -->
<!--          <my-flow-navigation-popup *ngIf="!$any(viewModel.attachmentInfo).placeholder && $any(viewModel.attachmentInfo).entryType == 'flow' && $any(viewModel.attachmentInfo).navigationVisible" view-model='$any(viewModel.attachmentInfo).navigation'></my-flow-navigation-popup>-->

        <div class="emailInfo" *ngIf="!entry.placeholder && entry.entryType == 'email'">
          <span *ngIf="!entry.exists" class="emailData">
              <span class="emailIcon">
                <i class="mi-mail mainEmailIcon"></i>
              </span>
              <span class="subject">Email deleted</span>
            </span>
          <span *ngIf="entry.exists" class="emailData">
            <button class="namePart" (click)="$any(viewModel).toggleEmailViewerVisibility(entry)">
              <span class="emailIcon">
              <i class="mi-mail mainEmailIcon"></i>
              <i class="mi-up emailDirectionIcon" *ngIf="!entry.received"></i>
              <i class="mi-down emailDirectionIcon" *ngIf="entry.received"></i>
            </span>
              <span class="subject">{{entry.subject}}</span>
            </button>
              <span *ngIf="viewModel.detailed" class="details">
                <span class="senderReceiver" [my-tooltip]="{text: entry.senderReceiverAddress, position: 'top'}">{{entry.senderReceiverName}}</span><!--
           --><div class="theme-content-vertical-separator"></div><!--
             --><span class="created"><my-time-summary [time]="entry.created" /></span>
              </span>
            </span>
        </div>

        <div class="unknownInfo" *ngIf="entry.entryType == 'unknown'">
          <i class="mi-help"></i>
          <span>{{entry.variableTypeName}}</span>
        </div>


        <div class="deleteButton" *ngIf="!viewModel.disabled && viewModel.addRemoveEnabled && !entry.uploadInProgress && !entry.placeholder">
          <div class="deleteSeparator theme-content-vertical-separator-no-margin"></div>
          <button class="delete" (click)="viewModel.onDelete()"><i class="mi-delete"></i></button>
        </div>


      </div>

    </div>


  <div class="theme-file-drop-area-glass"
       [class.hidden]="viewModel.attachmentDefined"
       *ngIf="!viewModel.disabled && viewModel.addRemoveEnabled"></div>

  <div [class.hidden]="viewModel.attachmentDefined || viewModel.disabled || !viewModel.addRemoveEnabled"
       [class.separated]="viewModel.entries.length > 0" class="uploadSection" *ngIf="!viewModel.minimal">

    <span class="uploadInfo">
              <i class="mi-attach"></i>{{'screen_attachment_select_or_drop_a' | i18n}}
      <button class="uploadButton maximal">{{'screen_attachment_select_or_drop_b' | i18n}}</button>
              <ng-container *ngIf="viewModel.attachFromRepository">
                {{'screen_attachment_select_or_drop_c' | i18n}}
                <button class="repositoryButton">{{'screen_attachment_select_or_drop_d' | i18n}}</button>
              </ng-container>

    </span>

  </div>

  <ng-container *ngIf="viewModel.minimal && viewModel.entries.length === 0">
    <button class="uploadButton minimal">
      <i class="mi-attach"></i>{{viewModel.placeholder}}
    </button>
  </ng-container>

</div>


<my-drop-menu cssClass="theme-drop-menu"
              (closed)="closeContextMenu()"
              [pointAnchor]="contextMenuPointAnchor"
              [(visible)]="contextMenuVisible">
  <ng-container *ngIf="contextMenuVisible && viewModel.entry">
    <button class="theme-drop-menu-entry" *ngIf="viewModel.entry.isFile" (click)="viewModel.toggleFileViewerVisibility(); closeContextMenu()"><i class="mi-view"></i>{{'Preview' | i18n}}</button>
    <a class="theme-drop-menu-entry"
       *ngIf="viewModel.entry.isFile && !viewModel.entry.uploadInProgress && !viewModel.entry.placeholder"
       [href]="$any(viewModel.entry).downloadUrl" onclick="event.stopPropagation()" (click)="closeContextMenu()"><i class="mi-download"></i>{{'Download' | i18n}}</a>
    <ng-container *ngIf="!viewModel.disabled && viewModel.addRemoveEnabled && !viewModel.entry.uploadInProgress && !viewModel.entry.placeholder">
      <hr class="theme-drop-menu-separator" />
      <button class="theme-drop-menu-entry theme-danger" (click)="viewModel.onDelete(); closeContextMenu();"><i class="mi-delete"></i>{{'common_delete' | i18n}}</button>
    </ng-container>
  </ng-container>
</my-drop-menu>
