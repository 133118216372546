import {None, NoneSingleton, Option, Some, VariableId} from "@utils";
import {PasswordValue, PasswordVariable} from "@shared-model";
import {ComponentsCommon} from "../ComponentsModel";
import {BlinkingValueComponentViewModel} from "./BlinkingComponentController";
import {PasswordInputComponentRefState, PasswordInputComponentState} from "./PasswordInputComponentState";
import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel, ScreenWrapperViewModel
} from "../screen-component.view-model";
import {CssBuilder, PasswordInputComponentDefinition, PasswordInputComponentRef} from "@screen-common";
import {ComponentIcons, ScreenSharedViewModel} from "../..";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class PasswordInputComponentViewModel extends ComponentViewModelWithLabel implements BlinkingValueComponentViewModel {

  override typeName = "PasswordInput";

  maxLength = 256;

  private valueBackup: string|undefined = undefined;

  public value: string|undefined;
  public changed: boolean = false;
  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false;

  public valueChangeListener: Option<() => void> = None();

  public css = "";
  public cssClasses: string = "";
  public outerCss = "";
  public outerCssClasses = "";


  startIcon: string|undefined = undefined;
  endIcon: string|undefined = undefined;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: PasswordInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: PasswordInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: PasswordInputComponentState,
              readonly refState: PasswordInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
             ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onFocus() {
    this.valueBackup = this.value;
  }

  onBlur() {
    if(this.valueBackup != this.value) {
      if (this.value === undefined || this.value.trim().length === 0) {
        this.componentState.updateModel(PasswordInputComponentDefinition.MODEL, Some(new PasswordVariable(new PasswordValue(""))));
        this.serverModel.changeModelWithAction(this.componentRefPath(), PasswordInputComponentDefinition.MODEL, new PasswordVariable(new PasswordValue("")), PasswordInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(PasswordInputComponentDefinition.MODEL, Some(new PasswordVariable(new PasswordValue(this.value.trim()))));
        this.serverModel.changeModelWithAction(this.componentRefPath(), PasswordInputComponentDefinition.MODEL, new PasswordVariable(new PasswordValue(this.value.trim())), PasswordInputComponentDefinition.ON_CHANGE);
      }
      this.valueBackup = this.value;
    }
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = CssBuilder.create();
    const outerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState) ;
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    ComponentViewModelUtils.toOuterShadowCss(outerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(cssBuilder, innerShadow);

    if(this.preview) {
      this.placeholder = "-";
      this.tooltip = None();
    } else {
      this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("");
      this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    }

    const newValue = this.componentState.model.valueOrDefault(None()).getOrUndefined();
    if(newValue === '' || newValue === undefined) { // only update password if it is cleared
      if(this.value != newValue) {
        this.valueChangeListener.forEach(listener => listener());
      }
      this.value = newValue;
    }
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    const icon = this.definition.icon.currentValue(() => this.componentState.icon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined);
    const iconPosition: string = this.definition.iconPosition.currentValue(() => this.componentState.iconPosition).valueOrDefault("center");

    // if no icon is defined, use the startIcon deprecated property
    this.startIcon = icon !== undefined && iconPosition === "start" ? icon : (
      this.definition.startIcon.currentValue(() => this.componentState.startIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    // if no icon is defined, use the endIcon deprecated property
    this.endIcon = icon !== undefined && iconPosition === "end" ? icon : (
      this.definition.endIcon.currentValue(() => this.componentState.endIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

    this.outerCss = outerCssBuilder.toCss();
    this.outerCssClasses = outerCssBuilder.toCssClasses();
  }


  addValueChangeListener(valueChangeListener: () => void) {
    this.valueChangeListener = Some(valueChangeListener);
  }

  onHeightAdjusted(): void {
    this.shared.eventBus.componentHeightChanged();
  }
}
