import {BusinessVariable, ObjectVariable} from "@shared-model";

export class SelectComponentOption {
  constructor(public id: string,
              public selected: boolean,
              public label: string,
              readonly value: BusinessVariable | undefined) {}

  static of(index: number, value: BusinessVariable, nameProvider: (v: BusinessVariable) => Promise<string>|string): SelectComponentOption {
    if(value instanceof ObjectVariable) {
      if(value.valueFor("label").isDefined() && value.valueFor("value").isDefined()) {

        const v = value.valueFor("label").get();

        const label = nameProvider(v);

        if(label instanceof Promise) {
          const option = new SelectComponentOption(index+"", false, "", value.valueFor("value").getOrError("no value"));
          label.then(valueName => {
            option.label = valueName;
          })
          return option;
        } else {
          return new SelectComponentOption(index+"", false, label, value.valueFor("value").getOrError("no value"));
        }

      } else {
        return new SelectComponentOption(index+"", false, "Error: Objects in options must have 'value/id' and 'label/name' fields", undefined);
      }
    } else {

      const label = nameProvider(value);
      if(label instanceof Promise) {
        const option =  new SelectComponentOption(index+"", false, "", value);
        label.then(valueName => {
          option.label = valueName;
        })
        return option;
      } else {
        return new SelectComponentOption(index+"", false, label, value);
      }
    }
  }

  static EMPTY(placeholder: string) {
    return new SelectComponentOption("xnp7Y6uPONKZiKEr", false, placeholder, undefined);
  }
}
