import {
  CssBuilder,
  DateInputComponentDefinition,
  DurationInputComponentDefinition,
  DurationInputComponentRef
} from "@screen-common";
import {IntervalVariable} from "@shared-model";
import {DurationInputComponentRefState, DurationInputComponentState} from "./DurationInputComponentState";
import {
  Duration,
  i18nLanguage,
  Language,
  None,
  NoneSingleton,
  Option,
  parseDurationInputString,
  Some,
  VariableId
} from "@utils";
import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "../screen-component.view-model";
import {ScreenSharedViewModel} from "../..";
import {ComponentsCommon} from "../ComponentsModel";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class DurationInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "DurationInput";

  public value: Duration | null = null;

  public textModel: string|undefined = "";

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false;
  public anchorVisible: boolean = false;
  public useSecondsInPicker: boolean = false;

  public hoursInDays: number = 24;
  public daysMarker: string = 'd';
  public hoursMarker: Array<string> = ['h'];
  public minutesMarker: string = 'm';
  public secondsMarker: string = 's';
  public separator: string = ' ';

  public innerCss: string = "";
  public innerCssClasses: string = "";
  public combinedCss = "";
  public combinedCssClasses: string = "";

  focused = false;
  keyboardInput = false;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: DurationInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: DurationInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: DurationInputComponentState,
              readonly refState: DurationInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);

    if(i18nLanguage() === Language.PL) {
      this.hoursMarker = ['g', 'h'];
    } else {
      this.hoursMarker = ['h'];
    }

    this.update();

  }


  onFocus() {
    if(!this.disabled && !this.focused) {
      this.focused = true;
    }
  }

  onPicked() {
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  }

  onChanged() {
    this.updateTextModel();
  }



  parseTextModel() {
    if (this.textModel && this.textModel.trim().length > 0) {
      this.value = parseDurationInputString(this.textModel, {
        daysMarker: this.daysMarker,
        hoursMarker: this.hoursMarker,
        minutesMarker: this.minutesMarker,
        secondsMarker: this.secondsMarker,
        separator: this.separator,
        hoursInDay: this.hoursInDays
      });

      this.updateTextModel();
    }
  }

  updateTextModel() {
    if (this.value === null) {
      this.textModel = undefined;
    } else {
      this.textModel = this.value.format(this.daysMarker, this.hoursMarker, this.minutesMarker, this.secondsMarker, this.separator, this.hoursInDays);
    }
  }

  closePicker() {
    this.focused = false;
  }

  textInputAccepted() {
    this.parseTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  };


  onClear() {
    this.value = null;
    this.updateTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  }

  updateValueChanged() {
    if (this.uncoveredAndVisible) {

      if (this.value === null) {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), DurationInputComponentDefinition.MODEL, DurationInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, Some(new IntervalVariable(this.value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), DurationInputComponentDefinition.MODEL, new IntervalVariable(this.value), DurationInputComponentDefinition.ON_CHANGE);
      }
    }
  }

  updateComponent(deep: boolean): void {


    const outsideCssBuilder = new CssBuilder();
    const innerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toSizeCss(outsideCssBuilder, this.definition.sizeProperties, this.componentState.boxState);
    ComponentViewModelUtils.toOuterShadowCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    ComponentViewModelUtils.toBackgroundCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toPaddingsCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toTextCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.hoursInDays = this.definition.hoursInDay.currentValue(() => this.componentState.hoursInDay).valueOrDefault(24);

    this.value = this.componentState.model.valueOrDefault(None()).getOrNull();
    this.updateTextModel();

    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("h m");
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);


    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(false);

    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(innerCssBuilder, innerShadow);
    super.updatePosition();

    this.combinedCss = outsideCssBuilder.toCss() + this.sizeCss;
    this.combinedCssClasses = outsideCssBuilder.toCssClasses();

    this.innerCss = innerCssBuilder.toCss();
    this.innerCssClasses = innerCssBuilder.toCssClasses();
  }

}

