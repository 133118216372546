import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {

  private readonly _SessionStorageService: undefined; // force TypeScript to check types (workaround for duck typing)

  setBoolean(key: string, value: boolean) {
    sessionStorage.setItem(key, value ? "t" : "f");
  }

  getBoolean(key: string, defaultValue: boolean = false): boolean {
    const item = sessionStorage.getItem(key);
    if (item == "t") {
      return true;
    } else if (item == "f") {
      return false;
    } else {
      return defaultValue;
    }
  }

  setString<T>(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getString(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  getObjectIfExists<T extends object>(key: string, onSuccess: (value: T) => void, onNotFound: () => void): void {
    const fromStorage = sessionStorage.getItem(key);
    if (fromStorage) {
      onSuccess(JSON.parse(fromStorage) as T);
    } else {
      onNotFound();
    }
  }

  setObject<T extends object>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeItem<T>(key: string) {
    sessionStorage.removeItem(key);
  }

}
