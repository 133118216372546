

<div class="notifications">
  <div class="notificationsTitleSection">
    <div class="notificationsTitle">{{'notifications_notifications' | i18n}}</div>
    <a routerLink="/user/notifications" (click)="hidePanel()">{{'notifications_show_all' | i18n}}</a>
    <div class="separator theme-action-button-separator"></div>
    <button class="close theme-button-close" (click)="hidePanel()"><i class="mi-delete"></i></button>
  </div>
  <hr class="notificationsInfoSeparator theme-content-horizontal-separator-no-margin" />
  <my-scroll contentClass="notificationsContent" [contentSizeChanged]="notificationsContentSizeChanged">
    <my-notifications (sizeChanged)="notificationsContentSizeChanged.emit()" [recentOnly]="true"></my-notifications>
  </my-scroll>
</div>

<div class="settings">

  <div class="accessibility">
    <div class="theme-form-title">{{'user_interface_look' | i18n}}</div>

    <div class="theme-settings-option">
      <i class="mi-eclipse-alt"></i>
      <span class="theme-label">{{'user_interface_look' | i18n}}</span>
      <my-theme-selector />
    </div>

    <div class="theme-settings-option">
      <i class="mi-text"></i>
      <span class="theme-label">{{'user_text_size' | i18n}}</span>
      <my-font-size-selector />
    </div>
  </div>

  <hr class="notificationsInfoSeparator theme-content-horizontal-separator-small-margin" />
  <div class="userSummary">
    <div class="userName">{{userName}}</div>
    <a class="theme-button-option" routerLink="/user" (click)="hidePanel()" [my-tooltip]="{text: 'user_settings_title' | i18n, position: 'top'}"><i class="mi-settings-sliders"></i></a>
    <div class="theme-action-button-separator"></div>
    <button class="theme-button-option" (click)="logout()"  [my-tooltip]="{i18n: 'main_menu_logout'}"><i class="mi-log-out"></i></button>
  </div>


</div>
