import {AggregateId} from "../cqrs/AggregateId";
import {ApplicationId, DatabaseConnectionId, DepartmentId, GroupId, ProcessId, ScreenId} from "./IdModel";
import {Typed} from "./Typed";

export interface AnyPersonId {
  className(): string;

  serialize(): string;

  asPersonId(): PersonId;

  asRemotePersonId(): RemotePersonId;

  equals(person: AnyPersonId): boolean;

  isPersonId(): boolean;

  isRemotePersonId(): boolean;
}

export class AnyPersonIdHelper {

  static equals(a: AnyPersonId, b: AnyPersonId): boolean {
    if (a instanceof PersonId && b instanceof PersonId) {
      return a.id.id === b.id.id;
    } else if (a instanceof RemotePersonId && b instanceof RemotePersonId) {
      return a.id === b.id && a.remoteOrganization === b.remoteOrganization;
    } else {
      return false;
    }
  }

  static deserialize(serialized: string) {
    if(serialized == PersonId.NO_ONE.id.id) {
      return PersonId.NO_ONE;
    } else if(serialized == PersonId.ANONYMOUS.id.id) {
      return PersonId.ANONYMOUS;
    } else if(serialized == PersonId.webClient.id.id) {
      return PersonId.webClient;
    } else {
      const parts = serialized.split("_");
      if (parts.length === 2) {
        return new RemotePersonId(parts[0], parts[1]);
      } else {
        return PersonId.of(serialized);
      }
    }
  }
}

export class AnyPersonIdFactory {
  static copyTyped(id: Typed<AnyPersonId>): Typed<AnyPersonId> {
    switch (Typed.className(id)) {
      case PersonId.className:
        return Typed.of(PersonId.of(<PersonId>Typed.value(id)));
      case RemotePersonId.className:
        return Typed.of(RemotePersonId.copy(<RemotePersonId>Typed.value(id)));
      default:
        throw new Error("Unsupported type [" + Typed.className(id) + "]");
    }
  }

  static copy(id: AnyPersonId): AnyPersonId {
    switch (id.className()) {
      case PersonId.className:
        return PersonId.of(<PersonId>id);
      case RemotePersonId.className:
        return RemotePersonId.copy(<RemotePersonId>id);
      default:
        throw new Error("Unsupported type [" + id.className() + "]");
    }

  }
}


export class RemotePersonId implements AnyPersonId {
  static className = "RemotePersonId";

  className(): string {
    return RemotePersonId.className;
  }

  constructor(readonly remoteOrganization: string,
              readonly id: string) {
  }

  static copy(other: RemotePersonId): RemotePersonId {
    return new RemotePersonId(other.remoteOrganization, other.id);
  }

  asPersonId(): PersonId {
    throw new Error("Not a local person");
  }

  asRemotePersonId(): RemotePersonId {
    return this;
  }

  equals(person: AnyPersonId): boolean {
    return AnyPersonIdHelper.equals(this, person);
  }

  serialize(): string {
    return this.remoteOrganization + "_" + this.id;
  }

  isPersonId(): boolean {
    return false;
  }

  isRemotePersonId(): boolean {
    return true;
  }
}

export class PersonId implements AnyPersonId {


  static className = "PersonId";

  private readonly _PersonId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, PersonId> = new Map<string, PersonId>();

  className(): string {
    return PersonId.className;
  }

  private constructor(public id: AggregateId) {}

  static copy(other: PersonId): PersonId {
    return PersonId.of(other.id.id);
  }

  static of(id: string|PersonId|AggregateId): PersonId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id.id);

    const fromCache = PersonId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new PersonId(new AggregateId(idString));
      PersonId.cache.set(idString, id);
      return id;
    }
  }


  static unique(ids: PersonId[]): PersonId[] {
    const uIds: PersonId[] = [];

    ids.forEach(id => {
      if (uIds.filter(uId => uId.id.id === id.id.id).length === 0) {
        uIds.push(id);
      }
    });

    return uIds;
  }

  static ANONYMOUS = PersonId.of(new AggregateId("up8odosmhrys"))
  static NO_ONE = new PersonId(new AggregateId("NO_ONE"));
  static webClient: PersonId = PersonId.of(new AggregateId("WebClient"));

  static isWebClient(personId: PersonId): boolean {
    return AnyPersonIdHelper.equals(personId, PersonId.webClient);
  }

  serialize(): string {
    return this.id.id;
  }

  asPersonId(): PersonId {
    return this;
  }

  asRemotePersonId(): RemotePersonId {
    throw new Error("Not a remote person");
  }

  equals(person: AnyPersonId): boolean {
    return AnyPersonIdHelper.equals(this, person);
  }

  isPersonId(): boolean {
    return true;
  }

  isRemotePersonId(): boolean {
    return false;
  }

}


export enum OrganizationNodeTypeEnum {
  person = "person",
  department = "department",
  group = "group",
  remoteOrganization = "remoteOrganization",
  process = "process",
  report = "report",
  application = "application",
  flow = "flow",
  screen = "screen",
  functions = "functions",
  databaseConnection = "databaseConnection",
}

export class OrganizationNodeType {
  constructor(readonly name: OrganizationNodeTypeEnum) {
  }

  static copy(other: OrganizationNodeType): OrganizationNodeType {
    switch (other.name) {
      case OrganizationNodeType.person.name:
        return OrganizationNodeType.person;
      case OrganizationNodeType.department.name:
        return OrganizationNodeType.department;
      case OrganizationNodeType.group.name:
        return OrganizationNodeType.group;
      case OrganizationNodeType.remoteOrganization.name:
        return OrganizationNodeType.remoteOrganization;
      case OrganizationNodeType.process.name:
        return OrganizationNodeType.process;
      case OrganizationNodeType.report.name:
        return OrganizationNodeType.report;
      case OrganizationNodeType.application.name:
        return OrganizationNodeType.application;
      case OrganizationNodeType.flow.name:
        return OrganizationNodeType.flow;
      case OrganizationNodeType.screen.name:
        return OrganizationNodeType.screen;
      case OrganizationNodeType.functions.name:
        return OrganizationNodeType.functions;
      case OrganizationNodeType.databaseConnection.name:
        return OrganizationNodeType.databaseConnection;
      default:
        throw new Error("Unsupported node type [" + other.name + "]");
    }
  }

  static person = new OrganizationNodeType(OrganizationNodeTypeEnum.person);
  static department = new OrganizationNodeType(OrganizationNodeTypeEnum.department);
  static group = new OrganizationNodeType(OrganizationNodeTypeEnum.group);
  static remoteOrganization = new OrganizationNodeType(OrganizationNodeTypeEnum.remoteOrganization);
  static process = new OrganizationNodeType(OrganizationNodeTypeEnum.process);
  static report = new OrganizationNodeType(OrganizationNodeTypeEnum.report);
  static application = new OrganizationNodeType(OrganizationNodeTypeEnum.application);
  static flow = new OrganizationNodeType(OrganizationNodeTypeEnum.flow);
  static screen = new OrganizationNodeType(OrganizationNodeTypeEnum.screen);
  static functions = new OrganizationNodeType(OrganizationNodeTypeEnum.functions);
  static databaseConnection = new OrganizationNodeType(OrganizationNodeTypeEnum.databaseConnection);

}


export class OrganizationNodeId {

  constructor(readonly id: AggregateId,
              readonly nodeType: OrganizationNodeType) {
  }

  static copy(other: OrganizationNodeId) {
    return new OrganizationNodeId(AggregateId.copy(other.id),
      OrganizationNodeType.copy(other.nodeType))
  }

  // static fromNode(node: OrganizationNode) {
  //   if (node.isDepartment() || node.isOrganization()) {
  //     return this.fromDepartmentId(node.id);
  //   } else if (node.isPerson()) {
  //     return this.fromPersonId(node.id);
  //   } else if (node.isGroup()) {
  //     return this.fromGroupId(node.id);
  //   } else {
  //     throw new Error("Undefined parent node type detected");
  //   }
  // }

  static equals(a: OrganizationNodeId, b: OrganizationNodeId): boolean {
    return a.id.id === b.id.id && a.nodeType.name === b.nodeType.name;
  }

  static fromDepartmentId(departmentId: AggregateId|DepartmentId) {
    if(departmentId instanceof DepartmentId) {
      return new OrganizationNodeId(AggregateId.copy(departmentId.id), OrganizationNodeType.department);
    } else {
      return new OrganizationNodeId(AggregateId.copy(departmentId), OrganizationNodeType.department);
    }
  }

  static fromPersonId(personId: AggregateId|PersonId) {
    if(personId instanceof PersonId) {
      return new OrganizationNodeId(AggregateId.copy(personId.id), OrganizationNodeType.person);
    } else {
      return new OrganizationNodeId(AggregateId.copy(personId), OrganizationNodeType.person);
    }
  }

  static fromGroupId(groupId: AggregateId|GroupId) {
    if(groupId instanceof GroupId) {
      return new OrganizationNodeId(AggregateId.copy(groupId.id), OrganizationNodeType.group);
    } else {
      return new OrganizationNodeId(AggregateId.copy(groupId), OrganizationNodeType.group);
    }
  }

  static fromProcessId(processId: AggregateId|ProcessId) {
    if(processId instanceof ProcessId) {
      return new OrganizationNodeId(AggregateId.copy(processId.id), OrganizationNodeType.process);
    } else {
      return new OrganizationNodeId(AggregateId.copy(processId), OrganizationNodeType.process);
    }
  }

  static fromApplicationId(applicationId: ApplicationId) {
    return new OrganizationNodeId(new AggregateId(applicationId.id), OrganizationNodeType.application);
  }

  static fromScreenId(screenId: ScreenId) {
    return new OrganizationNodeId(new AggregateId(screenId.id), OrganizationNodeType.application);
  }

  static fromDatabaseId(databaseConnectionId: DatabaseConnectionId) {
    return new OrganizationNodeId(new AggregateId(databaseConnectionId.id), OrganizationNodeType.databaseConnection);
  }

  isPerson() {
    return this.nodeType.name === OrganizationNodeType.person.name;
  }

  isDepartment() {
    return this.nodeType.name === OrganizationNodeType.department.name;
  }

  isGroup() {
    return this.nodeType.name === OrganizationNodeType.group.name;
  }

  isRemoteOrganization() {
    return this.nodeType.name === OrganizationNodeType.remoteOrganization.name;
  }

  isProcess() {
    return this.nodeType.name === OrganizationNodeType.process.name;
  }

  isReport() {
    return this.nodeType.name === OrganizationNodeType.report.name;
  }

  isApplication() {
    return this.nodeType.name === OrganizationNodeType.application.name;
  }

  isScreen() {
    return this.nodeType.name === OrganizationNodeType.screen.name;
  }

  isFlow() {
    return this.nodeType.name === OrganizationNodeType.flow.name;
  }

  isDatabaseId() {
    return this.nodeType.name === OrganizationNodeType.databaseConnection.name;
  }

  asPerson() {
    if (this.nodeType.name === OrganizationNodeType.person.name) {
      return PersonId.of(this.id);
    } else {
      throw new Error("Not a person");
    }
  }


  asGroup() {
    if (this.nodeType.name === OrganizationNodeType.group.name) {
      return new GroupId(this.id);
    } else {
      throw new Error("Not a group");
    }
  }

  asDepartment() {
    if (this.nodeType.name === OrganizationNodeType.department.name) {
      return new DepartmentId(this.id);
    } else {
      throw new Error("Not a person");
    }
  }

  equals(other: OrganizationNodeId) {
    return this.id.id === other.id.id && this.nodeType.name === other.nodeType.name;
  }

  notEquals(other: OrganizationNodeId) {
    return !this.equals(other);
  }
}
