import {Injectable} from "@angular/core";
import {I18nService, LibrariesService} from "../..";
import {
  ArrayVariable,
  BooleanVariable,
  BusinessVariable,
  CaseStatusVariable,
  DateTimeVariable,
  DateVariable,
  I18nTextVariable,
  LinkVariable,
  NullVariable,
  NumberVariable,
  ObjectVariable,
  StringVariable,
  TimeVariable
} from "@shared-model";
import {__, DeferredValue, Option, Typed} from "@utils";

declare const Papa: any;

@Injectable({
  providedIn: 'root',
})
export class CsvService {

  constructor(readonly librariesService: LibrariesService,
              readonly i18nService: I18nService) {}


  createCsv(columns: Array<BusinessVariable>, rows: Array<Array<BusinessVariable>>,
                            delimiter: string, locale: string, useTimezone: boolean,
            nameProvider: Option<(value: BusinessVariable, columnIndex: number) => DeferredValue<string>>): DeferredValue<string> {

    const header = columns.map(c => c.valueToSimpleString());

    const deferredContent = rows.map(row => row.map((cell, index) => this.variableToCsvValue(index, cell, locale, useTimezone, nameProvider)));

    const deffered = DeferredValue.createNoOnRequest<string>();


    DeferredValue.onAllReady(__(deferredContent).flatMap(t => t), () => {

      const content = deferredContent.map(c => c.map(cc => cc.getValue()));

      this.librariesService.loadPapaParse(() => {
        deffered.resolve(Papa.unparse({fields: header, data: content}, {
          quotes: false, //or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: delimiter,
          header: true,
          newline: "\r\n",
          skipEmptyLines: false, //or 'greedy',
          columns: null // or array of strings
        }));
      });


    });

    return deffered;
  }

  downloadCsv(content: string, fileName: string) {
    this.download(content, fileName, 'text/csv;encoding:utf-8');
  }

  // based on https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  download(content: string, fileName: string, mimeType: string) {
    const a = document.createElement('a');
    const utf8bom = "\ufeff";
    mimeType = mimeType || 'application/octet-stream';

    if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([utf8bom+content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(utf8bom+content); // only this mime type is supported
    }
  }

  variableToCsvValue(columnIndex: number, variable: BusinessVariable, locale: string, useTimezone: boolean,
                     nameProvider: Option<(value: BusinessVariable, columnIndex: number) => DeferredValue<string>>): DeferredValue<any> {
    switch(variable.className()) {
      case ArrayVariable.className: if(nameProvider.isDefined()) {
        const deferredValues = (<ArrayVariable<BusinessVariable>>variable).unwrappedValue().map(v => this.variableToCsvValue(columnIndex, v, locale, useTimezone, nameProvider));

        const deferred = DeferredValue.createNoOnRequest();
        DeferredValue.onAllReady(deferredValues, () => {
          deferred.resolve(deferredValues.map(d => d.getValue()));
        });
        return deferred;
      } else {
        return DeferredValue.resolved(JSON.stringify((<ArrayVariable<BusinessVariable>>variable).toJsonValue()));
      }
      case ObjectVariable.className: if(nameProvider.isDefined()) {
        const objValue = (<ObjectVariable>variable).value;

        const deferredValues = objValue.map(v => <[string, DeferredValue<string>]>[v[0], this.variableToCsvValue(columnIndex, Typed.value(v[1]), locale, useTimezone, nameProvider)]);
        const deferred = DeferredValue.createNoOnRequest();

        DeferredValue.onAllReady(deferredValues.map(d => d[1]), () => {
          let result: Array<string> = [];
          deferredValues.forEach(v => result.push([v[0]]+": "+ v[1].getValue()));
          deferred.resolve(result.join(", "));
        });
        return deferred;
      } else {
        return DeferredValue.resolved(JSON.stringify((<ObjectVariable>variable).toJsonValue()));
      }
      case StringVariable.className: return DeferredValue.resolved((<StringVariable>variable).value.replace(/\r\n/g,"\n").replace(/\r/g,"\n")); // this makes sure content is not broken while importing to excel
      case NumberVariable.className: return DeferredValue.resolved((<NumberVariable>variable).value.toLocaleString(locale, {useGrouping: false, maximumFractionDigits: 15}));
      case BooleanVariable.className: return DeferredValue.resolved((<BooleanVariable>variable).value ? "true" : "false");
      case DateVariable.className: return DeferredValue.resolved((<DateVariable>variable).value.isoFormatted());
      case DateTimeVariable.className: return DeferredValue.resolved(useTimezone ? this.i18nService.formatTimezonedISO((<DateTimeVariable>variable).value) : (<DateTimeVariable>variable).value.isoSimpleFormatted());
      case TimeVariable.className: return DeferredValue.resolved((<TimeVariable>variable).value.formattedToMinutes());
      case LinkVariable.className:
        const linkVariable = (<LinkVariable>variable);
        return DeferredValue.resolved(linkVariable.value.name + linkVariable.separator + linkVariable.value.url);
      case CaseStatusVariable.className: return DeferredValue.resolved((<CaseStatusVariable>variable).value.translate());
      case NullVariable.className: return DeferredValue.resolved("");
      case I18nTextVariable.className:if(nameProvider.isDefined()) {
        return nameProvider.get()(variable, columnIndex);
      } else {
        return DeferredValue.resolved((<I18nTextVariable>variable).value.toJsonValue());
      }
      default: if(nameProvider.isDefined()) {
        return nameProvider.get()(variable, columnIndex);
      } else {
        return DeferredValue.resolved(variable.valueToSimpleString());
      }
    }

  }

}
