import {Injectable} from "@angular/core";
import {global, restUrl, toastr} from "@utils";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {NavigationService} from "@shared";

@Injectable({
  providedIn: 'root',
})
export class OfflineService {

  private _offline = false;
  private offlineSubject = new Subject<boolean>();

  constructor(readonly http: HttpClient,
              readonly navigationService: NavigationService) {}

  get online() {
    return !this._offline;
  }

  get offline() {
    return this._offline;
  }

  getOfflineObservable() {
    return this.offlineSubject.asObservable();
  }

  init() {
    this._offline = global.offline;
    if(this._offline) {
      this.offlineSubject.next(this._offline);
    }

    if(this._offline) {
      setTimeout(() => {
        this.waitForConnection(Date.now());
      }, 100);
    }

    window.addEventListener("focus", (event) =>{
      this.checkConnection();
    });
  }

  waitForConnection(startTimestamp: number) {
    const promise = <Observable<HttpResponse<any>>>this.http.get(restUrl("technical/ping"), {observe: "response", responseType: "text"});
    promise.subscribe({
      next: (result: HttpResponse<any>) => {
        this.navigationService.reloadPage();
      },
      error: (reason) => {

        setTimeout(() => {
          this.waitForConnection(startTimestamp);
        }, (Date.now() - startTimestamp) < 5000 ? 100 : 1000); // in the first 5 seconds try every 100ms, then every second
      }
    });
  }


  checkConnection(onConnectionOk: () => void = () => {}) {
    const promise = <Observable<HttpResponse<any>>>this.http.get(restUrl("technical/ping"), {observe: "response", responseType: "text"});
    promise.subscribe({
      next: (result: HttpResponse<any>) => {
        onConnectionOk();
      },
      error: (reason) => {
        this.setOffline();
      }
    });
  }

  setOffline() {
    this._offline = true;
    this.offlineSubject.next(this._offline);
    setTimeout(() => {
      this.waitForConnection(Date.now());
    }, 100);
  }
}
