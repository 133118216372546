import {Injectable} from "@angular/core";
import {__, Language, Option, WeekDay} from "@utils";


export class RegionWithName {
  constructor(readonly localeCode: string,
              readonly englishName: string,
              readonly nativeName: string) {
  }

  static of(record: [string, string, string]) {
    return new RegionWithName(record[0], record[1], record[2]);
  }
}

export class WeekInfo {
  constructor(readonly firstDay: WeekDay,
              readonly weekend: Array<WeekDay>) {}
}

@Injectable({
  providedIn: 'root',
})
export class LocaleListService {

  private getTimezonesData(): Array<string> {
    return [
      "UTC",
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Addis_Ababa",
      "Africa/Algiers",
      "Africa/Asmera",
      "Africa/Bamako",
      "Africa/Bangui",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Blantyre",
      "Africa/Brazzaville",
      "Africa/Bujumbura",
      "Africa/Cairo",
      "Africa/Casablanca",
      "Africa/Ceuta",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Douala",
      "Africa/El_Aaiun",
      "Africa/Freetown",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Kigali",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Lome",
      "Africa/Luanda",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Malabo",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Africa/Mogadishu",
      "Africa/Monrovia",
      "Africa/Nairobi",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Porto-Novo",
      "Africa/Sao_Tome",
      "Africa/Tripoli",
      "Africa/Tunis",
      "Africa/Windhoek",
      "America/Adak",
      "America/Anchorage",
      "America/Anguilla",
      "America/Antigua",
      "America/Araguaina",
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Aruba",
      "America/Asuncion",
      "America/Bahia",
      "America/Bahia_Banderas",
      "America/Barbados",
      "America/Belem",
      "America/Belize",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Bogota",
      "America/Boise",
      "America/Buenos_Aires",
      "America/Cambridge_Bay",
      "America/Campo_Grande",
      "America/Cancun",
      "America/Caracas",
      "America/Catamarca",
      "America/Cayenne",
      "America/Cayman",
      "America/Chicago",
      "America/Chihuahua",
      "America/Coral_Harbour",
      "America/Cordoba",
      "America/Costa_Rica",
      "America/Creston",
      "America/Cuiaba",
      "America/Curacao",
      "America/Danmarkshavn",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Denver",
      "America/Detroit",
      "America/Dominica",
      "America/Edmonton",
      "America/Eirunepe",
      "America/El_Salvador",
      "America/Fort_Nelson",
      "America/Fortaleza",
      "America/Glace_Bay",
      "America/Godthab",
      "America/Goose_Bay",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guatemala",
      "America/Guayaquil",
      "America/Guyana",
      "America/Halifax",
      "America/Havana",
      "America/Hermosillo",
      "America/Indiana/Knox",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Tell_City",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Indianapolis",
      "America/Inuvik",
      "America/Iqaluit",
      "America/Jamaica",
      "America/Jujuy",
      "America/Juneau",
      "America/Kentucky/Monticello",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lima",
      "America/Los_Angeles",
      "America/Louisville",
      "America/Lower_Princes",
      "America/Maceio",
      "America/Managua",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Matamoros",
      "America/Mazatlan",
      "America/Mendoza",
      "America/Menominee",
      "America/Merida",
      "America/Metlakatla",
      "America/Mexico_City",
      "America/Miquelon",
      "America/Moncton",
      "America/Monterrey",
      "America/Montevideo",
      "America/Montreal",
      "America/Montserrat",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Nome",
      "America/Noronha",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Ojinaga",
      "America/Panama",
      "America/Pangnirtung",
      "America/Paramaribo",
      "America/Phoenix",
      "America/Port-au-Prince",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Punta_Arenas",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Recife",
      "America/Regina",
      "America/Resolute",
      "America/Rio_Branco",
      "America/Santa_Isabel",
      "America/Santarem",
      "America/Santiago",
      "America/Santo_Domingo",
      "America/Sao_Paulo",
      "America/Scoresbysund",
      "America/Sitka",
      "America/St_Barthelemy",
      "America/St_Johns",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Swift_Current",
      "America/Tegucigalpa",
      "America/Thule",
      "America/Thunder_Bay",
      "America/Tijuana",
      "America/Toronto",
      "America/Tortola",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Winnipeg",
      "America/Yakutat",
      "America/Yellowknife",
      "Antarctica/Casey",
      "Antarctica/Davis",
      "Antarctica/DumontDUrville",
      "Antarctica/Macquarie",
      "Antarctica/Mawson",
      "Antarctica/McMurdo",
      "Antarctica/Palmer",
      "Antarctica/Rothera",
      "Antarctica/Syowa",
      "Antarctica/Troll",
      "Antarctica/Vostok",
      "Arctic/Longyearbyen",
      "Asia/Aden",
      "Asia/Almaty",
      "Asia/Amman",
      "Asia/Anadyr",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Atyrau",
      "Asia/Baghdad",
      "Asia/Bahrain",
      "Asia/Baku",
      "Asia/Bangkok",
      "Asia/Barnaul",
      "Asia/Beirut",
      "Asia/Bishkek",
      "Asia/Brunei",
      "Asia/Calcutta",
      "Asia/Chita",
      "Asia/Choibalsan",
      "Asia/Colombo",
      "Asia/Damascus",
      "Asia/Dhaka",
      "Asia/Dili",
      "Asia/Dubai",
      "Asia/Dushanbe",
      "Asia/Famagusta",
      "Asia/Gaza",
      "Asia/Hebron",
      "Asia/Hong_Kong",
      "Asia/Hovd",
      "Asia/Irkutsk",
      "Asia/Jakarta",
      "Asia/Jayapura",
      "Asia/Jerusalem",
      "Asia/Kabul",
      "Asia/Kamchatka",
      "Asia/Karachi",
      "Asia/Katmandu",
      "Asia/Khandyga",
      "Asia/Krasnoyarsk",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Kuwait",
      "Asia/Macau",
      "Asia/Magadan",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Muscat",
      "Asia/Nicosia",
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk",
      "Asia/Oral",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Pyongyang",
      "Asia/Qatar",
      "Asia/Qostanay",
      "Asia/Qyzylorda",
      "Asia/Rangoon",
      "Asia/Riyadh",
      "Asia/Saigon",
      "Asia/Sakhalin",
      "Asia/Samarkand",
      "Asia/Seoul",
      "Asia/Shanghai",
      "Asia/Singapore",
      "Asia/Srednekolymsk",
      "Asia/Taipei",
      "Asia/Tashkent",
      "Asia/Tbilisi",
      "Asia/Tehran",
      "Asia/Thimphu",
      "Asia/Tokyo",
      "Asia/Tomsk",
      "Asia/Ulaanbaatar",
      "Asia/Urumqi",
      "Asia/Ust-Nera",
      "Asia/Vientiane",
      "Asia/Vladivostok",
      "Asia/Yakutsk",
      "Asia/Yekaterinburg",
      "Asia/Yerevan",
      "Atlantic/Azores",
      "Atlantic/Bermuda",
      "Atlantic/Canary",
      "Atlantic/Cape_Verde",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Atlantic/Reykjavik",
      "Atlantic/South_Georgia",
      "Atlantic/St_Helena",
      "Atlantic/Stanley",
      "Australia/Adelaide",
      "Australia/Brisbane",
      "Australia/Broken_Hill",
      "Australia/Currie",
      "Australia/Darwin",
      "Australia/Eucla",
      "Australia/Hobart",
      "Australia/Lindeman",
      "Australia/Lord_Howe",
      "Australia/Melbourne",
      "Australia/Perth",
      "Australia/Sydney",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Astrakhan",
      "Europe/Athens",
      "Europe/Belgrade",
      "Europe/Berlin",
      "Europe/Bratislava",
      "Europe/Brussels",
      "Europe/Bucharest",
      "Europe/Budapest",
      "Europe/Busingen",
      "Europe/Chisinau",
      "Europe/Copenhagen",
      "Europe/Dublin",
      "Europe/Gibraltar",
      "Europe/Guernsey",
      "Europe/Helsinki",
      "Europe/Isle_of_Man",
      "Europe/Istanbul",
      "Europe/Jersey",
      "Europe/Kaliningrad",
      "Europe/Kiev",
      "Europe/Kirov",
      "Europe/Lisbon",
      "Europe/Ljubljana",
      "Europe/London",
      "Europe/Luxembourg",
      "Europe/Madrid",
      "Europe/Malta",
      "Europe/Mariehamn",
      "Europe/Minsk",
      "Europe/Monaco",
      "Europe/Moscow",
      "Europe/Oslo",
      "Europe/Paris",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Riga",
      "Europe/Rome",
      "Europe/Samara",
      "Europe/San_Marino",
      "Europe/Sarajevo",
      "Europe/Saratov",
      "Europe/Simferopol",
      "Europe/Skopje",
      "Europe/Sofia",
      "Europe/Stockholm",
      "Europe/Tallinn",
      "Europe/Tirane",
      "Europe/Ulyanovsk",
      "Europe/Uzhgorod",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Vilnius",
      "Europe/Volgograd",
      "Europe/Warsaw",
      "Europe/Zagreb",
      "Europe/Zaporozhye",
      "Europe/Zurich",
      "Indian/Antananarivo",
      "Indian/Chagos",
      "Indian/Christmas",
      "Indian/Cocos",
      "Indian/Comoro",
      "Indian/Kerguelen",
      "Indian/Mahe",
      "Indian/Maldives",
      "Indian/Mauritius",
      "Indian/Mayotte",
      "Indian/Reunion",
      "Pacific/Apia",
      "Pacific/Auckland",
      "Pacific/Bougainville",
      "Pacific/Chatham",
      "Pacific/Easter",
      "Pacific/Efate",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Fiji",
      "Pacific/Funafuti",
      "Pacific/Galapagos",
      "Pacific/Gambier",
      "Pacific/Guadalcanal",
      "Pacific/Guam",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Kiritimati",
      "Pacific/Kosrae",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Marquesas",
      "Pacific/Midway",
      "Pacific/Nauru",
      "Pacific/Niue",
      "Pacific/Norfolk",
      "Pacific/Noumea",
      "Pacific/Pago_Pago",
      "Pacific/Palau",
      "Pacific/Pitcairn",
      "Pacific/Ponape",
      "Pacific/Port_Moresby",
      "Pacific/Rarotonga",
      "Pacific/Saipan",
      "Pacific/Tahiti",
      "Pacific/Tarawa",
      "Pacific/Tongatapu",
      "Pacific/Truk",
      "Pacific/Wake",
      "Pacific/Wallis",
    ]
  }

  private getLocaleDate(): Array<[string, string, string]> {
    return [
      ["ar-DZ", "Algeria", "الجزائر"],
      ["ar-BH", "Bahrain", "البحرين"],
      ["ar-TD", "Chad", "تشاد"],
      ["ar-KM", "Comoros", "جزر القمر"],
      ["ar-DJ", "Djibouti", "جيبوتي"],
      ["ar-EG", "Egypt", "مصر"],
      ["ar-ER", "Eritrea", "إريتريا"],
      ["ar-IQ", "Iraq", "العراق"],
      ["ar-IL", "Israel", "إسرائيل"],
      ["ar-JO", "Jordan", "الأردن"],
      ["ar-KW", "Kuwait", "الكويت"],
      ["ar-LB", "Lebanon", "لبنان"],
      ["ar-LY", "Libya", "ليبيا"],
      ["ar-MR", "Mauritania", "موريتانيا"],
      ["ar-MA", "Morocco", "المغرب"],
      ["ar-OM", "Oman", "سلطنة عمان"],
      ["ar-PS", "Palestinian Territories", "الاراضي الفلسطينية"],
      ["ar-QA", "Qatar", "دولة قطر"],
      ["ar-SA", "Saudi Arabia", "المملكة العربية السعودية"],
      ["ar-SO", "Somalia", "الصومال"],
      ["ar-SS", "South Sudan", "جنوب السودان"],
      ["ar-SD", "Sudan", "السودان"],
      ["ar-SY", "Syria", "سوريا"],
      ["ar-TN", "Tunisia", "تونس"],
      ["ar-AE", "United Arab Emirates", "الإمارات العربية المتحدة"],
      ["ar-EH", "Western Sahara", "الصحراء الغربية"],
      ["ar-YE", "Yemen", "اليمن"],

      ["pl-PL", "Poland", "Polska"],

      ["en-AS", "American Samoa", "American Samoa"],
      ["en-AI", "Anguilla", "Anguilla"],
      ["en-AG", "Antigua & Barbuda", "Antigua & Barbuda"],
      ["en-AU", "Australia", "Australia"],
      ["en-BS", "Bahamas", "Bahamas"],
      ["en-BB", "Barbados", "Barbados"],
      ["en-BE", "Belgium", "Belgium"],
      ["en-BZ", "Belize", "Belize"],
      ["en-BM", "Bermuda", "Bermuda"],
      ["en-BW", "Botswana", "Botswana"],
      ["en-IO", "British Indian Ocean Territory", "British Indian Ocean Territory"],
      ["en-VG", "British Virgin Islands", "British Virgin Islands"],
      ["en-CM", "Cameroon", "Cameroon"],
      ["en-CA", "Canada", "Canada"],
      ["en-KY", "Cayman Islands", "Cayman Islands"],
      ["en-CX", "Christmas Island", "Christmas Island"],
      ["en-CC", "Cocos (Keeling) Islands", "Cocos (Keeling) Islands"],
      ["en-CK", "Cook Islands", "Cook Islands"],
      ["en-DG", "Diego Garcia", "Diego Garcia"],
      ["en-DM", "Dominica", "Dominica"],
      ["en-ER", "Eritrea", "Eritrea"],
      ["en-FK", "Falkland Islands", "Falkland Islands"],
      ["en-FJ", "Fiji", "Fiji"],
      ["en-GM", "Gambia", "Gambia"],
      ["en-GH", "Ghana", "Ghana"],
      ["en-GI", "Gibraltar", "Gibraltar"],
      ["en-GD", "Grenada", "Grenada"],
      ["en-GU", "Guam", "Guam"],
      ["en-GG", "Guernsey", "Guernsey"],
      ["en-GY", "Guyana", "Guyana"],
      ["en-HK", "Hong Kong SAR China", "Hong Kong SAR China"],
      ["en-IN", "India", "India"],
      ["en-IE", "Ireland", "Ireland"],
      ["en-IM", "Isle of Man", "Isle of Man"],
      ["en-JM", "Jamaica", "Jamaica"],
      ["en-JE", "Jersey", "Jersey"],
      ["en-KE", "Kenya", "Kenya"],
      ["en-KI", "Kiribati", "Kiribati"],
      ["en-LS", "Lesotho", "Lesotho"],
      ["en-LR", "Liberia", "Liberia"],
      ["en-MO", "Macau SAR China", "Macau SAR China"],
      ["en-MG", "Madagascar", "Madagascar"],
      ["en-MW", "Malawi", "Malawi"],
      ["en-MY", "Malaysia", "Malaysia"],
      ["en-MT", "Malta", "Malta"],
      ["en-MH", "Marshall Islands", "Marshall Islands"],
      ["en-MU", "Mauritius", "Mauritius"],
      ["en-FM", "Micronesia", "Micronesia"],
      ["en-MS", "Montserrat", "Montserrat"],
      ["en-NA", "Namibia", "Namibia"],
      ["en-NR", "Nauru", "Nauru"],
      ["en-NZ", "New Zealand", "New Zealand"],
      ["en-NG", "Nigeria", "Nigeria"],
      ["en-NU", "Niue", "Niue"],
      ["en-NF", "Norfolk Island", "Norfolk Island"],
      ["en-MP", "Northern Mariana Islands", "Northern Mariana Islands"],
      ["en-PK", "Pakistan", "Pakistan"],
      ["en-PW", "Palau", "Palau"],
      ["en-PG", "Papua New Guinea", "Papua New Guinea"],
      ["en-PH", "Philippines", "Philippines"],
      ["en-PN", "Pitcairn Islands", "Pitcairn Islands"],
      ["en-PR", "Puerto Rico", "Puerto Rico"],
      ["en-RW", "Rwanda", "Rwanda"],
      ["en-WS", "Samoa", "Samoa"],
      ["en-SC", "Seychelles", "Seychelles"],
      ["en-SL", "Sierra Leone", "Sierra Leone"],
      ["en-SG", "Singapore", "Singapore"],
      ["en-SX", "Sint Maarten", "Sint Maarten"],
      ["en-SB", "Solomon Islands", "Solomon Islands"],
      ["en-ZA", "South Africa", "South Africa"],
      ["en-SS", "South Sudan", "South Sudan"],
      ["en-SH", "St. Helena", "St. Helena"],
      ["en-KN", "St. Kitts & Nevis", "St. Kitts & Nevis"],
      ["en-LC", "St. Lucia", "St. Lucia"],
      ["en-VC", "St. Vincent & Grenadines", "St. Vincent & Grenadines"],
      ["en-SD", "Sudan", "Sudan"],
      ["en-SZ", "Swaziland", "Swaziland"],
      ["en-TZ", "Tanzania", "Tanzania"],
      ["en-TK", "Tokelau", "Tokelau"],
      ["en-TO", "Tonga", "Tonga"],
      ["en-TT", "Trinidad & Tobago", "Trinidad & Tobago"],
      ["en-TC", "Turks & Caicos Islands", "Turks & Caicos Islands"],
      ["en-TV", "Tuvalu", "Tuvalu"],
      ["en-UM", "U.S. Outlying Islands", "U.S. Outlying Islands"],
      ["en-VI", "U.S. Virgin Islands", "U.S. Virgin Islands"],
      ["en-UG", "Uganda", "Uganda"],
      ["en-GB", "United Kingdom", "United Kingdom"],
      ["en-US", "United States", "United States"],
      ["en-VU", "Vanuatu", "Vanuatu"],
      ["en-ZM", "Zambia", "Zambia"],
      ["en-ZW", "Zimbabwe", "Zimbabwe"],
    ];
  }

  private getWeekInfoData(): Array<[string, number, Array<number>]> {
    return [
      ["af-ZA", 7, [6, 7]],
      ["am-ET", 7, [6, 7]],
      ["ar-AE", 6, [6, 7]],
      ["ar-BH", 6, [5, 6]],
      ["ar-DZ", 6, [5, 6]],
      ["ar-EG", 6, [5, 6]],
      ["ar-IQ", 6, [5, 6]],
      ["ar-JO", 6, [5, 6]],
      ["ar-KW", 6, [5, 6]],
      ["ar-LB", 1, [6, 7]],
      ["ar-LY", 6, [5, 6]],
      ["ar-MA", 1, [6, 7]],
      ["arn-CL", 1, [6, 7]],
      ["ar-OM", 6, [5, 6]],
      ["ar-QA", 6, [5, 6]],
      ["ar-SA", 7, [5, 6]],
      ["ar-SD", 6, [5, 6]],
      ["ar-SY", 6, [5, 6]],
      ["ar-TN", 1, [6, 7]],
      ["ar-YE", 7, [5, 6]],
      ["as-IN", 7, [7]],
      ["az-az", 1, [6, 7]],
      ["az-Cyrl-AZ", 1, [6, 7]],
      ["az-Latn-AZ", 1, [6, 7]],
      ["ba-RU", 1, [6, 7]],
      ["be-BY", 1, [6, 7]],
      ["bg-BG", 1, [6, 7]],
      ["bn-BD", 7, [6, 7]],
      ["bn-IN", 7, [7]],
      ["bo-CN", 7, [6, 7]],
      ["br-FR", 1, [6, 7]],
      ["bs-Cyrl-BA", 1, [6, 7]],
      ["bs-Latn-BA", 1, [6, 7]],
      ["ca-ES", 1, [6, 7]],
      ["co-FR", 1, [6, 7]],
      ["cs-CZ", 1, [6, 7]],
      ["cy-GB", 1, [6, 7]],
      ["da-DK", 1, [6, 7]],
      ["de-AT", 1, [6, 7]],
      ["de-CH", 1, [6, 7]],
      ["de-DE", 1, [6, 7]],
      ["de-LI", 1, [6, 7]],
      ["de-LU", 1, [6, 7]],
      ["dsb-DE", 1, [6, 7]],
      ["dv-MV", 5, [6, 7]],
      ["el-CY", 1, [6, 7]],
      ["el-GR", 1, [6, 7]],
      ["en-029", 1, [6, 7]],
      ["en-AU", 1, [6, 7]],
      ["en-BZ", 7, [6, 7]],
      ["en-CA", 7, [6, 7]],
      ["en-cb", 1, [6, 7]],
      ["en-GB", 1, [6, 7]],
      ["en-IE", 1, [6, 7]],
      ["en-IN", 7, [7]],
      ["en-JM", 7, [6, 7]],
      ["en-MT", 7, [6, 7]],
      ["en-MY", 1, [6, 7]],
      ["en-NZ", 1, [6, 7]],
      ["en-PH", 7, [6, 7]],
      ["en-SG", 7, [6, 7]],
      ["en-TT", 7, [6, 7]],
      ["en-US", 7, [6, 7]],
      ["en-ZA", 7, [6, 7]],
      ["en-ZW", 7, [6, 7]],
      ["es-AR", 1, [6, 7]],
      ["es-BO", 1, [6, 7]],
      ["es-CL", 1, [6, 7]],
      ["es-CO", 7, [6, 7]],
      ["es-CR", 1, [6, 7]],
      ["es-DO", 7, [6, 7]],
      ["es-EC", 1, [6, 7]],
      ["es-ES", 1, [6, 7]],
      ["es-GT", 7, [6, 7]],
      ["es-HN", 7, [6, 7]],
      ["es-MX", 7, [6, 7]],
      ["es-NI", 7, [6, 7]],
      ["es-PA", 7, [6, 7]],
      ["es-PE", 7, [6, 7]],
      ["es-PR", 7, [6, 7]],
      ["es-PY", 7, [6, 7]],
      ["es-SV", 7, [6, 7]],
      ["es-US", 7, [6, 7]],
      ["es-UY", 1, [6, 7]],
      ["es-VE", 7, [6, 7]],
      ["et-EE", 1, [6, 7]],
      ["eu-ES", 1, [6, 7]],
      ["fa-IR", 6, [5]],
      ["fi-FI", 1, [6, 7]],
      ["fil-PH", 7, [6, 7]],
      ["fo-FO", 1, [6, 7]],
      ["fr-BE", 1, [6, 7]],
      ["fr-CA", 7, [6, 7]],
      ["fr-CH", 1, [6, 7]],
      ["fr-FR", 1, [6, 7]],
      ["fr-LU", 1, [6, 7]],
      ["fr-MC", 1, [6, 7]],
      ["fy-NL", 1, [6, 7]],
      ["ga-IE", 1, [6, 7]],
      ["gd-GB", 1, [6, 7]],
      ["gd-ie", 1, [6, 7]],
      ["gl-ES", 1, [6, 7]],
      ["gsw-FR", 1, [6, 7]],
      ["gu-IN", 7, [7]],
      ["ha-Latn-NG", 1, [6, 7]],
      ["he-IL", 7, [5, 6]],
      ["hi-IN", 7, [7]],
      ["hr-BA", 1, [6, 7]],
      ["hr-HR", 1, [6, 7]],
      ["hsb-DE", 1, [6, 7]],
      ["hu-HU", 1, [6, 7]],
      ["hy-AM", 1, [6, 7]],
      ["id-ID", 7, [6, 7]],
      ["ig-NG", 1, [6, 7]],
      ["ii-CN", 7, [6, 7]],
      ["in-ID", 7, [6, 7]],
      ["is-IS", 1, [6, 7]],
      ["it-CH", 1, [6, 7]],
      ["it-IT", 1, [6, 7]],
      ["iu-Cans-CA", 7, [6, 7]],
      ["iu-Latn-CA", 7, [6, 7]],
      ["iw-IL", 7, [5, 6]],
      ["ja-JP", 7, [6, 7]],
      ["ka-GE", 1, [6, 7]],
      ["kk-KZ", 1, [6, 7]],
      ["kl-GL", 1, [6, 7]],
      ["km-KH", 7, [6, 7]],
      ["kn-IN", 7, [7]],
      ["kok-IN", 7, [7]],
      ["ko-KR", 7, [6, 7]],
      ["ky-KG", 1, [6, 7]],
      ["lb-LU", 1, [6, 7]],
      ["lo-LA", 7, [6, 7]],
      ["lt-LT", 1, [6, 7]],
      ["lv-LV", 1, [6, 7]],
      ["mi-NZ", 1, [6, 7]],
      ["mk-MK", 1, [6, 7]],
      ["ml-IN", 7, [7]],
      ["mn-MN", 1, [6, 7]],
      ["mn-Mong-CN", 7, [6, 7]],
      ["moh-CA", 7, [6, 7]],
      ["mr-IN", 7, [7]],
      ["ms-BN", 1, [6, 7]],
      ["ms-MY", 1, [6, 7]],
      ["mt-MT", 7, [6, 7]],
      ["nb-NO", 1, [6, 7]],
      ["ne-NP", 7, [6, 7]],
      ["nl-BE", 1, [6, 7]],
      ["nl-NL", 1, [6, 7]],
      ["nn-NO", 1, [6, 7]],
      ["no-no", 1, [6, 7]],
      ["nso-ZA", 7, [6, 7]],
      ["oc-FR", 1, [6, 7]],
      ["or-IN", 7, [7]],
      ["pa-IN", 7, [7]],
      ["pl-PL", 1, [6, 7]],
      ["prs-AF", 6, [4, 5]],
      ["ps-AF", 6, [4, 5]],
      ["pt-BR", 7, [6, 7]],
      ["pt-PT", 7, [6, 7]],
      ["qut-GT", 7, [6, 7]],
      ["quz-BO", 1, [6, 7]],
      ["quz-EC", 1, [6, 7]],
      ["quz-PE", 7, [6, 7]],
      ["rm-CH", 1, [6, 7]],
      ["ro-mo", 7, [6, 7]],
      ["ro-RO", 1, [6, 7]],
      ["ru-mo", 7, [6, 7]],
      ["ru-RU", 1, [6, 7]],
      ["rw-RW", 1, [6, 7]],
      ["sah-RU", 1, [6, 7]],
      ["sa-IN", 7, [7]],
      ["se-FI", 1, [6, 7]],
      ["se-NO", 1, [6, 7]],
      ["se-SE", 1, [6, 7]],
      ["si-LK", 1, [6, 7]],
      ["sk-SK", 1, [6, 7]],
      ["sl-SI", 1, [6, 7]],
      ["sma-NO", 1, [6, 7]],
      ["sma-SE", 1, [6, 7]],
      ["smj-NO", 1, [6, 7]],
      ["smj-SE", 1, [6, 7]],
      ["smn-FI", 1, [6, 7]],
      ["sms-FI", 1, [6, 7]],
      ["sq-AL", 1, [6, 7]],
      ["sr-BA", 1, [6, 7]],
      ["sr-CS", 1, [6, 7]],
      ["sr-Cyrl-BA", 1, [6, 7]],
      ["sr-Cyrl-CS", 1, [6, 7]],
      ["sr-Cyrl-ME", 1, [6, 7]],
      ["sr-Cyrl-RS", 1, [6, 7]],
      ["sr-Latn-BA", 1, [6, 7]],
      ["sr-Latn-CS", 1, [6, 7]],
      ["sr-Latn-ME", 1, [6, 7]],
      ["sr-Latn-RS", 1, [6, 7]],
      ["sr-ME", 1, [6, 7]],
      ["sr-RS", 1, [6, 7]],
      ["sr-sp", 1, [6, 7]],
      ["sv-FI", 1, [6, 7]],
      ["sv-SE", 1, [6, 7]],
      ["sw-KE", 7, [6, 7]],
      ["syr-SY", 6, [5, 6]],
      ["ta-IN", 7, [7]],
      ["te-IN", 7, [7]],
      ["tg-Cyrl-TJ", 1, [6, 7]],
      ["th-TH", 7, [6, 7]],
      ["tk-TM", 1, [6, 7]],
      ["tlh-QS", 1, [6, 7]],
      ["tn-ZA", 7, [6, 7]],
      ["tr-TR", 1, [6, 7]],
      ["tt-RU", 1, [6, 7]],
      ["tzm-Latn-DZ", 6, [5, 6]],
      ["ug-CN", 7, [6, 7]],
      ["uk-UA", 1, [6, 7]],
      ["ur-PK", 7, [6, 7]],
      ["uz-Cyrl-UZ", 1, [6, 7]],
      ["uz-Latn-UZ", 1, [6, 7]],
      ["uz-uz", 1, [6, 7]],
      ["vi-VN", 1, [6, 7]],
      ["wo-SN", 1, [6, 7]],
      ["xh-ZA", 7, [6, 7]],
      ["yo-NG", 1, [6, 7]],
      ["zh-CN", 7, [6, 7]],
      ["zh-HK", 7, [6, 7]],
      ["zh-MO", 7, [6, 7]],
      ["zh-SG", 7, [6, 7]],
      ["zh-TW", 7, [6, 7]],
      ["zu-ZA", 7, [6, 7]]
    ]
  }

  getLocaleListForLanguage(language: Language): Array<RegionWithName> {
    return this.getLocaleDate().filter(l => l[0].startsWith(language.name + "-")).map(l => RegionWithName.of(l));
  }

  getLocale(localeCode: string): Option<RegionWithName> {
    return __(this.getLocaleDate()).find(l => l[0] === localeCode).map(l => RegionWithName.of(l));
  }

  getAllTimeZones(): Array<string> {
    try {
      return (<any>Intl).supportedValuesOf('timeZone');
    } catch (e) {
      return this.getTimezonesData();
    }
  }

  getAllServerTimeZones(): Array<string> {
    return this.getTimezonesData();
  }


  getWeekInfoForLocale(locale: string): WeekInfo {
    try {
      const info: any = (<any>new Intl.Locale(locale)).weekInfo;
      return new WeekInfo(<WeekDay>info.firstDay, <Array<WeekDay>>info.weekend);
    } catch (e) {

      // console.log(__(this.getWeekInfoData()).uniqueBy(a => a[2].join(", ")).map(w => w[2]));

      try {
        return __(this.getWeekInfoData()).find(i => i[0] === locale).map(i => new WeekInfo(<WeekDay>i[1], <Array<WeekDay>>i[2])).getOrElseLazy(() => {
          return new WeekInfo(1, [6, 7]);
        });
      } catch (e) {
        return new WeekInfo(1, [6, 7]);
      }
    }
  }
}
