import {Component, OnInit} from '@angular/core';
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {__, i18n, Option, WeekDay} from "@utils";

class WeekendDaysOption implements DropDownSelectorOption {
  nameOnList: string = "";
  constructor(readonly value: number|null,
              readonly name: string) {}
}

@Component({
  selector: 'my-weekend-days-selector',
  templateUrl: './weekend-days-selector.component.html'
})
export class WeekendDaysSelectorComponent implements OnInit {

  // each digit represents week day, 1 - Monday, 7 - Sunday
  // written as numbers for easier comparison in select
  private options: Array<number> = [12, 23, 34, 45, 56, 67, 71, 1, 2, 3, 4, 5, 6, 7];

  weekendDays: number|null = null;
  weekendDaysOptions: Array<WeekendDaysOption>;
  defaultWeekendDays: WeekendDaysOption = null!;
  selectedWeekendDays: WeekendDaysOption = null!;

  constructor(readonly userSettingsService: UserSettingsStateService) {

    let options = this.options.map(o => new WeekendDaysOption(o, this.splitDigits(o).map(w => this.userSettingsService.getWeekdayName(w)).join(", ")))

    this.defaultWeekendDays = new WeekendDaysOption(null,  options[5].name);
    this.defaultWeekendDays.nameOnList = i18n("common_default_neuter");

    this.weekendDaysOptions = [this.defaultWeekendDays, ...options];

    this.userSettingsService.getWeekendDaysObservable().forEach(weekendDays => {
      this.weekendDays = weekendDays.map(this.fromDigits).getOrNull();
    })
  }

  ngOnInit(): void {
    this.weekendDays = this.userSettingsService.getWeekendDays().map(this.fromDigits).getOrNull();
    this.selectedWeekendDays = __(this.weekendDaysOptions).find(wd => wd.value === this.weekendDays).getOrElse(this.defaultWeekendDays);
  }

  weekendDaysChanged(weekdays: WeekendDaysOption|DropDownSelectorOption) {
    this.weekendDays = weekdays.value;
    this.userSettingsService.changeWeekendDays(Option.of(this.weekendDays).map(this.splitDigits));
  }

  private splitDigits(code: number) {
    if(code < 10) {
      return [code];
    } else {
      return [Math.floor(code/10), code%10];
    }

  }

  private fromDigits(days: Array<WeekDay>): number {
    return days.reduce((a, b) => a*10+b, 0);
  }
}
