<input type="text" class="theme-input timeInput" #InputElement
       [(ngModel)]="model"
       [disabled]="disabled"
       (focus)="onFocus()"
       [placeholder]="placeholder"
       (blur)="onInputValueConfirm()"
       (ngModelChange)="updateValue()"
       mask="Hh:m0" [dropSpecialCharacters]="false"/>

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [(visible)]="focused" [anchor]="InputElement" (closed)="onPickerClosed()" [contentChangeNotifier]="contentChangeNotifier">
  <ng-container *ngIf="focused">
    <div class="actions" *ngIf="nowButton || clearButton">
      <button *ngIf="clearButton" class="actionButton" (click)="setEmpty()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      <div class="actionSpacer"></div>
      <button *ngIf="nowButton" class="actionButton" (click)="setNow()"><i class="mi-clock"></i>{{'common_now' | i18n}}</button>
    </div>
    <div class="board">
      <my-time-picker-board [(time)]="value" (timeChange)="onInputValueChange()" (picked)="onPickerValueSelected()" (sizeChanged)="onPickerSizeChanged()"  />
    </div>
  </ng-container>
</my-drop-menu>
