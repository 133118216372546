import {businessEntityDownloadUrl, BusinessEntityId, FileUri, LocalDateTime, None, Option, Some} from "@utils";
import {DirInfo, FileBasicInfo, FileInfo, FileNotFound} from "@shared";
import {EmailBasicInfo, EmailsSharedService, FilesSharedService} from "@shared-model";


export class FileInfoModel {

    constructor(
      readonly uri: FileUri,
      readonly exists: boolean,
      readonly directory: boolean,
      readonly name: string,
      readonly size: number,
      readonly version: number,
      readonly repositoryPath: Option<string>,
      readonly modified: LocalDateTime,
      readonly downloadUrl: string
    ) {}

    static ofBasicInfo(info: FileBasicInfo, downloadUrl: (uri: FileUri) => string): FileInfoModel {
      if (info instanceof DirInfo) {
        const path: Option<string> = info.path.length === 0 ? None() : Some(info.path);
        return new FileInfoModel(
          info.uri,
          true,
          true,
          info.name,
          0,
          0,
          path,
          info.modified,
          ""
        );
      } else if (info instanceof FileInfo) {
        const path: Option<string> = info.path.length === 0 ? None() : Some(info.path);
        return new FileInfoModel(
          info.uri,
          true,
          false,
          info.name,
          info.size,
          info.version,
          path,
          info.modified,
          downloadUrl(info.uri)
        );
      } else if (info instanceof FileNotFound) {
        if (info.uri.isAnyFile()) {
          return new FileInfoModel(
            info.uri,
            false,
            false,
            "",
            0,
            0,
            None(),
            LocalDateTime.ZERO,
            ""
          );
        } else {
          return new FileInfoModel(
            info.uri,
            false,
            true,
            "",
            0,
            0,
            None(),
            LocalDateTime.ZERO,
            ""
          );
        }
      } else {
        throw new Error("Incorrect file type [" + (<any>info).className() + "]");
      }
    }
  }

  export class BusinessVariableInputServerModel {
    constructor(
      readonly filesSharedService: FilesSharedService,
      readonly emailsSharedService: EmailsSharedService,
      // readonly processFlowService: ProcessFlowService,
      // readonly businessEntityQueryService: BusinessEntityQueryService
    ) {
    }

    loadFilesInfo(file: FileUri, onSuccess: (fileInfo: FileInfoModel) => void): void {
      this.filesSharedService.filesInfo([file], files => {
        onSuccess(FileInfoModel.ofBasicInfo(files[0], (uri) => businessEntityDownloadUrl(new BusinessEntityId(""), uri)));
      });
    }

    loadEmailsInfo(requestNumber: number, emails: Array<FileUri>, onSuccess: (requestNumber: number, emailsInfo: Array<any>) => void): void {
      if(emails.length == 0) {
        onSuccess(requestNumber, []);
      } else {
        this.emailsSharedService.loadEmailsSummary(requestNumber, emails, onSuccess);
      }
    }

    loadEmailInfo(email: FileUri, onSuccess: (emailInfo: EmailBasicInfo) => void): void {
      this.loadEmailsInfo(0, [email], (requestNumber: number, emailsInfo: Array<EmailBasicInfo>) => onSuccess(emailsInfo[0]));
    }

  }


