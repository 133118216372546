import {ScreenContainerViewModel, ScreenRuntimeEventBus} from "./";

export class ScreenComponentController {

    private enabled = true;

    constructor(readonly $container: HTMLElement,
                readonly rootComponent: ScreenContainerViewModel,
                readonly eventBus: ScreenRuntimeEventBus) {

      eventBus.on(eventBus.componentHeightChanged, () => {
        if(this.enabled) {
          this.adjustComponentsDeltaHeight();
        }
      });

    }


    private adjustComponentsDeltaHeight() {
      // this.$container.find(".ScreenComponent").each((index, element) => {
      //   this.adjustElement($(element));
      // });
    }

    private adjustElement($element: HTMLElement) {
      // const classes: Array<string> = (<string>$element.attr("class")).split(/\s+/);
      //
      // const id = __(classes).find(c => c.indexOf("ViewComponent") == 0 && c.length > "ViewComponent".length).map(c => parseInt(c.substring("ViewComponent".length))).getOrError("Component id not found");
      //
      // // const deltaHeight = $element.attr("delta-height");
      // // let deltaHeightRem = 0;
      // // if(deltaHeight != null && deltaHeight.length > 0) {
      // //   deltaHeightRem = parseFloat(deltaHeight);
      // // }
      // const deltaHeightRem = 0;
      // // const minHeightRem = parseFloat($element.css("minHeight")) / elements.getREMSize() - deltaHeightRem;
      // const component = this.rootComponent.getComponentById(id).getOrError("No component found ["+id+"]");
      // const minHeightRem = component.desiredHeightRem;
      // // console.log("minHeightRem",minHeightRem,  "minHeight", parseFloat($element.css("minHeight")), "deltaHeightRem", deltaHeightRem * elements.getREMSize());
      //
      // const sizeRem = getSize(<HTMLElement>$element.get(0)).scale(1 / getREMSize());
      //
      //
      // const newDeltaHeightRem = Math.max(0, sizeRem.height - minHeightRem);
      //
      // // console.log("newDeltaHeightRem", newDeltaHeightRem, "size.height", sizeRem.height, "minHeightPx", minHeightRem)
      //
      // component.changeDeltaHeightRem(newDeltaHeightRem);


    }

    private round(value: number): number {
      return Math.round(value * 1000) / 1000;
    }

    destroy() {
      this.enabled = false;
    }
  }
