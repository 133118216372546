import {PersonNotification} from "./notifications.model";
import {__, AggregateId, AggregateVersion, LocalDateTime, None, Option, Some, toastr} from "@utils";
import {NotificationsService} from "./notifications.service";
import {ServerEventsService, SessionEventBus} from "@shared";
import {SystemNotification} from "./SystemNotification";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class NotificationsLocalService {
  private notifications: Array<PersonNotification> = [];
  private notificationsSubject: Subject<Array<PersonNotification>> = new Subject<Array<PersonNotification>>();


  private newNotifications: Array<PersonNotification> = [];
  private unreadNotificationsCount: number = 0;
  private unreadNotificationsCountSubject: Subject<number> = new Subject<number>();

  private subscriptionCode: Option<string> = None();

  private subscriptions: Array<number> = [];

  constructor(readonly notificationsService: NotificationsService, readonly serverEventsService: ServerEventsService, readonly sessionEventBus: SessionEventBus) {

    this.init();

    sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
      this.destroy();
      this.init();
    });

  }

  init() {
    this.loadNotifications(() => {});

    this.serverEventsService.subscribeForNotifications((subscriptionCode: string) => {
      this.subscriptionCode = Some(subscriptionCode);
    });

    this.subscriptions.push(this.serverEventsService.serverEventBus.on(this.serverEventsService.serverEventBus.newNotificationsReceived, (notifications: Array<PersonNotification>) => {
      notifications.forEach(notification => {
        const existing = __(this.notifications).find(n => n.aggregateId.id === notification.aggregateId.id);
        if (existing.isEmpty()) {
          this.notifications.unshift(notification);
          this.newNotifications.unshift(notification);
          this.unreadNotificationsCount++;
          this.notificationsSubject.next(this.notifications);
          SystemNotification.notify(notification);
        } else {
          existing.get().read = notification.read;
        }
      });
    }));

  }

  destroy() {
    this.subscriptions.forEach(s => this.serverEventsService.serverEventBus.unsubscribe(s));
    this.subscriptionCode.forEach(code => {
      this.serverEventsService.unsubscribe(code);
      this.subscriptionCode = None();
    })
  }

  private loadNotifications(onSuccess: () => void) {
    this.notificationsService.getNotifications((notifications: Array<PersonNotification>,moreAvailable:boolean, received: number, unread: number) => {
      this.notifications = notifications;
      this.unreadNotificationsCount = unread;
      this.notificationsSubject.next(this.notifications);
    });
  }

  private loadUnreadNotifications(onSuccess: () => void) {
    toastr.info("Not yet implemented");
    // this.notificationsService.getUnreadNotifications((notifications: Array<PersonNotification>,moreAvailable:boolean, received: number, unread: number) => {
    //   this.notifications = notifications;
    //   this.unreadNotificationsCount = unread;
    //   this.onNotificationsUpdatedCallbacks.forEach(callback => callback(this.newNotifications));
    // });
  }

  getUnreadNotificationList() {
    return new Promise<Array<PersonNotification>>(resolve => {
      this.loadUnreadNotifications(() => {
        resolve(this.notifications);
      });
    });
  }

  getOldUnreadNotifications(olderThan: LocalDateTime) {
    this.notificationsService.getUnreadNotificationsOlderThan(olderThan, (notifications: Array<PersonNotification>) => {
      this.notifications = this.notifications.concat(notifications);
      this.notificationsSubject.next(this.notifications);
    });
  }

  markNotificationRead(notificationId: AggregateId, version: AggregateVersion) {
    this.notificationsService.markNotificationRead(notificationId, version);

    this.notifications.forEach(notification => {
      if (notification.aggregateId.id === notificationId.id) {
        notification.read = true;
        this.unreadNotificationsCount--;
      }
    });
    this.unreadNotificationsCountSubject.next(this.unreadNotificationsCount);
  }

  markAllNotificationsAsRead() {
    this.notificationsService.markAllNotificationsAsRead();

    this.notifications.forEach(notification => {
      notification.read = true;
    });

    this.unreadNotificationsCount = 0;
    this.notificationsSubject.next(this.notifications);
    this.unreadNotificationsCountSubject.next(this.unreadNotificationsCount);
  }

  getOldNotifications(olderThan: LocalDateTime) {
    this.notificationsService.getNotificationsOlderThan(olderThan, (notifications: Array<PersonNotification>) => {
      this.notifications = this.notifications.concat(notifications);
      this.notificationsSubject.next(this.notifications);
    });
  }

  get notificationsObservable() {
    return this.notificationsSubject.asObservable();
  }

  get unreadNotificationsCountObservable() {
    return this.unreadNotificationsCountSubject.asObservable();
  }

  getNotifications() {
    return this.notifications;
  }
}
