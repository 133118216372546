import {Injectable} from "@angular/core";

import {ApplicationId} from "@utils";
import {AuthenticatedHttp, ScreenInfo} from "@shared-model";

@Injectable({
  providedIn: 'root',
})
export class ScreenRuntimeQueryService {

  constructor(
    readonly authenticatedHttp: AuthenticatedHttp,
  ) {}





}


