import {
  __,
  ___,
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdFactory,
  AnyFlowIdHelper,
  AnyPersonId,
  AnyPersonIdFactory,
  ApplicationId,
  FileUri,
  FlowId,
  FormElementId,
  FormElementRefId,
  i18nLanguage,
  I18nText,
  LocalDateTime,
  None,
  ObjectId,
  Option,
  OrganizationId,
  OrganizationNodeId,
  PersonId,
  Some,
  Typed
} from "@utils";
import {
  ArrayVariable,
  BasicPersonInfo,
  BooleanVariable,
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariableType,
  ContextPath,
  ContextVariable,
  CursorInfo,
  CursorPhases,
  CursorStatus,
  FlowCursorId,
  FlowImportance,
  FlowStatus,
  FlowWithCursor,
  NodeType,
  ObjectVariable,
  ProcessCursor,
  ProcessEdgeId,
  ProcessFlowComment,
  ProcessFlowDetailsInterface,
  ProcessFlowSummary,
  ProcessInfo,
  ProcessNodeId,
  RootVariable,
  RootVariableWithType,
  TaskEdge,
  TaskHistoryDetailsInterface,
  TaskIdentifier,
  TaskModelSummary,
  TaskPullAction,
  TaskStatus,
  TaskTrackedTime,
  VariablePath
} from "@shared-model";
import {FormSectionId, FormSectionRefId} from "@shared";
import {Subject} from "rxjs";
import {
  BasicModelInfo,
  DesignAttachment,
  ElementsFactory,
  ElementsRefsFactory,
  FlowPreviewScreen,
  FormElement,
  FormElementRef,
  FormModel,
  FormSectionInfo,
  GridProcessModel,
  InputElement,
  InputElementRef,
  NodeActivity,
  TaskDistributionMethod,
  TasksVisibility
} from "../../process-common.module";

export class FlowPreviewDetails {
  constructor(readonly flowDetails: ProcessFlowDetails,
              readonly process: ProcessInfo,
              readonly processRelease: GridProcessModel,
              readonly instanceOwner: Option<PersonId>) {}

  static copy(other: FlowPreviewDetails) {
    return new FlowPreviewDetails(
      ProcessFlowDetails.copy(other.flowDetails),
      ProcessInfo.copy(other.process),
      GridProcessModel.copy(other.processRelease),
      Option.copy(other.instanceOwner, PersonId.copy));
  }
}

export class FlowPreviewInfo {
  constructor(
    readonly applicationId: ApplicationId,
    readonly flowId: FlowId,
    readonly flowCode: string,
    readonly initialPersonId: Option<Typed<AnyPersonId>>,
    readonly created: LocalDateTime,
    readonly started: Option<LocalDateTime>,
    readonly finished: Option<LocalDateTime>,
    readonly finishesReached: Array<string>,
    readonly processName: string,
    readonly releaseNumber: number,
    readonly flowStatus: FlowStatus,
    readonly flowDescription: string,
    readonly previewDetails: Option<FlowPreviewDetails>,
    readonly previewScreens: Option<Array<FlowPreviewScreen>>,
    readonly comments: Option<Array<MyCommentServerModel>>,
    readonly currentSteps: Array<string>,
    readonly delayedSteps: Array<string>, // cursor thar are waiting on delayed edges
    readonly error: boolean,
    readonly labels: Array<string>,
    readonly systemLabels: Array<Typed<BusinessVariable>>,
    readonly importance: FlowImportance,
    readonly detailsPreviewAuthorized: boolean,
    readonly editionAuthorized: boolean,
    readonly cancelAuthorized: boolean,
    readonly deletionAuthorized: boolean,
    readonly allFormsPreviewAuthorized: boolean,
    readonly commentAdditionAuthorized: boolean,
    readonly authorizationChangeAuthorized: boolean
  ) {}

  static copy(other: FlowPreviewInfo) {
    return new FlowPreviewInfo(
      ApplicationId.copy(other.applicationId),
      FlowId.copy(other.flowId),
      other.flowCode,
      Option.copy(other.initialPersonId, AnyPersonIdFactory.copyTyped),
      LocalDateTime.copy(other.created),
      Option.copy(other.started, LocalDateTime.copy),
      Option.copy(other.finished, LocalDateTime.copy),
      other.finishesReached.slice(),
      other.processName,
      other.releaseNumber,
      FlowStatus.copy(other.flowStatus),
      other.flowDescription,
      Option.copy(other.previewDetails, FlowPreviewDetails.copy),
      Option.copy(other.previewScreens, screens => screens.map(FlowPreviewScreen.copy)),
      Option.copy(other.comments, comments => comments.map(MyCommentServerModel.copy)),
      other.currentSteps.slice(),
      other.delayedSteps.slice(),
      other.error,
      other.labels.slice(),
      other.systemLabels.map(BusinessVariableFactory.copyTyped),
      other.importance,
      other.detailsPreviewAuthorized,
      other.editionAuthorized,
      other.cancelAuthorized,
      other.deletionAuthorized,
      other.allFormsPreviewAuthorized,
      other.commentAdditionAuthorized,
      other.authorizationChangeAuthorized);
  }

  getAllPersonsIds(): Array<AnyPersonId> {
    return this.previewDetails.map(details => {
      return ___(details.flowDetails.summary.assignedPersonsUnwrapped()).flatMap(a => a[1]).uniqueBy(p => p.serialize()).value();
    }).getOrElse([]);

  }

  initialPersonIdUnwrapped() {
    return this.initialPersonId.map(i => Typed.value(i));
  }

  systemLabelsUnwrapped() {
    return this.systemLabels.map(i => Typed.value(i));
  }
}


export class ActionEntry {

  constructor(readonly nodeId: ProcessNodeId,
              readonly actionName:string,
              readonly assignedPersonsInfo:Array<BasicPersonInfo>) {}
}

export enum Status {
  unassigned,
  assigned,
  closed,
  inProgress
}

export enum Risk {
  normal,
  atRisk,
  delayed
}

export class FlowEntry {
  createdDate: LocalDateTime;
  startedDate: Option<LocalDateTime>;
  completedDate: Option<LocalDateTime>;
  terminated: Option<LocalDateTime>;
  status: string;
  requiresIntervention: boolean;
  currentActions: ActionEntry[];

  constructor (readonly processName: string,
               readonly process: AggregateId,
               readonly flow: ProcessFlowSummary,
               public currentLocalDeadline: Option<LocalDateTime>,
               public currentGlobalDeadline: Option<LocalDateTime>,
               readonly modelInfo: Option<BasicModelInfo>,
               readonly personIds: { [key: string]: BasicPersonInfo }) {

    this.createdDate = flow.statusInfo.created;
    this.completedDate = flow.statusInfo.finished.map(f => f.finished);
    this.startedDate = flow.statusInfo.started;

    if (flow.statusInfo.isTerminated()) {
      this.terminated = flow.statusInfo.finished.map(f => f.finished);
    } else {
      this.terminated = None();
    }

    this.requiresIntervention = __(flow.cursors).exists((cursor: ProcessCursor) => cursor.status.status.name === CursorStatus.error.name);

    this.status = FlowEntry.getRisk(currentLocalDeadline, currentGlobalDeadline, flow.statusInfo.finished.map(f => f.finished)) + " " + FlowEntry.getStatus(flow);
    this.process = process;

    const cursors: { [key: string]: ProcessCursor } = {};
    flow.cursors.forEach(cursor => cursors[cursor.cursorId] = cursor);

    // If release is not yet available then actions will be filled later
    if(modelInfo.isDefined()) {
      this.currentActions = flow.currentTasksSummary
        .filter(task => task.ended.isEmpty() && task.terminated.isEmpty() && cursors[task.cursorId].cursorPosition.nodeId.get() === task.nodeId)
        .map(task => new ActionEntry(modelInfo.get().release.findNodeById(task.nodeId).id,
          modelInfo.get().release.findNodeById(task.nodeId).name,
          task.personsAssignedUnwrapped().map(person => personIds[person.serialize()])));
    } else {
      this.currentActions = [];
    }

  }

  setDeadline(currentLocalDeadline: Option<LocalDateTime>, currentGlobalDeadline: Option<LocalDateTime>) {
    this.currentLocalDeadline = currentLocalDeadline;
    this.currentGlobalDeadline = currentGlobalDeadline;
    this.status = FlowEntry.getRisk(currentLocalDeadline, currentGlobalDeadline, this.flow.statusInfo.finished.map(f => f.finished)) + " " + FlowEntry.getStatus(this.flow);
  }

  static getStatus(flow: ProcessFlowSummary): string {
    if (flow.statusInfo.isCompleted() || flow.statusInfo.isTerminated()) {
      return Status[Status.closed];
    } else if (flow.statusInfo.isInProgress() || flow.statusInfo.isCreated()){
      const taskFound = flow.currentTasksSummary.filter(task => task.started.isEmpty() && task.ended.isEmpty())[0];
      if (taskFound !== undefined) {
        if (taskFound.personsAssigned.length === 0) {
          return Status[Status.unassigned];
        } else {
          return Status[Status.assigned];
        }
      } else {
        return Status[Status.inProgress];
      }
    } else {
      throw new Error("Flow status info not implemented");
    }
  }

  static getRisk(localDeadline: Option<LocalDateTime>, globalDeadline: Option<LocalDateTime>, completed: Option<LocalDateTime>) {
    let now = completed.getOrElse(LocalDateTime.now());

    if (localDeadline.isEmpty() || globalDeadline.isEmpty()) {
      return Risk[Risk.normal];
    }

    if (globalDeadline.get().isBefore(now)) {
      return Risk[Risk.delayed];
    } else if (localDeadline.get().isBefore(now)) {
      return Risk[Risk.atRisk];
    } else {
      return Risk[Risk.normal];
    }
  }

  isInProgress(nodeId: ProcessNodeId) {
    if(this.modelInfo.isDefined()) {
      const actor = this.modelInfo.get().release.findActorForNode(nodeId);
      if (actor.isEmpty()) {
        return false;
      }
      const cursorFound = __(this.flow.cursors).find(c => c.cursorPosition.nodeId.contains(nodeId)).getOrUndefined();
      const assignedPersonFound = __(this.flow.assignedPersons).find(ap => ap[0] === actor.get().id).getOrUndefined();
      return !this.isError(nodeId) && !this.isCancelled() && assignedPersonFound !== undefined && cursorFound !== undefined &&
        cursorFound.isInProgress();
    } else {
      return false;
    }
  }

  isDelayed(nodeId: ProcessNodeId) {
    const cursorFound = __(this.flow.cursors).find((cursor: ProcessCursor) => cursor.cursorPosition.nodeId.get() === nodeId);
    return cursorFound.exists(t => t.isDelayed());
  }

  isWaitingForAssignment(nodeId: ProcessNodeId): boolean {
    if(this.modelInfo.isDefined()) {
      const actor = this.modelInfo.get().release.findActorForNode(nodeId);
      if (actor.isEmpty()) {
        return false;
      }
      const assignedPersonFound = __(this.flow.assignedPersons).find(ap => ap[0] === actor.get().id).getOrUndefined();
      return !this.isError(nodeId) && !this.isCancelled() && (assignedPersonFound === undefined) &&
        actor.get().taskDistributionMethod.name !== TaskDistributionMethod.manualByManager.name;
    } else {
      return false;
    }
  }

  isNeedYourAssignment(nodeId: ProcessNodeId): boolean {
    if(this.modelInfo.isDefined()) {
      const actor = this.modelInfo.get().release.findActorForNode(nodeId);
      if (actor.isEmpty()) {
        return false;
      }
      const assignedPersonFound = __(this.flow.assignedPersons).find(ap => ap[0] === actor.get().id).getOrUndefined();
      return !this.isError(nodeId) && !this.isCancelled() && (assignedPersonFound === undefined) &&
        actor.get().taskDistributionMethod.name === TaskDistributionMethod.manualByManager.name;
    } else {
      return false;
    }
  }

  isWaitingForStart(nodeId: ProcessNodeId): boolean {
    if(this.modelInfo.isDefined()) {
      const actor = this.modelInfo.get().release.findActorForNode(nodeId);
      if (actor.isEmpty()) {
        return false;
      }
      const cursorFound = __(this.flow.cursors).find(c => c.cursorPosition.nodeId.contains(nodeId)).getOrUndefined();
      const assignedPersonFound = __(this.flow.assignedPersons).find(ap => ap[0] === actor.get().id).getOrUndefined();
      return !this.isError(nodeId) && !this.isCancelled() && assignedPersonFound !== undefined && cursorFound !== undefined &&
        cursorFound.phase.phase.name === CursorPhases.Action.waitingForStart.name;
    } else {
      return false;
    }
  }

  isCancelled(): boolean {
    return this.flow.statusInfo.isTerminated();
  }

  isError(nodeId: ProcessNodeId): boolean {
    const cursorFound = __(this.flow.cursors).find(c => c.cursorPosition.nodeId.contains(nodeId) && c.status.status.name === CursorStatus.error.name).getOrUndefined();
    return cursorFound !== undefined;
  }

}











export class FinishStatus {
  constructor(readonly name: string) {}
  static completed = new FinishStatus("completed");
  static terminated = new FinishStatus("terminated");

  isCompleted() {
    return this.name === "completed";
  }

  isTerminated() {
    return this.name === "terminated";
  }

  static copy(other: FinishStatus) {
    switch (other.name) {
      case "completed": return FinishStatus.completed;
      case "terminated": return FinishStatus.terminated;
      default: throw new Error("Unsupported finish status '"+JSON.stringify(other)+"'");
    }
  }
}

export class FinishInfo {

  constructor(readonly finished: LocalDateTime,
              readonly status: FinishStatus,
              readonly personId: Option<Typed<AnyPersonId>>) {}

  personIdUnwrapped() {
    return this.personId.forEach(p => Typed.value(p))
  }

  static copy(other: FinishInfo) {
    return new FinishInfo(LocalDateTime.copy(other.finished), FinishStatus.copy(other.status), Option.copy(other.personId).map(AnyPersonIdFactory.copyTyped));
  }
}


export class LogEntryLevel {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  static error = new LogEntryLevel("error");
  static warning = new LogEntryLevel("warning");
  static info = new LogEntryLevel("info");
  static systemError = new LogEntryLevel("systemError");
}

export interface FlowLogEntry {
  className(): string;
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
}

export class FlowLogEntryFactory {
  static copyTyped(logEntry: Typed<FlowLogEntry>) {
    switch(Typed.className(logEntry).replace("V1", "")) {
      case GenericLogEntry.className:
        const genericLogEntry = <GenericLogEntry>Typed.value(logEntry);
        return Typed.of(new GenericLogEntry(genericLogEntry.level, genericLogEntry.message, LocalDateTime.copy(genericLogEntry.timestamp)));

      case NodeLogEntry.className:
        const nodeLogEntry = <NodeLogEntry>Typed.value(logEntry);
        return Typed.of(new NodeLogEntry(nodeLogEntry.level, nodeLogEntry.message, LocalDateTime.copy(nodeLogEntry.timestamp), nodeLogEntry.nodeId));

      case EdgeLogEntry.className:
        const edgeLogEntry = <EdgeLogEntry>Typed.value(logEntry);
        return Typed.of(new EdgeLogEntry(edgeLogEntry.level, edgeLogEntry.message, LocalDateTime.copy(edgeLogEntry.timestamp), edgeLogEntry.edgeId));

      case ConditionLogEntry.className:
        const conditionLogEntry = <ConditionLogEntry>Typed.value(logEntry);
        return Typed.of(new ConditionLogEntry(conditionLogEntry.level, conditionLogEntry.message, LocalDateTime.copy(conditionLogEntry.timestamp), conditionLogEntry.nodeId, conditionLogEntry.edgeId));

      case SectionLogEntry.className:
        const sectionLogEntry = <SectionLogEntry>Typed.value(logEntry);
        return Typed.of(new SectionLogEntry(sectionLogEntry.level, sectionLogEntry.message, LocalDateTime.copy(sectionLogEntry.timestamp), sectionLogEntry.sectionId));

      case BeforeActionLogEntry.className:
        const beforeActionLogEntry = <BeforeActionLogEntry>Typed.value(logEntry);
        return Typed.of(new BeforeActionLogEntry(beforeActionLogEntry.level, beforeActionLogEntry.message, LocalDateTime.copy(beforeActionLogEntry.timestamp), beforeActionLogEntry.nodeId, beforeActionLogEntry.actionId, beforeActionLogEntry.actionName));

      case AfterActionLogEntry.className:
        const afterActionLogEntry = <AfterActionLogEntry>Typed.value(logEntry);
        return Typed.of(new AfterActionLogEntry(afterActionLogEntry.level, afterActionLogEntry.message, LocalDateTime.copy(afterActionLogEntry.timestamp), afterActionLogEntry.nodeId, afterActionLogEntry.actionId, afterActionLogEntry.actionName));

      case FieldLogEntry.className:
        const fieldLogEntry = <FieldLogEntry>Typed.value(logEntry);
        return Typed.of(new FieldLogEntry(fieldLogEntry.level, fieldLogEntry.message, LocalDateTime.copy(fieldLogEntry.timestamp), fieldLogEntry.nodeId, fieldLogEntry.sectionRefId, fieldLogEntry.fieldId));

      case VariableLogEntry.className:
        const variableLogEntry = <VariableLogEntry>Typed.value(logEntry);
        return Typed.of(new VariableLogEntry(variableLogEntry.level, variableLogEntry.message, LocalDateTime.copy(variableLogEntry.timestamp), variableLogEntry.variablePath));

      case FieldStateLogEntry.className:
        const fieldStateLogEntry = <FieldStateLogEntry>Typed.value(logEntry);
        return Typed.of(new FieldStateLogEntry(fieldStateLogEntry.level, fieldStateLogEntry.message, LocalDateTime.copy(fieldStateLogEntry.timestamp), fieldStateLogEntry.propertyName, fieldStateLogEntry.elementId));

      case AssignmentLogEntry.className:
        const assignmentLogEntry = <AssignmentLogEntry>Typed.value(logEntry);
        return Typed.of(new AssignmentLogEntry(assignmentLogEntry.level, assignmentLogEntry.message, LocalDateTime.copy(assignmentLogEntry.timestamp), assignmentLogEntry.roleId));

      case ActionButtonExecutionLogEntry.className:
        const actionButtonExecutionLogEntry = <ActionButtonExecutionLogEntry>Typed.value(logEntry);
        return Typed.of(new ActionButtonExecutionLogEntry(actionButtonExecutionLogEntry.level, actionButtonExecutionLogEntry.message, LocalDateTime.copy(actionButtonExecutionLogEntry.timestamp), actionButtonExecutionLogEntry.flowId, actionButtonExecutionLogEntry.cursorId, actionButtonExecutionLogEntry.elementId));

      case FlowDescriptionFormulaLogEntry.className:
        const flowDescriptionFormulaLogEntry = <FlowDescriptionFormulaLogEntry>Typed.value(logEntry);
        return Typed.of(new FlowDescriptionFormulaLogEntry(flowDescriptionFormulaLogEntry.level, flowDescriptionFormulaLogEntry.message, LocalDateTime.copy(flowDescriptionFormulaLogEntry.timestamp)));

      case ImportanceFormulaLogEntry.className:
        const importanceFormulaLogEntry = <ImportanceFormulaLogEntry>Typed.value(logEntry);
        return Typed.of(new ImportanceFormulaLogEntry(importanceFormulaLogEntry.level, importanceFormulaLogEntry.message, LocalDateTime.copy(importanceFormulaLogEntry.timestamp)));

      case ColorFormulaLogEntry.className:
        const colorFormulaLogEntry = <ColorFormulaLogEntry>Typed.value(logEntry);
        return Typed.of(new ColorFormulaLogEntry(colorFormulaLogEntry.level, colorFormulaLogEntry.message, LocalDateTime.copy(colorFormulaLogEntry.timestamp)));


      default:
        throw new Error("Not supported " + Typed.className(logEntry));
    }
  }
}

export class GenericLogEntry implements FlowLogEntry {
  static className = "GenericLogEntry";
  className() {return GenericLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
  }
}
export class NodeLogEntry implements FlowLogEntry {
  static className = "NodeLogEntry";
  className() {return NodeLogEntry.className}


  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime,
              readonly nodeId: ProcessNodeId) {
  }
}
export class EdgeLogEntry implements FlowLogEntry {
  static className = "EdgeLogEntry";
  className() {return EdgeLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  edgeId: ProcessEdgeId;


  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, edgeId: ProcessEdgeId) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.edgeId = edgeId;
  }
}

export class SectionLogEntry implements FlowLogEntry {
  static className = "SectionLogEntry";
  className() {return SectionLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  sectionId: number;


  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, sectionId: number) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.sectionId = sectionId;
  }
}
export class ConditionLogEntry implements FlowLogEntry {
  static className = "ConditionLogEntry";
  className() {return ConditionLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  nodeId: ProcessNodeId;
  edgeId: ProcessEdgeId;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, nodeId: ProcessNodeId, edgeId: ProcessEdgeId) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.nodeId = nodeId;
    this.edgeId = edgeId;
  }
}
export class BeforeActionLogEntry implements FlowLogEntry {
  static className = "BeforeActionLogEntry";
  className() {return BeforeActionLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  nodeId: ProcessNodeId;
  actionId: number;
  actionName: string;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, nodeId: ProcessNodeId, actionId: number, actionName: string) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.nodeId = nodeId;
    this.actionId = actionId;
    this.actionName = actionName;
  }
}
export class AfterActionLogEntry implements FlowLogEntry {
  static className = "AfterActionLogEntry";
  className() {return AfterActionLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  nodeId: ProcessNodeId;
  actionId: number;
  actionName: string;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, nodeId: ProcessNodeId, actionId: number, actionName: string) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.nodeId = nodeId;
    this.actionId = actionId;
    this.actionName = actionName;
  }
}
export class FieldLogEntry implements FlowLogEntry {
  static className = "FieldLogEntry";
  className() {return FieldLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  nodeId: ProcessNodeId;
  sectionRefId: FormSectionRefId;
  fieldId: number;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, nodeId: ProcessNodeId, sectionRefId: FormSectionRefId, fieldId: number) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.nodeId = nodeId;
    this.sectionRefId = sectionRefId;
    this.fieldId = fieldId;
  }
}

export class VariableLogEntry implements FlowLogEntry {
  static className = "VariableLogEntry";
  className() {return VariableLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  variablePath: string;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, variablePath: string) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.variablePath = variablePath;
  }
}

export class FieldStateLogEntry implements FlowLogEntry {
  static className = "FieldStateLogEntry";
  className() {return FieldStateLogEntry.className}
  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  propertyName: string;
  elementId: number;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, propertyName: string, elementId: number) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.propertyName = propertyName;
    this.elementId = elementId;
  }
}

export class AssignmentLogEntry implements FlowLogEntry {
  static className = "AssignmentLogEntry";
  className() { return AssignmentLogEntry.className }

  level: LogEntryLevel;
  message: string;
  timestamp: LocalDateTime;
  roleId: number;

  constructor(level: LogEntryLevel, message: string, timestamp: LocalDateTime, roleId: number) {
    this.level = level;
    this.message = message;
    this.timestamp = timestamp;
    this.roleId = roleId;
  }
}

export class ActionButtonExecutionLogEntry implements FlowLogEntry {
  static className = "ActionButtonExecutionLogEntry";

  className() {
    return ActionButtonExecutionLogEntry.className
  }

  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime,
              readonly flowId: AggregateId,
              readonly cursorId: FlowCursorId,
              readonly elementId: number) {}
}

export class FlowDescriptionFormulaLogEntry implements FlowLogEntry {
  static className = "FlowDescriptionFormulaLogEntry";
  className() {return FlowDescriptionFormulaLogEntry.className}

  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime) {
  }
}

// Deprecated
export class PriorityFormulaLogEntry implements FlowLogEntry {
  static className = "PriorityFormulaLogEntry";
  className() {return PriorityFormulaLogEntry.className}

  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime) {
  }
}

export class ImportanceFormulaLogEntry implements FlowLogEntry {
  static className = "ImportanceFormulaLogEntry";
  className() {return ImportanceFormulaLogEntry.className}

  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime) {
  }
}

export class ColorFormulaLogEntry implements FlowLogEntry {
  static className = "ColorFormulaLogEntry";
  className() {return ColorFormulaLogEntry.className}

  constructor(readonly level: LogEntryLevel,
              readonly message: string,
              readonly timestamp: LocalDateTime) {
  }
}

export class CursorHistory {

  constructor(readonly parentCursorsIds: number[],
              readonly pathNodesIds: number[]) {}

  static copy(other: CursorHistory) {
    return new CursorHistory(other.parentCursorsIds.slice(), other.pathNodesIds.slice());
  }
}






export class FlowAuthorization {
  constructor(readonly name: string) {}

  static edit = new FlowAuthorization("edit");
  static preview = new FlowAuthorization("preview");

  static copy(authorizationElement: FlowAuthorization) {
    switch (authorizationElement.name) {
      case FlowAuthorization.edit.name: return FlowAuthorization.edit;
      case FlowAuthorization.preview.name: return FlowAuthorization.preview;
      default: throw new Error("Unknown authorization element: " + authorizationElement.name);
    }
  }
}

export class ProcessFlowData {

  constructor(public cachedVariablesData: Array<[string, number]>,
              readonly visibleFormSections: FormSectionId[],
              readonly logs: Typed<FlowLogEntry>[]) {}

  static copy(other: ProcessFlowData): ProcessFlowData {
    return new ProcessFlowData(
      other.cachedVariablesData.map((e: [string, number]) => <[string, number]>[e[0], e[1]]),
      other.visibleFormSections.slice(),
      other.logs.map(l => FlowLogEntryFactory.copyTyped(l))
    );
  }

}

export class EntryPair {
  constructor(readonly name: BusinessVariable,
              readonly value: BusinessVariable) {
  }
}

export class FormFieldState {

  static READONLY = "readOnly";
  static HIDDEN = "hidden";
  static REQUIRED = "required";
  static ENTRIES = "entries";
  static DURING_EXECUTION = "duringExecution";

  static TRUE = 1;
  static FALSE = 2;


  // This should not be serialized to server
  private propertiesSubject = new Subject<Array<[string, BusinessVariable]>>();

  constructor(public contextObjectId: Option<ObjectId>,
              readonly elementId: FormElementId,
              public properties: Array<[string, BusinessVariable]>) {}


  private getProperty(name: string): Option<BusinessVariable> {
    return __(this.properties).find((p: [string, BusinessVariable]) => p[0] === name).map((p: [string, BusinessVariable]) => p[1]);
  }


  get entriesIsEmpty(): boolean {
    return this.getProperty(FormFieldState.ENTRIES).isEmpty();
  }

  get entriesIsArray(): boolean {
    return this.getProperty(FormFieldState.ENTRIES).exists((e: BusinessVariable) => e instanceof ArrayVariable);
  }

  get entriesIsObject(): boolean {
    return this.getProperty(FormFieldState.ENTRIES).exists((e: BusinessVariable) => e instanceof ArrayVariable);
  }


  get entriesArray(): Option<Array<EntryPair>> {
    const entries = this.getProperty(FormFieldState.ENTRIES);
    if(entries.isEmpty() || entries.exists((e: BusinessVariable) => e instanceof ArrayVariable && __(e.unwrappedValue()).all(f => f.className() === ObjectVariable.className))) {
      return <Option<Array<EntryPair>>>entries.map((e: BusinessVariable) => {
        if(e instanceof ArrayVariable) {
          return e.unwrappedValue().map(f => {
            const value = f.valueFor("value");
            let name = f.valueFor(i18nLanguage());

            if (name.isEmpty()) {
              name = f.valueFor("label");
            }
            if (name.isEmpty()) {
              name = f.valueFor("name");
            }
            if (name.isEmpty()) {
              name = f.valueFor("en");
            }

            if (value.isEmpty()) {
              throw new Error("Value in entry is not defined");
            }
            if (name.isEmpty()) {
              throw new Error("Name in entry is not defined (current language,'label, 'name' or 'en' property");
            }

            return new EntryPair(name.get(), value.get());
          });
        } else {
          throw new Error("Entries is not an array");
        }
      });
    } else {
      return <Option<Array<EntryPair>>>entries.map((e: BusinessVariable) => {
        if(e instanceof ArrayVariable) {
          return e.unwrappedValue().map(f => new EntryPair(f, f));
        } else {
          throw new Error("Entries is not an array");
        }
      });
    }
  }

  get readOnly(): boolean {
    return (<Option<BooleanVariable>>this.getProperty(FormFieldState.READONLY)).map(v => v.value).getOrElse(true); // if state is not set we want read only by default
  }

  get readOnlyDefaultFalse(): boolean {
    return (<Option<BooleanVariable>>this.getProperty(FormFieldState.READONLY)).map(v => v.value).getOrElse(false); // specifically for action button
  }

  get required(): boolean {
    return (<Option<BooleanVariable>>this.getProperty(FormFieldState.REQUIRED)).map(v => v.value).getOrElse(false); // if state is not set we want not required by default
  }

  get hidden(): boolean {
    return (<Option<BooleanVariable>>this.getProperty(FormFieldState.HIDDEN)).map(v => v.value).getOrElse(true); // if state is not set we want hidden by default
  }

  get duringExecution(): boolean {
    return (<Option<BooleanVariable>>this.getProperty(FormFieldState.DURING_EXECUTION)).map(v => v.value).getOrElse(false); // if state is not set we want not during execution by default
  }

  static copy(other: FormFieldState) {
    return new FormFieldState(Option.copy(other.contextObjectId),
      other.elementId,
      other.properties.map((p: [string, BusinessVariable]) => <[string, BusinessVariable]>[p[0], BusinessVariableFactory.copy(p[1])]));
  }


  static empty(id: FormElementId, contextObjectId: Option<ObjectId>) {
    return new FormFieldState(contextObjectId, id, []);
  }

  getPropertiesObservable() {
    return this.propertiesSubject.asObservable();
  }

  updateProperty(propertyName: string, value: Option<BusinessVariable>) {
    this.properties = this.properties.filter((p: [string, BusinessVariable]) => p[0] !== propertyName);

    if(value.isDefined()) {
      this.properties.push(<[string, BusinessVariable]>[propertyName, value.get()])
    }
    this.propertiesSubject.next(this.properties);
  }
}

export class CachedFormFieldState {


  constructor(readonly contextObjectId: Option<ObjectId>,
              readonly elementId: FormElementId,
              readonly properties: Array<[string, number]>) {}

  static copy(other: CachedFormFieldState) {
    return new CachedFormFieldState(Option.copy(other.contextObjectId),
      other.elementId,
      other.properties.map((p: [string, number]) => <[string, number]>[p[0], p[1]]));
  }

  decache(fieldsEntriesStateCache: { [p: number]: BusinessVariable }) {

    return new FormFieldState(Option.copy(this.contextObjectId), this.elementId, this.properties.map((p: [string, number]) => {
      const value: BusinessVariable =
                    p[1] === FormFieldState.TRUE ? BooleanVariable.TRUE() :
                    p[1] === FormFieldState.FALSE ? BooleanVariable.FALSE() :
                    Option.of(fieldsEntriesStateCache[p[1]]).getOrElseLazy(() => {throw new Error("There is no entry in cache for state value " + p[1])});
        return <[string, BusinessVariable]>[p[0], value];
    }));
  }
}

export class FormFieldsState {
  constructor(public elements: Array<FormFieldState>) {
  }

  static copy(other: FormFieldsState) {
    return new FormFieldsState(other.elements.map(FormFieldState.copy))
  }

  static empty() {
    return new FormFieldsState([]);
  }

  elementState(id: FormElementId, contextObjectId: Option<ObjectId>): FormFieldState {
    return __(this.elements).find(e => e.elementId.id === id.id && e.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id)).getOrElseLazy(() => FormFieldState.empty(id, contextObjectId));
  }

  updateState(elementId: FormElementId, contextObjectId: Option<ObjectId>, propertyName: string, value: Option<BusinessVariable>) {
    const state = __(this.elements).find(e => e.elementId.id === elementId.id && e.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id));

    if(state.isDefined()) {
      state.get().updateProperty(propertyName, value);
    } else {
      const newState = FormFieldState.empty(elementId, contextObjectId);
      newState.updateProperty(propertyName, value);
      this.elements.push(newState);
    }

  }
}

export class CachedFormFieldsState {
  constructor(readonly elements: Array<CachedFormFieldState>) {
  }

  static copy(other: CachedFormFieldsState) {
    return new CachedFormFieldsState(other.elements.map(CachedFormFieldState.copy))
  }

  static empty() {
    return new CachedFormFieldsState([]);
  }

  decache(fieldsEntriesStateCache: {[p: number]: BusinessVariable}): FormFieldsState {
    return new FormFieldsState(this.elements.map(element => element.decache(fieldsEntriesStateCache)));
  }

  elementState(id: FormElementId, contextObjectId: Option<ObjectId>) {
    return __(this.elements).find(e => e.elementId.id === id.id && e.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id)).getOrElseLazy(() => new CachedFormFieldState(contextObjectId, id, []));
  }
}



export class MyCommentServerModel{

  constructor(readonly commentId: number,
              readonly personId: Option<Typed<AnyPersonId>>,
              readonly commentText: string,
              readonly attachments: Array<FileUri>,
              readonly extraRecipients: Array<Typed<AnyPersonId>>,
              readonly created: LocalDateTime,
              readonly modified: Option<LocalDateTime>,
              readonly deleted: boolean) {}

  static copy(other : MyCommentServerModel) {
    return new MyCommentServerModel(
      other.commentId,
      Option.copy(other.personId, AnyPersonIdFactory.copyTyped),
      other.commentText,
      other.attachments.map(FileUri.copy),
      other.extraRecipients.map(AnyPersonIdFactory.copyTyped),
      LocalDateTime.copy(other.created),
      Option.copy(other.modified, LocalDateTime.copy),
      other.deleted);
  }

  get authorNode(): OrganizationNodeId|undefined {
    return this.personId.map(p => Typed.value(p)).filter(p => p.isPersonId()).map(p => OrganizationNodeId.fromPersonId(p.asPersonId())).getOrUndefined();
  }

  get extraRecipientsUnwrapped(): Array<AnyPersonId> {
    return this.extraRecipients.map(p => Typed.value(p));
  }
}


export class ProcessFlowDetails implements ProcessFlowDetailsInterface {

  constructor(readonly summary: ProcessFlowSummary,
              readonly authorization: Array<[FlowAuthorization, Array<OrganizationNodeId>]>,
              readonly cursorsHistory: Array<[number, CursorHistory]>,
              public tasksHistory: Array<TaskHistoryDetails>,
              public comments: Array<ProcessFlowComment>,
              readonly flowData: Option<ProcessFlowData>,
              readonly formFieldsState: CachedFormFieldsState,
              readonly cachedValues: Array<[number,Typed<BusinessVariable>]>) {}


  private decache(cachedVariablesData: Array<[string, number]>, cachedValues: Array<[number,Typed<BusinessVariable>]>): Array<RootVariable<BusinessVariable>> {
    const __cachedValues = __(cachedValues);
    return cachedVariablesData.map(([variableId, id]) => {
      const value = __cachedValues.find(([i, _]) => i === id).getOrError("Cached value '"+id+"' not found");
      return new RootVariable(variableId, value[1]);
    });
  }

  variablesData() {
    return this.flowData.map(flowData => this.decache(flowData.cachedVariablesData, this.cachedValues)).getOrElse([]);
  }

  errorsUnwrapped(): Array<FlowLogEntry> {
    return this.flowData.map(flowData => flowData.logs.map(e => Typed.value<FlowLogEntry>(e))).getOrElse([]);
  }

  static copy(other: ProcessFlowDetails) {
    const result = new ProcessFlowDetails(ProcessFlowSummary.copy(other.summary),
      other.authorization.map((authorization: [FlowAuthorization, OrganizationNodeId[]]) => {
        const result: [FlowAuthorization, OrganizationNodeId[]] = [FlowAuthorization.copy(authorization[0]), authorization[1].map(OrganizationNodeId.copy)];
        return result;
      }),
      other.cursorsHistory.map((h: [number, CursorHistory]) => <[number, CursorHistory]>[h[0], CursorHistory.copy(h[1])]),
      other.tasksHistory.map(TaskHistoryDetails.copy),
      other.comments.map(ProcessFlowComment.copy),
      Option.copy(other.flowData).map(ProcessFlowData.copy),
      CachedFormFieldsState.copy(other.formFieldsState),
      other.cachedValues.map((entry: [number, Typed<BusinessVariable>]) =>
        <[number, Typed<BusinessVariable>]>[entry[0], BusinessVariableFactory.copyTyped(entry[1])]));

    result.tasksHistory.forEach(t => t.updateFormFieldsStateNonCached(result.cachedValuesMap()));

    return result;
  }

  cachedValuesMap(): {[index: number]: BusinessVariable} {
    const map: {[index: number]: BusinessVariable} = {};
    this.cachedValues.forEach(v => map[v[0]] = Typed.value(v[1]));
    return map;
  }

  bytesSize() {
    const states = ___(this.cachedValues).map((e: [number,Typed<BusinessVariable>]) => 4 + Typed.value(e[1]).bytesSize()).sum();
    return states;
  }

  getEditAuthorizations() {
    return __(this.authorization).find(a => a[0].name === FlowAuthorization.edit.name).map(a => a[1]).getOrElse([]);
  }

  getPreviewAuthorizations() {
    return __(this.authorization).find(a => a[0].name === FlowAuthorization.preview.name).map(a => a[1]).getOrElse([]);
  }

}

export class ProcessFlowDetailsWithPersons {
  constructor(readonly flowDetails: ProcessFlowDetails, readonly persons: BasicPersonInfo[]){}

  static copy(other: ProcessFlowDetailsWithPersons){
    return new ProcessFlowDetailsWithPersons(ProcessFlowDetails.copy(other.flowDetails), other.persons.map(BasicPersonInfo.copy))
  }
}




export class TaskHistoryData {
  constructor(readonly cachedVariablesData: Array<[string, number]>,
              readonly visibleFormSections: FormSectionId[],
              readonly fieldsState: CachedFormFieldsState,
              readonly completedActivities: number[]) {

  }

  static copy(other: TaskHistoryData): TaskHistoryData {
    return new TaskHistoryData(
      other.cachedVariablesData.map((e: [string, number]) => <[string, number]>[e[0], e[1]]),
      other.visibleFormSections.slice(),
      CachedFormFieldsState.copy(other.fieldsState),
      other.completedActivities.slice()
    );
  }

  variablesData(processFlowDetails: ProcessFlowDetails): Array<RootVariable<BusinessVariable>> {
    const __cachedValues = __(processFlowDetails.cachedValues);
    return this.cachedVariablesData.map(([variableId, id]) => {
      const value = __cachedValues.find(([i, _]) => i === id).getOrError("Cached value '"+id+"' not found");
      return new RootVariable(variableId, value[1]);
    });

  }
}

export class TaskHistoryDetails implements TaskHistoryDetailsInterface {
  /* Local only */
  formFieldsStateNonCached: FormFieldsState;

  constructor(readonly nodeId: ProcessNodeId,
              readonly personsAssigned: Array<Typed<AnyPersonId>>,
              readonly created: LocalDateTime,
              readonly started: Option<LocalDateTime>,
              readonly deadline: Option<LocalDateTime>,
              readonly ended: Option<LocalDateTime>,
              readonly terminated: Option<LocalDateTime>,
              readonly taskData: Option<TaskHistoryData>,
              readonly trackedTime: Array<TaskTrackedTime>) {
    this.formFieldsStateNonCached = FormFieldsState.empty();
  }

  static copy(other: TaskHistoryDetails): TaskHistoryDetails {
    return new TaskHistoryDetails(
      other.nodeId,
      other.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      LocalDateTime.copy(other.created),
      Option.copy(other.started).map(LocalDateTime.copy),
      Option.copy(other.deadline).map(LocalDateTime.copy),
      Option.copy(other.ended).map(LocalDateTime.copy),
      Option.copy(other.terminated).map(LocalDateTime.copy),
      Option.copy(other.taskData).map(TaskHistoryData.copy),
      other.trackedTime.map(TaskTrackedTime.copy)
    );
  }

  updateFormFieldsStateNonCached(fieldsEntriesStateCache: {[index: number]: BusinessVariable}) {
    this.formFieldsStateNonCached = this.taskData.map(taskData => taskData.fieldsState).getOrElseLazy(() => CachedFormFieldsState.empty()).decache(fieldsEntriesStateCache);
  }

  personsAssignedUnwrapped(): Array<AnyPersonId> {
    return this.personsAssigned.map(p => Typed.value(p));
  }
}



export class FormElementSummary {
  constructor(
    readonly elementRef: Typed<FormElementRef>,
    readonly element: Typed<FormElement>,
    readonly formSectionId: FormSectionId,
    readonly variable: RootVariableWithType<BusinessVariable, BusinessVariableType>,
    readonly state: Option<CachedFormFieldState>,
    readonly readOnly: boolean
  ) {}

  static copy(other: FormElementSummary) {
    return new FormElementSummary(
      ElementsRefsFactory.copyTyped(other.elementRef),
      ElementsFactory.copyTyped(other.element),
      FormSectionId.copy(other.formSectionId),
      RootVariableWithType.copy(other.variable),
      Option.copy(other.state, CachedFormFieldState.copy),
      other.readOnly
    )
  }

  elementUnwrapped(): InputElement {
    return <InputElement>Typed.value(this.element);
  }

  elementRefUnwrapped(): InputElementRef {
    return <InputElementRef>Typed.value(this.elementRef);
  }
}


export class VariableValueWithLabel {
  constructor(readonly label: I18nText,
              readonly value: Typed<BusinessVariable>) {
  }

  unwrappedValue() {
    return Typed.value(this.value);
  }

  static copy(other: VariableValueWithLabel) {
    return new VariableValueWithLabel(I18nText.copy(other.label), BusinessVariableFactory.copyTyped(other.value));
  }
}

export class ProcessProgressStepStatus {
  constructor(readonly name: string) {}

  static completed = new ProcessProgressStepStatus("completed");
  static ongoing = new ProcessProgressStepStatus("ongoing");
  static upcoming = new ProcessProgressStepStatus("upcoming");

  static copy(status: ProcessProgressStepStatus) {
    switch(status.name) {
      case ProcessProgressStepStatus.completed.name: return ProcessProgressStepStatus.completed;
      case ProcessProgressStepStatus.ongoing.name: return ProcessProgressStepStatus.ongoing;
      case ProcessProgressStepStatus.upcoming.name: return ProcessProgressStepStatus.upcoming;
      default: throw new Error("Unknown ProcessProgressStepStatus: " + status.name);
    }
  }
}

export class ProcessProgressStep {
  constructor(readonly name: string,
              readonly status: ProcessProgressStepStatus,
              readonly time: Option<LocalDateTime>) {}

  static copy(step: ProcessProgressStep) {
    return new ProcessProgressStep(
      step.name,
      ProcessProgressStepStatus.copy(step.status),
      Option.copy(step.time, LocalDateTime.copy));
  }
}

export class TaskModel {

  formFieldsStateNonCached: FormFieldsState;
  pendingEvaluated: Array<ContextPath> = [];

  constructor(
    public flowId: Typed<AnyFlowId>,
    readonly flowVersion: AggregateVersion,
    readonly instanceId: AggregateId,
    readonly processId: AggregateId,
    readonly applicationId: Option<ApplicationId>,
    readonly organizationId: OrganizationId,
    readonly screenBased: boolean,
    readonly quickSubmitAllowed: boolean,
    readonly cursorInfo: Option<CursorInfo>,
    readonly nodeId: ProcessNodeId,
    readonly nodeType: NodeType,
    readonly roleId: number,
    readonly roleNodeList: Option<Array<OrganizationNodeId>>,
    readonly initialPersons: Array<Typed<AnyPersonId>>,
    public personsAssigned: Array<Typed<AnyPersonId>>,
    public assigneeLimit: number,
    readonly created: LocalDateTime,
    public started: Option<LocalDateTime>,
    readonly completed: Option<LocalDateTime>,
    readonly canceled: boolean,
    readonly deadline: Option<LocalDateTime>,
    public labels: Array<string>,
    public systemLabels: Array<Typed<BusinessVariable>>,
    public seen: boolean,
    readonly estimatedDurationSeconds: Option<number>,
    readonly nodeName: string,
    readonly roleName: string,
    readonly taskDescription: string,
    readonly taskInstruction: string,
    readonly processProgress: Array<ProcessProgressStep>,
    readonly form: FormModel,
    readonly formSections: Array<FormSectionInfo>,
    readonly formElements: Array<Typed<FormElement>>,
    public visibleFormSections: Array<FormSectionId>,
    readonly formVariables: Array<RootVariableWithType<BusinessVariable, BusinessVariableType>>,
    public fieldsState: CachedFormFieldsState,
    readonly activities: Array<NodeActivity>,
    readonly automatic: boolean,
    readonly attachments: Array<DesignAttachment>,
    public completedActivities: Array<number>,
    public comments: Array<ProcessFlowComment>,
    readonly flowCode: string,
    public flowDescription: string,
    readonly processName: string,
    readonly flowContinued: boolean,
    public variablesToEvaluate: Array<ContextPath>,
    readonly fieldsStateCachedValues: Array<[number,Typed<BusinessVariable>]>,
    readonly mainEdges: Array<TaskEdge>,
    readonly availableAlternativeEdges: Array<TaskEdge>,
    readonly pullActions: Array<TaskPullAction>,
    readonly assignableToMe: boolean,
    readonly tasksVisibility: TasksVisibility,
    public summaryElements: Array<FormElementSummary>,
    readonly substitution: boolean,
    readonly trackedTime: Array<TaskTrackedTime>,
    readonly taskStatus: TaskStatus,
    readonly instanceColor: Option<string>,
    readonly flowColor: Option<string>,
    readonly colorOverride: Option<Option<string>>,
    readonly importance: number,
    readonly importanceOverride: Option<number>,
    readonly urgency: number,
    readonly urgencyOverride: Option<number>,
    readonly canChangeImportance: boolean,
    readonly canChangeUrgency: boolean,
    readonly canChangeLabels: boolean,
    readonly commentsAccess: boolean,
    readonly working: boolean,
    readonly deadlineEditable: boolean
  ) {
    this.formFieldsStateNonCached = this.fieldsState.decache(this.cachedValuesMap());
  }


  systemLabelsUnwrapped(): Array<BusinessVariable> {
    return this.systemLabels.map(l => Typed.value(l));
  }

  personsAssignedUnwrapped() {
    return this.personsAssigned.map(p => Typed.value(p));
  }

  initialPersonsUnwrapped() {
    return this.initialPersons.map(p => Typed.value(p));
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  formElementsUnwrapped(): Array<FormElement> {
    return this.formElements.map(e => Typed.value(e));
  }

  updateFormFieldsStateNonCached() {
    this.formFieldsStateNonCached = this.fieldsState.decache(this.cachedValuesMap());
  }

  isInitialized(): boolean {
    return this.flowId !== null;
  }

  isMaterialized(): boolean {
    return Typed.value(this.flowId).id.charAt(0) !== "_";
  }

  visibleSectionsOnly(): Array<FormSectionInfo> {
    return this.formSections.filter(s => s.section.visibilityExpression.isEmpty() || this.visibleFormSections.filter(v => v.id === s.section.id.id).length > 0);
  }

  static copy(other: TaskModel) {
    return new TaskModel(
      AnyFlowIdFactory.copyTyped(other.flowId),
      AggregateVersion.copy(other.flowVersion),
      AggregateId.copy(other.instanceId),
      AggregateId.copy(other.processId),
      Option.copy(other.applicationId, ApplicationId.of),
      OrganizationId.of(other.organizationId),
      other.screenBased,
      other.quickSubmitAllowed,
      Option.copy(other.cursorInfo),
      other.nodeId,
      NodeType.copy(other.nodeType, other.automatic),
      other.roleId,
      Option.copy(other.roleNodeList).map( list => list.map(OrganizationNodeId.copy)),
      other.initialPersons.map(AnyPersonIdFactory.copyTyped),
      other.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      other.assigneeLimit,
      LocalDateTime.copy(other.created),
      Option.copy(other.started).map(LocalDateTime.copy),
      Option.copy(other.completed).map(LocalDateTime.copy),
      other.canceled,
      Option.copy(other.deadline).map(LocalDateTime.copy),
      other.labels.slice(),
      other.systemLabels.map(BusinessVariableFactory.copyTyped),
      other.seen,
      Option.copy(other.estimatedDurationSeconds),
      other.nodeName,
      other.roleName,
      other.taskDescription,
      other.taskInstruction,
      other.processProgress.map(ProcessProgressStep.copy),
      FormModel.copy(other.form),
      other.formSections.map(FormSectionInfo.copy),
      other.formElements.map(ElementsFactory.copyTyped),
      other.visibleFormSections.slice(),
      other.formVariables.map(RootVariableWithType.copy),
      CachedFormFieldsState.copy(other.fieldsState),
      other.activities.map(NodeActivity.copy),
      other.automatic,
      other.attachments.map(DesignAttachment.copy),
      other.completedActivities,
      other.comments.map(ProcessFlowComment.copy),
      other.flowCode,
      other.flowDescription,
      other.processName,
      other.flowContinued,
      other.variablesToEvaluate.map(ContextPath.copy),
      other.fieldsStateCachedValues.map((entry: [number, Typed<BusinessVariable>]) =>
        <[number, Typed<BusinessVariable>]>[entry[0], BusinessVariableFactory.copyTyped(entry[1])]),
      other.mainEdges.map(TaskEdge.copy),
      other.availableAlternativeEdges.map(TaskEdge.copy),
      other.pullActions.map(TaskPullAction.copy),
      other.assignableToMe,
      TasksVisibility.copy(other.tasksVisibility),
      other.summaryElements.map(FormElementSummary.copy),
      other.substitution,
      other.trackedTime.map(TaskTrackedTime.copy),
      TaskStatus.copy(other.taskStatus),
      Option.copy(other.instanceColor),
      Option.copy(other.flowColor),
      Option.copy(other.colorOverride, (c: Option<string>) => Option.copy(c)),
      other.importance,
      Option.copy(other.importanceOverride),
      other.urgency,
      Option.copy(other.urgencyOverride),
      other.canChangeImportance,
      other.canChangeUrgency,
      other.canChangeLabels,
      other.commentsAccess,
      other.working,
      other.deadlineEditable
    );
  }

  inputElementForRef(ref: InputElementRef): InputElement {
    return <InputElement>__(this.formElements).find(e => Typed.value(e).id.id === ref.elementId.id).map(e => Typed.value(e)).get();
  }

  hasInputElementRef(ref: FormElementRefId) {
    return __(this.formSections).exists(s => s.section.hasInputElementRefById(ref));
  }

  getInputElementRefById(ref: FormElementRefId) : Option<InputElementRef>{
    return __(this.formSections).find(s => s.section.hasInputElementRefById(ref)).map(s => s.section.getInputElementRefById(ref))
  }

  getSectionByRefId(ref: FormSectionRefId): Option<FormSectionInfo> {
    return __(this.formSections).find(s => s.sectionRef.id.id === ref.id);
  }

  is(task: TaskIdentifier) {
    return AnyFlowIdHelper.equals(this.flowIdUnwrapped(), task.flowIdUnwrapped()) && this.nodeId === task.nodeId;
  }

  toSummary() {
    const task = this;
    const flowCode = task.flowCode && task.flowCode.length > 0 ? Some(task.flowCode) : None();

    const fieldsInTaskBox = ___(task.formSections).filter(s => s.section.forEachVariableName.isEmpty() && (s.section.visibilityExpression.isEmpty() ||  __(task.visibleFormSections).exists(ss => ss.id == s.section.id.id))).flatMap(s => {

      return s.section.inputElementsRefsUnwrapped().filter(ref => {
        const field = task.inputElementForRef(ref);
        ref.visibleInTaskBox && ref.hidden.isUnknown() && !task.fieldState(field.id, None()).hidden;
      }).map(ref => {

        const field = task.inputElementForRef(ref);
        const fieldState = task.fieldState(field.id, None());
        const cachedState = task.fieldsState.elementState(field.id, None());
        const readonly = s.sectionRef.readOnly || ref.readOnly.isTrue() || ref.readOnly.isUnknown() && fieldState.readOnly;
        return new FormElementSummary(Typed.of(ref), Typed.of(field), s.section.id, __(task.formVariables).find(v => v.name == field.variableTypePath.toString()).getOrError("Cannot find field variable"), Some(cachedState), readonly)
      });

    }).value()

    const canAssignOther = task.tasksVisibility.name == TasksVisibility.allInRole.name && task.tasksVisibility.name === TasksVisibility.allInCase.name;

    return new TaskModelSummary(
      AnyFlowIdFactory.copyTyped(task.flowId), Option.copy(task.applicationId, ApplicationId.of), task.processId, task.instanceId, task.nodeId, task.nodeType, task.automatic, task.roleId, task.cursorInfo,
      task.created, task.started, task.completed, task.canceled, task.deadline,
      task.labels, task.systemLabels.map(BusinessVariableFactory.copyTyped), task.seen,
      task.taskDescription,
      flowCode,
      task.flowDescription,
      task.processName,
      task.nodeName,
      task.flowContinued,
      task.initialPersons.map(AnyPersonIdFactory.copyTyped),
      task.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      task.assigneeLimit, task.substitution,
      task.mainEdges,
      task.availableAlternativeEdges,
      task.pullActions,
      task.assignableToMe, canAssignOther,
      task.trackedTime, Option.copy(task.estimatedDurationSeconds), TaskStatus.copy(task.taskStatus),
      Option.copy(task.instanceColor),
      Option.copy(task.flowColor),
      Option.copy(task.colorOverride, (c: Option<string>) => Option.copy(c)),
      task.importance,
      Option.copy(task.importanceOverride),
      task.urgency,
      Option.copy(task.urgencyOverride),
      task.comments.length,
      fieldsInTaskBox,
      task.fieldsStateCachedValues,
      task.canChangeImportance,
      task.canChangeUrgency,
      task.canChangeLabels,
      task.commentsAccess,
      task.formVariables.map(v => v.unwrappedVariableOption().map(v => v.toSearchable())).join(" "),
      task.working,
      task.screenBased,
      task.quickSubmitAllowed,
      task.deadlineEditable
    );

  }

  toTaskIdentifier() {
    return new TaskIdentifier(this.flowId, this.nodeId);
  }

  fieldState(id: FormElementId, contextObjectId: Option<ObjectId>): FormFieldState {
    return this.formFieldsStateNonCached.elementState(id, contextObjectId);
  }

  updateSections(shownSections: FormSectionId[], hiddenSections: FormSectionId[]) {
    this.visibleFormSections = __(this.visibleFormSections).
    filter(section => !__(hiddenSections).exists(s => section.id === s.id));

    const newFormSections = __(shownSections).
    filter(s => !__(this.visibleFormSections).exists(section => section.id === s.id));

    this.visibleFormSections = this.visibleFormSections.concat(newFormSections);
  }

  updateVariables(variablesCleared: ContextPath[], modifiedVariables: ContextVariable<BusinessVariable>[]) {
    variablesCleared.forEach(path => this.setVariableInContext(path, None()));
    modifiedVariables.forEach(v => this.setVariableInContext(v.toContextPath(), Some(v.unwrappedVariable())));
  }

  private findObjectPathByObjectId(objectId: ObjectId): Option<VariablePath> {
    const objectVariable = ObjectVariable.fromRootVariables(this.formVariables.filter(v => v.variable.isDefined()).map(v => new RootVariable(v.name, v.variable.get())));
    return objectVariable.findObjectById(objectId).map(f => f[0]);
  }


  private setVariableInContext(path: ContextPath, value: Option<BusinessVariable>) {

    if(path.context.isDefined()) {
      const contextPath = this.findObjectPathByObjectId(path.context.get());
      if(contextPath.isDefined()) {
        this.setVariable(contextPath.get().concat(path.location), value);
      } // otherwise do nothing
    } else {
      this.setVariable(path.location, value);
    }

  }

  private setVariable(path: VariablePath, value: Option<BusinessVariable>): void {
    const rootVariable = __(this.formVariables).find(v => v.name === path.headName());

    if(path.isRoot() && !path.headIsIndexed()) {
      rootVariable.forEach(v => v.setVariable(value));
    } else if (path.isRoot() && path.headIsIndexed()) {
      rootVariable.forEach(v => v.unwrappedVariableOption().forEach(arr => {
        if(arr instanceof ArrayVariable) {
          arr.setValue(path.headNameIndex(), value);
        } else {
          throw new Error("Setting indexed value of non array variable " + path.toString()+", "+(typeof arr));
        }
      }))
    } else if (path.nonRoot() && !path.headIsIndexed()) {
      rootVariable.forEach(v => v.unwrappedVariableOption().forEach(obj => {
        if(obj instanceof ObjectVariable) {
          obj.setValueByPath(path.tail(), value);
        } else {
          throw new Error("Setting sub value of non object variable " + path.toString()+", "+(typeof obj));
        }
      }));
    } else { //non root and index is defined
      rootVariable.forEach(v => v.unwrappedVariableOption().forEach(arr => {
        if(arr instanceof ArrayVariable) {
          arr.setValueByPath(path.headNameIndex(), path.tail().tail(), value);
        } else {
          throw new Error("Setting indexed value of non array variable " + path.toString()+", "+(typeof arr));
        }
      }));
    }
  }

  cachedValuesMap(): {[index: number]: BusinessVariable} {
    const map: {[index: number]: BusinessVariable} = {};
    this.fieldsStateCachedValues.forEach(v => map[v[0]] = Typed.value(v[1]));
    return map;
  }

  updateFieldState(elementId: FormElementId, contextObjectId: Option<ObjectId>, propertyName: string, value: Option<BusinessVariable>) {
    this.formFieldsStateNonCached.updateState(elementId, contextObjectId, propertyName, value);
  }

  toFlowWithCursor() {
    return new FlowWithCursor(this.flowIdUnwrapped(), this.cursorInfo.getOrError("Cursor info not defined"));
  }
}

export class TaskModelWithPersons {
  constructor(readonly task: TaskModel,
              readonly persons: Array<BasicPersonInfo>){}

  static copy(other: TaskModelWithPersons){
    return new TaskModelWithPersons(TaskModel.copy(other.task), other.persons.map(BasicPersonInfo.copy))
  }
}



