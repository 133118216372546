import {DocumentTypeId} from "@utils";
import {
  BooleanVariable,
  BooleanVariableType,
  ComparisonOperator,
  DateTimeVariable,
  DateTimeVariableType,
  DateVariable,
  DateVariableType,
  NullVariable,
  NumberVariable,
  NumberVariableType,
  StringVariable,
  StringVariableType
} from "..";

export class SelectedDocumentType {
  constructor(public selected: SearchDocumentType) {}
}

export class SearchDocumentType {
  constructor(readonly name: string, readonly id: DocumentTypeId|null, readonly properties: Array<string>, readonly mock: boolean) {}
}
