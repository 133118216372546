import {
  ComponentIcons,
  ComponentsCommon,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel,
  ScreenWrapperViewModel,
} from "../..";
import {TextInputComponentRefState, TextInputComponentState} from "./TextInputComponentState";
import {StringVariable} from "@shared-model";
import {None, NoneSingleton, Option, Some, VariableId} from "@utils";
import {BlinkingValueComponentViewModel} from "./BlinkingComponentController";
import {
  CssBuilder,
  SwitchComponentDefinition,
  TextInputComponentDefinition,
  TextInputComponentRef,
  ValidationPreset
} from "@screen-common";

export class TextInputComponentViewModel extends ComponentViewModelWithLabel implements BlinkingValueComponentViewModel {

  override typeName = "TextInput";


  private valueBackup: string = "";

  public value: string = "";
  public changed: boolean = false;
  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false;
  public multiLine: boolean = false;
  public maxLength: string = "";

  public pattern: string|null = null;

  public unitVisible: boolean = false;

  public valueChangeListener: Option<() => void> = None();

  public css = "";
  public cssClasses: string = "";
  public outerCss = "";
  public outerCssClasses = "";

  startIcon: string|undefined = undefined;
  endIcon: string|undefined = undefined;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: TextInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: TextInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: TextInputComponentState,
              readonly refState: TextInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
             ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onFocus() {
    this.valueBackup = this.value;
  }

  onBlur() {
    if(this.valueBackup != this.value) {
      if (this.value.trim().length == 0) {
        this.componentState.updateModel(SwitchComponentDefinition.MODEL, Some(new StringVariable("")));
        this.serverModel.changeModelWithAction(this.componentRefPath(), TextInputComponentDefinition.MODEL, new StringVariable(""), TextInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(SwitchComponentDefinition.MODEL, Some(new StringVariable(this.value.trim())));
        this.serverModel.changeModelWithAction(this.componentRefPath(), TextInputComponentDefinition.MODEL, new StringVariable(this.value.trim()), TextInputComponentDefinition.ON_CHANGE);
      }
      this.valueBackup = this.value;
    }
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = CssBuilder.create();
    const outerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState) ;
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    ComponentViewModelUtils.toOuterShadowCss(outerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(cssBuilder, innerShadow);

    if(this.preview) {
      this.placeholder = "-";
      this.tooltip = None();
    } else {
      this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("");
      this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    }

    const newValue = this.componentState.model.valueOrDefault(None()).getOrElse("");
    if(this.value != newValue) {
      this.valueChangeListener.forEach(listener => listener());
    }
    this.value = newValue;
    this.multiLine = this.definition.multiLine.currentValue(() => this.componentState.multiLine).valueOrDefault(false);
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);
    this.maxLength = this.definition.maxTextLength.currentValue(() => this.componentState.maxLength).valueOrDefault(None()).map(t => ""+t).getOrElse("");
    this.pattern = this.definition.pattern.currentValue(() => this.componentState.pattern).valueOrDefault(None()).getOrNull();

    if(this.pattern == null) {
      const validationPreset = this.definition.validationPreset.currentValue(() => this.componentState.validationPreset).valueOrDefault(None()).map(ValidationPreset.byName);
      if(validationPreset.isDefined()) {
        this.pattern = ValidationPreset.patternByPreset(validationPreset.get()).getOrNull();
      }
    }

    const icon = this.definition.icon.currentValue(() => this.componentState.icon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined);
    const iconPosition: string = this.definition.iconPosition.currentValue(() => this.componentState.iconPosition).valueOrDefault("center");

    // if no icon is defined, use the startIcon deprecated property
    this.startIcon = icon !== undefined && iconPosition === "start" ? icon : (
      this.definition.startIcon.currentValue(() => this.componentState.startIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    // if no icon is defined, use the endIcon deprecated property
    this.endIcon = icon !== undefined && iconPosition === "end" ? icon : (
      this.definition.endIcon.currentValue(() => this.componentState.endIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

    this.outerCss = outerCssBuilder.toCss();
    this.outerCssClasses = outerCssBuilder.toCssClasses();
  }


  addValueChangeListener(valueChangeListener: () => void) {
    this.valueChangeListener = Some(valueChangeListener);
  }

  onHeightAdjusted(): void {
    this.shared.eventBus.componentHeightChanged();
  }
}
