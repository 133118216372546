import {DateInputComponentDefinition} from "@screen-common";
import {Option} from "@utils";
import {BusinessVariable, DateVariable} from "@shared-model";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";

export class DateInputComponentState extends LabeledScreenComponentState {
  static className = "DateInputComponentState";

  className() {
    return DateInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalDateProperty(DateInputComponentDefinition.MODEL);
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get minValue() {
    return this.properties.optionalDateProperty("minValue");
  }

  get maxValue() {
    return this.properties.optionalDateProperty("maxValue");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case DateInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof DateVariable) {
          this.properties.putValue(DateInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Date but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(DateInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: DateInputComponentState) {
    return new DateInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}

export class DateInputComponentRefState extends ScreenComponentRefState {
  static className = "DateInputComponentRefState";
  className() {
    return DateInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: DateInputComponentRefState) {
    return new DateInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}
