export function required<T>(value: T|null|undefined, valueName: string): T {
  if (value === null || value === undefined) {
    throw new Error("Value '"+valueName+"' is required at this point");
  } else {
    return value;
  }
}

export function valueOrDefault<T>(value: T|null|undefined, defaultValue: T): T {
  if (value === null || value === undefined) {
    return defaultValue;
  } else {
    return value;
  }
}

export function valueIfEmpty<T>(value: T|""|null|undefined, defaultValue: T): T {
  if (value === null || value === undefined || value === "") {
    return defaultValue;
  } else {
    return value;
  }
}

export function valueOrDefaultLazy<T>(value: T|null|undefined, defaultValue: () => T): T {
  if (value === null || value === undefined) {
    return defaultValue();
  } else {
    return value;
  }
}

export function nullIfUndefined<T>(value: T|null|undefined): T|null {
  if (value === undefined) {
    return null;
  } else {
    return value;
  }
}

export function undefinedIfNull<T>(value: T|null|undefined): T|undefined {
  if (value === null) {
    return undefined;
  } else {
    return value;
  }
}
