import {Injectable} from "@angular/core";
import {AuthenticatedHttp} from "..";
import {ContentWithAuthorization, DirectoryWithContent, FileRequestResult, FileRequestResultFactory} from "@shared";
import {FileUri, None, Option, Some, toastr, Typed} from "@utils";

class FindRootDirectoryContent {
  constructor(readonly showDeleted: boolean) {}
}

class FindDirectoryWithContent {
  constructor(readonly uri: FileUri, readonly showDeleted: boolean) {}
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsSharedService {

  private hasAccessToRootContentCache: Option<boolean> = None();

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}


  hasAccessToRootContent(onSuccess: (hasAccess: boolean) => void): void {
    if(this.hasAccessToRootContentCache.isDefined()) {
      onSuccess(this.hasAccessToRootContentCache.get());
    } else {
      this.findRootDirectoryContent(false).then((content: ContentWithAuthorization) => {
        const hasAccess = content.content.length > 0;
        this.hasAccessToRootContentCache = Some(hasAccess);
        onSuccess(hasAccess);
      });
    }
  }

  findRootDirectoryContent(showDeleted: boolean): Promise<ContentWithAuthorization> {

    return this.authenticatedHttp.postPromise<ContentWithAuthorization>("documents/structure/root-directory-content", new FindRootDirectoryContent(showDeleted)).then((data: ContentWithAuthorization) => {
      const content = ContentWithAuthorization.copy(data);
      const hasAccess = content.content.length > 0;
      this.hasAccessToRootContentCache = Some(hasAccess);
      return content;
    });

  }

  findDirectoryWithContent(uri: FileUri, showDeleted: boolean): Promise<DirectoryWithContent> {
    console.log("findDirectoryWithContent", uri, showDeleted);
    return this.authenticatedHttp.postPromise<Typed<FileRequestResult<DirectoryWithContent>>>("documents/structure/directory-with-content", new FindDirectoryWithContent(uri, showDeleted))
      .then((data: Typed<FileRequestResult<DirectoryWithContent>>) => {
        const copied = FileRequestResultFactory.copyTypedAndUnwrap<DirectoryWithContent>(data, DirectoryWithContent.copy)
        if(copied.isSuccess()) {
          return copied.asSuccess().file;
        } else {
          toastr.error(copied.errorMessage());
          throw new Error(copied.errorMessage());
        }
      })
  }




}
