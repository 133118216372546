const illegal = ["CON", "PRN", "AUX", "CLOCK$", "NUL",
"COM0", "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
"LPT0", "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"];
const regex = /^[^<>:"/\\|?*\u0000-\u001F]+$/;


export function validateFileDirectoryName(name: string): boolean {
  const trimmed = name.trim();
  return regex.test(trimmed) && trimmed.length <= 255 && illegal.indexOf(trimmed.toUpperCase()) === -1;
}

export function getFileExtension(fileName: string): string {
  const extension = getFileExtensionOrUndefined(fileName);
  if(extension) {
    return extension;
  } else {
    return "";
  }
}

export function getFileExtensionOrUndefined(fileName: string): string|undefined {
  const dotIndex = fileName.lastIndexOf(".");
  if(dotIndex > 0) {
    return fileName.substring(dotIndex + 1);
  } else {
    return undefined;
  }
}

export function getFileMainName(fileName: string): string {
  const dotIndex = fileName.lastIndexOf(".");
  if(dotIndex > 0) {
    return fileName.substring(0, dotIndex);
  } else {
    return fileName;
  }
}
