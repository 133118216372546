import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {LabeledValuesProperty, OptionalStringProperty, StringProperty} from "@screen-common";
import {Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {ScreenComponentId} from "@screen-common";

export class RadioButtonComponentRef extends InputComponentRef {
  static className = "RadioButtonComponentRef";
  className() {
    return RadioButtonComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: RadioButtonComponentRef): RadioButtonComponentRef {
    return new RadioButtonComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("RadioButtonRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = RadioButtonComponentRef.DEFAULT_PROPERTIES;
}

export class RadioButtonComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "RadioButtonComponentDefinition";
  className() {
    return RadioButtonComponentDefinition.className;
  }
  typeName() {
    return "RadioButton";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,RadioButtonComponentDefinition.DEFAULT_PROPERTIES);
  }

  get options(): LabeledValuesProperty {return this.properties.getLabeledValuesProperty("options", this.defaultPropertiesProvider)}

  get entriesLayout(): StringProperty {return this.properties.getStringProperty("entriesLayout", this.defaultPropertiesProvider)}

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  iconBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("iconBackgroundColor", defaultProvider);
  }

  static copy(other: RadioButtonComponentDefinition): RadioButtonComponentDefinition {
    return new RadioButtonComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [RadioButtonComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("RadioButtonComponentDefinition",
    (name: string) => {switch (name) {
      case "options": return LabeledValuesProperty.of(["ABC", "DEF", "GHI"]);
      case "iconBackgroundColor": return OptionalStringProperty.disabled("#000000ff");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionEntriesLayout,
    DefaultPropertyProvider.definitionBorders, DefaultPropertyProvider.definitionPaddings);
}
