import {BusinessVariable, NumberVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {NumberInputComponentDefinition} from "@screen-common";

export class NumberInputComponentState extends LabeledScreenComponentState {
  static className = "NumberInputComponentState";
  className() {
    return NumberInputComponentState.className;
  }

  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalNumberProperty(NumberInputComponentDefinition.MODEL);
  }

  get negativeTextColor() {
    return this.properties.optionalStringProperty("negativeTextColor")
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get multiLine() {
    return this.properties.booleanProperty("multiLine");
  }

  get minPrecision() {
    return this.properties.optionalNumberProperty("minPrecision");
  }

  get maxPrecision() {
    return this.properties.optionalNumberProperty("maxPrecision");
  }

  get minValue() {
    return this.properties.optionalNumberProperty("minValue");
  }

  get maxValue() {
    return this.properties.optionalNumberProperty("maxValue");
  }

  get unitName() {
    return this.properties.optionalI18nTextProperty("unitName");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get startIcon() {
    return this.properties.optionalStringProperty("startIcon");
  }

  get endIcon() {
    return this.properties.optionalStringProperty("endIcon");
  }

  get icon() {
    return this.properties.optionalStringProperty("icon");
  }

  get iconPosition() {
    return this.properties.stringProperty("iconPosition");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case NumberInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof NumberVariable) {
          this.properties.putValue(NumberInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Number but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(NumberInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: NumberInputComponentState) {
    return new NumberInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}


export class NumberInputComponentRefState extends ScreenComponentRefState {
  static className = "NumberInputComponentRefState";
  className() {
    return NumberInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: NumberInputComponentRefState) {
    return new NumberInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}

