import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {BusinessVariable, StringVariable} from "@shared-model";
import {Option} from "@utils";
import {TextInputComponentDefinition} from "@screen-common";

export class TextInputComponentState extends LabeledScreenComponentState {
  static className = "TextInputComponentState";
  className() {
    return TextInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }


  get multiLine() {
    return this.properties.booleanProperty("multiLine");
  }

  get model() {
    return this.properties.optionalStringProperty(TextInputComponentDefinition.MODEL);
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get pattern() {
    return this.properties.optionalStringProperty("pattern");
  }

  get validationPreset() {
    return this.properties.optionalStringProperty("validationPreset");
  }

  get minLength() {
    return this.properties.optionalNumberProperty("minLength");
  }

  get maxLength() {
    return this.properties.optionalNumberProperty("maxLength");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get startIcon() {
    return this.properties.optionalStringProperty("startIcon");
  }

  get endIcon() {
    return this.properties.optionalStringProperty("endIcon");
  }

  get icon() {
    return this.properties.optionalStringProperty("icon");
  }

  get iconPosition() {
    return this.properties.stringProperty("iconPosition");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case TextInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof StringVariable) {
          this.properties.putValue(TextInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type String but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(TextInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: TextInputComponentState) {
    return new TextInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class TextInputComponentRefState extends ScreenComponentRefState {
  static className = "TextInputComponentRefState";
  className() {
    return TextInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: TextInputComponentRefState) {
    return new TextInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
