import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {$$, toastr} from "@utils";

@Component({
  selector: 'my-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent implements OnInit {
  @Input() name: string = "";

  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  @Input() disabled: boolean = false;
  @Input() label: string = "";
  @Input() invalid: boolean = false;
  @Input() clickInterval: number = 0;
  @Input() contentClass: string = "";
  @Output() changeWithShift: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeNoShift: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() small: boolean = false;

  @Input() myTabindex: number|null = null;

  private lastToggle = 0;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      $$(this.viewContainerRef).addClass("animationEnabled"); // that prevent unwanted animation on init
    }, 500);
  }

  onClicked($event: MouseEvent|null) {

    const now = new Date().getTime();

    if(now - this.lastToggle > this.clickInterval) {
      if(!this.disabled) {
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
        // if(this.fastOnChange) {
        //   this.fastOnChange(this.marked);
        // }
        if($event && $event.shiftKey) {
          this.changeWithShift.emit(this.checked);
        }
        if($event && !$event.shiftKey) {
          this.changeNoShift.emit(this.checked);
        }
      }
      this.lastToggle = now;
    }

  };

}
