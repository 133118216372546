import {
  Component,
  ComponentRef, createComponent,
  createNgModule, EventEmitter,
  Injector,
  Input, OnChanges, OnInit, Output, SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {InputSuggestionProvider, StyleSet, StylesResolver, Translation, TranslationsResolver} from "@shared";
import {ActivatedRoute} from "@angular/router";
import {FileUri, mySetTimeout, toastr} from "@utils";

@Component({
  selector: "my-email-viewer",
  templateUrl: "./email-viewer.component.html"
})
export class EmailViewerComponent implements OnChanges {


  @Input() emailUri?: FileUri;

  @Input() labelsSuggestionProvider?: InputSuggestionProvider;

  @Input() readOnly: boolean = false;
  @Input() fullScreen: boolean = false;
  @Input() usageAuthorized: boolean = false;
  @Input() administrationAuthorized: boolean = false;

  @Output() closeRequested = new EventEmitter<void>();

  @ViewChild(TemplateRef, {read: ViewContainerRef}) private templateViewContainerRef: ViewContainerRef|undefined = undefined;

  private instance: Promise<ComponentRef<any>>|undefined = undefined;

  constructor(readonly route: ActivatedRoute,
              private injector: Injector,
              private stylesResolver: StylesResolver,
              private translationsResolver: TranslationsResolver) {}


  ngOnChanges() {
    if(this.instance === undefined) {
      this.createComponent();
    } else {
      this.instance.then(i => {
        this.assignInputs(i, true);
      });
    }
  }

  private createComponent() {
    this.instance = new Promise((resolve, reject) => {

      const module = import('../../../modules/mailbox.module/mailbox.module');
      const styles = this.stylesResolver.loadStyles(StyleSet.mailbox);
      const translations = this.translationsResolver.loadTranslations(Translation.mailbox);

      Promise.all([module, styles, translations]).then((values) => {

        const MailboxModule = values[0].MailboxModule;

        if(this.templateViewContainerRef === undefined) {
          throw new Error("templateViewContainerRef is undefined");
        } else {

          const moduleRef = createNgModule(MailboxModule, this.injector)

          this.templateViewContainerRef.clear();

          const instance: ComponentRef<any> = this.templateViewContainerRef.createComponent(moduleRef.instance.getEmailPreviewComponent(), {ngModuleRef: moduleRef});

          if (instance === undefined) {
            reject(new Error("Failed to create component"));
          } else {
            this.assignInputs(instance, false);
            resolve(instance);
          }
        }


      });
    });
  }

  private assignInputs(instance: ComponentRef<any>, change: boolean) {
    if (instance === undefined) {
      throw new Error("instance is undefined");
    } else {

      instance.instance.emailUri = this.emailUri;
      instance.instance.labelsSuggestionProvider = this.labelsSuggestionProvider;
      instance.instance.readOnly = this.readOnly;
      instance.instance.fullScreen = this.fullScreen;
      instance.instance.usageAuthorized = this.usageAuthorized;
      instance.instance.administrationAuthorized = this.administrationAuthorized;
      instance.instance.closeRequested = this.closeRequested;
      if (change) {
        mySetTimeout(() => {
          instance.changeDetectorRef.detectChanges();
        });
      }
    }
  }

}
