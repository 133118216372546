import {md5} from "./Crypto";

const gravatarCache: {[email: string]: string} = {};

export function gravatarHash(email: string): string {
  if(email) {
    const fromCache = gravatarCache[email];
    if(fromCache) {
      return fromCache;
    } else {
      const toCache = md5(email.trim().toLocaleLowerCase());
      gravatarCache[email] = toCache;
      return toCache;
    }
  } else {
    return "";
  }
}
