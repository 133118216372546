import {Option, VariableId} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  HeaderPropertiesState,
  LayoutsPropertiesState,
  OptionalContextComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState
} from "../..";

export class TabState {

  constructor(readonly properties: PropertiesStateHolder,
              readonly id: number) {
  }


  get header() {
    return this.properties.i18nTextProperty("header");
  }

  get enabled() {
    return this.properties.booleanProperty("enabled");
  }


  get layoutsState() {
    return new LayoutsPropertiesState("tab|"+this.id+"|", this.properties);
  }

  get paddingsState() {
    return new PaddingsPropertiesState("tab|"+this.id+"|", this.properties);
  }

  get backgroundsState() {
    return new BackgroundsPropertiesState("tab|"+this.id+"|", this.properties);
  }

  get contentTextSize() {return this.properties.optionalStringProperty("tab|"+this.id+"|textSize")}
  get contentTextColor() {return this.properties.optionalStringProperty("tab|"+this.id+"|textColor")}
  get contentTextFont() {return this.properties.optionalStringProperty("tab|"+this.id+"|textFont")}


}

export class  TabsContainerState extends OptionalContextComponentState {
  static className = "TabsContainerState";
  className() {
    return TabsContainerState.className;
  }

  constructor(public innerContext: Option<VariableId>,
              readonly properties: PropertiesStateHolder,
              override readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);
  readonly headerState = new HeaderPropertiesState(this.properties);

  updateInnerContext(innerContext: Option<VariableId>): void {
    this.innerContext = innerContext;
  }

  static copy(other: TabsContainerState) {
    return new TabsContainerState(Option.copy(other.innerContext).map(VariableId.copy),
      PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class TabsContainerRefState extends ScreenComponentRefState {
  static className = "TabsContainerRefState";
  className() {
    return TabsContainerRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: TabsContainerRefState) {
    return new TabsContainerRefState(PropertiesStateHolder.copy(other.properties));
  }

}
