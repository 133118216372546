import {ApplicationId, Option} from "@utils";

export interface ApplicationIcon {
  icon: string;
  colorNo: number;
}

export interface ApplicationIconDefinition {
  label: string;
  value: string;
  icon: string;
}

export class ApplicationIcons {

  static empty = {icon: "mi-plus", colorNo: 0};
  static global: ApplicationIcon = {icon: "mi-process", colorNo: 1};

  static readonly icons: Array<ApplicationIconDefinition> = [
    {label: "Archive", value: "archive", icon: "mi-archive"},
    {label: "Alarm-clock", value: "alarm-clock", icon: "mi-alarm-clock"},
    {label: "Balance", value: "balance", icon: "mi-balance-scale-left"},
    {label: "Ballot", value: "ballot", icon: "mi-ballot"},
    {label: "Bank", value: "bank", icon: "mi-bank"},
    {label: "Barchart", value: "barchart", icon: "mi-barchart"},
    {label: "Book", value: "book", icon: "mi-book-alt"},
    {label: "Bulb", value: "bulb", icon: "mi-bulb"},
    {label: "Calculator", value: "calculator", icon: "mi-calculator"},
    {label: "Camera", value: "camera", icon: "mi-camera"},
    {label: "Chat", value: "chat", icon: "mi-chat"},
    {label: "Check", value: "check", icon: "mi-checkbox"},
    {label: "Fingerprint", value: "fingerprint", icon: "mi-fingerprint"},
    {label: "Globe", value: "globe", icon: "mi-globe"},
    {label: "Handshake", value: "handshake", icon: "mi-handshake"},
    {label: "Hashtag", value: "hashtag", icon: "mi-hashtag"},
    {label: "Key", value: "key", icon: "mi-key"},
    {label: "Life Ring", value: "life-ring", icon: "mi-life-ring"},
    {label: "Mail", value: "mail", icon: "mi-mail"},
    {label: "Map", value: "map", icon: "mi-map"},
    {label: "Microphone", value: "microphone", icon: "mi-microphone"},
    {label: "Money", value: "money", icon: "mi-money"},
    {label: "Mouse", value: "mouse", icon: "mi-mouse"},
    {label: "Phone", value: "phone", icon: "mi-phone-office"},
    {label: "Plane", value: "plane", icon: "mi-plane-alt"},
    {label: "Shop", value: "shop", icon: "mi-shop"},
    {label: "Shopping Cart", value: "shopping-cart", icon: "mi-shopping-cart"},
    {label: "Sun", value: "sun", icon: "mi-sun"},
    {label: "Treatment", value: "treatment", icon: "mi-treatment"},
    {label: "Umbrella", value: "umbrella", icon: "mi-umbrella"},
    {label: "World", value: "world", icon: "mi-world"},
    {label: "Puzzle", value: "puzzle", icon: "mi-puzzle-piece"}
  ];

  static findIcon(iconCode: string): Option<ApplicationIconDefinition> {
    return Option.of(ApplicationIcons.icons.find(icon => icon.value === iconCode));
  }

  static getIconClass(iconCode: string): string {
    return ApplicationIcons.findIcon(iconCode).getOrElse(ApplicationIcons.icons[0]).icon;
  }

  static getApplicationOptionIcon(applicationId: Option<ApplicationId>, iconCode: string, colorId: number): ApplicationIcon {
    if(applicationId.isDefined()) {
      return ApplicationIcons.getApplicationIcon(applicationId.get(), iconCode, colorId);
    } else {
      return ApplicationIcons.global;
    }
  }

  static getApplicationIcon(applicationId: ApplicationId, iconCode: string, colorId: number): ApplicationIcon {
    return {
      icon: ApplicationIcons.getIcon(iconCode, applicationId),
      colorNo: ApplicationIcons.getColor(colorId, applicationId)
    }

  }

  static getColor(colorId: number, applicationId: ApplicationId): number {
    if(colorId > 0 && colorId <= 16) {
      return colorId;
    } else {
      const applicationChar = applicationId.id.charCodeAt(0);
      return applicationChar % 16 + 1;
    }
  }

  static getIcon(iconCode: string, applicationId: ApplicationId): string {
    const icon = ApplicationIcons.icons.find(icon => icon.value === iconCode);

    if(icon) {
      return icon.icon;
    } else {
      const applicationChar = applicationId.id.charCodeAt(0);
      return ApplicationIcons.icons[applicationChar % ApplicationIcons.icons.length].icon;
    }
  }

  static randomColorCode(): number {
    return Math.floor(Math.random() * 16) + 1;
  }

  static randomIconCode(): string {
    return ApplicationIcons.icons[Math.floor(Math.random() * ApplicationIcons.icons.length)].value;
  }
}
