import {
  AggregateId,
  AggregateVersion,
  FileUri,
  GroupId,
  LocalDateTime,
  Option,
  OrganizationNodeId,
  PersonId
} from "@utils";
import {MultiFactorAuthenticationMode} from "@shared-model";

export class CreatePerson {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly email: string,
              readonly position: string,
              readonly parentId: OrganizationNodeId,
              readonly supervisorId: Option<PersonId>,
              readonly avatar: Option<FileUri>,
              readonly phone: string,
              readonly internalAuthenticationEnabled: boolean,
              readonly externalAuthenticationEnabled: boolean,
              readonly mfa: MultiFactorAuthenticationMode) {}
}

export class CreateApiPerson {
  constructor(readonly name: string,
              readonly parentId: OrganizationNodeId) {}
}

export class GenerateApiKey {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion) {}
}

export class DeleteAPIToken {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion,
              readonly tokenId: number) {}
}

export class UpdateAPITokenValidUntil {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion,
              readonly tokenId: number,
              readonly valid: Option<LocalDateTime>) {}
}

export class RegularPersonInfo {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly position: string,
              readonly userEmail: string,
              readonly userPhone: string,
              readonly internalAuthenticationEnabled: boolean,
              readonly externalAuthenticationEnabled: boolean) {}
}

export class ApiPersonInfo {
  constructor(readonly name: string) {}
}

export class UpdatePersonInfo {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly parentId: OrganizationNodeId,
              readonly supervisorId: Option<PersonId>,
              readonly active: boolean,
              readonly avatar: Option<FileUri>,
              readonly regularInfo: Option<RegularPersonInfo>,
              readonly apiInfo: Option<ApiPersonInfo>,
              readonly newMfaMode: MultiFactorAuthenticationMode) {}
}

export class ChangePersonParent {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion,
              readonly parentId: OrganizationNodeId) {}
}

export class AddPersonToGroup {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {}
}

export class RemovePersonFromGroup {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {}
}

export class DeletePerson {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion) {}
}

export class RestorePerson {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion) {}
}

export class AnonymizePerson {
  constructor(readonly aggregateId: AggregateId, readonly expectedVersion: AggregateVersion) {}
}
