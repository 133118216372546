import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ApplicationsSharedService} from "@shared-model";
import {__, ___, ApplicationId} from "@utils";
import {Subject, Subscription} from "rxjs";
import {ApplicationSummaryViewModel} from "./application-summary-view.model";

@Component({
  selector: "my-applications-menu",
  templateUrl: "./applications-menu.component.html",
})
export class ApplicationsMenuComponent implements OnInit, OnDestroy {

  allApplications: Array<ApplicationSummaryViewModel> = [];
  nonFavoriteApplications: Array<ApplicationSummaryViewModel> = [];
  favoriteApplications: Array<ApplicationSummaryViewModel> = [];

  @Output() applicationSelected = new EventEmitter<ApplicationId | null>();
  @Input() contentAvailableNotifier?: Subject<boolean>;
  private subscription: Subscription | undefined;

  constructor(readonly applicationsSharedService: ApplicationsSharedService) {

  }

  ngOnInit(): void {

    this.subscription = this.applicationsSharedService.getPinnedObservable().subscribe((pinned) => {
      this.allApplications.forEach(app => {
        app.pinnedToMenu = __(pinned).exists(id => id.id.id == app.id.id);
      });
    });

    this.applicationsSharedService.loadUsableApplications().then((apps) => {

      this.applicationsSharedService.loadFavoriteApplications((favorites) => {

        this.applicationsSharedService.loadPinnedApplications((pinned) => {

          this.allApplications = ___(apps).map(a => {
            const app = new ApplicationSummaryViewModel(a.id, a.identifier, a.name, a.categoryId, a.description, a.iconCode, a.colorId);
            app.favorite = __(favorites).exists(id => id.id == a.id.id);
            app.pinnedToMenu = __(pinned).exists(id => id.id.id == a.id.id);
            return app;
          }).sortByAlphanumeric(a => a.name).value();
          this.initLists();
          if(this.contentAvailableNotifier) {
            this.contentAvailableNotifier.next(true);
          }
        });
      });
    });
  }

  selectApplication(application: ApplicationSummaryViewModel) {
    this.applicationSelected.emit(application.id);
  }

  onApplicationsClicked() {
    this.applicationSelected.emit(null);
  }

  togglePinToMenu(application: ApplicationSummaryViewModel) {
    application.pinnedToMenu = !application.pinnedToMenu;
    if(application.pinnedToMenu) {
      this.applicationsSharedService.addPinnedApplication(application.id);
    } else {
      this.applicationsSharedService.removePinnedApplication(application.id);
    }
  }

  toggleFavorite(application: ApplicationSummaryViewModel) {
    application.favorite = !application.favorite;
    this.initLists();
    if(application.favorite) {
      this.applicationsSharedService.addFavoriteApplication(application.id);
    } else {
      this.applicationsSharedService.removeFavoriteApplication(application.id);
    }
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initLists() {
    this.nonFavoriteApplications = this.allApplications.filter(a => !a.favorite);
    this.favoriteApplications = this.allApplications.filter(a => a.favorite);
  }

}
