import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {i18n} from "@utils";
import {PasswordMeterHelper} from "./PasswordMeterHelper";

class PasswordMeterValue {
  constructor(readonly score: number, readonly color: string, readonly translation: string) {}

  static veryWeak = new PasswordMeterValue(20, "#ED5565", "password_strength_VeryWeak");
  static weak = new PasswordMeterValue(40, "#ffae38", "password_strength_Weak");
  static good = new PasswordMeterValue(60, "#1bba9a", "password_strength_Good");
  static strong = new PasswordMeterValue(80, "#1bba9a", "password_strength_Strong");
  static veryStrong = new PasswordMeterValue(100, "#1bba9a", "password_strength_VeryStrong");

  static list = [PasswordMeterValue.veryWeak, PasswordMeterValue.weak, PasswordMeterValue.good, PasswordMeterValue.strong, PasswordMeterValue.veryStrong];
}

@Component({
  selector: 'my-password-meter',
  templateUrl: './password-meter.component.html',
  providers: [PasswordMeterHelper],
})
export class PasswordMeterComponent implements OnInit, OnChanges {

  @Input() password: string | undefined;
  @Input() validatePasswordPolicy: ((onResult: (passwordValid: boolean) => void) => void) | undefined;

  public passwordStrengthScore!: number;
  public currentPasswordMeterValue!: PasswordMeterValue | undefined;

  constructor(readonly passwordMeterHelper: PasswordMeterHelper) {}

  ngOnInit() {
    this.passwordStrengthScore = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.password) {
      this.calculatePasswordStrength(this.password.trim());

      if(this.passwordStrengthScore) {
        this.currentPasswordMeterValue = PasswordMeterValue.list.find(p => {
          const score = this.passwordStrengthScore > 100 ? 100 : this.passwordStrengthScore;
          return score <= p.score;
        });
      } else {
        this.currentPasswordMeterValue = PasswordMeterValue.veryWeak;
      }

    }
  }

  calculatePasswordStrength(password: string) {
    if(this.validatePasswordPolicy) {
      this.validatePasswordPolicy((passwordValid: boolean) => {
        if (passwordValid) {
          this.passwordStrengthScore = this.passwordMeterHelper.calculatePasswordStrength(password.trim());
        } else {
          this.passwordStrengthScore = 0;
        }
      });
    }
  }

}


