<div class="simplePage registerOrganizationPage" [class.mobile]="responsive.mobile">
  <header>
    <my-draw-in-logo [withName]="true"/>
  </header>
  <main>
    <my-register-organization-form />
  </main>
  <footer>
    <div class="settings">
      <button
        #viewParamsButton
        (click)="viewParamsMenuVisible = !viewParamsMenuVisible"
        class="theme-button-flat-small">{{'common_accessibility' | i18n}}
      </button>

      <div class="theme-action-button-separator"></div>
      <my-language-list></my-language-list>
    </div>
  </footer>
</div>
<my-drop-menu cssClass="theme-drop-menu viewParamsMenu"
              [anchor]="viewParamsButton"
              [(visible)]="viewParamsMenuVisible"
              horizontalPosition="end">
  <div *ngIf="viewParamsMenuVisible" class="viewParamsMenuContainer">
    <i class="viewParamsIcon mi-eclipse-alt"></i>
    <span class="viewParamsEntryText  theme-label">{{'common_site_theme' | i18n}}</span>
    <my-theme-selector class="alignEnd"/>

    <i class="viewParamsIcon mi-text"></i>
    <span class="viewParamsEntryText theme-label">{{'common_site_fontSize' | i18n}}</span>
    <my-font-size-selector class="alignEnd"/>

  </div>
</my-drop-menu>
