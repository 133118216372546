import {AggregateId, AggregateVersion, DepartmentId, GroupId, None, Option, OrganizationNodeId, PersonId} from "@utils";
import {DepartmentCalendar} from "@shared";

export class BasicDepartmentInfo {

  constructor(readonly id: AggregateId,
              readonly version: AggregateVersion,
              readonly name: string,
              readonly directorId: Option<PersonId>,
              readonly external: boolean,
              readonly deleted: boolean) {}

  static copy(other: BasicDepartmentInfo) {
    return new BasicDepartmentInfo(AggregateId.copy(other.id), AggregateVersion.copy(other.version), other.name,
      Option.copy(other.directorId).map(PersonId.of), other.external, other.deleted);
  }

}

export class ExternalDepartmentInfo {
  constructor(readonly externalId: string) {}

  static copy(other: ExternalDepartmentInfo) {
    return new ExternalDepartmentInfo(other.externalId);
  }
}

export class DepartmentInfo {

  constructor(readonly id: AggregateId,
              public version: AggregateVersion,
              readonly organizationId: AggregateId,
              readonly parentId: Option<DepartmentId>,
              readonly name: string,
              readonly directorId: Option<PersonId>,
              readonly calendar: Option<DepartmentCalendar>,
              readonly externalInfo: Option<ExternalDepartmentInfo>,
              readonly groups: Array<GroupId>,
              readonly deleted: boolean) {}

  static copy(other: DepartmentInfo) {
    return new DepartmentInfo(AggregateId.copy(other.id), AggregateVersion.copy(other.version), other.organizationId, Option.copy(other.parentId).map(DepartmentId.copy), other.name, Option.copy(other.directorId).map(PersonId.of), Option.copy(other.calendar).map(DepartmentCalendar.copy), Option.copy(other.externalInfo).map(ExternalDepartmentInfo.copy), other.groups.map(GroupId.copy), other.deleted);
  }
}
