import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {CalendarComponentViewModel} from "./CalendarComponentViewModel";
import {$$} from "@utils";
import {CalendarDateSelectionDrag} from "./CalendarDateSelectionDrag";

@Component({
  selector: 'my-calendar-component',
  templateUrl: './calendar-component.component.html',
  host: {
    "[class]": "'componentBody CalendarComponent ' + viewModel.customCssClass",
    "[style]": "viewModel.css"
  }
})
export class CalendarComponentComponent implements OnInit, OnDestroy {
  @Input({required: true}) viewModel!: CalendarComponentViewModel;
  private drag?: CalendarDateSelectionDrag;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const elements = $$(this.viewContainerRef).findOrError(".CalendarComponent");
      this.drag = new CalendarDateSelectionDrag(elements, elements, this.viewModel.calendarEventBus);
      this.drag.init();
    }, 1000)
  }

  ngOnDestroy(): void {
    if(this.drag) {
      this.drag.destroy();
    }
  }
}
