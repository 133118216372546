import {Component, Input} from "@angular/core";
import {ButtonComponentViewModel} from "./ButtonComponentViewModel";

@Component({
  selector: 'button[button-component]',
  templateUrl: './button-component.component.html',
  host: {
    "[class]": "'componentBody ButtonComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[class.inProgress]": "viewModel.inProgress",
    "[disabled]": "viewModel.disabled",
    "(click)": "viewModel.onClick()",
    "[my-tooltip]": "{text: viewModel.tooltipText, enabled: viewModel.tooltipEnabled}"
  }
})
export class ButtonComponentComponent {
  @Input({required:true}) viewModel!: ButtonComponentViewModel;

}
