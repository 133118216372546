import {
  $$Element,
  __,
  DragBehavior,
  getElementPositionAndSize,
  getElementPosition,
  None,
  Option,
  PositionXY,
  RectXY, runInAngularZone
} from "@utils";
import {CalendarComponentViewModel} from "./CalendarComponentViewModel";
import {CalendarEventBus} from "@shared";


class ElementWithPosition {
  constructor(readonly id: number,
              readonly rect: RectXY) {
  }
}

export class CalendarDateSelectionDrag extends DragBehavior<CalendarComponentViewModel> {

  private positions: Array<ElementWithPosition> = [];

  private startElement: Option<number> = None();
  private endElement: Option<number> = None();

  constructor(selection: $$Element, readonly calendarSelection: $$Element, readonly eventBus: CalendarEventBus) {
    super(selection);
  }

  override dragOrigin(draggedElement: $$Element, eventPosition: PositionXY, model: CalendarComponentViewModel): { x: number; y: number } {
    const nodePosition = getElementPosition(draggedElement);
    console.log("Element position", nodePosition.x + " " + nodePosition.y);
    console.log("dragOrigin", eventPosition.x + " " + eventPosition.y);

    this.positions = [];
    const that = this;
    this.calendarSelection.findAll(".selectablePeriod").forEach((e) => {
      const id = that.elementId(e);
      that.positions.push(new ElementWithPosition(id, getElementPositionAndSize(e)));
    });

    this.startElement = this.findElementInPosition(eventPosition);
    if (this.startElement.isDefined()) {
      this.eventBus.periodsSelected(this.startElement.get(), this.startElement.get());
    }

    return eventPosition;
  }

  override dragStarted(draggedElement: $$Element, eventPosition: PositionXY, model: CalendarComponentViewModel): void {
    // all in dragOrigin so it wil work just after mouse pressed, before move
    console.log("eventPosition", eventPosition.x + " " + eventPosition.y, this.startElement);
  }

  override dragged(draggedElement: $$Element, eventPosition: PositionXY, model: CalendarComponentViewModel): void {

    if (this.startElement.isDefined()) {

      const endElement = this.findElementInPosition(eventPosition);

      if (this.endElement.notEquals(endElement)) {
        this.endElement = endElement;
        runInAngularZone(() => {
        if (endElement.isDefined()) {

          if (this.startElement.get() <= endElement.get()) {
            this.eventBus.periodsSelected(this.startElement.get(), endElement.get());
          } else {
            this.eventBus.periodsSelected(endElement.get(), this.startElement.get());
          }

          } else {
            this.eventBus.periodSelectionCleared();
            console.log("Select none");
          }
        });
      }

    }

  }

  override dragEnded(draggedElement: $$Element, eventPosition: PositionXY, model: CalendarComponentViewModel): void {
    if (this.startElement.isDefined()) {
      const endElement = this.findElementInPosition(eventPosition);
      if (endElement.isDefined()) {

        if (this.startElement.get() <= endElement.get()) {
          this.eventBus.periodsSelected(this.startElement.get(), endElement.get());
        } else {
          this.eventBus.periodsSelected(endElement.get(), this.startElement.get());
        }
        console.log("Finish with selection " + this.startElement.get() + " to " + endElement.get());
      } else {
        this.eventBus.periodSelectionCleared();
        console.log("Finish no selection");
      }
    }
    this.startElement = None();
    this.endElement = None();
    this.positions = [];
  }

  override clicked(element: $$Element, eventPosition: PositionXY, model: CalendarComponentViewModel): void {
    // do nothing
  }


  private findElementInPosition(eventPosition: PositionXY) {
    return __(this.positions).find(e => e.rect.contains(eventPosition)).map(e => e.id);
  }


  private elementId(element: $$Element): number {
    return parseInt(element.getAsElement().className.split(/\s/).filter(c => c.indexOf("id") == 0)[0].substring(2))
  }


}
