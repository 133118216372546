import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TaskStatus} from "../TaskModel";
import {i18n} from "@utils";

export interface StatusOption {
  name: string;
  value: TaskStatus;
  disabled: boolean;
  disabledMessage: string|undefined;
}

@Component({
  selector: "my-task-status-selector[status][statuses]",
  templateUrl: "./task-status-selector.component.html"
})
export class TaskStatusSelectorComponent {

  opened: boolean = false;
  statusName: string = "";
  @Input() disabled: boolean = false;

  options: Array<StatusOption> = [];

  private _status: TaskStatus = TaskStatus.new;

  @Input() set statuses(statuses: Array<TaskStatus>) {
      this.options = statuses.map(s => {
        return {name: i18n("task_status_"+s.name), value: s, disabled: false, disabledMessage: undefined};
      });
  };

  @Input() set status(status: TaskStatus) {
    this._status = status;
    this.statusName = i18n("task_status_"+this._status.name);
  };
  @Output() statusChange = new EventEmitter<TaskStatus>();


  get status(): TaskStatus {
    return this._status;
  }

  openSelector() {
    if(!this.opened && !this.disabled) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  select(option: StatusOption) {
    this._status = option.value;
    this.statusChange.emit(option.value);
    this.opened = false;
  }
}
