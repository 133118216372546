<div class="sectionHeader {{viewModel.headerCssClasses}}" *ngIf="viewModel.headerVisible" [style]="viewModel.headerCss">{{viewModel.header}}</div>
<div class="tableEntriesScroll">
  <my-sortable class="sortableEntries" [disabled]="!viewModel.canMoveEntries" dragHandleSelector=".dragHandle"
               elementSelector=".tableEntryRow" [reinitDrag]="viewModel.reinitDrag" (moved)="viewModel.moveEntry($event.fromIndex, $event.toIndex)"
               [setDraggedElementBackground]="true">

    <div class="tableEntries childrenPlain layout_{{viewModel.layoutClass}} layoutAlign_{{viewModel.layoutAlignClass}} layoutStretch_{{viewModel.layoutStretchClass}} {{viewModel.childrenPlainCssClasses}}"
       style="{{viewModel.tableCss}} {{viewModel.childrenPlainCss}}">
    <div class="tableEntryHeader" *ngIf="viewModel.rows.length > 0">
      <div class="clickableGlassCell" *ngIf="viewModel.clickableEntry"></div>
      <div class="dragHandleHeader" *ngIf="viewModel.canMoveEntries"></div>
      <div class="tableColumnHeader" *ngFor="let column of viewModel.columns" style="{{column.css}}">
        <div class="columnLabelText" *ngIf="column.label.length > 0">{{column.label}}</div>
      </div>
      <div class="deleteRowColumnHeader" *ngIf="viewModel.canDeleteEntries"></div>
    </div>
      <div class="tableEntryRow" *ngFor="let row of viewModel.rows trackBy: trackByIndex"
         [ngClass]="{clickable: viewModel.clickableEntry}" (click)="viewModel.onRowClick(row)">
      <div class="dragHandle" *ngIf="viewModel.canMoveEntries"><i class="mi-move-alt"></i></div>
      <div class="clickableGlassCell" *ngIf="viewModel.clickableEntry"><div class="clickableGlass"></div></div>
      <div class="tableEntryCell" *ngFor="let cell of row.cells trackBy: trackByIndex">
        <div class="ScreenComponent ScreenComponent{{cell.child.id}} {{cell.child.errorClass}}"
             [class.nonVisibleComponent]="cell.child.nonVisibleComponent"
             *ngIf="cell.child.uncoveredAndVisible">

          <div class="componentBodyContainer labelPosition_{{cell.child.label?.positionClass}}">

            <div class="componentLabel" *ngIf="viewModel.label && viewModel.label.visible">
              <component-label [viewModel]="viewModel.label" />
            </div>


            <ng-container *ngComponentOutlet="children.componentOf(cell.child);inputs:children.inputsOf(cell.child)" />

            <div class="errorsInfo" *ngIf="cell.child.error">
              <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: cell.child.errorText, position: 'top'}"></i>
            </div>

            <div class="inProgressGlass" *ngIf="cell.child.inProgress"></div>
<!--                          SHARED -->

          </div>

        </div>
      </div>
      <div class="deleteRow" *ngIf="viewModel.canDeleteEntries">
        <button class="deleteButton" (click)="viewModel.deleteEntry(row)"><i class="mi-trash"></i></button>
      </div>
    </div>
  </div>
  </my-sortable>

  <div class="addButtonPanel" *ngIf="viewModel.canAddEntry">
    <button class="addButton" (click)="viewModel.addEntry()"><i class="mi-plus"></i>{{viewModel.addLabel}}</button>
  </div>

</div>

