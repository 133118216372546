import {createNgModule, Injectable, Injector} from "@angular/core";
import {
  AnyFlowId,
  ApplicationId,
  ApplicationIdentifier,
  Either,
  FlowId,
  Option,
  Right,
  ScreenIdentifier,
  ScreenInstanceId
} from "@utils";
import {ProcessNodeId, TaskIdentifier} from "@shared-model";
import {ScreenComponentRefIdentifier} from "./ScreenMessagesApi";
import {ApplicationComponentRef} from "./ScreenApi";


@Injectable({ providedIn: 'root' })
export class ScreenInstanceSharedWrapperService {

  constructor(private readonly injector:Injector) {}

  runAndPreload(application: Either<ApplicationId, ApplicationIdentifier>, screen: ApplicationComponentRef, params: Array<[string, string]>,
                onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void,
                onError: (error: string) => void): void {
    import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      service.runAndPreload(application, screen, params, onSuccess, onError);
    });
  }

  preloadInstance(instanceId: ScreenInstanceId,
                  onSuccess: () => void): void {
    import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      service.preloadInstance(instanceId, onSuccess);
    });
  }


  runAndGet(application: Either<ApplicationId, ApplicationIdentifier>, screen: ApplicationComponentRef, params: Array<[string, string]>,
            onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void,
            onError: (error: string) => void): void {
    import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      service.runAndGet(application, screen, params, onSuccess, onError);
    });
  }

  runForFlowTaskHistory(flowId: AnyFlowId, nodeId: ProcessNodeId, historyIndex: number, screen: Option<ScreenIdentifier>): Promise<ScreenInstanceId> {
    return import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      return service.runForFlowTaskHistory(flowId, nodeId, historyIndex, screen);
    });
  }

  runForFlow(flowId: FlowId, rootComponent: ScreenComponentRefIdentifier): Promise<ScreenInstanceId> {
    return import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      return service.runForFlow(flowId, rootComponent);
    });
  }

  runForFlowTask(taskId: TaskIdentifier, preload: boolean): Promise<ScreenInstanceId> {
    return import('../../../modules/screen.module/screen.module').then(({ScreenModule}) => {
      const service = createNgModule(ScreenModule, this.injector).instance.getScreenInstanceService();
      return service.runForFlowTask(taskId, preload);
    });
  }

}
