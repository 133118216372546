import {BusinessVariable, IntervalVariable} from "@shared-model";
import {DurationInputComponentDefinition} from "@screen-common";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";

export class DurationInputComponentState extends LabeledScreenComponentState {
  static className = "DurationInputComponentState";

  className() {
    return DurationInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalDurationProperty(DurationInputComponentDefinition.MODEL);
  }

  override get tooltip() {
    return this.properties.optionalI18nTextProperty("tooltip");
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get hoursInDay() {
    return this.properties.numberProperty("hoursInDay");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case DurationInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof IntervalVariable) {
          this.properties.putValue(DurationInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type 'Duration' but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(DurationInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: DurationInputComponentState) {
    return new DurationInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class DurationInputComponentRefState extends ScreenComponentRefState {
  static className = "DurationInputComponentRefState";
  className() {
    return DurationInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: DurationInputComponentRefState) {
    return new DurationInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
