<div class="title">
  <div>{{'common_datatable_form_title' | i18n}}</div>
  <button class="theme-button-close" (click)="onClose.emit()"><i class="mi-delete"></i></button>
</div>

<my-scroll class="formContent" contentClass="formScrollContent">
  <my-metadata *ngIf="rowData" [data]="rowData" [attributes]="rowAttributes" [validateType]="false" [showLabels]="true"
               [editMode]="editMode" [showNames]="false" [sortMainAttributes]="false" (attributeChange)="onValueChanged($event.path, $event.value)" />

  <div class="noRow" *ngIf="!rowData">
    {{'common_datatable_no_row_selected' | i18n}}
  </div>
</my-scroll>
