export class ParamApi {

  constructor(readonly name: string,
              readonly typeName: string) {
  }

  static copy(other: ParamApi) {
    return new ParamApi(other.name, other.typeName);
  }
}

export class FunctionApi {
  constructor(readonly name: string,
              readonly returnTypeName: string,
              readonly params: ParamApi[],
              readonly doc: string) {
  }

  static copy(other: FunctionApi) {
    return new FunctionApi(other.name,
      other.returnTypeName,
      other.params.map(p => ParamApi.copy(p)),
      other.doc);
  }

}

export class ConstantApi {
  constructor(readonly name: string,
              readonly typeName: string) {
  }

  static copy(other: ConstantApi) {
    return new ConstantApi(other.name,
      other.typeName);
  }
}

export class VariableApi {
  constructor(readonly name: string,
              readonly typeName: string) {
  }

  static copy(other: ConstantApi) {
    return new VariableApi(other.name,
      other.typeName);
  }
}

export class SuggestionsApi {
  constructor(readonly functions: Array<FunctionApi>,
              readonly constants: Array<ConstantApi>,
              readonly variables: Array<VariableApi>) {
  }

  static copy(other: SuggestionsApi) {
    return new SuggestionsApi(other.functions.map(FunctionApi.copy),
      other.constants.map(ConstantApi.copy),
      other.variables.map(VariableApi.copy));
  }
}

