<div class="simplePage emailConfirmationPage" [responsive-mobile-class]="'mobile'">

  <header class="header">
    <my-draw-in-logo [withName]="true"></my-draw-in-logo>
  </header>

  <main>

    <div *ngIf="setPassword" class="formContainer">
      <div class="formHeader">{{'emailConfirm_form_title' | i18n}}</div>
      <my-password-set-form (passwordSubmittedAndValidated)="setPasswordAndConfirmEmail($event)"/>
    </div>

    <div *ngIf="!finished && !setPassword" class="statusContainer">
      <span class="statusWarning">{{'emailConfirm_form_waiting' | i18n}}</span>
    </div>

    <div *ngIf="finished && !success" class="statusContainer">
      <span class="statusError">{{errorMessage}}</span>
    </div>

    <div *ngIf="!setPassword && finished && success && isOrganization && confirmResponse.isDefined()">
      <div class="statusContainer">
        <span class="statusSuccess">{{'emailConfirm_form_success' | i18n}}</span>
      </div>
      <my-login-form [defaultLogin]="confirmResponse.get().userEmail" />
    </div>

  </main>

  <footer class="footer">

    <div class="settings">
      <button
        #viewParamsButton
        (click)="viewParamsMenuVisible = !viewParamsMenuVisible"
        class="theme-button-flat-small">{{'login-layout_view_accessibility' | i18n}}
      </button>

      <div class="theme-action-button-separator"></div>
      <my-language-list></my-language-list>
    </div>
  </footer>

</div>

<my-drop-menu cssClass="theme-drop-menu viewParamsMenu"
              [anchor]="viewParamsButton"
              [(visible)]="viewParamsMenuVisible"
              horizontalPosition="end">

  <div *ngIf="viewParamsMenuVisible" class="viewParamsMenuContainer">
    <i class="viewParamsIcon mi-eclipse-alt"></i>
    <span class="viewParamsEntryText  theme-label">{{'login-layout_view_theme' | i18n}}</span>
    <my-theme-selector class="alignEnd"/>

    <i class="viewParamsIcon mi-text"></i>
    <span class="viewParamsEntryText theme-label">{{'login-layout_user_text_size' | i18n}}</span>
    <my-font-size-selector class="alignEnd"/>

  </div>
</my-drop-menu>


