import {AggregateVersion, AnyFlowId, AnyFlowIdFactory, CommandId, Typed} from "@utils";
import {CommandResponse, FailureResponse} from "@shared-model";

export interface AnyFlowCommandResponse extends CommandResponse {
  className(): string;
}

export function isAnyFlowSuccess(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowSuccessResponse.className;
}

export function isAnyFlowCustomSuccess(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowCustomSuccessResponse.className;
}


export function isAnyFlowFailure(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowFailureResponse.className;
}

export function isAnyFlowConcurrentModificationError(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowAggregateConcurrentModificationError.className;
}

export function isAnyFlowCommandHandlingError(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowCommandHandlingError.className;
}

export function isAnyFlowEventHandlingError(response: Typed<AnyFlowCommandResponse>): boolean {
  return Typed.className(response) == AnyFlowEventHandlingError.className;
}

export function asAnyFlowSuccess(response: Typed<AnyFlowCommandResponse>): AnyFlowSuccessResponse {
  if (Typed.className(response) == AnyFlowSuccessResponse.className) {
    return Typed.value(<Typed<AnyFlowSuccessResponse>>response);
  } else {
    throw new Error("Response is not of type success");
  }
}

export function asAnyFlowCustomSuccess<I>(response: Typed<AnyFlowCommandResponse>): AnyFlowCustomSuccessResponse<I> {
  if (Typed.className(response) == AnyFlowCustomSuccessResponse.className) {
    return Typed.value(<Typed<AnyFlowCustomSuccessResponse<I>>>response);
  } else {
    throw new Error("Response is not of type custom success");
  }
}

export function asAnyFlowFailure(response: Typed<AnyFlowCommandResponse>): AnyFlowFailureResponse {
  if (Typed.className(response) == AnyFlowFailureResponse.className) {
    return Typed.value(<Typed<AnyFlowFailureResponse>>response);
  } else {
    throw new Error("Response is not of type failure");
  }
}

export function asAnyFlowConcurrentModificationError(response: Typed<AnyFlowCommandResponse>): AnyFlowAggregateConcurrentModificationError {
  if (Typed.className(response) == AnyFlowAggregateConcurrentModificationError.className) {
    return Typed.value(<Typed<AnyFlowAggregateConcurrentModificationError>>response);
  } else {
    throw new Error("Response is not of type concurrent modification error");
  }
}

export function asAnyFlowCommandHandlingError(response: Typed<AnyFlowCommandResponse>): AnyFlowCommandHandlingError {
  if (Typed.className(response) == AnyFlowCommandHandlingError.className) {
    return Typed.value(<Typed<AnyFlowCommandHandlingError>>response);
  } else {
    throw new Error("Response is not of type command handling error");
  }
}

export function asAnyFlowEventHandlingError(response: Typed<AnyFlowCommandResponse>): AnyFlowEventHandlingError {
  if (Typed.className(response) == AnyFlowEventHandlingError.className) {
    return Typed.value(<Typed<AnyFlowEventHandlingError>>response);
  } else {
    throw new Error("Response is not of type event handling error");
  }
}


export class AnyFlowSuccessResponse implements AnyFlowCommandResponse {
  static className = "AnyFlowSuccessResponse";

  constructor(readonly flowId: Typed<AnyFlowId>, readonly aggregateVersion: AggregateVersion) {}

  static copy(other: AnyFlowSuccessResponse): AnyFlowSuccessResponse {
    return new AnyFlowSuccessResponse(AnyFlowIdFactory.copyTyped(other.flowId), AggregateVersion.copy(other.aggregateVersion));
  }

  className() {
    return AnyFlowSuccessResponse.className;
  }
}

export class AnyFlowCustomSuccessResponse<T> implements AnyFlowCommandResponse {
  static className = "AnyFlowCustomSuccessResponse";

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly aggregateVersion: AggregateVersion,
              readonly info: T) {}

  static copy<T>(other: AnyFlowCustomSuccessResponse<T>): AnyFlowCustomSuccessResponse<T> {
    return new AnyFlowCustomSuccessResponse(AnyFlowIdFactory.copyTyped(other.flowId), AggregateVersion.copy(other.aggregateVersion), other.info);
  }

  className() {
    return AnyFlowCustomSuccessResponse.className;
  }
}

export class AnyFlowAggregateConcurrentModificationError implements AnyFlowCommandResponse {
  static className = "AnyFlowAggregateConcurrentModificationError";

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly expected: AggregateVersion,
              readonly was: AggregateVersion) {}

  static copy(other: AnyFlowAggregateConcurrentModificationError): AnyFlowAggregateConcurrentModificationError {
    return new AnyFlowAggregateConcurrentModificationError(AnyFlowIdFactory.copyTyped(other.flowId), AggregateVersion.copy(other.expected), AggregateVersion.copy(other.was));
  }

  className() {
    return AnyFlowSuccessResponse.className;
  }
}

export class AnyFlowCommandHandlingError implements AnyFlowCommandResponse {
  static className = "AnyFlowCommandHandlingError";

  constructor(readonly commandName: string,
              readonly commandId: CommandId) {}

  static copy(other: AnyFlowCommandHandlingError): AnyFlowCommandHandlingError {
      return new AnyFlowCommandHandlingError(other.commandName, CommandId.copy(other.commandId));
  }

  className() {
    return AnyFlowCommandHandlingError.className;
  }
}

export class AnyFlowEventHandlingError implements AnyFlowCommandResponse {
  static className = "AnyFlowEventHandlingError";

  constructor(readonly eventName: string,
              readonly commandId: CommandId) {}

  static copy(other: AnyFlowEventHandlingError): AnyFlowEventHandlingError {
      return new AnyFlowEventHandlingError(other.eventName, CommandId.copy(other.commandId));
  }

  className() {
    return AnyFlowEventHandlingError.className;
  }
}

export class AnyFlowFailureResponse implements AnyFlowCommandResponse {
  static className = "AnyFlowFailureResponse";

  constructor(readonly exceptions: Array<string>) {}

  className() {
    return FailureResponse.className;
  }
}
