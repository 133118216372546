/** Wrapper for value that have to be serialized with type name
 * e.g. {"GridNode":{x:10,y:4}} instead of simply {x:10,y:4}
 * */
export class Typed<T extends {className():string}> {

  [name: string]: T;

  constructor(name: string, value: T) {
    this[name] = value;
  }

  static of<T extends {className():string}>(value: T): Typed<T> {
    return new Typed<T>(value.className(), value);
  }

  static value<T extends {className():string}>(typed: Typed<T>): T {
    return typed[Object.keys(typed)[0]]
  }

  static className<T extends {className():string}>(typed: Typed<T>): string {
    return Object.keys(typed)[0];
  }

  static copy<T extends {className():string}>(typed: Typed<T>, valueCopy: (value: T) => T = (T) => T):Typed<T>  {
    return new Typed(Object.keys(typed)[0], typed[Object.keys(typed)[0]]);
  }

  static map<T extends {className():string}, R extends {className():string}>(typed: Typed<T>, converter: (value:T) => R):Typed<R> {
    return new Typed<R>(Object.keys(typed)[0], converter(typed[Object.keys(typed)[0]]));
  }
}
