import {BusinessVariable, FlowImportance, FlowStatus, TaskStatus, TaskTimeStatus} from "@shared-model";

export interface FlowImportanceFilterEntry {
  readonly importance: FlowImportance;
  readonly label: string;
  checked: boolean;
  count: string;
}

export class FlowStatusFilterEntry {
  public count: string = "";
  constructor(
    readonly status: FlowStatus,
    readonly label: string,
    public checked: boolean,
    public defaultChecked: boolean,
    public additional: boolean = false) {}
}

export class TaskStatusFilterEntry {
  public count: string = "";
  constructor(
    readonly status: TaskStatus,
    readonly label: string,
    public checked: boolean) {}
}

export interface TaskTimeStatusFilterEntry {
  readonly status: TaskTimeStatus;
  readonly label: string;
  checked: boolean;
  count: string;
}

export interface FlowLabelFilterEntry {
  readonly label: string;
  checked: boolean;
  count: string;
  colorNo: number;
}

export interface FlowSystemLabelFilterEntry {
  readonly value: BusinessVariable;
  label: string;
  readonly group: boolean;
  checked: boolean;
  count: string;
  colorNo: number;
  sortLabel: string;
}
