import {Component, Input} from "@angular/core";
import {PersonAvatarInfo} from "@shared-model";
import {organizationsImageDownloadUrl} from "@utils";
import {GravatarService} from "./gravatar.service";

@Component({
  selector: ' my-avatar',
  templateUrl: './avatar.component.html',
  host: {
    "[class.smallIcon]": "smallIcon",
    "[class.mediumIcon]": "mediumIcon",
  }
})
export class AvatarComponent {
  _person: PersonAvatarInfo = null!;

  @Input() mediumIcon: boolean = false;
  @Input() smallIcon: boolean = false;

  @Input({required:true}) set person(p: PersonAvatarInfo) {
   this._person = p;
   this.calculateUrls();
  }

  downloadAvatarUrl: string|null = null;
  gravatarUrl: string|null = null;
  gravatarAvailable: boolean = false

  gravatarBlobUrl: string|null = null;

  constructor(private readonly gravatarService: GravatarService) {}

  private calculateUrls() {
    this.downloadAvatarUrl = "";
    this.gravatarUrl = "";
    this.gravatarAvailable = false;

    if(this._person && this._person.avatar.isDefined()) {
      this.downloadAvatarUrl = organizationsImageDownloadUrl(this._person.avatar.getOrError("Avatar not found"));
    } else if (this._person.gravatarHash().length > 0) {

      this.gravatarService.getImageBlobUrl(this._person.gravatarHash()).then((result) => {
        this.gravatarBlobUrl = result.getOrNull();
      });
    }
  }

  clearDownloadAvatarUrl() {
    this.downloadAvatarUrl = null;
  }

}
