import {ViewContainerRef} from "@angular/core";
import {$$, $$Element} from "./$$";
import {getREMSize, isAttachedToBody} from "./dom/Elements";
import {myRequestAnimationFrameNoAngular, mySetTimeoutNoAngular} from "./Scheduler";
import {global} from "./global";
import {toastr} from "./toastr";
import {runInAngularZone} from "./Angular";


export class ResponsiveWidthMonitor {

  private _width: number = 0;
  private _parent: any;
  get width() {return this._width;}

  private lastWidthZero = -1;

  private onWidthChange: Array<(width: number) => void> = [];

  private _mobile = false;
  private _desktop = true;
  get mobile() {return this._mobile;}
  get desktop() {return this._desktop;}

  constructor(readonly parentName: string, // for debugging
              readonly $$element: $$Element) {

    myRequestAnimationFrameNoAngular(() => {
      const display = this.$$element.getComputedStyle("display");
      const checkDisabled = true;
      if(!checkDisabled && (!display || display == "inline")) {
        toastr.error("ResponsiveWidthMonitor: element is not block displayed, parent: '" + $$element.getAsElement().tagName + "', parentName: '" + parentName + "'");
        throw new Error("Element is not block displayed");
      } else {
        this._parent = parent;
        window.addEventListener("resize", this.onResize);
        // this.check();
          this.check();
      }
    });

    if(this.$$element.width() > 0) {
      this.check();
    }

  }

  static of(parentName: string, element: ViewContainerRef|$$Element) {
    if (element instanceof ViewContainerRef) {
      return new ResponsiveWidthMonitor(parentName, $$(element));
    } else {
      return new ResponsiveWidthMonitor(parentName, element);
    }
  }


  onMobileChange(onChange: (mobile: boolean) => void): ResponsiveWidthMonitor {
    this.onWidthChange.push(width => {
      onChange(width < 61);
    });
    return this;
  }

  onSmallerThan(threshold: number, onChange: (small: boolean) => void): ResponsiveWidthMonitor {
    this.onWidthChange.push(width => {
      onChange(width < threshold);
    });
    return this;
  }

  onRemWidth(onChange: (width: number) => void): ResponsiveWidthMonitor {
    this.onWidthChange.push(onChange);
    return this;
  }

  private onResize = () => {
    this.check();

    // additional timeouts are to recheck in case windo resize caused other elements to resize
    setTimeout(() => {
      this.check();
    }, 500);
    setTimeout(() => {
      this.check();
    }, 2000);
  }

  check() {

    if(this._parent) {
      if(!isAttachedToBody(this.$$element.getAsHtmlElement())) {
        this.destroy();
      }
    }

    if (this._parent) { // otherwise it was destroyed
      const width = this.$$element.width() / getREMSize();

      if (width === 0) {
        const now = Date.now();

        if (this.lastWidthZero > 0 && now > this.lastWidthZero + 5000) {
          console.warn("ResponsiveWidthMonitor: width is 0 for 5 seconds, maybe destroy was not called? Parent: " + this.parentName, this._parent);
          this.destroy();
        } else {
          myRequestAnimationFrameNoAngular(() => {
            this.onResize();
          });
        }

        if (this.lastWidthZero === -1) {
          this.lastWidthZero = now;
        }

      } else {
        this.lastWidthZero = -1;
        this._width = width;
        if(this._mobile !== (width < 61)) {
          global.zone.run(() => {
            this._mobile = width < 61;
            this._desktop = !this._mobile;
          });
        }
        if (this.onWidthChange.length > 0) {
          global.zone.run(() => {
            this.onWidthChange.forEach(current => {
              current(width);
            });
          });
        }
      }
    }
  }

  destroy() {
    this._parent = null;
    window.removeEventListener("resize", this.onResize);
  }

}
