import {EventBus, Option} from "@utils";
import {GeoCoordinate} from "@shared-model";
import {MapMarkerViewModel} from "./MapComponentViewModel";

export class MapComponentEventBus extends EventBus {
  markersChanged(markers: Array<MapMarkerViewModel>) {}
  selectedMarkerChanged(marker: Option<MapMarkerViewModel>) {}
  mapCenterChanged(mapCenter: GeoCoordinate) {}
  mapBoundariesChanged(){}
}
