import {None, Option, VariableId} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  HeaderPropertiesState,
  LayoutsPropertiesState,
  OptionalContextComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState
} from "../..";
import {ArrayVariable, BooleanVariable, BusinessVariable, ObjectVariable} from "@shared-model";
import {MapComponentDefinition, ModalContainerDefinition, SwitchComponentDefinition} from "@screen-common";


export class ModalContainerState extends OptionalContextComponentState {
  static className = "ModalContainerState";

  className() {
    return ModalContainerState.className;
  }

  constructor(public override innerContext: Option<VariableId>,
              override readonly properties: PropertiesStateHolder,
              override readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get contentTextSize() {
    return this.properties.optionalStringProperty("textSize")
  }

  get contentTextColor() {
    return this.properties.optionalStringProperty("textColor")
  }

  get contentTextFont() {
    return this.properties.optionalStringProperty("textFont")
  }

  get header() {
    return this.properties.optionalI18nTextProperty("header")
  }

  get headerVisible() {
    return this.properties.booleanProperty("headerVisible")
  }

  readonly layoutsState = new LayoutsPropertiesState("", this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);
  readonly headerState = new HeaderPropertiesState(this.properties);

  static copy(other: ModalContainerState) {
    return new ModalContainerState(Option.copy(other.innerContext).map(VariableId.copy), PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

  updateInnerContext(innerContext: Option<VariableId>): void {
    this.innerContext = innerContext;
  }

  get popped() {
    return this.properties.booleanProperty(ModalContainerDefinition.POPPED);
  }

  get closeVisible() {
    return this.properties.booleanProperty("closeVisible");
  }

  get acceptLabel() {
    return this.properties.optionalI18nTextProperty("acceptLabel")
  }

  get declineLabel() {
    return this.properties.optionalI18nTextProperty("declineLabel")
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case ModalContainerDefinition.POPPED:
        if(value.isDefined() && value.get() instanceof BooleanVariable) {
          this.properties.putValue(ModalContainerDefinition.POPPED, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Boolean but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(ModalContainerDefinition.POPPED);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }
}

export class ModalContainerRefState extends ScreenComponentRefState {
  static className = "ModalContainerRefState";

  className() {
    return ModalContainerRefState.className;
  }

  constructor(override readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: ModalContainerRefState) {
    return new ModalContainerRefState(PropertiesStateHolder.copy(other.properties));
  }
}
