import {EventBus} from "@utils";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MenuEventBus extends EventBus {
  mainMenuOpenRequested() {}
  mainMenuCloseRequested() {}
}
