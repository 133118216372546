<ng-container *ngIf="selectedLanguage">

  <ng-container *ngIf="smallView">
  <button
    #languageSelector
    (click)="languageSelectorVisible = !languageSelectorVisible"
    class="theme-button-flat-small" [class.theme-button-link-icon-end-small]="showExpandIcon">{{displayedSelectedLanguage.name | uppercase}}
    <ng-container *ngIf="showExpandIcon">
      <i class="mi-caret-down" *ngIf="!languageSelectorVisible"></i>
      <i class="mi-caret-up" *ngIf="languageSelectorVisible"></i>
    </ng-container>
  </button>

  <my-drop-menu cssClass="theme-drop-menu languagesDropMenu"
                [anchor]="languageSelector"
                [(visible)]="languageSelectorVisible"
                horizontalPosition="end">
    <div *ngIf="languageSelectorVisible" class="languageOptions">
      <div class="theme-drop-menu-title">{{'common_choose_language' | i18n}}</div>

      <hr class="languagesSeparator theme-drop-menu-separator"/>
      <button *ngFor="let language of languageOptions"
              (click)="onLanguageChanged(language)"
              class="languageButton theme-button-flat-small"
              [class.selected]="language.selected">
      <span class="languageButtonInnerContainer">
        {{language.name}}
        <i *ngIf="language.selected" class="mi-check"></i>
      </span>
      </button>
    </div>
  </my-drop-menu>


  </ng-container>

  <ng-container *ngIf="!smallView">
    <my-drop-down-selector [selectedOption]="selectedLanguage" [options]="languageOptions" (selectedOptionChange)="onLanguageChanged($event)">
      <i prefix class="mi-world"></i>
    </my-drop-down-selector>
  </ng-container>

</ng-container>


