import {Component, Input} from "@angular/core";
import {LogicOperationValueViewModel, LogicOperationViewModel} from "./condition-rule.component";
import {DropMenuAnchor, ExpressionSuggestionsProvider, ReadableVariable} from "@shared";
import {BusinessVariableType, ProcessEdgeId, ProcessNodeId, VariablePath, VariableTypePath} from "@shared-model";
import {None, Option, Some, validateVariablePath} from "@utils";
import {MapCanvasEditorViewModel} from "../map-canvas-editor-view.model";
import {EdgeConditionLogicOperationChanged} from "../process-map-editor.ui-events";
import {parseScript} from "esprima";


export class LogicOperationSuggestionSuggestions {
  constructor(readonly variables: Array<ReadableVariable>,
              readonly texts: Array<string>) {
  }

  static empty = new LogicOperationSuggestionSuggestions([], []);
}

export interface LogicOperationSuggestionProvider {
  getSuggestions(): Promise<LogicOperationSuggestionSuggestions>;
}

type LogicValueType = "string"|"number"|"boolean"|"variable"|"expression";

@Component({
  templateUrl: './logic-operation.component.html',
  selector: 'my-logic-operation'
})
export class LogicOperationComponent {


  @Input({required: true}) indexPath!: ReadonlyArray<number>;
  @Input({required: true}) nodeId!: ProcessNodeId;
  @Input({required: true}) edgeId!: ProcessEdgeId;
  @Input({required: true}) operation!: LogicOperationViewModel;
  @Input({required: true}) viewModel!: MapCanvasEditorViewModel;
  @Input({required: true}) suggestionsProvider!: ExpressionSuggestionsProvider;
  @Input({required: true}) logicSuggestionsProvider!: LogicOperationSuggestionProvider;

  operatorMenuVisible: boolean = false;
  valueSelectorAnchor: DropMenuAnchor | undefined;
  valueSelectorValuePosition: "left"|"right"|undefined;
  valueSelectorVisible: boolean = false;
  suggestions: LogicOperationSuggestionSuggestions = LogicOperationSuggestionSuggestions.empty;
  query: any;
  focusOnShow: boolean = false;

  anyLeftValue: string = "";
  anyRightValue: string = "";

  showValueSelector(anchor: DropMenuAnchor, position: "left"|"right") {
    this.valueSelectorValuePosition = position;
    this.valueSelectorAnchor = anchor;
    this.valueSelectorVisible = true;
    this.suggestions = LogicOperationSuggestionSuggestions.empty;

    this.logicSuggestionsProvider.getSuggestions().then(suggestions => {
      this.suggestions = suggestions;
    });
  }

  hideValueSelector() {
    this.valueSelectorAnchor = undefined;
    this.valueSelectorValuePosition = undefined;
    this.valueSelectorVisible = false;
  }

  showOperatorMenu() {
    this.operatorMenuVisible = true;
  }


  hideOperatorMenu() {
    this.operatorMenuVisible = false;
  }

  queryChanged() {

  }

  selectSingle() {

  }

  allowFocus() {
    this.focusOnShow = true;
    setTimeout(() => {
      this.focusOnShow = false;
    }, 100);
  }

  changeValueType(position: "left" | "right", valueType: LogicValueType) {
    if(position === "left") {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, Some(new LogicOperationValueViewModel(valueType)), this.operation.operator, undefined);
    } else {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, undefined, this.operation.operator, Some(new LogicOperationValueViewModel(valueType)));
    }
    this.hideValueSelector();
    this.allowFocus();
  }

  changeValueToBoolean(position: "left" | "right", value: boolean) {
    if(position === "left") {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofBoolean(value)), this.operation.operator, undefined);
    } else {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, undefined, this.operation.operator, Some(LogicOperationValueViewModel.ofBoolean(value)));
    }
    this.hideValueSelector();
  }

  changeValueToText(position: "left" | "right", text: string) {
    if(position === "left") {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofString(text)), this.operation.operator, undefined);
    } else {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, undefined, this.operation.operator, Some(LogicOperationValueViewModel.ofString(text)));
    }
    this.hideValueSelector();
  }

  changeValueToVariable(position: "left" | "right", path: VariableTypePath, tpe: BusinessVariableType | null) {
    const otherOperation = this.operation.guessOtherOperationType(position, tpe);
    this.hideValueSelector();

    if(position === "left") {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofVariable(new VariablePath(path.path.join(".")))), this.operation.operator, otherOperation ? Some(otherOperation) : undefined);
    } else {
      this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, otherOperation ? Some(otherOperation) : undefined, this.operation.operator, Some(LogicOperationValueViewModel.ofVariable(new VariablePath(path.path.join(".")))));
    }

  }

  changeOperator(operator: "=="|"!="|"<"|"<="|">"|">="|"contains"|"!contains") {
    this.operation.changeOperator(operator);
    this.hideOperatorMenu();

    this.changeEdgeConditionLogicOperation(this.nodeId, this.edgeId, this.indexPath, undefined, this.operation.operator, undefined);

  }

  changeEdgeConditionLogicOperation(nodeId: ProcessNodeId, edgeId: ProcessEdgeId, indexPath: ReadonlyArray<number>,
                                    left: Option<LogicOperationValueViewModel> | undefined,
                                    operator: "==" | "!=" | "<" | "<=" | ">" | ">=" | "contains" | "!contains" | undefined,
                                    right: Option<LogicOperationValueViewModel> | undefined) {


    this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(nodeId, edgeId, indexPath, left, operator, right, false));
  }

  stringValueChanged(position: "left" | "right", value: string) {
    if(position === "left") {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofString(value.trim())), undefined, undefined, false));
    } else {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, Some(LogicOperationValueViewModel.ofString(value.trim())), false));
    }
  }

  numberValueChanged(position: "left" | "right", value: number|null) {
    if(position === "left") {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofNumber(value)), undefined, undefined, false));
    } else {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, Some(LogicOperationValueViewModel.ofNumber(value)), false));
    }
  }


  expressionValueChanged(position: "left" | "right", expression: string) {
    if(position === "left") {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofExpression(expression)), undefined, undefined, false));
    } else {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, Some(LogicOperationValueViewModel.ofExpression(expression)), false));
    }
  }

  variableValueChanged(position: "left" | "right", path: string) {
    if(position === "left") {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, Some(LogicOperationValueViewModel.ofVariable(new VariablePath(path.trim()))), undefined, undefined, false));
    } else {
      this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, Some(LogicOperationValueViewModel.ofVariable(new VariablePath(path.trim()))), false));
    }
  }


  operationAnyValueBlurred(position: "left" | "right") {
    if(position === "left") {
      const guessed = this.guessType(this.anyLeftValue);
      if(guessed) {
        this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, Some(this.valueOfType(guessed.tpe, guessed.value)), undefined, undefined, false));
      } else {
        this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, None(), undefined, undefined, false));
      }
    } else if(position === "right") {
      const guessed = this.guessType(this.anyRightValue);
      if(guessed) {
        this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, Some(this.valueOfType(guessed.tpe, guessed.value)), false));
      } else {
        this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionLogicOperationChanged(this.nodeId, this.edgeId, this.indexPath, undefined, undefined, None(), false));
      }
    }
  }

  valueOfType(tpe: LogicValueType, value: any): LogicOperationValueViewModel {
    switch (tpe){
      case "boolean": return new LogicOperationValueViewModel(tpe, "", null, value);
      case "expression": return new LogicOperationValueViewModel(tpe, value);
      case "number":return new LogicOperationValueViewModel(tpe, "", value);
      case "string":return new LogicOperationValueViewModel(tpe, value);
      case "variable":return new LogicOperationValueViewModel(tpe, value);
      default: throw new Error("Unknown type '" + tpe +"'");
    }
  }

  guessType(text: string): {tpe: LogicValueType, value: any}|undefined {

    if(text.trim().length === 0) {
      return undefined;
    } else if(this.isNumber(text)) {
      return {tpe: "number", value: parseFloat(text)};
    } else if(text === "true") {
      return {tpe: "boolean", value: true};
    } else if(text === "false") {
      return {tpe: "boolean", value: false};
    } else if(this.isVariablePath(text)) {
      return {tpe: "variable", value: text};
    } else if(this.isExpression(text)) {
      return {tpe: "expression", value: text};
    } else {
      return {tpe: "string", value: text};
    }

  }

  isNumber(text: string): boolean {
    const parsed = parseFloat(text);
    return !isNaN(parsed) && isFinite(parsed);
  }

  isVariablePath(text: string): boolean {
    const specialCharacters = [".", "_", "["];
    if (specialCharacters.some(o => text.indexOf(o) >= 0)) {
      try {
        return validateVariablePath(text);
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  }

  isExpression(text: string): boolean {
    const specialCharacters = ["+", "-", "*", "/", "<", "=", ">", "(", "[", ".", ";", "_"];

    if (specialCharacters.some(o => text.indexOf(o) >= 0)) {
      try {
        parseScript(text);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  }
}
