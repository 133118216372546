import {Option} from "@utils";
import {PersonSelectComponentDefinition} from "@screen-common";
import {BusinessVariable, PersonVariable} from "@shared-model";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";


export class PersonSelectComponentState extends LabeledScreenComponentState {
  static className = "PersonSelectComponentState";

  className() {
    return PersonSelectComponentState.className;
  }

  readonly textState = new TextPropertiesState(this.properties);

  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalOrganizationNodeProperty(PersonSelectComponentDefinition.MODEL);
  }

  override get tooltip() {
    return this.properties.optionalI18nTextProperty("tooltip");
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get persons() {
    return this.properties.booleanProperty("persons");
  }

  get departments() {
    return this.properties.booleanProperty("departments");
  }

  get organizationNodes() {
    return this.properties.optionalOrganizationNodesArrayProperty("organizationNodes");
  }

  get groups() {
    return this.properties.booleanProperty("groups");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case PersonSelectComponentDefinition.MODEL:
        if (value.isDefined() && value.get().className() === PersonVariable.className) {
          this.properties.putValue(PersonSelectComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw Error("Unable to set value, expected Person but was [" + value.get().className() + "]");
        } else {
          this.properties.clearValue(PersonSelectComponentDefinition.MODEL);
        }
        break;
      default:
        throw new Error("Model [" + modelName + "] is not supported by " + this.className());
    }
  }

  static copy(other: PersonSelectComponentState) {
    return new PersonSelectComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class PersonSelectComponentRefState extends ScreenComponentRefState {
  static className = "PersonSelectComponentRefState";

  className() {
    return PersonSelectComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: PersonSelectComponentRefState) {
    return new PersonSelectComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}


