import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalDateTimeProperty,
  OptionalI18nTextProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId, TextAlign} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class DateTimeInputDisplayMode {
  constructor(readonly name: string) {}
  static standard = new DateTimeInputDisplayMode("standard");
  static deadline = new DateTimeInputDisplayMode("deadline");

  static of(name: string) {
    switch (name) {
      case DateTimeInputDisplayMode.standard.name: return DateTimeInputDisplayMode.standard;
      case DateTimeInputDisplayMode.deadline.name: return DateTimeInputDisplayMode.deadline;
      default: throw new Error("Unsupported name '"+name+"'");
    }
  }
}

export class DateTimeInputComponentRef extends InputComponentRef {
    static className = "DateTimeInputComponentRef";

    className() {
      return DateTimeInputComponentRef.className;
    }

    constructor(
      readonly id: ScreenComponentRefId,
      readonly componentId: ScreenComponentId,
      readonly applicationScreen: Option<ScreenId>,
      readonly properties: ComponentProperties
    ) {
      super();
    }

    static copy(other: DateTimeInputComponentRef): DateTimeInputComponentRef {
      return new DateTimeInputComponentRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      );
    }

    static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("DateTimeInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
    override defaultPropertiesProvider = DateTimeInputComponentRef.DEFAULT_PROPERTIES;

  }

  export class DateTimeInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

    static className = "DateTimeInputComponentDefinition";
    static ON_CHANGE = "onChange";
    className() {
      return DateTimeInputComponentDefinition.className;
    }
    typeName() {
      return "DateTimeInput";
    }
    static MODEL = "model";

    constructor(
      override readonly id: ScreenComponentId,
      override readonly identifier: Option<string>,
      override readonly properties: ComponentProperties,
      readonly actionProperties: ComponentActionProperties,
      override readonly validationRules: ComponentValidationRules) {
      super(id, identifier, properties, validationRules,DateTimeInputComponentDefinition.DEFAULT_PROPERTIES);
    }

    get anchorVisible(): BooleanProperty {return this.properties.getBooleanProperty("anchorVisible", this.defaultPropertiesProvider)};
    get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)};
    get minValue(): OptionalDateTimeProperty {return this.properties.getOptionalDateTimeProperty("minValue", this.defaultPropertiesProvider)};
    get maxValue(): OptionalDateTimeProperty {return this.properties.getOptionalDateTimeProperty("maxValue", this.defaultPropertiesProvider)};
    innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
      return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
    }

    get displayMode(): StringProperty {return this.properties.getStringProperty("displayMode", this.defaultPropertiesProvider)};

    labelProperties = new LabelProperties(this.properties);
    textProperties = new TextsProperties(this.properties);
    paddingsProperties = new PaddingsProperties("", this.properties);
    bordersProperties = new BordersProperties(this.properties);
    backgroundsProperties = new BackgroundsProperties("", this.properties);

    static copy(other: DateTimeInputComponentDefinition): DateTimeInputComponentDefinition {
      return new DateTimeInputComponentDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules)
      );
    }

    getModelNames(): Array<string> {
      return [DateTimeInputComponentDefinition.MODEL];
    }

    static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("DateTimeInputDefinition", (name: string) => {switch (name) {
        case "displayMode": return StringProperty.of(DateTimeInputDisplayMode.standard.name);
        case "placeholder": return OptionalI18nTextProperty.disabled();
        case "minValue": return OptionalDateTimeProperty.disabled();
        case "maxValue": return OptionalDateTimeProperty.disabled();
        case "textAlign": return StringProperty.of(TextAlign.start.name);
        case "anchorVisible": return BooleanProperty.ofTrue();
        case "innerShadow": return OptionalBooleanProperty.disabled(true);
        default: return null;
      }},
      DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
      DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
      DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
      DefaultPropertyProvider.definitionPaddings);

  }
