import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SessionEventBus} from "./SessionEventBus";
import {SessionCookiesService} from "./session-cookies.service";
import {SessionService} from "./session.service";
import {OrganizationSessionInfoClientSide} from "./SessionServiceModel";
import {isStandalonePage, myRequestAnimationFrame, toastr} from "@utils";
import {Observable} from "rxjs";
import {OfflineService} from "../offline/offline.service";

@Injectable({
  providedIn: "root",
})
export class SessionServiceProvider {

  private sessionService: SessionService|null = null;
  private deferred: Array<(sessionService: SessionService) => void> = [];

  constructor(readonly sessionCookiesService: SessionCookiesService,
              private readonly http: HttpClient,
              readonly sessionEventBus: SessionEventBus,
              readonly offlineService: OfflineService) {
    if (isStandalonePage()) {
     // // ignore session initialization
      const session = new SessionService(this.sessionCookiesService, this.http, this.sessionEventBus);
      session.initAnonymousSession();
      this.sessionService = session;
      myRequestAnimationFrame(
        () => {
          this.sessionEventBus.userLoggedIn()
        }      );
    } else {
      if (offlineService.online) {
        setTimeout(() => {
          if (offlineService.online) {
            this.initSession();
          }
        }, 500);
      }
    }
  }

  private initSession() {
    const session = new SessionService(this.sessionCookiesService, this.http, this.sessionEventBus);
    session.loadSessionInfo(() => {
      this.sessionService = session;
      this.deferred.forEach(callback => callback(session));
      this.deferred = [];
      if (session.sessionToken !== null) {
        this.sessionEventBus.userLoggedIn();
      }
    });
  }

  getSessionService(onSuccess: (sessionService: SessionService) => void) {
    if (this.sessionService == null) {
      this.deferred.push(onSuccess);
    } else {
      onSuccess(this.sessionService);
    }
  }

  getOrganizationSessionInfoIfAvailable(onSuccess: (sessionInfo: OrganizationSessionInfoClientSide) => void) {
    this.getSessionService(sessionService => {
      let info = sessionService.organizationSessionInfo;
      if (info) {
        onSuccess(info);
      }
    });
  }

  getOrganizationSessionInfo(onSuccess: (sessionInfo: OrganizationSessionInfoClientSide) => void) {
    this.getSessionService(sessionService => {
      let info = sessionService.organizationSessionInfo;
      if (info == null) {
        toastr.error("No session info available");
      } else {
        onSuccess(info);
      }
    });
  }

  getOrganizationSessionInfoPromise(): Promise<OrganizationSessionInfoClientSide> {
    return new Promise<OrganizationSessionInfoClientSide>((resolve, reject) => {
      this.getOrganizationSessionInfo(sessionInfo => {
        resolve(sessionInfo);
      });
    });
  }

  getOrganizationSessionInfoObservable(): Promise<Observable<OrganizationSessionInfoClientSide|null>> {
    return new Promise<Observable<OrganizationSessionInfoClientSide|null>>((resolve, reject) => {
      this.getSessionService(sessionService => {
        resolve(sessionService.organizationSessionInfoObservable);
      });
    });
  }
}
