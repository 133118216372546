import {Component, Input, OnInit} from "@angular/core";
import {
  FileMessage,
  FlowMessage, MailboxMessage,
  PersonNotification,
  TaskMessage
} from "../../services/notifications/notifications.model";
import {NotificationsLocalService} from "../../services/notifications/notifications.local-service";
import {Router} from "@angular/router";
import {toastr} from "@utils";
import {NavigationService} from "@shared";

@Component({
  selector: 'my-notification',
  templateUrl: './notification.component.html',
  host: {
    "(click)": "notificationReadChanged()",
    "[class.read]": "notification.read"
  }
})
export class NotificationComponent implements OnInit {

  static readonly MAX_MESSAGE_LENGTH = 200;

  @Input({required: true}) notification!: PersonNotification;

  notificationMessage: string = "";
  trimmed: boolean = false;

  constructor(private readonly notificationsLocalService: NotificationsLocalService,
              private readonly navigationService: NavigationService) {
  }

  ngOnInit(): void {
    const notificationMessage = this.notification.unWrappedMessage().value;
    this.trimmed = notificationMessage.length > NotificationComponent.MAX_MESSAGE_LENGTH + 2;
    this.notificationMessage = this.trimmed
      ? notificationMessage.substring(0, NotificationComponent.MAX_MESSAGE_LENGTH).trim() + "..."
      : notificationMessage;
  }



  notificationReadChanged() {
    if (!this.notification.read) {
      this.notificationsLocalService.markNotificationRead(this.notification.aggregateId, this.notification.version);
    }
  }

  navigateByNotification() {
    this.notificationReadChanged();
    if(this.notification.isFlowMessage()) {
      const personNotificationMessage = <FlowMessage>this.notification.unWrappedMessage();
      if(personNotificationMessage.flowCode.length == 0 || personNotificationMessage.flowIdUnwrapped().isRemote()) {
        this.navigationService.navigateByUrl("/flow/@"+personNotificationMessage.flowIdUnwrapped().urlSerialized());
      } else {
        this.navigationService.navigateByUrl("/flow/"+personNotificationMessage.flowCode);
      }
    } else if(this.notification.isTaskMessage()) {
      const personNotificationMessage = <TaskMessage>this.notification.unWrappedMessage();
      this.navigationService.navigateByUrl("/tasks/@"+personNotificationMessage.flowIdUnwrapped().urlSerialized()+"/"+personNotificationMessage.nodeId);
    } else if(this.notification.isFileMessage()) {
      const personNotificationMessage = <FileMessage>this.notification.unWrappedMessage();
      this.navigationService.navigateByUrl('/documents/' + personNotificationMessage.path);
    } else if(this.notification.isMailboxMessage()) {
      const personNotificationMessage = <MailboxMessage>this.notification.unWrappedMessage();
      toastr.info("Mailbox navigation not yet implemented");
//      window.open('#/mailbox/' + personNotificationMessage.mailboxId.id)
    }
  }
}
