import {EventBus} from "@utils";
import {CalendarEntryModel} from "./calendar.component";

export class CalendarEventBus extends EventBus {

  entrySelected(selectedEntry: CalendarEntryModel) {}
  entryDeselected() {}

  periodsSelected(from: number, to: number) {}

  periodSelectionCleared() {}
}
