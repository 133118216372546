import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider, PaddingsProperties,
  ScreenComponentDefinition,
  ScreenComponentRef
} from "@screen-common";
import {StringProperty} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class HtmlComponentRef extends ScreenComponentRef {
  static className = "HtmlComponentRef";
  className() {
    return HtmlComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: HtmlComponentRef): HtmlComponentRef {
    return new HtmlComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("HtmlComponentRef", DefaultPropertyProvider.ref);
  override defaultPropertiesProvider = HtmlComponentRef.DEFAULT_PROPERTIES;
}

export class HtmlComponentDefinition extends ScreenComponentDefinition {

  static className = "HtmlComponentDefinition";
  className() {
    return HtmlComponentDefinition.className;
  }
  typeName() {
    return "Html";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules, HtmlComponentDefinition.DEFAULT_PROPERTIES);
  }

  get html(): StringProperty {return this.properties.getStringProperty("html", this.defaultPropertiesProvider)};

  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: HtmlComponentDefinition): HtmlComponentDefinition {
    return new HtmlComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("HtmlComponentDefinition",
    (name: string) => {switch (name) {
      default: return null;
    }},
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
