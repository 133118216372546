import {Component, Input, OnInit} from "@angular/core";
import {SystemSettingsService, Theme, UserSettingsStateService} from "@shared";
import {i18n, Option} from "@utils";

class ThemeOption {
  selected = false;

  constructor(public theme: Theme|null,
              readonly name: string) {}
}


@Component({
  selector: "my-theme-selector",
  templateUrl: "./theme-selector.component.html"
})
export class ThemeSelectorComponent implements OnInit {

  systemTheme!: ThemeOption;

  selectedTheme!: ThemeOption;
  displayedSelectedTheme!: ThemeOption;
  themeOptions: Array<ThemeOption> = [];

  themeDropMenuVisible = false;

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly systemSettingsService: SystemSettingsService) {
    userSettingsService.getThemeObservable().forEach(themeFromSettings => {
        this.themeOptions.forEach(theme => theme.selected = theme.theme === themeFromSettings.getOrNull());
        console.log(this.themeOptions);
      }
    );
  }

  themeName(theme: Theme): string {
    switch (theme) {
      case Theme.light: return i18n("theme_light");
      case Theme.dark: return i18n("theme_dark");
      case Theme.highContrast: return i18n("theme_high_contrast");
      case Theme.browserCompatibility: return i18n("theme_browser_compatibility");
      default: return "unknown theme";
    }
  }

  ngOnInit(): void {
    const themeFromSettings = this.userSettingsService.getTheme().getOrNull();

    this.themeOptions = [
      new ThemeOption(null, i18n("theme_default")),
      new ThemeOption(Theme.light, this.themeName(Theme.light)),
      new ThemeOption(Theme.dark, this.themeName(Theme.dark)),
      new ThemeOption(Theme.highContrast, this.themeName(Theme.highContrast)),
      // new ThemeOption(Theme.browserCompatibility, i18n('theme_browser_compatibility'))
    ];

    if (themeFromSettings) {
      this.themeOptions.forEach(theme => {
        theme.selected = theme.theme === themeFromSettings;
        if (theme.selected) {
          this.selectedTheme = theme;
        }
      });
    } else {
      this.themeOptions[0].selected = true;
      this.selectedTheme = this.themeOptions[0];
    }


    this.updateVisibleTheme();
  }

  private updateVisibleTheme() {
    const system = this.systemSettingsService.getEffectiveTheme();

    this.systemTheme = Option.of(this.themeOptions.find(theme => theme.theme === system)).getOrError("No system theme");

    if(this.selectedTheme.theme) {
      this.displayedSelectedTheme = this.selectedTheme;
    } else {
      this.displayedSelectedTheme = this.systemTheme;
    }

  }

  themeChanged() {
    this.userSettingsService.changeTheme(Option.of(this.selectedTheme?.theme));
  }

  themeSelected(themeOption: ThemeOption) {
    this.selectedTheme = themeOption;
    this.updateVisibleTheme();
    this.themeDropMenuVisible = false;
    this.themeChanged();
  }
}
