import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DropDownSelectorOption} from "@shared";
import {LocaleListService} from "../../../modules/user.module/serivces/locale-list/locale-list.service";
import {OrganizationInfo, OrganizationSharedService} from "@shared-model";

export class TimeZoneOption implements DropDownSelectorOption {
  constructor(readonly value: string|null,
              public name: string) {}
}

@Component({
  selector: "my-time-zone-selector",
  templateUrl: "./time-zone-selector.component.html"
})
export class TimeZoneSelectorComponent implements OnInit {

  timeZoneOptions: Array<TimeZoneOption> = [];
  defaultTimeZone: TimeZoneOption = null!;

  @Input() showExpandIcon: boolean = false;

  @Input() serverMode: boolean = false;

  @Input() timeZone: string|null = "";
  @Output() timeZoneChange = new EventEmitter<string>();

  constructor(readonly localeListService: LocaleListService,
              readonly organizationSharedService: OrganizationSharedService) {}

  ngOnInit(): void {
    this.organizationSharedService.getOrganizationInfo((organizationInfo: OrganizationInfo) => {

      this.defaultTimeZone = new TimeZoneOption(organizationInfo.settings.timezone, organizationInfo.settings.timezone);

      const availableTimezones = this.serverMode ? this.localeListService.getAllServerTimeZones() : this.localeListService.getAllTimeZones();

      this.timeZoneOptions = [
        this.defaultTimeZone,
        ...availableTimezones.map(tz => new TimeZoneOption(tz, tz))
      ]

    });

  }


}
