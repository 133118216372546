import {Action} from "./AuthorizationModel";

export const Actions = {

  FeaturesAccess:  {
    TasksCreation: new Action("TasksCreation"),
    ProcessesAccess: new Action("ProcessesAccess"),
    InstancesAccess: new Action("InstancesAccess"),
    CasesCalendarsAccess: new Action("CasesCalendarsAccess"),
    ReportsAccess: new Action("ReportsAccess"),
    // deprecated, do not use, check root content instead
    // DocumentsRepositoryAccess: new Action("DocumentsRepositoryAccess"), //means read
    FlowSearchAccess: new Action("FlowSearchAccess"),
    ManagerDashboardAccess: new Action("ManagerDashboardAccess"),
    SupervisorDashboardAccess: new Action("SupervisorDashboardAccess"),
    BenchmarkAccess: new Action("BenchmarkAccess"),
    OrganizationStructureAccess: new Action("OrganizationStructureAccess"),
    OrganizationSettingsAccess: new Action("OrganizationSettingsAccess"),
    FeedbackAccess: new Action("FeedbackAccess"),
    UserCompetencesAccess: new Action("UserCompetencesAccess"),
    ProcessSimulationAccess: new Action("UserCompetencesAccess"),
    ProcessAnalysisAccess: new Action("ProcessAnalysisAccess"),
    GlobalComponentsAccess: new Action("GlobalComponentsAccess"),

    // not used currently
    CostsAnalysisAccess: new Action("CostsAnalysisAccess"),
    OutsourcingStoreAccess: new Action("OutsourcingStoreAccess"),
    MyOutsourcingServicesAccess: new Action("MyOutsourcingServicesAccess"),
    ProcessStoreAccess: new Action("ProcessStoreAccess"),
    CustomerAccess: new Action("CustomerAccess"),
    PaymentsAccess: new Action("PaymentsAccess"),
  },

  Platform: {
    User: {
      SelfRegistration: new Action("SelfRegistration"),
      UserCreation: new Action("UserCreation"),
    }
  },

  Organization: {
    OrganizationSettingsEdition: new Action("OrganizationSettingsEdition"),
    CategoriesEdition: new Action("CategoriesEdition"),
    OrganizationStructureEdition: new Action("OrganizationStructureEdition"),
    OrganizationOwnerChange: new Action("OrganizationOwnerChange"),
    OrganizationAuthorizationChange: new Action("OrganizationAuthorizationChange"),
    OrganizationDeletion: new Action("OrganizationDeletion"),
    OrganizationActivation: new Action("OrganizationActivation"),
  },

  User: {
    EmailConfirm: new Action("EmailConfirm"),
    CredentialsEdition: new Action("CredentialsEdition"),
  },

  Person: {
    PersonSettingsEdition: new Action("PersonSettingsEdition"),
  },

  Competencies: {
    CompetenciesEdition: new Action("CompetenciesEdition"),
  },

  Absences: {
    AbsencesEdition: new Action("AbsencesEdition"),
  },

  Process: {
    InStoreProcessCreation: new Action("InStoreProcessCreation"),
    ProcessCreation: new Action("ProcessCreation"),
    ProcessPreview: new Action("ProcessPreview"),
    ProcessEdition: new Action("ProcessEdition"),
    ProcessCategoryEdition: new Action("ProcessCategoryEdition"),
    ProcessOwnerChange: new Action("ProcessOwnerChange"),
    ProcessAuthorizationChange: new Action("ProcessAuthorizationChange"),
    ProcessDeletion: new Action("ProcessDeletion"),
    ProcessInStoreEdition: new Action("ProcessInStoreEdition"),
    ProcessCustomVersionsChange: new Action("ProcessCustomVersionsChange"),

  }
,

  Instance: {
    InstanceCreation: new Action("InstanceCreation"),
    InstancePreview: new Action("InstancePreview"),
    InstanceEdition: new Action("InstanceEdition"),
    InstanceOwnerChange: new Action("InstanceOwnerChange"),
    InstanceAuthorizationChange: new Action("InstanceAuthorizationChange"),
    InstanceDeletion: new Action("InstanceDeletion"),
  },

  Flow: {
    FlowCreation: new Action("FlowCreation"),
    FlowPreview: new Action("FlowPreview"),
    FlowDetailsPreview: new Action("FlowDetailsPreview"),
    FlowAllFormsPreview: new Action("FlowAllFormsPreview"),
    FlowEdition: new Action("FlowEdition"),
    FlowCancel: new Action("FlowCancel"),
    FlowOwnerChange: new Action("FlowOwnershipChange"),
    FlowAuthorizationChange: new Action("FlowAuthorizationChange"),
    FlowCommentAddition: new Action("FlowCommentAddition"),

    Role: {
      TaskEdition: new Action("TaskEdition"),
      AssignSelf: new Action("AssignSelf"),
      AssignOther: new Action("AssignOther"),
    },

    Comment: {
      FlowCommentEdition: new Action("FlowCommentEdition"),
    }
  },

  Group: {
    GroupPreview: new Action("GroupPreview"),
    GroupCreation: new Action("GroupCreation"),
    GroupEdition: new Action("GroupEdition"),
    GroupDeletion: new Action("GroupDeletion"),
    GroupContentEdition: new Action("GroupContentEdition"),

    GroupInApplicationPreview: new Action("GroupInApplicationPreview"),
    GroupInApplicationCreation: new Action("GroupInApplicationCreation"),
    GroupInApplicationEdition: new Action("GroupInApplicationEdition"),
    GroupInApplicationDeletion: new Action("GroupInApplicationDeletion"),
    GroupInApplicationManagement: new Action("GroupInApplicationManagement"),
  },

  CasesCalendar: {
    CasesCalendarCreation: new Action("CasesCalendarCreation"),
    CasesCalendarUse: new Action("CasesCalendarUse"),
    CasesCalendarEdition: new Action("CasesCalendarEdition"),
    CasesCalendarDeletion: new Action("CasesCalendarDeletion"),
    CasesCalendarOwnerChange: new Action("CasesCalendarOwnerChange"),
    CasesCalendarAuthorizationChange: new Action("CasesCalendarAuthorizationChange"),
  },

  Report: {
    ReportCreation: new Action("ReportCreation"),
    ReportUse: new Action("ReportUse"),
    ReportEdition: new Action("ReportEdition"),
    ReportPreview: new Action("ReportPreview"),
    ReportDeletion: new Action("ReportDeletion"),
    ReportOwnerChange: new Action("ReportOwnerChange"),
    ReportAuthorizationChange: new Action("ReportAuthorizationChange"),
  },

  Constant: {
    ConstantCreation: new Action("ConstantCreation"),
    ConstantView: new Action("ConstantView"),
    ConstantEdition: new Action("ConstantEdition"),
    ConstantWrite: new Action("ConstantWrite"),
    ConstantDeletion: new Action("ConstantDeletion"),
    ConstantOwnerChange: new Action("ConstantOwnerChange"),
    ConstantAuthorizationChange: new Action("ConstantAuthorizationChange"),
  },

  DataStore: {
    DataStoreCreation: new Action("DataStoreCreation"),
    DataStoreDeletion: new Action("DataStoreDeletion"),
    DataStoreEdition: new Action("DataStoreEdition"),
    DataStoreView: new Action("DataStoreView"),
    DataStoreDataRead: new Action("DataStoreDataRead"),
    DataStoreDataWrite: new Action("DataStoreDataWrite"),
    DataStoreOwnerChange: new Action("DataStoreOwnerChange"),
    DataStoreAuthorizationChange: new Action("DataStoreAuthorizationChange"),
  },

  DocumentRepository: {
    DocumentRepositoryAdministration: new Action("DocumentRepositoryAdministration"),
    DocumentRepositoryWrite: new Action("DocumentRepositoryWrite"),
  },

  Kanban: {
    KanbanCreation: new Action("KanbanCreation"),
    KanbanUse: new Action("KanbanUse"),
    KanbanView: new Action("KanbanView"),
    KanbanOwnerChange: new Action("KanbanOwnerChange"),
    KanbanAuthorizationChange: new Action("KanbanAuthorizationChange"),
    KanbanEdition: new Action("KanbanEdition"),
    KanbanDeletion: new Action("KanbanDeletion"),
  },

  Application: {
    ApplicationAccess: new Action("ApplicationAccess"),
    ApplicationCreation: new Action("ApplicationCreation"),
    ApplicationView: new Action("ApplicationView"),
    ApplicationOwnerChange: new Action("ApplicationOwnerChange"),
    ApplicationGroupsManagement: new Action("ApplicationGroupsManagement"),
    ApplicationEdition: new Action("ApplicationEdition"),
    ApplicationDeletion: new Action("ApplicationDeletion"),
  },

  IncomingMailbox: {
    IncomingMailboxCreation: new Action("IncomingMailboxCreation"),
    IncomingMailboxView: new Action("IncomingMailboxView"),
    IncomingMailboxOwnerChange: new Action("IncomingMailboxOwnerChange"),
    IncomingMailboxEdition: new Action("IncomingMailboxEdition"),
    IncomingMailboxAuthorizationChange: new Action("IncomingMailboxAuthorizationChange"),
    IncomingMailboxSending: new Action("IncomingMailboxSending"),
    IncomingMailboxContentPreview: new Action("IncomingMailboxContentPreview"),
    IncomingMailboxContentUsage: new Action("IncomingMailboxContentUsage"),
    IncomingMailboxContentAdministration: new Action("IncomingMailboxContentAdministration"),
    IncomingMailboxDeletion: new Action("IncomingMailboxDeletion"),
  },

  DatabaseConnection: {
    DatabaseConnectionCreation: new Action("DatabaseConnectionCreation"),
    DatabaseConnectionView: new Action("DatabaseConnectionView"),
    DatabaseConnectionOwnerChange: new Action("DatabaseConnectionOwnerChange"),
    DatabaseConnectionEdition: new Action("DatabaseConnectionEdition"),
    DatabaseConnectionDeletion: new Action("DatabaseConnectionDeletion"),
    DatabaseConnectionAuthorizationChange: new Action("DatabaseConnectionAuthorizationChange"),
    DatabaseConnectionUse: new Action("DatabaseConnectionUse"),
  },

  RemoteOrganization: {
    RemoteOrganizationCreation: new Action("RemoteOrganizationCreation"),
    RemoteOrganizationOwnerChange: new Action("RemoteOrganizationOwnerChange"),
    RemoteOrganizationAuthorizationChange: new Action("RemoteOrganizationAuthorizationsChange"),
    RemoteOrganizationPreview: new Action("RemoteOrganizationPreview"),
    RemoteOrganizationEdition: new Action("RemoteOrganizationEdition"),
    RemoteOrganizationDeletion: new Action("RemoteOrganizationDeletion"),
  },

  Function: {
    FunctionsCreation: new Action("FunctionsCreation"),
    FunctionsView: new Action("FunctionsView"),
    FunctionsOwnerChange: new Action("FunctionsOwnerChange"),
    FunctionsEdition: new Action("FunctionsEdition"),
    FunctionsDeletion: new Action("FunctionsDeletion"),
    FunctionsAuthorizationChange: new Action("FunctionsAuthorizationChange"),
    FunctionsRun: new Action("FunctionsRun"),
  },


  OCR: {
    OCRUsage: new Action("OCRUsage")
  }

}
