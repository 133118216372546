import {
  __,
  AnyFlowId,
  AnyFlowIdFactory,
  AnyFlowIdHelper,
  clearArray,
  LocalDate,
  None, NoneSingleton,
  Option,
  Some,
  Typed
} from "@utils";
import {ProcessNodeId, TaskIdentifier} from "@shared-model";

export enum PlanPosition {
  top = "top",
  bottom = "bottom",
  default = "default",
}

export enum TaskPlan {
  today = "today",
  tomorrow = "tomorrow",
  remaining = "remaining"
}

export class PlannedTask {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId,
              readonly touched: Option<LocalDate>,
              readonly postponed: boolean // user can postpone task by moving delayed task to remaining plan
  ) {}

  static copy(other: PlannedTask): PlannedTask {
    return new PlannedTask(
      AnyFlowIdFactory.copyTyped(other.flowId),
      other.nodeId,
      Option.copy(other.touched, LocalDate.copy),
      other.postponed);
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  is(other: TaskIdentifier) {
    return AnyFlowIdHelper.equals(this.flowIdUnwrapped(), other.flowIdUnwrapped()) && this.nodeId === other.nodeId;
  }

  static of(taskIdentifier: TaskIdentifier, touched: Option<LocalDate>, postponed: boolean) {
    return new PlannedTask(taskIdentifier.flowId, taskIdentifier.nodeId, touched, postponed);
  }
}

export class UserTasksPlan {
  constructor(public date: LocalDate,
              public today: Array<PlannedTask>,
              public tomorrow: Array<PlannedTask>,
              public remaining: Array<PlannedTask>) {
    this.adjustTodayDate();
  }

  static copy(userTasksPlan: UserTasksPlan): UserTasksPlan {
    return new UserTasksPlan(
      LocalDate.copy(userTasksPlan.date),
      userTasksPlan.today.map(PlannedTask.copy),
      userTasksPlan.tomorrow.map(PlannedTask.copy),
      userTasksPlan.remaining.map(PlannedTask.copy));
  }

  static empty() {
    return new UserTasksPlan(LocalDate.localNowDate(), [], [], []);
  }

  removeTask(taskIdentifier: TaskIdentifier): PlannedTask|undefined {
    this.adjustTodayDate();
    let removed: Array<PlannedTask> = [];
    __(this.today).findIndexOf(t => t.is(taskIdentifier)).forEach(i => removed = this.today.splice(i, 1));
    if(removed.length === 0) {
      __(this.tomorrow).findIndexOf(t => t.is(taskIdentifier)).forEach(i => removed = this.tomorrow.splice(i, 1));
    }
    if(removed.length === 0) {
      __(this.remaining).findIndexOf(t => t.is(taskIdentifier)).forEach(i => removed = this.remaining.splice(i, 1));
    }
    return removed[0];
  }

  changeTasks(taskIdentifier: TaskIdentifier, plan: TaskPlan, touched: Option<LocalDate>, postponed: boolean, tasksPlanOrder: Array<TaskIdentifier>) {
    this.adjustTodayDate();
    tasksPlanOrder.forEach((task, index) => {
      if(task.equals(taskIdentifier)) {
        this.addTask(task, plan, touched, Some(postponed), index);
      } else {
        this.addTask(task, plan, None(), NoneSingleton, index);
      }
    });
  }

  adjustTodayDate() {
    const today = LocalDate.localNowDate();
    if(this.date.isEqual(today)) {
      // this is current plan
    } else if (this.date.isBefore(today)) {
      // this is plan from yesterday or earlier move all tomorrow to today
      this.today = this.today.concat(this.tomorrow);
      this.tomorrow = [];
    } else {
      // that could happen if user changed date on device
    }
    this.date = today;
  }

  addTask(taskIdentifier: TaskIdentifier, plan: TaskPlan, touched: Option<LocalDate>, postponed: Option<boolean>, position: number|PlanPosition) {
    const removed = this.removeTask(taskIdentifier);
    let list: Array<PlannedTask>|undefined = undefined;
    switch (plan) {
      case "today": list = this.today; break;
      case "tomorrow": list = this.tomorrow; break;
      case "remaining": list = this.remaining; break;
      default: throw new Error(`Unknown plan: ${plan}`);
    }

    const newTouched = touched.orElse(removed ? removed.touched : None());
    const newPostponed = postponed.getOrElse(removed ? removed.postponed : false);

    let index: number|undefined = undefined;
    if(typeof position === "number") {
      index = position;
    } else if(position === "top") {
      index = 0;
    } else if (position === "bottom") {
      index = list.length;
    } else if (position === "default") {
      index = 0; // todo sort by priority
    }

    if (list) {

      if (index === undefined || index >= list.length) {
        list.push(PlannedTask.of(taskIdentifier, newTouched, newPostponed));
      } else {
        list.splice(index, 0, PlannedTask.of(taskIdentifier, newTouched, newPostponed));
      }
    }
  }

  filterExistingTasks(allTasks: Array<TaskIdentifier>) {
    const __allTasks = __(allTasks);
    const existingToday = this.today.filter(t => __allTasks.exists(tt => t.is(tt)));
    const existingTomorrow = this.tomorrow.filter(t => __allTasks.exists(tt => t.is(tt)));
    const existingRemaining = this.remaining.filter(t => __allTasks.exists(tt => t.is(tt)));
    clearArray(this.today);
    clearArray(this.tomorrow);
    clearArray(this.remaining);
    this.today.push(...existingToday);
    this.tomorrow.push(...existingTomorrow);
    this.remaining.push(...existingRemaining);
  }
}
