import {Component, Input, ViewContainerRef} from "@angular/core";
import {DateInputComponentViewModel} from "./DateInputComponentViewModel";
import {$$, mySetTimeout, mySetTimeoutNoAngular} from "@utils";

@Component({
  selector: 'my-date-input-component',
  templateUrl: './date-input-component.component.html',
  host: {
      "[class]": "'componentBody DateInputComponent DateInputCommon '+ viewModel.customCssClass + ' ' + viewModel.combinedCssClasses",
      "[style]": "viewModel.combinedCss",
      "[class.disabled]": "viewModel.disabled",
      "[class.preview]": "viewModel.preview",
      "[class.tableCellMode]": "viewModel.tableCellMode",
      "[class.validationError]": "viewModel.validationError"
  }
})
export class DateInputComponentComponent {
  @Input({required:true}) viewModel!: DateInputComponentViewModel;

  readonly inputElement: HTMLInputElement;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
    this.inputElement = viewContainerRef.element.nativeElement;
  }

  onFocused() {
    this.selectInputText();
    this.viewModel.onFocus();
  }

  private selectInputText() {
    mySetTimeoutNoAngular(() => {
      $$(this.viewContainerRef).findOrError("input").select();
    });
  }

  requestBlur() {
    $$(this.viewContainerRef).findOrError("input").blur();
  }
}

