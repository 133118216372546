import {Component, OnInit} from "@angular/core";
import {AuthenticateUserViewModel} from "./authenticate-user.view-model";
import {ActivatedRoute} from "@angular/router";
import {valueOrDefault} from "@utils";
import {SessionServiceProvider} from "@shared";

@Component({
  selector: 'my-authenticate-user-page',
  templateUrl: './authenticate-user-page.component.html'
})
export class AuthenticateUserPageComponent implements OnInit {

  viewModel!: AuthenticateUserViewModel;

  viewParamsMenuVisible: boolean = false;
  token: string;

  constructor(activatedRoute: ActivatedRoute,
              private readonly sessionServiceProvider: SessionServiceProvider) {
    this.token = valueOrDefault(activatedRoute.snapshot.paramMap.get("token"), "");
  }

  ngOnInit(): void {

    this.viewModel = new AuthenticateUserViewModel(this.token, this.sessionServiceProvider);

    this.viewModel.init();
  }

}
