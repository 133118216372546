<span class="inputHeader" *ngIf="viewModel.anchorVisible"><i class="mi-calendar-day"></i></span>
<input type="text"
        class="input dateInput {{viewModel.innerCssClasses}}"
        [style]="viewModel.innerCss"
        [placeholder]="viewModel.placeholder"
        [(ngModel)]="viewModel.textModel"
        (focus)="onFocused()"
        (change)="viewModel.textInputAccepted()"
        (keydown.enter)="requestBlur()"
        [disabled]="viewModel.disabled" />

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [(visible)]="viewModel.focused" [anchor]="inputElement" (cancel)="viewModel.onEditionEnded()"
              [closeOnBlur]="true" [asModalOnMobile]="true">
  <ng-container *ngIf="viewModel.focused">
    <div class="actions">
      <button class="actionButton" (click)="viewModel.clear()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      <div class="actionSpacer"></div>
      <button class="actionButton" (click)="viewModel.setYesterday()">{{'common_days_yesterday' | i18n}}</button>
      <button class="actionButton" (click)="viewModel.setToday()">{{'common_days_today' | i18n}}</button>
    </div>
    <my-date-picker-board [(date)]="viewModel.value" (picked)="viewModel.onPicked()" />
  </ng-container>
</my-drop-menu>

