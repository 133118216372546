import {Injectable} from "@angular/core";
import {AuthenticatedHttp, LaunchableSummary} from "..";
import {InstanceId, Option, PersonId} from "@utils";


@Injectable({
  providedIn: 'root'
})
export class ProcessInstanceSharedService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

}
