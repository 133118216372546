import {BusinessVariable, GeoCoordinate, GeoCoordinateVariable, ObjectVariable} from "@shared-model";
import {CssBuilder, MapComponentDefinition, MapComponentRef} from "@screen-common";
import {__, None, Option, Some, Typed, VariableId} from "@utils";
import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel, ScreenWrapperViewModel
} from "../screen-component.view-model";
import {MapComponentRefState, MapComponentState} from "./MapComponentState";
import {MapComponentEventBus} from "./MapComponentEventBus";
import {ScreenSharedViewModel} from "../..";
import {ComponentModelChange} from "../../model/screen.runtime-model";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class MapMarkerViewModel {
    constructor(readonly coordinate: GeoCoordinate,
                readonly value: Option<BusinessVariable>,
                readonly description: Option<string>,
                readonly label: Option<string>) {}

    equals(other: MapMarkerViewModel) {
      return this.value.equals(other.value, (v1, v2) => v1.isEqual(v2))
        && this.description.equals(other.description)
        && this.label.equals(other.label)
        && this.coordinate.isEqual(other.coordinate);
    }
  }

  export class MapComponentViewModel extends ComponentViewModelWithLabel {

    readonly warsaw = new GeoCoordinate(52.2296756, 21.0122287);

    override typeName = "Map";

    public markers: Array<MapMarkerViewModel> = [];
    public css: string = "";
    public cssClasses: string = "";
    public required: boolean = false;

    public mapCenter: Option<GeoCoordinate> = None();
    public mapCenterVisible: boolean = false;
    public mapBoundaries: Option<BusinessVariable> = None();
    public selectedMarker: Option<MapMarkerViewModel> = None();

    readonly eventBus: MapComponentEventBus = new MapComponentEventBus();

    constructor(override readonly shared: ScreenSharedViewModel,
                override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                readonly context: VariableId,
                override readonly definition: MapComponentDefinition,
                override readonly componentScreenId: string,
                readonly ref: MapComponentRef,
                override readonly refScreenId: string,
                override readonly componentState: MapComponentState,
                readonly refState: MapComponentRefState,
                readonly serverModel: ScreenInstanceServerModel) {
      super(parent, definition, componentState, refState, shared);
      this.update();
    }

    updateComponent(deep: boolean): void { console.log("updateComponent");

      const cssBuilder: CssBuilder = new CssBuilder();

      ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

      this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

      const mapCenterVisible = this.definition.mapCenterVisible.currentValue(() => this.componentState.mapCenterVisible).valueOrDefault(None());
      this.mapCenterVisible = mapCenterVisible.getOrElse(this.definition.mapCenter.isEnabled());
      this.mapCenter = this.definition.mapCenter.currentValue(() => this.componentState.mapCenter).valueOrDefault(None()).map(v => GeoCoordinate.copy(v.value));


      this.updateMarkers();
      this.updateSelectedMarker();
      this.updateBoundaries();

      super.updatePosition();

      this.css = cssBuilder.toCss() + this.sizeCss;
      this.cssClasses = cssBuilder.toCssClasses();

    }

    private updateMarkers() {
      const markers = this.componentState.markers.unwrappedValue().map(marker => {
        const coordinate = (<GeoCoordinateVariable>marker.valueFor("coordinate").get()).value;
        const value = marker.valueFor("value");
        const description = marker.valueFor("description").map(d => d.valueToSimpleString());
        const label = marker.valueFor("label").map(d => d.valueToSimpleString());
        return new MapMarkerViewModel(coordinate, value, description, label);
      });

      const areSameMearkers = (markers.length == this.markers.length)
        && __(markers).all(marker => __(this.markers).find(m => m.equals(marker)).isDefined());

      if(!areSameMearkers){
        this.markers = markers;
        this.eventBus.markersChanged(markers);
      }
    }

    private updateSelectedMarker(){
      const selectedValue = this.definition.selected.currentValue(() => this.componentState.selected).valueOrDefault(None());
      const selectedMarker = this.getMarkerByValue(selectedValue);
      const isSelected = (this.selectedMarker.isEmpty() && selectedMarker.isEmpty()) ||
        (this.selectedMarker.isDefined() && selectedMarker.isDefined() && this.selectedMarker.equals(selectedMarker, (m1, m2) => m1.equals(m2)))
      if(!isSelected){
        this.selectedMarker = selectedMarker;
        this.eventBus.selectedMarkerChanged(selectedMarker);
      }
    }

    private updateBoundaries(){ console.log('updateBoundaries');
      const boundaries = this.definition.mapBoundaries.currentValue(() => this.componentState.mapBoundaries).valueOrDefault(None());

      console.log('  this.mapBoundaries', this.mapBoundaries);
      console.log('  boundaries', boundaries);

      const sameBoundaries = (this.mapBoundaries.isEmpty() && boundaries.isEmpty()) ||
        (this.mapBoundaries.isDefined() && boundaries.isDefined() && this.mapBoundaries.equals(boundaries, (b1, b2) => b1.isEqual(b2)));

      console.log('isSameBoundaries', sameBoundaries);

      if(!sameBoundaries) { console.log('fire mapBoundariesChanged event')
        this.mapBoundaries = boundaries;
        this.eventBus.mapBoundariesChanged();
      }

    }

    private getMarkerByValue(value: Option<BusinessVariable>) {
      return __(this.markers).find(marker => marker.value.equals(value, (v1, v2) => v1.isEqual(v2)));
    }

    private checkMarkersChanged(markersA: Array<MapMarkerViewModel>, markersB: Array<MapMarkerViewModel>){
      if(markersA.length != markersB.length) return true;

      const markers_a = markersA.sort((a, b) => a.coordinate.compare(b.coordinate));
      const markers_b = markersB.sort((a, b) => a.coordinate.compare(b.coordinate));

      return !__(markers_a).all((marker_a, index) => {
        return markers_b[index] == marker_a;
      });
    }

    markerSelected(marker: MapMarkerViewModel) {
      if(this.definition.selected.enabled) {
        if (marker.value.isDefined()) {
          this.componentState.updateModel(MapComponentDefinition.SELECTED, Some(marker.value.get()));
          this.serverModel.changeModelWithAction(this.componentRefPath(), MapComponentDefinition.SELECTED, marker.value.get(), "onSelectedChange");
        } else {
          this.componentState.updateModel(MapComponentDefinition.SELECTED, None());
          this.serverModel.clearModelWithAction(this.componentRefPath(), MapComponentDefinition.SELECTED, "onSelectedChange");
        }
      }
    }

    unselect(){
      if(this.definition.selected.enabled) {
        this.serverModel.clearModel(this.componentRefPath(), MapComponentDefinition.SELECTED);
      }
    }

    mapPositionChanged(center: GeoCoordinate, southWest: GeoCoordinate, northEast: GeoCoordinate) {

      const changes: Array<ComponentModelChange> = [];

      if(this.definition.mapCenter.enabled) {
        changes.push(new ComponentModelChange(MapComponentDefinition.MAP_CENTER, Some(Typed.of(new GeoCoordinateVariable(center)))));
      }
      if(this.definition.mapBoundaries.enabled) {
        changes.push(new ComponentModelChange(MapComponentDefinition.MAP_BOUNDARIES, Some(Typed.of(new ObjectVariable({"southWest": new GeoCoordinateVariable(southWest), "northEast": new GeoCoordinateVariable(northEast)})))));
      }
      if(changes.length > 0) {
        this.serverModel.changeMultipleModelsWithAction(this.componentRefPath(), changes, "onMapPositionChange");
      }
    }
  }
