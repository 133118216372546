import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {PasswordInputComponentViewModel} from "./PasswordInputComponentViewModel";
import {BlinkingComponentController} from "./BlinkingComponentController";

@Component({
  selector: 'my-password-input-component',
  templateUrl: './password-input-component.component.html',
  host: {
    "[class]": "'componentBody PasswordInputComponent ' + viewModel.customCssClass + ' ' + viewModel.outerCssClasses",
    "[class.preview]": "viewModel.preview",
    "[class.tableCellMode]": "viewModel.tableCellMode",
    "[class.validationError]": "viewModel.validationError",
    "[class.startIcon]": "viewModel.startIcon",
    "[class.endIcon]": "viewModel.endIcon",
    "[style]": "viewModel.outerCss",
  }
})
export class PasswordInputComponentComponent implements OnInit, OnDestroy {
  @Input({required:true}) viewModel!: PasswordInputComponentViewModel;
  private blinkController!: BlinkingComponentController;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    if(this.viewModel === null) {
      throw new Error("No viewModel");
    } else {
      this.blinkController = new BlinkingComponentController(this.viewContainerRef.element.nativeElement, ".input", this.viewModel);
    }
  }

  ngOnDestroy(): void {
    this.blinkController.destroy();
  }
}
