<div class="titleBar">
  <span class="title">
    {{'main_menu_applications' | i18n}}
  </span>
  <a class="allApplications" routerLink="/applications" (click)="onApplicationsClicked()">
    {{'Pokaż wszystkie' | i18n}}
  </a>
</div>

<div class="separator" *ngIf="favoriteApplications.length > 0">
  <hr class="theme-content-horizontal-separator-small-margin" />
</div>

<div class="applicationsList" *ngIf="favoriteApplications.length > 0">
  <my-applications-list [applications]="favoriteApplications" [favorites]="true"
                        (selectApplication)="selectApplication($event)" (toggleFavorite)="toggleFavorite($event)" (togglePinToMenu)="togglePinToMenu($event)" />
</div>



<div class="separator" *ngIf="nonFavoriteApplications.length > 0">
  <hr class="theme-content-horizontal-separator-small-margin" />
</div>

<div class="applicationsList" *ngIf="nonFavoriteApplications.length > 0">
  <my-applications-list [applications]="nonFavoriteApplications" (selectApplication)="selectApplication($event)" (toggleFavorite)="toggleFavorite($event)" (togglePinToMenu)="togglePinToMenu($event)" />
</div>

