import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {$$, ___, clearArray, Search} from "@utils";

export interface InputSuggestionProvider {
  suggestions(): Promise<Array<string>>;
}

class SuggestionInputViewModel {
  public focused: boolean = false;
  public suggestionPopupVisible: boolean = false;
  public clearButtonVisible: boolean = false;
  readonly suggestions: Array<string> = [];
  public activeSuggestionIndex = -1; // -1 means no suggestion is selected
  private originalText = "";

  constructor(readonly suggestionsProvider: InputSuggestionProvider|Array<string>,
              public text: string,
              public exclude: Array<string>,
              readonly textChange: EventEmitter<string>,
              readonly onPopupShown: () => void,
              readonly onPopupHidden: () => void,
              readonly onBlurWithChange: (text: string) => void,
              readonly onBlur: () => void) {
    this.originalText = text;
  }


  inputFocused() {
    this.updateSuggestions();
    this.suggestionPopupVisible = true;
    this.onPopupShown();
    this.clearButtonVisible = this.text.length > 0;
    this.focused = true;
  }

  inputBlurred() {
    this.suggestionPopupVisible = false;
    this.clearButtonVisible = false;
    this.onPopupHidden();
    this.focused = false;
    if(this.text !== this.originalText) {
      this.onBlurWithChange(this.text);
      this.originalText = this.text;
    } else {
      this.onBlur();
    }
  }

  inputChanged() {
    this.updateSuggestions();
    this.clearButtonVisible = this.text.length > 0;

    if(!this.suggestionPopupVisible) {
      this.suggestionPopupVisible = true;
      this.onPopupShown();
    }

    this.textChange.emit(this.text);
  }

  private getSuggestions(): Promise<Array<string>> {
    if(Array.isArray(this.suggestionsProvider)) {
      return new Promise((resolve, reject) => {
        resolve(<Array<string>>this.suggestionsProvider);
      })
    } else {
      return (<InputSuggestionProvider>this.suggestionsProvider).suggestions();
    }
  }

  private updateSuggestions() {
    this.getSuggestions().then((suggestions: Array<string>) => {

      let cleaned = ___(suggestions).unique().sortBy(s => s).value();

      cleaned = Search.filter(cleaned, s => s, this.text);

      clearArray(this.suggestions);
      cleaned.forEach(s => {
        if(this.exclude.indexOf(s) < 0) {
          this.suggestions.push(s)
        }
      });

      this.activeSuggestionIndex = Math.min(this.activeSuggestionIndex, this.suggestions.length -1);

    });
  }

  suggestionAccepted(suggestion: string) {
    this.text = suggestion;
    this.suggestionPopupVisible = false;
    this.onPopupHidden();
    this.inputBlurred();
  }

  clearValue() {
    this.text = "";
    this.inputChanged();
  }

  valueAccepted() {
    if(this.activeSuggestionIndex >= 0 && this.suggestions.length > 0) {
      this.text = this.suggestions[this.activeSuggestionIndex];
    }
    this.inputBlurred();
  }

  upPressed($event: Event) {
    this.previousSuggestion();
    $event.preventDefault();
  }

  downPressed($event: Event) {
    this.nextSuggestion();
    $event.preventDefault();
  }

  enterPressed($event: Event) {
    if(this.suggestionPopupVisible && this.suggestions.length > 0 && this.activeSuggestionIndex >= 0) {
      this.valueAccepted();
    } else {
      $$($event.target).blur();
      this.inputBlurred();
    }
    $event.preventDefault();
  }

  nextSuggestion() {
    this.activeSuggestionIndex++;
    this.activeSuggestionIndex = Math.max(-1, Math.min(this.activeSuggestionIndex, this.suggestions.length - 1));
  }

  previousSuggestion() {
    this.activeSuggestionIndex--;
    this.activeSuggestionIndex = Math.max(-1, Math.min(this.activeSuggestionIndex, this.suggestions.length - 1));
  }

  setText(text: string) {
    this.text = text;
    this.originalText = text;
  }

  setExclude(exclude: Array<string>) {
    this.exclude = exclude;
  }
}

@Component({
  selector: "suggesting-input",
  templateUrl: "./suggesting-input.component.html"
})
export class SuggestingInputComponent implements OnInit {
  @Input() text: string = "";
  @Output() textChange = new EventEmitter<string>();

  @Input() placeholder: string = "";

  @Input() focusOnShow: boolean | "forceMobile" = false;


  @Input() exclude: Array<string> = [];

  @Input() disabled: boolean = false;

  @Input({required:true}) suggestions!: InputSuggestionProvider|Array<string>;

  @Input() suggestionMenuAnchor?: HTMLElement;

  @Output() accepted = new EventEmitter<string>();
  @Output() canceled = new EventEmitter<void>();

  viewModel!: SuggestionInputViewModel;

  ngOnInit(): void {

    this.viewModel = new SuggestionInputViewModel(this.suggestions, this.text, this.exclude, this.textChange, () => {

    }, () => {

    }, (text: string) => {
      this.text = text;
      this.textChange.emit(text);
      this.accepted.emit(text);
    }, () => {
      this.canceled.emit();
    });
  }


}
