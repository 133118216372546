import {None, Option, Some} from "./data-types/Option";
import {ElementRef, ViewContainerRef} from "@angular/core";

export class $$Element {


  private element: HTMLElement | SVGElement;

  constructor(value: HTMLElement | SVGElement) {
    this.element = value;
  }

  empty(): $$Element {
    this.element.innerHTML = '';
    return this;
  }

  appendTo(parent: $$Element | HTMLElement | SVGElement): $$Element {
    if (parent instanceof $$Element) {
      parent.element.appendChild(this.element);
    } else {
      parent.appendChild(this.element);
    }
    return this;
  }

  appendChild(child: $$Element | HTMLElement | SVGElement): $$Element {
    if (child instanceof $$Element) {
      this.element.appendChild(child.getAsElement());
    } else {
      this.element.appendChild(child);
    }
    return this;
  }

  clone(): $$Element {
    return new $$Element(this.element.cloneNode(true) as HTMLElement|SVGElement);
  }

  html(): string;
  html(value: string): $$Element;
  html(value: string | undefined = undefined): $$Element | string {
    if (value === undefined) {
      return this.element.innerHTML;
    } else {
      this.element.innerHTML = value;
      return this;
    }
  }

  text(): string;
  text(value: string): $$Element;
  text(value: string | undefined = undefined): $$Element | string {
    if (this.element instanceof HTMLElement) {
      if (value === undefined) {
        return this.element.innerText || this.element.textContent || "";
      } else {
        this.element.innerText = value;
        return this;
      }
    } else {
      throw new Error("Not an HTML element");
    }
  }

  hasClass(className: string): boolean {
    return this.element.classList.contains(className);
  }

  addClass(className: string): $$Element {
    this.element.classList.add(className);
    return this;
  }

  removeClass(className: string): $$Element {
    this.element.classList.remove(className);
    return this;
  }

  /** will be marked as deprecated - use toggleClass */
  classed(className: string): boolean;
  classed(className: string, force: boolean): $$Element;
  classed(className: string, force: boolean | undefined = undefined): $$Element | boolean {
    if (force === undefined) {
      return this.element.classList.contains(className);
    } else {
      this.element.classList.toggle(className, force);
      return this;
    }
  }

  toggleClass(className: string, force: boolean): $$Element {
    this.element.classList.toggle(className, force);
    return this;
  }

  attribute(name: string): string | null;
  attribute(name: string, value: string | number | null): $$Element;
  attribute(name: string, value: string | number | null | undefined = undefined): $$Element | string | null {
    return this.attributeImpl(name, value);
  }

  attr(name: string): string | null;
  attr(name: string, value: string | number | null): $$Element;
  attr(name: string, value: string | number | null | undefined = undefined): $$Element | string | null {
    return this.attributeImpl(name, value);
  }

  private attributeImpl(name: string, value: string | number | null | undefined = undefined): $$Element | string | null {
    if (value === undefined) {
      return this.element.getAttribute(name);
    } else if (value === null) {
      this.element.removeAttribute(name);
      return this;
    } else if (typeof value === 'number') {
      this.element.setAttribute(name, value.toString());
      return this;
    } else {
      this.element.setAttribute(name, value);
      return this;
    }
  }

  getAsHtmlElement(): HTMLElement {
    if (this.element instanceof HTMLElement) {
      return this.element;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  getAsElement(): Element {
    return this.element;
  }

  getAsSVG(): SVGElement {
    if (this.element instanceof SVGElement) {
      return this.element;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  getAsCanvas(): HTMLCanvasElement {
    if (this.element instanceof HTMLCanvasElement) {
      return this.element;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  getAsInput(): HTMLInputElement {
    if (this.element instanceof HTMLInputElement) {
      return this.element;
    } else {
      throw new Error('Not an input element');
    }
  }


  trigger(event: string) {
    this.element.dispatchEvent(new Event(event));
  }

  on<EVENT extends Event>(event: string, handler: (event: EVENT) => void): $$Element {
    this.element.addEventListener(event, <(event: Event) => void>handler);
    return this;
  }

  onPassive<EVENT extends Event>(event: string, handler: (event: EVENT) => void): $$Element {
    this.element.addEventListener(event, <(event: Event) => void>handler, {passive: true});
    return this;
  }

  off<EVENT extends Event>(event: string, handler: (event: EVENT) => void): $$Element {
    this.element.removeEventListener(event, <(event: Event) => void>handler);
    return this;
  }

  click(): void;
  click(handler: (event: MouseEvent) => void): $$Element;
  click(handler: ((event: MouseEvent) => void) | undefined = undefined): $$Element | void {
    if (this.element instanceof HTMLElement) {
      if (handler === undefined) {
        this.element.click();
      } else {
        this.element.addEventListener('click', handler);
      }
      return this;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  keydown(handler: (event: KeyboardEvent) => void): $$Element {
    if (this.element instanceof HTMLElement) {
      this.element.addEventListener('keydown', handler);
      return this;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  pointerdown(handler: (event: PointerEvent) => void) {
    if (this.element instanceof HTMLElement) {
      this.element.addEventListener('pointerdown', handler);
      return this;
    } else {
      throw new Error("Not an HTML element");
    }
  }

  pointerup(handler: (event: PointerEvent) => void) {
    if (this.element instanceof HTMLElement) {
      this.element.addEventListener('pointerup', handler);
      return this;
    } else {
      throw new Error("Not an HTML element");
    }
  }


  change(): $$Element
  change(handler: (event: Event) => void): $$Element;
  change(handler: ((event: Event) => void) | undefined = undefined): $$Element {
    if (handler === undefined) {
      this.element.dispatchEvent(new Event("change"));
    } else {
      this.element.addEventListener('change', handler);
    }
    return this;
  }

  scroll(handler: (event: Event) => void): $$Element {
    this.element.addEventListener('scroll', handler);
    return this;
  }

  focus(): $$Element;
  focus(handler: ((event: FocusEvent) => void)): $$Element;
  focus(handler: ((event: FocusEvent) => void) | undefined = undefined): $$Element {
    if (this.element instanceof HTMLElement) {
      if (handler === undefined) {
        this.element.focus();
        return this;
      } else {
        this.element.addEventListener('focus', handler);
        return this;
      }
    } else {
      throw new Error("Not an HTML element");
    }
  }


  blur(): $$Element;
  blur(handler: (event: FocusEvent) => void): $$Element;
  blur(handler: ((event: FocusEvent) => void) | undefined = undefined): $$Element {
    if (this.element instanceof HTMLElement) {
      if (handler === undefined) {
        this.element.blur();
        return this;
      } else {
        this.element.addEventListener('blur', handler);
        return this;
      }
    } else {
      throw new Error("Not an HTML element");
    }
  }


  children(): $$Element[] {
    return Array.from(this.element.children).map((e) => new $$Element(<HTMLElement | SVGElement>e));
  }

  childOrError(): $$Element {
    if (this.element.children.length === 0) {
      throw new Error("Element has no children");
    } else if (this.element.children.length > 1) {
      throw new Error("Element has more than one child");
    } else {
      return new $$Element(<HTMLElement | SVGElement>this.element.children[0]);
    }
  }

  val(value: any | null): $$Element;
  val(): any;
  val(value: any | null | undefined = undefined): $$Element | any | null {
    return this.valueImpl(value);
  }

  value(value: any | null): $$Element;
  value(): any;
  value(value: any | null | undefined = undefined): $$Element | any | null {
    return this.valueImpl(value);
  }

  private valueImpl(value: any | null | undefined = undefined): $$Element | any | null {
    if (this.element instanceof HTMLInputElement ||
      this.element instanceof HTMLTextAreaElement ||
      this.element instanceof HTMLSelectElement) {
      if (value === undefined) {
        return this.element.value;
      } else if (value === null) {
        this.element.value = "";
        return this;
      } else {
        this.element.value = value;
        return this;
      }
    } else {
      throw new Error('Not an input element');
    }
  }

  datum(): any | null;
  datum(value: any | null): $$Element;
  datum(value: any | null | undefined = undefined): $$Element | string | null {
    return this.data("dragDatum", value);
  }
  setDragDatum(value: any|null): $$Element {
    return this.data("dragDatum", value);
  }

  data(name: string): any|null;
  data(name: string, value: any|null): $$Element;
  data(name: string, value: any|null|undefined = undefined): $$Element|string|null {
    if (value === undefined) {
      let datasetElement: any | undefined;
      if ((<any>this.element)._myData !== undefined) {
        datasetElement = (<any>this.element)._myData[name];
      }
      if (datasetElement === undefined) {
        return null;
      } else {
        return datasetElement;
      }
    } else {
      if (value === null) {
        if ((<any>this.element)._myData !== undefined) {
          delete (<any>this.element)._myData[name];
        }
      } else {
        if ((<any>this.element)._myData === undefined) {
          (<any>this.element)._myData = {};
        }
        (<any>this.element)._myData[name] = value;
      }
      return this;
    }
  }

  prop(name: string): any | null;
  prop(name: string, value: any | null): $$Element;
  prop(name: string, value: any | null | undefined = undefined): $$Element | any | null {
    if (value === undefined) {
      const val = (<any>this.element)[name];
      if (val === undefined) {
        return null;
      } else {
        return val;
      }
    } else {
      if (value === null) {
        delete (<any>this.element)[name];
      } else {
        (<any>this.element)[name] = value;
      }
      return this;
    }
  }

  find(selector: string): Option<$$Element> {
    const found = this.element.querySelector(selector);
    if (found === null) {
      return None();
    } else {
      return Some(new $$Element(<HTMLElement | SVGElement>found));
    }
  }

  findOrNull(selector: string): $$Element | null {
    const found = this.element.querySelector(selector);
    if (found === null) {
      return null;
    } else {
      return new $$Element(<HTMLElement | SVGElement>found);
    }
  }

  findOrError(selector: string): $$Element;
  findOrError(selector: string, errorMessage: string): $$Element;
  findOrError(selector: string, errorMessage: string = "Element '" + selector + "' not found"): $$Element {
    const found = this.element.querySelector(selector);
    if (found === null) {
      throw new Error(errorMessage);
    } else {
      return new $$Element(<HTMLElement | SVGElement>found);
    }
  }

  styles(properties: {}): $$Element {
    return this.cssImpl(properties, undefined);
  }

  style(name: string): string | null;
  style(name: string, value: string | number | null): $$Element;
  style(nameOrProperties: string | {}, value: string | number | null | undefined = undefined): $$Element | string | null {
    return this.cssImpl(nameOrProperties, value);
  }

  css(properties: {}): $$Element;
  css(name: string): string | null;
  css(name: string, value: string | number | null): $$Element;
  css(nameOrProperties: string | {}, value: string | number | null | undefined = undefined): $$Element | string | null {
    return this.cssImpl(nameOrProperties, value);
  }

  width(): number;
  width(width: string | number): void;
  width(width: string | number | undefined = undefined): void | number {
    if (width === undefined) {
      if (this.element instanceof HTMLElement) {
        return this.element.offsetWidth;
      } else {
        return this.element.clientWidth;
      }
    } else {
      this.css("width", width);
    }
  }

  height(): number;
  height(height: string | number | null): void;
  height(height: string | number | null | undefined = undefined): void | number {
    if (height === undefined) {
      if (this.element instanceof HTMLElement) {
        return this.element.offsetHeight;
      } else {
        return this.element.clientHeight;
      }
    } else {
      this.css("height", height);
    }
  }

  private cssImpl(nameOrProperties: string | {}, value: string | number | undefined | null) {
    if (typeof nameOrProperties === 'string') {
      if (value === undefined) {
        return (<any>this.element.style)[nameOrProperties];
      } else {
        if (value === null) {
          (<any>this.element.style)[nameOrProperties] = null;
        } else {
          if((nameOrProperties == "top" || nameOrProperties == "left" || nameOrProperties == "right" || nameOrProperties == "bottom" || nameOrProperties == "width" || nameOrProperties == "height") && (typeof value) === 'number') {
            (<any>this.element.style)[nameOrProperties] = value + "px";
          } else {
            (<any>this.element.style)[nameOrProperties] = value;
          }
        }
        return this;
      }
    } else {
      for (let key in nameOrProperties) {
        const value = (<any>nameOrProperties)[key];
        if((key == "top" || key == "left" || key == "right" || key == "bottom" || key == "width" || key == "height") && (typeof value) === 'number') {
          (<any>this.element.style)[key] = value + "px";
        } else {
          (<any>this.element.style)[key] = value;
        }
      }
      return this;
    }
  }

  remove() {
    this.element.remove();
  }

  after(other: $$Element) {
    this.element.after(other.element);
  }

  findAll(selector: string) {
    const found: Array<$$Element> = [];
    this.element.querySelectorAll(selector).forEach(element => found.push(new $$Element(<HTMLElement | SVGElement>element)));
    return found;
  }

  childrenWithClass(className: string): Array<$$Element> {
    const children: Array<$$Element> = [];
    for (let i = 0; i < this.element.children.length; i++) {
      const child = this.element.children[i];
      if (child instanceof HTMLElement && child.classList.contains(className)) {
        children.push(new $$Element(child));
      }
    }
    return children;
  }

  childWithClassOrError(className: string): $$Element {
    const children = this.childrenWithClass(className);
    if (children.length === 0) {
      throw new Error("Unable to find child with class " + className);
    } else if (children.length > 1) {
      throw new Error("Found more than one child with class " + className);
    } else {
      return children[0];
    }
  }

  // Returns current element or closest parent matching given selector
  closest(selector: string): $$Element | null {
    if (this.element.matches(selector)) {
      return this;
    } else {
      let parent: HTMLElement | SVGElement | null = this.element.parentElement;
      while (parent !== null) {
        if (parent.matches(selector)) {
          return new $$Element(parent);
        }
        parent = parent.parentElement;
      }
      return null;
    }
  }

  closestOrError(selector: string): $$Element {
    const closest = this.closest(selector);
    if (closest === null) {
      throw new Error("Unable to find closest parent with selector " + selector);
    } else {
      return closest;
    }
  }

  parents(selector: string): Array<$$Element> {
    const found: Array<$$Element> = [];
    let parent: HTMLElement | SVGElement | null = this.element.parentElement;
    while (parent !== null) {
      if (parent.matches(selector)) {
        found.push(new $$Element(parent));
      }
      parent = parent.parentElement;
    }
    return found;
  }

  parent(): $$Element | null {
    const parent: HTMLElement | SVGElement | null = this.element.parentElement;
    if (parent === null) {
      return null;
    } else {
      return new $$Element(parent);
    }
  }

  scrollIntoView(param: { inline?: "center" | "end" | "nearest" | "start"; block?: "center" | "end" | "nearest" | "start" } = {}) {
    this.element.scrollIntoView(param);
  }

  findParentWithClassOrError(parentClass: string) {
    let parent = this.element.parentElement;
    while (parent !== null) {
      if (parent.classList.contains(parentClass)) {
        return new $$Element(parent);
      }
      parent = parent.parentElement;
    }
    throw new Error("Unable to find parent with class " + parentClass);
  }

  findParentOrItselfWithClassOrError(parentClass: string) {
    if (this.element.classList.contains(parentClass)) {
      return this;
    } else {
      return this.findParentWithClassOrError(parentClass);
    }
  }

  indexOf(child: $$Element) {
    return Array.prototype.indexOf.call(this.element.children, child.element);
  }

  classes(): Array<string> {
    return Array.from(this.element.classList);
  }

  is(element: $$Element) {
    return this.element === element.element;
  }

  contains(element: $$Element) {
    return this.element.contains(element.element);
  }

  isNot(element: $$Element) {
    return this.element !== element.element;
  }

  getComputedStyle(display: string): string | undefined {
    const propertyValue = window.getComputedStyle(this.element).getPropertyValue(display);
    if (propertyValue === "") {
      return undefined;
    } else {
      return propertyValue;
    }
  }

  tagNameLowercase() {
    return this.element.tagName.toLowerCase();
  }

  select() {
    if(this.element instanceof HTMLInputElement || this.element instanceof HTMLTextAreaElement) {
      this.element.select();
    }
  }
}

export function $$tag(tagName: string): $$Element {
  return new $$Element(document.createElement(tagName));
}

export function $$(document: Document): $$Element;
export function $$(elementRef: ElementRef): $$Element;
export function $$(viewContainerRef: ViewContainerRef): $$Element;
export function $$(html: string): $$Element;
export function $$(element: EventTarget|null): $$Element;
export function $$(element: HTMLElement|SVGElement|null): $$Element;
export function $$(content: ElementRef|ViewContainerRef|$$Element|EventTarget|Document|Window|null|string): $$Element {
  if(content === null) {
    throw new Error('Element is null');
  } else if (typeof content === 'string') {
    const div = document.createElement('div');
    div.innerHTML = content;
    if(div.children.length === 0) {
      throw new Error('Unable to create element from '+content+"'");
    } else if (div.children.length === 1) {
      return new $$Element(div.firstChild as HTMLElement|SVGElement);
    } else {
      throw new Error('Creating many elements from html is not supported: '+content+"'");
    }
  } else if (content instanceof HTMLElement) {
    return new $$Element(content);
  } else if (content instanceof SVGElement) {
    return new $$Element(content);
  } else if (content instanceof ViewContainerRef) {
    return new $$Element(content.element.nativeElement as HTMLElement);
  } else if (content instanceof ElementRef) {
    return new $$Element(content.nativeElement as HTMLElement);
  } else if (content instanceof Document) {
    return new $$Element(content.body);
  } else if (content instanceof Window) {
    throw new Error('Window is not supported');
  } else {
    throw new Error('Unsupported type');
  }
}
