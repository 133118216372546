import {Config} from "./config";
import {I18nServiceInterface} from "./I18n";
import {NgZone} from "@angular/core";


interface ToastrServiceInterface {
  error(text: string, tag: string): void;

  info(text: string, tag: string): void;

  warning(text: string, tag: string): void;

  success(text: string, tag: string): void;

  clearOldMessages(): void;

  clearMessagesWithTag(tag: string): void;

  infoWithTimeout(message: string, timeout: number, tag: string): void;
}

export class Global {
  private _i18nService: I18nServiceInterface | undefined;
  private _toastrService: ToastrServiceInterface | undefined;

  private _config: Config | undefined;
  private _zone: NgZone | undefined;

  offline: boolean = false;

  get i18nService(): I18nServiceInterface {
    if (this._i18nService === undefined) {
      throw new Error("i18nServiceInstance not yet initialized");
    } else {
      return this._i18nService;
    }
  }

  set i18nService(value: I18nServiceInterface) {
    this._i18nService = value;
  }

  get toastrService(): ToastrServiceInterface {
    if (this._toastrService === undefined) {
      throw new Error("toastrService not yet initialized");
    } else {
      return this._toastrService;
    }
  }

  set toastrService(value: ToastrServiceInterface) {
    this._toastrService = value;
  }

  get config(): Config {
    if (this._config === undefined) {
      throw new Error("config not yet initialized");
    } else {
      return this._config;
    }
  }

  set config(value: Config) {
    this._config = value;
  }

  get zone(): NgZone {
    if (this._zone === undefined) {
      throw new Error("zone not yet initialized");
    } else {
      return this._zone;
    }
  }

  set zone(value: NgZone) {
    this._zone = value;
  }
}

export const global: Global = new Global();
