import {Component, Input, OnInit} from "@angular/core";
import {LocalDate, LocalDateTime} from "@utils";
import {I18nService} from "../../services/internationalization/i18n.service";

@Component({
  selector: 'my-time-summary',
  templateUrl: './time-summary.component.html',
  styleUrls: ['./time-summary.component.scss']
})
export class TimeSummaryComponent implements OnInit {
  tooltip: string = "";
  text: string = "";

  constructor(readonly i18nService: I18nService) {}

  @Input() tooltipLabel: string = "";

  @Input() detailed: boolean = false;
  @Input() preciseOnly: boolean = false;
  @Input() summaryTitle: string|undefined;

  @Input() midnightAsAPreviousDay: boolean = false;

  private _value: LocalDateTime|LocalDate|undefined;

  private initialized = false;

  @Input({required:true}) set time(value: LocalDateTime|LocalDate|undefined) {
    this._value = value;
    if(this.initialized) {
      this.initTexts();
    }
  }

  ngOnInit(): void {
    this.initTexts();
    this.initialized = true;
  }

  private initTexts() {
    let tooltip = "";
    let text = "";

    let value = this._value;

    if(this.midnightAsAPreviousDay && value instanceof LocalDateTime) {
      value = this.i18nService.toPreviousDayIfMidnight(value);
    }

    if(value === undefined) {
      // nothing
    } else if(value instanceof LocalDateTime) {
      text = this.detailed ? this.i18nService.formatShortDateTime(value, true) : value.formattedFromNow();
      tooltip = this.i18nService.formatMediumDateTime(value);
    } else if(value instanceof LocalDate) {
      text = value.formattedFromNow();
      tooltip = this.i18nService.formatDate(value);
    }
    if(this.preciseOnly) {
      this.text = tooltip;
      this.tooltip = this.tooltipLabel;
    } else {
      this.text = text;
      this.tooltip = this.labelPrefixWithSpace() + tooltip;
    }
  }

  private labelPrefixWithSpace() {
    return this.tooltipLabel.length > 0 ? this.tooltipLabel + ": " : "";
  }
}
