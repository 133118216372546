import {__, PositionXY, Size} from "@utils";

export class StaticConfig {

  readonly cellSize = new Size(185, 135);
  readonly nodeSize = new Size(175, 130);
  readonly annotationTextHorizontalMargin = 12;
  readonly annotationTextVerticalMargin = 8;
  readonly annotationMinimumSize = new Size(175, 40);
  readonly annotationMaximumSize = new Size(this.cellSize.width * 3, this.cellSize.height* 3);

  readonly rectangleNodeLabelSize = new Size(138, 82);
  readonly roundNodeLabelSize = new Size(63, 58);
  readonly diamondNodeLabelSize = new Size(115, 66);
  readonly signalNodeLabelSize = new Size(64, 54);

  readonly rectangleInPlaceEditorSize = new Size(130, 70);
  readonly roundInPlaceEditorSize = new Size(50, 50);
  readonly diamondInPlaceEditorSize = new Size(80, 45);

  readonly toolbarNodeSize = new Size(50, 50);
  readonly toolbarMainButtonSize = new Size(75, 75);
  readonly toolbarNodesSpacing = 11;
  readonly toolbarMainButtonSpacing = 13;

  readonly gridTextMargin = 4;
  readonly nodeTextMargin = 10;

  readonly contextMenuIconsSize = 50;
  readonly contextMenuIconsSpacing = 20;
  readonly contextMenuBackgroundPadding = new Size(34, 17);
  readonly contextMenuBackgroundSize = new Size(275, 172);

  readonly edgeEndSize = 10;
  readonly edgeStartSize = 3.5;
  readonly edgeSignalStartSize = 15;

  readonly alternativeEdgeIconSize = 16;

  readonly rolesHeaderWidth = 140;
  readonly columnHeaderHeight = 34;
  readonly rowNumberPaddingInPrintMode = 30;



  readonly pathFinderNodeWeight = 1000;
  readonly pathFinderEdgeWeight = 0.5;

  readonly newNodePopupSpacing = 50;
  readonly newNodePopupIconSize = 38;

  readonly verticalEdgesLabelsDirection = 1; // -1 : top to bottom, 1 : bottom to top

  readonly toolTipsWidth = 150;
  readonly toolTipsTextPadding = 15;

  readonly commentIndicatorRadius = 15;

}

export enum DesignMapMode {
  Edit,
  ReadOnly,
  MapPreview,
  MapPreviewReadOnly
}

export class DesignMapConfig extends StaticConfig {

  /** Read only mode */
  designMapMode = DesignMapMode.ReadOnly;

  private designMapModeChangeListeners: ((newMode: DesignMapMode) => void)[] = [];

  addDesignMapModeChangeListener(listener: (newMode: DesignMapMode) => void) {
    this.designMapModeChangeListeners.push(listener);
  }

  private callDesignMapModeChangeListeners() {
    this.designMapModeChangeListeners.forEach(listener => listener(this.designMapMode));
  }

  modeMatches(allowedModes: DesignMapMode[]) {
    return __(allowedModes).contains(this.designMapMode);
  }

  setDesignMapMode(newMode: DesignMapMode) {
    this.designMapMode = newMode;
    this.callDesignMapModeChangeListeners();
  }

  get isEditMode() {
    return this.designMapMode === DesignMapMode.Edit;
  }

  get nonEditMode() {
    return this.designMapMode !== DesignMapMode.Edit;
  }

  get isMapPreviewMode() {
    return this.designMapMode === DesignMapMode.MapPreview;
  }

  get nonMapPreviewMode() {
    return this.designMapMode !== DesignMapMode.MapPreview;
  }

  setMapPreviewMode() {
    this.designMapMode = DesignMapMode.MapPreview;
    this.callDesignMapModeChangeListeners();
  }

  setMapPreviewReadOnlyMode() {
    this.designMapMode = DesignMapMode.MapPreviewReadOnly;
    this.callDesignMapModeChangeListeners();
  }


  get isMapPreviewReadOnly() {
    return this.designMapMode === DesignMapMode.MapPreviewReadOnly;
  }

  get isReadOnlyMode() {
    return this.designMapMode === DesignMapMode.ReadOnly;
  }

  get nonReadOnlyMode() {
    return this.designMapMode !== DesignMapMode.ReadOnly;
  }

  setReadOnlyMode() {
    this.designMapMode = DesignMapMode.ReadOnly;
    this.callDesignMapModeChangeListeners();
  }

  /** Toolbar position **/

  toolbarPosition: PositionXY = new PositionXY(0, 0);
  private updateToolbarPosition = () => {
    this.toolbarPosition = new PositionXY(
      this._editorSize.width - Math.max(this.toolbarNodeSize.width, this.toolbarMainButtonSize.width) - 50 - this.toolTipsWidth,
      this._editorSize.height - ((this.toolbarNodeSize.height + this.toolbarNodesSpacing) * 6 + this.toolbarMainButtonSize.height + (this.toolbarMainButtonSpacing - this.toolbarNodesSpacing)) - 30)
  };

  /** Editor size and position **/


  /** Editor size and position **/
  private _editorSize = new Size(0, 0);
  private _editorPosition = new PositionXY(0,0);

  updateEditorSizeAndPosition = (size: Size, position: PositionXY) => {
    this._editorSize = size;
    this._editorPosition = position;
    this.updateToolbarPosition();
  };


  get editorSize() {return this._editorSize;}

  public get editorPosition(): PositionXY {
    return this._editorPosition;
  }

  constructor() {
    super();
    this.updateToolbarPosition();
  }


}
