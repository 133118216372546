import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalI18nTextProperty,
  OptionalStringProperty,
  TrileanProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {I18nText, Option, ScreenId, Some} from "@utils";
import {ScreenComponentRefId} from "@shared";


export class SwitchComponentRef extends InputComponentRef {
  static className = "SwitchComponentRef";
  className() {
    return SwitchComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: SwitchComponentRef): SwitchComponentRef {
    return new SwitchComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("SwitchComponentRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = SwitchComponentRef.DEFAULT_PROPERTIES;
}

export class SwitchComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "SwitchComponentDefinition";
  className() {
    return SwitchComponentDefinition.className;
  }
  typeName() {
    return "Switch";
  }

  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              readonly actionProperties: ComponentActionProperties,
              override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,SwitchComponentDefinition.DEFAULT_PROPERTIES);
  }

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);

  get threeStates(): BooleanProperty {return this.properties.getBooleanProperty("threeStates", this.defaultPropertiesProvider)};



  get yesLabel(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("yesLabel", this.defaultPropertiesProvider)};

  get noLabel(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("noLabel", this.defaultPropertiesProvider)};

  yesBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("yesBackgroundColor", defaultProvider);
  }

  noBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("noBackgroundColor", defaultProvider);
  }

  noTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("noTextColor", defaultProvider);
  }

  knobColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("knobColor", defaultProvider);
  }

  static copy(other: SwitchComponentDefinition): SwitchComponentDefinition {
    return new SwitchComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [SwitchComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("SwitchComponentDefinition",
    (name: string) => {switch (name) {
      case "threeStates": return BooleanProperty.ofFalse();
      case "defaultValue": return TrileanProperty.ofFalse();
      case "yesLabel": return OptionalI18nTextProperty.of(I18nText.of(Some("Tak"), Some("Yes")));
      case "noLabel": return OptionalI18nTextProperty.of(I18nText.of(Some("Nie"), Some("No")));
      case "knobColor": return OptionalStringProperty.disabled("#ffffffff");
      case "yesBackgroundColor": return OptionalStringProperty.disabled("#ffffffff");
      case "noBackgroundColor": return OptionalStringProperty.disabled("#000000ff");
      case "noTextColor": return OptionalStringProperty.disabled("#000000ff");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);
}

