import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel, ScreenWrapperViewModel
} from "../screen-component.view-model";
import {CssBuilder, DateInputComponentDefinition, DateInputComponentRef} from "@screen-common";
import {DateInputComponentRefState, DateInputComponentState} from "./DateInputComponentState";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {DateVariable} from "@shared-model";
import {ComponentsCommon} from "../ComponentsModel";
import {
  I18nText,
  LocalDate, myRequestAnimationFrame,
  None,
  NoneSingleton,
  Option,
  parseDateInputString,
  Some,
  toFixedLengthString,
  VariableId
} from "@utils";
import {ScreenSharedViewModel} from "../..";


export class DateInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "DateInput";

  public value: LocalDate|null = null;

  private inputModel: string|undefined = undefined;
  private readableModel: string|undefined = undefined;

  public textModel: string|undefined;

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = true;

  public focused: boolean = false;
  public anchorVisible: boolean = false;

  public innerCss: string = "";
  public innerCssClasses: string = "";
  public combinedCss = "";
  public combinedCssClasses: string = "";

  public minValue: Option<LocalDate> = NoneSingleton;
  public maxValue: Option<LocalDate> = NoneSingleton;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: DateInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: DateInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: DateInputComponentState,
              readonly refState: DateInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }


  onFocus() {
    if(!this.disabled && !this.focused) {
      this.focused = true;
      this.bindCorrectModel();
    }
  }

  onPicked() {
    this.updateTextModel();
    this.updateValueChanged();
    this.closePicker();
    this.bindCorrectModel();
  }

  parseTextModel() {
    if (this.textModel && this.textModel.trim().length > 0) {
      const parsed = LocalDate.of(this.textModel);
      this.value = parsed.isSuccess() ? parsed.result : null;
      this.updateTextModel();
    }
  }

  updateTextModel() {
    if (this.value === null) {
      this.inputModel = undefined;
      this.readableModel = undefined;
    } else {
      this.inputModel = this.value.formatted();
      this.readableModel = this.value.formattedShortWords();
    }
    this.bindCorrectModel();
  }

  closePicker = () => {

    this.focused = false;
  }

  private bindCorrectModel() {
    if (this.focused) {
      this.textModel = this.inputModel;
    } else {
      this.textModel = this.readableModel;
    }
  }

  onChange = () => {
    if (this.textModel == undefined || this.textModel.trim().length === 0) {
      this.inputModel = undefined;
      this.readableModel = undefined;
      this.bindCorrectModel();
    }
  };

  textInputAccepted() {
    this.parseTextModel();
    this.updateValueChanged();
    this.closePicker();
    this.updateTextModel();
    this.bindCorrectModel();
    this.onChange();
  };

  onEditionEnded() {
    myRequestAnimationFrame(() => {
      this.parseTextModel();
      this.updateValueChanged();
      this.updateTextModel();
    });
  }

  clear = () => {
    this.value = null;
    this.updateTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
    this.bindCorrectModel();
    this.onChange();
  };

  setToday = () => {
    this.value = LocalDate.nowDate();
    this.updateTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
    this.bindCorrectModel();
    this.onChange();
  };

  setYesterday = () => {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    this.value = LocalDate.fromDate(yesterdayDate);
    this.updateTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
    this.bindCorrectModel();
    this.onChange();
  };

  updateValueChanged() {
    if(this.editable && this.uncoveredAndVisible) {

      if(this.value === null) {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), DateInputComponentDefinition.MODEL, DateInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, Some(new DateVariable(this.value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), DateInputComponentDefinition.MODEL, new DateVariable(this.value), DateInputComponentDefinition.ON_CHANGE);
      }

    }
  }

  updateComponent(deep: boolean): void {

    const outsideCssBuilder = new CssBuilder();
    const innerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toSizeCss(outsideCssBuilder, this.definition.sizeProperties, this.componentState.boxState);
    ComponentViewModelUtils.toOuterShadowCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    ComponentViewModelUtils.toBackgroundCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toPaddingsCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toTextCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(NoneSingleton).map(t => t.getCurrentWithFallback());
    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(NoneSingleton).getOrElse(I18nText.ofCurrent("dd-mm-yyyy")).getCurrentWithFallback();
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.minValue = this.definition.minValue.currentValue(() => this.componentState.minValue).valueOrDefault(NoneSingleton);
    this.maxValue = this.definition.maxValue.currentValue(() => this.componentState.maxValue).valueOrDefault(NoneSingleton);

    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(false);

    this.value = this.componentState.model.valueOrDefault(NoneSingleton).getOrNull();
    this.updateTextModel();
    this.bindCorrectModel();

    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(NoneSingleton);

    ComponentsCommon.innerShadowCss(innerCssBuilder, innerShadow);

    super.updatePosition();

    this.combinedCss = outsideCssBuilder.toCss() + this.sizeCss;
    this.combinedCssClasses = outsideCssBuilder.toCssClasses();

    this.innerCss = innerCssBuilder.toCss();
    this.innerCssClasses = innerCssBuilder.toCssClasses();
  }


}
