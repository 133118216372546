import {VariableId} from "@utils";
import {
  ComponentViewModelUtils,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "../screen-component.view-model";
import {CssBuilder, HtmlComponentDefinition, HtmlComponentRef} from "@screen-common";
import {ComponentErrorInfo, ScreenSharedViewModel} from "../..";
import {HtmlComponentRefState, HtmlComponentState} from "./HtmlComponentState";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {Subject} from "rxjs";

export class HtmlComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Html";

  public html: string = "";
  private readonly htmlSubject = new Subject<string>();
  readonly htmlObservable = this.htmlSubject.asObservable();
  public css: string = "";
  public cssClasses: string = "";

  override errors: Array<ComponentErrorInfo> = [];

  constructor(
    override readonly shared: ScreenSharedViewModel,
    override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
    readonly context: VariableId,
    override readonly definition: HtmlComponentDefinition,
    override readonly componentScreenId: string,
    readonly ref: HtmlComponentRef,
    override readonly refScreenId: string,
    readonly componentState: HtmlComponentState,
    readonly refState: HtmlComponentRefState,
    readonly serverModel: ScreenInstanceServerModel,
  ) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }

  updateComponent(deep: boolean): void {
    const cssBuilder = CssBuilder.create();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider,this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);


    const oldHtml = this.html;
    this.html = this.definition.html.currentValue(() => this.componentState.html).valueOrDefault("");
    if (this.html !== oldHtml) {
      this.htmlSubject.next(this.html);
    }
    super.updatePosition();
    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();
  }

}
