import {AggregateId, AggregateVersion, Option} from "@utils";

export class AddCategory {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly categoryParentId: number,
              readonly categoryName: string) {}
}

export class AddCategoryResponse {
  static className = "AddCategoryResponse";
  constructor(readonly categoryId: Option<number>) {}

  className():string {
    return AddCategoryResponse.className
  }

  static copy(other: AddCategoryResponse) {
    return new AddCategoryResponse(Option.copy(other.categoryId));
  }

}

export class MoveCategory {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly categoryId: number,
              readonly newParentId: number) {}
}

export class RemoveCategory {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly categoryId: number) {}
}

export class ChangeCategoryName {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly categoryId: number,
              readonly newName: string) {}
}
