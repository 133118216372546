export class GridSize {
  constructor(readonly width: number,
              readonly height: number) {
  }

  isEqual(other: GridSize) {
    return this.width === other.width && this.height === other.height;
  }

  nonEqual(other: GridSize) {
    return !this.isEqual(other);
  }

  static copy(other: GridSize) {
    return new GridSize(other.width, other.height);
  }

  add(deltaWidth: number, deltaHeight: number) {
    return new GridSize(this.width + deltaWidth, this.height + deltaHeight);
  }

  scale(factor: number) {
    return new GridSize(this.width * factor, this.height * factor);
  }
}
