<ng-container *ngIf="searchBarMode">
  <button class="toggle theme-highlighted-button" #Button (click)="toggleMenu()" [class.anyone]="selected.length == 0">
    <div class="theme-button-highlight"></div>
    <ng-container *ngIf="selectedCount === 0"><i class="summaryIcon mi-user-group"></i>{{anyoneLabel}}</ng-container>
    <ng-container *ngIf="selectedCount > 0">
      <ng-container *ngIf="assignedToMeSelected">
        <my-person-summary *ngIf="currentUser | async as user" [personId]="user" [shortCurrentUserLabel]="meLabel" avatarPosition="end"/>
      </ng-container>
      <ng-container *ngIf="assignedToNoOneSelected && !assignedToMeSelected"><span class="icon"><i class="summaryIcon mi-user-remove"></i></span>{{noOneLabel}}</ng-container>
      <ng-container *ngIf="selectedList.length > 0 && !assignedToNoOneSelected && !assignedToMeSelected">
        <span class="mainLabel">{{mainLabel}}</span>
        <my-person-summary [personId]="selectedList[0]" [small]="true" avatarPosition="end"/>
      </ng-container>
      <span *ngIf="selectedCount > 1" class="theme-count-badge-small">+{{selectedCount - 1}}</span>
      <button class="clearFilter" *ngIf="selectedCount > 0" (click)="clearSelection(); $event.stopPropagation(); false;"><i class="mi-delete"></i></button>
    </ng-container>
  </button>
  <my-drop-menu [anchor]="Button" [(visible)]="opened" cssClass="personFilterSelector theme-selector-drop-menu" horizontalPosition="end">
    <my-scroll *ngIf="opened" [adjustHeight]="true" [contentSizeChanged]="contentSizeChanged" [adjustWidth]="true">
      <ng-container *ngIf="availableMode">

        <div class="theme-drop-menu-filter">
          <i class="mi-search"></i>
          <input type="text" [(ngModel)]="query" (ngModelChange)="queryChanged()" [focus-on-show]="true" [placeholder]="'tasks_search_person' | i18n"/>
        </div>

        <hr class="theme-drop-menu-separator" />

        <ng-container *ngIf="assignedToMeVisible">
          <button class="theme-drop-menu-entry specialEntry" (click)="selectAssignedToMe()"
                  [class.selected]="assignedToMeSelected">
            <my-person-summary *ngIf="currentUser | async as user" [personId]="user" [shortCurrentUserLabel]="'tasks_me' | i18n" />
            <span class="count">{{assignedToMeCount}}</span>
            <button class="selector" (click)="toggleAssignedToMe(); $event.stopPropagation();">
              <i class="mi-check"></i>
            </button>
          </button>

          <hr class="theme-drop-menu-separator additional-separator"/>

        </ng-container>

        <ng-container *ngIf="assignedToNoOneVisible">
          <button class="theme-drop-menu-entry specialEntry" (click)="selectAssignedToNoOne()"
                  [class.selected]="assignedToNoOneSelected">
            <span class="summary"><span class="icon"><i class="mi-user-remove"></i></span><span>{{noOneLabel}}</span></span>
            <span class="count">{{assignedToNoOneCount}}</span>
            <button class="selector" (click)="toggleAssignedToNoOne(); $event.stopPropagation();">
              <i class="mi-check"></i>
            </button>
          </button>

          <hr class="theme-drop-menu-separator additional-separator" *ngIf="available.length > 0"/>
        </ng-container>
        <button *ngFor="let selector of filteredSelectors; let index = index"
                class="theme-drop-menu-entry" (click)="selectPerson(selector.id)"
                [class.selected]="selector.selected" [class.first]="index == 0">
          <my-person-summary [personId]="selector.id" />
          <span class="count">{{selector.count}}</span>
          <button class="selector" (click)="toggleSelected(selector); $event.stopPropagation();">
            <i class="mi-check"></i>
          </button>
        </button>
      </ng-container>
      <my-organization-nodes-search-box *ngIf="allMode" [noneLabel]="noOneLabel" [unselectLabel]="anyoneLabel"
                                        (nodeSelected)="newAssignedToSelected($event)"
                                        [persons]="true" (nodeCleared)="newAssignedToCleared()"
                                        [unselectEnabled]="true" [noneEnabled]="true"
                                        [alwaysShowResults]="true" />
    </my-scroll>
  </my-drop-menu>


</ng-container>




<div *ngIf="!searchBarMode" class="personFilterSelectorSidePanel">

    <div *ngIf="assignedToMeVisible" class="filterEntry">
      <my-checkbox [checked]="assignedToMeSelected" (checkedChange)="toggleAssignedToMe()">
        <my-person-summary *ngIf="currentUser | async as user" [personId]="user" [shortCurrentUserLabel]="'tasks_me' | i18n" />
      </my-checkbox>
      <span class="count">{{assignedToMeCount}}</span>
    </div>

    <div *ngIf="assignedToNoOneVisible" class="filterEntry">
      <my-checkbox [checked]="assignedToNoOneSelected" (checkedChange)="toggleAssignedToNoOne()">
        <span class="summary"><span class="icon"><i class="mi-user-remove"></i></span><span class="name">{{noOneLabel}}</span></span>
      </my-checkbox>
      <span class="count">{{assignedToNoOneCount}}</span>

    </div>
    <div *ngFor="let selector of filteredSelectors; let index = index" [class.first]="index == 0" class="filterEntry">
      <my-checkbox [checked]="selector.selected" (checkedChange)="toggleSelected(selector)">
        <my-person-summary [personId]="selector.id" />
      </my-checkbox>
      <span class="count">{{selector.count}}</span>
    </div>
</div>
