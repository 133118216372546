<div class="commentsContainer">

  <my-new-comment
    *ngIf="!readOnly"
    [uploadParams]="uploadParams"
    [lastCommentReloaded]="lastCommentReloaded"
    [uploadFileUrl]="uploadFileUrl"
    [attachmentDownloadUrl]="attachmentDownloadUrl"
    [attachments]="attachments"
    [notifications]="notifications"
    (showAttachment)="onShowAttachments($event)"
    [activeOnShow]="false"
    (commentAdded)="onCommentAdded($event)" />

  <hr *ngIf="loaded && sortedComments.length > 0"
      class="theme-content-horizontal-separator-small-margin commentsSeparator"/>

  <my-scroll
    *ngIf="loaded && sortedComments.length > 0"
    class="propertiesScroll"
    [topShadow]="false"
    [scrollBottom]="scrollToBottom">

    <div class="comments">
      <div *ngFor="let comment of sortedComments" class="comment">
        <my-node-summary *ngIf="comment.authorNode" [showIcon]="true" [showName]="false" [nodeId]="comment.authorNode" [showTooltip]="comment.deleted" />
        <ng-container *ngIf="!comment.deleted">

          <div *ngIf="!comment.editMode" class="commentBubble">
            <svg class="commentTextTriangle" viewBox="0 0 1 1">
              <polygon points="0,0 1,0 1,1" />
            </svg>
            <div class="commentText">
              <div class="commentTextAndEditedStatusContainer">
                <div class="commentTextAndEditedStatus">
                  {{comment.commentText}}
                </div>

                <button *ngIf="comment.isCurrentPerson(currentPersonId) && !canEdit && !comment.editMode"
                        class="menuButton theme-button-icon-action-flat" (click)="toggleOptionsMenu($event, comment)">
                  <i class="menuIcon mi-menu-small"></i>
                </button>
              </div>

              <ng-container *ngIf="comment.attachments.length > 0 && comment.attachmentsInfo.isDefined()">
                <div class="attachmentsAndPersonsSeparator theme-content-horizontal-separator-no-margin"></div>

                <my-comment-attachments-list
                  [attachments]="comment.attachmentsInfo.get()"
                  (showAttachment)="onShowAttachments($event)"
                  [readOnly]="true"/>
              </ng-container>

              <div class="notified"
                   *ngIf="!comment.editMode && !comment.deleteMode && comment.extraRecipients.length > 0">
                <my-person-summary *ngFor="let person of comment.extraRecipients" [personId]="person" [showName]="true"
                                   [avatar]="true" [small]="true" [showTooltip]="true" tooltipPosition="top"/>
              </div>
            </div>
          </div>

          <p class="commentSummary" *ngIf="!comment.editMode && !comment.deleteMode">
            <my-node-summary *ngIf="comment.authorNode" [nodeId]="comment.authorNode" [showIcon]="false"/>
            <span>,&nbsp;</span>
            <span class="commentTime"><my-time-summary [time]="comment.created"/></span>

            <ng-container *ngIf="comment.modified">
              <span class="dotSeparator">•</span>

              <span class="edited">{{'comments_edited' | i18n}}
                <my-time-summary [time]="comment.modified"/>
            </span>
            </ng-container>

          </p>
        </ng-container>

        <!-- DELETED -->
        <div class="deleted" *ngIf="comment.deleted">{{'comment_deleted' | i18n}}</div>

        <!-- EDIT -->
        <ng-container *ngIf="comment.editMode">
          <div class="editable editMode">
            <adjusting-text-input [(model)]="comment.editedCommentText" class="theme-input"
                                  ngDefaultControl [focus-on-show]="true"
                                  [placeholder]="'comments_NewCommentPlaceholder' | i18n"
                                  (focus)="activated = true; focused = true"
                                  (blur)="focused = false;"></adjusting-text-input>
          </div>
          <div class="commentChangeConfirmButtons" *ngIf="comment.editMode">
            <button class="theme-button-cancel-small"
                    (click)="toggleCommentEditMode(comment)">{{'comments_Cancel' | i18n}}</button>
            <button class="theme-button-confirm-small sendButton"
                    (click)="changeComment(comment)">{{'comments_Save' | i18n}}</button>
          </div>
        </ng-container>


        <!-- DELETE -->
        <div class="commentChangeConfirmButtons" *ngIf="comment.deleteMode">

          <div class="confirmation" *ngIf="comment.deleteMode">{{'comments_DeleteConfirm' | i18n}}</div>
          <button class="theme-button-cancel-small"
                  (click)="toggleCommentDeleteMode(comment)">{{'comments_Cancel' | i18n}}</button>
          <button class="theme-button-confirm-small sendButton"
                  (click)="deleteComment(comment)">{{'comments_Delete' | i18n}}</button>
        </div>

      </div>
    </div>

  </my-scroll>


  <my-drop-menu [anchor]="optionsMenuAnchor" cssClass="commentOptionsMenu theme-drop-menu"
                [(visible)]="optionsMenuVisible">
    <ng-container *ngIf="optionsMenuVisible && optionsMenuComment">
      <button class="theme-drop-menu-entry"
              (click)="toggleCommentEditMode(optionsMenuComment)"
              *ngIf="optionsMenuComment.currentUserComment && !canEdit"><i class="mi-write"></i>Edytuj
      </button>
      <button class="theme-drop-menu-entry"
              (click)="toggleCommentDeleteMode(optionsMenuComment)"
              *ngIf="optionsMenuComment.currentUserComment && !canEdit"><i class="mi-trash"></i>Usuń
      </button>
    </ng-container>
  </my-drop-menu>

</div>

<my-file-viewer [visible]="fileViewerVisible" [files]="viewableFiles" [fullScreenOnly]="true"
                [currentFileIndex]="fileViewerIndex" [inView]="false" (close)="onFileViewerClosed()"/>
