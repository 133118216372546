<span class="inputHeader" *ngIf="viewModel.anchorVisible"><i class="mi-calendar-clock"></i></span>

<input type="text"
       class="input dateInput dateTimeInput {{viewModel.innerCssClasses}}"
       [style]="viewModel.innerCss"
       [placeholder]="viewModel.placeholder"
       [(ngModel)]="viewModel.textModel"
       (focus)="onFocused()"
       [attr.inputmode]="viewModel.deadlineMode ? 'none' : 'text'"
       (change)="viewModel.textInputAccepted()"
       (keydown.enter)="requestBlur()"
       [disabled]="viewModel.disabled" />

<!-- inputmode for deadline mode is to not show virtual keybord when showing popup -->

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [anchor]="inputElement"
              [(visible)]="viewModel.focused" (cancel)="viewModel.onEditionEnded()"
              [asModalOnMobile]="true" [closeOnBlur]="true">
  <ng-container *ngIf="viewModel.focused && !viewModel.deadlineMode">
    <div class="actions">
      <button class="actionButton" tabindex="-1" (click)="viewModel.clear()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      <div class="actionSpacer"></div>
      <button class="actionButton" tabindex="-1" (click)="viewModel.setNow()">{{'common_now' | i18n}}</button>
    </div>
    <div class="pickerBody">
      <my-date-picker-board [(date)]="viewModel.datePickerModel" (dateChange)="viewModel.onChanged()" (picked)="viewModel.onDatePicked()" />
      <my-time-picker-board [(time)]="viewModel.timePickerModel" (timeChange)="viewModel.onChanged()" (picked)="viewModel.onTimePicked()" />
    </div>
  </ng-container>
  <my-task-deadline-selector *ngIf="viewModel.focused && viewModel.deadlineMode"
                             [(deadline)]="viewModel.dateTimePickerModel" (close)="viewModel.onChanged();viewModel.closePicker()"
                             (deadlinePicked)="viewModel.onChanged();viewModel.closePicker()" (deadlineChange)="viewModel.onChanged()" />
</my-drop-menu>
