import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {None, Option, Some} from "@utils";
import {BusinessVariable, NumberVariable, VariablePath} from "@shared-model";

export class NumberVariableInputViewModel {

    previewText: string|null = null;
    internalModel: number|null = null;
    internalModelBackup: number|null = null;

    constructor(readonly path: VariablePath,
                public value: NumberVariable|null,
                public readOnly: boolean,
                public preview: boolean,
                readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>) {
      this.updatePreviewText();
      this.initInternalModel();
    }

    private updatePreviewText() {
      this.previewText = this.value === null ? "-" : this.value.valueToSimpleString();
    }

    initInternalModel() {
      if (this.value == null) {
        this.internalModel = null;
      } else {
        this.internalModel = this.value.value;
      }
      this.internalModelBackup = this.internalModel;
    }

    private changeVariableValue(value: NumberVariable|null) {
      this.value = value;
      this.updatePreviewText();
      this.initInternalModel();
      this.change.emit({value: value, path: this.path});
    }

    onInternalChange() {
      if(this.internalModelChanged()) {
        if (this.internalModel === null) {
          this.changeVariableValue(null);
        } else {
          this.changeVariableValue(new NumberVariable(this.internalModel));
        }
        this.internalModelBackup = this.internalModel;
      }
    }

    private internalModelChanged() {
      return this.internalModel !== this.internalModelBackup;
    }

    updateValue(value: BusinessVariable|null) {
      if(value === null || value instanceof NumberVariable) {
        this.value = value;
        this.initInternalModel();
      } else {
        throw new Error("Incorrect variable type, expected 'Number' but was '"+value.simpleValueType()+"'");
      }
    }

    updateMode(readOnly: boolean, preview: boolean) {
      this.readOnly = readOnly;
      this.preview = preview;
    }

    backupInternalValue() {
      this.internalModelBackup = this.internalModel;
    }
  }


@Component({
  selector: "my-number-variable-input",
  templateUrl: "./number-variable-input.component.html"
})
export class NumberVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  viewModel!: NumberVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this.value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new NumberVariableInputViewModel(this.path, this.value, this.readOnly, this.preview, this.change);
  }
}
