import {
  ComponentStatus,
  ComponentViewModelUtils,
  GridSizeProperty,
  ModalContainerRefState,
  ModalContainerState,
  ScreenComponentsState,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel,
  ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils,
} from "../..";
import {
  __,
  ___,
  clearArray,
  myRequestAnimationFrame,
  None,
  Option,
  overwriteArray,
  range,
  Some,
  VariableId
} from "@utils";
import {ScreenExternalEventBus} from "@shared-model";
import {ScreenComponentRefId} from "@shared";
import {
  AddedSpace,
  CssBuilder,
  CssUtils,
  LayoutAlign,
  LayoutStretch,
  LayoutType,
  LayoutWrap,
  ModalContainerDefinition,
  ModalContainerRef,
  ScreenComponentRefIdInScreen,
  TextFont
} from "@screen-common";
import {EventEmitter} from "@angular/core";


export class ModalContainerViewModel extends ScreenContainerViewModel {

    override typeName = "Modal";

    public override layoutClass: string = "";
    public layoutAlignClass: string = "";
    public layoutStretchClass: string = "";
    public layoutWrapClass: string = "";
    public override customCssClass: string  = "";
    public header: string = "";
    public headerVisible: boolean = false;

    readonly children: Array<ScreenComponentViewModel> = [];
    readonly visibleChildren: Array<ScreenComponentViewModel> = [];

    public combinedCss: string = "";
    public childrenPlainCss = "";
    public childrenPlainCssClasses = "";
    public cssClasses: string = "";
    public headerCss: string = "";
    public headerCssClasses: string = "";

    childrenPanelDeltaHeightRem = 0;

    private static GRID_REM_SIZE: number = 2.5;

    override nonVisibleComponent = true;
    popped: boolean = false;
    private ignoreNextOnClose: number = 0;

    acceptVisible: boolean = true;
    declineVisible: boolean = true;
    closeVisible: boolean = true;
    acceptLabel: string = "";
    declineLabel: string = "";

    actionButtonsVisible = true;
    readonly contentChanged: EventEmitter<void> = new EventEmitter<void>();


    public inProgressAccept = false;
    public inProgressDecline = false;

    constructor(override readonly shared: ScreenSharedViewModel,
                readonly externalEventBus: ScreenExternalEventBus,
                override readonly parent: ScreenContainerViewModel|ScreenWrapperViewModel,
                override readonly definition: ModalContainerDefinition,
                override readonly componentScreenId: string,
                override readonly ref: ModalContainerRef,
                override readonly refScreenId: string,
                override readonly componentState: ModalContainerState,
                override readonly refState: ModalContainerRefState,
                readonly context: VariableId,
                readonly serverModel: ScreenInstanceServerModel,
                readonly componentsState: ScreenComponentsState,
                readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
      super(parent, componentState, refState, definition, shared);
      this.update();
    }

    onClick() {
      if(this.definition.actionProperties.getActions("onClick").length > 0) {
        this.serverModel.executeAction(this.componentRefPath(), "onClick")
      }
    }

    getAllChildren(): Array<ScreenComponentViewModel> {
      return this.children;
    }

    override componentName(): string {
      if(this.headerVisible && this.header.trim().length > 0) {
        return this.header;
      } else {
        return super.componentName();
      }
    }

    getLayoutForComponent(componentRefId: ScreenComponentRefId) {
      return LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));
    }



    updateContainer(deep: boolean): void {

      const unit = ScreenFormConfig.panelUnitRemSize;

      if (!this.uncoveredAndVisible || this.status != ComponentStatus.initialized) {
        clearArray(this.children);
      } else {

        const maxHeight = this.definition.sizeProperties.maxHeight.currentValue(() => this.componentState.boxState.maxHeight).valueOrDefault(None());
        const maxWidth = this.definition.sizeProperties.maxWidth.currentValue(() => this.componentState.boxState.maxHeight).valueOrDefault(None());
        const contentMinHeight = this.definition.sizeProperties.contentMinHeight.currentValue(() => this.componentState.boxState.contentMinHeight).valueOrDefault(None());
        const contentMinWidth = this.definition.sizeProperties.contentMinWidth.currentValue(() => this.componentState.boxState.contentMinWidth).valueOrDefault(None());

        const headerOption = this.definition.header.currentValue(() => this.componentState.header).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
        this.header = headerOption.getOrElse("");
        this.headerVisible = headerOption.isDefined();

        const layout = LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));

        this.popped = this.componentState.popped.valueOrDefault(false);


        if(this.popped) {
          if (this.definition.model.enabled) {
            const modelProperty = this.definition.model.currentValue(() => this.componentState.properties.optionalModelProperty("model"));
            if (modelProperty.isSuccess() && this.componentState.innerContext.isDefined()) {
              this.updateChildren(this.componentState.innerContext.getOrError("Should be defined"));
            } else {
              clearArray(this.children);
            }
          } else {
            this.updateChildren(this.context);
          }
        } else {
          clearArray(this.children);
        }

        const cssBuilder = CssBuilder.create();
        const childrenPlainCssBuilder = CssBuilder.create();
        const headerCssBuilder = CssBuilder.create();

        const textSize = this.definition.contentTextSize(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
        const textColor = this.definition.contentTextColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextColor).valueOrDefault(None());
        const textFont = this.definition.contentTextFont(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

        const contentMinHeightValue = contentMinHeight.map(v => SizeProperty.sizeToCss(unit, v));
        const headerMinHeightValue = this.definition.headerProperties.headerMinHeight(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.headerState.headerMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

        CssUtils.fontCss(childrenPlainCssBuilder, textFont, textSize, false, false, false, false, textColor);
        ComponentViewModelUtils.toPaddingsCss(childrenPlainCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);


        CssUtils.childrenPlainMinHeight(childrenPlainCssBuilder, contentMinHeightValue, headerMinHeightValue, this.headerVisible, Some(this.childrenPanelDeltaHeightRem + "rem"));

        const contentMinWidthValue = contentMinWidth.map(v => SizeProperty.sizeToCss(unit, v));

        if(contentMinWidth.isDefined()){
          CssUtils.minSizes(childrenPlainCssBuilder, contentMinWidthValue.orElse(Some("0rem")), None());
        }

        CssUtils.sectionContentOverflow(childrenPlainCssBuilder, maxWidth, maxHeight);

        if (layout.isGrid()) {

          const rows = this.definition.layoutsProperties.rows.currentValue(() => this.componentState.layoutsState.rows).valueOrDefault(2);
          const columns = this.definition.layoutsProperties.columns.currentValue(() => this.componentState.layoutsState.columns).valueOrDefault(2);

          const columnsCss =  range(columns).map(r =>  GridSizeProperty.sizeToCss(unit, this.definition.properties.getStringProperty("column|"+r+"|width", ModalContainerDefinition.DEFAULT_PROPERTIES).currentValue(() => this.componentState.properties.stringProperty("column|"+r+"|width")).valueOrDefault("auto")));
          const rowsCss =  range(rows).map(r =>  GridSizeProperty.sizeToCss(unit, this.definition.properties.getStringProperty("row|"+r+"|height", ModalContainerDefinition.DEFAULT_PROPERTIES).currentValue(() => this.componentState.properties.stringProperty("row|"+r+"|height")).valueOrDefault("auto")));

          childrenPlainCssBuilder.addProperty("grid-template-columns: " + columnsCss.join(" ") + ";");
          childrenPlainCssBuilder.addProperty("grid-template-rows: " + rowsCss.join(" ") + ";");
        }

        if(!layout.isStatic()) {
          const gapColumn = this.definition.layoutsProperties.gapColumn.currentValue(() => this.componentState.layoutsState.gapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
          const gapRow = this.definition.layoutsProperties.gapRow.currentValue(() => this.componentState.layoutsState.gapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
          CssUtils.gaps(childrenPlainCssBuilder, gapRow, gapColumn);
        }

        const backgroundColorValue = this.definition.backgroundsProperties.backgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.backgroundsState.backgroundColor).valueOrDefault(None());

        CssUtils.backgroundColor(cssBuilder, backgroundColorValue);


        ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

        const defaultHeight = this.ref.positionProperties.desiredHeight.currentValue(() => this.refState.positionState.desiredHeight).valueOrDefault(None());
        const defaultWidth = this.ref.positionProperties.desiredWidth.currentValue(() => this.refState.positionState.desiredWidth).valueOrDefault(None());

        CssUtils.minSizes(cssBuilder,
          defaultWidth.map(v => SizeProperty.sizeToCss(unit, v)),
          defaultHeight.map(v => SizeProperty.sizeToCss(unit, v))
        );

        ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);




        ComponentViewModelUtils.toHeaderCss(headerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.headerProperties, this.componentState.headerState);


        this.layoutClass = layout.name;

        const layoutAlign = LayoutAlign.byName(this.definition.layoutsProperties.layoutAlign.currentValue(() => this.componentState.layoutsState.layoutAlign).valueOrDefault(LayoutAlign.DEFAULT.name));
        this.layoutAlignClass = layoutAlign.name;
        const layoutStretch = LayoutStretch.byName(this.definition.layoutsProperties.layoutStretch.currentValue(() => this.componentState.layoutsState.layoutStretch).valueOrDefault(LayoutStretch.DEFAULT.name));
        this.layoutStretchClass = layoutStretch.name;
        const layoutWrap = LayoutWrap.byName(this.definition.layoutsProperties.layoutWrap.currentValue(() => this.componentState.layoutsState.layoutWrap).valueOrDefault(LayoutWrap.wrap.name));
        this.layoutWrapClass = layoutWrap.name;

        super.updatePosition();
        this.combinedCss = cssBuilder.toCss() + this.sizeCss;
        this.cssClasses = cssBuilder.toCssClasses();

        this.childrenPlainCss = childrenPlainCssBuilder.toCss();
        this.childrenPlainCssClasses = childrenPlainCssBuilder.toCssClasses();

        this.headerCss = headerCssBuilder.toCss();
        this.headerCssClasses = headerCssBuilder.toCssClasses();

        clearArray(this.visibleChildren);
        this.visibleChildren.push(... this.children.filter(c => c.visible));

        this.closeVisible = this.definition.closeVisible.currentValue(() => this.componentState.closeVisible).valueOrDefault(false);

        const acceptButtonOption = this.definition.acceptLabel.currentValue(() => this.componentState.acceptLabel).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
        this.acceptLabel = acceptButtonOption.getOrElse("");
        this.acceptVisible = acceptButtonOption.isDefined();

        const declineButtonOption = this.definition.declineLabel.currentValue(() => this.componentState.declineLabel).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
        this.declineLabel = declineButtonOption.getOrElse("");
        this.declineVisible = declineButtonOption.isDefined();

        this.actionButtonsVisible = this.acceptVisible || this.declineVisible;


        this.inProgressAccept = this.componentState.properties.isActionInProgress("onAccepted");
        this.inProgressDecline = this.componentState.properties.isActionInProgress("onDeclined");

        this.contentChanged.emit();
      }
    }

  private updateChildren(childrenContext: VariableId) {
    const children = this.definition.children.map(childRefId => {
      return __(this.children).find(child => child.ref.id.id == childRefId.id && child.context.id === childrenContext.id)
        .getOrElseLazy(() => this.createViewModel(this, ScreenComponentRefIdInScreen.of(this.componentScreenId, childRefId), this.componentState.innerContext));
    });
    overwriteArray(this.children, children);
  }



  ifDefined(propertyName: string, value: string|null) {
      if(value == null || value.length === 0) {
        return "";
      } else {
        return propertyName+":"+value+";";
      }
    }

    childDeltaHeightChanged(): void {
      // shift down only children that are below start of added space

      const layout = LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));

      if(layout.isStatic()) {

        const allDeltas: Array<AddedSpace> = ___(this.children).filter(t => t.getDefaultStaticBottom().isDefined()).map(t => {
          const bottomRem = t.getDefaultStaticBottom().get() * ModalContainerViewModel.GRID_REM_SIZE;
          const deltaHeightRem = t.deltaHeightRem;
          return new AddedSpace(bottomRem, deltaHeightRem);
        }).filter(d => d.sizeRem > 0).value();

        this.childrenPanelDeltaHeightRem = ___(this.combineDeltas(allDeltas)).map(d => d.sizeRem).sum();

        this.children.forEach(child => {
          const componentTop = child.getDefaultStaticTop();
          if (componentTop.isDefined()) {
            const top = componentTop.get() * ModalContainerViewModel.GRID_REM_SIZE;
            const deltasAbove = allDeltas.filter(d => d.fromRem <= top);
            const combinedDeltasAbove = this.combineDeltas(deltasAbove);
            const deltasSum = ___(combinedDeltasAbove).map(d => d.sizeRem).sum();
            child.changeDeltaTopRem(deltasSum);
          }

        });
      } else {
        this.childrenPanelDeltaHeightRem = 0;
        this.children.forEach(child => {
          child.changeDeltaTopRem(0);
        });
      }

      this.update();
    }

    private combineDeltas(deltas: Array<AddedSpace>) {
      if(deltas.length > 0) {
        const sorted = __(deltas).sortBy(d => d.fromRem);

        const current = sorted[0];
        let last: AddedSpace = new AddedSpace(current.fromRem, current.sizeRem);
        const combined: Array<AddedSpace> = [last];
        sorted.forEach(current => {
          const lastTo = last.fromRem + last.sizeRem;
          if(current.fromRem >= last.fromRem && current.fromRem <= lastTo) {
            last.sizeRem = Math.max(last.sizeRem, current.fromRem + current.sizeRem - last.fromRem);
          } else {
            last = new AddedSpace(current.fromRem, current.sizeRem);
            combined.push(last);
          }
        });
        return combined;
      } else {
        return deltas;
      }

    }



    getComponentById(id: number): Option<ScreenComponentViewModel> {
      if(this.id == id) {
        return Some(this);
      } else {
        for(let i = 0; i < this.children.length; i++) {
          const child = this.children[i];
          if(child.id == id) {
            return Some(child);
          } else if(child.isContainer()) {
            const childSuccessor = (<ScreenContainerViewModel>child).getComponentById(id);
            if(childSuccessor.isDefined()) {
              return childSuccessor;
            }
          }
        }
      }
      return None();
    }


    static create(shared: ScreenSharedViewModel,
                  externalEventBus: ScreenExternalEventBus,
                  parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                  context: VariableId,
                  definition: ModalContainerDefinition,
                  componentScreenId: string,
                  ref: ModalContainerRef,
                  refScreenId: string,
                  componentState: ModalContainerState,
                  refState: ModalContainerRefState,
                  serverModel: ScreenInstanceServerModel,
                  componentsState: ScreenComponentsState,
                  createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {

      return new ModalContainerViewModel(shared, externalEventBus, parent, definition, componentScreenId, ref, refScreenId, componentState, refState, context, serverModel, componentsState, createViewModel);
    }


  onClosed() {
    if(this.ignoreNextOnClose + 500 < Date.now()) {
      this.close();
    }
  }

  close() {
    if(this.uncoveredAndVisible && !this.disabled && !this.inProgressDecline) {
      this.inProgressDecline = true;
      this.serverModel.closeModal(this.componentRefPath(), false);
      this.ignoreNextOnClose = Date.now();
    }
  }

  confirm() {
    if(this.uncoveredAndVisible && !this.disabled && !this.inProgressAccept) {
      this.serverModel.closeModal(this.componentRefPath(), true);
      this.ignoreNextOnClose = Date.now();

    }
  }

}

