import {ConstantApi, FunctionApi, SuggestionsApi, VariableApi} from "./expression-functions.model";
import {BusinessVariableType, VariableTypePath} from "@shared-model";
import {___, BusinessEntityTypeId} from "@utils";
import {VariableContext} from "@screen-common";

export class SuggestionEntries {
  constructor(readonly entries: Array<SuggestionEntry>) {
  }

  static of(suggestionsApi: SuggestionsApi) {


    const functions =  ___(suggestionsApi.functions).map(SuggestionEntry.functionApi).sortBy(e => e.mainInfo).value();
    const constants =  ___(suggestionsApi.constants).map(SuggestionEntry.constantApi).sortBy(e => e.mainInfo).value();
    const variables =  ___(suggestionsApi.variables).map(SuggestionEntry.variableApi).sortBy(e => e.mainInfo).value();

    return new SuggestionEntries(variables.concat(constants).concat(functions));
  }
}

export class SuggestionEntry {
  constructor(readonly type: "class"|"constant"|"enum"|"function"|"interface"|"keyword"|"method"|"namespace"|"property"|"text"|"type"|"variable",
              readonly mainInfo: string,
              readonly tooltip: string,
              readonly additionalInfo: string,
              readonly apply: string,
              readonly rawValue: string) {
  }

  static of(type: "class"|"constant"|"enum"|"function"|"interface"|"keyword"|"method"|"namespace"|"property"|"text"|"type"|"variable",
            mainInfo: string,
            tooltip: string,
            additionalInfo: string,
            applyValue: string,
            rawValue: string) {
    return new SuggestionEntry(type, mainInfo, tooltip, additionalInfo, applyValue, rawValue);
  }

  static text(value: string, detail: string) {
    return SuggestionEntry.of("text", '"'+value+'"', "", detail, value, value);
  }

  static variable(variableName: string, variableTypeFriendlyName: string, variableDisplayName: string, detail: string) {
    return SuggestionEntry.of("variable", variableDisplayName.length > 0 ? variableDisplayName : variableName, variableTypeFriendlyName, detail, variableName, variableName);
  }

  static variableApi(api: VariableApi) {
    return SuggestionEntry.variable(api.name, api.typeName, "", "");
  }

  static constant(label: string, tpe: string, detail: string) {
    return SuggestionEntry.of("constant", label, tpe, detail, label, label);
  }

  static constantApi(api: ConstantApi) {
    return SuggestionEntry.constant(api.name, api.typeName, "");
  }

  static function(label: string, info: string, detail: string, apply: string) {
    return SuggestionEntry.of("function", label, info, detail, apply, label);
  }

  static functionApi(api: FunctionApi) {
    return SuggestionEntry.function(
      api.name+ "(" + api.params.map(p => "_").join(", ") + ")",
      api.name + "(" + api.params.map(p => p.name + ": " + p.typeName).join(", ") + "): " + api.returnTypeName,
      api.doc,
      api.name+ "(");
  }

  getBoost() {
    switch (this.type) {
      case "variable":
        return 4;
      case "constant":
        return 3;
      case "text":
        return 2;
      case "function":
        return 1;
      default:
        return 0;
    }
  }
}

export class BusinessEntitySuggestionEntry {
  constructor(readonly id: BusinessEntityTypeId,
              readonly name: string,
              readonly identifier: string) {
  }
}

export interface ExpressionSuggestionsProvider {
  getSuggestions(): Promise<Array<SuggestionEntry>>;

  validateFunction(functionName: string): boolean;

  validateVariable(variable: VariableTypePath): boolean;

  getBindableVariables(): Promise<Array<BindableVariable>>;

  getReadableVariables(): Promise<Array<ReadableVariable>>;

  businessEntities(): Array<BusinessEntitySuggestionEntry>;
}


export class BindableVariable {
  constructor(readonly context: VariableContext,
              readonly path: VariableTypePath,
              readonly variableType: BusinessVariableType|null) {}

  getVariableFriendlyTypeName(): string {
    if(this.variableType !== null) {
      return this.variableType.friendlyTypeName();
    } else {
      return "";
    }
  }

  toReadableVariable() {
    return new ReadableVariable(this.path, this.variableType);
  }
}


export class ReadableVariable {
  constructor(readonly path: VariableTypePath,
              readonly variableType: BusinessVariableType|null) {}

  getVariableFriendlyTypeName(): string {
    if(this.variableType !== null) {
      return this.variableType.friendlyTypeName();
    } else {
      return "";
    }
  }
}
