import {None, Option, Typed} from "@utils";
import {BusinessVariableType, BusinessVariableTypeFactory} from "./variables/BusinessVariables";

export class ExpressionWithAst {

  constructor(public expression: string,
              public returnType: Option<Typed<BusinessVariableType>>) {
  }

  static empty() {
    return new ExpressionWithAst("", None());
  }

  static of(expression: string) {
    return new ExpressionWithAst(expression, None());
  }

  trim() {
    return new ExpressionWithAst(this.expression.trim(), this.returnType);
  }

  isEmpty() {
    return this.expression.trim().length === 0;
  }

  nonEmpty() {
    return this.expression.trim().length > 0;
  }

  static copy(other: ExpressionWithAst) {
    return new ExpressionWithAst(other.expression, Option.copy(other.returnType).map(t => BusinessVariableTypeFactory.copyTyped(t)));
  }

  static copyOptional(other: Option<ExpressionWithAst>) {
    return Option.copy(other).map(ExpressionWithAst.copy);
  }

  isEqual(other: ExpressionWithAst) {
    return this.expression == other.expression && this.returnType.equals(other.returnType);
  }

  static isEqual(that: ExpressionWithAst, other: ExpressionWithAst) {
    return that.expression == other.expression && that.returnType.equals(other.returnType);
  }
}
