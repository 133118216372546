import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel, StringProperty,
  TextsProperties
} from "@screen-common";
import {
  OptionalBooleanProperty,
  OptionalI18nTextProperty,
  OptionalStringProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class PasswordInputComponentRef extends InputComponentRef {
  static className = "PasswordInputComponentRef";

  className() {
    return PasswordInputComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: PasswordInputComponentRef): PasswordInputComponentRef {
    return new PasswordInputComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("PasswordInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = PasswordInputComponentRef.DEFAULT_PROPERTIES;

}

export class PasswordInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static className = "PasswordInputComponentDefinition";

  static MODEL = "model";
  static ON_CHANGE = "onChange";


  className() {
    return PasswordInputComponentDefinition.className;
  }
  typeName() {
    return "PasswordInput";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,PasswordInputComponentDefinition.DEFAULT_PROPERTIES);
  }

  get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)}
  get icon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("icon", this.defaultPropertiesProvider)};
  get iconPosition(): StringProperty {return this.properties.getStringProperty("iconPosition", this.defaultPropertiesProvider)};

  // deprecated
  get startIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("startIcon", this.defaultPropertiesProvider)};
  // deprecated
  get endIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("endIcon", this.defaultPropertiesProvider)};
  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);




  static copy(other: PasswordInputComponentDefinition): PasswordInputComponentDefinition {
    return new PasswordInputComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [PasswordInputComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("PasswordInputComponentDefinition",
    (name: string) => {switch (name) {
      case "placeholder": return OptionalI18nTextProperty.disabled();
      case "innerShadow": return OptionalBooleanProperty.disabled(true);
      case "paddingTop": return OptionalStringProperty.disabled("0.1cm");
      case "paddingLeft": return OptionalStringProperty.disabled("0.1cm");
      case "paddingBottom": return OptionalStringProperty.disabled("0.1cm");
      case "paddingRight": return OptionalStringProperty.disabled("0.1cm");
      case "startIcon": return OptionalStringProperty.disabled("confirm");
      case "endIcon": return OptionalStringProperty.disabled("confirm");
      case "icon": return OptionalStringProperty.disabled("confirm");
      case "iconPosition": return StringProperty.of("start");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
