import {
  I18nText,
  LocalDate,
  LocalDateTime,
  LocalTime,
  myRequestAnimationFrame,
  None,
  NoneSingleton,
  Option,
  Some,
  toLocalDateTimeFromTimezoned,
  toTimezonedLocalDateTimeFromLocal,
  VariableId
} from "@utils";
import {
  CssBuilder,
  DateInputComponentDefinition,
  DateTimeInputComponentDefinition,
  DateTimeInputComponentRef,
  DateTimeInputDisplayMode,
  TimeInputComponent
} from "@screen-common";
import {DateTimeInputComponentRefState, DateTimeInputComponentState} from "./DateTimeInputComponentState";
import {DateTimeVariable} from "@shared-model";
import {I18nService, UserSettingsStateService} from "@shared";
import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "../screen-component.view-model";
import {ScreenSharedViewModel} from "../..";
import {ComponentsCommon} from "../ComponentsModel";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class DateTimeInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "DateTimeInput";

  public value: LocalDateTime|null = null;

  private inputModel: string|undefined = undefined;
  private readableModel: string|undefined = undefined;
  public textModel: string|undefined;

  public dateTimePickerModel: LocalDateTime|null = null;
  public timePickerModel: LocalTime | null = null;
  public datePickerModel: LocalDate | null = null;

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";

  public required: boolean = false;

  public keyboardInput = false;

  public focused = false;
  public anchorVisible: boolean = false;

  public innerCss: string = "";
  public innerCssClasses: string = "";
  public combinedCss = "";
  public combinedCssClasses: string = "";

  private timePicked = false;
  private datePicked = false;
  standardMode: boolean = true;
  deadlineMode: boolean = false;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: DateTimeInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: DateTimeInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: DateTimeInputComponentState,
              readonly refState: DateTimeInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel,
              readonly userSettingsService: UserSettingsStateService,
              readonly i18nService: I18nService) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }


  onFocus() {
    if(!this.disabled && !this.focused) {
      this.datePicked = false;
      this.timePicked = false;

      this.focused = true;
      this.bindCorrectModel();

      if (this.value !== null) {
        const timezoned = this.i18nService.toTimezonedLocalDateTimeFromUTC(this.value);
        this.timePickerModel = LocalTime.copy(timezoned.time);
        this.datePickerModel = LocalDate.copy(timezoned.date);
      } else {
        this.timePickerModel = null;
        this.datePickerModel = null;
      }
      this.dateTimePickerModel = this.value;
    }
  }

  onDatePicked() {
    this.datePicked = true;
    if (this.datePicked && this.timePicked) {
      this.onPicked();
    }
  }

  onTimePicked() {
    this.timePicked = true;
    if (this.datePicked && this.timePicked) {
      this.onPicked();
    }
  }

  onPicked() {
    if(this.deadlineMode) {
      this.value = this.dateTimePickerModel;
    } else {
      const formatted = new LocalDateTime(this.datePickerModel!, this.timePickerModel!).isoSimpleFormattedToMinutes();
      const parsed = this.i18nService.parseDateTime(formatted);
      if (parsed.isSuccess()) {
        this.value = parsed.result;
      } else {
        throw new Error("Invalid date");
      }
    }

    this.updateTextModel();
    this.updateValueChanged();
    this.closePicker();
    this.bindCorrectModel();
  }


  onChanged() {

    if(this.deadlineMode) {
      if(this.dateTimePickerModel !== null) {
        this.value = this.dateTimePickerModel;
        this.updateTextModel();
      }
    } else {
      if(this.datePickerModel !== null && this.timePickerModel !== null) {
        const formatted = new LocalDateTime(this.datePickerModel!, this.timePickerModel!).isoSimpleFormattedToMinutes();
        const parsed = this.i18nService.parseDateTime(formatted);
        if (parsed.isSuccess()) {
          this.value = parsed.result;
          this.updateTextModel();
        }
      }
    }

  }

  parseTextModel() {
    if (this.textModel && this.textModel.trim().length > 0) {
      const dateTime = this.i18nService.parseDateTime(this.textModel);

      this.value = null;

      if(dateTime.isSuccess()) {
        this.value = dateTime.result;
      } else if(this.deadlineMode) {
        const date = LocalDate.of(this.textModel);
        if(date.isSuccess()) {
          const localDateEnd = LocalDateTime.fromLocalDateEnd(date.result);
          this.value = toLocalDateTimeFromTimezoned(toTimezonedLocalDateTimeFromLocal(localDateEnd, this.userSettingsService.getEffectiveTimeZone()));
        }
      }

      this.updateTextModel();
    }
  }

  updateTextModel() {
    if (this.value === null) {
      this.inputModel = undefined;
      this.readableModel = undefined;
    } else {
      let dateOrTime: LocalDateTime|LocalDate = this.value;
      if(this.deadlineMode) {
        dateOrTime = this.i18nService.toPreviousDayIfMidnight(this.value);
      }

      if(dateOrTime instanceof LocalDate) {
        this.inputModel = dateOrTime.formatted();
        this.readableModel = dateOrTime.formattedFromNow();
      } else {
        this.inputModel = this.i18nService.formatEditableDateTime(dateOrTime);
        this.readableModel = this.i18nService.formatShortDateTime(dateOrTime);
      }
    }
    this.bindCorrectModel();
  }

  closePicker = () => {
    this.focused = false;
    this.updateTextModel();
  }

  private bindCorrectModel() {
    if (this.focused) {
      this.textModel = this.inputModel;
    } else {
      this.textModel = this.readableModel;
    }
  }

  onChange = () => {
    if (this.textModel == undefined || this.textModel.trim().length === 0) {
      this.inputModel = undefined;
      this.readableModel = undefined;
      this.bindCorrectModel();
    }
  };




  setNow = () => {
    const now = LocalDateTime.now();
    const timezoned = this.i18nService.toTimezonedLocalDateTimeFromUTC(now);
    this.timePickerModel = timezoned.time;
    this.datePickerModel = timezoned.date;
    this.value = now;
    this.updateValueChanged();
    this.closePicker();
    this.bindCorrectModel();
    this.onChange();
  };

  clear = () => {
    this.timePickerModel = null;
    this.value = null;
    this.updateTextModel();
    this.updateValueChanged();
    this.closePicker();
    this.bindCorrectModel();
    this.onChange();
  };



  textInputAccepted() {
    this.parseTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  }

  onEditionEnded() {
    myRequestAnimationFrame(() => {
      this.parseTextModel();
      this.updateValueChanged();
      this.updateTextModel();
    });
  }

  updateValueChanged() {
    if (this.uncoveredAndVisible) {

      if (this.value === null) {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), TimeInputComponent.model, DateTimeInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, Some(new DateTimeVariable(this.value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), TimeInputComponent.model, new DateTimeVariable(this.value), DateTimeInputComponentDefinition.ON_CHANGE);
      }
    }
  }


  updateComponent(deep: boolean): void {

    const outsideCssBuilder = new CssBuilder();
    const innerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toSizeCss(outsideCssBuilder, this.definition.sizeProperties, this.componentState.boxState);
    ComponentViewModelUtils.toOuterShadowCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    ComponentViewModelUtils.toBackgroundCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toPaddingsCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toTextCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).getOrElse(I18nText.ofCurrent("dd-mm-yyyy hh:mm")).getCurrentWithFallback();
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(false);

    this.value = this.componentState.model.valueOrDefault(None()).getOrNull();

    const displayMode = DateTimeInputDisplayMode.of(this.definition.displayMode.currentValue(() => this.componentState.displayMode).valueOrDefault(DateTimeInputDisplayMode.standard.name));

    this.deadlineMode = displayMode === DateTimeInputDisplayMode.deadline;
    this.standardMode = displayMode === DateTimeInputDisplayMode.standard;

    this.updateTextModel();
    this.bindCorrectModel();


    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(innerCssBuilder, innerShadow);

    super.updatePosition();

    this.combinedCss = outsideCssBuilder.toCss() + this.sizeCss;
    this.combinedCssClasses = outsideCssBuilder.toCssClasses();

    this.innerCss = innerCssBuilder.toCss();
    this.innerCssClasses = innerCssBuilder.toCssClasses();
  }



}

