import {Component} from '@angular/core';
import {RadioEntry} from "@shared";
import {Trilean} from "@utils";

@Component({
  templateUrl: './styles-page.component.html'
})
export class StylesPageComponent {
  radioEntries: RadioEntry[] = [{name: "Option A"}, {name: "Option B"}, {name: "Option C"}];

  trileanTrue = Trilean.TRUE;
  trileanFalse = Trilean.FALSE;
  trileanUnknown = Trilean.UNKNOWN;

}
