export class LabelPosition {
  constructor(readonly name: string) {}

  isVisible() {
    return this.name === LabelPosition.leftAlign.name ||
           this.name === LabelPosition.flexAlign.name ||
           this.name === LabelPosition.topAlign.name;
  }

  isFlexAlign() {
    return this.name === LabelPosition.flexAlign.name;
  }

  isTopAlign() {
    return this.name === LabelPosition.topAlign.name;
  }

  static leftAlign = new LabelPosition("leftAlign");
  static topAlign = new LabelPosition("topAlign");
  static flexAlign = new LabelPosition("flexAlign");
  static noneAlign = new LabelPosition("noneAlign");

  static values: Array<LabelPosition> = [LabelPosition.topAlign, LabelPosition.leftAlign, LabelPosition.flexAlign, LabelPosition.noneAlign];

  static byName(name: string): LabelPosition {

    switch(name) {
      case LabelPosition.leftAlign.name: return LabelPosition.leftAlign;
      case LabelPosition.topAlign.name: return LabelPosition.topAlign;
      case LabelPosition.flexAlign.name: return LabelPosition.flexAlign;
      case LabelPosition.noneAlign.name: return LabelPosition.noneAlign;
      default: throw new Error("Unknown label position: " + name);
    }
  }

  static copy(other: LabelPosition) {
    return LabelPosition.byName(other.name);
  }
}
