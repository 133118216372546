<my-organization-node-select (nodeChange)="viewModel.onInternalChange($event)"
                             [inAllApplications]="false"
                             [fromApplication]="applicationId"
                             [fromGlobal]="fromGlobal"
                             [(node)]="viewModel.internalModel"
                             [readOnly]="viewModel.readOnly"
                             [preview]="viewModel.preview"
                             [persons]="persons"
                             [departments]="departments"
                             [groups]="groups"
                             [smallIcon]="smallIcon"/>
