import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {$$, i18nLanguage, I18nText, Language} from "@utils";

@Component({
  selector: 'my-i18n-text-input',
  templateUrl: './i18n-text-input.component.html',
  host: {
    "[class.preview]": "preview",
    "[class.disabled]": "disabled",
  }
})
export class I18nTextInputComponent implements OnInit {
  private _text!: I18nText;
  get text(): I18nText {return this._text;}
  @Input() set text(value: I18nText) {this._text = value; this.onTextChanged();}
  @Output() textChange = new EventEmitter<I18nText>();
  @Output() textChangedOnBlur = new EventEmitter<I18nText>();

  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  @Output() immediateChange = new EventEmitter<I18nText>();

  @Input() disabled: boolean = false;
  @Input() multiline: boolean = false;
  @Input() large: boolean = false;
  @Input() placeholder?: string;
  @Input() hint: string|undefined;

  @Input() preview: boolean = false;

  @Input() focusOnShow = false;

  language = i18nLanguage();
  missingCurrentLanguage = false;
  internalText: string = "";
  private internalModelPreTemporaryChange: I18nText|null = null;
  internalModel!: I18nText;
  @Output() onEscape = new EventEmitter<unknown>();

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    if(this.placeholder == undefined) {
      this.placeholder = "";
    }
  }

  private updateMissingCurrentLanguage() {
    if(this.internalModel) {
      this.missingCurrentLanguage = this.internalModel.get(i18nLanguage()).isEmpty() && this.internalModel.notEmpty();
    } else {
      this.missingCurrentLanguage = false;
    }
  }

  private updateInternalModel() {
    if(this.internalModel) {
      this.internalText = this.internalModel.get(this.language).getOrElse("");
    } else {
      this.internalText = "";
    }
  }


  nextLanguage() {
    const currentText = this.internalModelPreTemporaryChange == null ? this.internalModel.get(this.language) : this.internalModelPreTemporaryChange.get(this.language);
    this.internalModelPreTemporaryChange = null;
    if(currentText.isEmpty() && this.internalText.trim().length > 0 || currentText.isDefined() && currentText.get() != this.internalText.trim()) {
      this.internalModel.set(this.language, this.internalText);
      this.text = this.internalModel;
    }

    if(this.language.isPL()) {
      this.language = Language.EN;
    } else {
      this.language = Language.PL;
    }
    this.updateInternalModel();
    this.updateMissingCurrentLanguage();
  };

  onTextChanged() {
    this.internalModelPreTemporaryChange = null;
    this.internalModel = this.text ? I18nText.copy(this.text) : I18nText.empty();

    if(!this.language) {
      this.language = i18nLanguage();
    }

    if(this.internalModel && this.internalModel.get(this.language).isEmpty() && this.internalModel.notEmpty()) {
      this.language = this.internalModel.fallbackLanguage();
    }

    this.updateInternalModel();
    this.updateMissingCurrentLanguage();
  }

  internalOnBlur() {
    const currentText = this.internalModelPreTemporaryChange == null ? this.internalModel.get(this.language) : this.internalModelPreTemporaryChange.get(this.language);
    this.internalModelPreTemporaryChange = null;
    if(currentText.isEmpty() && this.internalText.trim().length > 0 || currentText.isDefined() && currentText.get() != this.internalText.trim()) {
      this.internalModel.set(this.language, this.internalText);
      this.text = this.internalModel;
      this.textChange.emit(this.text);
      this.textChangedOnBlur.emit(this.text);
    }
    this.updateMissingCurrentLanguage();
    this.blur.emit();
  };


  internalOnChange() {
    const currentText = this.internalModel.get(this.language);
    if(currentText.isEmpty() && this.internalText.trim().length > 0 || currentText.isDefined() && currentText.get() != this.internalText.trim()) {
      this.internalModelPreTemporaryChange = I18nText.copy(this.internalModel);
      this.internalModel.set(this.language, this.internalText);
      this.immediateChange.emit(this.internalModel);
    }
  }


  onEnter() {
    if(!this.multiline) {
      $$(this.viewContainerRef).findOrError("input").blur();
    }
  }
}

