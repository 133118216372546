import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {NavigationService, SessionServiceProvider} from "@shared";

@Injectable({
  providedIn: 'root',
})
export class NonLoggedInGuard implements CanActivate {


  constructor(private readonly sessionServiceProvider: SessionServiceProvider,
              private readonly navigationService: NavigationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.sessionServiceProvider.getSessionService(sessionService => {

        if (sessionService.isLoggedIn()) {
          this.navigationService.navigateByUrlReplaceCurrent('/');
          resolve(false);
        } else {
          if(state.url !== '/login') {
            this.navigationService.clearLastRequestedUrl();
          }
          resolve(true);
        }
      });
    });


  }
}

