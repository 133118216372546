import {Component, Input} from "@angular/core";

@Component({
  selector: 'my-draw-in-logo',
  templateUrl: './draw-in-logo.component.html',
})
export class DrawInLogoComponent {

  @Input() large: boolean = false;
  @Input() withName: boolean = false;
}
