import {millsToMinsRound, minsToMills} from "./Time";

export function formatDurationClockSeconds(seconds: number): string {
  return formatDurationClock(seconds * 1000);
}

export function formatDurationClock(millis: number): string {
  if(millis < 0) {
    return "- " + formatDurationClock(-millis);
  } else {
    let acc = "";

    const totalSeconds = Math.round(millis / 1000);
    const seconds = totalSeconds % 60;

    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;

    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 8;

    const totalDays = Math.floor(totalHours / 8);

    if (totalDays > 0)
      acc += totalDays + "d " + padded(hours) + ":" + padded(minutes) + ":" + padded(seconds);
    else if (hours > 0) {
      acc += padded(hours) + ":" + padded(minutes) + ":" + padded(seconds);
    } else {
      acc += padded(minutes) + ":" + padded(seconds);
    }

    return acc;
  }
}

export function formatDuration(millis: number): string {
  if(millis < 0) {
    return "- " + formatDuration(-millis);
  } else {
    let acc = "";

    const totalSeconds = Math.round(millis / 1000);
    const seconds = totalSeconds % 60;

    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;

    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 8;

    const totalDays = Math.floor(totalHours / 8);

    if (totalDays > 0) {
      acc += totalDays + "d "
    }
    if (hours > 0) {
      acc += hours+"h "
    }
    if(totalDays == 0 && minutes > 0) { // do not show minutes if there are days
      acc += minutes+"m ";
    }
    if (totalHours == 0 && seconds > 0) { // do not show seconds if there are hours
      acc += seconds+"s ";
    }

    return acc.trim();
  }
}

function padded(value: number): string {
  if (value < 10) {
    return "0" + value;
  }
  else {
    return value.toString();
  }
}

/**
 * @param ignoreSeconds - it will not display seconds if time is larger than 10 minutes
 */
export function toSecondsTimespan(mills: number, ignoreSeconds: boolean): string {
  const minute = 60 * 1000;
  const minutesString = mills < minute ? "" : toTimespan(mills);

  if(!ignoreSeconds || mills < 10 * minute) {
    const secondsString = Math.round((mills / 1000) % 60) +"s";
    if(minutesString.length > 0) {
      return minutesString + " " + secondsString;
    } else {
      return secondsString;
    }
  } else {
    return minutesString;
  }
}

export function toTimespan(mills: number): string {
  return toTimespanFromMins(millsToMinsRound(mills));
}

export function fromTimespan(millis: string): number {
  return minsToMills(fromTimespanToMins(millis));
}

export function toTimespanFromMinsClock(mins: number): string {
  if(mins < 0) {
    return "-"+toTimespanFromMinsClock(-mins);
  } else {
    let acc = "";

    const roundedMins = Math.round(mins);

    const minutes = roundedMins % 60;
    const days = Math.floor(roundedMins / 480);
    const hours = Math.floor((roundedMins % 480) / 60);

    const minutesString = (minutes < 10 ? "0" : "") + minutes;
    const hoursString = (hours < 10 ? "0" : "") + hours;

    if (days > 0) {
      acc += days + "d, ";
    }

    acc += hoursString + ":" + minutesString;

    return acc;
  }
}

export function toTimespanFromMinsWithoutDays(mins: number): string {
  if(mins < 0) {
    return "-"+toTimespanFromMinsWithoutDays(-mins);
  } else {
    let acc = "";

    const minutes = Math.round(mins);
    if (minutes == 0) {
      return "0m";
    }

    const hours = Math.floor(minutes / 60);
    let rem = minutes % 60;

    if (hours > 0) {
      acc += hours + "h";
      if (rem > 0) {
        acc += " ";
      }
    }

    if (rem > 0) {
      acc += rem + "m";
    }

    return acc;
  }
}

/**
 * @deprecated The method should not be used, because of complicated hours in days (8 or 24). Use toTimespanFromMinsWithoutDays
 */
export function toTimespanFromMins(mins: number, hoursInDay: number = 8): string {
  if(mins < 0) {
    return "-"+toTimespanFromMins(-mins, hoursInDay);
  } else {
    let acc = "";

    const minutes = Math.round(mins);
    if (minutes == 0) {
      return "0m";
    }

    const minutesInDay = hoursInDay * 60;

    const days = Math.floor(minutes / minutesInDay);
    let rem = minutes % minutesInDay;

    if (days > 0) {
      acc += days + "d";
      if (rem > 0) {
        acc += " ";
      }
    }

    const hours = Math.floor(rem / 60);
    rem = rem % 60;

    if (hours > 0) {
      acc += hours + "h";
      if (rem > 0) {
        acc += " ";
      }
    }

    if (rem > 0) {
      acc += rem + "m";
    }

    return acc;
  }
}

/**
 * @deprecated The method should not be used, because of complicated hours in days (8 or 24). Use toTimespanFromSecondsWithoutDays
 */
export function toTimespanFromSeconds(seconds: number, hoursInDay: number = 8): string {
  if(seconds < 0) {
    return "-" + toTimespanFromSeconds(-seconds, hoursInDay);
  } else {
    let mins = Math.floor(seconds/60);
    let sec = seconds % 60;
    return mins > 0
      ? toTimespanFromMins(Math.floor(seconds/60), hoursInDay) +(sec > 0 ? (" " + sec + "s") : "")
      : sec + "s";
  }
}

export function toTimespanFromSecondsWithoutDays(seconds: number): string {
  if(seconds < 0) {
    return "-" + toTimespanFromSecondsWithoutDays(-seconds);
  } else {
    let mins = Math.floor(seconds/60);
    let sec = seconds % 60;
    return mins > 0
      ? toTimespanFromMinsWithoutDays(Math.floor(seconds/60)) + " " + sec + "s"
      : sec + "s";
  }
}

export function fromTimespanToMins(timespan: string): number {
  throw new Error("Not implemented");
  // return _.chain(timespan.split(" "))
  //   .map((a: string) => {
  //     let value = parseInt(a.slice(0, -1));
  //     switch (a.slice(-1)) {
  //       case 'd':
  //         value *= 480;
  //         break;
  //       case 'h':
  //         value *= 60;
  //         break;
  //       case 'm':
  //         break;
  //       default:
  //         value = 0;
  //         break;
  //     }
  //     return value;
  //   })
  //   .reduce((a, b) => { return a + b; }, 0)
  //   .value();
}

export function toDoubleDigit(date: number): string {
  if (date < 10) {
    return "0" + date;
  } else {
    return "" + date;
  }
}
