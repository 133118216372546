import {$$Element} from "./$$";

export function findAncestorBackgroundColor(element: $$Element): string|null {

  const background = window.getComputedStyle(element.getAsElement()).backgroundColor;

  if (background && background.indexOf('transparent') < 0 && background.indexOf('rgba(0, 0, 0, 0)') < 0) {
    return background;
  } else {
    const parent = element.parent();
    if(parent) {
      return findAncestorBackgroundColor(parent);
    }
  }
  return null;
}
