<button (click)="viewModel.onChange()" class="entry"
        [class.checked]="viewModel.checked"
        [class.unknown]="viewModel.unknown">
  <svg viewBox="0 0 18 18" class="checkBoxSvg">
    <rect class="box"
          [style.fill]="(viewModel.checked ? viewModel.iconBackgroundColorCss : null) | sanitizeStyle"
          [style.stroke]="(viewModel.checked ? viewModel.iconBackgroundColorCss : null) | sanitizeStyle"
          x="1.967" y="1.967" width="14.066" height="14.066" rx="3.6"/>
    <path class="ticker"
          d="M 7.164,12.965 C 6.87,12.965 6.59,12.85 6.38,12.64 l -2.37,-2.37 c -0.255,-0.255 -0.255,-0.67 0,-0.924 0.255,-0.255 0.67,-0.255 0.924,0 L 7.164,11.58 13.067,5.675 c 0.255,-0.255 0.668,-0.255 0.924,0 0.255,0.255 0.255,0.67 0,0.924 L 7.95,12.64 C 7.74,12.85 7.458,12.965 7.164,12.965 Z"/>
    <text class="unknown" x="6.5" y="13" font-size="12">?</text>
  </svg>
  <div class="label">{{viewModel.valueLabel}}<span class="requiredIcon" *ngIf="viewModel.requiredIconVisible">*</span>
  </div>
</button>
