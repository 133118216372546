import {
  __, ___, arrayMove, AutomaticActionId,
  FormElementId,
  FormElementRefId, GridSize, GridXY, i18n,
  I18nText,
  None, nullIfUndefined,
  Option,
  removeFromArray, removeFromArrayBy, replaceArrayContent, required, ScriptInputType,
  Some,
  toastr, Trilean,
  Typed,
  valueOrDefault
} from "@utils";
import {
  ArrayVariable,
  ArrayVariableType,
  ArrayVariableValidation,
  BusinessVariable,
  BusinessVariableType,
  DateTimeVariableType,
  DateTimeVariableValidation,
  DateVariableType,
  DateVariableValidation,
  ExpressionWithAst,
  FileArrayValidation,
  FileVariableType,
  FileVariableValidation,
  NumberVariable,
  NumberVariableType,
  NumberVariableValidation,
  ObjectVariableType,
  OrganizationNodeVariableType,
  PersonVariableType,
  ProcessNodeId,
  StringVariable,
  StringVariableType,
  StringVariableValidation,
  StringVariableValidationType,
  TimeVariableType,
  TimeVariableValidation,
  VariableTypePath
} from "@shared-model";
import {
  FormElement,
  FormElementRef,
  FormModelValidation,
  InputElement,
  InputElementRef,
  StaticElement
} from "../model/FormModel";
import {Label, LabelRef} from "../model/elements/Label";
import {ActionButton, ActionButtonRef} from "../model/elements/ActionButton";
import {TextArea, TextAreaRef} from "../model/elements/TextArea";
import {DropList, DropListRef} from "../model/elements/DropList";
import {RadioButton, RadioButtonRef} from "../model/elements/RadioButton";
import {Checkbox, CheckboxRef} from "../model/elements/Checkbox";
import {AttachmentField, AttachmentFieldRef} from "../model/elements/AttachementField";
import {DateField, DateFieldRef} from "../model/elements/DateField";
import {
  AdvancedActionType,
  FormActionButtonChanged,
  FormActionButtonRefChanged,
  FormAttachmentFieldChanged,
  FormAttachmentFieldRefChangedV1,
  FormCheckboxChanged,
  FormCheckboxRefChangedV1,
  FormDateFieldChanged,
  FormDateFieldRefChangedV1,
  FormDropListChanged,
  FormDropListRefChangedV1,
  FormElementRemoved,
  FormInputElementRefRemoved,
  FormLabelChanged,
  FormLabelRefChanged,
  FormMapFieldChanged,
  FormMapFieldRefChanged,
  FormRadioButtonChanged,
  FormRadioButtonRefChangedV1, FormRepeatableSectionCreated,
  FormSectionCreated,
  FormSectionHeightChanged,
  FormSectionReferred,
  FormSectionRefRemoved,
  FormSectionRemoved,
  FormSectionsRefsOrderChangedV1,
  FormStaticElementRefRemoved,
  FormTextAreaChanged,
  FormTextAreaRefChangedV1, FormValidationChanged,
  ModelEvent, ProcessAutomaticAction,
  SectionNameChanged,
  SectionPropertiesChangedV1,
  SectionRefPropertiesChangedV1,
  TooltipChanged,
  VariableAddedV1, VariableExpressionUpdated, VariableNameChangedV1,
  VariableRemovedV1,
  VariableTypeUpdatedV1
} from "../../designer.module/services/process/legacy-form-events";
import {LabelPosition} from "../model/FormField";
import {DropDownSelectorOption, DropMenuState, FormSectionId, FormSectionRefId} from "@shared";
import {Observable, Subject} from "rxjs";
import {
  ProcessMapEditorModelEventBus
} from "../../designer.module/process-map-editor/map-editor/process-map-editor.model-event-bus";
import {GridProcessModel} from "../model/GridProcessModel";
import {GridProcessNode} from "../model/GridProcessNode";
import {ProcessMapCommonViewModel} from "./ProcessMapCommonViewModel";
import {ScriptAction, ScriptValue} from "@screen-common";

export class LegacyElementViewModel {

  isString = false;
  isNumber = false;
  isDate = false;
  isTime = false;
  isDateTime = false;
  isOrganizationNode = false;
  isPerson = false;
  isArrayOfString = false;
  isArrayOfNumber = false;
  isArrayOfOrganizationNode = false;

  elementId: FormElementId = null!;

  className: string = "";
  cssClassName: string = "";
  hasText: boolean = false;
  text: I18nText = I18nText.empty();

  boldVisible: boolean = false;
  bold: boolean = false;

  readOnlyVisible: boolean = false;
  readOnlyExpression: string = "";
  requiredVisible: boolean = false;
  requiredExpression: string = "";
  hiddenExpression: string = "";
  hasTooltip: boolean = false;
  tooltip: I18nText = I18nText.empty();
  variable: LegacyVariableViewModel|null = null;

  hasPlaceholder = false;
  placeholder: I18nText = I18nText.empty();

  entriesListType: "calculated"|"constant" = "constant";

  hasStaticEntries = false;
  staticEntries: Array<{value: string|number|null, checked: boolean}> = [];
  staticDefaultValueLabel?: string;
  entriesExpression: string = "";


  numberPrecision: number = 0;
  numberPercent = false;
  numberUnitVisible = false;
  numberUnit = "";
  numberMinValueVisible = false;
  numberMinValue = 0;
  numberMaxValueVisible = false;
  numberMaxValue = 0;

  attachmentsMin: number = 0;
  attachmentsMax: number = 0;
  attachmentsAllowedExtensions: string = "";

  dateMinVisible: boolean = false;
  dateMinExpression: string = "";
  dateMaxVisible: boolean = false;
  dateMaxExpression: string = "";


  textAreaValidationType: string = StringVariableValidationType.AnyValidationType.name;
  textAreaValidationMask: string = "";
  textAreaValidationRegex: string = "";

  actionsRefs: Array<LegacyButtonActionRefViewModel> = [];

  static of(element: FormElement, variable: LegacyVariableViewModel|undefined, actions: Array<LegacyButtonActionViewModel>) {

    const viewModel = new LegacyElementViewModel();

    viewModel.elementId = element.id;

    viewModel.cssClassName = element.cssClassName();
    viewModel.className = element.className();

    viewModel.tooltip = element.tooltip;

    viewModel.hiddenExpression = element.hiddenExpression.map(e => e.expression).getOrElse("");

    viewModel.hasText = element instanceof Label || element instanceof ActionButton;
    if(element instanceof ActionButton) {
      viewModel.hasText = true;
      viewModel.text = element.label;
      viewModel.readOnlyVisible = true;
      viewModel.readOnlyExpression = element.readOnlyExpression.map(e => e.expression).getOrElse("");
      viewModel.actionsRefs = element.actions.map(actionRef => {
        const action = required(actions.find(a => a.id.id === actionRef.actionId.getLeft().id), "action");
        return new LegacyButtonActionRefViewModel(action);
      })
    } else if(element instanceof Label) {
      viewModel.hasText = true;
      viewModel.text = element.text;
      viewModel.boldVisible = true;
      viewModel.bold = element.bold;
    } else {
      const input = <InputElement>element;
      viewModel.readOnlyVisible = true;
      viewModel.readOnlyExpression = input.readOnlyExpression.map(e => e.expression).getOrElse("");
      viewModel.requiredVisible = true;
      viewModel.requiredExpression = input.requiredExpression.map(e => e.expression).getOrElse("");

      // viewModel.variable = required(variable, "variable");
      viewModel.variable = nullIfUndefined(variable);
      if(!variable) {
        toastr.info("No variable " + input.variableTypePath.toString()+" for input " + input.id.id);
      }
    }


    // inputs

    if(element instanceof TextArea) {
      viewModel.hasPlaceholder = true;
      viewModel.placeholder = element.placeholder;

      const validation = element.validationUnwrapped().getOrUndefined();

      const variableType = viewModel.requiredVariable.variableType;
      if(variableType instanceof NumberVariableType) {
        viewModel.numberPrecision = variableType.precision;
        viewModel.numberPercent = variableType.percent;
        viewModel.numberUnitVisible = variableType.unit.isDefined();
        viewModel.numberUnit = variableType.unit.getOrElse("");



        if(validation instanceof NumberVariableValidation) {
          viewModel.numberMinValueVisible = validation.minValue.isDefined();
          viewModel.numberMinValue = validation.minValue.getOrElse(0);
          viewModel.numberMaxValueVisible = validation.maxValue.isDefined();
          viewModel.numberMaxValue = validation.maxValue.getOrElse(0);
        }

      }

      if(variableType instanceof StringVariableType) {
        if(validation instanceof StringVariableValidation) {
          viewModel.textAreaValidationType = valueOrDefault(validation.validationType, StringVariableValidationType.AnyValidationType).name;
          viewModel.textAreaValidationMask = valueOrDefault(validation.mask, None()).getOrElse("");
          viewModel.textAreaValidationRegex = valueOrDefault(validation.regex, None()).getOrElse("");
        }
      }

    } else if(element instanceof DropList) {
      viewModel.hasStaticEntries = true;
      viewModel.entriesListType = element.entriesExpression.isDefined() ? "calculated" : "constant";
      viewModel.entriesExpression = element.entriesExpression.map(e => e.expression).getOrElse("");
      viewModel.staticEntries = element.entries.unwrappedValue().map(e => {
        const checked = element.unwrappedDefaultValue().exists(v => v.isEqual(e));
        return {value: e.value, checked: checked}
      });

    } else if(element instanceof RadioButton) {
      viewModel.hasStaticEntries = true;
      viewModel.entriesListType = element.entriesExpression.isDefined() ? "calculated" : "constant";
      viewModel.entriesExpression = element.entriesExpression.map(e => e.expression).getOrElse("");
      viewModel.staticEntries = element.entries.unwrappedValue().map(e => {
        const checked = element.unwrappedDefaultValue().exists(v => v.isEqual(e));
        return {value: e.value, checked: checked}
      });
    } else if(element instanceof Checkbox) {
      viewModel.hasStaticEntries = true;
      viewModel.entriesListType = element.entriesExpression.isDefined() ? "calculated" : "constant";
      viewModel.entriesExpression = element.entriesExpression.map(e => e.expression).getOrElse("");
      viewModel.staticEntries = element.entries.unwrappedValue().map(e => {
        const checked = element.defaultValue.unwrappedValue().some(v => v.isEqual(e));
        return {value: e.value, checked: checked}
      });
    } else if(element instanceof AttachmentField) {


      const validation = element.validation.map(Typed.value).getOrUndefined();

      viewModel.attachmentsMin = element.minFileCount();
      viewModel.attachmentsMax = element.maxFileCount();

      if(validation instanceof FileArrayValidation) {
        viewModel.attachmentsAllowedExtensions = validation.fileValidation.allowedExtensions.map(v => v.toUpperCase()).join(", ");
      }

    } else if(element instanceof DateField) {
      const validation = element.validation.map(Typed.value).getOrUndefined();
      if(validation instanceof DateVariableValidation) {
        viewModel.dateMinVisible = validation.minValue.exists(e => e.nonEmpty());
        viewModel.dateMinExpression = validation.minValue.map(v => v.expression).getOrElse("");
        viewModel.dateMaxVisible = validation.maxValue.exists(e => e.nonEmpty());
        viewModel.dateMaxExpression = validation.maxValue.map(v => v.expression).getOrElse("");
      } else if(validation instanceof DateTimeVariableValidation) {
        viewModel.dateMinVisible = validation.minValue.exists(e => e.nonEmpty());
        viewModel.dateMinExpression = validation.minValue.map(v => v.expression).getOrElse("");
        viewModel.dateMaxVisible = validation.maxValue.exists(e => e.nonEmpty());
        viewModel.dateMaxExpression = validation.maxValue.map(v => v.expression).getOrElse("");
      } else if(validation instanceof TimeVariableValidation) {
        viewModel.dateMinVisible = validation.minValue.exists(e => e.nonEmpty());
        viewModel.dateMinExpression = validation.minValue.map(v => v.expression).getOrElse("");
        viewModel.dateMaxVisible = validation.maxValue.exists(e => e.nonEmpty());
        viewModel.dateMaxExpression = validation.maxValue.map(v => v.expression).getOrElse("");
      }

    }

    viewModel.updateView();

    return viewModel;
  }

  get requiredVariable() {
    return required(this.variable, "variable");
  }


  updateView() {

    this.hasTooltip = this.tooltip.notEmpty();

    this.isString = this.variable !== null && this.variable.variableTypeName === "StringVariableType";
    this.isNumber = this.variable !== null && this.variable.variableTypeName === "NumberVariableType";
    this.isDate = this.variable !== null && this.variable.variableTypeName === "DateVariableType";
    this.isTime = this.variable !== null && this.variable.variableTypeName === "TimeVariableType";
    this.isDateTime = this.variable !== null && this.variable.variableTypeName === "DateTimeVariableType";
    this.isPerson = this.variable !== null && this.variable.variableTypeName === "PersonVariableType";
    this.isOrganizationNode = this.variable !== null && this.variable.variableTypeName === "OrganizationNodeVariableType";

    this.isArrayOfString = this.variable !== null && this.variable.variableTypeName === "ArrayVariableType[StringVariableType]";
    this.isArrayOfNumber = this.variable !== null && this.variable.variableTypeName === "ArrayVariableType[NumberVariableType]";
    this.isArrayOfOrganizationNode = this.variable !== null && this.variable.variableTypeName === "ArrayVariableType[OrganizationNodeVariableType]";

    this.staticEntries.forEach(e => {
      if(e.checked) {
        this.staticDefaultValueLabel = e.value+"";
      }
    });

  }

  changeEvent(): ModelEvent {
    switch (this.className) {
      case "ActionButton": return new FormActionButtonChanged(this.elementId, this.text.getCurrentWithFallback(),
        this.actionsRefs.map(a => {
          const id = a.action.id.id % 1000; // 1000 is to revert result of fromLegacyAfterAction function on server
          const saveTo = a.action.saveTo.filter(s => s.variableName.trim().length > 0).map(s => s.variableName).join(",");
          return new ProcessAutomaticAction(id, None(), Typed.of(AdvancedActionType.of(a.action.expression, saveTo)), ExpressionWithAst.of(a.action.expression), None(), saveTo.length > 0 ? Some(saveTo) : None());
        }),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "AttachmentField": return new FormAttachmentFieldChanged(this.elementId, VariableTypePath.parse(this.requiredVariable.fullVariableName()),
        Some(Typed.of(new FileArrayValidation(new FileVariableValidation(0, this.attachmentsAllowedExtensions.split(",").map(t => t.trim().toUpperCase()).filter(t => t.length > 0)), new ArrayVariableValidation(this.attachmentsMin, this.attachmentsMax)))),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "TextArea": return new FormTextAreaChanged(this.elementId, this.requiredVariable.fullVariablePath(), this.placeholder.getCurrentWithFallback(),
        this.isString
          ? Some(Typed.of(new StringVariableValidation(StringVariableValidationType.ofName(this.textAreaValidationType),
            this.textAreaValidationRegex.trim().length > 0 ? Some(this.textAreaValidationRegex.trim()) : None(),
            this.textAreaValidationMask.trim().length > 0 ? Some(this.textAreaValidationMask.trim()) : None(),
          )))
          : Some(Typed.of(new NumberVariableValidation(this.numberMinValueVisible ? Some(this.numberMinValue) : None(), this.numberMaxValueVisible ? Some(this.numberMaxValue) : None()))),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "Checkbox": return new FormCheckboxChanged(this.elementId, this.requiredVariable.fullVariablePath(), None(),
        new ArrayVariable(this.staticEntries.filter(s => s.value !== null && (s.value+"".trim()) !== "").map(s => this.variableFrom(s.value!))),
        this.isArrayOfString ? ArrayVariable.fromTexts(this.staticEntries.filter(s => s.checked).map(s => <string>s.value)) : ArrayVariable.fromNumbers(this.staticEntries.filter(s => s.checked).map(s => <number>s.value)),
        this.entriesListType === 'constant' ? None() : Some(ExpressionWithAst.of(this.entriesExpression)),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "DateField": return new FormDateFieldChanged(this.elementId, this.requiredVariable.fullVariablePath(),
        this.isDate
          ? Some(Typed.of(new DateVariableValidation(this.dateMinVisible ? Some(ExpressionWithAst.of(this.dateMinExpression.trim())) : None(), this.dateMaxVisible ? Some(ExpressionWithAst.of(this.dateMaxExpression.trim())) : None())))
          : this.isDateTime
            ? Some(Typed.of(new DateTimeVariableValidation(this.dateMinVisible ? Some(ExpressionWithAst.of(this.dateMinExpression.trim())) : None(), this.dateMaxVisible ? Some(ExpressionWithAst.of(this.dateMaxExpression.trim())) : None())))
            : Some(Typed.of(new TimeVariableValidation(this.dateMinVisible ? Some(ExpressionWithAst.of(this.dateMinExpression.trim())) : None(), this.dateMaxVisible ? Some(ExpressionWithAst.of(this.dateMaxExpression.trim())) : None()))),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "DropList": return new FormDropListChanged(this.elementId, this.requiredVariable.fullVariablePath(), None(),
        new ArrayVariable(this.staticEntries.filter(s => s.value !== null && (s.value+"".trim()) !== "").map(s => this.variableFrom(s.value!))),
        Option.of(this.staticEntries.filter(s => s.value !== null && (s.value+"".trim()) !== "").find(s => s.checked)).map(s => this.variableFrom(s.value!)),
        this.entriesListType === 'constant' ? None() : Some(ExpressionWithAst.of(this.entriesExpression)),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None(),
        false, false);
      case "MapField": return new FormMapFieldChanged(this.elementId, this.requiredVariable.fullVariablePath(), None(),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "RadioButton": return new FormRadioButtonChanged(this.elementId, this.requiredVariable.fullVariablePath(), None(),
        new ArrayVariable(this.staticEntries.filter(s => s.value !== null && (s.value+"".trim()) !== "").map(s => this.variableFrom(s.value!))),
        Option.of(this.staticEntries.filter(s => s.value !== null && (s.value+"".trim()) !== "").find(s => s.checked)).map(s => this.variableFrom(s.value!)),
        this.entriesListType === 'constant' ? None() : Some(ExpressionWithAst.of(this.entriesExpression)),
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None(),
        this.requiredExpression.length > 0 ? Some(ExpressionWithAst.of(this.requiredExpression)) : None(),
        this.readOnlyExpression.length > 0 ? Some(ExpressionWithAst.of(this.readOnlyExpression)) : None());
      case "Label": return new FormLabelChanged(this.elementId, this.text.getCurrentWithFallback(), this.bold,
        this.hiddenExpression.length > 0 ? Some(ExpressionWithAst.of(this.hiddenExpression)) : None());
      default: throw new Error("unsupported type: '"+this.className+"'");
    }

  }


  private variableFrom(value: string|number): Typed<BusinessVariable> {
    if(typeof value == "string") {
      return Typed.of(new StringVariable(value));
    } else if(typeof value == "number") {
      return Typed.of(new NumberVariable(value));
    } else {
      toastr.error("Incorrect type");
      throw new Error("Incorrect type");
    }
  }

  addStaticEntry() {
    this.staticEntries.push({value: null, checked: false});
  }

  deleteEntry(entry: { value: string | number | null; checked: boolean }) {
    removeFromArray(this.staticEntries, entry);
  }

}

export class LegacyRefElementViewModel {

  leftCss = "";
  topCss = "";
  widthCss = "";
  heightCss = "";

  labelLeftCss?: string;
  labelTopCss?: string;

  refId: FormElementRefId = null!;

  gridXY: GridXY = null!
  gridSize: GridSize = null!;


  readOnly: Trilean = Trilean.FALSE;
  required: Trilean = Trilean.FALSE;
  hidden: Trilean = Trilean.FALSE;


  hasLabel: boolean = false;
  label: I18nText = I18nText.empty();
  labelPosition: LabelPosition = null!;
  labelGridShift: GridXY = null!;

  visibleInSummary: boolean = false;
  visibleInTaskBox: boolean = false;


  attachmentsFilesReadOnly: boolean = false;

  className: string = "";

  constructor(readonly element: LegacyElementViewModel) {
  }


  copy(refId: FormElementRefId, gridXY: GridXY): LegacyRefElementViewModel {
    const c = new LegacyRefElementViewModel(this.element);

    c.refId = refId;
    c.gridXY = gridXY;

    c.gridSize = this.gridSize;
    c.className = this.className;
    c.hasLabel = this.hasLabel;
    c.label = this.label;
    c.labelPosition = this.labelPosition;
    c.labelGridShift = this.labelGridShift;
    c.readOnly = this.readOnly;
    c.required = this.required;
    c.hidden = this.hidden;
    c.visibleInSummary = this.visibleInSummary;
    c.visibleInTaskBox = this.visibleInTaskBox;
    c.attachmentsFilesReadOnly = this.attachmentsFilesReadOnly;

    c.updateView();
    return c;
  }

  updateView() {

    this.leftCss = this.gridXY.gridX * 2.5 / 2 +"rem";
    this.topCss = this.gridXY.gridY * 2.5 / 2 +"rem";
    this.widthCss = this.gridSize.width * 2.5 / 2 +"rem";
    this.heightCss = this.gridSize.height * 2.5 / 2 +"rem";

    if(this.hasLabel && this.labelPosition.isFlexAlign()) {
      this.labelLeftCss = this.labelGridShift.gridX  * 2.5 / 2 + "rem";
      this.labelTopCss = this.labelGridShift.gridY  * 2.5 / 2 + "rem";
    } else {
      this.labelLeftCss = undefined;
      this.labelTopCss = undefined;
    }

    this.element.updateView();
  }

  static of(ref: FormElementRef, element: LegacyElementViewModel) {

    const viewModel = new LegacyRefElementViewModel(element);

    viewModel.className = ref.className();
    viewModel.refId = ref.id;
    viewModel.gridXY = ref.gridXY;
    viewModel.gridSize = ref.gridSize;


    viewModel.hasLabel = !(ref instanceof LabelRef) && !(ref instanceof ActionButtonRef);

    if(viewModel.hasLabel) {
      viewModel.label = (<InputElementRef>ref).label;
      viewModel.labelPosition = (<InputElementRef>ref).labelPosition;
      viewModel.labelGridShift = (<InputElementRef>ref).labelGridShift;
    }

    viewModel.hidden = ref.hidden;

    if(ref instanceof ActionButtonRef) {
      viewModel.readOnly = ref.readOnly;
    } else if(ref instanceof LabelRef) {
    } else {
      const inputRef = <InputElementRef>ref;
      viewModel.readOnly = inputRef.readOnly;
      viewModel.required = inputRef.required;

      viewModel.visibleInSummary = inputRef.visibleInSummary;
      viewModel.visibleInTaskBox = inputRef.visibleInTaskBox;
    }

    if(ref instanceof AttachmentFieldRef) {
      viewModel.attachmentsFilesReadOnly = ref.filesReadOnly;
    }

    viewModel.updateView();
    return viewModel;
  }

  // if fromSectionId element will be added, not moved
  changeRefEvent(sectionId: FormSectionId, fromSectionId: FormSectionId|null): ModelEvent {
    switch (this.className) {

      case "ActionButtonRef": return new FormActionButtonRefChanged(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.readOnly, this.hidden);

      case "AttachmentFieldRef": return new FormAttachmentFieldRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden,
        this.attachmentsFilesReadOnly,
        this.visibleInSummary, this.visibleInTaskBox);

      case "TextAreaRef": return new FormTextAreaRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "CheckboxRef": return new FormCheckboxRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "DateFieldRef": return new FormDateFieldRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "DropListRef": return new FormDropListRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "MapFieldRef": return new FormMapFieldRefChanged(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "RadioButtonRef": return new FormRadioButtonRefChangedV1(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.label.getCurrentWithFallback(), this.labelPosition, this.labelGridShift, this.required, this.readOnly, this.hidden, this.visibleInSummary, this.visibleInTaskBox);

      case "LabelRef": return new FormLabelRefChanged(sectionId, Option.of(fromSectionId), this.refId, this.element.elementId,
        this.gridXY, this.gridSize, this.hidden);

      default: throw new Error("unsupported type: '"+this.className+"'");
    }

  }




}

export class LegacyFormSectionViewModel {

  gridHeightCss = "";

  collapsed: boolean = false;

  constructor(readonly refId: FormSectionRefId,
              readonly sectionId: FormSectionId,
              public name: I18nText,
              public gridHeight: number,
              public readOnly: boolean,
              public hideHeader: boolean,
              public dynamicVisibility: boolean,
              public visibilityExpression: string,
              public variable: LegacyVariableViewModel|null,
              public lengthEditable: boolean,
              public minimumEntriesEnabled: boolean,
              public minimumEntries: number,
              public maximumEntriesEnabled: boolean,
              public maximumEntries: number,
              public displayAsTable: boolean,
              readonly multipleUsages: boolean,
              readonly elements: Array<LegacyRefElementViewModel>) {
    this.updateView()
  }

  copy(refId: FormSectionRefId) {
    return new LegacyFormSectionViewModel(refId, this.sectionId, this.name, this.gridHeight, this.readOnly, this.hideHeader, this.dynamicVisibility,
      this.visibilityExpression, this.variable, this.lengthEditable, this.minimumEntriesEnabled, this.minimumEntries,
      this.maximumEntriesEnabled, this.maximumEntries, this.displayAsTable, true,
      this.elements);
  }

  updateView() {
    this.gridHeightCss = this.gridHeight * 2.5 / 2 + "rem";
    this.elements.forEach(e => e.updateView());
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  maxElementGridY() {
    return __(this.elements).maxOfOrZero(e => e.gridXY.gridY + e.gridSize.height);
  }

  changeEvent() {
    return new SectionPropertiesChangedV1(this.sectionId,
      this.dynamicVisibility ? Some(ExpressionWithAst.of(this.visibilityExpression)) : None(),
      this.variable ? Some(this.variable.variableName) : None(),
      this.hideHeader,
      this.lengthEditable,
      this.minimumEntriesEnabled ? Some(this.minimumEntries) : None(),
      this.maximumEntriesEnabled ? Some(this.maximumEntries) : None(),
      this.displayAsTable
    );
  }

  refChangeEvent(nodeId: ProcessNodeId) {
    return new SectionRefPropertiesChangedV1(nodeId, this.refId, this.readOnly);
  }
}

export class LegacyPaletteElement {
  constructor(readonly typeName: string,
              readonly label: string,
              readonly remSize: GridSize) {}
}

export class LegacyOtherFormElement {
  constructor(readonly elementId: FormElementId,
              readonly label: string,
              readonly sectionId: FormSectionId,
              readonly variableName: string,
              readonly gridSize: GridSize) {
  }

}

export class LegacyOtherFormSection {
  constructor(
    readonly sectionId: FormSectionId,
    readonly sectionLabel: string,
    readonly variableName: string|undefined,
    readonly elements: Array<LegacyOtherFormElement>) {
  }
}

export class LegacyOtherNodeForm {
  expanded: boolean = false;
  constructor(
    readonly nodeName: string,
    readonly sections: Array<LegacyOtherFormSection>) {
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}

export class LegacyVariableViewModel {

  variableName: string = "";
  previousVariableName: string = "";
  variableType: BusinessVariableType|undefined;
  variableTypeName: string|undefined;

  automaticVariable: boolean = false;
  expression: string = "";
  subVariables: Array<LegacyVariableViewModel> = [];

  constructor(readonly context: LegacyVariableViewModel|null) {}

  static of(context: LegacyVariableViewModel|null,
            name: string,
            variableType: BusinessVariableType,
            automaticVariable: boolean,
            expression: string): LegacyVariableViewModel {


    const variable = new LegacyVariableViewModel(context);

    variable.variableName = name;
    variable.previousVariableName = name;
    variable.variableType = variableType;
    variable.automaticVariable = automaticVariable;
    variable.expression = expression;

    variable.updateView();
    return variable;
  }


  static ofArrayOfObject(name: string, automaticVariable: boolean,
                         expression: string): LegacyVariableViewModel {
    const variable = new LegacyVariableViewModel(null);

    variable.variableName = name;
    variable.previousVariableName = name;
    variable.variableType = new ArrayVariableType(Typed.of(new ObjectVariableType([])));
    variable.automaticVariable = automaticVariable;
    variable.expression = expression;

    variable.updateView();
    return variable;
  }

  setVariableType(tpe: BusinessVariableType) {
    this.variableType = tpe;
    this.variableTypeName = tpe.typeName();
  }

  setVariableName(name: string) {
    this.variableName = name;
    this.previousVariableName = name;
  }

  setSubVariables(subVariables: LegacyVariableViewModel[]) {
    this.subVariables = subVariables;
  }

  fullVariableName() {
    if(this.context) {
      return this.context.variableName+"."+this.variableName;
    } else {
      return this.variableName;
    }
  }

  fullPreviousVariableName(): string {
    if(this.context !== null) {
      return this.context.variableName+"."+this.previousVariableName;
    } else {
      return this.previousVariableName;
    }
  }

  fullVariablePath() {
    if(this.context) {
      return new VariableTypePath([this.context.variableName, this.variableName]);
    } else {
      return VariableTypePath.root(this.variableName);
    }
  }

  updateView() {
    this.variableTypeName = this.variableType !== undefined ? this.variableType.className() : undefined;

    if(this.variableType && this.variableType.isArray()) {
      this.variableTypeName += "["+this.variableType.asArray().subtypeUnwrapped().className()+"]";
    }
  }

  revertVariableName() {
    this.variableName = this.previousVariableName;
  }
}

export class LegacyVariablesViewModel {
  constructor(readonly variables: Array<LegacyVariableViewModel>) {}

  findRootVariable(variableName: string): LegacyVariableViewModel|undefined {
    return this.variables.find(v => v.context === null && variableName === v.variableName);
  }

  findVariable(variableTypePath: VariableTypePath): LegacyVariableViewModel|undefined {
    if(variableTypePath.path.length === 1) {
      return this.findRootVariable(variableTypePath.head());
    } else if(variableTypePath.path.length === 2){
      const context = required(this.findRootVariable(variableTypePath.head()), "no context");
      const variableName = variableTypePath.path[1];
      return context.subVariables.find(v => v.context === context && variableName === v.variableName);
    } else {
      throw new Error("Unsupported path length " + variableTypePath.path.length)
    }
  }

  push(variable: LegacyVariableViewModel) {
    this.variables.push(variable);
  }

  remove(variable: LegacyVariableViewModel) {
    if(variable.context) {
      removeFromArray(variable.context.subVariables, variable);
    } else {
      removeFromArray(this.variables, variable);
    }
  }
}

export class LegacyFormValidation {
  constructor(public validationExpression: string,
              public messageExpression: string) {}
}


export class LegacyButtonActionRefViewModel {
  constructor(readonly action: LegacyButtonActionViewModel) {}
}

export class LegacyButtonSaveToVariable {
  constructor(public variableName: string) {}
}

export class LegacyButtonActionViewModel {
  constructor(readonly id: AutomaticActionId,
              public expression: string,
              public saveTo: Array<LegacyButtonSaveToVariable>) {
  }

  deleteSaveTo(variable: LegacyButtonSaveToVariable) {
    removeFromArray(this.saveTo, variable);
  }

  addSaveTo() {
    this.saveTo.push(new LegacyButtonSaveToVariable(""));
  }
}

export class LegacyProcessNodeViewModel {
  selectedElementSingle?: LegacyRefElementViewModel;
  selectedElement: Array<LegacyRefElementViewModel> = []; // array for easier code in html
  selectedSectionSingle?: LegacyFormSectionViewModel;
  selectedSection: Array<LegacyFormSectionViewModel> = [];
  submitPropertiesSelected = false;
  noneSelected: boolean = true;


  private readonly modelUpdatedSubject = new Subject<void>;
  readonly modelUpdated: Observable<void> = this.modelUpdatedSubject.asObservable();

  readonly palette = [
    new LegacyPaletteElement("Label", "Etykieta", new GridSize(10, 2)),
    new LegacyPaletteElement("TextArea", "Input", new GridSize(10, 2)),
    new LegacyPaletteElement("Checkbox", "Checkboxes", new GridSize(10, 4)),
    new LegacyPaletteElement("RadioButton", "Radiobutton", new GridSize(10, 4)),
    new LegacyPaletteElement("DropList", "Select", new GridSize(10, 2)),
    new LegacyPaletteElement("DateField", "Data", new GridSize(8, 2)),
    new LegacyPaletteElement("AttachmentField", "Attachments", new GridSize(34, 4)),
    new LegacyPaletteElement("ActionButton", "Button", new GridSize(10, 2))
  ]

  readonly textAreaVariableTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Tekst", value: StringVariableType.className},
    {name: "Liczba", value: NumberVariableType.className}
  ];

  readonly textAreaValidationTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Dowolna wartość", value: StringVariableValidationType.AnyValidationType.name},
    {name: "Własna", value: StringVariableValidationType.CustomType.name},
    {name: "Pesel", value: StringVariableValidationType.PeselType.name},
    {name: "Kod pocztowy", value: StringVariableValidationType.PostalCodeType.name},
    {name: "Email", value: StringVariableValidationType.EmailType.name},
    {name: "NIP", value: StringVariableValidationType.NIPCodeType.name},
    {name: "Number konta bankowego", value: StringVariableValidationType.BankAccountNumberType.name},
  ];

  readonly dateVariableTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Data", value: DateVariableType.className},
    {name: "Data i godzina", value: DateTimeVariableType.className},
    {name: "Godzina", value: TimeVariableType.className}
  ];

  readonly labelPositions: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Góra", value: LabelPosition.topAlign, icon: "mi-up"},
    {name: "Lewo", value: LabelPosition.leftAlign, icon: "mi-left"},
    {name: "Dowolnie", value: LabelPosition.flexAlign, icon: "mi-repeat"},
    {name: "Ukryta", value: LabelPosition.noneAlign, icon: "mi-circle-cross"}
  ]

  readonly checkboxVariableTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Tekst", value: ArrayVariableType.className+"["+StringVariableType.className+"]"},
    {name: "Liczba", value: ArrayVariableType.className+"["+NumberVariableType.className+"]"},
    {name: "Osoba, Departament, Grupa", value: ArrayVariableType.className+"["+OrganizationNodeVariableType.className+"]"}
  ];


  readonly radioButtonVariableTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Tekst", value: StringVariableType.className},
    {name: "Liczba", value: NumberVariableType.className},
    {name: "Osoba, Departament, Grupa", value: OrganizationNodeVariableType.className}
  ];

  readonly dropListVariableTypes: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Tekst", value: StringVariableType.className},
    {name: "Liczba", value: NumberVariableType.className},
    {name: "Osoba", value: PersonVariableType.className},
    {name: "Osoba, Departament, Grupa", value: OrganizationNodeVariableType.className}
  ];

  readonly entriesListType: ReadonlyArray<DropDownSelectorOption> = [
    {name: "Zdefiniowana", value: "constant"},
    {name: "Wyliczana", value: "calculated"}
  ];

  readonly sectionDropMenu = new DropMenuState<LegacyFormSectionViewModel>();
  readonly elementDropMenu = new DropMenuState<LegacyRefElementViewModel>();

  constructor(readonly nodeId: ProcessNodeId,
              readonly sections: Array<LegacyFormSectionViewModel>,
              readonly otherNodes: Array<LegacyOtherNodeForm>,
              readonly modelEventBus: ProcessMapEditorModelEventBus|undefined,
              readonly viewModel: ProcessMapCommonViewModel,
              readonly validations: Array<LegacyFormValidation>,
              readonly actions: Array<LegacyButtonActionViewModel>,
              readonly variables: LegacyVariablesViewModel) {
    this.modelUpdatedSubject.next();
}

  static empty(nodeId: ProcessNodeId, modelEventBus: ProcessMapEditorModelEventBus, gridProcessModel: GridProcessModel, viewModel: ProcessMapCommonViewModel) {
    const variables = LegacyProcessNodeViewModel.createVariables(gridProcessModel);
    return new LegacyProcessNodeViewModel(nodeId, [], [], modelEventBus, viewModel, [], [], variables);
  }

  static createVariables(gridProcessModel: GridProcessModel): LegacyVariablesViewModel {
    return new LegacyVariablesViewModel(gridProcessModel.variablesTypes.map(v => {

      const expression = gridProcessModel.variableExpressionForPath(VariableTypePath.root(v.name));

      const automaticVariable = expression.exists(e => e.enabled);
      const automaticExpression = expression.map(e => e.expressionWithAst.expression).getOrElse("");

      if(v.unwrappedVariableType().isArray() && v.unwrappedVariableType().asArray().subtypeUnwrapped().isObject()) {
        const arrObj = LegacyVariableViewModel.ofArrayOfObject(v.name, automaticVariable, automaticExpression);

        const fields = v.unwrappedVariableType().asArray().subtypeUnwrapped().asObject().fields.map(f => {

          const subExpression = gridProcessModel.variableExpressionForPath(VariableTypePath.root(v.name).concat(VariableTypePath.root(f[0])));
          const subAutomatic = subExpression.exists(e => e.enabled);
          const subAutomaticExpression = subExpression.map(e => e.expressionWithAst.expression).getOrElse("");

          return LegacyVariableViewModel.of(arrObj, f[0], f[1].dataTypeUnwrapped(), subAutomatic, subAutomaticExpression);
        });

        arrObj.setSubVariables(fields);

        return arrObj

      } else {
        return LegacyVariableViewModel.of(null, v.name, v.unwrappedVariableType(), automaticVariable, automaticExpression);
      }
    }));
  }

  static of(node: GridProcessNode, gridProcessModel: GridProcessModel, modelEventBus: ProcessMapEditorModelEventBus|undefined, viewModel: ProcessMapCommonViewModel) {


    const actions = gridProcessModel.actionsUnwrapped().filter(a => a instanceof ScriptAction).map(a => {
      const action = <ScriptAction>a;
      return new LegacyButtonActionViewModel(action.id, action.script, action.saveTo.map(s => new LegacyButtonSaveToVariable(s.path.toString())));
    });
    const validations = gridProcessModel.findNodeById(node.id).form.options.validations.map(v => {
      return new LegacyFormValidation((<ScriptInputType>v.unwrappedValidationFormula()).value, (<ScriptInputType>v.unwrappedMessageFormula()).value);
    });


    const sections = node.form.sectionsRefs.map(ref => {
      const section = gridProcessModel.getSectionById(ref.sectionId);

      let sectionVariable: LegacyVariableViewModel|null = null;

      if(section.forEachVariableName.isDefined()) {
        sectionVariable = nullIfUndefined(viewModel.legacyVariables.findRootVariable(section.forEachVariableName.getOrError("no variable name")));
      }

      const sectionViewModel =  new LegacyFormSectionViewModel(ref.id, section.id, section.name, section.gridHeight, ref.readOnly,
        section.hideHeader,
        section.visibilityExpression.isDefined(), section.visibilityExpression.map(e => e.expression).getOrElse(""),
        sectionVariable,
        section.lengthEditable, section.minimumLength.isDefined(), section.minimumLength.getOrElse(0), section.maximumLength.isDefined(), section.maximumLength.getOrElse(10),
        section.tableMode,
        gridProcessModel.countSectionsRefForSection(section.id) > 1,
        []);



      const inputElements = section.inputElementsRefsUnwrapped().map(ref => {
        const element = gridProcessModel.inputElementById(ref.elementId);

        const elementViewModel = LegacyElementViewModel.of(element, viewModel.legacyVariables.findVariable(element.variableTypePath), actions);
        return LegacyRefElementViewModel.of(ref, elementViewModel);
      });

      const staticElements = section.staticElementsRefsUnwrapped().map(ref => {
        const element = <StaticElement>gridProcessModel.elementById(ref.elementId);
        const elementViewModel = LegacyElementViewModel.of(element, undefined, actions);
        return LegacyRefElementViewModel.of(ref, elementViewModel);
      });


      replaceArrayContent(sectionViewModel.elements, inputElements.concat(staticElements));

       return sectionViewModel;
    });




    const otherForms = ___(gridProcessModel.nodes)
      .filter(n => n.id !== node.id)
      .filter(n => n.nodeType.isAnyForm())
      .sortBy(n => n.gridXY.gridX + n.gridXY.gridY)
      .map(node => {

        const formSections = node.form.sectionsRefs.map(sectionRef => {
          const section = gridProcessModel.getSectionById(sectionRef.sectionId);

          const inputElements = __(section.inputElementsRefsUnwrapped())
            .sortBy(e => e.gridXY.gridY * 100 + e.gridXY.gridX)
            .map(elementRef => {
              const element = gridProcessModel.inputElementById(elementRef.elementId);
              return new LegacyOtherFormElement(element.id, elementRef.label.getCurrentWithFallback(), section.id, element.variableTypePath.toString(), elementRef.gridSize);
            })


          const staticElements = __(section.staticElementsRefsUnwrapped())
            .sortBy(e => e.gridXY.gridY * 100 + e.gridXY.gridX)
            .map(elementRef => {
              const element = gridProcessModel.elementById(elementRef.elementId);
              if(element instanceof Label) {
                return new LegacyOtherFormElement(element.id, element.text.getCurrentWithFallback(), section.id, "", elementRef.gridSize);
              } else if(element instanceof ActionButton) {
                return new LegacyOtherFormElement(element.id, element.label.getCurrentWithFallback(), section.id, "", elementRef.gridSize);
              } else {
                throw new Error("Incorrect static element");
              }
            });

          return new LegacyOtherFormSection(section.id, section.name.getCurrentWithFallback(), section.forEachVariableName.getOrUndefined(), inputElements.concat(staticElements));
        });

        return new LegacyOtherNodeForm(node.name.getCurrentWithFallback(), formSections);
      }).value();



    return new LegacyProcessNodeViewModel(node.id, sections, otherForms, modelEventBus, viewModel, validations, actions, viewModel.legacyVariables);
  }

  static findVariableExpression(path: VariableTypePath, variables: Array<LegacyVariableViewModel>): Option<LegacyVariableViewModel> {

    const head = path.head();

    const headVariable = variables.find(v => v.variableName === head);

    if(path.path.length === 1 || headVariable === undefined) {
      return Option.of(headVariable);
    } else if(path.path.length === 2){

      const subVariable = headVariable.subVariables.find(v => v.variableName === path.path[1]);
      return Option.of(subVariable);

    } else {
      throw new Error("Only paths of length 1 and 2 are supported but got " + path.path.join(".")+"")
    }

  }


  selectSubmitProperties() {
    this.selectedElementSingle = undefined;
    this.selectedElement = [];
    this.selectedSectionSingle = undefined;
    this.selectedSection = [];
    this.submitPropertiesSelected = true;
    this.noneSelected = false;
    this.modelUpdatedSubject.next();
  }

  elementSelected(element: LegacyRefElementViewModel) {
    this.selectedElementSingle = element;
    this.selectedElement = [element];
    this.selectedSectionSingle = undefined;
    this.selectedSection = [];
    this.submitPropertiesSelected = false;
    this.noneSelected = false;
    this.modelUpdatedSubject.next();
  }

  sectionSelected(section: LegacyFormSectionViewModel) {
    this.selectedElementSingle = undefined;
    this.selectedElement = [];
    this.selectedSectionSingle = section;
    this.selectedSection = [section];
    this.submitPropertiesSelected = false;
    this.noneSelected = false;
    this.modelUpdatedSubject.next();
  }

  unselectElement() {
    this.selectedElementSingle = undefined;
    this.selectedElement = [];
    this.selectedSectionSingle = undefined;
    this.selectedSection = [];
    this.submitPropertiesSelected = false;
    this.noneSelected = true;
    this.modelUpdatedSubject.next();
  }

  addNewSection() {
    const newSectionId = this.viewModel.nextLegacySectionId();
    const newSectionRefId = new FormSectionRefId(__(this.sections).maxOfOrDefault(s => s.refId.id, 0) + 1);

    const sectionName = i18n("process_legacy_form_section")+" " + newSectionId.id;
    this.sections.push(new LegacyFormSectionViewModel(newSectionRefId, newSectionId, I18nText.ofCurrent(sectionName), 20, false,
      false, false, "", null, false, false,
      0, false, 10, false, false, []));

    this.apply(new FormSectionCreated(newSectionId, sectionName), new FormSectionReferred(this.nodeId, newSectionId, newSectionRefId, this.sections.length, false));
  }

  addNewRepeatableSection() {
    const newSectionId = this.viewModel.nextLegacySectionId();
    const nextSectionRefId = new FormSectionRefId(__(this.sections).maxOfOrDefault(s => s.refId.id, 0) + 1);

    const variableName = "repeatable_"+newSectionId.id;


    const variablesWithNewNameUsedInElements = this.usedVariablesByNameCount(VariableTypePath.root(variableName));

    if(variablesWithNewNameUsedInElements > 0) {
      toastr.error("Variable '" + variableName+"' already exists");
      throw new Error("Variable already exists");
    } else {


      const variableType = ArrayVariableType.of(new ObjectVariableType([]));
      const variable = LegacyVariableViewModel.ofArrayOfObject(variableName, false, "");
      this.variables.push(variable);

      const sectionName = i18n("process_legacy_form_repeatable_section") + " " + newSectionId.id;
      this.sections.push(new LegacyFormSectionViewModel(nextSectionRefId, newSectionId, I18nText.ofCurrent(sectionName), 20, false,
        false, false, "", variable, true, false,
        0, false, 10, false, false, []));


      this.apply(
        new VariableAddedV1(VariableTypePath.root(variableName), Typed.of(variableType)),
        new FormRepeatableSectionCreated(newSectionId, sectionName, variableName),
        new FormSectionReferred(this.nodeId, newSectionId, nextSectionRefId, this.sections.length, false));
    }
  }

  addNewElementTo(paletteElement: LegacyPaletteElement, section: LegacyFormSectionViewModel, gridXY: GridXY) {

    const elementId = this.viewModel.nextLegacyElementId();
    const refId = this.viewModel.nextLegacyElementRefId();

    let sectionVariable = section.variable;

    const variableName = "input_"+elementId.id;

    const fullVariablePath = sectionVariable ? VariableTypePath.parse(sectionVariable.variableName+"."+variableName) : VariableTypePath.root(variableName);

    let events: Array<ModelEvent> = [];

    let formElement: FormElement|undefined;
    let formElementRef: FormElementRef|undefined;

    let ref: LegacyRefElementViewModel|undefined;

    let variable: LegacyVariableViewModel|undefined;

    switch (paletteElement.typeName) {
      case "ActionButton":
        formElement = new ActionButton(elementId, I18nText.empty(), I18nText.ofCurrent(i18n("process_legacy_button")), [],None(), None());
        formElementRef = new ActionButtonRef(refId, elementId, gridXY, paletteElement.remSize, Trilean.FALSE, Trilean.FALSE);
        break;
      case "AttachmentField":
        formElement = new AttachmentField(elementId, I18nText.empty(), fullVariablePath, Some(Typed.of(new FileArrayValidation(new FileVariableValidation(0, []), new ArrayVariableValidation(0, 10)))), None(), None(), None());
        formElementRef = new AttachmentFieldRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new ArrayVariableType(Typed.of(new FileVariableType())), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new ArrayVariableType(Typed.of(new FileVariableType())))));
        break;
      case "TextArea":
        formElement = new TextArea(elementId, I18nText.empty(), fullVariablePath, Some(Typed.of(new StringVariableValidation())), I18nText.empty(), None(), None(), None());
        formElementRef = new TextAreaRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new StringVariableType(), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new StringVariableType())));
        break;
      case "Checkbox":
        formElement = new Checkbox(elementId, I18nText.empty(), fullVariablePath, None(), new ArrayVariable([Typed.of(new StringVariable("ABC")), Typed.of(new StringVariable("CDE"))]), new ArrayVariable([]), None(), None(), None(), None());
        formElementRef = new CheckboxRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new ArrayVariableType(Typed.of(new StringVariableType())), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new ArrayVariableType(Typed.of(new StringVariableType())))));
        break;
      case "DateField":
        formElement = new DateField(elementId, I18nText.empty(), fullVariablePath, Some(Typed.of(new DateVariableValidation(None(), None()))), None(), None(), None());
        formElementRef = new DateFieldRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new DateVariableType(), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new DateVariableType())));
        break;
      case "DropList":
        formElement = new DropList(elementId, I18nText.empty(), fullVariablePath, None(), new ArrayVariable([Typed.of(new StringVariable("ABC")), Typed.of(new StringVariable("CDE"))]), None(), None(), None(), None(), None(), false, false);
        formElementRef = new DropListRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new StringVariableType(), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new StringVariableType())));
        break;
      case "MapField":toastr.error("Element not supported '"+paletteElement.typeName+"'");break;
      case "RadioButton":
        formElement = new RadioButton(elementId, I18nText.empty(), fullVariablePath, None(), new ArrayVariable([Typed.of(new StringVariable("ABC")), Typed.of(new StringVariable("CDE"))]), None(), None(), None(), None(), None());
        formElementRef = new RadioButtonRef(refId, elementId, gridXY, paletteElement.remSize, I18nText.ofCurrent("Input " + elementId.id), LabelPosition.topAlign, GridXY.ZERO, Trilean.FALSE, Trilean.FALSE, Trilean.FALSE, false, false);
        variable = LegacyVariableViewModel.of(sectionVariable, variableName, new StringVariableType(), false, "");
        events.push(new VariableAddedV1(fullVariablePath, Typed.of(new StringVariableType())));
        break;
      case "Label":
        formElement = new Label(elementId, I18nText.empty(), I18nText.ofCurrent("Etykieta"), false, None());
        formElementRef = new LabelRef(refId, elementId, gridXY, paletteElement.remSize, Trilean.FALSE);
        break;
      default: toastr.error("Element not supported '"+paletteElement.typeName+"'");
    }


    if(variable) {
      const variablesWithNewNameUsedInElements = this.usedVariablesByNameCount(VariableTypePath.root(variableName));

      if(variablesWithNewNameUsedInElements > 0) {
        toastr.error("Variable '" + variable.fullVariablePath().toString()+"' already exists");
        throw new Error("Variable already exists");
      } else {
        this.variables.push(variable);
      }
    }

    let element = LegacyElementViewModel.of(required(formElement, "formElement"), variable, this.actions);
    ref = LegacyRefElementViewModel.of(required(formElementRef, "formElementRef"), element);
    events.push(ref.element.changeEvent());
    events.push(ref.changeRefEvent(section.sectionId, null));

    section.elements.push(required(ref, "element"));
    this.apply(...events);

    this.elementSelected(ref);
  }

  addOtherElementTo(otherElement: LegacyOtherFormElement, section: LegacyFormSectionViewModel, gridXY: GridXY) {

    let sourceSectionVariableName: string|undefined = __(this.sections)
      .find(s => s.sectionId.id === otherElement.sectionId.id)
      .filter(s => s.variable !== null)
      .map(s => required(s.variable, "variable").fullVariableName())
      .getOrUndefined();
    if(!sourceSectionVariableName) {
      sourceSectionVariableName = ___(this.otherNodes)
        .flatMap(n => n.sections)
        .find(s => s.sectionId.id === section.sectionId.id)
        .map(s => s.variableName).getOrUndefined();
    }

    if(sourceSectionVariableName !== Option.of(section.variable).map(v => v.fullVariableName()).getOrUndefined()) {
      toastr.error("Cannot refer component in different repeatable section contexts");
    } else {

      const refId = this.viewModel.nextLegacyElementRefId();
      const referredElement = required(this.viewModel.nodes
        .flatMap(s => s.legacy.sections.flatMap(s => s.elements))
        .find(e => e.element.elementId.id === otherElement.elementId.id), "referredElement");

      const newElement = referredElement.copy(refId, gridXY);
      section.elements.push(newElement);
      this.apply(newElement.changeRefEvent(section.sectionId, null));
    }
  }

  addOtherSectionTo(otherSection: LegacyOtherFormSection, index: number) {
    const nextSectionRefId = new FormSectionRefId(__(this.sections).maxOfOrDefault(s => s.refId.id, 0) + 1);

    const referredSection = required(this.viewModel.nodes.flatMap(s => s.legacy.sections).find(s => s.sectionId.id === otherSection.sectionId.id), "referredSection");

    this.sections.splice(index, 0, referredSection.copy(nextSectionRefId));
    this.apply(new FormSectionReferred(this.nodeId, otherSection.sectionId, nextSectionRefId, index, false));
  }

  moveElementTo(element: LegacyRefElementViewModel, section: LegacyFormSectionViewModel, gridXY: GridXY) {

    const oldSection = required(this.sections.find(s => s.elements.indexOf(element) >= 0), "section");

    if(oldSection != section && oldSection.variable !== section.variable) {
      toastr.warning("Cannot move element between different repeatable sections contexts")
    } else {

      element.gridXY = gridXY;

      if (oldSection != section) {
        removeFromArray(oldSection.elements, element);
        section.elements.push(element);
      }

      this.apply(element.changeRefEvent(section.sectionId, oldSection.sectionId));
    }

    element.updateView();
  }

  resizeElement(ref: LegacyRefElementViewModel, gridWidth: number, gridHeight: number, gridX: number, gridY: number) {
    if(ref.gridXY.gridX !== gridX ||
      ref.gridXY.gridY !== gridY ||
      ref.gridSize.width !== gridWidth ||
      ref.gridSize.height !== gridHeight) {

      ref.gridXY = new GridXY(gridX, gridY);
      ref.gridSize = new GridSize(gridWidth, gridHeight);

      const oldSection = required(this.sections.find(s => s.elements.indexOf(ref) >= 0), "section");

      this.apply(ref.changeRefEvent(oldSection.sectionId, oldSection.sectionId));
      ref.updateView();
    }
  }

  private apply(...events: Array<ModelEvent>) {
    required(this.modelEventBus, "modelEventBus").legacyEventHappened(events);
  }

  resizeSection(section: LegacyFormSectionViewModel, gridHeight: number) {
    if(section.gridHeight !== gridHeight) {

      section.gridHeight = gridHeight;
      section.updateView();
      this.apply(new FormSectionHeightChanged(section.sectionId, gridHeight));
    }
  }

  moveSection(fromIndex: number, toIndex: number) {
    arrayMove(this.sections, fromIndex, toIndex);
    this.apply(new FormSectionsRefsOrderChangedV1(this.nodeId, this.sections.map(s => s.refId)));
  }

  deleteElement(refOption: LegacyRefElementViewModel | undefined) {
    const ref = required(refOption, "element");
    const oldSection = required(this.sections.find(s => s.elements.indexOf(ref) >= 0), "section");


    const allSectionInstances = this.viewModel.nodes.flatMap(n => n.legacy.sections).filter(s => s.sectionId.id === oldSection.sectionId.id);

    allSectionInstances.forEach(s => removeFromArrayBy(s.elements, r => r.refId.id === ref.refId.id && r.element.elementId.id === ref.element.elementId.id));

    const refDeleteEvents: Array<ModelEvent> = [];
    if(ref.className == "ActionButtonRef" || ref.className == "LabelRef") {
      refDeleteEvents.push(new FormStaticElementRefRemoved(oldSection.sectionId, ref.refId));
    } else {
      refDeleteEvents.push(new FormInputElementRefRemoved(oldSection.sectionId, ref.refId));
    }


    const elementsToDelete = this.nonReferedElementsToRemove(ref);
    const elementsDeleteEvents = elementsToDelete.map(e => new FormElementRemoved(e.elementId));

    const variablesToRemove = elementsToDelete.flatMap(e => this.unusedVariablesToRemove(e));
    const variablesDeleteEvents = variablesToRemove.map(v => new VariableRemovedV1(v.fullVariablePath()));

    variablesToRemove.forEach(v => this.variables.remove(v));

    this.apply(...refDeleteEvents.concat(elementsDeleteEvents).concat(variablesDeleteEvents))

    this.elementDropMenu.close();
  }

  deleteSection(sectionOption: LegacyFormSectionViewModel | undefined) {

    const section = required(sectionOption, "section");

    const otherSectionReferencesCount = this.viewModel.nodes
      .flatMap(n => n.legacy.sections)
      .filter(s => s.sectionId.id === section.sectionId.id && s !== section).length;

    removeFromArray(this.sections, section);

    const sectionRefDeleteEvents: Array<ModelEvent> = [new FormSectionRefRemoved(this.nodeId, section.refId)];

    if(otherSectionReferencesCount === 0) {

      const sectionDeleteEvent: Array<ModelEvent> = [new FormSectionRemoved(section.sectionId)];

      const sectionVariableDeleteEvent = section.variable ? [new VariableRemovedV1(section.variable.fullVariablePath())] : [];

      const refsThatWillBeDeleted = this.nonUsedElementsRefToRemove([section]);

      const elementsToDelete = refsThatWillBeDeleted.flatMap(ref => this.nonReferedElementsToRemove(ref));
      const elementsDeleteEvents = elementsToDelete.map(e => new FormElementRemoved(e.elementId));

      const variablesToRemove = elementsToDelete.flatMap(e => this.unusedVariablesToRemove(e));
      const elementsVariablesDeleteEvents: Array<ModelEvent> = variablesToRemove.map(v => new VariableRemovedV1(v.fullVariablePath()));

      this.apply(...elementsVariablesDeleteEvents.concat(elementsDeleteEvents).concat(sectionRefDeleteEvents.concat(sectionDeleteEvent).concat(sectionVariableDeleteEvent)));

    } else {
      this.apply(...sectionRefDeleteEvents);
    }

    this.sectionDropMenu.close();

    // this.apply(new FormSectionRemoved(section.sectionId), new FormSectionRefRemoved(this.nodeId, section.refId));
  }


  private nonUsedElementsRefToRemove(deletedSections: Array<LegacyFormSectionViewModel>): Array<LegacyRefElementViewModel> {
    return deletedSections.flatMap(s => s.elements);
  }

  private nonReferedElementsToRemove(deletedRef: LegacyRefElementViewModel): Array<LegacyElementViewModel> {
    const element = deletedRef.element;
    const allSections = this.viewModel.nodes.flatMap(n => n.legacy.sections);
    const remainingElements = allSections.flatMap(s => s.elements).filter(r => r !== deletedRef);

    if(!remainingElements.some(r => r.element.elementId.id === element.elementId.id)) {
      return [element];
    } else {
      return [];
    }
  }

  private unusedVariablesToRemove(deletedElement: LegacyElementViewModel): Array<LegacyVariableViewModel> {
    const allSections = this.viewModel.nodes.flatMap(n => n.legacy.sections);

    return deletedElement.variable ? [deletedElement.variable] : [];

    // removeUnusedVariables() {
    //   const events = this.engine.model.variablesTypes.filter(variableType =>
    //     this.engine.model.countVariableBindings(VariableTypePath.root(variableType.name)) === 0
    //   ).map(variableType => new VariableRemovedV1(VariableTypePath.root(variableType.name)));
    //
    //   if(events.length > 0) {
    //     this.engine.addAndApplyMultipleEvents(events);
    //   }
    // }
  }



  showElementDropMenu($event: MouseEvent, element: LegacyRefElementViewModel, readOnly: boolean) {
    if(!readOnly) {
      this.elementDropMenu.show($event, element);
    }
    this.elementSelected(element);
  }

  showSectionDropMenu($event: MouseEvent, section: LegacyFormSectionViewModel, readOnly: boolean) {
    if(!readOnly) {
      this.sectionDropMenu.show($event, section);
    }
    this.sectionSelected(section);
  }

  elementPropertiesChanged(element: LegacyElementViewModel) {
    this.apply(element.changeEvent());
    element.updateView();
  }

  elementVariableNameChanged(element: LegacyElementViewModel) {
    const variable = required(element.variable, "variable");
    const variablesWithNewNameUsedInElements = this.usedVariablesByNameCount(variable.fullVariablePath());

    if(variablesWithNewNameUsedInElements > 1) {
      toastr.error("Variable with given name already exists");
      variable.revertVariableName();
    } else {
      const elementChangeEvent = element.changeEvent();
      this.apply(new VariableNameChangedV1(VariableTypePath.parse(variable.fullPreviousVariableName()), variable.variableName), elementChangeEvent);
      element.updateView();
    }
  }


  private usedVariablesByNameCount(path: VariableTypePath): number {
    return __(this.variables.variables)
      .filter(v => v.fullVariablePath().isEqual(path))
      .filter(v => this.variableIsUsedInLegacyForm(v)) // find only used by elements, not actions as in new interface we'll not remove those saved in actions
      .length;
  }

  private variableIsUsedInLegacyForm(variable: LegacyVariableViewModel) {
    const inElements = this.viewModel.nodes.flatMap(n => n.legacy.sections)
      .flatMap(s => s.elements)
      .some(e => e.element.variable === variable);
    const inSections = this.viewModel.nodes
      .flatMap(n => n.legacy.sections)
      .some(s => s.variable === variable);
    return inElements || inSections;
  }

  sectionVariableNameChanged(section: LegacyFormSectionViewModel) {
    const variable = required(section.variable, "variable");

    const variablesWithNewNameUsedInElements = this.usedVariablesByNameCount(variable.fullVariablePath());

    if(variablesWithNewNameUsedInElements > 1) {
      toastr.error("Variable with given name already exists");
      variable.revertVariableName();
    } else {
      const elementChangeEvent = section.changeEvent();

      const subElementEvents = section.elements.filter(e => e.element.variable).map(e => {
        return e.element.changeEvent();
      });

      this.apply(...subElementEvents.concat([new VariableNameChangedV1(VariableTypePath.parse(variable.fullPreviousVariableName()), variable.variableName), elementChangeEvent]));
      section.updateView();
    }
  }

  elementVariableTypeChanged(element: LegacyElementViewModel) {
    const variable = required(element.variable, "variable");
    switch (variable.variableTypeName) {
      case "StringVariableType": variable.variableType = new StringVariableType(); break;
      case "NumberVariableType": variable.variableType = new NumberVariableType(element.numberPercent, 0, element.numberUnit.trim().length > 0 ? Some(element.numberUnit.trim()) : None()); break;
      case "DateVariableType": variable.variableType = new DateVariableType(); break;
      case "DateTimeVariableType": variable.variableType = new DateTimeVariableType(); break;
      case "TimeVariableType": variable.variableType = new TimeVariableType(); break;
      case "OrganizationNodeVariableType": variable.variableType = new OrganizationNodeVariableType(); break;
      case "PersonVariableType": variable.variableType = new PersonVariableType(); break;
      case "ArrayVariableType[NumberVariableType]": variable.variableType = ArrayVariableType.of(new NumberVariableType()); break;
      case "ArrayVariableType[StringVariableType]": variable.variableType = ArrayVariableType.of(new StringVariableType()); break;
      case "ArrayVariableType[OrganizationNodeVariableType]": variable.variableType = ArrayVariableType.of(new OrganizationNodeVariableType()); break;
      default:
        toastr.error("Unsupported type: " + variable.variableTypeName);
        throw new Error("Unsupported type: " + variable.variableTypeName);
    }

    this.apply(new VariableTypeUpdatedV1(VariableTypePath.parse(variable.fullVariableName()), Typed.of(required(variable.variableType, "variableType"))));

    element.updateView();

  }

  elementRefPropertiesChanged(element: LegacyRefElementViewModel) {
    const oldSection = required(this.sections.find(s => s.elements.indexOf(element) >= 0), "section");
    this.apply(element.changeRefEvent(oldSection.sectionId, oldSection.sectionId));
  }


  sectionPropertiesChanged(section: LegacyFormSectionViewModel) {
    this.apply(section.changeEvent())
  }

  sectionRefPropertiesChanged(section: LegacyFormSectionViewModel) {
    this.apply(section.refChangeEvent(this.nodeId))
  }

  sectionNameChanged(section: LegacyFormSectionViewModel) {
    this.apply(new SectionNameChanged(section.sectionId, section.name.getCurrentWithFallback()));
  }

  elementTooltipChanged(element: LegacyElementViewModel) {
    this.apply(new TooltipChanged(element.elementId, element.tooltip.getCurrentWithFallback()));
  }


  variableChanged(variable: LegacyVariableViewModel) {
    this.apply(new VariableExpressionUpdated(variable.fullVariablePath(), ExpressionWithAst.of(variable.expression), variable.automaticVariable));
  }

  defaultEntryChanged(element: LegacyElementViewModel, entry: {value: string|number|null, checked: boolean}) {
    element.staticEntries.forEach(e => {
      if(e !== entry) {
        e.checked = false;
      }
    });
    this.elementPropertiesChanged(element);
  }

  addNewValidation() {
    this.validations.push(new LegacyFormValidation("", ""));
  }

  validationChanged() {
    this.apply(new FormValidationChanged(this.nodeId, this.validations.map(v => {
      return new FormModelValidation(Typed.of(new ScriptInputType(v.validationExpression)), Typed.of(new ScriptInputType(v.messageExpression)))
    })));
  }

  deleteValidation(validation: LegacyFormValidation) {
    removeFromArray(this.validations, validation);
    this.validationChanged();
  }

  deleteActionRef(element: LegacyElementViewModel, actionRef: LegacyButtonActionRefViewModel) {
    removeFromArray(element.actionsRefs, actionRef);
    this.elementPropertiesChanged(element);
  }

  buttonActionsChanged(element: LegacyElementViewModel) {
    this.elementPropertiesChanged(element);
  }

  addNewAction(element: LegacyElementViewModel) {
    const nextActionId = this.viewModel.nextLegacyActionId();
    element.actionsRefs.push(new LegacyButtonActionRefViewModel(new LegacyButtonActionViewModel(nextActionId, "", [])));
    this.elementPropertiesChanged(element);
  }

  allButtonsActions() {
    return this.sections.flatMap(s => s.elements).flatMap(e => e.element.actionsRefs);
  }
}
