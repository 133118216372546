import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {
  $$,
  __,
  global,
  htmlEscape,
  mySetTimeout,
  None,
  Option,
  PointerXY,
  prettySize,
  runInAngularZone,
  SimpleFileUploaderController,
  Some,
  toastr,
  UploadFileResult, UploadFileResultHelper, UploadFileSuccess
} from "@utils";
import {LibrariesService, SessionServiceProvider} from "@shared";
import {MultiAttachmentInputComponentViewModel} from "./MultiAttachmentInputComponentViewModel";
import {AttachmentEntry, FileInfoViewModel} from "./AttachmentInputCommon";
import {RefIdInContext} from "../../model/screen.runtime-model";
import {MultiAttachmentInputComponentDefinition, SingleAttachmentInputComponentDefinition} from "@screen-common";


declare const qq: any;

@Component({
  selector: 'my-multi-attachment-input-component',
  templateUrl: './multi-attachment-input-component.component.html',
  host: {
      "[class]": "'componentBody MultiAttachmentInputComponent ' + viewModel.customCssClass + ' ' + viewModel.combinedCssClasses",
      "[responsive-class]": "['mobile', 30]",
      "[style]": "viewModel.combinedCss",
      "[class.minimal]": "viewModel.minimal",
      "[class.disabled]": "viewModel.disabled",
      "[class.preview]": "viewModel.preview",
      "[class.validationError]": "viewModel.validationError"
  }
})
export class MultiAttachmentInputComponentComponent implements OnInit, OnDestroy {
  @Input({required:true}) viewModel!: MultiAttachmentInputComponentViewModel;

  uploadHandlerInitialized: boolean = false;
  dropAreaInitialized = false;
  fileUploadController: Option<SimpleFileUploaderController<UploadFileResult>> = None();
  dragAndDropModule: Option<any> = None();


  contextMenuPointAnchor?: PointerXY;
  contextMenuVisible: boolean = false;
  contextMenuEntry!: AttachmentEntry;


  constructor(private readonly viewContainerRef: ViewContainerRef,
              private readonly librariesService: LibrariesService,
              private readonly sessionServiceProvider: SessionServiceProvider) {}

  cancelUpload = (entry: FileInfoViewModel) => {
    this.hideDropZone();
    this.fileUploadController.forEach(c => c.cancelUpload(entry.uploadId));
    this.viewModel.onCancel(entry);
  };



  ngOnInit(): void {
    this.librariesService.loadFineUploader(() => {
      mySetTimeout(() => {
        this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {
          this.initUploadHandler(organizationSessionInfo.sessionToken);

          if (!this.dropAreaInitialized) {
            const uploadDropZone = $$(this.viewContainerRef).findOrError(".fileUploadDropZone").getAsHtmlElement();
            this.dragAndDropModule.forEach(d => d.setupExtraDropzone(uploadDropZone));
            this.dropAreaInitialized = true;
          }
        });
      });

    });
  }


  initUploadHandler(sessionToken: string) {

    this.uploadHandlerInitialized = true;

    const element = <HTMLElement>this.viewContainerRef.element.nativeElement;
    const uploadButtonSelector: HTMLElement = <HTMLElement>(element.querySelector(".uploadButton"));

    if(uploadButtonSelector === null) {
      throw new Error("No uploadButton")
    } else {

      this.fileUploadController = Some(new SimpleFileUploaderController(
        'screen/add-file-to-model',
        uploadButtonSelector,
        sessionToken, this.viewModel.allowedExtensions, global.config.maxFileSize,
        true, true,
        () => {},
        (id: number, name: string, responseJSON: UploadFileResult) => this.onFileUploadComplete(id, name, responseJSON),
        (id, fileName, instance) => this.onFileUpload(id, fileName, instance),
        (file: { name: string, size: number }): boolean => this.onFileUploadValidate(file),
        (id: any, name: any, reason: any, request: any) => this.onFileUploadError(id, name, reason, request),
        (id: number, name: string, uploadedBytes: number, totalBytes: number) => this.onFileUploadProgress(id, name, uploadedBytes, totalBytes)));


      this.dragAndDropModule = Some(new qq.DragAndDrop({
        allowMultipleItems: true,
        dropZoneElements: [],
        classes: {
          dropActive: "theme-file-drop-zone-active"
        },
        callbacks: {
          processingDroppedFiles: () => {
            this.hideDropZone();
          },
          processingDroppedFilesComplete: (files: string | Array<any>, dropTarget: any) => {
            // 0 elements can appear for files with strange names on Chrome - dnd module of fine uploader uses
            // webkitGetAsEntry method which does not work for strange file names
            this.hideDropZone();
            if (!this.viewModel.disabled && this.viewModel.uploadAllowed) {
              if (files.length > 0) {
                this.fileUploadController.forEach(c => c.fineUploaderBasicInstance.addFiles(files)); //this submits the dropped files to Fine Uploader
              } else {
                toastr.error("File name not allowed");
              }
            }
          }
        }
      }));
      this.initListeners();
    }


  }

  private initListeners() {
    const uploadDropZone = $$(this.viewContainerRef).findOrError(".fileUploadDropZone").getAsHtmlElement();
    uploadDropZone.addEventListener("dragenter", (event) => {
      document.dispatchEvent(new DragEvent("dragenter", event)); // this is to notify other drop zones that dragenter happened
    });

    document.addEventListener("dragenter", this.onDragEnter);
    document.addEventListener("dragleave", this.onDragLeave);
    document.addEventListener("drop", this.onDrop);
  }


  ngOnDestroy(): void {
    document.removeEventListener("dragenter", this.onDragEnter);
    document.removeEventListener("dragleave", this.onDragLeave);
    document.removeEventListener("drop", this.onDrop);
  }

  onDragEnter = (e: Event) => {
    if (!this.viewModel.disabled) {
      const dt = (<DragEvent>e).dataTransfer;
      if (dt !== null && dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : __(<Array<string>>dt.types).contains('Files'))) {
        this.showDropZone()
      }
    }
  }

  onDragLeave = (e: Event) => {
    if ((<DragEvent>e).relatedTarget == null) { //moves outside window, otherwise it's other elementthat can handle drop
      this.hideDropZone();
    }
  };


  onDrop = (e: Event) => {
    this.hideDropZone();
  };

  showDropZone() {
    const element = <HTMLElement>this.viewContainerRef.element.nativeElement;
    const uploadDropZone = element.querySelector(".fileUploadDropZone")

    if(uploadDropZone !== null) {
      uploadDropZone.classList.add("theme-file-drop-zone");
    }
  }

  hideDropZone() {
    const element = <HTMLElement>this.viewContainerRef.element.nativeElement;
    const uploadDropZone = element.querySelector(".fileUploadDropZone")
    if(uploadDropZone !== null) {
      uploadDropZone.classList.remove("theme-file-drop-zone");
      uploadDropZone.classList.remove("theme-file-drop-zone-active");
    }
  }


  onFileUploadComplete(uploadId: number, name: string, result: UploadFileResult){
    this.hideDropZone();
    if(UploadFileResultHelper.isSuccess(result)) {
      runInAngularZone(() => {
        this.viewModel.fileUploadCompleted(uploadId, UploadFileResultHelper.getFileUri(result));
      });
    } else {
      toastr.error("Error while uploading file: " + UploadFileResultHelper.getError(result));
      runInAngularZone(() => {
        this.viewModel.fileUploadError(uploadId);
      });
    }
  }

  onFileUploadProgress(upladId: number, name: string, uploadedBytes: number, totalBytes: number){
    this.hideDropZone();
    runInAngularZone(() => {
      this.viewModel.fileUploadProgress(upladId, uploadedBytes, totalBytes);
    });
  }

  onFileUploadError(uploadId: number, name: string, reason: string, request: any){
    this.hideDropZone();
    toastr.error(reason);
    runInAngularZone(() => {
      this.viewModel.fileUploadError(uploadId);
    });
  }

  onFileUpload(id: number, fileName: string, fineUploaderBasicInstance: any){

    this.hideDropZone();

    // TODO for now versioning is disabled
    // if(previousFile.isDefined()) {
    //   fineUploaderBasicInstance.setParams({
    //     aggregateId: taskInfo .flowId.id,
    //     taskId: taskInfo.taskId.id,
    //     flowCursorVersion: taskInfo.cursorVersion,
    //     sectionId: scope.viewModel.sectionId.id,
    //     fieldId: scope.viewModel.elementId.id,
    //     fileUri: previousFile.get().uri.serialize(),
    //     version: previousFile.get().version
    //   });
    // } else {
    fineUploaderBasicInstance.setParams({
      mode: "append",
      instanceId: this.viewModel.instanceId.id,
      refsPath: this.serializeRefsPath(this.viewModel.componentRefPath()),
      actionsTriggered: MultiAttachmentInputComponentDefinition.ON_CHANGE,
      modelName: MultiAttachmentInputComponentDefinition.MODEL,
    });
    // }

    this.viewModel.newFileUploadStarted(id, fileName);
  }

  serializeRefsPath(path: Array<RefIdInContext>): string {
    return path.map(r => r.refId.screenId+"_"+r.refId.id+"_"+r.contextId.id).join(";");
  }

  onFileUploadValidate(file: {name: string, size: number}){
    this.hideDropZone();

    if(this.viewModel.disabled) {
      toastr.error("You are not allowed to upload to read only field");
      return false;
    } else if(file.size == 0) {
      toastr.error("You are not allowed to upload empty file");
      return false;
    } else if(file.size > global.config.maxFileSize) {
      toastr.error("File is too large, max allowed file size is "+prettySize(global.config.maxFileSize));
      return false;
    } else if(!this.viewModel.extensionValid(file.name)){
      toastr.error("File type not allowed for file "+htmlEscape(file.name)+", allowed: "+htmlEscape(this.viewModel.allowedExtensions.join(", ")));
      return false;
    } else {
      return true;
    }
  }


  showContextMenu($event: MouseEvent, entry: AttachmentEntry) {
    this.contextMenuPointAnchor = {x: $event.x, y: $event.y};
    this.contextMenuVisible = true;
    this.contextMenuEntry = entry;
  }

  closeContextMenu() {
    this.contextMenuVisible = false;
    this.contextMenuEntry = undefined!;
    this.contextMenuPointAnchor = undefined;
  }
}
