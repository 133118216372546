import {AggregateVersion, ApplicationId, Option, ReportId} from "@utils";

export class RunnableReportSummary {

  constructor(readonly id: ReportId,
              readonly identifier: string,
              readonly applicationId: Option<ApplicationId>,
              readonly name: string) {}

  static copy(other: RunnableReportSummary): RunnableReportSummary {
    return new RunnableReportSummary(
      ReportId.copy(other.id),
      other.identifier,
      Option.copy(other.applicationId, ApplicationId.of),
      other.name);
  }

  identifierOrId() {
    return this.identifier.length === 0 ? ("@" + this.id.id.id) : this.identifier;
  }
}

export class ReportSummary {

  constructor(readonly id: ReportId,
              readonly version: AggregateVersion,
              readonly applicationId: Option<ApplicationId>,
              readonly name: string,
              readonly published: boolean) {}

  static copy(other: ReportSummary): ReportSummary {
    return new ReportSummary(ReportId.copy(other.id), AggregateVersion.copy(other.version), Option.copy(other.applicationId, ApplicationId.of), other.name, other.published);
  }
}


export class ReportSummaryWithApplicationInfo {
  constructor(readonly applicationId: Option<ApplicationId>,
              readonly applicationName: string,
              readonly report: ReportSummary) {}

  static copy(other: ReportSummaryWithApplicationInfo) {
    return new ReportSummaryWithApplicationInfo(Option.copy(other.applicationId, ApplicationId.of), other.applicationName,
      ReportSummary.copy(other.report))
  }
}
