/**
 * Function for clearing array in place.
 */
import {Typed} from "./Typed";
import {__} from "../__";


export function clearArray(array: Array<any>) {
  while(array.length > 0) {
    array.pop();
  }
}

export function replaceArrayContent<T>(dst: Array<T>, newContent: Array<T>): void {
  clearArray(dst);
  newContent.forEach(e => dst.push(e));
}

export function objectToTuplesArray<T>(obj: { [id: number]: T }): Array<[number, T]> {
  const arr: Array<[number, T]> = [];
  Object.keys(obj).forEach(key => {
    arr.push([parseInt(key), obj[parseInt(key)]]);
  });
  return arr;
}

export function overwriteArray(dst: Array<any>, src: Array<any>): void {
  while(dst.length > 0) {
    dst.pop();
  }
  src.forEach(e => dst.push(e));
}

export function arrayAppend<T>(dst: Array<T>, src: Array<T>): void {
  src.forEach(e => dst.push(e));
}

export function collectFirst<T, R>(array: Array<T>, predicate: (element: T) => R|undefined|null): R|null {
  for(let i = 0; i < array.length; i++) {
    const result = predicate(array[i]);
    if(result !== undefined && result !== null) {
      return result;
    }
  }
  return null;
}


/** remove element from given array, element must be in array */
export function removeFromArray<T>(array: Array<T>, element: T) {
  const index = array.indexOf(element);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    throw new Error("element not found at the array");
  }
}

/** remove element from given array, element must be in array */
export function removeFromArrayAnyOccurance<T>(array: Array<T>, element: T) {
  let index = array.indexOf(element);
  while (index > -1) {
    array.splice(index, 1);
    index = array.indexOf(element)
  }
}

export function replaceInArrayBy<T>(array: Array<T>, element: T, predicate: (element: T) => boolean) {
  const index = __(array).findIndexOf(predicate).getOrError("Element not found");
  array[index] = element;
}

export function removeFromArrayBy<T>(array: Array<T>, predicate: (element: T) => boolean) {
  let index = __(array).findIndexOf(predicate).getOrElse(-1);

  while(index > -1) {
    array.splice(index, 1);
    index = __(array).findIndexOf(predicate).getOrElse(-1);
  }
}


export function createArrayFrom0ToNExclusive(n: number) {
  const numbers: number[] = [];
  for(let i = 0; i< n; i++) {
    numbers.push(i);
  }
  return numbers;
}

export function createArray<T>(n: number, element: T) {
  const arr: Array<T> = [];
  for(let i = 0; i< n; i++) {
    arr.push(element);
  }
  return arr;
}

export function indexOfTyped<T extends {className():string}>(arr: Typed<T>[], element: Typed<T>): number {
  if(element) {
    for(let i = 0; i < arr.length; i++) {
      if(Typed.value(arr[i]) === Typed.value(element)) {
        return i;
      }
    }
  }
  return -1;
}

// works for GridProcessNode, GridProcessEdge, GridProcessActor...
export function buildIdMap<T extends {id: number}, V>(elems: Array<T>, value: (entry: T) => V): { [id: number]: V} {
  const result: { [id: number]: V} = {};
  elems.forEach(elem => {
    result[elem.id] = value(elem);
  });
  return result;
}


export function moveElementInArray<T>(array: Array<T>, fromIndex: number, toIndex: number): Array<T> {
	if (toIndex >= array.length) {
		// var k = toIndex - array.length;
		// while ((k--) + 1) {
		// 	array.push(undefined);
		// }
    throw new Error("Not implemented");
	}
	array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
	return array;
}


export function nullableEquals(a: any|null, b: any|null, equals: (a: any, b: any) => boolean = (a, b) => a === b): boolean {
  if(a === null && b === null) {
    return true;
  } else if(a === null || b === null) {
    return false;
  } else {
    return equals(a, b);
  }
}

