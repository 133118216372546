import {__, clearArray, i18n, LocalDate, Option, required} from "@utils";
import {CalendarHelper} from "./CalendarHelper";
import {CalendarEventBus} from "./CalendarEventBus";
import {WeeklyCalendarHalfHourViewModel} from "./WeeklyCalendarViewModel";
import {CalendarComponent, CalendarEntryModel} from "./calendar.component";

export class YearlyCalendarEntry {

    readonly id = CalendarComponent.nextPeriodId();
    readonly mocks: Array<YearlyCalendarEntry> = [];
    public parent: YearlyCalendarEntry|null = null;

    constructor(readonly entry: CalendarEntryModel|null,
                public parentDay: YearlyCalendarDay|null,
                public index: number,
                readonly startDate: LocalDate|null,
                public endDate: LocalDate|null,
                readonly startDay: number,
                public endDay: number,
                public span: number,
                public cssSize: string,
                readonly name: string,
                readonly color: string,
                readonly inverted: boolean,
                readonly placeholder: boolean,
                public rows: number,
                public selected: boolean) {}

    static mock(parentDay: YearlyCalendarDay, parent: YearlyCalendarEntry|null) {
      const mock = new YearlyCalendarEntry(null, parentDay, -1, null, null, 0,0, 0, "calc(100% + 1px)", "", "", false, true, 0, false);
      mock.parent = parent;
      return mock;
    }

    updateMaxRows(rows: number, level: number = 1) {
      this.rows = rows;
      if(this.parent && this.parent.rows < rows) {
        console.log("Updating parent to " + rows, level);
        this.parent.updateMaxRows(rows, level + 1);
      }
      this.mocks.forEach(m => {
        if(m.rows < rows) {
          console.log("Updating mock to " + rows, level);
          m.updateMaxRows(rows, level + 1);
        }
      });

      if(this.parentDay && this.parentDay.rows < rows) {
        this.parentDay.updateMaxRows(rows, level + 1);
      }

    }
  }

  export class YearlyCalendarDay {

    readonly id = CalendarComponent.nextPeriodId();
    public selected: boolean = false;

    constructor(readonly date: LocalDate,
                readonly mock: boolean,
                readonly off: boolean,
                readonly visibleEntries: Array<YearlyCalendarEntry>,
                readonly entries: Array<YearlyCalendarEntry>,
                public rows: number) {}

    pushEntryToEmptySpace(entry: YearlyCalendarEntry): void {
      for(let i = 0; i < this.visibleEntries.length; i++) {
        if(!this.visibleEntries[i]) {
          this.visibleEntries[i] = entry;
          entry.index = i;
          return;
        }
      }
      if(this.visibleEntries.length < WeeklyCalendarHalfHourViewModel.MAXIMUM_VISIBLE_ENTRIES) {
        this.visibleEntries.push(entry);
        entry.index = this.visibleEntries.length - 1;
      }
      console.log("Pushed entry to index " +entry.index+" of "+this.date.formatted(), entry);
      entry.parentDay = this;
      this.entries.push(entry);
    }

    pushMockToIndex(entryPart: YearlyCalendarEntry): void {
      if(entryPart.index < WeeklyCalendarHalfHourViewModel.MAXIMUM_VISIBLE_ENTRIES) {
        if (this.visibleEntries[entryPart.index]) {
          throw new Error("Entry occupied already");
        } else {
          this.visibleEntries[entryPart.index] = YearlyCalendarEntry.mock(this, entryPart);
          entryPart.parentDay = this;
          console.log("Pushing mock to index " + entryPart.index+" of "+this.date.formatted(), entryPart);
          entryPart.mocks.push(this.visibleEntries[entryPart.index]);
        }
      }
    }


    fillEmptyWithMocks() {
      for(let i = 0; i < this.visibleEntries.length; i++) {
        if (!this.visibleEntries[i]) {
          this.visibleEntries[i] = YearlyCalendarEntry.mock(this, null);
        }
      }
    }

    updateMaxRows(rows: number, level: number) {
      this.rows = rows;
      this.visibleEntries.forEach(e => {
        if(e.rows < rows) {
          e.updateMaxRows(rows, level + 1);
        }
      })
    }
  }

  export class YearlyCalendarMonth {
    constructor(readonly name: string,
                readonly year: number,
                readonly monthNumber: number,
                public days: Array<YearlyCalendarDay>) {
    }
  }

  export class YearlyCalendarDayHeader {
    constructor(readonly dayNumber: number,
                readonly name: string) {}
  }


  export class YearlyCalendarViewModel {

    private today: LocalDate = LocalDate.nowDate();
    year: number = this.today.year;

    daysHeader: Array<YearlyCalendarDayHeader>;
    months: Array<YearlyCalendarMonth>;
    entries: Array<CalendarEntryModel> = [];

    constructor(readonly parent: CalendarComponent,
                readonly eventBus: CalendarEventBus,
                readonly onSelected: (model: CalendarEntryModel) => void) {
      this.months = [];
      for(let m = 1; m <= 12; m++) {
        this.months.push(new YearlyCalendarMonth(i18n("date_month_get_"+m), this.year, m,[]));
      }

      this.daysHeader = [];
      for(let d = 1; d <= 31; d++) {
        this.daysHeader.push(new YearlyCalendarDayHeader(d, this.formatDay(d)))
      }

      this.updateView();

      eventBus.on(eventBus.entrySelected, (selectedEntry: CalendarEntryModel) => this.onEntrySelected(selectedEntry));
      eventBus.on(eventBus.periodsSelected, (from: number, to: number) => this.onPeriodsSelected(from, to));

    }

    private onEntrySelected(selectedEntry: CalendarEntryModel) {
      this.months.forEach(month => {
        month.days.forEach(day => {
          day.visibleEntries.forEach(entry => {
            entry.selected = entry.entry === selectedEntry;
          });
        });
      });
    }

    private updateView(): void {

      this.months.forEach(month => {
        clearArray(month.days);
        const allDays = this.daysInMonth(this.year, month.monthNumber);
        for(let d = 1; d <= 31; d++) {
          const date = new LocalDate(this.year, month.monthNumber, d);
          const weekDay = date.weekDay();
          month.days.push(new YearlyCalendarDay(date, d > allDays, weekDay == 0 || weekDay == 6, [], [], 1));
        }
      });


      this.prepareMonths(this.year, this.entries, this.months);

    }

    prepareMonths(year: number, sortedEntries: Array<CalendarEntryModel>, months: Array<YearlyCalendarMonth>) {
      sortedEntries.forEach(entry => {

        const [cssColor, inverted]: [string, boolean] = Option.of(entry.entryType).map(r => r.valueToSimpleString().toLowerCase()).map(entryType => {
          const id = (CalendarHelper.hashCode(entryType) % 16 + 16) % 16; // hashCode can be negative
          return <[string, boolean]>[CalendarHelper.colors[id], CalendarHelper.inverted[id]];
        }).getOrElse(<[string, boolean]>["#bdefe5", false]);

        const entryName = entry.label;


        const entryParts: Array<YearlyCalendarEntry> = [];

        const from = entry.from.date;
        const to = entry.to ? entry.to.date : entry.from.date;

        let currentTime = from;

        let entryPart = new YearlyCalendarEntry(entry, null,-1, from, from, from.day, from.day, 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, 1, this.parent.selectedValue.exists(s => s.isEqual(entry.value)));
        entryParts.push(entryPart);
        console.log("appending", entryPart);
        while(currentTime.isBefore(to)) {

          currentTime = currentTime.plusDays(1);

          if(currentTime.day == 1) {
            entryPart = new YearlyCalendarEntry(entry, null,-1, currentTime, currentTime, currentTime.day, currentTime.day, 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, 1, this.parent.selectedValue.exists(s => s.isEqual(entry.value)));
            entryParts.push(entryPart);
            console.log("appending", entryPart);
          } else {
            entryPart.span++;
            entryPart.endDate = currentTime;
            entryPart.endDay = currentTime.day;
            entryPart.cssSize = "calc("+100*entryPart.span +"% + "+ entryPart.span+"px)";
            console.log("appending day to " + entryPart.endDate.formatted()+", "+entryPart.span);
          }

        }

        entryParts.forEach(entryPart => {
          months.forEach(month => {
            const startDate = required(entryPart.startDate, "startDate");
            const endDate = required(entryPart.endDate, "endDate");
            if(year === startDate.year && month.monthNumber == startDate.month) {
              month.days.forEach(day => {
                if (day.date.day == startDate.day) {
                  day.pushEntryToEmptySpace(entryPart);
                } else if (day.date.day > startDate.day && day.date.day <= endDate.day) {
                  day.pushMockToIndex(entryPart);
                }
              });
            }
          })

        });

      });

      months.forEach(month => {
        month.days.forEach(day => {
          day.fillEmptyWithMocks();
          day.rows = day.visibleEntries.length;
        });
      });

      months.forEach(month => {
        month.days.forEach(day => {
          day.visibleEntries.forEach(e => {
            e.updateMaxRows(day.rows);
          });
        });
      });


      months.forEach(month => {
        month.days.forEach(day => {
          day.rows = Math.max(day.rows, __(day.visibleEntries.map(e => e.rows)).maxOrDefault(1));
        });
      });
    }

    private daysInMonth(year: number, month: number): number {
      return new Date(year, month, 0).getDate();
    }

    previousYear() {
      this.year--;
      this.updateView();
    }


    nextYear() {
      this.year++;
      this.updateView();
    }

    updateEntries(entries: Array<CalendarEntryModel>) {
      this.entries = entries;
      this.updateView();
    }

    private formatDay(day: number): string {
      if(day < 10) {
        return "0"+day;
      } else {
        return ""+day;
      }
    }

    entrySelected(entry: YearlyCalendarEntry) {
      this.onSelected(required(entry.entry, "entry"));
      entry.selected = true;
    }

    private onPeriodsSelected(from: number, to: number) {

      this.months.forEach(month => {
        month.days.forEach(day => {
          day.selected = day.id >= from && day.id <= to;
        });
      })

    }
  }
