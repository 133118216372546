import {toastr} from "./toastr";
import {htmlEscape} from "./Html";

export function notImplementedException() {
  throw new Error("not implemented");
}

export function displayExceptions(exceptions: Array<string>) {
  toastr.error(htmlEscape(exceptions.join(", ")));
}
