<i *ngIf="viewModel.startIcon" class="start" [ngClass]="viewModel.startIcon"></i>

<input class="input {{viewModel.cssClasses}}" [style]="viewModel.css"
       [placeholder]="viewModel.placeholder" [disabled]="viewModel.disabled" [(ngModel)]="viewModel.value"
       [class.disabled]="viewModel.disabled"
       type="password" autocomplete="new-password"
       (blur)="viewModel.onBlur()" (focus)="viewModel.onFocus()"
       (keydown.enter)="viewModel.onBlur()"
       [maxlength]="viewModel.maxLength"
       />

<i *ngIf="viewModel.endIcon" class="end" [ngClass]="viewModel.endIcon"></i>

<!-- TODO -->
<!--1 my-on-enter="viewModel.onBlur()"-->
<!--2  [my-tooltip]="{position: 'top', text: viewModel.value}" -->

<!--3
ui-mask="{{viewModel.pattern}}"
         ui-options="{allowInvalidValue: true}"
         ui-mask-placeholder ui-mask-placeholder-char="_" model-view-value="true"
-->
