// We want to be able to use toastr without having to rely on angular dependency injection


import {global} from "./global";

export class ToastrCategory {
  static Global = "Global";
  static Task = "Task";
  static Case = "Case";
  static Application = "Application";
  static Process = "Process";
  static Report = "Report";
  static Datastore = "Datastore";
  static Constants = "Constants";
  static MapComments = "MapComments";
}


function error(text: string, tag: string = ""): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    console.log(text);
    global.toastrService.error(text, tag);
  }
}

function info(text: string, tag: string = ""): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.info(text, tag);
  }
}

function warning(text: string, tag: string = ""): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.warning(text, tag);
  }
}

function success(text: string, tag: string = ""): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.success(text, tag);
  }
}

function clearOldMessages(): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.clearOldMessages();
  }
}

function clearMessagesWithTag(tag: string): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.clearMessagesWithTag(tag);
  }
}

function infoWithTimeout(message: string, timeout: number = 5000, tag: string = ""): void {
  if(global.toastrService === undefined) {
    throw new Error("ToastrService is not initialized");
  } else {
    global.toastrService.infoWithTimeout(message, timeout, tag);
  }
}


export const toastr = {
  error: error,
  info: info,
  warning: warning,
  success,
  clearOldMessages,
  clearMessagesWithTag,
  infoWithTimeout
}



