import {Component} from "@angular/core";

@Component({
  selector: 'my-terms-and-policy',
  templateUrl: './terms-and-policy.component.html'
})
export class TermsAndPolicyComponent {

  constructor() {}

}
