import {
  BackgroundsPropertiesState,
  BordersPropertiesState, PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState
} from "../..";
import {Option} from "@utils";

export class HtmlComponentState extends ScreenComponentState {
  static className = "HtmlComponentState";
  className() {
    return HtmlComponentState.className;
  }

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get html() {
    return this.properties.stringProperty("html");
  }

  static copy(other: HtmlComponentState) {
    return new HtmlComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}


export class HtmlComponentRefState extends ScreenComponentRefState {
  static className = "HtmlComponentRefState";
  className() {
    return HtmlComponentRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: HtmlComponentRefState) {
    return new HtmlComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}
