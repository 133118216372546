<div class="filterContainer">
  <div class="theme-input-with-icon">
    <i class="searchIcon mi-search"></i>
    <input [ngClass]="{active: viewModel.resultsActive}"
           type="text" [(ngModel)]="viewModel.query" (ngModelChange)="viewModel.queryChanged()"
           tabindex="-1"
           placeholder="{{viewModel.newEntryPlaceholder}}" (focus)="viewModel.onFocus()"
           (keydown.arrowUp)="viewModel.onKeyDownUp($event)"
           (keydown.arrowDown)="viewModel.onKeyDownDown($event)"
           (keydown.enter)="viewModel.onKeyDownEnter($event)"
           (keydown.escape)="viewModel.onKeyDownEscape()"
           (blur)="viewModel.onBlur()"
           [focus-on-show]="viewModel.alwaysShowResults" />
  </div>
</div>
<div class="searchResults" *ngIf="viewModel.resultsActive">

  <div class="queryPrompt" *ngIf="viewModel.showPrompt">
    <span *ngIf="viewModel.isProcessModeClasses">
        {{'search_box_process_search_text' | i18n}}
      </span>
    <span *ngIf="viewModel.isAllModeClasses">
        {{'search_box_person_text' | i18n}}
      </span>
    <span *ngIf="viewModel.isPersonModeClasses">
        {{'search_box_person_search_text' | i18n}}
      </span>
    <span *ngIf="viewModel.isPersonAndDepartmentModeClasses">
        {{'search_box_person_search_department_text' | i18n}}
      </span>
  </div>

  <ng-container *ngIf="unselectEnabled">
    <div class="searchResult" (pointerdown)="viewModel.onUnselectClicked()">{{unselectLabel}}</div>
    <hr class="theme-content-horizontal-separator-small-margin" />
  </ng-container>
  <ng-container *ngIf="noneEnabled">
    <div class="searchResult" (pointerdown)="viewModel.onNoneClicked()">{{noneLabel}}</div>
    <hr class="theme-content-horizontal-separator-small-margin" />
  </ng-container>
  <div class="queryPrompt" *ngIf="viewModel.showNoResults">{{'search_box_person_no_results' | i18n}}</div>

  <ng-container *ngIf="viewModel.showResults">
<!--      <div class="searchResult personResult currentUser" *ngIf="viewModel.currentUserNode" [class.highlighted]="viewModel.highlightedResult.contains(-1)"-->
<!--           (mousedown)="viewModel.onNodeClicked(viewModel.currentUserNode)">-->
<!--        <ng-container *ngIf="viewModel.currentUserNode.person.isDefined()">-->
<!--          <my-avatar [person]="viewModel.currentUserNode.person.get()" />-->

<!--          <div *ngIf="currentUserLabel" class="nodeDescription" [ngClass]="{myGravatar: viewModel.currentUserNode.person.get().avatar.isDefined()}">-->
<!--            {{viewModel.currentUserLabel}}-->
<!--          </div>-->
<!--          <div *ngIf="!currentUserLabel" class="nodeDescription" [ngClass]="{myGravatar: viewModel.currentUserNode.person.get().avatar.isDefined(), extraDescription: viewModel.personNamesCounter[viewModel.currentUserNode.person.get().simpleName()] > 1}">-->
<!--            {{viewModel.currentUserNode.person.get().simpleName()}}-->

<!--            <div class="nodeExtraDescription" *ngIf="viewModel.personNamesCounter[viewModel.currentUserNode.person.get().simpleName()] > 1">-->
<!--              {{viewModel.currentUserNode.person.get().email}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </div>-->
<!--      <hr class="theme-content-horizontal-separator-small-margin" *ngIf="viewModel.currentUserNode" />-->
      <ng-container *ngFor="let node of viewModel.foundNodes; let i = index">
        <div class="searchResult"
             [ngClass]="{
              highlighted: viewModel.highlightedResult.contains(i),
              personResult: node.person.isDefined(),
              departmentResult: node.department.isDefined(),
              groupResult: node.group.isDefined(),
              currentUser: node.person.isDefined() && node.aggregateId.id === viewModel.currentUserId.id.id,
              processResult: node.process.isDefined(),
              reportResult: node.report.isDefined(),
              applicationResult: node.application.isDefined(),
              screenResult: node.screen.isDefined(),
              functionsResult: node.functions.isDefined()}"
              (click)="viewModel.onNodeClicked(node)">

          <ng-container *ngIf="node.person.isDefined()">
              <my-avatar [person]="node.person.get()" />

            <div *ngIf="viewModel.currentUserId.id.id === node.aggregateId.id && currentUserLabel" class="nodeDescription" [ngClass]="{myGravatar: node.person.get().avatar.isDefined()}">
              {{viewModel.currentUserLabel}}
            </div>

            <div  *ngIf="viewModel.currentUserId.id.id !== node.aggregateId.id || !currentUserLabel"
              class="nodeDescription" [ngClass]="{myGravatar: node.person.get().avatar.isDefined(), extraDescription: viewModel.personNamesCounter[node.person.get().simpleName()] > 1}">
              {{node.person.get().simpleName()}}

              <div class="nodeExtraDescription" *ngIf="viewModel.personNamesCounter[node.person.get().simpleName()] > 1">
                {{node.person.get().email}}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="node.department.isDefined()">
            <div class="theme-department-icon-small">
              <i class="mi-home"></i>
            </div>
            <div class="nodeDescription theme-department-name">
              {{node.department.get().name}}
            </div>
          </ng-container>

          <ng-container *ngIf="node.group.isDefined()">
            <div class="theme-group-icon-small">
              <i class="mi-user-group"></i>
            </div>
            <div class="nodeDescription theme-group-name">
              {{node.group.get().group.name}}
              <span class="applicationInfo" *ngIf="node.group.get().applicationId.isDefined()">{{node.group.get().applicationName}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="node.process.isDefined()">
            <div class="theme-process-icon-small">
              <i class="mi-right"></i>
            </div>
            <div class="nodeDescription theme-process-name">
              {{node.process.get().process.name}}
              <span class="applicationInfo" *ngIf="node.process.get().applicationId.isDefined()">[{{node.process.get().applicationName}}]</span>
            </div>
          </ng-container>

          <ng-container *ngIf="node.report.isDefined()">
            <div class="theme-report-icon-small">
              <i class="mi-barchart"></i>
            </div>
            <div class="nodeDescription theme-report-name">
              {{node.report.get().report.name}}
              <span class="applicationInfo" *ngIf="node.report.get().applicationId.isDefined()">[{{node.report.get().applicationName}}]</span>
            </div>
          </ng-container>

          <ng-container *ngIf="node.application.isDefined()">
            <div class="theme-application-icon-small">
              <i class="mi-tablet"></i>
            </div>
            <div class="nodeDescription">
              {{node.application.get().name}}
            </div>
          </ng-container>

          <ng-container *ngIf="node.screen.isDefined()">
            <div class="theme-screen-icon-small">
              <i class="mi-thumbnails"></i>
            </div>
            <div class="nodeDescription theme-screen-name">
              {{node.screen.get().screen.name}}
            </div>
          </ng-container>

          <ng-container *ngIf="node.functions.isDefined()">
            <div class="theme-functions-icon-small">
              <i class="mi-calculator"></i>
            </div>
            <div class="nodeDescription theme-functions-name">
              {{node.functions.get().functions.name}}
            </div>
          </ng-container>

        </div>
        <hr class="theme-content-horizontal-separator-small-margin" *ngIf="node.person.isDefined() && node.aggregateId.id === viewModel.currentUserId.id.id && viewModel.foundNodes.length > 1" />

      </ng-container>

    <div class="moreAvailableInfo" *ngIf="viewModel.moreAvailable">...<br /><br />Żeby wyszukać więcej rekordów podaj fragment ich nazwy</div>
  </ng-container>
</div>
