

export class Try<RESULT> {

  private _result: RESULT|null;
  private _error: Error|null;

  constructor(value: RESULT|null, error: Error|null) {
    if((value === undefined || value === null) && (error === undefined || error === null)) {
      throw new Error("Incorrect Try content, both value and error message are undefined.")
    } else if(value !== undefined && value !== null && error !== undefined && error !== null) {
      throw new Error("Incorrect Try content, both value and error message are defined.")
    } else {
      if (value === null || value === undefined) {
        this._result = null;
      } else {
        this._result = value;
      }
      if (error === null || error === undefined) {
        this._error = null;
      } else {
        this._error = error;
      }
    }
  }

  isSuccess() {
    return this._result !== null;
  }

  isFailure() {
    return this._error !== null;
  }

  get result(): RESULT {
    if(this._result !== null) {
      return this._result;
    } else {
      throw new Error("Cannot get result from a Failure: " + this.error.message);
    }
  }

  get error(): Error {
    if(this._error !== null) {
      return this._error;
    } else {
      throw new Error("Cannot get error from a Success");
    }
  }

  onSuccess(callback: (result: RESULT) => void): Try<RESULT> {
    if(this._result !== null) {
      callback(this._result);
    }
    return this;
  }

  onFailure(callback: (error: Error) => void): Try<RESULT> {
    if(this._error !== null) {
      callback(this._error);
    }
    return this;
  }

}

export function Success<RESULT>(result: RESULT) {
  return new Try<RESULT>(result, null);
}

export function Failure(error: Error|string): Try<any> {
  if(error instanceof Error) {
    return new Try(undefined, error);
  } else {
    return new Try(undefined, new Error(<string>error));
  }

}
