import {None, Option} from "@utils";
import {BusinessVariable, BusinessVariableType} from "@shared-model";

export class FormColumnModel {
  selected: boolean = false;

  constructor(public label: Option<string>,
              public name: string,
              public dataType: BusinessVariableType,
              public data: Option<BusinessVariable>) {
  }

  equals(other: FormColumnModel) {
    if (other.data.isDefined() && this.data.isDefined()) {
      return this.label.equals(other.label) && this.name == other.name && this.dataType.typeEqual(other.dataType) && this.data.get().isEqual(other.data.get());
    } else {
      return this.label.equals(other.label) && this.name == other.name && this.dataType.typeEqual(other.dataType) && this.data.equals(other.data);
    }
  }

  clearData() {
    this.data = None();
  }

  select() {
    this.selected = true;
  }

  static copy(other: FormColumnModel) {
    return new FormColumnModel(Option.copy(other.label), other.name, other.dataType, Option.copy(other.data.map(d => d.copy())));
  }
}
