import {MapComponentViewModel, MapMarkerViewModel} from "./MapComponentViewModel";
import {GeoCoordinate, GeoCoordinateVariable, ObjectVariable} from "@shared-model";
import {GoogleMap, MarkerClustererOptions} from "@angular/google-maps";
import {___, clearArray, mySetTimeout, None, Option, overwriteArray, Some} from "@utils";
import {MapComponentController} from "./MapComponentController";

declare class MarkerClusterer {
  constructor(map: google.maps.Map, markers?: google.maps.Marker[], options?: MarkerClustererOptions);
}

export class MapComponentViewModelWrapper {

  readonly warsaw = new GeoCoordinate(52.2296756, 21.0122287);

  static POSITION_UPDATE_TIMEOUT = 500;
  static DOUBLE_CLICK_TIMEOUT = 500;
  public positionChangeTimeout: number|null = null

  private prevent_map_event_fire = false;

  zoom: number = 12;
  center: google.maps.LatLng = new google.maps.LatLng(this.warsaw.latitude, this.warsaw.longitude);

  lastMarkerClickTime: number = 0;

  readonly markers: Array<google.maps.Marker> = [];
  public infoWindow: Option<google.maps.InfoWindow> = None();

  public selectedMarker: Option<google.maps.Marker> = None();
  public markerCluster: Option<any> = None();

  options: google.maps.MapOptions = {
    backgroundColor: "#f6f8f7",
    maxZoom: 19,
    minZoom: 3,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
    },
    streetViewControl: false,
    styles: [
      {
        "featureType": "landscape.man_made",
        "stylers": [
          {
            "lightness": 45
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ]
  };

  constructor(readonly viewModel: MapComponentViewModel,) {
  }
  private map: google.maps.Map|undefined;

  injectMap(map: GoogleMap): void {
    this.map = map.googleMap;
    this.init();
  }

  private init() {
    this.clearMarkers();
    this.initMarkers();


    const bounds = this.calculateBounds();

    this.prevent_map_event_fire = true;
    console.log("Fit bounds", bounds);
    this.map!.fitBounds(bounds);

    this.infoWindow = Some(new google.maps.InfoWindow({
      content: ""
    }));
  }

  private calculateBounds(){
    return this.viewModel.mapBoundaries
      .map(v => objectVariableToBounds(v as ObjectVariable))
      .getOrElse(this.calculateDefaultBounds());
  }

  private calculateDefaultBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => bounds.extend(marker.getPosition()!));

    if (this.markers.length > 0) {
      let maxLat = ___(this.markers).map(m => <number>m.getPosition()!.lat()).max();
      let maxLng = ___(this.markers).map(m => <number>m.getPosition()!.lng()).max();
      let minLat = ___(this.markers).map(m => <number>m.getPosition()!.lat()).min();
      let minLng = ___(this.markers).map(m => <number>m.getPosition()!.lng()).min();
      bounds.extend(new google.maps.LatLng(minLat - 0.001, minLng - 0.001));
      bounds.extend(new google.maps.LatLng(maxLat + 0.001, maxLng + 0.001));
    } else {
      bounds.extend(new google.maps.LatLng(this.warsaw.latitude - 0.03, this.warsaw.longitude - 0.03));
      bounds.extend(new google.maps.LatLng(this.warsaw.latitude + 0.03, this.warsaw.longitude + 0.03));``
    }
    return bounds;
  }

  initMarkers() {
    if(this.map !== undefined) {
      const newMarkers = this.viewModel.markers.map(marker => this.createMarker(marker));
      overwriteArray(this.markers, newMarkers);

      // this.markerCluster.forEach(m => m.clearMarkers());
      //
      // if (this.markerCluster.isEmpty()) {
      //   console.log("Init clusterer", this.markers);
      //   this.markerCluster = Some(new MarkerClusterer(this.map, this.markers, {
      //     imagePath: "assets/images/markerclusterer/m1.png",
      //     // imageFunction: this.clusterImageFunction,
      //     minimumClusterSize: 3,
      //     maxZoom: 13
      //   }
      //   // , () => {
      //   //   this.selectedMarker = None();
      //   // }
      //   ));
      // } else {
      //   this.markerCluster.get().addMarkers(this.markers);
      // }
    }
  }

  readonly clusterImageFunction: (n: number) => string = (n: number) => {
    switch(n) {
      case 1: return "assets/images/markerclusterer/m1.png";
      case 2: return "assets/images/markerclusterer/m2.png";
      case 3: return "assets/images/markerclusterer/m3.png";
      case 4: return "assets/images/markerclusterer/m4.png";
      case 5: return "assets/images/markerclusterer/m5.png";
      default: return "assets/images/markerclusterer/m5.png";
    }
  }

  createMarker(point: MapMarkerViewModel): google.maps.Marker {

    const marker = new google.maps.Marker({
      position: geoCoordinateToLatLng(point.coordinate),
      map: this.map,
      icon: MapComponentController.MARKER_ICON,
      label: point.label.getOrUndefined(),
      title: point.description.getOrUndefined()
    });

    marker.addListener('click', () => this.onMarkerSelected(marker) );

    setMarkerViewModel(marker, point);

    return marker;
  }


  private onMarkerSelected(marker: google.maps.Marker){
    this.isSelectedMarker(marker) && !this.isMarkerDoubleClick() ?
      this.unselectAllMarkers() : this.selectMarker(marker);
    this.updateLastMarkerClickTime();
  }


  private updateLastMarkerClickTime() {
    this.lastMarkerClickTime = new Date().getTime();
  }


  private unselectAllMarkers(){
    this.selectedMarker = None();
    this.markers.forEach(this.markUnselected);
    this.viewModel.unselect();
    this.infoWindow.map(window => window.close());
  }


  private isMarkerDoubleClick() {
    return new Date().getTime() - this.lastMarkerClickTime < MapComponentController.DOUBLE_CLICK_TIMEOUT;
  }

  private isSameMarker(marker1: google.maps.Marker, marker2: google.maps.Marker) {
    const model1 = getMarkerViewModel(marker1);
    const model2 = getMarkerViewModel(marker2);
    return model1.equals(model2);
  }


  private isSelectedMarker(marker: google.maps.Marker){
    return this.selectedMarker.isDefined() && this.isSameMarker(this.selectedMarker.get(), marker);
  }


  private markUnselected(marker: google.maps.Marker) {
    marker.setIcon(MapComponentController.MARKER_ICON)
  }


  private selectMarker(marker: google.maps.Marker) {
    const point = getMarkerViewModel(marker);
    this.infoWindow.map(info => info.close());
    if(point.description.exists(d => d.length > 0)) {
      this.infoWindow.map(info => {
        info.setContent(point.description.get());
        info.open(this.map, marker)
        // info.addListener('closeclick', () => {});
      });
    }

    this.markers.forEach(this.markUnselected);
    this.markSelected(marker);
    this.viewModel.markerSelected(point);
    this.selectedMarker = Some(marker);
  }

  private markSelected(marker: google.maps.Marker) {
    marker.setIcon(MapComponentController.MARKER_ICON_SELECTED);
  }




  clearMarkers() {
    this.markers.forEach(m => m.setMap(null));
    clearArray(this.markers);
  }

  onPositionChangeEventListener() {
    if(this.prevent_map_event_fire){
      mySetTimeout(() => this.prevent_map_event_fire = false, MapComponentViewModelWrapper.POSITION_UPDATE_TIMEOUT);
    } else {
      this.updateMapPositionInModel();
    }
  }

  private updateMapPositionInModel() {
    if(this.positionChangeTimeout !== null) {
      clearTimeout(this.positionChangeTimeout);
    }

    this.positionChangeTimeout = <number><unknown>mySetTimeout(() => {
      this.positionChangeTimeout = null;
      const center = this.map!.getCenter();
      const bounds = this.map!.getBounds();
      const southWest = bounds!.getSouthWest();
      const northEast = bounds!.getNorthEast();
      this.viewModel.mapPositionChanged(latLangToGeoCoordinate(center!), latLangToGeoCoordinate(southWest), latLangToGeoCoordinate(northEast));
    }, MapComponentController.POSITION_UPDATE_TIMEOUT);
  }


}

function geoCoordinateToLatLng(coordinate: GeoCoordinate) {
  return new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
}

function latLangToGeoCoordinate(coordinate: google.maps.LatLng) {
  return new GeoCoordinate(coordinate.lat(), coordinate.lng());
}

function setMarkerViewModel(marker: google.maps.Marker, viewModel: MapMarkerViewModel){
  marker.set("__marker_view_model__", viewModel);
}

function getMarkerViewModel(marker: google.maps.Marker): MapMarkerViewModel {
  return marker.get("__marker_view_model__");
}

function objectVariableToBounds(o: ObjectVariable) {
  const southWest = o.valueFor('southWest').map(c => (c as GeoCoordinateVariable).value).getOrError("Unable to convert ObjectVariable 'southWest' value to GeoCoordinate");
  const northEast = o.valueFor('northEast').map(c => (c as GeoCoordinateVariable).value).getOrError("Unable to convert ObjectVariable 'northEast' value to GeoCoordinate");
  return new google.maps.LatLngBounds(geoCoordinateToLatLng(southWest), geoCoordinateToLatLng(northEast));
}
