import {Injectable} from "@angular/core";
import {
  __, AggregateVersion,
  AnyPersonId,
  ApplicationId, Constants,
  None,
  Option, OrganizationId,
  OrganizationNodeId,
  PersonId,
  ResolvablePromise,
  Typed
} from "@utils";
import {
  ApplicationsSharedService,
  AuthenticatedHttp,
  BasicOrganizationNodeInfo, BasicPersonInfo,
  FindOrganizationNodes,
  FindOrganizationNodesByIds,
  OrganizationInfo, OrganizationNodeSearchResult,
  OrganizationStructureNodeInfo
} from "..";
import {GlobalEventBus} from "@shared";
import {PersonsSharedService} from "./persons.shared-service";
import {DepartmentsSharedService} from "./departments.shared-service";
import {GroupsSharedService} from "./groups.shared-service";

@Injectable({
  providedIn: 'root',
})
export class OrganizationSharedService {
  private organizationInfo: OrganizationInfo = null!;

  basicNodesInfoCache: {[nodeId: string]: ResolvablePromise<BasicOrganizationNodeInfo>} = {};

  static platformPersonInfo = new BasicPersonInfo(Typed.of(PersonId.of(Constants.platformPersonId)),AggregateVersion.ZERO,  OrganizationId.of("1ek7el8khedsk"), None(), "Platform", "", "", "", None(), false);

  constructor(private authenticatedHttp: AuthenticatedHttp,
              private personQueryService: PersonsSharedService,
              private departmentsQueryService: DepartmentsSharedService,
              private groupQueryService: GroupsSharedService,
              private applicationsQuerySharedService: ApplicationsSharedService,
              private globalEventBus: GlobalEventBus) {
    this.basicNodesInfoCache[Constants.platformPersonId.id.id] = ResolvablePromise.resolved<BasicOrganizationNodeInfo>(BasicOrganizationNodeInfo.fromPerson(OrganizationSharedService.platformPersonInfo));
  }


  //
  // findMyInfo(onSuccess: (person: Option<PersonInfo>) => void): void {
  //   if(this.myPersonInfo.isDefined()) {
  //     return onSuccess(this.myPersonInfo);
  //   } else {
  //     return this.authenticatedHttp.get("organization-structure/person/my-info",
  //       (data: Option<PersonInfo>) => {
  //         this.myPersonInfo = Option.copy(data).map(PersonInfo.copy);
  //         onSuccess(Option.copy(data).map(PersonInfo.copy));
  //       })
  //   }
  // }
  //
  // private myPersonInfo: Option<PersonInfo> = None();


  getOrganizationInfo(onSuccess: (organizationInfo: OrganizationInfo) => void) {
    if(this.organizationInfo) {
      onSuccess(this.organizationInfo);
    } else {
      this.authenticatedHttp.get("organization/info", (data: Option<OrganizationInfo>) => {
        const dataOption = Option.copy(data);
        if (dataOption) {
          const organizationInfo = OrganizationInfo.copy(dataOption.get());
          this.organizationInfo = organizationInfo;
          onSuccess(organizationInfo);
        } else {
          throw new Error("Problem with loading organization info");
        }
      });
    }
  }

  getOrganizationNodes(onSuccess: (response: Array<OrganizationStructureNodeInfo>) => void): void {
    this.authenticatedHttp.get("organization-structure/get-organization-nodes-by-organization-id",
      (data: Array<OrganizationStructureNodeInfo>) => onSuccess(data.map(OrganizationStructureNodeInfo.copy)))
  }



  // findOrganizationNodesInfosByIds(nodeIds: Array<AggregateId>, showDeleted: boolean,
  //                                 onSuccess: (persons: StructureOrganizationNodeInfo[],
  //                                             departments: StructureOrganizationNodeInfo[],
  //                                             groups: StructureOrganizationNodeInfo[]) => void): void {
  //   this.findNodesByIds(nodeIds, showDeleted, (nodes: OrganizationNode[]) => {
  //     const persons: OrganizationNode[] = [];
  //     const departments: OrganizationNode[] = [];
  //     const groups: OrganizationNode[] = [];
  //     nodes.forEach(n => {
  //       if(n.isDepartment() || n.isOrganization()) {
  //         departments.push(n)
  //       } else if (n.isPerson()) {
  //         persons.push(n)
  //       } else if (n.isGroup()) {
  //         groups.push(n)
  //       } else {
  //         throw new Error("Incorrect type");
  //       }
  //     });
  //
  //     let personsNodes: Array<StructureOrganizationNodeInfo>|null = null;
  //     let departmentsNodes: Array<StructureOrganizationNodeInfo>|null = null;
  //     let groupsNodes: Array<StructureOrganizationNodeInfo>|null = null;
  //
  //     if(persons.length > 0) {
  //       this.personService.findPersonsInfoByPersonsIds(persons.map(p => PersonId.of(p.id)), (personsInfos: Array<PersonInfo>) => {
  //         personsNodes = personsInfos.map(p => StructureOrganizationNodeInfo.ofPerson(persons.filter(pe => p.idUnwrapped().equals(PersonId.of(pe.id)))[0], p));
  //         if(departmentsNodes !== null && groupsNodes !== null) {
  //           onSuccess(personsNodes, departmentsNodes, groupsNodes);
  //         }
  //       });
  //     } else {
  //       personsNodes = [];
  //     }
  //
  //     if(departments.length > 0) {
  //       this.departmentsQueryService.findDepartmentsInfo(departments.map(p => new DepartmentId(p.id)), (departmentsInfos: Array<DepartmentInfo>) => {
  //         departmentsNodes = departmentsInfos.map(p => StructureOrganizationNodeInfo.ofDepartment(<DepartmentNode>departments.filter(pe => pe.id.id === p.id.id)[0], p));
  //         if(personsNodes !== null && groupsNodes !== null) {
  //           onSuccess(personsNodes, departmentsNodes, groupsNodes);
  //         }
  //       });
  //     } else {
  //       departmentsNodes = [];
  //     }
  //
  //     if(groups.length > 0) {
  //       this.groupQueryService.findGroupsInfos(groups.map(g => new GroupId(g.id)), (groupsInfos: GroupInfo[]) => {
  //         this.applicationQueryService.loadApplicationsNames(groupsInfos.filter(g => g.applicationId.isDefined()).map(g => g.applicationId.get()), (applicationNames: Array<ApplicationName>) => {
  //           groupsNodes = groupsInfos.map(p => {
  //
  //             const applicationName = p.applicationId.isDefined()
  //               ? __(applicationNames).find(a => a.id.id == p.applicationId.get().id).map(a => a.name).getOrElse("???")
  //               : "";
  //
  //             return StructureOrganizationNodeInfo.ofGroup(<GroupNode>groups.filter(pe => pe.id.id === p.id.id)[0], new GroupInfoWithApplicationInfo(p.applicationId, applicationName, p));
  //           });
  //           if(personsNodes !== null && departmentsNodes !== null) {
  //             onSuccess(personsNodes, departmentsNodes, groupsNodes);
  //           }
  //         });
  //       });
  //     } else {
  //       groupsNodes = [];
  //       if(personsNodes !== null && departmentsNodes !== null) {
  //         onSuccess(personsNodes, departmentsNodes, groupsNodes);
  //       }
  //     }
  //
  //
  //   });
  // }

  // findOrganizationTree(onSuccess: (response: DepartmentNode) => void) {
  //   this.authenticatedHttp.get("organization-structure/tree/organization",
  //     (typedData: Option<Typed<DepartmentNode>>) => {
  //       const response: Option<Typed<DepartmentNode>> = Option.copy(typedData).map(v => Typed.map(Typed.copy(v), (d : DepartmentNode) => DepartmentNode.copy(d)));
  //       if(response.isDefined()) {
  //         onSuccess(Typed.value(response.value));
  //       } else {
  //         toastr.error("Failed to retrieve organization structure!");
  //       }
  //     });
  // }
  //
  // findOrganizationTreeWithDeletedNodes(onSuccess: (response: DepartmentNode) => void) {
  //   this.authenticatedHttp.get("organization-structure/tree/organization-deleted-nodes",
  //     (typedData: Option<Typed<DepartmentNode>>) => {
  //       const response: Option<Typed<DepartmentNode>> = Option.copy(typedData).map(v => Typed.map(Typed.copy(v), (d : DepartmentNode) => DepartmentNode.copy(d)));
  //       if(response.isDefined()) {
  //         onSuccess(Typed.value(response.value));
  //       } else {
  //         toastr.error("Failed to retrieve organization structure!");
  //       }
  //     });
  // }
  //
  // findOrganizationNode(onSuccess: (response: StructureOrganizationNodeInfo) => void) {
  //   this.findOrganizationTree((node: DepartmentNode) => {
  //     this.departmentsQueryService.findDepartmentById(new DepartmentId(node.id), (department: Option<DepartmentInfo>) => {
  //       onSuccess(StructureOrganizationNodeInfo.ofDepartment(node, department.get()));
  //     });
  //   });
  // }
  //
  // findOrganizationTreePromise(): IPromise<DepartmentNode> {
  //   return this.authenticatedHttp.getPromise("organization-structure/tree/organization")
  //     .then((response: IHttpPromiseCallbackArg<Option<Typed<DepartmentNode>>>) => {
  //       const responseCopy = Option.copy(response.data).map(v => Typed.map(Typed.copy(v), (d : DepartmentNode) => DepartmentNode.copy(d)));
  //       if(responseCopy.isDefined()) {
  //         return Typed.value(responseCopy.value);
  //       } else {
  //         throw new Error("Failed to retrieve organization structure!");
  //       }
  //     },(reason: any) => {
  //       throw new Error("Promise not resolved: " + reason)
  //     })
  //
  // }
  //
  // findDepartmentsByDirectorId(directorId: AggregateId, onSuccess: (response: DepartmentNode[]) => void): void {
  //   this.authenticatedHttp.post("organization-structure/tree/departments-by-director",
  //     new FindDepartmentsByDirectorId(this.organizationSessionInfo.organizationId, directorId),
  //     (data: DepartmentNode[]) => {
  //       onSuccess(data);
  //     });
  // }
  //
  // findDirectSubordinates(supervisorId: PersonId, onSuccess: (response: PersonId[]) => void): void {
  //   this.authenticatedHttp.post("organization-structure/find-direct-subordinates", new FindDirectSubordinates(supervisorId),
  //     (data: PersonId[]) => {
  //       onSuccess(data.map(PersonId.of));
  //     });
  // }
  //
  //

  findPersonsByTextQuery(textQuery: string, personsWithinNodes: Option<Array<OrganizationNodeId>>, limit: number): Promise<{moreAvailable: boolean, persons: Array<AnyPersonId>}> {
    return this.findNodesByTextQuery(true, false, false, false, false, false, false, false, false,
      textQuery, None(), false, false, personsWithinNodes, limit)
      .then(n => ({moreAvailable: n.moreAvailable, persons: n.nodes.map(n => n.organizationNodeId.asPerson())}));
  }

  findNodesByTextQuery(persons: boolean, services: boolean, departments: boolean, groups: boolean, processes: boolean, reports: boolean,
                       applications: boolean, screens: boolean, functions: boolean, textQuery: string,
                       applicationId: Option<ApplicationId>, inAllApplications: boolean, fromGlobal: boolean,
                       personsWithinNodes: Option<Array<OrganizationNodeId>>,
                       limit: number): Promise<OrganizationNodeSearchResult> {
    return this.authenticatedHttp.postPromise<OrganizationNodeSearchResult>(
      "organization-structure/find-nodes-by-query",
      new FindOrganizationNodes(textQuery, persons, services, departments, groups, processes, reports, applications, functions, screens, applicationId, personsWithinNodes, inAllApplications, fromGlobal, limit)
    ).then(data => OrganizationNodeSearchResult.copy(data));
  }
  //
  // findNodesInGroup(groupId: GroupId, onSuccess: (nodes: BasicOrganizationNodeInfo[]) => void) {
  //   this.authenticatedHttp.post("organization-structure/find-nodes-in-group", new FindOrganizationNodesInGroup(groupId),
  //     (data: BasicOrganizationNodeInfo[]) => {
  //       onSuccess(data.map(BasicOrganizationNodeInfo.copy));
  //     });
  // }
  //

  findNodeBasicInfoById(id: OrganizationNodeId, onSuccess: (basicNodeInfo: Option<BasicOrganizationNodeInfo>) => void) {
    this.findNodesBasicInfoByIdsForApplication([id], None(), (nodes: Array<BasicOrganizationNodeInfo>) => {
      const found = __(nodes).find(node => node.organizationNodeId.equals(id));
      onSuccess(found);
    });
  }

  findNodesBasicInfoByIds(ids: OrganizationNodeId[], onSuccess: (nodes: BasicOrganizationNodeInfo[]) => void) {
    this.findNodesBasicInfoByIdsForApplication(ids, None(), onSuccess);
  }

  findNodesBasicInfoByIdsForApplication(ids: OrganizationNodeId[], applicationId: Option<ApplicationId>, onSuccess: (nodes: BasicOrganizationNodeInfo[]) => void) {

    const toLoad = ids.filter(id => !this.basicNodesInfoCache[id.id.id]);

    if(toLoad.length > 0) {

      toLoad.forEach(id => this.basicNodesInfoCache[id.id.id] = new ResolvablePromise<BasicOrganizationNodeInfo>());

      this.authenticatedHttp.post("organization-structure/find-nodes-by-ids", new FindOrganizationNodesByIds(toLoad),
        (data: BasicOrganizationNodeInfo[]) => {
          const nodes = data.map(BasicOrganizationNodeInfo.copy);
          nodes.forEach(node => {
            this.basicNodesInfoCache[node.aggregateId.id].resolve(node);
          });


          Promise.all(ids.map(id => this.basicNodesInfoCache[id.id.id].promise)).then(nodes => {
            onSuccess(this.markDeprecatedIfNotInScope(applicationId, nodes));
          });

        });
    } else {
      Promise.all(ids.map(id => this.basicNodesInfoCache[id.id.id].promise)).then(nodes => {
        onSuccess(this.markDeprecatedIfNotInScope(applicationId, nodes));
      });
    }
  }

  private markDeprecatedIfNotInScope(applicationId: Option<ApplicationId>, nodes: Array<BasicOrganizationNodeInfo>): Array<BasicOrganizationNodeInfo> {
    if(applicationId.isDefined()) {
      const appId = applicationId.get().id;
      return nodes.map(n => {
        const nodeAppId = n.contextApplicationId();
        if(nodeAppId.isEmpty() || nodeAppId.get().id == appId) {
          return n;
        } else {
          return BasicOrganizationNodeInfo.unavailableInfo(n.organizationNodeId);
        }
      })
    } else {
      return nodes;
    }
  }

}
