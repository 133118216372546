import {
  BusinessEntityId,
  BusinessEntityIdWithType,
  BusinessEntityTypeId,
  BusinessEntityTypeReleaseId,
  OrganizationId,
  Typed
} from "@utils";
import {BusinessVariable, BusinessVariableFactory} from "@shared-model";

export class BusinessEntitySummary {
  constructor(readonly id: BusinessEntityIdWithType,
              readonly releaseId: BusinessEntityTypeReleaseId,
              readonly organizationId: OrganizationId,
              readonly code: string,
              readonly name: Array<[string, Typed<BusinessVariable>]>,
              readonly description: Array<[string, Typed<BusinessVariable>]>,
              readonly deleted: boolean) {}

  static copy(other: BusinessEntitySummary) {
    return new BusinessEntitySummary(
      BusinessEntityIdWithType.copy(other.id),
      BusinessEntityTypeReleaseId.copy(other.releaseId),
      OrganizationId.copy(other.organizationId),
      other.code,
      other.name.map((e: [string, Typed<BusinessVariable>]) => <[string, Typed<BusinessVariable>]>[e[0], BusinessVariableFactory.copyTyped(e[1])]),
      other.description.map((e: [string, Typed<BusinessVariable>]) => <[string, Typed<BusinessVariable>]>[e[0], BusinessVariableFactory.copyTyped(e[1])]),
      other.deleted)
  }

  nameMap(): {[name: string]: BusinessVariable} {
    const obj: {[fieldName: string]: BusinessVariable} = {};
    this.name.forEach(n => obj[n[0]] = Typed.value(n[1]));
    return obj;
  }

  descriptionMap(): {[name: string]: BusinessVariable} {
    const obj: {[fieldName: string]: BusinessVariable} = {};
    this.description.forEach(n => obj[n[0]] = Typed.value(n[1]));
    return obj;
  }

  nameUnwrapped(): Array<BusinessVariable> {
    return this.name.map(e => Typed.value(e[1]));
  }

  nameText() {
    return this.nameUnwrapped().map(n => n.valueToSimpleString()).join(" ");
  }

  descriptionUnwrapped(): Array<BusinessVariable> {
    return this.description.map(e => Typed.value(e[1]));
  }

  descriptionText() {
    return this.descriptionUnwrapped().map(n => n.valueToSimpleString()).join(" ");
  }

}
