import {BasicAuthenticatedUserInfo, SessionServiceProvider} from "@shared";
import {toastr} from "@utils";

export class AuthenticateUserViewModel {

  loginVisible = false;
  confirmedVisible = false;
  linkNotValidVisible = false;

  login: string = "";
  password: string = "";

  constructor(
    readonly identityServiceUserToken: string,
    readonly sessionServiceProvider: SessionServiceProvider) {
  }

  init() {
    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.verifyUserIdentity(this.identityServiceUserToken, (info: BasicAuthenticatedUserInfo) => {
        this.confirmedVisible = true;
      }, () => {
        this.loginVisible = true;
      }, () => {
        this.linkNotValidVisible = true;
      }, () => {
        toastr.error("Error during identity check");
      })
    });

  }

  onSuccessfulLogin = () => {
    this.loginVisible = false;
    this.confirmedVisible = true;
  }


}
