import {
  AggregateId,
  AnyFlowId, AnyFlowIdFactory,
  AnyPersonId, ApplicationId,
  FileUri, FlowId,
  FormElementId,
  FormElementRefId,
  LocalDateTime,
  ObjectId,
  Option,
  OrganizationNodeId,
  PersonId,
  Typed,
  UploadFormFileResponse
} from "@utils";
import {
  BasicPersonInfo,
  BusinessVariable,
  BusinessVariableFactory,
  ContextPath,
  ContextVariable, CursorInfo,
  FlowCursorId,
  FlowCursorVersion,
  FlowImportance,
  FlowUrgency, PhaseWithStep, ProcessEdgeId, ProcessNodeId, TaskModelSummary
} from "@shared-model";
import {FlowAuthorization} from "../model/ProcessFlow";
import {FormSectionId, FormSectionRefId} from "@shared";


export class AssignPersonToRoleRouting {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly assignee: Typed<AnyPersonId>,
              readonly roleId: number) {}
}

export class AssignPersonToRoleBySupervisor {
  constructor(readonly aggregateId: AggregateId,
              readonly personId: PersonId,
              readonly roleId: number) {}
}

export class UnAssignPersonsFromRoleRouting {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly roleId: number,
              readonly personsIds: Array<Typed<AnyPersonId>>) {}
}

export class AssignSelfToRoleRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly roleId: number) {}
}

export class AssignSelfAndStartTaskRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly roleId: number,
              readonly flowCursor: FlowCursorId,
              readonly	flowCursorVersion: FlowCursorVersion) {}
}

export class AnonymizeFlowData {
  constructor(readonly aggregateId: AggregateId,
              readonly paths: Array<ContextPath>) {}
}

export class StartTaskRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class StopTask {
  constructor(readonly aggregateId: AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class StopTaskAndUnAssignPersons {
  constructor(readonly aggregateId: AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class MarkTaskWaiting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class CancelFlowRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class DeletePermanentlyProcessFlow {
  constructor(readonly aggregateId: AggregateId) {}
}

export class ToggleActivity {
  constructor(
    readonly aggregateId: AggregateId,
    readonly flowCursor: FlowCursorId,
    readonly flowCursorVersion: FlowCursorVersion,
    readonly activityId: number,
    readonly checked: boolean) {}
}


export class FieldWithData {

  constructor(readonly formSectionId: FormSectionId,
              readonly fieldId: FormElementRefId,
              readonly contextObjectId: Option<ObjectId>,
              readonly variable: Typed<BusinessVariable>) {}

  static copy(other: FieldWithData) {
    return new FieldWithData(other.formSectionId, other.fieldId, Option.copy(other.contextObjectId), BusinessVariableFactory.copyTyped(other.variable));
  }
}

export class FieldId {

  constructor(readonly formSectionId: FormSectionId,
              readonly fieldId: FormElementRefId,
              readonly contextObjectId: Option<ObjectId>) {}
}


export class FillFormFieldsResponse {

  constructor(readonly variablesToEvaluate: ContextPath[]) {}

  static copy(other: FillFormFieldsResponse) {
    return new FillFormFieldsResponse(other.variablesToEvaluate.map(ContextPath.copy));
  }

  className(): string { return "FillFormFieldsResponse"; }
}

export class MaterializeProcessFlow {
  constructor(readonly flowId: Typed<AnyFlowId>) {}
}

export class FillFormFieldsRouting {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly fieldsData: Array<FieldWithData>,
              readonly fieldsToClear: Array<FieldId>) {}
}

export class FillFlowVariables {

  constructor(readonly aggregateId: AggregateId,
              readonly variablesData: Array<ContextVariable<BusinessVariable>>,
              readonly variablesToClear: Array<ContextPath>) {
  }
}

export class ExecuteFlowFormulaByManager {
  constructor(readonly aggregateId: AggregateId, readonly expression: string, readonly contextPath: string) {}
}

export interface FlowFormulaExecutionResponse {
  className(): string;
  isSuccess(): boolean;
}

export class FlowFormulaExecutionSuccessResponse implements FlowFormulaExecutionResponse {

  constructor(readonly variable: Typed<BusinessVariable>,
              readonly durationMillis: number) {}

  static copy(other: FlowFormulaExecutionSuccessResponse) {
    return new FlowFormulaExecutionSuccessResponse(BusinessVariableFactory.copyTyped(other.variable), other.durationMillis);
  }

  static className: string = "FlowFormulaExecutionSuccessResponse";

  className(): string {
    return FlowFormulaExecutionSuccessResponse.className;
  }

  isSuccess(): boolean {
    return true
  };

}

export class FlowFormulaExecutionFailureResponse implements FlowFormulaExecutionResponse {

  constructor(readonly error: string,
              readonly durationMillis: number) {}

  static copy(other: FlowFormulaExecutionFailureResponse) {
    return new FlowFormulaExecutionFailureResponse(other.error, other.durationMillis);
  }

  static className: string = "FlowFormulaExecutionFailureResponse";

  className(): string {
    return FlowFormulaExecutionFailureResponse.className;
  }

  isSuccess(): boolean {
    return false
  };

}

export class FlowFormulaExecutionResponseFactory {

  static copy(response: FlowFormulaExecutionResponse): FlowFormulaExecutionResponse {
    return FlowFormulaExecutionResponseFactory.copyByType(response, response.className());
  }

  static copyTyped(response: Typed<FlowFormulaExecutionResponse>): Typed<FlowFormulaExecutionResponse> {
    return Typed.of(FlowFormulaExecutionResponseFactory.copyByType(Typed.value(response), Typed.className(response)));
  }

  static copyByType(response: FlowFormulaExecutionResponse, className: string): FlowFormulaExecutionResponse {
    switch (className) {
      case FlowFormulaExecutionSuccessResponse.className:
        return new FlowFormulaExecutionSuccessResponse(BusinessVariableFactory.copyTyped((<FlowFormulaExecutionSuccessResponse>response).variable), (<FlowFormulaExecutionSuccessResponse>response).durationMillis);
      case FlowFormulaExecutionFailureResponse.className:
        return new FlowFormulaExecutionFailureResponse((<FlowFormulaExecutionFailureResponse>response).error, (<FlowFormulaExecutionFailureResponse>response).durationMillis);
      default:
        throw new Error("Unsupported response class: " + className + ", response: " + JSON.stringify(response));
    }
  }
}

export class SubmitFormRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly edgeId: ProcessEdgeId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion) {}
}

export class UpdateRepeatSectionLengthRouting {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly sectionId: FormSectionId,
              readonly rowsToRemove: Array<ObjectId>,
              readonly rowsToAdd: Array<ObjectId>) {}
}

export class UploadFormFileFailure implements UploadFormFileResponse {
  constructor(readonly exceptions: Array<string>) {}
  static className = "UploadFormFileFailure";
  className() {
    return UploadFormFileFailure.className;
  }

  static copy(other: UploadFormFileFailure) {
    return new UploadFormFileFailure(other.exceptions.slice());
  }
}


export class UploadFileCommentResponse {
  constructor(readonly id: number,
              readonly name: string,
              readonly size: number) {}

  static copy(other: UploadFileCommentResponse) {
    return new UploadFileCommentResponse(other.id,
                                          other.name, other.size);
  }
}

export class AddAttachments {
  constructor(readonly aggregateId: AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly formSectionRefId: FormSectionRefId,
              readonly contextObjectId: Option<ObjectId>,
              readonly fieldRefId: FormElementRefId,
              readonly filesUris: Array<FileUri>) {}
}

export class DeleteAttachmentRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly formSectionRefId: FormSectionRefId,
              readonly contextObjectId: Option<ObjectId>,
              readonly fieldRefId: FormElementRefId,
              readonly fileUri: FileUri) {}
}

export class AddCommentRouting {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: Option<number>,
              readonly commentId: number,
              readonly commentText: string,
              readonly attachments: FileUri[],
              readonly extraRecipients: Array<PersonId>) {}

}

export class AddComment {

  constructor(readonly aggregateId: AggregateId,
              readonly nodeId: Option<number>,
              readonly commentId: number,
              readonly commentText: string,
              readonly attachments: FileUri[],
              readonly extraRecipients: Array<PersonId>) {}

}

export class FlowComment {
  constructor(public commentText: string,
              public attachments: FileUri[],
              public extraRecipients: BasicPersonInfo[]){}

  isValid(): boolean {
    return this.commentText.length > 0;
  }

  setEmpty(): void {
    this.commentText = "";
    this.attachments = [];
    this.extraRecipients = [];
  }

  static empty(): FlowComment {
    return new FlowComment("", [], []);
  }
}

export class ChangeComment {
  constructor(readonly aggregateId: AggregateId,
              readonly commentId: number,
              readonly commentText: string) {}
}

export class DeleteComment {
  constructor(readonly aggregateId: AggregateId,
              readonly commentId: number) {}
}

export class MoveFlowCursor {
  constructor(readonly aggregateId: AggregateId,
              readonly cursorId: FlowCursorId,
              readonly cursorVersion: FlowCursorVersion,
              readonly toNodeId: ProcessNodeId,
              readonly phaseWithStep: Option<PhaseWithStep>) {}
}

export class DeleteFlowCursor {
  constructor(readonly aggregateId: AggregateId,
              readonly cursorId: FlowCursorId,
              readonly cursorVersion: FlowCursorVersion) {}
}

export class CreateFlowCursor {
  constructor(readonly aggregateId: AggregateId,
              readonly nodeId: ProcessNodeId,
              readonly phaseWithStep: Option<PhaseWithStep>) {}
}

export class TerminateProcessFlow {
  constructor(readonly aggregateId: AggregateId,
              readonly comment: string) {}
}

export class AddFlowAuthorization {

  constructor(readonly aggregateId:AggregateId,
              readonly authorization: FlowAuthorization,
              readonly nodeId: OrganizationNodeId) {}
}

export class RemoveFlowAuthorization {

  constructor(readonly aggregateId:AggregateId,
              readonly authorization: FlowAuthorization,
              readonly nodeId: OrganizationNodeId) {
  }
}

export class ChangeFlowRelease {
  constructor(readonly aggregateId:AggregateId,
              readonly toReleaseId: AggregateId) {}
}

export class RedirectActionRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly edgeId: ProcessEdgeId,
              readonly addCommentOption: Option<AddCommentRouting>) {}
}

export class RedirectActionFromFlow {
  constructor(readonly aggregateId:AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly edgeId: ProcessEdgeId,
              readonly addCommentOption: Option<AddComment>) {}
}


export class PullActionRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly edgeId: ProcessEdgeId,
              readonly addCommentOption: Option<AddCommentRouting>) {}
}

export class ExecuteActionButtonRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly sectionId: number,
              readonly elementId: FormElementId,
              readonly contextObjectId: Option<ObjectId>) {}
}

export class FindAssignableTasks {
  constructor(readonly personsIds: Array<AggregateId>) {}
}

export class FindArchivedTasksForPerson {

  constructor(
    readonly applicationId: Array<ApplicationId>,
    readonly initiator: Array<Typed<AnyPersonId>>,
    readonly assignee: Array<Typed<AnyPersonId>>,
    readonly labels: Array<string>,
    readonly systemLabels: Array<Typed<BusinessVariable>>,
    readonly importance: Array<FlowImportance>,
    readonly createdFrom: Option<LocalDateTime>,
    readonly createdTo: Option<LocalDateTime>,
    readonly completedFrom: Option<LocalDateTime>,
    readonly completedTo: Option<LocalDateTime>,
    readonly limit: number,
    readonly offset: number) {}
}

export class ArchivedTasksSearchResult {
  constructor(readonly tasks: Array<TaskModelSummary>,
              readonly moreResultsAvailable: boolean,
              readonly nextOffset: number) {}

  static copy(other: ArchivedTasksSearchResult) {
    return new ArchivedTasksSearchResult(
      other.tasks.map(TaskModelSummary.copy),
      other.moreResultsAvailable,
      other.nextOffset);
  }
}

export class FindFlowsByIds {
  constructor(readonly ids: Array<AggregateId>) {}
}


export class TasksToBucketSent {
  constructor(readonly errors: Array<string>) {}
}

export class AddFlowLabel {
  constructor(readonly aggregateId: AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly label: string) {}
}

export class RemoveFlowLabel {
  constructor(readonly aggregateId: AggregateId,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly label: string) {}
}

export class MarkTaskAsSeenRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId) {}
}


export class MarkFlowAsWorkingRouting {
  constructor(readonly flowId: Typed<AnyFlowId>) {}
}

export class UnmarkTaskAsSeenRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId) {}
}


export class UpdateTaskProgressRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId,
              readonly progress: Option<number>) {}
}

export class OverrideImportanceRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly importance: Option<FlowImportance>) {}
}

export class OverrideUrgencyRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly urgency: Option<FlowUrgency>) {}
}

export class OverrideColorRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly color: Option<Option<string>>) {}
}

export class OverrideTaskDeadlineRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId,
              readonly deadline: Option<Option<LocalDateTime>>) {}
}


export class ChangeTaskDeadlineRouting {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId,
              readonly deadline: Option<LocalDateTime>) {}
}



export class GetAdHocFlowData {
  constructor(
    readonly flowId: FlowId
  ) {}
}

export class AdHocTaskData {
constructor(readonly description: string,
            readonly assignee: Option<OrganizationNodeId>,
            readonly deadline: Option<LocalDateTime>,
            readonly importance: number,
            readonly flowId: Typed<AnyFlowId>,
            readonly cursor: CursorInfo) {}

  static copy(other: AdHocTaskData) {
    return new AdHocTaskData(
      other.description,
      Option.copy(other.assignee, OrganizationNodeId.copy),
      Option.copy(other.deadline, LocalDateTime.copy),
      other.importance,
      AnyFlowIdFactory.copyTyped(other.flowId),
      CursorInfo.copy(other.cursor));
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }
}

export class GetTask {
  constructor(
    readonly flowId: Typed<AnyFlowId>,
    readonly nodeId: ProcessNodeId,
  ) {}
}

export class GetFlowsInfoForUser {
  constructor(readonly flowsIds: Array<Typed<AnyFlowId>>) {}
}
