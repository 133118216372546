import {Injectable} from "@angular/core";
import {AuthenticatedHttp, BusinessVariable, BusinessVariableFactory, ObjectVariable} from "@shared-model";
import {
  AggregateVersion,
  AggregateWrapper,
  BusinessEntityId, BusinessEntityIdWithType,
  BusinessEntityTypeId, BusinessEntityTypeReleaseId,
  LocalDateTime, Option,
  OrganizationId,
  OrganizationNodeId,
  Typed
} from "@utils";
import {
  BusinessEntitySearchQuery,
  BusinessEntitySearchSummariesQuery, BusinessEntitySearchSummariesResponse,
} from "./business-entity.model";
import {BusinessEntitySummary} from "@shared-model";

export class BusinessEntityHistoryEventFactory {
  static copy(variable: BusinessEntityHistoryEvent): BusinessEntityHistoryEvent {
    return BusinessEntityHistoryEventFactory.copyByType(variable, variable.className());
  }

  static copyTyped(variable: Typed<BusinessEntityHistoryEvent>): Typed<BusinessEntityHistoryEvent> {
    return Typed.of(BusinessEntityHistoryEventFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(variable: BusinessEntityHistoryEvent, className: string): BusinessEntityHistoryEvent {
    switch (className) {
      case BusinessEntityHistoryCreated.className:
        return BusinessEntityHistoryCreated.copy(<BusinessEntityHistoryCreated>variable);
      case BusinessEntityHistoryValueChanged.className:
        return BusinessEntityHistoryValueChanged.copy(<BusinessEntityHistoryValueChanged>variable);
      case BusinessEntityHistoryValueDeleted.className:
        return BusinessEntityHistoryValueDeleted.copy(<BusinessEntityHistoryValueDeleted>variable);
      case BusinessEntityHistoryDeleted.className:
        return BusinessEntityHistoryDeleted.copy(<BusinessEntityHistoryDeleted>variable);
      default:
        throw new Error("Unsupported history event class: " + className + ", variable: " + JSON.stringify(variable));
    }
  }
}

export interface BusinessEntityHistoryEvent {
  readonly by: Array<OrganizationNodeId>;
  readonly timestamp: LocalDateTime;

  className(): string;
}

export class BusinessEntityHistoryCreated implements BusinessEntityHistoryEvent {
  static className = "BusinessEntityHistoryCreated";

  constructor(readonly by: Array<OrganizationNodeId>,
              readonly timestamp: LocalDateTime) {
  }

  className(): string {
    return BusinessEntityHistoryCreated.className;
  }

  static copy(other: BusinessEntityHistoryCreated) {
    return new BusinessEntityHistoryCreated(
      other.by.map(OrganizationNodeId.copy),
      LocalDateTime.copy(other.timestamp));
  }
}

export class BusinessEntityHistoryValueChanged implements BusinessEntityHistoryEvent {
  static className = "BusinessEntityHistoryValueChanged";

  constructor(readonly by: Array<OrganizationNodeId>,
              readonly timestamp: LocalDateTime,
              readonly path: string,
              readonly value: Typed<BusinessVariable>) {
  }

  className(): string {
    return BusinessEntityHistoryValueChanged.className;
  }

  static copy(other: BusinessEntityHistoryValueChanged) {
    return new BusinessEntityHistoryValueChanged(
      other.by.map(OrganizationNodeId.copy),
      LocalDateTime.copy(other.timestamp),
      other.path,
      BusinessVariableFactory.copyTyped(other.value));
  }

  valueUnwrapped() {
    return Typed.value(this.value);
  }
}

export class BusinessEntityHistoryValueDeleted implements BusinessEntityHistoryEvent {
  static className = "BusinessEntityHistoryValueDeleted";

  constructor(readonly by: Array<OrganizationNodeId>,
              readonly timestamp: LocalDateTime,
              readonly path: string) {
  }

  className(): string {
    return BusinessEntityHistoryValueDeleted.className;
  }

  static copy(other: BusinessEntityHistoryValueDeleted) {
    return new BusinessEntityHistoryValueDeleted(
      other.by.map(OrganizationNodeId.copy),
      LocalDateTime.copy(other.timestamp),
      other.path);
  }
}

export class BusinessEntityHistoryDeleted implements BusinessEntityHistoryEvent {
  static className = "BusinessEntityHistoryDeleted";

  constructor(readonly by: Array<OrganizationNodeId>,
              readonly timestamp: LocalDateTime) {
  }

  className(): string {
    return BusinessEntityHistoryDeleted.className;
  }

  static copy(other: BusinessEntityHistoryDeleted) {
    return new BusinessEntityHistoryDeleted(
      other.by.map(OrganizationNodeId.copy),
      LocalDateTime.copy(other.timestamp));
  }
}

export class BusinessEntityHistory {
  constructor(readonly id: BusinessEntityId,
              readonly events: Array<Typed<BusinessEntityHistoryEvent>>) {
  }

  static copy(other: BusinessEntityHistory) {
    return new BusinessEntityHistory(
      BusinessEntityId.copy(other.id),
      other.events.map(BusinessEntityHistoryEventFactory.copyTyped)
    );
  }

  eventsUnwrapped(): Array<BusinessEntityHistoryEvent> {
    return this.events.map(e => Typed.value(e));
  }
}

export class BusinessEntity {
  constructor(readonly organizationId: OrganizationId,
              readonly entityType: BusinessEntityTypeId,
              readonly typeRelease: BusinessEntityTypeReleaseId,
              readonly code: string,
              readonly data: ObjectVariable,
              readonly created: LocalDateTime,
              readonly modified: LocalDateTime,
              readonly deleted: boolean) {
  }

  static copy(other: BusinessEntity) {
    return new BusinessEntity(
      OrganizationId.copy(other.organizationId),
      BusinessEntityTypeId.copy(other.entityType),
      BusinessEntityTypeReleaseId.copy(other.typeRelease),
      other.code,
      ObjectVariable.copy(other.data),
      LocalDateTime.copy(other.created),
      LocalDateTime.copy(other.modified),
      other.deleted
    );
  }

}

class FoundBusinessEntity {
  constructor(public id: BusinessEntityId, public timeCreated: LocalDateTime) {
  }

  static copy(other: FoundBusinessEntity) {
    return new FoundBusinessEntity(
      BusinessEntityId.copy(other.id),
      LocalDateTime.copy(other.timeCreated)
    );
  }
}

class GetBusinessEntitySummaryByIdWithFields {
  constructor(readonly businessEntityId: BusinessEntityIdWithType,
              readonly minVersion: Option<AggregateVersion>,
              readonly nameFields: Array<string>,
              readonly descriptionFields: Array<string>) {}
}


@Injectable({
  providedIn: "root",
})
export class BusinessEntityQueryService {
  constructor(
    readonly authenticatedHttp: AuthenticatedHttp
  ) {
  }

  findEntitiesSummaries(query: BusinessEntitySearchQuery, businessEntityTypeId: BusinessEntityTypeId, nameFields: Array<string>, descriptionFields: Array<string>,
                        onSuccess: (entities: Array<BusinessEntitySummary>, moreAvailable: boolean) => void,
                        onError: (error: string) => void) {

    this.authenticatedHttp.post("business-entity/find-summaries", new BusinessEntitySearchSummariesQuery(query, businessEntityTypeId, nameFields, descriptionFields), (data: BusinessEntitySearchSummariesResponse) => {
      const response = BusinessEntitySearchSummariesResponse.copy(data);
      if(response.error.isDefined()) {
        onError(response.error.getOrError("No error"));
      } else {
        onSuccess(response.entities, response.moreAvailable);
      }
    });
  }

  loadEntity(businessEntityId: BusinessEntityIdWithType, minVersion: Option<AggregateVersion>, onSuccess: (entity: Option<AggregateWrapper<BusinessEntity>>) => void) {
    this.authenticatedHttp.get("business-entity/get-by-id/" + businessEntityId.typeId+"/"+businessEntityId.id + "/"+minVersion.map(v => ""+v.asInt).getOrElse(""), (data: Option<AggregateWrapper<BusinessEntity>>) => {
      onSuccess(Option.copy(data, e => AggregateWrapper.copy(e, BusinessEntity.copy)));
    });
  }

  loadEntityAtTimestamp(businessEntityId: BusinessEntityIdWithType, instant: number, onSuccess: (entity: Option<AggregateWrapper<BusinessEntity>>) => void) {
    this.authenticatedHttp.get("business-entity/get-by-id-at-timestamp/" + businessEntityId.typeId+"/"+businessEntityId.id + "/"+instant, (data: Option<AggregateWrapper<BusinessEntity>>) => {
      onSuccess(Option.copy(data, e => AggregateWrapper.copy(e, BusinessEntity.copy)));
    });
  }

  loadEntitySummaryWithFields(businessEntityId: BusinessEntityIdWithType, minVersion: Option<AggregateVersion>,
                              nameFields: Array<string>,
                              descriptionFields: Array<string>, onSuccess: (entity: Option<BusinessEntitySummary>) => void) {
    this.authenticatedHttp.post("business-entity/get-summary-by-id-with-fields",
      new GetBusinessEntitySummaryByIdWithFields(businessEntityId, minVersion, nameFields, descriptionFields),
      (data: Option<BusinessEntitySummary>) => {
      onSuccess(Option.copy(data, BusinessEntitySummary.copy));
    });
  }

  loadEntityHistory(businessEntityId: BusinessEntityIdWithType, onSuccess: (entity: Option<BusinessEntityHistory>) => void) {
    this.authenticatedHttp.get("business-entity/get-history-by-id/" + businessEntityId.typeId+"/"+businessEntityId.id, (data: Option<BusinessEntityHistory>) => {
      onSuccess(Option.copy(data, BusinessEntityHistory.copy));
    });
  }
}
