import {Language, Option, PersonId, required, toastr} from "@utils";
import {HoursFormat} from "@shared-model";
import {PersonSettingsService} from "@shared";

export class UserSettingsServerModel {

  private personId?: PersonId;

  constructor(readonly personSettingsService: PersonSettingsService) {}

  updateLocaleSettings(timezone: Option<string>,
                       language: Option<Language>,
                       region: Option<string>,
                       hoursFormat: Option<HoursFormat>,
                       firstDayOfWeek: Option<number>,
                       weekend: Option<Array<number>>) {

    this.personSettingsService.changePersonLocaleSettings(required(this.personId, "personId"),
      timezone, language, region, hoursFormat, firstDayOfWeek, weekend, (id) => {
      // ok
    }, (exceptions) => {
      toastr.error("Failed to update locale notifications");
    });
  }


  sessionStarted(personId: PersonId) {
    this.personId = personId;
  }

  sessionCleared() {
    this.personId = undefined;
  }
}
