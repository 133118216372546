<input #Input type="text" [(ngModel)]="viewModel.text" class="suggestionInput form-control" (focus)="viewModel.inputFocused()"
       (ngModelChange)="viewModel.inputChanged()"
       (keydown.arrowDown)="viewModel.downPressed($event)" (keydown.arrowUp)="viewModel.upPressed($event)" (keydown.enter)="viewModel.enterPressed($event)"
       [placeholder]="placeholder" [focus-on-show]="focusOnShow"
       [readOnly]="disabled"/>
<div class="clearValue" (click)="viewModel.clearValue()" *ngIf="viewModel.clearButtonVisible"><i class="mi-delete"></i></div>

<my-drop-menu cssClass="suggestingInputPopup theme-drop-menu"
              [visible]="viewModel.suggestionPopupVisible && (viewModel.suggestions.length > 0)"
              (visibleChange)="viewModel.suggestionPopupVisible = $event"
              [anchor]="suggestionMenuAnchor ? suggestionMenuAnchor : Input" >
  <ng-container *ngIf="viewModel.suggestionPopupVisible">
    <div class="suggestions" [class.invisible]="viewModel.suggestions.length === 0">
      <button class="suggestion theme-drop-menu-entry" *ngFor="let suggestion of viewModel.suggestions; let index = index" (click)="viewModel.suggestionAccepted(suggestion)"
           [class.active]="index === viewModel.activeSuggestionIndex">
        {{suggestion}}
      </button>
    </div>
  </ng-container>
</my-drop-menu>
