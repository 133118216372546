import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {I18nText, None, Option, Some} from "@utils";
import {BusinessVariable, I18nTextVariable, VariablePath} from "@shared-model";

export class I18nTextVariableInputViewModel {

  previewText: string|null = null;
  internalModel!: I18nText;
  internalModelBackup: I18nText|null = null;

  constructor(readonly path: VariablePath,
              public value: I18nTextVariable|null,
              public readOnly: boolean,
              public preview: boolean,
              readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>) {
    this.updatePreviewText();
    this.initInternalModel();
  }

  private updatePreviewText() {
    this.previewText = this.value === null ? "-" : this.value.value.getCurrentWithFallback();
  }

  initInternalModel() {
    if (this.value == null) {
      this.internalModel = I18nText.empty();
    } else {
      this.internalModel = this.value.value;
    }
    this.internalModelBackup = this.internalModel;
  }

  private changeVariableValue(value: I18nTextVariable|null) {
    this.value = value;
    this.updatePreviewText();
    this.initInternalModel();
    this.change.emit({value: value, path: this.path});
  }

  onInternalChange() {
    if(this.internalModelChanged()) {
      if (this.internalModel === null) {
        this.changeVariableValue(null);
      } else {
        this.changeVariableValue(new I18nTextVariable(this.internalModel))
      }
      this.internalModelBackup = this.internalModel;
    }
  }

  private internalModelChanged() {
    return this.internalModel === null && this.internalModelBackup !== null ||
      this.internalModel !== null && this.internalModelBackup === null ||
      this.internalModel !== null && this.internalModelBackup !== null && !this.internalModel.isEqual(this.internalModelBackup);
  }

  updateValue(value: BusinessVariable|null) {
    if(value === null || value instanceof I18nTextVariable) {
      this.value = value;
      this.initInternalModel();
    } else {
      throw new Error("Incorrect variable type, expected 'I18nText' but was '"+value.simpleValueType()+"'");
    }
  }

  updateMode(readOnly: boolean, preview: boolean) {
    this.readOnly = readOnly;
    this.preview = preview;
  }

  backupInternalValue() {
    this.internalModelBackup = this.internalModel;
  }
}



@Component({
  selector: "my-i18n-text-variable-input",
  templateUrl: "./i18n-text-variable-input.component.html"
})
export class I18nTextVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  viewModel!: I18nTextVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new I18nTextVariableInputViewModel(this.path, this._value, this._readOnly, this._preview, this.change);
  }
}
