import {Component, ViewContainerRef} from '@angular/core';
import {ResponsiveWidthMonitor} from "@utils";

@Component({
  templateUrl: './page-not-found-page.component.html'
})
export class PageNotFoundPageComponent {
  viewParamsMenuVisible: boolean = false;

  readonly responsive = ResponsiveWidthMonitor.of("not-found-page", this.viewContainerRef);
  constructor(readonly viewContainerRef: ViewContainerRef) {

  }


}
