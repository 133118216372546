import {
  arraysEqual, arraysEqualBy, Duration,
  FileUri,
  I18nText,
  LocalDate,
  LocalDateTime, LocalTime,
  None,
  Option,
  Some,
  Trilean,
  Typed, validateVariableName,
  validateVariablePath, values
} from "@utils";
import {
  BooleanVariable,
  BusinessVariable, NumberVariable, ObjectVariable,
  OrganizationNodeVariable, StringVariable,
  VariablePath
} from "@shared-model";
import {PropertyTryError} from "./screen.model";

export enum PropertyType {
  None,
  String,
  OptionalString,
  Number,
  OptionalNumber,
  Boolean,
  OptionalBoolean,
  Trilean,
  I18nText,
  OptionalI18nText,
  Date,
  OptionalDate,
  Time,
  OptionalTime,
  DateTime,
  OptionalDateTime,
  Duration,
  OptionalDuration,
  File,
  OptionalFile,
  Model,
  OptionalModel,
  LabeledValues,
  OptionalOrganizationNodes,
  OptionalStringArray,

}

export class ContextTraversal {

  isNone = !this.root && !this.up && !this.customPath;

  constructor(readonly root: boolean,
              readonly up: number,
              readonly customPath: boolean,
              readonly path: string) {}

  static NONE = new ContextTraversal(false, 0, false,"");
  static ROOT = new ContextTraversal(true, 0, false,"");
  static UP1 = new ContextTraversal(false, 1, false,"");
  static UP2 = new ContextTraversal(false, 2, false,"");
  static CUSTOM = new ContextTraversal(true, 0, true,"");
  static up(up: number) {
    return new ContextTraversal(false, up, false,"");
  }

  static path(path: string) {
    return new ContextTraversal(false, 0, true, path);
  }

  toPath() {
    let path = "";
    if(this.root) {
      path += "$root."
    }
    for(let i = 0; i < this.up; i++) {
      path += "$super."
    }

    if(this.customPath && this.path.length > 0) {
      path += this.path;
      path += ".";
    }
    return path;
  }

  static fromSmartPath(variablePath: string): ContextTraversal {

    let contextPath = ""
    let lastDotIndex = variablePath.lastIndexOf('.');
    if (lastDotIndex != -1) {
      contextPath = variablePath.substring(0, lastDotIndex + 1);
    }

    if(contextPath.startsWith("$root.")) {
      contextPath = contextPath.substring(6);

      if(contextPath.length > 0) {
        return new ContextTraversal(true, 0, true, contextPath.substring(0, contextPath.length - 1));
      } else {
        return new ContextTraversal(true, 0, false,"");
      }
    } else if(contextPath.startsWith("$super.")) {
      let up = 0;
      while(contextPath.startsWith("$super.")) {
        up++;
        contextPath = contextPath.substring(7);
      }
      if(contextPath.length > 0) {
        return new ContextTraversal(false, up, false, contextPath.substring(0, contextPath.length - 1));
      } else {
        return new ContextTraversal(false, up, false, "");
      }

    } else {
      if(contextPath.length > 0) {
        return ContextTraversal.path(contextPath.substring(0, contextPath.length - 1));
      } else {
        return ContextTraversal.path(contextPath);
      }
    }
  }

  static variableNameFromSmartPath(saveToName: string) {
    let lastDotIndex = saveToName.lastIndexOf('.');
    if (lastDotIndex != -1) {
      return saveToName.substring(lastDotIndex + 1);
    } else {
      return saveToName;
    }
  }

  changePath(path: string) {
    return new ContextTraversal(this.root, this.up, this.customPath, path);
  }

  changeCustomPath(customPath: boolean) {
    return new ContextTraversal(this.root, this.up, customPath, this.path);
  }
}

export class VariableContext {
  private constructor(readonly id: number) {}

  static inherit: VariableContext = new VariableContext(0);
  static externalContext: VariableContext = new VariableContext(3);
  static screen: VariableContext = new VariableContext(4);
  static actions: VariableContext = new VariableContext(5);


  static copy(screenContext: VariableContext) {
    switch (screenContext.id) {
      case VariableContext.inherit.id: return VariableContext.inherit;
      case VariableContext.externalContext.id: return VariableContext.externalContext;
      case VariableContext.screen.id: return VariableContext.screen;
      case VariableContext.actions.id: return VariableContext.actions;
      case 1: return VariableContext.externalContext; // deprecated
      case 2: return VariableContext.screen; // deprecated
      default: return VariableContext.inherit;
    }
  }

  toString() {
    switch (this.id) {
      case VariableContext.inherit.id: return "Inherit";
      case VariableContext.externalContext.id: return "External Context";
      case VariableContext.screen.id: return "Screen";
      case VariableContext.actions.id: return "Actions";
      default: throw new Error("Unknown variable context id: '"+this.id+"'");
    }
  }

  isInhertited() {
    return this.id === VariableContext.inherit.id;
  }

  nonInherited() {
    return this.id !== VariableContext.inherit.id;
  }

  isExternalContext() {
    return this.id === VariableContext.externalContext.id;
  }

  isScreenContext() {
    return this.id === VariableContext.screen.id;
  }

}

export class ComponentPropertyFactory {
    static copy(other: ComponentProperty<any>): ComponentProperty<any> {
      return ComponentPropertyFactory.copyByType(other, other.className());
    }

    static copyTyped(other: Typed<ComponentProperty<any>>): Typed<ComponentProperty<any>> {
      return Typed.of(ComponentPropertyFactory.copyByType(Typed.value(other), Typed.className(other)));
    }

    static copyByType(other: ComponentProperty<any>, className: string): ComponentProperty<any> {
      switch (className) {
        case TrileanProperty.className: return TrileanProperty.copy(<TrileanProperty>other);
        case BooleanProperty.className: return BooleanProperty.copy(<BooleanProperty>other);
        case OptionalBooleanProperty.className: return OptionalBooleanProperty.copy(<OptionalBooleanProperty>other);
        case I18nTextProperty.className: return I18nTextProperty.copy(<I18nTextProperty>other);
        case OptionalI18nTextProperty.className: return OptionalI18nTextProperty.copy(<OptionalI18nTextProperty>other);
        case StringProperty.className: return StringProperty.copy(<StringProperty>other);
        case OptionalStringProperty.className: return OptionalStringProperty.copy(<OptionalStringProperty>other);
        case FileProperty.className: return FileProperty.copy(<FileProperty>other);
        case OptionalFileProperty.className: return OptionalFileProperty.copy(<OptionalFileProperty>other);
        case NumberProperty.className: return NumberProperty.copy(<NumberProperty>other);
        case OptionalNumberProperty.className: return OptionalNumberProperty.copy(<OptionalNumberProperty>other);
        case LabeledValuesProperty.className: return LabeledValuesProperty.copy(<LabeledValuesProperty>other);
        case OptionalOrganizationNodesProperty.className: return OptionalOrganizationNodesProperty.copy(<OptionalOrganizationNodesProperty>other);
        case OptionalStringArrayProperty.className: return OptionalStringArrayProperty.copy(<OptionalStringArrayProperty>other);
        case DateTimeProperty.className: return DateTimeProperty.copy(<DateTimeProperty>other);
        case TimeProperty.className: return TimeProperty.copy(<TimeProperty>other);
        case DurationProperty.className: return DurationProperty.copy(<DurationProperty>other);
        case OptionalDateTimeProperty.className: return OptionalDateTimeProperty.copy(<OptionalDateTimeProperty>other);
        case OptionalTimeProperty.className: return OptionalTimeProperty.copy(<OptionalTimeProperty>other);
        case OptionalDurationProperty.className: return OptionalDurationProperty.copy(<OptionalDurationProperty>other);
        case DateProperty.className: return DateProperty.copy(<DateProperty>other);
        case OptionalDateProperty.className: return OptionalDateProperty.copy(<OptionalDateProperty>other);
        case ModelProperty.className: return ModelProperty.copy(<ModelProperty>other);
        case OptionalModelProperty.className: return OptionalModelProperty.copy(<OptionalModelProperty>other);
        default: throw new Error("Not supported ["+className+"]");
      }
    }
  }




  export interface PropertySettings {
    className(): string;
    equals(other: PropertySettings): boolean;
    searchText(): string;

    convertTo(settingsType: PropertySettingsType): PropertySettings;
    toSettingsType(): PropertySettingsType;

    comparableString(): string;
  }

  export class PropertySettingsType {

    constructor(readonly name: string) {}

    static StaticValue = new PropertySettingsType("StaticValue");
    static BoundVariablePath = new PropertySettingsType("BoundVariablePath");
    static BoundExpression = new PropertySettingsType("BoundExpression");

    nonStaticValue() {
      return this.name !== PropertySettingsType.StaticValue.name;
    }

    isStaticValue() {
      return this.name === PropertySettingsType.StaticValue.name;
    }

    isBoundExpression() {
      return this.name === PropertySettingsType.BoundExpression.name;
    }

    isBoundVariablePath() {
      return this.name === PropertySettingsType.BoundVariablePath.name;
    }

  }

  export class PropertySettingsFactory {
    static copy(settings: PropertySettings): PropertySettings {
      return PropertySettingsFactory.copyByType(settings, settings.className());
    }

    static copyTyped(settings: Typed<PropertySettings>): Typed<PropertySettings> {
      return Typed.of(PropertySettingsFactory.copyByType(Typed.value(settings), Typed.className(settings)));
    }

    static copyByType(settings: PropertySettings, className: string): PropertySettings {
      switch (className) {
        case StaticValue.className: return StaticValue.copy(<StaticValue>settings);
        case BoundExpression.className: return BoundExpression.copy(<BoundExpression>settings);
        case BoundVariablePath.className: return BoundVariablePath.copy(<BoundVariablePath>settings);
        default: throw new Error("Unsupported variable class: " + className);
      }
    }
  }

  export class StaticValue implements PropertySettings {

    static readonly INSTANCE = new StaticValue();

    static className = "StaticValue"

    className(): string {
      return StaticValue.className;
    }

    static copy(other: StaticValue): StaticValue {
      return StaticValue.INSTANCE;
    }

    equals(other: PropertySettings): boolean {
      if(other instanceof StaticValue) {
        return true;
      } else {
        return false;
      }
    }

    searchText(): string {
      return "";
    }

    convertTo(settingsType: PropertySettingsType): PropertySettings {
      switch (settingsType.name) {
        case PropertySettingsType.StaticValue.name: return this;
        case PropertySettingsType.BoundExpression.name: return new BoundExpression("", false);
        case PropertySettingsType.BoundVariablePath.name: return new BoundVariablePath(VariableContext.inherit, VariablePath.empty());
        default: throw new Error("Settings of type '"+settingsType.name+"' not supported");
      }
    }

    toSettingsType(): PropertySettingsType {
      return PropertySettingsType.StaticValue;
    }

    comparableString(): string {
      return "Value";
    }


  }

  export class BoundExpression implements PropertySettings {
    static className = "BoundExpression"

    className(): string {
      return BoundExpression.className;
    }

    constructor(public expression: string,
                public async: boolean) {}

    static copy(other: BoundExpression): BoundExpression {
      return new BoundExpression(other.expression, other.async);
    }

    equals(other: PropertySettings): boolean {
      if(other instanceof BoundExpression) {
        return this.expression === other.expression && this.async === other.async;
      } else {
        return false;
      }
    }

    searchText(): string {
      return this.expression;
    }

    convertTo(settingsType: PropertySettingsType): PropertySettings {
      switch (settingsType.name) {
        case PropertySettingsType.StaticValue.name: return StaticValue.INSTANCE;
        case PropertySettingsType.BoundExpression.name: return this;
        case PropertySettingsType.BoundVariablePath.name: return new BoundVariablePath(VariableContext.inherit, VariablePath.empty());
        default: throw new Error("Settings of type '"+settingsType.name+"' not supported");
      }
    }

    toSettingsType(): PropertySettingsType {
      return PropertySettingsType.BoundExpression;
    }

    comparableString(): string {
      return this.expression;
    }



  }

  export class BoundVariablePath implements PropertySettings {
    static className = "BoundVariablePath"

    className(): string {
      return BoundVariablePath.className;
    }

    constructor(readonly screenContext: VariableContext,
                public path: VariablePath) {}

    static copy(other: BoundVariablePath): BoundVariablePath {
      return new BoundVariablePath(VariableContext.copy(other.screenContext),
        VariablePath.copy(other.path));
    }

    equals(other: PropertySettings): boolean {
      if(other instanceof BoundVariablePath) {
        return this.path.isEqual(other.path) && other.screenContext.id === this.screenContext.id;
      } else {
        return false;
      }
    }

    searchText(): string {
      return this.path.path;
    }

    convertTo(settingsType: PropertySettingsType): PropertySettings {
      switch (settingsType.name) {
        case PropertySettingsType.StaticValue.name: return StaticValue.INSTANCE;
        case PropertySettingsType.BoundExpression.name: return new BoundExpression(this.path.path, false);
        case PropertySettingsType.BoundVariablePath.name: return this;
        default: throw new Error("Settings of type '"+settingsType.name+"' not supported");
      }
    }

    toSettingsType(): PropertySettingsType {
      return PropertySettingsType.BoundVariablePath;
    }

    comparableString(): string {
      return this.path.path;
    }

  }





  export interface ComponentProperty<VALUE_TYPE> {
    readonly settings: Typed<PropertySettings>
    className(): string;
    settingsUnwrapped(): PropertySettings;
    currentValue(fromState: () => PropertyTry<any>): PropertyTry<VALUE_TYPE>;
    isOptional(): boolean;
    isEnabled(): boolean;
    toRequired(): ComponentProperty<any>;
    toOptional(): ComponentProperty<any>;

    equals(property: ComponentProperty<any>): boolean;
    staticValueText(): string|I18nText;
  }

  export interface OptionalComponentProperty<VALUE_TYPE> extends ComponentProperty<VALUE_TYPE> {
    enabled: boolean;
  }


  export class TrileanProperty implements ComponentProperty<Trilean> {
    static className = "TrileanProperty";
    className() {
      return TrileanProperty.className;
    }

    constructor(public staticValue: Trilean,
                readonly settings: Typed<PropertySettings>) {}

    isOptional(): boolean {
      return false;
    }

    static ofTrue() {
      return new TrileanProperty(Trilean.TRUE, Typed.of(StaticValue.INSTANCE));
    }

    static ofFalse() {
      return new TrileanProperty(Trilean.FALSE, Typed.of(StaticValue.INSTANCE));
    }

    get isTrue() {
      return this.settingsUnwrapped() instanceof StaticValue && this.staticValue;
    }

    get isFalse() {
      return this.settingsUnwrapped() instanceof StaticValue && !this.staticValue;
    }

    currentValue(fromState: () => PropertyTry<Trilean>): PropertyTry<Trilean> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static copy(other: TrileanProperty) {
      return new TrileanProperty(Trilean.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    equals(other: ComponentProperty<Trilean>): boolean {
      if (other instanceof TrileanProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.equals(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    static ofSettings(settings: PropertySettings, value: Trilean) {
      return new TrileanProperty(value, Typed.of(settings));
    }

    static static(staticValue: Trilean) {
      return new TrileanProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired(): TrileanProperty {
      return this;
    }

    toOptional(): ComponentProperty<Trilean> {
      throw new Error("No Optional Trillean Property");
    }

    staticValueText(): string {
      return this.staticValue.name;
    }

  }


  export class BooleanProperty implements ComponentProperty<boolean> {
    static className = "BooleanProperty";
    className() {
      return BooleanProperty.className;
    }

    constructor(public staticValue: boolean,
                readonly settings: Typed<PropertySettings>) {}

    isOptional(): boolean {
      return false;
    }

    static ofTrue() {
      return new BooleanProperty(true, Typed.of(StaticValue.INSTANCE));
    }

    static ofFalse() {
      return new BooleanProperty(false, Typed.of(StaticValue.INSTANCE));
    }

    get isTrue() {
      return this.settingsUnwrapped() instanceof StaticValue && this.staticValue;
    }

    get isFalse() {
      return this.settingsUnwrapped() instanceof StaticValue && !this.staticValue;
    }

    currentValue(fromState: () => PropertyTry<boolean>): PropertyTry<boolean> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static copy(other: BooleanProperty) {
      return new BooleanProperty(other.staticValue, PropertySettingsFactory.copyTyped(other.settings));
    }

    equals(other: ComponentProperty<boolean>): boolean {
      if (other instanceof BooleanProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    static ofSettings(settings: PropertySettings, value: boolean) {
      return new BooleanProperty(value, Typed.of(settings));
    }

    static static(staticValue: boolean) {
      return new BooleanProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static toRequired(property: BooleanProperty|OptionalBooleanProperty) {
      if(property instanceof BooleanProperty) {
        return property;
      } else if(property.enabled) {
        return new BooleanProperty(property.staticValue, property.settings);
      } else {
        throw new Error("Non enabled optional property cannot be converted to non optional");
      }
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalBooleanProperty(this.staticValue, true, this.settings);
    }

    staticValueText(): string {
      return this.staticValue ? "true" : "false";
    }

  }


  export class OptionalBooleanProperty implements ComponentProperty<Option<boolean>> {
    static className = "OptionalBooleanProperty";
    className() {
      return OptionalBooleanProperty.className;
    }

    constructor(public staticValue: boolean,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isOptional(): boolean {
      return true;
    }

    static ofTrue() {
      return new OptionalBooleanProperty(true, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofFalse() {
      return new OptionalBooleanProperty(false, true, Typed.of(StaticValue.INSTANCE));
    }

    static disabled(defaultValue: boolean) {
      return new OptionalBooleanProperty(defaultValue, false, Typed.of(StaticValue.INSTANCE));
    }

    get isTrue() {
      return this.enabled && this.settingsUnwrapped() instanceof StaticValue && this.staticValue;
    }

    get isFalse() {
      return this.enabled && this.settingsUnwrapped() instanceof StaticValue && !this.staticValue;
    }

    currentValue(fromState: () => PropertyTry<Option<boolean>>): PropertyTry<Option<boolean>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static copy(other: OptionalBooleanProperty) {
      return new OptionalBooleanProperty(other.staticValue, other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    equals(other: ComponentProperty<Option<boolean>>): boolean {
      return other instanceof OptionalBooleanProperty && this.isEqual(other);
    }

    isEqual(other: OptionalBooleanProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    static ofSettings(settings: PropertySettings, value: boolean, enabled: boolean) {
      return new OptionalBooleanProperty(value, enabled, Typed.of(settings));
    }



    static static(staticValue: boolean) {
      return new OptionalBooleanProperty(staticValue, true, Typed.of(StaticValue.INSTANCE));
    }

    static toOptional(property: BooleanProperty|OptionalBooleanProperty) {
      if(property instanceof BooleanProperty) {
        return new OptionalBooleanProperty(property.staticValue, true, property.settings);
      } else {
        return property;
      }
    }

    isEnabled(): boolean {
      return this.enabled;
    }


    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new BooleanProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    staticValueText(): string {
      return this.enabled ? (this.staticValue ? "true" : "false") : "";
    }
  }




  export class I18nTextProperty implements ComponentProperty<I18nText> {
    static className = "I18nTextProperty";
    className() {
      return I18nTextProperty.className;
    }

    constructor(readonly staticValue: I18nText,
                readonly settings: Typed<PropertySettings>) {}


    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalI18nTextProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }


    equals(other: ComponentProperty<I18nText>): boolean {
      if (other instanceof I18nTextProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<I18nText>): PropertyTry<I18nText> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static empty() {
      return new I18nTextProperty(I18nText.empty(), Typed.of(StaticValue.INSTANCE));
    }

    static plText(text: string) {
      return new I18nTextProperty(I18nText.pl(text), Typed.of(StaticValue.INSTANCE));
    }

    static of(text: I18nText) {
      return new I18nTextProperty(text, Typed.of(StaticValue.INSTANCE));
    }

    static copy(other: I18nTextProperty) {
      return new I18nTextProperty(I18nText.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): I18nText {
      return this.staticValue;
    }

  }

  export class OptionalI18nTextProperty implements OptionalComponentProperty<Option<I18nText>> {
    static className = "OptionalI18nTextProperty";
    className() {
      return OptionalI18nTextProperty.className;
    }

    constructor(readonly staticValue: I18nText,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}


    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    equals(other: ComponentProperty<Option<I18nText>>): boolean {
      return other instanceof OptionalI18nTextProperty && this.isEqual(other);
    }

    isEqual(other: OptionalI18nTextProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new I18nTextProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    staticValueOption() {
      if(this.enabled) {
        return Some(this.staticValue);
      } else {
        return None();
      }
    }

    currentValue(fromState: () => PropertyTry<Option<I18nText>>): PropertyTry<Option<I18nText>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static disabled() {
      return new OptionalI18nTextProperty(I18nText.empty(), false, Typed.of(StaticValue.INSTANCE));
    }

    static of(staticValue: I18nText) {
      return new OptionalI18nTextProperty(staticValue, true, Typed.of(StaticValue.INSTANCE));
    }

    static copy(other: OptionalI18nTextProperty) {
      return new OptionalI18nTextProperty(I18nText.copy(other.staticValue), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): I18nText|string {
      return this.enabled ? this.staticValue : "";
    }


  }







  export class FileProperty implements ComponentProperty<FileUri> {
    static className = "FileProperty";
    className() {
      return FileProperty.className;
    }

    constructor(readonly staticValue: string,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    equals(other: ComponentProperty<FileUri>): boolean {
      if (other instanceof FileProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalFileProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }


    currentValue(fromState: () => PropertyTry<FileUri>): PropertyTry<FileUri> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(FileUri.parse(this.staticValue));
      }
    }

    static copy(other: FileProperty) {
      return new FileProperty(other.staticValue, PropertySettingsFactory.copyTyped(other.settings));
    }

    static of(staticValue: string) {
      return new FileProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: string) {
      return new FileProperty(staticValue, Typed.of(settings));
    }

    staticValueText(): string {
      return this.staticValue;
    }
  }

  export class OptionalFileProperty implements OptionalComponentProperty<Option<FileUri>> {
    static className = "OptionalFileProperty";
    className() {
      return OptionalFileProperty.className;
    }

    constructor(readonly staticValue: string,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new FileProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    currentValue(fromState: () => PropertyTry<Option<FileUri>>): PropertyTry<Option<FileUri>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(FileUri.parse(this.staticValue)));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }


    static disabled(defaultValue: string) {
      return new OptionalFileProperty(defaultValue, false, Typed.of(StaticValue.INSTANCE));
    }


    static copy(other: OptionalFileProperty) {
      return new OptionalFileProperty(other.staticValue, other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static of(staticValue: string) {
      return new OptionalFileProperty(staticValue, true, Typed.of(StaticValue.INSTANCE));
    }

    equals(other: ComponentProperty<Option<FileUri>>): boolean {
      return other instanceof OptionalFileProperty && this.isEqual(other);
    }

    isEqual(other: OptionalFileProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }


    static static(staticValue: string, enabled: boolean) {
      return new OptionalFileProperty(staticValue, enabled, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: string, enabled: boolean) {
      return new OptionalFileProperty(staticValue, enabled, Typed.of(settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue : "";
    }
  }


  export class StringProperty implements ComponentProperty<string> {
    static className = "StringProperty";
    className() {
      return StringProperty.className;
    }

    constructor(readonly staticValue: string,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalStringProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }

    static of(value: string) {
      return new StringProperty(value, Typed.of(StaticValue.INSTANCE));
    }

    equals(other: ComponentProperty<string>): boolean {
      return other instanceof StringProperty && this.isEqual(other);
    }

    currentValue(fromState: () => PropertyTry<string>): PropertyTry<string> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static copy(other: StringProperty) {
      return new StringProperty(other.staticValue, PropertySettingsFactory.copyTyped(other.settings));
    }

    isEqual(other: StringProperty) {
      const settings = this.settingsUnwrapped();
      if(settings instanceof StaticValue) {
        return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
      } else {
        return settings.equals(other.settingsUnwrapped());
      }
    }

    static static(staticValue: string) {
      return new StringProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: string) {
      return new StringProperty(staticValue, Typed.of(settings));
    }

    staticValueText(): string {
      return this.staticValue;
    }

  }

  export class OptionalOrganizationNodesProperty implements OptionalComponentProperty<Option<Array<OrganizationNodeVariable>>> {
    static className = "OptionalOrganizationNodesProperty";
    className() {
      return OptionalOrganizationNodesProperty.className;
    }

    constructor(readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional(): OptionalOrganizationNodesProperty {
      return this;
    }

    toRequired(): ComponentProperty<any> {
      if(this.enabled) {
        throw new Error("No required Organization Nodes Property");
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }


    equals(other: ComponentProperty<Option<Array<OrganizationNodeVariable>>>): boolean {
      return other instanceof OptionalOrganizationNodesProperty && this.isEqual(other);
    }

    isEqual(other: OptionalOrganizationNodesProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped());
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<Array<OrganizationNodeVariable>>>): PropertyTry<Option<Array<OrganizationNodeVariable>>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          throw new Error("Static value not supported");
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static copy(other: OptionalOrganizationNodesProperty) {
      return new OptionalOrganizationNodesProperty(other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static disabled() {
      return new OptionalOrganizationNodesProperty(false, Typed.of(new BoundExpression("", false)));
    }

    static ofSettings(settings: PropertySettings, enabled: boolean) {
      return new OptionalOrganizationNodesProperty(enabled, Typed.of(settings));
    }

    static ofExpression(enabled: boolean, expression: string) {
      return new OptionalOrganizationNodesProperty(enabled, Typed.of(new BoundExpression(expression, false)));
    }


    static ofVariablePath(enabled: boolean, screenContext: VariableContext, expression: string) {
      if(validateVariablePath(expression)) {
        return new OptionalOrganizationNodesProperty(enabled, Typed.of(new BoundVariablePath(screenContext, new VariablePath(expression))));
      } else {
        throw new Error("Incorrect variable path");
      }
    }

    staticValueText(): string {
      return "";
    }
  }

  export class OptionalStringArrayProperty implements OptionalComponentProperty<Option<Array<string>>> {
    static className = "OptionalStringArrayProperty";
    className() {
      return OptionalStringArrayProperty.className;
    }

    constructor(readonly staticValue: Array<string>,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional(): OptionalStringArrayProperty {
      return this;
    }

    toRequired(): ComponentProperty<any> {
      if(this.enabled) {
        throw new Error("No required property");
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    equals(other: ComponentProperty<Option<Array<string>>>): boolean {
      return other instanceof OptionalStringArrayProperty && this.isEqual(other);
    }

    isEqual(other: OptionalStringArrayProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && arraysEqual(this.staticValue, other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<Array<string>>>): PropertyTry<Option<Array<string>>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static copy(other: OptionalStringArrayProperty) {
      return new OptionalStringArrayProperty(other.staticValue.slice(), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static disabled() {
      return new OptionalStringArrayProperty([], false, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: Array<string>, enabled: boolean) {
      return new OptionalStringArrayProperty(staticValue, enabled, Typed.of(settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue.join(", ") : "";
    }


  }

  export class OptionalStringProperty implements OptionalComponentProperty<Option<string>> {
    static className = "OptionalStringProperty";
    className() {
      return OptionalStringProperty.className;
    }

    constructor(readonly staticValue: string,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new StringProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    currentValue(fromState: () => PropertyTry<Option<string>>): PropertyTry<Option<string>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }


    static disabled(defaultValue: string) {
      return new OptionalStringProperty(defaultValue, false, Typed.of(StaticValue.INSTANCE));
    }


    static copy(other: OptionalStringProperty) {
      return new OptionalStringProperty(other.staticValue, other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static of(staticValue: string) {
      return new OptionalStringProperty(staticValue, true, Typed.of(StaticValue.INSTANCE));
    }

    equals(other: ComponentProperty<Option<string>>): boolean {
      return other instanceof OptionalStringProperty && this.isEqual(other);
    }

    isEqual(other: OptionalStringProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }


    static static(staticValue: string) {
      return new OptionalStringProperty(staticValue, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: string, enabled: boolean) {
      return new OptionalStringProperty(staticValue, enabled, Typed.of(settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue : "";
    }
  }


  export class NumberProperty implements ComponentProperty<number> {
    static className = "NumberProperty";
    className() {
      return NumberProperty.className;
    }

    constructor(readonly staticValue: number,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalNumberProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }


    equals(other: ComponentProperty<number>): boolean {
      if (other instanceof NumberProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<number>): PropertyTry<number> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static copy(other: NumberProperty) {
      return new NumberProperty(other.staticValue, PropertySettingsFactory.copyTyped(other.settings));
    }

    static of(staticValue: number) {
      return new NumberProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: number) {
      return new NumberProperty(staticValue, Typed.of(settings));
    }

    static static(staticValue: number) {
      return new NumberProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    staticValueText(): string {
      return this.staticValue.toString();
    }
  }


  export class OptionalNumberProperty implements OptionalComponentProperty<Option<number>> {
    static className = "OptionalNumberProperty";
    className() {
      return OptionalNumberProperty.className;
    }

    constructor(readonly staticValue: number,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new NumberProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    equals(other: ComponentProperty<Option<number>>): boolean {
      return other instanceof OptionalNumberProperty && this.isEqual(other);
    }

    isEqual(other: OptionalNumberProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue === other.staticValue;
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    static disabled(defaultValue: number) {
      return new OptionalNumberProperty(defaultValue, false, Typed.of(StaticValue.INSTANCE));
    }

    static of(value: number) {
      return new OptionalNumberProperty(value, true, Typed.of(StaticValue.INSTANCE));
    }

    currentValue(fromState: () => PropertyTry<Option<number>>): PropertyTry<Option<number>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static copy(other: OptionalNumberProperty) {
      return new OptionalNumberProperty(other.staticValue, other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static ofSettings(settings: PropertySettings, staticValue: number, enabled: boolean) {
      return new OptionalNumberProperty(staticValue, enabled, Typed.of(settings));
    }


    static static(staticValue: number, enabled: boolean) {
      return new OptionalNumberProperty(staticValue, enabled, Typed.of(StaticValue.INSTANCE));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue.toString() : "";
    }
  }




  export class DateTimeProperty implements ComponentProperty<LocalDateTime> {
    static className = "DateTimeProperty";
    className() {
      return DateTimeProperty.className;
    }

    constructor(readonly staticValue: LocalDateTime,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalDateTimeProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<LocalDateTime>): boolean {
      if (other instanceof DateTimeProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<LocalDateTime>): PropertyTry<LocalDateTime> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static of(staticValue: LocalDateTime) {
      return new DateTimeProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalDateTime) {
      return new DateTimeProperty(staticValue, Typed.of(settings));
    }

    static copy(other: DateTimeProperty) {
      return new DateTimeProperty(LocalDateTime.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.staticValue.toString()+" UTC";
    }
  }


  export class OptionalDateTimeProperty implements OptionalComponentProperty<Option<LocalDateTime>> {
    static className = "OptionalDateTimeProperty";
    className() {
      return OptionalDateTimeProperty.className;
    }

    constructor(readonly staticValue: LocalDateTime,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new DateTimeProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }


    equals(other: ComponentProperty<Option<LocalDateTime>>): boolean {
      return other instanceof OptionalDateTimeProperty && this.isEqual(other);
    }

    isEqual(other: OptionalDateTimeProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<LocalDateTime>>): PropertyTry<Option<LocalDateTime>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static of(value: LocalDateTime) {
      return new OptionalDateTimeProperty(value, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalDateTime, enabled: boolean) {
      return new OptionalDateTimeProperty(staticValue, enabled, Typed.of(settings));
    }


    static disabled() {
      return new OptionalDateTimeProperty(LocalDateTime.ZERO, false, Typed.of(StaticValue.INSTANCE));
    }


    static copy(other: OptionalDateTimeProperty) {
      return new OptionalDateTimeProperty(LocalDateTime.copy(other.staticValue), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string | I18nText {
      return this.enabled ? this.staticValue.toString()+" UTC" : "";
    }


  }





  export class DateProperty implements ComponentProperty<LocalDate> {
    static className = "DateProperty";
    className() {
      return DateProperty.className;
    }

    constructor(readonly staticValue: LocalDate,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalDateProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<LocalDate>): boolean {
      if (other instanceof DateProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<LocalDate>): PropertyTry<LocalDate> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static of(staticValue: LocalDate) {
      return new DateProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalDate) {
      return new DateProperty(staticValue, Typed.of(settings));
    }

    static copy(other: DateProperty) {
      return new DateProperty(LocalDate.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.staticValue.toString();
    }
  }

  export class OptionalDateProperty implements OptionalComponentProperty<Option<LocalDate>> {
    static className = "OptionalDateProperty";
    className() {
      return OptionalDateProperty.className;
    }

    constructor(readonly staticValue: LocalDate,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new DateProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }


    equals(other: ComponentProperty<Option<LocalDate>>): boolean {
      return other instanceof OptionalDateProperty && this.isEqual(other);
    }

    isEqual(other: OptionalDateProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<LocalDate>>): PropertyTry<Option<LocalDate>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static of(value: LocalDate) {
      return new OptionalDateProperty(value, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalDate, enabled: boolean) {
      return new OptionalDateProperty(staticValue, enabled, Typed.of(settings));
    }

    static disabled() {
      return new OptionalDateProperty(LocalDate.ZERO, false, Typed.of(StaticValue.INSTANCE));
    }


    static copy(other: OptionalDateProperty) {
      return new OptionalDateProperty(LocalDate.copy(other.staticValue), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue.toString() : "";
    }
  }


  export class TimeProperty implements ComponentProperty<LocalTime> {
    static className = "TimeProperty";
    className() {
      return TimeProperty.className;
    }

    constructor(readonly staticValue: LocalTime,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalTimeProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<LocalTime>): boolean {
      if (other instanceof TimeProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<LocalTime>): PropertyTry<LocalTime> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static of(staticValue: LocalTime) {
      return new TimeProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalTime) {
      return new TimeProperty(staticValue, Typed.of(settings));
    }

    static copy(other: TimeProperty) {
      return new TimeProperty(LocalTime.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.staticValue.toString();
    }
  }

  export class OptionalTimeProperty implements OptionalComponentProperty<Option<LocalTime>> {
    static className = "OptionalTimeProperty";
    className() {
      return OptionalTimeProperty.className;
    }

    constructor(readonly staticValue: LocalTime,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional(): OptionalTimeProperty {
      return this;
    }

    toRequired(): TimeProperty {
      if(this.enabled) {
        return new TimeProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    equals(other: ComponentProperty<Option<LocalTime>>): boolean {
      return other instanceof OptionalTimeProperty && this.isEqual(other);
    }

    isEqual(other: OptionalTimeProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<LocalTime>>): PropertyTry<Option<LocalTime>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static of(value: LocalTime) {
      return new OptionalTimeProperty(value, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: LocalTime, enabled: boolean) {
      return new OptionalTimeProperty(staticValue, enabled, Typed.of(settings));
    }


    static disabled() {
      return new OptionalTimeProperty(LocalTime.MIDNIGHT, false, Typed.of(StaticValue.INSTANCE));
    }


    static copy(other: OptionalTimeProperty) {
      return new OptionalTimeProperty(LocalTime.copy(other.staticValue), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue.toString() : "";
    }
  }


  export class DurationProperty implements ComponentProperty<Duration> {
    static className = "DurationProperty";
    className() {
      return DurationProperty.className;
    }

    constructor(readonly staticValue: Duration,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired() {
      return this;
    }

    toOptional() {
      return new OptionalDurationProperty(this.staticValue, true, this.settings);
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<Duration>): boolean {
      if (other instanceof DurationProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<Duration>): PropertyTry<Duration> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValue);
      }
    }

    static of(staticValue: Duration) {
      return new DurationProperty(staticValue, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: Duration) {
      return new DurationProperty(staticValue, Typed.of(settings));
    }

    static copy(other: DurationProperty) {
      return new DurationProperty(Duration.copy(other.staticValue), PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.staticValue.toString();
    }
  }

  export class OptionalDurationProperty implements OptionalComponentProperty<Option<Duration>> {
    static className = "OptionalDurationProperty";
    className() {
      return OptionalDurationProperty.className;
    }

    constructor(readonly staticValue: Duration,
                readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new DurationProperty(this.staticValue, this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }


    equals(other: ComponentProperty<Option<Duration>>): boolean {
      return other instanceof OptionalDurationProperty && this.isEqual(other);
    }

    isEqual(other: OptionalDurationProperty) {
      if (this.enabled === other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          if (settings instanceof StaticValue) {
            return settings.equals(other.settingsUnwrapped()) && this.staticValue.isEqual(other.staticValue);
          } else {
            return settings.equals(other.settingsUnwrapped());
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Option<Duration>>): PropertyTry<Option<Duration>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          return PropertyTry.successCompleted(Some(this.staticValue));
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static of(value: Duration) {
      return new OptionalDurationProperty(value, true, Typed.of(StaticValue.INSTANCE));
    }

    static ofSettings(settings: PropertySettings, staticValue: Duration, enabled: boolean) {
      return new OptionalDurationProperty(staticValue, enabled, Typed.of(settings));
    }

    static disabled() {
      return new OptionalDurationProperty(Duration.ZERO, false, Typed.of(StaticValue.INSTANCE));
    }

    static copy(other: OptionalDurationProperty) {
      return new OptionalDurationProperty(Duration.copy(other.staticValue), other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    staticValueText(): string {
      return this.enabled ? this.staticValue.toString() : "";
    }
  }


  export class ModelAutomaticEvaluation {
    constructor(readonly enabled: boolean,
                readonly initOnly: boolean,
                readonly async: boolean,
                readonly expression: string) {}

    static copy(other: ModelAutomaticEvaluation) {
      return new ModelAutomaticEvaluation(other.enabled, other.initOnly, other.async, other.expression);
    }

    static empty() {
      return new ModelAutomaticEvaluation(false, false, false, "");
    }
  }

  export class ModelProperty implements ComponentProperty<BusinessVariable> {
    static className = "ModelProperty";
    className() {
      return ModelProperty.className;
    }

    constructor(readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }


    toOptional(): OptionalModelProperty {
      return new OptionalModelProperty(true, this.settings);
    }

    toRequired(): ModelProperty {
      return this;
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<BusinessVariable>): boolean {
      if (other instanceof ModelProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped());
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }


    currentValue(fromState: () => PropertyTry<BusinessVariable>): PropertyTry<BusinessVariable> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        throw new Error("Dynamic only")
      }
    }

    static copy(other: ModelProperty) {
      return new ModelProperty(PropertySettingsFactory.copyTyped(other.settings));
    }

    static variableName(variableName: string) {
      return new ModelProperty(Typed.of(new BoundVariablePath(VariableContext.inherit, VariablePath.root(variableName))));
    }


    static ofExpression(expression: string) {
      return new ModelProperty(Typed.of(new BoundExpression(expression, false)));
    }

    static ofVariablePath(screenContext: VariableContext, variablePath: string) {
      if(validateVariablePath(variablePath)) {
        return new ModelProperty(Typed.of(new BoundVariablePath(screenContext, new VariablePath(variablePath))));
      } else {
        throw new Error("Incorrect variable path");
      }
    }

    staticValueText(): string {
      return "";
    }
  }

  export class OptionalModelProperty implements OptionalComponentProperty<Option<BusinessVariable>> {
    static className = "OptionalModelProperty";
    className() {
      return OptionalModelProperty.className;
    }

    constructor(readonly enabled: boolean,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return this.enabled;
    }

    toOptional() {
      return this;
    }

    toRequired() {
      if(this.enabled) {
        return new ModelProperty(this.settings);
      } else {
        throw new Error("Cannot convert non enabled property to Optional property");
      }
    }

    isOptional(): boolean {
      return true;
    }

    equals(other: ComponentProperty<Option<BusinessVariable>>): boolean {
      return other instanceof OptionalModelProperty && this.isEqual(other);
    }

    isEqual(other: OptionalModelProperty) {
      if (this.enabled == other.enabled) {
        if(this.enabled) {
          const settings = this.settingsUnwrapped();
          return settings.equals(other.settingsUnwrapped());
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    static disabled() {
      return new OptionalModelProperty(false, Typed.of(new BoundVariablePath(VariableContext.inherit, VariablePath.empty())));
    }

    currentValue(fromState: () => PropertyTry<Option<BusinessVariable>>): PropertyTry<Option<BusinessVariable>> {
      if(this.enabled) {
        const settings = this.settingsUnwrapped();
        if(!(settings instanceof StaticValue)) {
          return fromState();
        } else {
          throw new Error("Dynamic only");
        }
      } else {
        return PropertyTry.successCompleted(None());
      }
    }

    static copy(other: OptionalModelProperty) {
      return new OptionalModelProperty(other.enabled, PropertySettingsFactory.copyTyped(other.settings));
    }

    static ofExpression(enabled: boolean, expression: string) {
      return new OptionalModelProperty(enabled, Typed.of(new BoundExpression(expression, false)));
    }

    static ofVariableName(enabled: boolean, screenContext: VariableContext, variableName: string) {
      if(validateVariableName(variableName)) {
        return new OptionalModelProperty(enabled, Typed.of(new BoundVariablePath(screenContext, VariablePath.root(variableName))));
      } else {
        throw new Error("Incorrect variable path");
      }
    }

    static ofVariablePath(enabled: boolean, screenContext: VariableContext, variablePath: string) {
      if(validateVariablePath(variablePath)) {
        return new OptionalModelProperty(enabled, Typed.of(new BoundVariablePath(screenContext, new VariablePath(variablePath))));
      } else {
        throw new Error("Incorrect variable path");
      }
    }

    staticValueText(): string {
      return "";
    }
  }

  export class LabeledValueFactory {
    static copy(other: LabeledValue): LabeledValue {
      return LabeledValueFactory.copyByType(other, other.className());
    }

    static copyTyped(other: Typed<LabeledValue>): Typed<LabeledValue> {
      return Typed.of(LabeledValueFactory.copyByType(Typed.value(other), Typed.className(other)));
    }

    static copyByType(other: LabeledValue, className: string): LabeledValue {
      switch (className) {
        case StringLabeledValue.className: return StringLabeledValue.copy(<StringLabeledValue>other);
        case NumberLabeledValue.className: return NumberLabeledValue.copy(<NumberLabeledValue>other);
        case BooleanLabeledValue.className: return BooleanLabeledValue.copy(<BooleanLabeledValue>other);
        default: throw new Error("Not supported ["+className+"]");
      }
    }
  }


  export interface LabeledValue {
    label: Option<I18nText>;
    selected: boolean;
    toVisibleValue(): string;
    className(): string;

    isString(): boolean;
    isNumber(): boolean;
    isBoolean(): boolean;

    asString(): StringLabeledValue;
    asNumber(): NumberLabeledValue;
    asBoolean(): BooleanLabeledValue;

    equals(b: LabeledValue): boolean;

    toBusinessVariable(): BusinessVariable;

    comparableText(): string;
  }


  export class StringLabeledValue implements LabeledValue {
    static className = "StringLabeledValue";

    className(): string {
      return StringLabeledValue.className;
    }

    constructor(readonly value: Option<string>,
                readonly selected: boolean,
                readonly label: Option<I18nText>) {}

    equals(other: LabeledValue) {
      if(other instanceof StringLabeledValue) {
        return this.value.equals(other.value) && this.label.equals(other.label, (a, b) => a.isEqual(b));
      } else {
        return false;
      }
    }

    static copy(other: StringLabeledValue) {
      return new StringLabeledValue(Option.copy(other.value),
        other.selected,
        Option.copy(other.label, I18nText.copy));
    }

    toBusinessVariable(): BusinessVariable {

      const value = new StringVariable(this.value.getOrError("No value available").trim());

      if(this.label.isDefined()) {
        return new ObjectVariable({value: value, label: new StringVariable(this.label.get().getCurrentWithFallback())});
      } else {
        return value;
      }
    }


    isString(): boolean {
      return true;
    }

    isNumber(): boolean {
      return false;
    }

    isBoolean(): boolean {
      return false;
    }

    asString(): StringLabeledValue {
      return this;
    }

    asNumber(): NumberLabeledValue {
      throw new Error("Not a number");
    }

    asBoolean(): BooleanLabeledValue {
      throw new Error("Not a boolean");
    }

    toVisibleValue(): string {
      return this.label.map(l => l.getCurrentWithFallback()).getOrElse(this.value.getOrElse("-"));
    }

    comparableText(): string {
       return this.value.map(v => ""+v).getOrElse("null")+this.label.map(l => ": "+l.toComparableString()).getOrElse("");
    }
  }

  export class NumberLabeledValue implements LabeledValue  {
    static className = "NumberLabeledValue";

    className(): string {
      return NumberLabeledValue.className;
    }

    constructor(readonly value: Option<number>,
                readonly selected: boolean,
                readonly label: Option<I18nText>) {}

    equals(other: LabeledValue) {
      if(other instanceof NumberLabeledValue) {
        return this.value.equals(other.value) && this.label.equals(other.label, (a, b) => a.isEqual(b));
      } else {
        return false;
      }
    }

    static copy(other: NumberLabeledValue) {
      return new NumberLabeledValue(Option.copy(other.value), other.selected,
        Option.copy(other.label, I18nText.copy));
    }

    toBusinessVariable(): BusinessVariable {

      const value = new NumberVariable(this.value.getOrError("No value available"));

      if(this.label.isDefined()) {
        return new ObjectVariable({value: value, label: new StringVariable(this.label.get().getCurrentWithFallback())});
      } else {
        return value;
      }
    }

    isString(): boolean {
      return false;
    }

    isNumber(): boolean {
      return true;
    }

    isBoolean(): boolean {
      return false;
    }

    asString(): StringLabeledValue {
      throw new Error("Not a string");
    }

    asNumber(): NumberLabeledValue {
      return this;
    }

    asBoolean(): BooleanLabeledValue {
      throw new Error("Not a boolean");
    }

    toVisibleValue(): string {
      return this.label.map(l => l.getCurrentWithFallback()).getOrElse(this.value.map(v => ""+v).getOrElse("-"));
    }

    comparableText(): string {
       return this.value.map(v => ""+v).getOrElse("null")+this.label.map(l => ": "+l.toComparableString()).getOrElse("");
    }

  }

  export class BooleanLabeledValue implements LabeledValue  {
    static className = "BooleanLabeledValue";

    className(): string {
      return BooleanLabeledValue.className;
    }

    constructor(readonly value: Option<boolean>,
                readonly selected: boolean,
                readonly label: Option<I18nText>) {}

    equals(other: LabeledValue) {
      if(other instanceof BooleanLabeledValue) {
        return this.value.equals(other.value) && this.label.equals(other.label, (a, b) => a.isEqual(b));
      } else {
        return false;
      }
    }

    static copy(other: BooleanLabeledValue) {
      return new BooleanLabeledValue(Option.copy(other.value), other.selected,
        Option.copy(other.label, I18nText.copy));
    }

    toBusinessVariable(): BusinessVariable {

      const value = new BooleanVariable(this.value.getOrError("No value available"));

      if(this.label.isDefined()) {
        return new ObjectVariable({value: value, label: new StringVariable(this.label.get().getCurrentWithFallback())});
      } else {
        return value;
      }
    }

    isString(): boolean {
      return false;
    }

    isNumber(): boolean {
      return false;
    }

    isBoolean(): boolean {
      return true;
    }

    asString(): StringLabeledValue {
      throw new Error("Not a string");
    }

    asNumber(): NumberLabeledValue {
      throw new Error("Not a number");
    }

    asBoolean(): BooleanLabeledValue {
      return this;
    }

    toVisibleValue(): string {
      return this.label.map(l => l.getCurrentWithFallback()).getOrElse(this.value.map(v => ""+v).getOrElse("-"));
    }

    comparableText(): string {
      return this.value.map(v => ""+v).getOrElse("null")+this.label.map(l => ": "+l.toComparableString()).getOrElse("");
    }


  }

  export class LabeledValuesProperty implements ComponentProperty<Array<BusinessVariable>> {
    static className = "LabeledValuesProperty";
    className() {
      return LabeledValuesProperty.className;
    }

    constructor(readonly staticValue: Array<Typed<LabeledValue>>,
                readonly settings: Typed<PropertySettings>) {}

    settingsUnwrapped(): PropertySettings {
      return Typed.value(this.settings);
    }

    isEnabled(): boolean {
      return true;
    }

    toRequired(): LabeledValuesProperty {
      return this;
    }

    toOptional(): ComponentProperty<any> {
      throw new Error("No Optional Labeled values property");
    }

    isOptional(): boolean {
      return false;
    }

    equals(other: ComponentProperty<Array<BusinessVariable>>): boolean {
      if (other instanceof LabeledValuesProperty) {
        const settings = this.settingsUnwrapped();
        if(settings instanceof StaticValue) {
          return settings.equals(other.settingsUnwrapped()) && arraysEqualBy(this.staticValue, other.staticValue, (a, b) => Typed.value(a).equals(Typed.value(b)));
        } else {
          return settings.equals(other.settingsUnwrapped());
        }
      } else {
        return false;
      }
    }

    currentValue(fromState: () => PropertyTry<Array<BusinessVariable>>): PropertyTry<Array<BusinessVariable>> {
      const settings = this.settingsUnwrapped();
      if(!(settings instanceof StaticValue)) {
        return fromState();
      } else {
        return PropertyTry.successCompleted(this.staticValueUnwrapped().map(v => v.toBusinessVariable()));
      }
    }

    static copy(other: LabeledValuesProperty) {
      return new LabeledValuesProperty(other.staticValue.map(s => LabeledValueFactory.copyTyped(s)), PropertySettingsFactory.copyTyped(other.settings));
    }

    static of(values: Array<string>) {
      return new LabeledValuesProperty(values.map(v => Typed.of(new StringLabeledValue(Some(v), false, None()))), Typed.of(StaticValue.INSTANCE));
    }

    staticValueUnwrapped() {
      return this.staticValue.map(v => Typed.value(v));
    }

    staticValueText(): string | I18nText {
      return this.staticValue.map(v => {
        const vv: LabeledValue = Typed.value(v);
        return vv.comparableText()
      }).join(", ");
    }

  }

export class PropertyTry<VALUE_TYPE> {
  constructor(readonly p: boolean,
              readonly v: VALUE_TYPE | null,
              readonly e: PropertyTryError | null) {
    if(!p && v == null && e == null) {
      throw new Error("Both value and error cannot be null");
    } else if(!p && v !== null && e !== null) {
      throw new Error("Both value and error cannot be not null if not in progress");
    }
  }

  static success<VALUE_TYPE>(inProgress: boolean, value: VALUE_TYPE) {
    return new PropertyTry<VALUE_TYPE>(inProgress, value, null);
  }

  static successCompleted<VALUE_TYPE>(value: VALUE_TYPE) {
    return new PropertyTry<VALUE_TYPE>(false, value, null);
  }

  static error<VALUE_TYPE>(inProgress: boolean, message: string) {
    return new PropertyTry<VALUE_TYPE>(inProgress, null, new PropertyTryError(None(), message));
  }

  static errorCompleted<VALUE_TYPE>(message: string) {
    return new PropertyTry<VALUE_TYPE>(false, null, new PropertyTryError(None(), message));
  }

  static errorOf<VALUE_TYPE>(inProgress: boolean, errorTimestamp: LocalDateTime, errorMessage: string) {
    return new PropertyTry<VALUE_TYPE>(inProgress, null, new PropertyTryError(Some(errorTimestamp), errorMessage));
  }

  static errorFrom<VALUE_TYPE>(inProgress: boolean, error: PropertyTryError) {
    return new PropertyTry<VALUE_TYPE>(inProgress, null, error);
  }

  static inProgressEmpty<VALUE_TYPE>() {
    return new PropertyTry<VALUE_TYPE>(true, null, null);
  }


  isError() {
    return this.e !== null;
  }

  isSuccess() {
    return this.v !== null;
  }

  get error(): PropertyTryError {
    if(this.e == null) {
      throw new Error("Cannot get error from Success");
    } else {
      return this.e;
    }
  }

  get value(): VALUE_TYPE {
    if(this.v == null) {
      throw new Error("Cannot get value from Error");
    } else {
      return this.v;
    }
  }

  get valueIsDefined(): boolean {
    return this.v != null;
  }

  valueOrDefault(d: VALUE_TYPE): VALUE_TYPE {
    if(this.v == null) {
      return d;
    } else {
      return this.v;
    }
  }
}
