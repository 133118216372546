<div class="clickableGlass" *ngIf="viewModel.clickable"></div>
<div class="sectionHeader {{viewModel.headerCssClasses}}" *ngIf="viewModel.headerVisible" [style]="viewModel.headerCss">{{viewModel.header}}</div>
<div
  [class]="'childrenPlain ' + viewModel.childrenPlainCssClasses + ' layout_' + viewModel.layoutClass + ' layoutAlign_' + viewModel.layoutAlignClass + ' layoutStretch_' + viewModel.layoutStretchClass + ' layoutWrap_' + viewModel.layoutWrapClass"
  [style]="viewModel.childrenPlainCss">
  <ng-container *ngFor="let component of viewModel.children">
    <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
         [class.nonVisibleComponent]="component.nonVisibleComponent"
         [style]="component.positionCss">
      <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
        <!--              SHARED -->
        <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />

        <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />


        <div class="errorsInfo" *ngIf="component.error">
          <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
        </div>

        <div class="inProgressGlass" *ngIf="component.inProgress"></div>
        <!--              SHARED -->
      </div>
    </div>
  </ng-container>
</div>
