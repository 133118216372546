import {Component, Input} from "@angular/core";
import {displayExceptions} from "@utils";
import {SessionServiceProvider, UserRegistrationService, UserSettingsStateService} from "@shared";

class PasswordRecoveryForm {
  email: string = "";
}

@Component({
  selector: "my-password-recovery-form",
  templateUrl: "./password-recovery-form.component.html"
})
export class PasswordRecoveryFormComponent {

  @Input() userEmail: string = "";
  public form: PasswordRecoveryForm = new PasswordRecoveryForm();
  public finished: boolean = false;

  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly userSettingsService: UserSettingsStateService,
              private readonly userRegistrationService: UserRegistrationService) {
  }

  recoverPassword() {
    if(this.userEmail.trim().length > 0) {
      this.finished = true;
      this.form.email = this.userEmail;
      this.userRegistrationService.recoverPassword(this.form.email,
        () => {
        }, displayExceptions);
    } // otherwise do nothing
  };

}
