import {CalendarComponentViewModel} from "./CalendarComponentViewModel";
import {BusinessVariable} from "@shared-model";
import {__, LocalDate, LocalDateTime, Option, required, toastr} from "@utils";
import {
  CalendarCell,
  CalendarEntryModel,
  CalendarEventBus,
  CalendarHelper,
  CalendarRow,
  DropDownSelectorOption, ResourceCalendarPeriodViewModel, ResourceCalendarResources, ResourceCalendarSingleEntry
} from "@shared";
import {WeeklyCalendarViewModel} from "./WeeklyCalendarViewModel";
import {MonthlyCalendarViewModel} from "./MonthlyCalendarViewModel";



export class ResourceCalendarTimeHeader {
  constructor(readonly name: string) {
  }
}

export class ResourceCalendarPeriodType {
  static day = 0;
  static week = 1;
  static month = 2;
}

export class PeriodSelectorOption implements DropDownSelectorOption {
  constructor(readonly name: string,
              readonly value: number) {}
}

export class ResourceCalendarViewModel {

  static START_OF_WEEK = 1; // monday, might be sunday also


  today: LocalDate = LocalDate.nowDate();
  firstDay: LocalDate;

  periodName: string = "";
  columns = 7;

  resources: Array<ResourceCalendarResources> = [];
  timeHeader: Array<ResourceCalendarTimeHeader> = [];

  entries: Array<CalendarEntryModel> = [];

  periodOptions: Array<DropDownSelectorOption> = [
    new PeriodSelectorOption("Miesięczny", ResourceCalendarPeriodType.month),
    new PeriodSelectorOption("Tygodniowy", ResourceCalendarPeriodType.week),
    new PeriodSelectorOption("Dobowy", ResourceCalendarPeriodType.day)];

  periodLength = this.periodOptions[0];

  // static colors: Array<string> = ["rgb(92, 107, 192)", "rgb(255, 235, 59)", "rgb(102, 187, 106)", "rgb(126, 87, 194)", "rgb(255, 112, 67)", "rgb(41, 182, 246)", "rgb(255, 202, 40)", "rgb(55, 173, 150)",
  //   "rgb(171, 71, 188)", "rgb(239, 83, 80)", "rgb(38, 198, 218)", "rgb(255, 167, 38)", "rgb(156, 204, 101)", "rgb(236, 64, 122)", "rgb(236, 64, 122)", "rgb(236, 64, 122)"];


  static daysNames = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];


  constructor(readonly parent: CalendarComponentViewModel,
              readonly eventBus: CalendarEventBus,
              readonly onSelected: (model: CalendarEntryModel) => void) {

    this.firstDay = this.findFirstDayOfPeriod(this.today);
    this.updateView();

    eventBus.on(eventBus.entrySelected, (selectedEntry: CalendarEntryModel) => this.onEntrySelected(selectedEntry));
    eventBus.on(eventBus.periodsSelected, (from: number, to: number) => this.onPeriodsSelected(from, to));
  }

  private onEntrySelected(selectedEntry: CalendarEntryModel) {
    this.resources.forEach(resource => {
      resource.periods.forEach(period => {
        period.visibleEntries.forEach(entry => {
          entry.selected = entry.model === selectedEntry;
        });
      });
    });
  }

  private findFirstDayOfPeriod(date: LocalDate): LocalDate {
    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day:return date;
      case ResourceCalendarPeriodType.week:return date.plusDays(-date.weekDay() + ResourceCalendarViewModel.START_OF_WEEK);
      case ResourceCalendarPeriodType.month:return new LocalDate(date.year, date.month, 1);
      default:throw new Error("Incorrect period length");
    }
  }

  previousPeriod() {
    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day: this.firstDay = this.firstDay.plusDays(-1);       break;
      case ResourceCalendarPeriodType.week: this.firstDay = this.firstDay.plusDays(-7);       break;
      case ResourceCalendarPeriodType.month:
        const newYear = this.firstDay.month == 1 ? this.firstDay.year - 1 : this.firstDay.year;
        const newMonth = this.firstDay.month == 1 ? 12 : this.firstDay.month - 1;
        this.firstDay = new LocalDate(newYear, newMonth, 1);
        break;
      default: throw new Error("Incorrect period length");
    }
    this.updateView();
  }

  nextPeriod() {
    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day: this.firstDay = this.firstDay.plusDays(1); break;
      case ResourceCalendarPeriodType.week: this.firstDay = this.firstDay.plusDays(7); break;
      case ResourceCalendarPeriodType.month:
        const newYear = this.firstDay.month == 12 ? this.firstDay.year + 1 : this.firstDay.year;
        const newMonth = this.firstDay.month == 12 ? 1 : this.firstDay.month + 1;
        this.firstDay = new LocalDate(newYear, newMonth, 1);
        break;
      default:throw new Error("Incorrect period length");
    }
    this.updateView();
  }

  private updateView() {

    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day:
        this.periodName = this.firstDay.formattedWords();
        this.columns = 24;
        break;
      case ResourceCalendarPeriodType.week:
        this.periodName = WeeklyCalendarViewModel.weekName(this.firstDay, this.firstDay.plusDays(6));
        this.columns = 7;
        break;
      case ResourceCalendarPeriodType.month:
        this.periodName = MonthlyCalendarViewModel.monthName(this.firstDay.year, this.firstDay.month);
        this.columns = 31;
        break;
      default:
        throw new Error("Incorrect period length");
    }


    let resources: Array<Option<BusinessVariable>> = [];
    this.entries.forEach(entry => {
      if (__(resources).notExists(r => r.equals(Option.of(entry.resource), (a, b) => a.isEqual(b)))) {
        resources.push(Option.of(entry.resource));
      }
    })
    resources = __(resources).sortBy(r => r.map(rr => rr.valueToSimpleString()).getOrElse("--bez zasobu--"));


    this.resources = resources.map(r => {

      const periods: Array<ResourceCalendarPeriodViewModel> = [];
      switch (this.periodLength.value) {
        case ResourceCalendarPeriodType.day:
          for (let h = 0; h < 24; h++) {
            periods.push(new ResourceCalendarPeriodViewModel(h, this.firstDay, 0, [], [], h < 9 || h > 17));
          }
          break;
        case ResourceCalendarPeriodType.week:
          for (let d = 0; d < 7; d++) {
            const date = this.firstDay.plusDays(d);
            const weekDay = date.weekDay();
            periods.push(new ResourceCalendarPeriodViewModel(0, date, 0, [], [], weekDay == 0 || weekDay == 6));
          }
          break;
        case ResourceCalendarPeriodType.month:
          for (let d = 0; d < this.daysInMonth(this.firstDay.year, this.firstDay.month); d++) {
            const date = this.firstDay.plusDays(d);
            const weekDay = date.weekDay();
            periods.push(new ResourceCalendarPeriodViewModel(0, date, 0, [], [], weekDay == 0 || weekDay == 6));
          }
          break;
        default:
          throw new Error("Incorrect period length");
      }

      return new ResourceCalendarResources(r, r.map(rr => rr.valueToSimpleString()).getOrElse("--bez zasobu--"), periods);
    })


    this.timeHeader = [];

    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day:
        for (let h = 0; h < 24; h++) {
          this.timeHeader.push(new ResourceCalendarTimeHeader(this.formatTwoDigits(h)));
        }
        break;
      case ResourceCalendarPeriodType.week:
        for (let d = 0; d < 7; d++) {
          this.timeHeader.push(new ResourceCalendarTimeHeader(ResourceCalendarViewModel.daysNames[this.firstDay.plusDays(d).weekDay()]));
        }
        break;
      case ResourceCalendarPeriodType.month:
        for (let d = 1; d <= this.daysInMonth(this.firstDay.year, this.firstDay.month); d++) {
          this.timeHeader.push(new ResourceCalendarTimeHeader(this.formatTwoDigits(d)));
        }
        break;
      default:
        throw new Error("Incorrect period length");
    }

    switch (this.periodLength.value) {
      case ResourceCalendarPeriodType.day:

        this.resources.forEach(resource => {
          const row = new CalendarRow(0, resource.periods.map(p => new CalendarCell(p.hour, false, false, [], [], 1)));
          const start = LocalDateTime.fromLocalDateBegin(this.firstDay);
          const end = LocalDateTime.fromLocalDateBegin(this.firstDay.plusDays(1));
          CalendarHelper.prepareSeries(start, end, this.entries,
            entry => Option.of(entry.resource).equals(resource.value, (a, b) => a.isEqual(b)),
            entry => entry.label,
            dateTime => dateTime.date.daysBetween(this.firstDay),
            dateTime => dateTime.time.hour,
            dateTime => dateTime.time.hour == 0,
            60, [row], 3);

          for (let d = 0; d < resource.periods.length; d++) {
            const newCell = row.cells[d];
            const oldDay = resource.periods[d];
            oldDay.rows = newCell.rows;
            oldDay.visibleEntries = newCell.visibleEntries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
            oldDay.entries = newCell.entries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
          }
        })

        break;
      case ResourceCalendarPeriodType.week:

        this.resources.forEach(resource => {
          const row = new CalendarRow(0, resource.periods.map(p => new CalendarCell((p.date.weekDay() - ResourceCalendarViewModel.START_OF_WEEK + 7) % 7, false, false, [], [], 1)));
          const start = LocalDateTime.fromLocalDateBegin(this.firstDay);
          const end = LocalDateTime.fromLocalDateBegin(this.firstDay.plusDays(7));
          CalendarHelper.prepareSeries(start, end, this.entries,
            entry => Option.of(entry.resource).equals(resource.value, (a, b) => a.isEqual(b)),
            entry => entry.label,
            dateTime => {

              if (dateTime.date.isBefore(this.firstDay)) {
                let weeks = -Math.floor(dateTime.date.daysBetween(this.firstDay) / 7)
                if ((dateTime.date.weekDay() + 7 - ResourceCalendarViewModel.START_OF_WEEK) % 7 > (this.firstDay.weekDay() + 7 - ResourceCalendarViewModel.START_OF_WEEK) % 7) {
                  weeks--;
                }
                return weeks;
              } else {
                let weeks = Math.floor(this.firstDay.daysBetween(dateTime.date) / 7)
                if ((dateTime.date.weekDay() + 7 - ResourceCalendarViewModel.START_OF_WEEK) % 7 < (this.firstDay.weekDay() + 7 - ResourceCalendarViewModel.START_OF_WEEK) % 7) {
                  weeks++;
                }
                return weeks;
              }
            },
            dateTime => (dateTime.date.weekDay() - ResourceCalendarViewModel.START_OF_WEEK + 7) % 7,
            dateTime => dateTime.date.weekDay() == ResourceCalendarViewModel.START_OF_WEEK,
            60 * 24, [row], 3);

          for (let d = 0; d < resource.periods.length; d++) {
            const newCell = row.cells[d];
            const oldDay = resource.periods[d];
            oldDay.rows = newCell.rows;
            oldDay.visibleEntries = newCell.visibleEntries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
            oldDay.entries = newCell.entries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
          }
        })


        break;
      case ResourceCalendarPeriodType.month:


        this.resources.forEach(resource => {
          const row = new CalendarRow(0, resource.periods.map(p => new CalendarCell(p.date.day - 1, false, false, [], [], 1)));
          const start = LocalDateTime.fromLocalDateBegin(this.firstDay);
          const end = LocalDateTime.fromLocalDateBegin(this.firstDay.plusDays(this.daysInMonth(this.firstDay.year, this.firstDay.month)));
          CalendarHelper.prepareSeries(start, end, this.entries,
            entry => Option.of(entry.resource).equals(resource.value, (a, b) => a.isEqual(b)),
            entry => entry.label,
            dateTime => (dateTime.date.year - this.firstDay.year) * 12 + (dateTime.date.month - this.firstDay.month),
            dateTime => dateTime.date.day - 1, dateTime => dateTime.date.day == 1,
            60 * 24, [row], 3);

          for (let d = 0; d < resource.periods.length; d++) {
            const newCell = row.cells[d];
            const oldDay = resource.periods[d];
            oldDay.rows = newCell.rows;
            oldDay.visibleEntries = newCell.visibleEntries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
            oldDay.entries = newCell.entries.map(e => new ResourceCalendarSingleEntry(e.entry, e.name, e.color, e.inverted, e.placeholder, e.cssSize, false, false, !e.placeholder && this.parent.selectedValue.exists(s => e.entry !== null && s.isEqual(e.entry.value))));
          }
        })

        break;
      default:
        throw new Error("Incorrect period length");
    }
  }

  periodLengthChanged = (periodLength: PeriodSelectorOption) => {
    this.periodLength = periodLength;
    this.firstDay = this.findFirstDayOfPeriod(this.firstDay);
    this.updateView()
  };

  formatTwoDigits(hour: number): string {
    if (hour < 10) {
      return "0" + hour;
    } else {
      return "" + hour;
    }
  }


  updateEntries(entries: Array<CalendarEntryModel>) {
    this.entries = entries;
    this.updateView();
  }

  entrySelected(entry: ResourceCalendarSingleEntry) {
    this.onSelected(required(entry.model, "model"));
    entry.selected = true;
  }

  daysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  private onPeriodsSelected(from: number, to: number) {

    const singleResource = __(this.resources).find(r => __(r.periods).exists(p => p.id == from) && __(r.periods).exists(p => p.id == to));

    this.resources.forEach(resource => {
      resource.periods.forEach(period => {
        period.selected = false;
      });
    });

    singleResource.forEach(resource => {
      resource.periods.forEach(period => {
        period.selected = period.id >= from && period.id <= to;
      });
    });

  }
}
