import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty, NumberProperty,
  OptionalBooleanProperty, OptionalDurationProperty,
  OptionalI18nTextProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId, TextAlign} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class DurationInputComponentRef extends InputComponentRef {
  static className = "DurationInputComponentRef";

  className() {
    return DurationInputComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: DurationInputComponentRef): DurationInputComponentRef {
    return new DurationInputComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("DurationInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = DurationInputComponentRef.DEFAULT_PROPERTIES;

}

export class DurationInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static className = "DurationInputComponentDefinition";
  static ON_CHANGE = "onChange";
  className() {
    return DurationInputComponentDefinition.className;
  }
  typeName() {
    return "DurationInput";
  }
  static MODEL = "model";

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,DurationInputComponentDefinition.DEFAULT_PROPERTIES);
  }


  get anchorVisible(): BooleanProperty {return this.properties.getBooleanProperty("anchorVisible", this.defaultPropertiesProvider)};
  get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)};
  get minValue(): OptionalDurationProperty {return this.properties.getOptionalDurationProperty("minValue", this.defaultPropertiesProvider)};
  get maxValue(): OptionalDurationProperty {return this.properties.getOptionalDurationProperty("maxValue", this.defaultPropertiesProvider)};
  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  };
  get hoursInDay(): NumberProperty {return this.properties.getNumberProperty("hoursInDay", this.defaultPropertiesProvider)};

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: DurationInputComponentDefinition): DurationInputComponentDefinition {
    return new DurationInputComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [DurationInputComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("DurationInputDefinition", (name: string) => {switch (name) {
      case "placeholder": return OptionalI18nTextProperty.disabled();
      case "minValue": return OptionalDurationProperty.disabled();
      case "maxValue": return OptionalDurationProperty.disabled();
      case "textAlign": return StringProperty.of(TextAlign.start.name);
      case "anchorVisible": return BooleanProperty.ofTrue();
      case "innerShadow": return OptionalBooleanProperty.disabled(true);
      case "hoursInDay": return NumberProperty.of(24);
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);
}
