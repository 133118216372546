import {Component, OnInit} from "@angular/core";
import {NavigationService, SessionServiceProvider, UserSettingsStateService} from "@shared";
import {global, toastr} from "@utils";

@Component({
  selector: "my-login-page",
  templateUrl: "./login-page.component.html"
})
export class LoginPageComponent implements OnInit {

  entraIdLogins: Array<{name: string, identifier: string}> = [];
  externalLoginInProgress: boolean = false;

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly navigationService: NavigationService) {}

  ngOnInit(): void {

    this.entraIdLogins = global.config.entraIdSSO;

    const stored = localStorage.getItem("entraIdLogin");
    if(stored) {
      this.externalLoginInProgress = true;
      this.loginUsingEntraId(stored);
    }
  }


  loginUsingEntraId(identifier: string) {
    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.getEntraIdLoginIrl(identifier, (redirectUrl) => {
        console.log("redirect to " + redirectUrl);
        setTimeout(() => {
          window.location.href = redirectUrl;
        });
      }, () => {
        this.navigationService.navigateToLoginPage()
        toastr.info("Error while connecting to Entra ID");
      });
    })
  }
}
