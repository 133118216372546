import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApplicationId, None, Option, OrganizationNodeId} from "@utils";
import {
  BusinessVariable,
  DepartmentVariable,
  GroupVariable,
  OrganizationNodeVariable,
  PersonVariable,
  VariablePath
} from "@shared-model";


export class OrganizationNodeVariableInputViewModel {

    internalModel: Option<OrganizationNodeId> = None();
    internalModelBackup: Option<OrganizationNodeId> = None();

    constructor(readonly path: VariablePath,
                public value: OrganizationNodeVariable|null,
                public readOnly: boolean,
                public preview: boolean,
                readonly persons: boolean,
                readonly groups: boolean,
                readonly departments: boolean,
                readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>) {
      this.initInternalModel();
    }

    initInternalModel() {
      this.internalModel = Option.of(this.value).map(v => {
        if(v instanceof PersonVariable) {
          return OrganizationNodeId.fromPersonId(v.value.id);
        } if(v instanceof DepartmentVariable) {
          return OrganizationNodeId.fromDepartmentId(v.value.id);
        } else if(v instanceof GroupVariable) {
          return OrganizationNodeId.fromGroupId(v.value.id);
        } else {
          throw new Error("Unsupported variable type '"+v.simpleValueType()+"'");
        }

      });
      this.internalModelBackup = this.internalModel;
    }

    private changeVariableValue(value: OrganizationNodeVariable|null) {
      this.value = value;
      this.initInternalModel();
      this.change.emit({value: value, path: this.path});
    }

    onInternalChange = (node: Option<OrganizationNodeId>) => {
      this.internalModel = node;
      if(this.internalModelChanged()) {
        this.changeVariableValue(this.internalModel.map(id => {
          if(id.isPerson()) {
            return new PersonVariable(id.asPerson());
          } else if(id.isDepartment()) {
            return new DepartmentVariable(id.asDepartment());
          } else if(id.isGroup()) {
            return new GroupVariable(id.asGroup());
          } else {
            throw new Error("Node type not supported '"+id.nodeType.name+"'");
          }
        }).getOrNull());
        this.internalModelBackup = this.internalModel;
      }
    }

    private internalModelChanged() {
      return this.internalModel === null && this.internalModelBackup !== null ||
        this.internalModel !== null && this.internalModelBackup === null ||
        this.internalModel !== null && this.internalModelBackup !== null && !this.internalModel.equals(this.internalModelBackup);
    }

    updateValue(value: BusinessVariable|null) {
      if(value === null || value instanceof PersonVariable || value instanceof GroupVariable || value instanceof DepartmentVariable) {
        this.value = value;
        this.initInternalModel();
      } else {
        throw new Error("Incorrect variable type, expected organization node (Person, Department or Group) but was '"+value.simpleValueType()+"'");
      }
    }

    updateMode(readOnly: boolean, preview: boolean) {
      this.readOnly = readOnly;
      this.preview = preview;
    }

    backupInternalValue() {
      this.internalModelBackup = this.internalModel;
    }
  }

@Component({
  selector: "my-organization-node-variable-input",
  templateUrl: "./organization-node-variable-input.component.html"
})
export class OrganizationNodeVariableInputComponent implements OnInit {
  private _value!: OrganizationNodeVariable|null;
  get value(): OrganizationNodeVariable|null {return this._value;}
  @Input() set value(value: OrganizationNodeVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  @Input() persons: boolean = false;
  @Input() groups: boolean = false;
  @Input() departments: boolean = false;

  @Input() applicationId?: ApplicationId;
  @Input() fromGlobal: boolean = true;

  @Input() smallIcon: boolean = false;

  viewModel!: OrganizationNodeVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new OrganizationNodeVariableInputViewModel(this.path, this._value,
      this._readOnly,
      this._preview,
      this.persons,
      this.groups,
      this.departments,
      this.change);
  }
}
