import {Component, Input} from "@angular/core";
import {PersonSelectComponentViewModel} from "./PersonSelectComponentViewModel";

@Component({
  selector: 'my-person-select-component',
  templateUrl: './person-select-component.component.html',
  host: {
    "[class]": "'componentBody PersonSelectComponent ' + viewModel.customCssClass + ' ' + viewModel.outerShadowCssClasses",
    "[class.disabled]": "viewModel.disabled",
    "[class.preview]": "viewModel.preview",
    "[class.isTextColorSet]": "viewModel.isTextColorSet",
    "[class.validationError]": "viewModel.validationError",
    "[style]": "viewModel.outerShadowCss",
  }
})
export class PersonSelectComponentComponent {
  @Input({required:true}) viewModel!: PersonSelectComponentViewModel;

}
