<button #Button (click)="toggleSelector(); $event.stopPropagation(); false;" class="flowImportance importance_{{importance}}"
        [disabled]="disabled" [my-tooltip]="{text: importanceName, position: 'top', enabled: !showLabel}"
><span class="theme-flag-icon"><i class="mi-flag-full-a"></i><i class="mi-flag-full-b"></i></span><span *ngIf="showLabel" class="label">{{"task_importance_"+this.importance | i18n}}</span></button>
<my-drop-menu cssClass="flowImportanceSelectorMenu" [anchor]="Button" [(visible)]="opened">
  <ng-container *ngIf="opened">
    <button *ngFor="let option of options" (click)="select(option)" class="importance_{{option.value}}">
      <span class="theme-flag-icon"><i class="mi-flag-full-a"></i><i class="mi-flag-full-b"></i></span>
      {{option.name}}
      <i class="mi-check" *ngIf="option.value === importance"></i>
    </button>
  </ng-container>
</my-drop-menu>
