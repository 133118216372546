import {None, Option, RemoteOrganizationIdentifier, Some} from "@utils";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class RemoteOrganizationsSharedService {

  private loadInProgress = false;
  private remoteOrganizationsForMe: Option<Array<RemoteOrganizationIdentifier>> = None();
  private successCallbacks: Array<(remoteOrganizations: Array<RemoteOrganizationIdentifier>) => void> = [];

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  getRemoteOrganizationsForMe(onSuccess: (remoteOrganizations: Array<RemoteOrganizationIdentifier>) => void) {
    if(this.remoteOrganizationsForMe.isDefined()) {
      onSuccess(this.remoteOrganizationsForMe.get().map(RemoteOrganizationIdentifier.copy));
    } else if(this.loadInProgress) {
      this.successCallbacks.push(onSuccess);
    } else {
      this.loadInProgress = true;
      this.authenticatedHttp.get("remote-organizations/remote-organizations-for-me", (data: Array<RemoteOrganizationIdentifier>) => {
        this.remoteOrganizationsForMe = Some(data.map(RemoteOrganizationIdentifier.copy));
        this.loadInProgress = false;
        this.successCallbacks.forEach(s => s(data.map(a => RemoteOrganizationIdentifier.copy(a))));
        this.successCallbacks = [];
        onSuccess(data.map(a => RemoteOrganizationIdentifier.copy(a)));
      }, () => {
        this.loadInProgress = false;
        this.successCallbacks = [];
      })
    }
  }
}
