export class Language {

  static PL = new Language("pl");
  static PLR = new Language("plr"); // polish but right to left - for testing rtl languages support
  static EN = new Language("en");
  static ENR = new Language("enr"); // english but right to left - for testing rtl languages support
  static FR = new Language("fr");
  static DE = new Language("de");
  static ES = new Language("es");
  static JP = new Language("jp");
  static AR = new Language("ar");
  static HE = new Language("he");

  static DEFAULT = Language.PL;

  constructor(readonly name: string) {}

  static byName(language: string): Language {
    switch (language) {
      case Language.PL.name: return Language.PL;
      case Language.PLR.name: return Language.PLR;
      case Language.EN.name: return Language.EN;
      case Language.ENR.name: return Language.ENR;
      case Language.FR.name: return Language.FR;
      case Language.DE.name: return Language.DE;
      case Language.ES.name: return Language.ES;
      case Language.JP.name: return Language.JP;
      case Language.AR.name: return Language.AR;
      case Language.HE.name: return Language.HE;
      default: throw new Error("Language not supported! [" + language +"]");
    }
  }

  /** this is to test rtl languages */
  toBaseLanguage() {
    switch (this.name) {
      case Language.PLR.name: return Language.PL;
      case Language.ENR.name: return Language.EN;
      default: return this;
    }
  }

  static themeOfLanguage(language: Language): "default-ltr"|"default-rtl"|"arabic" {
    switch (language.name) {
      case Language.PLR.name: return "default-rtl";
      case Language.ENR.name: return "default-rtl";
      case Language.AR.name: return "arabic";
      case Language.HE.name: return "default-rtl";
      default: return "default-ltr";
    }
  }

  static copy(other: Language) {
    return Language.byName(other.name);
  }

  isPL() {
    return this.name === Language.PL.name;
  }
}

