import {Option, ScreenId} from "@utils";
import {
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  ScreenComponentDefinitionWithLabel
} from "@screen-common";
import {
  ModelProperty,
  OptionalBooleanProperty,
  OptionalModelProperty,
  VariableContext
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class MapComponentRef extends InputComponentRef {
  static className = "MapComponentRef";

  className() {
    return MapComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: MapComponentRef): MapComponentRef {
    return new MapComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    )
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("MapComponentRef",
    DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = MapComponentRef.DEFAULT_PROPERTIES;

}


export class MapComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MARKERS = "markers";
  static SELECTED = "selected";
  static MAP_BOUNDARIES = "mapBoundaries";
  static MAP_CENTER = "mapCenter";
  static ON_MAP_POSITION_CHANGE = "onMapPositionChange";
  static ON_SELECTED_CHANGE = "onSelectedChange";

  static MAP_CENTER_VISIBLE = "mapCenterVisible";

  static className = "MapComponentDefinition";
  className() {
    return MapComponentDefinition.className;
  }
  typeName() {
    return "Map";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,MapComponentDefinition.DEFAULT_PROPERTIES);
  }

  bordersProperties = new BordersProperties(this.properties);

  get markers(): ModelProperty {return this.properties.getModelProperty("markers", this.defaultPropertiesProvider)}
  get selected(): OptionalModelProperty {return this.properties.getOptionalModelProperty("selected", this.defaultPropertiesProvider)}
  get mapBoundaries(): OptionalModelProperty {return this.properties.getOptionalModelProperty("mapBoundaries", this.defaultPropertiesProvider)}
  get mapCenter(): OptionalModelProperty {return this.properties.getOptionalModelProperty("mapCenter", this.defaultPropertiesProvider)}
  get mapCenterVisible(): OptionalBooleanProperty {return this.properties.getOptionalBooleanProperty("mapCenterVisible", this.defaultPropertiesProvider)};

  static copy(other: MapComponentDefinition): MapComponentDefinition {
    return new MapComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    )
  }

  getModelNames(): Array<string> {
    return [MapComponentDefinition.MARKERS, MapComponentDefinition.SELECTED, MapComponentDefinition.MAP_BOUNDARIES];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("MapComponentDefinition",
    (name: string) => {switch (name) {
      case MapComponentDefinition.MAP_BOUNDARIES: return OptionalModelProperty.ofVariableName(false, VariableContext.inherit, "map_position");
      case MapComponentDefinition.MAP_CENTER_VISIBLE: return OptionalBooleanProperty.disabled(false);
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

  labelProperties = new LabelProperties(this.properties);
}
