import {md5, None, Option, Some} from "@utils";
import {Program as ESTree_Program} from "estree/index";
import {Injectable} from "@angular/core";
import {LibrariesService} from "@shared";

declare const esprima: {
  parse(script: string): ESTree_Program;
};

@Injectable({providedIn: 'root'})
export class ExpressionParser {

  validateCache: {[code: string]: Option<ESTree_Program>} = {};
  validateCacheQueue: Array<string> = [];

  constructor(readonly librariesService: LibrariesService) {}

  parse(expression:string): Promise<ESTree_Program> {
    return new Promise<ESTree_Program>((resolve, reject) => {
      this.librariesService.loadEsprima(() => {
        try {
          resolve(esprima.parse(expression));
        } catch(e) {
          reject(e);
        }
      });

    });

  }

  validateExpression(expression: string): Promise<boolean> {
    return this.parseExpression(expression).then(p => p.isDefined());
  }

  parseExpression(expression: string): Promise<Option<ESTree_Program>> {
    return new Promise<Option<ESTree_Program>>((resolve, reject) => {
      this.librariesService.loadEsprima(() => {
        resolve(this.parseExpressionImmediate(expression));
      });
    });
  }

  parseExpressionImmediate(expression: string): Option<ESTree_Program> {

    this.librariesService.ensureEsprimaLoaded();

    const sum = md5(expression);
    const fromCache = this.validateCache[sum];
    if (fromCache == undefined) {

      let parsed: Option<ESTree_Program> = None();
      try {
        parsed = Some(esprima.parse(expression));
      } catch (e) {
        parsed = None();
      }
      this.validateCache[sum] = parsed;
      this.validateCacheQueue.push(sum);
      if (this.validateCacheQueue.length > 100) {
        const toRemove = this.validateCacheQueue.shift();
        if (toRemove === undefined) {
          throw new Error("toRemove is undefined");
        } else {
          delete this.validateCache[toRemove];
        }
      }
      return parsed;
    } else {
      return fromCache;
    }
  }

}
