import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  HeaderProperties,
  LayoutsProperties,
  PaddingsProperties,
  RepeatableContextContainerDefinition,
  ScreenContainerRef
} from "@screen-common";
import {
  BooleanProperty,
  I18nTextProperty,
  ModelProperty,
  OptionalI18nTextProperty,
  OptionalNumberProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {LayoutAlign, LayoutStretch, LayoutType, ScreenComponentId} from "@screen-common";
import {i18n, I18nText, Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";

export class TableContainerRef extends ScreenContainerRef {
  static className = "TableContainerRef";
  className() {
    return TableContainerRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  get lengthEditable() {
    return this.properties.getBooleanProperty("lengthEditable", this.defaultPropertiesProvider);
  }

  get sortable() {
    return this.properties.getBooleanProperty("sortable", this.defaultPropertiesProvider);
  }

  static copy(other: TableContainerRef): TableContainerRef {
    return new TableContainerRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    )
  }

  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("TableRef", (name:string) => {
    switch (name) {
      case "lengthEditable": return BooleanProperty.ofFalse();
      case "sortable": return BooleanProperty.ofFalse();
      default: return null;
    }
  }, DefaultPropertyProvider.ref);

  override defaultPropertiesProvider = TableContainerRef.DEFAULT_PROPERTIES;
}

export class TableColumnDefinition {
  constructor(
    readonly componentRefId: ScreenComponentRefId) {}

  static copy(other: TableColumnDefinition) {
    return new TableColumnDefinition(ScreenComponentRefId.copy(other.componentRefId));
  }

}

export class TableContainerDefinition extends RepeatableContextContainerDefinition {

  static model = "model"
  static className = "TableContainerDefinition";

  className() {
    return TableContainerDefinition.className;
  }

  typeName() {
    return "Table";
  }

  static ON_CHANGE = "onChange";

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules,
    public columns: Array<TableColumnDefinition>) {
    super(id, identifier, properties, validationRules, TableContainerDefinition.DEFAULT_PROPERTIES);
  }

  get header(): OptionalI18nTextProperty {
    return this.properties.getOptionalI18nTextProperty("header", this.defaultPropertiesProvider)
  };

  get headerVisible(): BooleanProperty {
    return this.properties.getBooleanProperty("headerVisible", this.defaultPropertiesProvider)
  };

  get model(): ModelProperty {
    return this.properties.getModelProperty("model", this.defaultPropertiesProvider)
  };

  get entriesLayout(): StringProperty {
    return this.properties.getStringProperty("entriesLayout", this.defaultPropertiesProvider)
  };

  get addLabel(): I18nTextProperty {
    return this.properties.getI18nTextProperty("addLabel", this.defaultPropertiesProvider)
  };

  get entryVisible(): BooleanProperty {
    return this.properties.getBooleanProperty("entryVisible", this.defaultPropertiesProvider)
  };

  get entryEditable(): BooleanProperty {
    return this.properties.getBooleanProperty("entryEditable", this.defaultPropertiesProvider)
  };

  layoutsProperties = new LayoutsProperties("", this.properties, this.defaultPropertiesProvider);

  contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("contentTextSize", defaultProvider)
  }

  contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("contentTextColor", defaultProvider)
  }

  contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("contentTextFont", defaultProvider)
  }

  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);
  headerProperties = new HeaderProperties(this.properties);

  override getChildren(): Array<ScreenComponentRefId> {
    return this.columns.map(c => c.componentRefId);
  }

  get minEntries() {
    return this.properties.getOptionalNumberProperty("minEntries", this.defaultPropertiesProvider);
  }

  get maxEntries() {
    return this.properties.getOptionalNumberProperty("maxEntries", this.defaultPropertiesProvider);
  }


  static copy(other: TableContainerDefinition): TableContainerDefinition {
    return new TableContainerDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules),
      other.columns.map(TableColumnDefinition.copy)
    )
  }

  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("TableDefinition",
    (name: string) => {
      if (name.indexOf("column|") == 0) {
        const subName = name.substring(name.indexOf("|", name.indexOf("|") + 1) + 1, name.length);
        const columnSubName = subName.substring(subName.indexOf("|", subName.indexOf("|") + 1) + 1, subName.length);
        switch (columnSubName) {
          case "width":
            return StringProperty.of("auto");
          case "visible":
            return BooleanProperty.ofTrue();
          case "editable":
            return BooleanProperty.ofFalse();
          default:
            return null;
        }
      } else {
        switch (name) {
          case "headerVisible":
            return BooleanProperty.ofFalse();
          case "addLabel":
            return I18nTextProperty.of(I18nText.ofCurrent(i18n("screen_default_add_label")));
          case "minEntries":
            return OptionalNumberProperty.disabled(1);
          case "maxEntries":
            return OptionalNumberProperty.disabled(10);
          case "entryEditable":
            return BooleanProperty.ofTrue();
          case "entryVisible":
            return BooleanProperty.ofTrue();
          case "gapColumn":
            return OptionalStringProperty.disabled("0cm");
          case "gapRow":
            return OptionalStringProperty.disabled("0cm");
          case "layout":
            return StringProperty.of(LayoutType.horizontal.name);
          case "layoutAlign":
            return StringProperty.of(LayoutAlign.start.name);
          case "layoutStretch":
            return StringProperty.of(LayoutStretch.start.name);
          default:
            return null;
        }
      }
    },
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.containerPlain,
    DefaultPropertyProvider.containerPaddings,
    DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionBackgrounds,
    DefaultPropertyProvider.definitionSectionHeader,
    DefaultPropertyProvider.definitionComponent,
    DefaultPropertyProvider.definitionBorders);
}
