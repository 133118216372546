import {Component, Input, ViewContainerRef} from "@angular/core";
import {SwitchComponentViewModel} from "../..";
import {$$, mySetTimeoutNoAngular} from "@utils";

@Component({
  selector: 'switch-component',
  templateUrl: './switch-component.component.html',
  host: {
    "[class]": "'componentBody SwitchComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[class.readonly]": "viewModel.disabled",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class SwitchComponentComponent {
  @Input({required:true}) viewModel!: SwitchComponentViewModel;

  constructor(viewContainerRef: ViewContainerRef ) {
    mySetTimeoutNoAngular(() => {
      $$(viewContainerRef).addClass("animations");
    }, 500);
  }
}
