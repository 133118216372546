import {Injectable} from "@angular/core";
import {AggregateId, AggregateVersion, FileUri, Language, Option, PersonId, Typed} from "@utils";
import {AuthenticatedHttp, CommandResponse, commandResponseHandler, HoursFormat, PersonInfo} from "@shared-model";


export class PersonSettings {
  constructor(readonly sendEmailNotifications: boolean,
              readonly timezone: Option<string>,
              readonly language: Option<Language>,
              readonly customTheme: Option<string>) {}

  static copy(other: PersonSettings) {
    return new PersonSettings(other.sendEmailNotifications, Option.copy(other.timezone), Option.copy(other.language), Option.copy(other.customTheme, ));
  }
}

export class ChangePersonNotificationSettings {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly sendEmailNotifications: boolean) {}
}

export class ChangePersonLocaleSettings {
  constructor(readonly aggregateId: AggregateId,
              readonly timezone: Option<string>,
              readonly language: Option<Language>,
              readonly locale: Option<string>,
              readonly hoursFormat: Option<HoursFormat>,
              readonly firstDayOfWeek: Option<number>,
              readonly weekend: Option<Array<number>>) {}
}

export class ChangePersonAvatar {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly avatar: Option<FileUri>) {}
}

@Injectable({
  providedIn: "root",
})
export class PersonSettingsService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  private myPersonInfoCache?: Promise<Option<PersonInfo>>;

  changePersonNotificationSettings(personId: PersonId, expectedVersion: AggregateVersion, sendEmailNotifications: boolean,
                       onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    this.sendCommand("organization-structure/person/change-notifications-settings",
      new ChangePersonNotificationSettings(personId.id, expectedVersion, sendEmailNotifications), onSuccess, onFailure);
  }


  changePersonLocaleSettings(personId: PersonId,
                             timezone: Option<string>,
                             language: Option<Language>,
                             locale: Option<string>,
                             hoursFormat: Option<HoursFormat>,
                             firstDayOfWeek: Option<number>,
                             weekend: Option<Array<number>>,
                             onSuccess: (id: AggregateId) => void, onFailure: (exceptions: Array<string>) => void) {
    this.sendCommand("organization-structure/person/change-locale-settings",
      new ChangePersonLocaleSettings(personId.id, timezone, language, locale, hoursFormat, firstDayOfWeek, weekend), onSuccess, onFailure);
  }

  changePersonAvatar(personId: PersonId, expectedVersion: AggregateVersion, avatar: Option<FileUri>,
                     onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: (exceptions: string[]) => void) {
    this.sendCommand("organization-structure/person/change-avatar",
      new ChangePersonAvatar(personId.id, expectedVersion, avatar), onSuccess, onFailure);
  }

  private sendCommand(restUrl: string, command: any,
              onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {

    this.myPersonInfoCache = undefined;
    this.authenticatedHttp.post(restUrl, command,
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((id: AggregateId, version: AggregateVersion) => {
          // TODO FIX make it clear cache this.organizationStructureQueryService.clearCache();
          onSuccess(id, version);
        }).onFailure((exceptions: Array<string>) => {
          onFailure(exceptions);
        }).onAnyError(() => {
          onFailure([]);
        }).handle();
      });
  }

  findMyInfoPromise(): Promise<Option<PersonInfo>> {
    if(!this.myPersonInfoCache) {
      this.myPersonInfoCache = this.authenticatedHttp.getPromise<Option<PersonInfo>>("organization-structure/person/my-info")
        .then((response: Option<PersonInfo>) => Option.copy(response, PersonInfo.copy));
    }
    return this.myPersonInfoCache;
  }


}
