import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {myRequestAnimationFrameNoAngular, mySetTimeoutNoAngular, tryToFocusElement} from "@utils";

@Directive({
  selector: '[focus-on-show],[focus-on-show-init-only]'
})
export class FocusOnShowDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  private _focus: boolean|"forceMobile" = false;

  @Input("focus-on-show") set focus(value: boolean | "forceMobile") {
    this._focus = value;
    this.tryFocus();
  }

  @Input("focus-on-show-init-only") set focusInitOnly(value: boolean | "forceMobile") {
    this._focus = value;
  }

  ngOnInit() {
    this.tryFocus();
  }

  private tryFocus() {
    if (this._focus && (this.isDesktop() || this._focus === "forceMobile")) {
      tryToFocusElement(this.elementRef.nativeElement);
      myRequestAnimationFrameNoAngular(() => {
        tryToFocusElement(this.elementRef.nativeElement);
      });
      mySetTimeoutNoAngular(() => {
        tryToFocusElement(this.elementRef.nativeElement);
      });
      mySetTimeoutNoAngular(() => {
        tryToFocusElement(this.elementRef.nativeElement);
      }, 10);
      mySetTimeoutNoAngular(() => {
        tryToFocusElement(this.elementRef.nativeElement);
      }, 100); // delay is needed to make sure that element is visible, e.g. when it is within a popup that is being moved around the DOM
    }
  }

  private isDesktop() {
    return window.innerWidth > 768;
  }
}
