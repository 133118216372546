import {__, ___, AnyFlowId, i18n, LocalDateTime, mySetTimeout, None, Option, Some} from "@utils";
import {AssignableTask, TaskIdentifier, TaskModelSummary} from "./TaskModel";
import {Injectable} from "@angular/core";
import {ProcessNodeId} from "./flows.shared-service";

export class TaskTimeStatusEvent {

  constructor(readonly time: LocalDateTime,
              readonly flowId: AnyFlowId,
              readonly nodeId: ProcessNodeId,
              readonly newStatus: TaskTimeStatus) {
  }
}

export class TaskTimeStatus {
  constructor(readonly name: string) {
  }

  static onTime = new TaskTimeStatus("onTime");
  static delayed = new TaskTimeStatus("delayed");
  static atRisk = new TaskTimeStatus("atRisk");
  static atHighRisk = new TaskTimeStatus("atHighRisk");

  static byName(name: string) {
    switch (name) {
      case TaskTimeStatus.onTime.name:
        return TaskTimeStatus.onTime;
      case TaskTimeStatus.delayed.name:
        return TaskTimeStatus.delayed;
      case TaskTimeStatus.atRisk.name:
        return TaskTimeStatus.atRisk;
      case TaskTimeStatus.atHighRisk.name:
        return TaskTimeStatus.atHighRisk;
      default:
        throw new Error("Unsupported status [" + name + "]");
    }
  }

  static i18nName(status: TaskTimeStatus): string {
    switch (status.name) {
      case TaskTimeStatus.onTime.name:
        return i18n("task_time_status_onTime");
      case TaskTimeStatus.atRisk.name:
        return i18n("task_time_status_atRisk");
      case TaskTimeStatus.atHighRisk.name:
        return i18n("task_time_status_atHighRisk");
      case TaskTimeStatus.delayed.name:
        return i18n("task_time_status_delayed");
      default:
        throw new Error("Unsupported status [" + name + "]");
    }
  }

  static sortValue(status: TaskTimeStatus): string {
    switch (status.name) {
      case TaskTimeStatus.onTime.name:
        return "1";
      case TaskTimeStatus.atRisk.name:
        return "2";
      case TaskTimeStatus.atHighRisk.name:
        return "3";
      case TaskTimeStatus.delayed.name:
        return "3";
      default:
        throw new Error("Unsupported status [" + name + "]");
    }
  }

  static all = [TaskTimeStatus.onTime,
    TaskTimeStatus.atRisk,
    TaskTimeStatus.atHighRisk,
    TaskTimeStatus.delayed];
}

@Injectable({
  providedIn: "root"
})
export class TasksStatusManager {

  // private setupStrategies: { [key: string]: AtRiskStrategy[] } = {};
  private eventsQueue: TaskTimeStatusEvent[] = [];
  private timeout: number|undefined = undefined;

  taskStatuses: { [key: string]: string } = {};

  // private onStatusUpdated: Option<() => void>;

  static MAX_SAFE_TIMEOUT = 2147483647; // otherwise JS timeout will truncate duration to 0.

  constructor() {
    // this.onStatusUpdated = onStatusUpdated;
  }

  setTasks(newTasks: TaskModelSummary[]) {
    this.taskStatuses = {};
    // OLD CONFIGURABLE AT RISK
    // const newSetupIds = _.chain(newTasks)
    //   .map((t: TaskModelSummary) => t.instanceId)
    //   .filter((id: AggregateId) => this.setupStrategies[id.id] == undefined)
    //   .value();

    // if (_.isEmpty(newSetupIds)) {
    this.rebuildEventQueue(newTasks);
    // } else {
    //   this.processSetupService.loadSetupsByIds(newSetupIds, (setups: ProcessInstanceInfo[]) => {
    //     setups.forEach((s: ProcessInstanceInfo) => {
    //       this.setupStrategies[s.id.id] = s.setup.get().atRiskStrategies;
    //       this.rebuildEventQueue(newTasks);
    //     });
    //   });
    // }
  }

  calculateTaskStatus(started: Option<LocalDateTime>, deadline: Option<LocalDateTime>, estimatedDurationSeconds: Option<number>): TaskTimeStatus {
    const now = LocalDateTime.now();

    const delayed = this.computeDelayedTimestamp(deadline).exists(t => t.isBefore(now));
    const atHighRisk = this.computeAtHighRiskTimestamp(started, deadline, estimatedDurationSeconds).exists(t => t.isBefore(now));
    const atRisk = this.computeAtRiskTimestamp(started, deadline, estimatedDurationSeconds).exists(t => t.isBefore(now));

    if(delayed) {
      return TaskTimeStatus.delayed;
    } else if(atHighRisk) {
      return TaskTimeStatus.atHighRisk;
    } else if(atRisk) {
      return TaskTimeStatus.atRisk;
    } else {
      return TaskTimeStatus.onTime;
    }

  }

  statusOf(taskId: TaskIdentifier) {
    const taskStatus = this.taskStatuses[taskId.flowIdUnwrapped().urlSerialized() + "-" + taskId.nodeId];
    if (taskStatus) {
      return TaskTimeStatus.byName(taskStatus);
    } else {
      return TaskTimeStatus.onTime;
    }
  }

  rebuildEventQueue(tasks: TaskModelSummary[]) {
    const tasksEvents = this.getEvents(tasks);
    this.eventsQueue = tasksEvents.sort((event1: TaskTimeStatusEvent, event2: TaskTimeStatusEvent) => event1.time.differenceMillis(event2.time));
    this.updateStatusQueue();
  }

  private getEvents(tasks: TaskModelSummary[]): TaskTimeStatusEvent[] {
    return ___(tasks)
      .filter((task: TaskModelSummary) => task.deadline.isDefined()) // && task.deadline.value.isInFuture())
      .map((task: TaskModelSummary) => this.statusEventsForTask(task))
      .flatMap(t => t)
      .value();
  }

  private statusEventsForTask(task: TaskModelSummary): TaskTimeStatusEvent[] {
    const delayed = this.computeDelayedTimestamp(task.deadline).map(t => new TaskTimeStatusEvent(t, task.flowIdUnwrapped(), task.nodeId, TaskTimeStatus.delayed));
    const atHighRisk = this.computeAtHighRiskTimestamp(task.started, task.deadline, task.estimatedDurationSeconds).map(t => new TaskTimeStatusEvent(t, task.flowIdUnwrapped(), task.nodeId, TaskTimeStatus.atHighRisk));
    const atRisk = this.computeAtRiskTimestamp(task.started, task.deadline, task.estimatedDurationSeconds).map(t => new TaskTimeStatusEvent(t, task.flowIdUnwrapped(), task.nodeId, TaskTimeStatus.atRisk));

    return [delayed, atHighRisk, atRisk].filter(e => e.isDefined()).map(e => e.get());
  }

  private computeDelayedTimestamp(deadline: Option<LocalDateTime>): Option<LocalDateTime> {
    if (deadline.isEmpty()) {
      return None();
    } else {
      return Some(deadline.get());
    }
  }

  //TODO minusMillis does not take into account organization calendar
  private computeAtHighRiskTimestamp(started: Option<LocalDateTime>, deadline: Option<LocalDateTime>, estimatedDurationSeconds: Option<number>): Option<LocalDateTime> {
    if (deadline.isEmpty() || estimatedDurationSeconds.isEmpty()) {
      return None();
    } else if (started.isEmpty()) {
      return Some(deadline.get().minusMillis(estimatedDurationSeconds.get() * 1000 * 0.7));
    } else {
      return Some(deadline.get().minusMillis(estimatedDurationSeconds.get() * 1000 * 0.1));
    }
  }

  //TODO minusMillis does not take into account organization calendar
  private computeAtRiskTimestamp(started: Option<LocalDateTime>, deadline: Option<LocalDateTime>, estimatedDurationSeconds: Option<number>): Option<LocalDateTime> {
    if (deadline.isEmpty() || estimatedDurationSeconds.isEmpty()) {
      return None();
    } else if (started.isEmpty()) {
      return Some(deadline.get().minusMillis(estimatedDurationSeconds.get() * 1000 * 1.1));
    } else {
      return Some(deadline.get().minusMillis(estimatedDurationSeconds.get() * 1000 * 0.3));
    }

    // const strategies = this.setupStrategies[task.instanceId.id];
    //
    // if (!strategies) {
    //   return None();
    // }
    //
    // const atRiskDatesOptions: Option<LocalDateTime>[] = strategies.map((strategy: AtRiskStrategy) => {
    //
    //   if (task.started.isEmpty() || task.averageRealizationDurationMillis === 0) {
    //     return None();
    //   } else {
    //     return Some(task.started.value.plusMillis(task.averageRealizationDurationMillis * (strategy.value.value / 100)));
    //   }
    //
    //
    //   //  DO NOT DELETE - this is sample code that uses configurable coloring.
    //   // switch(strategy.strategyType.name) {
    //   //   case AtRiskStrategyType.fromAppearanceToSla.name:
    //   //     return Some(task.created.plusMillis(task.deadline.value.differenceMillis(task.created) * (strategy.value.value / 100)));
    //   //   case AtRiskStrategyType.remainingToAverage.name:
    //   //     if (task.averageRealizationDurationMillis === 0) {
    //   //       return None();
    //   //     }
    //   //     return Some(task.created.plusMillis(task.deadline.value.differenceMillis(task.created) - task.averageRealizationDurationMillis * (strategy.value.value / 100)));
    //   //   case AtRiskStrategyType.fromStartToAverage.name:
    //   //     if (task.started.isEmpty() || task.averageRealizationDurationMillis === 0) {
    //   //       return None();
    //   //     }
    //   //     return Some(task.started.value.plusMillis(task.averageRealizationDurationMillis * (strategy.value.value / 100)));
    //   // }
    // });
    //
    // const atRiskDates = atRiskDatesOptions
    //   .filter((dateOption: Option<LocalDateTime>) => dateOption.isDefined())
    //   .map((dateOption: Option<LocalDateTime>) => dateOption.value);
    //
    // const date = _.isEmpty(atRiskDates) ? None() : Some(_.min(atRiskDates, (date: LocalDateTime) => date.asMillis()));
    //
    // return date;
  }


  private applyEvent(event: TaskTimeStatusEvent) {
    this.taskStatuses[event.flowId.urlSerialized() + "-" + event.nodeId] = event.newStatus.name;
  }

  private updateStatusQueue() {
    if (this.timeout !== undefined) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    if (this.eventsQueue.length > 0) {

      let getNext = true;

      while (getNext) {
        let next: TaskTimeStatusEvent = this.eventsQueue[0];
        let timeDifference = next.time.differenceMillis(LocalDateTime.now());
        if (timeDifference > 0) {
          this.timeout = mySetTimeout(() => this.updateStatusQueue(), Math.min(timeDifference, TasksStatusManager.MAX_SAFE_TIMEOUT));
          getNext = false;
        } else {
          this.applyEvent(next);
          this.eventsQueue = __(this.eventsQueue).rest();
          getNext = this.eventsQueue.length > 0;
        }
      }
      // this.onStatusUpdated.forEach(f => f());
    }
  }

}
