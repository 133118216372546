import {getElementWidth, getREMSize, myRequestAnimationFrame, myRequestAnimationFrameNoAngular} from "@utils";

export interface InputWithUnitViewModel {
  unitVisible: boolean;
  uncoveredAndVisible: boolean;
  textAlignCenter: boolean;
}

export class UnitPaddingController {

  private visible = true;

  constructor(readonly viewModel: InputWithUnitViewModel,
              readonly $container: HTMLElement) {
  }

  start() {
    if (this.viewModel.unitVisible && this.viewModel.uncoveredAndVisible) {
      myRequestAnimationFrameNoAngular(() => {
        this.adjustUnitPadding();
      });
    }
  }


  adjustUnitPadding() {
    if(this.viewModel.uncoveredAndVisible) {

      const $input = <HTMLElement>this.$container.querySelector(".input");

      if($input === null) {
        throw new Error("No input");
      } else if (!this.viewModel.textAlignCenter) {
        const unitNameElement = this.$container.querySelector(".unitName");
        const paddingRight = $input.getAttribute("padding-right");
        if(unitNameElement && paddingRight) {
          const unitWidthRem = getElementWidth(unitNameElement) / getREMSize();
          $input.style.paddingRight = "calc("+paddingRight+" + "+unitWidthRem+"rem)";
        } else if (paddingRight) {
          $input.style.paddingRight = paddingRight;
        }

      }

    }
  }

  onValueChanged() {
    this.initAdjust();
  }

  initAdjust() {
    if(this.viewModel.unitVisible && this.viewModel.uncoveredAndVisible) {
      myRequestAnimationFrameNoAngular(() => {
        this.adjustUnitPadding();
      });
    }
  }

  destroy() {
    this.visible = false;
  }

}
