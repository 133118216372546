<div class="simplePage stylesPage">

  <h2>Components gallery</h2>

  <div class="component">
    <div class="name">Application icon</div>
    <div class="class">theme-application-icon</div>
    <pre class="html"><![CDATA[
      <div class="theme-application-icon-lage theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon-medium theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon-small theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-application-icon-large theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon-medium theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
      <div class="theme-application-icon-small theme-application-icon10">
        <i class="mi-calendar"></i>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Department icon, Group icon</div>
    <div class="class">theme-department-icon</div>
    <pre class="html"><![CDATA[
      <div class="theme-department-icon">
        <i class="mi-home"></i>
      </div>
      <div class="theme-department-icon-small">
        <i class="mi-home"></i>
      </div>
       <div class="theme-group-icon">
        <i class="mi-user-group"></i>
      </div>
      <div class="theme-group-icon-small">
        <i class="mi-user-group"></i>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-department-icon">
        <i class="mi-home"></i>
      </div>
      <div class="theme-department-icon-small">
        <i class="mi-home"></i>
      </div>
      <div class="theme-group-icon">
        <i class="mi-user-group"></i>
      </div>
      <div class="theme-group-icon-small">
        <i class="mi-user-group"></i>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Directory icon, File icon</div>
    <div class="class">theme-directory-icon, theme-file-icon, theme-attach-icon</div>
    <pre class="html"><![CDATA[
      <div class="theme-directory-icon">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-small">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-medium">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-large">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-file-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-large">
        <i class="mi-file"></i>
      </div>

      <div class="theme-attach-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon-large">
        <i class="mi-file"></i>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-directory-icon">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-small">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-medium">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-directory-icon-large">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-file-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-file-icon-large">
        <i class="mi-file"></i>
      </div>

      <div class="theme-attach-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-attach-icon-large">
        <i class="mi-file"></i>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Process icon, Screen icon, Report icon, Functions icon</div>
    <div class="class">theme-directory-icon, theme-file-icon</div>
    <pre class="html"><![CDATA[
      <div class="theme-process-icon">
        <i class="mi-right"></i>
      </div>
      <div class="theme-process-icon-small">
        <i class="mi-right"></i>
      </div>
      <div class="theme-process-icon theme-initials-icon">
        OF
      </div>
      <div class="theme-process-icon-small theme-initials-icon">
        M
      </div>
      <div class="theme-screen-icon">
        <i class="mi-stop"></i>
      </div>
      <div class="theme-screen-icon-small">
        <i class="mi-stop"></i>
      </div>
      <div class="theme-report-icon">
        <i class="mi-barchart"></i>
      </div>
      <div class="theme-report-icon-small">
        <i class="mi-barchart"></i>
      </div>
      <div class="theme-functions-icon">
        <i class="mi-calculator"></i>
      </div>
      <div class="theme-functions-icon-small">
        <i class="mi-calculator"></i>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-process-icon">
        <i class="mi-right"></i>
      </div>
      <div class="theme-process-icon-small">
        <i class="mi-right"></i>
      </div>
      <div class="theme-process-icon theme-initials-icon">
        OF
      </div>
      <div class="theme-process-icon-small theme-initials-icon">
        M
      </div>
      <div class="theme-screen-icon">
        <i class="mi-stop"></i>
      </div>
      <div class="theme-screen-icon-small">
        <i class="mi-stop"></i>
      </div>
      <div class="theme-report-icon">
        <i class="mi-barchart"></i>
      </div>
      <div class="theme-report-icon-small">
        <i class="mi-barchart"></i>
      </div>
      <div class="theme-functions-icon">
        <i class="mi-calculator"></i>
      </div>
      <div class="theme-functions-icon-small">
        <i class="mi-calculator"></i>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Error icon, Warning icon, OK icon, Info icon</div>
    <div class="class">theme-error-icon, theme-warning-icon, theme-ok-icon, theme-info-icon</div>
    <pre class="html"><![CDATA[
      <div class="theme-error-icon">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-error-icon-small">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-error-icon-medium">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-error-icon-large">
        <i class="mi-folder"></i>
      </div>
      <div class="theme-warning-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-warning-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-warning-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-warning-icon-large">
        <i class="mi-file"></i>
      </div>

      <div class="theme-ok-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-ok-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-ok-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-ok-icon-large">
        <i class="mi-file"></i>
      </div>
      <div class="theme-info-icon-small">
        <i class="mi-file"></i>
      </div>
      <div class="theme-info-icon-medium">
        <i class="mi-file"></i>
      </div>
      <div class="theme-info-icon">
        <i class="mi-file"></i>
      </div>
      <div class="theme-info-icon-large">
        <i class="mi-file"></i>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-error-icon">
        <i class="mi-info"></i>
      </div>
      <div class="theme-error-icon-small">
        <i class="mi-info"></i>
      </div>
      <div class="theme-error-icon-medium">
        <i class="mi-info"></i>
      </div>
      <div class="theme-error-icon-large">
        <i class="mi-info"></i>
      </div>
      <div class="theme-warning-icon">
        <i class="mi-info"></i>
      </div>
      <div class="theme-warning-icon-small">
        <i class="mi-info"></i>
      </div>
      <div class="theme-warning-icon-medium">
        <i class="mi-info"></i>
      </div>
      <div class="theme-warning-icon-large">
        <i class="mi-info"></i>
      </div>

      <div class="theme-ok-icon-small">
        <i class="mi-info"></i>
      </div>
      <div class="theme-ok-icon-medium">
        <i class="mi-info"></i>
      </div>
      <div class="theme-ok-icon">
        <i class="mi-info"></i>
      </div>
      <div class="theme-ok-icon-large">
        <i class="mi-info"></i>
      </div>
      <div class="theme-info-icon-small">
        <i class="mi-info"></i>
      </div>
      <div class="theme-info-icon-medium">
        <i class="mi-info"></i>
      </div>
      <div class="theme-info-icon">
        <i class="mi-info"></i>
      </div>
      <div class="theme-info-icon-large">
        <i class="mi-info"></i>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Confirm button</div>
    <div class="class">theme-button-confirm</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-confirm">Confirm</button>
      <button class="theme-button-confirm" disabled>Confirm (disabled)</button>
      <button class="theme-button-confirm-small">Confirm</button>
      <button class="theme-button-confirm-small" disabled>Confirm (disabled)</button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-confirm">Confirm</button>
      <button class="theme-button-confirm" disabled>Confirm (disabled)</button>
      <button class="theme-button-confirm-small">Confirm</button>
      <button class="theme-button-confirm-small" disabled>Confirm (disabled)</button>
    </div>
  </div>

  <div class="component">
    <div class="name">Confirm button icon</div>
    <div class="class">theme-button-confirm-with-icon</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-confirm-icon-only-large"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-medium"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-small"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-smallest"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-with-icon"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-medium"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-medium" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-small"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-small" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-smallest"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-smallest" disabled><i class="mi-check"></i>Confirm (disabled)</button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-confirm-icon-only-large"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-medium"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-small"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-icon-only-smallest"><i class="mi-check"></i></button>
      <button class="theme-button-confirm-with-icon"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-medium"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-medium" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-small"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-small" disabled><i class="mi-check"></i>Confirm (disabled)</button>
      <button class="theme-button-confirm-with-icon-smallest"><i class="mi-check"></i>Confirm</button>
      <button class="theme-button-confirm-with-icon-smallest" disabled><i class="mi-check"></i>Confirm (disabled)</button>
    </div>
  </div>

  <div class="component">
    <div class="name">Cancel button</div>
    <div class="class">theme-button-cancel</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-cancel">Cancel</button>
      <button class="theme-button-cancel-medium">Cancel</button>
      <button class="theme-button-cancel-small">Cancel</button>
      <button class="theme-button-cancel-icon"><i class="mi-delete"></i>Cancel</button>
      <button class="theme-button-cancel-icon-medium"><i class="mi-delete"></i>Cancel</button>
      <button class="theme-button-cancel-icon-small"><i class="mi-delete"></i>Cancel</button>
    ]]></pre>

    <div class="example">

      <button class="theme-button-cancel">Cancel</button>
      <button class="theme-button-cancel-medium">Cancel</button>
      <button class="theme-button-cancel-small">Cancel</button>
      <button class="theme-button-cancel-icon"><i class="mi-delete"></i>Cancel</button>
      <button class="theme-button-cancel-icon-medium"><i class="mi-delete"></i>Cancel</button>
      <button class="theme-button-cancel-icon-small"><i class="mi-delete"></i>Cancel</button>

    </div>

  </div>


  <div class="component">
    <div class="name">Link button</div>
    <div class="class">theme-button-link</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-link">Alternative</button>
      <button class="theme-button-link-small">Alternative</button>
      <button class="theme-button-link-icon"><i class="mi-rewind"></i>Cancel</button>
      <button class="theme-button-link-icon-small"><i class="mi-rewind"></i>Cancel</button>
      <button class="theme-button-link-icon-end">Forward<i class="mi-right"></i></button>
      <button class="theme-button-link-icon-end-small">Forward<i class="mi-right"></i></button>
    ]]></pre>

    <div class="example">

      <button class="theme-button-link">Alternative</button>
      <button class="theme-button-link-small">Alternative</button>
      <button class="theme-button-link-icon"><i class="mi-rewind"></i>Cancel</button>
      <button class="theme-button-link-icon-small"><i class="mi-rewind"></i>Cancel</button>
      <button class="theme-button-link-icon-end">Forward<i class="mi-right"></i></button>
      <button class="theme-button-link-icon-end-small">Forward<i class="mi-right"></i></button>

    </div>

  </div>

  <div class="component">
    <div class="name">Flat button</div>
    <div class="class">theme-button-flat</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-flat">Action</button>
      <button class="theme-button-flat-medium">Action</button>
      <button class="theme-button-flat-small">Action</button>
      <button class="theme-button-flat-icon"><i class="mi-delete"></i>Action</button>
      <button class="theme-button-flat-icon-medium"><i class="mi-delete"></i>Action</button>
      <button class="theme-button-flat-icon-small"><i class="mi-delete"></i>Action</button>
    ]]></pre>

    <div class="example">

      <button class="theme-button-flat">Action</button>
      <button class="theme-button-flat-medium">Action</button>
      <button class="theme-button-flat-small">Action</button>
      <button class="theme-button-flat-icon"><i class="mi-delete"></i>Action</button>
      <button class="theme-button-flat-icon-medium"><i class="mi-delete"></i>Action</button>
      <button class="theme-button-flat-icon-small"><i class="mi-delete"></i>Action</button>

    </div>

  </div>

  <div class="component">
    <div class="name">Close button</div>
    <div class="class">theme-button-close</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-close"><i class="mi-delete"></i></button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-close"><i class="mi-delete"></i></button>
    </div>

  </div>

  <div class="component">
    <div class="name">Action button - flat</div>
    <div class="class">theme-button-icon-action-flat</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-icon-action-flat"><i class="mi-expand"></i></button>
      <button class="theme-button-icon-action-flat-small"><i class="mi-expand"></i></button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-icon-action-flat"><i class="mi-expand"></i></button>
      <button class="theme-button-icon-action-flat-small"><i class="mi-expand"></i></button>
    </div>

  </div>


  <div class="component">
    <div class="name">Expand arrow</div>
    <div class="class">theme-expand-arrow</div>
    <pre class="html"><![CDATA[
      <i class="mi-navigate-down theme-expand-arrow-xsmall"></i>
      <i class="mi-navigate-down theme-expand-arrow-xsmall theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-small"></i>
      <i class="mi-navigate-down theme-expand-arrow-small theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow"></i>
      <i class="mi-navigate-down theme-expand-arrow theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-large"></i>
      <i class="mi-navigate-down theme-expand-arrow-large theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-large-main-color"></i>
      <i class="mi-navigate-down theme-expand-arrow-large-main-color theme-expanded"></i>
    ]]></pre>
    <div class="example">
      <i class="mi-navigate-down theme-expand-arrow-xsmall"></i>
      <i class="mi-navigate-down theme-expand-arrow-xsmall theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-small"></i>
      <i class="mi-navigate-down theme-expand-arrow-small theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow"></i>
      <i class="mi-navigate-down theme-expand-arrow theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-large"></i>
      <i class="mi-navigate-down theme-expand-arrow-large theme-expanded"></i>
      <i class="mi-navigate-down theme-expand-arrow-large-main-color"></i>
      <i class="mi-navigate-down theme-expand-arrow-large-main-color theme-expanded"></i>
    </div>
  </div>


  <div class="component">
    <div class="name">Option button</div>
    <div class="class">theme-button-option</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-option"><i class="mi-key"></i></button>
      <div class="theme-action-button-separator"></div>
      <button class="theme-button-option theme-active"><i class="mi-key"></i></button>
      <div class="theme-action-button-separator"></div>
      <button class="theme-button-option-icon-label"><i class="mi-key"></i>Open</button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-option"><i class="mi-key"></i></button>
      <button class="theme-button-option theme-active"><i class="mi-key"></i></button>
      <div class="theme-action-button-separator"></div>
      <button class="theme-button-option-icon-label"><i class="mi-key"></i>Open</button>
    </div>


  </div>

  <div class="component">
    <div class="name">Add main item button</div>
    <div class="class">theme-button-add-main-item</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-add-main-item"><i class="mi-plus"></i></button>
      <button class="theme-button-add-main-item theme-active"><i class="mi-plus"></i></button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-add-main-item"><i class="mi-plus"></i></button>
      <button class="theme-button-add-main-item theme-active"><i class="mi-plus"></i></button>
    </div>
  </div>

  <div class="component">
    <div class="name">Add item button</div>
    <div class="class">theme-button-add-item</div>
    <pre class="html"><![CDATA[
      <button class="theme-button-add-item"><i class="mi-plus"></i></button>
      <button class="theme-button-add-item theme-active"><i class="mi-plus"></i></button>
    ]]></pre>
    <div class="example">
      <button class="theme-button-add-item"><i class="mi-plus"></i></button>
      <button class="theme-button-add-item theme-active"><i class="mi-plus"></i></button>
    </div>
  </div>

  <div class="component">
    <div class="name">Tabs Switch</div>
    <div class="class">theme-tabs-switch</div>
    <pre class="html"><![CDATA[
      <div class="theme-tabs-switch">
        <a class="theme-tab theme-active" href="#">Tab A</a>
        <button class="theme-tab">Tab B</button>
        <button class="theme-tab"><i class="mi-cases"></i>Tab C</button>
        <button class="theme-tab theme-icon-only"><i class="mi-cases"></i></button>
      </div>
    ]]></pre>
    <div class="example">

      <div class="theme-tabs-switch">
        <a class="theme-tab theme-active" href="#">Tab A</a>
        <button class="theme-tab">Tab B</button>
        <button class="theme-tab"><i class="mi-cases"></i>Tab C</button>
        <button class="theme-tab theme-icon-only"><i class="mi-cases"></i></button>
      </div>

    </div>


  </div>


  <div class="component">
    <div class="name">Tabs</div>
    <div class="class">my-tabs</div>
    <pre class="html"><![CDATA[
        <div class="theme-tabs">
        <a class="theme-tab theme-active" href="#">Tab A</a>
        <button class="theme-tab">Tab B</button>
          <button class="theme-tab"><i class="mi-cases"></i>Tab C</button>
        <button class="theme-tab theme-icon-only"><i class="mi-cases"></i></button>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-tabs">
        <a class="theme-tab theme-active" href="#">Tab A</a>
        <button class="theme-tab">Tab B</button>
        <button class="theme-tab"><i class="mi-cases"></i>Tab C</button>
        <button class="theme-tab theme-icon-only"><i class="mi-cases"></i></button>
      </div>
    </div>


  </div>


  <div class="component">
    <div class="name">Count badge</div>
    <div class="class">theme-count-badge</div>
    <pre class="html"><![CDATA[
      <div class="theme-count-badge-small">7</div>
      <div class="theme-count-badge">7</div>
      <div class="theme-count-badge theme-ok">10</div>
      <div class="theme-count-badge theme-warning">40</div>
      <div class="theme-count-badge-separator"></div>
      <div class="theme-count-badge theme-error">100</div>
      <div class="theme-count-badge theme-info">1000</div>
    ]]></pre>
    <div class="example">

      <div class="theme-count-badge-small">7</div>
      <div class="theme-count-badge">7</div>
      <div class="theme-count-badge theme-ok">10</div>
      <div class="theme-count-badge theme-warning">40</div>
      <div class="theme-count-badge-separator"></div>
      <div class="theme-count-badge theme-error">100</div>
      <div class="theme-count-badge theme-info">1000</div>

    </div>


  </div>

  <div class="component">
    <div class="name">Label badge</div>
    <div class="class">theme-label-badge</div>
    <pre class="html"><![CDATA[
      <div class="theme-label-badge">default</div>
      <div class="theme-label-badge theme-system-label">system</div>
      <div class="theme-label-badge theme-color-1">first</div>
      <div class="theme-label-badge theme-color-4">forth</div>
      <div class="theme-label-badge theme-color-16 theme-deletable">sixteenth<button class="theme-danger" (click)="$event.stopPropagation(); false;" (pointerdown)="$event.stopPropagation()"><i class="mi-close"></i></button></div>
    ]]></pre>
    <div class="example">
      <div class="theme-label-badge">default</div>
      <div class="theme-label-badge theme-system-label">system</div>
      <div class="theme-label-badge theme-color-1">first</div>
      <div class="theme-label-badge theme-color-4">forth</div>
      <div class="theme-label-badge theme-color-16 theme-deletable">sixteenth<button class="theme-danger" (click)="$event.stopPropagation(); false;" (pointerdown)="$event.stopPropagation()"><i class="mi-close"></i></button></div>
    </div>
  </div>

  <div class="component">
    <div class="name">Search bar</div>
    <div class="class">theme-search-bar</div>
    <pre class="html"><![CDATA[
      <div class="theme-search-bar">
        <i class="mi-search theme-search-icon"></i>
        <input type="text" class="theme-search-query" placeholder="Search"/>
      </div>

      <div class="theme-search-bar theme-flat">
        <i class="mi-search theme-search-icon"></i>
        <input type="text" class="theme-search-query" placeholder="Search"/>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-search-bar">
        <i class="mi-search theme-search-icon"></i>
        <input type="text" class="theme-search-query" placeholder="Search"/>
      </div>

      <div class="theme-search-bar theme-flat">
        <i class="mi-search theme-search-icon"></i>
        <input type="text" class="theme-search-query" placeholder="Search"/>
      </div>
    </div>

  </div>

  <div class="component">
    <div class="name">Search bar</div>
    <div class="class">my-search-bar</div>
    <pre class="html"><![CDATA[
      <my-search-bar>
        <button class="theme-search-option"><i class="mi-settings-sliders"></i><div class="theme-button-highlight"></div>Filtry</button>
      </my-search-bar>

      <my-search-bar [flat]="true">
        <button class="theme-search-option"><i class="mi-settings-sliders"></i><div class="theme-button-highlight"></div>Filtry</button>
      </my-search-bar>
    ]]></pre>

    <div class="example">
      <my-search-bar>
        <button class="theme-search-option"><i class="mi-settings-sliders"></i><div class="theme-button-highlight"></div>Filtry</button>
      </my-search-bar>

      <my-search-bar [flat]="true">
        <button class="theme-search-option"><i class="mi-settings-sliders"></i><div class="theme-button-highlight"></div>Filtry</button>
      </my-search-bar>
    </div>
  </div>

  <div class="component">
    <div class="name">Box</div>
    <div class="class">theme-modal</div>
    <pre class="html"><![CDATA[
      <div class="theme-modal"></div>
    ]]></pre>
    <div class="example">
      <div class="theme-modal" style="min-width: 8.75rem; min-height: 6.125rem;">Hello</div>
    </div>
  </div>

  <div class="component">
    <div class="name">Box</div>
    <div class="class">theme-box</div>
    <pre class="html"><![CDATA[
      <div class="theme-box"></div>
    ]]></pre>
    <div class="example">
      <div class="theme-box" style="min-width: 8.75rem; min-height: 6.125rem;">Hello</div>
    </div>
  </div>

  <div class="component">
    <div class="name">List box</div>
    <div class="class">theme-list-box</div>
    <pre class="html"><![CDATA[
      <div class="theme-list-box">
        <div class="theme-list-box-item">John</div>
        <div class="theme-list-box-item">Jerry</div>
        <div class="theme-list-box-item">Top</div>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-list-box" style="min-width: 13.125rem;">
        <div class="theme-list-box-item">John</div>
        <div class="theme-list-box-item">Jerry</div>
        <div class="theme-list-box-item">Top</div>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Form title</div>
    <div class="class">theme-form-title</div>
    <pre class="html"><![CDATA[
      <div class="theme-form-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
    ]]></pre>
    <div class="example">
      <div class="theme-form-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
    </div>
  </div>

  <div class="component">
    <div class="name">Form info</div>
    <div class="class">theme-form-info</div>
    <pre class="html"><![CDATA[
      <div class="theme-form-info">Etiam egestas leo at nunc eleifend...</div>
    ]]></pre>
    <div class="example">
      <div class="theme-form-info">Etiam egestas leo at nunc eleifend, in ullamcorper sem ultricies. Aliquam tellus erat, ultrices in tortor et,
        euismod lacinia nisl. Fusce ut tortor at nisl tempus sollicitudin.
      </div>

    </div>
  </div>

  <div class="component">
    <div class="name">Form label</div>
    <div class="class">theme-form-label</div>
    <pre class="html"><![CDATA[
      <div class="theme-form-info">Imię i nazwisko:</div>
    ]]></pre>
    <div class="example">
      <div class="theme-form-info">Imię i nazwisko:</div>
    </div>
  </div>

  <div class="component">
    <div class="name">Form input</div>
    <div class="class">theme-input</div>
    <pre class="html"><![CDATA[
       <input type="text" class="theme-input" placeholder="Imię i nazwisko"/>
      <div class="theme-input-with-icon">
        <i class="mi-mail"></i>
        <input type="text" placeholder="Imię i nazwisko"/>
      </div>
      <div class="theme-input-box-with-icon">
        <i class="mi-mail"></i>
        <input type="text" placeholder="Imię i nazwisko"/>
      </div>
    ]]></pre>
    <div class="example">
      <input type="text" class="theme-input" placeholder="Imię i nazwisko"/>
      <div class="theme-input-with-icon">
        <i class="mi-mail"></i>
        <input type="text" placeholder="Imię i nazwisko"/>
      </div>
      <div class="theme-input-box-with-icon">
        <i class="mi-mail"></i>
        <input type="text" placeholder="Imię i nazwisko"/>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Large input box</div>
    <div class="class">theme-input-box-with-color-icon-large</div>
    <pre class="html"><![CDATA[
      <div class="theme-input-box-with-icon-large">
        <i class="mi-mail"></i>
        <input type="email" class="emailInput"/>
      </div>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-mail"></i>
        <input type="email" class="emailInput"/>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-input-box-with-icon-large">
        <i class="mi-mail"></i>
        <input type="email" class="emailInput"/>
      </div>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-mail"></i>
        <input type="email" class="emailInput"/>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Form separator</div>
    <div class="class">theme-content-horizontal-separator</div>
    <pre class="html"><![CDATA[
      <hr class="theme-content-horizontal-separator-small-margin"/>
    ]]></pre>
    <div class="example">
      <hr class="theme-content-horizontal-separator-small-margin"/>

    </div>
  </div>


  <div class="component">
    <div class="name">Drop menu</div>
    <div class="class">theme-drop-menu</div>
    <pre class="html"><![CDATA[
      <div class="theme-drop-menu">
        <div class="theme-drop-menu-entry">Polska</div>
        <div class="theme-drop-menu-entry">Francja</div>
        <div class="theme-drop-menu-entry">USA</div>
        <div class="theme-drop-menu-entry-strong">Kanada</div>
        <hr class="theme-drop-menu-separator"/>
        <div class="theme-drop-menu-entry-strong">Włochy</div>
        <hr class="theme-drop-menu-separator"/>
        <div class="theme-drop-menu-entry">Niemcy</div>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-drop-menu" style="min-width: 6.125rem">
        <div class="theme-drop-menu-entry">Polska</div>
        <div class="theme-drop-menu-entry">Francja</div>
        <div class="theme-drop-menu-entry">USA</div>
        <div class="theme-drop-menu-entry-strong">Kanada</div>
        <hr class="theme-drop-menu-separator"/>
        <div class="theme-drop-menu-entry-strong">Włochy</div>
        <hr class="theme-drop-menu-separator"/>
        <div class="theme-drop-menu-entry">Niemcy</div>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Selector drop menu</div>
    <div class="class">theme-selector-drop-menu</div>
    <pre class="html"><![CDATA[
      <div class="theme-drop-menu">

        <div class="theme-selector-drop-menu">
          <div class="theme-drop-menu-filter">
            <i class="mi-search"></i>
            <input type="text" placeholder="Search..."/>
          </div>
          <hr class="theme-drop-menu-separator"/>
          <button class="theme-drop-menu-entry">
            <i class="mi-apps"></i> Applications
            <button class="theme-selector">
              <i class="mi-check"></i>
            </button>
          </button>
          <hr class="theme-drop-menu-separator"/>
          <button class="theme-drop-menu-entry">
            <i class="mi-apps"></i> Groups
            <button class="theme-selector">
              <i class="mi-check"></i>
            </button>
          </button>
        </div>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-selector-drop-menu">
        <div class="theme-drop-menu-filter">
          <i class="mi-search"></i>
          <input type="text" placeholder="Search..."/>
        </div>
        <hr class="theme-drop-menu-separator"/>
        <button class="theme-drop-menu-entry">
          <span class="theme-name">Applications</span>
          <button class="theme-selector">
            <i class="mi-check"></i>
          </button>
        </button>
        <hr class="theme-drop-menu-separator"/>
        <button class="theme-drop-menu-entry">
          <span class="theme-name">Groups</span>
          <button class="theme-selector">
            <i class="mi-check"></i>
          </button>
        </button>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Toggle Button</div>
    <div class="class">theme-toggle-button</div>
    <pre class="html"><![CDATA[
      <button class="theme-toggle-button">Some option</button>
      <button class="theme-toggle-button theme-active">Some option</button>
      <button class="theme-toggle-button-outline">Some option</button>
      <button class="theme-toggle-button-outline theme-active">Some option</button>
    ]]></pre>
    <div class="example">
      <button class="theme-toggle-button">Some option</button>
      <button class="theme-toggle-button theme-active">Some option</button>
      <button class="theme-toggle-button-outline">Some option</button>
      <button class="theme-toggle-button-outline theme-active">Some option</button>
    </div>
  </div>

  <div class="component">
    <div class="name">Bottom space</div>
    <div class="class">theme-bottom-space</div>
    <pre class="html"><![CDATA[
      <div class="theme-bottom-space"></div>
    ]]></pre>
    <div class="example">
      <div class="theme-bottom-space"></div>
    </div>
  </div>

  <div class="component">
    <div class="name">Page title</div>
    <div class="class">theme-default-page-title</div>
    <pre class="html"><![CDATA[
      <div class="theme-default-page-title">Page title</div>
    ]]></pre>
    <div class="example">
      <div class="theme-default-page-title">Page title</div>
    </div>
  </div>

  <div class="component">
    <div class="name">Sub title</div>
    <div class="class">theme-sub-title</div>
    <pre class="html"><![CDATA[
      <div class="theme-sub-title">Sub title</div>
    ]]></pre>
    <div class="example">
      <div class="theme-sub-title">Sub title</div>
    </div>
  </div>


  <div class="component">
    <div class="name">Skeleton preloader</div>
    <div class="class">theme-skeleton-loader</div>
    <pre class="html"><![CDATA[
      <div class="theme-default-page-title theme-skeleton-loader">Skeleton loader</div>
      <div class="theme-count-badge-small theme-skeleton-loader-radius-smaller">1</div>
      <br />
      <div class="theme-skeleton-loader-list">
        <div class="theme-skeleton-loader"></div>
        <div class="theme-skeleton-loader"></div>
        <div class="theme-skeleton-loader"></div>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-default-page-title theme-skeleton-loader">Skeleton loader</div>
      <div class="theme-count-badge-small theme-skeleton-loader-radius-smaller">1</div>
      <br />
      <div class="theme-skeleton-loader-list" style="width: 10rem">
        <div class="theme-skeleton-loader"></div>
        <div class="theme-skeleton-loader"></div>
        <div class="theme-skeleton-loader"></div>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Side panel</div>
    <div class="class">theme-end-side-panel</div>
    <pre class="html"><![CDATA[
      <section style="
          display: grid;
          grid-template-columns: 3fr 2fr;
          grid-template-rows: 1fr;
          border: 1px solid"
      >
        <div>Main page content</div>

        <div class="theme-end-side-panel">
          <div class="theme-tabs-switch">
            <button class="theme-tab theme-active">Tab 1</button>
            <button class="theme-tab">Tab 2</button>
          </div>

          <div class="theme-content">
            <div class="theme-header">Side Panel header</div>
            <div>Content</div>
          </div>
        </div>
      </section>
    ]]></pre>
    <div class="example">
      <section style="display: grid; grid-template-columns: 3fr 2fr; grid-template-rows: 1fr;border: 1px solid">
        <div>Main page content</div>

        <div class="theme-end-side-panel">
          <div class="theme-tabs-switch">
            <button class="theme-tab theme-active">Tab 1</button>
            <button class="theme-tab">Tab 2</button>
          </div>

          <div class="theme-content">
            <div class="theme-header">Side Panel header</div>
            <div>Content</div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="component">
    <div class="name">Title bar</div>
    <div class="class">theme-bottom-space</div>
    <pre class="html"><![CDATA[
      <div class="theme-title-bar">
        <a class="theme-title-back">
          <i class="mi-navigate-left"></i>
        </a>
        <div class="theme-title-icon">
          <div class="theme-file-icon-large">
            <i class="mi-file"></i>
          </div>
        </div>
        <div class="theme-title">
           <div class="theme-main-info">
            Page title
          </div>
          <div class="theme-additional-info">
            More info
          </div>
        </div>
        <div class="theme-title-actions">
          <button class="theme-button-option-icon-label"><i class="mi-key"></i>Open</button>
        </div>
      </div>
    ]]></pre>
    <div class="example">
      <div class="theme-title-bar">
        <a class="theme-title-back">
          <i class="mi-navigate-left"></i>
        </a>
        <div class="theme-title-icon">
          <div class="theme-file-icon-large">
            <i class="mi-file"></i>
          </div>
        </div>
        <div class="theme-title">
          <div class="theme-main-info">
            Page title
          </div>
          <div class="theme-additional-info">
            More info
          </div>
        </div>
        <div class="theme-title-actions">
          <button class="theme-button-option-icon-label"><i class="mi-key"></i>Open</button>
        </div>
      </div>
    </div>
  </div>



  <div class="component">
    <div class="name">Theme Property</div>
    <div class="class">theme-property</div>
    <pre class="html"><![CDATA[
      <div class="theme-property">
        <div class="theme-label">Name</div>
        <div class="theme-value">Value</div>
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-property">
        <div class="theme-label">Name</div>
        <div class="theme-value">Value</div>
      </div>
    </div>
  </div>


  <div class="component">
    <div class="name">Selectable text</div>
    <div class="class">theme-selectable-text</div>
    <pre class="html"><![CDATA[
      <div class="theme-selectable-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />
        Aenean dapibus est sit amet diam placerat,<br />
        vel luctus nisl fermentum.
      </div>
    ]]></pre>

    <div class="example">
      <div class="theme-selectable-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />
        Aenean dapibus est sit amet diam placerat,<br />
        vel luctus nisl fermentum.
      </div>
    </div>
  </div>



  <div class="component">
    <div class="name">Settings option</div>
    <div class="class">theme-settings-option</div>
    <pre class="html"><![CDATA[
        <div class="theme-settings-option">
          <i class="mi-text"></i>
          <span class="theme-label">Wielkość tekstu</span>
          <my-font-size-selector />
        </div>
    ]]></pre>

    <div class="example">
      <div style="width: 20rem">
        <div class="theme-settings-option">
        <i class="mi-text"></i>
        <span class="theme-label">Wielkość tekstu</span>
        <my-font-size-selector />
      </div>
    </div>
  </div>
  </div>



  <div class="component">
    <div class="name">Checkbox</div>
    <div class="class">my-checkbox</div>
    <pre class="html"><![CDATA[
        <div class="theme-settings-option">
          <my-checkbox [checked]="false" label="Option A"/>
          <my-checkbox [checked]="true" label="Option B" />
          <my-checkbox [disabled]="true" label="Option C" />
          <my-checkbox [disabled]="false" label="Option D" />
          <my-checkbox [small]="true" label="Option A"/>
          <my-checkbox [checked]="true" [small]="true" label="Option B" />
        </div>
    ]]></pre>

    <div class="example">
      <div style="width: 20rem">
        <div class="theme-settings-option">
          <my-checkbox [checked]="false" label="Option A"/>
          <my-checkbox [checked]="true" label="Option B" />
          <my-checkbox [checked]="false" [disabled]="true" label="Option C" />
          <my-checkbox [checked]="true" [disabled]="true" label="Option D" />
          <my-checkbox [small]="true" label="Option A"/>
          <my-checkbox [checked]="true" [small]="true" label="Option B" />
        </div>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Trilean</div>
    <div class="class">my-trilean</div>
    <pre class="html"><![CDATA[
        <div class="theme-settings-option">
          <my-trilean [value]="trileanFalse" label="Option A"/>
          <my-trilean [value]="trileanTrue" label="Option B" />
          <my-trilean [value]="trileanUnknown" label="Option C" />
          <my-trilean [value]="trileanFalse" [disabled]="true" label="Option A"/>
          <my-trilean [value]="trileanTrue" [disabled]="true" label="Option B" />
          <my-trilean [value]="trileanUnknown" [disabled]="true" label="Option C" />
        </div>
    ]]></pre>

    <div class="example">
      <div style="width: 20rem">
        <div class="theme-settings-option">
          <my-trilean [value]="trileanFalse" label="Option A"/>
          <my-trilean [value]="trileanTrue" label="Option B" />
          <my-trilean [value]="trileanUnknown" label="Option C" />
          <my-trilean [value]="trileanFalse" [disabled]="true" label="Option A"/>
          <my-trilean [value]="trileanTrue" [disabled]="true" label="Option B" />
          <my-trilean [value]="trileanUnknown" [disabled]="true" label="Option C" />
        </div>
      </div>
    </div>
  </div>

  <div class="component">
    <div class="name">Radiobutotn</div>
    <div class="class">my-checkbox</div>
    <pre class="html"><![CDATA[
        <div class="theme-settings-option">
          <my-radiobutton [checked]="radioEntries[0]" [radioOptions]="radioEntries"/>
          <my-radiobutton [checked]="radioEntries[1]" [radioOptions]="radioEntries" [disabled]="true"/>
        </div>
    ]]></pre>

    <div class="example">
      <div style="width: 20rem">
        <div class="theme-settings-option">
          <my-radiobutton [checked]="radioEntries[0]" [radioOptions]="radioEntries"/>
          <my-radiobutton [checked]="radioEntries[1]" [radioOptions]="radioEntries" [disabled]="true"/>
        </div>
      </div>
    </div>
  </div>
</div>
