<div class="entryName" *ngIf="nameEnabled">
  <div class="entryNameContent">
    <my-checkbox *ngIf="selectable" class="checkbox" [(checked)]="entry.selected" (checkedChange)="selectedChanged.emit()" />
    <span class="entryLabel" *ngIf="showLabels && entry.fieldName.length > 0" [my-tooltip]="{text: entry.codeName}">{{entry.fieldName}}</span>
    <span class="codeName" *ngIf="showNames || entry.fieldName.length == 0" [my-tooltip]="{text: entry.fieldName}">{{entry.codeName}}</span>
    <span class="typeName" *ngIf="showTypes">{{entry.dataTypeName}}</span>
  </div>
</div>

<my-business-variable-input *ngIf="entry.simpleType"
                            [applicationId]="viewModel.applicationId"
                            [serverModel]="viewModel.businessVariableInputServerModel" [value]="entry.value"
                            [valueType]="entry.type"
                            [valueTypeName]="entry.typeName" [path]="entry.path"
                            (variableChange)="viewModel.entryValueUpdated($event)"
                            (previewRequested)="viewModel.variablePreviewRequested($event)"
                            [readOnly]="!viewModel.editMode || entry.nonEditable" [preview]="true" />
<div class="entryError" *ngIf="validateType && (entry.notFromType || entry.incorrectType)">
  <i class="mi-sensor-alert warning" *ngIf="!entry.incorrectType && entry.notFromType" [my-tooltip]="{text: 'Not from type'}"></i>
  <i class="mi-sensor-alert error" *ngIf="entry.incorrectType" [my-tooltip]="{text: 'Incorrect type, expected '+entry.expectedTypeName+' but was ' + entry.fieldTypeName}"></i>
</div>
