export class PasswordMeterHelper {

  constructor() {}

  private strReverse(text: string) {
    let newstring = "";
    for (let s = 0; s < text.length; s++) {
      newstring = text.charAt(s) + newstring;
    }
    return newstring;
  };

  calculatePasswordStrength(password: string) { // source from www.passwordmeter.com
    let nScore = 0, nLength = 0, nAlphaUC = 0, nAlphaLC = 0, nNumber = 0, nSymbol = 0, nMidChar = 0,
      nRequirements = 0, nUnqChar = 0, nRepChar = 0, nRepInc = 0, nConsecAlphaUC = 0, nConsecAlphaLC = 0,
      nConsecNumber = 0, nConsecSymbol = 0, nConsecCharType = 0, nSeqAlpha = 0, nSeqNumber = 0, nSeqSymbol = 0,
      nSeqChar = 0, nReqChar = 0;
    let nMultMidChar = 2, nMultConsecAlphaUC = 2, nMultConsecAlphaLC = 2, nMultConsecNumber = 2;
    let nMultSeqAlpha = 3, nMultSeqNumber = 3, nMultSeqSymbol = 3;
    let nMultLength = 4, nMultNumber = 4;
    let nMultSymbol = 6;
    let nTmpAlphaUC = 0, nTmpAlphaLC = 0, nTmpNumber = 0, nTmpSymbol = 0;
    let sAlphas = "abcdefghijklmnopqrstuvwxyz";
    let sNumerics = "01234567890";
    let sSymbols = ")!@#$%^&*()";
    let nMinPwdLen = 8;
    if (password !== undefined && password.length > 0) {
      nScore = password.length * nMultLength;
      nLength = password.length;
      let arrPwd = password.replace(/\s+/g, "").split(/\s*/);
      let arrPwdLen = arrPwd.length;

      for (let a = 0; a < arrPwdLen; a++) {
        if (arrPwd[a].match(/[A-Z]/g)) {
          if (nTmpAlphaUC !== undefined) {
            if ((nTmpAlphaUC + 1) == a) {
              nConsecAlphaUC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaUC = a;
          nAlphaUC++;
        }
        else if (arrPwd[a].match(/[a-z]/g)) {
          if (nTmpAlphaLC !== undefined) {
            if ((nTmpAlphaLC + 1) == a) {
              nConsecAlphaLC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaLC = a;
          nAlphaLC++;
        }
        else if (arrPwd[a].match(/[0-9]/g)) {
          if (a > 0 && a < (arrPwdLen - 1)) {
            nMidChar++;
          }
          if (nTmpNumber !== undefined) {
            if ((nTmpNumber + 1) == a) {
              nConsecNumber++;
              nConsecCharType++;
            }
          }
          nTmpNumber = a;
          nNumber++;
        }
        else if (arrPwd[a].match(/[^a-zA-Z0-9_]/g)) {
          if (a > 0 && a < (arrPwdLen - 1)) {
            nMidChar++;
          }
          if (nTmpSymbol !== undefined) {
            if ((nTmpSymbol + 1) == a) {
              nConsecSymbol++;
              nConsecCharType++;
            }
          }
          nTmpSymbol = a;
          nSymbol++;
        }
        let bCharExists = false;
        for (let b = 0; b < arrPwdLen; b++) {
          if (arrPwd[a] == arrPwd[b] && a != b) {
            bCharExists = true;
            nRepInc += Math.abs(arrPwdLen / (b - a));
          }
        }
        if (bCharExists) {
          nRepChar++;
          nUnqChar = arrPwdLen - nRepChar;
          nRepInc = (nUnqChar) ? Math.ceil(nRepInc / nUnqChar) : Math.ceil(nRepInc);
        }
      }

      for (let s = 0; s < 23; s++) {
        let sFwd = sAlphas.substring(s, s + 3);
        let sRev = this.strReverse(sFwd);
        if (password.toLowerCase().indexOf(sFwd) != -1 || password.toLowerCase().indexOf(sRev) != -1) {
          nSeqAlpha++;
          nSeqChar++;
        }
      }

      for (let s = 0; s < 8; s++) {
        let sFwd = sNumerics.substring(s, s + 3);
        let sRev = this.strReverse(sFwd);
        if (password.toLowerCase().indexOf(sFwd) != -1 || password.toLowerCase().indexOf(sRev) != -1) {
          nSeqNumber++;
          nSeqChar++;
        }
      }

      for (let s = 0; s < 8; s++) {
        let sFwd = sSymbols.substring(s, s + 3);
        let sRev = this.strReverse(sFwd);
        if (password.toLowerCase().indexOf(sFwd) != -1 || password.toLowerCase().indexOf(sRev) != -1) {
          nSeqSymbol++;
          nSeqChar++;
        }
      }

      if (nAlphaUC > 0 && nAlphaUC < nLength) {
        nScore = nScore + ((nLength - nAlphaUC) * 2);
      }
      if (nAlphaLC > 0 && nAlphaLC < nLength) {
        nScore = nScore + ((nLength - nAlphaLC) * 2);
      }
      if (nNumber > 0 && nNumber < nLength) {
        nScore = nScore + (nNumber * nMultNumber);
      }
      if (nSymbol > 0) {
        nScore = nScore + (nSymbol * nMultSymbol);
      }
      if (nMidChar > 0) {
        nScore = nScore + (nMidChar * nMultMidChar);
      }

      if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {
        nScore = nScore - nLength;
      }
      if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {
        nScore = nScore - nLength;
      }
      if (nRepChar > 0) {
        nScore = nScore - nRepInc;
      }
      if (nConsecAlphaUC > 0) {
        nScore = nScore - (nConsecAlphaUC * nMultConsecAlphaUC);
      }
      if (nConsecAlphaLC > 0) {
        nScore = nScore - (nConsecAlphaLC * nMultConsecAlphaLC);
      }
      if (nConsecNumber > 0) {
        nScore = nScore - (nConsecNumber * nMultConsecNumber);
      }
      if (nSeqAlpha > 0) {
        nScore = nScore - (nSeqAlpha * nMultSeqAlpha);
      }
      if (nSeqNumber > 0) {
        nScore = nScore - (nSeqNumber * nMultSeqNumber);
      }
      if (nSeqSymbol > 0) {
        nScore = nScore - (nSeqSymbol * nMultSeqSymbol);
      }

      nRequirements = nReqChar;

      let nMinReqChars;
      if (password.length >= nMinPwdLen) {
        nMinReqChars = 3;
      } else {
        nMinReqChars = 4;
      }
      if (nRequirements > nMinReqChars) {
        nScore = nScore + (nRequirements * 2);
      }

      return nScore;
    } else {
      return 0;
    }

  };

}
