<div class="tabsHeader">

  <ng-container *ngFor="let tab of viewModel.tabs">
    <span class="tabHeader" *ngIf="tab.uncoveredAndVisible" [ngClass]="{selected: tab === viewModel.activeTab, disabled: !tab.enabled}" [style]="viewModel.headerCss">
      <button *ngIf="tab.enabled" class="tab" (click)="viewModel.selectTab(tab)"  [ngClass]="{selected: tab === viewModel.activeTab}">{{tab.header}}</button>
      <button *ngIf="!tab.enabled" class="tab">{{tab.header}}</button>
    </span>
  </ng-container>

</div>
<div *ngIf="viewModel.activeTab"
     class="childrenPlain layout_{{viewModel.activeTab.layoutClass}} layoutAlign_{{viewModel.activeTab.layoutAlignClass}} layoutStretch_{{viewModel.activeTab.layoutStretchClass}} layoutWrap_{{viewModel.activeTab.layoutWrapClass}} {{viewModel.activeTab.childrenPlainCssClasses}}"
     [style]="viewModel.activeTab.childrenPlainCss">

  <ng-container *ngFor="let component of viewModel.activeTab.children">
    <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
         [class.nonVisibleComponent]="component.nonVisibleComponent"
         [style]="component.positionCss">
      <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
        <!--              SHARED -->
        <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />

        <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />

        <div class="errorsInfo" *ngIf="component.error">
          <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
        </div>

        <div class="inProgressGlass" *ngIf="component.inProgress"></div>
        <!--              SHARED -->
      </div>
    </div>
  </ng-container>
</div>
