import {HttpClient} from "@angular/common/http";
import {
  asSuccess,
  AuthenticatedHttp,
  CommandResponse,
  customCommandResponseHandler,
  CustomSuccessResponse, isSuccess,
  nonSuccessMessages
} from "@shared-model";
import {
  ActivatePersonAccountByAdmin,
  ConfirmPersonEmail,
  ConfirmPersonEmailByAdmin,
  CreateAdminWithoutPassword,
  DeactivatePersonAccountByAdmin,
  EmailConfirmResponse,
  EmailConfirmWithPasswordSet,
  RecoverPasswordByAdminUI, RecoverPasswordByAdminUIResponse,
  RecoverPasswordUI,
  RecoverPasswordUIResponse,
  ResetPassword,
  SelfCreateAdmin
} from "./user-register.model";
import {Injectable} from "@angular/core";
import {AggregateId, AggregateVersion, handleError, restUrl, Some, Typed} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {

  constructor(private http: HttpClient,
              readonly authenticatedHttp: AuthenticatedHttp) {}

  selfRegisterAdmin(registerForm: SelfCreateAdmin, onSuccess: () => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.http.post<Typed<CommandResponse>>(restUrl("users/self-register-admin"), registerForm, {withCredentials: true}).subscribe({
      next: (response: any) => {
        if (isSuccess(response.data)) {
          onSuccess();
        } else {
          onFailure([nonSuccessMessages(response.data)]);
        }
      },
      error: (reason) => {
        onError();
      }
    })
  }


  registerUserWithoutPassword(registerForm: CreateAdminWithoutPassword, onSuccess: (id: AggregateId, version: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.authenticatedHttp.post("users/register-user-without-password", registerForm, response => {
      if (isSuccess(response.data)) {
        onSuccess(asSuccess(response.data).aggregateId,asSuccess(response.data).aggregateVersion);
      } else {
        onFailure([nonSuccessMessages(response.data)]);
      }
    }, () => {
      onError()
    });
  }


  confirmEmail(emailConfirm: ConfirmPersonEmail, onSuccess: (confirmResponse: EmailConfirmResponse) => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.http.post<Typed<CustomSuccessResponse<EmailConfirmResponse>>>(restUrl("users/email-confirm"), emailConfirm, {withCredentials: true}).subscribe({
      next: (response: any) => {
        customCommandResponseHandler<EmailConfirmResponse>(response.data)
          .onSuccess((id: AggregateId, version: AggregateVersion, userInfo: EmailConfirmResponse) => onSuccess(userInfo))
          .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
      },
      error: (reason) => {
        onError();
      }
    })
  }

  confirmEmailInAdminPanel(emailConfirm: ConfirmPersonEmailByAdmin, onSuccess: (confirmResponse: EmailConfirmResponse) => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.authenticatedHttp.post("users/confirm-email-by-admin", emailConfirm, (data: Typed<CustomSuccessResponse<EmailConfirmResponse>>) => {
      customCommandResponseHandler<EmailConfirmResponse>(data)
        .onSuccess((id: AggregateId, version: AggregateVersion, userInfo: EmailConfirmResponse) => onSuccess(userInfo))
        .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
    })
  }

  activateUserInAdminPanel(user: ActivatePersonAccountByAdmin, onSuccess: () => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.authenticatedHttp.post("users/activate-user-by-admin", user, (data: Typed<CommandResponse>) => {
      if (isSuccess(data)) {
        onSuccess();
      } else {
        onFailure([nonSuccessMessages(data)]);
      }
    }, () => {
      onError()
    });
  }


  deactivateUserInAdminPanel(user: DeactivatePersonAccountByAdmin, onSuccess: () => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.authenticatedHttp.post("users/deactivate-user-by-admin", user, (data: Typed<CommandResponse>) => {
      if (isSuccess(data)) {
        onSuccess();
      } else {
        onFailure([nonSuccessMessages(data)]);
      }
    }, () => {
      onError()
    });
  }

  confirmEmailAndSetPassword(userId: string, activationCode: string, password: string, repeatPassword: string,
                             onSuccess: () => void, onFailure: (exceptions: Array<string>) => void) {;
    this.http.post<Typed<CommandResponse>>(restUrl("users/email-confirm-set-password"),
      new EmailConfirmWithPasswordSet(userId, activationCode, password, repeatPassword), {withCredentials: true}).subscribe({
      next: (response: any) => {
        if (isSuccess(response)) {
          onSuccess();
        } else {
          onFailure([nonSuccessMessages(response)]);
        }
      },
      error: (reason) => {
        onFailure(reason)
      }
    })
  }

  recoverPassword(email: string, onSuccess: () => void, onFailure: (exceptions: Array<string>) => void) {
    this.http.post<RecoverPasswordUIResponse>(restUrl("user-accounts/recover-password"), new RecoverPasswordUI(email), {withCredentials: true}).subscribe({
      next: (response: any) => {
        onSuccess();
      },
      error: (reason) => {
        onFailure(reason)
      }
    })
  }

  resetPassword(userId: string, recoveryCode: string, password: string, repeatPassword: string,
                onSuccess: () => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.http.post<Typed<CommandResponse>>(restUrl("user-accounts/reset-password"),
      new ResetPassword(new AggregateId(userId), recoveryCode, password, repeatPassword), {withCredentials: true}).subscribe({
      next: (response: any) => {
        if (isSuccess(response)) {
          onSuccess();
        } else {
          onFailure([nonSuccessMessages(response)]);
        }
      },
      error: (reason) => {
        onError()
      }
    })
  }

  verifyCode(code: string, userId: string, onSuccess: () => void, onFailure: () => void) {
    this.http.get<boolean>(restUrl(`user-accounts/verify-code/${code}/${userId}`), {withCredentials: true}).subscribe({
      next: (response: boolean) => {
        if (response) {
          onSuccess();
        } else {
          onFailure();
        }
      },
      error: (reason) => {
        onFailure();
      }
    })
  }

  generateCode(onSuccess: () => void, onFailure: (exceptions: Array<string>) => void): void {
    this.http.get(restUrl("user-accounts/generate-admin-code"), {withCredentials: true}).subscribe({
      next: (response: any) => {
        onSuccess();
      },
      error: (reason) => {
        onFailure(reason);
      }
    })
  }

  recoverPasswordByAdmin(email: string, onSuccess: (resetPasswordUrl: string) => void, onFailure: (exceptions: Array<string>) => void) {
    this.authenticatedHttp.post("user-accounts/recover-password-by-admin",
      new RecoverPasswordByAdminUI(email), (data: Typed<CustomSuccessResponse<RecoverPasswordByAdminUIResponse>>) => {
        customCommandResponseHandler<RecoverPasswordByAdminUIResponse>(data)
          .onSuccess((id: AggregateId, version: AggregateVersion, info: RecoverPasswordByAdminUIResponse) => onSuccess(info.resetUrlSuffix))
          .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
      });
  }

  recoverPasswordByAdminInApplication(email: string, organizationId: AggregateId, onSuccess: (resetPasswordUrl: string) => void, onFailure: (exceptions: Array<string>) => void) {
    this.authenticatedHttp.post("user-accounts/recover-password-by-platform-admin",
      new RecoverPasswordUI(email, organizationId), (data: Typed<CustomSuccessResponse<RecoverPasswordByAdminUIResponse>>) => {
        customCommandResponseHandler<RecoverPasswordByAdminUIResponse>(data)
          .onSuccess((id: AggregateId, version: AggregateVersion, info: RecoverPasswordByAdminUIResponse) => onSuccess(info.resetUrlSuffix))
          .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
      });
  }
}


