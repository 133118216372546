<div *ngIf="!finished" class="passwordRecoveryForm">

  <div class="titleContainer">
    <div class="title">{{'password-recovery_form_title' | i18n}}</div>
    <div class="organizationTitle">{{'password-recovery_description' | i18n}}</div>
  </div>

  <div class="passwordRecoveryFormField theme-input-box-with-color-icon-large">
    <i class="mi-mail"></i>
    <input type="email" placeholder="{{'password-recovery_form_email_placeholder' | i18n}}"
           required="true" [(ngModel)]="userEmail">
  </div>

  <button type="button" class="loginButton theme-button-confirm"
          (click)="recoverPassword()">{{'password-recovery_form_submit_button' | i18n}}</button>

  <div class="alreadyHasAccount">
    {{'password-recovery_already_has_account' | i18n}}
    <a routerLink="/login"
       class="logIn"
       (click)="recoverPassword()">
      &nbsp;{{'password-recovery_form_log_in' | i18n}}
    </a>
  </div>

</div>

<div *ngIf="finished">
  <div class="titleContainer">
    <div class="title">{{'password-recovery_form_email_sent' | i18n}}</div>
  </div>
</div>

