import {$$Element} from "./$$";

export abstract class ZoomBehavior {

  private zoom = 1;

  constructor(
    readonly selection:$$Element,
    readonly minZoom: number,
    readonly maxZoom: number) {
  }

  abstract zoomStart(zoomedElement:$$Element):void;
  abstract zoomed(zoomedElement:$$Element, scale:number, centerX:number, centerY:number):void;
  abstract zoomEnd(zoomedElement:$$Element):void;

  init() {

    this.selection.on("wheel", this.wheelListener);

    // const zoom = d3.behavior.zoom()
    //   .scale(1)
    //   .scaleExtent([this.minZoom, this.maxZoom])
    //   .on("zoomstart", this.internalZoomStart())
    //   .on("zoom", this.internalZoomed())
    //   .on("zoomend", this.internalZoomEnd());
    //
    // this.selection.call(zoom).on("dblclick.zoom",null);  //prevent zoom on double click
    return this;
  }

  private wheelListener = (event: WheelEvent) => {

    let newZoom = this.zoom;

    if (event.deltaY > 0) {
      newZoom = this.zoom * 0.9;
    } else {
      newZoom = this.zoom / 0.9;
    }
    newZoom = Math.min(Math.max(newZoom, this.minZoom), this.maxZoom);

    if (newZoom !== this.zoom) {
      this.zoom = newZoom;
      this.zoomed(this.selection, newZoom, event.clientX, event.clientY);
    }
  }

  internalZoomStart() {
    // const externalThis = this;
    // return function (d:T) {
    //   const eventTarget = d3.select(<any>this);
    //   if(d3.event.sourceEvent.type === "wheel") {
    //     externalThis.zoomStart(eventTarget, <T>d);
    //   }
    //
    // }
  }

  internalZoomed() {
    // const externalThis = this;
    // return function (d:T) {
    //   const containerPosition = elements.getPosition(this);
    //   const eventTarget = d3.select(<any>this);
    //   const scale = d3.event.scale;
    //
    //   if(d3.event.sourceEvent !== null && (d3.event.sourceEvent.type === "wheel")) {
    //     externalThis.zoomed(eventTarget, scale, d3.event.sourceEvent.clientX - containerPosition.x, d3.event.sourceEvent.clientY - containerPosition.y, <T>d);
    //   }
    // }
  }

  internalZoomEnd() {
    // const externalThis = this;
    // return function (d:T) {
    //   const eventTarget = d3.select(<any>this);
    //   if (d3.event.sourceEvent !== null && (d3.event.sourceEvent.type === "wheel")) {
    //     externalThis.zoomEnd(eventTarget, <T>d);
    //   }
    // }
  }

  destroy() {
    this.selection.off("wheel", this.wheelListener);
  }

}
