import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  createNgModule,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {StyleSet, StylesResolver, Translation, TranslationsResolver, ViewableFile} from "@shared";
import {DocumentsRepositoryEventBus} from "../../../modules/documents.module/DocumentsRepositoryEventBus";
import {mySetTimeout} from "@utils";

@Component({
  selector: "my-file-viewer",
  templateUrl: "./file-viewer.component.html",
  host: {
    "[class.hidden]": "!visible",
  }
})
export class FileViewerComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() files: Array<ViewableFile> = [];
  @Input() currentFileIndex: number = 0;
  @Input() fullScreenOnly: boolean = false;
  @Input() allowFullscreen: boolean = true;
  @Input() inView: boolean = false;
  @Input() splitModeAvailable: boolean = false;
  @Input() eventBus: DocumentsRepositoryEventBus = new DocumentsRepositoryEventBus();
  @Input() minimalInterface: boolean = false;
  @Input() serverModel!: any; // DocumentsRepositoryServerModel
  @Output() close = new EventEmitter<void>();

  internalClose = new EventEmitter<void>();


  @ViewChild(TemplateRef, { read: ViewContainerRef })
  private templateViewContainerRef: ViewContainerRef|undefined = undefined;

  private instance: Promise<ComponentRef<any>>|undefined = undefined;

  constructor(readonly route: ActivatedRoute,
              private injector: Injector,
              private stylesResolver: StylesResolver,
              private translationsResolver: TranslationsResolver) {}

  ngOnInit(): void {

    this.internalClose.subscribe(() => {
      if (this.instance !== undefined) {
        const instance = this.instance;
        this.instance = undefined;
        instance.then(i => {
          this.destroyComponent(i);
        });
      }
      this.close.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.visible) {
      if (this.instance !== undefined) {

        this.instance.then((instance) => {
          this.assignInputs(instance, true);
        });

      } else {
        this.createComponent();
      }
    } else {
      if (this.instance !== undefined) {
        const instance = this.instance;
        this.instance = undefined;
        instance.then(i => {
          this.destroyComponent(i);
        });
      }
    }
  }

  private destroyComponent(instance: ComponentRef<any>) {
    instance.destroy();
  }

  private createComponent() {

    this.instance = new Promise((resolve, reject) => {

      const module = import('../../../modules/documents.module/documents.module');
      const styles = this.stylesResolver.loadStyles(StyleSet.documents);
      const translations = this.translationsResolver.loadTranslations(Translation.documentsRepository, Translation.fileViewer, Translation.signature);

      Promise.all([module, styles, translations]).then((values) => {

        const DocumentsModule = values[0].DocumentsModule;

        if(this.templateViewContainerRef === undefined) {
          throw new Error("templateViewContainerRef is undefined");
        } else {

          const moduleRef = createNgModule(DocumentsModule, this.injector)

          this.templateViewContainerRef.clear();

          const instance: ComponentRef<any> = this.templateViewContainerRef.createComponent(moduleRef.instance.getFileViewerComponent(), {ngModuleRef: moduleRef});

          if (instance === undefined) {
            reject(new Error("Failed to create component"));
          } else {
            this.assignInputs(instance, false);
            resolve(instance);
          }
        }

      });
    });
  }

  private assignInputs(instance: ComponentRef<any>, change: boolean) {
    if(instance === undefined){
      throw new Error("instance is undefined");
    } else {
      instance.instance.visible = this.visible;
      instance.instance.files = this.files;
      instance.instance.currentFileIndex = this.currentFileIndex;
      instance.instance.allowFullscreen = this.allowFullscreen;
      instance.instance.fullScreenOnly = this.fullScreenOnly;
      instance.instance.inView = this.inView;
      instance.instance.splitModeAvailable = this.splitModeAvailable;
      instance.instance.eventBus = this.eventBus;
      instance.instance.minimalInterface = this.minimalInterface;
      instance.instance.serverModel = this.serverModel;
      instance.instance.close = this.internalClose;
      if(change) {
       mySetTimeout(() => {
          if(this.instance !== undefined) {
            instance.instance.paramsChanged();
          }
        });
      }
    }
  }
}
