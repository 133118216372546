/**
 * Immutable class for holding grid coordinates, that means number of row and column on process grid.
 * It does not hold pixel coordinates.
 */
export class GridXY {

  constructor(readonly gridX: number,
              readonly gridY: number) {
  }

  static ZERO: GridXY = new GridXY(0, 0);

  isEqual(other: GridXY) {
    return this.gridX === other.gridX && this.gridY === other.gridY;
  }

  nonEqual(other: GridXY) {
    return !this.isEqual(other);
  }

  /** Checks if both coordinates are non negative */
  nonNegative() {
    return this.gridX >= 0 && this.gridY >= 0;
  }

  /** For proper object initialization, e.g. after JSON deserialization */
  static copy(gridXY: GridXY) {
    return new GridXY(gridXY.gridX, gridXY.gridY)
  }

  shiftXY(shiftX: number, shiftY: number) {
    return new GridXY(this.gridX + shiftX, this.gridY + shiftY);
  }

  toString() {
    return `(${this.gridX}, ${this.gridY})`;
  }

}
