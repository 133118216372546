import {AggregateId, AnyPersonId, ApplicationId, GroupId, Option, OrganizationNodeId, PersonId, Typed} from "@utils";
import {BasicOrganizationNodeInfo} from "./OrganizationNodesModel";

export class FindNodesByIds {
  constructor(readonly ids: Array<AggregateId>,
              readonly deleted: boolean) {}
}


export class GetBasicPersonsInfoByIds {
  constructor(readonly personsIds: Array<Typed<AnyPersonId>>) {
  }
}

export class GetPersonsInfoByIds {
  constructor(readonly personsIds: Array<PersonId>) {
  }
}

export class FindPersonByQueryAnyWord {
  constructor(readonly query: string,
              readonly limit: number) {
  }
}

export class FindOrganizationNodes {
  constructor(readonly text: string,
              readonly persons: boolean,
              readonly services: boolean,
              readonly departments: boolean,
              readonly groups: boolean,
              readonly processes: boolean,
              readonly reports: boolean,
              readonly applications: boolean,
              readonly functions: boolean,
              readonly screens: boolean,
              readonly applicationId: Option<ApplicationId>,
              readonly personsWithinNodes: Option<Array<OrganizationNodeId>>,
              readonly inAllApplications: boolean,
              readonly fromGlobal: boolean,
              readonly limit: number) {}
}


export class OrganizationNodeSearchResult {
  constructor(readonly nodes: Array<BasicOrganizationNodeInfo>,
              readonly moreAvailable: boolean) {}

  static copy(result: OrganizationNodeSearchResult): OrganizationNodeSearchResult {
    return new OrganizationNodeSearchResult(
      result.nodes.map(BasicOrganizationNodeInfo.copy),
      result.moreAvailable)
  }
}


export class FindOrganizationNodesByIds {
  constructor(readonly ids: Array<OrganizationNodeId>) {}
}

export class FindOrganizationNodesInGroup {
  constructor(readonly groupId: GroupId) {}
}

export class FindAuthorizationsInfoById {
  constructor(readonly id: GroupId) {}
}
