import {Component} from '@angular/core';
import {UserSettingsStateService} from "@shared";
import {Language, Option} from "@utils";

@Component({
  selector: 'my-language-selector-user-settings',
  template: '<my-language-selector (languageChange)="onLanguageChanged($event)"></my-language-selector>'
})
export class LanguageSelectorUserSettingsComponent {

  constructor(readonly userSettingsService: UserSettingsStateService) {}

  onLanguageChanged(language: Option<Language>) {
    this.userSettingsService.changeLanguage(language);
  }
}
