import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {__} from "@utils";
import {
  PasswordValidatorService,
  SessionServiceProvider,
} from "@shared";

export class SetPasswordForm {
  newPassword: string = "";
  newRepeatPassword: string = "";

  isValid() {
    return this.newPassword.length > 0 && this.newRepeatPassword.length > 0 && this.newPassword == this.newRepeatPassword;
  }
}

@Component({
  selector: 'my-password-set-form',
  templateUrl: './password-set-form.component.html'
})
export class PasswordSetFormComponent implements OnInit {

  public form: SetPasswordForm = new SetPasswordForm();
  public passwordExceptions: Array<string> = [];
  public repeatPasswordExceptions: Array<string> = [];

  @Output() passwordSubmitted: EventEmitter<SetPasswordForm> = new EventEmitter<SetPasswordForm>();
  @Output() passwordSubmittedAndValidated: EventEmitter<SetPasswordForm> = new EventEmitter<SetPasswordForm>();

  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly passwordValidatorService: PasswordValidatorService) {
  }

  ngOnInit() {
    this.form = new SetPasswordForm();
  }

  validatePassword() {
    this.passwordValidatorService.validatePassword(this.form.newPassword.trim(), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });

  };

  validatePasswords() {
    this.passwordValidatorService.validatePasswords(this.form.newPassword.trim(), this.form.newRepeatPassword.trim(), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });

  };

  validatePasswordPolicy = (onResult: (result: boolean) => void) => {
    this.passwordValidatorService.validatePassword(this.form.newPassword.trim(), () => {
      onResult(true);
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      onResult(__(passwordExceptions).isEmpty());
    });
  };

  checkIfPasswordsMatchNow() {
    if (this.repeatPasswordExceptions.length > 0) {
      this.validatePasswords();
    }
  };

  setPassword() {
    this.passwordSubmitted.emit(this.form);

    this.passwordValidatorService.validatePasswords(this.form.newPassword.trim(), this.form.newRepeatPassword.trim(), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];

      this.passwordSubmittedAndValidated.emit(this.form);

    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });
  }

}
