const colorIdToColorValue: {[colorId: string]: string} = {
  "1":"#1ABC9C",
  "2":"#0ECFA8",
  "3":"#00BED6",
  "4":"#04A7D4",
  "5":"#016FB9",
  "6":"#4227B9",
  "7":"#465BB3",
  "8":"#1F1646",
  "9":"#F6AE2D",
  "10":"#FF8811",
  "11":"#E84855",
  "12":"#D94182",
  "13":"#B61624",
  "14":"#990099",
  "15":"#735CDD",
  "16":"#97A7C4",
}

const reportColors: Array<string> = [
  "#1ABC9CCC",
  "#F6AE2DCC",
  "#465BB3CC",
  "#E84855CC",
  "#04A7D4CC",
  "#FF8811CC",
  "#B61624CC",
  "#0ECFA8CC",
  "#4227B9CC",
  "#00BED6CC",
  "#016FB9CC",
  "#990099CC",
  "#97A7C4CC",
  "#1F1646CC",
  "#D94182CC",
  "#735CDDCC",
]

export function reportColorIndexToHex(index: number): string {
  return reportColors[index % reportColors.length];
}

export function mainColorHex(): string {
  return colorIdToColorValue["1"];
}

export function colorIdToHex(id: number|string): string {
  return colorIdToColorValue[id.toString()];
}

interface ThemeColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

class Colors {
  static WHITE = {r: 255, g: 255, b: 255, a: 255};
  static BLACK = {r: 0, g: 0, b: 0, a: 255};
}

export function validateColorHex(hex: string): boolean {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return colorRegex.test(hex);
}
export function parseColorHex(hex: string): ThemeColor|null {

  const colorRegex = /^#([A-Fa-f0-9]{6,8})$/;
  if (!colorRegex.test(hex)) {
    return null;
  } else {
    const trimmed = hex.replace('#', '');
    return {
      r: parseInt(trimmed.substring(0, 2), 16),
      g: parseInt(trimmed.substring(2, 4), 16),
      b: parseInt(trimmed.substring(4, 6), 16),
      a: trimmed.length == 6 ? 255 : parseInt(trimmed.substring(6, 8), 16),
    };
  }

}

export function colorToHex(color: ThemeColor): string {
  return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b) + componentToHex(color.a);
}

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function colorDistance(color1: ThemeColor, color2: ThemeColor): number {
  return Math.sqrt(
    Math.pow(color1.r - color2.r, 2) +
    Math.pow(color1.g - color2.g, 2) +
    Math.pow(color1.b - color2.b, 2)
  );
}

export function isDark(color: ThemeColor): boolean {
  const brightness = Math.sqrt(
    0.299 * Math.pow(color.r, 2) +
    0.587 * Math.pow(color.g, 2) +
    0.114 * Math.pow(color.b, 2)
  );
  return brightness < 128;
}

export function isLight(color: ThemeColor): boolean {
  return !isDark(color);
}

export function getContrastColor(color: ThemeColor): ThemeColor {
  return isDark(color) ? Colors.WHITE : Colors.BLACK;
}

export function mixColors(color1: ThemeColor, color2: ThemeColor, ratio: number): ThemeColor {
  if(ratio >= 0 && ratio <= 1) {
    return {
      r: Math.round(color1.r * ratio + color2.r * (1 - ratio)),
      g: Math.round(color1.g * ratio + color2.g * (1 - ratio)),
      b: Math.round(color1.b * ratio + color2.b * (1 - ratio)),
      a: Math.round(color1.a * ratio + color2.a * (1 - ratio)),
    };
  } else {
    throw new Error("Ratio must be between 0 and 1");
  }
}

export function lightenColor(color: ThemeColor, ratio: number): ThemeColor {
  return mixColors(Colors.WHITE, color, ratio);
}

export function darkenColor(color: ThemeColor, ratio: number): ThemeColor {
  return mixColors(Colors.BLACK, color, ratio);
}

export function saturate(color: ThemeColor, ratio: number): ThemeColor {
  const hsl = rgbToHsl(color);
  hsl.s = Math.min(1, hsl.s * ratio);
  return hslToRgb(hsl);
}

export function colorBrightness(color: ThemeColor): number {
  return Math.sqrt(
    0.299 * Math.pow(color.r, 2) +
    0.587 * Math.pow(color.g, 2) +
    0.114 * Math.pow(color.b, 2)
  ) / 255;
}

function rgbToHsl(color: ThemeColor): {h: number, s: number, l: number, a: number} {
  const r = color.r / 255;
  const g = color.g / 255;
  const b = color.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return {h, s, l, a: color.a};
}

function hslToRgb(hsl: {h: number, s: number, l: number, a: number}): ThemeColor {
  let r: number, g: number, b: number;

  const h = hsl.h;
  const s = hsl.s;
  const l = hsl.l;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {

    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) { t += 1; }
      if (t > 1) { t -= 1; }
      if (t < 1 / 6) { return p + (q - p) * 6 * t; }
      if (t < 1 / 2) { return q; }
      if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);

  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
    a: hsl.a,
  };
}

export function setAlpha(color: ThemeColor, alpha: number): ThemeColor {
  return {...color, a: alpha};
}

export function changeOpacity(hexColor: string, newOpacity: number): string {
  const color = parseColorHex(hexColor);

  if(color) {
    color.a = newOpacity;
    return colorToHex(color);
  } else {
    return hexColor;
  }
}
