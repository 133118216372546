export class Trilean {
  static TRUE = new Trilean("true");
  static UNKNOWN = new Trilean("unknown");
  static FALSE = new Trilean("false");

  private constructor(readonly name: string) {
    if(name !== "true" && name !== "unknown" && name !== "false") {
      throw new Error("Incorrect Trilean value [" + name + "]");
    }
  }

  isTrue() {
    return this.name === Trilean.TRUE.name;
  }

  notTrue() {
    return this.name !== Trilean.TRUE.name;
  }

  isUnknown() {
    return this.name === Trilean.UNKNOWN.name;
  }

  isFalse() {
    return this.name === Trilean.FALSE.name;
  }

  notFalse() {
    return this.name !== Trilean.FALSE.name;
  }

  isKnown() {
    return this.name !== Trilean.UNKNOWN.name;
  }

  booleanOrElse(orElse: boolean|(() => boolean)): boolean {
    if (this.name === Trilean.TRUE.name) {
      return true;
    } else if (this.name === Trilean.FALSE.name) {
      return false;
    } else {
      if (typeof orElse === "boolean") {
        return orElse;
      } else {
        return orElse();
      }
    }
  }

  booleanOrNull(): boolean|null {
    if (this.name === Trilean.TRUE.name) {
      return true;
    } else if (this.name === Trilean.FALSE.name) {
      return false;
    } else {
      return null;
    }
  }

    asBoolean() {
      if (this.name === Trilean.TRUE.name) {
        return true;
      } else if (this.name === Trilean.FALSE.name) {
        return false;
      } else {
        throw new Error("Cannot get boolean value from unknown Trilean");
      }
    }

  static copy(other: Trilean) {
    switch (other.name) {
      case "true": return Trilean.TRUE;
      case "unknown": return Trilean.UNKNOWN;
      case "false": return Trilean.FALSE;
      default: throw new Error("Incorrect Trilean value [" + other.name + "]");
    }
  }

  equals(other: Trilean) {
    return other.name === this.name;
  }

  static ofBoolean(value: boolean) {
    return value ? Trilean.TRUE : Trilean.FALSE;
  }
}
