import {Injectable} from "@angular/core";
import {AuthenticatedHttp} from "@shared-model";
import {BusinessEntityIdWithType, Option} from "@utils";
import {BusinessEntitySummary} from "./business-entity.shared-model";


@Injectable({
  providedIn: 'root',
})
export class BusinessEntitySharedService {
  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  loadEntitySummary(businessEntityId: BusinessEntityIdWithType, onSuccess: (entity: Option<BusinessEntitySummary>) => void) {
    this.authenticatedHttp.get("business-entity/get-summary-by-id/"+businessEntityId.typeId+"/"+businessEntityId.id,
      (data: Option<BusinessEntitySummary>) => {
        onSuccess(Option.copy(data, BusinessEntitySummary.copy));
      });
  }
}
