import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Language, Option} from "@utils";
import {DropDownSelectorOption, I18nService, LanguageOption, SystemSettingsService, UserSettingsStateService} from "@shared";
import {getLanguageOptions} from "../language-options";

@Component({
  selector: "my-language-list",
  templateUrl: "./language-list.component.html",
})
export class LanguageListComponent implements OnInit {

  systemLanguage!: LanguageOption;

  selectedLanguage!: LanguageOption;
  displayedSelectedLanguage!: LanguageOption;
  languageOptions: Array<LanguageOption>;
  defaultLanguageOption: LanguageOption = null!;

  languageSelectorVisible: boolean = false;

  @Input() smallView: boolean = true;
  @Input() showExpandIcon: boolean = false;

  @Output() languageChange = new EventEmitter<Option<Language>>();

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly systemSettingsService: SystemSettingsService,
              readonly i18nService: I18nService) {
    this.languageOptions = getLanguageOptions();
    this.defaultLanguageOption = this.languageOptions[0];
  }

  ngOnInit(): void {
    this.selectedLanguage = this.languageOptions.find(option => option.selected) as LanguageOption;
    this.updateVisibleLanguage();
  }

  onLanguageChanged(lang: LanguageOption|DropDownSelectorOption) {
    this.selectedLanguage.selected = false;
    this.selectedLanguage = lang as LanguageOption;

    const language = Option.of(lang.value);
    this.userSettingsService.changeLanguage(language);
    this.languageChange.emit(language);

    this.languageSelectorVisible = false;

    this.updateVisibleLanguage();
  }

  private updateVisibleLanguage() {
    const system = this.systemSettingsService.getEffectiveLanguage();

    this.systemLanguage = Option.of(this.languageOptions.find(theme => theme.value === system)).getOrError("No system theme");

    if(this.selectedLanguage.value) {
      this.displayedSelectedLanguage = this.selectedLanguage;
    } else {
      this.displayedSelectedLanguage = this.systemLanguage;
    }

  }

  protected readonly LanguageOption = LanguageOption;
}
