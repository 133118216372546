<div class="scrollSizer"></div>
<div class="scrollBody" [class.horizontal]="horizontal">
  <div class="scrollContent" [class]="contentClass" [class.noMinHeight]="adjustHeight" [class.noMinWidth]="adjustWidth">
    <ng-content></ng-content>
  </div>
</div>
<div class="topBottomShadowLayer"></div>
<div class="scrollGlass" style="display: none"></div>
<div class="verticalScrollBar">
  <div class="verticalScrollBackground"></div>
  <div class="verticalScrollHandle"></div>
</div>
<div class="horizontalScrollBar">
  <div class="horizontalScrollBackground"></div>
  <div class="horizontalScrollHandle"></div>
</div>
