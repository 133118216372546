import {Injectable} from "@angular/core";
import {FileUri, Typed} from "@utils";
import {EmailBasicInfo} from "./EmailModel";
import {GetEmailsSummaries} from "../../modules/mailbox.module/service/emails.model";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {FileRequestResult, FileRequestResultFactory} from "@shared";

@Injectable({
  providedIn: 'root',
})
export class EmailsSharedService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  loadEmailsSummary(requestNumber: number, emails: Array<FileUri>, onSuccess: (requestNumber: number, emailInfos: Array<EmailBasicInfo>) => void) {
    if(emails.length === 0) {
      onSuccess(requestNumber, []);
    } else {
      this.authenticatedHttp.post("email/incoming-email/get-emails-summaries", new GetEmailsSummaries(emails), (summaries: Array<Typed<FileRequestResult<EmailBasicInfo>>>) => {
        onSuccess(requestNumber, summaries.map(s => FileRequestResultFactory.copyTypedAndUnwrap(s, EmailBasicInfo.copy).asSuccess().file));
      });
    }
  }
}
