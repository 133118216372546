import {Component, OnInit} from "@angular/core";
import {MainMenuPage, MainMenuService} from "./main-menu.service";
import {SessionServiceProvider} from "@shared";
import {MenuEventBus} from "./menu.event-bus";

@Component({
  selector: 'my-mobile-menu',
  templateUrl: './mobile-menu.component.html'
})
export class MobileMenuComponent implements OnInit {

  mainDashboardActive: boolean = false;
  mainTasksAndProcessesActive: boolean = false;
  applicationsActive: boolean = false;
  documentsActive: boolean = false;
  userSettingsActive: boolean = false;
  adminActive: boolean = false;
  helpActive: boolean = false;

  visible = false;

  userFirstName: string = "";
  userLastName: string = "";
  userEmail: string = "";

  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly mainMenuService: MainMenuService,
              readonly menuEventBus: MenuEventBus) {
    mainMenuService.getPageObservable().forEach(page => {
      this.onPageChanged(page);
    });
    this.onPageChanged(mainMenuService.page);
  }

  ngOnInit() {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      this.userFirstName = sessionInfo.userFirstName;
      this.userLastName = sessionInfo.userLastName;
      this.userEmail = sessionInfo.userEmail;
    });
  }

  private onPageChanged(page: MainMenuPage) {
    this.mainDashboardActive = page == MainMenuPage.dashboard;
    this.mainTasksAndProcessesActive = page == MainMenuPage.tasksAndFlows;
    this.documentsActive = page == MainMenuPage.documents;
    this.applicationsActive = page == MainMenuPage.applications;
    this.userSettingsActive = page == MainMenuPage.settings;
    this.adminActive = page == MainMenuPage.admin;
    this.helpActive = page == MainMenuPage.help;
  }


  show() {
    this.visible = true;


  }

  hide() {
    this.visible = false;

  }

  showMainMenu() {
    this.menuEventBus.mainMenuOpenRequested();
  }
}
