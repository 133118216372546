<div class="error" *ngFor="let exception of passwordExceptions">{{exception}}</div>
<div class="theme-input-box-with-color-icon-large">
  <i class="mi-lock"></i>
  <input type="password" placeholder="{{'emailConfirm_form_password_placeholder' | i18n}}"
         required="required" [focus-on-show]="true"
         [(ngModel)]="form.newPassword" (blur)="validatePassword()" (change)="checkIfPasswordsMatchNow()">
</div>

<my-password-meter [password]="form.newPassword" [validatePasswordPolicy]="validatePasswordPolicy" />

<div class="error" *ngFor="let exception of repeatPasswordExceptions">{{exception}}</div>
<div class="theme-input-box-with-color-icon-large">
  <i class="mi-lock"></i>
  <input class="token" type="password" placeholder="{{'emailConfirm_form_password_repeat_placeholder' | i18n}}" required="required"
         [(ngModel)]="form.newRepeatPassword" (blur)="validatePasswords()" (change)="checkIfPasswordsMatchNow()">
</div>

<button class="submitButton theme-button-confirm-with-icon" (click)="setPassword()" [disabled]="!form.isValid">
  <i class="mi-check"></i>{{'emailConfirm_form_submit_button' | i18n}}
</button>

