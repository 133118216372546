import {AggregateId, AggregateVersion, GroupId} from "@utils";
import {Option, PersonId} from "@utils";

export class CreateDepartment {
  constructor(readonly name: string,
              readonly parentId: AggregateId,
              readonly director: Option<AggregateId>) {}
}

export class UpdateDepartmentInfo {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newName: string,
              readonly newDirector: Option<PersonId>,
              readonly newParentId: AggregateId|undefined) {}
}

export class ChangeDepartmentParent {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newParent: AggregateId) {}
}

export class AddDepartmentToGroup {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {}
}

export class RemoveDepartmentFromGroup {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {}
}

export class DeleteDepartment {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion) {}
}
