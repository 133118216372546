import {Component, OnInit} from "@angular/core";
import {LocalStorageService, NavigationService, SessionServiceProvider, UserSettingsStateService} from "@shared";
import {required, toastr} from "@utils";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: "./login-entra-id-page.component.html"
})
export class LoginEntraIdPageComponent implements OnInit {

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly navigationService: NavigationService,
              readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.sessionServiceProvider.getSessionService(sessionService => {

        const params=  this.navigationService.urlSearchParams();
        const code = params["code"];
        const sessionState = params["sessionState"];

      const entraIdIdentifier = required(this.activatedRoute.snapshot.paramMap.get("entraIdIdentifier"), "entraIdIdentifier");
      sessionService.loginToOrganizationUsingEntraId(entraIdIdentifier, code, () => {
          localStorage.setItem("entraIdLogin", entraIdIdentifier);
          this.navigationService.navigateToLastRequestedPageOrMainPage(["/login-entra-id"]);
        }, () => {
          localStorage.removeItem("entraIdLogin");
          this.navigationService.navigateToLoginPage()
          toastr.info("Login using Entra ID failed");
        })



    });

  }
}
