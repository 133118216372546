import {
  __,
  ___,
  AnyPersonId,
  AnyPersonIdHelper, arrayReplace,
  FileUri,
  LocalDateTime,
  None,
  now,
  Option,
  OrganizationNodeId,
  PersonId,
  required,
  Some
} from "@utils";
import {
  DirInfo,
  FileBasicInfo,
  FileInfo,
  FileNotFound,
  SessionServiceProvider,
  ViewableFile,
  ViewableFileUrl
} from "@shared";
import {FilesSharedService, PersonsSharedService, ProcessFlowComment} from "..";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable, Subscription} from "rxjs";

export class CommentAttachmentViewModel {

  nameWithoutExtension: string;
  extension?: string;

  constructor(public name: string,
              public uri: Option<FileUri>,
              public url: ViewableFileUrl,
              public size: number,
              public version: number,
              public loaded: boolean,
              public id: number,
              public modified: LocalDateTime) {
    const nameDividedByDots = this.name.split(".");
    const extension = nameDividedByDots.pop();
    if (!extension) {
      console.warn(`Extension for file ${this.name} cannot be parsed`);
    } else {
      this.extension = extension;
    }
    this.nameWithoutExtension = nameDividedByDots.join(".");
  }

  static copy(other: CommentAttachmentViewModel) {
    return new CommentAttachmentViewModel(other.name, Option.copy(other.uri).map(FileUri.copy), other.url, other.size,
      other.version, other.loaded, 0, LocalDateTime.copy(other.modified));
  }
}

export class CommentsViewModel {

  commentsChanged = new EventEmitter<Array<CommentViewModel>>;


  constructor(readonly comments: Array<CommentViewModel>) {
  }

  pushNewComment(newComment: CommentViewModel) {
    this.comments.push(newComment);
    this.commentsChanged.emit(this.comments);
  }

  updateTempCommentId(commentTempId: number, commentId: number) {
    this.comments.forEach(comment => {
      if (comment.commentId === commentTempId) {
        comment.commentId = commentId;
      }
    });
    this.commentsChanged.emit(this.comments);
  }

  lastCommentId() {
    return ___(this.comments).map(c => c.commentId).maxOrZero();
  }

  replaceComments(comments: Array<CommentViewModel>) {
    arrayReplace(this.comments, comments);
    this.commentsChanged.emit(this.comments);
  }
}


export class CommentViewModel {
  public attachmentsInfo: Option<Array<CommentAttachmentViewModel>> = None();
  public editMode: boolean = false;
  public deleteMode: boolean = false;
  public editedCommentText = "";

  public currentUserComment: boolean = false;

  constructor(public commentId: number,
              public authorNode: OrganizationNodeId|undefined,
              public commentText: string,
              public attachments: Array<FileUri>,
              public extraRecipients: Array<AnyPersonId>,
              public created: LocalDateTime,
              public modified: LocalDateTime|null,
              public deleted: boolean) {
    this.editedCommentText = commentText;
  }

  isValid(): boolean {
    return this.commentText.length > 0;
  }

  setEmpty(): void {
    this.commentText = "";
    this.authorNode = undefined;
    this.attachments = [];
    this.extraRecipients = [];
    this.created = LocalDateTime.now();
    this.deleted = false;
  }

  markAsDeleted(): void {
    this.commentText = "";
    this.attachments = [];
    this.extraRecipients = [];
    this.deleted = true;
  }

  static empty(): CommentViewModel {
    return new CommentViewModel(-1, undefined, "", [], [], now(), null, false);
  }


  static of(comment: ProcessFlowComment): CommentViewModel {

    const created = comment.previousCommentVersions.length == 0 ? comment.created : comment.previousCommentVersions[0].created;
    const modified = comment.previousCommentVersions.length == 0 ? null : comment.created;

    return new CommentViewModel(comment.commentId, comment.personUnwrapped().filter(p => p.isPersonId()).map(p => OrganizationNodeId.fromPersonId(p.asPersonId())).getOrUndefined(), comment.commentText, comment.attachments,
      comment.extraRecipientsUnwrapped(), created, modified, comment.deleted);
  }

  setAuthor(author: OrganizationNodeId|undefined) {
    this.authorNode = author;
  }

  isCurrentPerson(currentPersonId: AnyPersonId) {
    return this.authorNode && this.authorNode.isPerson() && AnyPersonIdHelper.equals(this.authorNode.asPerson(), currentPersonId);
  }

  changeComment(comment: string) {
    this.commentText = comment.trim();
  }
}


@Component({
  selector: "my-comments",
  templateUrl: "./comments.component.html"
})
export class CommentsComponent implements OnInit {

  @Input() uploadFileUrl: string = "";
  @Input() readOnly = false;
  @Input() canEdit = false;
  @Input() attachments = true;
  @Input() notifications = true;
  @Input() uploadParams = {};
  @Input() scrollToBottom = false;

  private initiated = false;

  sortedComments: Array<CommentViewModel> = [];

  private _comments!: CommentsViewModel;
  private subscription?: Subscription;

  get comments(): CommentsViewModel {
    return this._comments;
  }

  @Input() set comments(value: CommentsViewModel) {
    this._comments = value;
    if (this.initiated) {
      this.onCommentsChange();
    }
  }

  @Input() attachmentDownloadUrl: ((fileInfo: FileUri) => ViewableFileUrl)|undefined;

  loaded = false;
  currentPersonId!: AnyPersonId;

  activated = false;
  focused = false;

  optionsMenuAnchor: HTMLElement|undefined = undefined;
  optionsMenuComment: CommentViewModel|undefined = undefined;
  optionsMenuVisible: boolean = false;

  fileViewerIndex = -1;
  fileViewerVisible = false;
  viewableFiles: Array<ViewableFile> = [];

  @Output() commentAdded = new EventEmitter<CommentViewModel>();
  @Output() commentChanged = new EventEmitter<CommentViewModel>();
  @Output() commentDeleted = new EventEmitter<CommentViewModel>();

  @Input() lastCommentReloaded?: Observable<CommentViewModel>;


  constructor(readonly filesSharedService: FilesSharedService,
              readonly personsSharedService: PersonsSharedService,
              readonly sessionServiceProvider: SessionServiceProvider) {

    sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      this.currentPersonId = PersonId.of(sessionInfo.personId);
    });
  }

  ngOnInit(): void {
    this.onCommentsChange();
    this.initiated = true;
  }

  onCommentsCollectionChange() {
    this.updateSelfInfo();
    this.updateFilesInfo();
    this.sortedComments = __(this._comments.comments).sortBy(v => -v.created.asMillis());
  }

  toggleOptionsMenu(event: MouseEvent, comment: CommentViewModel) {
    this.optionsMenuAnchor = event.currentTarget as HTMLElement;
    this.optionsMenuVisible = !this.optionsMenuVisible;
    this.optionsMenuComment = comment;
  }

  closeOptionsMenu() {
    this.optionsMenuVisible = false;
    this.optionsMenuComment = undefined;
    this.optionsMenuAnchor = undefined;
  }

  private onCommentsChange() {
    this.onCommentsCollectionChange();

    this.loaded = true;
    this.cancelSubscription();
    this.subscription = this.comments.commentsChanged.subscribe(() => {
      this.onCommentsCollectionChange();
    });

  }

  private cancelSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  toggleCommentEditMode(comment: CommentViewModel) {
    comment.editMode = !comment.editMode;
    if (comment.editMode) {
      comment.editedCommentText = comment.commentText;
    }
    this.closeOptionsMenu();
  }

  toggleCommentDeleteMode(comment: CommentViewModel) {
    comment.deleteMode = !comment.deleteMode;
    this.closeOptionsMenu();
  }

  changeComment(comment: CommentViewModel) {
    if (comment.editedCommentText.trim().length === 0) {
      this.commentDeleted.emit(comment);
      comment.commentText = comment.editedCommentText;
      comment.modified = LocalDateTime.now();
      comment.deleted = true;
    } else if (comment.editedCommentText.trim() !== comment.commentText.trim()) {
      comment.commentText = comment.editedCommentText;
      comment.modified = LocalDateTime.now();
      this.commentChanged.emit(comment);
    }
    comment.editMode = false;
  }

  deleteComment(comment: CommentViewModel) {
    this.commentDeleted.emit(comment);
    comment.deleted = true;
    comment.deleteMode = false;
  }

  private updateSelfInfo() {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      const currentPerson = PersonId.of(sessionInfo.personId);
      this.comments.comments.forEach(comment => {
        comment.currentUserComment = comment.authorNode !== undefined && comment.authorNode.isPerson() && AnyPersonIdHelper.equals(comment.authorNode.asPerson(), currentPerson);
      });
    });

  }

  private updateFilesInfo() {

    const allAttachments = __(this.comments.comments).flatMap(c => c.attachments);
    this.filesSharedService.filesInfo(allAttachments, (files: Array<FileBasicInfo>) => {

      this.comments.comments.forEach(comment => {
        comment.attachmentsInfo = Some(comment.attachments.map(uri => {
          const fileInfo = __(files).find(f => f.uri.isEqual(uri)).get();
          return this.toCommentAttachmentInfo(fileInfo);
        }));
      });

    });
  }

  private toCommentAttachmentInfo(file: FileBasicInfo) {
    if (file instanceof FileInfo) {
      return new CommentAttachmentViewModel(file.name, Some(file.uri), required(this.attachmentDownloadUrl, "attachmentDownloadUrl")(file.uri), file.size, file.version, true, 0, file.modified);
    } else if (file instanceof DirInfo) {
      throw new Error("File expected");
    } else if (file instanceof FileNotFound) {
      if (file.uri.isAnyFile()) {
        return new CommentAttachmentViewModel("", Some(file.uri), ViewableFileUrl.empty, 0, 0, true, 0, now());
      } else {
        throw new Error("File expected");
      }
    } else {
      throw new Error("Unsupported file info type");
    }
  }

  onFileViewerClosed = () => {
    this.fileViewerVisible = false;
  };

  onShowAttachments($event: {viewableFiles: Array<ViewableFile>, fileIndex: number}) {
    this.viewableFiles = $event.viewableFiles;
    this.fileViewerIndex = $event.fileIndex;
    this.fileViewerVisible = true;
  }

  onCommentAdded($event: CommentViewModel) {
    this.commentAdded.emit($event);
    this.comments.pushNewComment($event);
  }
}
