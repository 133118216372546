import {ToastrEventBus} from "./ToastrEventBus";
import {global, NoneSingleton, Some} from "@utils";
import {Injectable} from "@angular/core";
import {NavigationService} from "@shared";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";


@Injectable({
  providedIn: "root"
})
export class ToastrService {

  private messages: Array<string> = [];
    eventBus = new ToastrEventBus();

  private constructor(private readonly router: Router) {
    global.toastrService = this;

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.clearOldMessages();
      }
    });
  }

  info(message: string, tag: string): void {
    this.eventBus.infoAdded(message, tag, NoneSingleton);
  }

  warning(message: string, tag: string): void {
    this.eventBus.warningAdded(message, tag, NoneSingleton);
  }

  error(message: string, tag: string): void {
    this.eventBus.errorAdded(message, tag, NoneSingleton);
  }

  success(message: string, tag: string): void {
    this.eventBus.successAdded(message, tag, NoneSingleton);
  }

  clearOldMessages(): void {
    this.eventBus.clearOldMessagesSent();
  }

  clearMessagesWithTag(tag: string): void {
    this.eventBus.clearMessagesWithTagSent(tag);
  }

  infoWithTimeout(message: string, timeout: number, tag: string) {
    this.eventBus.infoAdded(message, tag, Some(timeout));
  }
}
