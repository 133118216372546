import {Injectable, Injector} from "@angular/core";
import {CustomThemeSettings} from "./system-settings.service";
import {Theme, UserSettingsStateService} from "../user-settings/user-settings.state-service";
import {clearArray, Option} from "@utils";

@Injectable({
  providedIn: 'root',
})
export class AppSettingsController {


  constructor(readonly injector: Injector) {}

  readonly themeColorStack: Array<string> = [];


  applyCustomThemeSettingsToThePage(settings: CustomThemeSettings) {

    const theme = this.userSettingsStateService.getEffectiveTheme();
    const darkTheme = theme === Theme.dark;

    const tag = document.getElementById("customCssVariables");
    if(tag !== null) {
      tag.innerHTML = settings.toCss(darkTheme);
    } else {
      throw new Error("No custom style tag found");
    }

    this.applyFaviconColor(settings.accentColorHex());

    clearArray(this.themeColorStack);
    this.returnToPreviousTheme();

  }


  private applyFaviconColor(colorHex: string) {

    localStorage.setItem("accentColor", colorHex);
    const svgFavicon = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35'>"
      +"<path d='M0 18.9158C0 25.7 4.167 30 10.4183 30C16.6693 30 20.8366 23.5613 20.8366 23.5613C20.8366 23.5613 5.35037 7.822 0 2.09863V18.92Z' fill='%23"+colorHex.substring(1)+"'/>"
      +"<path d='M30 11.084C30 4.29253 25.8328 0 19.5819 0C13.3309 0 9.16357 6.4388 9.16357 6.4388C9.16357 6.4388 24.6498 22.1781 30 27.9015V11.0843Z' fill='%231F1646'/>"
      +"</svg>";

    const favicon = <HTMLLinkElement>document.getElementById('favicon');
    if(favicon) {
      favicon.href = `data:image/svg+xml;utf8,${svgFavicon}`;
    }
  }

  private get userSettingsStateService(): UserSettingsStateService {
    return this.injector.get(UserSettingsStateService);
  }

  changeBrowserThemeToDarkBackground() {
    this.changeBrowserThemeToCustomColor("#47474b", "#050607");  // #050607 && #47474b are dark popup glass over gray background (from screenshot)
  }


  changeBrowserThemeToLightBackground() {
    this.changeBrowserThemeToCustomColor("#bdbec0", "#0a0c0e");
  }

  private changeBrowserThemeToCustomColor(lightColor: string, darkColor: string) {
    const theme = this.userSettingsStateService.getEffectiveTheme();
    const darkTheme = theme === Theme.dark;
    this.setBrowserTheme(darkTheme ? darkColor : lightColor);
  }

  private setBrowserTheme(color: string) {
    const themeColorTag = document.querySelector('meta[name="theme-color"]')
    if(themeColorTag) {
      this.themeColorStack.push(color);
      themeColorTag.setAttribute('content', color);
    }
  }

  returnToPreviousTheme() {

    if (this.themeColorStack.length > 0) {
      this.themeColorStack.pop();
    }

    const theme = this.userSettingsStateService.getEffectiveTheme();
    const darkTheme = theme === Theme.dark;

    const baseColor = darkTheme ? "#15181C" : "#f2f5f7"; // default light background: fafbfc

    const themeColorTag = document.querySelector('meta[name="theme-color"]')
    if(themeColorTag) {
      if (this.themeColorStack.length > 0) {
        const color = this.themeColorStack[this.themeColorStack.length - 1];
        if(color) {
          themeColorTag.setAttribute('content', color);
        }
      } else {
        themeColorTag.setAttribute('content', baseColor);
      }
    }
  }

}
