import {$$, $$Element, $$tag, clearArray, None, Option, required, Some} from "@utils";

export class CachedLine {

  constructor(public text:string,
              readonly y:number,
              public dx:number,
              public dy:number,
              public width: number,
              public height: number) {}
}

const wrapperCache: {[key: string]: CachedLine[]} = {};
const wrapperCacheQueue: Array<string> = [];
const MAX_CACHE_SIZE = 2000;

export enum Horizontal {left, center, right}

export enum Vertical {top, middle, bottom}

let widthTestCtx: CanvasRenderingContext2D|null = null;


export class WrapperConfig {
  _horizontal: Horizontal = Horizontal.left;
  _vertical: Vertical = Vertical.top;
  _multiLine: boolean = true;
  _arialFont: boolean = false;
  _letterSpacing: string|undefined = undefined;
  _height: number = 10000;
  _width: number = 10000;
  _horizontalMargin: number = 0;
  _lineHeight = 14;
  _fontSize = 13;
  _cacheKey: Option<string> = None();
  _addDotsIfTruncated = true;

  _dynamicWidth: Option<(y: number) => number> = None();

  _splitLinesBy: Option<RegExp|string> = None();

  constructor(readonly cacheMasterKey: string = "") {}

  static create(cacheMasterKey: string = "") {
    return new WrapperConfig(cacheMasterKey);
  }

  _calculateWidth(y: number): number {
    return this._dynamicWidth.map(dw => dw(y)).getOrElse(this._width);
  }

  horizontal(_horizontal: Horizontal): WrapperConfig {
    this._horizontal = _horizontal;
    return this;
  }

  left(): WrapperConfig {
    this._horizontal = Horizontal.left;
    return this;
  }

  center(): WrapperConfig {
    this._horizontal = Horizontal.center;
    return this;
  }

  right(): WrapperConfig {
    this._horizontal = Horizontal.right;
    return this;
  }

  top(): WrapperConfig {
    this._vertical = Vertical.top;
    return this;
  }

  horizontalMargin(horizontalMargin: number): WrapperConfig {
    this._horizontalMargin = horizontalMargin;
    return this;
  }

  middle(): WrapperConfig {
    this._vertical = Vertical.middle;
    return this;
  }

  bottom(): WrapperConfig {
    this._vertical = Vertical.bottom;
    return this;
  }


  vertical(_vertical: Vertical): WrapperConfig {
    this._vertical = _vertical;
    return this;
  }

  multiLine(): WrapperConfig {
    this._multiLine = true;
    return this;
  }

  singleLine(): WrapperConfig {
    this._multiLine = false;
    return this;
  }

  arialFont(): WrapperConfig {
    this._arialFont = true;
    return this;
  }

  height(h: number): WrapperConfig {
    this._height = h;
    return this;
  }

  width(w: number): WrapperConfig {
    this._width = w;
    return this;
  }

  dynamicWidth(widthFunction: (y: number) => number): WrapperConfig {
    this._dynamicWidth = Some(widthFunction);
    return this;
  }

  cacheKey(key: string): WrapperConfig {
    this._cacheKey = Some(key);
    return this;
  }

  lineHeight(height: number): WrapperConfig {
    this._lineHeight = height;
    return this;
  }

  fontSize(size: number): WrapperConfig {
    this._fontSize = size;
    return this;
  }

  splitLinesBy(splitBy: RegExp|string): WrapperConfig {
    this._splitLinesBy = Some(splitBy);
    return this;
  }

  noDots(): WrapperConfig {
    this._addDotsIfTruncated = false;
    return this;
  }
  letterSpacing(letterSpacing: string): WrapperConfig {
    this._letterSpacing = letterSpacing;
    return this;
  }
}

export function wrapText(texts: Array<$$Element>, config: WrapperConfig) {

  if(config._width < 0) {
    throw new Error("Incorrect negative width " + config._width);
  }

  if(config._height < 0) {
    throw new Error("Incorrect negative height " + config._height);
  }

  texts.forEach((text) => {
    if(text.text().trim().length > 0) {
      wrapSingleText(text, config);
    }
  });

}


function wrapSingleText(text: $$Element, config: WrapperConfig) {

  const originalText = text.text();

  if(config._cacheKey.isDefined() && wrapperCache[config.cacheMasterKey+"|"+config._cacheKey.get()+"|"+originalText]) {
    const fromCache = wrapperCache[config.cacheMasterKey+"|"+config._cacheKey.get() + "|" + originalText];
    createFromCache(text, fromCache, config);
  } else {
    const calculated = wrapSingleTextNoCache(originalText, config);
    createFromCache(text, calculated, config);
  }

}


export function wrapSingleStringText(text: string, config: WrapperConfig): CachedLine[] {
  if(text.trim().length === 0) {
    return [];
  } else if(config._cacheKey.isDefined() && wrapperCache[config.cacheMasterKey+"|"+config._cacheKey.get()+"|"+text]) {
    return wrapperCache[config.cacheMasterKey+"|"+config._cacheKey.get() + "|" + text];
  } else {
    return wrapSingleTextNoCache(text, config);
  }
}


function wrapSingleTextNoCache(originalText: string, config: WrapperConfig): CachedLine[] {

  const lines =  config._splitLinesBy.isDefined()
                 ? originalText.trim().split(config._splitLinesBy.get())
                 : [originalText];

  const words = lines.map(line => line.trim().split(/\s+/));

  const maxLines = Math.floor(config._height / config._lineHeight);

  if(config._dynamicWidth.isDefined()) {
    if(config._splitLinesBy.isEmpty()) {
      return wrapSingleTextNoCacheDynamicWidth(words[0], maxLines, config, originalText);
    } else {
      throw new Error("Dynamic with multiline not supported");
    }
  } else {
    if (config._splitLinesBy.isDefined()) {
      console.log(words);
      return positionMultilineTextConstantWidth(words, maxLines, config, originalText);
    } else {
      return wrapSingleTextConstantWidth(words[0], maxLines, config, originalText);
    }
  }

}

function wrapSingleTextConstantWidth(wordsPrototype:Array<string>, maxLines:number, config: WrapperConfig, originalText:string): CachedLine[]{
  let words = wordsPrototype.slice();
  let currentWord = 0;
  let currentLine = 0;

  const tspans: CachedLine[] = [];

  while(currentWord < words.length && currentLine < maxLines) {

    const tspan = new CachedLine("", lineY(currentLine, config), 0, config._lineHeight, 0, config._lineHeight);

    tspans.push(tspan);

    const maxLineWidth = config._calculateWidth(0) - config._horizontalMargin * 2;
    let lineFillResult = fillLineWithWholeWords(currentWord, words, tspan, config, currentLine === maxLines - 1, maxLineWidth);

    let filledLineWidth = lineFillResult.lineWidth;
    if(lineFillResult.wordsConsumed === 0) {
      const partialWordLineFIllResult = fillLineWithPartOfWord(words[currentWord], tspan, config, currentLine === maxLines - 1, maxLineWidth)
      filledLineWidth = partialWordLineFIllResult.lineWidth;
      words[currentWord] = partialWordLineFIllResult.wordRemaining;
    }


    tspan.width = filledLineWidth;
    positionHorizontally(tspan, filledLineWidth, config);

    currentWord += lineFillResult.wordsConsumed;

    currentLine++;

  }
  positionVertically(currentLine, config, tspans);

  if(config._cacheKey.isDefined()) {
    saveToCache(config.cacheMasterKey+"|"+config._cacheKey.get()+"|"+originalText, tspans);
  }
  return tspans;
}

function positionMultilineTextConstantWidth(linesPrototype:Array<Array<string>>, maxLines:number, config: WrapperConfig, originalText:string): CachedLine[] {

  let currentTextLine = 0;

  let linesToTry = 0;
  const tspans: CachedLine[] = [];
  let currentProducedLine = 0;
  while(currentTextLine < linesPrototype.length) {
    let wordsPrototype = linesPrototype[currentTextLine];

    const words = wordsPrototype.slice();
    let currentWord = 0;




    while(currentWord < words.length && currentProducedLine < maxLines) {

      const tspan = new CachedLine("", lineY(currentProducedLine, config), 0, config._lineHeight, 0, config._lineHeight);

      tspans.push(tspan);

      const maxLineWidth = config._calculateWidth(0) - config._horizontalMargin * 2;
      let lineFillResult = fillLineWithWholeWords(currentWord, words, tspan, config, currentProducedLine === maxLines - 1, maxLineWidth);

      let filledLineWidth = lineFillResult.lineWidth;
      if(lineFillResult.wordsConsumed === 0) {
        const partialWordLineFIllResult = fillLineWithPartOfWord(words[currentWord], tspan, config, currentProducedLine === maxLines - 1, maxLineWidth)
        filledLineWidth = partialWordLineFIllResult.lineWidth;
        words[currentWord] = partialWordLineFIllResult.wordRemaining;
      }


      tspan.width = filledLineWidth;
      positionHorizontally(tspan, filledLineWidth, config);

      currentWord += lineFillResult.wordsConsumed;

      currentProducedLine++;

    }

    currentTextLine++;
  }

  positionVertically(linesToTry, config, tspans);

  if(config._cacheKey.isDefined()) {
    saveToCache(config.cacheMasterKey+"|"+config._cacheKey.get()+"|"+originalText, tspans);
  }

  return tspans;
}

function wrapSingleTextNoCacheDynamicWidth(wordsPrototype:Array<string>, maxLines:number, config: WrapperConfig, originalText:string): CachedLine[] {
  let currentWord = 0;


  let linesToTry = 0;

  const tspans: CachedLine[] = [];

  while(currentWord < wordsPrototype.length && linesToTry < maxLines) {
    linesToTry++;
    currentWord = 0;
    let currentLine = 0;
    const words = wordsPrototype.slice();

    clearArray(tspans);

    while(currentLine < linesToTry && currentWord < words.length) {
      const tspan = new CachedLine("", lineY(currentLine, config), 0, config._lineHeight, 0, config._lineHeight);

      tspans.push(tspan);

      let currentLineBottomY = 0;
      const lineHeightPx = config._lineHeight;
      switch (config._vertical) {
        case Vertical.top: currentLineBottomY = lineHeightPx * currentLine; break;
        case Vertical.middle: currentLineBottomY = config._height / 2 - linesToTry * lineHeightPx / 2 + lineHeightPx * currentLine; break;
        case Vertical.bottom: currentLineBottomY = config._height - lineHeightPx * (currentLine + 1); break;
      }

      const currentLineMiddleY = currentLineBottomY + lineHeightPx / 2;
      const maxLineWidth = config._calculateWidth(currentLineMiddleY) - config._horizontalMargin * 2;
      const lineFillResult = fillLineWithWholeWords(currentWord, words, tspan, config, currentLine === maxLines - 1, maxLineWidth);

      let filledLineWidth = lineFillResult.lineWidth;
      if(lineFillResult.wordsConsumed === 0) {
        const partialWordLineFIllResult = fillLineWithPartOfWord(words[currentWord], tspan, config, currentLine === maxLines - 1, maxLineWidth);
        filledLineWidth = partialWordLineFIllResult.lineWidth;
        words[currentWord] = partialWordLineFIllResult.wordRemaining;
      }

      tspan.width = filledLineWidth;

      positionHorizontally(tspan, filledLineWidth, config);

      currentWord += lineFillResult.wordsConsumed;

      currentLine++;

    }
  }

  positionVertically(linesToTry, config, tspans);

  if(config._cacheKey.isDefined()) {
    saveToCache(config.cacheMasterKey+"|"+config._cacheKey.get()+"|"+originalText, tspans);
  }

  return tspans;
}

function positionHorizontally(tspan: CachedLine, filledLineWidth: number, config: WrapperConfig){
  switch(config._horizontal) {
    case Horizontal.left: tspan.dx = config._horizontalMargin; break;
    case Horizontal.center: tspan.dx = (config._width - filledLineWidth) / 2; break;
    case Horizontal.right: tspan.dx = (config._width - config._horizontalMargin - filledLineWidth); break;
  }
}


function createFromCache(text: $$Element, lines: CachedLine[], config: WrapperConfig) {
  const x = parseInt(required(text.attr("x"), "x"));
  const y = parseInt(required(text.attr("y"), "x"));
  text.text("");
  lines.forEach(line => {
    text.appendChild($$tag("tspan"))
      .style("font-size", config._fontSize+"px")
      .attr("x", x)
      .attr("y", y + line.y)
      .attr("dy", line.dy+"px")
      .attr("dx", line.dx+"px")
      .text(line.text)
  });
}

function positionVertically(linesCount:number, config:WrapperConfig, tspans: CachedLine[]) {
  const currentHeight = linesCount * config._lineHeight;
  let shift = 0;
  switch (config._vertical) {
    case Vertical.top: break;
    case Vertical.middle: shift = (config._height - currentHeight) / 2 / config._lineHeight; break;
    case Vertical.bottom: shift = (config._height - currentHeight) / config._lineHeight; break;
  }
  if (shift !== 0) {
    tspans.forEach(tspan => tspan.dy = (1 + shift) * config._lineHeight);
  }
}


function lineY(currentLine: number, config: WrapperConfig) {
  return config._lineHeight * currentLine;
}

class WholeWordsLineFIllResult {
  wordsConsumed: number;
  lineWidth: number;

  constructor(wordsConsumed:number, lineWidth:number) {
    this.wordsConsumed = wordsConsumed;
    this.lineWidth = lineWidth;
  }
}


class PartialWordLineFIllResult {
  wordRemaining: string;
  lineWidth: number;

  constructor(wordRemaining:string, lineWidth:number) {
    this.wordRemaining = wordRemaining;
    this.lineWidth = lineWidth;
  }
}

function fillLineWithWholeWords(currentWord: number, words: Array<string>, tspan: CachedLine, config: WrapperConfig, lastLine: boolean, maxLineWidth: number): WholeWordsLineFIllResult {
  // Fill line
  let wordsConsumed = 0;
  let lineContent = "";
  let previousLineContent = "";
  let currentLineWidth = 0;
  while(currentWord + wordsConsumed < words.length && currentLineWidth < maxLineWidth) {
    previousLineContent = lineContent;
    if(lineContent.length === 0) {
      lineContent += words[currentWord + wordsConsumed];
    } else {
      lineContent += " " + words[currentWord + wordsConsumed];
    }
    wordsConsumed++;


    currentLineWidth = widthOfText(lineContent, config._fontSize+"px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);
  }

  if(currentLineWidth > maxLineWidth) {
    lineContent = previousLineContent;
    currentLineWidth = widthOfText(lineContent, config._fontSize+"px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);
    wordsConsumed = Math.max(0, wordsConsumed - 1);
  }


  if(config._addDotsIfTruncated && lastLine && currentWord + wordsConsumed < words.length && wordsConsumed > 0) {
    lineContent += " " + words[currentWord + wordsConsumed]; // add part of additional word before dots
    currentLineWidth = widthOfText(lineContent, config._fontSize+"px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);
    while(lineContent.length > 0 && currentLineWidth > maxLineWidth) {
      do {
        lineContent = lineContent.substring(0, lineContent.length - 1);
      } while (lineContent.charAt(lineContent.length - 1).trim().length === 0); //last char a is whitespace

      currentLineWidth = widthOfText(lineContent, config._fontSize+"px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);
    }
  }

  tspan.text = lineContent;

  return new WholeWordsLineFIllResult(wordsConsumed, currentLineWidth);
}


function fillLineWithPartOfWord(word: string, tspan: CachedLine, config: WrapperConfig, lastLine: boolean, maxLineWidth: number): PartialWordLineFIllResult {

  let lineContentPartial = word;
  let lineContent: string;

  if(lastLine && config._addDotsIfTruncated) {
    lineContent = lineContentPartial+"...";
  } else {
    lineContent = lineContentPartial;
  }

  let currentLineWidth = widthOfText(lineContent, config._fontSize+"px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);
  let charsConsumed = Math.ceil(word.length * maxLineWidth / currentLineWidth); // this optimization might generate a little shorter lines than optimal

  if(maxLineWidth > 0) {
    do {
      charsConsumed--;
      lineContentPartial = word.substr(0, charsConsumed);
      if (lastLine && config._addDotsIfTruncated) {
        lineContent = lineContentPartial + "...";
      } else {
        lineContent = lineContentPartial;
      }

      currentLineWidth = widthOfText(lineContent, config._fontSize + "px", config._arialFont ? "sofia-pro, Arial" : undefined, config._letterSpacing);

      if (charsConsumed < -10) {
        throw new Error("Too long word to fit in line");
      }

    } while (currentLineWidth > maxLineWidth && maxLineWidth > 0);
  }
  tspan.text = lineContent;

  return new PartialWordLineFIllResult(word.substr(charsConsumed), currentLineWidth);
}

function saveToCache(cacheKey: string, cachedLines: CachedLine[]) {
  wrapperCache[cacheKey] = cachedLines;
  wrapperCacheQueue.push(cacheKey);
  if(wrapperCacheQueue.length > MAX_CACHE_SIZE) {
    const removedKey = wrapperCacheQueue.shift();
    delete wrapperCache[required(removedKey, "removedKey")];
  }
}

function widthOfText(text: string, fontSize: string, fontFamily: string|undefined, letterSpacing: string|undefined): number {
  if(widthTestCtx == null) {
    $$(document.body).appendChild($$("<canvas style='visibility:hidden;display:inline-block;position:fixed' class='widthTest'></canvas>"));
    widthTestCtx = (<HTMLCanvasElement>$$(document).findOrError(".widthTest").getAsHtmlElement()).getContext("2d");
  }

  if(letterSpacing) {
    try {
      (<any>widthTestCtx!).letterSpacing = letterSpacing;
    } catch (e) {
      // ignore, as this might not be supported
    }
  }
  widthTestCtx!.font = fontFamily ? (fontSize+" "+fontFamily) : fontSize;
  return widthTestCtx!.measureText(text).width;

}
