import {i18n, LocalDate, Option, required} from "@utils";
import {BusinessVariable} from "@shared-model";
import {CalendarComponent, CalendarEntryModel} from "./calendar.component";
import {CalendarEventBus} from "./CalendarEventBus";
import {CalendarHelper} from "./CalendarHelper";

export class MonthlyCalendarSingleEntry {

    constructor(
      readonly model: CalendarEntryModel|null,
      public index: number,
      readonly startDate: LocalDate|null,
      public endDate: LocalDate|null,
      public daysSpan: number,
      public cssWidth: string,
      readonly name: string,
      readonly color: string,
      readonly inverted: boolean,
      readonly placeholder: boolean,
      readonly openLeft: boolean,
      public openRight: boolean,
      public selected : boolean
      ) {
    }

    static mock() {
      return new MonthlyCalendarSingleEntry(null, -1, null, null, 1, "calc(100% + 1px)", "", "transparent", false, true, false, false, false);
    }
  }

  export class MonthlyCalendarDayViewModel {

    readonly id = CalendarComponent.nextPeriodId();
    public selected: boolean = false;

    constructor(
      readonly date: LocalDate,
      readonly otherMonth: boolean,
      readonly today: boolean,
      readonly off: boolean,
      public entries: Array<MonthlyCalendarSingleEntry>) {
    }

    pushEntryToEmptySpace(entry: MonthlyCalendarSingleEntry): void {
      for(let i = 0; i < this.entries.length; i++) {
        if(!this.entries[i]) {
          this.entries[i] = entry;
          entry.index = i;
          return;
        }
      }
      this.entries.push(entry);
      entry.index = this.entries.length - 1;
    }

    pushMockToIndex(index: number): void {
      if(this.entries[index]) {
        throw new Error("Entry occupied already");
      } else {
        this.entries[index] = MonthlyCalendarSingleEntry.mock();
      }
    }


    fillEmptyWithMocks() {
      for(let i = 0; i < this.entries.length; i++) {
        if (!this.entries[i]) {
          this.entries[i] = MonthlyCalendarSingleEntry.mock();
        }
      }
    }

  }

  export class MonthlyCalendarWeekViewModel {
    constructor(
      readonly days: Array<MonthlyCalendarDayViewModel>) {}
  }

  export class MonthlyCalendarDayHeader {
    constructor(readonly dayNumber: number,
                readonly name: string) {}
  }

  export class MonthlyCalendarViewModel {

    static START_OF_WEEK = 1; // monday, might be sunday also

    today: LocalDate = LocalDate.nowDate();
    year: number;
    month: number;
    monthName: string;
    weeks: Array<MonthlyCalendarWeekViewModel> = [];

    entries: Array<CalendarEntryModel> = [];

    // static colors: Array<string> = ["rgb(92, 107, 192)", "rgb(255, 235, 59)", "rgb(102, 187, 106)", "rgb(126, 87, 194)", "rgb(255, 112, 67)", "rgb(41, 182, 246)", "rgb(255, 202, 40)", "rgb(55, 173, 150)",
    //   "rgb(171, 71, 188)", "rgb(239, 83, 80)", "rgb(38, 198, 218)", "rgb(255, 167, 38)", "rgb(156, 204, 101)", "rgb(236, 64, 122)", "rgb(236, 64, 122)", "rgb(236, 64, 122)"];


    daysHeader = [new MonthlyCalendarDayHeader(1, "Poniedziałek"),
      new MonthlyCalendarDayHeader(2, "Wtorek"),
      new MonthlyCalendarDayHeader(3, "Środa"),
      new MonthlyCalendarDayHeader(4, "Czwartek"),
      new MonthlyCalendarDayHeader(5, "Piątek"),
      new MonthlyCalendarDayHeader(6, "Sobota"),
      new MonthlyCalendarDayHeader(7, "Niedziela")];

    constructor(readonly parent: CalendarComponent,
                readonly eventBus: CalendarEventBus,
                readonly onSelected: (model: CalendarEntryModel) => void) {
      this.year = this.today.year;
      this.month = this.today.month;
      this.monthName = i18n("date_month_get_"+this.month);
      this.updateView();

      eventBus.on(eventBus.entrySelected, (selectedEntry: CalendarEntryModel) => this.onEntrySelected(selectedEntry));
      eventBus.on(eventBus.periodsSelected, (from: number, to: number) => this.onPeriodsSelected(from, to));
    }

    private onEntrySelected(selectedEntry: CalendarEntryModel) {
      this.weeks.forEach(week => {
        week.days.forEach(day => {
          day.entries.forEach(entry => {
            entry.selected = entry.model === selectedEntry;
          });
        });
      });
    }

    previousMonth() {
      this.month--;
      if(this.month == 0) {
        this.month = 12;
        this.year--;
      }
      this.updateView();
    }

    nextMonth() {
      this.month++;
      if(this.month == 13) {
        this.month = 1;
        this.year++;
      }
      this.updateView();
    }

    static monthName(year: number, month: number): string {
      return i18n("date_month_get_"+month) +" "+year;
    }

    private updateView() {
      this.monthName = MonthlyCalendarViewModel.monthName(this.year, this.month);

      const monthBeginning = new LocalDate(this.year, this.month, 1);
      const firstWeekDay = LocalDate.getDayOfWeek(monthBeginning);

      this.weeks = [];

      let calendarBeginning = monthBeginning;
      if(firstWeekDay > MonthlyCalendarViewModel.START_OF_WEEK) {
        calendarBeginning = calendarBeginning.plusDays(- firstWeekDay + MonthlyCalendarViewModel.START_OF_WEEK);
      } else if(firstWeekDay < MonthlyCalendarViewModel.START_OF_WEEK) {
        calendarBeginning = calendarBeginning.plusDays(- firstWeekDay + MonthlyCalendarViewModel.START_OF_WEEK - 7 );
      }


      let currentWeek = calendarBeginning;

      // always 6 weeks (e.g. as in Windows calendar, it will be always the same size)
      for(let w = 0; w < 6; w++) {

        const days: Array<MonthlyCalendarDayViewModel> = [];

        for(let i = 0; i < 7; i++) {
          const dayDate = currentWeek.plusDays(i);
          const otherMonth = this.year != dayDate.year || this.month != dayDate.month;
          const weekDay = dayDate.weekDay();

          days.push(new MonthlyCalendarDayViewModel(dayDate, otherMonth, dayDate.isEqual(this.today), weekDay == 6 || weekDay == 0, []));
        }

        const week = new MonthlyCalendarWeekViewModel(days);
        this.weeks.push(week)

        currentWeek = currentWeek.plusDays(7);
      }

      const sortedEntries = this.entries.sort((a, b) => a.from.isEqual(b.from) ? 0 : (a.from.isBefore(b.from) ? -1 : 0));

      MonthlyCalendarViewModel.prepareWeeks(sortedEntries, this.weeks, this.parent.selectedValue);
    }


    // Used by Monthly and weekly calendar
    static prepareWeeks(sortedEntries: Array<CalendarEntryModel>, weeks: Array<MonthlyCalendarWeekViewModel>, selectedValue: Option<BusinessVariable>) {

      sortedEntries.forEach(entry => {

        const [cssColor, inverted]: [string, boolean] = Option.of(entry.entryType).map(r => r.valueToSimpleString().toLowerCase()).map(entryType => {
          const id = (CalendarHelper.hashCode(entryType) % 16 + 16) % 16; // hashCode can be negative
          return <[string, boolean]>[CalendarHelper.colors[id], CalendarHelper.inverted[id]];
        }).getOrElse(<[string, boolean]>["#bdefe5", false]);

        const entryName = entry.from.time.formattedToMinutes()+" "+entry.label;


        const entryParts: Array<MonthlyCalendarSingleEntry> = [];

        if(entry.to === null || entry.from.date.isEqual(entry.to.date)) {
          const entryPart = new MonthlyCalendarSingleEntry(entry, -1, entry.from.date, entry.from.date, 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, false, false, selectedValue.exists(s => s.isEqual(entry.value)));
          entryParts.push(entryPart);
          console.log("appending single", entryPart);
        } else {
          const to = entry.to.date;
          let date: LocalDate = entry.from.date;

          let entryPart = new MonthlyCalendarSingleEntry(entry, -1, date, date,1,  "calc(100% + 1px)", entryName, cssColor, inverted, false, false, false, selectedValue.exists(s => s.isEqual(entry.value)));
          entryParts.push(entryPart);
          console.log("appending", entryPart);
          while(date.isBefore(to)) {
            date = date.plusDays(1);
            if(date.weekDay() == MonthlyCalendarViewModel.START_OF_WEEK) {
              entryPart = new MonthlyCalendarSingleEntry(entry, -1, date, date, 1,  "calc(100% + 1px)", entryName, cssColor, inverted, false, true, date.isBefore(to), selectedValue.exists(s => s.isEqual(entry.value)));
              entryParts.push(entryPart);
              console.log("appending", entryPart);
            } else {
              entryPart.daysSpan++;
              entryPart.endDate = required(entryPart.endDate, "endDate").plusDays(1);
              entryPart.cssWidth = "calc("+100*entryPart.daysSpan +"% + "+ entryPart.daysSpan+"px)";
              entryPart.openRight = date.isBefore(to);
            }
          }
        }

        entryParts.forEach(entryPart => {

          weeks.forEach(week => {
            week.days.forEach(day => {
              const startDate = required(entryPart.startDate, "startDate");
              const endDate = required(entryPart.endDate, "endDate");
              if(day.date.isEqual(startDate)) {
                day.pushEntryToEmptySpace(entryPart);
              } else {
                if (day.date.isAfter(startDate) && !day.date.isAfter(endDate)) {
                  day.pushMockToIndex(entryPart.index);
                }
              }
            });
          })

        });

      });

      weeks.forEach(week => {
        week.days.forEach(day => {
          day.fillEmptyWithMocks();
        });
      });


    }

    updateEntries(entries: Array<CalendarEntryModel>) {
      this.entries = entries;
      this.updateView();
    }

    entrySelected(entry: MonthlyCalendarSingleEntry) {
      this.onSelected(required(entry.model, "model"));
      entry.selected = true;
    }

    private onPeriodsSelected(from: number, to: number) {

      this.weeks.forEach(week => {
        week.days.forEach(day => {
          day.selected = day.id >= from && day.id <= to;
        });
      })

    }
  }
