export class ResolvablePromise<T> {
  readonly promise: Promise<T>;
  private _resolve!: (value: (PromiseLike<T> | T)) => void;
  private _reject!: (reason?: any) => void;
  private _value: T | undefined;
  get resolved(): boolean {
    return this._value !== undefined;
  }

  get value(): T {
    if(this._value === undefined) {
      throw new Error("Promise not resolved");
    } else {
      return this._value;
    }
  }

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value: T) {
    this._value = value;
    this.resolve(value);
  }

  reject(reason: any) {
    this._reject(reason);
  }

  then(onFulfilled?: ((value: T) => (PromiseLike<T> | T)) | undefined | null, onRejected?: ((reason: any) => (PromiseLike<T> | T)) | undefined | null): Promise<T> {
    return this.promise.then(onFulfilled, onRejected);
  }

  static resolved<T>(value: T) {
    const resolvablePromise = new ResolvablePromise<T>();
    resolvablePromise.resolve(value);
    return resolvablePromise;
  }
}


export function whenPromise<T>(value: Promise<T>|T, callback: (value: T) => void) {
  if(value instanceof Promise) {
    value.then(callback);
  } else {
    callback(value);
  }
}
