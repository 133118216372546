import {$$Element, GridXY, PositionXY, toastr} from "@utils";
import {EditorCanvasModel} from "./EditorCanvasModel";
import {DesignCanvasViewPort, ProcessMapCommonViewModel} from "./ProcessMapCommonViewModel";
import {DesignMapConfig} from "./Config";

export class ProcessMapController {
  private designCanvasTransformationsElement: $$Element;
  private actorsHeader: $$Element;
  private phaseHeader: $$Element|null;
  private cornerElement: $$Element;
  private cornerElementLabel: $$Element;
  private rowContextMenuLayer: $$Element;
  private columnContextMenuLayer: $$Element;


  private dragHandleElement: $$Element;
  private dragActorHandleElement: $$Element;

  constructor(readonly viewModel: ProcessMapCommonViewModel,
              readonly config: DesignMapConfig,
              readonly canvasModel: EditorCanvasModel,
              readonly viewPort: DesignCanvasViewPort,
              readonly container: $$Element) {
    this.designCanvasTransformationsElement = container.findOrError(".designCanvasTransformations");
    this.actorsHeader = container.findOrError("svg.rowsHeaderLayer");
    this.phaseHeader = container.findOrNull("svg.mainPhaseDefinitionLayer");
    this.cornerElement = container.findOrError("svg.cornerElement");
    this.cornerElementLabel = container.findOrError("text.cornerElementLabel");
    this.rowContextMenuLayer = container.findOrError("svg.rowContextMenuLayer");
    this.columnContextMenuLayer = container.findOrError("svg.columnContextMenuLayer");

    this.dragHandleElement = container.findOrError("rect.backgroundGridRect");
    this.dragActorHandleElement = container.findOrError("rect.rowsHeaderBackground");
  }


  setPosition(position: PositionXY, updateView: boolean) {
    this.safelySetNewPosition(position);
    if(updateView) {
      this.updateViewPort();
      this.updateDragHandleView(this.viewModel.canvasModel);
    }
  }

  shiftViewByHeaderSize(headers: number) {
    this.setPosition(this.viewPort.position.shiftXY(0, this.config.columnHeaderHeight * headers), true);
  }

  private safelySetNewPosition(desiredPosition: PositionXY) {
    const maxX = this.config.rolesHeaderWidth * this.viewPort.scale;
    const minX = this.config.editorSize.width - this.canvasModel.gridWidth * this.config.cellSize.width * this.viewPort.scale;

    const maxY = this.config.columnHeaderHeight * this.viewPort.scale;
    const minY = this.config.editorSize.height - this.canvasModel.gridHeight * this.config.cellSize.height * this.viewPort.scale;

    this.viewPort.position = new PositionXY(Math.min(maxX, Math.max(desiredPosition.x, minX)),
      Math.min(maxY, Math.max(desiredPosition.y, minY)));
  }

  updateViewPort() {
    this.designCanvasTransformationsElement
      .attr("transform", "translate("+ this.viewPort.position.x + " " + this.viewPort.position.y + ")" +
        " scale("+this.viewPort.scale+")");

    this.actorsHeader.attr("x", -this.viewPort.position.x / this.viewPort.scale);

    if(this.phaseHeader === null) {
      this.phaseHeader = this.container.findOrError("svg.mainPhaseDefinitionLayer");
    }
    this.phaseHeader.attr("y", -this.viewPort.position.y / this.viewPort.scale);

    this.cornerElement
      .attr("x", -this.viewPort.position.x / this.viewPort.scale)
      .attr("y", -this.viewPort.position.y / this.viewPort.scale)
      .attr("height", this.config.columnHeaderHeight / this.viewPort.scale + 1);

    this.rowContextMenuLayer.attr("x", (-this.viewPort.position.x / this.viewPort.scale) - this.config.contextMenuIconsSize / 2);
    this.columnContextMenuLayer.attr("y", ((-this.viewPort.position.y + 5) / this.viewPort.scale));
  }

  private updateDragHandleView(editorCanvasModel: EditorCanvasModel) {
    this.dragHandleElement
      .attr("x", this.viewPort.position.x)
      .attr("y", this.viewPort.position.y)
      .attr("width", editorCanvasModel.gridWidth * this.config.cellSize.width * this.viewPort.scale)
      .attr("height", editorCanvasModel.gridHeight * this.config.cellSize.height * this.viewPort.scale);

    this.dragActorHandleElement
      .attr("width", this.config.rolesHeaderWidth)
      .attr("height", editorCanvasModel.gridHeight * this.config.cellSize.height * this.viewPort.scale);
  }

  updateView() {
    this.updateViewPort();
    this.updateBackgroundView(new GridXY(0, 0));
  }

  private updateBackgroundView(includeGridXY: GridXY):void {
    this.canvasModel.updateModel(includeGridXY);
    //
    // // this.designMapInternalEventBus.gridSizeUpdated(new GridSize(this.canvasModel.gridWidth, this.canvasModel.gridHeight));
    // this.updateDragHandleView(this.canvasModel);
    // this.backgroundGridController.updateBackgroundGridView(this.canvasModel);
    // this.phaseHeaderController.updateView();
    // this.actorsHeaderController.updateView();
  }

  setZoom(scale: number, centerX:number, centerY:number):void {
      // it can be triggered only by dragging, and then it could interfere
      // with drag handling, so we only kick this of, when scale change
      if(this.viewPort.scale != scale) {

      const canvasX = (centerX - this.viewPort.position.x) / this.viewPort.scale;
      const newX = centerX - scale * canvasX;
      const canvasY = (centerY - this.viewPort.position.y) / this.viewPort.scale;
      const newY = centerY - scale * canvasY;

      this.viewPort.scale = scale;
      this.safelySetNewPosition(new PositionXY(newX, newY));

      this.updateViewPort();
      this.updateDragHandleView(this.canvasModel);
    }
  }


}


// export class ProcessMapController {
  //
  // constructor(readonly viewModel: ProcessMapViewModel) {}
  //
  // updateView() {
  //   this.updateBackgroundView(new GridXY(0, 0));
  // }

  // updateViewPort() {
  //
  //   this.actorsHeaderController.updatePositionX(this.viewPort.position.x, this.viewPort.scale);
  //   this.phaseHeaderController.updatePositionY(this.viewPort.position.y, this.viewPort.scale);
  //   this.actorsHeaderController.updateCornerPositionX(this.viewPort.position.x, this.viewPort.scale);
  //   this.phaseHeaderController.updateCornerPositionY(this.viewPort.position.y, this.viewPort.scale);
  //   this.rowMenuController.updatePositionX(this.viewPort.position.x, this.viewPort.scale);
  //   this.columnMenuController.updatePositionY(this.viewPort.position.y, this.viewPort.scale);
  // }

// }
