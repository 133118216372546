const possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function randomString(length: number): string {
  return Array.apply(null, new Array(length)).map(function () {
    return possibleCharacters[Math.floor(Math.random() * possibleCharacters.length)];
  }).join('');
}

let estimateTextWidthCacheFontSize = -1;
let estimateTextWidthCache: { [text: string]: number } = {};

function estimateTextWidthFromCache(text: string, fontSize: number): number | undefined {
  if (estimateTextWidthCacheFontSize == fontSize) {
    return estimateTextWidthCache[text];
  } else {
    estimateTextWidthCacheFontSize = fontSize;
    estimateTextWidthCache = {};
    return undefined;
  }
}

function putInEstimateTextWidthCache(text: string, fontSize: number, estimated: number): void {
  if (estimateTextWidthCacheFontSize != fontSize) {
    estimateTextWidthCacheFontSize = fontSize;
    estimateTextWidthCache = {};
  }
  estimateTextWidthCache[text] = estimated;
}

export function estimateTextWidth(text: string, fontSize: number): number {

  const fromCache = estimateTextWidthFromCache(text, fontSize);

  if (fromCache != undefined) {
    return fromCache;
  } else {
    const testElement = document.createElement("div");
    testElement.style.visibility = "hidden";
    testElement.style.whiteSpace = "nowrap";
    testElement.style.display = "inline-block";
    testElement.style.fontSize = fontSize + "px";

    testElement.appendChild(document.createTextNode(text));
    document.body.appendChild(testElement);

    const width = testElement.clientWidth;
    document.body.removeChild(testElement);
    putInEstimateTextWidthCache(text, fontSize, width);
    return width;
  }
}


let findSubstringFittingCacheFontSize = -1;
let findSubstringFittingCache: { [cacheKey: string]: string } = {};

function findSubstringFittingFromCache(text: string, fontSize: number, maxWidth: number): string | undefined {
  if (findSubstringFittingCacheFontSize == fontSize) {
    return findSubstringFittingCache[text + "|" + maxWidth];
  } else {
    findSubstringFittingCacheFontSize = fontSize;
    findSubstringFittingCache = {};
    return undefined;
  }
}

function putInFindSubstringFittingCache(text: string, fontSize: number, maxWidth: number, substring: string): void {
  if (findSubstringFittingCacheFontSize != fontSize) {
    findSubstringFittingCacheFontSize = fontSize;
    findSubstringFittingCache = {};
  }
  findSubstringFittingCache[text + "|" + maxWidth] = substring;
}

export function findSubstringFitting(text: string, fontSize: number, maxWidth: number): string {

  const fromCache = findSubstringFittingFromCache(text, fontSize, maxWidth);
  if (fromCache != undefined) {
    return fromCache;
  } else {
    const testElement = document.createElement("div");
    testElement.style.visibility = "hidden";
    testElement.style.whiteSpace = "nowrap";
    testElement.style.display = "inline-block";
    testElement.style.fontSize = fontSize + "px";
    document.body.appendChild(testElement);

    let tmp = text;

    testElement.appendChild(document.createTextNode(tmp));
    let textWidth = testElement.clientWidth;
    let truncated = false;

    while (tmp.length > 0 && textWidth > maxWidth) {
      tmp = tmp.substr(0, tmp.length - 1);
      if (testElement.firstChild !== null) {
        testElement.removeChild(testElement.firstChild);
      }
      testElement.appendChild(document.createTextNode(tmp + "."));
      textWidth = testElement.clientWidth;
      truncated = true;
    }

    document.body.removeChild(testElement);
    const result = truncated ? (tmp + ".") : tmp;
    putInFindSubstringFittingCache(text, fontSize, maxWidth, result);
    return result;
  }


}


export function toTwoDigits(n: number) {
  return n < 10 ? '0' + n : n;
}

export function toFourDigits(n: number) {
  return n < 10 ? '000' + n : n < 100 ? '00' + n : n < 1000 ? '0' + n : n;
}

export function toNineDigits(n: number) {
return n < 10 ? '00000000' + n : n < 100 ? '0000000' + n : n < 1000 ? '000000' + n : n < 10000 ? '00000' + n : n < 100000 ? '0000' + n : n < 1000000 ? '000' + n : n < 10000000 ? '00' + n : n < 100000000 ? '0' + n : n;
}


export function toFixedLengthString(n: number, digits: number) {
  let str = n + "";
  while (str.length < digits) {
    str = "0" + str;
  }
  return str;
}

export function trim(value: string | undefined) {
  if (value) {
    return value.trim();
  } else {
    return "";
  }
}

export function escapeFilePathForUriNoEncoding(path: string) {
  return path.replace(/\+/g, "*").replace(/ /g, "+");
}

export function decodeFilePathFromUriNoDecoding(path: string | null) {
  if (path) {
    return path.replace(/\+/g, " ").replace(/\*/g, "+");
  } else {
    return "";
  }
}

export function encodeFilePath(path: string) {
  return encodeURIComponent(path).replace(/%2F/g, "/");
}

export function nameToIdentifier(name: string): string {
  return name.trim().toLowerCase().replace(/\s+/g, "_").replace(/[^a-ząćęłńóśźż0-9_$]+/g, "").replace(/^[$0-9_]+/g, "");
}

export function removeExtraWhitespaces(value: string | undefined | null) {
  return value ? value.replace(/\s\s+/g, ' ').trim() : value;
}

export function emptyIfNull(value: string | undefined | null) {
  return value ? value : "";
}

export function hashCode(text: string): number {
  let hash = 0;
  if (text.length == 0) {
    return hash;
  }
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
}

export function colorId(text: string): number {
  return Math.abs(hashCode(text)) % 16 + 1;
}

export function filterOutUtf8Icons(text: string): string {
  return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}
