<div class="input {{viewModel.cssClasses}}" [style]="viewModel.css" [class.disabled]="viewModel.disabled">
  <my-organization-node-select [mediumIcon]="true"
                               [placeholder]="viewModel.placeholder"
                               (nodeChange)="viewModel.onChange($event)"
                               [withinNodes]="viewModel.withinNodes"
                               [(node)]="viewModel.value"
                               [preview]="viewModel.preview"
                               [readOnly]="viewModel.disabled"
                               [persons]="viewModel.persons"
                               [groups]="viewModel.groups"
                               [departments]="viewModel.departments"
                               [inAllApplications]="false" />
</div>
