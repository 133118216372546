import {AggregateWrapper, ApplicationId, DocumentTypeId, None, Option, Some} from "@utils";
import {DocumentTypeInfo} from "@shared";
import {AuthenticatedHttp} from "@shared-model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class DocumentsTypesSharedService {

  private activeDocumentTypes: Option<Array<DocumentTypeInfo>> = None();

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  clearTypesNodesCache() {
    this.activeDocumentTypes = None();
  }


  loadActiveDocumentTypes(onSuccess: (documents: Array<DocumentTypeInfo>) => void) {
    if (this.activeDocumentTypes.isEmpty()) {
      this.authenticatedHttp.getPromise<Array<DocumentTypeInfo>>("documents/type/getAllActive")
        .then((response: Array<DocumentTypeInfo>) => {
          this.activeDocumentTypes = Some(response.map(d => DocumentTypeInfo.copy(d)));
          onSuccess(this.activeDocumentTypes.get());
        })
    } else {
      onSuccess(this.activeDocumentTypes.get());
    }
  }

}
