import {__, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BooleanProperty,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  HeaderProperties,
  I18nTextProperty,
  LayoutsProperties, ModelProperty,
  OptionalContextContainerDefinition,
  OptionalModelProperty,
  OptionalStringProperty,
  PaddingsProperties,
  ScreenComponentId,
  ScreenContainerRef, StringProperty
} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class ViewSwitcherViewDefinition {
  constructor(readonly id: number,
              readonly children: Array<ScreenComponentRefId>) {}


  getIdentifier(properties: ComponentProperties): StringProperty {return properties.getStringProperty("view|"+this.id+"|identifier", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};

  getHeader(properties: ComponentProperties): I18nTextProperty {return properties.getI18nTextProperty("view|"+this.id+"|header", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};
  getVisible(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("view|"+this.id+"|visible", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};
  getEditable(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("view|"+this.id+"|editable", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};
  getUncovered(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("view|"+this.id+"|uncovered", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};
  getEnabled(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("view|"+this.id+"|enabled", ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES)};

  contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("view|"+this.id+"|contentTextSize", defaultProvider)}
  contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("view|"+this.id+"|contentTextColor", defaultProvider)}
  contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("view|"+this.id+"|contentTextFont", defaultProvider)}


  layoutsProperties(properties: ComponentProperties) {
    return new LayoutsProperties("view|"+this.id+"|", properties, ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES);
  }

  paddingsProperties(properties: ComponentProperties) {
    return new PaddingsProperties("view|"+this.id+"|", properties);
  }

  backgroundsProperties(properties: ComponentProperties) {
    return new BackgroundsProperties("view|"+this.id+"|", properties);
  }

  static copy(other: ViewSwitcherViewDefinition) {
    return new ViewSwitcherViewDefinition(
      other.id,
      other.children.map(ScreenComponentRefId.copy)
    )
  }
}


export class ViewSwitcherContainerRef extends ScreenContainerRef {
  static className = "ViewSwitcherContainerRef";
  className() {
    return ViewSwitcherContainerRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: ViewSwitcherContainerRef): ViewSwitcherContainerRef {
    return new ViewSwitcherContainerRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    )
  }


  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.ofFallbacks("ViewSwitcherRef", DefaultPropertyProvider.ref);

  override defaultPropertiesProvider = ViewSwitcherContainerRef.DEFAULT_PROPERTIES;
}


export class ViewSwitcherContainerDefinition extends OptionalContextContainerDefinition {
  static className = "ViewSwitcherContainerDefinition";
  static ACTIVE_VIEW: string = "activeView";
  static VIEW_PARAM: string = "viewParam";
  className() {
    return ViewSwitcherContainerDefinition.className;
  }
  typeName() {
    return "ViewSwitcher";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules,
    public views: Array<ViewSwitcherViewDefinition>) {
    super(id, identifier, properties, validationRules,ViewSwitcherContainerDefinition.DEFAULT_PROPERTIES);
  }

  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);
  headerProperties = new HeaderProperties(this.properties);

  static copy(other: ViewSwitcherContainerDefinition): ViewSwitcherContainerDefinition {
    return new ViewSwitcherContainerDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules),
      other.views.map(ViewSwitcherViewDefinition.copy)
    )
  }

  get model(): OptionalModelProperty {return this.properties.getOptionalModelProperty("model", this.defaultPropertiesProvider)};

  get activeView(): ModelProperty {return this.properties.getModelProperty("activeView", this.defaultPropertiesProvider)}
  get viewParam(): OptionalModelProperty {return this.properties.getOptionalModelProperty("viewParam", this.defaultPropertiesProvider)}

  override getChildren(): Array<ScreenComponentRefId> {
    return __(this.views).flatMap(t => t.children);
  }

  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("ViewSwitcherDefinition", (name:string) => {

    if(name.indexOf("view|") == 0) {
      const viewSubName = name.substring(name.indexOf("|", name.indexOf("|") + 1) + 1, name.length);
      return DefaultPropertyProvider.ofFallbacks(viewSubName,
        DefaultPropertyProvider.containerPlain,
        DefaultPropertyProvider.containerPaddings,
        DefaultPropertyProvider.definitionBorders,
        DefaultPropertyProvider.definitionBackgrounds).getIfExistsOrNull(viewSubName);

    } else {
      switch (name) {
        default: return null;
      }
    }

  },
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent,
    DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionBackgrounds,
    DefaultPropertyProvider.definitionSectionHeader
  );
}
