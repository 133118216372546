import {
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  NoContextContainerDefinition,
  ScreenComponentRef
} from "@screen-common";
import {LayoutType, ScreenComponentId} from "@screen-common";
import {Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";

export class CalendarComponentRef extends ScreenComponentRef {
    static className = "CalendarComponentRef";

    className() {
      return CalendarComponentRef.className;
    }

    constructor(
      readonly id: ScreenComponentRefId,
      readonly componentId: ScreenComponentId,
      readonly applicationScreen: Option<ScreenId>,
      readonly properties: ComponentProperties
    ) {
      super();
    }

    static copy(other: CalendarComponentRef): CalendarComponentRef {
      return new CalendarComponentRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      )
    }

    static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("CalendarComponentRef",
      DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = CalendarComponentRef.DEFAULT_PROPERTIES;

  }

  export class CalendarSelectedPopupDefinition {
    constructor(readonly layout: LayoutType,
                readonly children: Array<ScreenComponentRefId>) {}

    static copy(other: CalendarSelectedPopupDefinition) {
      return new CalendarSelectedPopupDefinition(LayoutType.copy(other.layout), other.children.map(c => ScreenComponentRefId.copy(c)));
    }
  }



  export class CalendarComponentDefinition extends NoContextContainerDefinition {

    static ENTRIES = "entries";
    static SELECTED = "selected";
    static ON_SELECTED_CHANGE = "onSelectedChange";

    static className = "CalendarComponentDefinition";
    className() {
      return CalendarComponentDefinition.className;
    }
    typeName() {
      return "Calendar";
    }

    constructor(
      id: ScreenComponentId,
      identifier: Option<string>,
      properties: ComponentProperties,
      readonly selectedPopup: Option<CalendarSelectedPopupDefinition>,
      readonly actionProperties: ComponentActionProperties,
      validationRules: ComponentValidationRules) {
      super(id, identifier, properties, validationRules, DefaultPropertyProvider.ofFallbacks("DefaultDefinition", DefaultPropertyProvider.definitionComponent));
    }

    bordersProperties = new BordersProperties(this.properties);


    static copy(other: CalendarComponentDefinition): CalendarComponentDefinition {
      return new CalendarComponentDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        Option.copy(other.selectedPopup, CalendarSelectedPopupDefinition.copy),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules)
      )
    }

    getModelNames(): Array<string> {
      return [CalendarComponentDefinition.SELECTED];
    }


    static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("CalendarComponentDefinition",
      (name: string) => {switch (name) {
        default: return null;
      }},
      DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
      DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
      DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
      DefaultPropertyProvider.definitionPaddings);
  }

