import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// components
// pages
import {LoginPageComponent} from "./pages/login/login-page.component";
import {PageNotFoundPageComponent} from "./pages/page-not-found/page-not-found-page.component";
import {SharedModule} from "./shared/shared.module";
import {MainMenuComponent} from "./components/main-menu/main-menu.component";
import {PageLayoutComponent} from "./components/page-layout/page-layout.component";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@environments';
import {HttpClientModule} from "@angular/common/http";
import {AppUpdateService, I18nService} from "@shared";
import {RegisterOrganizationPageComponent} from "./pages/register-organization/register-organization-page.component";
import {EmailConfirmationPageComponent} from "./pages/email-confirmation/email-confirmation-page.component";
import {
  RegisterOrganizationFormComponent
} from "./pages/register-organization/register-organization-form/register-organization-form.component";
import {Location} from '@angular/common';
import {PasswordRecoveryPageComponent} from "./pages/password-recovery/password-recovery-page.component";
import {
  PasswordRecoveryFormComponent
} from "./pages/password-recovery/password-recovery-form/password-recovery-form.component";
import {PasswordResetPageComponent} from "./pages/password-reset/password-reset-page.component";
import {PasswordResetFormComponent} from "./pages/password-reset/password-reset-form/password-reset-form.component";
import {StylesPageComponent} from "./pages/styles/styles-page.component";
import {MobileMenuComponent} from "./components/main-menu/mobile-menu.component";
import {FontsTestPageComponent} from "./pages/styles/fonts-test-page.component";
import {SharedModelModule} from "./shared-model/shared-model.module";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {NotificationsPanelComponent} from "./components/notifications-panel/notifications-panel.component";
import { LoginPageLayoutComponent } from './components/login-page-layout/login-page-layout.component';
import { AboutPageComponent } from './pages/about/about-page.component';
import {AuthenticateUserPageComponent} from "./pages/authenticate-user/authenticate-user-page.component";
import {PasswordSetFormComponent} from "./pages/email-confirmation/password-set-form/password-set-form.component";
import {ImpersonatePageComponent} from "./pages/impersonate/impersonate-page.component";
import {LoginEntraIdPageComponent} from "./pages/login/login-entra-id-page.component";

// This is to prevent angular for removing '/' from the end of the URL (it's needed in documents repository)
Location.stripTrailingSlash = (url: string) => {
  return url;
};


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        SharedModelModule,
    ],
  declarations: [
    AppComponent,
    // components
    PageLayoutComponent,
    MainMenuComponent,
    NotificationsPanelComponent,
    MobileMenuComponent,
    LoginPageLayoutComponent,
    // pages
    LoginPageComponent,
    LoginEntraIdPageComponent,
    PageNotFoundPageComponent,
    RegisterOrganizationPageComponent,
    StylesPageComponent,
    FontsTestPageComponent,
    RegisterOrganizationFormComponent,
    EmailConfirmationPageComponent,
    PasswordRecoveryPageComponent,
    PasswordRecoveryFormComponent,
    PasswordResetPageComponent,
    AuthenticateUserPageComponent,
    PasswordResetFormComponent,
    PasswordSetFormComponent,
    AboutPageComponent,
    ImpersonatePageComponent,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: resourceProviderFactory,
    deps: [I18nService],
    multi: true
  },
    AppUpdateService,
    provideEnvironmentNgxMask()
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function resourceProviderFactory(provider: I18nService) {
  return () => provider.initialize([]);
}
