

<my-modal cssClass="theme-modal-simple-prompt fileSelectorModal" [(visible)]="visible" (visibleChange)="visibleChange.emit($event)" backgroundStyle="dark" [backgroundVisible]="true" [closeOnBackgroundClick]="true">

  <div class="theme-title">{{'documentsRepository_select_from_repository' | i18n}}<button class="theme-button-close" (click)="onClose()"><i class="mi-delete"></i></button></div>

  <my-scroll>

    <div class="directoryPath">
      <button class="upButton" (click)="navigateUpClicked()"><i class="mi-up"></i></button>
      <span class="pathText">{{path}}</span>
    </div>
    <div class="directoryContent">
      <div class="directoryEntry" *ngFor="let directory of directories">
        <my-checkbox [(checked)]="directory.selected" *ngIf="!singleFileMode" />
        <button (click)="directoryClicked(directory)">
          <i class="mi-folder"></i>
          <span class="directoryName">{{directory.name}}</span>
        </button>
      </div>
      <div class="fileEntry" *ngFor="let file of files">
        <my-checkbox [(checked)]="file.selected" *ngIf="!singleFileMode" />
        <button (click)="fileClicked(file)">
          <i class="mi-file"></i>
          <span class="fileName">{{file.name}}</span>
        </button>
        <span class="fileSize">{{file.size | myFileSizeFormat}}</span>
      </div>
      <div class="emptyInfo" *ngIf="directoryIsEmpty()">
        [ {{'documentsRepository_directory_empty' | i18n}} ]
      </div>
    </div>

  </my-scroll>

  <div class="theme-buttons">
    <button class="theme-button-cancel-icon theme-align-start" (click)="onClose()"><i class="mi-delete"></i>{{'common_cancel' | i18n}}</button>
    <button class="theme-button-confirm-with-icon" (click)="pin()" *ngIf="!singleFileMode">Pin documents</button>
  </div>
</my-modal>
