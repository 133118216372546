import {Injectable} from "@angular/core";
import {AuthenticatedHttp, ScreenInfo} from "@shared-model";
import {
  AggregateVersion,
  AggregateWrapper,
  ApplicationId,
  I18nText,
  Option, OrganizationNodeId, ProcessId,
  ScreenId,
  ScreenReleaseId,
  toastr
} from "@utils";
import {SkinInfo, SkinPrototype} from "../model/screen-skins.model";
import {ScreenRelease, ScreenWithRelease} from "../model/screen.model";

@Injectable({
  providedIn: 'root',
})
export class ScreenCommonQueryService {


  private skinsPromise?: Promise<Array<SkinPrototype>>;

  constructor(
    readonly authenticatedHttp: AuthenticatedHttp,
  ) {}

  private skinNameFrom(name: any): I18nText {
    const translations: Array<[string, string]> = [];
    Object.keys(name).forEach(key => translations.push(<[string, string]>[key, <string>name[key]]));
    return new I18nText(translations);
  }

  loadSkins(): Promise<Array<SkinPrototype>> {

    if(this.skinsPromise !== undefined) {
      return this.skinsPromise;
    } else {

      this.skinsPromise = new Promise((resolve, reject) => {
        const skins: Array<SkinPrototype> = [];
        let misses = 0;

        resolve([]);
        this.authenticatedHttp.get("screen/skins/list.json", (skinsList: Array<SkinInfo>) => {
          if (skinsList.length > 0) {
            skinsList.map(s => new SkinInfo(this.skinNameFrom(s.name), s.identifier)).forEach(skinInfo => {
              this.authenticatedHttp.get("screen/skins/" + skinInfo.identifier + ".json", (skin: any) => {
                skins.push(new SkinPrototype(skinInfo.name, skinInfo.identifier, skin));
                if (skins.length + misses == skinsList.length) {
                  resolve(skins);
                }
              }, () => {
                misses += 1;
                console.error("Skin not loaded", skinInfo.name);
                if (skins.length + misses == skinsList.length) {
                  resolve(skins);
                }
              });
            });
          } else {
            resolve([]);
          }
        }, () => {
          console.error("Skins list not loaded");
          resolve([]);
        });
      });
      return this.skinsPromise;
    }

  }

  loadReleaseById(releaseId: ScreenReleaseId, onSuccess: (release: ScreenRelease, id: ScreenReleaseId, version: AggregateVersion) => void) {
    this.authenticatedHttp.get("screen/get-release/" + releaseId.id, (data: Option<AggregateWrapper<ScreenRelease>>) =>{
      const copied = Option.copy(data).map(aw => AggregateWrapper.copy(aw, ScreenRelease.copy));
      if(copied.isDefined()) {
        onSuccess(copied.get().aggregate, new ScreenReleaseId(copied.get().id.id), copied.get().version);
      } else {
        toastr.error("Unable to load view release");
      }
    });
  }

  load(applicationId: ApplicationId, screenId: ScreenId, screenReleaseId: ScreenReleaseId, onSuccess: (view: Option<ScreenWithRelease>) => void) {
    this.authenticatedHttp.get("screen/get/" + applicationId.id + "/" + screenId.id + "/" + screenReleaseId.id, (data: Option<ScreenWithRelease>) =>{
      const copied = Option.copy(data, ScreenWithRelease.copy);
      onSuccess(copied);
    });
  }

  loadWithWorkingRelease(applicationId: ApplicationId, screenId: ScreenId): Promise<Option<ScreenWithRelease>> {
    return this.authenticatedHttp.getPromise<Option<ScreenWithRelease>>("screen/get-working/" + applicationId.id + "/" + screenId.id).then((data: Option<ScreenWithRelease>) => {
      return Option.copy(data, ScreenWithRelease.copy);
    });
  }


  loadWithWorkingReleaseOrWait(applicationId: ApplicationId, screenId: ScreenId, onSuccess: (view: Option<ScreenWithRelease>) => void) {
    this.authenticatedHttp.get("screen/get-working-or-wait/" + applicationId.id + "/" + screenId.id, (data: Option<ScreenWithRelease>) =>{
      const copied = Option.copy(data, ScreenWithRelease.copy);
      onSuccess(copied);
    });
  }

  getAllFromApplication(applicationId: ApplicationId, onSuccess: (screens: Array<ScreenInfo>) => void) {
    this.authenticatedHttp.get("screen/get-all-from-application/"+applicationId.id, (data: Array<ScreenInfo>) => {
      const copied = data.map(a => ScreenInfo.copy(a));
      onSuccess(copied);
    })
  }

}
