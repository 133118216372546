import {Injectable} from "@angular/core";
import {
  ArrayVariable,
  BasicOrganizationNodeInfo, BusinessEntitySummary, BusinessEntityVariable,
  BusinessVariable, DepartmentVariable,
  GroupVariable, ObjectVariable,
  OrganizationSharedService, PersonVariable, StringVariable
} from "@shared-model";
import {i18n, Option, OrganizationNodeId, Typed} from "@utils";
import {BusinessEntitySharedService} from "@shared-model";

@Injectable({
  providedIn: 'root',
})
export class VariableTextPreviewService {
  static cache: Map<string, Promise<string>> = new Map<string, Promise<string>>();

  constructor(private readonly organizationSharedService: OrganizationSharedService,
              private readonly businessEntitySharedService: BusinessEntitySharedService) {}

  getTexts(values: Array<BusinessVariable>): Promise<Map<BusinessVariable, string>> {
    return Promise.all(values.map(value => this.getText(value, false).then(t => <[BusinessVariable, string]>[value, t]))).then((values: Array<[BusinessVariable, string]>) => {
      const map = new Map<BusinessVariable, string>();
      values.forEach(value => map.set(value[0], value[1]));
      return map;
    });
  }

  getTextsArray(values: Array<BusinessVariable>): Promise<Array<string>> {
    return Promise.all(values.map(value => this.getText(value, false).then(t => <[BusinessVariable, string]>[value, t]))).then((values: Array<[BusinessVariable, string]>) => {
      return values.map(value => value[1]);
    });
  }

  getText(value: BusinessVariable, taskSystemLabel: boolean): Promise<string> {

    const fromCache = VariableTextPreviewService.cache.get(value.className()+" "+value.valueToString()+"_"+taskSystemLabel);

    if(fromCache != null) {
      return fromCache;
    } else {

      const promise = new Promise<string>((resolve, reject) => {
        if (value instanceof GroupVariable) {
          this.organizationSharedService.findNodesBasicInfoByIds([OrganizationNodeId.fromGroupId(value.value.id)], (nodes: Array<BasicOrganizationNodeInfo>) => {
            if (nodes.length > 0) {
              resolve(nodes[0].asGroup().group.name);
            } else {
              resolve(value.valueToSimpleString());
            }
          })
        } else if (value instanceof DepartmentVariable) {
          this.organizationSharedService.findNodesBasicInfoByIds([OrganizationNodeId.fromDepartmentId(value.value.id)], (nodes: Array<BasicOrganizationNodeInfo>) => {
            if (nodes.length > 0) {
              resolve(nodes[0].asDepartment().name);
            } else {
              resolve(value.valueToSimpleString());
            }
          })
        } else if (value instanceof PersonVariable) {
          this.organizationSharedService.findNodesBasicInfoByIds([OrganizationNodeId.fromPersonId(value.value.id)], (nodes: Array<BasicOrganizationNodeInfo>) => {
            if (nodes.length > 0) {
              resolve(nodes[0].asPerson().simpleName());
            } else {
              resolve(value.valueToSimpleString());
            }
          })
        } else if (value instanceof BusinessEntityVariable) {
          this.businessEntitySharedService.loadEntitySummary(value.value, (summary: Option<BusinessEntitySummary>) => {

            if(summary.isDefined()) {
              const s=  summary.getOrError("no summary");
              const nameValues = s.nameUnwrapped();
              const descriptionValues = s.descriptionUnwrapped();
              this.getTextsArray(nameValues.concat(descriptionValues)).then(nameEndDescription => {
                const name = nameEndDescription.slice(0, nameValues.length);
                const description = nameEndDescription.slice(nameValues.length);

                if(name.length > 0) {
                  return name.join(" · ");
                } else {
                  return s.code;
                }
              }).then(result => {
                resolve(result);
              })

            } else {
              resolve(value.valueToSimpleString());
            }

          });
        } else if (value instanceof ObjectVariable) {

          const promisses: Array<Promise<[string, string]>> = value.value.map(entry => {
            const name = entry[0];
            const value = Typed.value(entry[1]);
            return this.getText(value, taskSystemLabel).then(text => {
              return <[string, string]>[name, text];
            });
          });

          Promise.all(promisses).then(entries => {
            resolve(entries.map(entry => entry[0] + ": " + entry[1]).join(",\n"));
          });

        } else if (value instanceof ArrayVariable) {

          this.getTextsArray(value.unwrappedValue()).then(texts => {
            resolve(texts.join(",\n"));
          });

        } else if(taskSystemLabel && value instanceof StringVariable){

          const text = value.value;
          if(text === "own") {
            resolve(i18n("tasks_system_label_own"));
          } else if(text === "individual") {
            resolve(i18n("tasks_system_label_individual"));
          } else {
            resolve(value.valueToSimpleString());
          }

        } else {
          resolve(value.valueToSimpleString());
        }

      });
      VariableTextPreviewService.cache.set(value.className()+"_"+value.valueToString()+"_"+taskSystemLabel, promise);

      return promise;
    }
  }
}
