import {AggregateId, AggregateVersion, ApplicationId, I18nText, Option, OrganizationNodeId} from "@utils";

export class BasicGroupInfo {
  constructor(public id: AggregateId,
              public version: AggregateVersion,
              public applicationId: Option<ApplicationId>,
              public name: string,
              readonly description: string,
              readonly identifier: string,
              public deleted: boolean) {}

  static copy(other: BasicGroupInfo) {
    return new BasicGroupInfo(AggregateId.copy(other.id),
      AggregateVersion.copy(other.version),
      Option.copy(other.applicationId, ApplicationId.of),
      other.name,
      other.description,
      other.identifier,
      other.deleted);
  }

}


export class GroupBasicInfoWithApplicationInfo {
  constructor(readonly applicationId: Option<ApplicationId>,
              readonly applicationName: string,
              readonly group: BasicGroupInfo) {}

  static copy(other: GroupBasicInfoWithApplicationInfo) {
    return new GroupBasicInfoWithApplicationInfo(Option.copy(other.applicationId, ApplicationId.of), other.applicationName,
      BasicGroupInfo.copy(other.group))
  }

}

export class GroupInfo {
  constructor(public id: AggregateId,
              public version: AggregateVersion,
              public applicationId: Option<ApplicationId>,
              public name: I18nText,
              public description: I18nText,
              public identifier: string,
              readonly externalPath: Option<Array<string>>,
              public deleted: boolean) {}

  static copy(other: GroupInfo) {
    return new GroupInfo(
      AggregateId.copy(other.id),
      AggregateVersion.copy(other.version),
      Option.copy(other.applicationId).map(ApplicationId.of),
      I18nText.copy(other.name),
      I18nText.copy(other.description),
      other.identifier,
      Option.copy(other.externalPath).map(p => p.slice()),
      other.deleted);
  }
}

export class GroupAuthorizationsInfo {
  constructor(readonly id: AggregateId,
              readonly version: AggregateVersion,
              readonly editors: Array<OrganizationNodeId>,
              readonly taskGroupCreators: Array<OrganizationNodeId>,
              readonly taskPersonCreators: Array<OrganizationNodeId>,) {}

  static copy(other: GroupAuthorizationsInfo) {
    return new GroupAuthorizationsInfo(other.id, other.version,
      other.editors.map(OrganizationNodeId.copy),
      other.taskGroupCreators.map(OrganizationNodeId.copy),
      other.taskPersonCreators.map(OrganizationNodeId.copy));
  }

}
