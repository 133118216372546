<button (click)="onClicked(option); $event.stopPropagation(); false;"
        *ngFor="let option of radioOptions"
        (keydown.space)="onClicked(option)"
        [class.small]="small"
        [class.checked]="checked && option.name === checked.name"
        [class.enabled]="!disabled"
        [class.invalid]="invalid">
  <svg viewBox="0 0 18 18" class="radioButtonSvg">
    <circle class="outsideCircle" cx="9" cy="9" r="7.5"/>
    <circle class="insideCircle" cx="9" cy="9" r="3.5"/>
    <circle class="border" cx="9" cy="9" r="7.5"/>
  </svg>
  <div class="label" *ngIf="option">{{getOptionName(option)}}</div>
</button>
