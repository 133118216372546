import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FileUri, None, Option, Some} from "@utils";
import {BusinessVariable, BusinessVariableInputServerModel, EmailVariable, VariablePath} from "@shared-model";

export class EmailVariableInputViewModel {

    emailExists: boolean = false;
    emailSubject: string|null = null;
    emailSummary: string|null = null;
    emailSenderReceiver: string|null = null;
    internalModel: FileUri|null = null;
    internalModelBackup: FileUri|null = null;

    constructor(readonly serverModel: BusinessVariableInputServerModel,
                readonly path: VariablePath,
                public value: EmailVariable|null,
                public readOnly: boolean,
                public preview: boolean,
                readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>,
                readonly previewRequested: EventEmitter<BusinessVariable>) {
      this.updatePreviewText();
      this.initInternalModel();
    }

    private updatePreviewText() {
      if(this.value === null) {
        this.emailExists = false;
        this.emailSubject = null;
        this.emailSummary = null;
        this.emailSenderReceiver = null;
      } else {
        this.emailExists = true;
        this.emailSubject = "";
        this.emailSummary = "";
        this.emailSenderReceiver = "";
        this.serverModel.loadEmailInfo(this.value.value, (emailInfo => {
          this.emailExists = true;
          this.emailSubject = emailInfo.subject;
          this.emailSummary = emailInfo.contentSummary;
          this.emailSenderReceiver = emailInfo.senderReceiverAddress;
        }));
      }
    }

    initInternalModel() {
      if (this.value == null) {
        this.internalModel = null;
      } else {
        this.internalModel = this.value.value;
      }
      this.internalModelBackup = this.internalModel;
    }

    private changeVariableValue(value: EmailVariable|null) {
      this.value = value;
      this.updatePreviewText();
      this.initInternalModel();
      this.change.emit({value: value, path: this.path});
    }

    onInternalChange() {
      if(this.internalModelChanged()) {
        if (this.internalModel === null) {
          this.changeVariableValue(null);
        } else {
          this.changeVariableValue(new EmailVariable(this.internalModel));
        }
        this.internalModelBackup = this.internalModel;
      }
    }

    private internalModelChanged() {
      return this.internalModel === null && this.internalModelBackup !== null ||
        this.internalModel !== null && this.internalModelBackup === null ||
        this.internalModel !== null && this.internalModelBackup !== null && !this.internalModel.isEqual(this.internalModelBackup);
    }

    updateValue(value: BusinessVariable|null) {
      if(value === null || value instanceof EmailVariable) {
        this.value = value;
        this.initInternalModel();
      } else {
        throw new Error("Incorrect variable type, expected 'Email' but was '"+value.simpleValueType()+"'");
      }
    }

    updateMode(readOnly: boolean, preview: boolean) {
      this.readOnly = readOnly;
      this.preview = preview;
    }

    backupInternalValue() {
      this.internalModelBackup = this.internalModel;
    }

    requestPreview() {
      if(this.value != null) {
        this.previewRequested.emit(this.value);
      } else {
        throw new Error("Cannot preview no value");
      }
    }
  }

@Component({
  selector: "my-email-variable-input",
  templateUrl: "./email-variable-input.component.html"
})
export class EmailVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  viewModel!: EmailVariableInputViewModel;

  @Input() previewRequested = new EventEmitter<BusinessVariable>();
  @Input() serverModel?: BusinessVariableInputServerModel;

  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    if(this.serverModel === undefined) {
      throw new Error("Server model is not set");
    } else {
      this.viewModel = new EmailVariableInputViewModel(this.serverModel, this.path, this._value, this._readOnly, this._preview, this.change, this.previewRequested);
    }
  }
}

