import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {LocalDate, mySetTimeoutNoAngular, onFormFocusLost, parseDateInputString} from "@utils";

@Component({
  selector: 'my-date',
  templateUrl: './date.component.html',
  host: {
    "[class.pureLook]" : "pureLook"
  }
})
export class DateComponent implements OnInit, OnDestroy {
  private _value: LocalDate|null = null;

  get value(): LocalDate | null {return this._value;}
  @Input() set value(value: LocalDate | null) {this._value = value; this.onValueChange()}


  pickerValue: LocalDate| null = null;

  @Input() popupEnabled: boolean = true;

  @Input() pureLook: boolean = false; // remove all visual decorations, it will be handled externally

  @Input() useSmartParser: boolean = true;
  @Input() clearButton: boolean = false;
  @Input() todayButton: boolean = false;
  @Input() yesterdayButton: boolean = false;
  @Output() valueChange = new EventEmitter<LocalDate|null>();

  @Input() summaryFormat: 'short'|'long' = 'short';

  @Input() readOnly: boolean = false;

  @ViewChild("InputElement") private inputElement: ElementRef<HTMLInputElement> | undefined;

  @Input() placeholder = "dd-mm-yyyy";

  focused: boolean = false;
  previewTextModel: string | null = null;
  inputTextModel: string | null = null;
  lastValue:LocalDate|null = null;

  @Input() openOnShow: boolean = false;

  private cancelFormFocusLost?: () => void;

  readonly anchor: HTMLElement;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
    this.anchor = viewContainerRef.element.nativeElement
  }

  ngOnInit(): void {
    this.lastValue = this._value;
    this.pickerValue = this._value;
    this.updateTextModelFromValue();
    if(this.openOnShow) {
      this.onFocus();
    }
  }

  ngOnDestroy(): void {
    if(this.cancelFormFocusLost){
      this.cancelFormFocusLost();
      this.cancelFormFocusLost = undefined;
    }
  }

  onFocus(): void {
    if(!this.focused){
      this.focused = true;
      this.updateTextModelFromValue();
      this.selectInput();
      this.cancelFormFocusLost = onFormFocusLost(this.anchor, () => this.onInputValueConfirm());
    }
  }

  // onInputBlur(): void {
  //   if (this.focused) {
  //     this.focused = false;
  //     this.updateValue();
  //     this.updateTextModel();
  //   }
  // }

  private blur(): void {
    if (this.focused) {
      this.focused = false;
      this.updateValueFromTextModel();
      this.updateTextModelFromValue();
    }
  }

  onInputValueConfirm(): void {
    this.updateValueFromTextModel();
    this.blur();
  }

  onPickerValueSelected(): void {
    this._value = this.pickerValue;
    this.updateTextModelFromValue();
    this.blur();
  }

  onPickerClosed(): void {
    if (!this.areValueAndModelInSync()) {
      this.updateValueFromTextModel();
    }
    this.blur();
  }

  setYesterday(): void {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this._value = LocalDate.fromLocalDate(yesterday);
    this.pickerValue = this._value;
    this.updateTextModelFromValue();
    this.blur();
  }

  setToday(): void {
    this._value = (LocalDate.fromLocalDate(new Date()));
    this.pickerValue = this._value;
    this.updateTextModelFromValue();
    this.blur();
  }

  setEmpty(): void {
    this._value = null;
    this.pickerValue = this._value;
    this.updateTextModelFromValue();
    this.blur();
  }

  private areValueAndModelInSync(): boolean {
    return (this._value) ? this.formatTextInputFromValue() == this.inputTextModel : this.inputTextModel == null;
  }

  private updateTextModelFromValue(): void {
    this.previewTextModel = this.formatTextPreviewModelsFromValue();
    this.inputTextModel = this.formatTextInputFromValue();
    this.handleVariableChange();
  }

  private updateValueFromTextModel(): void {
    if (this.inputTextModel && this.inputTextModel.trim() != "") {
      this._value = this.useSmartParser ? this.smartParseModel(this.inputTextModel) : this.defaultParseModel(this.inputTextModel);
      this.previewTextModel = this.formatTextPreviewModelsFromValue();
      this.inputTextModel = this.formatTextInputFromValue();
    } else {
      this._value = null;
      this.inputTextModel = null;
      this.previewTextModel = null;
    }
  }

  private formatTextPreviewModelsFromValue(): string|null {
    return this._value ? (this.summaryFormat == "short" ? this._value.formattedShortWords() : this._value.formattedWords()) : null;
  }

  private formatTextInputFromValue(): string|null {
    return this._value ? this._value.formatted() : null;
  }

  private defaultParseModel(model: string): LocalDate | null {
    const parseTry = LocalDate.of(model);
    return parseTry.isSuccess() ? parseTry.result : null;
  }

  private smartParseModel(model: string): LocalDate | null {
    const parsed = parseDateInputString(model);
    return LocalDate.fromLocalDate(parsed);
  }

  private selectInput() {
    mySetTimeoutNoAngular(() => this.inputElement?.nativeElement.select());
  }

  private handleVariableChange() {
    if((this._value === null && this.lastValue === null) || (this._value !== null && this.lastValue !== null && this._value.isEqual(this.lastValue))){
      return;
    } else {
      this.lastValue = this._value;
      this.valueChange.emit(this._value);
    }
  }

  private onValueChange() {
    this.pickerValue = this._value;
    this.previewTextModel = this.formatTextPreviewModelsFromValue();
    this.inputTextModel = this.formatTextInputFromValue();
  }
}
