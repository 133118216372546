import {Component, Input, OnInit} from "@angular/core";
import {Option, valueOrDefault} from "@utils";
import {
  BooleanVariableType,
  BusinessVariableType,
  NumberVariableType, ProcessEdgeId, ProcessNodeId,
  StringVariableType,
  VariablePath
} from "@shared-model";
import {LogicOperationSuggestionProvider, LogicOperationSuggestionSuggestions} from "./logic-operation.component";
import {MapCanvasEditorViewModel} from "../map-canvas-editor-view.model";
import {EdgeConditionExpressionChanged, EdgeConditionOperationTypeChanged} from "../process-map-editor.ui-events";
import {
  BooleanValue,
  ExpressionValue, LogicOperationValue,
  NumberValue,
  StringValue,
  VariableValue
} from "../../../../process-common.module";
import {ExpressionSuggestionsProvider} from "@shared";

export class LogicOperationValueViewModel {

  constructor(
    public valueType: "string"|"number"|"boolean"|"variable"|"expression",
    public stringValue: string = "",
    public numberValue: number|null = null,
    public booleanValue: boolean = true,
    ) {
  }

  static emptyString() {
    return new LogicOperationValueViewModel("string", "");
  }

  static emptyVariable() {
    return new LogicOperationValueViewModel("variable", "", null, true);
  }

  static ofString(value: string) {
    return new LogicOperationValueViewModel("string", value);
  }

  static ofNumber(value: number|null) {
    return new LogicOperationValueViewModel("number", "", value);
  }

  static ofVariable(variablePath: VariablePath) {
    return new LogicOperationValueViewModel("variable", variablePath.path);
  }

  static ofExpression(expression: string) {
    return new LogicOperationValueViewModel("expression", expression);
  }

  static ofBoolean(value: boolean) {
    return new LogicOperationValueViewModel("boolean", "", null, value);
  }


  changeValueType(valueType: "string"|"number"|"boolean"|"variable"|"expression") {
    this.valueType = valueType;
    this.initDefaultValue();
  }

  private initDefaultValue() {
    switch (this.valueType) {
      case "string": this.stringValue = ""; break;
      case "variable": this.stringValue = ""; break;
      case "number": this.numberValue = null; break;
      case "boolean": this.booleanValue = true; break;
      case "expression": this.stringValue = ""; break;
      default: throw new Error("Unknown value type '" + this.valueType+"'");
    }
  }

  changeValueToTrue() {
    this.changeValueType("boolean");
    this.booleanValue = true;
  }

  changeValueToFalse() {
    this.changeValueType("boolean");
    this.booleanValue = false;
  }

  changeValueToVariable(path: VariablePath, tpe: BusinessVariableType | undefined) {
    this.changeValueType("variable");
    this.stringValue = path.path;
  }

  changeValueToText(text: string) {
    this.changeValueType("string");
    this.stringValue = text;
  }

  isEmpty() {
    switch (this.valueType) {
      case "string": return this.stringValue.length === 0;
      case "variable": return this.stringValue.length === 0;
      case "number": return this.numberValue === null;
      case "boolean": return false;
      case "expression": return this.stringValue.length === 0;
      default: throw new Error("Unknown value type '" + this.valueType+"'");
    }
  }

  copy() {
    return new LogicOperationValueViewModel(this.valueType, this.stringValue, this.numberValue, this.booleanValue);
  }

  toLogicOperationValue(): LogicOperationValue {
    switch (this.valueType) {
      case "string": console.log("string", this.stringValue); return new StringValue(valueOrDefault(this.stringValue, ""));
      case "number": console.log("number", this.numberValue); return new NumberValue(Option.of(this.numberValue));
      case "boolean": console.log("boolean", this.booleanValue); return new BooleanValue(valueOrDefault(this.booleanValue, true));
      case "variable": console.log("variable", this.stringValue); return new VariableValue(new VariablePath(valueOrDefault(this.stringValue, "")));
      case "expression": console.log("expression", this.stringValue); return new ExpressionValue(valueOrDefault(this.stringValue, ""));
      default: throw new Error("Incorrect value type '" + this.valueType +"'");
    }
  }

}

export class LogicOperationViewModel {
  constructor(
    public left: LogicOperationValueViewModel|undefined,
    public operator: "=="|"!="|"<"|"<="|">"|">="|"contains"|"!contains",
    public right: LogicOperationValueViewModel|undefined) {}

  changeOperator(operator: "=="|"!="|"<"|"<="|">"|">="|"contains"|"!contains") {
    this.operator = operator;
  }

  guessOtherOperationType(position: "left" | "right", tpe: BusinessVariableType | null): LogicOperationValueViewModel|undefined {

    const otherOperation = position === "left" ? this.right : this.left;

    if(tpe) {
      if(otherOperation === undefined || otherOperation.isEmpty()) {
          if (tpe.typeName() === 'Number') {
            return new LogicOperationValueViewModel("number");
          } else if (tpe.typeName() === 'String') {
            return new LogicOperationValueViewModel("string");
          } else if (tpe.typeName() === 'Boolean') {
            return new LogicOperationValueViewModel("boolean", "", null, true);
          }
        }
      }

    return undefined;
  }

  copy() {
    return new LogicOperationViewModel(this.left ? this.left.copy() : undefined, this.operator, this.right ? this.right.copy() : undefined);
  }
}

export class LogicRuleViewModel {

  constructor(
    public ruleType: "operation"|"complex",
    public operation: LogicOperationViewModel|undefined,
    public subRules: Array<LogicRuleViewModel>,
    public operator: "and"|"or"
  ) {

  }

  static empty() {
    return new LogicRuleViewModel("operation", new LogicOperationViewModel(undefined, "==", undefined), [], "and");
  }


  static operation(operation = new LogicOperationViewModel(undefined, "==", undefined)) {
    return new LogicRuleViewModel("operation", operation, [], "and");
  }


  getRuleByIndexPath(indexPath: number[]): LogicRuleViewModel {
    if(indexPath.length === 0) {
      return this;
    } else {
      return this.subRules[indexPath[0]].getRuleByIndexPath(indexPath.slice(1));
    }
  }

  copy(): LogicRuleViewModel {
    return new LogicRuleViewModel(this.ruleType, this.operation === undefined ? undefined : this.operation.copy(), this.subRules.map(s => s.copy()), this.operator);
  }
}

export class ConditionRuleViewModel {
  constructor(
    public operationType: "always"|"never"|"rule"|"expression",
    public condition: LogicRuleViewModel,
    public expression: string) {}
}

class SomeLogicOperationSuggestionProvider implements LogicOperationSuggestionProvider {

  constructor(private readonly suggestionProvider: ExpressionSuggestionsProvider) {
  }

  getSuggestions(): Promise<LogicOperationSuggestionSuggestions> {

    const texts = this.suggestionProvider.getSuggestions().then(suggestions => {
      return suggestions.filter(s => s.type === "text").map(t => t.rawValue);
    });

    const variables = this.suggestionProvider.getReadableVariables().then(variables => {
      return variables;
    });

    return Promise.all([texts, variables]).then(([texts, variables]) => {
      return new LogicOperationSuggestionSuggestions(
        variables, texts);
    });

  }

}

@Component({
  templateUrl: './condition-rule.component.html',
  selector: 'my-condition-rule'
})
export class ConditionRuleComponent implements OnInit {
  @Input({required: true}) nodeId!: ProcessNodeId;
  @Input({required: true}) edgeId!: ProcessEdgeId;
  @Input({required: true}) conditionRule! : ConditionRuleViewModel;
  @Input({required: true}) viewModel!: MapCanvasEditorViewModel;
  @Input({required: true}) suggestionsProvider!: ExpressionSuggestionsProvider;


  logicSuggestionsProvider!: LogicOperationSuggestionProvider;

  parentIndexPath: Array<number> = [];

  ngOnInit() {
    this.logicSuggestionsProvider = new SomeLogicOperationSuggestionProvider(this.suggestionsProvider);
  }

  operationTypeChanged(operationType: "always"|"never"|"rule"|"expression") {
    this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionOperationTypeChanged(this.nodeId, this.edgeId, operationType, false));
  }

  operationExpressionChanged(expression: string) {
    this.viewModel.modelEventBus.uiEventHappened(new EdgeConditionExpressionChanged(this.nodeId, this.edgeId, expression, false));
  }
}
