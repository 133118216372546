import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: "my-switch",
  templateUrl: "./switch.component.html",
  host: {
    "[class.large]": "styleVersion === 'large'",
    "[class.disabled]": "disabled",
    "(click)": "onClicked()",
    "(keydown.space)": "onClicked()", // for accessibility
    "(keydown.enter)": "onClicked()", // for accessibility
    "[class.marked]": "marked",
    "[class.threeStates]": "threeStates",
    "[class.lightMode]": "lightMode",
  }
})
export class SwitchComponent implements AfterViewInit {
  @Input() marked: boolean = false;
  @Output() markedChange = new EventEmitter<boolean>();
  private _disabled: boolean = false
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.updateTabIndex();
  }
  get disabled(): boolean {return this._disabled;}
  @Input() label: string | undefined = undefined;
  @Input() labelOnStart: boolean = false;
  @Output() change = new EventEmitter<boolean>();
  @Input() styleVersion: "small"|"large" = "small";

  @Input() yesLabel?: string;
  @Input() noLabel?: string;

  @Input() threeStates: boolean = false;
  @Input() lightMode: boolean = false;

  private externalTabIndex: number = -1;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const element = <HTMLElement>this.elementRef.nativeElement;
    if(!this.disabled) {
      this.externalTabIndex = element.tabIndex;
      if (this.externalTabIndex < 0) {
        element.tabIndex = 0;
      }
    }
  }

  private updateTabIndex() {
      const element = <HTMLElement>this.elementRef.nativeElement;
      if (this._disabled) {
        element.tabIndex = this.externalTabIndex;
      } else {
        this.externalTabIndex = element.tabIndex;
        if (this.externalTabIndex < 0) {
            element.tabIndex = 0;
        }
      }
  }

  onClicked() {
    if (!this.disabled) {
      this.marked = !this.marked;
      this.markedChange.emit(this.marked);
      this.change.emit(this.marked);
    }
  };
}
