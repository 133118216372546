import {Injectable} from "@angular/core";
import {EventBus} from "@utils";
import {TaskIdentifier, TaskModelSummary} from "@shared-model";

@Injectable({
  providedIn: "root"
})
export class GlobalEventBus extends EventBus {
  // onChangePersonSettings() {}
  // onChangePersonSettings(personSettings: PersonSettings, personNodeVersion: AggregateVersion) {}
  tasksListLoaded(tasks: Array<TaskModelSummary>): void {}
  tasksListUpdated(added: Array<TaskModelSummary>, updated: Array<TaskModelSummary>, removed: Array<TaskIdentifier>): void {}
}
