import {
  AggregateId,
  AggregateVersion, AggregateWrapper,
  AnyFlowId,
  EventBus,
  FlowId, mySetTimeout, mySetTimeoutNoAngular,
  None,
  Option,
  randomString,
  RemoteFlowId,
  RemoteOrganizationIdentifier,
  restUrl,
  ScreenInstanceId,
  Some,
  toastr
} from "@utils";
import {
  I18nService,
  NewScreenInstanceEvents,
  ScreenInstancesListUpdate,
  SessionEventBus,
  SessionServiceProvider
} from "../..";
import {Injectable} from "@angular/core";
import {AuthenticatedHttp, ProcessFlowEventInfo, TasksChanged} from "@shared-model";
import {PersonNotification} from "../notifications/notifications.model";
import {ProcessFlowDetails} from "../../../modules/task-form.module/model/ProcessFlow";
import {Subject} from "rxjs";
import {OfflineService} from "../offline/offline.service";


class ListenConfirmed {
    channelId: string = "";
  }
  class ListenResponse {
    error: string|undefined;
    events: ListenResponseData[]|undefined;
    subscriptionsIds: Array<string> = [];
  }
  class ListenResponseData {
    dataType: string = "";
    data: any;
    metadata: any;
  }


  export class ServerEventBus extends EventBus {
    flowEvent(rawEventInfo: ProcessFlowEventInfo) {}
    screenInstanceEvents(eventsNotCopied: NewScreenInstanceEvents) {}
    countUpdate(n: number) {}
    tasksUpdated(tasksChanged: TasksChanged) {}
    // activeFlowsUpdated(flows: Array<ProcessFlowDetails>, organizationId: AggregateId) {}
    // allFlowsUpdated(flows: ProcessFlowDetails[], organizationId: AggregateId) {}
    // flowDetailsUpdated(flow: ProcessFlowDetails, flowId: FlowId) {}
    // instanceInfoUpdated(instanceInfo: ProcessInstanceInfo, instanceId: AggregateId) {}
    myRolesUpdated() {}
    // recentDesignsUpdated(recentDesigns: ProcessInfo[]) {}
    newNotificationsReceived(notifications: Array<PersonNotification>) {}
    screenInstancesChange(instancesListUpdate: ScreenInstancesListUpdate) {}
  }


  export class SubscriptionType {
    constructor(readonly name: string) {}

    static FlowEventInfo = new SubscriptionType("FlowEventInfo");
    static ActiveFlowsCount = new SubscriptionType("ActiveFlowsCount");
    static ActiveFlows = new SubscriptionType("ActiveFlows");
    static AllFlows = new SubscriptionType("AllFlows");
    static FlowDetails = new SubscriptionType("FlowDetails");
    static TasksChange = new SubscriptionType("TasksChange");
    static AvailableTasks = new SubscriptionType("AvailableTasks");
    static AssignedTasks = new SubscriptionType("AssignedTasks");
    static InstanceInfo = new SubscriptionType("InstanceInfo");
    static MyRoles = new SubscriptionType("MyRoles");
    static Notifications = new SubscriptionType("Notifications");
    static ScreenInstance = new SubscriptionType("ScreenInstance");
    static ScreenInstancesChange = new SubscriptionType("ScreenInstancesChange");

  }



  @Injectable({
    providedIn: 'root',
  })
  export class ServerEventsService {
    static uniqueIdentifier = randomString(8);
    static requestCounter = 0;

    private channelId: Option<string> = None();
    private remoteChannels: {[organization: string]: string} = {};

    public serverEventBus = new ServerEventBus();

    private delayedSubscriptions: Array<() => void> = [];

    private remoteOrganizations: Array<RemoteOrganizationIdentifier> = [];

    private sharedWorkerAvailable: boolean = !!(<any>window).SharedWorker;
    private worker: Worker|SharedWorker|null = null;

    private debugEnabled = false;
    flowDetailsUpdatedSubject: Subject<ProcessFlowDetails> = new Subject<ProcessFlowDetails>();

    constructor(readonly authenticatedHttp: AuthenticatedHttp,
                readonly i18nService: I18nService,
                readonly sessionServiceProvider: SessionServiceProvider,
                readonly sessionEventBus: SessionEventBus,
                readonly offlineService: OfflineService,
                // readonly organizationSessionInfo: OrganizationSessionInfoClientSide,
                // readonly $window: ng.IWindowService,
                // readonly remoteOrganizationsQueryService: RemoteOrganizationsQueryService,
                // readonly $timeout: ng.ITimeoutService
                ) {


      sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
        this.init();
      });

      sessionEventBus.on(sessionEventBus.userLoggedOut, (sessionToken: string) => {
        this.unsubscribeAll(sessionToken);
      });

      sessionServiceProvider.getOrganizationSessionInfoIfAvailable(sessionInfo => {
        this.init();
      });
    }

    private unsubscribeAll(sessionToken: string) {
      this.channelId = None();
      this.remoteChannels = {};
      this.postMessageToWorker({messageType: "userLoggedOut", sessionToken: sessionToken});
    }

    private init() {

      this.initLocal();

      this.remoteOrganizations.forEach(remoteOrganization => {
        this.initRemote(remoteOrganization);
      });

    }

    private emergencyReload(error: string) {
      console.error("Error while listening to server events, reloading page to re-establish connection" + error);
      mySetTimeoutNoAngular(() => window.location.reload(), 1000);
      throw new Error("Error while listening to server events, reloading page to re-establish connection: " + error);
    }


    private debug(...args: Array<any>): void {
      if(this.debugEnabled) {
        if (args.length === 1) {
          console.log("Page: " + args[0]);
        } else if (args.length === 2) {
          console.log("Page: " + args[0], args[1]);
        } else if (args.length === 3) {
          console.log("Page: " + args[0], args[1], args[2]);
        } else if (args.length === 4) {
          console.log("Page: " + args[0], args[1], args[2], args[3]);
        } else if (args.length === 5) {
          console.log("Page: " + args[0], args[1], args[2], args[3], args[4]);
        } else if (args.length === 6) {
          console.log("Page: " + args[0], args[1], args[2], args[3], args[4], args[5]);
        } else {
          console.log("Page:", args);
        }
      }
    }

    private postMessageToWorker(message: Record<string, any>) {
      // flag check is
      if(this.sharedWorkerAvailable) {
        (<SharedWorker>this.worker).port.postMessage(message);
      } else if(!this.sharedWorkerAvailable) {
        (<Worker>this.worker).postMessage(message);
      } else {
        throw new Error("No worker");
      }
    }

    private listenOnWorkerMessage(onMessage: (message: any) => void) {
      if(this.sharedWorkerAvailable) {
        (<SharedWorker>this.worker).port.addEventListener("message", (e: { data: { messageType: any; channelId: string; events: Array<any>; error: string; }; }) => {
          onMessage(e.data)
        });
      } else if(!this.sharedWorkerAvailable) {
        (<Worker>this.worker).onmessage = (event: MessageEvent) => {
          onMessage(event.data);
        };
      } else {
        throw new Error("No worker");
      }
    }

    private initLocal() {

      this.debug("Initiating or connecting to worker");

      if(this.sharedWorkerAvailable) {
        console.log("Starting shared worker");
        this.worker = new SharedWorker("/serverEventsWorker.min.js?nocache="+encodeURIComponent(btoa("a"))); // TODO FIX add anti cache
        this.worker.port.start();
      } else {
        console.log("Starting non shared worker");
        this.worker = new Worker("/serverEventsWorker.min.js?nocache="+encodeURIComponent(btoa("a"))); // TODO FIX add anti cache
      }


      this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {

        this.postMessageToWorker({
          messageType: "listen", "serviceIdentifier": ServerEventsService.uniqueIdentifier, "urlPrefix": restUrl(""),
          "sessionId": organizationSessionInfo.sessionToken
        });

        this.listenOnWorkerMessage((message: { messageType: any; channelId: string; events: Array<any>; error: string; }) => {
          this.handleWorkerMessage(message);
        })

        if (!(<any>window).isUnloading) {
          window.addEventListener("unload", () => {
            this.postMessageToWorker({
              messageType: "pageClosed",
              sessionId: organizationSessionInfo.sessionToken
            });
          });
        }

      });
    }



    private handleWorkerMessage(data: { messageType: any; channelId: string; events: Array<any>; error: string; }) {
      switch(data.messageType) {
        case "listenResponse":
          this.channelId = Some(data.channelId);
          this.debug("Page: Connected to channel "+data.channelId);
          this.replayDelayedSubscriptions();
          break;
        case "events":
          this.debug("Page: Got response ", data.messageType, data.events);

          // timeout is used to force scope update
          data.events.forEach((event) => {
            this.handleUpdateDataFromServer(event.dataType, event.data, event.metadata);
          });
          break;
        case "ping":
          this.postMessageToWorker({messageType: "pong"});
          break;
        case "pong":
          console.log("Received pong from worker");
          break;
        case "emergencyReload":
          this.offlineService.setOffline();
          // this.emergencyReload(data.error);
          break;
        case "unableToConnect":
          this.offlineService.setOffline();
          // toastr.info(this.i18nService.translate("common_reconnecting"));
          // this.emergencyReload(data.error);
          break;
        case "subscribeFailed":
          toastr.error("Subscription failed [" + data.error+"]");
          break;
        case "unsubscribeFailed":
          toastr.error("Unsubscribe failed [" + data.error+"]");
          break;
        case "channelOpeningFailed":
          toastr.error("Channel opening failed [" + data.error+"]");
          break;
        case "channelOpeningNotAuthorized": // ignore
          break;
        default:
          this.debug("Page: Got response ", data.messageType);
      }
    }

    private replayDelayedSubscriptions() {
      const delayed = this.delayedSubscriptions;
      this.delayedSubscriptions = [];
      while(delayed.length > 0) {
        const subscription = delayed.shift();
        if(subscription !== undefined) {
          subscription();
        }
      }
    }


    private initRemote(remoteOrganizationIdentifier: RemoteOrganizationIdentifier) {
      // TODO enable remote
      // delete this.remoteChannels[remoteOrganizationIdentifier.id];
      // this.authenticatedHttp.getWithDetailedError("server-events/open-remote-channel/"+remoteOrganizationIdentifier.id+"/"+ServerEventsService.uniqueIdentifier, (data: ListenConfirmed) => {
      //   this.remoteChannels[remoteOrganizationIdentifier.id] = data.channelId;
      //   this.listenAgainForServerEvents(Some(remoteOrganizationIdentifier));
      //   this.replayDelayedSubscriptions();
      // });
    }

    private channelFor(remoteOrganizationIdentifier: Option<RemoteOrganizationIdentifier>): Option<string> {
      if(remoteOrganizationIdentifier.isEmpty()) {
        return this.channelId;
      } else {
        return Option.of(this.remoteChannels[remoteOrganizationIdentifier.get().id]);
      }
    }





    subscribe(url: (channelId: string) => string, onSuccess: (subscriptionId: string) => void) {
      if(this.channelId.isDefined()) {
        this.debug("Page: Will subscribe by worker " + url(this.channelId.get()));
        this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {

          this.postMessageToWorker({
            messageType: "subscribe",
            "sessionId": organizationSessionInfo.sessionToken,
            subscriptionUrl: url(this.channelId.get())
          });
        });
      } else {
        this.delayedSubscriptions.push(() => this.subscribe(url, onSuccess));
      }

    }


    subscribeForFlowEventInfo(flowId: AnyFlowId, flowVersion: AggregateVersion, onSuccess: (subscriptionCode: string) => void): void {
      let endpoint = "";
      let channelId = "";
      let flow = "";

      if(flowId instanceof FlowId) {
        endpoint = "subscribe-for-flow-events-info/";
        // channelId = this.channelId.get();
        flow = flowId.id;
      } else if(flowId instanceof RemoteFlowId) {
        endpoint = "remote-subscribe-for-flow-events-info/"+flowId.remoteOrganization+"/";
        // channelId = this.channelFor(Some(new RemoteOrganizationIdentifier(flowId.remoteOrganization))).get();
        flow = flowId.id;
      } else {
        throw new Error("Incorrect flow id")
      }

      // TODO fix handling remote
      this.subscribe(channelId => `server-events/${endpoint}${channelId}/${flow}/${flowVersion.asInt}`, subscriptionId => onSuccess(subscriptionId));

    }


    subscribeForScreenInstance(instanceId: ScreenInstanceId, lastVersion: number, onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-screen-instance/${channelId}/${instanceId.id}/${lastVersion}`, subscriptionId => onSuccess(subscriptionId));
    }

    subscribeForScreenInstancesListChange(onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-screen-instances-list-change/${channelId}`, subscriptionId => onSuccess(subscriptionId));
    }


    subscribeForInstanceInfo(instanceId: AggregateId, onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-instance/${channelId}/${instanceId.id}`, subscriptionId => onSuccess(subscriptionId));
    }

    subscribeForTasksChange(remoteOrganization: Option<RemoteOrganizationIdentifier>, onSuccess: (subscriptionCode: string) => void): void {

      const channel = this.channelFor(remoteOrganization);

      if (channel.isDefined()) {

        // TODO fix remote
        this.subscribe(channelId =>
          remoteOrganization.isDefined() ?
            `server-events/remote-subscribe-tasks-change/${remoteOrganization.get().id}/${channelId}` :
            `server-events/subscribe-tasks-change/${channelId}`,
            subscriptionId => onSuccess(subscriptionId));
      } else {
        const clone = Option.copy(remoteOrganization).map(RemoteOrganizationIdentifier.copy);
        this.delayedSubscriptions.push(() => this.subscribeForTasksChange(clone, onSuccess));
      }
    }

    subscribeForMyRoleUpdates(onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-my-roles/${channelId}`, subscriptionId => onSuccess(subscriptionId));
    }


    subscribeForFlow(flowId: AnyFlowId, onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-flow-details/${channelId}/${flowId.urlSerialized()}`, subscriptionId => onSuccess(subscriptionId));
    }

    subscribeForNotifications(onSuccess: (subscriptionCode: string) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-notifications/${channelId}`, subscriptionId => onSuccess(subscriptionId));
    }

    unsubscribeRemote(remoteOrganization: RemoteOrganizationIdentifier, subscriptionCode: string): void {
      if(this.channelId.isDefined()) {
        this.authenticatedHttp.get("server-events/remote-unsubscribe/"+remoteOrganization.id+"/" + this.channelId.get() + "/" + subscriptionCode,
          (data: string) => {
            // do nothing
          });
      }
    }

    unsubscribe(subscriptionCode: string): void {
      this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {

        this.postMessageToWorker({
          messageType: "unsubscribe",
          sessionId: organizationSessionInfo.sessionToken,
          subscriptionId: subscriptionCode
        });
      });
    }


    private lastUpdates: { [name: string]: any } = {};

    private handleUpdateDataFromServer(dataType: string, data: any, metadata: any): void {

      switch(dataType) {
        case SubscriptionType.FlowEventInfo.name: this.handleEventInfoFromServer(data); break;
        // case SubscriptionType.ActiveFlowsCount.name: this.handleCountUpdateFromServer(<number>data); break;
        // case SubscriptionType.ActiveFlows.name: this.handleActiveFlowsFromServer((<ProcessFlowDetails[]>data).map(pfd => ProcessFlowDetails.copy(pfd)), <AggregateId>metadata); break;
        // case SubscriptionType.AllFlows.name: this.handleAllFlowsFromServer((<ProcessFlowDetails[]>data).map(pfd => ProcessFlowDetails.copy(pfd)), <AggregateId>metadata); break;
        case SubscriptionType.FlowDetails.name: this.flowDetailsUpdatedSubject.next(ProcessFlowDetails.copy(data)); break;
        case SubscriptionType.TasksChange.name:
          // if (!_.isEqual(this.lastUpdates[dataType], data)) {
          //   this.lastUpdates[dataType] = data;
            this.handleTasksChangedFromServer(TasksChanged.copy(data));
          // }
          break;
        // case SubscriptionType.InstanceInfo.name: this.handleInstanceInfoFromServer(ProcessInstanceInfo.copy(data), <AggregateId>metadata); break;
        // case SubscriptionType.MyRoles.name: this.handleRolesChangedFromServer(<boolean>data, <AggregateId>metadata); break;
        case SubscriptionType.Notifications.name: this.handleNotificationsFromServer(<Array<AggregateWrapper<PersonNotification>>>data); break;
        case SubscriptionType.ScreenInstance.name: this.handleScreenInstanceEvents(<NewScreenInstanceEvents>data); break;
        case SubscriptionType.ScreenInstancesChange.name: this.handleScreenInstancesChange(<ScreenInstancesListUpdate>data); break;
        default: throw new Error("Unexpected data type: ["+dataType+"]");
      }
    }

    private handleTasksChangedFromServer(tasksChanged: TasksChanged): void {
      this.serverEventBus.tasksUpdated(tasksChanged);
    }

    private handleScreenInstanceEvents(data: NewScreenInstanceEvents) {
      this.serverEventBus.screenInstanceEvents(data);
    }

    private handleNotificationsFromServer(data: Array<AggregateWrapper<PersonNotification>>): void {
      this.serverEventBus.newNotificationsReceived(data.map(n => PersonNotification.copy(n.id, n.version, n.aggregate)));
    }

    private handleEventInfoFromServer(eventInfo: ProcessFlowEventInfo): void {
      this.serverEventBus.flowEvent(eventInfo);
    }

    private handleScreenInstancesChange(data: ScreenInstancesListUpdate) {
      this.serverEventBus.screenInstancesChange(ScreenInstancesListUpdate.copy(data));
    }

    get flowDetailsUpdated() {
      return this.flowDetailsUpdatedSubject.asObservable();
    }
  }

