import {Injectable} from '@angular/core';
import {ConfirmPersonEmail, EmailConfirmResponse, EmailConfirmWithPasswordSet} from "../..";
import {AggregateId, AggregateVersion, handleError, None, Option, restUrl, Some, toastr, Typed} from "@utils";
import {HttpClient} from "@angular/common/http";
import {
  CommandResponse,
  customCommandResponseHandler,
  CustomSuccessResponse,
  isSuccess,
  nonSuccessMessages
} from "@shared-model";
import {
  AliasExists,
  EmailExists,
  ExistsResponse,
  NameExists,
  RegisterOrganizationAndUser,
  RegisterOrganizationHelper
} from "./organization-register.model";

@Injectable({
  providedIn: 'root',
})
export class OrganizationRegistrationService {

  private token: Option<number> = None();

  constructor(private readonly http: HttpClient) {}

  getRegistrationToken(): void {
    this.http.get(restUrl("saga/get-register-token"), {withCredentials: true}).subscribe({
      next: (response: any) => {
        this.token = Some(<number>response);
      },
      error: (reason) => {
        handleError(reason)
      }
    })
  }

  registerOrganizationAndUser(registerForm: RegisterOrganizationAndUser, onSuccess:() => void, onFailure:(exceptions:Array<string>) => void) {
    if(this.token.isDefined()) {
      const preparedToken = RegisterOrganizationHelper.prepareToken(this.token.get());

      this.http.post<Typed<CommandResponse>>(restUrl("saga/register-organization-user/" + preparedToken), registerForm, {withCredentials: true}).subscribe({
        next: (response: any) => {
          if (isSuccess(response)) {
            onSuccess();
          } else {
            onFailure([nonSuccessMessages(response)]);
          }
        },
        error: (reason) => {
          handleError(reason)
        }
      })
    } else {
      toastr.error("Incorrect email");
    }

  }

  confirmEmail(emailConfirm: ConfirmPersonEmail, onSuccess: (confirmResponse: EmailConfirmResponse) => void, onFailure: (exceptions: Array<string>) => void, onError: () => void) {
    this.http.post<Typed<CustomSuccessResponse<EmailConfirmResponse>>>(restUrl("users/email-confirm"), emailConfirm, {withCredentials: true}).subscribe({
      next: (response) => {
        customCommandResponseHandler(response)
          .onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion, info: any) => onSuccess(<EmailConfirmResponse>info))
          .onFailure((exceptions: Array<string>) => onFailure(exceptions))
          .handle();
      },
      error: (reason) => {
        handleError(reason)
      }
    })
  }

  confirmEmailAndSetPassword(userId: string, activationCode: string, password: string, repeatPassword: string,
                             onSuccess: () => void, onFailure: (exceptions: Array<string>) => void) {;
    this.http.post<Typed<CommandResponse>>(restUrl("users/email-confirm-set-password"),
      new EmailConfirmWithPasswordSet(userId, activationCode, password, repeatPassword), {withCredentials: true}).subscribe({
      next: (response: any) => {
        if (isSuccess(response)) { onSuccess(); }
        else { onFailure([nonSuccessMessages(response)]); }
      },
      error: (reason) => { onFailure(reason) }
    })
  }

  //
  // createDefaultAuthorization(onSuccess:(groups: Array<GroupInfo>) => void, onFailure:(exceptions:Array<string>) => void) {
  //   this.authenticatedHttp.post("saga/create-default-authorization-configuration", new CreateDefaultAuthorization(),
  //     (data:Typed<CreateDefaultAuthorizationSagaResponse>) => {
  //       if(Typed.className(data) === DefaultAuthorizationCreated.className) {
  //         onSuccess(DefaultAuthorizationCreated.copy(Typed.value(<Typed<DefaultAuthorizationCreated>>data)).groups);
  //       } else if (Typed.className(data) === DefaultAuthorizationCreationFailure.className) {
  //         onFailure(Typed.value(<Typed<DefaultAuthorizationCreationFailure>>data).exceptions);
  //       } else {
  //         toastr.error("Incorrect server response");
  //       }
  //
  //     });
  // }
  //
  //
  //
  checkIfEmailExists(email:string, onSuccess:(result:boolean) => void):void {
    // new request to server
    if(email) {
      this.http.post<boolean>(restUrl("user-accounts/email-exists"), new EmailExists(email), {withCredentials: true}).subscribe({
        next: (response => {
          onSuccess(response);
        }),
        error: (reason) => {
          handleError(reason)
        }
      })
    } else {
      onSuccess(false);
    }
  }

  checkIfOrganizationNameExists(name:string, onSuccess:(result:boolean) => void):void {
    // new request to server
    this.http.post<ExistsResponse>(restUrl("organization/name-exists"), new NameExists(name), {withCredentials: true}).subscribe({
      next: ((response: ExistsResponse) => {
        onSuccess(response.result!);
      }),
      error: (reason) => {
        handleError(reason)
      }
    })
  }

  checkIfURLInUse(alias:string, onSuccess:(result:boolean) => void):void {
    // new request to server
    this.http.post<ExistsResponse>(restUrl("organization/alias-exists"), new AliasExists(alias), {withCredentials: true}).subscribe({
      next: ((response: ExistsResponse) => {
        onSuccess(response.result!);
      }),
      error: (reason) => {
        handleError(reason)
      }
    })
  }

}
