import {mySetTimeout, mySetTimeoutNoAngular} from "@utils";

export interface BlinkingValueComponentViewModel {

  addValueChangeListener(valueChangeListener: () => void): void;
}

export class BlinkingComponentController {

  private lastTimeout: number = -1;

  constructor(readonly $container: HTMLElement, readonly inputSelector: string,
              readonly viewModel: BlinkingValueComponentViewModel) {
    viewModel.addValueChangeListener(() => this.onValueChanged());
  }

  private onValueChanged() {

    clearTimeout(this.lastTimeout);

    this.lastTimeout = mySetTimeoutNoAngular(() => {
      const $input = this.$container.querySelector(this.inputSelector);
      if($input === null) {
        throw new Error("No input found");
      } else {
        $input.classList.remove("changed2");
        $input.classList.add("changed1");
        this.lastTimeout = mySetTimeoutNoAngular(() => {
          $input.classList.remove("changed1");
          $input.classList.add("changed2");
          this.lastTimeout = mySetTimeoutNoAngular(() => {
            $input.classList.remove("changed2");
          }, 3000);
        }, 200);
      }
    });
  }

  destroy() {
    clearTimeout(this.lastTimeout);
  }
}


