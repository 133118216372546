// Reflects server side

import {AggregateId, Language} from "@utils";

export interface RegisterFormType {
  isAdminForm(): boolean;
}

export class RegisterForm implements RegisterFormType {
  email: string = "";
  password: string = "";
  repeatPassword: string = "";
  firstName: string = "";
  lastName: string = "";
  licenseAccepted: boolean = false;

  isAdminForm() {
    return false;
  }
}

export class RegisterOrganizationAndUser extends RegisterForm {
  organizationName: string = "";
  alias: string = "";
  industry: string = "";
  outsourcer: boolean = false;
  timezone: string | undefined = "";
  language: Language | undefined;

  override isAdminForm() {
    return false;
  }
}

export class RegisterAdministrator extends RegisterForm {
  code: string = "";

  override isAdminForm() {
    return true;
  }
}

// Reflects server side
export class EmailExists {
  constructor(readonly email: string) {}
}

// Reflects server side
export class EmailExistsResponse {
  result: boolean | undefined;
}

// Reflects server side
export class NameExists {
  constructor(readonly name: string) {}
}

// Reflects server side
export class AliasExists {
  constructor(readonly alias: string) {}
}

// Reflects server side
export class ExistsResponse {
  result: boolean | undefined;
}

class CreateDefaultAuthorization {
}

interface CreateDefaultAuthorizationSagaResponse {
  className(): string;
}

export class RegisterOrganizationHelper {

  static multiplier = 15131;

  static prepareToken(token: number): number {
    return (token * this.multiplier) % 1000000;
  }
}
