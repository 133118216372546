import {
  AggregateVersion,
  ApplicationId, I18nText, LocalDateTime,
  Option,
  OrganizationId, OrganizationNodeId, PersonId,
  ScreenId,
  ScreenIdentifier,
  ScreenReleaseId
} from "@utils";
import {ScreenLifeMode} from "@shared";

export class ScreenRef {
  private constructor(readonly id: string|undefined, // must be undefined instead of null for proper JSON serialization
                      readonly identifier: ScreenIdentifier|undefined) {}// must be undefined instead of null for proper JSON serialization

  static ofId(id: ScreenId) {
    return new ScreenRef(id.id, undefined);
  }

  static ofIdentifier(identifier: string) {
    return new ScreenRef(undefined, identifier);
  }

  static copy(other: ScreenRef) {
    if(other.id != undefined) {
      return new ScreenRef(other.id, undefined);
    } else {
      return new ScreenRef(undefined, other.identifier);
    }
  }

  idOption(): Option<ScreenId> {
    return Option.of(this.id).map(id => new ScreenId(id));
  }
  identifierOption(): Option<string> {
    return Option.of(this.identifier);
  }

  toIdentifierString(): string {
    if(this.id != undefined) {
      return "@"+this.id;
    } else  if(this.identifier != undefined){
      return this.identifier;
    } else {
      throw new Error("Invalid screen reference");
    }

  }
}

export class ScreenSummaryWithApplicationInfo {
  constructor(
    readonly applicationId: ApplicationId,
    readonly applicationName: string,
    readonly screen: ScreenSummary) {}

  static copy(other: ScreenSummaryWithApplicationInfo) {
    return new ScreenSummaryWithApplicationInfo(
      ApplicationId.of(other.applicationId),
      other.applicationName,
      ScreenSummary.copy(other.screen));
  }
}

export class ScreenSummary {
  constructor(
    readonly id: ScreenId,
    readonly version: AggregateVersion,
    readonly organizationId: OrganizationId,
    readonly identifier: string,
    readonly applicationId: ApplicationId,
    readonly name: string
  ) {}

  static copy(other: ScreenSummary): ScreenSummary {
    return new ScreenSummary(
      ScreenId.copy(other.id),
      AggregateVersion.copy(other.version),
      OrganizationId.of(other.organizationId),
      other.identifier,
      ApplicationId.of(other.applicationId),
      other.name
    )
  }
}

export class RunnableScreenSummary {
  constructor(
    readonly id: ScreenId,
    readonly identifier: string,
    readonly organizationId: OrganizationId,
    readonly applicationId: ApplicationId,
    readonly name: string,
    readonly lifeMode: ScreenLifeMode
  ) {}

  static copy(other: RunnableScreenSummary): RunnableScreenSummary {
    return new RunnableScreenSummary(
      ScreenId.copy(other.id),
      other.identifier,
      OrganizationId.of(other.organizationId),
      ApplicationId.of(other.applicationId),
      other.name,
      ScreenLifeMode.copy(other.lifeMode)
    )
  }

  identifierOrId() {
    return this.identifier.length === 0 ? ("@" + this.id.id) : this.identifier;
  }
}


export class ScreenReleaseSummary {
  constructor(
    readonly id: ScreenReleaseId,
    readonly version: AggregateVersion,
    readonly releaseNumber: number,
    readonly comment: string,
    readonly code: string,
    readonly creationDate: LocalDateTime,
    readonly creator: PersonId
  ) {}

  static copy(other: ScreenReleaseSummary) {
    return new ScreenReleaseSummary(
      ScreenReleaseId.copy(other.id),
      AggregateVersion.copy(other.version),
      other.releaseNumber,
      other.comment,
      other.code,
      LocalDateTime.copy(other.creationDate),
      PersonId.of(other.creator)
    )
  }
}


export class Screen {
  constructor(
    readonly organizationId: OrganizationId,
    readonly identifier: string,
    readonly applicationId: ApplicationId,
    readonly name: I18nText,
    readonly workingRelease: Option<ScreenReleaseId>,
    readonly activeRelease: Option<ScreenReleaseId>,
    readonly parent: Option<OrganizationNodeId>, // process, report, entity
    readonly previewAuthorization: Array<OrganizationNodeId>,
    readonly editAuthorization: Array<OrganizationNodeId>,
    readonly runAuthorization: Array<OrganizationNodeId>,
    readonly runExternalAnonymously: boolean,
    readonly runExternalByServices: boolean,
    readonly runExternalByServicesAuthorization: Array<OrganizationNodeId>) {}

  static copy(other: Screen) {
    return new Screen(
      OrganizationId.of(other.organizationId),
      other.identifier,
      ApplicationId.of(other.applicationId),
      I18nText.copy(other.name),
      Option.copy(other.workingRelease).map(ScreenReleaseId.copy),
      Option.copy(other.activeRelease).map(ScreenReleaseId.copy),
      Option.copy(other.parent).map(OrganizationNodeId.copy),
      other.previewAuthorization.map(OrganizationNodeId.copy),
      other.editAuthorization.map(OrganizationNodeId.copy),
      other.runAuthorization.map(OrganizationNodeId.copy),
      other.runExternalAnonymously,
      other.runExternalByServices,
      other.runExternalByServicesAuthorization.map(OrganizationNodeId.copy)
    )
  }
}


export class ScreenInfo {
  constructor(
    readonly id: ScreenId,
    readonly version: AggregateVersion,
    readonly organizationId: OrganizationId,
    readonly applicationId: ApplicationId,
    readonly screen: Option<Screen>,
    readonly releases: Array<ScreenReleaseSummary>,
    readonly workingRelease: Option<ScreenReleaseId>,
    readonly activeRelease: Option<ScreenReleaseId>
  ) {}

  static copy(other: ScreenInfo): ScreenInfo {
    return new ScreenInfo(
      ScreenId.copy(other.id),
      AggregateVersion.copy(other.version),
      OrganizationId.of(other.organizationId),
      ApplicationId.of(other.applicationId),
      Option.copy(other.screen).map(Screen.copy),
      other.releases.map(ScreenReleaseSummary.copy),
      Option.copy(other.workingRelease, ScreenReleaseId.copy),
      Option.copy(other.activeRelease, ScreenReleaseId.copy)
    )
  }
}
