import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {__, ___, ApplicationId, i18n, Search} from "@utils";
import {ApplicationIcon, ApplicationIcons, ApplicationsSharedService} from "@shared-model";

export interface ApplicationOption {
  id: ApplicationId;
  name: string;
  applicationIcon: ApplicationIcon;
  count: string;
}

export interface ApplicationSelector {
  id: ApplicationId;
  name: string;
  applicationIcon: ApplicationIcon;
  count: string;
  selected: boolean;
}

@Component({
  selector: 'my-application-filter-selector',
  templateUrl: './application-filter-selector.component.html',
})
export class ApplicationFilterSelectorComponent implements OnInit {

  // external state
  private _available?: Array<ApplicationOption>;
  private _selected: Array<ApplicationId> = [];

  private allApplications: Array<ApplicationOption> = [];

  allSelectors: Array<ApplicationSelector> = [];
  filteredSelectors: Array<ApplicationSelector> = [];
  selectedList: Array<ApplicationOption> = [];

  allApplicationsVisible: boolean = true;

  opened: boolean = false;

  contentSizeChanged = new EventEmitter<void>;

  @Input() set selected(selected: Array<ApplicationId>) {
    this._selected = selected;
    this.updateSelectors();
  };

  /** If applications are not set all applications available for user will be used */
  @Input() set available(available: Array<ApplicationOption>) {
    this._available = available;
    this.updateSelectors();
  };

  @Output() selectedChange = new EventEmitter<Array<ApplicationId>>();
  query: string = "";


  @Input() searchBarMode: boolean = true;

  constructor(private readonly applicationsSharedService: ApplicationsSharedService) {}

  ngOnInit(): void {
    if(this._available === undefined) {
      Promise.all([
        this.applicationsSharedService.loadAllProcessesAndApplications(),
        this.applicationsSharedService.loadUsableApplications()
      ]).then(([allProcessesAndApplications, usableApplications]) => {

        const allApplications: Array<ApplicationOption> = allProcessesAndApplications.applications.map((app) => {

          const icon = ApplicationIcons.getApplicationIcon(app.id, app.iconCode, app.colorId);

          return {
            id: app.id,
            name: app.name,
            applicationIcon: icon,
            count: ""
          }
        });

        const availableFilterApplications: Array<ApplicationOption> = usableApplications.map((app) => {
          const icon = ApplicationIcons.getApplicationIcon(app.id, app.iconCode, app.colorId);
          return {
            id: app.id,
            name: app.name,
            applicationIcon: icon,
            count: ""
          }
        });

        this.allApplications = ___(allApplications.concat(availableFilterApplications))
          .uniqueBy(a => a.id.id)
          .sortByAlphanumeric(s => s.name)
          .value();


        this.updateSelectors();
      });
    }


  }


  toggleMenu() {
    this.opened = !this.opened;
    this.query = "";
    this.filterByQuery();
  }



  private updateSelectors() {

    if(this._available || this.allSelectors) {
      const applications = this._available === undefined ? this.allApplications : this._available;


      this.selectedList = ___(applications).filter(application => __(this._selected).exists(a => a.id === application.id.id)).sortByAlphanumeric(a => a.name).value();
      this.allSelectors = ___(applications).sortByAlphanumeric(a => a.name).map(a => ({
        id: a.id,
        name: a.name,
        applicationIcon: a.applicationIcon,
        count: a.count,
        selected: __(this._selected).exists(s => s === a.id),
      })).value();
      this.filterByQuery();
    } // otherwise it will be initializaed after load

  }

  selectApplication(application: ApplicationSelector) {
    this.selectedChange.emit([application.id]);
    this._selected = [application.id];
    this.allSelectors.forEach(a => a.selected = false);
    application.selected = true;
    this.selectedList = this.allSelectors.filter(a => a.selected);
    this.opened = false;
  }

  clearSelection() {
    this._selected = [];
    this.selectedList = [];
    this.selectedChange.emit(this._selected);
    this.opened = false;
  }

  toggleSelected(application: ApplicationSelector) {
    application.selected = !application.selected;
    this.selectedList = ___(this.allSelectors).filter(a => a.selected).sortByAlphanumeric(a => a.name).value();
    this._selected = this.allSelectors.filter(a => a.selected).map(a => a.id);
    this.selectedChange.emit(this._selected);
  }

  queryChanged() {
    this.filterByQuery();
  }

  filterByQuery() {
    if(this.query === "") {
      this.filteredSelectors = this.allSelectors;
    } else {
      this.filteredSelectors = Search.filter(this.allSelectors, a => a.name, this.query);
      this.allApplicationsVisible = Search.matches(i18n('tasks_all_applications'), this.query);
    }
    this.contentSizeChanged.emit();
  }
}
