import {None, Typed} from "@utils";
import {
  ButtonComponentRefState,
  ButtonComponentState,
  CalendarComponentState,
  DateInputComponentRefState,
  DateInputComponentState,
  DateTimeInputComponentRefState,
  DateTimeInputComponentState,
  DropListComponentRefState,
  DropListComponentState,
  DurationInputComponentRefState,
  DurationInputComponentState,
  HtmlComponentRefState,
  HtmlComponentState,
  ImageComponentRefState,
  ImageComponentState,
  LabelComponentRefState,
  LabelComponentState,
  LinkComponentRefState,
  LinkComponentState,
  MapComponentRefState,
  MapComponentState, ModalContainerRefState, ModalContainerState,
  MultiAttachmentInputComponentRefState,
  MultiAttachmentInputComponentState,
  MultiCheckboxComponentRefState,
  MultiCheckboxComponentState,
  NumberInputComponentRefState,
  NumberInputComponentState,
  PasswordInputComponentRefState,
  PasswordInputComponentState,
  PersonSelectComponentRefState,
  PersonSelectComponentState,
  PropertiesStateHolder,
  RadioButtonComponentRefState,
  RadioButtonComponentState,
  RepeatableContainerRefState,
  RepeatableContainerState,
  ScreenComponentRefState,
  ScreenComponentState,
  SectionContainerRefState,
  SectionContainerState,
  SingleAttachmentInputComponentRefState,
  SingleAttachmentInputComponentState,
  SingleCheckboxComponentRefState,
  SingleCheckboxComponentState,
  SwitchComponentRefState,
  SwitchComponentState,
  TableContainerRefState,
  TableContainerState,
  TabsContainerRefState,
  TabsContainerState,
  TextInputComponentRefState,
  TextInputComponentState,
  TimeInputComponentRefState,
  TimeInputComponentState, ViewSwitcherContainerRefState, ViewSwitcherContainerState,
  WidgetComponentRefState,
  WidgetComponentState
} from "..";

export class ScreenComponentRefStateFactory {
  static copy(other: ScreenComponentRefState): ScreenComponentRefState {
    return ScreenComponentRefStateFactory.copyByType(other, other.className());
  }

  static copyTyped(variable: Typed<ScreenComponentRefState>): Typed<ScreenComponentRefState> {
    return Typed.of(ScreenComponentRefStateFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(other: ScreenComponentRefState, className: string): ScreenComponentRefState {
    switch (className) {
      // case BarChartComponentRefState.className: return BarChartComponentRefState.copy(<BarChartComponentRefState>other);
      case ButtonComponentRefState.className: return ButtonComponentRefState.copy(<ButtonComponentRefState>other);
      // case DataTableComponentRefState.className: return DataTableComponentRefState.copy(<DataTableComponentRefState>other);
      case LabelComponentRefState.className: return LabelComponentRefState.copy(<LabelComponentRefState>other);
      case LinkComponentRefState.className: return LinkComponentRefState.copy(<LinkComponentRefState>other);
      case ImageComponentRefState.className: return ImageComponentRefState.copy(<ImageComponentRefState>other);
      case HtmlComponentRefState.className: return HtmlComponentRefState.copy(<HtmlComponentRefState>other);
      case MapComponentRefState.className: return MapComponentRefState.copy(<MapComponentRefState>other);
      // case CalendarComponentRefState.className: return CalendarComponentRefState.copy(<CalendarComponentRefState>other);
      case NumberInputComponentRefState.className: return NumberInputComponentRefState.copy(<NumberInputComponentRefState>other);
      case SectionContainerRefState.className: return SectionContainerRefState.copy(<SectionContainerRefState>other);
      case ModalContainerRefState.className: return ModalContainerRefState.copy(<ModalContainerRefState>other);
      case RepeatableContainerRefState.className: return RepeatableContainerRefState.copy(<RepeatableContainerRefState>other);
      case TableContainerRefState.className: return TableContainerRefState.copy(<TableContainerRefState>other);
      case SwitchComponentRefState.className: return SwitchComponentRefState.copy(<SwitchComponentRefState>other);
      case TabsContainerRefState.className: return TabsContainerRefState.copy(<TabsContainerRefState>other);
      case ViewSwitcherContainerRefState.className: return ViewSwitcherContainerRefState.copy(<ViewSwitcherContainerRefState>other);
      case TextInputComponentRefState.className: return TextInputComponentRefState.copy(<TextInputComponentRefState>other);
      case PasswordInputComponentRefState.className: return PasswordInputComponentRefState.copy(<PasswordInputComponentRefState>other);
      case DateInputComponentRefState.className: return DateInputComponentRefState.copy(<DateInputComponentRefState>other);
      case DateTimeInputComponentRefState.className: return DateTimeInputComponentRefState.copy(<DateTimeInputComponentRefState>other);
      case TimeInputComponentRefState.className: return TimeInputComponentRefState.copy(<TimeInputComponentRefState>other);
      case DurationInputComponentRefState.className: return DurationInputComponentRefState.copy(<DurationInputComponentRefState>other);
      case DropListComponentRefState.className: return DropListComponentRefState.copy(<DropListComponentRefState>other);
      case RadioButtonComponentRefState.className: return RadioButtonComponentRefState.copy(<RadioButtonComponentRefState>other);
      case MultiCheckboxComponentRefState.className: return MultiCheckboxComponentRefState.copy(<MultiCheckboxComponentRefState>other);
      case SingleCheckboxComponentRefState.className: return SingleCheckboxComponentRefState.copy(<SingleCheckboxComponentRefState>other);
      case MultiAttachmentInputComponentRefState.className: return MultiAttachmentInputComponentRefState.copy(<MultiAttachmentInputComponentRefState>other);
      case SingleAttachmentInputComponentRefState.className: return SingleAttachmentInputComponentRefState.copy(<SingleAttachmentInputComponentRefState>other);
      case PersonSelectComponentRefState.className: return PersonSelectComponentRefState.copy(<PersonSelectComponentRefState>other);
      case WidgetComponentRefState.className: return WidgetComponentRefState.copy(<WidgetComponentRefState>other);
      default:
        console.log("Mocking '" + className+"'");
        return new LabelComponentRefState(new PropertiesStateHolder([], None(), [], []));
        // throw new Error("Unsupported ScreenComponentRefState ["+className+"]");
    }
  }
}

export class ScreenComponentStateFactory {
  static copy(other: ScreenComponentState): ScreenComponentState {
    return ScreenComponentStateFactory.copyByType(other, other.className());
  }

  static copyTyped(variable: Typed<ScreenComponentState>): Typed<ScreenComponentState> {
    return Typed.of(ScreenComponentStateFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(other: ScreenComponentState, className: string): ScreenComponentState {
    switch (className) {
      // case BarChartComponentState.className: return BarChartComponentState.copy(<BarChartComponentState>other);
      case ButtonComponentState.className: return ButtonComponentState.copy(<ButtonComponentState>other);
      // case DataTableComponentState.className: return DataTableComponentState.copy(<DataTableComponentState>other);
      case LabelComponentState.className: return LabelComponentState.copy(<LabelComponentState>other);
      case LinkComponentState.className: return LinkComponentState.copy(<LinkComponentState>other);
      case ImageComponentState.className: return ImageComponentState.copy(<ImageComponentState>other);
      case HtmlComponentState.className: return HtmlComponentState.copy(<HtmlComponentState>other);
      case MapComponentState.className: return MapComponentState.copy(<MapComponentState>other);
      case CalendarComponentState.className: return CalendarComponentState.copy(<CalendarComponentState>other);
      case NumberInputComponentState.className: return NumberInputComponentState.copy(<NumberInputComponentState>other);
      case SectionContainerState.className: return SectionContainerState.copy(<SectionContainerState>other);
      case ModalContainerState.className: return ModalContainerState.copy(<ModalContainerState>other);
      case RepeatableContainerState.className: return RepeatableContainerState.copy(<RepeatableContainerState>other);
      case TableContainerState.className: return TableContainerState.copy(<TableContainerState>other);
      case SwitchComponentState.className: return SwitchComponentState.copy(<SwitchComponentState>other);
      case TabsContainerState.className: return TabsContainerState.copy(<TabsContainerState>other);
      case ViewSwitcherContainerState.className: return ViewSwitcherContainerState.copy(<ViewSwitcherContainerState>other);
      case TextInputComponentState.className: return TextInputComponentState.copy(<TextInputComponentState>other);
      case PasswordInputComponentState.className: return PasswordInputComponentState.copy(<PasswordInputComponentState>other);
      case DateInputComponentState.className: return DateInputComponentState.copy(<DateInputComponentState>other);
      case DateTimeInputComponentState.className: return DateTimeInputComponentState.copy(<DateTimeInputComponentState>other);
      case TimeInputComponentState.className: return TimeInputComponentState.copy(<TimeInputComponentState>other);
      case DurationInputComponentState.className: return DurationInputComponentState.copy(<DurationInputComponentState>other);
      case DropListComponentState.className: return DropListComponentState.copy(<DropListComponentState>other);
      case MultiCheckboxComponentState.className: return MultiCheckboxComponentState.copy(<MultiCheckboxComponentState>other);
      case SingleCheckboxComponentState.className: return SingleCheckboxComponentState.copy(<SingleCheckboxComponentState>other);
      case MultiAttachmentInputComponentState.className: return MultiAttachmentInputComponentState.copy(<MultiAttachmentInputComponentState>other);
      case SingleAttachmentInputComponentState.className: return SingleAttachmentInputComponentState.copy(<SingleAttachmentInputComponentState>other);
      case PersonSelectComponentState.className: return PersonSelectComponentState.copy(<PersonSelectComponentState>other);
      case RadioButtonComponentState.className: return RadioButtonComponentState.copy(<RadioButtonComponentState>other);
      case WidgetComponentState.className: return WidgetComponentState.copy(<WidgetComponentState>other);
      default: return new LabelComponentState(new PropertiesStateHolder([], None(), [], []), None());
        // throw new Error("Unsupported ScreenComponentState ["+className+"]");
    }
  }
}
