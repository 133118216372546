import {None} from "@utils";
import {ComponentViewModelUtils, ScreenComponentViewModel} from "./screen-component.view-model";
import {CssBuilder, LabelPosition, LabelProperties} from "@screen-common";
import {LabelPropertiesState} from "../model/screen-component-state.model";

export class ComponentLabelViewModel {

    public visible: boolean = false;
    public label: string = "";
    public positionClass: string = "";
    public css: string = "";
    public sizeCss: string = "";
    public cssClasses: string = "";
    public requiredVisible: boolean = false;
    public tooltip: string|undefined = undefined;
    public previewMode = false;
    public forceHide = false;

    public validationError: boolean = false;

    constructor(
      readonly parentComponent: ScreenComponentViewModel
    ) {}


    update(properties: LabelProperties, state: LabelPropertiesState, required: boolean, editable: boolean, preview: boolean, tooltip: string|undefined,
           validationError: boolean) {

      const optionalLabel = properties.label(this.parentComponent.definition.defaultPropertiesProvider).currentValue(() => state.label).valueOrDefault(None()).map(l => l.getCurrentWithFallback());
      this.label = optionalLabel.getOrElse("");

      this.visible = optionalLabel.isDefined() && !this.forceHide;

      this.requiredVisible = required && editable;

      this.positionClass = LabelPosition.byName(properties.labelPosition(this.parentComponent.skinName, this.parentComponent.typeName, this.parentComponent.componentClass, this.parentComponent.defaultPropertyProvider).currentValue(() => state.labelPosition).valueOrDefault("")).name;

      const cssBuilder = CssBuilder.create();

      ComponentViewModelUtils.toLabelCss(cssBuilder, this.parentComponent.skinName, this.parentComponent.typeName, this.parentComponent.componentClass, this.parentComponent.defaultPropertyProvider, properties, state);

      this.css = cssBuilder.toCss();
      this.cssClasses = cssBuilder.toCssClasses();
      this.tooltip = tooltip;

      this.previewMode = preview;
      this.validationError = validationError;
    }

    updateStateFlags(required: boolean, editable: boolean, preview: boolean) {

      this.requiredVisible = required && editable;
      this.previewMode = preview;
    }

    forceHideLabel(forceHide: boolean) {
      this.forceHide = forceHide;

      this.visible = this.label.length > 0 && !this.forceHide;

    }
  }

