import {Component, Input} from "@angular/core";
import {TaskListManagerService, TaskSummaryViewModel} from "@shared-model";
import {TasksServerModel} from "../../../modules/task-form.module/service/TasksServerModel";
import {TaskViewModel} from "../../../modules/task-form.module/TaskViewModel";

@Component({
  selector: "my-add-label-input",
  templateUrl: "./add-label-input.component.html",
})
export class AddLabelInputComponent {

  @Input() mobile = false;

  @Input({required: true}) tasksServerModel!: TasksServerModel;
  @Input({required: true}) task!: TaskSummaryViewModel|TaskViewModel;
  @Input() buttonClass = 'theme-button-icon-action-flat-small';

  newLabel: string = "";

  addLabelOpened: boolean = false;
  addLabelAnchor?: HTMLButtonElement;

  constructor(
    readonly taskListManagerService: TaskListManagerService,
  ) {}

  toggleAddLabel(addLabelAnchor: HTMLButtonElement) {
    this.addLabelOpened = !this.addLabelOpened;
    this.addLabelAnchor = addLabelAnchor;
  }

  labelAddConfirmed(label: string) {
    if (label !== undefined && label !== "") {
      if (this.task instanceof TaskSummaryViewModel) {
        const cursor = this.task.cursorInfo.getOrError("cursorInfo is undefined");
        this.tasksServerModel.addLabel(this.task.toTaskIdentifier(), cursor, this.newLabel);
        this.task.addLabel(label);
      } else if (this.task instanceof TaskViewModel) {
        this.task.addLabel(label);
      }
    }
    this.closeNewLabelPopup();
  }

  labelChangeCanceled() {
    this.closeNewLabelPopup();
  }

  taskPlainLabels() {
    return this.task.getPlainLabels();
  }

  private closeNewLabelPopup() {
    this.newLabel = "";
    this.addLabelOpened = false;
    this.addLabelAnchor = undefined;
  }
}
