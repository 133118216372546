import {
  ComponentStatus,
  ComponentViewModelUtils,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils,
  TabsContainerRefState,
  TabsContainerState,
  TabState,
} from "../..";
import {__, ___, clearArray, I18nText, None, Option, overwriteArray, Some, VariableId} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {
  CssBuilder, CssUtils,
  TabEntryDefinition,
  TabsContainerDefinition, TabsContainerRef
} from "@screen-common";
import {
  LayoutAlign,
  LayoutStretch,
  LayoutType,
  LayoutWrap,
  ScreenComponentRefIdInScreen,
  TextFont
} from "@screen-common";

export class TabEntryViewModel {

  public header: string = "";
  public visible: boolean = false;
  public editable: boolean = false;
  public uncovered: boolean = false;
  public enabled: boolean = false;

  public uncoveredAndVisible: boolean = false;

  public layoutClass: string = "";
  public layoutAlignClass: string = "";
  public layoutStretchClass: string = "";
  public layoutWrapClass: string = "";

  public childrenPlainCss = "";
  public childrenPlainCssClasses = "";


  public childrenPanelDeltaHeightRem = 0;

  readonly children: Array<ScreenComponentViewModel> = [];

  constructor(readonly parent: TabsContainerViewModel,
              readonly definition: TabEntryDefinition,
              readonly componentState: TabState,
              readonly prefix: string) {}

  update(deep: boolean) {

    const childrenPlainCssBuilder = CssBuilder.create();

    const unit = ScreenFormConfig.panelUnitRemSize;

    const layout = LayoutType.byName(this.definition.layoutsProperties(this.parent.definition.properties).layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));
    const layoutAlign = LayoutAlign.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutAlign.currentValue(() => this.componentState.layoutsState.layoutAlign).valueOrDefault(LayoutAlign.DEFAULT.name));
    const layoutStretch = LayoutStretch.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutStretch.currentValue(() => this.componentState.layoutsState.layoutStretch).valueOrDefault(LayoutStretch.DEFAULT.name));
    const layoutWrap = LayoutWrap.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutWrap.currentValue(() => this.componentState.layoutsState.layoutWrap).valueOrDefault(LayoutWrap.wrap.name));
    this.layoutClass = layout.name;
    this.layoutAlignClass = layoutAlign.name;
    this.layoutStretchClass = layoutStretch.name;
    this.layoutWrapClass = layoutWrap.name;


    this.header = this.definition.getHeader(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.i18nTextProperty("tab|"+this.definition.id +"|header")).valueOrDefault(I18nText.empty()).getCurrentWithFallback();
    this.visible = this.definition.getVisible(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("tab|"+this.definition.id +"|visible")).valueOrDefault(false);
    this.editable = this.definition.getEditable(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("tab|"+this.definition.id +"|editable")).valueOrDefault(true);
    this.uncovered = this.definition.getUncovered(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("tab|"+this.definition.id +"|uncovered")).valueOrDefault(true);
    this.enabled = this.definition.getEnabled(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("tab|"+this.definition.id +"|enabled")).valueOrDefault(true);


    if(this.visible) {
      if (this.parent.definition.model.enabled) {
        const modelProperty = this.parent.definition.model.currentValue(() => this.parent.componentState.properties.optionalModelProperty("model"));
        if (this.parent.componentState.innerContext.isDefined() && modelProperty.isSuccess()) {
          this.updateChildren(this.parent.componentState.innerContext.getOrError("Should be defined"));
        } else {
          clearArray(this.children);
        }
      } else {
        this.updateChildren(this.parent.context);
      }
    } else {
      clearArray(this.children);
    }

    this.children.forEach(child => child.updateComponent(deep));

    this.uncoveredAndVisible = this.uncovered && this.visible;

    const textSize = this.definition.contentTextSize(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const textColor = this.definition.contentTextColor(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextColor).valueOrDefault(None());
    const textFont = this.definition.contentTextFont(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

    CssUtils.fontCss(childrenPlainCssBuilder, textFont, textSize, false, false, false, false, textColor);

    const contentMinHeight = this.parent.definition.sizeProperties.contentMinHeight.currentValue(() => this.parent.componentState.boxState.contentMinHeight).valueOrDefault(None());
    const contentMinWidth = this.parent.definition.sizeProperties.contentMinWidth.currentValue(() => this.parent.componentState.boxState.contentMinWidth).valueOrDefault(None());

    ComponentViewModelUtils.toPaddingsCss(childrenPlainCssBuilder, this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.definition.paddingsProperties(this.parent.definition.properties), this.componentState.paddingsState);

    const headerMinHeight = Some("2.625rem");

    CssUtils.childrenPlainMinHeight(childrenPlainCssBuilder,
      contentMinHeight.map(v => SizeProperty.sizeToCss(unit, v)),
      headerMinHeight, true,
      Some(this.childrenPanelDeltaHeightRem + "rem")
    );

    CssUtils.childrenPlainMinWidth(childrenPlainCssBuilder,
      contentMinWidth.orElse(Some("1cm")).map(v => SizeProperty.sizeToCss(unit, v))
    );

    const backgroundColorValue = this.definition.backgroundsProperties(this.parent.definition.properties).backgroundColor(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider).currentValue(() => this.componentState.backgroundsState.backgroundColor).valueOrDefault(None());
    CssUtils.backgroundColor(childrenPlainCssBuilder, backgroundColorValue);

    if(!layout.isStatic()) {
      const gapColumn = this.definition.layoutsProperties(this.parent.definition.properties).gapColumn.currentValue(() => this.componentState.layoutsState.gapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      const gapRow = this.definition.layoutsProperties(this.parent.definition.properties).gapRow.currentValue(() => this.componentState.layoutsState.gapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      CssUtils.gaps(childrenPlainCssBuilder, gapRow, gapColumn);
    }

    this.childrenPlainCss = childrenPlainCssBuilder.toCss();
    this.childrenPlainCssClasses = childrenPlainCssBuilder.toCssClasses();

  }

  updateChildren(childrenContext: VariableId) {
    const children = this.definition.children.map(childRefId => {
      return __(this.children).find(child => child.ref.id.id === childRefId.id && child.context.id === childrenContext.id)
        .getOrElseLazy(() => this.parent.createViewModel(this.parent, ScreenComponentRefIdInScreen.of(this.parent.componentScreenId, childRefId), this.parent.componentState.innerContext));
    });
    overwriteArray(this.children, children);
  }


  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if(child.id == id) {
        return Some(child);
      } else if(child.isContainer()) {
        const childSuccessor = (<ScreenContainerViewModel>child).getComponentById(id);
        if(childSuccessor.isDefined()) {
          return childSuccessor;
        }
      }
    }
    return None();
  }

  static create(parent: TabsContainerViewModel, definition: TabEntryDefinition, componentState: TabState, prefix: string) {

    return new TabEntryViewModel(parent, definition, componentState, prefix);
  }

  clearChildren() {
    clearArray(this.children);
  }
}

export class TabsContainerViewModel extends ScreenContainerViewModel {

  override typeName = "Tabs";

  public activeTab: TabEntryViewModel|null = null;

  public override customCssClass: string = "";
  public css: string = "";
  public cssClasses: string = "";
  public headerCss: string = "";
  public headerCssClasses: string = "";

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              override readonly definition: TabsContainerDefinition,
              override readonly componentScreenId: string,
              readonly ref: TabsContainerRef,
              override readonly refScreenId: string,
              readonly componentState: TabsContainerState,
              readonly refState: TabsContainerRefState,
              readonly context: VariableId,
              readonly serverModel: ScreenInstanceServerModel,
              public tabs: Array<TabEntryViewModel>,
              readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
    super(parent, componentState, refState, definition, shared);
  }

  getLayoutForComponent(componentRefId: ScreenComponentRefId) {
    const tabDefinition = __(this.definition.tabs).find(t => __(t.children).exists(c => c.id == componentRefId.id)).getOrError("No tab found");
    const tab = __(this.tabs).find(t => t.definition.id === tabDefinition.id).getOrError("No tab view model found");
    const layoutName = tabDefinition.layoutsProperties(this.definition.properties)
      .layout.currentValue(() => tab.componentState.layoutsState.layout)
      .valueOrDefault(LayoutType.horizontal.name)
    return LayoutType.byName(layoutName);
  }

  injectTabs(tabs: Array<TabEntryViewModel>) {
    this.tabs = tabs;
    this.update();
  }

  getAllChildren(): Array<ScreenComponentViewModel> {
    return __(this.tabs).flatMap(t => t.children);
  }

  selectTab(tab: TabEntryViewModel) {
    this.activeTab = tab;
  }

  updateContainer(deep: boolean) {

    if (!this.uncoveredAndVisible || this.status != ComponentStatus.initialized) {
      this.tabs.forEach(t => t.clearChildren());
    } else {

      const headerCssBuilder = CssBuilder.create();
      const cssBuilder = CssBuilder.create();

      ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
      ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);

      ComponentViewModelUtils.toHeaderCss(headerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.headerProperties, this.componentState.headerState);

      this.tabs.forEach(t => t.update(deep));

      const visibleTabs = this.tabs.filter(t => t.visible);

      if(this.activeTab !== null && !visibleTabs.includes(this.activeTab)) {
        this.activeTab = null;
      }

      if(this.activeTab == null) {
        this.activeTab = __(visibleTabs).firstOption().getOrNull();
      }

      this.headerCss = headerCssBuilder.toCss();
      this.headerCssClasses = headerCssBuilder.toCssClasses();

      this.css =cssBuilder.toCss();
      this.cssClasses = cssBuilder.toCssClasses();


    }
    super.updatePosition();
  }


  static create(shared: ScreenSharedViewModel,
                parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                context: VariableId,
                definition: TabsContainerDefinition,
                componentScreenId: string,
                ref: TabsContainerRef,
                refScreenId: string,
                componentState: TabsContainerState,
                refState: TabsContainerRefState,
                serverModel: ScreenInstanceServerModel,
                createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {

    const viewModel =  new TabsContainerViewModel(shared, parent, definition, componentScreenId, ref, refScreenId, componentState, refState, context, serverModel, [], createViewModel);

    const tabs = definition.tabs.map((tab, index) => {
      return TabEntryViewModel.create(viewModel, tab, new TabState(componentState.properties, index), "tab|"+index+"|");
    });

    viewModel.injectTabs(tabs);

    return viewModel;
  }

  childDeltaHeightChanged(): void {
  }

  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.tabs.length; i++) {
      const fromEntry = this.tabs[i].getComponentById(id);
      if (fromEntry.isDefined()) {
        return fromEntry;
      }

    }
    return None();
  }

}
