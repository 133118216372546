import {FileUri, None, VariableId} from "@utils";
import {ImageComponentRefState, ImageComponentState} from "./ImageComponentState";
import {
  ComponentViewModelUtils,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "../screen-component.view-model";
import {ScreenSharedViewModel} from "../..";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {CssBuilder, FileSource, ImageComponentDefinition, ImageComponentRef} from "@screen-common";

export class ImageComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Image";

  public fileSource!: FileSource;
  public css: string = "";
  public cssClasses: string = "";

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: ImageComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: ImageComponentRef,
              override readonly refScreenId: string,
              readonly componentState: ImageComponentState,
              readonly refState: ImageComponentRefState,
              readonly serverModel: ScreenInstanceServerModel) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }


  updateComponent(deep: boolean): void {

    const cssBuilder = CssBuilder.create();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider,this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    const currentValueOption = this.definition.fileSource.currentValue(() => this.componentState.fileSource).valueOrDefault(None());

    this.fileSource = new FileSource("");

    currentValueOption.forEach(currentValue => {
      if(currentValue instanceof FileUri) {
        this.fileSource = new FileSource(currentValue.serialize());
      } else {
        throw new Error("Unexpected current value type");
      }
      if(this.fileSource.fileUri.isRepositoryFile()) {
        this.fileSource.downloadUrl = this.serverModel.getFileDownloadUri(this.componentRefPath(), this.fileSource.fileUri);
      } else if(this.fileSource.fileUri.isRepositoryFileId()) {
        this.fileSource.downloadUrl = this.serverModel.getFileDownloadUri(this.componentRefPath(), this.fileSource.fileUri);
      } else {
        this.fileSource.downloadUrl = this.fileSource.url;
      }
    });


    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();
  }


}

