

// const depolonization: Array<[string, string]> = [
//   ['ą', 'a'],
//   ['ć', 'c'],
//   ['ę', 'e'],
//   ['ł', 'l'],
//   ['ń', 'n'],
//   ['ó', 'o'],
//   ['ś', 's'],
//   ['ż', 'z'],
//   ['ź', 'z'],
//   ['Ą', 'A'],
//   ['Ć', 'C'],
//   ['Ę', 'E'],
//   ['Ł', 'L'],
//   ['Ń', 'N'],
//   ['Ó', 'O'],
//   ['Ś', 'S'],
//   ['Ż', 'Z'],
//   ['Ź', 'Z']
// ];
//
// export function depolonizeLowerCase(text: string): string {
//   return depolonize(text).toLocaleLowerCase();
// }
//
// export function depolonize(text: string): string {
//   let result = text;
//   depolonization.forEach((substitution: [string, string]) => {
//     result = result.replace(substitution[0], substitution[1]);
//   });
//   return result;
// }



function spaceIfNonBreakingSpace(separator: string) {
  if(separator == '\xa0') {
    return " ";
  } else {
    return separator;
  }
}

const whitespaceRegexp = new RegExp(String.fromCharCode(160), "g");
export function nbspToSpace(text: string) {
  return text.replace(whitespaceRegexp, " ");
}

export const decimalSeparator = (1.1).toLocaleString()[1];

function findThousandSeparator() {
  const stringified = (1000000).toLocaleString();
  if(stringified.length > 7) {
    return spaceIfNonBreakingSpace(stringified[1]);
  } else {
    return '';
  }
}
export const thousandSeparator = findThousandSeparator();
