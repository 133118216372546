import {ApplicationId, MailboxId, Option} from "@utils";

export class RunnableMailboxSummary {

  constructor(readonly id: MailboxId,
              readonly identifier: string,
              readonly applicationId: Option<ApplicationId>,
              readonly name: string,
              readonly incoming: boolean,
              readonly outgoing: boolean) {}

  static copy(other: RunnableMailboxSummary): RunnableMailboxSummary {
    return new RunnableMailboxSummary(
      MailboxId.copy(other.id),
      other.identifier,
      Option.copy(other.applicationId, ApplicationId.of),
      other.name,
      other.incoming,
      other.outgoing);
  }

  identifierOrId() {
    return this.identifier.length === 0 ? ("@" + this.id.id) : this.identifier;
  }
}
