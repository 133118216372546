import {Component, EventEmitter, Input, Output} from "@angular/core";
import {i18n} from "@utils";
import {FlowImportance, FlowImportanceList} from "../FlowModel";

interface ImportanceOption {
  name: string;
  value: FlowImportance;
}

@Component({
  selector: "my-flow-importance-selector",
  templateUrl: "./flow-importance-selector.component.html"
})
export class FlowImportanceSelectorComponent {
  opened: boolean = false;
  importanceName: string = "";
  @Input() showLabel = false;
  @Input() disabled: boolean = false;
  @Input() selected: FlowImportance = FlowImportance.normal;

  static options: Array<ImportanceOption>|undefined;

  options!: Array<ImportanceOption>;

  private _importance: FlowImportance = FlowImportance.normal;

  @Input() set importance(importance: FlowImportance) {
    this._importance = importance;
    this.importanceName = i18n("task_importance_"+this._importance);
  };
  @Output() importanceChange = new EventEmitter<FlowImportance>();


  constructor() {
  }

  get importance(): FlowImportance {
    return this._importance;
  }

  toggleSelector() {
    if(!this.opened && !this.disabled) {

      if(!FlowImportanceSelectorComponent.options) {
        FlowImportanceSelectorComponent.options = FlowImportanceList.selectable.map(s => {
          return {name: i18n("task_importance_"+s), value: s};
        });
      }
      this.options = FlowImportanceSelectorComponent.options;

      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  select(option: ImportanceOption) {
    this._importance = option.value;
    this.importanceChange.emit(option.value);
    this.opened = false;
  }
}
