import {AggregateId, AggregateVersion, displayExceptions, i18n, toastr, Typed} from "@utils";
import {
  AggregateConcurrentModificationError,
  asCommandHandlingError,
  asConcurrentModificationError,
  asCustomSuccess,
  asEventHandlingError,
  asFailure,
  asSuccess,
  CommandHandlingError,
  CommandResponse,
  EventHandlingError,
  isCommandHandlingError,
  isConcurrentModificationError,
  isCustomSuccess,
  isEventHandlingError,
  isFailure,
  isSuccess
} from "./StandardResponses";

class CommandResponseHandler {
  private data: Typed<CommandResponse>;

  private onSuccessHandler = (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {};

  private onFailureHandler = displayExceptions;

  private onConcurrentModificationErrorHandler = (error: AggregateConcurrentModificationError) => {
    toastr.error(concurrentModificationMessage(error));
    console.error(`Error: trying to modify incorrect entity (${error.aggregateType.typeName}, ${error.aggregateId.id}), expected version (${error.expected.asInt}) but now it was version (${error.was.asInt}).`)
  };
  private onCommandHandlingErrorHandler = (error: CommandHandlingError) => {
    toastr.error(`500: Error handling command.`);
    console.error(`Error handling command (${error.commandName}, ${error.commandId.asLong})`)
  };
  private onEventHandlingErrorHandler = (error: EventHandlingError) => {
    toastr.error(`500: Error handling event.`);
    console.error(`Error handling event (${error.eventName}, ${error.commandId.asLong})`)
  };

  private onElseHandler = () => {};
  private onAnyErrorHandler = () => {};

  constructor(data: Typed<CommandResponse>) {
    this.data = data;
  }

  handle(): void {
    if (isSuccess(this.data)) {
      const success = asSuccess(this.data);
      this.onSuccessHandler(AggregateId.copy(success.aggregateId), AggregateVersion.copy(success.aggregateVersion));
    } else {
      this.onAnyErrorHandler();
      if (isFailure(this.data)) {
        this.onFailureHandler(asFailure(this.data).exceptions);
      } else if (isConcurrentModificationError(this.data)) {
        this.onConcurrentModificationErrorHandler(asConcurrentModificationError(this.data));
      } else if (isCommandHandlingError(this.data)) {
        this.onCommandHandlingErrorHandler(asCommandHandlingError(this.data));
      } else if (isEventHandlingError(this.data)) {
        this.onEventHandlingErrorHandler(asEventHandlingError(this.data));
      } else {
        this.onElseHandler();
        console.error("Unknown response type", this.data);
        throw new Error("Unknown response type");
      }
    }
  }

  onSuccess(onSuccessHandler: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void) {
    this.onSuccessHandler = onSuccessHandler;
    return this;
  }

  onFailure(onFailureHandler: (exceptions: Array<string>) => void) {
    this.onFailureHandler = onFailureHandler;
    return this;
  }

  onConcurrentModificationError(onConcurrentModificationErrorHandler: (error: AggregateConcurrentModificationError) => void) {
    this.onConcurrentModificationErrorHandler = onConcurrentModificationErrorHandler;
    return this;
  }

  onCommandHandlingError(onCommandHandlingErrorHandler: (error: CommandHandlingError) => void) {
    this.onCommandHandlingErrorHandler = onCommandHandlingErrorHandler;
    return this;
  }

  onEventHandlingError(onEventHandlingErrorHandler: (error: EventHandlingError) => void) {
    this.onEventHandlingErrorHandler = onEventHandlingErrorHandler;
    return this;
  }

  onElse(onElseHandler: () => void) {
    this.onElseHandler = onElseHandler;
    return this;
  }

  onAnyError(errorHandler: () => void) {
    this.onAnyErrorHandler = errorHandler;
    return this;
  }
}

function concurrentModificationMessage(error: AggregateConcurrentModificationError) {
  switch (error.aggregateType.typeName) {
    case "pl.platform.domain.processrelease.model.GridProcessModel": return i18n("errors_concurrent_process_release");
    case "pl.platform.domain.persons.Person": return i18n("errors_concurrent_person");
    default: return i18n("errors_concurrent_default");
  }
}


class CustomCommandResponseHandler<I> {
  private data: Typed<CommandResponse>;

  private onSuccessHandler = (aggregateId: AggregateId, aggregateVersion: AggregateVersion, info: I) => {};

  private onFailureHandler = displayExceptions;
  private onAnyErrorHandler = () => {};

  private onConcurrentModificationErrorHandler = (error: AggregateConcurrentModificationError) => {
    toastr.error(concurrentModificationMessage(error));
    console.error(`Error because trying to modify incorrect entity (${error.aggregateType.typeName}, ${error.aggregateId.id}), expected version (${error.expected.asInt}) but now it was version (${error.was.asInt}).`)
  };
  private onCommandHandlingErrorHandler = (error: CommandHandlingError) => {
    toastr.error(`500: Error handling command.`);
    console.error(`Error handling command (${error.commandName}, ${error.commandId.asLong})`)
  };
  private onEventHandlingErrorHandler = (error: EventHandlingError) => {
    toastr.error(`500: Error handling event.`);
    console.error(`Error handling event (${error.eventName}, ${error.commandId.asLong})`)
  };

  constructor(data: Typed<CommandResponse>) {
    this.data = data;
  }

  handle(): void {
    if (isCustomSuccess(this.data)) {
      const success = asCustomSuccess<I>(this.data);
      this.onSuccessHandler(AggregateId.copy(success.aggregateId), AggregateVersion.copy(success.aggregateVersion), success.info);
    } else {
      this.onAnyErrorHandler();

      if (isFailure(this.data)) {
        this.onFailureHandler(asFailure(this.data).exceptions);
      } else if (isConcurrentModificationError(this.data)) {
        this.onConcurrentModificationErrorHandler(asConcurrentModificationError(this.data));
      } else if (isCommandHandlingError(this.data)) {
        this.onCommandHandlingErrorHandler(asCommandHandlingError(this.data));
      } else if (isEventHandlingError(this.data)) {
        this.onEventHandlingErrorHandler(asEventHandlingError(this.data));
      } else {
        console.error("Unknown response type", this.data);
        throw new Error("Unknown response type");
      }
    }
  }

  onSuccess(onSuccessHandler: (aggregateId: AggregateId, aggregateVersion: AggregateVersion, info: I) => void) {
    this.onSuccessHandler = onSuccessHandler;
    return this;
  }


  onFailure(onFailureHandler: (exceptions: Array<string>) => void) {
    this.onFailureHandler = onFailureHandler;
    return this;
  }

  onConcurrentModificationError(onConcurrentModificationErrorHandler: (error: AggregateConcurrentModificationError) => void) {
    this.onConcurrentModificationErrorHandler = onConcurrentModificationErrorHandler;
    return this;
  }

  onCommandHandlingError(onCommandHandlingErrorHandler: (error: CommandHandlingError) => void) {
    this.onCommandHandlingErrorHandler = onCommandHandlingErrorHandler;
    return this;
  }

  onEventHandlingError(onEventHandlingErrorHandler: (error: EventHandlingError) => void) {
    this.onEventHandlingErrorHandler = onEventHandlingErrorHandler;
    return this;
  }

  onAnyError(errorHandler: () => void) {
    this.onAnyErrorHandler = errorHandler;
    return this;
  }
}


export function commandResponseHandler(data: Typed<CommandResponse>): CommandResponseHandler {
  return new CommandResponseHandler(data)
}

export function customCommandResponseHandler<I>(data: Typed<CommandResponse>): CustomCommandResponseHandler<I> {
  return new CustomCommandResponseHandler<I>(data)
}
