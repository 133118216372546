import {AggregateId, OrganizationNodeId} from "@utils";
import {BasicOrganizationNodeInfo} from "../organization-structure/OrganizationNodesModel";

export class CustomAuthorization {
  constructor(readonly name: string) {}

  isEqual(other: CustomAuthorization) {
    return this.name == other.name;
  }
}

export class CustomFeatureAccess {
  static TasksCreation = new CustomAuthorization("TasksCreation");
  static ProcessesAccess = new CustomAuthorization("ProcessesAccess");
  static CasesCalendarsAccess = new CustomAuthorization("CasesCalendarsAccess");
  static ReportsAccess = new CustomAuthorization("ReportsAccess");
  static DocumentsRepositoryAccess = new CustomAuthorization("DocumentsRepositoryAccess"); // this means read
  static FlowSearchAccess = new CustomAuthorization("FlowSearchAccess");
  static ManagerDashboardAccess = new CustomAuthorization("ManagerDashboardAccess");
  static SupervisorDashboardAccess = new CustomAuthorization("SupervisorDashboardAccess");
  static BenchmarkAccess = new CustomAuthorization("BenchmarkAccess");
  static OrganizationAccess = new CustomAuthorization("OrganizationAccess");
  static FeedbackAccess = new CustomAuthorization("FeedbackAccess");
  static GlobalComponentsAccess = new CustomAuthorization("GlobalComponentsAccess")
}

export class CustomAuthorizations {

  // Organization
  static OrganizationSettingsEdition = new CustomAuthorization("OrganizationSettingsEdition");
  static CategoriesEdition = new CustomAuthorization("CategoriesEdition");
  static OrganizationStructureEdition = new CustomAuthorization("OrganizationStructureEdition");
  static OrganizationAuthorizationEdition = new CustomAuthorization("OrganizationAuthorizationEdition");

  // Process
  static ProcessDesignCreation = new CustomAuthorization("ProcessDesignCreation");
  static EveryProcessPreview = new CustomAuthorization("EveryProcessPreview");
  static EveryProcessOwnershipEdition = new CustomAuthorization("EveryProcessOwnershipEdition");

  // Instance
  static InstanceCreation = new CustomAuthorization("InstanceCreation");
  static EveryInstancePreview = new CustomAuthorization("EveryInstancePreview");
  static EveryInstanceOwnershipEdition = new CustomAuthorization("EveryInstanceOwnershipEdition");

  // Flow
  static EveryFlowPreview = new CustomAuthorization("EveryFlowPreview");
  static EveryFlowOwnershipEdition = new CustomAuthorization("EveryFlowOwnershipEdition");

  // Cases calendar
  static CasesCalendarCreation = new CustomAuthorization("CasesCalendarCreation");
  static EveryCasesCalendarUse = new CustomAuthorization("EveryCasesCalendarUse");
  static EveryCasesCalendarOwnershipEdition = new CustomAuthorization("EveryCasesCalendarOwnershipEdition");

  // Report
  static ReportCreation = new CustomAuthorization("ReportCreation");
  static EveryReportUse = new CustomAuthorization("EveryReportUse"); // actually EveryReportView
  static EveryReportOwnershipEdition = new CustomAuthorization("EveryReportOwnershipEdition");

  // Constant
  static ConstantCreation = new CustomAuthorization("ConstantCreation");
  static EveryConstantView = new CustomAuthorization("EveryConstantView");
  static EveryConstantOwnershipEdition = new CustomAuthorization("EveryConstantOwnershipEdition");

  // Datastore
  static DatastoreCreation = new CustomAuthorization("DatastoreCreation");
  static EveryDatastoreView = new CustomAuthorization("EveryDatastoreView");
  static EveryDatastoreOwnershipEdition = new CustomAuthorization("EveryDatastoreOwnershipEdition");

  // Document repository
  static DocumentRepositoryWrite = new CustomAuthorization("DocumentRepositoryWrite");
  static DocumentRepositoryAdministration = new CustomAuthorization("DocumentRepositoryAdministration");

  // Kanban
  static KanbanCreation = new CustomAuthorization("KanbanCreation");
  static EveryKanbanUse = new CustomAuthorization("EveryKanbanUse"); // actually EveryKanbanView
  static EveryKanbanOwnershipEdition = new CustomAuthorization("EveryKanbanOwnershipEdition");

  // Application
  static ProcessApplicationCreation = new CustomAuthorization("ProcessApplicationCreation");
  static EveryProcessScreen = new CustomAuthorization("EveryProcessScreen");
  static EveryProcessApplicationOwnershipEdition = new CustomAuthorization("EveryProcessApplicationOwnershipEdition");

  // GlobalComponents
  static GlobalComponentCreation = new CustomAuthorization("GlobalComponentCreation");
  static EveryGlobalComponentView = new CustomAuthorization("EveryGlobalComponentView");
  static EveryGlobalComponentOwnershipEdition = new CustomAuthorization("EveryGlobalComponentOwnershipEdition");

  // Ocr
  static OcrApiUse = new CustomAuthorization("OcrApiUse");

  // Remote organization
  static RemoteOrganizationCreation = new CustomAuthorization("RemoteOrganizationCreation")
  static EveryRemoteOrganizationView = new CustomAuthorization("EveryRemoteOrganizationView")
  static EveryRemoteOrganizationOwnershipEdition = new CustomAuthorization("EveryRemoteOrganizationOwnershipEdition")
}

export class OrganizationAuthorization {
  constructor(readonly organizationId: AggregateId,
              readonly authorization: Array<[CustomAuthorization, Array<OrganizationNodeId>]>) {}

  static copy(other: OrganizationAuthorization) {
    return new OrganizationAuthorization(other.organizationId, other.authorization.map((authorization: [CustomAuthorization, Array<OrganizationNodeId>]) => {
      const result: [CustomAuthorization, Array<OrganizationNodeId>] = [authorization[0], authorization[1].slice().map(node => OrganizationNodeId.copy(node))];
      return result;
    }));
  }

}
