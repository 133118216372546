import {
  __,
  FileUri,
  i18n,
  I18nText,
  mySetTimeout,
  None,
  NoneSingleton,
  Option,
  ScreenInstanceId,
  Some,
  toastr,
  VariableId
} from "@utils";
import {BusinessEntityVariable, BusinessVariable, CaseVariable, EmailVariable, FileVariableV2, ScreenExternalEventBus} from "@shared-model";
import {ViewableFile, ViewableFileUrl} from "@shared";
import {AttachmentEntry, BusinessEntityInfoViewModel, ComponentViewModelUtils, ComponentViewModelWithLabel, DirectoryInfoViewModel, FileInfoViewModel, MultiAttachmentInputComponentRefState, MultiAttachmentInputComponentState, ScreenContainerViewModel, ScreenInstanceServerModel, ScreenSharedViewModel, ScreenWrapperViewModel, UnknownInfoViewModel} from "../..";
import {CssBuilder, MultiAttachmentDisplayMode, MultiAttachmentInputComponentDefinition, MultiAttachmentInputComponentRef} from "@screen-common";

export class MultiAttachmentInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "MultiAttachmentInput";

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false;
  public invalid: boolean = false; //TODO

  public addRemoveEnabled: boolean = false;


  public allowedExtensions: Array<string> = [];
  public allowedExtensionsDefined: boolean = false;
  readonly instanceId: ScreenInstanceId;

  public entriesDefined: boolean = false;
  public entries: Array<AttachmentEntry> = [];

  public uploadAllowed: boolean = true;
  public anchorVisible: boolean = true;

  public detailed: boolean = false;
  public minimal: boolean = false;
  public combinedCss = "";
  public combinedCssClasses: string = "";
  attachFromRepository: boolean = false;

  private uploadsCount = 0;
  private nothingChangedRecently = true;

  private afterUploadUpdateTimeout: number = -1;
  private updateSkipped: boolean = false;

  constructor(override readonly shared: ScreenSharedViewModel,
              readonly externalEventBus: ScreenExternalEventBus,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: MultiAttachmentInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: MultiAttachmentInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: MultiAttachmentInputComponentState,
              readonly refState: MultiAttachmentInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.instanceId = this.serverModel.getInstanceId();
    this.update();
  }

  private updateEntriesDefined() {
    this.entriesDefined = this.entries.length > 0;
  }

  onDelete(entry: AttachmentEntry) {
    const entryIndex = this.entries.indexOf(entry);
    this.serverModel.removeFromModelWithAction(this.componentRefPath(), MultiAttachmentInputComponentDefinition.MODEL, entryIndex, entry.value!, MultiAttachmentInputComponentDefinition.ON_CHANGE);
    this.entries.splice(entryIndex, 1);
    this.updateEntriesDefined();
    this.nothingChangedRecently = false;
    this.scheduleAfterUploadUpdate();
  }

  onCancel(entry: FileInfoViewModel) {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.updateEntriesDefined();
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).getOrElse(I18nText.ofCurrent(i18n("Wybierz"))).getCurrentWithFallback();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.addRemoveEnabled = this.ref.addRemoveEnabled.currentValue(() => this.refState.addRemoveEnabled).valueOrDefault(false);

    const modelAllowedExtensions = this.definition.allowedExtensions.currentValue(() => this.componentState.allowedExtensions).valueOrDefault(None());
    this.allowedExtensions = modelAllowedExtensions.getOrElse([]);
    this.allowedExtensionsDefined = modelAllowedExtensions.isDefined();

    const displayMode = MultiAttachmentDisplayMode.of(this.definition.displayMode.currentValue(() => this.componentState.displayMode).valueOrDefault(MultiAttachmentDisplayMode.full.name));

    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(true);

    this.detailed = displayMode == MultiAttachmentDisplayMode.full;
    this.minimal = displayMode == MultiAttachmentDisplayMode.minimal;


    const entriesOption = this.componentState.model.valueOrDefault(None());

    if(this.nothingChangedRecently) {

      if (entriesOption.isDefined()) {
        const newEntries = entriesOption.get().unwrappedValue();

        let oldEntries = this.entries;
        this.entries = [];

        const entriesToKeep: Array<AttachmentEntry> = [];

        const entriesToAdd: Array<BusinessVariable> = [];

        newEntries.forEach(e => {
          const found = __(oldEntries).findIndexOf(ee => ee.value !== null && ee.value.isEqual(e));
          if (found.isDefined()) {
            entriesToKeep.push(oldEntries[found.get()]);
            oldEntries.splice(found.get(), 1);
          } else {
            entriesToAdd.push(e);
          }
        });

        const addedEntries: Array<AttachmentEntry> = entriesToAdd.map(entry => {
          if (entry instanceof FileVariableV2 && entry.value.isAnyFile()) {
            return FileInfoViewModel.placeholder(entry);
          } else if (entry instanceof FileVariableV2 && entry.value.isAnyDir()) {
            return DirectoryInfoViewModel.placeholder(entry);
          } else if (entry instanceof FileVariableV2) {
            throw new Error("Unsupported file type '" + entry.value.serialize() + "'");
          } else if (entry instanceof BusinessEntityVariable) {
            return BusinessEntityInfoViewModel.placeholder(entry);
          } else {
            return UnknownInfoViewModel.placeholder(entry);
          }
        });

        this.entries = entriesToKeep.concat(addedEntries);

        const files: Array<FileVariableV2> = entriesToAdd.filter(e => e instanceof FileVariableV2);
        const emails: Array<EmailVariable> = entriesToAdd.filter(e => e instanceof EmailVariable);
        const cases: Array<CaseVariable> = entriesToAdd.filter(e => e instanceof CaseVariable);

        this.serverModel.loadFilesInfo(this.componentRefPath(), files.map(f => f.value), (filesInfo) => {
          const __filesInfo = __(filesInfo);
          this.entries.forEach(entry => {
            if (entry instanceof FileInfoViewModel) {
              __filesInfo.find(e => e.uri.isEqual(entry.uri)).forEach(info => {
                entry.updateInfo(info);
              });
            }
          });
        });

        // this.serverModel.loadEmailsInfo(0, emails.map(e => e.value), (requestNumber, emailsInfo) => {
        //   const __emailsInfo = __(emailsInfo);
        //   this.entries.forEach(entry => {
        //     if(entry instanceof EmailInfoViewModel) {
        //       __emailsInfo.find(e => e.uri.isEqual(entry.uri)).forEach(info => {
        //         entry.updateInfo(info);
        //       });
        //     }
        //   });
        // });

        // this.serverModel.loadFlowsInfo(0, cases.map(e => e.value), (requestNumber: number, flowsInfo: Array<Option<FlowAndTasksInfoForUser>> ) => {
        //   const __flowsInfo = __(flowsInfo);
        //   this.entries.forEach(entry => {
        //     if(entry instanceof FlowInfoViewModel) {
        //       __flowsInfo.find(info => info.isDefined() && info.get().flowIdUnwrapped().id == entry.id.id).forEach(info => {
        //         entry.updateInfo(info.get());
        //       });
        //     }
        //   });
        // });


        // Business entities
        this.entries.forEach(entry => {
          if (entry instanceof BusinessEntityInfoViewModel) {
            this.serverModel.loadBusinessEntityInfo(entry.id, (entityInfo) => {
              entry.updateInfo(entityInfo)
            });
          }
        });

      } else {
        this.entries = [];
      }
    } else {
      this.updateSkipped = true;
    }


    this.updateEntriesDefined();

    super.updatePosition();

    this.combinedCss = cssBuilder.toCss() +this.sizeCss;
    this.combinedCssClasses = cssBuilder.toCssClasses();
  }


  fileUploadCompleted(uploadId: number, fileUri: FileUri) {
    const downloadUrl = this.serverModel.getFileDownloadUri(this.componentRefPath(), fileUri);
    this.entries.filter(f => f instanceof FileInfoViewModel && f.uploadId == uploadId).forEach((file) => {
      (<FileInfoViewModel>file).completeFileUpload(fileUri, downloadUrl);
    });
    this.updateEntriesDefined();
    this.decreaseUpload();
  }

  fileUploadProgress(uploadId: number, uploadedBytes: number, totalBytes: number) {
    this.entries.filter(f => f instanceof FileInfoViewModel && f.uploadId == uploadId).forEach(file => {
      (<FileInfoViewModel>file).updateFileUpload(uploadedBytes, totalBytes);
    });
    this.updateEntriesDefined();
  }

  extensionValid(fileName: string) {
    return true;
  }

  fileUploadError(uploadId: number) {
    this.decreaseUpload();
    const errorFile: FileInfoViewModel|undefined = <FileInfoViewModel|undefined>this.entries.find(f => f instanceof FileInfoViewModel && f.uploadId == uploadId);
    if(errorFile) {
      this.entries.splice(this.entries.indexOf(errorFile), 1);
      toastr.error("File upload error '" + errorFile.name+"'");
    }
  }

  newFileUploadStarted(uploadId: number, fileName: any) {
    this.entries.push(FileInfoViewModel.newUpload(fileName, uploadId));
    this.uploadsCount++;
    this.updateEntriesDefined();
  }

  private increaseUpload() {
    this.uploadsCount++;
    this.nothingChangedRecently = false;
    this.clearAfterUploadUpdateTimeout();
  }

  private scheduleAfterUploadUpdate() {
    this.clearAfterUploadUpdateTimeout();
    this.afterUploadUpdateTimeout = mySetTimeout(() => {
      this.nothingChangedRecently = true;
      if(this.updateSkipped) {
        this.updateComponent(false);
      }
    }, 3000); // wait until all data is available after last upload completed
  }

  private clearAfterUploadUpdateTimeout() {
    if(this.afterUploadUpdateTimeout !== -1) {
      clearTimeout(this.afterUploadUpdateTimeout);
      this.afterUploadUpdateTimeout = -1;
    }
  }

  private decreaseUpload() {
    this.uploadsCount--;
    if(this.uploadsCount === 0) {
      this.scheduleAfterUploadUpdate();
    }
  }

  toggleFileViewerVisibility(entry: FileInfoViewModel) {
    const files: Array<FileInfoViewModel> = this.entries
      .filter(f => f instanceof FileInfoViewModel).map(f => <FileInfoViewModel>f);
    const viewableFiles = files
      .map((file: FileInfoViewModel) => new ViewableFile(file.name, file.size, file.version > 1 ? Some(file.version) : None(), new ViewableFileUrl(file.downloadUrl), false, file.modified, file.uri, false, None(), None(), None(), None(), !file.exists));
    this.externalEventBus.filesPreviewRequested(viewableFiles, files.indexOf(entry));
  };

}
