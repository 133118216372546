import {AuthenticatedHttp} from "@shared-model";
import {AggregateId, AggregateVersion, AggregateWrapper, LocalDateTime, toastr, Typed} from "@utils";
import {PersonNotification} from "./notifications.model";
import {
  AllPersonNotificationMarkedAsReadFailure,
  AllPersonNotificationMarkedAsReadSuccess,
  FindNotificationsOlderThan,
  FindUnreadNotificationsOlderThan,
  MarkAllNotificationsAsReadForPersonSagaResponse,
  MarkAllNotificationsAsReadForPersonSagaResponseFactory,
  MarkAllPersonNotificationsAsRead,
  MarkPersonNotificationRead,
  NotificationNumberAndSearchResult,
  NotificationSearchResult
} from "./notification.service-messages";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  getNotifications(onSuccess: (notifications: Array<PersonNotification>, moreAvailable: boolean, received: number, unread: number) => void) {
    this.authenticatedHttp.get("notifications/get-by-person-id",
      (data: NotificationNumberAndSearchResult) => {
        const copied = data.notifications.map(aw => new AggregateWrapper(aw.id, aw.version, PersonNotification.copy(aw.id, aw.version, aw.aggregate)));
        onSuccess(copied.map(aw => <PersonNotification>aw.aggregate), data.moreAvailable, data.received, data.unread);
      }
    )
  }

  getUnreadNotifications(onSuccess: (notifications: Array<PersonNotification>, moreAvailable: boolean, received: number, unread: number) => void) {
    this.authenticatedHttp.get("notifications/get-by-person-id-unread",
      (data: NotificationNumberAndSearchResult) => {
        const copied = data.notifications.map(aw => new AggregateWrapper(aw.id, aw.version, PersonNotification.copy(aw.id, aw.version, aw.aggregate)));
        onSuccess(copied.map(aw => <PersonNotification>aw.aggregate), data.moreAvailable, data.received, data.unread);
      }
    )
  }


  getNotificationsOlderThan(olderThan: LocalDateTime, onSuccess: (notifications: Array<PersonNotification>, moreAvailable: boolean) => void) {
    this.authenticatedHttp.post("notifications/get-by-person-id-older-than", new FindNotificationsOlderThan(olderThan),
      (data: NotificationSearchResult) => {
        const copied = data.notifications.map(aw => new AggregateWrapper(aw.id, aw.version, PersonNotification.copy(aw.id, aw.version, aw.aggregate)));
        onSuccess(copied.map(aw => <PersonNotification>aw.aggregate), data.moreAvailable);
      }
    )
  }

  getUnreadNotificationsOlderThan(olderThan: LocalDateTime, onSuccess: (notifications: Array<PersonNotification>, moreAvailable: boolean) => void) {
    this.authenticatedHttp.post("notifications/get-by-person-id-unread-older-than", new FindUnreadNotificationsOlderThan(olderThan),
      (data: NotificationSearchResult) => {
        const copied = data.notifications.map(aw => new AggregateWrapper(aw.id, aw.version, PersonNotification.copy(aw.id, aw.version, aw.aggregate)));
        onSuccess(copied.map(aw => <PersonNotification>aw.aggregate), data.moreAvailable);
      }
    )
  }

  markNotificationRead(notificationId: AggregateId, version: AggregateVersion): void {
    this.authenticatedHttp.post("notifications/mark-notification-read", new MarkPersonNotificationRead(notificationId, version),
      (data: PersonNotification) => {
      }
    )
  }

  markAllNotificationsAsRead(): void {
    this.authenticatedHttp.post("saga/mark-all-person-notification-as-read", new MarkAllPersonNotificationsAsRead(),
      (data: Typed<MarkAllNotificationsAsReadForPersonSagaResponse>) => {
        const responseUnwrapped = Typed.value(MarkAllNotificationsAsReadForPersonSagaResponseFactory.copyTyped(data));
        switch (responseUnwrapped.className()) {
          case AllPersonNotificationMarkedAsReadSuccess.className :
            break;
          case AllPersonNotificationMarkedAsReadFailure.className :
            toastr.error("Problem with notifications");
            console.error((<AllPersonNotificationMarkedAsReadFailure>responseUnwrapped).exceptions);
            break;
        }
      }
    )
  }
}
