import {ExpressionWithAst} from "@shared-model";
import {None, Option} from "@utils";

export class NodeAssignmentMethod {
  constructor(readonly name: string) {
  }

  static copy(other: NodeAssignmentMethod) {
    return new NodeAssignmentMethod(other.name);
  }

  isRole() {
    return this.name === NodeAssignmentMethod.role.name;
  }

  isExpression() {
    return this.name === NodeAssignmentMethod.expression.name;
  }

  isVariable() {
    return this.name === NodeAssignmentMethod.variable.name;
  }

  static role = new NodeAssignmentMethod("Role");
  static expression = new NodeAssignmentMethod("Expression");
  static variable = new NodeAssignmentMethod("Variable");
}

export class NodeAssignment {

  constructor(readonly method: NodeAssignmentMethod,
              readonly expressionWithAst: Option<ExpressionWithAst>,
              readonly field: Option<string>) {}

  static copy(other: NodeAssignment) {
    return new NodeAssignment(NodeAssignmentMethod.copy(other.method),
      Option.copy(other.expressionWithAst).map(ExpressionWithAst.copy),
      Option.copy(other.field)
    );
  }

  static role = new NodeAssignment(NodeAssignmentMethod.role, None(), None());
}
