export class Action {
  constructor(readonly name: string) {}
}

export namespace Roles {

  export class Entity {
    name: string;

    constructor(name: string) {
      this.name = name;
    }

    static equal(a: Entity, b: Entity) {
      return a.name === b.name;
    }
  }

  export class Role {
    entity: Entity;
    name: string;

    constructor(entity: Roles.Entity, name: string) {
      this.entity = entity;
      this.name = name;
    }

    static equal(a: Role, b: Role) {
      return a.name === b.name && Entity.equal(a.entity, b.entity);
    }

    static toString(role: Role) {
      return role.entity.name+":"+role.name;
    }
  }

}
