<div class="commentAttachmentsList">
  <div *ngFor="let attachment of attachments; let index = index" class="appendableListEntry attachment{{index}}">

    <ng-container *ngIf="readOnly">
      <a
        *ngIf="attachment.loaded"
        class="downloadLink"
        href="{{attachment.url.url}}"
        onclick="return false;"
        (click)="toggleFileViewerVisibility(index)">

        <div class="theme-attach-icon-small"><i class="mi-file"></i></div>
        <span class="fileNameAndExtension">
              <span class="fileName">{{attachment.nameWithoutExtension}}</span>
              <span *ngIf="attachment.extension">.{{attachment.extension}}</span>
            </span>
        <span class="fileSize">{{attachment.size | myFileSizeFormat}}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="!readOnly">
      <a
        *ngIf="attachment.loaded"
        class="downloadLink"
        href="{{attachment.url.url}}"
        onclick="return false;"
        (click)="toggleFileViewerVisibility(index)">

        <div class="theme-attach-icon-small"><i class="mi-file"></i></div>
        <span class="fileNameAndExtension">
              <span class="fileName">{{attachment.name}}</span>
            </span>
      </a>
      <i *ngIf="!attachment.loaded" class="mi-refresh"></i>
      <button *ngIf="attachment.loaded" class="deleteButton theme-button-icon-action-flat-small"
              (click)="removeEntry(attachment)">
        <i class="mi-delete"></i>
      </button>
    </ng-container>

  </div>
</div>


