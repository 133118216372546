import {Injectable} from "@angular/core";
import {CategoriesInfoDocument, ExportProcessesStructureByCategoryId, ProcessSettings} from "./types";
import {
  asSuccess,
  AuthenticatedHttp,
  CommandResponse,
  commandResponseHandler,
  customCommandResponseHandler,
  CustomSuccessResponse,
  isSuccess,
  nonSuccessMessages,
  TokenResponse
} from "@shared-model";
import {map, shareReplay} from "rxjs/operators";
import {from, Observable} from "rxjs";
import {LocalSettingsService} from "../session/local-settings.service";
import {AggregateId, AggregateVersion, DocumentationPageId, Some, Typed} from "@utils";
import {AddCategory, AddCategoryResponse, ChangeCategoryName, MoveCategory, RemoveCategory} from "./categories.model";
import {RecoverPasswordByAdminUIResponse, RecoverPasswordUI} from "../user-register/user-register.model";

@Injectable({
  providedIn: "root"
})
export class CategoriesService {

  private categories$ = from(this.authenticatedHttp.getPromise<CategoriesInfoDocument>("categories/get"))
    .pipe(
      map(CategoriesInfoDocument.copy),
      shareReplay({bufferSize: 1, refCount: true}),
    );

  constructor(private authenticatedHttp: AuthenticatedHttp,
              readonly localSettingsService: LocalSettingsService) {}

  loadCategories(): Observable<CategoriesInfoDocument> {
    return this.categories$;
  }

  downloadCategoriesStructure(categoryId: number, processesSettings: ProcessSettings, onSuccess: (file: any) => void) {
    return this.authenticatedHttp.postWithCustomResponse("categories-projection/export-processes-structure",
      new ExportProcessesStructureByCategoryId(categoryId, this.localSettingsService.getLanguage(), processesSettings),
      "text",
      (file: any) => {
        onSuccess(file);
      });
  }

  addCategory(aggregateId: AggregateId, expectedVersion: AggregateVersion, categoryParentId: number, categoryName: string,
                 onSuccess: (id: AggregateId, aggregateVersion: AggregateVersion, newCategoryId: number) => void,
                 onFailure: (exceptions: Array<string>) => void) {

    const command = new AddCategory(aggregateId, expectedVersion, categoryParentId, categoryName);
    this.authenticatedHttp.post("categories/add", command,

      (data: Typed<CustomSuccessResponse<AddCategoryResponse>>) => {
        customCommandResponseHandler<AddCategoryResponse>(data)
          .onSuccess((id: AggregateId, version: AggregateVersion, info: AddCategoryResponse) => {
            onSuccess(id, version, AddCategoryResponse.copy(info).categoryId.getOrError("Category id is not present"));
          })
          .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
    });
  }

  moveCategory(aggregateId: AggregateId, expectedVersion: AggregateVersion, categoryId: number, newParentId: number,
                     onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void) {

    const command = new MoveCategory(aggregateId, expectedVersion, categoryId, newParentId);
    this.authenticatedHttp.post("categories/move", command,
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((id: AggregateId, version: AggregateVersion) => {
          onSuccess(id, version);
        }).handle();
      });
  }

  changeCategoryName(aggregateId: AggregateId, expectedVersion: AggregateVersion, categoryId: number, newName: string,
                     onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void) {

    const command = new ChangeCategoryName(aggregateId, expectedVersion, categoryId, newName);
    this.authenticatedHttp.post("categories/change", command,
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((id: AggregateId, version: AggregateVersion) => {
          onSuccess(id, version);
        }).handle();
      });
  }

  removeCategory(aggregateId: AggregateId, expectedVersion: AggregateVersion, categoryId: number,
                   onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void) {
    const command = new RemoveCategory(aggregateId, expectedVersion, categoryId);
    this.authenticatedHttp.post("categories/remove", command,
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).handle();
      }
    );
  }

}
