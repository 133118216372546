import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

export enum MainMenuPage {
  none,
  dashboard,
  tasksAndFlows,
  applications,
  designer,
  documents,
  settings,
  admin,
  help
}

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  private _page: MainMenuPage = MainMenuPage.none;
  get page(): MainMenuPage {return this._page;}

  private _application: string|undefined = undefined;
  get application(): string | undefined {return this._application;}

  private pageSubject = new Subject<MainMenuPage>();
  private applicationSubject = new Subject<string|undefined>();

  private notificationsPanelVisible: boolean = false;
  private notificationsPanelVisibleSubject = new Subject<boolean>();

  setPage(page: MainMenuPage, force: boolean = false) {

    if(!force && page == MainMenuPage.applications) {
      if(this._page != MainMenuPage.applications) {
        // it will be set by page, but for now disable old page if it's not applications
        this._page = MainMenuPage.none;
      }
    } else {
      this._page = page;
    }

    this.pageSubject.next(this._page);

  }

  getPageObservable() {
    return this.pageSubject.asObservable();
  }


  getApplicationObservable() {
    return this.applicationSubject.asObservable();
  }

  setApplication(applicationIdentifier: string) {
    this.applicationSubject.next(applicationIdentifier);
  }

  getNotificationsPanelVisibleObservable() {
    return this.notificationsPanelVisibleSubject.asObservable();
  }

  toggleNotificationsPanel() {
    this.notificationsPanelVisible = !this.notificationsPanelVisible;
    this.notificationsPanelVisibleSubject.next(this.notificationsPanelVisible);
  }

  hideNotificationsPanel() {
    this.notificationsPanelVisible = false;
    this.notificationsPanelVisibleSubject.next(this.notificationsPanelVisible);
  }

  getNotificationsPanelVisible() {
    return this.notificationsPanelVisible;
  }
}
