import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalI18nTextProperty,
  OptionalOrganizationNodesProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";

export class PersonSelectComponentRef extends InputComponentRef {
  static className = "PersonSelectComponentRef";

  className() {
    return PersonSelectComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: PersonSelectComponentRef): PersonSelectComponentRef {
    return new PersonSelectComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("PersonSelectComponentRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = PersonSelectComponentRef.DEFAULT_PROPERTIES;
}

export class PersonSelectComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "PersonSelectComponentDefinition";

  className() {
    return PersonSelectComponentDefinition.className;
  }

  typeName() {
    return "PersonSelect";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules, PersonSelectComponentDefinition.DEFAULT_PROPERTIES);
  }

  get organizationNodes(): OptionalOrganizationNodesProperty {
    return this.properties.getOptionalOrganizationNodesProperty("organizationNodes", this.defaultPropertiesProvider)
  }

  override get tooltip(): OptionalI18nTextProperty {
    return this.properties.getOptionalI18nTextProperty("tooltip", this.defaultPropertiesProvider)
  }

  get placeholder(): OptionalI18nTextProperty {
    return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)
  }

  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  get persons(): BooleanProperty {
    return this.properties.getBooleanProperty("persons", this.defaultPropertiesProvider)
  }

  get groups(): BooleanProperty {
    return this.properties.getBooleanProperty("groups", this.defaultPropertiesProvider)
  }

  get departments(): BooleanProperty {
    return this.properties.getBooleanProperty("departments", this.defaultPropertiesProvider)
  }

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: PersonSelectComponentDefinition): PersonSelectComponentDefinition {
    return new PersonSelectComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }


  getModelNames(): Array<string> {
    return [PersonSelectComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("PersonSelectComponentDefinition",
    (name: string) => {
      switch (name) {
        case "persons":
          return BooleanProperty.ofTrue();
        case "groups":
          return BooleanProperty.ofFalse();
        case "departments":
          return BooleanProperty.ofFalse();
        case "organizationNodes":
          return OptionalOrganizationNodesProperty.disabled();
        case "placeholder":
          return OptionalI18nTextProperty.disabled();
        case "innerShadow":
          return OptionalBooleanProperty.disabled(true);
        default:
          return null;
      }
    },
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);
}
