import {None, Option, Some} from "./data-types/Option";
import {__} from "./__";

const variableNameRegexp = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ][0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ_$]*$/;
const staticVariablePathRegexp = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ][0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ_$]*(\[[0-9]+])*$/;
const staticVariablePathAllow$Regexp = /^[$a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ][0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ_$]*(\[[0-9]+])*$/;

export function variableNameFromText(text: string): Option<string> {
  return variableNameFromTextKeepCase(text.toLocaleLowerCase());
}

export function variableNameFromTextKeepCase(text: string, trim: boolean = true): Option<string> {
  const trimmed = trim ? text.trim() : text;
  const variableName = trimmed.replace(/[\s]+/g, "_").replace(/^[_$]/g, "").replace(/[^0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ_$]/g, "").replace(/_+/g, "_");

  if (validateVariableName(variableName)) {
    return Some(variableName);
  } else {
    return None();
  }
}

export function fixVariablePath(text: string, trim: boolean = true): string {
  const trimmed = trim ? text.trim() : text;
  return trimmed.replace(/[^0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ_$\[\]\\.]/g, "");
}

export function validateVariableName(variableName: string): boolean {
  return variableNameRegexp.test(variableName);
}

export function validateVariablePath(path: string): boolean {
  const splitted = path.split(".");
  return __(splitted).all(entry => staticVariablePathRegexp.test(entry));
}

export function validateVariablePathAllowDollarSign(path: string): boolean {
  const splitted = path.split(".");
  return __(splitted).all(entry => staticVariablePathAllow$Regexp.test(entry));
}

