import {Component, ElementRef, Input, OnDestroy, ViewChild, ViewContainerRef} from "@angular/core";
import {mySetTimeout} from "@utils";
import {SelectComponentOption} from "./select-common";
import {DropListComponentViewModel} from "./DropListComponentViewModel";

@Component({
  selector: 'my-drop-list-component',
  templateUrl: './drop-list-component.component.html',
  host: {
    "[class]": "'componentBody DropListComponent ' + viewModel.customCssClass",
    "[class.preview]": "viewModel.preview",
    "[class.validationError]": "viewModel.validationError",
    "[style]": "viewModel.outerCss",
  }
})
export class DropListComponentComponent implements OnDestroy {
  @Input({required:true}) viewModel!: DropListComponentViewModel;

  @ViewChild("DropListComponent") container: ElementRef<HTMLElement>|undefined;

  enabled = true;
  open = false;
  focused = false;

  private keyboardListeningEnabled = false;

  readonly inputElement: HTMLInputElement;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
    this.inputElement = viewContainerRef.element.nativeElement;
  }

  private isEnabled(){
    return !this.viewModel.disabled;
  }

  onClick = () => {
    if(this.isEnabled()) {
      if(this.open) {
        this.closePicker();
      } else {
        this.openPicker();
      }
    }
  }

  openPicker = () => {
    this.open = true;
  }

  onDropMenuOpened(popup: HTMLElement) {
    mySetTimeout(() => {
      const dropListFilter = <HTMLInputElement>popup.querySelector(".dropListFilter");
      if(dropListFilter !== null) { // it's optional
        dropListFilter.select();
      }
    });
  }

  private closePicker = () => {
    this.open = false;
  }

  optionSelected = (option: SelectComponentOption) => {
    this.closePicker();
    this.viewModel.optionSelected(option);
  }


  onFocus = () => {

    if(!this.keyboardListeningEnabled) {
      this.enableKeyEvents();
    }

    if(!this.focused && this.isEnabled()){
      this.focused = true;
    }
  }

   onBlur = () => {
     if(this.focused){
       this.focused = false;
     }
     if(this.keyboardListeningEnabled) {
       this.disableKeyEvents();
     }
   }

  onKeyEvent = (event: KeyboardEvent) => {
    if (this.isEnabled() && this.focused) {
      if (this.open) {
        switch (event.key) {
          case 'ArrowUp' : {
            this.viewModel.activePrevious();
            event.preventDefault();
            break;
          }
          case 'ArrowDown' : {
            this.viewModel.activeNext();
            event.preventDefault();
            break;
          }
          case 'Enter' : {
            this.viewModel.selectActive();
            mySetTimeout(this.closePicker);
            event.preventDefault();
            break;
          }
          case 'Escape' : {
            this.closePicker();
            event.preventDefault();
            break;
          }
        }
      } else {
        switch(event.key){
          case 'Enter' : {
            this.onClick();
            event.preventDefault();
            break;
          }
          case 'ArrowUp' : {
            this.viewModel.activePrevious();
            this.viewModel.selectActive();
            this.viewModel.updateActiveIndexToSelected();
            event.preventDefault();
            break;
          }
          case 'ArrowDown' : {
            this.viewModel.activeNext();
            this.viewModel.selectActive();
            this.viewModel.updateActiveIndexToSelected();
            event.preventDefault();
            break;
          }
        }
      }
    }
  }

  private enableKeyEvents() {
    this.keyboardListeningEnabled = true;
    window.addEventListener('keydown', this.onKeyEvent);
  }

  private disableKeyEvents() {
    this.keyboardListeningEnabled = false;
    window.removeEventListener('keydown', this.onKeyEvent);
  }

  ngOnDestroy(): void {
    if(this.keyboardListeningEnabled) {
      this.disableKeyEvents();
    }
  }

  onDropMenuClosed() {
    this.viewModel.clearFilter();
  }
}
