import {__, ___, htmlEscape, toastr} from "@utils";

import {
  ArrayExpression as ESTree_ArrayExpression,
  ArrowFunctionExpression as ESTree_ArrowFunctionExpression,
  AssignmentExpression as ESTree_AssignmentExpression,
  BinaryExpression as ESTree_BinaryExpression,
  BlockStatement as ESTree_BlockStatement,
  CallExpression as ESTree_CallExpression,
  CatchClause as ESTree_CatchClause,
  ConditionalExpression as ESTree_ConditionalExpression,
  DoWhileStatement as ESTree_DoWhileStatement,
  ExpressionStatement as ESTree_ExpressionStatement,
  ForStatement as ESTree_ForStatement,
  Identifier as ESTree_Identifier,
  IfStatement as ESTree_IfStatement,
  Literal as ESTree_Literal,
  LogicalExpression as ESTree_LogicalExpression,
  MemberExpression as ESTree_MemberExpression,
  Node as ESTree_Node,
  ObjectExpression as ESTree_ObjectExpression,
  Program as ESTree_Program,
  ReturnStatement as ESTree_ReturnStatement,
  SequenceExpression as ESTree_SequenceExpression,
  SwitchCase as ESTree_SwitchCase,
  SwitchStatement as ESTree_SwitchStatement,
  TaggedTemplateExpression as ESTree_TaggedTemplateExpression,
  TemplateLiteral as ESTree_TemplateLiteral,
  ThrowStatement as ESTree_ThrowStatement,
  TryStatement as ESTree_TryStatement,
  UnaryExpression as ESTree_UnaryExpression,
  UpdateExpression as ESTree_UpdateExpression,
  VariableDeclaration as ESTree_VariableDeclaration,
  VariableDeclarator as ESTree_VariableDeclarator,
  WhileStatement as ESTree_WhileStatement
} from "estree/index";

export class EstreeVariableUsageAnalyzer {

  static findInNode(estree: ESTree_Node): Array<string> {
    switch (estree.type) {
      case "ExpressionStatement": return EstreeVariableUsageAnalyzer.findVariableInExpressionStatement(<ESTree_ExpressionStatement> estree);
      case "MemberExpression": return EstreeVariableUsageAnalyzer.findInMemberExpression(<ESTree_MemberExpression>estree);
      case "BinaryExpression": return EstreeVariableUsageAnalyzer.findInBinaryExpression(<ESTree_BinaryExpression>estree);
      case "LogicalExpression": return EstreeVariableUsageAnalyzer.findInLogicalExpression(<ESTree_LogicalExpression>estree);
      case "Literal": return EstreeVariableUsageAnalyzer.findInLiteral(<ESTree_Literal>estree);
      case "UnaryExpression": return EstreeVariableUsageAnalyzer.findInUnaryExpression(<ESTree_UnaryExpression>estree);
      case "CallExpression": return EstreeVariableUsageAnalyzer.findInCallExpression(<ESTree_CallExpression>estree);
      case "Identifier": return EstreeVariableUsageAnalyzer.findInIdentifier(<ESTree_Identifier>estree);
      case "ObjectExpression": return EstreeVariableUsageAnalyzer.findInObjectExpression(<ESTree_ObjectExpression>estree);
      case "ArrayExpression": return EstreeVariableUsageAnalyzer.findInArrayExpression(<ESTree_ArrayExpression>estree);
      case "ConditionalExpression": return EstreeVariableUsageAnalyzer.findInConditionalExpression(<ESTree_ConditionalExpression>estree);
      case "ArrowFunctionExpression": return EstreeVariableUsageAnalyzer.findInArrowFunctionExpression(<ESTree_ArrowFunctionExpression>estree);
      case "Program": return EstreeVariableUsageAnalyzer.findInProgram(<ESTree_Program>estree);
      case "EmptyStatement": return [];
      case "ReturnStatement": return EstreeVariableUsageAnalyzer.findInReturnStatement(<ESTree_ReturnStatement>estree);
      case "IfStatement": return EstreeVariableUsageAnalyzer.findInIfStatement(<ESTree_IfStatement>estree);
      case "SwitchStatement": return EstreeVariableUsageAnalyzer.findInSwitchStatement(<ESTree_SwitchStatement>estree);
      case "SwitchCase": return EstreeVariableUsageAnalyzer.findInSwitchCase(<ESTree_SwitchCase>estree);
      case "WhileStatement": return EstreeVariableUsageAnalyzer.findInWhileStatement(<ESTree_WhileStatement>estree);
      case "DoWhileStatement": return EstreeVariableUsageAnalyzer.findInDoWhileStatement(<ESTree_DoWhileStatement>estree);
      case "ForStatement": return EstreeVariableUsageAnalyzer.findInForStatement(<ESTree_ForStatement>estree);
      case "BlockStatement": return EstreeVariableUsageAnalyzer.findInBlockStatement(<ESTree_BlockStatement>estree);
      case "VariableDeclaration": return EstreeVariableUsageAnalyzer.findInVariableDeclaration(<ESTree_VariableDeclaration>estree);
      case "VariableDeclarator": return EstreeVariableUsageAnalyzer.findInVariableDeclarator(<ESTree_VariableDeclarator>estree);
      case "ThrowStatement": return EstreeVariableUsageAnalyzer.findInThrowStatement(<ESTree_ThrowStatement>estree);
      case "TryStatement": return EstreeVariableUsageAnalyzer.findInTryStatement(<ESTree_TryStatement>estree);
      case "CatchClause": return EstreeVariableUsageAnalyzer.findInCatchClause(<ESTree_CatchClause>estree);
      case "FunctionDeclaration": return [];
      case "UpdateExpression": return EstreeVariableUsageAnalyzer.findInUpdateExpression(<ESTree_UpdateExpression>estree);
      case "AssignmentExpression": return EstreeVariableUsageAnalyzer.findInAssignmentExpression(<ESTree_AssignmentExpression>estree);
      case "SequenceExpression": return EstreeVariableUsageAnalyzer.findInSequenceExpression(<ESTree_SequenceExpression>estree);
      case "TemplateLiteral": return EstreeVariableUsageAnalyzer.findInTemplateLiteral(<ESTree_TemplateLiteral>estree);
      case "TaggedTemplateExpression": return EstreeVariableUsageAnalyzer.findInTaggedTemplateExpression(<ESTree_TaggedTemplateExpression>estree);

      default:

        switch (<any>estree.type) { // those types seems to be missing in the estree typings
          case "Directive": return [];
          case "NullLiteral": return [];
          case "StringLiteral": return [];
          case "BooleanLiteral": return [];
          case "NumberLiteral": return [];
          case "RegexLiteral": return [];
          default:
            toastr.error("Unsupported expression type: [" + htmlEscape(estree.type) + "]");
            throw Error("Unsupported expression type: [" + estree.type + "]");

        }

    }
  }

  static findInProgram(estree:ESTree_Program):Array<string> {
    return ___(estree.body.flatMap(EstreeVariableUsageAnalyzer.findInNode)).unique().value();
  }

  static findVariableInExpressionStatement(estree:ESTree_ExpressionStatement):Array<string> {
    return __(EstreeVariableUsageAnalyzer.findInNode(estree.expression)).unique();
  }

  static findVariableInNode(estree:ESTree_Node):Array<string> {
    return __(EstreeVariableUsageAnalyzer.findInNode(estree)).unique();
  }

  private static findInArrayExpression(estree:ESTree_ArrayExpression):Array<string> {
    return __(estree.elements).flatMap(e => {
      if (e?.type === "SpreadElement") {
        throw Error("SpreadElement not supported");
      } else if(e === null) {
        return [];
      } else {
        return EstreeVariableUsageAnalyzer.findInNode(e);
      }
    });
  }

  private static findInObjectExpression(estree:ESTree_ObjectExpression):Array<string> {
    return __(estree.properties).flatMap(p => {
      if (p.type === "SpreadElement") {
        throw Error("SpreadElement not supported");
      } else {
        return EstreeVariableUsageAnalyzer.findInNode(p.value);
      }
    });
  }

  private static findInMemberExpression(estree:ESTree_MemberExpression):Array<string> {
    if (estree.computed) {
      return EstreeVariableUsageAnalyzer.findInNode(estree.object).concat(EstreeVariableUsageAnalyzer.findInNode(estree.property));
    } else {
      return EstreeVariableUsageAnalyzer.findInNode(estree.object).concat(EstreeVariableUsageAnalyzer.findInIdentifier(<ESTree_Identifier>estree.property));
    }
  }

  private static findInBinaryExpression(estree:ESTree_BinaryExpression):Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.left).concat(EstreeVariableUsageAnalyzer.findInNode(estree.right));
  }

  private static findInLogicalExpression(estree:ESTree_LogicalExpression):Array<string>{
    return EstreeVariableUsageAnalyzer.findInNode(estree.left).concat(EstreeVariableUsageAnalyzer.findInNode(estree.right));
  }

  private static findInLiteral(estree:ESTree_Literal):Array<string> {
    return [];
  }

  private static findInUnaryExpression(estree:ESTree_UnaryExpression):Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.argument);
  }

  private static findInCallExpression(estree:ESTree_CallExpression):Array<string> {
    if(estree.callee.type === "Identifier" && (<ESTree_Identifier>estree.callee).name === "$") {
      if(estree.arguments.length === 1) {
        if(estree.arguments[0].type === "Literal" && estree.arguments[0]) {
          const value = (<ESTree_Literal>estree.arguments[0]).value;
          if(typeof value === "string") {
            return [<string>(<ESTree_Literal>estree.arguments[0]).value];
          } else {
            throw new Error("$ call takes exactly 1 argument of type string, not of type " + (typeof value));
          }

        } else {
          return EstreeVariableUsageAnalyzer.findInNode(estree.callee);
        }
      } else {
        throw new Error("$ call takes exactly 1 argument");
      }
    } else {
      return __(estree.arguments).flatMap(a => EstreeVariableUsageAnalyzer.findInNode(a)).concat(EstreeVariableUsageAnalyzer.findInNode(estree.callee));
    }
  }

  private static findInIdentifier(estree:ESTree_Identifier):Array<string> {
    return [estree.name];
  }

  private static findInConditionalExpression(estree: ESTree_ConditionalExpression):Array<string> {
    //TODO fix
    return [];
  }

  private static findInArrowFunctionExpression(estree: ESTree_ArrowFunctionExpression):Array<string> {
    //TODO fix
    return [];
  }




  private static findInReturnStatement(estree: ESTree_ReturnStatement): Array<string> {
    if(estree.argument) {
      return EstreeVariableUsageAnalyzer.findInNode(estree.argument);
    } else {
      return [];
    }
  }

  private static findInIfStatement(estree: ESTree_IfStatement): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.test)
      .concat(EstreeVariableUsageAnalyzer.findInNode(estree.consequent))
      .concat(estree.alternate ? EstreeVariableUsageAnalyzer.findInNode(estree.alternate) : []);
  }

  private static findInSwitchStatement(estree: ESTree_SwitchStatement): Array<string> {
    const discriminant = EstreeVariableUsageAnalyzer.findInNode(estree.discriminant);
    const cases = __(estree.cases).flatMap(c => EstreeVariableUsageAnalyzer.findInNode(c));
    return discriminant.concat(cases);
  }

  private static findInSwitchCase(estree: ESTree_SwitchCase): Array<string> {
    const test = estree.test ? EstreeVariableUsageAnalyzer.findInNode(estree.test) : [];
    const consequences = __(estree.consequent).flatMap(c => EstreeVariableUsageAnalyzer.findInNode(c));
    return test.concat(consequences);
  }

  private static findInWhileStatement(estree: ESTree_WhileStatement): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.test)
      .concat(EstreeVariableUsageAnalyzer.findInNode(estree.body));
  }

  private static findInDoWhileStatement(estree: ESTree_DoWhileStatement): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.test)
      .concat(EstreeVariableUsageAnalyzer.findInNode(estree.body));
  }

  private static findInForStatement(estree: ESTree_ForStatement): Array<string> {

    const init = estree.init ? EstreeVariableUsageAnalyzer.findInNode(estree.init) : [];
    const test = estree.test ? EstreeVariableUsageAnalyzer.findInNode(estree.test) : [];
    const update = estree.update ? EstreeVariableUsageAnalyzer.findInNode(estree.update) : [];
    const body = estree.body ? EstreeVariableUsageAnalyzer.findInNode(estree.body) : [];

    return init
      .concat(test)
      .concat(update)
      .concat(body);
  }

  private static findInBlockStatement(estree: ESTree_BlockStatement): Array<string> {
    return __(estree.body).flatMap(b => EstreeVariableUsageAnalyzer.findInNode(b));
  }

  private static findInVariableDeclaration(estree: ESTree_VariableDeclaration): Array<string> {
    return __(estree.declarations).flatMap(d => EstreeVariableUsageAnalyzer.findInNode(d));
  }

  private static findInVariableDeclarator(estree: ESTree_VariableDeclarator): Array<string> {
    if(estree.init) {
      return EstreeVariableUsageAnalyzer.findInNode(estree.init);
    } else {
      return [];
    }
  }

  private static findInThrowStatement(estree: ESTree_ThrowStatement): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.argument);
  }

  private static findInTryStatement(estree: ESTree_TryStatement): Array<string> {

    const block = EstreeVariableUsageAnalyzer.findInNode(estree.block);
    const handler = estree.handler ? EstreeVariableUsageAnalyzer.findInNode(estree.handler) : [];
    const finalizer = estree.finalizer ? EstreeVariableUsageAnalyzer.findInNode(estree.finalizer) : [];

    return block
      .concat(handler)
      .concat(finalizer);
  }

  private static findInCatchClause(estree: ESTree_CatchClause): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.body);
  }

  private static findInUpdateExpression(estree: ESTree_UpdateExpression): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.argument);
  }

  // TODO when assigning process variable will be possible in expression this should be adjusted
  private static findInAssignmentExpression(estree: ESTree_AssignmentExpression): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.right);
  }

  private static findInSequenceExpression(estree: ESTree_SequenceExpression): Array<string> {
    return __(estree.expressions).flatMap(e => EstreeVariableUsageAnalyzer.findInNode(e));
  }

  private static findInTemplateLiteral(estree: ESTree_TemplateLiteral): Array<string> {
    return __(estree.expressions).flatMap(e => EstreeVariableUsageAnalyzer.findInNode(e));
  }

  private static findInTaggedTemplateExpression(estree: ESTree_TaggedTemplateExpression): Array<string> {
    return EstreeVariableUsageAnalyzer.findInNode(estree.tag).concat(EstreeVariableUsageAnalyzer.findInTemplateLiteral(estree.quasi));
  }
}
