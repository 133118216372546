export function values<T>(object: {[key: string]: T}): Array<T> {
  return Object.keys(object).map(key => object[key]);
}

export function objectsEqual(objA: {[name: string]: any}, objB: {[name: string]: any}): boolean {
  return objectsEqualBy(objA, objB);
}

export function objectsEqualBy(objA: {[name: string]: any}, objB: {[name: string]: any}, comparator: (a: any, b: any) => boolean = (a, b) => a === b): boolean {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!comparator(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
}
