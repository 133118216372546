import {I18nText, Option, Trilean} from "@utils";
import {TaskDistribution} from "./TaskDistribution";
import {TasksVisibility} from "./ProcessModel";
import {NodeAssignment} from "./NodeAssignment";

export class GridProcessActor {

  constructor(readonly id: number,
              readonly identifier: Option<string>,
              readonly name: I18nText,
              readonly description: I18nText,
              readonly gridY: number,
              readonly rowsCount: number,
              readonly nodeAssignment: NodeAssignment,
              readonly taskDistribution: TaskDistribution,
              readonly incompatibleRoles: Array<number>,
              readonly tasksVisibility: TasksVisibility,
              readonly roleMembersCanChangeTaskAssignment: Trilean,
              readonly roleMembersCanChangeTaskAssignmentExpression: string,
              readonly assigneeLimit: number,
              readonly canChangeCaseImportance: Trilean,
              readonly canChangeCaseImportanceExpression: string,
              readonly canChangeCaseUrgency: Trilean,
              readonly canChangeCaseUrgencyExpression: string,
              readonly canChangeCaseLabels: Trilean,
              readonly canChangeCaseLabelsExpression: string,
              readonly commentsAccess: boolean) {}

  /** For proper object initialization, e.g. after JSON deserialization */
  static copy(other: GridProcessActor) {
    return new GridProcessActor(
      other.id,
      Option.copy(other.identifier),
      I18nText.copy(other.name),
      I18nText.copy(other.description),
      other.gridY,
      other.rowsCount,
      NodeAssignment.copy(other.nodeAssignment),
      TaskDistribution.copy(other.taskDistribution),
      other.incompatibleRoles.slice(),
      TasksVisibility.copy(other.tasksVisibility),
      Trilean.copy(other.roleMembersCanChangeTaskAssignment),
      other.roleMembersCanChangeTaskAssignmentExpression,
      other.assigneeLimit,
      Trilean.copy(other.canChangeCaseImportance),
      other.canChangeCaseImportanceExpression,
      Trilean.copy(other.canChangeCaseUrgency),
      other.canChangeCaseUrgencyExpression,
      Trilean.copy(other.canChangeCaseLabels),
      other.canChangeCaseLabelsExpression,
      other.commentsAccess
    );
  }
}
