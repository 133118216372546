import {GridXY} from "@utils";

export class PathWrappingRectangle {
  constructor(readonly minX: number,
              readonly minY: number,
              readonly maxX: number,
              readonly maxY: number) {}

  static within(rectangle: PathWrappingRectangle, point: GridXY) {
    return rectangle.minX <= point.gridX && rectangle.maxX >= point.gridX &&
           rectangle.minY <= point.gridY && rectangle.maxY >= point.gridY;
  }
}

export class GridXYPath {

  constructor(readonly points: ReadonlyArray<GridXY>) {}

  static copy(path: GridXYPath) {
    return new GridXYPath(path.points.map((point: GridXY) => GridXY.copy(point)));
  }

  equals(path: GridXYPath): boolean {
    if(path.points.length !== this.points.length) {
      return false;
    } else {
      for(let i=0; i<this.points.length; i++) {
        const thisPoint = this.points[i];
        const otherPoint = path.points[i];
        if(thisPoint.nonEqual(otherPoint)) {
          return false;
        }
      }
      return true;
    }
  }


  static gridCellsForPath(path: GridXYPath): GridXY[] {
    return path.points
      .map(p => new GridXY(Math.floor((p.gridX - 1) / 6), Math.floor((p.gridY - 1) / 6)))
  }

  static pathWrappingRectangle(path: GridXYPath, gridPadding: number = 0): PathWrappingRectangle {
    let minX = 1000000;
    let minY = 1000000;
    let maxX = -1;
    let maxY = -1;

    const points = path.points;
    const pointsLength = points.length;
    for (let p = 0; p < pointsLength; p++) {
      const x = points[p].gridX;
      const y = points[p].gridY;
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
    }
    return new PathWrappingRectangle(GridXYPath.toGridCoordinate(minX) - gridPadding,
      GridXYPath.toGridCoordinate(minY) - gridPadding,
      GridXYPath.toGridCoordinate(maxX) + gridPadding,
      GridXYPath.toGridCoordinate(maxY) + gridPadding);
  }

  static toGridCoordinate(c: number) {
    return Math.floor((c - 1) / 6);
  }

}


export class GridXYPathEndings {
  constructor(readonly from: GridXY,
              readonly to: GridXY) {}
}
