import {LabelComponentState} from "../label/LabelComponentState";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState
} from "../..";
import {Option} from "@utils";

export class ImageComponentState extends ScreenComponentState {
  static className = "ImageComponentState";
  className() {
    return LabelComponentState.className;
  }

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get fileSource() {
    return this.properties.optionalFileProperty("fileSource");
  }

  static copy(other: ImageComponentState) {
    return new ImageComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class ImageComponentRefState extends ScreenComponentRefState {
  static className = "ImageComponentRefState";
  className() {
    return LabelComponentState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: ImageComponentRefState) {
    return new ImageComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}


