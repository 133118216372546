import {PositionXY} from "./data-types/PositionXY";
import {WrapperConfig, wrapSingleStringText} from "./TextWrapper";

export class CanvasColors {
  static title = "#16a184";
  static background = "#ffffff";
  static gridLines = "#e5e9f0";
  static text = "#8095B7";
  static startNode = "#1ABC9C";
  static actionNode = "#04A7D4";
  static automaticNode = "#7374cf";
  static conditionNode = "#0ECFA8";
  static externalNode = "#9baaaa";
  static finishNode = "#0E3E69";
  static edge = "#76859b";
  static edgeLabel = "#374b5b";
  static subProcessNode = "#78c56f";
  static delayNode = "#8879b4";
}
export class CanvasBrush {

  private static TWO_PI = 2 * Math.PI;
  private static HALF_PI = 2 * Math.PI;
  private static THREE_SECOND_PI = 2 * Math.PI;

  constructor(readonly ctx: CanvasRenderingContext2D) {}

  drawCircle(centerX: number, centerY: number, radius: number, fillColor: string|null, strokeColor: string|null): void {
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, CanvasBrush.TWO_PI, false);
    if(fillColor) {
      ctx.fillStyle = fillColor;
      ctx.fill();
    }
    if(strokeColor) {
      ctx.strokeStyle = strokeColor;
      ctx.stroke();
    }
  }

  drawRoundedRhombus(x: number, y: number, width: number, height: number, fillColor: string): void {
    const ctx = this.ctx;
    ctx.setLineDash([]);
    ctx.lineJoin = "round";
    const lineWidth = 6;
    ctx.lineWidth = lineWidth;
    ctx.fillStyle = fillColor;
    ctx.strokeStyle = fillColor;

    ctx.beginPath();
    ctx.moveTo(x + lineWidth/2,y + height / 2);
    ctx.lineTo(x + width / 2, y + lineWidth/2);
    ctx.lineTo(x + width - lineWidth/2,     y + height / 2);
    ctx.lineTo(x + width / 2, y + height - lineWidth/2);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  }

  drawPolyLine(points: Array<PositionXY>, width: number, color: string): void {
    const ctx = this.ctx;
    ctx.moveTo(points[0].x, points[0].y);
    for(let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.stroke();
  }

  drawRoundedPolyLine(points: Array<PositionXY>, radius: number, width: number, color: string): void {
    const ctx = this.ctx;
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.setLineDash([]);
    ctx.beginPath();

    let previousPoint: PositionXY|null = null;

    points.forEach((point, index) => {
      if(previousPoint == null) {
        previousPoint = point;
        ctx.moveTo(previousPoint.x, previousPoint.y);
      } else {
        const nextPoint: PositionXY|null = index < points.length - 1
          ? points[index + 1]
          : null;


        const directionFrom = CanvasBrush.getLineDirection(previousPoint, point);
        ctx.lineTo(point.x - directionFrom.x * radius, point.y - directionFrom.y * radius);


        if(nextPoint !== null) {
          const directionTo = CanvasBrush.getLineDirection(point, nextPoint);
          ctx.arcTo(point.x, point.y, point.x + directionTo.x * radius, point.y + directionTo.y * radius, radius);
        }

        previousPoint = point;

      }
    });

    ctx.stroke();
    ctx.closePath();

  }

  drawRoundedDashedLine(points: Array<PositionXY>, radius: number, width: number, color: string): void {
    const ctx = this.ctx;
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.setLineDash([3]);
    ctx.beginPath();


    let previousPoint: PositionXY|null = null;

    points.forEach((point, index) => {
      if(previousPoint == null) {
        previousPoint = point;
        ctx.moveTo(previousPoint.x, previousPoint.y);
      } else {
        const nextPoint: PositionXY|null = index < points.length - 1
          ? points[index + 1]
          : null;


        const directionFrom = CanvasBrush.getLineDirection(previousPoint, point);
        ctx.lineTo(point.x - directionFrom.x * radius, point.y - directionFrom.y * radius);


        if(nextPoint !== null) {
          const directionTo = CanvasBrush.getLineDirection(point, nextPoint);
          ctx.arcTo(point.x, point.y, point.x + directionTo.x * radius, point.y + directionTo.y * radius, radius);
        }

        previousPoint = point;

      }
    });

    ctx.stroke();
    ctx.closePath();

  }



  /**
   *
   * @returns degree in radians
   */
  private static getLineDirection(startPosition: PositionXY, endPosition: PositionXY): {x: number, y: number} {
    if(startPosition.x === endPosition.x) {
      if(endPosition.y < startPosition.y) {
        return {x: 0, y: -1};
      } else {
        return {x: 0, y: 1};
      }
    } else if (startPosition.y === endPosition.y) {
      if(endPosition.x < startPosition.x) {
        return {x: -1, y: 0};
      } else {
        return {x: 1, y: 0};
      }
    } else {
      throw new Error("No direction");
    }
  }

  drawRoundedRect(x: number, y: number, width: number, height: number, fillColor: string, round: number): void {
    const ctx = this.ctx;
    ctx.beginPath()
    ctx.roundRect(x , y, width, height, round);
    ctx.lineWidth = 0;
    ctx.fillStyle = fillColor;
    ctx.fill();
  }

  paintCenteredLabel(nodeLabel: string, screenNodePosition: PositionXY, config: WrapperConfig, fontColor: string, fontSize: number, fontFamily: string, scale: number = 1, fontStyle: string = ""): void {
    const ctx = this.ctx;
    ctx.fillStyle = fontColor;
    ctx.font = fontStyle + (fontSize * scale) + 'px ' + fontFamily;
    ctx.textAlign = "center";
    const lines = wrapSingleStringText(nodeLabel, config);
    lines.forEach(line => {
      ctx.fillText(line.text, screenNodePosition.x * scale, (screenNodePosition.y + line.y + line.dy) * scale)
    });

  }
}
