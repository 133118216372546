import {Component, Input} from "@angular/core";
import {SectionContainerViewModel} from "./SectionContainerViewModel";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-section-container',
  templateUrl: './section-container.component.html',
  host: {
    "[class]": "'SectionContainer componentBody ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.combinedCss",
    "[class.withHeader]": "viewModel.headerVisible",
    "[class.clickable]": "viewModel.clickable",
    "(click)": "viewModel.onClick()",
  }
})
export class SectionContainerComponent {
  @Input({required:true}) viewModel!: SectionContainerViewModel;

  readonly children = new ContainerChildrenHandler();

}
