<div class="hours">
  <div class="timePickerLabel">{{'common_hour' | i18n}}</div>
  <div class="hoursButtons">
    <button class="entry" tabindex="-1" *ngFor="let hour of hours" (click)="selectHour(hour)" [ngClass]="{selected: hour === hourSelected}">{{hour}}</button>
  </div>
</div>

<div class="minutes" *ngIf="!allMinutesMode">
  <div class="timePickerLabel"><button tabindex="-1" (click)="toggleMinutesMode()">{{'common_minute' | i18n}}</button></div>
  <div class="minutesButtons">
    <button class="entry" tabindex="-1" *ngFor="let minute of minutes" (click)="selectMinute(minute)" [class.selected]="minute === minuteSelected">{{minute}}</button>
  </div>
</div>

<div class="minutes allMinutesMode" *ngIf="allMinutesMode">
  <div class="timePickerLabel"><button tabindex="-1" (click)="toggleMinutesMode()">{{'common_minute' | i18n}}</button></div>
  <div class="minutesButtons">
    <button class="entry" tabindex="-1" *ngFor="let minute of allMinutes" (click)="selectMinute(minute)" [class.selected]="minute === minuteSelected">{{minute}}</button>
  </div>
</div>
