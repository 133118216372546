import {Pipe, PipeTransform} from "@angular/core";
import {prettySize} from "@utils";


@Pipe({name: 'myFileSizeFormat'})
export class FileSizeFormatPipe implements PipeTransform {

  constructor() {
  }

  transform(input: number): string {
    return prettySize(input);
  }

}
