import {None, Option} from "@utils";
import {ExpressionWithAst} from "@shared-model";

export class TaskDistributionAlgorithm {
  constructor(readonly name: string) {}

  static algorithm1 = new TaskDistributionAlgorithm("algorithm 1");
  static algorithm2 = new TaskDistributionAlgorithm("algorithm 2");
  static algorithm3 = new TaskDistributionAlgorithm("algorithm 3");
}

export class TaskDistributionMethod {
  constructor(readonly name: string) {}

  static selfAssign = new TaskDistributionMethod("SelfAssign");
  static manualByManager = new TaskDistributionMethod("ManualByManager");
  static manualBySupervisor = new TaskDistributionMethod("ManualBySupervisor");
  static expression = new TaskDistributionMethod("Expression");
  static field = new TaskDistributionMethod("Field");
  static automaticToSupervisor = new TaskDistributionMethod("AutomaticToSupervisor");
  static automatic = new TaskDistributionMethod("Automatic");

  isSelfAssign() {
    return this.name === TaskDistributionMethod.selfAssign.name
  }

  isManualByManager() {
    return this.name === TaskDistributionMethod.manualByManager.name;
  }

  isManualBySupervisor() {
    return this.name === TaskDistributionMethod.manualBySupervisor.name;
  }

  isExpression() {
    return this.name === TaskDistributionMethod.expression.name;
  }

  isField() {
    return this.name === TaskDistributionMethod.field.name;
  }

  isAutomaticToSupervisor() {
    return this.name === TaskDistributionMethod.automaticToSupervisor.name;
  }

  isAutomatic() {
    return this.name === TaskDistributionMethod.automatic.name;
  }
}

export class TaskDistribution {
  constructor(readonly method: TaskDistributionMethod,
              readonly expressionWithAst: Option<ExpressionWithAst>,
              readonly field: Option<string>,
              readonly supervisorOf: Option<number>,
              readonly automatic: Option<TaskDistributionAlgorithm>,
              readonly bidirectional: boolean) {}

  static copy(other: TaskDistribution) {
    return new TaskDistribution(other.method,
      Option.copy(other.expressionWithAst).map(ExpressionWithAst.copy),
      Option.copy(other.field),
      Option.copy(other.supervisorOf),
      Option.copy(other.automatic),
      other.bidirectional
    );
  }

  static selfAssign = new TaskDistribution(TaskDistributionMethod.selfAssign, None(), None(), None(), None(), false);
}
