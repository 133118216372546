import {Injectable} from "@angular/core";
import {LibrariesService} from "@shared";

declare const showdown: {Converter: any};

@Injectable({
  providedIn: 'root',
})
export class MarkdownService {

  constructor(readonly librariesService: LibrariesService) {

  }


  markdownToHtml(markdown: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.librariesService.loadShowdown(() => {
        resolve(this.markdownToHtmlSync(markdown));
      });
    });
  }

  private markdownToHtmlSync(markdown: string): string {
    const converter = new showdown.Converter();
    converter.setOption("tables", true);
    converter.setOption("tasklists", true);
    converter.setOption("strikethrough", true);
    converter.setOption("simpleLineBreaks", true);
    converter.setOption("underline", true);
    return converter.makeHtml(markdown);
  }
}
