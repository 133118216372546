<i class="theme-search-icon mi-search"></i>
<input
  [(ngModel)]="query"
  [focus-on-show]="focusOnShow"
  class="textQuery theme-search-query"
  type="text"
  placeholder="{{placeholder ? placeholder : 'common_search' | i18n}}"
  (keydown.enter)="applied.emit(query)"
  (focus)="searchFocused.emit()"
  (change)="search()" />
<button *ngIf="query.length > 0" class="clearQuery theme-search-option-icon-only" (click)="clear()">
  <i class="mi-delete"></i>
</button>
<ng-content></ng-content>
