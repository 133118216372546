<ng-container *ngIf="!finished && !invalidLink">

  <div class="titleContainer">
    <div class="title">{{'password-reset_set_new_password' | i18n}}</div>
  </div>

  <div class="error" *ngFor="let exception of passwordExceptions">{{exception}}</div>
  <div class="theme-input-box-with-color-icon-large">
    <i class="mi-lock"></i>
    <input type="password" placeholder="{{'password-reset_password' | i18n}}"
           required="required" [focus-on-show]="true"
           [(ngModel)]="form.newPassword" (blur)="validatePassword()" (change)="checkIfPasswordsMatchNow()">
  </div>

  <my-password-meter [password]="form.newPassword" [validatePasswordPolicy]="validatePasswordPolicy" />

  <div class="error" *ngFor="let exception of repeatPasswordExceptions">{{exception}}</div>
  <div class="theme-input-box-with-color-icon-large">
    <i class="mi-lock"></i>
    <input class="token" type="password" placeholder="{{'password-reset_repeat_password' | i18n}}" required="required"
           [(ngModel)]="form.newRepeatPassword" (blur)="validatePasswords()" (change)="checkIfPasswordsMatchNow()">
  </div>

  <button class="resetButton theme-button-confirm-with-icon" (click)="resetPassword()" [disabled]="!form.isValid"><i class="mi-check"></i>{{'password-reset_form_submit_button' | i18n}}</button>

</ng-container>

<my-login-form *ngIf="finished && !invalidLink && organizationUrl" [onSuccessfulLogin]="onSuccessfulLogin" />

<div *ngIf="invalidLink" class="statusContainer">
  <span class="statusInfo">Invalid link!</span>
</div>
