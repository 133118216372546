<span class="inputHeader" *ngIf="viewModel.anchorVisible"><i class="mi-clock"></i></span>

<input type="text"
       [style]="viewModel.innerCss"
       class="input dateInput timeInput {{viewModel.innerCssClasses}}"
       [placeholder]="viewModel.placeholder"
       [(ngModel)]="viewModel.textModel"
       (focus)="onFocused()"
       (change)="viewModel.textInputAccepted()"
       (keydown.enter)="requestBlur()"
       [disabled]="viewModel.disabled"/>

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [(visible)]="viewModel.focused" [anchor]="inputElement" (closed)="viewModel.textInputAccepted()"
              [closeOnBlur]="true" [asModalOnMobile]="true">
  <ng-container *ngIf="viewModel.focused">
    <div class="actions">
      <button class="actionButton" tabindex="-1" (click)="viewModel.clear()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      <div class="actionSpacer"></div>
      <button class="actionButton" tabindex="-1" (click)="viewModel.setNow()">{{'common_now' | i18n}}</button>
    </div>
    <my-time-picker-board [(time)]="viewModel.value" (timeChange)="viewModel.onChanged()" (picked)="viewModel.onPicked()" />
  </ng-container>
</my-drop-menu>
