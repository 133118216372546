import {htmlEscape} from "./Html";
import {getElementSize} from "./dom/Elements";
import {$$} from "./$$";


/**
 * Truncates text to match given height&width constraints with appended three dots (...) if necessary.
 * @returns truncated text and eight of that text.
 */
export function truncateText(container: HTMLElement, text: string, width: number, height: number): {text: string, height: number} {

  const $container = $$(container);
  let truncated = text.trim();

  const placeholder = $$(document.createElement("div"));
  placeholder
    .css({
      position: "absolute",
      visibility: "hidden",
      width: width+"px",
      maxHeight: "none",
      height: "auto"
      })
    .html(htmlEscape(truncated));
  $container.appendChild(placeholder);

  let truncatedHeight = getElementSize(placeholder.getAsElement()).height;

  if(truncatedHeight > height) {
    let words = text.split(/\s+/);
    do {
      words.pop();
      truncated = words.join(" ")+"...";
      placeholder.html(htmlEscape(truncated));
      truncatedHeight = getElementSize(placeholder.getAsElement()).height;
    } while (truncatedHeight > height && truncatedHeight > 0);
  }

  placeholder.remove();

  return {text: truncated, height: truncatedHeight};
}

export function escapeToSimpleText(name: string): string {
  return name.replace(/[^0-9a-zA-ZażźćńółęąśA-ZŻŹĆŃÓŁĘĄŚ_-]+/g, "_");
}
