import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BasicOrganizationNodeInfo, OrganizationSharedService} from "@shared-model";
import {AggregateId, ApplicationId, i18n, None, NoneSingleton, Option, OrganizationNodeId} from "@utils";
import {SessionServiceProvider} from "@shared";
import {OrganizationNodesSearchBoxViewModel} from "./organization-nodes-search-box.view-model";

@Component({
  selector: 'my-organization-nodes-search-box',
  templateUrl: './organization-nodes-search-box.component.html'
})
export class OrganizationNodesSearchBoxComponent implements OnInit {

  public viewModel!: OrganizationNodesSearchBoxViewModel;

  @Input() applicationId: ApplicationId|null = null;

  private _excludeNodes: Array<OrganizationNodeId> = [];
  @Input() set excludeNodes(excludeNodes: Array<OrganizationNodeId>) {
    this._excludeNodes = excludeNodes;
    if(this.viewModel !== undefined) {
      this.viewModel.setExcludeNodes(excludeNodes);
    }
  }

  @Input() noneEnabled: boolean = false;
  @Input() unselectEnabled: boolean = false;

  @Input() noneLabel: string = i18n("common_select");
  @Input() unselectLabel: string = i18n("common_select");

  @Input() newEntryPlaceholder: string|undefined = undefined;
  @Input() nodesInternal: Array<BasicOrganizationNodeInfo> = [];

  @Input() persons: boolean = false;
  @Input() includeDeletedNodes: boolean = false;
  @Input() departments: boolean = false;
  @Input() groups: boolean = false;
  @Input() services: boolean = false;
  @Input() processes: boolean = false;
  @Input() reports: boolean = false;
  @Input() applications: boolean = false;
  @Input() screens: boolean = false;
  @Input() functions: boolean = false;
  @Input() anonymous: boolean = false;
  @Input() alwaysShowResults: boolean = false;
  @Input() inAllApplications: boolean = false;
  @Input() fromGlobal: boolean = true;

  @Input() withinNodes: Option<Array<OrganizationNodeId>> = None();

  @Output() canceled = new EventEmitter<void>();
  @Output() nodeCleared = new EventEmitter<void>();
  @Output() nodeSelected = new EventEmitter<BasicOrganizationNodeInfo>();
  @Input() onCustomValue: ((value: string) => void)|null = null;

  @Input() currentUser: boolean = false;
  @Input() currentUserLabel: string|undefined = undefined;

  @Output() resultsChanged = new EventEmitter<void>()

  constructor(readonly organizationStructureQueryService: OrganizationSharedService,
              readonly sessionServiceProvider: SessionServiceProvider) {}

  ngOnInit() {
    this.viewModel = new OrganizationNodesSearchBoxViewModel(
      this.organizationStructureQueryService,
      this.sessionServiceProvider,
      Option.of(this.applicationId),
      this._excludeNodes,
      this.unselectEnabled,
      this.newEntryPlaceholder,
      this.nodesInternal,
      this.persons,
      this.includeDeletedNodes,
      this.departments,
      this.groups,
      this.services,
      this.processes,
      this.reports,
      this.applications,
      this.screens,
      this.functions,
      this.anonymous,
      this.currentUser,
      this.currentUserLabel,
      this.alwaysShowResults,
      this.inAllApplications,
      this.withinNodes,
      this.fromGlobal,
      this.nodeCleared,
      this.nodeSelected,
      this.canceled,
      this.onCustomValue,
      () => {
        this.resultsChanged.emit();
      }
      );

    this.viewModel.triggerSearch();
  }
}
