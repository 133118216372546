import {Either, Option, Typed} from "@utils";


export type UnaryOperator =  "-" | "+" | "!" | "~" | "typeof" | "void" | "delete";
export type BinaryOperator = "==" | "!=" | "===" | "!==" | "<" | "<=" | ">" | ">=" | "<<" |
  ">>" | ">>>" | "+" | "-" | "*" | "/" | "%" | "**" | "|" | "^" | "&" | "in" |
  "instanceof";
export type LogicalOperator = "||" | "&&" | "??";
export type UpdateOperator = "++" | "--";
export type AssignmentOperator = "=" | "+=" | "-=" | "*=" | "/=" | "%=" | "**=" | "<<=" | ">>=" | ">>>=" |
  "|=" | "^=" | "&=" | "||=" | "&&=" | "??=";

export const binaryOperators = ["+","-","/","%","*","<","<=",">",">=","==","!="];

export abstract class Node {
  abstract className():string;

  constructor(readonly loc:Option<SourceLocation>,
              readonly range:Option<[number, number]>) {
  }
}

export class SourceLocation {
  constructor(readonly source:Option<string>,
              readonly start:Position,
              readonly end:Position) {}

  static copy(other:SourceLocation) {
    return new SourceLocation(Option.copy(other.source), other.start, other.end);
  }
}

export class Position {
  constructor(readonly line: number,
              readonly column: number) {}
}


export class Program extends Node {
  className():string {
    return "Program";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly body:Array<Typed<Statement>>,
              readonly sourceType:"script" | "module") {
    super(loc, range);
  }
}

export abstract class Statement extends Node {

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>) {
    super(loc, range);
  }
}

export class EmptyStatement extends Statement {
  className() {
    return "EmptyStatement";
  }
  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>) {
    super(loc, range);
  }
}

export class ReturnStatement extends Statement {
  className() {
    return "ReturnStatement";
  }
  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly argument:Option<Typed<Expression>>) {
    super(loc, range);
  }
}


export class ExpressionStatement extends Statement {
  className() {
    return "ExpressionStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly expression:Typed<Expression>) {
    super(loc, range);
  }
}

export class IfStatement extends Statement {
  className() {
    return "IfStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly test:Typed<Expression>,
              readonly consequent: Typed<Statement>,
              readonly alternate: Option<Typed<Statement>>) {
    super(loc, range);
  }
}

export class SwitchStatement extends Statement {
  className() {
    return "SwitchStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly discriminant:Typed<Expression>,
              readonly cases: Array<SwitchCase>) {
    super(loc, range);
  }
}

export class SwitchCase extends Node {
  className() {
    return "SwitchCase";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly test:Option<Typed<Expression>>, // None means default
              readonly consequent: Array<Typed<Statement>>) {
    super(loc, range);
  }
}

export class WhileStatement extends Statement {
  className() {
    return "WhileStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly test:Typed<Expression>,
              readonly body: Typed<Statement>) {
    super(loc, range);
  }
}

export class DoWhileStatement extends Statement {
  className() {
    return "DoWhileStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly body: Typed<Statement>,
              readonly test:Typed<Expression>) {
    super(loc, range);
  }
}

export class ForStatement extends Statement {
  className() {
    return "ForStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
                readonly init: Option<Either<VariableDeclaration,Typed<Expression>>>,
              readonly test:Option<Typed<Expression>>,
              readonly update:Option<Typed<Expression>>,
              readonly body: Typed<Statement>) {
    super(loc, range);
  }
}

export class BlockStatement extends Statement {
  className() {
    return "BlockStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly body:Array<Typed<Statement>>) {
    super(loc, range);
  }
}

export class ThrowStatement extends Statement {
  className() {
    return "ThrowStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly argument:Typed<Expression>) {
    super(loc, range);
  }
}

export class TryStatement extends Statement {
  className() {
    return "TryStatement";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly block:BlockStatement,
              readonly handler: Option<CatchClause>,
              readonly finalizer: Option<BlockStatement>) {
    super(loc, range);
  }
}

export class CatchClause extends Node {
  className() {
    return "CatchClause";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly param: Typed<Pattern>,
              readonly body: BlockStatement) {
    super(loc, range);
  }
}


export abstract class Expression extends Node {

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>) {
    super(loc, range);
  }
}

export class MemberExpression extends Expression {
  className() {
    return "MemberExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly object:Typed<Expression>,
              readonly property:Typed<Expression>,
              readonly computed:boolean) {
    super(loc, range);
  }
}

export class BinaryExpression extends Expression {
  className() {
    return "BinaryExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly operator:BinaryOperator,
              readonly left:Typed<Expression>,
              readonly right:Typed<Expression>) {
    super(loc, range);
  }

}

export class LogicalExpression extends Expression {
  className() {
    return "LogicalExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly operator:LogicalOperator,
              readonly left:Typed<Expression>,
              readonly right:Typed<Expression>) {
    super(loc, range);
  }
}

export class UnaryExpression extends Expression {
  className() {
    return "UnaryExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly operator:UnaryOperator,
              readonly prefix:boolean,
              readonly argument:Typed<Expression>) {
    super(loc, range);
  }
}

export class CallExpression extends Expression {
  className() {
    return "CallExpression";
  }

  readonly arguments:Array<Typed<Expression>>;

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly callee:Typed<Expression>, args:Array<Typed<Expression>>) { // arguments is a default parameter name in JS
    super(loc, range);
    this.arguments = args;
  }
}

export abstract class Literal extends Expression {

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>) {
    super(loc, range);
  }
}

export class NullLiteral extends Literal {
  className() {
    return "NullLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>) {
    super(loc, range);
  }

}

export class StringLiteral extends Literal {
  className() {
    return "StringLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly value:string) {
    super(loc, range);
  }

}

export class BooleanLiteral extends Literal {
  className() {
    return "BooleanLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly value:boolean) {
    super(loc, range);
  }
}

export class NumberLiteral extends Literal {
  className() {
    return "NumberLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly value:number) {
    super(loc, range);
  }
}

export class RegexLiteral extends Literal {
  className() {
    return "RegexLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly pattern: string,
              readonly flags: string) {
    super(loc, range);
  }
}

export class Identifier extends Expression implements Pattern {
  className() {
    return "Identifier";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly name:string) {
    super(loc, range);
  }
}

export class ObjectExpression extends Expression {
  className() {
    return "ObjectExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly properties: Array<[Typed<Expression>, Typed<Expression>]>) { // It seems that object properties could be store using Property type, but we need to keep it for backward compatibility
    super(loc, range);
  }
}

export class ArrayExpression extends Expression {
  className() {
    return "ArrayExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>, readonly values: Array<Typed<Expression>>) {
    super(loc, range);
  }
}

export class ConditionalExpression extends Expression {
  className() {
    return "ConditionalExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly test: Typed<Expression>, readonly consequent: Typed<Expression>, readonly alternate: Typed<Expression>) {
    super(loc, range);
  }
}


export abstract class Pattern extends Node {
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>) {
    super(loc, range);
  }
}

export class ArrowFunctionExpression extends Expression {
  className() {
    return "ArrowFunctionExpression";
  }

  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly id: Option<Identifier>,
              readonly params: Array<Typed<Pattern>>,
              readonly body: Either<BlockStatement, Typed<Expression>>,
              readonly generator: boolean,
              readonly expression: boolean) {
    super(loc, range);
  }

}

export abstract class Declaration extends Statement {
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>) {
    super(loc, range);
  }
}

export class VariableDeclaration extends Declaration {
  className() {
    return "VariableDeclaration";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly declarations: Array<VariableDeclarator>,
              readonly kind: "var" | "let" | "const") {
    super(loc, range);
  }
}

export class VariableDeclarator extends Node {
  className() {
    return "VariableDeclarator";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly id: Typed<Pattern>,
              readonly init: Option<Typed<Expression>>) {
    super(loc, range);
  }
}

export class UpdateExpression extends Expression {
  className() {
    return "UpdateExpression";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly operator: UpdateOperator,
              readonly argument: Typed<Expression>,
              readonly prefix: boolean) {
    super(loc, range);
  }
}

export class AssignmentExpression extends Expression {
  className() {
    return "AssignmentExpression";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly operator: AssignmentOperator,
              readonly left: Either<Typed<Pattern>, Typed<Expression>>,
              readonly right: Typed<Expression>) {
    super(loc, range);
  }
}

export class SequenceExpression extends Expression {
  className() {
    return "SequenceExpression";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly expressions: Array<Typed<Expression>>) {
    super(loc, range);
  }
}

export class FunctionDeclaration extends Statement {
  className() {
    return "FunctionDeclaration";
  }
  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly id: Identifier,
              readonly params: Array<Typed<Pattern>>,
              readonly body: BlockStatement) {
    super(loc, range);
  }
}


export class Directive extends Node {
  className() {
    return "Directive";
  }

  constructor(loc: Option<SourceLocation>, range: Option<[number, number]>,
              readonly expression: Typed<Literal>,
              readonly directive: string) {
    super(loc, range);
  }
}

export class TypedEstreeFactory {

  static copy(node:Node):Node {
    return TypedEstreeFactory.copyByType(node, node.className());
  }

  static copyTyped(node:Typed<Node>):Typed<Node> {
    return Typed.of(TypedEstreeFactory.copyByType(Typed.value(node), Typed.className(node)));
  }

  static copyByType(node:Node, className:string):Node {
    switch (className) {
      case "Program": {
        const n = <Program>node;
        return new Program(Option.copy(n.loc), Option.copy(n.range), n.body.map(s => TypedEstreeFactory.copyTyped(s)), n.sourceType);
      }
      case "EmptyStatement": {
        const n = <EmptyStatement>node;
        return new EmptyStatement(Option.copy(n.loc), Option.copy(n.range));
      }
      case "ReturnStatement": {
        const n = <ReturnStatement>node;
        return new ReturnStatement(Option.copy(n.loc), Option.copy(n.range), Option.copy(n.argument).map(e => TypedEstreeFactory.copyTyped(e)));
      }
      case "ExpressionStatement": {
        const n = <ExpressionStatement>node;
        return new ExpressionStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.expression));
      }
      case "IfStatement": {
        const n = <IfStatement>node;
        return new IfStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.test), TypedEstreeFactory.copyTyped(n.consequent), Option.copy(n.alternate).map(a => TypedEstreeFactory.copyTyped(a)));
      }
      case "SwitchStatement": {
        const n = <SwitchStatement>node;
        return new SwitchStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.discriminant), n.cases.map(c => <SwitchCase>TypedEstreeFactory.copyByType(c, "SwitchCase")));
      }
      case "SwitchCase": {
        const n = <SwitchCase>node;
        return new SwitchCase(Option.copy(n.loc), Option.copy(n.range), Option.copy(n.test).map(a => TypedEstreeFactory.copyTyped(a)), n.consequent.map(c => TypedEstreeFactory.copyTyped(c)));
      }
      case "WhileStatement": {
        const n = <WhileStatement>node;
        return new WhileStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.test), TypedEstreeFactory.copyTyped(n.body));
      }
      case "DoWhileStatement": {
        const n = <DoWhileStatement>node;
        return new DoWhileStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.body), TypedEstreeFactory.copyTyped(n.test));
      }
      case "ForStatement": {
        const n = <ForStatement>node;
        return new ForStatement(Option.copy(n.loc), Option.copy(n.range),
          Option.copy(n.init).map(f => Either.copy(f, (variableDeclaration: VariableDeclaration) => <VariableDeclaration>TypedEstreeFactory.copyByType(variableDeclaration, "VariableDeclaration"), expression => TypedEstreeFactory.copyTyped(expression))),
          Option.copy(n.test).map(e => TypedEstreeFactory.copyTyped(e)), Option.copy(n.update).map(e => TypedEstreeFactory.copyTyped(e)), TypedEstreeFactory.copyTyped(n.body));
      }
      case "BlockStatement": {
        const n = <BlockStatement>node;
        return new BlockStatement(Option.copy(n.loc), Option.copy(n.range), n.body.map(s => TypedEstreeFactory.copyTyped(s)));
      }
      case "MemberExpression": {
        const n = <MemberExpression>node;
        return new MemberExpression(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.object), TypedEstreeFactory.copyTyped(n.property), n.computed);
      }
      case "BinaryExpression": {
        const n = <BinaryExpression>node;
        return new BinaryExpression(Option.copy(n.loc), Option.copy(n.range), n.operator, TypedEstreeFactory.copyTyped(n.left), TypedEstreeFactory.copyTyped(n.right));
      }
      case "LogicalExpression": {
        const n = <LogicalExpression>node;
        return new LogicalExpression(Option.copy(n.loc), Option.copy(n.range), n.operator, TypedEstreeFactory.copyTyped(n.left), TypedEstreeFactory.copyTyped(n.right));
      }
      case "UnaryExpression": {
        const n = <UnaryExpression>node;
        return new UnaryExpression(Option.copy(n.loc), Option.copy(n.range), n.operator, n.prefix, TypedEstreeFactory.copyTyped(n.argument));
      }
      case "CallExpression": {
        const n = <CallExpression>node;
        return new CallExpression(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.callee), n.arguments.map(a => TypedEstreeFactory.copyTyped(a)));
      }
      case "NullLiteral": {
        const n = <NullLiteral>node;
        return new NullLiteral(Option.copy(n.loc), Option.copy(n.range));
      }
      case "StringLiteral": {
        const n = <StringLiteral>node;
        return new StringLiteral(Option.copy(n.loc), Option.copy(n.range), n.value);
      }
      case "BooleanLiteral": {
        const n = <BooleanLiteral>node;
        return new BooleanLiteral(Option.copy(n.loc), Option.copy(n.range), n.value);
      }
      case "NumberLiteral": {
        const n = <NumberLiteral>node;
        return new NumberLiteral(Option.copy(n.loc), Option.copy(n.range), n.value);
      }
      case "RegexLiteral": {
        const n = <RegexLiteral>node;
        return new RegexLiteral(Option.copy(n.loc), Option.copy(n.range), n.pattern, n.flags);
      }
      case "Identifier": {
        const n = <Identifier>node;
        return new Identifier(Option.copy(n.loc), Option.copy(n.range), n.name);
      }
      case "ObjectExpression": {
        const n = <ObjectExpression>node;
        return new ObjectExpression(Option.copy(n.loc), Option.copy(n.range), n.properties.map(kvp => {
          // do not simplify - consts with type annotations are here to keep the compiler happy
          const key: Typed<Node> = TypedEstreeFactory.copyTyped(kvp[0]);
          const val: Typed<Node> = TypedEstreeFactory.copyTyped(kvp[1]);
          const pair: [Typed<Node>, Typed<Node>] = [key, val];
          return pair;
        }));
      }
      case "ArrayExpression": {
        const n = <ArrayExpression>node;
        return new ArrayExpression(Option.copy(n.loc), Option.copy(n.range), n.values.map(TypedEstreeFactory.copyTyped));
      }
      case "ConditionalExpression": {
        const n = <ConditionalExpression>node;
        return new ConditionalExpression(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.test), TypedEstreeFactory.copyTyped(n.consequent), TypedEstreeFactory.copyTyped(n.alternate));
      }
      case "ArrowFunctionExpression": {
        const n = <ArrowFunctionExpression>node;
        return new ArrowFunctionExpression(Option.copy(n.loc), Option.copy(n.range), Option.copy(n.id).map(e => <Identifier>TypedEstreeFactory.copyByType(e, "Identifier")),
          n.params.map(e => TypedEstreeFactory.copyTyped(e)),
          Either.copy(n.body, blockStatement => <BlockStatement>TypedEstreeFactory.copyByType(blockStatement, "BlockStatement"), expression => TypedEstreeFactory.copyTyped(expression)), n.generator, n.expression);
      }
      case "VariableDeclaration": {
        const n = <VariableDeclaration>node;
        return new VariableDeclaration(Option.copy(n.loc), Option.copy(n.range), n.declarations.map(d => <VariableDeclarator>TypedEstreeFactory.copyByType(d, "VariableDeclarator")), n.kind);
      }
      case "VariableDeclarator": {
        const n = <VariableDeclarator>node;
        return new VariableDeclarator(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.id), Option.copy(n.init).map(e => TypedEstreeFactory.copyTyped(e)));
      }
      case "UpdateExpression": {
        const n = <UpdateExpression>node;
        return new UpdateExpression(Option.copy(n.loc), Option.copy(n.range), n.operator, TypedEstreeFactory.copyTyped(n.argument), n.prefix);
      }
      case "AssignmentExpression": {
        const n = <AssignmentExpression>node;
        return new AssignmentExpression(Option.copy(n.loc), Option.copy(n.range), n.operator,
          Either.copy(n.left, pattern => TypedEstreeFactory.copyTyped(pattern), expression => TypedEstreeFactory.copyTyped(expression)),
          TypedEstreeFactory.copyTyped(n.right));
      }
      case "SequenceExpression": {
        const n = <SequenceExpression>node;
        return new SequenceExpression(Option.copy(n.loc), Option.copy(n.range), n.expressions.map(e => TypedEstreeFactory.copyTyped(e)));
      }

      case "ThrowStatement": {
        const n = <ThrowStatement>node;
        return new ThrowStatement(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.argument));
      }
      case "TryStatement": {
        const n = <TryStatement>node;
        return new TryStatement(Option.copy(n.loc), Option.copy(n.range),
          <BlockStatement>TypedEstreeFactory.copyByType(n.block, "BlockStatement"),
          Option.copy(n.handler).map(c => <CatchClause>TypedEstreeFactory.copyByType(c, "CatchClause")),
          Option.copy(n.finalizer).map(f => <BlockStatement>TypedEstreeFactory.copyByType(f, "BlockStatement")))
      }
      case "CatchClause": {
        const c = <CatchClause>node;
        return new CatchClause(Option.copy(c.loc), Option.copy(c.range),
          TypedEstreeFactory.copyTyped(c.param),
          <BlockStatement>TypedEstreeFactory.copyByType(c.body, "BlockStatement"));
      }
      case "FunctionDeclaration": {
        const n = <FunctionDeclaration>node;
        return new FunctionDeclaration(Option.copy(n.loc), Option.copy(n.range),
          <Identifier>TypedEstreeFactory.copyByType(n.id, "Identifier"),
          n.params.map(p => TypedEstreeFactory.copyTyped(p)),
          <BlockStatement>TypedEstreeFactory.copyByType(n.body, "BlockStatement"));
      }
      case "Directive": {
        const n = <Directive>node;
        return new Directive(Option.copy(n.loc), Option.copy(n.range), TypedEstreeFactory.copyTyped(n.expression), n.directive);
      }
      case "TemplateLiteral": {
        const n = <TemplateLiteral>node;
        return new TemplateLiteral(Option.copy(n.loc), Option.copy(n.range),
          n.quasis.map(q => <TemplateElement>TypedEstreeFactory.copyByType(q, TemplateElement.className)),
          n.expressions.map(e => TypedEstreeFactory.copyTyped(e)));
      }
      case "TaggedTemplateExpression": {
        const n = <TaggedTemplateExpression>node;
        return new TaggedTemplateExpression(Option.copy(n.loc), Option.copy(n.range),
          TypedEstreeFactory.copyTyped(n.tag),
          <TemplateLiteral>TypedEstreeFactory.copy(n.quasi));
      }
      case "TemplateElement": {
        const n = <TemplateElement>node;
        return new TemplateElement(Option.copy(n.loc), Option.copy(n.range),
          n.tail, new TemplateElementValue(n.value.cooked, n.value.raw));
      }

      default:
        throw new Error("Unsupported type [" + className + "]")
    }
  }

}


export class TemplateLiteral extends Expression  {
  className() {
    return "TemplateLiteral";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly quasis: Array<TemplateElement>,
              readonly expressions: Array<Typed<Expression>>) {
    super(loc, range);
  }
}

// e.g. s`some text`
export class TaggedTemplateExpression extends Expression  {

  className() {
    return "TaggedTemplateExpression";
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly tag: Typed<Expression>,
              readonly quasi: TemplateLiteral) {
    super(loc, range);
  }
}

export class TemplateElementValue {
  constructor(readonly cooked: string,
              readonly raw: string) {}
}

export class TemplateElement extends Node {
  static className = "TemplateElement";
  className() {
    return TemplateElement.className;
  }

  constructor(loc:Option<SourceLocation>, range:Option<[number, number]>,
              readonly tail: boolean,
              readonly value: TemplateElementValue) {
    super(loc, range);
  }
}
