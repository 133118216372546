import {LocalDate, LocalDateTime} from "./data-types/Time";

export function daysInYear(year: number): number {
  return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
}

export function weekDay(date: LocalDate|LocalDateTime): number {
  if (date instanceof LocalDate) {
    return date.weekDay();
  } else {
    return date.date.weekDay();
  }
}

export function daysInMonth(date: LocalDate|LocalDateTime): number {
  const year = date instanceof LocalDate ? date.year : date.date.year;
  const month = date instanceof LocalDate ? date.month : date.date.month;
  switch (month) {
    case 1: case 3: case 5: case 7: case 8: case 10: case 12: return 31;
    case 4: case 6: case 9: case 11: return 30;
    case 2: return daysInYear(year) === 366 ? 29 : 28;
    default: throw new Error("Invalid month: " + month);
  }
}

export function isoWeeksInYear(year: number): number;
export function isoWeeksInYear(date: LocalDate): number;
export function isoWeeksInYear(date: LocalDate|number): number {
  const year = (typeof date === "number") ? date : date.year;
  const firstDay = new LocalDate(year, 1, 1);
  const lastDay = new LocalDate(year, 12, 31);
  return Math.ceil((lastDay.dayOfYear() - firstDay.dayOfYear()) / 7);
}

export function isoWeekInYear(dateTime: LocalDate|LocalDateTime): {year: number, week: number} {

  const date = dateTime instanceof LocalDate ? dateTime : dateTime.date;
  const dayNum = date.weekDay();
  const dayOfYear = date.dayOfYear();

  // Calculate the week of the year
  let weekNum = Math.floor((dayOfYear - dayNum + 10) / 7);

  if (weekNum === 0) {
    // Week belongs to the end of the previous year
    return { year: date.year - 1, week: isoWeeksInYear(date.year - 1) };
  } else if (weekNum > isoWeeksInYear(date.year)) {
    // Week belongs to the start of the next year
    return { year: date.year + 1, week: 1 };
  } else {
    // Week belongs to the current year
    return { year: date.year, week: weekNum };
  }
}
