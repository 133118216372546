import {AggregateId, AggregateVersion, FileUri, Option} from "@utils";
import {WeeklyOperatingHours} from "@shared";
import {OperatingHoursException} from "@shared-model";

export class UpdateOrganizationInfo {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly address: string,
              readonly phone: string,
              readonly fax: string,
              readonly email: string,
              readonly website: string,
              readonly industry: string,
              readonly logo: Option<FileUri>,
              readonly showInfoOnLoginPage: boolean,
              readonly description: string) {
  }
}

export class UpdateWeeklyHours {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newWeeklyHoursOption: Option<WeeklyOperatingHours>) {}
}

export class AddCalendarException {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newException: OperatingHoursException) {}
}

export class UpdateCalendarException {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newException: OperatingHoursException) {}
}

export class DeleteCalendarException {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly id: number) {}
}


export class ChangeOrganizationAccentColor {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly accentColor: Option<string>) {}
}

export class ChangeOrganizationLanguage {
  constructor(readonly aggregateId:AggregateId,
              readonly expectedVersion:AggregateVersion,
              readonly language:string) {
  }
}

export class ChangeOrganizationTimezone {
  constructor(readonly aggregateId:AggregateId,
              readonly expectedVersion:AggregateVersion,
              readonly timezone:string) {}
}
