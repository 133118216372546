import {BusinessVariable, PasswordVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {PasswordInputComponentDefinition} from "@screen-common";

export class PasswordInputComponentState extends LabeledScreenComponentState {
  static className = "PasswordInputComponentState";
  className() {
    return PasswordInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }


  get model() {
    return this.properties.optionalPasswordProperty(PasswordInputComponentDefinition.MODEL);
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get startIcon() {
    return this.properties.optionalStringProperty("startIcon");
  }

  get endIcon() {
    return this.properties.optionalStringProperty("endIcon");
  }

  get icon() {
    return this.properties.optionalStringProperty("icon");
  }

  get iconPosition() {
    return this.properties.stringProperty("iconPosition");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case PasswordInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof PasswordVariable) {
          this.properties.putValue(PasswordInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type String but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(PasswordInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: PasswordInputComponentState) {
    return new PasswordInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class PasswordInputComponentRefState extends ScreenComponentRefState {
  static className = "PasswordInputComponentRefState";
  className() {
    return PasswordInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: PasswordInputComponentRefState) {
    return new PasswordInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
