
<div class="empty" *ngIf="visibleNotifications.length === 0">{{'notifications_empty' | i18n}}</div>

<ng-container *ngIf="!recentOnly && visibleNotifications.length > 0">
  <div class="sectionName">Dzisiaj</div>
  <hr class="theme-background-horizontal-separator-small-margin" />
  <div class="empty" *ngIf="todayNotifications.length === 0">{{'notifications_empty' | i18n}}</div>
  <my-notification *ngFor="let notification of todayNotifications" [notification]="notification"></my-notification>

  <div class="sectionName">Starsze</div>
  <hr class="theme-background-horizontal-separator-small-margin" />
  <div class="empty" *ngIf="olderNotifications.length === 0">{{'notifications_empty' | i18n}}</div>
  <my-notification *ngFor="let notification of olderNotifications" [notification]="notification"></my-notification>

</ng-container>

<ng-container *ngIf="recentOnly && visibleNotifications.length > 0">
  <my-notification *ngFor="let notification of recentNotifications" [notification]="notification"></my-notification>

</ng-container>
