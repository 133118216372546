import {Injectable} from "@angular/core";
import {__, ApplicationId, Option, ResolvablePromise, Some} from "@utils";
import {ApplicationInfo, ApplicationName, ProcessesAndApplications} from "./application-model";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {UsableApplicationSummary} from "../../modules/applications.module/model/ApplicationModel";
import {SessionServiceProvider} from "@shared";
import {PinnedApplication} from "../user-volatile-settings/UserPinnedApplications.model";
import {UserVolatileSettingsSharedService} from "../user-volatile-settings/UserVolatileSettings.shared-service";
import {Subject} from "rxjs";

export class GetApplicationNames {
  constructor(readonly ids: Array<ApplicationId>) {
  }
}

@Injectable({
  providedIn: "root",
})
export class ApplicationsSharedService {

  private readonly identifierToIdCache: {[identifier: string]: Promise<Option<ApplicationId>>} = {};

  private readonly namesCache: {[id: string]: ResolvablePromise<ApplicationName>} = {};

  private usableApplicationsCache?: Promise<Array<UsableApplicationSummary>> = undefined;


  private pinnedApplicationsSubject: Subject<Array<PinnedApplication>> = new Subject<Array<PinnedApplication>>();
  private favoriteApplicationsSubject: Subject<Array<ApplicationId>> = new Subject<Array<ApplicationId>>();

  constructor(readonly authenticatedHttp: AuthenticatedHttp,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly userVolatileSettingsSharedService: UserVolatileSettingsSharedService) {

    this.namesCache[""] = ResolvablePromise.resolved<ApplicationName>(new ApplicationName(ApplicationId.of(""), "???", "???", 0, "globe"));
  }


  loadApplicationOptionName(applicationId: Option<ApplicationId>, onSuccess: (name: ApplicationName) => void) {
    if(applicationId.isDefined()) {
      this.loadApplicationName(applicationId.get(), onSuccess);
    } else {
      onSuccess(ApplicationName.global);
    }
  }

  loadApplicationName(applicationId: ApplicationId, onSuccess: (name: ApplicationName) => void) {
    this.loadApplicationsNames([applicationId], names => {
      onSuccess(names[0]);
    });
  }

  getApplicationIdByIdentifier(identifier: string): Promise<Option<ApplicationId>> {

    const fromCache = this.identifierToIdCache[identifier];
    if(fromCache) {
      return fromCache;
    } else if(identifier.indexOf("@") === 0) {
      const promise = new Promise<Option<ApplicationId>>((resolve) => {
        resolve(Some(ApplicationId.of(identifier.substring(1))));
      });
      this.identifierToIdCache[identifier] = promise;
      return promise;
    } else {
      const promise = this.authenticatedHttp.getPromise<Option<ApplicationId>>("application/get-id/" + identifier).then((data: Option<ApplicationId>) => {
        return Option.copy(data, ApplicationId.of);
      });
      this.identifierToIdCache[identifier] = promise;
      return promise;
    }
  }

  loadApplicationsNamesPromise(applicationsIds: Array<ApplicationId>): Promise<Array<ApplicationName>> {
    return new Promise<Array<ApplicationName>>(resolve => {
      this.loadApplicationsNames(applicationsIds, names => {
        resolve(names);
      });
    });
  }

  loadApplicationsNames(applicationsIds: Array<ApplicationId>, onSuccess: (data: Array<ApplicationName>) => void) {

    const toLoad = __(applicationsIds).filter(id => !this.namesCache.hasOwnProperty(id.id));

    if(toLoad.length === 0) {
      Promise.all(applicationsIds.map(appId => this.namesCache[appId.id].promise)).then(appNames => {
        onSuccess(appNames)
      });
    } else {

      toLoad.forEach(id => this.namesCache[id.id] = new ResolvablePromise<ApplicationName>());

      this.authenticatedHttp.post("application/get-names", new GetApplicationNames(toLoad), (data: Array<ApplicationName>) => {
        const copied = data.map(ApplicationName.copy);

        toLoad.forEach(id => {
          const name = copied.find(n => n.id.id === id.id);
          if(name === undefined) {
            this.namesCache[id.id].resolve(new ApplicationName(id, "?", "?", 0, ""));
          } else {
            this.namesCache[name.id.id].resolve(name);
          }
        });

        Promise.all(applicationsIds.map(appId => this.namesCache[appId.id].promise)).then(appNames => {
          onSuccess(appNames)
        });
      });
    }
  }

  loadUsableApplications(): Promise<Array<UsableApplicationSummary>> {
    if(this.usableApplicationsCache === undefined) {
      this.usableApplicationsCache = this.authenticatedHttp.getPromise<Array<UsableApplicationSummary>>("application/load-usable-applications-summary")
        .then((data: Array<UsableApplicationSummary>) => data.map(UsableApplicationSummary.copy));
    }
    return this.usableApplicationsCache;
  }


  loadAllProcessesAndApplications(): Promise<ProcessesAndApplications> {
    return this.authenticatedHttp.getPromise<ProcessesAndApplications>("processes-and-applications/all").then((response: ProcessesAndApplications) => {
      return ProcessesAndApplications.copy(response);
    });
  }

  // FAVORITE APPLICATIONS

  addFavoriteApplication(id: ApplicationId) {
    this.userVolatileSettingsSharedService.getUserFavoriteApplications().then(applications => {
      if(!__(applications).exists(a => a.id === id.id)) {
        applications.push(id);
      }
      this.userVolatileSettingsSharedService.updateUserFavoriteApplications(applications, () => {});
    });
  }

  removeFavoriteApplication(id: ApplicationId) {
    this.userVolatileSettingsSharedService.getUserFavoriteApplications().then(applications => {
      const toRemove = __(applications).findIndexOf(a => a.id === id.id);
      toRemove.forEach(i => applications.splice(i, 1));
      this.userVolatileSettingsSharedService.updateUserFavoriteApplications(applications, () => {});
    });
  }


  loadFavoriteApplications(onSuccess: (applications: Array<ApplicationId>) => void) {
    this.userVolatileSettingsSharedService.getUserFavoriteApplications().then(applications => {
      onSuccess(applications);
    });
  }

  loadFavoriteApplicationsPromise() {
    return this.userVolatileSettingsSharedService.getUserFavoriteApplications();
  }

  // PINNED APPLICATIONS

  loadPinnedApplications(onSuccess: (applications: Array<PinnedApplication>) => void) {
    this.userVolatileSettingsSharedService.getUserPinnedApplications().then(applications => {
      this.enrichPinnedApplications(applications, pinned => {
        onSuccess(pinned);
        this.pinnedApplicationsSubject.next(pinned);
      });
    });
  }

  async loadPinnedApplicationsPromise(): Promise<PinnedApplication[]> {
    const userPinnedApplications = await this.userVolatileSettingsSharedService.getUserPinnedApplications();
    return new Promise(resolve => {
      this.enrichPinnedApplications(userPinnedApplications, pinned => {
        this.pinnedApplicationsSubject.next(pinned);
        resolve(pinned);
      });
    });
  }

  addPinnedApplication(id: ApplicationId) {
    this.userVolatileSettingsSharedService.getUserPinnedApplications().then(applications => {
      if(!__(applications).exists(a => a.id === id.id)) {
        applications.push(id);
        this.userVolatileSettingsSharedService.updateUserPinnedApplications(applications, () => {});
        this.enrichPinnedApplications(applications, pinned => {
          this.pinnedApplicationsSubject.next(pinned);
        });
      }
    });
  }

  removePinnedApplication(id: ApplicationId) {
    this.userVolatileSettingsSharedService.getUserPinnedApplications().then(applications => {
      const toRemove = __(applications).findIndexOf(a => a.id === id.id);
      toRemove.forEach(i => applications.splice(i, 1));
      this.userVolatileSettingsSharedService.updateUserPinnedApplications(applications, () => {});
      this.enrichPinnedApplications(applications, pinned => {
        this.pinnedApplicationsSubject.next(pinned);
      });
    });
  }

  getPinnedObservable() {
    return this.pinnedApplicationsSubject.asObservable();
  }

  private enrichPinnedApplications(applications: Array<ApplicationId>, onSuccess: (applications: Array<PinnedApplication>) => void) {
    this.loadApplicationsNames(applications, names => {
      onSuccess(applications.map(a => {
        const name = __(names).find(n => n.id.id === a.id).getOrElse(new ApplicationName(a, "???", "???", 0, ""));
        return new PinnedApplication(a, name.name, name.identifier, name.iconCode, name.colorId);
      }));
    });
  }

  getApplicationInfo(applicationId: ApplicationId): Promise<Option<ApplicationInfo>> {
    return this.authenticatedHttp.getPromise<Option<ApplicationInfo>>("application/get/" + applicationId.id)
      .then((response: Option<ApplicationInfo>) => {
        return Option.copy(response, ApplicationInfo.copy);
      })
  }

}
