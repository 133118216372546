import {FormElement, FormElementRef} from "./FormModel";
import {Typed} from "@utils";
import {Label, LabelRef} from "./elements/Label";
import {DropList, DropListRef} from "./elements/DropList";
import {TextArea, TextAreaRef} from "./elements/TextArea";
import {AttachmentField, AttachmentFieldRef} from "./elements/AttachementField";
import {RadioButton, RadioButtonRef} from "./elements/RadioButton";
import {ActionButton, ActionButtonRef} from "./elements/ActionButton";
import {Checkbox, CheckboxRef} from "./elements/Checkbox";
import {DateField, DateFieldRef} from "./elements/DateField";
import {MapField, MapFieldRef} from "./elements/MapField";

export class ElementsFactory {

  static copy(element: FormElement): FormElement {
    return ElementsFactory.copyByType(element, element.className());
  }

  static copyTyped(element: Typed<FormElement>): Typed<FormElement> {
    return Typed.of(ElementsFactory.copyByType(Typed.value(element), Typed.className(element)));
  }

  static copyByType(element: FormElement, className: string): FormElement {
    switch(className) {
      case Label.className: return Label.copy(<Label>element);
      case Checkbox.className: return Checkbox.copy(<Checkbox>element);
      case RadioButton.className: return RadioButton.copy(<RadioButton>element);
      case DropList.className: return DropList.copy(<DropList>element);
      case TextArea.className: return TextArea.copy(<TextArea>element);
      case DateField.className: return DateField.copy(<DateField>element);
      case AttachmentField.className: return AttachmentField.copy(<AttachmentField>element);
      case ActionButton.className: return ActionButton.copy(<ActionButton>element);
      case MapField.className: return MapField.copy(<MapField>element);
      default :throw new Error("Unknown field type " + element.className());
    }
  }


}


export class ElementsRefsFactory {

  static copy(element: FormElementRef): FormElementRef {
    return ElementsRefsFactory.copyByType(element, element.className());
  }

  static copyTyped(element: Typed<FormElementRef>): Typed<FormElementRef> {
    return Typed.of(ElementsRefsFactory.copyByType(Typed.value(element), Typed.className(element)));
  }

  static copyByType(element: FormElementRef, className: string): FormElementRef {
    switch(className) {
      case LabelRef.className: return LabelRef.copy(<LabelRef>element);
      case CheckboxRef.className: return CheckboxRef.copy(<CheckboxRef>element);
      case RadioButtonRef.className: return RadioButtonRef.copy(<RadioButtonRef>element);
      case DropListRef.className: return DropListRef.copy(<DropListRef>element);
      case TextAreaRef.className: return TextAreaRef.copy(<TextAreaRef>element);
      case DateFieldRef.className: return DateFieldRef.copy(<DateFieldRef>element);
      case AttachmentFieldRef.className: return AttachmentFieldRef.copy(<AttachmentFieldRef>element);
      case ActionButtonRef.className: return ActionButtonRef.copy(<ActionButtonRef>element);
      case MapFieldRef.className: return MapFieldRef.copy(<MapFieldRef>element);
      default :throw new Error("Unknown field type " + element.className());
    }
  }


}
