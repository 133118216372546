import {None, Option, ScreenId, Some} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {ScreenComponentId, StringProperty} from "@screen-common";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties, ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef, LabelProperties, PaddingsProperties,
  ScreenComponentDefinitionWithLabel, TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalI18nTextProperty,
  OptionalNumberProperty, OptionalStringProperty
} from "@screen-common";

export class ValidationPreset {
    constructor(readonly name: string) {}

    static email = new ValidationPreset("email");
    static polishPostalCode = new ValidationPreset("polishPostalCode");
    static polishPesel = new ValidationPreset("polishPesel");
    static polishNip = new ValidationPreset("polishNip");
    static polishBankAccount = new ValidationPreset("polishBankAccount");

    static byName(name: string): ValidationPreset {
      switch (name) {
        case ValidationPreset.email.name: return ValidationPreset.email;
        case ValidationPreset.polishPostalCode.name: return ValidationPreset.polishPostalCode;
        case ValidationPreset.polishPesel.name: return ValidationPreset.polishPesel;
        case ValidationPreset.polishNip.name: return ValidationPreset.polishNip;
        case ValidationPreset.polishBankAccount.name: return ValidationPreset.polishBankAccount;
        default: throw new Error("Unsupported preset: '"+name+"'");
      }
    }

    static patternByPreset(preset: ValidationPreset): Option<string> {
      switch (preset.name) {
        case ValidationPreset.email.name: return None();
        case ValidationPreset.polishPostalCode.name: return Some("99-999");
        case ValidationPreset.polishPesel.name: return None();
        case ValidationPreset.polishNip.name: return None();
        case ValidationPreset.polishBankAccount.name: return None();
        default: throw new Error("Unsupported preset: '"+preset.name+"'");
      }
    }
  }

  export class TextInputComponentRef extends InputComponentRef {
    static className = "TextInputComponentRef";

    className() {
      return TextInputComponentRef.className;
    }

    constructor(
      readonly id: ScreenComponentRefId,
      readonly componentId: ScreenComponentId,
      readonly applicationScreen: Option<ScreenId>,
      readonly properties: ComponentProperties
    ) {
      super();
    }

    static copy(other: TextInputComponentRef): TextInputComponentRef {
      return new TextInputComponentRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      );
    }

    static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("TextInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
    override defaultPropertiesProvider = TextInputComponentRef.DEFAULT_PROPERTIES;

  }

  export class TextInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

    static className = "TextInputComponentDefinition";

    static MODEL = "model";
    static ON_CHANGE = "onChange";


    className() {
      return TextInputComponentDefinition.className;
    }
    typeName() {
      return "TextInput";
    }

    constructor(
      override readonly id: ScreenComponentId,
      override readonly identifier: Option<string>,
      override readonly properties: ComponentProperties,
      readonly actionProperties: ComponentActionProperties,
      override readonly validationRules: ComponentValidationRules) {
      super(id, identifier, properties, validationRules,TextInputComponentDefinition.DEFAULT_PROPERTIES);
    }

    get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)}
    get multiLine(): BooleanProperty {return this.properties.getBooleanProperty("multiLine", this.defaultPropertiesProvider)}
    get minTextLength(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("minTextLength", this.defaultPropertiesProvider)}
    get maxTextLength(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("maxTextLength", this.defaultPropertiesProvider)}
    innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
      return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
    }
    get pattern(): OptionalStringProperty {return this.properties.getOptionalStringProperty("pattern", this.defaultPropertiesProvider)}
    get validationPreset(): OptionalStringProperty {return this.properties.getOptionalStringProperty("validationPreset", this.defaultPropertiesProvider)}

    get icon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("icon", this.defaultPropertiesProvider)};
    get iconPosition(): StringProperty {return this.properties.getStringProperty("iconPosition", this.defaultPropertiesProvider)};

    // deprecated
    get startIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("startIcon", this.defaultPropertiesProvider)};
    // deprecated
    get endIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("endIcon", this.defaultPropertiesProvider)};

    labelProperties = new LabelProperties(this.properties);
    textProperties = new TextsProperties(this.properties);
    paddingsProperties = new PaddingsProperties("", this.properties);
    bordersProperties = new BordersProperties(this.properties);
    backgroundsProperties = new BackgroundsProperties("", this.properties);




    static copy(other: TextInputComponentDefinition): TextInputComponentDefinition {
      return new TextInputComponentDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules)
      );
    }

    getModelNames(): Array<string> {
      return [TextInputComponentDefinition.MODEL];
    }

    static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("TextInputComponentDefinition",
      (name: string) => {switch (name) {
        case "placeholder": return OptionalI18nTextProperty.disabled();
        case "multiLine": return BooleanProperty.ofTrue();
        case "validationPreset": return OptionalStringProperty.disabled("email");
        case "regex": return OptionalStringProperty.disabled("[a-z]5");
        case "pattern": return OptionalStringProperty.disabled("99-999");
        case "minTextLength": return OptionalNumberProperty.disabled(0);
        case "maxTextLength": return OptionalNumberProperty.disabled(10);
        case "innerShadow": return OptionalBooleanProperty.disabled(true);
        case "paddingTop": return OptionalStringProperty.disabled("0.1cm");
        case "paddingLeft": return OptionalStringProperty.disabled("0.1cm");
        case "paddingBottom": return OptionalStringProperty.disabled("0.1cm");
        case "paddingRight": return OptionalStringProperty.disabled("0.1cm");
        case "startIcon": return OptionalStringProperty.disabled("confirm");
        case "endIcon": return OptionalStringProperty.disabled("confirm");
        case "icon": return OptionalStringProperty.disabled("confirm");
        case "iconPosition": return StringProperty.of("start");
        default: return null;
      }},
      DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
      DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
      DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
      DefaultPropertyProvider.definitionPaddings);

  }

