import {__, None, Option} from "@utils";
import {VariablePath} from "./VariablePath";
import {ArrayVariable, BusinessVariable, ObjectVariable, RootVariable} from "./BusinessVariables";

export class BusinessVariablesUtil {
  static variableByPath(variablePath: VariablePath, variables: RootVariable<BusinessVariable>[]): Option<BusinessVariable> {

    if (variablePath.isRoot()) {
      return __(variables).find(v => v.name === variablePath.lastName()).map(v => v.unwrappedVariable());
    } else {
      const contextPath = variablePath.context();
      const contextOption = __(variables).find(v => v.name === contextPath.headName());
      if (contextOption.isDefined()) {
        const context = contextOption.get();
        if (context.unwrappedVariable().className() === ArrayVariable.className) {
          const a = <ArrayVariable<BusinessVariable>>context.unwrappedVariable();
          const arrayEntry = (<ArrayVariable<BusinessVariable>>context.unwrappedVariable()).unwrappedValue()[variablePath.headNameIndex()];
          if (arrayEntry.className() === ObjectVariable.className) {
            return (<ObjectVariable>arrayEntry).valueFor(variablePath.lastName());
          } else {
            return None();
          }
        } else if (context.unwrappedVariable().className() === ObjectVariable.className) {
          const o = <ObjectVariable>context.unwrappedVariable();
          return o.valueFor(variablePath.lastName());
        } else {
          throw new Error("Only Object or Array[Object] supported, but was " + +context.unwrappedVariable().className());
        }
      } else {
        return None();
      }
    }
  }
}
