import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LocalDate, range, WeekDay} from "@utils";
import {UserSettingsStateService} from "@shared";

export class CalendarDay {

  constructor(readonly label: string,
              readonly value: LocalDate,
              readonly otherMonth: boolean,
              readonly today: boolean,
              readonly weekend: boolean,
              readonly past: boolean,
              public selected: boolean) {
  }
}

export class CalendarWeek {
    constructor(readonly weekNr: number,
              readonly days: Array<CalendarDay>) {}
}

@Component({
  selector: 'my-date-picker-board',
  templateUrl: './date-picker-board.component.html',
  host: {
    '[class.pastAsError]': 'pastAsError'
  },
})
export class DatePickerBoardComponent implements OnInit {

  private _date: LocalDate|null = null;
  get date(): LocalDate | null {return this._date;}
  @Input({required:true}) set date(value: LocalDate | null) {this._date = value;this.resetCalendar();}
  @Output() dateChange = new EventEmitter<LocalDate|null>();

  @Input() pastAsError = false;

  @Output() picked = new EventEmitter<LocalDate>();

  weeks: Array<CalendarWeek> = [];
  private pageDate: LocalDate = LocalDate.nowDate();

  weekdays: Array<WeekDay> = [];

  constructor(readonly userSettingsService: UserSettingsStateService) {}


  ngOnInit() {
    this.resetCalendar();
  }

  getTempDate() {
    return this.pageDate;
  }

  setPageDate(other: LocalDate) {
    this.pageDate = other;
    this.prepareCalendar();
  }

  setPreviousMonth() {
    if(this.pageDate.month > 1) {
      this.setPageDate(new LocalDate(this.pageDate.year, this.pageDate.month - 1, 1));
    } else {
      this.setPageDate(new LocalDate(this.pageDate.year - 1, 12, 1));
    }
  }

  setNextMonth() {
    if(this.pageDate.month < 12) {
      this.setPageDate(new LocalDate(this.pageDate.year, this.pageDate.month + 1, 1));
    } else {
      this.setPageDate(new LocalDate(this.pageDate.year + 1, 1, 1));
    }
  }

  prepareCalendar() {
    const year = this.pageDate.year;
    const month = this.pageDate.month;
    const weeks: Array<CalendarWeek> = [];


    this.weekdays

    const nowDate = LocalDate.nowDate();

    let currentIndex = 1;
    let endIndex = LocalDate.daysInMonth(this.pageDate);

    const firstDayOfWeek = this.userSettingsService.getEffectiveFirstDayOfWeek();
    let endWeekDay = (firstDayOfWeek + 6) % 7
    endWeekDay = endWeekDay === 0 ? 7 : endWeekDay;

    this.weekdays = range(7).map(day => day + firstDayOfWeek).map(day => day > 7 ? day - 7 : day).map(day => day === 0 ? 7 : day);

    let daysInWeek = [];
    let weekNr = 1;

    const weekend = this.userSettingsService.getWeekendMap();

    while (currentIndex <= endIndex) {
      let date = new LocalDate(year, month, currentIndex);
      const weekDay = date.weekDaySunday7()
      daysInWeek.push(new CalendarDay(currentIndex.toString(), date, false,
        date.isEqual(nowDate),
        weekend[weekDay],
        date.isBefore(nowDate),
        this._date !== null && this._date.isEqual(date)));

      if(weekDay === endWeekDay || currentIndex === endIndex) {
        weeks.push(new CalendarWeek(weekNr, daysInWeek));
        daysInWeek = [];
        weekNr += 1;
      }
      currentIndex += 1;
    }

    if(weeks[0].days.length < 7) {
      const firstDate = weeks[0].days[0].value;
      range(7-weeks[0].days.length).forEach(nr => {
        const date = firstDate.plusDays(-nr - 1);
        const weekDay = date.weekDaySunday7();
        weeks[0].days.unshift(new CalendarDay(date.day.toString(), date, true,
          date.isEqual(nowDate),
          weekend[weekDay],
          date.isBefore(nowDate),
          this._date !== null && this._date.isEqual(date)));
      })
    }

    if(weeks[weeks.length-1].days.length < 7) {
      const lastDate = weeks[weeks.length-1].days[weeks[weeks.length-1].days.length - 1].value;
      range(7-weeks[weeks.length-1].days.length).forEach(nr => {
        const date = lastDate.plusDays(nr + 1);
        const weekDay = date.weekDaySunday7();
        weeks[weeks.length-1].days.push(new CalendarDay(date.day.toString(), date, true,
          date.isEqual(nowDate),
          weekend[weekDay],
          date.isBefore(nowDate),
          this._date !== null && this._date.isEqual(date)));
      })
    }

    this.weeks = weeks;
  }

  setValue(date: LocalDate) {
    this._date = date;
    this.dateChange.emit(date);
    this.picked.emit(date);
    this.resetCalendar();
  }

  private resetCalendar() {
    if (this.date === null) {
      this.pageDate = LocalDate.nowDate();
    } else {
      this.pageDate = this.date;
    }
    this.prepareCalendar();
  }
}
