import {Component, Input} from "@angular/core";
import {TabsContainerViewModel} from "../..";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-tabs-container',
  templateUrl: './tabs-container.component.html',
  host: {
    "[class]": "'componentBody TabsContainer ' + viewModel.customCssClass",
    "[style]": "viewModel.css + ' ' + viewModel.sizeCss",
  }
})
export class TabsContainerComponent {
  @Input({required:true}) viewModel!: TabsContainerViewModel;

  readonly children = new ContainerChildrenHandler();
}

