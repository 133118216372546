import {AnyFlowId, FileUri, FormElementRefId, LocalDateTime, None, ObjectId, Option, OrganizationNodeId} from "@utils";
import {ProcessNodeId} from "@shared-model";

export class ViewableFileUrl {
  constructor(
    readonly url: string,
  ) {}

  public static empty = new ViewableFileUrl("");
}

export class FormSectionRefId {
  readonly _FormSectionRefId: undefined;  // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}
  static copy(other: FormSectionRefId) {
    return new FormSectionRefId(other.id);
  }

}


export class FormSectionId {
  readonly _FormSectionId: undefined;  // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}

  static copy(other: FormSectionId) {
    return new FormSectionId(other.id);
  }
}


export class FileTaskInfo {
  constructor(public flowId: AnyFlowId,
              readonly nodeId: ProcessNodeId) {}
}


export class FileTaskFieldInfo {
  constructor(public flowId: AnyFlowId,
              readonly nodeId: ProcessNodeId,
              readonly formSectionRefId: FormSectionRefId,
              readonly formSectionId: FormSectionId,
              readonly fieldId: FormElementRefId,
              readonly contextObjectId: Option<ObjectId>) {}
}

export class FileFlowInfo {
  constructor(public flowId: AnyFlowId) {}
}

export class ViewableFile {
  constructor(public name: string,
              readonly size: number,
              public fileVersion: Option<number>,
              public url: ViewableFileUrl,
              readonly versionDeleted: boolean,
              readonly uploadDate: LocalDateTime,
              readonly fileUri: FileUri,
              public writeAuthorized: boolean,
              readonly flowInfo: Option<FileFlowInfo>,
              readonly flowTaskInfo: Option<FileTaskInfo>,
              readonly flowTaskFieldInfo: Option<FileTaskFieldInfo>,
              readonly matchingSignatureFile: Option<FileUri> = None(),
              readonly deleted: boolean) {}

  updateWriteAuthorized(writeAuthorized: boolean) {
    this.writeAuthorized = writeAuthorized;
  }

  injectMatchingSignatureFile(matchingSignatureFile: Option<FileUri>): ViewableFile {
    return new ViewableFile(this.name, this.size, this.fileVersion, this.url, this.versionDeleted, this.uploadDate, this.fileUri, this.writeAuthorized, this.flowInfo, this.flowTaskInfo, this.flowTaskFieldInfo, matchingSignatureFile, this.deleted);
  }

  injectVersion(version: Option<number>): ViewableFile {
    return new ViewableFile(this.name, this.size, version, this.url, this.versionDeleted, this.uploadDate, this.fileUri, this.writeAuthorized, this.flowInfo, this.flowTaskInfo, this.flowTaskFieldInfo, this.matchingSignatureFile, this.deleted);
  }

  injectUrl(url: ViewableFileUrl): ViewableFile {
    return new ViewableFile(this.name, this.size, this.fileVersion, url, this.versionDeleted, this.uploadDate, this.fileUri, this.writeAuthorized, this.flowInfo, this.flowTaskInfo, this.flowTaskFieldInfo, this.matchingSignatureFile, this.deleted);
  }

  urlWithVersion() {
    return this.url.url + this.fileVersion.map(v => ":"+v).getOrElse("");
  }
}

export class FileLockInfo {
  constructor(
    readonly locked: LocalDateTime,
    readonly locker: OrganizationNodeId
  ) {}

  static copy(other: FileLockInfo) {
    return new FileLockInfo(LocalDateTime.copy(other.locked), OrganizationNodeId.copy(other.locker));
  }
}
