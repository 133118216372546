const entityMap: {[character: string]: string} = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': '&quot;',
  "'": '&#39;',
  "/": '&#x2F;'
};

export function htmlEscape(html: string) {
  return String(html).replace(/[&<>"'\/]/g, (s: string) => entityMap[s]);
}

export function unescape(html: string) {
  const e = document.createElement('div');
  e.innerHTML = html;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function isHtmlContent(content: string): boolean {
  return /<[a-z][\s\S]*>/i.test(content);
}

export function setHtmlElementData(element: HTMLElement, name: string, value: any) {
  element.dataset[name] = value;
}

export function clearInnerHtml(element: HTMLElement) {
  element.innerHTML = '';
}

export function convertLinksToTags(html: string): string {
  const urlPattern = /((https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s<]*)?)/gi;

  return html.replace(urlPattern, (url) => {
    let href = url.startsWith('http') ? url : `http://${url}`;

    let lastChar = "";

    if(href.endsWith(".") || href.endsWith(",") || href.endsWith(")") || href.endsWith("]") || href.endsWith("}") || href.endsWith("\"") || href.endsWith("'") || href.endsWith(":") || href.endsWith(";") || href.endsWith("!") || href.endsWith("?") || href.endsWith("…") || href.endsWith(" ")) {
      lastChar = url[url.length - 1];
      url = url.substring(0, url.length - 1);
      href = href.substring(0, href.length - 1);
    }

    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`+lastChar;
  });
}

