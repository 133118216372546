import {i18n, LocalDate, Option} from "@utils";

export class CustomProcessInfo {
    constructor(readonly description: ProcessDescription,
                readonly measures: Array<ProcessMeasure>,
                readonly risks: Array<ProcessRisk>) {}

    static copy(other: CustomProcessInfo) {
      return new CustomProcessInfo(
        ProcessDescription.copy(other.description),
        other.measures.map(ProcessMeasure.copy),
        other.risks.map(ProcessRisk.copy))
    }
  }

  export class ProcessDescription {
    constructor(readonly target: string,
                readonly owner: string,
                readonly inputsProcesses: Array<string>,
                readonly outputsProcesses: Array<string>,
                readonly relatedProcesses: Array<string>,
                readonly documents: Array<ProcessDocument>,
                readonly tools: Array<ProcessTool>) {}

    static copy(other: ProcessDescription) {
      return new ProcessDescription(
        other.target,
        other.owner,
        other.inputsProcesses.slice(),
        other.outputsProcesses.slice(),
        other.relatedProcesses.slice(),
        other.documents.map(ProcessDocument.copy),
        other.tools.map(ProcessTool.copy))
    }
  }

  export class ProcessDocument {
    constructor(readonly documentType: string,
                readonly text: string) {}

    static copy(other: ProcessDocument) {
      return new ProcessDocument(
        other.documentType,
        other.text);
    }

    static empty() {
      return new ProcessDocument("","")
    }
  }

  export class ProcessTool {
    constructor(readonly name: string,
                readonly toolType: string,
                readonly domainAuthorization: string,
                readonly authorizationTypes: Array<string>,
                readonly supported: boolean,
                readonly personalDataRegistration: Option<string>) {}

    static copy(other: ProcessTool) {
      return new ProcessTool(
        other.name,
        other.toolType,
        other.domainAuthorization,
        other.authorizationTypes.slice(),
        other.supported,
        Option.copy(other.personalDataRegistration))
    }
  }

  export class ProcessMeasure {
    constructor(readonly name: string,
                readonly unit: string,
                readonly description: string,
                readonly valueChangesInterpretation: string,
                readonly dataSource: string,
                readonly areaOfData: Array<string>,
                readonly frequence: string,
                readonly reportDate: Option<LocalDate>,
                readonly baseDate: string,
                readonly baseValue: string,
                readonly expectedDate: string,
                readonly expectedValue: string,
                readonly baseValueDeviation: string) {}

    static copy(other: ProcessMeasure) {
      return new ProcessMeasure(
        other.name,
        other.unit,
        other.description,
        other.valueChangesInterpretation,
        other.dataSource,
        other.areaOfData,
        other.frequence,
        Option.copy(other.reportDate, LocalDate.copy),
        other.baseDate,
        other.baseValue,
        other.expectedDate,
        other.expectedValue,
        other.baseValueDeviation)
    }

  }

  export class ProcessRisk {
    constructor(readonly description: string,
                readonly controlMechanisms: Array<string>,
                readonly occurrenceProbability: number,
                readonly impactStrength: number,
                readonly occurrenceResponse: string,
                readonly occurrenceResponseDescription: string) {}

    static copy(other: ProcessRisk) {
      return new ProcessRisk(
        other.description,
        other.controlMechanisms.slice(),
        other.occurrenceProbability,
        other.impactStrength,
        other.occurrenceResponse,
        other.occurrenceResponseDescription)
    }

    static getLevelLabel(level: number): string {
      if(level == 0) {
        return "";
      } else if(level <= 2) {
        return i18n('designMap_customProcessInfo_risks_level_low');
      } else if(level <= 3) {
        return i18n('designMap_customProcessInfo_risks_level_medium');
      } else if(level <= 3.5) {
        return i18n('designMap_customProcessInfo_risks_level_high');
      } else if(level <= 5) {
        return i18n('designMap_customProcessInfo_risks_level_veryhigh');
      }

      throw new Error("Unexpected level value. Value should be integer in range [0,5]")
    }
  }


