import {RemoteOrganizationId} from "@utils";

export class BasicRemoteOrganizationInfo {
  constructor(readonly id: RemoteOrganizationId,
              readonly name: string,
              readonly deleted: boolean) {}

  static copy(other: BasicRemoteOrganizationInfo) {
    return new BasicRemoteOrganizationInfo(RemoteOrganizationId.of(other.id), other.name, other.deleted);
  }
}
