import {
  ArrayVariableType,
  BusinessVariableType,
  BusinessVariableTypeFactory,
  DateTimeVariableType,
  ExpressionWithAst,
  FileVariableType,
  NodeType, ProcessAnnotationId, ProcessEdgeId, ProcessNodeId,
  StringVariableType, VariablePath
} from "@shared-model";
import {
  __,
  AggregateId,
  AutomaticActionId,
  AutomaticActionRefId,
  Either,
  GridXY,
  I18nText, MultiTypeInput, MultiTypeInputFactory,
  None,
  Option, ProcessId, ProcessStart,
  ShiftXY, TextInputType,
  Typed
} from "@utils";
import {NodeProperties} from "./NodeProperties";
import {FormModel} from "./FormModel";
import {ApplicationComponentRef, FormSectionId} from "@shared";
import {ConditionProperties} from "./ConditionProperties";
import {NodeMetadata} from "./NodeMetadata";
import {AutomaticActionRef} from "@screen-common";

export class GridProcessAnnotation {
  constructor(
    readonly id: ProcessAnnotationId,
    readonly gridXY: GridXY,
    readonly gridShiftXY: ShiftXY,
    readonly text: I18nText,
    readonly nodes: Array<ProcessNodeId>,
    readonly edges: Array<ProcessEdgeId>) {}

  static copy(other: GridProcessAnnotation) {
    return new GridProcessAnnotation(
      other.id,
      GridXY.copy(other.gridXY),
      ShiftXY.copy(other.gridShiftXY),
      I18nText.copy(other.text),
      other.nodes.slice(),
      other.edges.slice(),
    )
  }
}

export class GridProcessNode {


  constructor(readonly id: ProcessNodeId,
              readonly identifier: Option<string>,
              readonly name: I18nText,
              readonly startLabel: I18nText,
              readonly description: I18nText,
              readonly instruction: I18nText,
              readonly nodeType: NodeType,
              readonly gridXY: GridXY,
              readonly properties: NodeProperties,
              readonly metadata: NodeMetadata,
              readonly form: FormModel,
              readonly conditionProperties: ConditionProperties,
              readonly automaticActions: AutomaticActions,
              readonly externalProcessProperties: ExternalProcessProperties,
              readonly endProperties: EndProperties,
              readonly startTriggers: StartTriggers) {

  }


  hasExternalProcessOutputMapping() {
    return this.nodeType.isExternal();
  }

  actionsCountSummary() {
    const count = this.automaticActions.before.length + this.automaticActions.after.length;
    if(count > 0) {
      return "(" + count+")";
    } else {
      return "";
    }
  }

  /** For proper object initialization, e.g. after JSON deserialization */
  static copy(other: GridProcessNode) {
    return new GridProcessNode(other.id,
      Option.copy(other.identifier),
      I18nText.copy(other.name),
      I18nText.copy(other.startLabel),
      I18nText.copy(other.description),
      I18nText.copy(other.instruction),
      NodeType.copy(other.nodeType, other.metadata.automatic),
      GridXY.copy(other.gridXY),
      NodeProperties.copy(other.properties),
      NodeMetadata.copy(other.metadata),
      FormModel.copy(other.form),
      ConditionProperties.copy(other.conditionProperties),
      AutomaticActions.copy(other.automaticActions),
      ExternalProcessProperties.copy(other.externalProcessProperties),
      EndProperties.copy(other.endProperties),
      StartTriggers.copy(other.startTriggers)
    );
  }
}

class StartTriggerInputField {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly valueType: Typed<BusinessVariableType>,
    readonly required: boolean
  ) {}

  static copy(other: StartTriggerInputField) {
    return new StartTriggerInputField(
      other.id,
      other.name,
      BusinessVariableTypeFactory.copyTyped(other.valueType),
      other.required);
  }

  valueTypeUnwrapped() {
    return Typed.value(this.valueType);
  }
}

class StartTriggerOutputField {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly valueType: Typed<BusinessVariableType>
  ) {}

  static copy(other: StartTriggerOutputField) {
    return new StartTriggerOutputField(
      other.id,
      other.name,
      BusinessVariableTypeFactory.copyTyped(other.valueType)
    );
  }

  valueTypeUnwrapped() {
    return Typed.value(this.valueType);
  }
}

export class StartTriggers {
  constructor(readonly emailTrigger: EmailStartTrigger,
              readonly casesSchedulerTrigger: CasesSchedulerStartTrigger,
              readonly otherProcessTrigger: OtherProcessTrigger,
              readonly outOfPlatformTrigger: OutOfPlatformTrigger,
              readonly apiTrigger: ApiTrigger,
              readonly input: Array<StartTriggerInputField>,
              readonly output: Array<StartTriggerOutputField>) {
  }

  static copy(other: StartTriggers) {
    return new StartTriggers(
      EmailStartTrigger.copy(other.emailTrigger),
      CasesSchedulerStartTrigger.copy(other.casesSchedulerTrigger),
      OtherProcessTrigger.copy(other.otherProcessTrigger),
      OutOfPlatformTrigger.copy(other.outOfPlatformTrigger),
      ApiTrigger.copy(other.apiTrigger),
      other.input.map(StartTriggerInputField.copy),
      other.output.map(StartTriggerOutputField.copy));
  }

  static empty() {
    return new StartTriggers(
      EmailStartTrigger.empty(),
      CasesSchedulerStartTrigger.empty(),
      OtherProcessTrigger.empty(),
      OutOfPlatformTrigger.empty(),
      ApiTrigger.empty(),
      [],
      []);
  }
}


export class OtherProcessTrigger {

  constructor(readonly enabled: boolean,
              readonly serviceId: Option<number>,
              readonly externalizedFields: Array<string>) {}

  static copy(other: OtherProcessTrigger) {
    return new OtherProcessTrigger(other.enabled, Option.copy(other.serviceId), other.externalizedFields.slice());
  }

  static empty() {
    return new OtherProcessTrigger(false, None(), []);
  }
}

export class EmailStartTrigger  {



  constructor(readonly enabled: boolean,
              readonly mappings: Array<EmailElementMapping>,
              readonly headerMappings: Array<EmailHeaderMapping>) {}

  static copy(other: EmailStartTrigger) {
    return new EmailStartTrigger(other.enabled, other.mappings.map(EmailElementMapping.copy), other.headerMappings.map(EmailHeaderMapping.copy));
  }

  static empty() {
    return new EmailStartTrigger(false, [], []);
  }

  getMapping(mailElementName: string) {
    return __(this.mappings).find(mapping => mapping.mailElementName === mailElementName).map(e => e.variableName.getOrElse("")).getOrElse("");
  }
}

export class CasesSchedulerStartTrigger  {
  constructor(readonly enabled: boolean) {}

  static copy(other: CasesSchedulerStartTrigger) {
    return new CasesSchedulerStartTrigger(other.enabled);
  }

  static empty() {
    return new CasesSchedulerStartTrigger(false);
  }
}

export class OutOfPlatformTrigger  {
  constructor(readonly enabled: boolean) {}

  static copy(other: OutOfPlatformTrigger) {
    return new OutOfPlatformTrigger(other.enabled);
  }

  static empty() {
    return new OutOfPlatformTrigger(false);
  }
}

export class ApiTrigger  {
  constructor(readonly enabled: boolean) {}

  static copy(other: ApiTrigger) {
    return new ApiTrigger(other.enabled);
  }

  static empty() {
    return new ApiTrigger(false);
  }
}

export class EmailElementType {
  constructor(readonly mailElementName: string,
              readonly variableType: BusinessVariableType) {}

  getVariableTypeCopy(): Typed<BusinessVariableType> {
    return Typed.of(BusinessVariableTypeFactory.copy(this.variableType));
  }

  static subject = new EmailElementType("subject", new StringVariableType());
  static body = new EmailElementType("body", new StringVariableType());
  static from = new EmailElementType("from", new StringVariableType());
  static recipients = new EmailElementType("recipients", new ArrayVariableType(Typed.of(new StringVariableType())));
  static sentDate = new EmailElementType("sentDate", new DateTimeVariableType());
  static receivedDate = new EmailElementType("receivedDate", new DateTimeVariableType());
  static replyTo = new EmailElementType("replyTo", new StringVariableType());
  static attachments = new EmailElementType("attachments", new ArrayVariableType(Typed.of(new FileVariableType())));
  static wholeEmail = new EmailElementType("wholeEmail", new ArrayVariableType(Typed.of(new FileVariableType())));
  static emailEmail = new EmailElementType("emailEmail", new ArrayVariableType(Typed.of(new FileVariableType())));
  static flowId = new EmailElementType("flowId", new ArrayVariableType(Typed.of(new FileVariableType())));
  static headers = new EmailElementType("headers", new ArrayVariableType(Typed.of(new StringVariableType())));
}

export class EmailElementMapping {
  constructor(readonly mailElementName: string,
              readonly variableName: Option<string>) {}

  static copy(other: EmailElementMapping): EmailElementMapping {
    return new EmailElementMapping(other.mailElementName,
      Option.copy(other.variableName))
  }

  static subjectMapping = () => new EmailElementMapping(EmailElementType.subject.mailElementName, None());
  static bodyMapping = () => new EmailElementMapping(EmailElementType.body.mailElementName, None());
  static fromMapping = () => new EmailElementMapping(EmailElementType.from.mailElementName, None());
  static recipientsMapping = () => new EmailElementMapping(EmailElementType.recipients.mailElementName, None());
  static sentDateMapping = () => new EmailElementMapping(EmailElementType.sentDate.mailElementName, None());
  static receivedDateMapping = () => new EmailElementMapping(EmailElementType.receivedDate.mailElementName, None());
  static replyToMapping = () => new EmailElementMapping(EmailElementType.replyTo.mailElementName, None());
  static attachmentsMapping = () => new EmailElementMapping(EmailElementType.attachments.mailElementName, None());
  static wholeEmailMapping = () => new EmailElementMapping(EmailElementType.wholeEmail.mailElementName, None());
  static emailEmailMapping = () => new EmailElementMapping(EmailElementType.emailEmail.mailElementName, None());
  static emailFlowIdMapping = () => new EmailElementMapping(EmailElementType.flowId.mailElementName, None());

  static mappings = [EmailElementMapping.subjectMapping(),
    EmailElementMapping.bodyMapping,
    EmailElementMapping.fromMapping,
    EmailElementMapping.recipientsMapping,
    EmailElementMapping.sentDateMapping,
    EmailElementMapping.receivedDateMapping,
    EmailElementMapping.replyToMapping,
    EmailElementMapping.attachmentsMapping,
    EmailElementMapping.wholeEmailMapping,
    EmailElementMapping.emailEmailMapping,
    EmailElementMapping.emailFlowIdMapping];
}

export class EmailHeaderMapping {
  constructor(readonly headerName: string,
              readonly variableName: Option<string>) {}

  static copy(other: EmailHeaderMapping): EmailHeaderMapping {
    return new EmailHeaderMapping(other.headerName, Option.copy(other.variableName));
  }
}

export class AutomaticActions {
  constructor(readonly before: Array<AutomaticActionRef>,
              readonly after: Array<AutomaticActionRef>) {}

  static newEmpty() {
    return new AutomaticActions([], []);
  }

  static copy(other: AutomaticActions): AutomaticActions {
    return new AutomaticActions(other.before.map(AutomaticActionRef.copy), other.after.map(AutomaticActionRef.copy));
  }
}

export class ErrorHandlingType {
  private constructor(readonly name: string) {}

  static throwError = new ErrorHandlingType("throwError");
  static finishGracefully = new ErrorHandlingType("finishGracefully");

  static copy(other: ErrorHandlingType) {
    switch (other.name) {
      case "throwError":
        return ErrorHandlingType.throwError;
      case "finishGracefully":
        return ErrorHandlingType.finishGracefully;
      default:
        throw new Error("Unsupported error handling type type: " + other.name);
    }
  }
}

export class ProcessServiceId {

  constructor(readonly processId: AggregateId,
              readonly serviceId: number) {}

  static copy(other: ProcessServiceId) {
    return new ProcessServiceId(other.processId, other.serviceId);
  }

  isEqual(other: ProcessServiceId) {
    return other.serviceId === this.serviceId && other.processId.id === this.processId.id;
  }
}

export class InputMappingV1 {
  constructor(readonly id: number,
              readonly name: string,
              readonly value: Typed<MultiTypeInput>) {}

  static copy(other: InputMappingV1): InputMappingV1 {
    return new InputMappingV1(other.id, other.name, MultiTypeInputFactory.copyTyped(other.value));
  }

  valueUnwrapped() {
    return Typed.value(this.value);
  }
}

export class OutputMappingV1 {
  constructor(readonly id: number,
              readonly externalVariableName: string,
              readonly variableName: Option<string>) {}

  static copy(other: OutputMappingV1) {
    return new OutputMappingV1(other.id, other.externalVariableName, Option.copy(other.variableName));
  }
}



export class ExternalProcessProperties {

  constructor(readonly processServiceId: Option<ProcessServiceId>, // deprecated
              readonly processStart: Option<ProcessStart>,
              readonly identifiersMode: boolean,
              readonly applicationIdentifier: Typed<MultiTypeInput>,
              readonly processInstanceIdentifier: Typed<MultiTypeInput>,
              readonly startNodeIdentifier: Typed<MultiTypeInput>,
              readonly errorHandling: ErrorHandlingType,
              readonly killOnTerminate: boolean,
              readonly supportedInterfaces: Array<number>,
              readonly supportedReleases: Array<AggregateId>,
              readonly inputMappings: Array<InputMappingV1>,
              readonly outputMappings: Array<OutputMappingV1>,
              readonly result: Option<string>) {}

  applicationIdentifierUnwrapped() {
    return Typed.value(this.applicationIdentifier);
  }

  processInstanceIdentifierUnwrapped() {
    return Typed.value(this.processInstanceIdentifier);
  }

  startNodeIdentifierUnwrapped() {
    return Typed.value(this.startNodeIdentifier);
  }

  static newEmpty() {
    return new ExternalProcessProperties(None(), None(), false, Typed.of(TextInputType.empty()), Typed.of(TextInputType.empty()), Typed.of(TextInputType.empty()),
      ErrorHandlingType.throwError, false, [], [], [], [], None());
  }

  static copy(other: ExternalProcessProperties): ExternalProcessProperties {
    return new ExternalProcessProperties(
      Option.copy(other.processServiceId, ProcessServiceId.copy),
      Option.copy(other.processStart, ProcessStart.copy),
      other.identifiersMode,
      MultiTypeInputFactory.copyTyped(other.applicationIdentifier),
      MultiTypeInputFactory.copyTyped(other.processInstanceIdentifier),
      MultiTypeInputFactory.copyTyped(other.startNodeIdentifier),
      ErrorHandlingType.copy(other.errorHandling),
      other.killOnTerminate,
      other.supportedInterfaces.slice(),
      other.supportedReleases.slice(),
      other.inputMappings.map(InputMappingV1.copy),
      other.outputMappings.map(OutputMappingV1.copy),
      Option.copy(other.result)
    );
  }
}

export class EndProperties {
  constructor(readonly terminate: boolean) {}

  static empty() {
    return new EndProperties(false);
  }

  static copy(other: EndProperties) {
    return new EndProperties(other.terminate);
  }
}
