import {__, AggregateId, ApplicationId, None, Option, OrganizationNodeId, PersonId, Some, toastr} from "@utils";
import {BasicOrganizationNodeInfo, OrganizationSharedService} from "@shared-model";

export class OrganizationNodeSelectViewModel {

  horizontal: boolean = false;
  singleMode: boolean = false;
  selectMode: boolean = false;


  private _inAllApplications: boolean = false;
  restrictedNodes: Array<AggregateId> = [];


  nodeInfo: BasicOrganizationNodeInfo|null = null;

  searchBoxVisible: boolean = false;
  excludeNodes: Array<OrganizationNodeId> = [];

  constructor(public node: OrganizationNodeId|null,
              readonly organizationStructureQueryService: OrganizationSharedService,
              public readOnly: boolean,
              public preview: boolean,
              readonly persons: boolean,
              readonly includeDeletedNodes: boolean,
              readonly departments: boolean,
              readonly groups: boolean,
              readonly processes: boolean,
              readonly reports: boolean,
              readonly applications: boolean,
              readonly screens: boolean,
              readonly functions: boolean,
              readonly anonymous: boolean,
              private _applicationId: ApplicationId|null,
              inAllApplications: boolean,
              readonly fromGlobal: boolean,
              excludeNodes: Array<OrganizationNodeId>,
              readonly focusMainButton: () => void,
              readonly onNodeChanged: ((node: Option<OrganizationNodeId>) => void)) {
    this.excludeNodes = excludeNodes;
    this._inAllApplications = inAllApplications;
    this.updateNodeInfo();
  }


  get inAllApplications(): boolean {
    return this._inAllApplications;
  }

  setInAllApplications(inAllApplications: boolean) {
    this._inAllApplications = inAllApplications;
  }

  get applicationId(): ApplicationId|null {
    return this._applicationId;
  }

  setApplicationId(applicationId: ApplicationId|null) {
    this._applicationId = applicationId;
  }

  setPreview(preview: boolean) {
    this.preview = preview;
  }

  setReadOnly(readOnly: boolean) {
    this.readOnly = readOnly;
  }

  openSearchBox() {
    this.searchBoxVisible = true;
  }

  closeSearchBox() {
    this.searchBoxVisible = false;
    this.focusMainButton();
  }

  toggleSearchBox() {
    if(!this.readOnly) {
      this.searchBoxVisible ? this.closeSearchBox() : this.openSearchBox();
    }
  }

  onNodeSelected = (node: BasicOrganizationNodeInfo) => {

    this.nodeInfo = node;
    this.node = node.organizationNodeId;
    this.searchBoxVisible = false;

    this.onNodeChanged(Some(node.organizationNodeId));
  };

  removeNode = () => {
    this.node = null;
    this.nodeInfo = null;
    this.searchBoxVisible = false;
    this.onNodeChanged(None());
  };

  updateNodeInfo() {

    if(this.node) {

      if(this.node.id.id === PersonId.ANONYMOUS.id.id) {
        this.nodeInfo = BasicOrganizationNodeInfo.createAnonymous();
      } else {
        this.organizationStructureQueryService.findNodesBasicInfoByIdsForApplication([this.node], Option.of(this.applicationId), (nodes: Array<BasicOrganizationNodeInfo>) => {
          if(nodes.length == 1) {
            this.nodeInfo = nodes[0];
          } else {
            toastr.error("Cannot get organization node info");
          }
        });
      }

    } else {
      this.nodeInfo = null;
    }

  }

  nodeToNodeInfo(node: OrganizationNodeId, nodes: Array<BasicOrganizationNodeInfo>) {
    return __(nodes).find(n => n.organizationNodeId.id.id === node.id.id && n.organizationNodeId.nodeType.name === node.nodeType.name).getOrElse(BasicOrganizationNodeInfo.deletedInfo(node));
  }

  setNode(n: Option<OrganizationNodeId>) {
    this.node = n.getOrNull();
    this.updateNodeInfo();
  }

  setExcludeNodes(excludeNodes: Array<OrganizationNodeId>) {
    this.excludeNodes = excludeNodes;
  }
}
