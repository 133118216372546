import {
  AdHocTaskHelper,
  ApplicationIcon, ApplicationIcons,
  BusinessVariable,
  BusinessVariableType,
  CursorInfo, DepartmentVariable, FlowCursorId,
  FlowImportance,
  FormElementSummaryHelper,
  GroupVariable, NodeType, PersonVariable, ProcessEdgeId, ProcessNodeId,
  RootVariableWithType,
  TaskIdentifier,
  TaskModelSummary,
  TaskPlan,
  TasksStatusManager,
  TaskStatus,
  TaskTimeStatus
} from "@shared-model";
import {
  __, __Array,
  AnyFlowId,
  AnyFlowIdHelper,
  AnyPersonId, AnyPersonIdHelper,
  ApplicationId, arraysEqual, arraysEqualBy, clearArray, colorId,
  i18n,
  InstanceId,
  LocalDateTime, mySetTimeout, None,
  Option, OrganizationNodeId,
  PersonId,
  ProcessId, removeFromArrayBy, required, Some, toastr,
  Typed
} from "@utils";
import {Constants} from "@utils";


interface FlowLabel {
  name: string;
  color: number;
}

export class SubmitTaskSummaryButtonViewModel {
  constructor(readonly name: string,
              readonly edgeId: ProcessEdgeId) {}
}

export class TaskSummaryPullActionViewModel {
  constructor(readonly edgeId: ProcessEdgeId,
              readonly name: string,
              readonly cursorId: FlowCursorId) {}
}

export class TaskSummaryBusinessField {
  isOther: boolean = false;
  organizationNode: OrganizationNodeId|undefined = undefined;
  constructor(readonly name: string,
              readonly valueString: string|undefined,
              readonly value: BusinessVariable|undefined, readonly tpe: BusinessVariableType) {
    if(value instanceof PersonVariable) {
      this.organizationNode = OrganizationNodeId.fromPersonId(value.value);
    }
    if(value instanceof GroupVariable) {
      this.organizationNode = OrganizationNodeId.fromGroupId(value.value);
    }
    if(value instanceof DepartmentVariable) {
      this.organizationNode = OrganizationNodeId.fromDepartmentId(value.value);
    }
    this.isOther = this.organizationNode === undefined;
  }
}

export interface TasksCounts {
  count: number;
  cssClass: "theme-ok" | "theme-warning" | "theme-error";
}

export class TaskWarnings {
  static calculateCountsInPlace(warnings: Array<TasksCounts>, tasks: __Array<TaskSummaryViewModel>) {
    // counts only warnings and errors
    clearArray(warnings);
    const atRisk = tasks.count(t => t.isAtRisk);
    const atHighRisk = tasks.count(t => t.isAtHighRisk);
    const delayed = tasks.count(t => t.isDelayed);

    if(delayed > 0) {
      warnings.push({cssClass: "theme-error", count: delayed});
    }
    if(atRisk + atHighRisk > 0) {
      warnings.push({cssClass: "theme-warning", count: atRisk + atHighRisk});
    }

  }
}

export class TaskSummaryViewModel {

  static JUST_ADDED_TIMESPAN = 2000;
  static JUST_REMOVED_TIMESPAN = 500;
  static JUST_UPDATED_TIMESPAN = 1000;

  private static TASK_REALISATION_NODE_ID = 2;

  taskRouterLinkSuffix: string = "";
  applicationIcon: ApplicationIcon = ApplicationIcons.empty;

  isFinishedOnTime: boolean = false;
  isFinishedLate: boolean = false;

  isInProgressOnTime = false;
  isDelayed: boolean = false;
  isAtRisk: boolean = false;
  isAtHighRisk: boolean = false;


  isCompleted: boolean = false;

  cannotChangeStatus: boolean = false;

  riskSortValue: number = 0;


  availableStatuses: Array<TaskStatus> = [];
  availableAdditionalStatuses: Array<TaskStatus> = [];

  applicationName: string = "";

  description: string | undefined = "";

  labels: Array<FlowLabel> = [];

  importanceTooltip: string = "";
  statusName: string = "";
  importance: FlowImportance = FlowImportance.normal;

  addedTimestamp: number = 0;

  justAdded: boolean = false;
  removedTimestamp: number = 0;
  justUpdated: boolean = false;

  plan: TaskPlan = TaskPlan.remaining;
  moveTodayVisible: boolean = false;
  moveTomorrowUpVisible: boolean = false;
  moveTomorrowDownVisible: boolean = false;
  moveRemainingVisible: boolean = false;
  addTagVisible: boolean = false;
  addCommentVisible: boolean = false;
  idClass: string;

  planIndex: number = -1; // used for sorting, might be a fraction
  canCompleteOrUncomplete: boolean = false;
  onScreen: boolean = false;
  notStarted: boolean;
  completedMillis: number;
  flowRouterLinkSuffix: any;

  delayUpdateUntil: number = 0;
  postponedUpdateModels: Array<TaskModelSummary> = [];
  postponedUpdateTimeout: number | undefined = undefined;

  constructor(
    readonly currentPerson: PersonId,
    private readonly tasksStatusManager: TasksStatusManager,
    public flowId: AnyFlowId,
    public applicationId: Option<ApplicationId>,
    public processId: ProcessId,
    public instanceId: InstanceId,
    public nodeId: ProcessNodeId,
    public roleId: number,
    public created: LocalDateTime,
    public started: Option<LocalDateTime>,
    public completed: Option<LocalDateTime>,
    public canceled: boolean,
    public deadline: Option<LocalDateTime>,
    labels: Array<string>,
    public systemLabels: Array<BusinessVariable>,
    public seen: boolean,
    public flowCode: string,
    public flowDescription: string,
    public nodeName: string,
    public processName: string,
    public showProcessNameOnly: boolean,
    public initiatedBy: Array<AnyPersonId>,
    public personsAssigned: Array<AnyPersonId>,
    public systemImportance: FlowImportance,
    public importanceOverride: Option<FlowImportance>,
    public commentsCount: number,
    public createdMillis: number,
    public createdNano: number,
    public deadlineMillis: number, // or Number.MAX_SAFE_INTEGER
    public assignableToMe: boolean,
    public canAssignOther: boolean,
    public status: TaskStatus,
    public searchSummary: string,
    readonly nodeType: NodeType,
    readonly canChangeImportance: boolean,
    readonly canChangeLabels: boolean,
    readonly cursorInfo: Option<CursorInfo>,
    public businessFields: Array<TaskSummaryBusinessField>,
    public working: boolean,
    public pullActions: Array<TaskSummaryPullActionViewModel>,
    readonly screenBased: boolean,
    readonly quickSubmitAllowed: boolean,
    readonly assigneeLimit: number,
    readonly deadlineEditable: boolean,
    readonly returned: boolean,
    readonly adHocTask: boolean,
    readonly submitButtons: Array<SubmitTaskSummaryButtonViewModel>,
  ) {

    this.idClass = "task-id-" + flowId.urlSerialized() + "-" + nodeId;

    this.notStarted = this.flowCode.length === 0;

    this.completedMillis = completed.map(c => c.asMillis()).getOrElse(0);

    this.initLabels(labels);
    this.init();

  }

  private initLabels(labels: Array<string>) {
    this.labels = labels.map(l => {
      return {name: l, color: colorId(l)};
    });

  }

  private init() {
    this.importance = this.importanceOverride.getOrElse(this.systemImportance);

    this.taskRouterLinkSuffix = "@" + this.flowId.urlSerialized() + "/" + this.nodeId;

    this.importanceTooltip = i18n("task_importance_" + this.importance) + (this.canChangeImportance ? "" : " (" + i18n("task_priority_change_not_allowed") + ")");
    this.statusName = i18n("task_status_" + this.status.name);

    this.isCompleted = this.completed.isDefined();
    this.completedMillis = this.completed.map(c => c.asMillis()).getOrElse(0);

    this.isFinishedOnTime = this.completed.isDefined() && (!this.deadline.isDefined() || this.deadline.get().isAfter(this.completed.get()));
    this.isFinishedLate = this.completed.isDefined() && this.deadline.isDefined() && this.deadline.get().isBefore(this.completed.get());

    this.isInProgressOnTime = this.completed.isEmpty() && this.deadline.isDefined() &&
      (this.tasksStatusManager.statusOf(this.toTaskIdentifier()) === TaskTimeStatus.onTime);
    this.isDelayed = this.completed.isEmpty() && this.tasksStatusManager.statusOf(this.toTaskIdentifier()) === TaskTimeStatus.delayed;
    this.isAtRisk = this.completed.isEmpty() && this.tasksStatusManager.statusOf(this.toTaskIdentifier()) === TaskTimeStatus.atRisk;
    this.isAtHighRisk = this.completed.isEmpty() && this.tasksStatusManager.statusOf(this.toTaskIdentifier()) === TaskTimeStatus.atHighRisk;


    if (this.isAtRisk) {
      this.riskSortValue = 2 + this.importance * 1.1;
    } else if (this.isAtHighRisk) {
      this.riskSortValue = 4 + this.importance * 1.1;
    } else if (this.isDelayed) {
      this.riskSortValue = 6 + this.importance * 1.1;
    } else if (this.isFinishedLate) {
      this.riskSortValue = 2;
    } else {
      this.riskSortValue = this.importance * 1.1;
    }

    this.availableStatuses = [TaskStatus.todo, TaskStatus.inProgress, TaskStatus.completed];
    this.availableAdditionalStatuses = [TaskStatus.waiting];

    this.description = this.flowDescription.length > 0 ? this.flowDescription : (this.businessFields.length > 0 ? undefined : this.flowCode);

    this.canCompleteOrUncomplete = this.quickSubmitAllowed && this.assignableToMe && __(this.personsAssigned).exists(t => t.equals(this.currentPerson));

    const canChangeStatus = this.completed.isEmpty() && this.personsAssigned.some(p => p.equals(this.currentPerson)) ||
      this.completed.isDefined() && this.pullActions.length === 1;

    this.cannotChangeStatus = !canChangeStatus;

    this.updateVisibilityFlags();
  }

  getPlainLabels(): Array<string> {
    return this.labels.map(l => l.name);
  }

  private updateVisibilityFlags() {
    this.moveTodayVisible = this.plan !== TaskPlan.today;
    this.moveTomorrowUpVisible = this.plan === TaskPlan.remaining;
    this.moveTomorrowDownVisible = this.plan === TaskPlan.today;
    this.moveRemainingVisible = this.plan !== TaskPlan.remaining;
    this.addCommentVisible = true;
    this.addTagVisible = this.labels.length < 3 && this.canChangeLabels;
  }

  static of(t: TaskModelSummary, tasksStatusManager: TasksStatusManager, adHocTaskHelper: AdHocTaskHelper, currentPerson: PersonId): TaskSummaryViewModel {
    const processName = i18n(t.processName);
    const nodeName = i18n(t.nodeName);

    let showProcessNameOnly = false;

    if (t.processId.id === Constants.taskProcessId.id.id && t.nodeId === TaskSummaryViewModel.TASK_REALISATION_NODE_ID) {
      showProcessNameOnly = true;
    }

    const processId = ProcessId.of(t.processId);
    return new TaskSummaryViewModel(
      currentPerson,
      tasksStatusManager,
      Typed.value(t.flowId),
      t.applicationId,
      processId,
      InstanceId.of(t.instanceId),
      t.nodeId,
      t.roleId,
      t.created,
      t.started,
      t.completed,
      t.canceled,
      t.deadline,
      t.labels,
      t.systemLabelsUnwrapped(),
      t.seen,
      t.flowCode.getOrElse(""),
      t.flowDescription,
      nodeName,
      processName,
      showProcessNameOnly,
      t.initialPersonsUnwrapped(),
      t.personsAssignedUnwrapped(),
      t.importance,
      t.importanceOverride,
      t.commentsCount,
      t.created.asMillis(),
      t.created.time.nano,
      t.deadline.map(d => d.asMillis()).getOrElse(Number.MAX_SAFE_INTEGER),
      t.assignableToMe,
      t.canAssignOther,
      t.taskStatus,
      t.searchSummary,
      t.nodeType,
      t.canChangeImportance,
      t.canChangeLabels,
      t.cursorInfo,
      t.formElementsVisibleInTaskBox.map(e => {
        const copied = RootVariableWithType.copy(e.variable);
        return new TaskSummaryBusinessField(
          FormElementSummaryHelper.extractFieldName(e),
          copied.unwrappedVariableOption().map(v => v.valueToSimpleString()).getOrUndefined(),
          copied.unwrappedVariableOption().getOrUndefined(),
          copied.unwrappedVariableType());
      }),
      t.working,
      t.pullActions.map(a => new TaskSummaryPullActionViewModel(a.edgeId, a.edgeName.getOrElse(a.toNodeName).getCurrentWithFallback(), a.cursorId)),
      t.screenBased,
      t.quickSubmitAllowed && t.mainEdges.length === 1,
      t.assigneeLimit,
      t.deadlineEditable,
      t.flowContinued,
      t.processId.id === Constants.taskProcessId.id.id,
      t.mainEdges.map(e => new SubmitTaskSummaryButtonViewModel(e.edgeName.map(e => e.getCurrentWithFallback()).getOrElse(""), e.edgeId))
    );

  }

  setImportance(importance: FlowImportance) {
    this.importance = importance;
    this.importanceTooltip = i18n("task_importance_" + this.importance);
  }

  toTaskIdentifier() {
    return TaskIdentifier.of(this.flowId, this.nodeId);
  }

  setJustAdded() {
    this.addedTimestamp = Date.now();
    this.justAdded = true;
    return this;
  }

  updateJustAdded(now: number) {
    this.justAdded = this.addedTimestamp > 0 && now - this.addedTimestamp < TaskSummaryViewModel.JUST_ADDED_TIMESPAN;
  }

  setRemoved() {
    this.removedTimestamp = Date.now();
    return this;
  }


  update(adHocTaskHelper: AdHocTaskHelper, task: TaskModelSummary) {
    this.updateInternal(task, adHocTaskHelper);
    this.delayUpdateUntil = Date.now() + TaskSummaryViewModel.JUST_UPDATED_TIMESPAN;
  }

  updateInternal(task: TaskModelSummary, adHocTaskHelper: AdHocTaskHelper) {


    if(this.delayUpdateUntil > Date.now()) {

      this.postponedUpdateModels.push(task);

      if(this.postponedUpdateTimeout === undefined) {
        this.postponedUpdateTimeout = mySetTimeout(() => {
          this.delayUpdateUntil = 0;
          this.postponedUpdateTimeout = undefined;
          this.postponedUpdateModels.forEach(m => this.updateInternal(m, adHocTaskHelper));
          this.postponedUpdateModels = [];
        }, this.delayUpdateUntil - Date.now());
      }

    } else {

      let showOnlyProcessName = false;
      if (task.processId.id === Constants.taskProcessId.id.id && task.nodeId === TaskSummaryViewModel.TASK_REALISATION_NODE_ID) {
        showOnlyProcessName = true;
      }

      this.flowId = task.flowIdUnwrapped();
      this.applicationId = task.applicationId;
      this.processId = ProcessId.of(task.processId);
      this.instanceId = InstanceId.of(task.instanceId);
      this.nodeId = task.nodeId;
      this.roleId = task.roleId;
      this.created = task.created;
      this.started = task.started;
      this.completed = task.completed;
      this.isCompleted = task.completed.isDefined();
      this.canceled = task.canceled;
      this.deadline = task.deadline;
      this.seen = task.seen;
      this.flowCode = task.flowCode.getOrElse("");
      this.flowDescription = task.flowDescription;
      this.processName = i18n(task.processName);
      this.nodeName = i18n(task.nodeName);
      this.showProcessNameOnly = showOnlyProcessName;
      this.businessFields = task.formElementsVisibleInTaskBox.map(e => {
        const copied = RootVariableWithType.copy(e.variable);
        return new TaskSummaryBusinessField(
          FormElementSummaryHelper.extractFieldName(e),
          copied.unwrappedVariableOption().map(v => v.valueToSimpleString()).getOrUndefined(),
          copied.unwrappedVariableOption().getOrUndefined(),
          copied.unwrappedVariableType());
      });
      this.pullActions = task.pullActions.map(a => new TaskSummaryPullActionViewModel(a.edgeId, a.edgeName.getOrElse(a.toNodeName).getCurrentWithFallback(), a.cursorId));
      this.working = task.working;

      this.personsAssigned = task.personsAssigned.map(p => Typed.value(p));
      this.systemImportance = task.importance;
      this.importanceOverride = task.importanceOverride;
      this.commentsCount = task.commentsCount;
      this.createdMillis = task.created.asMillis();
      this.createdNano = task.created.time.nano;
      this.deadlineMillis = task.deadline.map(d => d.asMillis()).getOrElse(Number.MAX_SAFE_INTEGER);
      this.status = task.taskStatus;
      this.statusName = i18n("task_status_" + this.status.name);
      this.searchSummary = task.searchSummary;

      this.initLabels(task.labels);
      this.init();
      this.justUpdated = true;

      this.notStarted = this.flowCode.length === 0;

    }
  }

  setPlan(tomorrow: TaskPlan, planIndex: number) {
    this.planIndex = planIndex;
    this.plan = tomorrow;
    this.updateVisibilityFlags();
  }

  is(other: TaskIdentifier) {
    return AnyFlowIdHelper.equals(this.flowId, other.flowIdUnwrapped()) && this.nodeId === other.nodeId;
  }

  hasPersonAssigned(currentPersonId: PersonId) {
    return __(this.personsAssigned).exists(p => p.equals(currentPersonId));
  }

  setPersonsAssigned(personIds: Array<AnyPersonId>) {
    this.personsAssigned = personIds;
    this.init();
  }

  hasTimeStatus(timeStatus: TaskTimeStatus) {
    switch (timeStatus) {
      case TaskTimeStatus.onTime: return this.isFinishedOnTime || (!this.isDelayed && !this.isAtRisk && !this.isAtHighRisk);
      case TaskTimeStatus.delayed: return this.isFinishedLate || this.isDelayed;
      case TaskTimeStatus.atRisk: return this.isAtRisk;
      case TaskTimeStatus.atHighRisk: return this.isAtHighRisk;
      default: throw new Error("Unknown time status: '" + timeStatus.name+"'");
    }
  }

  changeStatusToCompleted() {
    if(this.started.isEmpty()) {
      this.started = Some(LocalDateTime.now());
    }

    this.completed = Some(LocalDateTime.now());
    this.isCompleted = true;
    this.status = TaskStatus.completed;
    this.statusName = i18n("task_status_" + this.status.name);

    this.delayUpdateUntil = Date.now() + TaskSummaryViewModel.JUST_UPDATED_TIMESPAN;
  }

  changeStatusToInProgress() {
    this.completed = None();
    this.started = Some(LocalDateTime.now());
    this.isCompleted = false;
    this.completeStatusChangeTo(TaskStatus.inProgress);
  }

  changeStatusToTodo() {

    this.completed = None();
    this.started = None();
    this.isCompleted = false;
    this.completeStatusChangeTo(TaskStatus.todo);
  }

  changeStatusToWaiting() {
    this.completed = None();
    this.started = None();
    this.isCompleted = false;
    this.completeStatusChangeTo(TaskStatus.waiting);
  }

  private completeStatusChangeTo(status: TaskStatus) {
    this.status = status;
    this.statusName = i18n("task_status_" + this.status.name);
    this.delayUpdateUntil = Date.now() + TaskSummaryViewModel.JUST_UPDATED_TIMESPAN;

  }

  equals(summary: TaskModelSummary) {
    let equals = true;

    equals = equals && this.completed.equals(summary.completed, (a, b) => a.isEqual(b));
    equals = equals && this.canceled === summary.canceled;
    equals = equals && this.deadline.equals(summary.deadline, (a, b) => a.isEqual(b));
    equals = equals && AnyFlowIdHelper.equals(this.flowId, summary.flowIdUnwrapped());
    equals = equals && this.flowCode === summary.flowCode.getOrElse("");
    equals = equals && this.flowDescription === summary.flowDescription;
    equals = equals && arraysEqualBy(this.personsAssigned, summary.personsAssignedUnwrapped(), (a, b) => AnyPersonIdHelper.equals(a, b));
    equals = equals && this.systemImportance === summary.importance;
    equals = equals && this.importanceOverride.equals(summary.importanceOverride);
    equals = equals && this.commentsCount === summary.commentsCount;
    equals = equals && this.created.isEqual(summary.created);
    equals = equals && this.started.equals(summary.started, (a, b) => a.isEqual(b));
    equals = equals && this.deadline.equals(summary.deadline, (a, b) => a.isEqual(b));
    equals = equals && this.status === summary.taskStatus;
    equals = equals && this.searchSummary === summary.searchSummary;
    equals = equals && arraysEqual(this.labels.map(l => l.name), summary.labels);
    equals = equals && arraysEqualBy(this.systemLabels, summary.systemLabelsUnwrapped(), (a, b) => a.isEqual(b));
    equals = equals && arraysEqual(summary.pullActions.map(a => a.edgeId), this.pullActions.map(a => a.edgeId));

    return equals;

  }

  isTaskProcess() {
    return this.processId.id.id === Constants.taskProcessId.id.id;
  }

  getSingleMainEdgeId(onSuccess: (edgeId: number) => void): void {
    if(this.submitButtons.length === 1) {
      onSuccess(required(this.submitButtons[0], "button").edgeId);
    } else if(this.submitButtons.length === 0) {
      toastr.info("Task has no main buttons, cannot submit");
    } else {
      toastr.info("Task has alternative main buttons, cannot submit");
    }
  }

  addLabel(label: string) {
    this.labels.push({name: label, color: colorId(label)});
  }

  removeLabel(label: string) {
    removeFromArrayBy(this.labels, l => l.name === label);
  }
}
