import {handleError, i18n, restUrl} from "@utils";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PasswordPolicy} from "./password-validator.model";

@Injectable({
  providedIn: 'root',
})
export class PasswordValidatorService {

  private passwordPolicy: PasswordPolicy = null!;

  private blankCharacters = /\s/g;
  private lowerCaseLetters = /[a-zżźćńółęąś]/g;
  private upperCaseLetters = /[A-ZŻŹĆŃÓŁĘĄŚ]/g;
  private digits = /[0-9]/g;
  private specialCharacters = /[^0-9a-zżźćńółęąśA-ZŻŹĆŃÓŁĘĄŚ]/g;

  constructor(private readonly http: HttpClient) {}

  private initializePasswordPolicy(onSuccess: () => void) {
    this.http.get<PasswordPolicy>(restUrl("users/password-policy"), {withCredentials: true}).subscribe({
      next: (response => {
        this.passwordPolicy = response;
        onSuccess();
      }),
      error: (reason) => {
        handleError(reason)
      }
    })

  }

  validatePassword(password: string, onSuccess: () => void, onError: (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => void) {
    this.initializePasswordPolicy(() => {
      this.validatePasswordWithPolicy(password, password, onSuccess, onError);
    });
  }

  validatePasswords(password: string, repeatPassword: string, onSuccess: () => void, onError: (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => void) {
    this.initializePasswordPolicy(() => {
      this.validatePasswordWithPolicy(password, repeatPassword, onSuccess, onError);
    });
  }

  private validatePasswordWithPolicy(password: string, repeatPassword: string, onSuccess: () => void, onError: (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => void) {

    const passwordExceptions: Array<string> = [];
    const repeatPasswordExceptions: Array<string> = [];

    if(password.length < this.passwordPolicy.minimumLength) {
      passwordExceptions.push(i18n("password_too_short", {minimumLength: this.passwordPolicy.minimumLength}));
    }

    if(password !== repeatPassword) {
      repeatPasswordExceptions.push(i18n("password_not_match"));
    }

    if(this.length(password.match(this.blankCharacters)) > 0) {
      passwordExceptions.push(i18n("password_cannot_contain_spaces"));
    }

    if(this.length(password.match(this.lowerCaseLetters)) < this.passwordPolicy.minimumLowerCaseLetters) {
      passwordExceptions.push(i18n("password_not_enough_lower_case", {minimum: this.passwordPolicy.minimumLowerCaseLetters}));
    }

    if(this.length(password.match(this.upperCaseLetters)) < this.passwordPolicy.minimumUpperCaseLetters) {
      passwordExceptions.push(i18n("password_not_enough_upper_case", {minimum: this.passwordPolicy.minimumUpperCaseLetters}));
    }

    if(this.length(password.match(this.digits)) < this.passwordPolicy.minimumDigits) {
      passwordExceptions.push(i18n("password_not_enough_digits", {minimum: this.passwordPolicy.minimumDigits}));
    }

    if(this.length(password.match(this.specialCharacters)) < this.passwordPolicy.minimumSpecialCharacters) {
      passwordExceptions.push(i18n("password_not_enough_special", {minimum: this.passwordPolicy.minimumSpecialCharacters}));
    }

    if(passwordExceptions.length > 0 || repeatPasswordExceptions.length > 0) {
      onError(passwordExceptions, repeatPasswordExceptions);
    } else {
      onSuccess();
    }
  }

  private length(matches: RegExpMatchArray|null): number {
    if(matches) {
      return matches.length;
    } else {
      return 0;
    }
  }
}
