import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ApplicationSummaryViewModel} from "@shared-model";
import {DropMenuAnchor} from "@shared";

@Component({
  selector: "my-applications-list",
  templateUrl: "./applications-list.component.html",
  host: {
    "[class.favorites]": "favorites"
  }
})
export class ApplicationsListComponent {
  @Input() applications: Array<ApplicationSummaryViewModel> = [];
  @Input() favorites: boolean = false;

  @Output() selectApplication = new EventEmitter<ApplicationSummaryViewModel>();
  @Output() toggleFavorite = new EventEmitter<ApplicationSummaryViewModel>();
  @Output() togglePinToMenu = new EventEmitter<ApplicationSummaryViewModel>();
  contextMenuApp: ApplicationSummaryViewModel | undefined = undefined;
  contextMenuVisible: boolean = false;
  contextMenuAnchor: DropMenuAnchor | undefined = undefined;

  onToggleFavorite(app: ApplicationSummaryViewModel) {
    this.toggleFavorite.emit(app);
    this.contextMenuVisible = false;
  }

  onTogglePinToMenu(app: ApplicationSummaryViewModel) {
    this.togglePinToMenu.emit(app);
    this.contextMenuVisible = false;
  }

  toggleContextMenu($event: MouseEvent, model: ApplicationSummaryViewModel) {
    this.contextMenuApp = model;
    this.contextMenuAnchor = $event.target as HTMLElement;
    this.contextMenuVisible = true;
  }

  toggleContextMenuPoint($event: MouseEvent, model: ApplicationSummaryViewModel) {
    this.contextMenuApp = model;
    this.contextMenuAnchor = undefined;
    this.contextMenuVisible = true;
  }
}
