import {AnyFlowId, AnyInstanceId, AnyPersonId, EventBus, LocalDate} from "@utils";
import {PersonSummary, PlanPosition, ProcessNodeId, TaskIdentifier, TaskModelSummary, TaskPlan} from "../..";

export class TasksEventBus extends EventBus {

  taskAssigned(taskId: TaskIdentifier, personId: AnyPersonId) {}

  taskUnassigned(taskId: TaskIdentifier, personId: AnyPersonId) {}

  flowIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {} // used in case of flow materialization

  // viewUpdated(applyNeeded: boolean) {}
  //
  tasksLoaded(allTasks: Array<TaskModelSummary>, persons: Array<PersonSummary>) {}

  tasksUpdated(added: Array<TaskModelSummary>, updated: Array<TaskModelSummary>, removed: Array<TaskIdentifier>, personsInfo: Array<PersonSummary>) {}

  taskSubmitted(flowId: AnyFlowId, nodeId: ProcessNodeId) {}

  taskRedirected(flowId: AnyFlowId, nodeId: ProcessNodeId) {}

  newFlowCancelled(flowId: AnyFlowId) {}

  //
  // taskDragStarted(task: TaskSummaryViewModel) {}
  //
  // taskDragged(eventPosition: PositionXY) {}
  //
  // taskDragEnded(eventPosition: PositionXY) {}
  //
  // taskSelected(task: TaskIdentifier) {}
  //
  // taskSummaryRequested(task: TaskIdentifier) {}
  //
  taskFormOpenRequested(task: TaskIdentifier) {}
  //
  // taskSummaryCloseRequested() {}
  //
  // taskFormCloseRequested() {}
  //
  // taskSwitchedSelected(task: TaskSummaryViewModel) {}
  //
  // tasksRangeSelected(task: TaskSummaryViewModel) {}
  //
  tasksMoveConfirmed(tasks: Array<TaskIdentifier>) {}
  //
  tasksMoveFailed(tasks: Array<TaskIdentifier>) {}
  //
  // taskRedirectConfirmed(taskIdentifier: TaskIdentifier) {}
  //
  // activityToggleConfirmed(task: TaskModelSummary, activityId: number) {}
  //
  // availableKanbansLoaded(kanbans: Array<KanbanSummary>) {}
  //
  // kanbanLoaded(kanban: AggregateWrapper<Kanban>, tasksGrouping: TasksSwimLanesGrouping, tasksSorting: TasksSwimLanesSorting, myTasksOnly: boolean, newOnly: boolean) {}
  //
  // selectedTasksChanged(selectedTasks: Array<TaskIdentifier>) {}
  //
  newFlowClosed() {}
  //
  // filterChanged() {}
  //
  // processAndTaskFilterChanged(processFilter: Array<ApplicationProcessTaskFilter>) {}
  //
  // searchPhraseChanged(searchPhrase: Optional<string>) {}
  //
  // columnConfigurationChanged(columnName: string) {}
  //
  // kanbanLoadFailed() {}
  //
  // swimLaneToggled() {}
  //
  // taskPageChanged(page: TasksPage) {}
  //
  // colorSelectorShown() {}
  //
  statusSelectorShown() {}

  importanceSelectorShown() {}

  urgencySelectorShown() {}
  //
  newTaskOpenRequested(instanceId: AnyInstanceId, startNodeId: ProcessNodeId) {}
  //
  // taskOpened() {}
  //
  // swimLanesUpdated() {}
  //
  taskLoadStarted(willBeOpenedAfterLoad: boolean) {}

  taskPlanTaskMoveRequested(taskIdentifier: TaskIdentifier, plan: TaskPlan, touched: LocalDate, postponed: boolean, planPosition: PlanPosition) {}

  taskPlanChangeRequested(taskIdentifier: TaskIdentifier, plan: TaskPlan, touched: LocalDate, postponed: boolean, tasksPlanOrder: Array<TaskIdentifier>) {}

  taskMarkedSeen(taskIdentifier: TaskIdentifier, seen: boolean) {}

  taskNotFound(taskId: TaskIdentifier) {}
}
