import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {MainMenuService} from "../main-menu/main-menu.service";
import {NavigationService, OrganizationSessionInfoClientSide, SessionServiceProvider} from "@shared";
import {required} from "@utils";
import {Router} from "@angular/router";

@Component({
  selector: 'my-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  host: {
    '[class.visible]': 'visible'
  }
})
export class NotificationsPanelComponent implements OnInit {

  visible: boolean = false;
  userName: string = "";

  logoutButtonVisible: boolean = false;


  @Output() visibilityChanged = new EventEmitter<boolean>();
  notificationsContentSizeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(readonly mainMenuService: MainMenuService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly navigationService: NavigationService) {

    this.visible = mainMenuService.getNotificationsPanelVisible();
    this.visibilityChanged.emit(this.visible);

    mainMenuService.getNotificationsPanelVisibleObservable().subscribe((visible) => {
      this.visible = visible;
      this.visibilityChanged.emit(visible);
    });

  }

  ngOnInit(): void {

    this.sessionServiceProvider.getSessionService(sessionService => {

      if(sessionService.isLoggedIn()) {
        this.updateUserInfo(required(sessionService.organizationSessionInfo, "sessionInfo"));
      }
      sessionService.organizationSessionInfoObservable.subscribe((info) => {
        if(info) {
          this.updateUserInfo(info);
        } else {
          this.clearUserInfo();
        }
      });

    });

  }

  private updateUserInfo(info: OrganizationSessionInfoClientSide) {
    this.userName = info.userFirstName+" "+info.userLastName;
  }


  private clearUserInfo() {
    this.userName = "";
  }

  logout() {
    this.hidePanel();
    this.sessionServiceProvider.getSessionService(sessionService => sessionService.logout());
    this.navigationService.navigateToLoginPage();
  }

  hidePanel() {
    this.mainMenuService.hideNotificationsPanel();
  }
}
