import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel,
  ScreenWrapperViewModel
} from "../..";
import {__, clearArray, None, NoneSingleton, Option, Some, VariableId} from "@utils";
import {RadioButtonComponentRefState, RadioButtonComponentState} from "./RadioButtonComponentState";
import {SelectComponentOption} from "./select-common";
import {
  CssBuilder,
  RadioButtonComponentDefinition,
  RadioButtonComponentRef,
  SelectEntriesLayoutType
} from "@screen-common";

export class RadioButtonComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "RadioButton";

  public value: string|undefined;
  public tooltip: Option<string> = NoneSingleton;
  public css: string = "";
  public cssClasses: string = "";
  public required: boolean = false;
  public invalid: boolean = false; //TODO

  public classes = "";

  public iconBackgroundColorCss: string|null = null;

  public options: Array<SelectComponentOption> = [];

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: RadioButtonComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: RadioButtonComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: RadioButtonComponentState,
              readonly refState: RadioButtonComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onSelect(selected: SelectComponentOption) {
    if(this.uncoveredAndVisible && !this.disabled) {
      this.options.filter(o => o !== selected).forEach(o => o.selected = false);
      selected.selected = !selected.selected;

      if (selected.selected) {
        this.value = selected.id;
      } else {
        this.value = undefined;
      }

      if (this.value === undefined || this.value === null) {
        this.componentState.updateModel(RadioButtonComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), RadioButtonComponentDefinition.MODEL, RadioButtonComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(RadioButtonComponentDefinition.MODEL, Some(selected.value!));
        this.serverModel.changeModelWithAction(this.componentRefPath(), RadioButtonComponentDefinition.MODEL, selected.value!, RadioButtonComponentDefinition.ON_CHANGE);
      }
    }
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);


    // this.options = this.definition.options.currentValue(() => this.componentState.options).valueOrDefault([]).map((s, index) => SelectComponentOption.of(index, s));

    const newOptions = this.definition.options.currentValue(() => this.componentState.options).valueOrDefault([]).map((s, index) => SelectComponentOption.of(index, s, s => s.valueToSimpleString()));
    const oldOptions = this.options.slice();
    clearArray(this.options);
    newOptions.forEach(o => {

      const existing = oldOptions.find(oo => oo.value === o.value || oo.value !== undefined && o.value !== undefined && oo.value.isEqual(o.value));

      if(existing) {
        existing.selected = o.selected;
        existing.label = o.label;
        existing.id = o.id;
        this.options.push(existing);
      } else {
        this.options.push(o);
      }
    });


    this.value = this.prepareValue();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);
    const entriesLayoutCssClass = SelectEntriesLayoutType.of(this.definition.entriesLayout.currentValue(() => this.componentState.entriesLayout).valueOrDefault(SelectEntriesLayoutType.vertical.name)).toCssClass();
    this.classes = (this.customCssClass+" "+entriesLayoutCssClass).trim()+` RadioButtonComponent_${this.id}`;
    super.updatePosition();

    this.iconBackgroundColorCss = this.definition.iconBackgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.iconBackgroundColor).valueOrDefault(None()).getOrNull();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();
  }

  prepareValue(): string {
    const value = this.componentState.model.valueOrDefault(None()).getOrUndefined();
    this.options.forEach(o => o.selected = false);
    const existing = __(this.options).find(o => o.value == undefined && value == undefined || o.value != undefined && o.value.isEqual(value));
    existing.forEach(o => o.selected = true);
    if(existing.isDefined()) {
      return existing.get().id;
    } else {
      return "-0"; // TODO handle missing option
    }
  }


}
