import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {PageLayout, PageLayoutService} from "../../resolvers/page-layout.service";

@Injectable({ providedIn: 'root' })
export class PageLayoutResolver implements Resolve<boolean> {
  constructor(readonly pageLayoutService: PageLayoutService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|any {
    return new Promise((resolve, reject) => {
      let pageLayout: PageLayout = route.data["pageLayout"];
      this.pageLayoutService.setNextLayout(pageLayout);
      resolve(true);
    });
  }
}
