import {LocalDateTime, Option, Typed} from "@utils";
import {BusinessVariable} from "./variables/BusinessVariables";

export class ComparisonOperator {

  constructor(readonly name: string) {}

  static E = new ComparisonOperator("=");
  static LT = new ComparisonOperator("<");
  static LTE = new ComparisonOperator("<=");
  static NE = new ComparisonOperator("<>");
  static GTE = new ComparisonOperator(">=");
  static GT = new ComparisonOperator(">");
  static LIKE = new ComparisonOperator("LIKE");
}

export class LogicOperator {

  constructor(readonly name: string) {}

  static AND = new LogicOperator("AND");
  static OR = new LogicOperator("OR");
}


export interface FieldQueryExpression {
  className(): string;
}

export class FieldQueryComplex implements FieldQueryExpression {
  className(): string {
    return "FieldQueryComplex";
  }

  constructor(readonly operator: LogicOperator,
              readonly expressions: Typed<FieldQueryExpression>[]) {}

  static of(operator: LogicOperator, expressions: FieldQueryExpression[]) {
    return new FieldQueryComplex(operator, expressions.map(e => Typed.of(e)));
  }
}

export class FieldQuery implements FieldQueryExpression {
  className(): string {
    return "FieldQuery";
  }

  constructor(readonly fieldName: string,
              readonly operator: ComparisonOperator,
              readonly value: Typed<BusinessVariable>) {
  }

  static of(fieldName: string, operator: ComparisonOperator, value: BusinessVariable) {
    return new FieldQuery(fieldName, operator, Typed.of(value));
  }
}

export class TimeConstraint {
  constructor(readonly min: Option<LocalDateTime>,
              readonly max: Option<LocalDateTime>,
              readonly includeMin: boolean,
              readonly includeMax: boolean) {
  }

  notEmpty() {
    return this.min.isDefined() || this.max.isDefined();
  }

  equals(other: TimeConstraint) {
    return this.min.equals(other.min, (a, b) => a.isEqual(b)) &&
      this.max.equals(other.max, (a, b) => a.isEqual(b)) &&
      this.includeMin === other.includeMin &&
      this.includeMax === other.includeMax;
  }
}

export class LongConstraint {
  constructor(readonly min: Option<number>,
              readonly max: Option<number>,
              readonly includeMin: boolean,
              readonly includeMax: boolean) {
  }

  notEmpty() {
    return this.min.isDefined() || this.max.isDefined();
  }
}
