import {Pipe, PipeTransform} from "@angular/core";
import {I18nService} from "..";


@Pipe({name: 'i18nCount'})
export class I18nCountPipe implements PipeTransform {

  constructor(private readonly i18nService: I18nService) {
  }

  // transform(input: string|I18nText): string {
  //     if(input == undefined) {
  //         return "";
  //     } else if(input instanceof I18nText) {
  //         return input.getCurrentWithFallback();
  //     } else {
  //         return i18n(input);
  //     }
  // }

  transform(input: string, count: number): string {
    if (input == undefined) {
      return "no input";
      // } else if(input instanceof I18nText) {
      //   return input.getCurrentWithFallback();
    } else {
      return this.i18nService.translateCount(input, count);
    }
  }

}
