import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {BusinessVariable, VariablePath} from "@shared-model";


@Component({
  selector: "my-case-code-variable-input",
  templateUrl: "./case-code-variable-input.component.html"
})
export class CaseCodeVariableInputComponent implements OnInit {
  private _value!: BusinessVariable | null;
  code: string = "";
  get value(): BusinessVariable | null {
    return this._value;
  }

  @Input() set value(value: BusinessVariable | null) {
    this._value = value;
    this.onValueChanged();
  }


  private _readOnly: boolean = false;
  get readOnly(): boolean {
    return this._readOnly;
  }

  @Input() set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
    this.onModeChanged();
  }

  private _preview: boolean = false;
  get preview(): boolean {
    return this._preview;
  }

  @Input() set preview(preview: boolean) {
    this._preview = preview;
    this.onModeChanged();
  }

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{ value: BusinessVariable | null, path: VariablePath }>();

  constructor() {
  }


  private onValueChanged() {
    this.code = this._value ? this._value.value : "";
  }

  private onModeChanged() {
  }

  ngOnInit(): void {
    this.code = this._value ? this._value.value : "";
  }
}
