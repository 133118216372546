export class ActionIneffectiveness {

  constructor(readonly name: string,
              readonly description: string,
              readonly analysis: string) {
  }

  static copy(other: ActionIneffectiveness) {
    return new ActionIneffectiveness(other.name, other.description, other.analysis);
  }
}
