import {LocalDateTime} from "./data-types/Time";
import {global} from "./global";
import {NgZone} from "@angular/core";

export function scheduleUTC(handler: () => void, dateTime: LocalDateTime): number {
  return mySetTimeout(handler, dateTime.differenceMillis(LocalDateTime.now()));
}

export function scheduleLocal(handler: () => void, dateTime: LocalDateTime): number {
  return mySetTimeout(handler, dateTime.differenceMillis(LocalDateTime.localNow()));
}

export function clearSchedule(id: number) {
  clearTimeout(id);
}

// PLAIN

export function mySetTimeout(callback: () => void, delay?: number): number {
  return window.setTimeout(callback, delay);
}

export function mySetInterval(callback: () => void, delay: number): number {
  return window.setInterval(callback, delay);
}

export function myRequestAnimationFrame(callback: () => void): number {
  return requestAnimationFrame(callback);
}


export function mySetTimeoutNoAngular(callback: () => void, delay?: number): number {
  if(NgZone.isInAngularZone()) {
    return global.zone.runOutsideAngular(() => {
      return window.setTimeout(callback, delay);
    });
  } else {
    return window.setTimeout(callback, delay);
  }
}

export function mySetIntervalNoAngular(callback: () => void, delay: number): number {
  if(NgZone.isInAngularZone()) {
    return global.zone.runOutsideAngular(() => {
      return window.setInterval(callback, delay);
    });
  } else {
    return window.setInterval(callback, delay);
  }
}

export function myRequestAnimationFrameNoAngular(callback: () => void): number {
  if(NgZone.isInAngularZone()) {
    return global.zone.runOutsideAngular(() => {
      return requestAnimationFrame(callback);
    });
  } else {
    return requestAnimationFrame(callback);
  }
}


//debug

// LOGGING
//
// let lastSecond = 0;
// let lastSecondIntervals = 0;
// let lastSecondTimeouts = 0;
// let lastSecondFrames = 0;
// let lastSecondIntervalsNoAngular = 0;
// let lastSecondTimeoutsNoAngular = 0;
// let lastSecondFramesNoAngular = 0;
//
// function logIfNeeded() {
//   const second = Math.floor(Date.now() / 1000);
//   if (second !== lastSecond) {
//     if (lastSecond > 0) {
//       console.log("mySetInterval in second " + lastSecond+" timeouts: " + lastSecondTimeouts + " intervals: " + lastSecondIntervals + " frames: " + lastSecondFrames + " PURE: "+
//         " timeouts: " + lastSecondTimeoutsNoAngular + " intervals: " + lastSecondIntervalsNoAngular + " frames: " + lastSecondFramesNoAngular);
//     }
//     lastSecond = second;
//     lastSecondIntervals = 0;
//     lastSecondTimeouts = 0;
//     lastSecondFrames = 0;
//     lastSecondIntervalsNoAngular = 0;
//     lastSecondTimeoutsNoAngular = 0;
//     lastSecondFramesNoAngular = 0;
//   }
// }
//
//
//
// export function mySetTimeout(callback: () => void, delay?: number): number {
//   return setTimeout(() => {
//     logIfNeeded();
//     lastSecondTimeouts++;
//     callback();
//   }, delay);
// }
//
// export function mySetInterval(callback: () => void, delay: number): number {
//   return setInterval(() => {
//     logIfNeeded();
//     lastSecondIntervals++;
//     callback();
//   }, delay);
// }
//
// export function myRequestAnimationFrame(callback: () => void): number {
//   return requestAnimationFrame(() => {
//     logIfNeeded();
//     lastSecondFrames++;
//     callback();
//   });
// }
//
//
// export function mySetTimeoutNoAngular(callback: () => void, delay?: number): number {
//   return global.zone.runOutsideAngular(() => {
//     return setTimeout(() => {
//       logIfNeeded();
//       lastSecondTimeoutsNoAngular++;
//       callback();
//     }, delay);
//   });
// }
//
// export function mySetIntervalNoAngular(callback: () => void, delay: number): number {
//   return global.zone.runOutsideAngular(() => {
//     return setInterval(() => {
//       logIfNeeded();
//       lastSecondIntervalsNoAngular++;
//       callback();
//     }, delay);
//   });
// }
//
// export function myRequestAnimationFrameNoAngular(callback: () => void): number {
//   return global.zone.runOutsideAngular(() => {
//     return requestAnimationFrame(() => {
//       logIfNeeded();
//       lastSecondFramesNoAngular++;
//       callback();
//     });
//   });
// }
