import {ExpressionWithAst, ProcessEdgeId, VariablePath} from "@shared-model";
import {__, None, Option, Some, Typed} from "@utils";
import {FormElement} from "./FormModel";
import {Label} from "./elements/Label";
import {Checkbox} from "./elements/Checkbox";
import {RadioButton} from "./elements/RadioButton";
import {DropList} from "./elements/DropList";
import {TextArea} from "./elements/TextArea";
import {DateField} from "./elements/DateField";
import {AttachmentField} from "./elements/AttachementField";
import {ActionButton} from "./elements/ActionButton";
import {MapField} from "./elements/MapField";

export class EdgeCondition {
  constructor(readonly operationType: "always"|"never"|"rule"|"expression",
              readonly expressionRule: string,
              readonly edgeId: ProcessEdgeId,
              readonly logicRule: LogicRule) {}

  static copy(other: EdgeCondition) {

    if(other.operationType == "always" || other.operationType == "never" || other.operationType == "rule" || other.operationType == "expression") {
      return new EdgeCondition(other.operationType, other.expressionRule, other.edgeId, LogicRule.copy(other.logicRule));
    } else {
      throw new Error("Unknown operationType '" + other.operationType+"'");
    }

  }
}


export class ConditionProperties {
  constructor(readonly conditionType: Option<ConditionType>,
              readonly openType: Option<OpenType>,
              readonly closeParallelType: Option<CloseParallelType>,
              readonly conditions: Array<EdgeCondition>,
              readonly defaultEdge: Option<number>) {}

  getConditionByEdgeId(edgeId: ProcessEdgeId) {
    const result = __(this.conditions).find((c: EdgeCondition) => c.edgeId === edgeId).getOrUndefined();
    if (result == undefined) {
      throw new Error("no edge condition found for edge id " + edgeId);
    } else {
      return result;
    }
  }

  static copy(other:ConditionProperties) {
    return new ConditionProperties(Option.copy(other.conditionType).map(t => new ConditionType(t.name)),
      Option.copy(other.openType).map(t => new OpenType(t.name)),
      Option.copy(other.closeParallelType).map(t => new CloseParallelType(t.name)),
      other.conditions.map(c => EdgeCondition.copy(c)),
      Option.copy(other.defaultEdge)
    );
  }

  static newEmpty() {
    return new ConditionProperties(None(), None(), None(), [], None());
  }
}

export class ConditionType {
  constructor(readonly name: string) {}
  isOpen = () => this.name === "open";
  isCloseParallel = () => this.name === "closeParallel";

  static open = new ConditionType("open");
  static closeParallel = new ConditionType("closeParallel");

}


export class OpenType {
  constructor(readonly name: string) {}
  isSimple = () => this.name === OpenType.simple.name;
  isParallelAll = () => this.name === OpenType.parallelAll.name;
  isParallelAccepted = () => this.name === OpenType.parallelAccepted.name;
  isParallelAdvanced = () => this.name === OpenType.parallelAdvanced.name;

  static simple = new OpenType("simple");
  static parallelAll = new OpenType("parallelAll");
  static parallelAccepted = new OpenType("parallelAccepted");
  static parallelAdvanced = new OpenType("parallelAdvanced");
}

export class CloseParallelType {
  constructor(readonly name: string) {}
  isAll = () => this.name === "all";
  isFirst = () => this.name === "first";
  isAdvanced = () => this.name === "advanced";
  static all = new CloseParallelType("all");
  static first = new CloseParallelType("first");
  static advanced = new CloseParallelType("advanced");
}

export class LogicRuleOperator {

  constructor(readonly name: string) {}

  static and = new LogicRuleOperator("and");
  static or = new LogicRuleOperator("or");

  isAnd = () => this.name === "and";
  isOr = () => this.name === "or";

  static copy(other: LogicRuleOperator) {
    if (other.name === "and") {
      return LogicRuleOperator.and;
    } else if (other.name === "or") {
      return LogicRuleOperator.or;
    } else {
      throw new Error("unknown logic operator name " + other.name);
    }
  }

  static of(operator: "and"|"or") {
    if(operator === "and") {
      return LogicRuleOperator.and;
    } else {
      return LogicRuleOperator.or;
    }
  }

  toOperator(): "and"|"or" {
    switch (this.name) {
      case "and": return "and";
      case "or": return "or";
      default: throw new Error("unknown logic operator name " + this.name);
    }
  }
}


export class LogicRule {
  constructor(readonly operator: LogicRuleOperator,
              readonly isOperation: boolean,
              readonly operation: Option<LogicOperation>,
              readonly subRules: Array<LogicRule>) {}

  static copy(other: LogicRule):LogicRule {
    return new LogicRule(
      LogicRuleOperator.copy(other.operator),
      other.isOperation,
      Option.copy(other.operation, LogicOperation.copy),
      other.subRules.map(LogicRule.copy));
  }
}

export class LogicOperationOperator {
  constructor(readonly name: string) {}

  static equalTo = new LogicOperationOperator("equalTo");
  static lowerThan = new LogicOperationOperator("lowerThan");
  static lowerEqualThan = new LogicOperationOperator("lowerEqualThan");
  static greaterThan = new LogicOperationOperator("greaterThan");
  static greaterEqualThan = new LogicOperationOperator("greaterEqualThan");
  static notEqualTo = new LogicOperationOperator("notEqualTo");
  static contains = new LogicOperationOperator("contains");
  static notContains = new LogicOperationOperator("notContains");

  static copy(other: LogicOperationOperator) {
    switch(other.name) {
      case LogicOperationOperator.equalTo.name: return LogicOperationOperator.equalTo;
      case LogicOperationOperator.lowerThan.name: return LogicOperationOperator.lowerThan;
      case LogicOperationOperator.lowerEqualThan.name: return LogicOperationOperator.lowerEqualThan;
      case LogicOperationOperator.greaterThan.name: return LogicOperationOperator.greaterThan;
      case LogicOperationOperator.greaterEqualThan.name: return LogicOperationOperator.greaterEqualThan;
      case LogicOperationOperator.notEqualTo.name: return LogicOperationOperator.notEqualTo;
      case LogicOperationOperator.contains.name: return LogicOperationOperator.contains;
      case LogicOperationOperator.notContains.name: return LogicOperationOperator.notContains;
      default: throw new Error("Unsupported operator: '" + other.name+"'")
    }
  }

  toSimpleOperator(): "=="|"!="|"<"|"<="|">"|">="|"contains"|"!contains" {
    switch(this.name) {
      case LogicOperationOperator.equalTo.name: return "==";
      case LogicOperationOperator.lowerThan.name: return "<";
      case LogicOperationOperator.lowerEqualThan.name: return "<=";
      case LogicOperationOperator.greaterThan.name: return ">";
      case LogicOperationOperator.greaterEqualThan.name: return ">=";
      case LogicOperationOperator.notEqualTo.name: return "!=";
      case LogicOperationOperator.contains.name: return "contains";
      case LogicOperationOperator.notContains.name: return "!contains";
      default: throw new Error("Unsupported operator: '" + this.name+"'")
    }
  }

  static fromSimpleOperator(operator: "=="|"!="|"<"|"<="|">"|">="|"contains"|"!contains"): LogicOperationOperator {
    switch(operator) {
      case "==": return LogicOperationOperator.equalTo;
      case "<": return LogicOperationOperator.lowerThan;
      case "<=": return LogicOperationOperator.lowerEqualThan;
      case ">": return LogicOperationOperator.greaterThan;
      case ">=": return LogicOperationOperator.greaterEqualThan;
      case "!=": return LogicOperationOperator.notEqualTo;
      case "contains": return LogicOperationOperator.contains;
      case "!contains": return LogicOperationOperator.notContains;
      default: throw new Error("Unsupported operator: '" + operator+"'")
    }
  }
}

export class LogicOperationValueFactory {

  static copy(element: LogicOperationValue): LogicOperationValue {
    return LogicOperationValueFactory.copyByType(element, element.className());
  }

  static copyTyped(element: Typed<LogicOperationValue>): Typed<LogicOperationValue> {
    return Typed.of(LogicOperationValueFactory.copyByType(Typed.value(element), Typed.className(element)));
  }

  static copyByType(element: LogicOperationValue, className: string): LogicOperationValue {
    switch(className) {
      case StringValue.className: return StringValue.copy(<StringValue>element);
      case NumberValue.className: return NumberValue.copy(<NumberValue>element);
      case BooleanValue.className: return BooleanValue.copy(<BooleanValue>element);
      case VariableValue.className: return VariableValue.copy(<VariableValue>element);
      case ExpressionValue.className: return ExpressionValue.copy(<ExpressionValue>element);
      default :throw new Error("Unknown operation value type " + element.className());
    }
  }


}

export interface LogicOperationValue {
  className(): string;
}

export class StringValue implements LogicOperationValue {
  static className = "StringValue";
  className(): string {
    return StringValue.className;
  }

  constructor(readonly value: string) {}

  static copy(other: StringValue) {
    return new StringValue(other.value);
  }
}
export class NumberValue implements LogicOperationValue {
  static className = "NumberValue";
  className(): string {
    return NumberValue.className;
  }
  constructor(readonly value: Option<number>) {}

  static copy(other: NumberValue) {
    return new NumberValue(Option.copy(other.value));
  }
}
export class BooleanValue implements LogicOperationValue {
  static className = "BooleanValue";
  className(): string {
    return BooleanValue.className;
  }
  static TRUE = new BooleanValue(true);
  static FALSE = new BooleanValue(true);
  constructor(readonly value: boolean) {}
  static copy(other: BooleanValue) {
    if(other.value) {
      return BooleanValue.TRUE;
    } else {
      return BooleanValue.FALSE;
    }
  }
}
export class VariableValue implements LogicOperationValue {
  static className = "VariableValue";
  className(): string {
    return VariableValue.className;
  }
  constructor(readonly value: VariablePath) {}
  static copy(other: VariableValue) {
    return new VariableValue(VariablePath.copy(other.value));
  }
}
export class ExpressionValue implements LogicOperationValue {
  static className = "ExpressionValue";
  className(): string {
    return ExpressionValue.className;
  }
  constructor(readonly value: string) {}
  static copy(other: ExpressionValue) {
    return new ExpressionValue(other.value);
  }
}




export class LogicOperation {
  constructor(readonly left: Option<Typed<LogicOperationValue>>,
              readonly operator: LogicOperationOperator,
              readonly right: Option<Typed<LogicOperationValue>>) {}

  leftUnwrapped() {
    return this.left.map(Typed.value);
  }

  rightUnwrapped() {
    return this.right.map(Typed.value);
  }

  static copy(other: LogicOperation): LogicOperation {
    return new LogicOperation(Option.copy(other.left, LogicOperationValueFactory.copyTyped),
      LogicOperationOperator.copy(other.operator),
      Option.copy(other.right, LogicOperationValueFactory.copyTyped));
  }
}
