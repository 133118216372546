import {AggregateId, AggregateVersion} from "./AggregateId";
import {Typed} from "../data-types/Typed";

export class AggregateWrapper<T> {
    constructor(readonly id:AggregateId,
                readonly version:AggregateVersion,
                readonly aggregate:T) {}

    static copy<T>(other: AggregateWrapper<T>, aggregateCopier: (aggreage: T) => T): AggregateWrapper<T> {
      return new AggregateWrapper(AggregateId.copy(other.id), AggregateVersion.copy(other.version), aggregateCopier(other.aggregate));
    }
  }


  export class TypedAggregateWrapper<T extends {className():string}> {

    constructor(readonly id:AggregateId,
                readonly version:AggregateVersion,
                readonly aggregate:Typed<T>) {}

    static copy<T extends {className():string}>(other:TypedAggregateWrapper<T>, copyAggregate:(aggregate: Typed<T>) => Typed<T>) {
      return new TypedAggregateWrapper(AggregateId.copy(other.id), AggregateVersion.copy(other.version), copyAggregate(other.aggregate));
    }
  }
