<ng-container *ngIf="!registrationDisabled">

  <ng-container *ngIf="!organizationRegistered">

    <ng-container *ngIf="registrationStepForm == RegistrationStepForm.ORGANIZATION">

      <div class="registerStep">
        <span>{{'common_step' | i18n}} 1 / 3</span>
      </div>
      <div class="registerTitle">{{'register_organization_form_organization_title' | i18n}}</div>
      <div class="registerDescription">{{'register_organization_site_description2' | i18n}}</div>

      <span class="error" *ngIf="organizationNameAlreadyExists">
        {{'register_organization_form_error_organization_name_already_exists' | i18n}}
      </span>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-user-group"></i>
        <input type="text" placeholder="{{'register_organization_form_organization_name_placeholder' | i18n}}" required="required" [focus-on-show]="true"
               [(ngModel)]="organizationName" (blur)="validateName();validateAlias()" (keyup)="updateAlias()">
      </div>

      <hr class="theme-content-horizontal-separator-no-margin" />

      <span class="error"
            *ngIf="urlAlreadyInUse">{{'register_organization_form_error_app_url_already_in_use' | i18n}}</span>
      <span class="error" *ngIf="urlTooShort">{{'register_organization_form_error_app_url_too_short' | i18n}}</span>

      <div class="horizontalGrid">
        <div class="theme-input-box-with-color-icon-large">
          <i class="mi-link"></i>
          <input type="text" placeholder="{{'register_organization_form_organization_url_placeholder' | i18n}}" required="required"
                 [(ngModel)]="organizationAlias" (blur)="validateAlias()" (change)="modifyAlias()">
        </div>
        <div class="applicationDomainWithPort">.{{currentApplicationDomainWithPort}}</div>
      </div>

      <button class="registerButton theme-button-confirm-with-icon iconOnRight" (click)="validateOrganizationForm()" [disabled]="!(organizationName && organizationAlias)">{{'common_continue' | i18n}}<i class="mi-navigate-right"></i></button>

    </ng-container>

    <ng-container *ngIf="registrationStepForm == RegistrationStepForm.USER">

      <div class="registerStep">
        <button class="backButton theme-button-link-icon" (click)="registrationStepForm = RegistrationStepForm.ORGANIZATION"><i class="mi-navigate-left-small"></i></button>
        <span>{{'common_step' | i18n}} 2 / 3</span>
      </div>
      <div class="registerTitle">{{'register_organization_form_user_title' | i18n}}</div>
      <div class="registerDescription">{{'register_organization_site_description2' | i18n}}</div>

      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-user"></i>
        <input type="text" placeholder="{{'register_organization_form_first_name_placeholder' | i18n}}"
               required="required" [focus-on-show]="true"
               [(ngModel)]="userFirstName">
      </div>

      <hr class="theme-content-horizontal-separator-no-margin" />

      <span class="error"
            *ngIf="emailValidator.emailChecked && !emailValidator.emailCorrect">{{'register_organization_form_error_email_not_valid' | i18n}}</span>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-mail"></i>
        <input type="text" placeholder="{{'register_organization_form_email_placeholder' | i18n}}"
               required="required"
               [(ngModel)]="userEmail" (blur)="validateEmail()">
      </div>

      <button class="registerButton theme-button-confirm-with-icon iconOnRight" (click)="validateUserForm()" [disabled]="!(userFirstName && userEmail)">{{'common_continue' | i18n}}<i class="mi-navigate-right"></i></button>

    </ng-container>

    <ng-container *ngIf="registrationStepForm == RegistrationStepForm.PASSWORD">

      <div class="registerStep">
        <button class="backButton theme-button-link-icon" (click)="registrationStepForm = RegistrationStepForm.USER"><i class="mi-navigate-left-small"></i></button>
        <span>{{'common_step' | i18n}} 3 / 3</span>
      </div>
      <div class="registerTitle">{{'register_organization_form_password_title' | i18n}}</div>
      <div class="registerDescription">{{'register_organization_site_description2' | i18n}}</div>

      <div class="error" *ngFor="let exception of passwordExceptions">{{exception}}</div>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-lock"></i>
        <input type="password" placeholder="{{'register_organization_form_password_placeholder' | i18n}}"
               required="required" [focus-on-show]="true"
               [(ngModel)]="userPassword" (blur)="validatePassword()" (change)="checkIfPasswordsMatchNow()">
      </div>

      <div class="error" *ngFor="let exception of repeatPasswordExceptions">{{exception}}</div>
      <div class="theme-input-box-with-color-icon-large">
        <i class="mi-lock"></i>
        <input type="password"            placeholder="{{'register_organization_form_password_repeat_placeholder' | i18n}}" required="required"
               [(ngModel)]="userPasswordRepeat" (blur)="validatePasswords()" (change)="checkIfPasswordsMatchNow()">
      </div>

      <hr class="theme-content-horizontal-separator-no-margin" />

      <div class="horizontalContent">
        <my-checkbox [(checked)]="termsAndPolicyAcceptance" [label]="'register_organization_form_terms_and_policy_agree' | i18n" />

        <button class="theme-button-link-small no-padding" (click)="toggleShowingTermsAndPolicy()">{{'register_organization_form_terms_and_policy_label2' | i18n}}</button>
      </div>

      <button class="registerButton theme-button-confirm-with-icon" (click)="registerOrganization()" [disabled]="!(userPassword && userPasswordRepeat && termsAndPolicyAcceptance)"><i class="mi-check"></i>{{'register_organization_form_submit_button' | i18n}}</button>

    </ng-container>

    <my-modal cssClass="termsAndPolicyContainer" [(visible)]="termsAndPolicyVisible" [backgroundVisible]="true"
              [closeOnBackgroundClick]="true" (closed)="closeTermsAndPolicy()">

      <ng-container *ngIf="termsAndPolicyVisible">
        <button (click)="closeTermsAndPolicy()" class="closeTermsAndPolicy theme-button-close"><i class="mi-delete"></i></button>
        <my-terms-and-policy />
      </ng-container>

    </my-modal>

  </ng-container>

  <ng-container *ngIf="organizationRegistered">
    <div class="statusContainer" >
      <div class="statusTitle">{{'register_organization_form_success_status_header' | i18n}}</div>
      <div class="statusDescription">{{'register_organization_form_success_status_content_description' | i18n}}</div>
      <div class="statusEmail"><i class="mi-mail"></i> {{userEmail}}</div>
    </div>
  </ng-container>

</ng-container>

<ng-container *ngIf="registrationDisabled">
  <div class="statusContainer">
    <div *ngIf="registrationDisabledChecked" class="formHeader">404</div>
    <div *ngIf="registrationDisabledChecked" class="statusDescription">Not found!</div>
  </div>
</ng-container>
