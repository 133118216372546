import {Injectable} from "@angular/core";
import {AppSettingsController} from "./app-settings-controller";
import {Theme} from "@shared";
import {
  colorBrightness,
  colorIdToHex,
  darkenColor,
  global,
  Language,
  mainColorHex,
  required,
  validateColorHex
} from "@utils";
import {colorToHex, saturate, lightenColor, parseColorHex, setAlpha} from "../../../utils/Colors";
import {OrganizationSharedService} from "@shared-model";

export class CustomThemeSettings {
  private accentColor: string|null = null;



  // !TODO Validate properties values before injecting to the page

  accentColorHex() {

    let color: string|undefined = undefined;

    if(this.accentColor !== null && this.accentColor.startsWith("#") && validateColorHex(this.accentColor)) {
      color = this.accentColor;
    } else if(this.accentColor !== null && !this.accentColor.startsWith("#")) {
      color = colorIdToHex(this.accentColor);
    }

    if(color !== undefined) {
      return color;
    } else {
      return mainColorHex();
    }
  }

  toCss(darkTheme: boolean) {

    const colorHex = this.accentColorHex();

    const color = colorHex ? parseColorHex(colorHex) : null;

    if(color !== null && colorHex !== undefined) {

      let brighness = colorBrightness(color);

      let bright = brighness > 0.5;
      let veryBright = brighness > 0.8;

      let css = ":root{";
      // css = this.ifDefined(css,"--custom-main-font-color", this.mainFontColor);
      // css = this.ifDefined(css,"--custom-background-color", this.defaultBackgroundColor);


      if (!darkTheme) {
        css = this.ifDefined(css, "--custom-color-a", colorHex);
        css = this.ifDefined(css, "--custom-font-color-a", bright ? colorToHex(saturate(darkenColor(color, brighness / 4), 1 + brighness / 4)) : colorHex);
        css = this.ifDefined(css, "--custom-on-a-font-color", veryBright ? "#1f1646" : "#fff");

        css = this.ifDefined(css, "--custom-button-color-a", colorHex);
        css = this.ifDefined(css, "--custom-button-color-a-gradient", colorToHex(saturate(lightenColor(color, 0.12), 1.3)));
        css = this.ifDefined(css, "--custom-button-color-a-hover", colorToHex(saturate(lightenColor(color, 0.12), 1.3)));
        css = this.ifDefined(css, "--custom-button-color-a-shadow", colorToHex(setAlpha(color, 63)));
        css = this.ifDefined(css, "--custom-default-link-font-color", colorHex);
        css = this.ifDefined(css, "--custom-icon-background-color-a", colorToHex(setAlpha(color, 51)));
        css = this.ifDefined(css, "--custom-selection-background-color-a", colorToHex(setAlpha(color, 26)));
      } else {

        css = this.ifDefined(css, "--custom-color-a", colorHex);
        css = this.ifDefined(css, "--custom-font-color-a", bright ? colorToHex(saturate(darkenColor(color, brighness / 4), 1 + brighness / 4)) : colorHex);
        css = this.ifDefined(css, "--custom-on-a-font-color", veryBright ? "#1f1646" : "#fff");
        css = this.ifDefined(css, "--custom-button-color-a", colorHex);
        css = this.ifDefined(css, "--custom-button-color-a-gradient", colorToHex(saturate(lightenColor(color, 0.12), 1.3)));
        css = this.ifDefined(css, "--custom-button-color-a-hover", colorToHex(saturate(lightenColor(color, 0.12), 1.3)));
        css = this.ifDefined(css, "--custom-button-color-a-shadow", colorToHex(setAlpha(color, 63)));
        css = this.ifDefined(css, "--custom-default-link-font-color", colorHex);
        css = this.ifDefined(css, "--custom-selection-background-color-a", colorToHex(setAlpha(color, 26)));


      }

      css += "}";
      return css;

    } else {
      return ":root{}";
    }

  }

  private ifDefined(css: string, propertyName: string, ...propertyValue: Array<string | null>): string {
    for(let i = 0; i < propertyValue.length; i++) {
      let value = propertyValue[i];
      if(value !== null && value.trim().length > 0) {
        return css + propertyName + ":" + value + ";";
      }
    }
    return css;
  }

  changeProperties(accentColor: string | null) {
    this.accentColor = accentColor;
  }


}


@Injectable({
  providedIn: 'root',
})
export class SystemSettingsService {

  private customThemeSettings = new CustomThemeSettings();

  private systemTimeZone: string;
  private systemLocale: string;
  private systemLanguage: Language;
  private systemTheme: Theme = Theme.light;


  constructor(private readonly appSettingsController: AppSettingsController) {

    this.customThemeSettings.changeProperties(global.config.accentColor.getOrNull());


    this.systemTimeZone = this.retrieveSystemTimeZone();
    this.systemLocale = this.retrieveSystemLocale();
    this.systemLanguage = global.config.organizationLanguage.map(Language.copy).getOrElse(this.extractLanguageFromLocale(this.systemLocale));

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.systemTheme = Theme.dark;
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if(event.matches) {
        this.systemTheme = Theme.dark;
      } else {
        this.systemTheme = Theme.light;
      }
    });

    // after components initialization
    requestAnimationFrame(() => {
      appSettingsController.applyCustomThemeSettingsToThePage(this.customThemeSettings);
    });
  }

  setOrganizationLanguage(language: Language) {
    this.systemLanguage = language;
  }

  setOrganizationTimezone(timezone: string) {
    this.systemTimeZone = timezone;
  }

  setCustomThemeProperties(accentColor: string | null) {
    this.customThemeSettings.changeProperties(accentColor);
    this.appSettingsController.applyCustomThemeSettingsToThePage(this.customThemeSettings);
  }

  private retrieveSystemTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private retrieveSystemLocale(): string {
    return navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  }

  private extractLanguageFromLocale(locale: string): Language {
    let language = "en";
    if(locale != null && locale.indexOf("-") > 0) {
      language = locale.substring(0, locale.indexOf("-"));
    } else if(locale != null && locale.indexOf("-") < 0 && /^[a-z]{2,3}$/.test(locale)) {
      language = locale;
    }
    try {
      return Language.byName(language);
    } catch (e) {
      return Language.DEFAULT;
    }
  }

  getEffectiveLanguage(): Language {
    return this.systemLanguage;
  }

  getEffectiveTheme() {
    return this.systemTheme;
  }

  getEffectiveTimeZone() {
    return this.systemTimeZone;
  }

  getEffectiveLocale() {
    return this.systemLocale;
  }

}
