import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {None, Option, Some} from "@utils";
import {BusinessVariable, StringVariable, VariablePath} from "@shared-model";

export class StringVariableInputViewModel {

  internalModel: string = "";
  internalModelBackup: string|null = null;

  constructor(readonly path: VariablePath,
              public value: StringVariable|null,
              public readOnly: boolean,
              public preview: boolean,
              readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>) {
    this.initInternalModel();
  }

  initInternalModel() {
    if (this.value == null) {
      this.internalModel = "";
    } else {
      this.internalModel = this.value.value;
    }
    this.internalModelBackup = this.internalModel;
  }

  private changeVariableValue(value: StringVariable|null) {
    this.value = value;
    this.initInternalModel();
    this.change.emit({value: value, path: this.path});
  }

  onInternalChange() {
    if(this.internalModelChanged()) {
      if (this.internalModel === null || this.internalModel.trim().length === 0) {
        this.changeVariableValue(null);
      } else {
        this.changeVariableValue(new StringVariable(this.internalModel.trim()));
      }
      this.internalModelBackup = this.internalModel;
    }
  }

  private internalModelChanged() {
    return this.internalModel !== this.internalModelBackup;
  }

  updateValue(value: BusinessVariable|null) {
    if(value === null || value instanceof StringVariable) {
      this.value = value;
      this.initInternalModel();
    } else {
      throw new Error("Incorrect variable type, expected 'String' but was '"+value.simpleValueType()+"'");
    }
  }

  updateMode(readOnly: boolean, preview: boolean) {
    this.readOnly = readOnly;
    this.preview = preview;
  }

  backupInternalValue() {
    this.internalModelBackup = this.internalModel;
  }
}


@Component({
  selector: "my-string-variable-input",
  templateUrl: "./string-variable-input.component.html"
})
export class StringVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}
  @Output() valueChange = new EventEmitter<BusinessVariable|null>();

  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  viewModel!: StringVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this._readOnly, this._preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new StringVariableInputViewModel(this.path, this._value, this._readOnly, this._preview, this.change);
  }
}
