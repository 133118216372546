<button (click)="onClicked(); $event.stopPropagation(); false;"
        [focus-on-show]="focusOnShow"
        [tabindex]="myTabindex"
        [class.checked]="checked"
        [class.unknown]="unknown"
        [class.enabled]="!disabled"
        [class.invalid]="invalid"
        [class.small]="small">
  <svg viewBox="0 0 18 18" class="checkBoxSvg">
    <rect class="box" x="1.967" y="1.967" width="14.066" height="14.066" rx="3.6"/>
    <path class="ticker" d="M 7.164,12.965 C 6.87,12.965 6.59,12.85 6.38,12.64 l -2.37,-2.37 c -0.255,-0.255 -0.255,-0.67 0,-0.924 0.255,-0.255 0.67,-0.255 0.924,0 L 7.164,11.58 13.067,5.675 c 0.255,-0.255 0.668,-0.255 0.924,0 0.255,0.255 0.255,0.67 0,0.924 L 7.95,12.64 C 7.74,12.85 7.458,12.965 7.164,12.965 Z" />
    <text class="questionMark" x="6.5" y="12.5" font-size="12">?</text>
  </svg>
  <div class="label" *ngIf="label">{{label}}</div><ng-content></ng-content>
</button>
