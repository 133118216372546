import {decryptFromJson, None, Option, randomString, toEncryptedJson} from "@utils";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SessionCookiesService {

  private ORG_KEY = "95GWBM9KG3DA1SIJ39N4BUS63";
  private APP_KEY = "H3RIBP5PCDZXO8ZWHLZRFPUP6";

  constructor() {}

  deleteOrganizationSessionId(): void {

    Option.of(localStorage.getItem(this.ORG_KEY))
      .map(k => localStorage.removeItem(k));

    localStorage.removeItem(this.ORG_KEY);

  }

  deleteApplicationSessionId(): void {
    Option.of(localStorage.getItem(this.APP_KEY))
      .map(k => localStorage.removeItem(k));

    localStorage.removeItem(this.APP_KEY);
  }

  setOrganizationSessionToken(sessionToken: string): void {
    this.deleteOrganizationSessionId();
    const key = randomString(32);
    localStorage.setItem(this.ORG_KEY, key);
    localStorage.setItem(key, toEncryptedJson({s: sessionToken}, key));
  }

  setApplicationSessionToken(sessionToken: string): void {
    this.deleteApplicationSessionId();
    const key = randomString(32);
    localStorage.setItem(this.APP_KEY, key);
    localStorage.setItem(key, toEncryptedJson({s: sessionToken}, key));
  }

  getOrganizationSessionToken(): Option<string> {
    try {
      return Option.of(localStorage.getItem(this.ORG_KEY))
        .flatMapZip(k => Option.of(localStorage.getItem(k)))
        .map(ks => decryptFromJson(ks[1], ks[0]).s);
    } catch (e) {
      console.log("Error getting application session token: " + e);
      return None();
    }
  }


  getApplicationSessionToken(): Option<string> {
    try {
      return Option.of(localStorage.getItem(this.APP_KEY))
        .flatMapZip(k => Option.of(localStorage.getItem(k)))
        .map(ks => decryptFromJson(ks[1], ks[0]).s);
    } catch (e) {
      console.log("Error getting application session token: " + e);
      return None();
    }
  }
}
