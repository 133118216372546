<a class="entry" [ngClass]="{active: mainTasksAndProcessesActive}" routerLink="/tasks">
  <div class="content"><i class="icon mi-document-end-task"></i><span class="label">{{'main_menu_tasks' | i18n}}</span></div>
</a>
<a class="entry" [ngClass]="{active: applicationsActive}" routerLink="/applications">
  <div class="content"><i class="icon mi-apps"></i><span class="label">{{'main_menu_applications' | i18n}}</span></div>
</a>
<a class="entry" [ngClass]="{active: mainDashboardActive}" routerLink="/">
  <div class="content">
    <my-draw-in-logo />
  </div>
</a>
<a class="entry" [ngClass]="{active: documentsActive}" routerLink="/documents/.">
  <div class="content"><i class="icon mi-folder"></i><span class="label">{{'main_menu_documents' | i18n}}</span></div>
</a>
<button class="entry" (click)="showMainMenu()">
  <div class="content"><i class="icon mi-menu"></i><span class="label">{{'main_menu_menu' | i18n}}</span></div>
</button>
