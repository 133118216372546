import {Component, ViewContainerRef} from '@angular/core';
import {global, ResponsiveWidthMonitor} from "@utils";
import {NavigationService} from "@shared";


@Component({
  templateUrl: './register-organization-page.component.html'
})
export class RegisterOrganizationPageComponent {
  viewParamsMenuVisible: boolean = false;

  readonly responsive = ResponsiveWidthMonitor.of("register-organization-page", this.viewContainerRef);

  constructor(readonly viewContainerRef: ViewContainerRef,
              readonly navigationService: NavigationService) {

    const currentHost = window.location.href;
    const mainUrl = global.config.mainApplicationUrl;

    if(!currentHost.startsWith(mainUrl)) {
      this.navigationService.navigateToExternalUrl(mainUrl);
    }
  }
}
