import { Component } from '@angular/core';
import {global, organizationsLogoDownloadUrl} from "@utils";

@Component({
  selector: 'my-login-page-layout',
  templateUrl: './login-page-layout.component.html',
})
export class LoginPageLayoutComponent {
  viewParamsMenuVisible: boolean = false;
  organizationLogoUrl = global.config.organizationLogoUrl.getOrNull();
}
