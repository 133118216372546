import {AggregateId, DirectoryId, FileUri, LocalDateTime, Option, OrganizationNodeId} from "@utils";
import {FileVersionClientInfo} from "@shared";

export class FileVersionSummary {

  constructor(public fileId: AggregateId,
              public version: number,
              public created: LocalDateTime,
              public deleted: Option<LocalDateTime>,
              public deleteBy: Option<OrganizationNodeId>,
              public createdBy: OrganizationNodeId,
              public fileSize: number) {}

  static of(fileId: AggregateId, version: FileVersionClientInfo): FileVersionSummary {
    return new FileVersionSummary(fileId, version.id, version.created, version.deleted, version.deleter,
      version.creator, version.fileSize);
  }
}


export interface OneTimeFileId {
  readonly id: string;
}

export class PathSize {
  constructor(readonly id: DirectoryId,
              readonly parentId: DirectoryId,
              readonly size: number,
              readonly sizeWithAllVersions: number,
              readonly wholeSize: number) {}
  static copy(other: PathSize) {
    return new PathSize(other.id, other.parentId, other.size, other.sizeWithAllVersions, other.wholeSize);
  }
}

export class IsXmlInvoice {
  constructor(readonly uri: FileUri,
              readonly isKSeF: boolean,
              readonly isPEF: boolean) {}
  static copy(other: IsXmlInvoice) {
    return new IsXmlInvoice(FileUri.copy(other.uri), other.isKSeF, other.isPEF)
  }
}
