import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel, ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils
} from "../screen-component.view-model";
import {BlinkingValueComponentViewModel} from "./BlinkingComponentController";
import {decimalSeparator, nbspToSpace, None, NoneSingleton, Option, Some, thousandSeparator, VariableId} from "@utils";
import {ComponentIcons, ScreenInstanceServerModel, ScreenSharedViewModel} from "../..";
import {NumberVariable} from "@shared-model";
import {ScreenFormConfig} from "../../ScreenFormConfig";
import {ComponentsCommon} from "../ComponentsModel";
import {
  CssBuilder, CssUtils,
  NumberInputComponentDefinition,
  NumberInputComponentRef,
  TextAlign,
  TextFont
} from "@screen-common";
import {NumberInputComponentRefState, NumberInputComponentState} from "./NumberInputComponentState";
import {InputWithUnitViewModel} from "./AdjustableInputComponentController";

export class NumberInputComponentViewModel extends ComponentViewModelWithLabel implements BlinkingValueComponentViewModel, InputWithUnitViewModel {

  override typeName = "NumberInput";

  private valueBackup: number|null = null;

  public internalValue: string = "";
  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public unitCss: string = "";
  public css: string = "";
  public cssClasses: string = "";
  public outerCss: string = "";
  public outerCssClasses: string = "";
  public required: boolean = false;
  public multiLine: boolean = false;

  public minPrecision: Option<number> = NoneSingleton;
  public maxPrecision: Option<number> = NoneSingleton;
  public minValue: Option<number> = NoneSingleton;
  public maxValue: Option<number> = NoneSingleton;
  public unit: string = "";
  public unitVisible: boolean = false;
  public paddingRightCssValue: string = ""; // used for unit padding adjustment

  textAlignCenter: boolean = false;

  public valueChangeListener: Option<() => void> = NoneSingleton;


  startIcon: string|undefined = undefined;
  endIcon: string|undefined = undefined;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: NumberInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: NumberInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: NumberInputComponentState,
              readonly refState: NumberInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onFocus() {
    this.valueBackup = this.parseNumber(this.internalValue);
  }

  onBlur() {
    let value = (this.internalValue && this.internalValue.length > 0) ? this.parseNumber(this.internalValue) : null;
    if(value !== null && isNaN(value)) {
      value = null;
    }
    if(value !== null && !isNaN(value)) {
      this.internalValue = this.formatNumber(value, this.minPrecision, this.maxPrecision);
    } else {
      this.internalValue = "";
    }
    if(this.valueBackup != value) {
      if (value == null) {
        this.componentState.updateModel(NumberInputComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), NumberInputComponentDefinition.MODEL, NumberInputComponentDefinition.ON_CHANGE);
        this.internalValue = "";
      } else {
        this.componentState.updateModel(NumberInputComponentDefinition.MODEL, Some(new NumberVariable(value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), NumberInputComponentDefinition.MODEL, new NumberVariable(value), NumberInputComponentDefinition.ON_CHANGE);
      }
      this.valueBackup = this.parseNumber(this.internalValue);
    }
    this.updateWithInternalValue(value); // model value might not be up to date at this point
  }

  parseNumber(textValue: string): number|null {
    if(textValue.trim().length == 0) {
      return null;
    } else {
      return this.round(parseFloat(this.toParsableNumber(textValue)));
    }
  }

  updateComponent(deep: boolean): void {
    this.updateWithInternalValue(this.componentState.model.valueOrDefault(None()).getOrNull());
  }

  updateWithInternalValue(numberValue: number|null) {

    const cssBuilder = CssBuilder.create();
    const outerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState) ;
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.minPrecision = this.definition.minPrecision.currentValue(() => this.componentState.minPrecision).valueOrDefault(None());
    this.maxPrecision = this.definition.maxPrecision.currentValue(() => this.componentState.maxPrecision).valueOrDefault(None());

    const newInternalValue = this.formatNumber(numberValue, this.minPrecision, this.maxPrecision);
    if(this.emptyIfNull(this.internalValue) != newInternalValue) {
      this.valueChangeListener.forEach(listener => listener());
    }

    this.internalValue = newInternalValue;

    const negative = numberValue != null ? numberValue < 0 : false;

    const textSize = this.definition.textProperties.textSize(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.textState.textSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const textFont = this.definition.textProperties.textFont(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.textState.textFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

    // Will use different color for negative numbers
    const textColorValue = this.definition.textProperties.textColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.textState.textColor).valueOrDefault(None());
    const negativeTextColorValue = this.definition.negativeTextColor.currentValue(() => this.componentState.negativeTextColor).valueOrDefault(None());
    const textColor = negativeTextColorValue.filter(c => negative).orElse(textColorValue);

    CssUtils.fontCss(cssBuilder, textFont, textSize, false, false, false, false, textColor);

    const unit = ScreenFormConfig.panelUnitRemSize;
    const paddingTop = this.definition.paddingsProperties.paddingTop(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.paddingsState.paddingTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingLeft = this.definition.paddingsProperties.paddingLeft(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.paddingsState.paddingLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingBottom = this.definition.paddingsProperties.paddingBottom(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.paddingsState.paddingBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingRight = this.definition.paddingsProperties.paddingRight(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.paddingsState.paddingRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    CssUtils.paddings(cssBuilder, paddingTop, None(), paddingBottom, paddingLeft);

    const paddingTopCssValue = paddingTop.getOrElse("0.6125rem"); // 0.7 should match css default value
    const paddingRightCssValue = paddingRight.getOrElse("0.25rem"); // 0.3 should match css default value

    this.paddingRightCssValue = paddingRightCssValue; // this will be adjusted by controller when unit size will be available
    this.unitCss = `right: ${paddingRightCssValue}; padding-top: calc(${paddingTopCssValue} + 0.12em);`;

    if(this.preview) {
      this.placeholder = "-";
      this.tooltip = None();
    } else {
      this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
      this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("");
    }

    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.multiLine = this.definition.multiLine.currentValue(() => this.componentState.multiLine).valueOrDefault(false);

    this.minValue = this.definition.minValue.currentValue(() => this.componentState.minValue).valueOrDefault(None());
    this.maxValue = this.definition.maxValue.currentValue(() => this.componentState.maxValue).valueOrDefault(None());
    const unitName = this.definition.unitName.currentValue(() => this.componentState.unitName).valueOrDefault(None());

    this.unit = unitName.map(u => u.getCurrentWithFallback()).getOrElse("");
    this.unitVisible = unitName.exists(u => u.notEmpty());

    ComponentViewModelUtils.toOuterShadowCss(outerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(cssBuilder, innerShadow);

    let textAlign = TextAlign.byName(this.definition.textProperties.textAlign(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.textState.textAlign).valueOrDefault(TextAlign.DEFAULT.name));
    this.textAlignCenter = textAlign.isCenter();

    const icon = this.definition.icon.currentValue(() => this.componentState.icon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined);
    const iconPosition: string = this.definition.iconPosition.currentValue(() => this.componentState.iconPosition).valueOrDefault("center");

    // if no icon is defined, use the startIcon deprecated property
    this.startIcon = icon !== undefined && iconPosition === "start" ? icon : (
      this.definition.startIcon.currentValue(() => this.componentState.startIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    // if no icon is defined, use the endIcon deprecated property
    this.endIcon = icon !== undefined && iconPosition === "end" ? icon : (
      this.definition.endIcon.currentValue(() => this.componentState.endIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
    );

    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

    this.outerCss = outerCssBuilder.toCss();
    this.outerCssClasses = outerCssBuilder.toCssClasses();
  }

  private emptyIfNull(value: string|null) {
    if(value == null) {
      return "";
    } else {
      return value;
    }
  }

  formatNumber(value: number|null, minPrecision: Option<number>, maxPrecision: Option<number>): string {
    if(value != null) {
      const options: Intl.NumberFormatOptions = {};
      if (minPrecision.isDefined()) {
        options.minimumFractionDigits = minPrecision.get();
      }
      if (maxPrecision.isDefined()) {
        options.maximumFractionDigits = maxPrecision.get();
      }
      return value.toLocaleString([], options);
    } else {
      return "";
    }
  }

  isValid(value: number) {
    if(this.maxPrecision.isDefined()) {
      const precision = this.maxPrecision.get();
      const rounded = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
      return rounded === value;
    } else {
      return true;
    }
  }

  isNumeric(value: string) {
    let dotOnEnd = false;
    if(this.minPrecision.exists(p => p <= 0)) {
      dotOnEnd = value.indexOf(decimalSeparator) === value.length - 1;
    }
    const noE = this.toParsableNumber(value);
    return !dotOnEnd && !isNaN(parseFloat(noE)) && isFinite(<any>noE);
  }

  toParsableNumber(value: string) {
    const val = decimalSeparator !== '.' ? value.replace(/\./g, "?") : value;
    return nbspToSpace(val).replace(this.escapeRegExp(thousandSeparator), "").replace(this.escapeRegExp(decimalSeparator), ".").replace("e", "@")
  }

  escapeRegExp(value: string) {
    return value !== '.' ? new RegExp(value, "g") : /\./g;
  }

  round(value: number) {
    if(this.maxPrecision.isDefined()) {
      let scale = Math.pow(10, this.maxPrecision.get());
      return Math.round(value * scale) / scale;
    } else {
      return value;
    }
  }

  addValueChangeListener(valueChangeListener: () => void): void {
    this.valueChangeListener = Some(valueChangeListener);
  }

  onHeightAdjusted(): void {
    this.shared.eventBus.componentHeightChanged();
  }




}
