<i *ngIf="viewModel.startIcon" class="start" [ngClass]="viewModel.startIcon"></i>
<adjusting-text-input ngDefaultControl *ngIf="viewModel.multiLine"
                      class="input {{viewModel.cssClasses}}"
                      [style]="viewModel.css"
                      [disabled]="viewModel.disabled"
                      [class.disabled]="viewModel.disabled"
                      [placeholder]="viewModel.placeholder"
                      [(model)]="viewModel.value"
                      (blur)="viewModel.onBlur()"
                      (focus)="viewModel.onFocus()"></adjusting-text-input>
<input class="input {{viewModel.cssClasses}}" [style]="viewModel.css" *ngIf="!viewModel.multiLine"
       [placeholder]="viewModel.placeholder" [disabled]="viewModel.disabled" [(ngModel)]="viewModel.value"
       type="text" [class.disabled]="viewModel.disabled"
       (blur)="viewModel.onBlur()" (focus)="viewModel.onFocus()"
       (keydown.enter)="viewModel.onBlur()"
       [maxlength]="viewModel.maxLength"
/>
<i *ngIf="viewModel.endIcon" class="end" [ngClass]="viewModel.endIcon"></i>

<!--TODO-->

<!--my-on-enter="viewModel.onBlur()" [my-tooltip]="{position: 'top', text: viewModel.value}"-->

<!--ui-mask="{{viewModel.pattern}}"-->
<!--ui-options="{allowInvalidValue: true}"-->
<!--ui-mask-placeholder ui-mask-placeholder-char="_" model-view-value="true"-->
