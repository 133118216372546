import {TimeInputComponentRefState, TimeInputComponentState} from "./TimeInputComponentState";
import {
  ComponentsCommon,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel,
  ScreenWrapperViewModel
} from "../..";
import {LocalTime, None, NoneSingleton, Option, parseTimeInputString, Some, VariableId} from "@utils";
import {
  CssBuilder,
  DateInputComponentDefinition,
  TimeInputComponent,
  TimeInputComponentDefinition,
  TimeInputComponentRef
} from "@screen-common";
import {TimeVariable} from "@shared-model";
import {I18nService} from "@shared";

export class TimeInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "TimeInput";

  public value: LocalTime|null = null;

  public textModel: string|undefined = undefined;

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false;

  public focused: boolean = false;
  public anchorVisible: boolean = false;

  public innerCss: string = "";
  public innerCssClasses: string = "";
  public combinedCss = "";
  public combinedCssClasses: string = "";

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: TimeInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: TimeInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: TimeInputComponentState,
              readonly refState: TimeInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel,
              readonly i18nService: I18nService) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }




  onFocus() {
    if(!this.disabled && !this.focused) {
      this.focused = true;
    }
  }

  onPicked() {
    this.updateTextModel();
    this.updateValueChanged();
    this.closePicker();
  }

  onChanged() {
    this.updateTextModel();
  }

  parseTextModel(){
    if (this.textModel && this.textModel.trim().length > 0) {
      const time = parseTimeInputString(this.textModel);
      this.value = new LocalTime(time.getHours(), time.getMinutes(), 0, 0);
      this.updateTextModel();
    }
  }

  updateTextModel() {
    if (this.value === null) {
      this.textModel = undefined;
    } else {
      this.textModel = this.value.formattedToMinutes();
    }
  }

  closePicker() {
    this.focused = false;
  }

  textInputAccepted() {
    this.parseTextModel();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  };



  clear = () => {
    this.value = null;
    this.updateTextModel();
    this.updateValueChanged();
    this.closePicker();
  };

  setNow = () => {
    this.value = LocalTime.now();
    this.updateValueChanged();
    this.updateTextModel();
    this.closePicker();
  };


  updateValueChanged() {
    if(!this.disabled && this.uncoveredAndVisible) {

      if(this.value === null) {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), TimeInputComponent.model, TimeInputComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(DateInputComponentDefinition.MODEL, Some(new TimeVariable(this.value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), TimeInputComponent.model, new TimeVariable(this.value), TimeInputComponentDefinition.ON_CHANGE);
      }
    }
  }


  updateComponent(deep: boolean): void {

    const outsideCssBuilder = new CssBuilder();
    const innerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toSizeCss(outsideCssBuilder, this.definition.sizeProperties, this.componentState.boxState);
    ComponentViewModelUtils.toOuterShadowCss(outsideCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    ComponentViewModelUtils.toBackgroundCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toPaddingsCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toTextCss(innerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("hh:mm");
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(false);

    this.value = this.componentState.model.valueOrDefault(None()).getOrNull();
    this.updateTextModel();

    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(innerCssBuilder, innerShadow);
    super.updatePosition();

    this.combinedCss = outsideCssBuilder.toCss() + this.sizeCss;
    this.combinedCssClasses = outsideCssBuilder.toCssClasses();

    this.innerCss = innerCssBuilder.toCss();
    this.innerCssClasses = innerCssBuilder.toCssClasses();
  }

}
