<div class="entryName" *ngIf="notRoot && nameEnabled">
  <div class="entryNameContent">
    <button class="expand" (click)="entry.toggleExpanded(); sizeChanged.emit()">
      <i class="mi-navigate-down theme-expand-arrow" [class.theme-expanded]="entry.expanded"></i>
    </button>
    <span class="entryLabel" *ngIf="showLabels && entry.fieldName.length > 0" [my-tooltip]="{text: entry.codeName}">{{entry.fieldName}}</span>
    <span class="codeName" *ngIf="showNames || entry.fieldName.length == 0" [my-tooltip]="{text: entry.fieldName}">{{entry.codeName}}</span>
    <span class="typeName" *ngIf="showTypes" [my-tooltip]="{text: entry.fieldName}">{{entry.dataTypeName}}</span>
    <span *ngIf="!entry.expanded && entry.subEntriesCount > 0" class="contentAvailable" (click)="entry.toggleExpanded(); sizeChanged.emit()">...</span>
  </div>
</div>

<ng-container *ngIf="!nameEnabled">

  <span *ngIf="!entry.expanded && entry.subEntriesCount > 0" class="contentAvailable" (click)="entry.toggleExpanded(); sizeChanged.emit()">...</span>

  <div class="entryName">
    <button class="expand" (click)="entry.toggleExpanded(); sizeChanged.emit()">
      <i class="mi-navigate-down theme-expand-arrow" [class.theme-expanded]="entry.expanded"></i>
    </button>
  </div>

</ng-container>



<div class="entryValue" *ngIf="entry.expanded">
  <ng-container *ngFor="let subEntry of entry.subEntries">
    <metadata-object [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" *ngIf="subEntry.objectType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes" [notRoot]="true"></metadata-object>
    <metadata-array [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" *ngIf="subEntry.arrayType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes" [notRoot]="true"></metadata-array>
    <metadata-simple [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" *ngIf="subEntry.simpleType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes"></metadata-simple>
  </ng-container>
</div>

<div class="entryError" *ngIf="validateType && (entry.notFromType || entry.incorrectType)">
  <i class="mi-sensor-alert warning" *ngIf="!entry.incorrectType && entry.notFromType" [my-tooltip]="{text: 'Not from type'}"></i>
  <i class="mi-sensor-alert error" *ngIf="entry.incorrectType" [my-tooltip]="{text: 'Incorrect type, expected '+entry.expectedTypeName+' but was ' + entry.fieldTypeName}"></i>
</div>
