import {Component, Input, OnInit} from "@angular/core";
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {__, i18n, Option} from "@utils";
import {LocaleListService} from "../../../modules/user.module/serivces/locale-list/locale-list.service";
import {OrganizationInfo, OrganizationSharedService} from "@shared-model";

export class TimeZoneOption implements DropDownSelectorOption {
  selected = false;
  nameOnList: string = "";

  constructor(readonly value: string|null,
              public name: string) {}
}

@Component({
  selector: "my-user-time-zone-selector",
  templateUrl: "./user-time-zone-selector.component.html"
})
export class UserTimeZoneSelectorComponent implements OnInit {

  organizationTimeZone: string = null!;
  timeZone: string|null = null;
  timeZoneOptions: Array<TimeZoneOption> = [];
  defaultTimeZone: TimeZoneOption = null!;
  selectedTimeZone: string|null = null;

  @Input() showExpandIcon: boolean = false;

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly organizationSharedService: OrganizationSharedService,
              readonly localeListService: LocaleListService) {

    this.defaultTimeZone = new TimeZoneOption(null, this.organizationTimeZone);

    this.organizationSharedService.getOrganizationInfo((organizationInfo: OrganizationInfo) => {
      this.organizationTimeZone = organizationInfo.settings.timezone;
      this.defaultTimeZone.name = this.organizationTimeZone;
      this.defaultTimeZone.nameOnList = i18n("common_default_female");
    });

    this.timeZoneOptions = [
      this.defaultTimeZone,
      ...this.localeListService.getAllTimeZones().map(tz => new TimeZoneOption(tz, tz))
    ]

    this.userSettingsService.getTimeZoneObservable().forEach(timeZone => {
      this.timeZone = timeZone.getOrNull();
    });
  }

  ngOnInit(): void {
    const timeZoneFromSettings = this.userSettingsService.getTimeZone().getOrNull();
    this.timeZone = timeZoneFromSettings || this.timeZoneOptions[0].name;
    this.timeZoneOptions.forEach(tz => tz.selected = this.timeZone === tz.value);
    this.selectedTimeZone = this.timeZone;
  }

  timeZoneChanged(timeZone: string) {
    this.timeZone = timeZone;
    this.timeZoneOptions.forEach(tz => tz.selected = timeZone === tz.value);
    this.userSettingsService.changeTimeZone(Option.of(this.timeZone));
  }
}
