import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider, HeaderProperties, LayoutsProperties,
  PaddingsProperties, RepeatableContextContainerDefinition, ScreenContainerRef
} from "@screen-common";
import {
  BooleanProperty,
  I18nTextProperty, ModelProperty,
  OptionalI18nTextProperty,
  OptionalNumberProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {EntriesLayoutType, LayoutWrap, ScreenComponentId} from "@screen-common";
import {i18n, I18nText, Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";


export class RepeatableContainer {
    static model = "model"
  }

  export class RepeatableContainerRef extends ScreenContainerRef {
    static className = "RepeatableContainerRef";
    className() {
      return RepeatableContainerRef.className;
    }

    constructor(
      readonly id: ScreenComponentRefId,
      readonly componentId: ScreenComponentId,
      readonly applicationScreen: Option<ScreenId>,
      readonly properties: ComponentProperties
    ) {
      super();
    }

    get lengthEditable() {
      return this.properties.getBooleanProperty("lengthEditable", this.defaultPropertiesProvider);
    }

    get sortable() {
      return this.properties.getBooleanProperty("sortable", this.defaultPropertiesProvider);
    }

    static copy(other: RepeatableContainerRef): RepeatableContainerRef {
      return new RepeatableContainerRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      )
    }

    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("RepeatableRef", (name:string) => {
      switch (name) {
        case "lengthEditable": return BooleanProperty.ofFalse();
        case "sortable": return BooleanProperty.ofFalse();
        default: return null;
      }
    }, DefaultPropertyProvider.ref);

    override defaultPropertiesProvider = RepeatableContainerRef.DEFAULT_PROPERTIES;
  }

  export class RepeatableContainerDefinition extends RepeatableContextContainerDefinition {

    static className = "RepeatableContainerDefinition";
    className() {
      return RepeatableContainerDefinition.className;
    }
    typeName() {
      return "Repeatable";
    }

    static ON_CHANGE = "onChange";


    constructor(
      override readonly id: ScreenComponentId,
      override readonly identifier: Option<string>,
      override readonly properties: ComponentProperties,
      readonly actionProperties: ComponentActionProperties,
      override readonly validationRules: ComponentValidationRules,
      public children: Array<ScreenComponentRefId>) {
      super(id, identifier, properties, validationRules, RepeatableContainerDefinition.DEFAULT_PROPERTIES);
    }

    get header(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("header", this.defaultPropertiesProvider)};
    get headerVisible(): BooleanProperty {return this.properties.getBooleanProperty("headerVisible", this.defaultPropertiesProvider)};
    get model(): ModelProperty {return this.properties.getModelProperty("model", this.defaultPropertiesProvider)};
    get entriesLayout(): StringProperty {return this.properties.getStringProperty("entriesLayout", this.defaultPropertiesProvider)};
    get entriesLayoutWrap(): StringProperty {return this.properties.getStringProperty("entriesLayoutWrap", this.defaultPropertiesProvider)};

    get entriesGapColumn(): OptionalStringProperty {return this.properties.getOptionalStringProperty("entriesGapColumn", this.defaultPropertiesProvider)};
    get entriesGapRow(): OptionalStringProperty {return this.properties.getOptionalStringProperty("entriesGapRow", this.defaultPropertiesProvider)};

    get addLabel(): I18nTextProperty {return this.properties.getI18nTextProperty("addLabel", this.defaultPropertiesProvider)};
    get entryVisible(): BooleanProperty {return this.properties.getBooleanProperty("entryVisible", this.defaultPropertiesProvider)};
    get entryEditable(): BooleanProperty {return this.properties.getBooleanProperty("entryEditable", this.defaultPropertiesProvider)};

    layoutsProperties = new LayoutsProperties("", this.properties, this.defaultPropertiesProvider);

    contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextSize", defaultProvider)}
    contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextColor", defaultProvider)}
    contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextFont", defaultProvider)}

    paddingsProperties = new PaddingsProperties("", this.properties);
    bordersProperties = new BordersProperties(this.properties);
    backgroundsProperties = new BackgroundsProperties("", this.properties);
    headerProperties = new HeaderProperties(this.properties);

    override getChildren(): Array<ScreenComponentRefId> {
      return this.children;
    }

    get minEntries() {
      return this.properties.getOptionalNumberProperty("minEntries", this.defaultPropertiesProvider);
    }

    get maxEntries() {
      return this.properties.getOptionalNumberProperty("maxEntries", this.defaultPropertiesProvider);
    }


    static copy(other: RepeatableContainerDefinition): RepeatableContainerDefinition {
      return new RepeatableContainerDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules),
        other.children.map(ScreenComponentRefId.copy)
      )
    }

    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("RepeatableDefinition", (name:string) => {
      switch (name) {
        case "headerVisible": return BooleanProperty.ofFalse();
        case "addLabel": return I18nTextProperty.of(I18nText.ofCurrent(i18n("screen_default_add_label")));
        case "minEntries": return OptionalNumberProperty.disabled(1);
        case "maxEntries": return OptionalNumberProperty.disabled(10);
        case "entryEditable": return BooleanProperty.ofTrue();
        case "entriesLayout": return StringProperty.of(EntriesLayoutType.vertical.name);
        case "entriesLayoutWrap": return StringProperty.of(LayoutWrap.wrap.name);
        case "entriesGapRow": return OptionalStringProperty.disabled("0.5cm");
        case "entriesGapColumn": return OptionalStringProperty.disabled("0.5cm");
        case "entryVisible": return BooleanProperty.ofTrue();
        default: return null;
      }},
      DefaultPropertyProvider.definitionLabel,
      DefaultPropertyProvider.containerPlain,
      DefaultPropertyProvider.containerPaddings,
      DefaultPropertyProvider.definitionBorders,
      DefaultPropertyProvider.definitionBackgrounds,
      DefaultPropertyProvider.definitionSectionHeader,
      DefaultPropertyProvider.definitionComponent,
      DefaultPropertyProvider.definitionBorders);

      override displayName(): string {
      return (this.header.settingsUnwrapped().toSettingsType().isStaticValue() ? this.header.staticValue.getCurrentWithFallback() : "");
    }
  }
