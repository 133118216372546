import {Injectable} from "@angular/core";
import {AuthenticatedHttp, LaunchableSummary} from "..";
import {AggregateId} from "@utils";
import {FindMiniModels} from "./process.commands";
import {MiniGridProcessModel} from "./MiniGridProcessModel";

class FindLaunchableInstances {}

@Injectable({
  providedIn: 'root'
})
export class ProcessSharedService {

  findMiniModelsCache: {[key: string]: MiniGridProcessModel} = {};

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  loadLaunchables(onSuccess: (data: Array<LaunchableSummary>) => void) {
    this.authenticatedHttp.post("processes-designs-projection/launchables", new FindLaunchableInstances(),
      (data: Array<LaunchableSummary>) => {
        onSuccess(data.map(LaunchableSummary.copy));
      }
    )
  }

  findMiniModels(releases: Array<AggregateId>, onSuccess: (miniModels: Array<MiniGridProcessModel>) => void): void {
    const fromCache: MiniGridProcessModel[] = [];
    const toLoad: AggregateId[] = [];

    releases.forEach(r => {
      const cached = this.findMiniModelsCache[r.id];
      if(cached) {
        fromCache.push(cached);
      } else {
        toLoad.push(r);
      }
    });

    if (toLoad.length > 0) {
      this.authenticatedHttp.post(
        "process/find-mini-models",
        new FindMiniModels(AggregateId.unique(toLoad)),
        (data: Array<MiniGridProcessModel>) => {
          const dataCopied = data.map(MiniGridProcessModel.copy);
          dataCopied.forEach(l => {
            this.findMiniModelsCache[l.releaseId.id] = l;
          });

          onSuccess(dataCopied.concat(fromCache));
        }
      )
    } else {
      onSuccess(fromCache);
    }
  }
}
