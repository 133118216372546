<div class="i18nTextInput theme-input-with-hint">
  <input *ngIf="!multiline" type="text" [(ngModel)]="internalText" (blur)="internalOnBlur()"
         [focus-on-show]="focusOnShow" (focus)="focus.emit()"
         [placeholder]="placeholder" (ngModelChange)="internalOnChange()"
         (keydown.enter)="onEnter()" (keydown.escape)="onEscape.emit()" [readonly]="disabled"/>

  <adjusting-text-input *ngIf="multiline" ngDefaultControl
                        [focus-on-show]="focusOnShow"
                         class="theme-input"
                        (focus)="focus.emit($event)"
                         [placeholder]="placeholder"
                         [(model)]="internalText"
                         (change)="internalOnChange()"
                         (blur)="internalOnBlur()" [disabled]="disabled"
                         [large]="large"></adjusting-text-input>

  <button class="languageInfo" [class.missingLanguage]="missingCurrentLanguage" (click)="nextLanguage()">{{language.name}}</button>
  <span class="theme-input-hint" *ngIf="hint">{{hint}}</span>
</div>
