import {Directive, ElementRef, Input, OnDestroy, OnInit} from "@angular/core";
import {htmlEscape, i18n, I18nText, toastr} from "@utils";
import {TooltipController} from "./tooltip.controller";


interface TooltipParams {
  text: string | I18nText | undefined;
  i18n: string | undefined;
  prefix: string | I18nText| undefined;
  position: "top" | "left" | "bottom" | "right" | undefined;
  delay: number | undefined;
  mobileVisibility: boolean | undefined;
  enabled: boolean | undefined;
  error: boolean | undefined;
  alwaysVisible: boolean | undefined;
}

@Directive({
  selector: '[my-tooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {

  private _myTooltipParams: Partial<TooltipParams> = null!;
  private params?: TooltipParams;
  private contentToDisplay: Array<HTMLElement> = [];

  @Input("my-tooltip") set myTooltipParams(t: Partial<TooltipParams>) {
    this._myTooltipParams = t;
    this.initData();
  };


  private readonly controller: TooltipController;

  constructor(elementRef: ElementRef) {
    this.controller = new TooltipController(<HTMLElement>elementRef.nativeElement, () => {
      return this.contentToDisplay;
    }, () => "", () => {});
  }

  ngOnInit() {
    if(this.params !== undefined) {
      this.initData();
      this.controller.init();
    } else {
      console.log("No tooltip data");
    }
  }

  private initData() {
    this.params = this._myTooltipParams as TooltipParams;
    this.controller.updateParams(this.params.position, this.params.delay, this.params.mobileVisibility, this.params.enabled, this.params.error, this.params.alwaysVisible, "center");

    const tooltipText: string = this.params.i18n
      ? this.extractText(i18n(this.params.i18n))
      : this.extractText(this.params.text);

    const titleText: string = this.extractText(this.params.prefix);

    const contentToDisplay: Array<HTMLElement> = [];
    if(titleText.length > 0) {
      const tooltipTitle = document.createElement("span");
      tooltipTitle.classList.add("tooltipTitle");
      tooltipTitle.textContent = htmlEscape(titleText);
      contentToDisplay.push(tooltipTitle);
    }

    if(tooltipText.length > 0) {
      const textSpan = document.createElement("span");
      textSpan.classList.add("tooltipText");
      textSpan.textContent = tooltipText;
      contentToDisplay.push(textSpan);
    }

    this.contentToDisplay = contentToDisplay;
  }

  ngOnDestroy(): void {
    this.controller.destroy();
  }


  private extractText(t: string | I18nText | undefined, suffix: string = ""): string {
    const text = t instanceof I18nText ? t.getCurrentWithFallback() : (t === undefined ? "" : t);
    if(text.length > 0) {
      return text + suffix;
    } else {
      return "";
    }
  }
}
