import {Injectable} from "@angular/core";
import {Subject} from "rxjs";


export enum PageLayout {
  simple,
  mainMenu
}

@Injectable({
  providedIn: 'root',
})
export class PageLayoutService {
  nextLayout: PageLayout|null = null;
  layout: PageLayout = PageLayout.simple;
  layoutSubject = new Subject<PageLayout>();

  applyPageLayout() {
    if(this.nextLayout != null) {
      this.layout = this.nextLayout;
      this.nextLayout = null;
      this.layoutSubject.next(this.layout);
    }
  }

  getLayoutObservable() {
    return this.layoutSubject.asObservable();
  }

  setNextLayout(pageLayout: PageLayout) {
    this.nextLayout = pageLayout;
  }
}
