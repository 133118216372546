import {EventBus, ObjectId, Option} from "@utils";
import {FormColumnModel} from "./FormColumnModel";
import {BusinessVariable, ObjectVariable, VariablePath} from "@shared-model";

/** Used for comunication between data table and row preview in form mode */
export class DataTableFormEventBus extends EventBus {

  formShown() {}

  rowSelected(rowIndex: number, rowData: Array<FormColumnModel>, rowPureData: ObjectVariable){}

  rowDeselected() {}

  formValueChanged(rowIndex: number, rowId: ObjectId, path: VariablePath, value: BusinessVariable | null) {}
}
