import {Component, Input} from "@angular/core";
import {MultiCheckboxComponentViewModel} from "./MultiCheckboxComponentViewModel";

@Component({
  selector: 'my-multi-checkbox-component',
  templateUrl: './multi-checkbox-component.component.html',
  host: {
    "[class]": "'componentBody MultiCheckboxComponent ' + viewModel.classes + ' ' + viewModel.cssClasses",
    "[class.enabled]": "!viewModel.disabled",
    "[class.invalid]": "viewModel.invalid",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class MultiCheckboxComponentComponent {
  @Input({required:true}) viewModel!: MultiCheckboxComponentViewModel;

  protected readonly caches = caches;
}
