import {FileUri, Option} from "@utils";
import {FileInfo} from "@shared";

export class NodeActivity {

  constructor(readonly id: number,
              readonly name: string,
              readonly required: boolean) {}

  static copy(other: NodeActivity) {
    return new NodeActivity(other.id, other.name, other.required);
  }

  static equal(a: NodeActivity, b: NodeActivity) {
    return a.id === b.id && a.name === b.name && a.required === b.required;
  }
}

export class ProcessResource {

  constructor(public name: string,
              public unit: string,
              public quantity: number) {}

  static copy(other: ProcessResource) {
    return new ProcessResource(other.name, other.unit, other.quantity);
  }

  static equal(a: ProcessResource, b: ProcessResource) {
    return a.name === b.name && a.unit === b.unit && a.quantity === b.quantity;
  }

  static copyFromToAFromB(a: ProcessResource, b: ProcessResource) {
    a.name = b.name;
    a.unit = b.unit;
    a.quantity = b.quantity;
  }

  static nonEmpty(other: ProcessResource) {
    return other.name !== "" || other.unit !== "" || other.quantity !== null && other.quantity !== 0;
  }
}

export class DesignAttachment {
  public basicInfo: FileInfo|undefined = undefined;

  constructor(readonly id: number,
              readonly name: string,
              readonly fileInfo: Option<FileUri>) {
  }

  static copy(other: DesignAttachment): DesignAttachment {
    return new DesignAttachment(other.id, other.name, Option.copy(other.fileInfo).map(f => FileUri.copy(f)));
  }

  static equal(a: DesignAttachment, b: DesignAttachment) {
    return a.id === b.id && a.name === b.name; // TODO consider fileinfo?
  }
}

// Reflects server side
export class NodeProperties {


  constructor(
    public activities: Array<NodeActivity>,
    public attachments: Array<DesignAttachment>,
    public systems: Array<string>,
    public documents: Array<string>,
    public resources: Array<ProcessResource>
  ) {}

  static copy(other:NodeProperties) {
    return new NodeProperties(
      other.activities.map(NodeActivity.copy),
      other.attachments.map(DesignAttachment.copy),
      other.systems.slice(0),
      other.documents.slice(0),
      other.resources.map(ProcessResource.copy)
    );
  }

  static newEmpty() {
    return new NodeProperties([], [], [], [], []);
  }

}
