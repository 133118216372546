import {AggregateId, ApplicationId} from "@utils";
import {Action} from "@shared-model";

export class AuthorizeActionsByReport {
  constructor(readonly reportId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByConstant {
  constructor(readonly constantId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByDatastore {
  constructor(readonly datastoreId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByCasesCalendar {
  constructor(readonly casesCalendarId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByProcess {
  constructor(readonly processId: AggregateId,
              readonly actions: Array<Action>) { }
}

export class AuthorizeActionsByInstance {
  constructor(readonly instanceId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByFlow {
  constructor(readonly flowId: AggregateId,
              readonly instanceId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByGroup {
  constructor(readonly groupId: AggregateId,
              readonly actions: Array<Action>) { }
}

export class AuthorizeActionsByOrganization {
  constructor(readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByKanban {
  constructor(readonly kanbanId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByIncomingMailbox {
  constructor(readonly incomingMailboxId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByDatabaseConnection {
  constructor(readonly databaseConnectionId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByApplication {
  constructor(readonly applicationId: ApplicationId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByRemoteOrganization {
  constructor(readonly remoteOrganizationId: AggregateId,
              readonly actions: Array<Action>) {}
}

export class AuthorizeActionsByFunctions {
  constructor(readonly functionsId: AggregateId,
              readonly actions: Array<Action>) {}
}
