import {
  __,
  estimateTextWidth,
  findSubstringFitting,
  GridXY,
  None,
  Option,
  PositionXY,
  required,
  Size,
  Some, valueOrDefaultLazy
} from "@utils";
import {XYCalculator} from "./XYCalculator";
import {NodeType, ProcessEdgeId, ProcessNodeId} from "@shared-model";
import {DesignMapConfig} from "./Config";
import {EdgeEndPosition, ProcessEdgeViewModel, ProcessNodeViewModel} from "./ProcessMapCommonViewModel";
import {GridProcessNode} from "../model/GridProcessNode";

export class EdgeLabelRect {
  constructor(readonly edge: ProcessEdgeViewModel,
              readonly x: number,
              readonly y: number,
              readonly width: number,
              readonly height: number) {}
}

export class EdgeLabelPosition {

  constructor(public x: number,
              public y: number,
              public vertical: boolean,
              public shortenedLabel: string) {}
}

export class EdgeWithShift {

  constructor(readonly edge: ProcessEdgeViewModel,
              readonly path: GridXY[],
              readonly labelCenterPosition: EdgeLabelPosition,
              readonly defaultEdge: boolean) {}

  static sortNorthToSouth(edges: Array<ProcessEdgeViewModel>): Array<ProcessEdgeViewModel> {
    return __(edges).sortBy((edge: ProcessEdgeViewModel) => {
      const first: GridXY = edge.path.points[0];
      const last: GridXY = edge.path.points[edge.path.points.length - 1];
      // distance from top left, should be average of dimensions but division by 2 removed for simplicity
      return first.gridY + last.gridY + first.gridX + last.gridX;
    });
  }

}

export class ReservedShifts {
  shifts: Array<number> = [];

  contains(shift: number): boolean {
    return __(this.shifts).contains(shift);
  }

  add(shift: number) {
    this.shifts.push(shift);
  }
}


export class EdgeXYCalculator {


  constructor(readonly xyCalculator: XYCalculator,
              readonly config: DesignMapConfig) {
  }

  //scale to print in different sizes for example in print mode
  findShifts(edges: Array<ProcessEdgeViewModel>, nodes: Array<ProcessNodeViewModel>, scale: number = 1): EdgeWithShift[] {

    const gridSize = this.findPathMaximumCoordinates(edges);

    const verticalShifts: Array<Array<ReservedShifts>> = this.initShiftsArray(gridSize);
    const horizontalShifts: Array<Array<ReservedShifts>> = this.initShiftsArray(gridSize);


    const shiftedEdges: EdgeWithShift[] = edges.map(edge => {
      const path: GridXY[] = edge.path.points.map(point => new GridXY(point.gridX, point.gridY));
      const nodeFrom = this.findNodeById(nodes, edge.fromNodeId);
      const defaultEdge = nodeFrom.nodeType.isCondition()
        ? nodeFrom.requiredCondition().defaultEdge !== undefined && nodeFrom.requiredCondition().defaultEdge === edge.id
        : false;
      return new EdgeWithShift(edge, path, this.findLabelPosition(nodes, edge, path, scale), defaultEdge);
    });


    return shiftedEdges;
  }

  findNodeById(nodes: Array<ProcessNodeViewModel>, id: ProcessNodeId): ProcessNodeViewModel {
    return required(nodes.find(node => node.id === id), "node");
  }

  // We want to place text in center of first section of the path, so we operate on first two points of the path
  //scale to print in different sizes for example in print mode
  private findLabelPosition(nodes: Array<ProcessNodeViewModel>, edge: ProcessEdgeViewModel, path: GridXY[], scale: number = 1): EdgeLabelPosition {

    const edgeNewName = (edge.delay && edge.name.isEmpty())
      ? ""
      : edge.name.getCurrentWithFallback();

    let edgeName = edge.delay
      ? String.fromCharCode(parseInt("23F0", 16 * scale)).concat(" ").concat(edgeNewName)
      : edgeNewName;

    if(edgeName.trim().length === 0) {
      edgeName = "mock"; // to avoid empty label
    } else {
      edgeName.trim();
    }

    const labelPadding = 12 * scale;

    const estimatedTextLength = estimateTextWidth(edgeName, 10 * scale) + labelPadding;

    let sectionIndex = 0;
    let result: EdgeLabelPosition|null = null;
    let spaceForLabel: number = -1;
    let largestSpace = 0;

    do {

      const xDirection = Math.min(1, Math.max(-1, (path[sectionIndex + 1].gridX - path[sectionIndex].gridX))); // -1 - left, 0 - none, 1 - right
      const yDirection = Math.min(1, Math.max(-1, (path[sectionIndex + 1].gridY - path[sectionIndex].gridY))); //-1 - top, 0 - none, 1 - bottom

      const nodeAtEnd = path.length === sectionIndex + 2 ? 1 : 0; // 1 - yes, 0 - no - if first section ends with node we need to shift position of end point by half the size of the node

      const sectionStartPosition = this.xyCalculator.gridXYToPathPosition(path[sectionIndex]);
      const sectionEndPosition = this.xyCalculator.gridXYToPathPosition(path[sectionIndex + 1]);


      const startNodeCenterPosition = this.xyCalculator.gridXYToCellCenterPosition(this.findNodeById(nodes, edge.fromNodeId).gridXY());
      const endNodeCenterPosition = this.xyCalculator.gridXYToCellCenterPosition(this.findNodeById(nodes, edge.toNodeId).gridXY());

      let sectionStartNodeSize = new Size(16, 16).scale(scale);  // corner radius
      if (sectionIndex === 0) {
        const startDifference = startNodeCenterPosition.minus(sectionStartPosition);
        sectionStartNodeSize = NodeType.nodeSize[this.findNodeById(nodes, edge.fromNodeId).nodeType.name].scale(scale).plus(-Math.abs(startDifference.width), -Math.abs(startDifference.height));
      }
      let sectionEndNodeSize = new Size(16, 16).scale(scale); // corner radius
      if (sectionIndex + 2 === path.length) {
        const endDifference = endNodeCenterPosition.minus(sectionEndPosition);
        sectionEndNodeSize = NodeType.nodeSize[this.findNodeById(nodes, edge.toNodeId).nodeType.name].scale(scale).plus(-Math.abs(endDifference.width), -Math.abs(endDifference.height));
      }


      const xStartOfSection = sectionStartPosition.x + xDirection * sectionStartNodeSize.width / 2;
      const yStartOfSection = sectionStartPosition.y + yDirection * sectionStartNodeSize.height / 2;

      const xEndOfSection = sectionEndPosition.x - nodeAtEnd * xDirection * sectionEndNodeSize.width / 2;
      const yEndOfSection = sectionEndPosition.y - nodeAtEnd * yDirection * sectionEndNodeSize.height / 2;

      const centerX = (xStartOfSection + xEndOfSection) / 2;
      const centerY = (yStartOfSection + yEndOfSection) / 2;

      const vertical = xDirection === 0;

      const textHorizontalCenterShiftX = vertical ? this.config.verticalEdgesLabelsDirection * 3.5 * scale : 0; // in svg text is positioned based on bottom line so we need to shift it to center
      const textHorizontalCenterShiftY = vertical ? 0 : 3.5 * scale;

      spaceForLabel = vertical ? Math.abs(yEndOfSection - yStartOfSection) : Math.abs(xEndOfSection - xStartOfSection);

      if(spaceForLabel > largestSpace) { // only pu in larges section so far
        result = new EdgeLabelPosition(centerX + textHorizontalCenterShiftX, centerY + textHorizontalCenterShiftY, vertical, "");
        largestSpace = spaceForLabel;
      }

      sectionIndex++;
    } while (spaceForLabel < estimatedTextLength && sectionIndex < path.length - 1);



    if(result == null) {
      throw new Error("No result");
    } else {

      if (estimatedTextLength > spaceForLabel) {
        if (path.length === 2 && spaceForLabel < (result.vertical ? this.config.nodeSize.height : this.config.nodeSize.width)) {
          if (result.vertical) {
            result.x -= this.config.verticalEdgesLabelsDirection * 3.5 * scale;
            result.y += 3.5 * scale;
          } else {
            result.x += this.config.verticalEdgesLabelsDirection * 3.5 * scale;
            result.y -= 3.5 * scale;
          }

          result.vertical = !result.vertical;
          result.shortenedLabel = findSubstringFitting(edgeName, 10 * scale, (result.vertical ? this.config.nodeSize.height : this.config.nodeSize.width))
        } else {
          result.shortenedLabel = findSubstringFitting(edgeName, 10 * scale, largestSpace - 8 * scale);
        }
      } else {
        result.shortenedLabel = edgeName;
      }

      return result;
    }
  }

  private verticalShiftForLine(fromPoint: GridXY, toPoint: GridXY, verticalShifts: Array<Array<ReservedShifts>>): number {
    let possibleShift = 0;
    while (!this.verticalShiftAvailable(possibleShift, fromPoint, toPoint, verticalShifts)) {
      possibleShift = this.nextShift(possibleShift);
    }
    this.addVerticalShift(possibleShift, fromPoint, toPoint, verticalShifts);
    return possibleShift;
  }

  private horizontalShiftForLine(fromPoint: GridXY, toPoint: GridXY, horizontalShifts: Array<Array<ReservedShifts>>): number {
    let possibleShift = 0;
    while (!this.horizontalShiftAvailable(possibleShift, fromPoint, toPoint, horizontalShifts)) {
      possibleShift = this.nextShift(possibleShift);
    }
    this.addHorizontalShift(possibleShift, fromPoint, toPoint, horizontalShifts);
    return possibleShift;
  }

  private nextShift(possibleShift: number): number {
    if (possibleShift > 0) {
      possibleShift = -possibleShift;
    } else {
      possibleShift = -possibleShift + 1;
    }
    return possibleShift;
  }

  private verticalShiftAvailable(shift: number, fromPoint: GridXY, toPoint: GridXY, verticalShifts: Array<Array<ReservedShifts>>): boolean {
    const orderedPoints = this.orderedVerticalPoints(fromPoint, toPoint);
    for (let y = orderedPoints.fromY; y < orderedPoints.toY; y++) {
      if (verticalShifts[y][orderedPoints.x].contains(shift)) {
        return false;
      }
    }
    return true;
  }

  private horizontalShiftAvailable(shift: number, fromPoint: GridXY, toPoint: GridXY, shifts: Array<Array<ReservedShifts>>): boolean {
    const orderedPoints = this.orderedHorizontalPoints(fromPoint, toPoint);
    for (let x = orderedPoints.fromX; x < orderedPoints.toX; x++) {
      if (shifts[orderedPoints.y][x].contains(shift)) {
        return false;
      }
    }
    return true;
  }


  private orderedHorizontalPoints(fromPoint: GridXY, toPoint: GridXY) {
    if (fromPoint.gridY != toPoint.gridY) {
      throw new Error("Horizontal line required");
    }
    const y = fromPoint.gridY;
    if (fromPoint.gridX < toPoint.gridX) {
      const fromX = fromPoint.gridX;
      const toX = toPoint.gridX;
      return {y:y, fromX: fromX, toX: toX};
    } else {
      const fromX = toPoint.gridX;
      const toX = fromPoint.gridX;
      return {y:y, fromX: fromX, toX: toX};
    }
  }

  private orderedVerticalPoints(fromPoint: GridXY, toPoint: GridXY) {
    if (fromPoint.gridX != toPoint.gridX) {
      throw new Error("Vertical line required");
    }
    const x = fromPoint.gridX;
    if (fromPoint.gridY < toPoint.gridY) {
      const fromY = fromPoint.gridY;
      const toY = toPoint.gridY;
      return {x: x, fromY: fromY, toY: toY};
    } else {
      const fromY = toPoint.gridY;
      const toY = fromPoint.gridY;
      return {x: x, fromY: fromY, toY: toY};
    }
  }

  private findPathMaximumCoordinates(edges: Array<ProcessEdgeViewModel>) {
    let maxEdgeX = 0;
    let maxEdgeY = 0;
    edges.forEach(edge => {
      edge.path.points.forEach(point => {
        if (point.gridX > maxEdgeX) {
          maxEdgeX = point.gridX;
        }
        if (point.gridY > maxEdgeY) {
          maxEdgeY = point.gridY;
        }
      });
    });
    return new GridXY(maxEdgeX, maxEdgeY);
  }

  private initShiftsArray(maximumCoords: GridXY) {
    const array: Array<Array<ReservedShifts>> = [];
    for (let y = 0; y <= maximumCoords.gridY; y++) {
      array[y] = [];
      for (let x = 0; x <= maximumCoords.gridX; x++) {
        array[y][x] = new ReservedShifts();
      }
    }
    return array;
  }

  private addVerticalShift(shift: number, fromPoint: GridXY, toPoint: GridXY, shifts: Array<Array<ReservedShifts>>): void {
    const orderedPoints = this.orderedVerticalPoints(fromPoint, toPoint);
    for (let y = orderedPoints.fromY; y < orderedPoints.toY; y++) {
      shifts[y][orderedPoints.x].add(shift);
    }
  }

  private addHorizontalShift(shift: number, fromPoint: GridXY, toPoint: GridXY, shifts: Array<Array<ReservedShifts>>): void {

    const orderedPoints = this.orderedHorizontalPoints(fromPoint, toPoint);
    for (let x = orderedPoints.fromX; x < orderedPoints.toX; x++) {
      shifts[orderedPoints.y][x].add(shift);
    }
  }

  private printShifts(shifts: Array<Array<ReservedShifts>>) {
    let text = "";
    for (let y = 0; y < shifts.length; y++) {
      for (let x = 0; x < shifts[y].length; x++) {
        text += shifts[y][x].shifts + '|';
      }
      text += '\n';
    }
    return text;
  }

  calculatePositionOfEnding(nodes: Array<ProcessNodeViewModel>, edgeWithShift: EdgeWithShift, edgeStart: boolean, edgeDefault: boolean, nodesSizes: {[nodeType: string]: Size}): EdgeEndPosition {
    return this.calculatePositionOfEnding2(nodes, edgeWithShift.edge.id, edgeWithShift.path, edgeWithShift.edge.fromNodeId, edgeWithShift.edge.toNodeId, edgeStart, edgeDefault, nodesSizes);
  }

  calculatePositionOfEnding2(nodes: Array<ProcessNodeViewModel>, edgeId: ProcessEdgeId, edgePath: ReadonlyArray<GridXY>, fromNode: ProcessNodeId|GridXY, toNode: ProcessNodeId|GridXY, edgeStart: boolean, edgeDefault: boolean,
                             nodesSizes: {[nodeType: string]: Size}): EdgeEndPosition {
    let node: ProcessNodeViewModel|undefined;
    let gridXY: GridXY;
    let lastPathXY: GridXY;
    let secondToLastPathXY: GridXY;
    let iconSizeShift: number = 0; // otherwise it will be center coordinates

    let nodeType: NodeType|undefined = undefined;

    if (edgeStart) {
      node = fromNode instanceof GridXY ? undefined : this.findNodeById(nodes, fromNode);
      gridXY = node ? node.gridXY() : fromNode as GridXY;
      lastPathXY = edgePath[0];
      secondToLastPathXY = edgePath[1];
      iconSizeShift = this.config.edgeStartSize;
    } else {
      node = toNode instanceof GridXY ? undefined : this.findNodeById(nodes, toNode);
      gridXY = node ? node.gridXY() : toNode as GridXY;
      lastPathXY = edgePath[edgePath.length - 1];
      secondToLastPathXY = edgePath[edgePath.length - 2];
      iconSizeShift = this.config.edgeEndSize;
    }

    nodeType = node ? node.nodeType : undefined;

    const lastPathXYPosition = this.xyCalculator.gridXYToPathPosition(lastPathXY);

    const nodeSize = nodeType ? nodesSizes[nodeType.name] : new Size(50, 40); // if no ode type than it's empty cell

    const cellCenterPosition = this.xyCalculator.gridXYToCellCenterPosition(gridXY);

    if (lastPathXY.gridX < secondToLastPathXY.gridX) {
      return this.rightEnding(edgeId, cellCenterPosition, nodeSize, iconSizeShift, lastPathXYPosition, edgeStart, edgeDefault);
    } else if (lastPathXY.gridX > secondToLastPathXY.gridX) {
      return this.leftEnding(edgeId, cellCenterPosition, nodeSize, iconSizeShift, lastPathXYPosition, edgeStart, edgeDefault);
    } else if (lastPathXY.gridY < secondToLastPathXY.gridY) {
      return this.bottomEnding(edgeId, cellCenterPosition, nodeSize, iconSizeShift, lastPathXYPosition, edgeStart, edgeDefault);
    } else if (lastPathXY.gridY > secondToLastPathXY.gridY) {
      return this.topEnding(edgeId, cellCenterPosition, nodeSize, iconSizeShift, lastPathXYPosition, edgeStart, edgeDefault);
    } else {
      throw new Error("Incorrect edgeWithShift path " + edgePath);
    }
  }

  private edgeEndPosition(edgeId: ProcessEdgeId,
                          x: number,
                          y: number,
                          rotateDegrees: number,
                          start: boolean,
                          edgeDefault: boolean): EdgeEndPosition {
    return new EdgeEndPosition(
      edgeId,
      x,
      y,
      rotateDegrees,
      start,
      edgeDefault
    );

  }

  static NODE_STROKE_WIDTH_HALF = 2;

  private topEnding(edgeId: ProcessEdgeId, cellCenterPosition: PositionXY, nodeSize: Size, iconSize: number, lastPathXYPosition: PositionXY, start: boolean, edgeDefault: boolean) {
    return this.edgeEndPosition(
      edgeId,
        lastPathXYPosition.x - iconSize / 2,
        cellCenterPosition.y - nodeSize.height / 2 - iconSize - EdgeXYCalculator.NODE_STROKE_WIDTH_HALF + (start ? -2 : 1.5),
      90, start, edgeDefault);
  }

  private bottomEnding(edgeId: ProcessEdgeId, cellCenterPosition: PositionXY, nodeSize: Size, iconSize: number, lastPathXYPosition: PositionXY, start: boolean, edgeDefault: boolean) {
    return this.edgeEndPosition(
      edgeId,
        lastPathXYPosition.x - iconSize / 2,
        cellCenterPosition.y + nodeSize.height / 2 + EdgeXYCalculator.NODE_STROKE_WIDTH_HALF + (start ? 2 : -1.5),
      270, start, edgeDefault);
  }

  private leftEnding(edgeId: ProcessEdgeId, cellCenterPosition: PositionXY, nodeSize: Size, iconSize: number, lastPathXYPosition: PositionXY, start: boolean, edgeDefault: boolean) {
    return this.edgeEndPosition(
      edgeId,
        cellCenterPosition.x - nodeSize.width / 2 - iconSize - EdgeXYCalculator.NODE_STROKE_WIDTH_HALF + (start ? -2 : 1.5),
        lastPathXYPosition.y - iconSize / 2,
      0, start, edgeDefault);
  }

  private rightEnding(edgeId: ProcessEdgeId, cellCenterPosition: PositionXY, nodeSize: Size, iconSize: number, lastPathXYPosition: PositionXY, start: boolean, edgeDefault: boolean) {
    return this.edgeEndPosition(
      edgeId,
    cellCenterPosition.x + nodeSize.width / 2 + EdgeXYCalculator.NODE_STROKE_WIDTH_HALF + (start ? 2 : -1.5),
    lastPathXYPosition.y - iconSize / 2,
      180, start, edgeDefault);
  }

}
