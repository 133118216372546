import {Component, OnInit} from '@angular/core';
import {DropDownSelectorOption, UserSettingsStateService} from "@shared";
import {I18nService} from "../..";
import {__, i18n, Option} from "@utils";
import {LocaleListService, RegionWithName} from "../../../modules/user.module/serivces/locale-list/locale-list.service";

class LocaleOption implements DropDownSelectorOption{
  nameOnList: string = "";
  constructor(readonly value: string|null,
              readonly name: string) {}

  static of(region: RegionWithName) {
    const name = region.nativeName != region.englishName ? region.nativeName +" ("+region.englishName+")" : region.nativeName;
    return new LocaleOption(region.localeCode, name)
  }
}

@Component({
  selector: 'my-locale-selector',
  templateUrl: './locale-selector.component.html'
})
export class LocaleSelectorComponent implements OnInit {

  locale: string|null = null;
  defaultLocaleOption: LocaleOption = null!;
  localeOptions: Array<LocaleOption> = [];
  selectedLocale: LocaleOption = null!;

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly localeListService: LocaleListService,
              readonly i18nService: I18nService) {


    this.userSettingsService.getLanguageObservable().forEach(language => {
      this.initOptions();
    });
    this.userSettingsService.getLocaleObservable().forEach(locale => {
      this.locale = locale.flatMap(l =>this.localeListService.getLocale(l)).map(l => l.localeCode).getOrNull();
    })
  }
  initOptions() {
    let options = this.localeListService.getLocaleListForLanguage(this.userSettingsService.getEffectiveLanguage()).map(r => {
      return LocaleOption.of(r)
    })
    this.defaultLocaleOption = new LocaleOption(null, options[0].name);
    this.defaultLocaleOption.nameOnList = i18n("common_default_male");

    this.localeOptions = [this.defaultLocaleOption, ...options];

    if(!__(this.localeOptions).exists(o => o.value === this.locale)) {
      this.locale = null;
    }
  }


  ngOnInit(): void {
    this.initOptions();
    this.locale = this.userSettingsService.getLocale().getOrNull();
    this.selectedLocale = __(this.localeOptions).find(l => l.value === this.locale).getOrElse(this.defaultLocaleOption);
  }

  localeChanged(locale: LocaleOption|DropDownSelectorOption) {
    this.locale = locale.value;
    this.userSettingsService.changeLocale(Option.of(this.locale));
  }
}
