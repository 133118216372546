import {FormSectionId} from "@shared";
import {ProcessNodeId} from "@shared-model";
import {AutomaticActionId} from "@utils";

export class VariableUsageType {
  constructor(readonly name:string) {}

  static read = new VariableUsageType("read");
  static write = new VariableUsageType("write");

  isRead() {
    return this.name === VariableUsageType.read.name;
  }

  isWrite() {
    return this.name === VariableUsageType.write.name;
  }
}

export interface VariableUsage {
  className(): string;
  usageType: VariableUsageType;
}

export class InputFieldRefVariableUsage implements VariableUsage {

  static className = "InputFieldRefVariableUsage";

  constructor(readonly usageType:VariableUsageType,
              readonly sectionId:FormSectionId,
              readonly nodeId: ProcessNodeId) {}

  className() {
    return InputFieldRefVariableUsage.className;
  }
}

export class ConditionNodeVariableUsage implements VariableUsage {

  static className = "ConditionNodeVariableUsage";

  constructor(readonly usageType:VariableUsageType,
              readonly nodeId: ProcessNodeId) {}

  className() {
    return ConditionNodeVariableUsage.className;
  }
}

export class BeforeActionVariableUsage implements VariableUsage {

  static className = "BeforeActionVariableUsage";

  constructor(readonly usageType:VariableUsageType,
              readonly nodeId: ProcessNodeId,
              readonly actionId:AutomaticActionId) {}

  className() {
    return BeforeActionVariableUsage.className;
  }
}

export class AfterActionVariableUsage implements VariableUsage {

  static className = "AfterActionVariableUsage";

  constructor(readonly usageType: VariableUsageType,
              readonly nodeId: ProcessNodeId,
              readonly actionId: AutomaticActionId) {}

  className() {
    return AfterActionVariableUsage.className;
  }
}

export class ExternalProcessOutputMappingVariableUsage implements VariableUsage {

  static className = "ExternalProcessOutputMappingVariableUsage";

  constructor(readonly usageType: VariableUsageType,
              readonly nodeId: ProcessNodeId,
              readonly externalProcessVariableName: string) {}

  className() {
    return ExternalProcessOutputMappingVariableUsage.className;
  }
}

export class ExternalProcessResultMappingVariableUsage implements VariableUsage {

  static className = "ExternalProcessResultMappingVariableUsage";

  constructor(readonly usageType: VariableUsageType,
              readonly nodeId: ProcessNodeId) {}

  className() {
    return ExternalProcessResultMappingVariableUsage.className;
  }
}

export class RepeatableSectionVariableUsage implements VariableUsage {

  static className = "RepeatableSectionVariableUsage";

  constructor(readonly usageType: VariableUsageType,
              readonly sectionId: FormSectionId,
              readonly nodesIds: Array<number>) {}

  className() {
    return RepeatableSectionVariableUsage.className;
  }
}

export class ActorVariableUsage implements VariableUsage {

  static className = "ActorVariableUsage";

  constructor(readonly usageType: VariableUsageType,
              readonly actorId: number) {}

  className() {
    return ActorVariableUsage.className;
  }
}
