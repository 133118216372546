import {__, ___, I18nText, None, Option, Some} from "@utils";
import {
  BooleanProperty,
  ComponentProperty,
  NumberProperty,
  OptionalBooleanProperty,
  OptionalNumberProperty,
  OptionalStringProperty,
  PropertyType,
  StringProperty
} from "./screen-properties.model";

export class SkinInfo {
  constructor(readonly name: any, readonly identifier: string) {}

}

export class SkinPrototype {
  constructor(readonly name: I18nText,
              readonly identifier: string,
              readonly skin: { [componentName: string]: {[componentClass: string]: {[property: string]: string}} }) {
  }
}

export class SkinName {
    constructor(readonly name: I18nText, readonly identifier: string) {}
  }

  export class ComponentClassName {
    constructor(readonly name: string, readonly identifier: string,
                readonly skinName: string, readonly skinIdentifier: string) {}
  }

  export class Skin {
    constructor(readonly name: string,
                readonly identifier: string,
                readonly skin: { [componentName: string]: {[componentClass: string]: {[property: string]: string}} }) {
    }

    static ofPrototype(prototype: SkinPrototype): Skin {
      return new Skin(prototype.name.getCurrentWithFallback(), prototype.identifier, Skin.toSkin(prototype.skin));
    }

    private static toSkin(prototype: {[componentName: string]: { [componentClass: string]: { [property: string]: string } } }) {
      const newSkin: {[s: string]: {[key: string]:{[name: string]: string}}} = {};
      Object.keys(prototype).forEach(componentName => {
        const newComponentsClasses: {[key: string]:{[name: string]: string}} = {};
        const componentsClasses = prototype[componentName];
        Object.keys(componentsClasses).forEach(componentClass => {
          const properties = componentsClasses[componentClass];
          const newProperties:{[name: string]: string} = {};
          Object.keys(properties).forEach(propertyName => {
            newProperties[propertyName] = properties[propertyName];
          });
          newComponentsClasses[componentClass] = newProperties;
        });
        newSkin[componentName] = newComponentsClasses;
      });
      return newSkin;
    }

  }

  export class SkinsPropertiesProvider {

    private skins: Array<Skin> = [];

    constructor(
      readonly skinInfo: () => {skinName: Option<string>, componentTypeName: string, componentClass: string},
      skinsPrototypes: Array<SkinPrototype>) {
      this.skins = skinsPrototypes.map(Skin.ofPrototype);
    }


    // get(propertyName: string): ComponentProperty<any> {
    //
    //   const {skinName, componentTypeName, componentClass} = this.skinInfo();
    //
    //   const skinProperty = this.skinPropertyFor(skinName, componentTypeName, componentClass, propertyName);
    //   if(skinProperty != null) {
    //     return skinProperty;
    //   } else {
    //     throw new Error("Skin Provider: property '"+propertyName+"' not found");
    //   }
    // }
    //
    // getIfExistsOrNull(propertyName: string): ComponentProperty<any>|null {
    //
    //
    //   const {skinName, componentTypeName, componentClass} = this.skinInfo();
    //
    //   const skinProperty = this.skinPropertyFor(skinName, componentTypeName, componentClass, propertyName);
    //   if(skinProperty != null) {
    //     return skinProperty;
    //   } else {
    //     return null;
    //   }
    // }

    getIfExists(propertyName: string, propertyType: PropertyType): Option<ComponentProperty<any>> {

      const {skinName, componentTypeName, componentClass} = this.skinInfo();

      const skinProperty = this.skinPropertyFor(skinName, componentTypeName, componentClass, propertyName, propertyType);
      if(skinProperty != null) {
        return Some(skinProperty);
      } else {
        return None();
      }
    }

    private skinPropertyFor(skinIdentifierOption: Option<string>, componentTypeName: string, componentClass: string, propertyName: string, propertyType: PropertyType): ComponentProperty<any>|null {
      const skinIdentifier = skinIdentifierOption.getOrElse("$default");
      const skin = __(this.skins).find(s => s.identifier == skinIdentifier).map(s => s.skin).getOrNull();
      if(skin == null) {
        if(componentTypeName == "$any") {
          return null;
        } else {
          return this.skinPropertyFor(skinIdentifierOption, "$any", componentClass, propertyName, propertyType);
        }
      } else {
        const component = skin[componentTypeName];
        if(component == null) {
          if(componentTypeName == "$any") {
            return null;
          } else {
            return this.skinPropertyFor(skinIdentifierOption, "$any", componentClass, propertyName, propertyType);
          }
        } else {
          let properties = component[componentClass];
          if (properties == null) {
            properties = component["$default"];
          }
          if (properties != null) {
            const property = properties[propertyName];
            if (property != null) {
              return this.parseProperty(propertyName, property, propertyType);
            } else {
              if(componentTypeName == "$any") {
                return null;
              } else {
                return this.skinPropertyFor(skinIdentifierOption, "$any", componentClass, propertyName, propertyType);
              }
            }
          } else {
            if(componentTypeName == "$any") {
              return null;
            } else {
              return this.skinPropertyFor(skinIdentifierOption, "$any", componentClass, propertyName, propertyType);
            }
          }
        }
      }
    }

    private parseProperty(propertyName: string, value: string, propertyType: PropertyType): ComponentProperty<any> {
      switch (propertyType) {
        case PropertyType.None: throw new Error("Converting "+propertyName+" to disabled");
        case PropertyType.String: return StringProperty.of(value);
        case PropertyType.OptionalString: if(value.trim().length == 0) {
          return OptionalStringProperty.disabled("");
        } else {
          return OptionalStringProperty.of(value);
        }
        case PropertyType.Number: return NumberProperty.of(parseFloat(value));
        case PropertyType.OptionalNumber:  if(value.trim().length == 0) {
          return OptionalNumberProperty.disabled(0);
        } else {
          return  OptionalNumberProperty.of(parseFloat(value));
        }
        case PropertyType.Boolean: switch (value.toLowerCase()) {
          case "true": return BooleanProperty.ofTrue();
          case "false": return BooleanProperty.ofFalse();
          default: throw new Error("Incorrect boolean property value: '"+value+"'");
        }
        case PropertyType.OptionalBoolean: switch (value.toLowerCase()) {
          case "true": return OptionalBooleanProperty.ofTrue();
          case "false": return OptionalBooleanProperty.ofFalse();
          case "": return OptionalBooleanProperty.disabled(false);
          default: throw new Error("Incorrect optional boolean property value: '"+value+"'");
        }
        case PropertyType.Trilean: throw new Error("Converting "+propertyName+" to Trilean not Supported");
        case PropertyType.I18nText: throw new Error("Converting "+propertyName+" to I18nText not Supported");
        case PropertyType.OptionalI18nText: throw new Error("Converting "+propertyName+" to OptionalI18nText not Supported");
        case PropertyType.Date: throw new Error("Converting "+propertyName+" to Date not Supported");
        case PropertyType.OptionalDate: throw new Error("Converting "+propertyName+" to OptionalDate not Supported");
        case PropertyType.Time: throw new Error("Converting "+propertyName+" to Time not Supported");
        case PropertyType.OptionalTime: throw new Error("Converting "+propertyName+" to OptionalTime not Supported");
        case PropertyType.DateTime: throw new Error("Converting "+propertyName+" to DateTime not Supported");
        case PropertyType.OptionalDateTime: throw new Error("Converting "+propertyName+" to OptionalDateTime not Supported");
        case PropertyType.OptionalDuration: throw new Error("Converting "+propertyName+" to OptionalDuration not Supported");
        case PropertyType.File: throw new Error("Converting "+propertyName+" to File not Supported");
        case PropertyType.OptionalFile: throw new Error("Converting "+propertyName+" to OptionalFile not Supported");
        case PropertyType.Model: throw new Error("Converting "+propertyName+" to Model not Supported");
        case PropertyType.OptionalModel: throw new Error("Converting "+propertyName+" to OptionalModel not Supported");
        case PropertyType.LabeledValues: throw new Error("Converting "+propertyName+" to LabeledValues not Supported");
        case PropertyType.OptionalOrganizationNodes: throw new Error("Converting "+propertyName+" to OptionalOrganizationNodes not Supported");
        case PropertyType.OptionalStringArray: throw new Error("Converting "+propertyName+" to OptionalStringArray not Supported");
        default: throw new Error("Unknown property type '"+propertyType+"'");
      }
    }


    allSkins(): Array<SkinName> {
      return this.skins.filter(s => s.identifier.indexOf("$") !== 0).map(s => new SkinName(I18nText.ofCurrent(s.name), s.identifier));
    }

    allClasses(skin: string, componentTypeName: string): Array<ComponentClassName> {

      const allSkinsWithDefault = this.skins.map(s => new SkinName(I18nText.ofCurrent(s.name), s.identifier));

      return __(allSkinsWithDefault).flatMap((skinName: SkinName) => {
        const skin = __(this.skins).find(s => s.identifier == skinName.identifier).map(s => s.skin).getOrElse({});
        const componentClasses = skin[componentTypeName];
        if(componentClasses == null) {
          return [];
        } else {
          return ___(Object.keys(componentClasses)).filter(k => k.indexOf("$") !== 0).sortBy(c => c).map(c => new ComponentClassName(c, c, skinName.name.getCurrentWithFallback(), skinName.identifier)).value();
        }
      });

    }
  }
