import {Language} from "@utils";
import {I18nService, LanguageOption, UserSettingsStateService} from "@shared";
import {inject} from "@angular/core";

export const getLanguageOptions = () => {
  const userSettingsStateService = inject(UserSettingsStateService);

  const i18n = inject(I18nService);

  const defaultLanguage = new LanguageOption(null, userSettingsStateService.getDefaultLanguage().name.toUpperCase());
  defaultLanguage.nameOnList = i18n.translate('common_default_male');

  const languages = [
    defaultLanguage,
    new LanguageOption(Language.PL, Language.PL.name.toUpperCase()),
    new LanguageOption(Language.EN, Language.EN.name.toUpperCase()),
    new LanguageOption(Language.AR, Language.AR.name.toUpperCase()),
    new LanguageOption(Language.PLR, Language.PL.name.toUpperCase() + " (RTL)"),
    new LanguageOption(Language.ENR, Language.EN.name.toUpperCase() + " (RTL)")
  ];

  const selectedLanguage = userSettingsStateService.getLanguage().getOrNull();

  if (selectedLanguage) {
    languages.forEach(option => option.selected = selectedLanguage === option.value);
  } else {
    languages[0].selected = true;
  }

  return languages;
};
