import {__, Either, Left, NoneSingleton, Option, Right, Typed} from "@utils";

import {
  ArrayExpression as ESTree_ArrayExpression,
  ArrowFunctionExpression as ESTree_ArrowFunctionExpression,
  AssignmentExpression as ESTree_AssignmentExpression,
  BinaryExpression as ESTree_BinaryExpression,
  BlockStatement as ESTree_BlockStatement,
  CallExpression as ESTree_CallExpression,
  CatchClause as ESTree_CatchClause,
  ConditionalExpression as ESTree_ConditionalExpression,
  DoWhileStatement as ESTree_DoWhileStatement,
  EmptyStatement as ESTree_EmptyStatement,
  Expression as ESTree_Expression,
  ExpressionStatement as ESTree_ExpressionStatement,
  ForStatement as ESTree_ForStatement,
  FunctionDeclaration as ESTree_FunctionDeclaration,
  Identifier as ESTree_Identifier,
  IfStatement as ESTree_IfStatement,
  Literal as ESTree_Literal,
  LogicalExpression as ESTree_LogicalExpression,
  MemberExpression as ESTree_MemberExpression,
  Node as ESTree_Node,
  ObjectExpression as ESTree_ObjectExpression,
  Program as ESTree_Program,
  Property as ESTree_Property,
  ReturnStatement as ESTree_ReturnStatement,
  SequenceExpression as ESTree_SequenceExpression,
  SourceLocation as ESTree_SourceLocation,
  SpreadElement as ESTree_SpreadElement,
  SwitchCase as ESTree_SwitchCase,
  SwitchStatement as ESTree_SwitchStatement,
  TaggedTemplateExpression as ESTree_TaggedTemplateExpression,
  TemplateElement as ESTree_TemplateElement,
  TemplateLiteral as ESTree_TemplateLiteral,
  ThrowStatement as ESTree_ThrowStatement,
  TryStatement as ESTree_TryStatement,
  UnaryExpression as ESTree_UnaryExpression,
  UpdateExpression as ESTree_UpdateExpression,
  VariableDeclaration as ESTree_VariableDeclaration,
  VariableDeclarator as ESTree_VariableDeclarator,
  WhileStatement as ESTree_WhileStatement
} from "estree/index";
import {
  ArrayExpression,
  ArrowFunctionExpression,
  AssignmentExpression,
  BinaryExpression,
  binaryOperators,
  BlockStatement,
  BooleanLiteral,
  CallExpression,
  CatchClause,
  ConditionalExpression,
  Directive,
  DoWhileStatement,
  EmptyStatement,
  Expression,
  ExpressionStatement,
  ForStatement,
  FunctionDeclaration,
  Identifier,
  IfStatement,
  Literal,
  LogicalExpression,
  MemberExpression,
  Node,
  NullLiteral,
  NumberLiteral,
  ObjectExpression,
  Position,
  Program,
  RegexLiteral,
  ReturnStatement,
  SequenceExpression,
  SourceLocation,
  StringLiteral,
  SwitchCase,
  SwitchStatement,
  TaggedTemplateExpression,
  TemplateElement,
  TemplateElementValue,
  TemplateLiteral,
  ThrowStatement,
  TryStatement,
  UnaryExpression,
  UpdateExpression,
  VariableDeclaration,
  VariableDeclarator,
  WhileStatement
} from "./TypedEstree";

export class TypedEstreeConverter {

  static convert(estree:ESTree_Node):Node {
    switch (estree.type) {
      case "Program": return TypedEstreeConverter.convertProgram(<ESTree_Program>estree);
      case "MemberExpression": return TypedEstreeConverter.convertMemberExpression(<ESTree_MemberExpression>estree);
      case "Identifier": return TypedEstreeConverter.convertIdentifier(<ESTree_Identifier>estree);
      case "EmptyStatement": return TypedEstreeConverter.convertEmptyStatement(<ESTree_EmptyStatement> estree);
      case "ReturnStatement": return TypedEstreeConverter.convertReturnStatement(<ESTree_ReturnStatement> estree);
      case "ExpressionStatement": return TypedEstreeConverter.convertExpressionStatement(<ESTree_ExpressionStatement> estree);
      case "IfStatement": return TypedEstreeConverter.convertIfStatement(<ESTree_IfStatement> estree);
      case "SwitchStatement": return TypedEstreeConverter.convertSwitchStatement(<ESTree_SwitchStatement> estree);
      case "WhileStatement": return TypedEstreeConverter.convertWhileStatement(<ESTree_WhileStatement> estree);
      case "DoWhileStatement": return TypedEstreeConverter.convertDoWhileStatement(<ESTree_DoWhileStatement> estree);
      case "ForStatement": return TypedEstreeConverter.convertForStatement(<ESTree_ForStatement> estree);
      case "BlockStatement": return TypedEstreeConverter.convertBlockStatement(<ESTree_BlockStatement> estree);
      case "VariableDeclaration": return TypedEstreeConverter.convertVariableDeclaration(<ESTree_VariableDeclaration> estree);
      case "BinaryExpression": return TypedEstreeConverter.convertBinaryExpression(<ESTree_BinaryExpression>estree);
      case "LogicalExpression": return TypedEstreeConverter.convertLogicalExpression(<ESTree_LogicalExpression>estree);
      case "Literal": return TypedEstreeConverter.convertLiteral(<ESTree_Literal>estree);
      case "UnaryExpression": return TypedEstreeConverter.convertUnaryExpression(<ESTree_UnaryExpression>estree);
      case "CallExpression": return TypedEstreeConverter.convertCallExpression(<ESTree_CallExpression>estree);
      case "ObjectExpression": return TypedEstreeConverter.convertObjectExpression(<ESTree_ObjectExpression>estree);
      case "ArrayExpression": return TypedEstreeConverter.convertArrayExpression(<ESTree_ArrayExpression>estree);
      case "ConditionalExpression": return TypedEstreeConverter.convertConditionalExpression(<ESTree_ConditionalExpression>estree);
      case "ArrowFunctionExpression": return TypedEstreeConverter.convertArrowFunctionExpression(<ESTree_ArrowFunctionExpression>estree);
      case "UpdateExpression": return TypedEstreeConverter.convertUpdateExpression(<ESTree_UpdateExpression>estree);
      case "AssignmentExpression": return TypedEstreeConverter.convertAssignmentExpression(<ESTree_AssignmentExpression>estree);
      case "SequenceExpression": return TypedEstreeConverter.convertSequenceExpression(<ESTree_SequenceExpression>estree);
      case "ThrowStatement": return TypedEstreeConverter.convertThrowStatement(<ESTree_ThrowStatement>estree);
      case "TryStatement": return TypedEstreeConverter.convertTryStatement(<ESTree_TryStatement>estree);
      case "CatchClause": return TypedEstreeConverter.convertCatchClause(<ESTree_CatchClause>estree);
      case "FunctionDeclaration": return TypedEstreeConverter.convertFunctionDeclaration(<ESTree_FunctionDeclaration>estree);
      case "TemplateLiteral": return TypedEstreeConverter.convertTemplateLiteral(<ESTree_TemplateLiteral>estree);
      case "TemplateElement": return TypedEstreeConverter.convertTemplateElement(<ESTree_TemplateElement>estree);
      case "TaggedTemplateExpression":
        // Maybe in future
        //return TypedEstreeConverter.convertTaggedTemplateExpression(<ESTree_TaggedTemplateExpression>estree);
        throw Error("Unsupported statement type: [" + estree.type + "]");
      default:
        if(<any>estree.type === "Directive") { // in used typed definition it's not proper value
          return TypedEstreeConverter.convertDirective(estree);
        } else {
          throw Error("Unsupported statement type: [" + estree.type + "]");
        }
    }
  }

  static isPattern(estree: ESTree_Node): boolean {
    return estree.type === "MemberExpression" || estree.type === "Identifier";
  }


  private static convertProgram(estree:ESTree_Program):Program {
    return new Program(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      estree.body.map(expression => Typed.of(TypedEstreeConverter.convert(expression))),
      estree.sourceType
    );
  }

  private static convertSourceLocation(sourceLocation:ESTree_SourceLocation|null|undefined):Option<SourceLocation> {
    return Option.of(sourceLocation)
      .map((location:ESTree_SourceLocation) => new SourceLocation(Option.of(location.source),
        new Position(location.start.line, location.start.column),
        new Position(location.end.line, location.end.column)));
  }


  private static convertEmptyStatement(estree: ESTree_EmptyStatement): EmptyStatement {
    return new EmptyStatement(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range));
  }

  private static convertReturnStatement(estree: ESTree_ReturnStatement): ReturnStatement {
    return new ReturnStatement(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range), Option.of(estree.argument).map(a => Typed.of(TypedEstreeConverter.convert(a))));
  }

  private static convertExpressionStatement(estree:ESTree_ExpressionStatement):ExpressionStatement {
    return new ExpressionStatement(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.expression)));
  }

  private static convertIfStatement(estree: ESTree_IfStatement): IfStatement {
    return new IfStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.test)),
      Typed.of(TypedEstreeConverter.convert(estree.consequent)),
      Option.of(estree.alternate).map(a => Typed.of(TypedEstreeConverter.convert(a))));
  }

  private static convertSwitchStatement(estree: ESTree_SwitchStatement): SwitchStatement {
    return new SwitchStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.discriminant)),
      estree.cases.map(c => TypedEstreeConverter.convertSwitchCase(c)));
  }

  private static convertSwitchCase(estree: ESTree_SwitchCase): SwitchCase {
    return new SwitchCase(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Option.of(estree.test).map(e => Typed.of(TypedEstreeConverter.convert(e))),
      estree.consequent.map(s => Typed.of(TypedEstreeConverter.convert(s))))
  }

  private static convertWhileStatement(estree: ESTree_WhileStatement): WhileStatement {
    return new WhileStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.test)),
      Typed.of(TypedEstreeConverter.convert(estree.body)));
  }

  private static convertDoWhileStatement(estree: ESTree_DoWhileStatement): DoWhileStatement {
    return new DoWhileStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.body)),
      Typed.of(TypedEstreeConverter.convert(estree.test)));
  }

  private static convertForStatement(estree: ESTree_ForStatement): ForStatement {
    return new ForStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Option.of(estree.init).map(i => {
        if(i.type === "VariableDeclaration") {
          return <Either<VariableDeclaration,Typed<Expression>>>Left(TypedEstreeConverter.convertVariableDeclaration(<ESTree_VariableDeclaration>i))
        } else {
          return <Either<VariableDeclaration,Typed<Expression>>>Right(Typed.of(TypedEstreeConverter.convert(i)))
        }
      }),
      Option.of(estree.test).map(t => Typed.of(TypedEstreeConverter.convert(t))),
      Option.of(estree.update).map(u => Typed.of(TypedEstreeConverter.convert(u))),
      Typed.of(TypedEstreeConverter.convert(estree.body)));
  }

  private static convertBlockStatement(estree:ESTree_BlockStatement):BlockStatement {
    return new BlockStatement(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      estree.body.map(s => Typed.of(TypedEstreeConverter.convert(s))));
  }

  private static convertVariableDeclaration(estree: ESTree_VariableDeclaration): VariableDeclaration {
    return new VariableDeclaration(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.declarations.map(d => TypedEstreeConverter.convertVariableDeclarator(d)), estree.kind);
  }

  private static convertVariableDeclarator(estree: ESTree_VariableDeclarator): VariableDeclarator {
    return new VariableDeclarator(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.id)),
      Option.of(estree.init).map(i => Typed.of(TypedEstreeConverter.convert(i))));
  }


  private static convertMemberExpression(estree:ESTree_MemberExpression):MemberExpression {
    if (estree.computed) {
      return new MemberExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
        Option.of(estree.range),
        Typed.of(TypedEstreeConverter.convert(estree.object)),
        Typed.of(TypedEstreeConverter.convert(estree.property)),
        estree.computed);
    } else {
      return new MemberExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
        Option.of(estree.range),
        Typed.of(TypedEstreeConverter.convert(estree.object)),
        Typed.of(TypedEstreeConverter.convertIdentifier(<ESTree_Identifier>estree.property)),
        estree.computed);
    }
  }

  private static convertBinaryExpression(estree:ESTree_BinaryExpression):BinaryExpression {
    if(__(binaryOperators).contains(estree.operator)) {
      return new BinaryExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
        Option.of(estree.range),
        estree.operator,
        Typed.of(TypedEstreeConverter.convert(estree.left)),
        Typed.of(TypedEstreeConverter.convert(estree.right)));
    } else {
      throw new Error("Operator " + estree.operator + " is not supported");
    }
  }

  private static convertLogicalExpression(estree:ESTree_LogicalExpression):LogicalExpression {
    return new LogicalExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      estree.operator,
      Typed.of(TypedEstreeConverter.convert(estree.left)),
      Typed.of(TypedEstreeConverter.convert(estree.right)));
  }

  private static convertLiteral(estree:ESTree_Literal):Literal {
    if (typeof estree.value === "number") {
      return new NumberLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range), <number>estree.value);
    } else if (typeof estree.value === "string") {
      return new StringLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range), <string>estree.value);
    } else if (typeof estree.value === "boolean") {
      return new BooleanLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range), <boolean>estree.value);
    } else if ((<any>estree.value) instanceof RegExp) {
      const regex = <RegExp>estree.value;
      const flags: string = (regex.multiline ? 'm' : '') +
                  (regex.ignoreCase ? 'i' : '');
      return new RegexLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range), regex.source, flags);
    } else if (estree.value === null || estree.value === undefined) {
      return new NullLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range));
    } else {
      throw new Error("Unsupported literal type " + (typeof estree.value));
    }
  }

  private static convertUnaryExpression(estree:ESTree_UnaryExpression):UnaryExpression {
    return new UnaryExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      estree.operator,
      estree.prefix,
      Typed.of(TypedEstreeConverter.convert(estree.argument)));
  }

  private static convertCallExpression(estree:ESTree_CallExpression):CallExpression {
    return new CallExpression(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.callee)),
      estree.arguments.map(expression => Typed.of(TypedEstreeConverter.convert(expression))));
  }

  private static convertIdentifier(estree:ESTree_Identifier):Identifier {
    return new Identifier(TypedEstreeConverter.convertSourceLocation(estree.loc),
      Option.of(estree.range),
      estree.name);
  }

  private static convertObjectExpression(estree:ESTree_ObjectExpression):ObjectExpression {
    const properties: [Typed<Expression>, Typed<Expression>][] = estree.properties.map((p: ESTree_Property|ESTree_SpreadElement) => {
      if(p.type === "SpreadElement") {
        throw new Error("Spread elements are not supported");
      } else {

        // do not compress - typed consts help keep the compiler happy
        const key: Typed<Expression> = Typed.of(TypedEstreeConverter.convert(p.key));
        const value: Typed<Expression> = Typed.of(TypedEstreeConverter.convert(p.value));
        const pair: [Typed<Expression>, Typed<Expression>] = [key, value];
        return pair;
      }
    });

    return new ObjectExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range), properties);
  }

  private static convertArrayExpression(estree: ESTree_ArrayExpression): ArrayExpression {
    return new ArrayExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.elements.filter(e => e !== null).map(e => {
        if(e?.type === "SpreadElement") {
          throw new Error("Spread elements are not supported");
        } else {
          return TypedEstreeConverter.convert(<ESTree_Node>e);
        }
      }).map(e => Typed.of(e)))
  }

  private static convertConditionalExpression(estree: ESTree_ConditionalExpression): ConditionalExpression {
    return new ConditionalExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.test)),
      Typed.of(TypedEstreeConverter.convert(estree.consequent)),
      Typed.of(TypedEstreeConverter.convert(estree.alternate))
    );
  }

  private static convertArrowFunctionExpression(estree: ESTree_ArrowFunctionExpression): ArrowFunctionExpression {

    let body: Either<BlockStatement, Typed<Expression>>;

    if(estree.body.type === "BlockStatement") {
      body = Left(TypedEstreeConverter.convertBlockStatement(<ESTree_BlockStatement>estree.body));
    } else {
      body = Right(Typed.of(TypedEstreeConverter.convert(<ESTree_Expression>estree.body)));
    }

    let identifier: Option<Identifier> = NoneSingleton;
    if((<any>estree).id !== null) {
      identifier = Option.of((<any>estree).id).map(id => TypedEstreeConverter.convertIdentifier(id))
    }

    return new ArrowFunctionExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      identifier,
      estree.params.map(p => Typed.of(TypedEstreeConverter.convert(p))), body, estree.generator ? estree.generator : false, estree.expression);
  }



  private static convertUpdateExpression(estree:ESTree_UpdateExpression):UpdateExpression {
    return new UpdateExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.operator,
      Typed.of(TypedEstreeConverter.convert(estree.argument)),
      estree.prefix);
  }

  private static convertAssignmentExpression(estree:ESTree_AssignmentExpression):AssignmentExpression {
    return new AssignmentExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.operator,
      TypedEstreeConverter.isPattern(estree.left) ? Left(Typed.of(TypedEstreeConverter.convert(estree.left))) : Right(Typed.of(TypedEstreeConverter.convert(estree.right))),
      Typed.of(TypedEstreeConverter.convert(estree.right)));
  }

  private static convertSequenceExpression(estree:ESTree_SequenceExpression):SequenceExpression {
    return new SequenceExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.expressions.map(e => Typed.of(TypedEstreeConverter.convert(e))));
  }

  private static convertThrowStatement(estree: ESTree_ThrowStatement) {
    return new ThrowStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.argument)));
  }

  private static convertTryStatement(estree: ESTree_TryStatement) {
    return new TryStatement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      TypedEstreeConverter.convertBlockStatement(estree.block),
      Option.of(estree.handler).map(h => TypedEstreeConverter.convertCatchClause(h)),
      Option.of(estree.finalizer).map(f => TypedEstreeConverter.convertBlockStatement(f)));
  }

  private static convertCatchClause(estree: ESTree_CatchClause) {
    if(estree.param === null) {
      throw new Error("catch clause must have a parameter");
    } else {
      return new CatchClause(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
        Typed.of(TypedEstreeConverter.convert(estree.param)),
        TypedEstreeConverter.convertBlockStatement(estree.body));
    }
  }

  private static convertFunctionDeclaration(estree: ESTree_FunctionDeclaration) {
    if(estree.id === null) {
      throw new Error("function declaration must have an id");
    } else {
      return new FunctionDeclaration(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
        TypedEstreeConverter.convertIdentifier(estree.id),
        estree.params.map(p => Typed.of(TypedEstreeConverter.convert(p))),
        TypedEstreeConverter.convertBlockStatement(<ESTree_BlockStatement>estree.body))
    }
  }

  private static convertDirective(estree: ESTree_Node) {
    return new Directive(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert((<any>estree).expression)), (<any>estree).directive);
  }

  private static convertTemplateLiteral(estree: ESTree_TemplateLiteral) {
    return new TemplateLiteral(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      estree.quasis.map(q => TypedEstreeConverter.convertTemplateElement(q)),
      estree.expressions.map(e => Typed.of(TypedEstreeConverter.convert(e)))
    );
  }

  private static convertTaggedTemplateExpression(estree: ESTree_TaggedTemplateExpression) {
    return new TaggedTemplateExpression(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
      Typed.of(TypedEstreeConverter.convert(estree.tag)),
      TypedEstreeConverter.convertTemplateLiteral(estree.quasi)
    );
  }

  private static convertTemplateElement(estree: ESTree_TemplateElement) {
    return new TemplateElement(TypedEstreeConverter.convertSourceLocation(estree.loc), Option.of(estree.range),
     estree.tail,
     new TemplateElementValue(estree.value.cooked ? estree.value.cooked : "", estree.value.raw)
    );
  }
}
