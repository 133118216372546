import {Component, Input} from "@angular/core";
import {SingleCheckboxComponentViewModel} from "./SingleCheckboxComponentViewModel";

@Component({
  selector: 'my-single-checkbox-component',
  templateUrl: './single-checkbox-component.component.html',
  host: {
    "[class]": "'componentBody SingleCheckboxComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[class.enabled]": "!viewModel.disabled",
    "[class.invalid]": "viewModel.invalid",
    "[class.validationError]": "viewModel.validationError",
    "[style]": "viewModel.css",
  }
})
export class SingleCheckboxComponentComponent {
  @Input({required:true}) viewModel!: SingleCheckboxComponentViewModel;

}
