<button class="entry" (click)="viewModel.onSelect(option)" *ngFor="let option of viewModel.options"
        [ngClass]="{checked: option.selected}">
  <svg viewBox="0 0 20 20" class="radioButtonSvg">
    <circle class="outsideCircle"
            [style.fill]="(option.selected ? viewModel.iconBackgroundColorCss : null) | sanitizeStyle" cx="9" cy="9"
            r="7.5"/>
    <circle class="insideCircle"
            [style.fill]="(option.selected ? viewModel.iconBackgroundColorCss : null) | sanitizeStyle" cx="9" cy="9"
            r="3.5"/>
    <circle class="border"
            [style.stroke]="(option.selected ? viewModel.iconBackgroundColorCss : null) | sanitizeStyle" cx="9" cy="9"
            r="7.5"/>
  </svg>
  <span class="label">{{option.label}}</span>
</button>
