import {__, ___, LocalDateTime, Option} from "@utils";
import {CalendarEntryModel} from "./calendar.component";

export class CalendarRow {

  constructor(
    readonly rowNumber: number,
    readonly cells: Array<CalendarCell>) {
  }

}

export class CalendarCell {
  constructor(readonly column: number,
              readonly mock: boolean,
              readonly off: boolean,
              readonly visibleEntries: Array<CalendarEntryPart>,
              readonly entries: Array<CalendarEntryPart>,
              public rows: number) {
  }

  pushEntryToEmptySpace(entry: CalendarEntryPart, maximumVisibleEntries: number): void {
    for (let i = 0; i < this.visibleEntries.length; i++) {
      if (!this.visibleEntries[i]) {
        this.visibleEntries[i] = entry;
        entry.index = i;
        return;
      }
    }
    if (this.visibleEntries.length < maximumVisibleEntries) {
      this.visibleEntries.push(entry);
      entry.index = this.visibleEntries.length - 1;
    }
    console.log("Pushed entry to index " + entry.index + " of column " + this.column, entry);
    entry.parentCell = this;
    this.entries.push(entry);
  }

  pushMockToIndex(entryPart: CalendarEntryPart, maximumVisibleEntries: number): void {
    if (entryPart.index < maximumVisibleEntries) {
      if (this.visibleEntries[entryPart.index]) {
        throw new Error("Entry occupied already");
      } else {
        this.visibleEntries[entryPart.index] = CalendarEntryPart.mock(this, entryPart);
        entryPart.parentCell = this;
        console.log("Pushing mock to index " + entryPart.index + " of column " + this.column, entryPart);
        entryPart.mocks.push(this.visibleEntries[entryPart.index]);
      }
    }
  }


  fillEmptyWithMocks() {
    for (let i = 0; i < this.visibleEntries.length; i++) {
      if (!this.visibleEntries[i]) {
        this.visibleEntries[i] = CalendarEntryPart.mock(this, null);
      }
    }
  }

  updateMaxRows(rows: number, level: number) {
    this.rows = rows;
    this.visibleEntries.forEach(e => {
      if (e.rows < rows) {
        e.updateMaxRows(rows, level + 1);
      }
    })
  }

}

export class CalendarEntryPart {

  readonly mocks: Array<CalendarEntryPart> = [];
  public parent: CalendarEntryPart|null = null;

  constructor(readonly entry: CalendarEntryModel|null,
              public parentCell: CalendarCell|null,
              public index: number,
              readonly startDate: LocalDateTime|null,
              public endDate: LocalDateTime|null,
              readonly rowNumber: number,
              readonly startColumn: number,
              public endColumn: number,
              public span: number,
              public cssSize: string,
              readonly name: string,
              readonly color: string,
              readonly inverted: boolean,
              readonly placeholder: boolean,
              public rows: number) {
  }

  static mock(parentDay: CalendarCell, parent: CalendarEntryPart|null) {
    const mock = new CalendarEntryPart(null, parentDay, -1, null, null, 0, 0, 0, 0, "calc(100% + 1px)", "", "", false, true, 0);
    mock.parent = parent;
    return mock;
  }

  updateMaxRows(rows: number, level: number = 1) {
    this.rows = rows;
    if (this.parent && this.parent.rows < rows) {
      console.log("Updating parent to " + rows, level);
      this.parent.updateMaxRows(rows, level + 1);
    }
    this.mocks.forEach(m => {
      if (m.rows < rows) {
        console.log("Updating mock to " + rows, level);
        m.updateMaxRows(rows, level + 1);
      }
    });

    if (this.parentCell && this.parentCell.rows < rows) {
      this.parentCell.updateMaxRows(rows, level + 1);
    }

  }
}


export class CalendarHelper {

  static colors: Array<string> = ["#5c6bc0", "#ffeb3b", "#66bb6a", "#7e57c2", "#ff7043", "#29b6f6",
    "#ffca28", "#37ad96", "#ab47bc", "#ef5350", "#26c6da", "#ffa726",
    "#9ccc65", "#ec407a", "#f37b50", "#d4e157"];
  static inverted: Array<boolean> = [true, false, true, true, true, true, false, true, true, true, false, true, true, true, true, false];

  static DEFAULT_COLOR = "#bdefe5";

  static hashCode(text: string): number {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash += Math.pow(text.charCodeAt(i) * 31, text.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }


  static prepareSeries(fromDateTime: LocalDateTime, toDateTime: LocalDateTime,
                       entires: Array<CalendarEntryModel>,
                       seriesFilter: (entry: CalendarEntryModel) => boolean,
                       entryNameProvider: (entry: CalendarEntryModel) => string,
                       rowNumberProvider: (dateTime: LocalDateTime) => number,
                       columnNumberProvider: (dateTime: LocalDateTime) => number,
                       rowStart: (dateTime: LocalDateTime) => boolean,
                       cellStepMinutes: number,
                       calendarRows: Array<CalendarRow>,
                       maximumVisibleEntries: number) {

    const sorted = ___(entires).filter(seriesFilter).sortBy(v => v.from).value();

    sorted.forEach(entry => {

      const [cssColor, inverted]: [string, boolean] = Option.of(entry.entryType).map(r => r.valueToSimpleString().toLowerCase()).map(entryType => {
        const id = (CalendarHelper.hashCode(entryType) % 16 + 16) % 16; // hashCode can be negative
        return <[string, boolean]>[CalendarHelper.colors[id], CalendarHelper.inverted[id]];
      }).getOrElse(<[string, boolean]>[CalendarHelper.DEFAULT_COLOR, false]);

      const entryName = entryNameProvider(entry);

      const entryParts: Array<CalendarEntryPart> = [];

      const from = entry.from;
      const to = entry.to ? entry.to : from;

      let currentTime = from;

      const startColumn = columnNumberProvider(from);
      let entryPart = new CalendarEntryPart(entry, null, -1, from, from, rowNumberProvider(from), startColumn, startColumn, 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, 1);
      entryParts.push(entryPart);
      console.log("appending", entryPart);
      currentTime = currentTime.plusMillis(cellStepMinutes * 60 * 1000);
      while (!currentTime.isAfter(to) && currentTime.isBefore(toDateTime)) {


        if (rowStart(currentTime)) {
          const column = columnNumberProvider(currentTime);
          entryPart = new CalendarEntryPart(entry, null, -1, currentTime, currentTime, rowNumberProvider(currentTime), column, column, 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, 1);
          entryParts.push(entryPart);
          console.log("appending", entryPart);
        } else {
          entryPart.span++;
          entryPart.endDate = currentTime;
          entryPart.endColumn = columnNumberProvider(currentTime);
          entryPart.cssSize = "calc(" + 100 * entryPart.span + "% + " + entryPart.span + "px)";
          console.log("appending day to " + entryPart.endColumn + ", " + entryPart.span);
        }
        currentTime = currentTime.plusMillis(cellStepMinutes * 60 * 1000);

      }

      entryParts.forEach(entryPart => {
        calendarRows.forEach(row => {
          if (row.rowNumber == entryPart.rowNumber) {
            row.cells.forEach(cell => {
              if (row.rowNumber == entryPart.rowNumber && cell.column == entryPart.startColumn) {
                cell.pushEntryToEmptySpace(entryPart, maximumVisibleEntries);
              } else if (cell.column > entryPart.startColumn && cell.column <= entryPart.endColumn) {
                cell.pushMockToIndex(entryPart, maximumVisibleEntries);
              }
            });
          }
        })

      });

    });

    calendarRows.forEach(calendarRow => {
      calendarRow.cells.forEach(cell => {
        cell.fillEmptyWithMocks();
        cell.rows = cell.visibleEntries.length;
      });
    });

    calendarRows.forEach(calendarRow => {
      calendarRow.cells.forEach(cell => {
        cell.visibleEntries.forEach(e => {
          e.updateMaxRows(cell.rows);
        });
      });
    });


    calendarRows.forEach(calendarRow => {
      calendarRow.cells.forEach(cell => {
        cell.rows = Math.max(cell.rows, __(cell.visibleEntries.map(e => e.rows)).maxOrDefault(1));
      });
    });
  }


}
