/**
 * Immutable wrapper for width and height values.
 */
export class Size {

  constructor(readonly width: number,
              readonly height: number) {}

  /**
   * It returns new Size object scaled by a given factor.
   */
  scale(factor: number) {
    return new Size(this.width * factor, this.height * factor);
  }

  plus(plusWidth: number, plusHeight: number) {
    return new Size(this.width + plusWidth, this.height + plusHeight);
  }

  isEqual(other: Size) {
    return this.width == other.width && this.height == other.height;
  }
}
