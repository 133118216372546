import {Injectable} from "@angular/core";
import {AuthenticatedHttp, BusinessVariable, BusinessVariableFactory} from "@shared-model";
import {ConstantId, Option, Typed} from "@utils";


export class Translations {
  constructor(readonly constant: ConstantId,
              readonly translations: Array<[Typed<BusinessVariable>, string]>) {}

  static copy(translations: Translations): Translations {
    return new Translations(
      translations.constant,
      translations.translations.map(([key, value]) => [BusinessVariableFactory.copyTyped(key), value])
    );
  }

  unwrappedTranslations(): Array<[BusinessVariable, string]> {
    return this.translations.map(([key, value]) => [Typed.value(key), value]);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConstantsSharedService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}


  findTranslations(constant: ConstantId): Promise<Option<Array<[BusinessVariable, string]>>> {
    return this.authenticatedHttp.getPromise<Option<Array<[Typed<BusinessVariable>, string]>>>("organization-constants/translations/"+constant.id)
      .then((data: Option<Array<[Typed<BusinessVariable>, string]>>) => {
        return Option.copy(data).map(translations => translations.map(([key, value]) => [Typed.value(BusinessVariableFactory.copyTyped(key)), value]));
      })
  }

}
