import {NgZone} from "@angular/core";
import {global} from "./global";

export function runInAngularZone<RESULT>(callback: () => RESULT): RESULT {
  if (NgZone.isInAngularZone()) {
    return callback();
  } else {
    return global.zone.run<RESULT>(callback);
  }
}

export function runOutsideAngularZone<RESULT>(callback: () => RESULT): RESULT {
  if (NgZone.isInAngularZone()) {
    return global.zone.runOutsideAngular<RESULT>(callback);
  } else {
    return callback();
  }
}
