import {__, ___, None, Option, Typed, validateVariablePath} from "@utils";
import {VariableContext} from "@screen-common";

export class VariablePathEntryFactory {
    static copy(input: VariablePathEntry): VariablePathEntry {
      return VariablePathEntryFactory.copyByType(input, input.className());
    }

    static copyTyped(action: Typed<VariablePathEntry>): Typed<VariablePathEntry> {
      return Typed.of(VariablePathEntryFactory.copyByType(Typed.value(action), Typed.className(action)));
    }

    static copyByType(entry: VariablePathEntry, className: string): VariablePathEntry {
      switch (className) {
        case Name.className: return new Name((<Name>entry).name);
        case Index.className: return new Index((<Index>entry).index);
        case AnyIndex.className: return new AnyIndex();
        default:throw new Error("Unsupported entry type class: " + className);
      }
    }
  }

  export interface VariablePathEntry {
    className(): string;
    equals(other: VariablePathEntry): boolean;
  }

  export class Name implements VariablePathEntry {
    static className = "Name";
    className() {
      return Name.className;
    }
    constructor(readonly name: string) {}

    equals(other: VariablePathEntry): boolean {
      if(other instanceof Name) {
        return this.name === other.name;
      } else {
        return false;
      }
    }

  }

  export class Index implements VariablePathEntry {
    static className = "Index";
    className() {
      return Index.className;
    }
    constructor(readonly index: number) {}

    equals(other: VariablePathEntry): boolean {
      if(other instanceof Index) {
        return this.index === other.index;
      } else {
        return false;
      }
    }
  }

  export class AnyIndex implements VariablePathEntry {
    static className = "AnyIndex";
    className() {
      return AnyIndex.className;
    }
    constructor() {}

    equals(other: VariablePathEntry): boolean {
      return other instanceof AnyIndex;
    }
  }

  export class ContextChange implements VariablePathEntry {
    static className = "ContextChange";
    className() {
      return ContextChange.className;
    }
    constructor(readonly context: VariableContext) {}

    equals(other: VariablePathEntry): boolean {
      if(other instanceof ContextChange) {
        return this.context === other.context;
      } else {
        return false;
      }
    }
  }


  // It can be VariableTypePath or VariablePath
  export class AnyVariablePath {
    constructor(readonly path: Array<Typed<VariablePathEntry>>) {}

    static of(path: Array<VariablePathEntry>) {
      return new AnyVariablePath(path.map(Typed.of));
    }

    concat(childPath: AnyVariablePath) {
      return new AnyVariablePath(this.path.concat(childPath.path));
    }

    pathUnwrapped(): Array<VariablePathEntry> {
      return this.path.map(Typed.value);
    }

    appendName(variableName: string) {
      const newPath = this.path.slice();
      newPath.push(Typed.of(new Name(variableName)));
      return new AnyVariablePath(newPath);
    }

    appendIndex(index: number): AnyVariablePath {
      const newPath = this.path.slice();
      newPath.push(Typed.of(new Index(index)));
      return new AnyVariablePath(newPath);
    }
    appendAnyIndex(): AnyVariablePath {
      const newPath = this.path.slice();
      newPath.push(Typed.of(new AnyIndex()));
      return new AnyVariablePath(newPath);
    }

    appendContextChange(scope: VariableContext): AnyVariablePath {
      const newPath = this.path.slice();
      newPath.push(Typed.of(new ContextChange(scope)));
      return new AnyVariablePath(newPath);
    }

    static parse(path: string): AnyVariablePath {
      const trimmed = path.trim();
      if(trimmed.length === 0) {
        return AnyVariablePath.EMPTY;
      } else {
        const entries = ___(path.split(/[\\.\\[]/)).filterNot(e => e.length === 0).map(e => {
          if(e.indexOf("]") == e.length - 1) {
            const index = e.substring(0, e.length - 1);
            if(index === "*") {
              return new AnyIndex();
            } else {
              return new Index(parseInt(index));
            }
          } else {
            return new Name(e);
          }
        }).value();

        return new AnyVariablePath(entries.map(Typed.of));
      }
    }

    startsWith(path: AnyVariablePath): boolean {
      if(this.path.length >= path.length()) {
        for(let i = 0; i < path.length(); i++) {
          if(!Typed.value(this.path[i]).equals(Typed.value(path.path[i]))) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }

    length(): number {
      return this.path.length;
    }

    dropHead(elementsToDrop: number): AnyVariablePath {
      return new AnyVariablePath(this.path.slice(elementsToDrop));
    }

    static EMPTY = new AnyVariablePath([]);

    static root(variableName: string) {
      if(variableName.trim().length == 0) {
        throw new Error("Empty name not allowed");
      } else {
        return new AnyVariablePath([Typed.of(new Name(variableName))]);
      }
    }

    toString(): string {
      let p = "";

      this.path.forEach(entry => {
        const unwrapped = Typed.value(entry);

        if(unwrapped instanceof Name) {
          if(p.length === 0) {
            p = unwrapped.name;
          } else {
            p += "."+unwrapped.name;
          }
        } else if(unwrapped instanceof Index) {
          p += "["+unwrapped.index+"]";
        } else if(unwrapped instanceof AnyIndex) {
          p += "[*]";
        } else if(unwrapped instanceof ContextChange) {
          p += `{${unwrapped.context.toString()}}`;
        } else {
          throw new Error("Incorrect path unwrapped '"+(typeof unwrapped)+"'");
        }
      });
      return p;

    }

    static copy(other: AnyVariablePath): AnyVariablePath {
      return new AnyVariablePath(other.path.map(VariablePathEntryFactory.copyTyped));

    }

    notEmpty() {
      return this.path.length > 0;
    }

    isEmpty() {
      return this.path.length === 0;
    }

    equals(other: AnyVariablePath) {
      if(this.path.length === other.path.length) {
        for(let i = 0; i < this.path.length; i++) {
          if(!Typed.value(this.path[i]).equals(Typed.value(other.path[i]))) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }

    toVariableTypePath() {
      return new VariableTypePath(this.pathUnwrapped().filter(p => p.className() === Name.className).map(p => (<Name>p).name));
    }
  }

  export class VariablePath {
    constructor(readonly path: string) {
      // todo add validation of path correctness
    }

    static parse(path: string): VariablePath {
      if(validateVariablePath(path)) {
        return new VariablePath(path);
      } else {
        throw new Error("Incorrect path: "+path);
      }
    }

    static of(splitted: Array<string|number>): VariablePath {
      var path = "";
      for(let i = 0; i < splitted.length; i++) {
        if(typeof splitted[i] === "number") {
          path += "["+splitted[i]+"]";
        } else {
          if(path.length == 0) {
            path += splitted[i];
          } else {
            path += "."+splitted[i]
          }
        }
      }
      return new VariablePath(path);
    }

    static copy(other: VariablePath) {
      return new VariablePath(other.path)
    }

    static root(name: string, index: Option<number> = None()) {
      if(index.isDefined()) {
        return new VariablePath(name+"["+index.get()+"]");
      } else {
        return new VariablePath(name);
      }
    }

    static empty() {
      return new VariablePath("");
    }

    private splitted() {
      return this.path.split(/[\.\[]/g).filter(e => e.length > 0).map(entry => {
        if(entry.charAt(entry.length - 1) === "]") {
          return parseInt(entry.substring(0, entry.length - 1))
        } else {
          return entry;
        }
      });
    }


    lastName(): string {
      const l = __(this.splitted()).last();
      if(typeof l === "number") {
        throw new Error("Trying to get last entry which is index");
      } else {
        return l;
      }
    }

    isEmpty() {
      return this.path.length == 0;
    }

    notEmpty() {
      return this.path.length > 0
    }


    isRoot() {
      return this.splitted().length == 1;
    }

    nonRoot() {
      return !this.isRoot();
    }

    context() {
      return VariablePath.of(__(this.splitted()).init());
    }

    headIndex() {
      const l = __(this.splitted()).first();
      if(typeof l === "string") {
        throw new Error("Trying to get first index which is string");
      } else {
        return l;
      }
    }

    headName(): string {
      const l = __(this.splitted()).first();
      if(typeof l === "number") {
        throw new Error("Trying to get first entry which is index");
      } else {
        return l;
      }
    }

    headNameIndex(): number {
      const l = __(this.splitted()).first();
      const i = ___(this.splitted()).rest().first();
      if(typeof l === "string" && typeof i === "number") {
        return i;
      } else {
        throw new Error("Trying to get first index from incorrect path: " + this.path);
      }
    }

    headIsIndexed(): boolean {
      const splitted = this.splitted();
      if(splitted.length > 1) {
        const l = __(splitted).first();
        const i = ___(splitted).rest().first();
        return typeof l === "string" && typeof i === "number";
      } else {
        return false;
      }
    }

    startsWithIndex() {
      const l = __(this.splitted()).first();
      return typeof l === "number";
    }

    tail(): VariablePath {
      return VariablePath.of(__(this.splitted()).rest());
    }

    toString() {
      return this.path;
    }

    isEqual(other: VariablePath) {
      return this.path === other.path;
    }

    concat(other: VariablePath) {
      if(this.isEmpty()) {
        return other;
      } else if(other.isEmpty()) {
        return this;
      } else if(other.path.indexOf("[") == 0) {
        return new VariablePath(this.path + other.path);
      } else {
        return new VariablePath(this.path + "." + other.path);
      }
    }

    static rootIndex(index: number) {
      return new VariablePath("[" + index + "]");
    }

  }

  export class VariableTypePath {

    constructor(public path: Array<string>) {}

    static copy(other: VariableTypePath) {
      return new VariableTypePath(other.path.slice());
    }

    static root(variableName: string) {
      return new VariableTypePath([variableName]);
    }

    toVariablePath(indices: Array<Option<number>>) {
      let p: string = "";
      for(let i = 0; i < indices.length; i++) {
        if(p.length > 0) {
          p += ".";
        }
        if(indices[i].isDefined()) {
          p += this.path[i] + "["+indices[i].get()+"]";
        } else {
          p += this.path[i];
        }
      }
      return new VariablePath(p);
    }

    isEmpty() {
      return this.path.length < 1;
    }

    isRoot() {
      return this.path.length == 1;
    }

    last() {
      return __(this.path).last();
    }

    head() {
      return __(this.path).first();
    }

    tail(): VariableTypePath {
      return new VariableTypePath(__(this.path).rest());
    }

    variableNameOnly() {
      return this.last();
    }

    changeVariableName(variableName: string) {
      const newPath = this.path.slice();
      newPath[newPath.length - 1] = variableName;
      return new VariableTypePath(newPath);
    }

    context() {
      return new VariableTypePath(this.path.slice(0, this.path.length - 1));
    }

    toString() {
      return this.path.join(".");
    }

    isEqual(other: VariableTypePath) {
      return this.toString() === other.toString();
    }

    addVariableToContext(variableName: string) {
      const newPath = this.path.slice();
      newPath.push(variableName);
      return new VariableTypePath(newPath);
    }

    hasPrefix(prefix: VariableTypePath) {
      if(this.path.length >= prefix.path.length) {
        for(let i = 0; i < prefix.path.length; i++) {
          if(this.path[i] !== prefix.path[i]) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }

    changePrefix(newPrefix: VariableTypePath) {
      return new VariableTypePath(newPrefix.path.concat(this.path.slice(newPrefix.path.length)));
    }

    static parse(variableTypePath: string) {
      return new VariableTypePath(variableTypePath.split("."));
    }

    concat(variableTypePath: VariableTypePath) {
      return new VariableTypePath(this.path.concat(variableTypePath.path));
    }

    static empty() {
      return new VariableTypePath([]);
    }
  }
