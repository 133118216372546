import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {LocalTime, mySetTimeout} from "@utils";
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'my-time',
  templateUrl: './time.component.html',
  host: {
    "[class.pureLook]" : "pureLook"
  }
})
export class TimeComponent implements OnInit, OnDestroy {
  private _value: LocalTime|null = null;
  get value(): LocalTime | null {return this._value;}
  @Input({required: true}) set value(value: LocalTime | null) {this._value = value; this.onValueChange()}

  @Input() useSmartParser: boolean = true;
  @Input() clearButton: boolean = false;
  @Input() nowButton: boolean = false;
  @Output() valueChange = new EventEmitter<LocalTime|null>();
  @Output() valueSet = new EventEmitter<LocalTime|null>();

  @Input() pureLook: boolean = false; // remove all visual decorations, it will be handled externally

  @Input() disabled: boolean = false;


  @Input() placeholder = "hh:mm";

  @ViewChild("InputElement") private inputElement: ElementRef<HTMLInputElement> | undefined;

  @Input() openOnShow: boolean = false;

  focused: boolean = false;
  model: string | null = null;
  lastValue:LocalTime|null = null;

  contentChangeNotifier: Subject<void> = new Subject();

  ngOnInit(): void {
    this.lastValue = this._value;
    this.updateModel();
    if(this.openOnShow) {
      this.onFocus();
    }
  }

  ngOnDestroy(): void {
  }

  onFocus(): void {
    if(!this.focused && !this.disabled) {
      this.focused = true;
      this.updateModel();
      this.selectInput();
    }
  }

  onUnfocus(picked: boolean = false): void {
    if (this.focused) {
      this.focused = false;
      this.updateModel(picked);
    }
  }

  onInputValueChange(): void {
    this.handleVariableChange();
  }

  onInputValueConfirm(): void {
    this.updateValueAndModel();
    this.onInputValueChange();
  }

  onPickerValueSelected(): void {
    this.onUnfocus(true);

  }

  onPickerClosed(): void {
    if (!this.areValueAndModelInSync()) {
      this.updateValueAndModel();
    }
    this.onUnfocus();
  }

  setNow(): void {
    this._value = LocalTime.now();
    this.updateModel();
    this.onUnfocus();
  }

  setEmpty(): void {
    this._value = null;
    this.updateModel();
    this.onUnfocus();
  }

  private areValueAndModelInSync(): boolean {
    return (this._value) ? this.formatValue(this._value) == this.model : this.model == null;
  }

  private updateModel(picked: boolean = false): void {
    this.model = this.formatValue(this._value);
    this.handleVariableChange(picked);
  }

  updateValue(): void {
    if (this.model && this.model.trim() != "") {
      this._value = this.defaultParseModel(this.model);
    } else {
      this._value = null;
    }
  }

  updateValueAndModel(): void {
    if (this.model && this.model.trim() != "") {
      this._value = this.defaultParseModel(this.model);
      this.model = this.formatValue(this._value);
    } else {
      this._value = null;
      this.model = null;
    }
  }

  private formatValue(value: LocalTime | null): string | null {
    if (value && value.isValid()) {
      return value.formattedToMinutes();
    } else {
      return null;
    }
  }

  private defaultParseModel(model: string): LocalTime | null {
    const parseTry = LocalTime.of(model);
    return parseTry.isSuccess() ? parseTry.result : null;
  }

  private selectInput() {
    mySetTimeout(() => this.inputElement?.nativeElement.select());
  }

  private handleVariableChange(picked: boolean = false) {
    if((this._value === null && this.lastValue === null) || (this._value !== null && this.lastValue !== null && this._value.isEqual(this.lastValue))){
      return;
    } else {
      this.lastValue = this._value;
      this.valueChange.emit(this._value);
      if(picked) {
        this.valueSet.emit(this._value);
      }
    }
  }

  private onValueChange() {
    this.model = this.formatValue(this._value);
  }

  onModelChange() {
    this.updateValueAndModel();
  }

  protected readonly blur = blur;

  onPickerSizeChanged() {
    this.contentChangeNotifier.next();
  }
}
