import {Component, Input} from "@angular/core";
import {LinkComponentViewModel} from "./LinkComponentViewModel";

@Component({
  selector: 'a[link-component]',
  templateUrl: './link-component.component.html',
  host: {
    "[class]": "'componentBody LinkComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[href]": "viewModel.url",
    "[target]": "'_blank'",
  }
})
export class LinkComponentComponent {
  @Input({required:true}) viewModel!: LinkComponentViewModel;

}
