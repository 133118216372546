import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState,
  TextPropertiesState
} from "../..";
import {Option} from "@utils";

export class LinkComponentState extends ScreenComponentState {
  static className = "LinkComponentState";
  className() {
    return LinkComponentState.className;
  }

  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  readonly textState = new TextPropertiesState(this.properties);

  get text() {
    return this.properties.i18nTextProperty("text");
  }

  get url() {
    return this.properties.stringProperty("url");
  }

  static copy(other: LinkComponentState) {
    return new LinkComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class LinkComponentRefState extends ScreenComponentRefState {
  static className = "LinkComponentRefState";
  className() {
    return LinkComponentState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: LinkComponentRefState) {
    return new LinkComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}


