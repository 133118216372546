import {BusinessVariable, DateTimeVariable} from "@shared-model";
import {DateTimeInputComponentDefinition} from "@screen-common";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  LabelPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";

export class DateTimeInputComponentState extends LabeledScreenComponentState {
  static className = "DateTimeInputComponentState";

  className() {
    return DateTimeInputComponentState.className;
  }

  readonly textState = new TextPropertiesState(this.properties);
  override readonly labelState = new LabelPropertiesState(this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalDateTimeProperty(DateTimeInputComponentDefinition.MODEL);
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }

  get displayMode() {
    return this.properties.stringProperty("displayMode");
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case DateTimeInputComponentDefinition.MODEL:
        if (value.isDefined() && value.get() instanceof DateTimeVariable) {
          this.properties.putValue(DateTimeInputComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type DateTime but [" + value.get().simpleValueType() + "]");
        } else {
          this.properties.clearValue(DateTimeInputComponentDefinition.MODEL);
        }
        break;
      default:
        throw new Error("Model [" + modelName + "] is not supported by " + this.className());
    }
  }

  static copy(other: DateTimeInputComponentState) {
    return new DateTimeInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class DateTimeInputComponentRefState extends ScreenComponentRefState {
  static className = "DateTimeInputComponentRefState";

  className() {
    return DateTimeInputComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: DateTimeInputComponentRefState) {
    return new DateTimeInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
