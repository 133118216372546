<div *ngIf="warningsEnabled" class="systemWarningsBar">
  <span *ngIf="licenceExpirationSoon">
    <i class="warningIcon mi-shield-exclamation"></i>
    <span>{{'common_license_expires_soon' | i18n}} <my-time-summary [time]="licenceExpiration" /></span>
  </span>
  <span *ngIf="licenceExpired" class="systemError">
    <i class="warningIcon mi-shield-exclamation"></i>
    {{'common_license_expired' | i18n}}
  </span>
  <span *ngIf="usersLimitExceeded">
    <i class="warningIcon mi-shield-exclamation"></i>
    {{'common_users_limit_exceeded' | i18nCount:usersLimitExceededBy}}
  </span>
  <button class="theme-button-flat-icon-small" (click)="closeSystemWarnings()"><i class="mi-delete"></i></button>
</div>
<div *ngIf="menuNavigationPage" class="defaultPage" [class.warningsEnabled]="warningsEnabled">
  <div class="mainNavigationBackground" [class.visible]="panelBackgroundVisible" (click)="hideNotificationsPanel()"></div>
  <div class="mainNavigation">
    <my-main-menu />
    <my-notifications-panel (visibilityChanged)="panelBackgroundVisible = $event"/>
  </div>
  <div class="pageContent">
    <router-outlet />
  </div>
  <my-mobile-menu />
</div>
<div *ngIf="simplePage" class="simplePage">
  <router-outlet />
</div>
<div class="pagePreloader" [class.visible]="preloaderVisible"><div class="bar"></div></div>
