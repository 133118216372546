import {AnyFlowId, AnyFlowIdFactory, None, Option, ProcessId, Some, Typed} from "@utils";
import {FlowInfoForUser} from "./FlowModel";
import {TaskInfoForUser} from "./TaskModel";

export class FlowAndTasksInfoForUser {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowInfo: Option<FlowInfoForUser>,
              readonly tasks: Array<TaskInfoForUser>,
              public processName: Option<string>) {
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  flowCode(): Option<string> {
    if(this.flowInfo.isDefined()) {
      return this.flowInfo.flatMap(i => i.flowCode.length > 0 ? Some(i.flowCode) : None());
    } else {
      return None();
    }
  }

  processId(): Option<ProcessId> {
    if(this.flowInfo.isDefined()) {
      return this.flowInfo.map(i => ProcessId.of(i.processId));
    } else if(this.tasks.length > 0) {
      return Some(ProcessId.of(this.tasks[0].processId));
    } else {
      return None();
    }
  }

  static copy(other: FlowAndTasksInfoForUser) {
    return new FlowAndTasksInfoForUser(AnyFlowIdFactory.copyTyped(other.flowId), Option.copy(other.flowInfo, FlowInfoForUser.copy),
      other.tasks.map(TaskInfoForUser.copy), Option.copy(other.processName));
  }

}

