<div class="metadataContent" *ngFor="let entry of viewModel.mainEntry">
  <metadata-object  [selectable]="selectable" (selectedChanged)="onSelectedChanged()"
                    class="root" *ngIf="entry.objectType" [viewModel]="viewModel" [entry]="entry"
                   [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels"
                   [showTypes]="showTypes" [nameEnabled]="false" (sizeChanged)="sizeChanged.emit()"/>
  <metadata-array
    [selectable]="selectable" (selectedChanged)="onSelectedChanged()"
    class="root" *ngIf="entry.arrayType" [viewModel]="viewModel" [entry]="entry"
                  [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels"
                  [showTypes]="showTypes" [nameEnabled]="false" (sizeChanged)="sizeChanged.emit()" />
  <metadata-simple
    [selectable]="selectable" (selectedChanged)="onSelectedChanged()"
    class="root" *ngIf="entry.simpleType" [viewModel]="viewModel" [entry]="entry"
                   [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels"
                   [showTypes]="showTypes" [nameEnabled]="false" (sizeChanged)="sizeChanged.emit()" />
</div>

<my-file-viewer [visible]="viewModel.fileViewerVisible"
                [files]="viewModel.viewableFiles"
                [currentFileIndex]="viewModel.viewableFileIndex"
                (close)="viewModel.onCloseFileViewer()" />

<my-email-viewer *ngIf="viewModel.emailViewerVisible && viewModel.viewableEmail"
                 [readOnly]="true" [usageAuthorized]="false" [administrationAuthorized]="false"
                 [emailUri]="viewModel.viewableEmail"
                 (closeRequested)="viewModel.onEmailCloseRequested()" />
