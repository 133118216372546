<ng-container *ngIf="preview && readOnly && !value">
  -
</ng-container>
<ng-container [ngSwitch]="viewModel.valueTypeName" *ngIf="!preview || !readOnly || value">
  <my-string-variable-input *ngSwitchCase="'String'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-password-variable-input *ngSwitchCase="'Password'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-i18n-text-variable-input *ngSwitchCase="'I18nText'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-number-variable-input *ngSwitchCase="'Number'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-boolean-variable-input *ngSwitchCase="'Boolean'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-date-variable-input *ngSwitchCase="'Date'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-date-time-variable-input *ngSwitchCase="'DateTime'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-time-variable-input *ngSwitchCase="'Time'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-interval-variable-input *ngSwitchCase="'Interval'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-organization-node-variable-input [applicationId]="applicationId" [persons]="true" *ngSwitchCase="'Person'" [value]="$any(value)" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [smallIcon]="true" />
  <my-organization-node-variable-input [applicationId]="applicationId" [departments]="true" *ngSwitchCase="'Department'" [value]="$any(value)" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [smallIcon]="true"/>
  <my-organization-node-variable-input [applicationId]="applicationId" [groups]="true" *ngSwitchCase="'Group'" [value]="$any(value)" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [smallIcon]="true" />
  <my-organization-node-variable-input [applicationId]="applicationId" [persons]="true" [departments]="true" [groups]="true" *ngSwitchCase="'OrganizationNode'" [value]="$any(value)" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [smallIcon]="true" />
  <my-file-variable-input *ngSwitchCase="'File'" [serverModel]="serverModel" [value]="value" [path]="path" [readOnly]="readOnly"
                          [preview]="preview" [change]="variableChange" [previewRequested]="previewRequested" />
  <my-case-variable-input *ngSwitchCase="'Case'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-case-code-variable-input *ngSwitchCase="'CaseCode'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" />
  <my-email-variable-input *ngSwitchCase="'Email'" [serverModel]="serverModel" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [previewRequested]="previewRequested" />
  <my-business-entity-variable-input *ngSwitchCase="'BusinessEntity'" [value]="value" [path]="path" [readOnly]="readOnly" [preview]="preview" [change]="variableChange" [valueType]="$any(valueType)"/>
  <div *ngSwitchDefault>{{viewModel.previewText}}</div>
</ng-container>



