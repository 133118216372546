import {None, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider, HeaderProperties, LayoutsProperties, OptionalContextContainerDefinition,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenContainerRef
} from "@screen-common";
import {
  OptionalI18nTextProperty,
  OptionalModelProperty,
  OptionalStringProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";


export class SectionContainerRef extends ScreenContainerRef {
    static className = "SectionContainerRef";
    className() {
      return SectionContainerRef.className;
    }

    constructor(
      override readonly id: ScreenComponentRefId,
      override readonly componentId: ScreenComponentId,
      override readonly applicationScreen: Option<ScreenId>,
      override readonly properties: ComponentProperties
    ) {
      super();
    }


    static copy(other: SectionContainerRef): SectionContainerRef {
      return new SectionContainerRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      )
    }

    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.ofFallbacks("SectionRef", DefaultPropertyProvider.ref);

    override defaultPropertiesProvider = SectionContainerRef.DEFAULT_PROPERTIES;

    override createDefaultDefinition(id: ScreenComponentId): ScreenComponentDefinition {
      return new SectionContainerDefinition(id, None(), ComponentProperties.empty(), new ComponentActionProperties([]), new ComponentValidationRules([]), []);
    }
  }

  export class SectionContainerDefinition extends OptionalContextContainerDefinition {

    static className = "SectionContainerDefinition";

    className() {
      return SectionContainerDefinition.className;
    }
    typeName() {
      return "Section";
    }


    constructor(
      override readonly id: ScreenComponentId,
      override readonly identifier: Option<string>,
      override readonly properties: ComponentProperties,
      readonly actionProperties: ComponentActionProperties,
      override readonly validationRules: ComponentValidationRules,
      public children: Array<ScreenComponentRefId>) {
      super(id, identifier, properties, validationRules, SectionContainerDefinition.DEFAULT_PROPERTIES);
    }

    get model(): OptionalModelProperty {return this.properties.getOptionalModelProperty("model", this.defaultPropertiesProvider)};
    get header(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("header", this.defaultPropertiesProvider)}

    layoutsProperties = new LayoutsProperties("", this.properties, this.defaultPropertiesProvider);
    headerProperties = new HeaderProperties(this.properties);


    contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextSize", defaultProvider)}
    contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextColor", defaultProvider)}
    contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextFont", defaultProvider)}

    paddingsProperties = new PaddingsProperties("", this.properties);
    bordersProperties = new BordersProperties(this.properties);
    backgroundsProperties = new BackgroundsProperties("", this.properties);


    override getChildren(): Array<ScreenComponentRefId> {
      return this.children;
    }

    static copy(other: SectionContainerDefinition): SectionContainerDefinition {
      return new SectionContainerDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules),
        other.children.map(ScreenComponentRefId.copy)
      )
    }



    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("SectionDefinition", (name:string) => {
      switch (name) {
        case "model": return OptionalModelProperty.disabled(); // used by root panel
        default: return null;
      }},
      DefaultPropertyProvider.definitionLabel,
      DefaultPropertyProvider.containerPlain,
      DefaultPropertyProvider.containerPaddings,
      DefaultPropertyProvider.definitionBackgrounds,
      DefaultPropertyProvider.definitionSectionHeader,
      DefaultPropertyProvider.definitionComponent,
      DefaultPropertyProvider.definitionBorders);

    override displayName(): string {
      return (this.header.settingsUnwrapped().toSettingsType().isStaticValue() ? this.header.staticValue.getCurrentWithFallback() : "");
    }
  }


