import {Component, Input, OnInit} from "@angular/core";
import {BusinessVariable} from "@shared-model";
import {LocalDateTime, None, Option} from "@utils";

import {DropDownSelectorOption} from "@shared";
import {MonthlyCalendarViewModel} from "./MonthlyCalendarViewModel";
import {WeeklyCalendarViewModel} from "./WeeklyCalendarViewModel";
import {ResourceCalendarViewModel} from "./ResourceCalendarViewModel";
import {YearlyCalendarViewModel} from "./YearlyCalendarViewModel";
import {CalendarEventBus} from "./CalendarEventBus";


export class CalendarEntryModel {
  constructor(readonly value: BusinessVariable,
              readonly resource: BusinessVariable|undefined,
              readonly entryType: BusinessVariable|undefined,
              readonly from: LocalDateTime,
              readonly to: LocalDateTime|null,
              readonly label: string) {}

  durationDays() {
    if(this.to) {
      return this.to.date.daysBetween(this.from.date) + 1;
    } else {
      return 1;
    }
  }

  durationMonths() {
    if(this.to) {
      const from = this.from.date;
      const to = this.to.date;
      return (to.year - from.year) * 12 - (to.month - from.month) + 1;
    } else {
      return 1;
    }
  }

}

export class CalendarSelectedPopupViewModel {

  constructor(readonly parent: CalendarComponent) {
  }
}

export enum CalendarComponentType {
  MONTHLY, WEEKLY, YEARLY, RESOURCES
}

export class CalendarTypesOption implements DropDownSelectorOption {
  constructor(readonly name: string,
              readonly value: CalendarComponentType) {}
}

@Component({
  selector: "my-calendar",
  templateUrl: "./calendar.component.html"
})
export class CalendarComponent implements OnInit {


  private _entries: Array<CalendarEntryModel> = [];
  get entries(): Array<CalendarEntryModel> {
    return this._entries;
  }
  @Input() set entries(value: Array<CalendarEntryModel>) {
    this._entries = value;
    if(this.initialized) {
      this.drawCalendar();
    }
  }

  public monthlyType: boolean = true;
  public weeklyType: boolean = false;
  public resourceType: boolean = false;
  public yearlyType: boolean = false;
  readonly calendarEventBus = new CalendarEventBus();


  readonly typeOptions = [
    new CalendarTypesOption("Miesięczny", CalendarComponentType.MONTHLY),
    new CalendarTypesOption("Tygodniowy", CalendarComponentType.WEEKLY),
    new CalendarTypesOption("Roczny", CalendarComponentType.YEARLY),
    new CalendarTypesOption("Zasoby", CalendarComponentType.RESOURCES)];


  @Input() calendarType = CalendarComponentType.MONTHLY;
  initialized = false;

  public selectedValue: Option<BusinessVariable> = None()

  public monthly = new MonthlyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  });

  public weekly = new WeeklyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public resource = new ResourceCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public yearly = new YearlyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public selectedPopupVisible = false;

  private static entryId = 0;


  static nextPeriodId() {
    return CalendarComponent.entryId++;
  }

  static hashCode(text: string): number {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash += Math.pow(text.charCodeAt(i) * 31, text.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  calendarTypeChanged = (calendarType: CalendarComponentType) => {
    this.calendarType = calendarType;
    this.monthlyType = calendarType == CalendarComponentType.MONTHLY;
    this.weeklyType = calendarType == CalendarComponentType.WEEKLY;
    this.resourceType = calendarType == CalendarComponentType.RESOURCES;
    this.yearlyType = calendarType == CalendarComponentType.YEARLY;
    if(this.monthlyType) {
      this.monthly.updateEntries(this.entries);
    }
    if(this.weeklyType) {
      this.weekly.updateEntries(this.entries);
    }
    if(this.resourceType) {
      this.resource.updateEntries(this.entries);
    }
    if(this.yearlyType) {
      this.yearly.updateEntries(this.entries);
    }
  };

  ngOnInit(): void {

    this.drawCalendar();
    this.initialized = true;

    this.calendarTypeChanged(this.calendarType);
  }



  drawCalendar(): void {

    this.monthly.updateEntries(this.entries);
    this.weekly.updateEntries(this.entries);
    this.resource.updateEntries(this.entries);
    this.yearly.updateEntries(this.entries);

    // this.entries = [
    //   new CalendarEntryModel(new CaseVariable(new FlowId("a")), Some(new PersonVariable(PersonId.of("aa"))), None(), LocalDateTime.fromLocalDateEnd(LocalDate.nowDate()), None(), "Test 1"),
    //   new CalendarEntryModel(new CaseVariable(new FlowId("b")), Some(new PersonVariable(PersonId.of("bb"))), None(), LocalDateTime.fromLocalDateEnd(LocalDate.nowDate().plusDays(1)), None(), "Test 2"),
    //   new CalendarEntryModel(new CaseVariable(new FlowId("c")), Some(new PersonVariable(PersonId.of("cc"))), None(), LocalDateTime.fromLocalDateEnd(LocalDate.nowDate().plusDays(3)), Some(LocalDateTime.fromLocalDateEnd(LocalDate.nowDate().plusDays(5))), "Test 3"),
    // ]


    //
    //
    // this.entries = this.componentState.entries.unwrappedValue().map(entry => {
    //
    //   const fromVariable: BusinessVariable = entry.valueFor("from").getOrError("required 'from' value not available");
    //   const toVariable = entry.valueFor("to");
    //
    //   let from: LocalDateTime;
    //   if(fromVariable.className() == DateVariable.className) {
    //     from = LocalDateTime.fromLocalDateEnd((<DateVariable>fromVariable).value);
    //   } else if(fromVariable.className() == DateTimeVariable.className) {
    //     from = (<DateTimeVariable>fromVariable).value;
    //   } else {
    //     throw new Error("'From' must be Date or DateTime, not [" + fromVariable.className()+"]");
    //   }
    //
    //   let to: Option<LocalDateTime>;
    //   if(toVariable.isDefined()) {
    //     if(toVariable.get().className() == DateVariable.className) {
    //       to = Some(LocalDateTime.fromLocalDateEnd((<DateVariable>toVariable.get()).value));
    //     } else if(toVariable.get().className() == DateTimeVariable.className) {
    //       to = Some((<DateTimeVariable>toVariable.get()).value);
    //     } else {
    //       throw new Error("'To' must be Date or DateTime, not [" + fromVariable.className()+"]");
    //     }
    //   } else {
    //     to = None();
    //   }
    //
    //
    //   const value = entry.valueFor("value").getOrError("Required 'value' not available");
    //   const label = entry.valueFor("label").map(d => d.valueToSimpleString()).getOrError("Required 'label' not available");
    //   const type = entry.valueFor("type");
    //   const resource = entry.valueFor("resource");
    //   return new CalendarEntryModel(value, resource, type, from, to, label);
    // });
    //
    // this.selectedValue = this.componentState.selected.valueOrDefault(None());
    // //
    // this.monthly.updateEntries(this.entries);
    // this.weekly.updateEntries(this.entries);
    // this.resource.updateEntries(this.entries);
    // this.yearly.updateEntries(this.entries);

  }


  entrySelected(entry: CalendarEntryModel) {
    // this.serverModel.changeModelWithAction(this.componentRefPath(), CalendarComponentDefinition.SELECTED, entry.value, CalendarComponentDefinition.ON_SELECTED_CHANGE);
    this.selectedPopupVisible = true;
    this.calendarEventBus.entrySelected(entry);
  }

  entryUnselected() {
    // this.serverModel.clearModel(this.componentRefPath(), CalendarComponentDefinition.SELECTED);
    this.selectedPopupVisible = false;
    this.calendarEventBus.entryDeselected();
  }

  private injectSelectedPopup(selectedPopup: Option<CalendarSelectedPopupViewModel>) {
    // this.selectedPopup = selectedPopup;
  }

  childDeltaHeightChanged(): void {
  }




}
