import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http'
import {Observable} from 'rxjs';
import {i18n, isStandalonePage, restUrl, toastr} from "@utils";
import {SessionServiceProvider} from "@shared";
import {OfflineService} from "../shared/services/offline/offline.service";

/**
 * Service that provides REST interface to Server Side of the application.
 * It add credential information to request and can handle Unauthorized message.
 * It should be used instead of plain $http service when user authentication is needed.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticatedHttp {

  readonly defaultResponseType = "json";

  constructor(private http: HttpClient,
              private sessionServiceProvider: SessionServiceProvider,
              private offlineService: OfflineService) {
  }

  private handleRequest(url: string, request: any | null, customResponseType: string,
                        onSuccess: (data: any, status: any, headers: any) => void,
                        onError: (data: any, status: any) => void,
                        secondTry: boolean = false, binary: boolean = false) {

    this.sessionServiceProvider.getSessionService(sessionService => {
      const sessionToken = sessionService.sessionToken;

      if (sessionToken === null) {
        throw new Error("Session token is null");
      } else {

        const promise: Observable<HttpResponse<any>> = request === null
          ? <any>this.http.get(restUrl(url), this.createAuthorizationHeaderConfig(sessionToken, binary, customResponseType))
          : <any>this.http.post(restUrl(url), request, this.createAuthorizationHeaderConfig(sessionToken, binary, customResponseType));


        promise.subscribe({
          next: (result: HttpResponse<any>) => {
            onSuccess(result.body, result.status, result.headers);
          },
          error: (reason) => {
            console.log("AuthenticatedHttp error", reason);
            if (reason.status === 401) {
              if (secondTry) {
                onError(i18n("errors_not_authenticated"), reason.status);
              } else {
                sessionService.isAuthenticated(authenticated => {

                  const sessionNotChangedMeanwhile = (sessionToken === sessionService.sessionToken);
                  if (sessionNotChangedMeanwhile && !isStandalonePage()) {
                    sessionService.loginToOrganizationAutomatically(() => {
                      this.handleRequest(url, request, customResponseType, onSuccess, onError, true);
                    }, () => {
                      onError(i18n("errors_not_authenticated"), reason.status);
                    });
                  } else {
                    if (authenticated) {
                      this.handleRequest(url, request, customResponseType, onSuccess, onError, true);
                    } else {
                      onError(i18n("errors_not_authenticated"), reason.status);
                    }
                  }
                });

              }

            } else {
              if(reason.status === 0 || reason.status === 504) {
                this.offlineService.checkConnection(() => {
                  onError(reason.data, reason.status);
                });
              } else if (onError) {
                onError(reason.data, reason.status);
              } else {
                throw new Error("Not implemented");
                // rest.handleError(reason);
              }
            }
          }

        });
      }
    })
  }


  get<T>(restUrl: string, onSuccess: (data: T) => void, onError: () => void = () => {
  }) {
    this.handleRequest(restUrl, null, this.defaultResponseType,
      (data: any, status: any, headers: any) => onSuccess(data),
      (data: any, status: any) => onError());
  }

  getWithDetailedError<T>(restUrl: string, onSuccess: (data: T) => void, onError: (data: any, status: any, headers: any, xhrStatus: string) => void = () => {
  }) {
    this.handleRequest(restUrl, null, this.defaultResponseType,
      (data: any, status: any, headers: any) => onSuccess(data),
      (data: any, status: any) => onError(data, status, "", ""));
  }

  getPromise<T>(restUrl: string, secondTry: boolean = false, binary: boolean = false): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.handleRequest(restUrl, null, this.defaultResponseType,
        (data: any, status: any, headers: any) => resolve(data),
        (data: any, status: any) => {
          if(data) {
            toastr.error(status+": "+data);
          } else {
            toastr.error(status);
          }
          reject({status: status, data: data});
        },
        secondTry, binary);
    });
  }

  post(restUrl: string, requestData: any, onSuccess: (data: any) => void, onError: (status: number, data: any) => void = (status: number, data: any) => {
    toastr.error("Error handling request: " + status);
  }, secondTry: boolean = false, binary: boolean = false) {
    this.handleRequest(restUrl, requestData, this.defaultResponseType,
      (data: any, status: any, headers: any) => onSuccess(data),
      (data: any, status: any) => onError(status, data),
      secondTry, binary);
  }

  postWithCustomResponse(restUrl: string, requestData: any, responseType: string, onSuccess: (data: any) => void, onError: (status: number, data: any) => void = (status: number, data: any) => {
    toastr.error("Error handling request: " + status);
  }, secondTry: boolean = false, binary: boolean = false) {
    this.handleRequest(restUrl, requestData, responseType,
      (data: any, status: any, headers: any) => onSuccess(data),
      (data: any, status: any) => onError(status, data),
      secondTry, binary);
  }

  postPromise<T>(restUrl: string, requestData: any, secondTry: boolean = false, binary: boolean = false): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.handleRequest(restUrl, requestData, this.defaultResponseType,
        (data: any, status: any, headers: any) => resolve(data),
        (data: any, status: any) => {
          if(data) {
            toastr.error(status+": "+data);
          } else {
            toastr.error(status);
          }
          reject({status: status, data: data});
        },
        secondTry, binary);
    });
  }

  createAuthorizationHeaderConfig(sessionToken: string, binary: boolean = false, responseType: string): any {
    if (sessionToken.length === 0) {
      return {
        observe: "response",
        responseType: binary ? "arraybuffer" : responseType,
        withCredentials: true
      };

    } else {
      return {
        observe: "response",
        headers: {
          'Authorization': sessionToken // if this causes NPE then session initialization needs to be tweaked
        },
        responseType: binary ? "arraybuffer" : responseType,
        withCredentials: true
      };
    }
  }

}
