import {
  ComponentLabelViewModel,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SwitchComponentRefState,
  SwitchComponentState
} from "../..";
import {I18nText, mySetTimeout, None, Option, Some, VariableId} from "@utils";
import {BooleanVariable} from "@shared-model";
import {CssBuilder, SwitchComponentDefinition, SwitchComponentRef} from "@screen-common";

export class SwitchComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "Switch";

  private threeStates: boolean = false;
  public value: boolean | undefined;
  public tooltip: Option<string> = None();
  public css: string = "";
  public cssClasses: string = "";
  public required: boolean = false;
  public override label!: ComponentLabelViewModel;
  public yesLabel: string = "";
  public noLabel: string = "";

  public knobCssBackgroundColor: string|null = null;

  yesBackgroundColorCss: string|null = null;
  noBackgroundColorCss: string|null = null;
  noTextColorCss: string|null = null;


  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: SwitchComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: SwitchComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: SwitchComponentState,
              readonly refState: SwitchComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
              ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onClick() {
    if(this.uncoveredAndVisible && !this.disabled) {

      if(this.threeStates) {
        if(this.value === true) {
          this.value = false;
        } else if(this.value === false) {
          this.value = undefined;
        } else {
          this.value = true;
        }
      } else {
        this.value = !this.value;
      }
      if(this.value === undefined) {
        this.componentState.updateModel(SwitchComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), SwitchComponentDefinition.MODEL, "onChange");
      } else {
        this.componentState.updateModel(SwitchComponentDefinition.MODEL, Some(new BooleanVariable(this.value)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), SwitchComponentDefinition.MODEL, new BooleanVariable(this.value), "onChange");
      }
    }
  }

  updateComponent(deep: boolean) {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider,this.definition.textProperties, this.componentState.textState);

    this.value = this.componentState.model.valueOrDefault(None()).getOrUndefined();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.yesLabel = this.definition.yesLabel.currentValue(() => this.componentState.yesLabel).valueOrDefault(None()).getOrElseLazy(() => I18nText.empty()).getCurrentWithFallback();
    this.noLabel = this.definition.noLabel.currentValue(() => this.componentState.noLabel).valueOrDefault(None()).getOrElseLazy(() => I18nText.empty()).getCurrentWithFallback();
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.threeStates = this.definition.threeStates.currentValue(() => this.componentState.threeStates).valueOrDefault(false);

    super.updatePosition();

    this.knobCssBackgroundColor = this.definition.knobColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.knobColor).valueOrDefault(None()).getOrNull();

    this.yesBackgroundColorCss = this.definition.yesBackgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.yesBackgroundColor).valueOrDefault(None()).getOrNull();
    this.noBackgroundColorCss = this.definition.noBackgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.noBackgroundColor).valueOrDefault(None()).getOrNull();
    this.noTextColorCss = this.definition.noTextColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.noTextColor).valueOrDefault(None()).getOrNull();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

  }

}

