import {__, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  HeaderProperties,
  LayoutsProperties,
  OptionalContextContainerDefinition,
  PaddingsProperties,
  ScreenContainerRef
} from "@screen-common";
import {
  BooleanProperty,
  I18nTextProperty,
  OptionalModelProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class TabEntryDefinition {
  constructor(readonly id: number,
              readonly children: Array<ScreenComponentRefId>) {}


  getHeader(properties: ComponentProperties): I18nTextProperty {return properties.getI18nTextProperty("tab|"+this.id+"|header", TabsContainerDefinition.DEFAULT_PROPERTIES)};
  getVisible(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("tab|"+this.id+"|visible", TabsContainerDefinition.DEFAULT_PROPERTIES)};
  getEditable(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("tab|"+this.id+"|editable", TabsContainerDefinition.DEFAULT_PROPERTIES)};
  getUncovered(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("tab|"+this.id+"|uncovered", TabsContainerDefinition.DEFAULT_PROPERTIES)};
  getEnabled(properties: ComponentProperties): BooleanProperty {return properties.getBooleanProperty("tab|"+this.id+"|enabled", TabsContainerDefinition.DEFAULT_PROPERTIES)};

  contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("tab|"+this.id+"|contentTextSize", defaultProvider)}
  contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("tab|"+this.id+"|contentTextColor", defaultProvider)}
  contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider, properties: ComponentProperties): OptionalStringProperty {return properties.getOptionalStringProperty("tab|"+this.id+"|contentTextFont", defaultProvider)}


  layoutsProperties(properties: ComponentProperties) {
    return new LayoutsProperties("tab|"+this.id+"|", properties, TabsContainerDefinition.DEFAULT_PROPERTIES);
  }

  paddingsProperties(properties: ComponentProperties) {
    return new PaddingsProperties("tab|"+this.id+"|", properties);
  }

  backgroundsProperties(properties: ComponentProperties) {
    return new BackgroundsProperties("tab|"+this.id+"|", properties);
  }

  static copy(other: TabEntryDefinition) {
    return new TabEntryDefinition(
      other.id,
      other.children.map(ScreenComponentRefId.copy)
    )
  }
}


export class TabsContainerRef extends ScreenContainerRef {
  static className = "TabsContainerRef";
  className() {
    return TabsContainerRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: TabsContainerRef): TabsContainerRef {
    return new TabsContainerRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    )
  }


  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.ofFallbacks("TabsRef", DefaultPropertyProvider.ref);

  override defaultPropertiesProvider = TabsContainerRef.DEFAULT_PROPERTIES;
}


export class TabsContainerDefinition extends OptionalContextContainerDefinition {
  static className = "TabsContainerDefinition";
  className() {
    return TabsContainerDefinition.className;
  }
  typeName() {
    return "Tabs";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules,
    public tabs: Array<TabEntryDefinition>) {
    super(id, identifier, properties, validationRules,TabsContainerDefinition.DEFAULT_PROPERTIES);
  }

  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);
  headerProperties = new HeaderProperties(this.properties);

  static copy(other: TabsContainerDefinition): TabsContainerDefinition {
    return new TabsContainerDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules),
      other.tabs.map(TabEntryDefinition.copy)
    )
  }

  get model(): OptionalModelProperty {return this.properties.getOptionalModelProperty("model", this.defaultPropertiesProvider)};

  override getChildren(): Array<ScreenComponentRefId> {
    return __(this.tabs).flatMap(t => t.children);
  }

  static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("TabsDefinition", (name:string) => {

    if(name.indexOf("tab|") == 0) {
      const tabSubName = name.substring(name.indexOf("|", name.indexOf("|") + 1) + 1, name.length);
      return DefaultPropertyProvider.ofFallbacks(tabSubName,
        DefaultPropertyProvider.containerPlain,
        DefaultPropertyProvider.containerPaddings,
        DefaultPropertyProvider.definitionBorders,
        DefaultPropertyProvider.definitionBackgrounds).getIfExistsOrNull(tabSubName);

    } else {
      switch (name) {
        default: return null;
      }
    }

  },
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent,
    DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionBackgrounds,
    DefaultPropertyProvider.definitionSectionHeader
  );
}
