<span class="inputHeader" *ngIf="viewModel.anchorVisible"><i class="mi-time-quarter-past"></i></span>

<input type="text"
       [style]="viewModel.innerCss"
       class="input dateInput durationInput {{viewModel.innerCssClasses}}"
       [placeholder]="viewModel.placeholder"
       [(ngModel)]="viewModel.textModel"
       (focus)="onFocused()"
       (change)="viewModel.textInputAccepted()"
       (keydown.enter)="requestBlur()"
       [disabled]="viewModel.disabled" />

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [(visible)]="viewModel.focused" [anchor]="inputElement" (closed)="viewModel.textInputAccepted()"
              [closeOnBlur]="true" [asModalOnMobile]="true">
  <ng-container *ngIf="viewModel.focused">
    <div class="actions">
      <button class="actionButton" tabindex="-1" (click)="viewModel.onClear()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      <div class="actionSpacer"></div>
    </div>
    <my-duration-picker-board [(duration)]="viewModel.value"
                              (durationChange)="viewModel.onChanged()"
                              (picked)="viewModel.onPicked()"
                              [hoursInDay]="viewModel.hoursInDays"
                              [useSeconds]="viewModel.useSecondsInPicker" />
  </ng-container>
</my-drop-menu>
