import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserSettingsStateService} from "@shared";
import {environment} from "@environments";


export enum StyleSet {
  sharedModel = "shared-model",
  user = "user",
  mainDashboard = "main-dashboard",
  tasks = "tasks",
  flows = "flows",
  taskForm = "task-form",
  documents = "documents",
  application = "application",
  screen = "screen",
  screenCommon = "screen-common",
  report = "report",
  mailbox = "mailbox",
  documentation = "documentation",
  dataTable = "dataTable",
  applications = "applications",
  designer = "designer",
  admin = "admin",
  help = "help",
  dashboard = "dashboard",
}

@Injectable({ providedIn: 'root' })
export class StylesResolver implements Resolve<boolean> {
  constructor(readonly userSettingsService: UserSettingsStateService) {

    userSettingsService.getThemeObservable().forEach(theme => this.onThemeOrDirectionChanged());
    userSettingsService.getLanguageObservable().forEach(language => this.onThemeOrDirectionChanged());

  }

  private stylesLoaded: Array<string> = [];

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|any {
    return new Promise((resolve, reject) => {

      const stylesRequested: Array<StyleSet> = route.data["styles"];
      this.loadStyles(... stylesRequested).then(() => {
        resolve(true);
      });

    });
  }

  loadStyles(...stylesRequested: Array<StyleSet>): Promise<void> {
    return new Promise((resolve, reject) => {
      const theme = this.userSettingsService.getEffectiveTheme().name;
      const textDirection = this.userSettingsService.getLanguageTheme();

      const toLoad: Array<string> = [];
      stylesRequested.forEach(style => {
        if(this.stylesLoaded.indexOf(style) < 0) {
          toLoad.push(style);
          this.stylesLoaded.push(style);
        }
      });

      if(toLoad.length == 0) {
        resolve();
      } else {

        let loadedCount = 0;

        toLoad.forEach(style => {
          let link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          link.id = "style_"+style;
          link.onload = () => {
            loadedCount++;
            if (loadedCount == toLoad.length) {
              resolve();
            }
          };
          link.href = "styles/"+style + "-"+theme+"-"+textDirection+".css?no-cache="+environment.buildStamp;

          let headScript = document.querySelector('script');
          if (headScript != null && headScript.parentNode != null) {
            headScript.parentNode.insertBefore(link, headScript);
          } else {
            throw new Error("No head script");
          }
        });

      }

    });
  }

  private getRelativeLocation(url: string): string {
    let rootLocation = window.location.protocol+"//"+window.location.host+"/";
    if(url.indexOf(rootLocation) == 0) {
      return url.substring(rootLocation.length);
    } else {
      throw new Error(`url '${url}' does not start with root '${rootLocation}'`);
    }
  }

  private onThemeOrDirectionChanged() {
    const theme = this.userSettingsService.getEffectiveTheme().name;
    const languageTheme = this.userSettingsService.getLanguageTheme();

    this.stylesLoaded.forEach(style => {
      const link = document.getElementById("style_"+style);
      if(link instanceof HTMLLinkElement) {
        const oldHref = this.getRelativeLocation(link.href);
        const newHref = "styles/"+style + "-"+theme+"-"+languageTheme+".css?no-cache="+environment.buildStamp;

        if(oldHref != newHref) { // setting the same href causes reload, and we don't want it
          link.href = newHref;
        }
      } else if (link == null) {
        throw new Error(`Link 'style_${style}' not found`);
      } else {
        throw new Error(`element 'style_${style}' is not a link`);
      }

    })

  }
}
