import {Component, Input} from "@angular/core";
import {ImageComponentViewModel} from "./ImageComponentViewModel";

@Component({
  selector: 'img[image-component]',
  templateUrl: './image-component.component.html',
  host: {
    "[class]": "'componentBody ImageComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[src]": "viewModel.fileSource.downloadUrl",
    "[class.emptySource]": "!viewModel.fileSource.hasDownloadUrl()"
  }
})

// What to do with *ngIf="viewModel.uncoveredAndVisible"?


export class ImageComponentComponent {
  @Input({required:true}) viewModel!: ImageComponentViewModel;
}
