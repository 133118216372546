import {ApplicationComponentId, ApplicationId, Option, ScreenId, ScreenInstanceId, Typed} from "@utils";

export class ScreenLifeMode {
  constructor(readonly name: string) {}

  static volatile = new ScreenLifeMode("volatile");
  static singleInstance = new ScreenLifeMode("singleInstance");
  static multipleInstances = new ScreenLifeMode("multipleInstances");

  static copy(other: ScreenLifeMode) {
    switch (other.name) {
      case ScreenLifeMode.volatile.name: return ScreenLifeMode.volatile;
      case ScreenLifeMode.singleInstance.name: return ScreenLifeMode.singleInstance;
      case ScreenLifeMode.multipleInstances.name: return ScreenLifeMode.multipleInstances;
      default: throw new Error("Unsupported life mode '"+other.name+"'");
    }
  }

  isVolatile() {
    return this.name === ScreenLifeMode.volatile.name;
  }
  isSingleInstance() {
    return this.name === ScreenLifeMode.singleInstance.name;
  }
  isMultipleInstances() {
    return this.name === ScreenLifeMode.multipleInstances.name;
  }
}


export class ScreenInstancesListUpdate {
  constructor(readonly instances: Array<ScreenInstanceSummary>) {}
  static copy(other: ScreenInstancesListUpdate) {
    return new ScreenInstancesListUpdate(other.instances.map(ScreenInstanceSummary.copy));
  }
}

export interface ScreenInstanceStateEvent {
  className(): string;
  version: number;
}


export class NewScreenInstanceEvents {
  constructor(readonly screenInstanceId: ScreenInstanceId, readonly events: Array<Typed<ScreenInstanceStateEvent>>, readonly error: Option<string>) {}

  get eventsUnwrapped(): Array<ScreenInstanceStateEvent> {
    return this.events.map(e => Typed.value(e));
  }

}

export class ScreenInstanceSummary {
  terminated: boolean = false;
  constructor(
    readonly applicationId: ApplicationId,
    readonly instanceId: ScreenInstanceId,
    readonly screenId: ScreenId,
    readonly mainTitle: string,
    readonly subTitle: Option<string>,
    readonly lifeMode: ScreenLifeMode
  ) {}

  static copy(other: ScreenInstanceSummary) {
    return new ScreenInstanceSummary(
      ApplicationId.of(other.applicationId),
      ScreenInstanceId.copy(other.instanceId),
      ScreenId.copy(other.screenId),
      other.mainTitle,
      Option.copy(other.subTitle),
      ScreenLifeMode.copy(other.lifeMode));
  }
}

export class ApplicationComponentRef {
  private constructor(readonly id: ApplicationComponentId|undefined, // must be undefined instead of null for proper JSON serialization
                      readonly identifier: string|undefined) {}// must be undefined instead of null for proper JSON serialization

  static ofId(id: ApplicationComponentId) {
    return new ApplicationComponentRef(id, undefined);
  }

  static ofIdentifier(identifier: string) {
    return new ApplicationComponentRef(undefined, identifier);
  }

  static copy(other: ApplicationComponentRef) {
    if(other.id != undefined) {
      return new ApplicationComponentRef(ApplicationComponentId.copy(other.id), undefined);
    } else {
      return new ApplicationComponentRef(undefined, other.identifier);
    }
  }

  idOption(): Option<ApplicationComponentId> {
    return Option.of(this.id);
  }
  identifierOption(): Option<string> {
    return Option.of(this.identifier);
  }

  static ofScreenId(screenId: ScreenId) {
    return new ApplicationComponentRef(ApplicationComponentId.ofScreenId(screenId), undefined);
  }
}
