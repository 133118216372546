import {AggregateVersion, AnyFlowId, AnyFlowIdFactory, displayExceptions, i18n, toastr, Typed} from "@utils";
import {
  AnyFlowAggregateConcurrentModificationError,
  AnyFlowCommandHandlingError,
  AnyFlowCommandResponse,
  AnyFlowEventHandlingError,
  asAnyFlowCommandHandlingError, asAnyFlowConcurrentModificationError,
  asAnyFlowCustomSuccess,
  asAnyFlowEventHandlingError,
  asAnyFlowFailure, asAnyFlowSuccess,
  isAnyFlowCommandHandlingError,
  isAnyFlowConcurrentModificationError,
  isAnyFlowCustomSuccess,
  isAnyFlowEventHandlingError,
  isAnyFlowFailure,
  isAnyFlowSuccess,
  isCommandHandlingError,
  isEventHandlingError
} from "@shared-model";


export class AnyFlowCommandResponseHandler {
  private data: Typed<AnyFlowCommandResponse>;

  private onSuccessHandler = (flowId: AnyFlowId, flowVersion: AggregateVersion) => {
  };

  private onFailureHandler = displayExceptions;

  private onConcurrentModificationErrorHandler = (error: AnyFlowAggregateConcurrentModificationError) => {
    toastr.error(concurrentModificationMessage(error));
    console.error(`Error: trying to modify incorrect entity (${Typed.value(error.flowId).urlSerialized()}), expected version (${error.expected.asInt}) but now it was version (${error.was.asInt}).`)
  };
  private onCommandHandlingErrorHandler = (error: AnyFlowCommandHandlingError) => {
    toastr.error(`500: Error handling command.`);
    console.error(`Error handling command (${error.commandName}, ${error.commandId.asLong})`)
  };
  private onEventHandlingErrorHandler = (error: AnyFlowEventHandlingError) => {
    toastr.error(`500: Error handling event.`);
    console.error(`Error handling event (${error.eventName}, ${error.commandId.asLong})`)
  };

  private onElseHandler = () => {
  };
  private onAnyErrorHandler = () => {
  };

  constructor(data: Typed<AnyFlowCommandResponse>) {
    this.data = data;
  }

  handle(): void {
    if (isAnyFlowSuccess(this.data)) {
      const success = asAnyFlowSuccess(this.data);
      this.onSuccessHandler(Typed.value(AnyFlowIdFactory.copyTyped(success.flowId)), AggregateVersion.copy(success.aggregateVersion));
    } else if (isAnyFlowCustomSuccess(this.data)) {
      const success = asAnyFlowCustomSuccess(this.data);
      this.onSuccessHandler(Typed.value(AnyFlowIdFactory.copyTyped(success.flowId)), AggregateVersion.copy(success.aggregateVersion));
    } else {
      this.onAnyErrorHandler();
      if (isAnyFlowFailure(this.data)) {
        this.onFailureHandler(asAnyFlowFailure(this.data).exceptions);
      } else if (isAnyFlowConcurrentModificationError(this.data)) {
        this.onConcurrentModificationErrorHandler(asAnyFlowConcurrentModificationError(this.data));
      } else if (isAnyFlowCommandHandlingError(this.data)) {
        this.onCommandHandlingErrorHandler(asAnyFlowCommandHandlingError(this.data));
      } else if (isAnyFlowEventHandlingError(this.data)) {
        this.onEventHandlingErrorHandler(asAnyFlowEventHandlingError(this.data));
      } else {
        this.onElseHandler();
        console.error("Unknown response type", this.data);
        throw new Error("Unknown response type");
      }
    }
  }

  onSuccess(onSuccessHandler: (flowId: AnyFlowId, aggregateVersion: AggregateVersion) => void) {
    this.onSuccessHandler = onSuccessHandler;
    return this;
  }

  onFailure(onFailureHandler: (exceptions: Array<string>) => void) {
    this.onFailureHandler = onFailureHandler;
    return this;
  }

  onConcurrentModificationError(onConcurrentModificationErrorHandler: (error: AnyFlowAggregateConcurrentModificationError) => void) {
    this.onConcurrentModificationErrorHandler = onConcurrentModificationErrorHandler;
    return this;
  }

  onCommandHandlingError(onCommandHandlingErrorHandler: (error: AnyFlowCommandHandlingError) => void) {
    this.onCommandHandlingErrorHandler = onCommandHandlingErrorHandler;
    return this;
  }

  onEventHandlingError(onEventHandlingErrorHandler: (error: AnyFlowEventHandlingError) => void) {
    this.onEventHandlingErrorHandler = onEventHandlingErrorHandler;
    return this;
  }

  onElse(onElseHandler: () => void) {
    this.onElseHandler = onElseHandler;
    return this;
  }

  onAnyError(errorHandler: () => void) {
    this.onAnyErrorHandler = errorHandler;
    return this;
  }
}

export function concurrentModificationMessage(error: AnyFlowAggregateConcurrentModificationError) {
  return i18n("errors_concurrent_default");
}


export class CustomAnyFlowCommandResponseHandler<I> {
  private data: Typed<AnyFlowCommandResponse>;

  private onSuccessHandler = (flowId: AnyFlowId, aggregateVersion: AggregateVersion, info: I) => {
  };

  private onFailureHandler = displayExceptions;
  private onAnyErrorHandler = () => {
  };

  private onConcurrentModificationErrorHandler = (error: AnyFlowAggregateConcurrentModificationError) => {
    toastr.error(concurrentModificationMessage(error));
    console.error(`Error because trying to modify incorrect entity (${Typed.value(error.flowId).urlSerialized()}), expected version (${error.expected.asInt}) but now it was version (${error.was.asInt}).`)
  };
  private onCommandHandlingErrorHandler = (error: AnyFlowCommandHandlingError) => {
    toastr.error(`500: Error handling command.`);
    console.error(`Error handling command (${error.commandName}, ${error.commandId.asLong})`)
  };
  private onEventHandlingErrorHandler = (error: AnyFlowEventHandlingError) => {
    toastr.error(`500: Error handling event.`);
    console.error(`Error handling event (${error.eventName}, ${error.commandId.asLong})`)
  };

  constructor(data: Typed<AnyFlowCommandResponse>) {
    this.data = data;
  }

  handle(): void {
    if (isAnyFlowCustomSuccess(this.data)) {
      const success = asAnyFlowCustomSuccess<I>(this.data);
      this.onSuccessHandler(Typed.value(AnyFlowIdFactory.copyTyped(success.flowId)), AggregateVersion.copy(success.aggregateVersion), success.info);
    } else {
      this.onAnyErrorHandler();

      if (isAnyFlowFailure(this.data)) {
        this.onFailureHandler(asAnyFlowFailure(this.data).exceptions);
      } else if (isAnyFlowConcurrentModificationError(this.data)) {
        this.onConcurrentModificationErrorHandler(asAnyFlowConcurrentModificationError(this.data));
      } else if (isCommandHandlingError(this.data)) {
        this.onCommandHandlingErrorHandler(asAnyFlowCommandHandlingError(this.data));
      } else if (isEventHandlingError(this.data)) {
        this.onEventHandlingErrorHandler(asAnyFlowEventHandlingError(this.data));
      } else {
        console.error("Unknown response type", this.data);
        throw new Error("Unknown response type");
      }
    }
  }

  onSuccess(onSuccessHandler: (flowId: AnyFlowId, aggregateVersion: AggregateVersion, info: I) => void) {
    this.onSuccessHandler = onSuccessHandler;
    return this;
  }


  onFailure(onFailureHandler: (exceptions: Array<string>) => void) {
    this.onFailureHandler = onFailureHandler;
    return this;
  }

  onConcurrentModificationError(onConcurrentModificationErrorHandler: (error: AnyFlowAggregateConcurrentModificationError) => void) {
    this.onConcurrentModificationErrorHandler = onConcurrentModificationErrorHandler;
    return this;
  }

  onCommandHandlingError(onCommandHandlingErrorHandler: (error: AnyFlowCommandHandlingError) => void) {
    this.onCommandHandlingErrorHandler = onCommandHandlingErrorHandler;
    return this;
  }

  onEventHandlingError(onEventHandlingErrorHandler: (error: AnyFlowEventHandlingError) => void) {
    this.onEventHandlingErrorHandler = onEventHandlingErrorHandler;
    return this;
  }

  onAnyError(errorHandler: () => void) {
    this.onAnyErrorHandler = errorHandler;
    return this;
  }
}


export function anyFlowCommandResponseHandler(data: Typed<AnyFlowCommandResponse>): AnyFlowCommandResponseHandler {
  return new AnyFlowCommandResponseHandler(data)
}

export function customAnyFlowCommandResponseHandler<I>(data: Typed<AnyFlowCommandResponse>): CustomAnyFlowCommandResponseHandler<I> {
  return new CustomAnyFlowCommandResponseHandler<I>(data)
}
