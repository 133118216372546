import {BooleanVariable, BusinessVariable} from "@shared-model";
import {Option} from "@utils";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {SwitchComponentDefinition} from "@screen-common";

export class SwitchComponentState extends LabeledScreenComponentState {
  static className = "SwitchComponentState";
  className() {
    return SwitchComponentState.className;
  }

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  readonly textState = new TextPropertiesState(this.properties);

  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties)

  get model() {
    return this.properties.optionalBooleanProperty(SwitchComponentDefinition.MODEL);
  }

  get yesLabel() {
    return this.properties.optionalI18nTextProperty("yesLabel");
  }

  get noLabel() {
    return this.properties.optionalI18nTextProperty("noLabel");
  }

  get threeStates() {
    return this.properties.booleanProperty("threeStates");
  }

  get knobColor() {return this.properties.optionalStringProperty("knobColor")}

  get yesBackgroundColor() {return this.properties.optionalStringProperty("yesBackgroundColor")}
  get noBackgroundColor() {return this.properties.optionalStringProperty("noBackgroundColor")}

  get noTextColor() {return this.properties.optionalStringProperty("noTextColor")}



  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case SwitchComponentDefinition.MODEL:
        if(value.isDefined() && value.get() instanceof BooleanVariable) {
          this.properties.putValue(SwitchComponentDefinition.MODEL, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Boolean but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(SwitchComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: SwitchComponentState) {
    return new SwitchComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}

export class SwitchComponentRefState extends ScreenComponentRefState {
  static className = "SwitchComponentRefState";
  className() {
    return SwitchComponentRefState.className;
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: SwitchComponentRefState) {
    return new SwitchComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}


