<div class="simplePage loginPage" [responsive-mobile-class]="'mobile'">
  <header class="header">
    <img *ngIf="organizationLogoUrl"
         class="organizationLogo"
         [src]="organizationLogoUrl"
         [alt]="'login-layout_organization_logo_alt' | i18n"/>

    <ng-container *ngIf="!organizationLogoUrl">
      <my-draw-in-logo [withName]="true"></my-draw-in-logo>
    </ng-container>
  </header>
  <main>
    <ng-content/>
  </main>

  <footer class="footer">
    <a class="applicationLogoContainer" href="https://neula.co/" target="_blank" *ngIf="organizationLogoUrl">
      <my-draw-in-logo></my-draw-in-logo>
      <span class="applicationLogo">neula</span>
    </a>

    <div class="settings">
      <button
        #viewParamsButton
        (click)="viewParamsMenuVisible = !viewParamsMenuVisible"
        class="theme-button-flat-small">{{'login-layout_view_accessibility' | i18n}}
      </button>

      <div class="theme-action-button-separator"></div>
      <my-language-list></my-language-list>
    </div>
  </footer>
</div>

<my-drop-menu cssClass="theme-drop-menu viewParamsMenu"
              [anchor]="viewParamsButton"
              [(visible)]="viewParamsMenuVisible"
              horizontalPosition="end"
>
  <div *ngIf="viewParamsMenuVisible" class="viewParamsMenuContainer">
    <i class="viewParamsIcon mi-eclipse-alt"></i>
    <span class="viewParamsEntryText  theme-label">{{'login-layout_view_theme' | i18n}}</span>
    <my-theme-selector class="alignEnd"/>

    <i class="viewParamsIcon mi-text"></i>
    <span class="viewParamsEntryText theme-label">{{'login-layout_user_text_size' | i18n}}</span>
    <my-font-size-selector class="alignEnd"/>

  </div>
</my-drop-menu>


