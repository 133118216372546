import {PersonNotification} from "./notifications.model";
import {AggregateId, AggregateVersion, AggregateWrapper, LocalDateTime, Typed} from "@utils";

export abstract class MarkAllNotificationsAsReadForPersonSagaResponse {
  static className = "MarkAllNotificationsAsReadForPersonSagaResponse";
  className(): string {
    return MarkAllNotificationsAsReadForPersonSagaResponse.className;
  }
}

export class AllPersonNotificationMarkedAsReadSuccess implements MarkAllNotificationsAsReadForPersonSagaResponse {
  static className = "AllPersonNotificationMarkedAsReadSuccess";

  constructor(readonly markedAsRead: number) {}

  static copy(other: AllPersonNotificationMarkedAsReadSuccess) {
    return new AllPersonNotificationMarkedAsReadSuccess(other.markedAsRead);
  }

  className() {
    return AllPersonNotificationMarkedAsReadSuccess.className
  }
}

export class AllPersonNotificationMarkedAsReadFailure implements MarkAllNotificationsAsReadForPersonSagaResponse {
  static className = "AllPersonNotificationMarkedAsReadFailure";

  constructor(readonly exceptions: Array<string>) {}

  static copy(other: AllPersonNotificationMarkedAsReadFailure) {
    return new AllPersonNotificationMarkedAsReadFailure(other.exceptions);
  }

  className() {
    return AllPersonNotificationMarkedAsReadFailure.className
  }
}

export class MarkAllNotificationsAsReadForPersonSagaResponseFactory {
  static copy(response: MarkAllNotificationsAsReadForPersonSagaResponse): MarkAllNotificationsAsReadForPersonSagaResponse {
    return MarkAllNotificationsAsReadForPersonSagaResponseFactory.copyByType(response, response.className());
  }

  static copyTyped(response: Typed<MarkAllNotificationsAsReadForPersonSagaResponse>): Typed<MarkAllNotificationsAsReadForPersonSagaResponse> {
    return Typed.of(MarkAllNotificationsAsReadForPersonSagaResponseFactory.copyByType(Typed.value(response), Typed.className(response)));
  }

  static copyByType(response: MarkAllNotificationsAsReadForPersonSagaResponse, className: string): MarkAllNotificationsAsReadForPersonSagaResponse {
    switch (className) {
      case AllPersonNotificationMarkedAsReadSuccess.className: {
        return new AllPersonNotificationMarkedAsReadSuccess((<AllPersonNotificationMarkedAsReadSuccess>response).markedAsRead);
      }
      case AllPersonNotificationMarkedAsReadFailure.className: {
        return new AllPersonNotificationMarkedAsReadFailure((<AllPersonNotificationMarkedAsReadFailure>response).exceptions);
      }
      default: {
        throw new Error("Saga notification response type not supported: " + className);
      }
    }
  }
}

export class NotificationSearchResult {
  constructor(readonly notifications: Array<AggregateWrapper<PersonNotification>>,
              readonly moreAvailable: boolean) {
  }
}

export class NotificationNumberAndSearchResult {
  constructor(readonly notifications: Array<AggregateWrapper<PersonNotification>>,
              readonly moreAvailable: boolean,
              readonly received: number,
              readonly unread: number) {
  }
}

export class MarkPersonNotificationRead {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion) {}
}

export class FindNotificationsOlderThan {
  constructor(readonly olderThan: LocalDateTime) {}
}

export class FindUnreadNotificationsOlderThan{
  constructor(readonly olderThan: LocalDateTime) {}
}

export class MarkAllPersonNotificationsAsRead {
  constructor() {}
}
