import {I18nText, Typed} from "@utils";
import {ArrayVariableType, BusinessVariableType, ObjectVariableType} from "@shared-model";

export class NamedAttributeViewModel {
  constructor(readonly codeName: string,
              readonly name: I18nText,
              readonly attributeType: AttributeTypeViewModel) {}

  getTypeName() {
    return this.attributeType.getTypeName();
  }

  getVariableType() {
    return this.attributeType.getType();
  }

  getSimpleTypeName() {
    return this.attributeType.getSimpleTypeName();
  }

  isSingle() {
    return this.attributeType.isSingle();
  }

  isArray() {
    return this.attributeType.isArray();
  }

  isObject() {
    return this.attributeType.isObject();
  }

  isAnyArrayOfObject() {
    return (this.attributeType).isAnyArrayOfObject();
  }

  static of(name: string, i18nText: I18nText|undefined, businessVariableType: BusinessVariableType) {
    return new NamedAttributeViewModel(name, i18nText ? i18nText : I18nText.empty(), AttributeTypeViewModelFactory.ofBusinessVariableType(businessVariableType));
  }

  isOrganizationNode() {
    return this.attributeType.getTypeName() === "OrganizationNode";
  }
}

export interface AttributeTypeViewModel {
  getSimpleTypeName(): string;
  getTypeName(): string;

  getType(): BusinessVariableType;

  isSingle(): boolean;
  isArray(): boolean;
  isObject(): boolean;

  isAnyArrayOfObject(): boolean;
}

export class SingleAttributeTypeViewModel implements AttributeTypeViewModel {

  constructor(readonly dataType: BusinessVariableType) {}

  getType(): BusinessVariableType {
    return this.dataType;
  }

  getTypeName(): string {
    return this.dataType.typeName();
  }

  getSimpleTypeName(): string {
    return this.dataType.typeName();
  }

  isSingle(): boolean {
    return true;
  }

  isArray(): boolean {
    return false;
  }

  isObject(): boolean {
    return false;
  }

  isAnyArrayOfObject(): boolean {
    return false;
  }

}


export class ArrayAttributeTypeViewModel implements AttributeTypeViewModel {

  constructor(readonly subType: AttributeTypeViewModel, readonly dataType: BusinessVariableType) {}

  getType(): BusinessVariableType {
    return this.dataType;
  }

  getSimpleTypeName(): string {
    return "Array";
  }

  getTypeName(): string {
    return "Array["+this.subType.getTypeName()+"]";
  }

  isSingle(): boolean {
    return false;
  }

  isArray(): boolean {
    return true;
  }

  isObject(): boolean {
    return false;
  }

  isAnyArrayOfObject(): boolean {
    if(this.subType.isObject()) {
      return true;
    } else if(this.subType.isArray()) {
      return this.subType.isAnyArrayOfObject();
    } else {
      return false;
    }
  }

  getLastObject(): ObjectAttributeTypeViewModel {
    if(this.subType.isArray()) {
      return (<ArrayAttributeTypeViewModel>this.subType).getLastObject();
    } else if(this.subType.isObject()) {
      return <ObjectAttributeTypeViewModel>this.subType;
    } else {
      throw new Error("Should be array or object");
    }
  }
}


export class ObjectAttributeTypeViewModel implements AttributeTypeViewModel {

  constructor(public subAttributes: Array<NamedAttributeViewModel>, readonly dataType: BusinessVariableType) {}

  getType(): BusinessVariableType {
    return this.dataType;
  }



  getTypeName(): string {
    return "Object";
  }

  getSimpleTypeName(): string {
    return "Object";
  }

  isSingle(): boolean {
    return false;
  }

  isArray(): boolean {
    return false;
  }

  isObject(): boolean {
    return true;
  }

  isAnyArrayOfObject(): boolean {
    return false;
  }

}


export class AttributeTypeViewModelFactory {

  static ofBusinessVariableType(businessVariableType: BusinessVariableType): AttributeTypeViewModel {
    if (businessVariableType instanceof ArrayVariableType) {
      const arr = <ArrayVariableType<BusinessVariableType>>businessVariableType;
      return new ArrayAttributeTypeViewModel(AttributeTypeViewModelFactory.ofBusinessVariableType(Typed.value(arr.subType)), arr);
    } else if (businessVariableType instanceof ObjectVariableType) {
      const obj = <ObjectVariableType>businessVariableType;
      return new ObjectAttributeTypeViewModel(obj.fields.map(subtype => new NamedAttributeViewModel(subtype[0], I18nText.ofCurrent(subtype[0]), AttributeTypeViewModelFactory.ofBusinessVariableType(subtype[1].dataTypeUnwrapped()))), obj);
    } else {
      return new SingleAttributeTypeViewModel(businessVariableType);
    }

  }
}
