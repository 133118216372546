import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environments';
import {AggregateId, global, Language, LocalDate, LocalDateTime, Option, restUrl} from "@utils";

declare const organizationId: Option<AggregateId>;
declare const organizationName: Option<string>;
declare const organizationLogoUrl: Option<string>;
declare const organizationLanguage: Option<Language>;
declare const accentColor: Option<string>;
declare const mainApplicationUrl: string;
declare const singleOrganization: boolean;
declare const internalAuthentication: boolean;
declare const loginWithUsername: boolean;
declare const applicationVersion: string;
declare const buildTimestamp: string;
declare const licenseOwner: string;
declare const serverTime: LocalDateTime;
declare const googleMapsApiKey: string;
declare const clientTime: number;
declare const maxFileSize: number;
declare const iconColor: string;
declare const instanceName: string;
declare const officeOnlineEnabled: boolean;
declare const theme: string;
declare const customProcessCard: boolean;
declare const filesSharingEnabled: boolean;
declare const signaturesVerificationEnabled: boolean;
declare const diagramsNetEnabled: boolean;
declare const diagramsNetUrl: string;
declare const webdavPort: number;
declare const webdavProtocol: string;
declare const accessibilityEnabled: boolean;
declare const kerberosSsoEnabled: boolean;
declare const licenseExpirationInfo: {expired?: boolean, expiresOn?: LocalDate};
declare const usersLimitInfo: {exceededBy?: number};
declare const entraIdSSO: Array<{name: string, identifier: string}>;
declare const chatbotEnabled: boolean;
declare const chatbotUrl: string;
declare const chatbotPagePrefix: string;
declare const chatbotRewriteUrlTo: string;

if (environment.production) {
  enableProdMode();
}

function loadServerConfig(onLoaded: () => void, onError: () => void) {
  console.log("loadServerConfig");
  const script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("src", restUrl("server-config/config.js"));
  script.setAttribute("async", "false");
  script.onload = onLoaded;
  script.onerror = onError;
  try {
    document.head.insertBefore(script, document.head.firstChild);
  } catch (e) {
    console.log("???");
    onError();
  }
}

function handleNoConfigAvailable() {
  try {
    const config = global.config;
    const cachedText = localStorage.getItem("config");
    if (cachedText) {
      const cached = JSON.parse(cachedText);
      config.organizationId = Option.copy(cached.organizationId);
      config.organizationName = Option.copy(cached.organizationName);
      config.organizationLogoUrl = Option.copy(cached.organizationLogoUrl);
      config.organizationLanguage = Option.copy(cached.organizationLanguage);
      config.accentColor = Option.copy(cached.accentColor);
      config.mainApplicationUrl = cached.mainApplicationUrl;
      config.internalAuthentication = cached.internalAuthentication;
      config.loginWithUsername = cached.loginWithUsername;
      config.singleOrganization = cached.singleOrganization;
      config.applicationVersion = cached.applicationVersion;
      config.buildTimestamp = cached.buildTimestamp;
      config.licenseOwner = cached.licenseOwner;
      config.serverTime = LocalDateTime.copy(cached.serverTime);
      config.googleMapsApiKey = cached.googleMapsApiKey;
      config.serverClientTimeMillsDiff = config.serverTime.asMillis() - clientTime;
      config.maxFileSize = cached.maxFileSize;
      config.iconColor = cached.iconColor;
      config.instanceName = cached.instanceName;
      config.officeOnlineEnabled = cached.officeOnlineEnabled;
      config.theme = cached.theme;
      config.customProcessCard = cached.customProcessCard;
      config.filesSharingEnabled = cached.filesSharingEnabled;
      config.signaturesVerificationEnabled = cached.signaturesVerificationEnabled;
      config.diagramsNetEnabled = cached.diagramsNetEnabled;
      config.diagramsNetUrl = cached.diagramsNetUrl;
      config.webdavPort = cached.webdavPort;
      config.webdavProtocol = cached.webdavProtocol;
      config.accessibilityEnabled = cached.accessibilityEnabled;
      config.kerberosSsoEnabled = cached.kerberosSsoEnabled;
      config.licenseExpirationInfo = {expired: cached.licenseExpirationInfo.expired, expiresOn: cached.licenseExpirationInfo.expiresOn ? LocalDate.copy(cached.licenseExpirationInfo.expiresOn) : undefined};
      config.usersLimitInfo = cached.usersLimitInfo;
      config.entraIdSSO = cached.entraIdSSO;
      config.chatbotEnabled = cached.chatbotEnabled;
      config.chatbotUrl = cached.chatbotUrl;
      config.chatbotPagePrefix = cached.chatbotPagePrefix;
      config.chatbotRewriteUrlTo = cached.chatbotRewriteUrlTo;
      console.log("Cached config");

      global.offline = true;
    } else {
      console.log("No cached config");
      alert("Cannot connect to Neula server! Please verify your internet connection...")
    }
  } catch (e) {
    console.error(e);
    alert("Cannot connect to Neula server! Please verify your internet connection..")
  }

}


function initServerConfig() {
  console.log("initServerConfig")
  const config = global.config;
  try {
    config.organizationId = Option.copy(organizationId);
    config.organizationName = Option.copy(organizationName);
    config.organizationLogoUrl = Option.copy(organizationLogoUrl);
    config.organizationLanguage = Option.copy(organizationLanguage);
    config.accentColor = Option.copy(accentColor);
    config.mainApplicationUrl = mainApplicationUrl;
    config.internalAuthentication = internalAuthentication;
    config.loginWithUsername = loginWithUsername;
    config.singleOrganization = singleOrganization;
    config.applicationVersion = applicationVersion;
    config.buildTimestamp = buildTimestamp;
    config.licenseOwner = licenseOwner;
    config.serverTime = LocalDateTime.copy(serverTime);
    config.googleMapsApiKey = googleMapsApiKey;
    config.serverClientTimeMillsDiff = config.serverTime.asMillis() - clientTime;
    config.maxFileSize = maxFileSize;
    config.iconColor = iconColor;
    config.instanceName = instanceName;
    config.officeOnlineEnabled = officeOnlineEnabled;
    config.theme = theme;
    config.customProcessCard = customProcessCard;
    config.filesSharingEnabled = filesSharingEnabled;
    config.signaturesVerificationEnabled = signaturesVerificationEnabled;
    config.diagramsNetEnabled = diagramsNetEnabled;
    config.diagramsNetUrl = diagramsNetUrl;
    config.webdavPort = webdavPort;
    config.webdavProtocol = webdavProtocol;
    config.accessibilityEnabled = accessibilityEnabled;
    config.kerberosSsoEnabled = kerberosSsoEnabled;
    config.licenseExpirationInfo = {expired: licenseExpirationInfo.expired, expiresOn: licenseExpirationInfo.expiresOn ? LocalDate.copy(licenseExpirationInfo.expiresOn) : undefined};
    config.usersLimitInfo = usersLimitInfo;
    config.entraIdSSO = entraIdSSO;
    config.chatbotEnabled = chatbotEnabled;
    config.chatbotUrl = chatbotUrl;
    config.chatbotPagePrefix = chatbotPagePrefix;
    config.chatbotRewriteUrlTo = chatbotRewriteUrlTo;
    localStorage.setItem("config", JSON.stringify(config));
    global.offline = false;
  } catch (e) {
    handleNoConfigAvailable();
  }
}

declare const language: any;

loadServerConfig(() => {

  initServerConfig();
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

  // localStorage.setItem("accentColor", global.config.accentColor.getOrElse("1"));

}, () => {

  handleNoConfigAvailable();


  initServerConfig();
  platformBrowserDynamic().bootstrapModule(AppModule)
  // localStorage.setItem("accentColor", global.config.accentColor.getOrElse("1"));

  //
  // // translations are not loaded yet
  // switch (language) {
  //   case "pl": alert("Nie można połączyć się z serwerem Neula! Sprawdź swoje połączenie internetowe."); break;
  //   case "ar": alert("لا يمكن الاتصال بخادم Neula! يرجى التحقق من اتصالك بالإنترنت."); break;
  //   default: alert("Cannot connect to Neula server! Please verify your internet connection.");
  // }
});


window.addEventListener("keydown", (event) => {
  if(event.key === "Tab") {
    document.body.classList.add("keyboardNavigationEnabled");
  }
});

window.addEventListener("pointerdown", () => {
  document.body.classList.remove("keyboardNavigationEnabled");
});
