import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  BoxPropertiesState,
  ButtonComponentRefState,
  ButtonComponentState,
  ButtonComponentViewModel,
  CalendarComponentRefState,
  CalendarComponentState,
  CalendarComponentViewModel,
  ComponentErrorInfo,
  ComponentLabelViewModel,
  DateInputComponentRefState,
  DateInputComponentState,
  DateInputComponentViewModel,
  DateTimeInputComponentRefState,
  DateTimeInputComponentState,
  DateTimeInputComponentViewModel,
  DropListComponentRefState,
  DropListComponentState,
  DropListComponentViewModel,
  DurationInputComponentRefState,
  DurationInputComponentState,
  DurationInputComponentViewModel,
  HeaderPropertiesState,
  HtmlComponentRefState,
  HtmlComponentState,
  HtmlComponentViewModel,
  ImageComponentRefState,
  ImageComponentState,
  ImageComponentViewModel,
  LabelComponentRefState,
  LabelComponentState,
  LabelComponentViewModel,
  LabeledScreenComponentState,
  LabelPropertiesState,
  LinkComponentRefState,
  LinkComponentState,
  LinkComponentViewModel,
  MapComponentRefState,
  MapComponentState,
  MapComponentViewModel,
  ModalContainerRefState,
  ModalContainerState,
  ModalContainerViewModel,
  MultiAttachmentInputComponentRefState,
  MultiAttachmentInputComponentState,
  MultiAttachmentInputComponentViewModel,
  MultiCheckboxComponentRefState,
  MultiCheckboxComponentState,
  MultiCheckboxComponentViewModel,
  NumberInputComponentRefState,
  NumberInputComponentState,
  NumberInputComponentViewModel,
  PaddingsPropertiesState,
  PasswordInputComponentRefState,
  PasswordInputComponentState,
  PasswordInputComponentViewModel,
  PersonSelectComponentState,
  PersonSelectComponentViewModel,
  RadioButtonComponentRefState,
  RadioButtonComponentState,
  RadioButtonComponentViewModel,
  RefIdInContext,
  RefPositionPropertiesState,
  RepeatableContainerRefState,
  RepeatableContainerState,
  RepeatableContainerViewModel,
  ScreenComponentRefState,
  ScreenComponentsState,
  ScreenComponentState,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel,
  SectionContainerRefState,
  SectionContainerState,
  SectionContainerViewModel,
  SingleAttachmentInputComponentRefState,
  SingleAttachmentInputComponentState,
  SingleAttachmentInputComponentViewModel,
  SingleCheckboxComponentRefState,
  SingleCheckboxComponentState,
  SingleCheckboxComponentViewModel,
  SwitchComponentRefState,
  SwitchComponentState,
  SwitchComponentViewModel,
  TableContainerRefState,
  TableContainerState,
  TableContainerViewModel,
  TabsContainerRefState,
  TabsContainerState,
  TabsContainerViewModel,
  TextInputComponentRefState,
  TextInputComponentState,
  TextInputComponentViewModel,
  TextPropertiesState,
  TimeInputComponentRefState,
  TimeInputComponentState,
  TimeInputComponentViewModel, ViewSwitcherContainerRefState,
  ViewSwitcherContainerState,
  ViewSwitcherContainerViewModel,
  WidgetComponentState,
  WidgetComponentViewModel
} from "..";
import {__, getREMSize, I18nText, None, Option, Some, VariableId} from "@utils";
import {
  I18nService,
  NavigationService,
  ScreenComponentRefId,
  UserSettingsStateService,
  VariableTextPreviewService
} from "@shared";
import {ScreenExternalEventBus} from "@shared-model";
import {
  BackgroundsProperties,
  BordersProperties,
  ButtonComponentDefinition,
  ButtonComponentRef,
  CalendarComponentDefinition,
  CalendarComponentRef,
  CssBuilder,
  CssUtils,
  DateInputComponentDefinition,
  DateInputComponentRef,
  DateTimeInputComponentDefinition,
  DateTimeInputComponentRef,
  DefaultPropertyProvider,
  DropListComponentDefinition,
  DropListComponentRef,
  DurationInputComponentDefinition,
  DurationInputComponentRef,
  HeaderProperties,
  HtmlComponentDefinition,
  HtmlComponentRef,
  ImageComponentDefinition,
  ImageComponentRef,
  LabelComponentDefinition,
  LabelComponentRef, LabelPosition,
  LabelProperties,
  LayoutType,
  LinkComponentDefinition,
  LinkComponentRef,
  MapComponentDefinition,
  MapComponentRef, ModalContainerDefinition, ModalContainerRef,
  MultiAttachmentInputComponentDefinition,
  MultiAttachmentInputComponentRef,
  MultiCheckboxComponentDefinition,
  MultiCheckboxComponentRef,
  NumberInputComponentDefinition,
  NumberInputComponentRef,
  PaddingsProperties,
  PasswordInputComponentDefinition,
  PasswordInputComponentRef,
  PersonSelectComponentDefinition,
  PersonSelectComponentRef,
  PositionProperties,
  RadioButtonComponentDefinition,
  RadioButtonComponentRef,
  RepeatableContainerDefinition,
  RepeatableContainerRef,
  ScreenComponentDefinition,
  ScreenComponentDefinitionWithLabel,
  ScreenComponentIdInScreen,
  ScreenComponentRef,
  ScreenComponentRefIdInScreen,
  ScreenComponents,
  SectionContainerDefinition,
  SectionContainerRef,
  SingleAttachmentInputComponentDefinition,
  SingleAttachmentInputComponentRef,
  SingleCheckboxComponentDefinition,
  SingleCheckboxComponentRef,
  SizeProperties,
  SkinsPropertiesProvider,
  SwitchComponentDefinition,
  SwitchComponentRef,
  TableContainerDefinition,
  TableContainerRef,
  TabsContainerDefinition,
  TabsContainerRef,
  TextAlign,
  TextFont,
  TextInputComponentDefinition,
  TextInputComponentRef,
  TextsProperties,
  TextVerticalAlign,
  TimeInputComponentDefinition,
  TimeInputComponentRef, ViewSwitcherContainerDefinition, ViewSwitcherContainerRef,
  WidgetComponentDefinition,
  WidgetComponentRef,
} from "@screen-common";
import {Observable, Subject} from "rxjs";


export class SizeProperty {
  static sizeToCss(unitSize: number, size: string): string {

    if (size.indexOf("%") > 0) {
      return parseFloat(size) + "%";
    } else if (size.indexOf("px") > 0) {
      return parseFloat(size) / ScreenFormConfig.DEFAULT_REM_SIZE + "rem";
    } else if (size.indexOf("cm") > 0) {
      return (unitSize * parseFloat(size)) + "rem";
    } else {
      throw new Error("Missing unit");
    }

  }

  static sizeToCssPx(unitSize: number, size: string) {
    if (size.indexOf("%") > 0) {
      return parseFloat(size) + "%";
    } else if (size.indexOf("px") > 0) {
      return parseFloat(size) + "px";
    } else if (size.indexOf("cm") > 0) {
      return (unitSize * parseFloat(size)) * getREMSize() + "px";
    } else {
      throw new Error("Missing unit");
    }
  }

  static cmToNumberOnly(size: string, defaultValue: number): number {
    if (size.indexOf("cm") > 0) {
      return parseFloat(size);
    } else {
      return defaultValue;
    }

  }
}

export class GridSizeProperty {
  static sizeToCss(unitSize: number, size: string): string {

    if (size.trim() == "auto") {
      return "auto";
    }
    if (size.indexOf("%") > 0) {
      return parseFloat(size) + "%";
    } else {
      return (unitSize * parseFloat(size)) + "rem";
    }
  }

}

export class ComponentViewModelUtils {

  static toSizeCss(cssBuilder: CssBuilder, sizeProperties: SizeProperties, boxState: BoxPropertiesState): void {

    const unit = ScreenFormConfig.panelUnitRemSize;

    const maxWidth = sizeProperties.maxWidth.currentValue(() => boxState.maxWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const maxHeight = sizeProperties.maxHeight.currentValue(() => boxState.maxHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    CssUtils.maxSizes(cssBuilder, maxWidth, maxHeight);
  }


  static toPaddingsCssAsMargins(cssBuilder: CssBuilder, skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                       paddingsProperties: PaddingsProperties, state: PaddingsPropertiesState): void {
    ComponentViewModelUtils.toPaddingsCss(cssBuilder, skinName, componentTypeName, componentClass, defaultPropertyProvider, paddingsProperties, state, true);
  }

  // asMargins is useful in special cases, eg. in repeatable section entires
  static toPaddingsCss(cssBuilder: CssBuilder, skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                                 paddingsProperties: PaddingsProperties, state: PaddingsPropertiesState, asMargins: boolean = false): void {

    const unit = ScreenFormConfig.panelUnitRemSize;
    const paddingTop = paddingsProperties.paddingTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.paddingTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingLeft = paddingsProperties.paddingLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.paddingLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingBottom = paddingsProperties.paddingBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.paddingBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingRight = paddingsProperties.paddingRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.paddingRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    if(asMargins) {
      CssUtils.margins(cssBuilder, paddingTop, paddingRight, paddingBottom, paddingLeft);
    } else{
      CssUtils.paddings(cssBuilder, paddingTop, paddingRight, paddingBottom, paddingLeft);
    }

  }

  static toBackgroundCss(cssBuilder: CssBuilder, skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                                   backgroundsProperties: BackgroundsProperties, state: BackgroundsPropertiesState): void {

    const backgroundColor = backgroundsProperties.backgroundColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.backgroundColor).valueOrDefault(None());

    CssUtils.backgroundColor(cssBuilder, backgroundColor);

  }

  static toBorderCss(cssBuilder: CssBuilder, skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                               bordersProperties: BordersProperties, state: BordersPropertiesState): void {

    const unit = ScreenFormConfig.panelUnitRemSize;

    const borderTop = bordersProperties.borderTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderLeft = bordersProperties.borderLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderBottom = bordersProperties.borderBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderRight = bordersProperties.borderRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    const borderColorTop = bordersProperties.borderColorTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderColorTop).valueOrDefault(None());
    const borderColorLeft = bordersProperties.borderColorLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderColorLeft).valueOrDefault(None())
    const borderColorBottom = bordersProperties.borderColorBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderColorBottom).valueOrDefault(None())
    const borderColorRight = bordersProperties.borderColorRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderColorRight).valueOrDefault(None())

    const borderRadius = bordersProperties.borderRadius(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.borderRadius).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    CssUtils.borders(cssBuilder, borderTop, borderRight, borderBottom, borderLeft, borderColorTop, borderColorRight, borderColorBottom, borderColorLeft, borderRadius);
  }

  static toOuterShadowCss(cssBuilder: CssBuilder,
                          skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                          bordersProperties: BordersProperties, state: BordersPropertiesState): void {
    const outerShadow = bordersProperties.outerShadow(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => state.outerShadow).valueOrDefault(false);
    const shadowValue = outerShadow ? Some("0 0.25rem 1rem rgba(0,0,80,.08)") : None();
    // return outerShadow ? "box-shadow: 0 0.375rem 1.3125rem rgba(0,0,80,.08);": "";
    CssUtils.shadows(cssBuilder, shadowValue, None());
  }

  // used by link
  static toTextColorCss(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                        textProperties: TextsProperties, textState: TextPropertiesState): string {
    return textProperties.textColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textColor).valueOrDefault(None()).map(v => "color: " + v + ";").getOrElse("");
  }



  static toTextCss(cssBuilder: CssBuilder,
                             skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                             textProperties: TextsProperties, textState: TextPropertiesState): void {

    const textSize = textProperties.textSize(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const textColor = textProperties.textColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textColor).valueOrDefault(None());

    //QUESTION which properties should be inherited?

    const bold = textProperties.bold(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.bold).valueOrDefault(false);
    const italic = textProperties.italic(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.italic).valueOrDefault(false);
    const underline = textProperties.underline(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.underline).valueOrDefault(false);
    const lineThrough = textProperties.lineThrough(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.lineThrough).valueOrDefault(false);
    const textFont = textProperties.textFont(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));
    CssUtils.fontCss(cssBuilder, textFont, textSize, bold, italic, underline, lineThrough, textColor);


    const textAlign = textProperties.textAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textAlign).valueOrDefault(TextAlign.DEFAULT.name);

    CssUtils.horizontalAlign(cssBuilder, textAlign);

    const textVerticalAlign = textProperties.textVerticalAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => textState.textVerticalAlign).valueOrDefault(TextVerticalAlign.DEFAULT.name);

    CssUtils.verticalAlign(cssBuilder, textVerticalAlign);
  }


  static toHeaderCss(cssBuilder: CssBuilder,
                     skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                     headerProperties: HeaderProperties, headerState: HeaderPropertiesState): void {

    const unit = ScreenFormConfig.panelUnitRemSize;


    //QUESTION which properties should be inherited?

    const fontFamily = headerProperties.headerTextFont(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));
    const fontSize = headerProperties.headerTextSize(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const bold = headerProperties.headerTextBold(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextBold).valueOrDefault(false);
    const italic = headerProperties.headerTextItalic(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextItalic).valueOrDefault(false);
    const underlined = headerProperties.headerTextUnderline(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextUnderline).valueOrDefault(false);
    const lineThrough = headerProperties.headerTextLineThrough(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextLineThrough).valueOrDefault(false);
    const fontColor = headerProperties.headerTextColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextColor).valueOrDefault(None());

    CssUtils.fontCss(cssBuilder, fontFamily, fontSize, bold, italic, underlined, lineThrough, fontColor);


    const textAlign = headerProperties.headerTextAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextAlign).valueOrDefault(None()).getOrElse(TextAlign.DEFAULT.name);

    CssUtils.horizontalAlign(cssBuilder, textAlign);

    const textVerticalAlign = headerProperties.headerTextVerticalAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerTextVerticalAlign).valueOrDefault(None());

    CssUtils.verticalAlign(cssBuilder, textVerticalAlign.getOrElse(TextVerticalAlign.center.name));

    const backgroundColorValue = headerProperties.headerBackgroundColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerBackgroundColor).valueOrDefault(None());
    CssUtils.backgroundColor(cssBuilder, backgroundColorValue);

    const minHeightValue = headerProperties.headerMinHeight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.headerMinHeight).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    CssUtils.minSizes(cssBuilder, None(), minHeightValue);

    // Header borders

    const borderTop = headerProperties.borderTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderLeft = headerProperties.borderLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderBottom = headerProperties.borderBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const borderRight = headerProperties.borderRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    const borderColorTop = headerProperties.borderColorTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderColorTop).valueOrDefault(None());
    const borderColorLeft = headerProperties.borderColorLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderColorLeft).valueOrDefault(None());
    const borderColorBottom = headerProperties.borderColorBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderColorBottom).valueOrDefault(None());
    const borderColorRight = headerProperties.borderColorRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderColorRight).valueOrDefault(None());

    const borderRadius = headerProperties.borderRadius(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.borderRadius).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    CssUtils.borders(cssBuilder, borderTop, borderRight, borderBottom, borderLeft, borderColorTop, borderColorRight, borderColorBottom, borderColorLeft, borderRadius);

    // Header paddings

    const paddingTop = headerProperties.paddingTop(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.paddingTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingLeft = headerProperties.paddingLeft(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.paddingLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingBottom = headerProperties.paddingBottom(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.paddingBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const paddingRight = headerProperties.paddingRight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => headerState.paddingRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    CssUtils.paddings(cssBuilder, paddingTop, paddingRight, paddingBottom, paddingLeft);
  }


  static toLabelCss(cssBuilder: CssBuilder, skinName: Option<string>, componentTypeName: string, componentClass: string, defaultPropertyProvider: DefaultPropertyProvider,
                    labelProperties: LabelProperties, labelState: LabelPropertiesState): void {

    const textSize = labelProperties.labelSize(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const textColor = labelProperties.labelColor(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelColor).valueOrDefault(None());

    //QUESTION which properties should be inherited?

    const bold = labelProperties.labelBold(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelBold).valueOrDefault(false);
    const italic = labelProperties.labelItalic(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelItalic).valueOrDefault(false);
    const underline = labelProperties.labelUnderline(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelUnderline).valueOrDefault(false);
    const lineThrough = labelProperties.labelLineThrough(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelLineThrough).valueOrDefault(false);
    const textFont = labelProperties.labelFont(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

    CssUtils.fontCss(cssBuilder, textFont, textSize, bold, italic, underline, lineThrough, textColor);

    const textAlignOption = labelProperties.labelAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelAlign).valueOrDefault(None());
    textAlignOption.forEach(textAlign => {
      CssUtils.horizontalAlign(cssBuilder, textAlign);
    });

    const labelVerticalAlignOption = labelProperties.labelVerticalAlign(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => labelState.labelVerticalAlign).valueOrDefault(None());
    labelVerticalAlignOption.forEach(labelVerticalAlign => {
      CssUtils.verticalAlign(cssBuilder, labelVerticalAlign);
    });
  }


  static toFlexGrowCss(cssBuilder: CssBuilder, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, defSizeProperties: SizeProperties, defBoxState: BoxPropertiesState): void {
    const desiredWidthValue = refPositionProperties.desiredWidth.currentValue(() => refPositionState.desiredWidth).valueOrDefault(None()).map(v => SizeProperty.cmToNumberOnly(v, 1));
    const contentMinWidthValue = defSizeProperties.contentMinWidth.currentValue(() => defBoxState.contentMinWidth).valueOrDefault(None()).map(v => SizeProperty.cmToNumberOnly(v, 1));
    CssUtils.flexGrow(cssBuilder, desiredWidthValue, contentMinWidthValue);
  }


  static toFlexBasisCss(cssBuilder: CssBuilder, parentLayout: LayoutType, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, defSizeProperties: SizeProperties, defBoxState: BoxPropertiesState, unit: number): void {
    const desiredWidthValue = refPositionProperties.desiredWidth.currentValue(() => refPositionState.desiredWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const contentMinWidthValue = defSizeProperties.contentMinWidth.currentValue(() => defBoxState.contentMinWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const desiredHeightValue = refPositionProperties.desiredHeight.currentValue(() => refPositionState.desiredHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const contentMinHeightValue = defSizeProperties.contentMinHeight.currentValue(() => defBoxState.contentMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    CssUtils.flexBasis(cssBuilder, parentLayout, desiredWidthValue, contentMinWidthValue, desiredHeightValue, contentMinHeightValue);
  }


  static toMinSizesCss(cssBuilder: CssBuilder, parentLayout: LayoutType, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, defSizeProperties: SizeProperties, defBoxState: BoxPropertiesState, unit: number): void {
    const desiredWidthValue = refPositionProperties.desiredWidth.currentValue(() => refPositionState.desiredWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const desiredHeightValue = refPositionProperties.desiredHeight.currentValue(() => refPositionState.desiredHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const contentMinWidthValue = defSizeProperties.contentMinWidth.currentValue(() => defBoxState.contentMinWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const contentMinHeightValue = defSizeProperties.contentMinHeight.currentValue(() => defBoxState.contentMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    CssUtils.internalMinSizes(cssBuilder, parentLayout, desiredWidthValue, contentMinWidthValue, desiredHeightValue, contentMinHeightValue);
  }


  static toMaxSizesCss(cssBuilder: CssBuilder, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, defSizeProperties: SizeProperties, defBoxState: BoxPropertiesState, unit: number): void {
    const maxWidthValue = defSizeProperties.maxWidth.currentValue(() => defBoxState.maxWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const maxHeightValue = defSizeProperties.maxHeight.currentValue(() => defBoxState.maxHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    CssUtils.maxSizes(cssBuilder, maxWidthValue, maxHeightValue);
  }


  static toMarginsCss(cssBuilder: CssBuilder, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, unit: number): void {
    const marginTopValue = refPositionProperties.marginTop.currentValue(() => refPositionState.marginTop).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const marginRightValue = refPositionProperties.marginRight.currentValue(() => refPositionState.marginRight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const marginBottomValue = refPositionProperties.marginBottom.currentValue(() => refPositionState.marginBottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const marginLeftValue = refPositionProperties.marginLeft.currentValue(() => refPositionState.marginLeft).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    CssUtils.margins(cssBuilder, marginTopValue, marginRightValue, marginBottomValue, marginLeftValue);
  }


  static toStaticPosition(cssBuilder: CssBuilder, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, deltaTopRem: number, unit: number): void {
    const topValue = refPositionProperties.top.currentValue(() => refPositionState.top).valueOrDefault(None()).map(v => "calc(" + SizeProperty.sizeToCss(unit, v) + " + " + deltaTopRem + "rem)");
    const rightValue = refPositionProperties.right.currentValue(() => refPositionState.right).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v))
    const bottomValue = refPositionProperties.bottom.currentValue(() => refPositionState.bottom).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const leftValue = refPositionProperties.left.currentValue(() => refPositionState.left).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    return CssUtils.staticPosition(cssBuilder, topValue, rightValue, bottomValue, leftValue);
  }


  static toGridPosition(cssBuilder: CssBuilder, refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState): void {
    const gridRowStart = refPositionProperties.row.currentValue(() => refPositionState.row).valueOrDefault(None());
    const gridRowEnd = refPositionProperties.rowSpan.currentValue(() => refPositionState.rowSpan).valueOrDefault(1);
    const gridColumnStart = refPositionProperties.column.currentValue(() => refPositionState.column).valueOrDefault(None());
    const gridColumnEnd = refPositionProperties.columnSpan.currentValue(() => refPositionState.columnSpan).valueOrDefault(1);
    return CssUtils.gridPosition(cssBuilder, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd);
  }


  static toDesiredHeightRem(refPositionProperties: PositionProperties, refPositionState: RefPositionPropertiesState, defSizeProperties: SizeProperties, defBoxState: BoxPropertiesState, unit: number): number {
    const desiredHeightValue = refPositionProperties.desiredHeight.currentValue(() => refPositionState.desiredHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const contentMinHeightValue = defSizeProperties.contentMinHeight.currentValue(() => defBoxState.contentMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const defaultValue = SizeProperty.sizeToCss(unit, "1cm");
    return CssUtils.desiredHeightValue(desiredHeightValue, contentMinHeightValue, defaultValue);
  }

}


export class ComponentStatus {
  static preDefaultValue = 1
  static initialized = 2
}

export class ComponentValidationErrorViewModel {
  constructor(readonly errors: Array<string>,
              readonly componentId: number,
              readonly componentName: string) {
  }
}

export class ComponentInternalErrorViewModel {
  constructor(readonly errors: Array<ComponentErrorInfo>,
              readonly componentId: number,
              readonly definitionId: number,
              readonly refId: number,
              readonly componentName: string) {
  }
}

export class ComponentViewModelFactory {

  static root(shared: ScreenSharedViewModel, externalEventBus: ScreenExternalEventBus,
              parent: ScreenWrapperViewModel,
              i18nService: I18nService, userSettingsService: UserSettingsStateService,
              context: VariableId, componentRefId: ScreenComponentRefIdInScreen,
              screensComponents: { [screenId: string]: ScreenComponents },
              componentsState: ScreenComponentsState, serverModel: ScreenInstanceServerModel,
              navigationService: NavigationService, variableTextPreviewService: VariableTextPreviewService): ScreenComponentViewModel {
    return ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, context, componentRefId, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService);
  }

  static of(shared: ScreenSharedViewModel, externalEventBus: ScreenExternalEventBus,
            i18nService: I18nService, userSettingsService: UserSettingsStateService,
            parent: ScreenContainerViewModel | ScreenWrapperViewModel, context: VariableId,
            componentRefId: ScreenComponentRefIdInScreen, screensComponents: { [screenId: string]: ScreenComponents },
            componentsState: ScreenComponentsState, serverModel: ScreenInstanceServerModel,
            navigationService: NavigationService,
            variableTextPreviewService: VariableTextPreviewService): ScreenComponentViewModel {

    const componentRef: ScreenComponentRef = screensComponents[componentRefId.screenId].getRef(componentRefId.toComponentRefId());

    const componentScreenId = componentRef.applicationScreen.map(s => s.id).getOrElse(componentRefId.screenId);
    const componentId = new ScreenComponentIdInScreen(componentScreenId, componentRef.componentId.id);
    const definition: ScreenComponentDefinition = screensComponents[componentScreenId].getDefinition(componentRef.componentId);

    const componentState = componentsState.getComponentState(context, componentId);
    const refState = componentsState.getComponentRefState(context, componentRefId);

    switch (componentRef.className()) {
      case ButtonComponentRef.className: return new ButtonComponentViewModel(shared, parent, context, <ButtonComponentDefinition>definition, componentScreenId, <ButtonComponentRef>componentRef, componentRefId.screenId, <ButtonComponentState>componentState, <ButtonComponentRefState>refState, serverModel);
      // case BarChartComponentRef.className: return new BarChartComponentViewModel(shared, parent, context, <BarChartComponentDefinition>definition, componentScreenId, <BarChartComponentRef>componentRef, componentRefId.screenId, <BarChartComponentState>componentState, <BarChartComponentRefState>refState, serverModel);
      // case DataTableComponentRef.className: return new DataTableComponentViewModel(shared, parent, context, <DataTableComponentDefinition>definition, componentScreenId, <DataTableComponentRef>componentRef, componentRefId.screenId, <DataTableComponentState>componentState, <DataTableComponentRefState>refState, serverModel);
      case NumberInputComponentRef.className: return new NumberInputComponentViewModel(shared, parent, context, <NumberInputComponentDefinition>definition, componentScreenId, <NumberInputComponentRef>componentRef, componentRefId.screenId, <NumberInputComponentState>componentState, <NumberInputComponentRefState>refState, serverModel);
      case TextInputComponentRef.className: return new TextInputComponentViewModel(shared, parent, context, <TextInputComponentDefinition>definition, componentScreenId, <TextInputComponentRef>componentRef, componentRefId.screenId, <TextInputComponentState>componentState, <TextInputComponentRefState>refState, serverModel);
      case PasswordInputComponentRef.className: return new PasswordInputComponentViewModel(shared, parent, context, <PasswordInputComponentDefinition>definition, componentScreenId, <PasswordInputComponentRef>componentRef, componentRefId.screenId, <PasswordInputComponentState>componentState, <PasswordInputComponentRefState>refState, serverModel);
      case DateInputComponentRef.className: return new DateInputComponentViewModel(shared, parent, context, <DateInputComponentDefinition>definition, componentScreenId, <DateInputComponentRef>componentRef, componentRefId.screenId, <DateInputComponentState>componentState, <DateInputComponentRefState>refState, serverModel);
      case DateTimeInputComponentRef.className: return new DateTimeInputComponentViewModel(shared, parent, context, <DateTimeInputComponentDefinition>definition, componentScreenId, <DateTimeInputComponentRef>componentRef, componentRefId.screenId, <DateTimeInputComponentState>componentState, <DateTimeInputComponentRefState>refState, serverModel, userSettingsService, i18nService);
      case TimeInputComponentRef.className: return new TimeInputComponentViewModel(shared, parent, context, <TimeInputComponentDefinition>definition, componentScreenId, <TimeInputComponentRef>componentRef, componentRefId.screenId, <TimeInputComponentState>componentState, <TimeInputComponentRefState>refState, serverModel, i18nService);
      case DurationInputComponentRef.className: return new DurationInputComponentViewModel(shared, parent, context, <DurationInputComponentDefinition>definition, componentScreenId, <DurationInputComponentRef>componentRef, componentRefId.screenId, <DurationInputComponentState>componentState, <DurationInputComponentRefState>refState, serverModel);
      case DropListComponentRef.className: return new DropListComponentViewModel(shared, parent, context, <DropListComponentDefinition>definition, componentScreenId, <DropListComponentRef>componentRef, componentRefId.screenId, <DropListComponentState>componentState, <DropListComponentRefState>refState, serverModel, variableTextPreviewService);
      case RadioButtonComponentRef.className: return new RadioButtonComponentViewModel(shared, parent, context, <RadioButtonComponentDefinition>definition, componentScreenId, <RadioButtonComponentRef>componentRef, componentRefId.screenId, <RadioButtonComponentState>componentState, <RadioButtonComponentRefState>refState, serverModel);
      case MultiCheckboxComponentRef.className: return new MultiCheckboxComponentViewModel(shared, parent, context, <MultiCheckboxComponentDefinition>definition, componentScreenId, <MultiCheckboxComponentRef>componentRef, componentRefId.screenId, <MultiCheckboxComponentState>componentState, <MultiCheckboxComponentRefState>refState, serverModel);
      case SingleCheckboxComponentRef.className: return new SingleCheckboxComponentViewModel(shared, parent, context, <SingleCheckboxComponentDefinition>definition, componentScreenId, <SingleCheckboxComponentRef>componentRef, componentRefId.screenId, <SingleCheckboxComponentState>componentState, <SingleCheckboxComponentRefState>refState, serverModel);
      case LabelComponentRef.className: return new LabelComponentViewModel(shared, parent, context, <LabelComponentDefinition>definition, componentScreenId, <LabelComponentRef>componentRef, componentRefId.screenId, <LabelComponentState>componentState, <LabelComponentRefState>refState, serverModel);
      case LinkComponentRef.className: return new LinkComponentViewModel(shared, parent, context, <LinkComponentDefinition>definition, componentScreenId, <LinkComponentRef>componentRef, componentRefId.screenId, <LinkComponentState>componentState, <LinkComponentRefState>refState, serverModel);
      case ImageComponentRef.className: return new ImageComponentViewModel(shared, parent, context, <ImageComponentDefinition>definition, componentScreenId, <ImageComponentRef>componentRef, componentRefId.screenId, <ImageComponentState>componentState, <ImageComponentRefState>refState, serverModel);
      case HtmlComponentRef.className: return new HtmlComponentViewModel(shared, parent, context, <HtmlComponentDefinition>definition, componentScreenId, <HtmlComponentRef>componentRef, componentRefId.screenId, <HtmlComponentState>componentState, <HtmlComponentRefState>refState, serverModel);
      case MapComponentRef.className: return new MapComponentViewModel(shared, parent, context, <MapComponentDefinition>definition, componentScreenId, <MapComponentRef>componentRef, componentRefId.screenId, <MapComponentState>componentState, <MapComponentRefState>refState, serverModel);
      case CalendarComponentRef.className: return CalendarComponentViewModel.create(shared, parent, context, <CalendarComponentDefinition>definition, componentScreenId, <CalendarComponentRef>componentRef, componentRefId.screenId, <CalendarComponentState>componentState, <CalendarComponentRefState>refState, serverModel, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case SectionContainerRef.className:
        return SectionContainerViewModel.create(shared, externalEventBus, parent, context, <SectionContainerDefinition>definition, componentScreenId, <SectionContainerRef>componentRef, componentRefId.screenId, <SectionContainerState>componentState, <SectionContainerRefState>refState, serverModel, componentsState, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case ModalContainerRef.className:
        return ModalContainerViewModel.create(shared, externalEventBus, parent, context, <ModalContainerDefinition>definition, componentScreenId, <ModalContainerRef>componentRef, componentRefId.screenId, <ModalContainerState>componentState, <ModalContainerRefState>refState, serverModel, componentsState, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case RepeatableContainerRef.className:
        return RepeatableContainerViewModel.create(shared, parent, context, <RepeatableContainerDefinition>definition, componentScreenId, <RepeatableContainerRef>componentRef, componentRefId.screenId, <RepeatableContainerState>componentState, <RepeatableContainerRefState> refState, serverModel, componentsState, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case TabsContainerRef.className:
        return TabsContainerViewModel.create(shared, parent, context, <TabsContainerDefinition>definition, componentScreenId, <TabsContainerRef>componentRef, componentRefId.screenId, <TabsContainerState>componentState, <TabsContainerRefState> refState, serverModel, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case ViewSwitcherContainerRef.className:
        return ViewSwitcherContainerViewModel.create(shared, parent, context, <ViewSwitcherContainerDefinition>definition, componentScreenId, <ViewSwitcherContainerRef>componentRef, componentRefId.screenId, <ViewSwitcherContainerState>componentState, <ViewSwitcherContainerRefState> refState, serverModel, navigationService, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case TableContainerRef.className: return TableContainerViewModel.create(shared, parent, context, <TableContainerDefinition>definition, componentScreenId, <TableContainerRef>componentRef, componentRefId.screenId, <TableContainerState>componentState, <TableContainerRefState> refState, serverModel, screensComponents, componentsState, (parent: ScreenContainerViewModel, cr: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ComponentViewModelFactory.of(shared, externalEventBus, i18nService, userSettingsService, parent, childContext.getOrElse(context), cr, screensComponents, componentsState, serverModel, navigationService, variableTextPreviewService));
      case SwitchComponentRef.className: return new SwitchComponentViewModel(shared, parent, context, <SwitchComponentDefinition>definition, componentScreenId, <SwitchComponentRef>componentRef, componentRefId.screenId, <SwitchComponentState>componentState, <SwitchComponentRefState>refState, serverModel);
      case MultiAttachmentInputComponentRef.className: return new MultiAttachmentInputComponentViewModel(shared, externalEventBus, parent, context, <MultiAttachmentInputComponentDefinition>definition, componentScreenId, <MultiAttachmentInputComponentRef>componentRef, componentRefId.screenId, <MultiAttachmentInputComponentState>componentState, <MultiAttachmentInputComponentRefState>refState, serverModel);
      case SingleAttachmentInputComponentRef.className: return new SingleAttachmentInputComponentViewModel(shared, externalEventBus, parent, context, <SingleAttachmentInputComponentDefinition>definition, componentScreenId, <SingleAttachmentInputComponentRef>componentRef, componentRefId.screenId, <SingleAttachmentInputComponentState>componentState, <SingleAttachmentInputComponentRefState>refState, serverModel);
      case PersonSelectComponentRef.className: return new PersonSelectComponentViewModel(shared, parent, context, <PersonSelectComponentDefinition>definition, componentScreenId, <PersonSelectComponentRef>componentRef, componentRefId.screenId, <PersonSelectComponentState>componentState, <DropListComponentRefState>refState, serverModel);
      case WidgetComponentRef.className: return new WidgetComponentViewModel(shared, parent, context, <WidgetComponentDefinition>definition, componentScreenId, <WidgetComponentRef>componentRef, componentRefId.screenId, <WidgetComponentState>componentState, <DropListComponentRefState>refState, serverModel);
      default:
        throw new Error("Component not yet supported [" + componentRef.className() + "]")
    }

  }
}

export interface ScreenWrapperViewModel {
  isEditable(): boolean;
  isReadOnlyMode(): boolean;
}

export abstract class ScreenComponentViewModel {

  static idGenerator = 0;

  // Optional
  layoutClass: string|undefined;
  label: ComponentLabelViewModel|undefined;

  typeName: string = "";
  uncovered: boolean = false;
  visible: boolean = false;
  refDesktopVisible: boolean = true;
  refTabletVisible: boolean = true;
  refMobileVisible: boolean = true;
  uncoveredAndVisible: boolean = true;
  status: number = 0;
  componentClass: string = "";
  skinName: Option<string> = None();
  customCssClass: string = "";
  customCss: string = "";
  private readonly customCssSubject: Subject<string> = new Subject<string>();
  readonly customCssObservable: Observable<string> = this.customCssSubject.asObservable();
  positionCss: string = "";
  sizeCss: string = "";
  desiredHeightRem: number = 0;

  abstract updateComponent(deep: boolean): void;

  abstract componentState: ScreenComponentState;
  componentScreenId: string = "";
  abstract refState: ScreenComponentRefState;
  abstract ref: ScreenComponentRef;
  refScreenId: string = "";
  validationErrors: Array<string> = [];
  validationError: boolean = false;
  errors: Array<ComponentErrorInfo> = [];
  error: boolean = false;
  errorText: string = "";
  errorClass: string = "";
  inProgress: boolean = false;

  id = ScreenComponentViewModel.idGenerator++;

  deltaTopRem = 0;
  deltaHeightRem = 0; // component sel delta height

  readOnlyMode: boolean = false; //just reflects if whole screen is in read only mode

  selfEditable: boolean = false;
  editable: boolean = false;
  editableStatic: boolean = false; // used to determine if preview mode should be used by default


  disabled: boolean = false; // not editable and not read only

  inheritedOrSelfPreview: Option<boolean> = None();
  selfPreview: Option<boolean> = None();
  preview: boolean = false;

  abstract context: VariableId;

  readonly defaultPropertyProvider: DefaultPropertyProvider;

  tableCellMode: boolean;
  nonTableCellMode: boolean;

  nonVisibleComponent: boolean = false; // component should not be displayed directly (e.g. modal)

  protected constructor(readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                        componentState: ScreenComponentState,
                        refState: ScreenComponentRefState,
                        readonly definition: ScreenComponentDefinition,
                        readonly shared: ScreenSharedViewModel) {

    componentState.onUpdate(() => this.update());
    refState.onUpdate(() => this.update());

    const skinsPropertyProvider = new SkinsPropertiesProvider(() => ({
      skinName: this.skinName,
      componentTypeName: this.typeName,
      componentClass: this.componentClass,
    }), this.shared.skins);

    this.defaultPropertyProvider = DefaultPropertyProvider.ofSkins(skinsPropertyProvider, this.definition.defaultPropertiesProvider);

    this.tableCellMode = this.parent instanceof ScreenContainerViewModel && this.parent.isTable();
    this.nonTableCellMode = !this.tableCellMode
  }


  update() {
    this.uncovered = this.ref.uncovered.currentValue(() => this.refState.uncovered).valueOrDefault(true) && this.definition.uncovered.currentValue(() => this.componentState.uncovered).valueOrDefault(true);
    this.visible = this.ref.visible.currentValue(() => this.refState.visible).valueOrDefault(true) && this.definition.visible.currentValue(() => this.componentState.visible).valueOrDefault(true);

    this.refMobileVisible = this.ref.mobileVisible.currentValue(() => this.refState.mobileVisible).valueOrDefault(true);
    this.refTabletVisible = this.ref.tabletVisible.currentValue(() => this.refState.tabletVisible).valueOrDefault(true);
    this.refDesktopVisible = this.ref.desktopVisible.currentValue(() => this.refState.desktopVisible).valueOrDefault(true);

    this.uncoveredAndVisible = this.uncovered && this.visible && ((this.shared.mobile && this.refMobileVisible) || (this.shared.desktop && this.refDesktopVisible) || (this.shared.tablet && this.refTabletVisible));

    this.status = this.componentState.status.valueOrDefault(-1);
    if(this.status === -1) {
      throw new Error("Status is not set for component [" + this.componentName() + "]");
    }
    this.componentClass = this.definition.sizeProperties.componentClass.currentValue(() => this.componentState.boxState.componentClass).valueOrDefault("$default");


    this.customCssClass = this.definition.sizeProperties.cssClass.currentValue(() => this.componentState.boxState.cssClass).valueOrDefault(None()).getOrElse("");

    const oldCustomCss = this.customCss;
    this.customCss = this.definition.properties.getOptionalStringProperty("customCss", this.defaultPropertyProvider).currentValue(() => this.componentState.properties.optionalStringProperty("customCss")).valueOrDefault(None()).getOrElse("");
    if (oldCustomCss !== this.customCss) {
      this.customCssSubject.next(this.customCss);
    }
    this.errors = this.componentState.properties.errors().concat(this.refState.properties.errors());
    this.error = this.errors.length > 0;
    this.errorText = this.errors.map(e => this.componentName()+" - "+e.name+": "+e.message).join(", ");
    this.validationErrors = this.componentState.properties.allValidationErrors();
    this.validationError = this.validationErrors.length > 0;
    this.errorClass = this.error ? "error" : (this.validationError ? "validationError" : "");
    this.inProgress = this.componentState.properties.anyInProgress() || this.refState.properties.anyInProgress();
    this.updateEditableAndPreview();
    const skinNameChanged = this.updateSkinName();
    this.updateComponent(skinNameChanged);
  }

  private updateSkinName(): boolean {
    const currentSkinName = this.skinName;
    this.skinName = this.definition.sizeProperties.componentSkin.currentValue(() => this.componentState.boxState.componentSkin).valueOrDefault(None());
    if (this.skinName.isEmpty() && this.parent instanceof ScreenContainerViewModel) {
      this.parent.updateSkinName();
      this.skinName = this.parent.skinName;
    }
    return currentSkinName != null && !currentSkinName.equals(this.skinName);
  }

  updateEditableAndPreview() {
    this.updateReadOnlyMode();
    this.updateEditable();
    this.updatePreview();
    this.disabled = !this.editable || this.readOnlyMode;
  }

  private updateReadOnlyMode() {
    if (this.parent instanceof ScreenContainerViewModel) {
      this.readOnlyMode = this.parent.isReadOnlyMode();
    } else {
      this.readOnlyMode = this.parent.isReadOnlyMode();
    }
  }

  private updatePreview() {

    if (this.editable) {
      this.inheritedOrSelfPreview = None();
      this.selfPreview = None();
      this.preview = false;
    } else {
      // By default component is in preview mode if editable is static false, otherwise it's based on a preview setting
      this.selfPreview = this.ref.preview.currentValue(() => this.refState.preview).valueOrDefault(None())

      if (this.selfPreview.isEmpty()) { // use default behavior if 'editable' is static
        if (this.parent instanceof ScreenContainerViewModel) {
          this.parent.updatePreview();
          this.inheritedOrSelfPreview = this.parent.inheritedOrSelfPreview.orElse(this.selfPreview);
          if (this.parent.preview) {
            this.preview = true;
          } else {
            if (this.inheritedOrSelfPreview.isEmpty()) {
              this.preview = this.editableStatic;
            } else {
              this.preview = this.inheritedOrSelfPreview.get();
            }
          }
        } else {
          this.inheritedOrSelfPreview = this.selfPreview;
          this.preview = this.editableStatic; // if dynamic it should be only read only mode, not preview
        }

      } else {
        this.inheritedOrSelfPreview = this.selfPreview;
        this.preview = this.selfPreview.get();
      }

    }


  }

  componentRefPath(): Array<RefIdInContext> {
    const currentId = [new RefIdInContext(ScreenComponentRefIdInScreen.of(this.refScreenId, this.ref.id), this.context)];
    if (this.parent instanceof ScreenContainerViewModel) {
      return this.parent.componentRefPath().concat(currentId);
    } else {
      return currentId;
    }
  }

  private updateEditable() {

    this.editableStatic = this.ref.editable.settingsUnwrapped().toSettingsType().isStaticValue() && this.definition.editable.settingsUnwrapped().toSettingsType().isStaticValue() || this.definition.modelIsReadOnly();
    this.selfEditable = this.ref.editable.currentValue(() => this.refState.editable).valueOrDefault(false) &&
      this.definition.editable.currentValue(() => this.componentState.editable).valueOrDefault(false) &&
      !this.definition.modelIsReadOnly();
    if (this.parent instanceof ScreenContainerViewModel) {
      this.parent.updateEditable();
    }
    if (this.parent instanceof ScreenContainerViewModel) {
      this.editable = this.selfEditable && this.parent.editable;
    } else {
      this.editable = this.selfEditable && this.parent.isEditable();
    }
  }

  protected updatePosition() {

    const unit = ScreenFormConfig.panelUnitRemSize;

    const parentLayout = this.parent instanceof ScreenContainerViewModel ? this.parent.getLayoutForComponent(this.ref.id) : LayoutType.vertical;


    const positionCssBuilder = new CssBuilder();
    const sizeCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toMarginsCss(positionCssBuilder, this.ref.positionProperties, this.refState.positionState, unit);
    ComponentViewModelUtils.toStaticPosition(positionCssBuilder, this.ref.positionProperties, this.refState.positionState, this.deltaTopRem, unit);
    ComponentViewModelUtils.toGridPosition(positionCssBuilder, this.ref.positionProperties, this.refState.positionState);
    ComponentViewModelUtils.toFlexBasisCss(positionCssBuilder, parentLayout, this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState, unit);
    ComponentViewModelUtils.toFlexGrowCss(positionCssBuilder, this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState);
    // Set the maximum size on the outer element to properly handle flex layout settings.
    // However, skip this if the labels are next to the control because it messes with
    // the control's width settings
    const labelVertical = this.label && this.label.visible && (LabelPosition.byName(this.label.positionClass).isTop() || LabelPosition.byName(this.label.positionClass).isBottom())
    if(!this.label || !this.label.visible || labelVertical){
      ComponentViewModelUtils.toMaxSizesCss(positionCssBuilder, this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState, unit);
    }

    this.positionCss = positionCssBuilder.toCss();

    ComponentViewModelUtils.toMaxSizesCss(sizeCssBuilder, this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState, unit);
    ComponentViewModelUtils.toMinSizesCss(sizeCssBuilder, parentLayout, this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState, unit);

    this.sizeCss = sizeCssBuilder.toCss();

    this.desiredHeightRem = ComponentViewModelUtils.toDesiredHeightRem(this.ref.positionProperties, this.refState.positionState, this.definition.sizeProperties, this.componentState.boxState, unit)

  }

  getDefaultStaticTop() {
    return this.ref.positionProperties.top.currentValue(() => this.refState.positionState.top).valueOrDefault(None()).filter(h => h.indexOf("%") == -1).map(parseFloat);
  }

  getDefaultStaticBottom() {
    const top = this.ref.positionProperties.top.currentValue(() => this.refState.positionState.top).valueOrDefault(None()).filter(h => h.indexOf("%") == -1).map(parseFloat);
    const desiredHeight = this.ref.positionProperties.desiredHeight.currentValue(() => this.refState.positionState.desiredHeight).valueOrDefault(None());
    const minContentHeight = this.definition.sizeProperties.contentMinHeight.currentValue(() => this.componentState.boxState.contentMinHeight).valueOrDefault(None());

    const unit = ScreenFormConfig.panelUnitRemSize;

    if (top.isDefined()) {
      return Some(top.get() + parseFloat(desiredHeight.orElse(minContentHeight).getOrElse("1cm")));
    } else {
      console.log("No static bottom");
      return None();
    }

  }


  changeDeltaTopRem(deltaTopRem: number) {
    if (this.deltaTopRem != deltaTopRem) {
      console.log("Delta top changed " + this.id + " " + (Math.round(deltaTopRem * 100) / 100));
      this.deltaTopRem = deltaTopRem;
      this.updatePosition();
    }
  }

  changeDeltaHeightRem(deltaHeightRem: number) {
    const deltaChanged = Math.abs(this.deltaHeightRem - deltaHeightRem) > 0.001;
    if (deltaChanged) {
      this.deltaHeightRem = deltaHeightRem;
      if (this.parent instanceof ScreenContainerViewModel) { // if not root
        this.parent.childDeltaHeightChanged();
      }
      this.updatePosition();
    }
  }

  isContainer() {
    return false;
  }

  componentName(): string {
    return this.typeName;
  }

  getValidationErrorsRecursive(): Array<ComponentValidationErrorViewModel> {
    const errors = this.componentState.properties.allValidationErrors();
    if (errors.length > 0) {
      return [new ComponentValidationErrorViewModel(errors, this.id, this.componentName())];
    } else {
      return [];
    }
  }

  getInternalErrorsRecursive(): Array<ComponentInternalErrorViewModel> {
    const defErrors = this.componentState.properties.errors();
    const refErrors = this.refState.properties.errors();
    if (defErrors.length > 0 || refErrors.length > 0) {
      return [new ComponentInternalErrorViewModel(defErrors.concat(refErrors), this.id, this.definition.id.id, this.ref.id.id, this.componentName())];
    } else {
      return [];
    }
  }

  onMobileUpdated() {
    this.uncoveredAndVisible = this.uncovered && this.visible && ((this.shared.mobile && this.refMobileVisible) || (this.shared.desktop && this.refDesktopVisible) || (this.shared.tablet && this.refTabletVisible));
  }
}

export abstract class ComponentViewModelWithLabel extends ScreenComponentViewModel {

  public override label: ComponentLabelViewModel;


  abstract required: boolean;

  labelSizeCss: string = "";

  constructor(override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              override readonly definition: ScreenComponentDefinitionWithLabel,
              readonly componentState: LabeledScreenComponentState,
              refState: ScreenComponentRefState,
              override readonly shared: ScreenSharedViewModel) {
    super(parent, componentState, refState, definition, shared);
    this.label = new ComponentLabelViewModel(this);
  }

  // used to hide header in table
  forceHideLabel(forceHide: boolean) {
    this.label.forceHideLabel(forceHide);
  }

  override update(): void {
    super.update();


    const tooltipText: I18nText | null = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).getOrNull();

    this.label.update(this.definition.labelProperties, this.componentState.labelState, this.required, this.editable, this.preview, tooltipText != null ? tooltipText.getCurrentWithFallback() : undefined,
      this.validationError);
  }


  override updateEditableAndPreview() {
    super.updateEditableAndPreview();
    this.label.updateStateFlags(this.required, this.editable, this.preview);
  }

  override componentName(): string {
    if (this.label.visible && this.label.label.trim().length > 0) {
      return this.label.label.trim();
    } else {
      return super.componentName();
    }
  }


  protected override updatePosition() {
    super.updatePosition();

    const unit = ScreenFormConfig.panelUnitRemSize;

    const skinName = this.skinName;
    const componentTypeName = this.typeName;
    const componentClass = this.componentClass;
    const defaultPropertyProvider = this.defaultPropertyProvider;

    const labelWidthValue = this.definition.labelProperties.labelWidth(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => this.componentState.labelState.labelWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const labelHeightValue = this.definition.labelProperties.labelHeight(skinName, componentTypeName, componentClass, defaultPropertyProvider).currentValue(() => this.componentState.labelState.labelHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    this.labelSizeCss = "";

    const labelSizeCssBuilder = new CssBuilder();

    CssUtils.minSizes(labelSizeCssBuilder, labelWidthValue, labelHeightValue);
    this.label.sizeCss = labelSizeCssBuilder.toCss();
  }

}

export class SizeUtils {
  static sizeToCss(size: string) {
    if (size.indexOf("%") > 0) {
      return size;
    } else if (size.indexOf("px") > 0) {
      return (parseFloat(size.substring(0, size.indexOf("px"))) / ScreenFormConfig.DEFAULT_REM_SIZE) + "rem";
    } else if (size.indexOf("cm") > 0) {
      return (parseFloat(size.substring(0, size.indexOf("cm"))) * ScreenFormConfig.panelUnitRemSize) + "rem";
    } else {
      throw new Error("No unit defined");
    }
  }
}

export abstract class ScreenContainerViewModel extends ScreenComponentViewModel {
  protected constructor(override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                        componentState: ScreenComponentState, refState: ScreenComponentRefState, definition: ScreenComponentDefinition, shared: ScreenSharedViewModel) {
    super(parent, componentState, refState, definition, shared);
  }

  // Triggered by child that might have changed size
  updateTop() {
    this.updatePosition();
    if (this.parent instanceof ScreenContainerViewModel) {
      this.parent.updateTop();
    }
  }

  updateComponent(deep: boolean) {
    this.updateContainer(deep);
    if (deep) {
      this.getAllChildren().forEach(c => {
        c.update();
      });
    }
  }

  abstract updateContainer(deep: boolean): void;

  abstract childDeltaHeightChanged(): void;


  abstract getComponentById(id: number): Option<ScreenComponentViewModel>;

  override updateEditableAndPreview() {
    super.updateEditableAndPreview();
    this.getAllChildren().forEach(child => child.updateEditableAndPreview());
  }

  override isContainer() {
    return true;
  }

  abstract getAllChildren(): Array<ScreenComponentViewModel>;

  override getValidationErrorsRecursive(): Array<ComponentValidationErrorViewModel> {
    const selfErrors = super.getValidationErrorsRecursive();
    const childrenErrors = __(this.getAllChildren()).flatMap(ch => ch.getValidationErrorsRecursive());
    return childrenErrors.concat(selfErrors); // child errors before selft, although self should not be validated if there are children errors
  }

  override getInternalErrorsRecursive(): Array<ComponentInternalErrorViewModel> {
    const selfErrors = super.getInternalErrorsRecursive();
    const childrenErrors = __(this.getAllChildren()).flatMap(ch => ch.getInternalErrorsRecursive());
    return childrenErrors.concat(selfErrors); // child errors before selft, although self should not be validated if there are children errors
  }

  abstract getLayoutForComponent(componentRefId: ScreenComponentRefId): LayoutType;

  isTable() {
    return false;
  }

  isReadOnlyMode() {
    return this.readOnlyMode;
  }

  override onMobileUpdated() {
    super.onMobileUpdated();
    this.getAllChildren().forEach(child => child.onMobileUpdated());
  }
}

