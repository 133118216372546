import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState,
  TextPropertiesState
} from "../..";
import {Option} from "@utils";

export class LabelComponentState extends ScreenComponentState {
    static className = "LabelComponentState";
    className() {
      return LabelComponentState.className;
    }

    readonly paddingsState = new PaddingsPropertiesState("", this.properties);
    readonly bordersState = new BordersPropertiesState(this.properties);
    readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

    constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
      super(properties);
    }

    readonly textState = new TextPropertiesState(this.properties);

    get text() {
      return this.properties.stringifiedI18nProperty("text");
    }

  get tooltip() {
    return this.properties.optionalI18nTextProperty("tooltip");
  }

  get icon() {
    return this.properties.optionalStringProperty("icon");
  }

  get iconPosition() {
    return this.properties.stringProperty("iconPosition");
  }

    static copy(other: LabelComponentState) {
      return new LabelComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
    }

  }


  export class LabelComponentRefState extends ScreenComponentRefState {
    static className = "LabelComponentRefState";
    className() {
      return LabelComponentRefState.className;
    }

    constructor(readonly properties: PropertiesStateHolder) {
      super(properties);
    }

    static copy(other: LabelComponentRefState) {
      return new LabelComponentRefState(PropertiesStateHolder.copy(other.properties));
    }
  }
