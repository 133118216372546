import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FileUri, None, Option, required, Some} from "@utils";
import {BusinessVariable, BusinessVariableInputServerModel, FileVariableV2, VariablePath} from "@shared-model";

export class FileVariableInputViewModel {

    fileExists: boolean = false;
    filePath: string|null = null;
    internalModel: FileUri|null = null;
    internalModelBackup: FileUri|null = null;

    constructor(readonly serverModel: BusinessVariableInputServerModel,
                readonly path: VariablePath,
                public value: FileVariableV2|null,
                public readOnly: boolean,
                public preview: boolean,
                readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>,
                readonly previewRequested: EventEmitter<BusinessVariable>) {
      this.updatePreviewText();
      this.initInternalModel();
    }

    private updatePreviewText() {

      if(this.value === null) {
        this.fileExists = false;
        this.filePath = "";
      } else {
        this.fileExists = true;
        this.filePath = "";
        this.serverModel.loadFilesInfo(this.value.value, (fileInfo => {
          this.fileExists = true;
          this.filePath = fileInfo.repositoryPath.getOrElse(fileInfo.name);
        }));
      }

    }

    initInternalModel() {
      if (this.value == null) {
        this.internalModel = null;
      } else {
        this.internalModel = this.value.value;
      }
      this.internalModelBackup = this.internalModel;
    }

    private changeVariableValue(value: FileVariableV2|null) {
      this.value = value;
      this.updatePreviewText();
      this.initInternalModel();
      this.change.emit({value: value, path: this.path});
    }

    onInternalChange() {
      if(this.internalModelChanged()) {
        if (this.internalModel === null) {
          this.changeVariableValue(null);
        } else {
          this.changeVariableValue(new FileVariableV2(this.internalModel));
        }
        this.internalModelBackup = this.internalModel;
      }
    }

    private internalModelChanged() {
      return this.internalModel === null && this.internalModelBackup !== null ||
        this.internalModel !== null && this.internalModelBackup === null ||
        this.internalModel !== null && this.internalModelBackup !== null && !this.internalModel.isEqual(this.internalModelBackup);
    }

    updateValue(value: BusinessVariable|null) {
      if(value === null || value instanceof FileVariableV2) {
        this.value = value;
        this.initInternalModel();
      } else {
        throw new Error("Incorrect variable type, expected 'File' but was '"+value.simpleValueType()+"'");
      }
    }

    updateMode(readOnly: boolean, preview: boolean) {
      this.readOnly = readOnly;
      this.preview = preview;
    }

    backupInternalValue() {
      this.internalModelBackup = this.internalModel;
    }

    requestPreview() {
      if(this.value != null) {
        this.previewRequested.emit(this.value);
      } else {
        throw new Error("Cannot preview no value");
      }
    }
  }

@Component({
  selector: "my-file-variable-input",
  templateUrl: "./file-variable-input.component.html"
})
export class FileVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();


  @Input() previewRequested = new EventEmitter<BusinessVariable>();
  @Input({required: true}) serverModel?: BusinessVariableInputServerModel;

  viewModel!: FileVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new FileVariableInputViewModel(required(this.serverModel, "serverModel"), this.path, this._value, this._readOnly, this._preview, this.change, this.previewRequested);
  }
}

