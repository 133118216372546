import {FileUri, LocalDateTime, Option, OrganizationNodeId, PersonId} from "@utils";


export class EmailUID {
  constructor(readonly uid: string) {}

  static copy(other: EmailUID) {
    return new EmailUID(other.uid);
  }
}

export class EmailCommentVersion {
  constructor(readonly text: string,
              readonly created: LocalDateTime) {}

  static copy(other: EmailCommentVersion) {
    return new EmailCommentVersion(
      other.text,
      LocalDateTime.copy(other.created))
  }
}

export class EmailComment {
  constructor(readonly commentId: number,
              readonly authorId: OrganizationNodeId,
              readonly deleted: Option<LocalDateTime>,
              readonly versions: Array<EmailCommentVersion>,
              readonly notifyPersons: Array<PersonId>,
              readonly attachments: Array<FileUri>) {}

  static copy(other: EmailComment) {
    return new EmailComment(
      other.commentId,
      OrganizationNodeId.copy(other.authorId),
      Option.copy(other.deleted).map(LocalDateTime.copy),
      other.versions.map(EmailCommentVersion.copy),
      other.notifyPersons.map(PersonId.copy),
      other.attachments.map(FileUri.copy))
  }
}

export class EmailAttachmentSummary {
  constructor(readonly id: number,
              readonly uid: Option<string>,
              readonly fileName: string,
              readonly mimeType: string,
              readonly inline: boolean,
              readonly encodedSize: number,
              readonly size: Option<number>) {}

  static copy(other: EmailAttachmentSummary) {
    return new EmailAttachmentSummary(
      other.id,
      Option.copy(other.uid),
      other.fileName,
      other.mimeType,
      other.inline,
      other.encodedSize,
      Option.copy(other.size));
  }
}

export class EmailContent {
  constructor(readonly textMessage: Option<string>,
              readonly htmlMessage: Option<string>) {}

  static copy(other: EmailContent) {
    return new EmailContent(Option.copy(other.textMessage), Option.copy(other.htmlMessage));
  }
}
