import {Component, Input} from "@angular/core";
import {ModalContainerViewModel} from "./ModalContainerViewModel";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-modal-container',
  templateUrl: './modal-container.component.html',
  host: {
    "[class]": "'ModalContainer componentBody ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.combinedCss",
    "[class.withHeader]": "viewModel.headerVisible",
    "(click)": "viewModel.onClick()",
  }
})
export class ModalContainerComponent {
  @Input({required:true}) viewModel!: ModalContainerViewModel;

  readonly children = new ContainerChildrenHandler();

}
