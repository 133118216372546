import {i18n, I18nText, None, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BooleanProperty,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  HeaderProperties,
  I18nTextProperty,
  LayoutsProperties,
  ModelProperty,
  OptionalContextContainerDefinition,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenContainerRef
} from "@screen-common";
import {
  OptionalI18nTextProperty,
  OptionalModelProperty,
  OptionalStringProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";


export class ModalContainerRef extends ScreenContainerRef {
    static className = "ModalContainerRef";
    className() {
      return ModalContainerRef.className;
    }

    constructor(
      override readonly id: ScreenComponentRefId,
      override readonly componentId: ScreenComponentId,
      override readonly applicationScreen: Option<ScreenId>,
      override readonly properties: ComponentProperties
    ) {
      super();
    }


    static copy(other: ModalContainerRef): ModalContainerRef {
      return new ModalContainerRef(
        ScreenComponentRefId.copy(other.id),
        ScreenComponentId.copy(other.componentId),
        Option.copy(other.applicationScreen, ScreenId.copy),
        ComponentProperties.copy(other.properties)
      )
    }

    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.ofFallbacks("ModalRef", DefaultPropertyProvider.ref);

    override defaultPropertiesProvider = ModalContainerRef.DEFAULT_PROPERTIES;

    override createDefaultDefinition(id: ScreenComponentId): ScreenComponentDefinition {
      return new ModalContainerDefinition(id, None(), ComponentProperties.empty(), new ComponentActionProperties([]), new ComponentValidationRules([]), []);
    }
  }

  export class ModalContainerDefinition extends OptionalContextContainerDefinition {

    static className = "ModalContainerDefinition";

    static POPPED = "popped";

    className() {
      return ModalContainerDefinition.className;
    }
    typeName() {
      return "Modal";
    }


    constructor(
      override readonly id: ScreenComponentId,
      override readonly identifier: Option<string>,
      override readonly properties: ComponentProperties,
      readonly actionProperties: ComponentActionProperties,
      override readonly validationRules: ComponentValidationRules,
      public children: Array<ScreenComponentRefId>) {
      super(id, identifier, properties, validationRules, ModalContainerDefinition.DEFAULT_PROPERTIES);
    }

    get popped(): ModelProperty {return this.properties.getModelProperty("popped", this.defaultPropertiesProvider)}

    get closeVisible(): BooleanProperty {return this.properties.getBooleanProperty("closeVisible", this.defaultPropertiesProvider)}

    get acceptLabel(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("acceptLabel", this.defaultPropertiesProvider)}
    get declineLabel(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("declineLabel", this.defaultPropertiesProvider)}

    get model(): OptionalModelProperty {return this.properties.getOptionalModelProperty("model", this.defaultPropertiesProvider)};
    get header(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("header", this.defaultPropertiesProvider)}

    layoutsProperties = new LayoutsProperties("", this.properties, this.defaultPropertiesProvider);
    headerProperties = new HeaderProperties(this.properties);


    contentTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextSize", defaultProvider)}
    contentTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextColor", defaultProvider)}
    contentTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {return this.properties.getOptionalStringProperty("contentTextFont", defaultProvider)}

    paddingsProperties = new PaddingsProperties("", this.properties);
    bordersProperties = new BordersProperties(this.properties);
    backgroundsProperties = new BackgroundsProperties("", this.properties);


    override getChildren(): Array<ScreenComponentRefId> {
      return this.children;
    }

    static copy(other: ModalContainerDefinition): ModalContainerDefinition {
      return new ModalContainerDefinition(
        ScreenComponentId.copy(other.id),
        Option.copy(other.identifier),
        ComponentProperties.copy(other.properties),
        ComponentActionProperties.copy(other.actionProperties),
        ComponentValidationRules.copy(other.validationRules),
        other.children.map(ScreenComponentRefId.copy)
      )
    }



    static DEFAULT_PROPERTIES: DefaultPropertyProvider = DefaultPropertyProvider.of("ModalDefinition", (name:string) => {
      switch (name) {
        case "acceptLabel": return OptionalI18nTextProperty.of(I18nText.ofCurrent(i18n("screen_default_accept_label")));
        case "declineLabel": return OptionalI18nTextProperty.of(I18nText.ofCurrent(i18n("screen_default_decline_label")));
        case "closeVisible": return BooleanProperty.ofTrue();
        case "model": return OptionalModelProperty.disabled(); // used by root panel
        default: return null;
      }},
      DefaultPropertyProvider.definitionLabel,
      DefaultPropertyProvider.containerPlain,
      DefaultPropertyProvider.containerPaddings,
      DefaultPropertyProvider.definitionBackgrounds,
      DefaultPropertyProvider.definitionSectionHeader,
      DefaultPropertyProvider.definitionComponent,
      DefaultPropertyProvider.definitionBorders);

    override displayName(): string {
      return (this.header.settingsUnwrapped().toSettingsType().isStaticValue() ? this.header.staticValue.getCurrentWithFallback() : "");
    }
  }


