<input type="text" class="theme-input dateInput" #InputElement
       *ngIf="!focused"
       [(ngModel)]="previewTextModel"
       (focus)="onFocus()"
       [placeholder]="placeholder" />

<input type="text" class="theme-input dateInput" #InputElement
       *ngIf="focused"
       [(ngModel)]="inputTextModel"
       [placeholder]="placeholder"
       (keydown.enter)="onInputValueConfirm()"
       mask="d0-M0-0000" [dropSpecialCharacters]="false" />

<my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [visible]="popupEnabled && focused" [anchor]="anchor" (closed)="onPickerClosed()">
    <ng-container *ngIf="popupEnabled && focused">
      <div class="actions">
        <button *ngIf="yesterdayButton" class="actionButton" (click)="setYesterday()">{{'common_days_yesterday' | i18n}}</button>
        <button *ngIf="todayButton" class="actionButton" (click)="setToday()">{{'common_days_today' | i18n}}</button>
        <button *ngIf="clearButton" class="actionButton" (click)="setEmpty()"><i class="mi-delete"></i>{{'common_clear' | i18n}}</button>
      </div>
      <div class="board">
        <my-date-picker-board [(date)]="pickerValue" (picked)="onPickerValueSelected()" />
      </div>
    </ng-container>
</my-drop-menu>
