import {
  ParentComponentId, PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState,
  TextPropertiesState
} from "../../model/screen-component-state.model";
import {ArrayVariable, BusinessVariable, ObjectVariable} from "@shared-model";
import {None, Option} from "@utils";
import {CalendarComponentDefinition} from "@screen-common";

export class CalendarComponentState extends ScreenComponentState {

  static className = "CalendarComponentState";
  className() {
    return CalendarComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get selected() {
    return this.properties.optionalBusinessVariableProperty(CalendarComponentDefinition.SELECTED);
  }

  get entries(): ArrayVariable<ObjectVariable> {
    const opt = <Option<ArrayVariable<ObjectVariable>>>this.properties.optionalObjectsArrayProperty(CalendarComponentDefinition.ENTRIES).valueOrDefault(None());
    return opt.getOrElseLazy(() => new ArrayVariable<ObjectVariable>([]));
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case CalendarComponentDefinition.ENTRIES:
        if(value.isDefined() && value.get() instanceof ArrayVariable) {
          this.properties.putValue(CalendarComponentDefinition.ENTRIES, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Array[Object] but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(CalendarComponentDefinition.ENTRIES);
        }
        break;
      case CalendarComponentDefinition.SELECTED:
        if(value.isDefined()) {
          this.properties.putValue(CalendarComponentDefinition.SELECTED, value.get());
        } else {
          this.properties.clearValue(CalendarComponentDefinition.SELECTED);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: CalendarComponentState) {
    return new CalendarComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }
}

export class CalendarComponentRefState extends ScreenComponentRefState {
  static className = "CalendarComponentRefState";
  className() {
    return CalendarComponentRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }


  static copy(other: CalendarComponentRefState) {
    return new CalendarComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}
