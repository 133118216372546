import {createArrayFrom0ToNExclusive, GridXY, range} from "@utils";
import {DesignMapConfig} from "./Config";
import {GridProcessModel} from "../model/GridProcessModel";

export class VerticalLine {
  constructor(public x1: number,
              public y1: number,
              public x2: number,
              public y2: number) {
  }
}

export class HorizontalLine {
  constructor(public x1: number,
              public y1: number,
              public x2: number,
              public y2: number) {
  }
}

export class EditorCanvasModel {
  version = 0;
  gridWidth: number = 0;
  gridHeight: number = 0;
  private gridProcessModel!: GridProcessModel;

  horizontalLines: Array<HorizontalLine> = [];
  verticalLines: Array<VerticalLine> = [];
  gridColumns: Array<number> = [];

  constructor(private config: DesignMapConfig) {}


  changeModel(gridProcessModel: GridProcessModel) {
    this.gridProcessModel = gridProcessModel;
    this.updateModel(new GridXY(0, 0));
  }

  updateModel(fakeNodeGridXY: GridXY) {
    const gridSize = this.gridProcessModel.gridSize(fakeNodeGridXY);
    if(this.gridWidth !== gridSize.width || this.gridHeight !== gridSize.height) {
      this.version++;
    }
    this.gridWidth = gridSize.width;
    this.gridHeight = gridSize.height;
    this.horizontalLines = range(this.gridHeight + 1).map(y => new HorizontalLine(
      0,
      y * this.config.cellSize.height + 0.5,
      this.gridWidth * this.config.cellSize.width,
      y * this.config.cellSize.height + 0.5));
    this.verticalLines = range(this.gridWidth + 1).map(x => new VerticalLine(
      x * this.config.cellSize.width + 0.5,
      0,
      x * this.config.cellSize.width + 0.5,
      this.gridHeight * this.config.cellSize.height));
    this.gridColumns = range(this.gridWidth);
  }


}
