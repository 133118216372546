import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from "@angular/core";
import {BlinkingComponentController} from "./BlinkingComponentController";
import {NumberInputComponentController} from "./NumberFormatComponentController";
import {UnitPaddingController} from "./AdjustableInputComponentController";
import {NumberInputComponentViewModel} from "./NumberInputComponentViewModel";
import {myRequestAnimationFrame, mySetTimeout} from "@utils";

@Component({
  selector: 'my-number-input-component',
  templateUrl: './number-input-component.component.html',
  host: {
    "[class]": "'componentBody NumberInputComponent ' + viewModel.customCssClass + ' ' + viewModel.outerCssClasses",
    "[class.preview]": "viewModel.preview",
    "[class.tableCellMode]": "viewModel.tableCellMode",
    "[class.validationError]": "viewModel.validationError",
    "[class.startIcon]": "viewModel.startIcon",
    "[class.endIcon]": "viewModel.endIcon",
    "[style]": "viewModel.outerCss",
  }
})
export class NumberInputComponentComponent implements OnInit, OnDestroy, OnChanges {
  @Input({required:true}) viewModel!: NumberInputComponentViewModel;

  private controller!: UnitPaddingController;
  private blinkController!: BlinkingComponentController;
  private formatController: NumberInputComponentController|null = null;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              private readonly changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    if(this.viewModel === null) {
      throw new Error("No viewModel");
    } else {
      this.initFormatController();
      this.controller = new UnitPaddingController(this.viewModel, this.viewContainerRef.element.nativeElement);
      this.blinkController = new BlinkingComponentController(this.viewContainerRef.element.nativeElement, ".input", this.viewModel);
    }
  }

  initFormatController() {
    mySetTimeout(() => {
      if (this.formatController !== null) {
        this.formatController.destroy();
      }
      const input = (<HTMLElement>this.viewContainerRef.element.nativeElement).querySelector(".input");
      if(input === null) {
        throw new Error("No input");
      } else {
        this.formatController = new NumberInputComponentController(<HTMLInputElement>input, this.viewModel, this.changeDetectorRef);
      }
    });
  }


  ngOnDestroy(): void {
    this.controller.destroy();
    this.blinkController.destroy();
    if(this.formatController !== null) {
      this.formatController.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {


    // scope.$watch("viewModel.enabled", () => {
    //   initFormatController();
    // });
    //
    // scope.$watch("viewModel.unitName", () => {
    //   myRequestAnimationFrame(() => {
    //     controller.onValueChanged();
    //   })
    // });


    this.initFormatController();

    myRequestAnimationFrame(() => {
      this.controller.onValueChanged();
    });
  }



}
