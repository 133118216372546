import {
  DepartmentId,
  GroupId, i18n,
  I18nText,
  None,
  NoneSingleton,
  Option,
  OrganizationNodeId,
  PersonId,
  Some,
  toastr,
  VariableId
} from "@utils";
import {CssBuilder, PersonSelectComponentDefinition, PersonSelectComponentRef, PropertyTry} from "@screen-common";
import {PersonSelectComponentRefState, PersonSelectComponentState} from "./PersonSelectComponentState";
import {
  ComponentLabelViewModel,
  ComponentsCommon,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel
} from "../..";
import {SelectComponentOption} from "../select/select-common";
import {DepartmentVariable, GroupVariable, OrganizationNodeVariable, PersonVariable} from "@shared-model";

export class PersonSelectComponentViewModel extends ComponentViewModelWithLabel {

  iconClass: string = "mi-user";
  override typeName = "PersonSelect";


  public override label!: ComponentLabelViewModel;

  public value: Option<OrganizationNodeId> = None();
  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public css: string = "";
  public cssClasses: string = "";
  public outerShadowCss: string = "";
  public outerShadowCssClasses: string = "";
  public persons: boolean = false;
  public groups: boolean = false;
  public departments: boolean = false;
  public required: boolean = false;
  public invalid: boolean = false;

  public options: Array<SelectComponentOption> = [];

  // isTextColorSet helps inherit font color from component to child element .organizationNode,
  // which can have its own color defined by css stylesheet
  public isTextColorSet: boolean = false;
  withinNodes: Option<Array<OrganizationNodeId>> = None();

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: PersonSelectComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: PersonSelectComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: PersonSelectComponentState,
              readonly refState: PersonSelectComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onChange = (node: Option<OrganizationNodeId>) => {

    if (node.isDefined()) {
      if(node.get().isPerson()) {
        this.serverModel.changeModelWithAction(this.componentRefPath(), PersonSelectComponentDefinition.MODEL, new PersonVariable(PersonId.of(node.get().id)), "onChange");
      }
      else if(node.get().isDepartment()) {
        this.serverModel.changeModelWithAction(this.componentRefPath(), PersonSelectComponentDefinition.MODEL, new DepartmentVariable(new DepartmentId(node.get().id)), "onChange");
      }
      else if(node.get().isGroup()) {
        this.serverModel.changeModelWithAction(this.componentRefPath(), PersonSelectComponentDefinition.MODEL, new GroupVariable(new GroupId(node.get().id)), "onChange");
      }
      else {
        toastr.error("Only person, department and group types are supported");
      }
    } else {
      this.serverModel.clearModelWithAction(this.componentRefPath(), PersonSelectComponentDefinition.MODEL, "onChange");
    }

  };

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();
    const outerShadowCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);
    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);

    const text_color = this.definition.textProperties.textColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.textState.textColor);
    this.isTextColorSet = text_color.valueOrDefault(None()).isDefined();

    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).getOrElse(I18nText.ofCurrent(i18n("common_select"))).getCurrentWithFallback();

    this.value = this.componentState.model.valueOrDefault(None()).map(OrganizationNodeId.copy);
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.persons = this.definition.persons.currentValue(() => this.componentState.persons).valueOrDefault(false);
    this.groups = this.definition.groups.currentValue(() => this.componentState.groups).valueOrDefault(false);
    this.departments = this.definition.departments.currentValue(() => this.componentState.departments).valueOrDefault(false);

    ComponentViewModelUtils.toOuterShadowCss(outerShadowCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());

    ComponentsCommon.innerShadowCss(cssBuilder, innerShadow);

    super.updatePosition();

    this.outerShadowCss = outerShadowCssBuilder.toCss();
    this.outerShadowCssClasses = outerShadowCssBuilder.toCssClasses();

    const nodes: PropertyTry<Option<Array<OrganizationNodeVariable>>> = this.definition.organizationNodes.currentValue(() => this.componentState.organizationNodes)
    if(nodes.isError()) {
      this.withinNodes = Some([]);
    } else {
      this.withinNodes = nodes.valueOrDefault(Some([])).map(v => v.map(n => n.toOrganizationNodeId()));
    }

    this.css = cssBuilder.toCss();
    this.cssClasses = cssBuilder.toCssClasses();
  }


}

