import {Component, Input} from "@angular/core";
import {
  FileUri, FlowId,
  gravatarHash,
  OrganizationNodeId,
  OrganizationNodeTypeEnum,
  organizationsImageDownloadUrl,
  PersonId
} from "@utils";
import {
  ApplicationIcon, ApplicationIcons,
  BasicOrganizationNodeInfo, FlowsSharedService,
  OrganizationSharedService,
  PersonsSharedService
} from "@shared-model";
import {GravatarService} from "../avatar/gravatar.service";


@Component({
  selector: 'my-node-summary',
  templateUrl: './node-summary.component.html',
  host: {
    '[class]': 'nodeType',
    '[class.small]': 'smallIcon'
  }
})
export class NodeSummaryComponent {
  private _nodeId!: OrganizationNodeId;
  get nodeId(): OrganizationNodeId {
    return this._nodeId;
  }
  @Input({required:true}) set nodeId(value: OrganizationNodeId) {
    this._nodeId = value;
    this.onNodeIdChanged();
  }

  @Input() showName: boolean = true;
  @Input() showIcon: boolean = true;

  name: string = "";
  initials: string = "";
  avatarUrl: string|undefined;
  gravatarBlobUrl: string|null = null;

  nodeType!: OrganizationNodeTypeEnum;
  @Input() showTooltip: boolean = false;
  @Input() prefixTooltipInfo?: string;
  @Input() suffixTooltipInfo?: string;

  applicationIcon: ApplicationIcon = ApplicationIcons.empty;
  nameClass: string|undefined;
  iconClass: string|undefined;
  icon: string|undefined;

  @Input() smallIcon: boolean = false;

  constructor(private readonly organizationSharedService: OrganizationSharedService,
              private readonly flowsSharedService: FlowsSharedService,
              private readonly gravatarService: GravatarService) {
  }

  private onNodeIdChanged() {

    if(this.nodeId.isFlow()) {

      this.flowsSharedService.loadFlowBasicInfo(FlowId.of(this.nodeId.id), flowInfo => {
        if (flowInfo.isEmpty()) {
          this.name = "Info not found"
        } else {
          const flow =  flowInfo.getOrError("no flow");
          this.name = flow.flowCode.getOrElse("?")+" "+flow.flowDescription;
          this.initials = "";
          this.nameClass = "theme-process-name";
          this.iconClass = this.smallIcon ? "theme-process-icon-medium" : "theme-process-icon";
          this.icon = "mi-right";
        }
      });
    } else {


      this.organizationSharedService.findNodesBasicInfoByIds([this.nodeId], (nodes: BasicOrganizationNodeInfo[]) => {

        if (nodes.length === 0) {
          this.name = "Info not found"
        } else {
          const node = nodes[0];

          this.nodeType = node.organizationNodeId.nodeType.name;

          if (node.isPerson()) {
            const person = node.asPerson();
            this.name = person.simpleName();
            this.initials = person.initials();
            this.gravatarService.getImageBlobUrl(gravatarHash(person.email)).then((result) => {
              this.gravatarBlobUrl = result.getOrNull();
            });
            this.avatarUrl = person.avatar.map(a => organizationsImageDownloadUrl(a)).getOrUndefined();
          } else if (node.isGroup()) {
            const group = node.asGroup();
            this.name = group.group.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-group-name";
            this.iconClass = this.smallIcon ? "theme-group-icon-medium" : "theme-group-icon";
            this.icon = "mi-user-group";
          } else if (node.isDepartment()) {
            const department = node.asDepartment();
            this.name = department.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-department-name";
            this.iconClass = this.smallIcon ? "theme-department-icon-medium" : "theme-department-icon";
            this.icon = "mi-home";
          } else if (node.isApplication()) {
            const app = node.asApplication();
            this.name = app.name;
            this.initials = this.getInitialsFromName(this.name);
          } else if (node.isProcess()) {
            const process = node.asProcess();
            this.name = process.process.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-process-name";
            this.iconClass = this.smallIcon ? "theme-process-icon-medium" : "theme-process-icon";
            this.icon = "mi-right";
          } else if (node.isReport()) {
            const report = node.asReport();
            this.name = report.report.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-report-name";
            this.iconClass = this.smallIcon ? "theme-report-icon-medium" : "theme-report-icon";
            this.icon = "mi-barchart";
          } else if (node.isScreen()) {
            const screen = node.asScreen();
            this.name = screen.screen.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-screen-name";
            this.iconClass = this.smallIcon ? "theme-screen-icon-medium" : "theme-screen-icon";
            this.icon = "mi-stop";
          } else if (node.isFunctions()) {
            const functions = node.asFunctions();
            this.name = functions.functions.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-functions-name";
            this.iconClass = this.smallIcon ? "theme-functions-icon-medium" : "theme-functions-icon";
            this.icon = "mi-calculator";
          } else if (node.isRemoteOrganization()) {
            const org = node.asRemoteOrganization();
            this.name = org.name;
            this.initials = this.getInitialsFromName(this.name);
            this.nameClass = "theme-remote-organization-name";
            this.iconClass = this.smallIcon ? "theme-remote-organization-icon-medium" : "theme-remote-organization-icon";
            this.icon = "mi-user-group";
          } else {
            throw new Error("Unknown node type: " + node.organizationNodeId.nodeType.name);
          }
        }

      });
    }
  }

  private getInitialsFromName(name: string) {
    const initials = name.split(" ").map(s => s[0]).join("");
    if(initials.length > 2) {
      return initials.substring(0, 2);
    } else {
      return initials;
    }
  }
}
