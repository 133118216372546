import {
  BackgroundsPropertiesState,
  BordersPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  ScreenComponentState
} from "../../model/screen-component-state.model";
import {Option} from "@utils";

export class WidgetComponentState extends ScreenComponentState {
  static className = "WidgetComponentState";
  className() {
    return WidgetComponentState.className;
  }

  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get screenInstanceId() {
    return this.properties.optionalStringProperty("screenInstanceId");
  }

  static copy(other: WidgetComponentState) {
    return new WidgetComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}


export class WidgetComponentRefState extends ScreenComponentRefState {
  static className = "WidgetComponentRefState";
  className() {
    return WidgetComponentRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: WidgetComponentRefState) {
    return new WidgetComponentRefState(PropertiesStateHolder.copy(other.properties));
  }
}
