<div class="calendarHeader">
  <button class="currentValueButton">{{'date_month_get_' + getTempDate().month | i18n}} {{getTempDate().year}}</button>
  <div class="spacer"></div>
  <button class="prevMonthButton" (click)="setPreviousMonth()"><i class="mi-navigate-left"></i></button>
  <button class="nextMonthButton" (click)="setNextMonth()"><i class="mi-navigate-right"></i></button>
</div>
<div class="calendarContent">
  <div class="weeksHeader">
    <span *ngFor="let weekDay of weekdays">{{'date_weekday_vshort_'+weekDay | i18n}}</span>
  </div>
  <div class="weeksContent">
    <div class="week" *ngFor="let week of weeks">
      <button *ngFor="let day of week.days" class="dayButton" [ngClass]="{selected: day.selected, today: day.today, other: day.otherMonth, weekend: day.weekend, past: day.past}" (click)="setValue(day.value)">{{day.label}}</button>
    </div>
  </div>
</div>
