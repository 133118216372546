import {__, LocalDateTime, Option} from "@utils";
import {ArrayVariable, BusinessVariable, CaseVariable, EmailVariable, FileVariableV2} from "@shared-model";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  LabeledScreenComponentState, ParentComponentId,
  PropertiesStateHolder,
  ScreenComponentRefState,
  TextPropertiesState
} from "../..";
import {MultiAttachmentInputComponentDefinition} from "@screen-common";

export class MultiAttachmentInputComponentState extends LabeledScreenComponentState {
  static className = "MultiAttachmentInputComponentState";
  className() {
    return MultiAttachmentInputComponentState.className;
  }
  readonly textState = new TextPropertiesState(this.properties);

  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(readonly properties: PropertiesStateHolder,
              readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get model() {
    return this.properties.optionalArrayProperty(MultiAttachmentInputComponentDefinition.MODEL);
  }

  get placeholder() {
    return this.properties.optionalI18nTextProperty("placeholder");
  }

  get maxFileSize() {
    return this.properties.optionalNumberProperty("maxFileSize");
  }

  get minAttachments() {
    return this.properties.optionalNumberProperty("minAttachments");
  }

  get maxAttachments() {
    return this.properties.optionalNumberProperty("maxAttachments");
  }

  get allowedExtensions() {
    return this.properties.optionalStringArrayProperty("allowedExtensions");
  }

  get displayMode() {
    return this.properties.stringProperty("displayMode");
  }

  get anchorVisible() {
    return this.properties.booleanProperty("anchorVisible");
  }

  get allowDir() {
    return this.properties.booleanProperty("allowDir");
  }

  get innerShadow() {
    return this.properties.optionalBooleanProperty("innerShadow");
  }


  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case MultiAttachmentInputComponentDefinition.MODEL:
        if(value.isDefined() && value.get().className() === ArrayVariable.className && (<ArrayVariable<BusinessVariable>>value.get()).isEmpty() || __((<ArrayVariable<BusinessVariable>>value.get()).unwrappedValue()).all(e => {
          return e instanceof FileVariableV2 || e instanceof CaseVariable || e instanceof EmailVariable
        })) {
          this.properties.putValue(MultiAttachmentInputComponentDefinition.MODEL, value.get());
        } else if(value.isDefined() && value.get().className() === ArrayVariable.className) {
          this.properties.putError(MultiAttachmentInputComponentDefinition.MODEL, LocalDateTime.now(), "Unable to set value, expected Array of (File, Directory, Case or Email) but was ["+(<ArrayVariable<BusinessVariable>>value.get()).unwrappedValue().map(v => v.simpleValueType()).join(", ")+"]");
        } else if(value.isDefined()) {
          this.properties.putError(MultiAttachmentInputComponentDefinition.MODEL, LocalDateTime.now(), "Unable to set value, expected Array of (File, Directory, Case or Email) but was ["+value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(MultiAttachmentInputComponentDefinition.MODEL);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  static copy(other: MultiAttachmentInputComponentState) {
    return new MultiAttachmentInputComponentState(PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class MultiAttachmentInputComponentRefState extends ScreenComponentRefState {
  static className = "MultiAttachmentInputComponentRefState";
  className() {
    return MultiAttachmentInputComponentRefState.className;
  }

  get addRemoveEnabled() {
    return this.properties.booleanProperty("addRemoveEnabled");
  }

  get required() {
    return this.properties.booleanProperty("required");
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  static copy(other: MultiAttachmentInputComponentRefState) {
    return new MultiAttachmentInputComponentRefState(PropertiesStateHolder.copy(other.properties));
  }

}
