import {ApplicationId} from "@utils";

export class UserFavoriteApplications {
  constructor(readonly applications: Array<ApplicationId>) {}

  static copy(other: UserFavoriteApplications): UserFavoriteApplications {
    return new UserFavoriteApplications(other.applications.map(ApplicationId.copy));
  }
}

export class UserPinnedApplications {
  constructor(readonly applications: Array<ApplicationId>) {}

  static copy(other: UserPinnedApplications): UserPinnedApplications {
    return new UserPinnedApplications(other.applications.map(ApplicationId.copy));
  }
}

export class PinnedApplication {
  constructor(readonly id: ApplicationId,
              readonly name: string,
              readonly identifier: string,
              readonly iconCode: string,
              readonly colorId: number) {}

  static copy(other: PinnedApplication): PinnedApplication {
    return new PinnedApplication(
      ApplicationId.copy(other.id),
      other.name,
      other.identifier,
      other.iconCode,
      other.colorId);
  }
}
