import {Injectable} from "@angular/core";
import {
  NavigationService,
  PersonSettingsService,
  SessionServiceProvider,
  SystemSettingsService,
  UserSettingsController
} from "@shared";
import {Subject} from "rxjs";
import {
  __,
  arraysEqual,
  i18n,
  isStandalonePage,
  Language,
  None,
  Option,
  required,
  Some,
  TimeHoursFormat,
  toastr,
  WeekDay
} from "@utils";
import {LocaleListService} from "../../../modules/user.module/serivces/locale-list/locale-list.service";
import {HoursFormat} from "@shared-model";
import {UserSettingsServerModel} from "./user-settings.server-model";


export class Theme {

  constructor(readonly name: string) {}

  static light = new Theme("light");
  static dark = new Theme("dark");
  static highContrast = new Theme("high-contrast");
  static browserCompatibility = new Theme("browser-compatibility");

  static byName(name: string): Theme {
    switch (name) {
      case Theme.light.name: return Theme.light;
      case Theme.dark.name: return Theme.dark;
      case Theme.highContrast.name: return Theme.highContrast;
      case Theme.browserCompatibility.name: return Theme.browserCompatibility;
      default: throw new Error("Incorrect theme '"+name+"'");
    }
  }

}

export class ThemeFontSize {

  constructor(readonly name: string) {}

  static M = new ThemeFontSize("m");
  static L = new ThemeFontSize("l");
  static XL = new ThemeFontSize("xl");
  static DEFAULT: ThemeFontSize = ThemeFontSize.M;

  static byNameOrDefault(name: string|null): ThemeFontSize {
    if(name === null) {
      return ThemeFontSize.DEFAULT;
    } else {
      switch (name) {
        case ThemeFontSize.M.name: return ThemeFontSize.M;
        case ThemeFontSize.L.name: return ThemeFontSize.L;
        case ThemeFontSize.XL.name: return ThemeFontSize.XL;
        default: return ThemeFontSize.DEFAULT;
      }
    }
  }

  static byName(name: string): ThemeFontSize {
    switch (name) {
      case ThemeFontSize.M.name: return ThemeFontSize.M;
      case ThemeFontSize.L.name: return ThemeFontSize.L;
      case ThemeFontSize.XL.name: return ThemeFontSize.XL;
      default: throw new Error("Incorrect font size '"+name+"'");
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserSettingsStateService {

  private readonly LANGUAGE_KEY = "language";
  private readonly TIME_ZONE_KEY = "timeZone";
  private readonly LOCALE_KEY = "locale";
  private readonly HOURS_FORMAT_KEY = "hoursFormat";
  private readonly FIRST_DAY_OF_WEEK_KEY = "firstDayOfWeek";
  private readonly WEEKEND_KEY = "weekend";
  private readonly FONT_SIZE_KEY = "fontSize";
  private readonly THEME_KEY = "theme";

  private locale: Option<string> = None();
  private localeSubject: Subject<Option<string>> = new Subject<Option<string>>();

  private language: Option<Language> = None();
  private languageSubject: Subject<Option<Language>> = new Subject<Option<Language>>();

  private fontSize: ThemeFontSize = ThemeFontSize.DEFAULT;
  private fontSizeSubject: Subject<ThemeFontSize> = new Subject<ThemeFontSize>();

  private theme: Option<Theme> = None();
  private themeSubject: Subject<Option<Theme>> = new Subject<Option<Theme>>();

  // TODO - just have a region and language selector, and display formats

  //(Intl.RelativeTimeFormat)


  private timeZone: Option<string> = None();
  private timeZoneSubject: Subject<Option<string>> = new Subject<Option<string>>();

  private timeHoursFormat: Option<TimeHoursFormat> = None();
  private timeHoursFormatSubject: Subject<Option<TimeHoursFormat>> = new Subject<Option<TimeHoursFormat>>();

  private firstDayOfWeek: Option<WeekDay> = None(); // 1 - monday, 7 - sunday (ISO 8601 Based)
  private firstDayOfWeekSubject: Subject<Option<WeekDay>> = new Subject<Option<WeekDay>>();

  private weekendDays: Option<Array<WeekDay>> = None();
  private weekendDaysSubject: Subject<Option<Array<WeekDay>>> = new Subject<Option<Array<WeekDay>>>();

  private readDateFormat: string = "dd MM yyyy"; // e.g. 17 Aug 2022, maybe use (Intl.DateTimeFormat)
  private editDateFormat: string = "dd-mm-yyyy" // e.g. 17-08-2022

  // private timeIncludeZero - TODO is that necessary?
  private numberFormat: string = "###,###,##0.#####"; // probably best to use build in JS formatting (Intl.NumberFormat)
  private currencySymbolSide: "L"|"R" = "R";
  private currencyFormat: string = "###,###,##0.00"; // (Intl.NumberFormat)

  private serverModel = new UserSettingsServerModel(this.personSettingsService);

  userLoggedIn: boolean = false;

  constructor(private readonly navigationService: NavigationService,
              private readonly userSettingsController: UserSettingsController,
              private readonly systemSettingsService: SystemSettingsService,
              private readonly localeListService: LocaleListService,
              private readonly sessionServiceProvider: SessionServiceProvider,
              private readonly personSettingsService: PersonSettingsService) {
    if(!isStandalonePage()) {
      this.init();
    } else {
      this.initDefaults();
    }
  }

  private init() {

    this.initLocallyStoredSettings();


    this.sessionServiceProvider.getSessionService(sessionService => {

      if (sessionService.isLoggedIn()) {
        const sessionInfo = required(sessionService.organizationSessionInfo, "sessionInfo");
        this.serverModel.sessionStarted(sessionInfo.getPersonId());
        this.initSettingsFromUserSettings();
      }

      sessionService.organizationSessionInfoObservable.subscribe(sessionInfo => {
        if (sessionInfo) {
          this.serverModel.sessionStarted(sessionInfo.getPersonId());
          this.initSettingsFromUserSettings();
        } else {
          this.serverModel.sessionCleared();
          this.initLocallyStoredSettings();
        }
      });
    });

    // TODO ASYNC UPDATE USER SETTINGS FROM SERVER
  }

  private initLocallyStoredSettings(): void {

    this.userLoggedIn = false;

    let reload = false;

    this.initLocallyStoredLanguage();
    this.initLocallyStoredLocale();
    this.initLocallyStoredTimeZone();
    this.initLocallyStoredHoursFormat();
    this.initLocallyStoredFirstDayOfWeek();
    this.initLocallyStoredWeekend();
    this.initLocallyStoredFontSize();
    reload ||= this.initTheme();

    if(reload) {
      this.navigationService.reloadPage();
    }
  }

  private initLocallyStoredLanguage(): void {
    const savedLanguage = localStorage.getItem(this.LANGUAGE_KEY);

    if(savedLanguage == null) {
      this.language = None();
    } else {
      try {
        this.language = Some(Language.byName(savedLanguage));
      } catch {
        this.language = None();
      }
    }

    this.userSettingsController.onLanguageChanged(this.language.getOrElse(this.systemSettingsService.getEffectiveLanguage()));
  }

  private initLocallyStoredTimeZone(): void {
    this.timeZone = Option.of(localStorage.getItem(this.TIME_ZONE_KEY));
  }

  private initLocallyStoredLocale(): void {
    this.locale = Option.of(localStorage.getItem(this.LOCALE_KEY));
  }

  private initLocallyStoredHoursFormat(): void {
    const saved = localStorage.getItem(this.HOURS_FORMAT_KEY);
    this.timeHoursFormat = saved == "12" ? Some(12) : (saved == "24" ? Some(24) : None());
  }

  private initLocallyStoredFirstDayOfWeek(): void {
    const saved = localStorage.getItem(this.FIRST_DAY_OF_WEEK_KEY);
    if(saved == null) {
      this.firstDayOfWeek = None();
    } else {
      try {
        const parsed = parseInt(saved);
        if(parsed >= 1 && parsed <= 7) {
          this.firstDayOfWeek = Some(parsed);
        } else {
          this.firstDayOfWeek = None();
        }
      } catch {
        this.firstDayOfWeek = None();
      }
    }
  }

  private initLocallyStoredWeekend(): void {
    const saved = localStorage.getItem(this.WEEKEND_KEY);
    if(saved == null) {
      this.weekendDays = None();
    } else {
      try {
        const parsed: Array<any> = saved.split('').map(s => parseInt(s));
        if(Array.isArray(parsed)) {
          if(__(parsed).all(p => p >= 1 && p <= 7)) {
            this.weekendDays = Some(__(parsed).unique());
          } else {
            this.weekendDays = None();
          }
        } else {
          this.weekendDays = None();
        }
      } catch {
        this.weekendDays = None();
      }
    }
  }


  private initLocallyStoredFontSize(): void {

    const savedFontSize = localStorage.getItem(this.FONT_SIZE_KEY);
    const newFontSize = ThemeFontSize.byNameOrDefault(savedFontSize);
    if(savedFontSize !== newFontSize.name) {
      localStorage.setItem("fontSize", newFontSize.name);
      this.userSettingsController.onFontSizeChanged(newFontSize);
    }

    if(newFontSize.name !== this.fontSize.name) {
      this.fontSize = newFontSize;
    }

  }

  private initTheme(): boolean {

    let reload = false;
    const savedTheme = localStorage.getItem(this.THEME_KEY);
    let newTheme: Option<Theme>;

    try {
      if(savedTheme == null) {
        newTheme = None();
      } else {
        newTheme = Some(Theme.byName(savedTheme));
      }
    } catch(e) {
      newTheme = None();
    }

    if(newTheme.map(l => l.name).getOrNull() != savedTheme) {
      if(newTheme.isDefined()) {
        localStorage.setItem(this.THEME_KEY, newTheme.get().name);
      } else {
        localStorage.removeItem(this.THEME_KEY);
      }

      reload = true;
    }

    if(!newTheme.equals(this.theme, (a, b) => a.name === b.name)) {
      this.theme = newTheme;
    }

    return reload;
  }


  changeFontSize(size: ThemeFontSize) {
    this.fontSize = size;
    localStorage.setItem("fontSize", this.fontSize.name);
    this.userSettingsController.onFontSizeChanged(this.fontSize);
    this.fontSizeSubject.next(size);
  }

  changeTheme(theme: Option<Theme>) {
    this.theme = theme;
    if(theme.isDefined()) {
      localStorage.setItem(this.THEME_KEY, this.theme.get().name);
    } else {
      localStorage.removeItem(this.THEME_KEY);
    }
    this.themeSubject.next(theme);
    this.userSettingsController.onThemeChanged(this.theme.getOrElse(this.systemSettingsService.getEffectiveTheme()), Language.themeOfLanguage(this.language.getOrElse(this.systemSettingsService.getEffectiveLanguage())));
  }



  changeLanguage(language: Option<Language>, saveChanges: boolean = true, refreshPage: boolean = true): boolean {
    if(!this.language.equals(language, (a, b) => a.name === b.name)) {
      this.language = language;

      if(language.isDefined()) {
        localStorage.setItem(this.LANGUAGE_KEY, this.language.get().name);
      } else {
        localStorage.removeItem(this.LANGUAGE_KEY);
      }

      this.languageSubject.next(language);
      const effectiveLanguage = this.language.getOrElse(this.systemSettingsService.getEffectiveLanguage());

      this.changeLocale(Some(effectiveLanguage.name), false, false);
      this.userSettingsController.onThemeChanged(this.theme.getOrElse(this.systemSettingsService.getEffectiveTheme()), Language.themeOfLanguage(effectiveLanguage));

      if(saveChanges) {
        this.saveUserSettings();
      }
      if(refreshPage) {
        this.navigationService.refreshPage();
      }

      return true;
    } else {
      return false;
    }
  }



  changeLocale(locale: Option<string>, saveChanges: boolean = true, refreshPage: boolean = true): boolean {
    if(!this.locale.equals(locale)) {
      this.locale = locale;
      this.localeSubject.next(locale);

      if (locale.isDefined()) {
        localStorage.setItem(this.LOCALE_KEY, this.locale.get());
      } else {
        localStorage.removeItem(this.LOCALE_KEY);
      }

      if (saveChanges) {
        this.saveUserSettings();
      }

      if(refreshPage) {
        this.navigationService.refreshPage();
      }
      return true;
    } else {
      return false;
    }
  }

  changeTimeZone(timeZone: Option<string>, saveChanges: boolean = true, refreshPage: boolean = true): boolean {
    if(!this.timeZone.equals(timeZone)) {
      this.timeZone = timeZone;
      this.timeZoneSubject.next(timeZone);

      if (timeZone.isDefined()) {
        localStorage.setItem(this.TIME_ZONE_KEY, this.timeZone.get());
      } else {
        localStorage.removeItem(this.TIME_ZONE_KEY);
      }

      if (saveChanges) {
        this.saveUserSettings();
      }

      if(refreshPage) {
        this.navigationService.refreshPage();
      }
      return true;
    } else {
      return false;
    }
  }


  changeTimeHoursFormat(timeHoursFormat: Option<TimeHoursFormat>, saveChanges: boolean = true, refreshPage: boolean = true) {
    if(!this.timeHoursFormat.equals(timeHoursFormat)) {
      this.timeHoursFormat = timeHoursFormat;
      this.timeHoursFormatSubject.next(timeHoursFormat);

      if (timeHoursFormat.isDefined()) {
        localStorage.setItem(this.HOURS_FORMAT_KEY, this.timeHoursFormat.get()+"");
      } else {
        localStorage.removeItem(this.HOURS_FORMAT_KEY);
      }

      if (saveChanges) {
        this.saveUserSettings();
      }

      if(refreshPage) {
        this.navigationService.refreshPage();
      }
      return true;
    } else {
      return false;
    }
  }

  changeFirstDayOfWeek(firstDayOfWeek: Option<WeekDay>, saveChanges: boolean = true, refreshPage: boolean = true) {

    if(!this.firstDayOfWeek.equals(firstDayOfWeek)) {

      this.firstDayOfWeek = firstDayOfWeek;
      this.firstDayOfWeekSubject.next(firstDayOfWeek);

      if (firstDayOfWeek.isDefined()) {
        localStorage.setItem(this.FIRST_DAY_OF_WEEK_KEY, this.firstDayOfWeek.get()+"");
      } else {
        localStorage.removeItem(this.FIRST_DAY_OF_WEEK_KEY);
      }

      if (saveChanges) {
        this.saveUserSettings();
      }

      if(refreshPage) {
        this.navigationService.refreshPage();
      }
      return true;
    } else {
      return false;
    }
  }

  changeWeekendDays(weekendDays: Option<Array<WeekDay>>, saveChanges: boolean = true, refreshPage: boolean = true) {

    if(!this.weekendDays.equals(weekendDays, (a, b) => arraysEqual(a, b))) {

      this.weekendDays = weekendDays;
      this.weekendDaysSubject.next(weekendDays);


      if (weekendDays.isDefined()) {
        localStorage.setItem(this.WEEKEND_KEY, this.weekendDays.get().join(""));
      } else {
        localStorage.removeItem(this.WEEKEND_KEY);
      }

      if (saveChanges) {
        this.saveUserSettings();
      }

      if(refreshPage) {
        this.navigationService.refreshPage();
      }
      return true;
    } else {
      return false;
    }

  }


  getLanguage() {
    return this.language;
  }

  getLanguageObservable() {
    return this.languageSubject.asObservable();
  }

  getFontSize() {
    return this.fontSize;
  }

  getFontSizeObservable() {
    return this.fontSizeSubject.asObservable();
  }

  getTheme() {
    return this.theme;
  }

  getThemeObservable() {
    return this.themeSubject.asObservable();
  }

  getLanguageTheme() {
    return Language.themeOfLanguage(this.language.getOrElse(this.systemSettingsService.getEffectiveLanguage()));
  }

  getEffectiveLocale() {
    return this.locale.getOrElse(this.systemSettingsService.getEffectiveLocale());
  }

  getTimeZone() {
    return this.timeZone;
  }

  getEffectiveTimeZone(): string {
    return this.timeZone.getOrElse(this.timeZone.getOrElse(this.systemSettingsService.getEffectiveTimeZone()));
  }

  getTimeHoursFormat(): Option<TimeHoursFormat> {
    return this.timeHoursFormat;
  }

  getEffectiveFirstDayOfWeek(): WeekDay {
    return this.firstDayOfWeek.getOrElse(this.getDefaultFirstDayOfWeek());
  }

  getWeekdayName(weekDay: WeekDay): string {
    if(weekDay > 0 && weekDay < 8) {
      return i18n("calendar_weekday" + weekDay);
    } else {
      throw new Error("Incorrect week day '"+weekDay+"'");
    }

}

  getEffectiveWeekendDays(): Array<WeekDay> {
    return this.weekendDays.getOrElse(this.getDefaultWeekendDays());
  }

  getWeekendMap(): {[weekDay: number]: boolean} {
    const weekendDays = this.getEffectiveWeekendDays();
    const map: {[weekDay: number]: boolean} = {};
    [1, 2, 3, 4, 5, 6, 7].forEach(wd => map[wd] = false);
    weekendDays.forEach(wd => map[wd] = true);
    return map;
  }

  getLocaleObservable() {
    return this.localeSubject.asObservable();
  }

  getTimeZoneObservable() {
    return this.timeZoneSubject.asObservable();
  }


  getTimeHoursFormatObservable() {
    return this.timeHoursFormatSubject.asObservable();
  }

  getFirstDayOfWeekObservable() {
    return this.firstDayOfWeekSubject.asObservable();
  }

  getWeekendDaysObservable() {
    return this.weekendDaysSubject.asObservable();
  }


  getEffectiveLanguage() {
    return this.language.getOrElse(this.systemSettingsService.getEffectiveLanguage());
  }

  getEffectiveTheme() {
    return this.theme.getOrElse(this.systemSettingsService.getEffectiveTheme());
  }

  getLocale() {
    return this.locale;
  }

  getDefaultLocale(): Option<string> {

    if(this.language.isEmpty() || this.language.contains(this.systemSettingsService.getEffectiveLanguage())) {
      return Some(this.systemSettingsService.getEffectiveLocale());
    } else {
      return None();
    }

  }

  getDefaultFirstDayOfWeek(): WeekDay {
    return this.localeListService.getWeekInfoForLocale(this.getEffectiveLocale()).firstDay;
  }

  getDefaultWeekendDays(): Array<WeekDay> {
    return this.localeListService.getWeekInfoForLocale(this.getEffectiveLocale()).weekend;
  }

  getDefaultLanguage() {
    return this.systemSettingsService.getEffectiveLanguage();
  }

  // based on current effective locale
  getDefaultTimeHoursFormat(): TimeHoursFormat {
    const formatted = Intl.DateTimeFormat(this.getEffectiveLocale(), {timeZone: "UTC", hour: "2-digit"}).format(Date.UTC(2000, 0, 1, 20, 0, 0, 0));
    return formatted.indexOf("20") >= 0 ? 24 : 12;
  }

  getDefaultTimeZone() {
    return this.systemSettingsService.getEffectiveTimeZone();
  }

  getDefaultTheme() {
    return this.systemSettingsService.getEffectiveTheme();
  }

  getFirstDayOfWeek() {
    return this.firstDayOfWeek;
  }

  getWeekendDays() {
    return this.weekendDays;
  }


  private initSettingsFromUserSettings() {

    this.userLoggedIn = true;

    this.personSettingsService.findMyInfoPromise().then(myInfo => {
      if(myInfo.isDefined()) {

        const info = myInfo.getOrError("No info");

        let refreshPage = false;

        refreshPage ||= this.changeLanguage(info.language, false, false);
        refreshPage ||= this.changeTimeZone(info.timezone, false, false);
        refreshPage ||= this.changeLocale(info.locale, false, false);
        refreshPage ||= this.changeTimeHoursFormat(info.hoursFormat.map(hf => hf.is12() ? 12 : 24), false, false);
        refreshPage ||= this.changeFirstDayOfWeek(info.firstDayOfWeek, false, false);
        refreshPage ||= this.changeWeekendDays(info.weekend, false, false);

        let reloadPage = this.initTheme();

        if(reloadPage) {
          this.navigationService.reloadPage();
        } else if(refreshPage) {
          setTimeout(() => {
            this.navigationService.refreshPage();
          });
        }


      } else {
        toastr.error("No person info found");
      }

    });



  }

  private saveUserSettings() {
    if(this.userLoggedIn) {
      this.serverModel.updateLocaleSettings(this.timeZone, this.language, this.locale, this.timeHoursFormat.map(f => f == 12 ? HoursFormat.format12 : HoursFormat.format24), this.firstDayOfWeek, this.weekendDays);
    }
  }

  private initDefaults() {
    this.theme = Some(Theme.light);
    this.language = None();
    this.locale = None();
    this.timeZone = None();
    this.timeHoursFormat = None();
    this.firstDayOfWeek = None();
    this.weekendDays = None();
  }
}
