import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalDateProperty,
  OptionalI18nTextProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId, TextAlign} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class DateInputComponentRef extends InputComponentRef {
  static className = "DateInputComponentRef";

  className() {
    return DateInputComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: DateInputComponentRef): DateInputComponentRef {
    return new DateInputComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("DateInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = DateInputComponentRef.DEFAULT_PROPERTIES;

}

export class DateInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";
  static className = "DateInputComponentDefinition";

  className() {
    return DateInputComponentDefinition.className;
  }

  typeName() {
    return "DateInput";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,DateInputComponentDefinition.DEFAULT_PROPERTIES);
  }


  get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)};
  get minValue(): OptionalDateProperty {return this.properties.getOptionalDateProperty("minValue", this.defaultPropertiesProvider)};
  get maxValue(): OptionalDateProperty {return this.properties.getOptionalDateProperty("maxValue", this.defaultPropertiesProvider)};
  get anchorVisible(): BooleanProperty {return this.properties.getBooleanProperty("anchorVisible", this.defaultPropertiesProvider)};

  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: DateInputComponentDefinition): DateInputComponentDefinition {
    return new DateInputComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [DateInputComponentDefinition.MODEL];
  }


  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("DateInputDefinition", (name: string) => {switch (name) {
      case "placeholder": return OptionalI18nTextProperty.disabled();
      case "minValue": return OptionalDateProperty.disabled();
      case "maxValue": return OptionalDateProperty.disabled();
      case "textAlign": return StringProperty.of(TextAlign.start.name);
      case "anchorVisible": return BooleanProperty.ofTrue();
      case "innerShadow": return OptionalBooleanProperty.disabled(true);
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
