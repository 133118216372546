import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FlowId, None, Option, Some, toastr} from "@utils";
import {BusinessVariable, CaseVariable, FlowsSharedService, FlowSummaryViewModel, VariablePath} from "@shared-model";

export class CaseVariableInputViewModel {

  previewText: string | null = null;
  internalModel: FlowSummaryViewModel | null = null;
  flowCode: null | string = null;

  constructor(readonly flowsSharedService: FlowsSharedService,
              readonly path: VariablePath,
              public value: CaseVariable | null,
              public readOnly: boolean,
              public preview: boolean,
              readonly change: EventEmitter<{ value: BusinessVariable | null, path: VariablePath }>) {
    this.updatePreviewText();
    this.initInternalModel();
  }

  private updatePreviewText() {
    this.previewText = this.value === null ? "-" : "Case#" + this.value.value.id;
  }

  initInternalModel() {
    if (this.value == null) {
      this.internalModel = null;
    } else {
      this.flowsSharedService.loadFlowBasicInfo(this.value.value, (result) => {
        if (result.isDefined()) {
          const flow = result.getOrError("no flow");
          this.flowCode = flow.flowCode.getOrNull();
          this.previewText = flow.flowCode.getOrElse("New") + " " + flow.flowDescription;
        } else {
          toastr.info("Flow not found");
        }
      });

    }
  }

  private changeVariableValue(value: CaseVariable | null) {
    this.value = value;
    this.updatePreviewText();
    this.initInternalModel();
    this.change.emit({value: value, path: this.path});
  }


  updateValue(value: BusinessVariable | null) {
    if (value === null || value instanceof CaseVariable) {
      this.value = value;
      this.initInternalModel();
    } else {
      throw new Error("Incorrect variable type, expected 'Case' but was '" + value.simpleValueType() + "'");
    }
  }

  updateMode(readOnly: boolean, preview: boolean) {
    this.readOnly = readOnly;
    this.preview = preview;
  }

}

@Component({
  selector: "my-case-variable-input",
  templateUrl: "./case-variable-input.component.html"
})
export class CaseVariableInputComponent implements OnInit {
  private _value!: BusinessVariable | null;
  get value(): BusinessVariable | null {
    return this._value;
  }

  @Input() set value(value: BusinessVariable | null) {
    this._value = value;
    this.onValueChanged();
  }


  private _readOnly: boolean = false;
  get readOnly(): boolean {
    return this._readOnly;
  }

  @Input() set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
    this.onModeChanged();
  }

  private _preview: boolean = false;
  get preview(): boolean {
    return this._preview;
  }

  @Input() set preview(preview: boolean) {
    this._preview = preview;
    this.onModeChanged();
  }

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{ value: BusinessVariable | null, path: VariablePath }>();

  viewModel!: CaseVariableInputViewModel;

  constructor(private readonly flowsSharedService: FlowsSharedService) {
  }


  private onValueChanged() {
    if (this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if (this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new CaseVariableInputViewModel(this.flowsSharedService, this.path, this._value, this._readOnly, this._preview, this.change);
  }
}
