import {AggregateId, AggregateVersion, Option} from "@utils";

// export class CategoryTree {
//   name: string;
//   children: CategoryTree[];
//   processes: AggregateId[];
//   applications: ApplicationId[];
//   processNames: Array<string>;
// }

export class ExportProcessesStructureByCategoryId {
  constructor(readonly categoryId: number, readonly language: Option<string>, readonly processSettings: ProcessSettings) {}
}

export class ProcessSettings {
  constructor(public releaseDescription: boolean,
              public processRoles: boolean,
              public processOwner: boolean,
              public customTarget: boolean,
              public customInputsProcesses: boolean,
              public customOutputsProcesses: boolean,
              public customRelatedProcesses: boolean,
              public customProcessDocuments: boolean,
              public customProcessMeasures: boolean,
              public customProcessRisks: boolean,
              public customProcessTools: boolean,
              public customProcessVersions: boolean) {}

  static default() {
    return new ProcessSettings(false, false,false, false,
      false,false, false, false,
      false, false, false, false)
  }

}

export class CategoriesInfoDocument {

  constructor(public categoriesInfo: Option<CategoriesInfo>,
              public processesCount: Array<[number, number]>,
              public applicationsCount: Array<[number, number]>) {}

  static copy(other: CategoriesInfoDocument) {
    return new CategoriesInfoDocument(
      Option.copy(other.categoriesInfo, CategoriesInfo.copy),
      other.processesCount.slice(),
      other.applicationsCount.slice()
    );
  }

}

export class CategoriesInfo {
  constructor(readonly aggregateId: AggregateId,
              public aggregateVersion: AggregateVersion,
              public categories: Categories) {}

  static copy(other: CategoriesInfo) {
    return new CategoriesInfo(other.aggregateId, other.aggregateVersion, Categories.copy(other.categories));
  }
}

export class Categories {

  constructor(readonly organizationId: AggregateId,
              public categoryTree: StructuredCategoryTree) {}

  static copy(other: Categories) {
    return new Categories(other.organizationId, StructuredCategoryTree.copy(other.categoryTree));
  }

  // findMaxId(): number {
  //   return this.findMaxIdRecursive(this.categoryTree);
  // }

  // findCategoryById(categoryId: number): Category {
  //   if(this.categoryTree.categoryId === categoryId) {
  //     return this.categoryTree;
  //   } else {
  //     return this.findCategoryByIdRecursive(this.categoryTree, categoryId);
  //   }
  // }
  //
  // findCategoryAncestors(categoryId: number): Category[] {
  //   return this.findCategoryAncestorsRecursive(this.categoryTree, categoryId);
  // }
  //
  // private findMaxIdRecursive(category: Category): number {
  //   const maxId = __.max(category.children.map(child => child.categoryId));
  //   const takenOverMaxId = _.max(category.takenOver);
  //   const descendantsMaxId = _.max(category.children.map(child => this.findMaxIdRecursive(child)));
  //
  //   return Math.max(category.categoryId, maxId, takenOverMaxId, descendantsMaxId);
  // }

  // private findCategoryByIdRecursive(category: Category, categoryId: number): Category {
  //   let categoryFound = category.children.filter((child: Category) => child.categoryId === categoryId)[0];
  //   if(categoryFound !== undefined) {
  //     return categoryFound;
  //   } else {
  //     _.find(category.children, (child: Category) => {
  //       categoryFound = this.findCategoryByIdRecursive(child, categoryId);
  //       return categoryFound;
  //     });
  //     return categoryFound;
  //   }
  // }

  // private findCategoryAncestorsRecursive(category: Category, categoryId: number): Category[] {
  //   if(category.categoryId === categoryId) {
  //     return [];
  //   }
  //
  //   if(category.children.length === 0) {
  //     return [];
  //   } else {
  //     let path: Category[];
  //     category.children.find((child: Category) => {
  //       path = this.findCategoryAncestorsRecursive(child, categoryId);
  //       return path;
  //     });
  //
  //     if(path) {
  //       return [category].concat(path);
  //     } else {
  //       return undefined;
  //     }
  //   }
  // }

}

export class StructuredCategoryTree {

  constructor(public categoryId: number,
              public categoryName: string,
              public children: StructuredCategoryTree[],
              public takenOver: number[]) {}

  static copy(other: StructuredCategoryTree): StructuredCategoryTree {
    return new StructuredCategoryTree(other.categoryId, other.categoryName, other.children.map(c => StructuredCategoryTree.copy(c)), other.takenOver.slice());
  }

}
