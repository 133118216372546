import {
  AuthenticatedHttp,
  BasicOrganizationNodeInfo,
  BusinessVariable,
  DepartmentVariable,
  GroupVariable,
  OrganizationSharedService,
  PersonVariable,
  ProcessesNamingResponseTransformed,
  ProcessReleaseNames, ProcessReleaseNamesTransformed
} from "..";
import {AggregateId, ApplicationId, i18n, None, Option, OrganizationNodeId, Some} from "@utils";
import {Injectable} from "@angular/core";

export class ProcessesNamingQuery {
  instances: Array<AggregateId>;
  processes: Array<AggregateId>;
  processesReleases: Array<AggregateId>;

  constructor(instances: Array<AggregateId>, processes: Array<AggregateId>, processesReleases: Array<AggregateId>) {
    this.instances = instances;
    this.processes = processes;
    this.processesReleases = processesReleases;
  }
}

export interface ProcessesNamingResponse {
  instances: Array<[AggregateId, string]>;
  processes: Array<[AggregateId, string]>;
  releases: Array<[AggregateId, ProcessReleaseNames]>;
  processesApplications: Array<[AggregateId, Option<ApplicationId>]>;
}

@Injectable({
  providedIn: 'root',
})
export class ProcessesNamingQueryService {

  private instancesCache: {[id: string]: string} = {};
  private processesCache: {[id: string]: string} = {"7r81zurqox2i": i18n("common_task_process_name")};
  private processesApplicationsCache: {[id: string]: Option<ApplicationId>} = {"7r81zurqox2i": Some(ApplicationId.tasks)}
  private processesReleasesCache: {[id: string]: ProcessReleaseNamesTransformed} = {};

  private organizationNodesNames: {[id: string]: string} = {};

  constructor(readonly authenticatedHttp: AuthenticatedHttp,
              readonly organizationStructureQueryService: OrganizationSharedService) {
  }

  queryForNames(instances: Array<AggregateId>, processes: Array<AggregateId>, processesReleases: Array<AggregateId>, onSuccess: (names: ProcessesNamingResponseTransformed) => void) {

    const instancesToLoad: Array<AggregateId> = [];
    const instancesFromCache: {[id: string]: string} = {};
    instances.forEach(i => {
      if (this.instancesCache[i.id]) {
        instancesFromCache[i.id] = this.instancesCache[i.id];
      } else {
        instancesToLoad.push(i);
      }
    });

    const processesToLoad: Array<AggregateId> = [];
    const processesFromCache: {[id: string]: string} = {};
    const processesApplicationsFromCache: {[id: string]: Option<ApplicationId>} = {};
    processes.forEach(i => {
      if (this.processesCache[i.id]) {
        processesFromCache[i.id] = this.processesCache[i.id];
        processesApplicationsFromCache[i.id] = this.processesApplicationsCache[i.id];
      } else {
        processesToLoad.push(i);
      }
    });

    const processesReleasesToLoad: Array<AggregateId> = [];
    const processesReleasesFromCache: {[id: string]: ProcessReleaseNamesTransformed} = {};
    processesReleases.forEach(i => {
      if (this.processesReleasesCache[i.id]) {
        processesReleasesFromCache[i.id] = this.processesReleasesCache[i.id];
      } else {
        processesReleasesToLoad.push(i);
      }
    });


    if(instancesToLoad.length === 0 && processesToLoad.length === 0 && processesReleasesToLoad.length === 0) {
      onSuccess(new ProcessesNamingResponseTransformed(instancesFromCache, processesFromCache, processesReleasesFromCache, processesApplicationsFromCache));
    } else {
      this.authenticatedHttp.post("process/find-names", new ProcessesNamingQuery(instancesToLoad, processesToLoad, processesReleasesToLoad),
        (data: ProcessesNamingResponse) => {
        const loadedResponse = ProcessesNamingResponseTransformed.from(data);

        // save to cache


        instancesToLoad.forEach(i => {
          this.instancesCache[i.id] = this.orDefault(loadedResponse.instances[i.id], "?");
        });

        processesToLoad.forEach(i => {
          this.processesCache[i.id] = this.orDefault(loadedResponse.processes[i.id], "?");
          this.processesApplicationsCache[i.id] = this.orDefaultApplication(loadedResponse.processesApplications[i.id], Some(ApplicationId.EMPTY));
        });

        processesReleasesToLoad.forEach(i => {
          const loaded = loadedResponse.releases[i.id];
          if (loaded) {
            this.processesReleasesCache[i.id] = loaded;
          } else {
            this.processesReleasesCache[i.id] = new ProcessReleaseNamesTransformed(None(), {}, {});
          }
        });



        // merge with cache
        Object.keys(instancesFromCache).forEach((key => {
          loadedResponse.instances[key] = instancesFromCache[key];
        }));

        Object.keys(processesFromCache).forEach((key => {
          loadedResponse.processes[key] = processesFromCache[key];
        }));

        Object.keys(processesApplicationsFromCache).forEach((key => {
          loadedResponse.processesApplications[key] = processesApplicationsFromCache[key];
        }));

        Object.keys(processesReleasesFromCache).forEach((key => {
          loadedResponse.releases[key] = processesReleasesFromCache[key];
        }));

        onSuccess(loadedResponse);
      });

    }

  }


  private orDefaultApplication(value: Option<ApplicationId>, defaultValue: Option<ApplicationId>): Option<ApplicationId> {
    return value ? value : defaultValue;
  }

  private orDefault(value: string|undefined|null, defaultValue: string): string {
    return value ? value : defaultValue;
  }

  preloadNamesForVariables(variables: Array<BusinessVariable>, onDone: () => void) {

    const nodesToLoad: Array<OrganizationNodeId> = [];


    variables.filter(v => {
      if(v instanceof GroupVariable) {
        if(!this.organizationNodesNames[v.value.id.id]) {
          nodesToLoad.push(OrganizationNodeId.fromGroupId(v.value.id));
        }
      } else if(v instanceof DepartmentVariable) {
        if(!this.organizationNodesNames[v.value.id.id]) {
          nodesToLoad.push(OrganizationNodeId.fromDepartmentId(v.value.id));
        }
      } else if(v instanceof PersonVariable) {
        if(!this.organizationNodesNames[v.value.id.id]) {
          nodesToLoad.push(OrganizationNodeId.fromPersonId(v.value.id));
        }
      }
    });


    if(nodesToLoad.length > 0) {
      this.organizationStructureQueryService.findNodesBasicInfoByIds(nodesToLoad, (nodes: Array<BasicOrganizationNodeInfo>) => {
        nodes.forEach(n => {
          this.organizationNodesNames[n.aggregateId.id] = n.simpleName();
        })
        onDone();
      });
    } else {
      onDone();
    }

  }

  variableNameSync(l: BusinessVariable): string {
    if(l instanceof DepartmentVariable) {
      const loaded = this.organizationNodesNames[l.value.id.id];
      if(loaded) {
        return loaded;
      }
    } else if(l instanceof GroupVariable) {
      const loaded = this.organizationNodesNames[l.value.id.id];
      if(loaded) {
        return loaded;
      }
    } else if(l instanceof PersonVariable) {
      const loaded = this.organizationNodesNames[l.value.id.id];
      if(loaded) {
        return loaded;
      }
    }
    return l.valueToSimpleString();
  }
}
