import {LocalDate, LocalDateTime, LocalTime, TimezonedLocalDateTime} from "../data-types/Time";


export enum TimeHoursFormat {
  h12 = 12,
  h24 = 24
}
export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7
}

export const WeekDayList: Array<WeekDay> = [WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY, WeekDay.SATURDAY, WeekDay.SUNDAY]

export function minsToMills(minutes: number) {
  return minutes * 60000;
}

export function millsToMinsRound(mills: number) {
  return Math.round(mills / 60000);
}

export function toTimezonedLocalDateTimeFromUTC(timestamp: LocalDateTime, timezone: string): TimezonedLocalDateTime {
  return toTimezonedLocalDateTime(timestamp, timezone, "utc");
}

export function toTimezonedLocalDateTimeFromLocal(timestamp: LocalDateTime, timezone: string): TimezonedLocalDateTime {
  return toTimezonedLocalDateTime(timestamp, timezone, "local");
}

function toTimezonedLocalDateTime(timestamp: LocalDateTime, timezone: string, type: "utc"|"local"): TimezonedLocalDateTime {

  // TODO fallback if Intl.DateTimeFormat is not available
  // TODO cache

  let date: number;
  switch (type) {
    case "utc":
      date = Date.UTC(timestamp.date.year, timestamp.date.month - 1, timestamp.date.day, timestamp.time.hour, timestamp.time.minute, timestamp.time.second, timestamp.time.nano / 1000000);
      break;
    case "local": // FIX this might use system timezone, not the one defined by user
      date = new Date(timestamp.date.year, timestamp.date.month - 1, timestamp.date.day, timestamp.time.hour, timestamp.time.minute, timestamp.time.second, timestamp.time.nano / 1000000).getTime();
      break;
    default: throw new Error("Incorrect type '" + type + "'");
  }

  //'01.01.1901, 02:25:00 GMT+1:24'
  const formatted = new Intl.DateTimeFormat("pl", {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    timeZoneName: 'longOffset',
    timeZone: timezone
  }).format(date);


  let dateTimeSeparatorIndex = formatted.indexOf(" ");
  const year = parseInt(formatted.substring(6, dateTimeSeparatorIndex - 1));
  const month = parseInt(formatted.substring(3, 5));
  const day = parseInt(formatted.substring(0, 2));

  const hour = parseInt(formatted.substring(dateTimeSeparatorIndex + 1, dateTimeSeparatorIndex + 3));
  const minute = parseInt(formatted.substring(dateTimeSeparatorIndex + 4, dateTimeSeparatorIndex + 6));
  const second = parseInt(formatted.substring(dateTimeSeparatorIndex + 7, dateTimeSeparatorIndex + 9));

  const timezoneOffset = formatted.substring(dateTimeSeparatorIndex + 13, formatted.length);

  return new TimezonedLocalDateTime(new LocalDate(year, month, day), new LocalTime(hour, minute, second, 0), timezoneOffset);
}

export function toLocalDateTimeFromTimezoned(timestamp: TimezonedLocalDateTime): LocalDateTime {
  // TODO fallback if Intl.DateTimeFormat is not available
  // TODO cache
  const formatted = timestamp.toDate().toISOString()
  const year = parseInt(formatted.substring(0, 4));
  const month = parseInt(formatted.substring(5, 7));
  const day = parseInt(formatted.substring(8, 10));
  const hour = parseInt(formatted.substring(11, 13));
  const minute = parseInt(formatted.substring(14, 16));
  const second = parseInt(formatted.substring(17, 19));
  const millisecond = parseInt(formatted.substring(20, 23));
  return new LocalDateTime(new LocalDate(year, month, day), new LocalTime(hour, minute, second, millisecond * 1000000));
}


  //
  // export class TimeCalculator {
  //   private calendar: DepartmentCalendar;
  //   private calendarTimezone: string;
  //   constructor(calendar: DepartmentCalendar, calendarTimezone: string) {
  //     this.calendar = calendar;
  //     this.calendarTimezone = calendarTimezone;
  //   }
  //
  //   computeCompanyTime(from: LocalDateTime, to: LocalDateTime): number {
  //     return computeTimeByCalendar(from, to, this.calendar, this.calendarTimezone);
  //   }
  // }


export function nowTimestamp(): number {
  return new Date().getTime();
}


export function millisOfYear(year: number): number {
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  let days = 0;

  if(year < 1970) {
    for (let i = year; i < 1970; i++) {
      if (i % 4 === 0 && (i % 100 !== 0 || i % 400 === 0)) {
        // Leap year
        days += 366;
      } else {
        days += 365;
      }
    }
  } else {
    for (let i = 1970; i < year; i++) {
      if (i % 4 === 0 && (i % 100 !== 0 || i % 400 === 0)) {
        // Leap year
        days += 366;
      } else {
        days += 365;
      }
    }

  }
  return days * millisecondsInADay;
}
