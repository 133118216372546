import {ExpressionWithAst, ProcessEdgeId, ProcessNodeId, ProcessRoleId} from "@shared-model";
import {Duration, GridXYPath, I18nText, None, Option, toTimespanFromMins, Trilean, Typed} from "@utils";
import {EdgeType} from "@shared-model";

export type EdgeDelayMethod = "ActionDelay"|"CaseStartDelay"|"ExpressionDelay"|"FormFieldDelay";

export abstract class EdgeDelay {

  abstract className(): string;
  abstract isValid(): boolean;
  abstract useOrganizationCalendar: boolean;

  abstract formattedToString(): string;

  abstract getMethod(): EdgeDelayMethod;

  abstract getDuration(): Duration;

  abstract getExpression(): string;

}

export class CaseStartDelay extends EdgeDelay {
  static className = "CaseStartDelay";

  constructor(public delayMillis: number, public useOrganizationCalendar: boolean) {
    super();
  }

  className() {
    return CaseStartDelay.className;
  }

  isValid(): boolean {
    return this.delayMillis > 0;
  }

  static empty(): CaseStartDelay {
    return new CaseStartDelay(0, false);
  }

  formattedToString(): string {
    return "";
  }

  override getMethod(): EdgeDelayMethod {
    return "CaseStartDelay";
  }
  override getDuration(): Duration {
    return Duration.ofMilliseconds(this.delayMillis);
  }
  override getExpression(): string {
    return "";
  }

}

export class ActionDelay extends EdgeDelay {
  static className = "ActionDelay";

  constructor(public delayMillis: number, public useOrganizationCalendar: boolean) {
    super();
  }

  className() {
    return ActionDelay.className;
  }

  isValid(): boolean {
    return this.delayMillis > 0;
  }

  static empty(): ActionDelay {
    return new ActionDelay(0, false);
  }

  formattedToString(): string {
    if(this.useOrganizationCalendar) {
      return toTimespanFromMins(Math.ceil(this.delayMillis / 60000), 8)+ " (org)";
    } else {
      return toTimespanFromMins(Math.ceil(this.delayMillis / 60000), 24);
    }
  }

  override getMethod(): EdgeDelayMethod {
    return "ActionDelay";
  }
  override getDuration(): Duration {
    return Duration.ofMilliseconds(this.delayMillis);
  }
  override getExpression(): string {
    return "";
  }
}

export class FormFieldDelay extends EdgeDelay {
  static className = "FormFieldDelay";

  constructor(public formField: string, public useOrganizationCalendar: boolean) {
    super();
  }

  className() {
    return FormFieldDelay.className;
  }

  isValid(): boolean {
    return !this.isEmpty();
  }

  static empty(): FormFieldDelay {
    return new FormFieldDelay("", true);
  }

  isEmpty(): boolean {
    return this.formField.length === 0;
  }

  formattedToString(): string {
    return "";
  }

  override getMethod(): EdgeDelayMethod {
    return "FormFieldDelay";
  }
  override getDuration(): Duration {
    return Duration.ZERO;
  }
  override getExpression(): string {
    return this.formField;
  }
}

export class ExpressionDelay extends EdgeDelay {
  formattedToString(): string {
    return "";
  }
  static className = "ExpressionDelay";

  constructor(public expressionWithAst: ExpressionWithAst, public useOrganizationCalendar: boolean) {
    super();
  }

  isValid(): boolean {
    return !this.expressionWithAst.isEmpty();
  }

  className() {
    return ExpressionDelay.className;
  }

  static empty(): ExpressionDelay {
    return new ExpressionDelay(ExpressionWithAst.empty(), true);
  }

  override getMethod(): EdgeDelayMethod {
    return "ExpressionDelay";
  }
  override getDuration(): Duration {
    return Duration.ZERO;
  }
  override getExpression(): string {
    return this.expressionWithAst.expression;
  }
}

export class EdgeProperties {
  constructor(public delay: Option<Typed<EdgeDelay>>) {}

  static copy(other: EdgeProperties): EdgeProperties {
    return new EdgeProperties(Option.copy(other.delay).map(edgeDelay => EdgeDelayFactory.copyTyped(edgeDelay)))
  }

  static empty() {
    return new EdgeProperties(None());
  }

  unwrappedOptionEdgeDelay(): Option<EdgeDelay> {
    return this.delay.map(d => Typed.value(d));
  }

  equals(other: EdgeProperties): boolean {
    return this.delay.equals(other.delay);
  }
}

// Reflects server side
export class GridProcessEdge {


  constructor(readonly id:ProcessEdgeId,
              readonly identifier: Option<string>,
              readonly edgeType: EdgeType,
              readonly name: I18nText,
              readonly fromNodeId: ProcessNodeId,
              readonly toNodeId: ProcessNodeId,
              readonly edgePath: GridXYPath,
              readonly pathPriority: Option<number>,
              readonly properties: EdgeProperties,
              readonly outAllowed: Trilean,
              readonly outAllowedExpression: string,
              readonly inAllowed: Trilean,
              readonly inAllowedExpression: string,
              readonly rolesAllowed: Array<ProcessRoleId>,
              readonly terminating: boolean) {}

  static copy(other: GridProcessEdge) {
    return new GridProcessEdge(
      other.id,
      Option.copy(other.identifier),
      EdgeType.copy(other.edgeType),
      I18nText.copy(other.name),
      other.fromNodeId,
      other.toNodeId,
      GridXYPath.copy(other.edgePath),
      Option.copy(other.pathPriority),
      EdgeProperties.copy(other.properties),
      Trilean.copy(other.outAllowed),
      other.outAllowedExpression,
      Trilean.copy(other.inAllowed),
      other.inAllowedExpression,
      other.rolesAllowed.slice(),
      other.terminating);
  }

}

export class EdgeDelayFactory {
  static copy(edgeDelay: EdgeDelay): EdgeDelay {
    return EdgeDelayFactory.copyByType(edgeDelay, edgeDelay.className());
  }

  static copyTyped(edgeDelay: Typed<EdgeDelay>): Typed<EdgeDelay> {
    return Typed.of(EdgeDelayFactory.copyByType(Typed.value(edgeDelay), Typed.className(edgeDelay)));
  }

  static copyByType(edgeDelay: EdgeDelay, className: string): EdgeDelay {
    switch (className) {
      case CaseStartDelay.className: return new CaseStartDelay((<CaseStartDelay>edgeDelay).delayMillis, (<CaseStartDelay>edgeDelay).useOrganizationCalendar);
      case ActionDelay.className: return new ActionDelay((<ActionDelay>edgeDelay).delayMillis, (<ActionDelay>edgeDelay).useOrganizationCalendar);
      case FormFieldDelay.className: return new FormFieldDelay((<FormFieldDelay>edgeDelay).formField, (<FormFieldDelay>edgeDelay).useOrganizationCalendar);
      case ExpressionDelay.className: return new ExpressionDelay(ExpressionWithAst.copy((<ExpressionDelay>edgeDelay).expressionWithAst), (<ExpressionDelay>edgeDelay).useOrganizationCalendar);
      default:throw new Error("Unsupported edge delay class: " + className + ", edge delay: " + JSON.stringify(edgeDelay));
    }
  }
}
