import {$$, $$Element} from "./$$";
import {PositionXY} from "./data-types/PositionXY";
import {None, Option, Some} from "./data-types/Option";
import {mySetTimeoutNoAngular} from "./Scheduler";
import {global} from "./global";
import {runInAngularZone} from "./Angular";
import {required} from "./Code";
import {blurAnyFocusedElement} from "./dom/Elements";

export abstract class DragBehavior<MODEL> {

  private currentDragInProgress = false;
  private currentDragElement: $$Element|null = null;

  private _elements: Array<$$Element>;

  private draggedDistance: number = 0;

  private _internalDragInitiatedOnLeftClick = false;

  private lastParams: {draggedElement: $$Element, eventPosition: PositionXY, model: MODEL}|null = null;

  private static dragInProgress: Option<DragBehavior<any>> = None(); // Right clicking on other draggable element during drag prevented original dragend event to happen

  protected clickDistance = 5;
  protected touchClickDistance = 10;

  protected dragDuration = 250;

  private currentEvent: PointerEvent|null = null;

  private origin: { x: number, y: number }|null = null;
  private originMouse: { x: number, y: number }|null = null;
  private lastPosition: { x: number, y: number }|null = null;

  private startTimestamp: number|undefined = undefined;
  private startDragPosition!: PositionXY;

  private started = false;

  private draggedAlreadyCalled = false;

  protected touch: boolean = false;

  private destroyed = false;

  constructor(elements: $$Element|Array<$$Element>) {
    if (elements instanceof $$Element) {
      this._elements = [elements];
    } else {
      this._elements = elements;
    }
  }

  ctrlPressed() {
    if(this.currentEvent === null) {
      throw new Error("Can be called only during drag event");
    } else {
      try {
        return this.currentEvent.ctrlKey;
      } catch (e) {
        return false;
      }
    }
  }

  altPressed() {
    if(this.currentEvent === null) {
      throw new Error("Can be called only during drag event");
    } else {
      try {
        return this.currentEvent.altKey;
      } catch (e) {
        return false;
      }
    }
  }

  shiftPressed() {
    if(this.currentEvent === null) {
      throw new Error("Can be called only during drag event");
    } else {
      try {
        return this.currentEvent.shiftKey;
      } catch (e) {
        return false;
      }
    }
  }

  /** Should return initial coordinates of elements, that will be adjusted during drag and returned as eventPosition. Null if drag should not be initiated */
  abstract dragOrigin(element: $$Element, eventPosition: PositionXY, model: MODEL): {x:number, y:number}|null;
  abstract dragStarted(element: $$Element, eventPosition: PositionXY, model: MODEL): void;
  abstract dragged(element: $$Element, eventPosition: PositionXY, model: MODEL): void;
  abstract dragEnded(element: $$Element, eventPosition: PositionXY, model: MODEL): void;
  abstract clicked(element: $$Element, eventPosition: PositionXY, model: MODEL): void;

  // override for handling context menu request
  contextMenu?: (element: $$Element, eventPosition: PositionXY, model: MODEL) => void = undefined

  // override for handling touch cancel (e.g. when browser starts to scroll overriding touch drag behavior)
  dragCanceled?: (element: $$Element, eventPosition: PositionXY, model: MODEL) => void = undefined

  protected scrollContainer: Array<$$Element> = [];

  private dragOriginInternal(currentTarget: HTMLElement, x: number, y: number) {
    const eventTarget = $$(currentTarget);
    const d = eventTarget.datum();
    this.origin = this.dragOrigin(eventTarget, new PositionXY(x, y), <MODEL>d);
    this.originMouse = new PositionXY(x, y);
  }

  private internalStartPointer = (event: PointerEvent) => {
    if(event.button === 0 && event.pointerType !== "touch" && this.dragBehaviorNotDisabled(<HTMLElement>event.target)) {
      this.touch = false;
      this.internalStart(event.pointerId, <HTMLElement>event.target, <HTMLElement>event.currentTarget, event.clientX, event.y,
        () => event.stopPropagation(), () => event.preventDefault(), () => event.stopImmediatePropagation());
    }
   // console.log("Internal start pointer", event);
  }

  private internalStartTouch = (event: TouchEvent) => {
    if(this.dragBehaviorNotDisabled(<HTMLElement>event.target)) {
      this.touch = true;

      const touch = event.touches[0];
      this.internalStart(touch.identifier, <HTMLElement>event.target, <HTMLElement>event.currentTarget, touch.clientX, touch.clientY,
        () => {}, () => {}, () => {});
    }
  }

  private dragBehaviorNotDisabled(target: HTMLElement) {
    let el: $$Element|null = $$(target);
    while (el) {
      if(el.classed("no-drag-behavior")) {
        return false;
      }
      const parent: HTMLElement|null = el.getAsElement().parentElement;
      if(parent) {
        el = $$(parent);
      } else {
        el = null;
      }
    }
    return true
  }

  private internalStart(pointerId: number, target: HTMLElement, currentTarget: HTMLElement,
                        x: number, y: number,
                        stopPropagation: () => void, preventDefault: () => void, stopImmediatePropagation: () => void) {

    DragBehavior.dragInProgress.forEach(drag => {
      drag.forceEnd();
    });



    target.setPointerCapture(pointerId);

    global.zone.runOutsideAngular(() => {
      window.document.body.addEventListener("pointermove", this.internalDraggedPointer);
      window.document.body.addEventListener("touchmove", this.internalDraggedTouch);
      window.document.body.addEventListener("pointerup", this.internalEndPointer);
      window.document.body.addEventListener("touchend", this.internalEndTouch);
      window.document.body.addEventListener("touchcancel", this.internalCancelTouch);

      if(this.touch) {
        this.scrollContainer.forEach(container => container.on("scroll", this.internalParentScroll));
      }


    });



    this.dragOriginInternal(currentTarget, x, y);

    if(this.origin) {

      stopPropagation();
      preventDefault();
      stopImmediatePropagation();

      this.startTimestamp = new Date().getTime();

      this.draggedAlreadyCalled = false;

      this._internalDragInitiatedOnLeftClick = false;

      if (this.origin === null) {
        throw new Error("Origin not set");
      } else if (this.originMouse === null) {
        throw new Error("Origin mouse not set");
      } else {

        const d = $$(currentTarget).datum();

        // if (!this.touch) {

        this._internalDragInitiatedOnLeftClick = true;
        this.draggedDistance = 0;
        const eventTarget = $$(currentTarget);
        const eventX = this.origin.x + x - this.originMouse.x;
        const eventY = this.origin.y + y - this.originMouse.y;
        this.lastPosition = {x: eventX, y: eventY};

        (<any>this)["__last_position__"] = {x: eventX, y: eventY};
        DragBehavior.dragInProgress = Some(this);
        this.currentDragInProgress = true;
        this.currentDragElement = eventTarget;

        this.startDragPosition = new PositionXY(eventX, eventY);

        this.lastParams = {draggedElement: eventTarget, eventPosition: this.startDragPosition, model: <MODEL>d};
        // this.dragStarted(eventTarget, position, <MODEL>d);

        mySetTimeoutNoAngular(() => {
          if (!this.destroyed && this.currentDragInProgress && !this.started && this.currentDragElement == eventTarget) { //if this.currentDragElement != eventTarget then next drag occred before this started
            this.started = true;
            runInAngularZone(() => {
              blurAnyFocusedElement();
              this.dragStarted(eventTarget, this.startDragPosition, <MODEL>d);
            });
            mySetTimeoutNoAngular(() => {
              if (!this.draggedAlreadyCalled && !this.destroyed) {
                this.draggedAlreadyCalled = true;
                this.dragged(eventTarget, this.startDragPosition, <MODEL>d);
              }
            }, 10); // 10 to ensure that redraw occured after drag started
          }
        }, this.dragDuration);

        // }
        stopPropagation();
      }
    }
  }

  internalDraggedTouch = (event: TouchEvent) => {
    this.internalDragged(() => {}, () => {}, event.touches[0].clientX, event.touches[0].clientY);
  }

  internalDraggedPointer = (event: PointerEvent) => {
    this.internalDragged(() => event.stopPropagation(), () => event.preventDefault(), event.clientX, event.clientY);
  }

  internalDragged(stopPropagation: () => void, preventDefault: () => void, x: number, y:number) {
    if(this.currentDragInProgress && this.currentDragElement) {
      stopPropagation();
      preventDefault();
      if (this.origin === null) {
        throw new Error("Origin not set");
      } else if (this.originMouse === null) {
        throw new Error("Origin mouse not set");
      } else if (this.lastParams === null) {
        throw new Error("Last params not set");
      } else {
        if (this._internalDragInitiatedOnLeftClick) {

          const d = this.currentDragElement.datum();


          const eventX = this.origin.x + x - this.originMouse.x;
          const eventY = this.origin.y + y - this.originMouse.y;

          if(this.lastPosition !== null) {
            this.draggedDistance += Math.abs(eventX - this.lastPosition.x) + Math.abs(eventY - this.lastPosition.y);
          }


          this.lastPosition = {x: eventX, y: eventY};

          if(this.draggedDistance > (this.touch ? this.touchClickDistance : this.clickDistance) || this.started) {

            if(this.started) {
              const position = new PositionXY(eventX, eventY);
              this.draggedAlreadyCalled = true;
              this.dragged(this.currentDragElement, position, <MODEL>d);
              this.lastParams.eventPosition = position;
            } else {
              this.started = true;
              runInAngularZone(() => {
                if(this.currentDragElement) {
                  blurAnyFocusedElement();
                  this.dragStarted(this.currentDragElement, this.startDragPosition, <MODEL>d);
                } else {
                  throw new Error("Current drag element not set");
                }
              });
              const position = new PositionXY(eventX, eventY);
              const el = this.currentDragElement;
              mySetTimeoutNoAngular(() => {
                if (!this.draggedAlreadyCalled && !this.destroyed) {
                  this.draggedAlreadyCalled = true;
                  this.dragged(el, position, <MODEL>d);
                }
              }, 10); // 10 to ensure that redraw occured after drag started
              this.lastParams.eventPosition = position;
            }
          }


        }
        stopPropagation();
      }
    }
  }

  internalEndPointer = (event: PointerEvent)=> {
    this.internalEnd(false, event.pointerId, <HTMLElement>event.target, () => event.stopPropagation(), () => event.preventDefault());
  }

  internalEndTouch = (event: TouchEvent)=> {
    const touch = event.changedTouches[0];
    this.internalEnd(false, touch.identifier, <HTMLElement>event.target, () => event.stopPropagation(), () => {});
  }

  internalCancelTouch = (event: TouchEvent) => {
    const touch = event.changedTouches[0];
    this.internalEnd(true, touch.identifier, <HTMLElement>event.target, () => event.stopPropagation(), () => {});
  }

  internalParentScroll = (event: Event) => {
    this.internalEnd(true, 0, <HTMLElement>event.target, () => {}, () => {});
  }

  internalEnd(cancel: boolean, pointerId: number, target: HTMLElement, stopPropagation: () => void, preventDefault: () => void) {

    target.releasePointerCapture(pointerId);

    if(this.currentDragInProgress && this.currentDragElement) {

      window.document.body.removeEventListener("pointermove", this.internalDraggedPointer);
      window.document.body.removeEventListener("touchmove", this.internalDraggedTouch);
      window.document.body.removeEventListener("pointerup", this.internalEndPointer);
      window.document.body.removeEventListener("touchend", this.internalEndTouch);

      stopPropagation();
      preventDefault();
      if (this.origin === null) {
        throw new Error("Origin not set");
      } else if (this.originMouse === null) {
        throw new Error("Origin mouse not set");
      } else if (this.lastPosition === null) {
        throw new Error("Last position not set");
      } else if(this.lastParams === null) {
        throw new Error("Last params not set");
      } else {
        if (this._internalDragInitiatedOnLeftClick) {

          const d = this.currentDragElement.datum();

          const eventTarget = this.currentDragElement;
          this.origin = null;
          this.originMouse = null;
          const lastPosition: { x: number; y: number } = this.lastPosition;
          this.lastPosition = null;
          DragBehavior.dragInProgress = None();
          this.currentDragInProgress = false;
          this.currentDragElement = null;
          this.lastParams = null;
          global.zone.run(() => {
            if (!this.started && !cancel) {
              if (this.contextMenu && Date.now() - required(this.startTimestamp, "startTimestamp") > 1000) {
                this.contextMenu(eventTarget, new PositionXY(lastPosition.x, lastPosition.y), <MODEL>d);
              } else {
                blurAnyFocusedElement();
                this.clicked(eventTarget, new PositionXY(lastPosition.x, lastPosition.y), <MODEL>d);
              }
            } else {
              if(cancel && this.dragCanceled) {
                this.dragCanceled(eventTarget, new PositionXY(lastPosition.x, lastPosition.y), <MODEL>d);
              } else {
                this.dragEnded(eventTarget, new PositionXY(lastPosition.x, lastPosition.y), <MODEL>d);
              }
            }
          });

          this.started = false;
          this.startTimestamp = undefined;
        }
        stopPropagation();
      }
    }
  }

  private forceEnd() {
    if(this.lastParams !== null) {
      const params = this.lastParams;
      this.origin = null;
      this.originMouse = null;
      this.lastPosition = null;
      DragBehavior.dragInProgress = None();
      this.lastParams = null;
      this.started = false;
      runInAngularZone(() => {
        this.dragEnded(params.draggedElement, params.eventPosition, params.model);
      });
    } else {
      throw new Error("Last Params not defined");
    }

  }

  onClick = (event: MouseEvent) => {
    event.preventDefault(); // to prevent default behavior of click, e.g. for links
  }

  init() {

    this._elements.forEach(
      (element) => {
        global.zone.runOutsideAngular(() => {
          element.on("pointerdown", this.internalStartPointer);
          element.onPassive("touchstart", this.internalStartTouch);
          element.on("click", this.onClick);
        });

        // element.on("pointermove", event => this.internalDragged(<PointerEvent>event));
        // element.on("pointerup", event => this.internalEnd(<PointerEvent>event));
        // element.on("dragstart", event => this.internalStart(<DragEvent>event));
        // element.on("drag", event => this.internalDragged(<DragEvent>event));
        // element.on("dragend", event => this.internalEnd(<DragEvent>event));
      }
    );

    return this;

  }

  destroy() {

    DragBehavior.dragInProgress.forEach(drag => {
      drag.forceEnd();
    });

    this._elements.forEach(
      (element) => {
        element.off("pointerdown", this.internalStartPointer);
        element.off("touchstart", this.internalStartTouch);
        element.off("click", this.onClick);
        // element.off("dragstart", event => this.internalStart(<DragEvent>event));
        // element.off("drag", event => this.internalDragged(<DragEvent>event));
        // element.off("dragend", event => this.internalEnd(<DragEvent>event));
      }
    );

    window.document.body.removeEventListener("pointermove", this.internalDraggedPointer);
    window.document.body.removeEventListener("touchmove", this.internalDraggedTouch);
    window.document.body.removeEventListener("pointerup", this.internalEndPointer);
    window.document.body.removeEventListener("touchend", this.internalEndTouch);
    window.document.body.removeEventListener("touchcancel", this.internalCancelTouch);

    if(this.touch) {
      this.scrollContainer.forEach(container => container.off("scroll", this.internalParentScroll));
    }

    this.destroyed = true;

  }

  isInPlaceClick() {
    return this.draggedDistance < this.clickDistance;
  }
}
