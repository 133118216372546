<div class="keywords">
  <div *ngFor="let keyword of keywords" class="theme-label-badge" [class.theme-deletable]="deleteEnabled && !readOnly">
    {{keyword}}
    <button *ngIf="deleteEnabled && !readOnly" class="theme-danger" (click)="deleteKeyword(keyword)"><i class="mi-delete"></i></button>
  </div>
</div>
<button class="theme-button-link-icon-small" (click)="addKeyword()" *ngIf="addEnabled && !addInputVisible && !readOnly"><i class="mi-plus"></i>{{'common_add_keyword' | i18n}}</button>
<input *ngIf="addInputVisible" class="theme-input" [(ngModel)]="newKeyword"
       (keydown.enter)="acceptNewKeyWord()" (keydown.tab)="acceptNewKeyWord()" (keydown.escape)="cancelNewKeyword()"
       [placeholder]="'common_add' | i18n" [focus-on-show]="true"/>
<button class="theme-button-cancel-small" *ngIf="addInputVisible" (click)="cancelNewKeyword()">{{'common_cancel' | i18n}}</button>
<button class="theme-button-confirm-small" *ngIf="addInputVisible" (click)="acceptNewKeyWord()">{{'common_ok' | i18n}}</button>

