import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalI18nTextProperty,
  OptionalNumberProperty,
  OptionalStringArrayProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {Option, ScreenId} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {MultiAttachmentDisplayMode} from "./MultiAttachmentInputComponentDefinition";

export class SingleAttachmentDisplayMode {
  constructor(readonly name: string) {}
  static full = new SingleAttachmentDisplayMode("full");
  static minimal = new SingleAttachmentDisplayMode("minimal");

  static of(name: string) {
    switch (name) {
      case SingleAttachmentDisplayMode.full.name: return SingleAttachmentDisplayMode.full;
      case SingleAttachmentDisplayMode.minimal.name: return SingleAttachmentDisplayMode.minimal;
      default: throw new Error("Unsupported name '"+name+"'");
    }
  }
}


export class SingleAttachmentInputComponentRef extends InputComponentRef {
  static className = "SingleAttachmentInputComponentRef";
  className() {
    return SingleAttachmentInputComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  get addRemoveEnabled(): BooleanProperty {return this.properties.getBooleanProperty("addRemoveEnabled", this.defaultPropertiesProvider)};

  static copy(other: SingleAttachmentInputComponentRef): SingleAttachmentInputComponentRef {
    return new SingleAttachmentInputComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("SingleAttachmentInputComponentRef",
    (name: string) => {switch (name) {
    case "addRemoveEnabled": return BooleanProperty.ofTrue();
    default: return null;
  }},
    DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = SingleAttachmentInputComponentRef.DEFAULT_PROPERTIES;
}

export class SingleAttachmentInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  static className = "SingleAttachmentInputComponentDefinition";
  className() {
    return SingleAttachmentInputComponentDefinition.className;
  }
  typeName() {
    return "SingleAttachmentInput";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,SingleAttachmentInputComponentDefinition.DEFAULT_PROPERTIES);
  }

  get displayMode(): StringProperty {return this.properties.getStringProperty("displayMode", this.defaultPropertiesProvider)};

  get anchorVisible(): BooleanProperty {return this.properties.getBooleanProperty("anchorVisible", this.defaultPropertiesProvider)};

  get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)}
  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  get allowedExtensions(): OptionalStringArrayProperty {return this.properties.getOptionalStringArrayProperty("allowedExtensions", this.defaultPropertiesProvider)}

  get maxFileSize(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("maxFileSize", this.defaultPropertiesProvider)}

  get addRemoveEnabled(): BooleanProperty {return this.properties.getBooleanProperty("addRemoveEnabled", this.defaultPropertiesProvider)}

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: SingleAttachmentInputComponentDefinition): SingleAttachmentInputComponentDefinition {
    return new SingleAttachmentInputComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [SingleAttachmentInputComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("SingleAttachmentInputComponentDefinition",
    (name: string) => {switch (name) {
      case "displayMode": return StringProperty.of(MultiAttachmentDisplayMode.full.name);
      case "placeholder": return OptionalI18nTextProperty.disabled();
      case "maxFileSize": return OptionalNumberProperty.disabled(10485760); // 10MB
      case "innerShadow": return OptionalBooleanProperty.disabled(true);
      case "anchorVisible": return BooleanProperty.ofTrue();
      case "allowedExtensions": return OptionalStringProperty.disabled("");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);
}
