import {AggregateId} from "@utils";

// Reflects server side

export class SelfCreateAdmin {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly email: string,
              readonly password: string,
              readonly repeatPassword: string,
              readonly code: string) {}
}


export class CreateAdminWithoutPassword {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly email: string) {}
}

/**
 * Service responsible for user registration.
 */

export class ConfirmPersonEmail {

  constructor(readonly aggregateId: AggregateId,
              readonly activationCode: string) {}
}

export class ConfirmPersonEmailByAdmin {
  constructor(readonly aggregateId: AggregateId) {}
}

export class DeactivatePersonAccountByAdmin {
  constructor(readonly aggregateId: AggregateId) {}
}

export class ActivatePersonAccountByAdmin {
  constructor(readonly aggregateId: AggregateId) {}
}

export class EmailConfirmResponse {
  constructor(readonly firstName: string,
              readonly lastName: string,
              readonly userEmail: string) {}
}

export class EmailConfirmWithPasswordSet {
  constructor(readonly personIdString: string,
              readonly activationCode: string,
              readonly password: string,
              readonly repeatPassword: string) {}
}

export class RecoverPasswordUI {
  constructor(readonly email: string,
              readonly organization: AggregateId|undefined = undefined) {}
}

export class RecoverPasswordUIResponse {
}

export class RecoverPasswordByAdminUI {
  constructor(readonly email: string) {}
}

export class RecoverPasswordByAdminUIResponse {
  constructor(readonly resetUrlSuffix: string) {}
}

export class ResetPassword {
  constructor(readonly aggregateId: AggregateId,
              readonly recoveryCode: string,
              readonly password: string,
              readonly repeatPassword: string) {}
}
