import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Trilean} from "@utils";

@Component({
  selector: 'my-trilean',
  templateUrl: './trilean.component.html'
})
export class TrileanComponent {

  private _value!: Trilean;
  get value(): Trilean {return this._value;}
  @Input() set value(value: Trilean) {this._value = value;this.onModelChanged();}

  @Output() valueChange = new EventEmitter<Trilean>();
  @Input() disabled: boolean = false;
  @Input() label?: string;
  @Input() invalid: boolean = false;
  @Input() small: boolean = false;
  @Input() clickInterval: number = 0;

  @Input() focusOnShow: boolean = false;
  @Input() myTabindex: number|null = null;

  checked: boolean = false;
  unknown: boolean = false;

  private lastToggle = 0;

  private trileanOfState() {
    if(this.checked && !this.unknown) {
      return Trilean.TRUE;
    } else if(this.unknown && !this.checked) {
      return Trilean.UNKNOWN;
    } else if(!this.checked && !this.unknown) {
      return Trilean.FALSE;
    } else {
      throw new Error("Invalid state");
    }
  }

  onModelChanged() {
    if (this._value.isFalse()) {
      this.checked = false;
      this.unknown = false;
    } else if (this._value.isTrue()) {
      this.checked = true;
      this.unknown = false;
    } else {
      this.checked = false;
      this.unknown = true;
    }
  }

  onClicked() {
    const now = new Date().getTime();

    if(now - this.lastToggle > this.clickInterval) {
      if(!this.disabled) {
        this.nextState();
        this._value = this.trileanOfState();
        this.valueChange.emit(this._value);
      }
      this.lastToggle = now;
    }
  };

  private nextState() {
    if(this.checked && !this.unknown) {
      this.checked = false;
      this.unknown = true;
    } else if(this.unknown && !this.checked) {
      this.checked = false;
      this.unknown = false;
    } else if(!this.checked && !this.unknown) {
      this.checked = true;
      this.unknown = false;
    } else {
      throw new Error("Invalid state");
    }
  }
}
