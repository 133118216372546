import {EventBus, Option} from "@utils";

export class ToastrEventBus extends EventBus {
  infoAdded(message: string, tag: string, timeout: Option<number>) {}
  warningAdded(message: string, tag: string, timeout: Option<number>) {}
  errorAdded(message: string, tag: string, timeout: Option<number>) {}
  successAdded(message: string, tag: string, timeout: Option<number>) {}

  clearOldMessagesSent() {}
  clearMessagesWithTagSent(tag: string) {}
}
