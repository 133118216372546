import {CalendarComponentViewModel} from "./CalendarComponentViewModel";
import {__, LocalDate, LocalDateTime, Option, required} from "@utils";
import {
  MonthlyCalendarDayViewModel,
  MonthlyCalendarSingleEntry, MonthlyCalendarViewModel,
  MonthlyCalendarWeekViewModel
} from "./MonthlyCalendarViewModel";
import {CalendarEntryModel, CalendarEventBus, CalendarHelper} from "@shared";

export class WeeklyCalendarSingleEntry {
  readonly mocks: Array<WeeklyCalendarSingleEntry> = [];
  public parent: WeeklyCalendarSingleEntry|null = null;

  constructor(
    readonly model: CalendarEntryModel|null,
    public index: number,
    readonly date: LocalDate|null,
    readonly startHalfHour: number,
    public endHalfHour: number,
    public span: number,
    public cssHeight: string,
    readonly name: string,
    readonly color: string,
    readonly inverted: boolean,
    readonly placeholder: boolean,
    public columns: number,
    public selected: boolean
  ) {
  }

  static mock(parent: WeeklyCalendarSingleEntry | null) {
    const mock = new WeeklyCalendarSingleEntry(null, -1, null, 0, 0, 0, "calc(100% + 1px)", "", "transparet", false, true, 1, false);
    mock.parent = parent;
    return mock;
  }
}

export class WeeklyCalendarHalfHourViewModel {
  static MAXIMUM_VISIBLE_ENTRIES = 4;

  readonly entries: Array<WeeklyCalendarSingleEntry> = [];
  readonly visibleEntries: Array<WeeklyCalendarSingleEntry> = [];

  readonly id = CalendarComponentViewModel.nextPeriodId();
  public selected: boolean = false;


  constructor(
    readonly halfHour: number,
    readonly working: boolean,
    readonly endOfFullHour: boolean,
    public columns: number) {
  }

  pushEntryToEmptySpace(entry: WeeklyCalendarSingleEntry): void {
    for (let i = 0; i < this.visibleEntries.length; i++) {
      if (this.visibleEntries[i] === undefined) {
        this.visibleEntries[i] = entry;
        entry.index = i;
        return;
      }
    }
    if (this.visibleEntries.length < WeeklyCalendarHalfHourViewModel.MAXIMUM_VISIBLE_ENTRIES) {
      this.visibleEntries.push(entry);
      entry.index = this.visibleEntries.length - 1;
    }
    this.entries.push(entry);
  }

  pushMockToIndex(entryPart: WeeklyCalendarSingleEntry): void {
    if (entryPart.index < WeeklyCalendarHalfHourViewModel.MAXIMUM_VISIBLE_ENTRIES) {
      if (this.visibleEntries[entryPart.index]) {
        throw new Error("Entry occupied already");
      } else {
        this.visibleEntries[entryPart.index] = WeeklyCalendarSingleEntry.mock(entryPart);
        entryPart.mocks.push(this.visibleEntries[entryPart.index]);
      }
    }
  }


  fillEmptyWithMocks() {
    for (let i = 0; i < this.visibleEntries.length; i++) {
      if (!this.visibleEntries[i]) {
        this.visibleEntries[i] = WeeklyCalendarSingleEntry.mock(null);
      }
    }
  }
}

export class WeeklyCalendarDayViewModel {
  constructor(
    readonly date: LocalDate,
    readonly dayName: string,
    readonly halfHours: Array<WeeklyCalendarHalfHourViewModel>) {
  }

}

export class WeeklyCalendarDayHeader {
  constructor(readonly date: LocalDate,
              readonly day: number,
              readonly name: string,
              public entries: Array<MonthlyCalendarSingleEntry>) {
  }
}

export class WeeklyCalendarHourHeader {
  constructor(readonly hour: number,
              readonly name: string) {
  }
}

export class WeeklyCalendarViewModel {

  static START_OF_WEEK = 1; // monday, might be sunday also

  today: LocalDate = LocalDate.nowDate();
  year: number;
  week: number;
  weekFirstDay: LocalDate;
  weekLastDay: LocalDate;
  days: Array<WeeklyCalendarDayViewModel> = [];

  weekName: string = "";
  dayHeader: Array<WeeklyCalendarDayHeader> = [];
  hourHeader: Array<WeeklyCalendarHourHeader> = [];

  entries: Array<CalendarEntryModel> = [];

  // static colors: Array<string> = ["rgb(92, 107, 192)", "rgb(255, 235, 59)", "rgb(102, 187, 106)", "rgb(126, 87, 194)", "rgb(255, 112, 67)", "rgb(41, 182, 246)", "rgb(255, 202, 40)", "rgb(55, 173, 150)",
  //   "rgb(171, 71, 188)", "rgb(239, 83, 80)", "rgb(38, 198, 218)", "rgb(255, 167, 38)", "rgb(156, 204, 101)", "rgb(236, 64, 122)", "rgb(236, 64, 122)", "rgb(236, 64, 122)"];

  static colors: Array<string> = ["#5c6bc0",
    "#ffeb3b",
    "#66bb6a",
    "#7e57c2",
    "#ff7043",
    "#29b6f6",
    "#ffca28",
    "#37ad96",
    "#ab47bc",
    "#ef5350",
    "#26c6da",
    "#ffa726",
    "#9ccc65",
    "#ec407a",
    "#f37b50",
    "#d4e157"];
  static inverted: Array<boolean> = [true, false, true, true, true, true, false, true, true, true, false, true, true, true, true, false];


  static daysNames = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];


  constructor(readonly parent: CalendarComponentViewModel,
              readonly eventBus: CalendarEventBus,
              readonly onSelected: (model: CalendarEntryModel) => void) {
    this.year = this.today.year;
    this.week = LocalDate.getWeekNumber(this.today);

    this.weekFirstDay = this.today.plusDays(-this.today.weekDay() + WeeklyCalendarViewModel.START_OF_WEEK);
    this.weekLastDay = this.weekFirstDay.plusDays(6);

    this.updateView();

    eventBus.on(eventBus.entrySelected, (selectedEntry: CalendarEntryModel) => this.onEntrySelected(selectedEntry));
    eventBus.on(eventBus.periodsSelected, (from: number, to: number) => this.onPeriodsSelected(from, to));
  }

  private onEntrySelected(selectedEntry: CalendarEntryModel) {
    this.days.forEach(day => {
      day.halfHours.forEach(halfHour => {
        halfHour.visibleEntries.forEach(entry => {
          entry.selected = entry.model === selectedEntry;
        });
      });
    });
  }

  previousWeek() {
    this.weekFirstDay = this.weekFirstDay.plusDays(-7);
    this.weekLastDay = this.weekFirstDay.plusDays(6);
    this.updateView();
  }

  nextWeek() {
    this.weekFirstDay = this.weekFirstDay.plusDays(7);
    this.weekLastDay = this.weekFirstDay.plusDays(6);
    this.updateView();
  }

  static weekName(from: LocalDate, to: LocalDate): string {
    if (from.month == to.month) {
      return from.day + " - " + to.formattedWords();
    } else if (from.year == to.year) {
      return from.formattedWordsWithoutYear() + " - " + to.formattedWords();
    } else {
      return from.formattedWords() + " - " + to.formattedWords();
    }
  }

  private updateView() {
    this.weekName = WeeklyCalendarViewModel.weekName(this.weekFirstDay, this.weekLastDay);


    this.hourHeader = [];
    for (let h = 0; h < 24; h++) {
      this.hourHeader.push(new WeeklyCalendarHourHeader(h, this.formatHour(h)));
    }

    this.dayHeader = [];
    for (let d = 0; d < 7; d++) {
      this.dayHeader.push(new WeeklyCalendarDayHeader(this.weekFirstDay.plusDays(d), this.weekFirstDay.plusDays(d).day, WeeklyCalendarViewModel.daysNames[d + WeeklyCalendarViewModel.START_OF_WEEK], []));
    }

    this.days = [];
    for (let d = 0; d < 7; d++) {

      let halfHours: Array<WeeklyCalendarHalfHourViewModel> = [];
      for (let hh = 0; hh < 48; hh++) {
        const weekDay = (d + WeeklyCalendarViewModel.START_OF_WEEK) % 7;
        halfHours.push(new WeeklyCalendarHalfHourViewModel(hh, weekDay != 0 && weekDay != 6 && hh >= 18 && hh < 36, hh % 2 == 1, 1));
      }

      this.days.push(new WeeklyCalendarDayViewModel(this.weekFirstDay.plusDays(d), WeeklyCalendarViewModel.daysNames[d + WeeklyCalendarViewModel.START_OF_WEEK], halfHours));
    }


    const sortedEntriesSingleDayWithTime = __(this.entries).filter(e => (e.to === null || e.from.date.isEqual(e.to.date))).sort((a, b) => a.from.isEqual(b.from) ? 0 : (a.from.isBefore(b.from) ? -1 : 0));
    const sortedEntriesDayHeader = __(this.entries).filterNot(e => (e.to === null  || e.from.date.isEqual(e.to.date))).sort((a, b) => a.from.isEqual(b.from) ? 0 : (a.from.isBefore(b.from) ? -1 : 0));


    const weekModel = [new MonthlyCalendarWeekViewModel(this.days.map(d => {
      const weekDay = d.date.weekDay();
      return new MonthlyCalendarDayViewModel(d.date, false, false, weekDay == 0 || weekDay == 6, [])
    }))];
    MonthlyCalendarViewModel.prepareWeeks(sortedEntriesDayHeader, weekModel, this.parent.selectedValue);
    weekModel[0].days.forEach(d => {

      const day = __(this.dayHeader).find(dd => dd.date.isEqual(d.date));
      day.get().entries = d.entries;

    });


    this.prepareHours(sortedEntriesSingleDayWithTime, this.days);


  }

  prepareHours(sortedEntries: Array<CalendarEntryModel>, days: Array<WeeklyCalendarDayViewModel>) {
    sortedEntries.forEach(entry => {

      const [cssColor, inverted]: [string, boolean] = Option.of(entry.entryType).map(r => r.valueToSimpleString().toLowerCase()).map(entryType => {
        const id = (CalendarHelper.hashCode(entryType) % 16 + 16) % 16; // hashCode can be negative
        return <[string, boolean]>[CalendarHelper.colors[id], CalendarHelper.inverted[id]];
      }).getOrElse(<[string, boolean]>["#bdefe5", false]);

      const entryName = entry.from.time.formattedToMinutes() + " " + entry.label;


      const entryParts: Array<WeeklyCalendarSingleEntry> = [];

      const from = entry.from;
      const to = entry.to ? entry.to : from.plusMillis(1800000);  // 30 minutes in millis

      let currentTime = from;

      let entryPart = new WeeklyCalendarSingleEntry(entry, -1, from.date, WeeklyCalendarViewModel.toHalfHour(from), WeeklyCalendarViewModel.toHalfHour(to), 1, "calc(100% + 1px)", entryName, cssColor, inverted, false, 1, this.parent.selectedValue.exists(s => s.isEqual(entry.value)));
      entryParts.push(entryPart);
      console.log("appending", entryPart);
      while (currentTime.isBefore(to)) {
        currentTime = currentTime.plusMillis(1800000); // 30 minutes in millis
        entryPart.span++;
        entryPart.endHalfHour++;
        entryPart.cssHeight = "calc(" + 100 * entryPart.span + "% + " + entryPart.span + "px)";
      }

      entryParts.forEach(entryPart => {
        days.forEach(day => {
          if (entryPart.date !== null && day.date.isEqual(entryPart.date)) {
            day.halfHours.forEach(halfHour => {
              if (halfHour.halfHour == entryPart.startHalfHour) {
                halfHour.pushEntryToEmptySpace(entryPart);
              } else if (halfHour.halfHour > entryPart.startHalfHour && halfHour.halfHour <= entryPart.endHalfHour) {
                halfHour.pushMockToIndex(entryPart);
              }
            });
          }
        })

      });

    });

    days.forEach(day => {
      day.halfHours.forEach(halfHour => {
        halfHour.fillEmptyWithMocks();
        halfHour.columns = Math.max(halfHour.visibleEntries.length, halfHour.columns);
        halfHour.visibleEntries.forEach(e => {
          e.columns = Math.max(halfHour.columns, e.columns);
          if (e.parent) {
            e.parent.columns = Math.max(e.parent.columns, e.columns);
          }
          e.mocks.forEach(m => {
            m.columns = Math.max(m.columns, e.columns);
            if (m.parent) {
              m.parent.columns = Math.max(m.parent.columns, m.columns);
            }
          });
        });
      });
    });

    days.forEach(day => {
      day.halfHours.forEach(halfHour => {
        halfHour.columns = Math.max(halfHour.columns, __(halfHour.visibleEntries.map(e => e.columns)).maxOrDefault(1));
      });
    });
  }

  static toHalfHour(date: LocalDateTime): number {
    return date.time.hour * 2 + (date.time.minute >= 30 ? 1 : 0);
  }


  formatHour(hour: number): string {
    if (hour < 10) {
      return "0" + hour;
    } else {
      return "" + hour;
    }
  }


  updateEntries(entries: Array<CalendarEntryModel>) {
    this.entries = entries;
    this.updateView();
  }

  entrySelected(entry: WeeklyCalendarSingleEntry) {
    this.onSelected(required(entry.model, "model"));

    entry.selected = true;
  }

  private onPeriodsSelected(from: number, to: number) {

    this.days.forEach(day => {
      day.halfHours.forEach(halfHour => {
        halfHour.selected = halfHour.id >= from && halfHour.id <= to;
      });
    })

  }
}
