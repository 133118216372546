import {AggregateId} from "../cqrs/AggregateId";
import {randomAlphanumeric} from "../RandomUtil";

export class ReportId {

  private static cache: Map<string, ReportId> = new Map<string, ReportId>();

  constructor(readonly id: AggregateId){}
  static copy(other: ReportId) {
    return new ReportId(AggregateId.copy(other.id));
  }

  static of(id: string|ReportId|AggregateId): ReportId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id.id);

    const fromCache = ReportId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ReportId(new AggregateId(idString));
      ReportId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return this.id;
  }
}


export class DatastoreId {

  private static cache: Map<string, DatastoreId> = new Map<string, DatastoreId>();

  constructor(readonly id: AggregateId){}
  static copy(other: DatastoreId) {
    return new DatastoreId(AggregateId.copy(other.id));
  }

  static of(id: string|DatastoreId|AggregateId): DatastoreId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id.id);

    const fromCache = DatastoreId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new DatastoreId(new AggregateId(idString));
      DatastoreId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return this.id;
  }
}

export class ConstantId {
  private readonly _ConstantId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, ConstantId> = new Map<string, ConstantId>();

  static EMPTY = new ConstantId("");

  private constructor(readonly id: string) {}

  static of(id: ConstantId|AggregateId|string): ConstantId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = ConstantId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ConstantId(idString);
      ConstantId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static copy(id: ConstantId) {
    return ConstantId.of(id.id);
  }

  isEmpty() {
    return this == ConstantId.EMPTY;
  }
}




export type ApplicationIdentifier = string;

export class ApplicationId {
  private readonly _ApplicationId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, ApplicationId> = new Map<string, ApplicationId>();

  static EMPTY = new ApplicationId("");
  static tasks: ApplicationId = ApplicationId.of("2shdfytryrm2");

  private constructor(readonly id: string) {}

  static of(id: ApplicationId|AggregateId|string): ApplicationId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = ApplicationId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ApplicationId(idString);
      ApplicationId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static copy(id: ApplicationId) {
    return ApplicationId.of(id.id);
  }

  isEmpty() {
    return this == ApplicationId.EMPTY;
  }
}


export class ProcessReleaseId {

  private readonly _ProcessReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, ProcessReleaseId> = new Map<string, ProcessReleaseId>();

  private constructor(readonly id: string) {
  }

  static of(id: string|ProcessReleaseId|AggregateId): ProcessReleaseId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id);

    const fromCache = ProcessReleaseId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ProcessReleaseId(idString);
      ProcessReleaseId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }
}

export class ProcessStart {
  constructor(readonly processId: ProcessId,
              readonly nodeId: number) {}

  static copy(other: ProcessStart) {
    return new ProcessStart(ProcessId.of(other.processId), other.nodeId);
  }
}

export class ProcessId {

  private readonly _ProcessId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, ProcessId> = new Map<string, ProcessId>();

  private constructor(readonly id: AggregateId) {
  }

  static of(id: string|ProcessId|AggregateId): ProcessId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id.id);

    const fromCache = ProcessId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ProcessId(new AggregateId(idString));
      ProcessId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id.id);
  }

  isAdHocTask() {
    return this.id.id === "7r81zurqox2i"; //Constants.taskProcessId.id.id;
  }
}


export class OrganizationId {
  private readonly _OrganizationId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, OrganizationId> = new Map<string, OrganizationId>();

  private constructor(readonly id: string) {
  }

  static copy(id: OrganizationId): OrganizationId {
    return OrganizationId.of(id.id);
  }

  static of(id: string|OrganizationId|AggregateId): OrganizationId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id);

    const fromCache = OrganizationId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new OrganizationId(idString);
      OrganizationId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

}

export class GroupId {

  private readonly _GroupId: undefined; // force TypeScript to check types (workaround for duck typing)

  constructor(readonly id: AggregateId) {
  }

  static copy(other: GroupId): GroupId {
    return new GroupId(AggregateId.copy(other.id));
  }
}

export class DepartmentId {

  private readonly _DepartmentId: undefined; // force TypeScript to check types (workaround for duck typing)

  constructor(readonly id: AggregateId) {
  }

  static copy(other: DepartmentId): DepartmentId {
    return new DepartmentId(AggregateId.copy(other.id));
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new DepartmentId(aggregateId);
  }
}

export class WidgetId {
  private readonly _WidgetId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new WidgetId(aggregateId.id);
  }

  static copy(other: WidgetId) {
    return new WidgetId(other.id);
  }
}


export class WidgetReleaseId {
  private readonly _WidgetReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new WidgetReleaseId(aggregateId.id);
  }

  static copy(other: WidgetReleaseId) {
    return new WidgetReleaseId(other.id);
  }
}

export interface RepositoryNodeId {
  id: AggregateId;
}

export class DirectoryId implements RepositoryNodeId {
  private readonly _Directory: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: AggregateId) {
  }
}

export class FileId implements RepositoryNodeId {
  private readonly _FileId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: AggregateId) {
  }

  equals(other: FileId) {
    return FileId.equals(this, other);
  }

  static equals(fileId: FileId, other: FileId) {
    return fileId.id.id === other.id.id;
  }

  static copy(other: FileId) {
    return new FileId(other.id);
  }
}

export class DocumentTypeId {
  constructor(readonly id: AggregateId) {
  }

  static copy(other: DocumentTypeId) {
    return new DocumentTypeId(AggregateId.copy(other.id));
  }

  static of(documentTypeId: string|AggregateId) {
    if(documentTypeId instanceof AggregateId) {
      return new DocumentTypeId(documentTypeId);
    } else {
      return new DocumentTypeId(new AggregateId(documentTypeId));
    }
  }
}

export class DocumentPropertyId {
  constructor(readonly id: AggregateId) {
  }

  static copy(other: DocumentPropertyId) {
    return new DocumentPropertyId(AggregateId.copy(other.id));
  }
}


export class FunctionsId {
  private readonly _FunctionsId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, FunctionsId> = new Map<string, FunctionsId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: FunctionsId|AggregateId|string): FunctionsId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = FunctionsId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new FunctionsId(idString);
      FunctionsId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: FunctionsId) {
    return FunctionsId.of(other.id);
  }

  equals(other: FunctionsId) {
    return this.id === other.id;
  }

}


export class FunctionsReleaseId {
  private readonly _FunctionsReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new FunctionsReleaseId(aggregateId.id);
  }

  static copy(other: FunctionsReleaseId) {
    return new FunctionsReleaseId(other.id);
  }
}

export class MailboxId {
  private readonly _MailboxId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, MailboxId> = new Map<string, MailboxId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: MailboxId|AggregateId|string): MailboxId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = MailboxId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new MailboxId(idString);
      MailboxId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: MailboxId) {
    return MailboxId.of(other.id);
  }

  equals(other: MailboxId) {
    return this.id === other.id;
  }
}


export class EmailId {
  private readonly _EmailId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new EmailId(aggregateId.id);
  }

  static copy(other: EmailId) {
    return new EmailId(other.id);
  }

  equals(other: EmailId) {
    return this.id === other.id;
  }
}

export class VariableId {
  private readonly _VariableId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  static copy(other: VariableId) {
    return new VariableId(other.id);
  }
}

export class ObjectId {
  private readonly _ObjectId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  static copy(other: ObjectId) {
    return new ObjectId(other.id);
  }

  static next() {
    return new ObjectId(randomAlphanumeric(16));
  }
}

export class BusinessEntityTypeId {
  private readonly _BusinessEntityTypeId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(aggregateId: AggregateId) {
    return new BusinessEntityTypeId(aggregateId.id);
  }

  static copy(other: BusinessEntityTypeId) {
    return new BusinessEntityTypeId(other.id);
  }
}

export class BusinessEntityTypeReleaseId {
  private readonly _BusinessEntityTypeReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(aggregateId: AggregateId) {
    return new BusinessEntityTypeReleaseId(aggregateId.id);
  }

  static copy(other: BusinessEntityTypeReleaseId) {
    return new BusinessEntityTypeReleaseId(other.id);
  }
}

export class BusinessEntityIdWithType {
  constructor(readonly typeId: string, readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(typeId: BusinessEntityTypeId, id: BusinessEntityId) {
    return new BusinessEntityIdWithType(typeId.id, id.id);
  }

  static copy(other: BusinessEntityIdWithType) {
    return new BusinessEntityIdWithType(other.typeId, other.id);
  }

  serialize() {
    return this.typeId+"|"+this.id;
  }
}


export class BusinessEntityId {
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(aggregateId: AggregateId) {
    return new BusinessEntityId(aggregateId.id);
  }

  static copy(other: BusinessEntityId) {
    return new BusinessEntityId(other.id);
  }
}

export class DatabaseConnectionId {
  private readonly _DatabaseConnectionId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, DatabaseConnectionId> = new Map<string, DatabaseConnectionId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: DatabaseConnectionId|AggregateId|string): DatabaseConnectionId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = DatabaseConnectionId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new DatabaseConnectionId(idString);
      DatabaseConnectionId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: DatabaseConnectionId) {
    return DatabaseConnectionId.of(other.id);
  }

  equals(other: DatabaseConnectionId) {
    return this.id === other.id;
  }

}


export class IntegrationActionsReleaseId {
  private readonly _IntegrationActionsReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new IntegrationActionsReleaseId(aggregateId.id);
  }

  static copy(other: IntegrationActionsReleaseId) {
    return new IntegrationActionsReleaseId(other.id);
  }
}

export class ScreenReleaseId {
  private readonly _ScreenReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: AggregateId|string) {
    if(id instanceof AggregateId) {
      return new ScreenReleaseId(id.id);
    } else {
      return new ScreenReleaseId(id);
    }
  }

  static copy(other: ScreenReleaseId) {
    return new ScreenReleaseId(other.id);
  }

}

export class AutomaticActionId {
  private readonly _AutomaticActionId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {
  }

  static copy(other: AutomaticActionId) {
    return new AutomaticActionId(other.id);
  }
}

export class AutomaticActionRefId {
  private readonly _AutomaticActionRefId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {
  }

  static copy(other: AutomaticActionRefId) {
    return new AutomaticActionRefId(other.id);
  }
}


export class ScreenInstanceId {
  private readonly _ScreenInstanceId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  static copy(other: ScreenInstanceId) {
    return new ScreenInstanceId(other.id);
  }
}

export class WidgetInstanceId {
  private readonly _WidgetInstanceId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  static copy(other: WidgetInstanceId) {
    return new WidgetInstanceId(other.id);
  }
}

export class ReportReleaseId {
  private readonly _ReportReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new ReportReleaseId(aggregateId.id);
  }

  static copy(other: ReportReleaseId) {
    return new ReportReleaseId(other.id);
  }
}


export class ApplicationComponentType {
  static process = "process";
  static entity = "entity";
  static screen = "screen";
  static dictionary = "dictionary";
}

export class ApplicationComponentId {
  constructor(readonly id: string,
              readonly tpe: string) {
  }

  static copy(other: ApplicationComponentId) {
    return new ApplicationComponentId(other.id, other.tpe);
  }

  static ofScreenId(screenId: ScreenId) {
    return new ApplicationComponentId(screenId.id, ApplicationComponentType.screen);
  }
}

export type ScreenIdentifier = string;

export class ScreenId {
  private readonly _ScreenId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, ScreenId> = new Map<string, ScreenId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: ScreenId|AggregateId|string): ScreenId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = ScreenId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new ScreenId(idString);
      ScreenId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: ScreenId) {
    return ScreenId.of(other.id);
  }

  equals(other: ScreenId) {
    return this.id === other.id;
  }

}


export class RemoteOrganizationId {
  private readonly _RemoteOrganizationId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, RemoteOrganizationId> = new Map<string, RemoteOrganizationId>();

  private constructor(readonly id: string) {
  }

  static of(id: string|RemoteOrganizationId|AggregateId): RemoteOrganizationId {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id);

    const fromCache = RemoteOrganizationId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new RemoteOrganizationId(idString);
      RemoteOrganizationId.cache.set(idString, id);
      return id;
    }
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

}


export class FormElementId {
  private readonly _FormElementId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}

  static increment(id: FormElementId) {
    return new FormElementId(id.id + 1);
  }

  static copy(other: FormElementId): FormElementId {
    return new FormElementId(other.id);
  }
}


export class FormElementRefId {

  private readonly _FormElementRefId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}

  static increment(id: FormElementRefId) {
    return new FormElementRefId(id.id + 1);
  }

  static copy(other: FormElementRefId) {
    return new FormElementRefId(other.id);
  }
}


export class DocumentationId {
  private readonly _DocumentationId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string){}
  toAggregateId() {
    return new AggregateId(this.id);
  }
  static fromAggregateId(aggregateId: AggregateId) {
    return new DocumentationId(aggregateId.id);
  }
  static copy(other: DocumentationId) {
    return new DocumentationId(other.id);
  }
}

export class DocumentationPageId {
  private readonly _DocumentationPageId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string){}
  toAggregateId() {
    return new AggregateId(this.id);
  }
  static fromAggregateId(aggregateId: AggregateId) {
    return new DocumentationPageId(aggregateId.id);
  }
  static copy(other: DocumentationPageId) {
    return new DocumentationPageId(other.id);
  }
}


export class DocumentationPageReleaseId {
  private readonly _DocumentationPageReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string){}
  toAggregateId() {
    return new AggregateId(this.id);
  }
  static fromAggregateId(aggregateId: AggregateId) {
    return new DocumentationPageReleaseId(aggregateId.id);
  }
  static copy(other: DocumentationPageReleaseId) {
    return new DocumentationPageReleaseId(other.id);
  }
}




export class DataSourceId {
  private readonly _DataSourceId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, DataSourceId> = new Map<string, DataSourceId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: DataSourceId|AggregateId|string): DataSourceId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = DataSourceId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new DataSourceId(idString);
      DataSourceId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: DataSourceId) {
    return DataSourceId.of(other.id);
  }

  equals(other: DataSourceId) {
    return this.id === other.id;
  }

}


export class DataSourceReleaseId {
  private readonly _DataSourceReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new DataSourceReleaseId(aggregateId.id);
  }

  static copy(other: DataSourceReleaseId) {
    return new DataSourceReleaseId(other.id);
  }
}


export class DataVisualizationId {
  private readonly _DataVisualizationId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, DataVisualizationId> = new Map<string, DataVisualizationId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: DataVisualizationId|AggregateId|string): DataVisualizationId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = DataVisualizationId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new DataVisualizationId(idString);
      DataVisualizationId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: DataVisualizationId) {
    return DataVisualizationId.of(other.id);
  }

  equals(other: DataVisualizationId) {
    return this.id === other.id;
  }

}


export class DataVisualizationReleaseId {
  private readonly _DataVisualizationReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new DataVisualizationReleaseId(aggregateId.id);
  }

  static copy(other: DataVisualizationReleaseId) {
    return new DataVisualizationReleaseId(other.id);
  }
}


export class DashboardId {
  private readonly _DashboardId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, DashboardId> = new Map<string, DashboardId>();
  constructor(readonly id: string) {}

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static of(id: DashboardId|AggregateId|string): DashboardId {
    const idString = typeof id === 'string' ? id : id.id;

    const fromCache = DashboardId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new DashboardId(idString);
      DashboardId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: DashboardId) {
    return DashboardId.of(other.id);
  }

  equals(other: DashboardId) {
    return this.id === other.id;
  }

}


export class DashboardReleaseId {
  private readonly _DashboardReleaseId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }

  static fromAggregateId(aggregateId: AggregateId) {
    return new DashboardReleaseId(aggregateId.id);
  }

  static copy(other: DashboardReleaseId) {
    return new DashboardReleaseId(other.id);
  }
}
