import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel
} from "../..";
import {I18nText, None, NoneSingleton, Option, Some, VariableId} from "@utils";
import {SingleCheckboxComponentRefState, SingleCheckboxComponentState} from "./SingleCheckboxComponentState";
import {BooleanVariable} from "@shared-model";
import {
  CssBuilder,
  SingleCheckboxComponentDefinition, SingleCheckboxComponentRef
} from "@screen-common";

export class SingleCheckboxComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "SingleCheckbox";

  private threeStates: boolean = false;
  public checked: boolean = false;
  public unknown: boolean = false;

  public tooltip: Option<string> = NoneSingleton;
  public valueLabel: string = "";
  public css: string = "";
  public cssClasses: string = "";
  public required: boolean = false;
  public invalid: boolean = false; //TODO

  public requiredIconVisible = false;

  public iconBackgroundColorCss: string|null = null;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: SingleCheckboxComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: SingleCheckboxComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: SingleCheckboxComponentState,
              readonly refState: SingleCheckboxComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onChange() {

    if(this.uncoveredAndVisible && !this.disabled) {

      if (this.threeStates) {

        if(this.checked && !this.unknown) {
          this.checked = false;
          this.unknown = true;
        } else if(!this.checked && this.unknown) {
          this.checked = false;
          this.unknown = false;
        } else if (!this.checked && !this.unknown) {
          this.checked = true;
          this.unknown = false;
        } else {
          throw new Error("Invalid state");
        }
      } else {
        this.checked = !this.checked;
      }

      if (this.threeStates && this.unknown) {
        this.componentState.updateModel(SingleCheckboxComponentDefinition.MODEL, None());
        this.serverModel.clearModelWithAction(this.componentRefPath(), SingleCheckboxComponentDefinition.MODEL, SingleCheckboxComponentDefinition.ON_CHANGE);
      } else {
        this.componentState.updateModel(SingleCheckboxComponentDefinition.MODEL, Some(new BooleanVariable(this.checked)));
        this.serverModel.changeModelWithAction(this.componentRefPath(), SingleCheckboxComponentDefinition.MODEL, new BooleanVariable(this.checked), SingleCheckboxComponentDefinition.ON_CHANGE);
      }
    }

  }

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.valueLabel = this.definition.valueLabel.currentValue(() => this.componentState.valueLabel).valueOrDefault(I18nText.ofCurrent("!not label!")).getCurrentWithFallback();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);


    this.threeStates = this.definition.threeStates.currentValue(() => this.componentState.threeStates).valueOrDefault(false);

    const stateOption = this.componentState.model.valueOrDefault(None());
    const stateChecked: boolean|null = this.threeStates ? stateOption.getOrNull() : stateOption.getOrElse(false);
    this.checked = stateChecked === true;
    this.unknown = stateChecked === null;

    this.requiredIconVisible = this.required && (!this.label || !this.label.visible);

    this.customCssClass = `SingleCheckboxComponent_${this.id}`;

    this.iconBackgroundColorCss = this.definition.iconBackgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.iconBackgroundColor).valueOrDefault(None()).getOrNull();


    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

  }


}
