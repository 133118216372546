import {GridSize, GridXY, PositionXY, ShiftXY, Size} from "@utils";
import {DesignMapConfig} from "./Config";
import {DesignCanvasViewPort} from "./ProcessMapCommonViewModel";

export class XYCalculator {

  constructor(readonly config: DesignMapConfig,
              readonly designCanvasViewPort: DesignCanvasViewPort) {}

  screenSizeToCanvasSize(size: Size) {
    return size.scale(1.0 / this.designCanvasViewPort.scale);
  }

  gridSizeToCanvasSize(gridSize: GridSize) {
    return new Size(gridSize.width * this.config.cellSize.width, gridSize.height * this.config.cellSize.height);
  }

  canvasSizeToScreenSize(size: Size) {
    return size.scale(this.designCanvasViewPort.scale);
  }

  screenPositionToCanvasPosition(position: PositionXY) {
    return new PositionXY((position.x - this.designCanvasViewPort.position.x) / this.designCanvasViewPort.scale,
                          (position.y - this.designCanvasViewPort.position.y) / this.designCanvasViewPort.scale)
  }

  canvasPositionToScreenPosition(position: PositionXY) {
    return new PositionXY(position.x * this.designCanvasViewPort.scale + this.designCanvasViewPort.position.x,
                          position.y * this.designCanvasViewPort.scale + this.designCanvasViewPort.position.y);
  }

  canvasPositionToScreenPositionNoYDraggingInPrintMode(position: PositionXY) {
    return new PositionXY(position.x * this.designCanvasViewPort.scale + this.designCanvasViewPort.position.x + this.config.rowNumberPaddingInPrintMode,
                         (position.y + this.config.columnHeaderHeight) * this.designCanvasViewPort.scale);
  }

  canvasPositionToScreenPositionNoXDragging(position: PositionXY) {
    return new PositionXY( (position.x + this.config.rolesHeaderWidth) * this.designCanvasViewPort.scale,
                          position.y * this.designCanvasViewPort.scale + this.designCanvasViewPort.position.y);
  }

  canvasPositionToScreenPositionNoXDraggingInPrintMode(position: PositionXY) {
    return new PositionXY( (position.x + this.config.rolesHeaderWidth + this.config.rowNumberPaddingInPrintMode) * this.designCanvasViewPort.scale,
      position.y * this.designCanvasViewPort.scale + this.designCanvasViewPort.position.y);
  }

  canvasPositionToWindowPositionInPrintMode(position: PositionXY) {
    return new PositionXY(position.x + this.config.rolesHeaderWidth + this.config.rowNumberPaddingInPrintMode, position.y + this.config.columnHeaderHeight * 3);
  }

  canvasPositionToGridXY(position: PositionXY) {
    return new GridXY(Math.floor(position.x / this.config.cellSize.width),
                      Math.floor(position.y / this.config.cellSize.height))
  }

  canvasPositionToGridXYShift(position: PositionXY): ShiftXY {
    const x = position.x / this.config.cellSize.width;
    const y = position.y / this.config.cellSize.height;
    return new ShiftXY(x - Math.floor(x), y - Math.floor(y))
  }


  canvasDistanceToScreenDistance(distance: number) {
    return distance * this.designCanvasViewPort.scale;
  }

  nodePositionToNearestGridXY(position: PositionXY) {
    return new GridXY(Math.floor((position.x + this.config.nodeSize.width / 2) / this.config.cellSize.width),
                      Math.floor((position.y + this.config.nodeSize.height / 2) / this.config.cellSize.height))
  }

  nodePositionToNearestGridXYShift(position: PositionXY): ShiftXY {
    const x = (position.x + this.config.nodeSize.width / 2) / this.config.cellSize.width;
    const y = (position.y + this.config.nodeSize.height / 2) / this.config.cellSize.height;
    return new ShiftXY(x - Math.floor(x), y - Math.floor(y))
  }

  contextMenuIconPositionToNearestGridXY(position: PositionXY) {
    return new GridXY(Math.floor((position.x + this.config.contextMenuIconsSize / 2) / this.config.cellSize.width),
                      Math.floor((position.y + this.config.contextMenuIconsSize / 2) / this.config.cellSize.height));
  }

  gridXYToNodePosition(gridXY: GridXY) {
    return this.gridXYToNodePositionWithNodeSize(gridXY, this.config.nodeSize);
  }

  gridXYToNodePositionWithNodeSize(gridXY: GridXY, nodeSize: Size) {
    return new PositionXY(gridXY.gridX * this.config.cellSize.width + (this.config.cellSize.width - nodeSize.width) / 2,
                          gridXY.gridY * this.config.cellSize.height + (this.config.cellSize.height - nodeSize.height) / 2);
  }

  gridXYToCellPosition(gridXY: GridXY) {
    return new PositionXY(gridXY.gridX * this.config.cellSize.width,
                          gridXY.gridY * this.config.cellSize.height);
  }

  gridXYToCellPositionWithScale(gridXY: GridXY) {
    return new PositionXY(gridXY.gridX * this.config.cellSize.width * this.designCanvasViewPort.scale,
      gridXY.gridY * this.config.cellSize.height * this.designCanvasViewPort.scale);
  }

  gridXYToCellCenterPosition(gridXY: GridXY) {
    return new PositionXY(gridXY.gridX * this.config.cellSize.width + this.config.cellSize.width / 2,
                          gridXY.gridY * this.config.cellSize.height + this.config.cellSize.height / 2);
  }

  gridXYToPathPosition(gridXY: GridXY) {

    const cellsX = Math.floor(gridXY.gridX / 6) * this.config.cellSize.width;
    const cellsY = Math.floor(gridXY.gridY / 6) * this.config.cellSize.height;

    let shiftX: number;
    if(gridXY.gridX % 6 < 3) { // edge
      shiftX = (gridXY.gridX % 6 - 1) * this.config.cellSize.width / 8;
    } else { //cell
      shiftX = ((gridXY.gridX - 3) % 6 - 1) * this.config.cellSize.width / 6 + this.config.cellSize.width / 2;
    }

    let shiftY: number;
    if(gridXY.gridY % 6 < 3) { // edge
      shiftY = (gridXY.gridY % 6 - 1) * this.config.cellSize.height / 8;
    } else { //cell
      shiftY = ((gridXY.gridY - 3) % 6 - 1) * this.config.cellSize.height / 6 + this.config.cellSize.height / 2;
    }



    return new PositionXY(cellsX + shiftX, cellsY + shiftY);
  }

  gridXYToCellMiddleTextPosition(gridXY: GridXY) {
    return this.gridXYToCellMiddleTextPositionWidth(gridXY, this.config.nodeSize.width);
    //return new PositionXY(
    //  gridXY.gridX * this.config.cellSize.width + (this.config.cellSize.width - this.config.nodeSize.width) / 2 + this.config.inNodeTextMargin,
    //  gridXY.gridY * this.config.cellSize.height + this.config.cellSize.height / 2 );
  }

  gridXYToCellMiddleTextPositionWidth(gridXY: GridXY, width: number) {
    return new PositionXY(
      gridXY.gridX * this.config.cellSize.width + (this.config.cellSize.width - width) / 2 + this.config.nodeTextMargin,
      gridXY.gridY * this.config.cellSize.height + this.config.cellSize.height / 2 );
  }

  gridXYToCellUpperRightLabelPosition(gridXY: GridXY) {
    return new PositionXY(
      gridXY.gridX * this.config.cellSize.width + this.config.cellSize.width - (this.config.cellSize.width - this.config.nodeSize.width) / 0.92 + this.config.nodeTextMargin,
      gridXY.gridY * this.config.cellSize.height + this.config.cellSize.height / 4 );
  }

  columnHeaderPosition(columnNumber: number): PositionXY {
    return new PositionXY(
      (columnNumber - 1) * this.config.cellSize.width + this.config.rolesHeaderWidth,
      - this.config.columnHeaderHeight);
  }

  rowHeaderPosition(rowNumber: number): PositionXY {
    return new PositionXY(0, rowNumber * this.config.cellSize.height);
  }
}
