
export interface EventBusInterface {
  on(call: (...params: Array<any>) => void, callback: (...params: Array<any>) => void): number;
  unsubscribe(subscription: number): void;
}

export class EventBus implements EventBusInterface {

  private logMessages = false;
  private logStart: number = 0;
  private callbacks: Array<Array<any>> = [];
  private callbackQueue: Array<Array<any>> = []; // callback, arguments
  private originals: Array<any> = [];
  private subscriptionsCounter = 0;
  private subscriptions: [number, number][] = [];
  private destroyed: boolean = false;

  constructor() {

    const methods: Array<string> = this.getMethods(this);
    const that = this;

    for(let i = 0; i<methods.length;i++) {
      if(methods[i] !== "getMethods" && methods[i] !== "on" && methods[i] !== "init" && methods[i] !== "unsubscribe"
        && methods[i] !== "turnLoggingOn" && methods[i] !== "turnLoggingOff") {

        let wrapperFactory = function(length: any) {
          return function() {
            if (that.destroyed) return;
            let callbacksCount = 0;
            for(var p=0;p<that.callbacks[length].length;p++)
              if(that.callbacks[length][p]!==undefined) {
                that.callbackQueue.push([that.callbacks[length][p], arguments]);
                callbacksCount++;
              }
            if(that.logMessages) console.log("EventBus: "+methods[length]+" - "+callbacksCount+", "+((new Date().getTime() - that.logStart))+"ms");
            while(that.callbackQueue.length > 0) {
              let callback = that.callbackQueue.shift();
              let callbackStart = new Date().getTime();
              callback![0].apply(that, callback![1]);
              if(that.logMessages) {
                console.log("Callback for "+methods[length]+" finished at: "+((new Date().getTime() - that.logStart))+"ms");
                if((new Date().getTime() - callbackStart) > 50) {
                  console.log(callback![0]);
                }
              }

            }
          }
        };

        let wrapper = wrapperFactory(that.originals.length);

        (<any>that)[methods[i]] = wrapper;
        that.callbacks[i] = [];
        that.originals.push(wrapper);
      }
    }

  }

  turnLoggingOn() {
    this.logStart = new Date().getTime();
    this.logMessages = true;
  }

  turnLoggingOff() {
    this.logMessages = false;
  }


  private getMethods(obj: any)
  {
    const res: Array<any> = [];
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).filter(m => m != "constructor").forEach(m => {
      if(typeof obj[m] == "function") {
        res.push(m)
      }
    });
    return res;
  }

  on(call: (...params: Array<any>) => void, callback: (...params: Array<any>) => void): number {

    const index = this.originals.indexOf(<any>call);
    this.callbacks[index].push(callback);

    const subscription = this.subscriptionsCounter;
    this.subscriptionsCounter++;

    this.subscriptions[subscription] = [index, this.callbacks[index].length - 1];
    return subscription;
  }

  unsubscribe(subscription: number): void {
    this.callbacks[this.subscriptions[subscription][0]][this.subscriptions[subscription][1]] = undefined;
  }

  destroy() {
    this.destroyed = true;
    this.callbacks = [];
    this.subscriptions = [];
  }

}

