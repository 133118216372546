import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Observable} from 'rxjs';
import {MapComponentViewModelWrapper} from "./MapComponentViewModelWrapper";
import {GoogleMap} from "@angular/google-maps";
import {LibrariesService} from "@shared";
import {MapComponentViewModel} from "./MapComponentViewModel";
import {global} from "@utils";

@Component({
  selector: 'my-map-component',
  templateUrl: './map-component.component.html',
  host: {
    "[class]": "'componentBody MapComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
  }
})
export class MapComponentComponent implements OnInit {

  @Input({required:true}) viewModel!: MapComponentViewModel;

  apiLoaded: Observable<boolean> | undefined;

  wrapper!: MapComponentViewModelWrapper;

  private _map: GoogleMap|undefined = undefined;

  @ViewChild(GoogleMap) set googleMap(map: GoogleMap|undefined) {
    if(map !== undefined) {
      this._map = map;
      this.wrapper!.injectMap(map);
    }
  };

  constructor(private librariesService: LibrariesService) {}

  ngOnInit() {
    this.apiLoaded = this.librariesService.loadGoogleMaps(global.config.googleMapsApiKey, () => this.onApiLoaded());
  }

  private onApiLoaded() {
    this.wrapper = new MapComponentViewModelWrapper(this.viewModel);
    if(this._map !== undefined) {
      this.wrapper.injectMap(this._map);
    }
  }
}



// namespace screenModule {
//
//   import Marker = google.maps.Marker;
//   import GeoCoordinate = model.GeoCoordinate;
//   import MapOptions = google.maps.MapOptions;
//
//   declare const MarkerClusterer: any;
//
//   interface MapComponentScope extends ng.IScope {
//     viewModel: MapComponentViewModel;
//   }
//
//   app.directive("myMapComponent", <any>function () {
//     return {
//       scope: {
//         viewModel: "="
//       },
//       restrict: "E",
//       templateUrl: "/app/modules/screen/components/map/MapComponent.html",
//       link: function (scope: MapComponentScope, element: HTMLElement[], attrs: any) {
//
//         const controller = new MapComponentController(scope.viewModel, $(element[0]).find(".MapComponent .mapContent").get(0));
//
//         controller.init();
//
//           // scope.$watch("viewModel.markers", () => {
//         //   // if (scope.viewModel.enabled) {
//         //   //   clearMarkers();
//         //   //   initMarkers();
//         //   // }
//         // });
//         //
//         // scope.$watchGroup(["viewModel.enabled"], () => {
//         //
//         // });
//
//
//
//
//       }
//     }
//   });
// }
