import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenComponentRef
} from "@screen-common";
import {OptionalBooleanProperty, OptionalFileProperty} from "@screen-common";
import {ScreenComponentId} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class ImageComponentRef extends ScreenComponentRef {
  static className = "ImageComponentRef";
  className() {
    return ImageComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: ImageComponentRef): ImageComponentRef {
    return new ImageComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("ImageComponentRef", DefaultPropertyProvider.ref);
  override defaultPropertiesProvider = ImageComponentRef.DEFAULT_PROPERTIES;
}

export class ImageComponentDefinition extends ScreenComponentDefinition {

  static className = "ImageComponentDefinition";
  className() {
    return ImageComponentDefinition.className;
  }
  typeName() {
    return "Image";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,ImageComponentDefinition.DEFAULT_PROPERTIES);
  }

  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  get fileSource(): OptionalFileProperty {return this.properties.getOptionalFileProperty("fileSource", this.defaultPropertiesProvider)};

  static copy(other: ImageComponentDefinition): ImageComponentDefinition {
    return new ImageComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [];
  }


  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("ImageComponentDefinition",
    (name: string) => {switch (name) {
      case "innerShadow": return OptionalBooleanProperty.disabled(true);
      default: return null;
    }},
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
