import {Component, EventEmitter, Input, Output} from "@angular/core";

export interface RadioEntry {
  name: string
}

@Component({
  selector: 'my-radiobutton',
  templateUrl: './radiobutton.component.html'
})
export class RadiobuttonComponent {
  @Input() noEmpty: boolean = false;
  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;

  @Input() checked: RadioEntry|undefined;
  @Output() checkedChange = new EventEmitter<RadioEntry>();

  @Input() radioOptions: RadioEntry[] = [];

  @Input() optionName?: (entry: RadioEntry) => string;

  @Input() small: boolean = false;

  onClicked = (option: RadioEntry) => {
    if(!this.disabled) {
      if(this.checked !== option) {
        this.checked = option;
      } else if(!this.noEmpty){
        this.checked = undefined;
      }

      this.checkedChange.emit(this.checked);
    }
  };

  getOptionName = (entry: RadioEntry) => {
    if(this.optionName !== undefined) {
      return this.optionName(entry);
    } else {
      return entry.name;
    }
  }
}
