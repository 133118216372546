import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  InputComponentRef,
  LabelProperties,
  PaddingsProperties,
  ScreenComponentDefinitionWithLabel,
  TextsProperties
} from "@screen-common";
import {
  BooleanProperty,
  OptionalBooleanProperty,
  OptionalI18nTextProperty,
  OptionalNumberProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentId, TextAlign} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class NumberInputComponentRef extends InputComponentRef {
  static className = "NumberInputComponentRef";

  className() {
    return NumberInputComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: NumberInputComponentRef): NumberInputComponentRef {
    return new NumberInputComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("NumberInputRef", DefaultPropertyProvider.ref, DefaultPropertyProvider.refRequiredInput);
  override defaultPropertiesProvider = NumberInputComponentRef.DEFAULT_PROPERTIES;

}

export class NumberInputComponentDefinition extends ScreenComponentDefinitionWithLabel {

  static className = "NumberInputComponentDefinition";

  static MODEL = "model";
  static ON_CHANGE = "onChange";

  className() {
    return NumberInputComponentDefinition.className;
  }
  typeName() {
    return "NumberInput";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,NumberInputComponentDefinition.DEFAULT_PROPERTIES);
  }

  get placeholder(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("placeholder", this.defaultPropertiesProvider)};
  get minPrecision(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("minPrecision", this.defaultPropertiesProvider)};
  get maxPrecision(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("maxPrecision", this.defaultPropertiesProvider)};
  get multiLine(): BooleanProperty {return this.properties.getBooleanProperty("multiLine", this.defaultPropertiesProvider)}
  get minValue(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("minValue", this.defaultPropertiesProvider)};
  get maxValue(): OptionalNumberProperty {return this.properties.getOptionalNumberProperty("maxValue", this.defaultPropertiesProvider)};
  get unitName(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("unitName", this.defaultPropertiesProvider)};
  get negativeTextColor(): OptionalStringProperty {return this.properties.getOptionalStringProperty("negativeTextColor", this.defaultPropertiesProvider)}

  get icon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("icon", this.defaultPropertiesProvider)};
  get iconPosition(): StringProperty {return this.properties.getStringProperty("iconPosition", this.defaultPropertiesProvider)};

  // deprecated
  get startIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("startIcon", this.defaultPropertiesProvider)};
  // deprecated
  get endIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("endIcon", this.defaultPropertiesProvider)};

  innerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("innerShadow", defaultProvider);
  }

  labelProperties = new LabelProperties(this.properties);
  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  static copy(other: NumberInputComponentDefinition): NumberInputComponentDefinition {
    return new NumberInputComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [NumberInputComponentDefinition.MODEL];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("NumberInputDefinition", (name: string) => {switch (name) {
    case "placeholder": return OptionalI18nTextProperty.disabled();
    case "multiLine": return BooleanProperty.ofTrue();
    case "negativeTextColor": return OptionalStringProperty.disabled("$error-color");
    case "innerShadow": return OptionalBooleanProperty.disabled(true);
    case "minPrecision": return OptionalNumberProperty.disabled(2);
    case "maxPrecision": return OptionalNumberProperty.disabled(2);
    case "minValue": return OptionalNumberProperty.disabled(0);
    case "maxValue": return OptionalNumberProperty.disabled(1000);
    case "unitName": return OptionalI18nTextProperty.disabled();
    case "textAlign": return StringProperty.of(TextAlign.end.name);
    case "paddingTop": return OptionalStringProperty.disabled("0.1cm");
    case "paddingLeft": return OptionalStringProperty.disabled("0.1cm");
    case "paddingBottom": return OptionalStringProperty.disabled("0.1cm");
    case "paddingRight": return OptionalStringProperty.disabled("0.1cm");
    case "startIcon": return OptionalStringProperty.disabled("confirm");
    case "endIcon": return OptionalStringProperty.disabled("confirm");
    case "icon": return OptionalStringProperty.disabled("confirm");
    case "iconPosition": return StringProperty.of("start");
    default: return null;
  }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionLabel, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);



}
