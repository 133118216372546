import {
  ComponentViewModelUtils,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel
} from "../..";
import {CssBuilder, LinkComponentDefinition, LinkComponentRef} from "@screen-common";
import {LinkComponentRefState, LinkComponentState} from "./LinkComponentState";
import {I18nText, VariableId} from "@utils";


export class LinkComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Link";


  public text: string = "";
  public url: string = "";
  public css: string = "";
  public cssClasses: string = "";

  constructor(
    override readonly shared: ScreenSharedViewModel,
    override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
    readonly context: VariableId,
    override readonly definition: LinkComponentDefinition,
    override readonly componentScreenId: string,
    readonly ref: LinkComponentRef,
    override readonly refScreenId: string,
    readonly componentState: LinkComponentState,
    readonly refState: LinkComponentRefState,
    readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }


  updateComponent(deep: boolean): void {

    const cssBuilder = CssBuilder.create();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.url = this.definition.url.currentValue(() => this.componentState.url).valueOrDefault("");
    this.text = this.definition.text.currentValue(() => this.componentState.text).valueOrDefault(I18nText.empty()).getCurrentWithFallback();
    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();
  }

}
