<a class="application applicationColor{{app.applicationIcon.colorNo}}"
   (click)="selectApplication.emit(app)"
   [routerLink]="app.applicationRouterLink" *ngFor="let app of applications"
   [my-tooltip]="{text: app.description, position: 'right'}"
   (contextmenu)="toggleContextMenuPoint($event, app); $event.stopPropagation(); false;"
>
  <div class="icon theme-application-icon-medium theme-application-icon{{app.applicationIcon.colorNo}}">
    <i [class]="app.applicationIcon.icon"></i>
  </div>
  <span class="name">
    {{app.name}}
  </span>
<!--  <button class="pinnedToMenu theme-button-icon-action-flat-small" *ngIf="app.pinnedToMenu"-->
<!--          [class.marked]="app.pinnedToMenu"-->
<!--          (click)="onToggleFavorite(app);$event.preventDefault();$event.stopImmediatePropagation();"><i class="mi-tag"></i></button>-->
  <button class="favorite theme-button-icon-action-flat-small"
          [class.marked]="app.favorite"
          (click)="onToggleFavorite(app);$event.preventDefault();$event.stopImmediatePropagation();"><i class="mi-star"></i></button>
</a>



<my-drop-menu cssClass="taskContextMenu theme-drop-menu"
              [anchor]="contextMenuAnchor"
              [(visible)]="contextMenuVisible">
  <ng-container *ngIf="contextMenuVisible && contextMenuApp">
    <button class="theme-drop-menu-entry" (click)="onToggleFavorite(contextMenuApp)"><i class="mi-star"></i>
      <ng-container *ngIf="contextMenuApp.favorite">
        {{'Dodaj do ulubionych' | i18n}}
      </ng-container>
      <ng-container *ngIf="!contextMenuApp.favorite">
        {{'Usuń z ulubionych' | i18n}}
      </ng-container>
    </button>
    <button *ngIf="contextMenuApp.pinnedToMenu" class="theme-drop-menu-entry" (click)="onTogglePinToMenu(contextMenuApp)">
      <i class="mi-thumbtack"></i>
        {{'Odepnij od menu głównego' | i18n}}
    </button>
    <button *ngIf="!contextMenuApp.pinnedToMenu" class="theme-drop-menu-entry" (click)="onTogglePinToMenu(contextMenuApp)">
      <i class="mi-thumbtack"></i>
      {{'Przypnij do menu głównego' | i18n}}
    </button>
  </ng-container>
</my-drop-menu>
