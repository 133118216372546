import {
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "../screen-component.view-model";
import {None, ScreenInstanceId, VariableId} from "@utils";
import {ScreenExternalEventBus} from "@shared-model";
import {ScreenSharedViewModel} from "../../model/ViewModel";
import {WidgetComponentDefinition, WidgetComponentRef} from "@screen-common";
import {WidgetComponentRefState, WidgetComponentState} from "./widget-component.state";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class WidgetComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Widget";

  public screenInstanceId: ScreenInstanceId|null = null;
  public css: string = "";

  screenExternalEventBus = new ScreenExternalEventBus();

  constructor(
    override readonly shared: ScreenSharedViewModel,
    override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
    readonly context: VariableId,
    override readonly definition: WidgetComponentDefinition,
    override readonly componentScreenId: string,
    readonly ref: WidgetComponentRef,
    override readonly refScreenId: string,
    readonly componentState: WidgetComponentState,
    readonly refState: WidgetComponentRefState,
    readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }

  updateComponent(deep: boolean): void {

    this.css = "";
    this.screenInstanceId = this.componentState.screenInstanceId.valueOrDefault(None()).map(id => new ScreenInstanceId(id)).getOrNull();
    super.updatePosition();
  }

}
