import {AggregateId, ApplicationId, LocalDateTime, MailboxId, Option} from "@utils";

export class ComponentType {
  constructor(readonly name: string) {}

  static readonly mailbox = new ComponentType("m");

  static of(name: string) {
    switch (name) {
      case "m": return ComponentType.mailbox;
      default: throw new Error("Unknown component type: " + name);
    }

  }
}

export class LogLevel {
  constructor(readonly name: string) {}

  static readonly error = new LogLevel("e")
  static readonly warning = new LogLevel("w")
  static readonly info = new LogLevel("i")
  static readonly success = new LogLevel("s")

  static of(name: string) {
    switch (name) {
      case "e": return LogLevel.error;
      case "w": return LogLevel.warning;
      case "i": return LogLevel.info;
      case "s": return LogLevel.success;
      default: throw new Error("Unknown log level: " + name);
    }
  }
}

export class LogEntry {
  constructor(readonly id: string, //protected id
              readonly applicationId: Option<ApplicationId>,
              readonly componentType: Option<ComponentType>,
              readonly component: Option<AggregateId>,
              readonly entity: Option<AggregateId>,
              readonly timestamp: LocalDateTime,
              readonly level: LogLevel,
              readonly message: string,
              readonly details: string) {}

  static copy(other: LogEntry) {
    return new LogEntry(other.id,
      Option.copy(other.applicationId, ApplicationId.copy),
      Option.copy(other.componentType, c => ComponentType.of(c.name)),
      Option.copy(other.component, AggregateId.copy),
      Option.copy(other.entity, AggregateId.copy),
      LocalDateTime.copy(other.timestamp),
      LogLevel.of(other.level.name),
      other.message,
      other.details);
  }
}

export class GetLogsById {
  constructor(
    public mailboxId: MailboxId,
    public limit: number,
    public afterId: Option<number>,
    public afterCreated: Option<LocalDateTime>
  ) {}
}

export class LogSearchResult {
  constructor(readonly logs: Array<LogEntry>,
              readonly moreAvailable: boolean) {}

  static copy(other: LogSearchResult) {
    return new LogSearchResult(other.logs.map(LogEntry.copy), other.moreAvailable);
  }
}
