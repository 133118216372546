import {ApplicationIcon, ApplicationIcons} from "../application-icons";
import {ApplicationId} from "@utils";

export class ApplicationSummaryViewModel {

  applicationRouterLink: string;
  pinnedToMenu: boolean = false;
  favorite: boolean = false;
  active: boolean = false;
  tasksCount: number | undefined;
  readonly applicationIcon: ApplicationIcon;

  constructor(readonly id: ApplicationId,
              readonly identifier: string,
              readonly name: string,
              readonly categoryId: number,
              readonly description: string,
              readonly iconCode: string,
              readonly colorId: number
  ) {
    this.applicationIcon = ApplicationIcons.getApplicationIcon(id, iconCode, colorId);
    if(identifier.length > 0) {
      this.applicationRouterLink = `/application/${identifier}`;
    } else {
      this.applicationRouterLink = `/application/@${id.id}`;
    }
  }

  static searchString(application: ApplicationSummaryViewModel): string {
    return `${application.name} ${application.description}`;
  }

  withFavorite(favorite: boolean): this {
    this.favorite = favorite;
    return this;
  }

  withPinnedToMenu(pinned: boolean): this {
    this.pinnedToMenu = pinned;
    return this;
  }

  withTasksCount(tasksCount: number): this {
    this.tasksCount = tasksCount;
    return this;
  }
}
