import {Component, OnInit} from '@angular/core';
import {AggregateId, global, i18n, None, Option, Some} from "@utils";
import {ActivatedRoute} from "@angular/router";
import {NavigationService, PasswordValidatorService, SessionServiceProvider, UserRegistrationService} from "@shared";
import {
  ConfirmPersonEmail,
  EmailConfirmResponse
} from "@shared";
import {
  OrganizationRegistrationService
} from "@shared";
import {SetPasswordForm} from "./password-set-form/password-set-form.component";

@Component({
  templateUrl: './email-confirmation-page.component.html'
})
export class EmailConfirmationPageComponent implements OnInit {
  readonly activationCode!: string | null;
  readonly userId!: string | null;

  public finished: boolean = false;
  public success: boolean = false;
  public setPassword: boolean = false;
  public confirmResponse: Option<EmailConfirmResponse> = None();
  public errorMessage: string | undefined;
  public isOrganization: boolean = false;
  public organizationUrlAlias: string | null;

  viewParamsMenuVisible: boolean = false;
  organizationLogoUrl = global.config.organizationLogoUrl.getOrNull();

  constructor(private readonly registerService: OrganizationRegistrationService,
              private activatedRoute: ActivatedRoute,
              readonly sessionServiceProvider: SessionServiceProvider,
              private readonly navigationService: NavigationService) {

    this.activationCode = activatedRoute.snapshot.paramMap.get("activationCode");
    this.userId = activatedRoute.snapshot.paramMap.get("userId");
    this.setPassword = activatedRoute.snapshot.queryParamMap.get("mode") === "set-password";
    this.organizationUrlAlias = activatedRoute.snapshot.queryParamMap.get("alias");
  }

  ngOnInit() {
    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.getOrganization(
        (id: AggregateId) => {
          this.isOrganization = true;
        }, () => {
          this.isOrganization = false;
        });
    });

    if(!this.setPassword) {
      this.confirmEmail();
    }
  }

  confirmEmail() {
    if(this.userId && this.activationCode) {
      const confirmPersonEmail = new ConfirmPersonEmail(new AggregateId(this.userId), this.activationCode);
      this.registerService.confirmEmail(confirmPersonEmail,
        (confirmResponse: EmailConfirmResponse) => {
          // on success
          this.confirmResponse = Some(confirmResponse);
          this.finished = true;
          this.success = true;
        }, (exceptions: Array<string>) => {
          if ("EmailAlreadyActiveException" === exceptions[0]) {
            this.finished = true;
            this.success = true;
          } else {
            // on failure
            this.errorMessage = i18n("emailConfirm_form_error_failed") + exceptions.join(", ");
            this.finished = true;
            this.success = false;
          }
        }, () => {
          // on error
          this.errorMessage = i18n("emailConfirm_form_error_failure");
          this.finished = true;
          this.success = false;
        });
    }
  }

  setPasswordAndConfirmEmail(passwordForm: SetPasswordForm) {
    this.setPassword = false;
    if(this.userId && this.activationCode){
      this.registerService.confirmEmailAndSetPassword(this.userId, this.activationCode, passwordForm.newPassword, passwordForm.newRepeatPassword,
      () => {
        this.finished = true;
        this.success = true;
        this.confirmEmail(); // Just to get user email and display login form
      },
      (exceptions: Array<string>) => {
        this.finished = true;
        this.success = false;
        this.errorMessage = i18n("emailConfirm_form_error_failed") + exceptions.join(", ");
      });
    }
  }

}
