import {
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdFactory,
  EmailId,
  LocalDateTime,
  MailboxId,
  Option,
  PersonId,
  Typed
} from "@utils";


export interface NotificationMessage {
  className(): string;
  value: string;
}

export class SystemMessage implements NotificationMessage {
  static className = "SystemMessage";
  className(): string {
    return SystemMessage.className
  }

  constructor(readonly value: string) {}

  static copy(other: SystemMessage){
    return new SystemMessage(other.value);
  }

}

export class FlowMessage implements NotificationMessage {
  static className = "FlowMessage";
  className(): string {
    return FlowMessage.className
  }

  constructor(readonly value: string,
              readonly flowId: Typed<AnyFlowId>,
              readonly flowCode: string) {}

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  static copy(other: FlowMessage){
    return new FlowMessage(other.value, AnyFlowIdFactory.copyTyped(other.flowId), other.flowCode);
  }
}


export class TaskMessage implements NotificationMessage {
  static className = "TaskMessage";
  className(): string {
    return TaskMessage.className
  }

  constructor(readonly value: string,
              readonly flowId: Typed<AnyFlowId>,
              readonly flowCode: string,
              readonly nodeId: number) {}

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }

  static copy(other: TaskMessage){
    return new TaskMessage(other.value, AnyFlowIdFactory.copyTyped(other.flowId), other.flowCode, other.nodeId);
  }
}

export class FileMessage implements NotificationMessage {
  static className = "FileMessage";
  className(): string {
    return FileMessage.className
  }

  constructor(readonly value: string,
              readonly path: string) {}

  static copy(other: FileMessage){
    return new FileMessage(other.value, other.path);
  }
}

export class MailboxMessage implements NotificationMessage {
  static className = "MailboxMessage";
  className(): string {
    return MailboxMessage.className
  }

  constructor(readonly value: string,
              readonly mailboxId: MailboxId,
              readonly emailId: Option<EmailId>) {}

  static copy(other: MailboxMessage){
    return new MailboxMessage(other.value,
      MailboxId.copy(other.mailboxId),
      Option.copy(other.emailId, EmailId.copy));
  }
}


export class NotificationSeverity {

  constructor(readonly name: string) {}

  static normal = new NotificationSeverity("normal");
  static important = new NotificationSeverity("important");

  static copy(other: NotificationSeverity) {
    switch(other.name) {
      case NotificationSeverity.normal.name: return NotificationSeverity.normal;
      case NotificationSeverity.important.name: return NotificationSeverity.important;
      default: throw new Error("Unsupported severity type " + other.name);
    }
  }
}


export class PersonNotification {


  constructor(readonly aggregateId: AggregateId,
              readonly version: AggregateVersion,
              readonly subject: string,
              readonly notificationMessage: Typed<NotificationMessage>,
              readonly notificationSeverity: NotificationSeverity,
              readonly createdDate: LocalDateTime,
              public read: boolean,
              readonly receiverId: PersonId) {

  }

  static copy(aggreagateId: AggregateId, version: AggregateVersion, other: PersonNotification) {
    return new PersonNotification(aggreagateId, version, other.subject, NotificationMessageFactory.copyTyped(other.notificationMessage),
      NotificationSeverity.copy(other.notificationSeverity), LocalDateTime.copy(other.createdDate),
      other.read, other.receiverId);
  }

  unWrappedMessage(){
    return Typed.value(this.notificationMessage);
  }

  isImportantSeverity(){
    return this.notificationSeverity.name === NotificationSeverity.important.name;
  };

  isNormalSeverity(){
    return this.notificationSeverity.name === NotificationSeverity.normal.name;
  };

  isFlowMessage() {
    return this.unWrappedMessage().className() === FlowMessage.className;
  }

  isTaskMessage() {
    return this.unWrappedMessage().className() === TaskMessage.className;
  }

  isFileMessage() {
    return this.unWrappedMessage().className() === FileMessage.className;
  }

  isMailboxMessage() {
    return this.unWrappedMessage().className() === MailboxMessage.className;
  }

  isSystemMessage() {
    return this.unWrappedMessage().className() === SystemMessage.className;
  }

}

export class NotificationMessageFactory {
  static copy(message: NotificationMessage): NotificationMessage {
    return NotificationMessageFactory.copyByType(message, message.className());
  }

  static copyTyped(message: Typed<NotificationMessage>): Typed<NotificationMessage> {
    return Typed.of(NotificationMessageFactory.copyByType(Typed.value(message), Typed.className(message)));
  }

  static copyByType(message: NotificationMessage, className: string): NotificationMessage {
    switch (className) {
      case SystemMessage.className: return SystemMessage.copy(<SystemMessage>message);
      case FlowMessage.className: return FlowMessage.copy(<FlowMessage>message);
      case TaskMessage.className: return TaskMessage.copy(<TaskMessage>message);
      case FileMessage.className: return FileMessage.copy(<FileMessage>message);
      case MailboxMessage.className: return MailboxMessage.copy(<MailboxMessage>message);
      default: throw new Error("Unsupported message type: " + JSON.stringify(message));
    }
  }
}
