import {Component, Input} from "@angular/core";
import {RadioButtonComponentViewModel} from "./RadioButtonComponentViewModel";

@Component({
  selector: 'my-radio-button-component',
  templateUrl: './radio-button-component.component.html',
  host: {
    "[class]": "'componentBody RadioButtonComponent ' + viewModel.classes + ' ' + viewModel.cssClasses",
    "[class.enabled]": "!viewModel.disabled",
    "[class.invalid]": "viewModel.invalid",
    "[class.validationError]": "viewModel.validationError",
    "[style]": "viewModel.css",
  }
})
export class RadioButtonComponentComponent {
  @Input({required:true}) viewModel!: RadioButtonComponentViewModel;

}
