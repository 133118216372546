import {Injectable} from "@angular/core";
import {
  asSuccess,
  AuthenticatedHttp,
  BasicDepartmentInfo,
  CommandResponse,
  DepartmentInfo,
  isSuccess, nonSuccessMessages, OperatingHoursException,
  OrganizationInfo
} from "..";
import {AggregateId, AggregateVersion, DepartmentId, Option, Typed} from "@utils";
import {FindDepartmentById, FindDepartmentsByIds} from "./DepartmentsMessages";
import {WeeklyOperatingHours} from "@shared";
import {
  AddCalendarException, DeleteCalendarException,
  UpdateCalendarException,
  UpdateWeeklyHours
} from "../commands/OrganizationCommands";

@Injectable({
  providedIn: 'root',
})
export class DepartmentsSharedService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  findDepartmentById(id: DepartmentId, onSuccess: (departmentInfo: DepartmentInfo) => void): void {
    this.authenticatedHttp.post("organization-structure/department/info", new FindDepartmentById(id),
      (data: Option<DepartmentInfo>) => {
        const dataOption = Option.copy(data);
        if (dataOption) {
          const departmentInfo = DepartmentInfo.copy(dataOption.get())
          onSuccess(departmentInfo);
        } else {
          throw new Error("Problem with loading department info");
        }
      });
  }

  findDepartmentsBasicInfo(departmentIds: Array<DepartmentId>, onSuccess: (departments: Array<BasicDepartmentInfo>) => void) {
    if(departmentIds.length === 0) {
      onSuccess([]);
    } else {
      this.authenticatedHttp.post("organization-structure/department/basic-infos", new FindDepartmentsByIds(departmentIds),
        (data: Array<BasicDepartmentInfo>) => {
          data = data.map(BasicDepartmentInfo.copy);
          onSuccess(data);
        });
    }
  }

  updateCalendarWeeklyHours(departmentId: AggregateId, expectedVersion: AggregateVersion, newWeeklyHoursOption: Option<WeeklyOperatingHours>,
                            onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    const command = new UpdateWeeklyHours(departmentId, expectedVersion, newWeeklyHoursOption);
    this.authenticatedHttp.post("organization-structure/department/update-weekly-hours", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  addCalendarException(departmentId: AggregateId, expectedVersion: AggregateVersion, newCalendarException: OperatingHoursException,
                       onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    const command = new AddCalendarException(departmentId, expectedVersion, newCalendarException);
    this.authenticatedHttp.post("organization-structure/department/add-exception", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  updateCalendarException(departmentId: AggregateId, expectedVersion: AggregateVersion, calendarException: OperatingHoursException,
                          onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    const command = new UpdateCalendarException(departmentId, expectedVersion, calendarException);
    this.authenticatedHttp.post("organization-structure/department/update-exception", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  removeCalendarException(departmentId: AggregateId, expectedVersion: AggregateVersion, calendarExceptionId: number,
                          onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void, onFailure: (exceptions: Array<string>) => void) {
    const command = new DeleteCalendarException(departmentId, expectedVersion, calendarExceptionId);
    this.authenticatedHttp.post("organization-structure/department/remove-exception", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }
}
