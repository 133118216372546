import {Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  I18nTextProperty,
  OptionalI18nTextProperty,
  OptionalStringProperty,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenComponentId,
  ScreenComponentRef,
  StringProperty,
  TextsProperties
} from "@screen-common";
import {ScreenComponentRefId} from "@shared";

export class LabelComponentRef extends ScreenComponentRef {
  static className = "LabelComponentRef";
  className() {
    return LabelComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: LabelComponentRef): LabelComponentRef {
    return new LabelComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("LabelComponentRef", DefaultPropertyProvider.ref);
  override defaultPropertiesProvider = LabelComponentRef.DEFAULT_PROPERTIES;

}

export class LabelComponentDefinition extends ScreenComponentDefinition {

  static className = "LabelComponentDefinition";
  className() {
    return LabelComponentDefinition.className;
  }
  typeName() {
    return "Label";
  }

  constructor(
    override readonly id: ScreenComponentId,
    override readonly identifier: Option<string>,
    override readonly properties: ComponentProperties,
    readonly actionProperties: ComponentActionProperties,
    override  readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules,LabelComponentDefinition.DEFAULT_PROPERTIES);
  }

  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  get tooltip(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("tooltip", this.defaultPropertiesProvider)};

  get icon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("icon", this.defaultPropertiesProvider)};
  get iconPosition(): StringProperty {return this.properties.getStringProperty("iconPosition", this.defaultPropertiesProvider)};


  get text(): I18nTextProperty {return this.properties.getI18nTextProperty("text", this.defaultPropertiesProvider)};

  static copy(other: LabelComponentDefinition): LabelComponentDefinition {
    return new LabelComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [];
  }


  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("LabelComponentDefinition",
    (name: string) => {
      switch (name) {
        case "icon": return OptionalStringProperty.disabled("confirm");
        case "iconPosition": return StringProperty.of("start");
        default: return null;
      }},
    DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);

}
