import {___, clearArray, mySetTimeout, None, Option, overwriteArray, Some} from "@utils";
import {MapComponentViewModel, MapMarkerViewModel} from "./MapComponentViewModel";
import {GeoCoordinate, GeoCoordinateVariable, ObjectVariable} from "@shared-model";

export class MapComponentController {

  readonly warsaw = new GeoCoordinate(52.2296756, 21.0122287);

  public map: google.maps.Map|null = null;
  readonly markers: Array<google.maps.Marker> = [];
  public infoWindow: Option<google.maps.InfoWindow> = None();

  public selectedMarker: Option<google.maps.Marker> = None();
  public markerCluster: Option<any> = None();

  public positionChangeTimeout: number|null = null

  static POSITION_UPDATE_TIMEOUT = 700;
  static DOUBLE_CLICK_TIMEOUT = 500;

  private prevent_map_event_fire = false;

  // TODO: Another idea of handling marker icons:
  //  https://stackoverflow.com/questions/2472957/how-can-i-change-the-color-of-a-google-maps-marker
  static MARKER_ICON = "assets/images/markers/marker.svg";
  static MARKER_ICON_SELECTED = "assets/images/markers/marker_selected.svg";

  private lastMarkerClickTime = 0;

  constructor(readonly viewModel: MapComponentViewModel,
              readonly mapElement: HTMLElement) {

    viewModel.eventBus.on(viewModel.eventBus.markersChanged, (markers: Array<MapMarkerViewModel>) => {
      if (this.viewModel.uncoveredAndVisible) {
        this.clearMarkers();
        this.initMarkers();
      }
    });

    viewModel.eventBus.on(viewModel.eventBus.selectedMarkerChanged, (selectedMarker: Option<MapMarkerViewModel>) => {
      if (this.viewModel.uncoveredAndVisible) {
        this.markers.forEach(this.markUnselected);
        if (selectedMarker.isDefined()) {
          const model = selectedMarker.get();
          this.markers
            .filter(marker => getMarkerViewModel(marker).equals(model))
            .map(marker => {
              this.selectedMarker = Option.of(marker);
              this.markSelected(marker);
            })
        } else {
          this.selectedMarker = None();
        }
      }
    });

    viewModel.eventBus.on(viewModel.eventBus.mapBoundariesChanged, () => {
      if(this.viewModel.uncoveredAndVisible){
        const bounds = this.calculateBounds();
        this.prevent_map_event_fire = true;
        this.updateBounds(bounds);
      }
    });

  }

  init() {
    // googleMaps.initMaps(() => {

      if (this.map === null) {
        this.map = new google.maps.Map(this.mapElement, {
          backgroundColor: "#f6f8f7",
          zoom: 12,
          maxZoom: 19,
          minZoom: 3,
          scrollwheel: false,
          center: new google.maps.LatLng(this.warsaw.latitude, this.warsaw.longitude),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
          },
          streetViewControl: false,
        });
      }

      this.map.addListener('zoom_changed', () => {
        this.onPositionChangeEventListener();
      });

      this.map.addListener("bounds_changed", (event: any) => {
        this.onPositionChangeEventListener();
      });

      // this.map.addListener("click", (event) => {
      //   console.log("Clicked: " + event.latLng);
      // });

      const style = [
        {
          "featureType": "landscape.man_made",
          "stylers": [
            {
              "lightness": 45
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ];
      this.map.setOptions(<google.maps.MapOptions>{styles: style});

      this.clearMarkers();
      this.initMarkers();

      const bounds = this.calculateBounds();

      this.prevent_map_event_fire = true;
      this.map.fitBounds(bounds);

      this.infoWindow = Some(new google.maps.InfoWindow({
        content: ""
      }));

    // });
  }

  private onPositionChangeEventListener(){
    if(this.prevent_map_event_fire){
      mySetTimeout(() => this.prevent_map_event_fire = false, MapComponentController.POSITION_UPDATE_TIMEOUT);
    } else {
      this.updateMapPositionInModel();
    }
  }

  initMarkers() {
    if(this.map !== null) {
      const newMarkers = this.viewModel.markers.map(marker => this.createMarker(marker));
      overwriteArray(this.markers, newMarkers);


      this.markerCluster.forEach(m => m.clearMarkers());

      if (this.markerCluster.isEmpty()) {
        // this.markerCluster = Some(new MapTrafficLayer(this.map, this.markers, {
        //   imageFunction: this.clusterImageFunction,
        //   minimumClusterSize: 3,
        //   maxZoom: 13
        // }, () => {
        //   this.selectedMarker = None();
        // }));
      } else {
        this.markerCluster.get().addMarkers(this.markers);
      }
    }
  }

  clearMarkers() {
    this.markers.forEach(m => m.setMap(null));
    clearArray(this.markers);
  }

  /*updateMarkers(markers: Array<MapMarkerViewModel>) {
    if(this.map !== null) {
      const newMarkers = markers.map(marker => this.createMarker(marker));
      overwriteArray(markers, newMarkers);

      this.markerCluster.forEach(m => m.clearMarkers());

      if (this.markerCluster.isEmpty()) {
        this.markerCluster = Some(new MarkerClusterer(this.map, markers, {
          imageFunction: this.clusterImageFunction,
          minimumClusterSize: 3,
          maxZoom: 13
        }, () => {
          this.selectedMarker = None();
        }));
      } else {
        this.markerCluster.get().addMarkers(markers);
      }
    }
  }*/

  createMarker(point: MapMarkerViewModel): google.maps.Marker {

    const marker = new google.maps.Marker({
      position: geoCoordinateToLatLng(point.coordinate),
      map: this.map,
      icon: MapComponentController.MARKER_ICON,
      label: point.label.getOrUndefined(),
      title: point.description.getOrUndefined()
    });

    marker.addListener('click', () => this.onMarkerSelected(marker) );

    setMarkerViewModel(marker, point);

    return marker;
  }

  private onMarkerSelected(marker: google.maps.Marker){
    this.isSelectedMarker(marker) && !this.isMarkerDoubleClick() ?
      this.unselectAllMarkers() : this.selectMarker(marker);
    this.updateLastMarkerClickTime();
  }


  private updateMapPositionInModel() {
    if(this.positionChangeTimeout !== null) {
      clearTimeout(this.positionChangeTimeout);
    }

    this.positionChangeTimeout = <number><unknown>mySetTimeout(() => {
      this.positionChangeTimeout = null;
      const center = this.map!.getCenter();
      const bounds = this.map!.getBounds();
      const southWest = bounds!.getSouthWest();
      const northEast = bounds!.getNorthEast();
      this.viewModel.mapPositionChanged(latLangToGeoCoordinate(center!), latLangToGeoCoordinate(southWest), latLangToGeoCoordinate(northEast));
    }, MapComponentController.POSITION_UPDATE_TIMEOUT);
  }

  private calculateBounds(){
    return this.viewModel.mapBoundaries
      .map(v => objectVariableToBounds(v as ObjectVariable))
      .getOrElse(this.calculateDefaultBounds());
  }

  private calculateDefaultBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => bounds.extend(marker.getPosition()!));

    if (this.markers.length > 0) {
      let maxLat = ___(this.markers).map(m => <number>m.getPosition()!.lat()).max();
      let maxLng = ___(this.markers).map(m => <number>m.getPosition()!.lng()).max();
      let minLat = ___(this.markers).map(m => <number>m.getPosition()!.lat()).min();
      let minLng = ___(this.markers).map(m => <number>m.getPosition()!.lng()).min();
      bounds.extend(new google.maps.LatLng(minLat - 0.001, minLng - 0.001));
      bounds.extend(new google.maps.LatLng(maxLat + 0.001, maxLng + 0.001));
    } else {
      bounds.extend(new google.maps.LatLng(this.warsaw.latitude - 0.03, this.warsaw.longitude - 0.03));
      bounds.extend(new google.maps.LatLng(this.warsaw.latitude + 0.03, this.warsaw.longitude + 0.03));
    }
    return bounds;
  }

  readonly clusterImageFunction: (n: number) => string = (n: number) => {
    switch(n) {
      case 1: return "images/markerclusterer/m1.png";
      case 2: return "images/markerclusterer/m2.png";
      case 3: return "images/markerclusterer/m3.png";
      case 4: return "images/markerclusterer/m4.png";
      case 5: return "images/markerclusterer/m5.png";
      default: return "images/markerclusterer/m5.png";
    }
  }


  private updateBounds(bounds: google.maps.LatLngBounds){
    this.map!.fitBounds(bounds);
  }

  private updateCenter(coordinate: GeoCoordinate) {
    this.map!.setCenter(geoCoordinateToLatLng(coordinate));
  }

  private isMarkerDoubleClick() {
    return new Date().getTime() - this.lastMarkerClickTime < MapComponentController.DOUBLE_CLICK_TIMEOUT;
  }

  private updateLastMarkerClickTime() {
    this.lastMarkerClickTime = new Date().getTime();
  }

  private isSelectedMarker(marker: google.maps.Marker){
    return this.selectedMarker.isDefined() && this.isSameMarker(this.selectedMarker.get(), marker);
  }

  private isSameMarker(marker1: google.maps.Marker, marker2: google.maps.Marker) {
    const model1 = getMarkerViewModel(marker1);
    const model2 = getMarkerViewModel(marker2);
    return model1.equals(model2);
  }

  private selectMarker(marker: google.maps.Marker) {
    const point = getMarkerViewModel(marker);
    this.infoWindow.map(info => info.close());
    if(point.description.exists(d => d.length > 0)) {
      this.infoWindow.map(info => {
        info.setContent(point.description.get());
        info.open(this.map, marker)
        // info.addListener('closeclick', () => {});
      });
    }

    this.markers.forEach(this.markUnselected);
    this.markSelected(marker);
    this.viewModel.markerSelected(point);
    this.selectedMarker = Some(marker);
  }

  private unselectAllMarkers(){
    this.selectedMarker = None();
    this.markers.forEach(this.markUnselected);
    this.viewModel.unselect();
    this.infoWindow.map(window => window.close());
  }

  private markSelected(marker: google.maps.Marker) {
    marker.setIcon(MapComponentController.MARKER_ICON_SELECTED);
  }

  private markUnselected(marker: google.maps.Marker) {
    marker.setIcon(MapComponentController.MARKER_ICON)
  }

}

function geoCoordinateToLatLng(coordinate: GeoCoordinate) {
  return new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
}

function latLangToGeoCoordinate(coordinate: google.maps.LatLng) {
  return new GeoCoordinate(coordinate.lat(), coordinate.lng());
}

function setMarkerViewModel(marker: google.maps.Marker, viewModel: MapMarkerViewModel){
  marker.set("__marker_view_model__", viewModel);
}

function getMarkerViewModel(marker: google.maps.Marker): MapMarkerViewModel {
  return marker.get("__marker_view_model__");
}

function objectVariableToBounds(o: ObjectVariable) {
  const southWest = o.valueFor('southWest').map(c => (c as GeoCoordinateVariable).value).getOrError("Unable to convert ObjectVariable 'southWest' value to GeoCoordinate");
  const northEast = o.valueFor('northEast').map(c => (c as GeoCoordinateVariable).value).getOrError("Unable to convert ObjectVariable 'northEast' value to GeoCoordinate");
  return new google.maps.LatLngBounds(geoCoordinateToLatLng(southWest), geoCoordinateToLatLng(northEast));
}

