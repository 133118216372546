import {AggregateId, AggregateType, AggregateVersion, CommandId, Typed} from "@utils";

export interface CommandResponse {
  className(): string;
}

export function isSuccess(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == SuccessResponse.className;
}

export function isCustomSuccess(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == CustomSuccessResponse.className;
}


export function isFailure(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == FailureResponse.className;
}

export function isConcurrentModificationError(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == AggregateConcurrentModificationError.className;
}

export function isCommandHandlingError(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == CommandHandlingError.className;
}

export function isEventHandlingError(response: Typed<CommandResponse>): boolean {
  return Typed.className(response) == EventHandlingError.className;
}

export function asSuccess(response: Typed<CommandResponse>): SuccessResponse {
  if(Typed.className(response) == SuccessResponse.className) {
    return Typed.value(<Typed<SuccessResponse>>response);
  } else {
    throw new Error("Response is not of type success");
  }
}

export function asCustomSuccess<I>(response: Typed<CommandResponse>): CustomSuccessResponse<I> {
  if(Typed.className(response) == CustomSuccessResponse.className) {
    return Typed.value(<Typed<CustomSuccessResponse<I>>>response);
  } else {
    throw new Error("Response is not of type custom success");
  }
}

export function nonSuccessMessages(response: Typed<CommandResponse>): string {
  if (Typed.className(response) == FailureResponse.className) {
    return Typed.value(<Typed<FailureResponse>>response).exceptions!.join(", ");
  } else if (Typed.className(response) == EventHandlingError.className) {
    return "Error while handling event "+Typed.value(<Typed<EventHandlingError>>response).eventName+".";
  } else if (Typed.className(response) == AggregateConcurrentModificationError.className) {
    const error = Typed.value(<Typed<AggregateConcurrentModificationError>>response);
    return "Aggregate concurrent modification error of "+error.aggregateType!.typeName+". Expected "+error.expected!.asInt+" but was "+error.was!.asInt;
  } else if (Typed.className(response) == CommandHandlingError.className) {
    return "Error while handling command "+Typed.value(<Typed<CommandHandlingError>>response).commandName+".";
  } else{
    throw new Error("Response is not of generic failure");
  }
}

export function asFailure(response: Typed<CommandResponse>): FailureResponse {
  if (Typed.className(response) == FailureResponse.className) {
    return Typed.value(<Typed<FailureResponse>>response);
  } else {
    throw new Error("Response is not of type failure");
  }
}

export function asConcurrentModificationError(response: Typed<CommandResponse>): AggregateConcurrentModificationError {
  if(Typed.className(response) == AggregateConcurrentModificationError.className) {
    return Typed.value(<Typed<AggregateConcurrentModificationError>>response);
  } else {
    throw new Error("Response is not of type concurrent modification error");
  }
}

export function asCommandHandlingError(response: Typed<CommandResponse>): CommandHandlingError {
  if(Typed.className(response) == CommandHandlingError.className) {
    return CommandHandlingError.copy(Typed.value(<Typed<CommandHandlingError>>response));
  } else {
    throw new Error("Response is not of type command handling error");
  }
}

export function asEventHandlingError(response: Typed<CommandResponse>): EventHandlingError {
  if(Typed.className(response) == EventHandlingError.className) {
    return EventHandlingError.copy(Typed.value(<Typed<EventHandlingError>>response));
  } else {
    throw new Error("Response is not of type event handling error");
  }
}





export class SuccessResponse implements CommandResponse {
  static className = "SuccessResponse";

  constructor(readonly aggregateId: AggregateId,
              readonly aggregateVersion: AggregateVersion) {}

  static copy(successResponse: SuccessResponse): SuccessResponse {
    return new SuccessResponse(AggregateId.copy(successResponse.aggregateId),
      AggregateVersion.copy(successResponse.aggregateVersion));
  }

  className() {
    return SuccessResponse.className;
  }
}

export class CustomSuccessResponse<T> implements CommandResponse {
  static className = "CustomSuccessResponse";

  constructor(readonly aggregateId: AggregateId,
              readonly aggregateVersion: AggregateVersion,
              readonly info: T) {}

  static copy<T>(response: CustomSuccessResponse<T>): CustomSuccessResponse<T> {
    return new CustomSuccessResponse(AggregateId.copy(response.aggregateId), AggregateVersion.copy(response.aggregateVersion), response.info);
  }

  className() {
    return CustomSuccessResponse.className;
  }
}

export class AggregateConcurrentModificationError implements CommandResponse {
  static className = "AggregateConcurrentModificationError";

  constructor(readonly aggregateId: AggregateId,
              readonly aggregateType: AggregateType,
              readonly expected: AggregateVersion,
              readonly was: AggregateVersion) {}

  static copy(other: AggregateConcurrentModificationError): AggregateConcurrentModificationError {
    return new AggregateConcurrentModificationError(
      AggregateId.copy(other.aggregateId),
      AggregateType.copy(other.aggregateType),
      AggregateVersion.copy(other.expected),
      AggregateVersion.copy(other.was));
  }

  className() {
    return SuccessResponse.className;
  }
}

export class CommandHandlingError implements CommandResponse {
  static className = "CommandHandlingError";

  constructor(
    readonly commandName: string,
    readonly commandId: CommandId) {
  }

  static copy(other: CommandHandlingError): CommandHandlingError {
    return new CommandHandlingError(other.commandName, CommandId.copy(other.commandId));
  }

  className() {
    return CommandHandlingError.className;
  }
}

export class EventHandlingError implements CommandResponse {
  static className = "EventHandlingError";

  constructor(readonly eventName: string, readonly commandId: CommandId) {}

  static copy(other: EventHandlingError): EventHandlingError {
    return new EventHandlingError(other.eventName, CommandId.copy(other.commandId));
  }

  className() {
    return EventHandlingError.className;
  }
}

export class FailureResponse implements CommandResponse {
  static className = "FailureResponse";

  constructor(readonly exceptions: Array<string>) {}

  static copy(other: FailureResponse): FailureResponse {
    return new FailureResponse(other.exceptions);
  }

  className() {
    return FailureResponse.className;
  }
}



