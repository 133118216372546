import {
  BusinessVariable,
  BusinessVariableInputServerModel,
  EmailsSharedService,
  FilesSharedService,
  ObjectVariable
} from "@shared-model";
import {ScreenViewModel} from "../screen.view-model";
import {ScreenInstanceServerModel} from "../screen-instance.server-model";
import {ScreenRuntimeEventBus} from "../services/screen-runtime.event-bus";

export class ScreenDebugViewModel {

  dataVisible: boolean = true;
  logsVisible: boolean = false;
  evaluatorVisible: boolean = false;

  customContextEnabled: boolean = false;

  expressionContext: string = "";
  expressionToEvaluate = "";
  evaluateExpressionCallNo = -1;
  evaluationPending = false;
  evaluationCompleted: boolean = false;
  evaluationResult: Array<BusinessVariable> = []; // Array for angularjs refresh
  evaluationResultTypeName: string = "";
  evaluationError: string = "";
  evaluationDurationMillis: number = -1;

  readonly businessVariableInputServerModel: BusinessVariableInputServerModel;

  constructor(readonly parent: ScreenViewModel,
              readonly serverModel: ScreenInstanceServerModel,
              readonly eventBus: ScreenRuntimeEventBus,
              public logs: string,
              public errors: string,
              public debugData: ObjectVariable,
              public loaded: boolean,
              readonly filesService: FilesSharedService,
              readonly emailsSharedService: EmailsSharedService,
              // readonly processFlowService: ProcessFlowService,
              // readonly businessEntityQueryService: BusinessEntityQueryService
  ) {

    this.eventBus.on(this.eventBus.expressionEvaluated, (callNumber: number, result: BusinessVariable, durationMillis: number) => {
      if(this.evaluateExpressionCallNo === callNumber) {
        this.onExpressionEvaluated(result, durationMillis);
      }
    });

    this.eventBus.on(this.eventBus.expressionEvaluatedWithError, (callNumber: number, error: string, durationMillis: number) => {
      if(this.evaluateExpressionCallNo === callNumber) {
        this.onExpressionEvaluatedWithError(error, durationMillis);
      }
    })

    this.businessVariableInputServerModel = new BusinessVariableInputServerModel(filesService, emailsSharedService);

  }

  showData() {
    this.dataVisible = true;
    this.logsVisible = false;
    this.evaluatorVisible = false;
  }

  showLogs() {
    this.dataVisible = false;
    this.logsVisible = true;
    this.evaluatorVisible = false;
  }

  showEvaluator() {
    this.dataVisible = false;
    this.logsVisible = false;
    this.evaluatorVisible = true;
  }

  closeDebugInfo() {
    this.parent.closeDebugInfo();
  }

  evaluate() {
    this.evaluationPending = true;
    this.evaluateExpressionCallNo = this.serverModel.evaluateExpression(this.expressionToEvaluate, this.expressionContext);
  }

  private onExpressionEvaluated(result: BusinessVariable, durationMillis: number) {
    this.evaluationPending = false;
    this.evaluationCompleted = true;
    this.evaluationResult = [result];
    this.evaluationResultTypeName = result.simpleValueType();
    this.evaluationError = "";
    this.evaluationDurationMillis = durationMillis;
  }

  private onExpressionEvaluatedWithError(error: string, durationMillis: number) {
    this.evaluationPending = false;
    this.evaluationCompleted = true;
    this.evaluationResult = [];
    this.evaluationResultTypeName = "Error";
    this.evaluationError = error;
    this.evaluationDurationMillis = durationMillis;
  }
}
