import {AggregateId, AggregateVersion, ApplicationId, GroupId, I18nText, Option, OrganizationNodeId} from "@utils";

export class CreateGroup {
  constructor(readonly name: I18nText,
              readonly identifier: string,
              readonly applicationId: Option<ApplicationId>) {
  }
}

export class ChangeGroupInfo {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly name: I18nText,
              readonly description: I18nText,
              readonly identifier: string) {}
}

export class AddGroupToGroup {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {
  }
}

export class RemoveGroupFromGroup {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly groupId: GroupId) {}
}

export class DeleteGroup {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion) {}
}

export class AddGroupAuthorization {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly newEditorId: Option<OrganizationNodeId>,
              readonly newGroupTaskCreatorId: Option<OrganizationNodeId>,
              readonly newPersonTaskCreatorId: Option<OrganizationNodeId>) {}
}

export class RemoveGroupAuthorization {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly editorId: Option<OrganizationNodeId>,
              readonly groupTaskCreatorId: Option<OrganizationNodeId>,
              readonly personTaskCreatorId: Option<OrganizationNodeId>) {}
}

export class ChangeGroupAuthorizations {

  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly editors: Array<OrganizationNodeId>,
              readonly groupTaskCreators: Array<OrganizationNodeId>,
              readonly personTaskCreators: Array<OrganizationNodeId>) {}

}
