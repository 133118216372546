import {Md5} from "ts-md5";

export function toEncryptedJson(something: any, passphrase: string): string {
  return btoa(JSON.stringify(something));
  // return CryptoJS.AES.encrypt(JSON.stringify(something), passphrase);
}

export function decryptFromJson(encryptedJson: any, passphrase: string): any {
  return JSON.parse(atob(encryptedJson))
  // return JSON.parse(CryptoJS.AES.decrypt(encryptedJson, passphrase).toString(CryptoJS.enc.Utf8));
}

export function md5(input: string): string {
  return Md5.hashStr(input);
}
