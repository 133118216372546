import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MetadataEntryViewModel, MetadataFormViewModel} from "@shared-model";

@Component({
  selector: 'metadata-array',
  templateUrl: './metadata-array.component.html',
  host: {
    '[class.nameDisabled]': '!nameEnabled',
    '[class]': '"entryLevel"+entry.level'
  }
})
export class MetadataArrayComponent {
  @Input({required:true}) viewModel!: MetadataFormViewModel;
  @Input() entry!: MetadataEntryViewModel;
  @Input() validateType: boolean = true;
  @Input() showLabels: boolean = false;
  @Input() showNames: boolean = false;
  @Input() showTypes: boolean = false;
  @Input() nameEnabled: boolean = true;

  @Input() selectable: boolean = false;

  @Input() notRoot: boolean = false;
  @Output() sizeChanged = new EventEmitter<void>();
  @Output() selectedChanged = new EventEmitter<void>();
}
