import {
  __,
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdFactory,
  AnyPersonId,
  AnyPersonIdFactory,
  FileUri,
  FlowId,
  i18n,
  InstanceId,
  LocalDateTime,
  None,
  Option,
  OrganizationNodeId, ProcessId,
  Some,
  Typed
} from "@utils";
import {BasicPersonInfo} from "../organization-structure/PersonModel";
import {ContextPath} from "../variables/ContextPath";
import {TaskStatus, TaskTrackedTime} from "./TaskModel";
import {BusinessVariable, BusinessVariableFactory} from "../variables/BusinessVariables";
import {FlowCommentId, FlowCursorId, FlowCursorVersion, ProcessEdgeId, ProcessNodeId} from "./flows.shared-service";


export class TaskSummary {


  constructor(readonly nodeId: ProcessNodeId,
              readonly roleId: number,
              readonly cursorId: FlowCursorId,
              readonly seen: Array<Typed<AnyPersonId>>,
              readonly personsAssigned: Array<Typed<AnyPersonId>>,
              readonly created: LocalDateTime,
              readonly started: Option<LocalDateTime>,
              readonly deadline: Option<LocalDateTime>, // deadlive is available only for dynamically evaluated deadlines
              readonly overrodeDurationSeconds: Option<Option<number>>,
              readonly ended: Option<LocalDateTime>,
              readonly terminated: Option<LocalDateTime>,
              readonly variablesToEvaluate: Array<ContextPath>,
              readonly trackedTime: Array<TaskTrackedTime>,
              readonly taskStatus: TaskStatus,
              readonly searchSummary: string,
              readonly canChangeImportance: boolean,
              readonly canChangeLabels: boolean) {}


  personsAssignedUnwrapped() {
    return this.personsAssigned.map(p => Typed.value(p));
  }

  static copy(other:TaskSummary):TaskSummary {
    return new TaskSummary(
      other.nodeId,
      other.roleId,
      other.cursorId,
      other.seen.map(AnyPersonIdFactory.copyTyped),
      other.personsAssigned.map(AnyPersonIdFactory.copyTyped),
      LocalDateTime.copy(other.created),
      Option.copy(other.started).map(LocalDateTime.copy),
      Option.copy(other.deadline).map(LocalDateTime.copy),
      Option.copy(other.overrodeDurationSeconds, (m: Option<number>) => Option.copy(m)),
      Option.copy(other.ended).map(LocalDateTime.copy),
      Option.copy(other.terminated).map(LocalDateTime.copy),
      other.variablesToEvaluate.map(ContextPath.copy),
      other.trackedTime.map(TaskTrackedTime.copy),
      TaskStatus.copy(other.taskStatus),
      other.searchSummary,
      other.canChangeImportance,
      other.canChangeLabels
    );
  }
}

export class StatusWithMessage {
  constructor(readonly status: CursorStatus,
              readonly message: string) {}
}

export class PhaseWithStep {

  constructor(readonly phase: CursorPhase,
              readonly step: number) {}

  static copy(other: PhaseWithStep) {
    return new PhaseWithStep(CursorPhase.copy(other.phase), other.step);
  }
}

export class CursorStatus {
  constructor(readonly name: string) {}
  static ok = new CursorStatus("ok");
  static error = new CursorStatus("error");
}

export class CursorPosition {
  constructor(readonly nodeId: Option<ProcessNodeId>,
              readonly edgeId: Option<ProcessEdgeId>) {}

  static copy(other: CursorPosition): CursorPosition {
    return new CursorPosition(Option.copy(other.nodeId), Option.copy(other.edgeId))
  }

  isNode() {
    return this.nodeId.isDefined();
  }

  isEdge() {
    return this.edgeId.isDefined();
  }
}


export class ProcessSubFlow {

  constructor(readonly id: AggregateId,
              readonly nodeId: ProcessNodeId,
              readonly completed: boolean) {}

  static copy(other: ProcessSubFlow) {
    return new ProcessSubFlow(other.id, other.nodeId, other.completed);
  }
}

export class CursorPhase {
  constructor(readonly name: string) {}

  static copy(other: CursorPhase) {
    return new CursorPhase(other.name);
  }
}

export namespace CursorPhases {
  export class Condition {
    static condition = new CursorPhase("condition");
    static waitingForOther = new CursorPhase("waitingForOther");
  }

  export class Action {
    static before = new CursorPhase("before");
    static waitingForStart = new CursorPhase("waitingForStart");
    static inProgress = new CursorPhase("inProgress");
    static after = new CursorPhase("after");
    static completed = new CursorPhase("completed");
  }

  export class Edge {
    static delay = new CursorPhase("delay");
  }
}

export class ProcessCursor {

  constructor(readonly cursorId: FlowCursorId,
              public cursorVersion: FlowCursorVersion,
              readonly cursorPosition: CursorPosition,
              readonly currentSLA: Option<LocalDateTime>,
              readonly phase: PhaseWithStep,
              readonly status: StatusWithMessage) {}

  static copy(other: ProcessCursor) {
    return new ProcessCursor(other.cursorId, other.cursorVersion,
      CursorPosition.copy(other.cursorPosition), other.currentSLA, other.phase, other.status);
  }

  isInProgress() {
    return this.status.status.name === CursorStatus.ok.name &&
      this.phase.phase.name === CursorPhases.Action.inProgress.name
  }

  isDelayed() {
    return this.status.status.name === CursorStatus.ok.name &&
      this.phase.phase.name === CursorPhases.Edge.delay.name
  }

  isWaitingForStart() {
    return this.status.status.name === CursorStatus.ok.name &&
      this.phase.phase.name === CursorPhases.Action.waitingForStart.name;
  }

  isError() {
    return this.status.status.name === CursorStatus.error.name;
  }
}


export class ParentFlowCursor {
  constructor(readonly cursorId: FlowCursorId,
              readonly cursorVersion: FlowCursorVersion) {}

  static copy(other: ParentFlowCursor) {
    return new ParentFlowCursor(other.cursorId, other.cursorVersion);
  }
}

export class FlowStatus {
  constructor(readonly name: string) {}

  translate(): string {
    return i18n("enum_case_status_" + this.name);
  }
  static created = new FlowStatus("created");
  static inProgress = new FlowStatus("inProgress");
  static terminated = new FlowStatus("terminated");
  static completed = new FlowStatus("completed");

  static order = [FlowStatus.created, FlowStatus.inProgress, FlowStatus.terminated, FlowStatus.completed];
  static usable = [FlowStatus.inProgress, FlowStatus.terminated, FlowStatus.completed];

  static compare(a: FlowStatus, b: FlowStatus): number {
    return __(FlowStatus.order).findIndexOf(s => s.name === a.name).getOrElse(0) - __(FlowStatus.order).findIndexOf(s => s.name === b.name).getOrElse(0);
  }

  static copy(other: FlowStatus) {
    return new FlowStatus(other.name);
  }

  static byName(name: string):FlowStatus {
    switch(name) {
      case FlowStatus.created.name: return FlowStatus.created;
      case FlowStatus.inProgress.name: return FlowStatus.inProgress;
      case FlowStatus.terminated.name: return FlowStatus.terminated;
      case FlowStatus.completed.name: return FlowStatus.completed;
      default: throw new Error("Not supported flow status ["+name+"]")
    }
  }
}

export class FlowStatusInfo {

  constructor(readonly created: LocalDateTime,
              readonly started: Option<LocalDateTime>,
              readonly finished: Option<FinishInfo>,
              readonly finishNodesReached: Array<number>) {}

  static copy(other: FlowStatusInfo) {
    return new FlowStatusInfo(
      LocalDateTime.copy(other.created),
      Option.copy(other.started).map(LocalDateTime.copy),
      Option.copy(other.finished).map(FinishInfo.copy),
      other.finishNodesReached.slice());
  }

  flowStatus() {
    if (this.started.isEmpty()) {
      return FlowStatus.created;
    } else if (this.finished.isDefined() && this.finished.get().status.isCompleted()) {
      return FlowStatus.completed;
    } else if (this.finished.isDefined() && this.finished.get().status.isTerminated()) {
      return FlowStatus.terminated;
    } else if (this.finished.isDefined()) {
      throw new Error("Unknown flow finish status " + this.finished.get().status.name)
    } else {
      return FlowStatus.inProgress;
    }
  }

  isCreated() {
    return this.flowStatus() === FlowStatus.created;
  }
  isInProgress() {
    return this.flowStatus() === FlowStatus.inProgress;
  }
  isCompleted() {
    return this.flowStatus() === FlowStatus.completed;
  }
  isTerminated() {
    return this.flowStatus() === FlowStatus.terminated;
  }
}


export class FlowNameInfo {
  constructor(readonly flowCode: string,
              readonly flowDescription: string) {}

  static copy(other: FlowNameInfo) {
    return new FlowNameInfo(other.flowCode,
      other.flowDescription);
  }
}

export class FlowParentsIds {
  constructor(readonly parentsFlowsIds: Array<AggregateId>,
              readonly parentFlowCursor: Option<ParentFlowCursor>,
              readonly subFlows: Array<ProcessSubFlow>,
              readonly organizationId: AggregateId,
              readonly processId: AggregateId,
              readonly processReleaseId: AggregateId,
              readonly processInstanceId: AggregateId) {

  }

  static copy(other: FlowParentsIds) {
    return new FlowParentsIds(other.parentsFlowsIds.slice(), Option.copy(other.parentFlowCursor).map(ParentFlowCursor.copy),
      other.subFlows.map(ProcessSubFlow.copy),
      AggregateId.copy(other.organizationId),
      AggregateId.copy(other.processId),
      AggregateId.copy(other.processReleaseId),
      AggregateId.copy(other.processInstanceId));
  }
}

export class FlowBasicInfo {
  constructor(readonly flowId: FlowId,
              readonly processId: ProcessId,
              readonly flowCode: Option<string>,
              readonly flowDescription: string,
              readonly started: LocalDateTime) {}

  static copy(other: FlowBasicInfo) {
    return new FlowBasicInfo(FlowId.copy(other.flowId),
      ProcessId.of(other.processId),
      Option.copy(other.flowCode),
      other.flowDescription,
      LocalDateTime.copy(other.started));
  }
}

export class FoundFlow {

  constructor(readonly id: FlowId,
              readonly startDate: Option<LocalDateTime>) {}

  static copy(other: FoundFlow) {
    return new FoundFlow(FlowId.copy(other.id), Option.copy(other.startDate));
  }
}

export class FlowInfoForUser {
  constructor(
    readonly flowId: Typed<AnyFlowId>,
    readonly processId: AggregateId,
    readonly instanceId: InstanceId,
    readonly flowCode: string,
    readonly flowDescription: string,
    readonly created: LocalDateTime,
    readonly started: Option<LocalDateTime>,
    readonly finished: Option<FinishInfo>,
    readonly commentsCount: number,
    readonly finishNodesReached: Array<number>,
    readonly importance: number,
    readonly urgency: number) {}

  static copy(other: FlowInfoForUser) {
    return new FlowInfoForUser(
      AnyFlowIdFactory.copyTyped(other.flowId),
      AggregateId.copy(other.processId),
      InstanceId.of(other.instanceId),
      other.flowCode,
      other.flowDescription,
      LocalDateTime.copy(other.created),
      Option.copy(other.started, LocalDateTime.copy),
      Option.copy(other.finished, FinishInfo.copy),
      other.commentsCount,
      other.finishNodesReached.slice(),
      other.importance,
      other.urgency
    );
  }
}


export class FinishInfo {

  constructor(readonly finished: LocalDateTime,
              readonly status: FinishStatus,
              readonly personId: Option<Typed<AnyPersonId>>) {}

  personIdUnwrapped() {
    return this.personId.forEach(p => Typed.value(p))
  }

  static copy(other: FinishInfo) {
    return new FinishInfo(LocalDateTime.copy(other.finished), FinishStatus.copy(other.status), Option.copy(other.personId).map(AnyPersonIdFactory.copyTyped));
  }
}


export class FinishStatus {
  constructor(readonly name: string) {}
  static completed = new FinishStatus("completed");
  static terminated = new FinishStatus("terminated");

  isCompleted() {
    return this.name === "completed";
  }

  isTerminated() {
    return this.name === "terminated";
  }

  static copy(other: FinishStatus) {
    switch (other.name) {
      case "completed": return FinishStatus.completed;
      case "terminated": return FinishStatus.terminated;
      default: throw new Error("Unsupported finish status '"+JSON.stringify(other)+"'");
    }
  }
}

export class ProcessFlowComment {

  avatar: number = 0;

  constructor(readonly commentId: FlowCommentId,
              readonly person: Option<Typed<AnyPersonId>>,
              public personInfo: Option<CommentPersonInfo>, // client side only
              readonly nodeId: Option<number>,
              readonly commentText: string,
              readonly attachments: Array<FileUri>,
              readonly extraRecipients: Array<Typed<AnyPersonId>>,
              readonly previousCommentVersions: Array<PreviousCommentVersion>,
              readonly deleted: boolean,
              readonly created: LocalDateTime) {}

  personUnwrapped(): Option<AnyPersonId> {
    return this.person.map(p => Typed.value(p));
  }

  extraRecipientsUnwrapped(): Array<AnyPersonId> {
    return this.extraRecipients.map(p => Typed.value(p));
  }

  static copy(other: ProcessFlowComment): ProcessFlowComment {
    return new ProcessFlowComment(
      other.commentId,
      Option.copy(other.person).map(AnyPersonIdFactory.copyTyped),
      other.personInfo ? Option.copy(other.personInfo) : None(),
      Option.copy(other.nodeId),
      other.commentText,
      other.attachments.map(FileUri.copy),
      other.extraRecipients.map(AnyPersonIdFactory.copyTyped),
      other.previousCommentVersions.map(PreviousCommentVersion.copy),
      other.deleted,
      LocalDateTime.copy(other.created));
  }

  isValid(): boolean {
    return this.commentText.trim().length > 0;
  }

  static ofSimpleText(id: FlowCommentId, comment: string): ProcessFlowComment {
    return new ProcessFlowComment(id, None(), None(), None(), comment, [], [], [], false, LocalDateTime.now())
  }

}

export class PreviousCommentVersion {
  constructor(
    readonly comment: string,
    readonly created: LocalDateTime) {}

  static copy(other: PreviousCommentVersion) {
    return new PreviousCommentVersion(
      other.comment,
      LocalDateTime.copy(other.created));
  }

}


export class CommentPersonInfo {

  constructor(readonly personId: AnyPersonId,
              readonly firstName: string,
              readonly lastName: string,
              readonly email: Option<string>) {
    this.personId = personId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  simpleName() {
    return this.firstName+" "+this.lastName;
  }

  initials() {
    return this.firstName.charAt(0)+this.lastName.charAt(0);
  }

  static fromPersonInfo(info: BasicPersonInfo) {
    return new CommentPersonInfo(info.idUnwrapped(), info.firstName, info.lastName, Some(info.email));
  }
}


export enum FlowUrgency {
   backlog = 0,
  low = 1,
  normal = 2,
  height = 3,
  critical = 4,
}

export class FlowUrgencyList {
  static DEFAULT = FlowUrgency.normal;
  static all = [FlowUrgency.backlog, FlowUrgency.low, FlowUrgency.normal, FlowUrgency.height, FlowUrgency.critical];
}

export enum FlowImportance {
  low = 1,
  normal = 2,
  height = 3,
  critical = 4
}

export class FlowImportanceList {
  static DEFAULT = FlowImportance.normal;
  static all = [FlowImportance.low, FlowImportance.normal, FlowImportance.height, FlowImportance.critical];
  static selectable = [FlowImportance.critical, FlowImportance.height, FlowImportance.normal];
}


export class ProcessFlowSummary {
  constructor(readonly flowId: AggregateId,
              readonly initialPersonId: Option<Typed<AnyPersonId>>,
              readonly parentsIds: FlowParentsIds,
              readonly flowNameData: FlowNameInfo,
              readonly statusInfo: FlowStatusInfo,
              readonly rolesNodeLists: Array<[number, Array<OrganizationNodeId>]>,
              public assignedPersons: Array<[number, Array<Typed<AnyPersonId>>]>,
              readonly systemLabels: Array<Typed<BusinessVariable>>,
              readonly labels: Array<string>,
              readonly currentTasksSummary: Array<TaskSummary>,
              readonly cursors: Array<ProcessCursor>,
              readonly deadlines: Array<[number, LocalDateTime]>,
              readonly color: Option<string>,
              readonly importance: FlowImportance,
              readonly urgency: FlowUrgency,
              readonly comments: number) {}

  assignedPersonsUnwrapped(): Array<[number, Array<AnyPersonId>]> {
    return this.assignedPersons.map((ap: [number, Array<Typed<AnyPersonId>>]) => <[number, Array<AnyPersonId>]>[ap[0], ap[1].map(p => Typed.value(p))]);
  }

  assignedPersonsForRole(roleId: number): Array<AnyPersonId> {
    return __(this.assignedPersonsUnwrapped())
      .find((ap: [number, Array<AnyPersonId>]) => ap[0] === roleId)
      .map((ap: [number, Array<AnyPersonId>]) => ap[1])
      .getOrElse([]);
  }

  deadlineFor(nodeId: ProcessNodeId): Option<LocalDateTime> {
    return Option.of(this.deadlines.filter(d => d[0] === nodeId)[0]).map(d => d[1]);
  }

  cursorById(cursorId: FlowCursorId): Option<ProcessCursor> {
    return __(this.cursors).find(c => c.cursorId === cursorId);
  };

  static copy(other: ProcessFlowSummary) {
    return new ProcessFlowSummary(
      other.flowId,
      Option.copy(other.initialPersonId,
      AnyPersonIdFactory.copyTyped),
      FlowParentsIds.copy(other.parentsIds),
      FlowNameInfo.copy(other.flowNameData),
      FlowStatusInfo.copy(other.statusInfo),
      other.rolesNodeLists.map(e => <[number, Array<OrganizationNodeId>]> [e[0], e[1].map(OrganizationNodeId.copy)]),
      other.assignedPersons.map((e: [number, Array<Typed<AnyPersonId>>]) => <[number, Array<Typed<AnyPersonId>>]>[e[0], e[1].map(AnyPersonIdFactory.copyTyped)]),
      other.systemLabels.map(BusinessVariableFactory.copyTyped),
      other.labels.slice(),
      other.currentTasksSummary.map(TaskSummary.copy),
      other.cursors.map(ProcessCursor.copy),
      other.deadlines.map((deadline: [number, LocalDateTime]) => <[number, LocalDateTime]>[deadline[0], LocalDateTime.copy(deadline[1])]),
      Option.copy(other.color),
      other.importance,
      other.urgency,
      other.comments
    );
  }

  initialPersonIdUnwrapped() {
    return this.initialPersonId.map(p => Typed.value(p));
  }

  systemLabelsUnwrapped() {
    return this.systemLabels.map(l => Typed.value(l));
  }

  getFlowId() {
    return FlowId.of(this.flowId);
  }
}

export class ProcessFlowEventInfo {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowVersion: AggregateVersion,
              readonly event: Typed<any>) {}

  static className() {
    return "ProcessFlowEventInfo";
  }

  flowIdUnwrapped() {
    return Typed.value(this.flowId);
  }
}
