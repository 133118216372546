import {required, valueOrDefault} from "@utils";

export type RequestResultStatus = "found"|"notFound"|"notAuthorized"|"error";

export class RequestResult<T> {

  constructor(readonly status: RequestResultStatus,
              private readonly value?: T,
              private readonly message?: string) {}

  get(): T {
    if(this.status === "found") {
      return required(this.value, "value");
    } else {
      throw new Error("Trying to get value from '" + this.status+"'");
    }
  }

  getOrError(error: string) {
    if(this.status === "found") {
      return required(this.value, "value");
    } else {
      throw new Error(error);
    }
  }

  getMessage() {
    if(this.status === "found") {
      throw new Error("Trying to get message from '" + this.status+"'");
    } else {
      return valueOrDefault(this.message, "");
    }
  }

  isFound() {
    return this.status === "found";
  }

  isNotFound() {
    return this.status === "notFound";
  }

  isNotAuthorized() {
    return this.status === "notAuthorized";
  }

  isError() {
    return this.status === "error";
  }

  static copy<T>(result: RequestResult<T>, copy?: (other: T) => T): RequestResult<T> {

    if(result.status === "found") {
      return new RequestResult("found", copy ? copy(required(result.value, "value")) : result.value);
    } else if(result.status === "notFound") {
      return ResultNotFound();
    } else if(result.status === "notAuthorized") {
      return ResultNotAuthorized();
    } else if(result.status === "error") {
      return new RequestResult<T>("error", undefined, result.message);
    } else {
      throw new Error("Unknown status: " + result.status);
    }
  }


  getOrNull(): T|null {
    if(this.status === "found") {
      return required(this.value, "value");
    } else {
      return null;
    }
  }
}


export function ResultFound<E>(value: E): RequestResult<E> {
  return new RequestResult("found", value);
}

export function ResultNotFound<E>(): RequestResult<E> {
  return new RequestResult<E>("notFound");
}

export function ResultNotAuthorized<E>(): RequestResult<E> {
  return new RequestResult<E>("notAuthorized");
}

export function ResultError<E>(message: string): RequestResult<E> {
  return new RequestResult<E>("error", undefined, message);
}
