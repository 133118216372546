import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BooleanVariable, BusinessVariable, VariablePath} from "@shared-model";
import {Option, Trilean} from "@utils";

export class BooleanVariableInputViewModel {

  previewText: string|null = null
  internalModel: boolean = false;
  internalModelBackup: boolean = false;

  constructor(readonly path: VariablePath,
              public value: BusinessVariable|null,
              public readOnly: boolean,
              public preview: boolean,
              readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>) {
    this.updatePreviewText();
    this.initInternalModel();
  }

  private updatePreviewText() {
    this.previewText = this.value === null ? "-" : this.value.valueToSimpleString();
  }

  initInternalModel() {
    if (this.value == null) {
      this.internalModel = false;
    } else {
      this.internalModel = this.value.value;
    }
    this.internalModelBackup = this.internalModel;
  }

  private changeVariableValue(value: BusinessVariable|null) {
    this.value = value;
    this.updatePreviewText();
    this.initInternalModel();
    this.change.emit({value: value, path: this.path});
  }

  onInternalChange() {
    if(this.internalModelChanged()) {
      this.changeVariableValue(Option.of(this.internalModel).map(BooleanVariable.of).getOrNull());
      this.internalModelBackup = this.internalModel;
    }
  }

  private internalModelChanged() {
    return this.internalModel !== this.internalModelBackup;
  }

  updateValue(value: BusinessVariable|null) {
    if(value === null || value instanceof BooleanVariable) {
      this.value = value;
      this.initInternalModel();
    } else {
      throw new Error("Incorrect variable type, expected 'Boolean' but was '"+value.simpleValueType()+"'");
    }
  }

  updateMode(readOnly: boolean, preview: boolean) {
    this.readOnly = readOnly;
    this.preview = preview;
  }

  backupInternalValue() {
    this.internalModelBackup = this.internalModel;
  }
}


@Component({
  selector: "my-boolean-variable-input",
  templateUrl: "./boolean-variable-input.component.html"
})
export class BooleanVariableInputComponent implements OnInit {
  private _value!: BusinessVariable|null;
  get value(): BusinessVariable|null {return this._value;}
  @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}


  private _readOnly: boolean = false;
  get readOnly(): boolean {return this._readOnly;}
  @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

  private _preview: boolean = false;
  get preview(): boolean {return this._preview;}
  @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

  @Input() path!: VariablePath;
  @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  viewModel!: BooleanVariableInputViewModel;


  private onValueChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateValue(this._value);
    }
  }

  private onModeChanged() {
    if(this.viewModel) { // might be undefined during initialization
      this.viewModel.updateMode(this.readOnly, this.preview);
    }
  }

  ngOnInit(): void {
    this.viewModel = new BooleanVariableInputViewModel(this.path, this._value, this._readOnly, this._preview, this.change);
  }
}
