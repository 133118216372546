/**
 * Immutable class that can be used to change PositionXY coordinates by given values.
 */
export class ShiftXY {
  static ZERO: ShiftXY = new ShiftXY(0, 0);
  constructor(readonly x: number,
              readonly y: number) {}

  static copy(other: ShiftXY) {
    return new ShiftXY(other.x, other.y);
  }
}
