/**
 * Immutable value holder for pixel coordinates.
 */
import {Size} from "./Size";
import {ShiftXY} from "./ShiftXY";
import {RectXY} from "./RectXY";

export class PositionXY {

  constructor(readonly x:number, readonly y:number) {}


  /**
   * It returns new Position instance with coordinates shifted by given value.
   */
  shift(shift: ShiftXY) {
    return new PositionXY(this.x + shift.x, this.y + shift.y);
  }

  shiftXY(shiftX: number, shiftY: number) {
    return new PositionXY(this.x + shiftX, this.y + shiftY);
  }

  minus(other: PositionXY) {
    return new Size(this.x - other.x, this.y - other.y);
  }

  minusToPosition(other: PositionXY) {
    return new PositionXY(this.x - other.x, this.y - other.y);
  }

  plusToPosition(other: PositionXY) {
    return new PositionXY(this.x + other.x, this.y + other.y);
  }

  scale(scale: number) {
    return new PositionXY(this.x * scale, this.y * scale);
  }

  isEqual(other: PositionXY) {
    return this.x === other.x && this.y === other.y;
  }

  nonEqual(other: PositionXY) {
    return !this.isEqual(other);
  }

  round() {
    return new PositionXY(Math.round(this.x), Math.round(this.y));
  }

  toString() {
    return `(${this.x}, ${this.y})`;
  }

}


export interface PointerXY {
  x: number;
  y: number;
}
