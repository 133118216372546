import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {I18nService, Translation} from "..";

@Injectable({ providedIn: 'root' })
export class TranslationsResolver implements Resolve<boolean> {
  constructor(readonly i18nService: I18nService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|any {
    return this.loadTranslations(...<Array<Translation>>route.data["translations"]);
  }

  loadTranslations(...translations: Array<Translation>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.i18nService.initialize(translations).then((value: boolean) => {
        resolve();
      });
    });
  }
}
