import {AggregateId, AggregateVersion, GridXY, GridXYPath, I18nText, LocalDateTime} from "@utils";
import {NodeType} from "@shared-model";

export class EdgeType {
  constructor(readonly name: string) {}

  isNormal() {
    return this.name === EdgeType.normal.name;
  }

  isAnyAlternative() {
    return this.name === EdgeType.alternative.name ||
      this.name === EdgeType.actionUserRedirect.name ||
      this.name === EdgeType.actionWait.name ||
      this.name === EdgeType.actionDelay.name;
  }

  isAnyDelayAlternative() {
    return this.name === EdgeType.actionWait.name || this.name === EdgeType.actionDelay.name;
  }

  isActionRedirect() {
    return this.name === EdgeType.actionUserRedirect.name;
  }

  isActionWait() {
    return this.name === EdgeType.actionWait.name;
  }

  isActionDelay() {
    return this.name === EdgeType.actionDelay.name;
  }

  static normal = new EdgeType("normal");
  /**
   * @deprecated - use actionRedirect instead
   */
  static alternative = new EdgeType("alternative"); // redirect
  static actionUserRedirect = new EdgeType("actionUserRedirect"); // redirect
  static actionWait = new EdgeType("actionWait");
  static actionDelay = new EdgeType("actionDelay");


  static copy(other: EdgeType) {
    switch (other.name) {
      case EdgeType.normal.name: return EdgeType.normal;
      case EdgeType.alternative.name: return EdgeType.alternative;
      case EdgeType.actionUserRedirect.name: return EdgeType.actionUserRedirect;
      case EdgeType.actionWait.name: return EdgeType.actionWait;
      case EdgeType.actionDelay.name: return EdgeType.actionDelay;
      default: throw new Error("Unsupported EdgeType " + other.name);
    }
  }

  isUserRedirect() {
    return this.name === EdgeType.actionUserRedirect.name;
  }
}

export class MiniGridProcessModel {

  constructor(readonly lastUpdated: LocalDateTime,
              readonly version: AggregateVersion,
              readonly processId: AggregateId,
              readonly releaseId: AggregateId,
              readonly nodes: MiniNode[],
              readonly edges: MiniEdge[]) {}

  static copy(other: MiniGridProcessModel): MiniGridProcessModel {
    return new MiniGridProcessModel(
      LocalDateTime.copy(other.lastUpdated),
      other.version,
      AggregateId.copy(other.processId),
      AggregateId.copy(other.releaseId),
      other.nodes.map(MiniNode.copy),
      other.edges.map(MiniEdge.copy)
    )
  }
}

export class MiniNode {

  constructor(readonly label: I18nText,
              readonly nodeType: NodeType,
              readonly gridXY: GridXY,
              readonly automatic: boolean) {}

  static copy(other: MiniNode) {
    return new MiniNode(I18nText.copy(other.label), NodeType.copy(other.nodeType, other.automatic), GridXY.copy(other.gridXY), other.automatic);
  }
}

export class MiniEdge {

  constructor(readonly fromNodeId: number,
              readonly toNodeId: number,
              readonly edgePath: GridXYPath,
              readonly edgeType: EdgeType) {}

  static copy(other: MiniEdge) {
    return new MiniEdge(other.fromNodeId, other.toNodeId, GridXYPath.copy(other.edgePath), EdgeType.copy(other.edgeType));
  }
}
