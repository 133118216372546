import {Injectable} from "@angular/core";
import {
  __,
  ___,
  AnyFlowId,
  FileUri,
  FlowId,
  FormElementRefId,
  ObjectId,
  Option,
  RemoteFlowId,
  toastr,
  Typed
} from "@utils";
import {
  FileBasicInfo,
  FileBasicInfoFactory,
  FileRequestResult,
  FileRequestResultFactory,
  FormSectionRefId,
  GetFilesInfo
} from "@shared";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {ProcessNodeId} from "../flow-and-task/flows.shared-service";

@Injectable({
  providedIn: 'root',
})
export class FilesSharedService {

    constructor(readonly authenticatedHttp: AuthenticatedHttp) {}


    filesInfoDetailed(filesInfoRequest: number, filesUri: Array<FileUri>, onSuccess: (filesInfoRequest: number, files: Array<FileRequestResult<Typed<FileBasicInfo>>>) => void) {
      if(filesUri.length === 0) {
        onSuccess(filesInfoRequest, []);
      } else {
        this.authenticatedHttp.post("files/info", new GetFilesInfo(filesUri), (data: Array<Typed<FileRequestResult<Typed<FileBasicInfo>>>>) => {
          const result = data.map(d => FileRequestResultFactory.copyTypedAndUnwrap<Typed<FileBasicInfo>>(d, c => FileBasicInfoFactory.copyTyped(c)));
          onSuccess(filesInfoRequest, result);
        });
      }
    }

    filesInfo(filesUri: Array<FileUri>, onSuccess: (files: Array<FileBasicInfo>) => void) {
      if(filesUri.length === 0) {
        onSuccess([]);
      } else {
        this.authenticatedHttp.post("files/info", new GetFilesInfo(filesUri), (data: Array<Typed<FileRequestResult<Typed<FileBasicInfo>>>>) => {
            const result = data.map(d => FileRequestResultFactory.copyTypedAndUnwrap<Typed<FileBasicInfo>>(d, c => FileBasicInfoFactory.copyTyped(c)));
            if (__(result).all(r => r.isSuccess())) {
              onSuccess(result.map(r => Typed.value(r.asSuccess().file)));
            } else {
              toastr.error("Error while getting files info: " + ___(result).filter(r => !r.isSuccess()).map(r => r.errorMessage()).value().join(", "));
            }
          }
        );
      }
    }

    filesInfoFromTask(filesInfoRequest: number, flowId: AnyFlowId, nodeId: ProcessNodeId, formSectionRefId: FormSectionRefId,
                      fieldRefId: FormElementRefId, contextObjectId: Option<ObjectId>, filesUri: Array<FileUri>,
                      onSuccess: (filesInfoRequest: number, files: Array<FileRequestResult<Typed<FileBasicInfo>>>) => void) {
      if(filesUri.length === 0) {
        onSuccess(filesInfoRequest, []);
      } else if(flowId instanceof FlowId) {
        this.filesInfoDetailed(filesInfoRequest, filesUri.map(uri => FileUri.taskFormField(uri, flowId, nodeId, formSectionRefId.id, fieldRefId.id, contextObjectId.map(c => c.id))), onSuccess);
      } else if(flowId instanceof RemoteFlowId) {
       throw new Error("Not supported")
      }
    }

    filesInfoFromFlow(filesInfoRequest: number, flowId: AnyFlowId, filesUri: Array<FileUri>,
                      onSuccess: (filesInfoRequest: number, files: Array<FileRequestResult<Typed<FileBasicInfo>>>) => void) {
      if(filesUri.length === 0) {
        onSuccess(filesInfoRequest, []);
      } else if(flowId instanceof FlowId) {
        this.filesInfoDetailed(filesInfoRequest, filesUri.map(uri => FileUri.flow(uri, flowId)), onSuccess);
      } else if(flowId instanceof RemoteFlowId) {
       toastr.info("Getting files info from remote flow is not supported");
      }
    }

  }
