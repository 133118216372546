import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  $$,
  __,
  AggregateId,
  AnyPersonId,
  AnyPersonIdHelper,
  FileUri,
  global,
  htmlEscape,
  i18n,
  mySetTimeout,
  None,
  now,
  Option,
  OrganizationNodeId,
  prettySize,
  randomString,
  required,
  SimpleFileUploaderController,
  Some,
  toastr,
  UploadFormFileResponse,
  UploadFormFileSuccess
} from "@utils";
import {CommentAttachmentViewModel, CommentViewModel} from "./comments.component";
import {BasicOrganizationNodeInfo} from "../organization-structure/OrganizationNodesModel";
import {
  LibrariesService,
  OrganizationSessionInfoClientSide,
  SessionServiceProvider,
  ViewableFile,
  ViewableFileUrl
} from "@shared";
import {Observable, Subject, Subscription} from "rxjs";

declare const qq: any;

@Component({
  selector: "my-new-comment",
  templateUrl: "./new-comment.component.html",
})
export class NewCommentComponent implements OnInit, OnDestroy {
  @Input() uploadParams: {} = {};
  @Input() uploadFileUrl: string = "";
  @Input() attachmentDownloadUrl: ((fileInfo: FileUri) => ViewableFileUrl)|undefined;
  @Input() attachments: boolean = true;
  @Input() notifications: boolean = true;
  notificationSelectorVisible: boolean = false;
  errorMessage: string|undefined;

  private _activeOnShow = false;
  @Input() set activeOnShow(active: boolean) {
    this._activeOnShow = active;
    if (active) {
      this.activated = true;
    }
  }

  @Output() showAttachment = new EventEmitter<{viewableFiles: Array<ViewableFile>, fileIndex: number}>();

  @ViewChild("uploadFile") uploadFileContainer?: ElementRef<HTMLDivElement>;

  focused = false;
  commentInvalid = false;

  newComment = CommentViewModel.empty();
  @HostBinding("class.activated") activated = false;
  restrictedNodes: Array<AggregateId> = [];

  private readonly subscriptions: Array<Subscription> = [];

  private newSize = 0;

  readonly elementId = randomString(10);

  private fileUploadController!: SimpleFileUploaderController<UploadFormFileResponse>;

  notifeeResultsChanged = new Subject<void>();

  @Output() commentAdded = new EventEmitter<CommentViewModel>();

  @Input() lastCommentReloaded?: Observable<CommentViewModel>;


  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly librariesService: LibrariesService) {
  }

  ngOnInit() {
    this.librariesService.loadFineUploader(() => {
      mySetTimeout(() => {
        this.sessionServiceProvider.getOrganizationSessionInfo((sesionInfo: OrganizationSessionInfoClientSide) => {

          if (this.attachments && this.uploadFileContainer) {
            this.fileUploadController = new SimpleFileUploaderController(
              this.uploadFileUrl,
              this.uploadFileContainer.nativeElement,
              sesionInfo.sessionToken, [],
              20 * 1024 * 1024,
              false, true,
              () => {},
              (id: number, name: string, responseJSON: UploadFormFileResponse) => this.onFileUploadComplete(id, name, UploadFormFileSuccess.copy(<UploadFormFileSuccess>responseJSON)),
              (id, fileName, instance) => this.onFileUpload(id, fileName, instance),
              (file: {name: string, size: number}): boolean => this.onFileUploadValidate(file),
              (id: any, name: any, reason: any, request: any) => this.onFileUploadError(id, name, reason, request),
              (id: number, name: string, uploadedBytes: number, totalBytes: number) => this.onFileUploadProgress(id, name, uploadedBytes, totalBytes),
            );
          }


          this.restrictedNodes = [sesionInfo.personId];
        });

        this.clearNewComment();
        this.createSubscriptions();

        $$(document)
          .on("dragenter", this.onDragEnter)
          .on("dragleave", this.onDragLeave)
          .on("drop", this.onDrop);

        if (this.attachments) {
          new qq.DragAndDrop({
            dropZoneElements: [$$(this.viewContainerRef).findOrError(".newCommentContent").getAsHtmlElement()],
            classes: {
              dropActive: "theme-file-drop-zone-active"
            },
            callbacks: {
              processingDroppedFiles: function () {
                //TODO: display some sort of a "processing" or spinner graphic
              },
              processingDroppedFilesComplete: (files: string|Array<any>, dropTarget: any) => {
                //TODO: hide spinner/processing graphic
                this.activated = true;

                // 0 elements can appear for files with strange names on Chrome - dnd module of fine uploader uses
                // webkitGetAsEntry method which does not work for strange file names
                if (files.length > 0) {
                  this.fileUploadController.fineUploaderBasicInstance.addFiles(files); //this submits the dropped files to Fine Uploader
                } else {
                  toastr.error("File name not allowed");
                }
              }
            }
          });
        }
      });
    });

  }

  private onDragEnter = (e: DragEvent) => {
    console.log("drag over");
    const dt = e.dataTransfer;
    if (dt !== null && dt.types && (dt.types.indexOf ? dt.types.indexOf("Files") != -1 : __(<Array<string>>dt.types).contains("Files"))) {
      $$(this.viewContainerRef).findOrError(".newCommentContent")
        .classed("theme-file-drop-zone", true);
    }
  };

  private onDragLeave = (e: DragEvent) => {
    if (e.relatedTarget == null) { //moves outside window, otherwise it's other element that can handle drop
      $$(this.viewContainerRef).findOrError(".newCommentContent")
        .classed("theme-file-drop-zone", false);
    }
  };

  private onDrop = (e: DragEvent) => {
    $$(this.viewContainerRef).findOrError(".newCommentContent")
      .classed("theme-file-drop-zone", false);
  };

  onEventBusChange() {
    this.destroySubscriptions();
    this.createSubscriptions();
  }


  onReceiverAdded = (basicOrganizationNodeInfo: BasicOrganizationNodeInfo) => {
    this.newComment.extraRecipients.push(basicOrganizationNodeInfo.asPerson().idUnwrapped());
  };

  onReceiverDeleted = (basicOrganizationNodeInfo: BasicOrganizationNodeInfo) => {
    this.newComment.extraRecipients = this.newComment.extraRecipients.filter(recipient => !AnyPersonIdHelper.equals(recipient, basicOrganizationNodeInfo.asPerson().idUnwrapped()));
  };

  onAttachmentDeleted = (attachmentInfo: CommentAttachmentViewModel) => {
    this.newComment.attachments = this.newComment.attachments.filter(attachment => attachment.path !== attachmentInfo.uri.get().path);
    this.newComment.attachmentsInfo = Some(this.newComment.attachmentsInfo.get().filter(attachment => attachment.uri.get().path !== attachmentInfo.uri.get().path));
  };


  private createSubscriptions() {

    if(this.lastCommentReloaded) {
      this.subscriptions.push(this.lastCommentReloaded.subscribe((comment: CommentViewModel) => {
        this.activated = true;
        this.newComment = comment;
      }));
    }

  }

  private destroySubscriptions() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  cancelComment = () => {
    this.activated = false;
    this.clearNewComment();
  };

  acceptComment() {
    setTimeout(async () => {
      this.sendComment();
    });
  }

  sendComment = async () => {
    const sessionInfo = await this.sessionServiceProvider.getOrganizationSessionInfoPromise();
    this.commentInvalid = !this.newComment.isValid();
    if (this.commentInvalid) {
      this.errorMessage = i18n("comment_can_not_be_empty");
    } else {
      this.newComment.commentId = Math.round(- Math.random() * 1000000000);
      this.newComment.setAuthor(OrganizationNodeId.fromPersonId(sessionInfo.personId));
      this.commentAdded.emit(this.newComment);
      this.activated = false;
      this.clearNewComment();
    }
  };

  onBlur = () => {
    this.commentInvalid = !this.newComment.isValid();
  };


  private onFileUploadComplete(id: number, name: string, responseJSON: UploadFormFileSuccess) {
    if (this.attachmentDownloadUrl === undefined) {
      throw new Error("attachmentDownloadUrl is not defined");
    } else {
      this.newComment.attachmentsInfo = Some(this.newComment.attachmentsInfo.get().map(f => {
        if (f.name === name && f.id === id) {
          return new CommentAttachmentViewModel(f.name, Some(responseJSON.fileUri), required(this.attachmentDownloadUrl, "attachmentDownloadUrl")(responseJSON.fileUri), f.size, Math.max(f.version, 1), true, id, f.modified);
        } else {
          return f;
        }
      }));
      this.newComment.attachments.push(responseJSON.fileUri);
      this.activated = true;
    }
  }

  private onFileUpload(id: number, fileName: string, fineUploaderBasicInstance: any) {
    fineUploaderBasicInstance.setParams(this.uploadParams);
    if (this.newComment.attachmentsInfo.isDefined()) {
      this.newComment.attachmentsInfo.get().push(new CommentAttachmentViewModel(fileName, None(), ViewableFileUrl.empty, this.newSize, 0, false, id, now()));
    } else {
      this.newComment.attachmentsInfo = Some([new CommentAttachmentViewModel(fileName, None(), ViewableFileUrl.empty, this.newSize, 0, false, id, now())]);
    }
  }

  private onFileUploadValidate(file: {name: string, size: number}) {
    $$(document).trigger("drop"); // so other drop areas can deactivate

    if (file.size == 0) {
      toastr.error("You are not allowed to upload empty file");
      return false;
    } else if (file.size > global.config.maxFileSize) {
      toastr.error("File is too large, max allowed file size is " + prettySize(global.config.maxFileSize));
      return false;
    }
    this.newSize = file.size;
    return true;
  }

  private onFileUploadError(id: any, name: any, reason: any, request: any) {
    toastr.error(htmlEscape(reason));
    this.newComment.attachmentsInfo = Some(this.newComment.attachmentsInfo.get().filter(f => f.name !== name));
  }

  onFileUploadProgress(upladId: number, name: string, uploadedBytes: number, totalBytes: number) {

  }

  toggleAddRecipient() {
    this.newNotifee = None();
    this.notificationSelectorVisible = !this.notificationSelectorVisible;
  };

  onNotifeeSelected = (node: BasicOrganizationNodeInfo) => {

    if (node.isPerson()) {
      const person = node.asPerson().idUnwrapped();
      if (!__(this.newComment.extraRecipients).exists(r => AnyPersonIdHelper.equals(r, person))) {
        this.newComment.extraRecipients.push(person);
      }
    }

    this.newNotifee = None();
    this.notificationSelectorVisible = false;

  };

  onNotifeeCleared = () => {
    this.newNotifee = None();
    this.notificationSelectorVisible = false;
  };

  newNotifee: Option<OrganizationNodeId> = None();

  private clearNewComment() {
    this.commentInvalid = false;
    this.newComment = CommentViewModel.empty();
    this.notificationSelectorVisible = false;
    this.newNotifee = None();
    this.errorMessage = undefined;
  }

  ngOnDestroy() {
    $$(document)
      .off("dragenter", this.onDragEnter)
      .off("dragleave", this.onDragLeave)
      .off("drop", this.onDrop);
    this.destroySubscriptions();
  }

  onShowAttachments($event: {viewableFiles: Array<ViewableFile>; fileIndex: number}) {
    this.showAttachment.emit($event);
  }

  deleteRecipient(person: AnyPersonId) {
    this.newComment.extraRecipients = this.newComment.extraRecipients.filter(p => !AnyPersonIdHelper.equals(p, person));
  }

  onNotifeeResultsChanged() {
    this.notifeeResultsChanged.next();
  }

  attachmentsVisible() {
    return this.activated && this.attachments && this.newComment.attachmentsInfo.isDefined() && this.newComment.attachmentsInfo.get().length > 0;
  }
}
