<button #addLabelAnchor
        [class]="buttonClass"
        [my-tooltip]="{i18n: 'tasks_add_label' | i18n, enabled: !addLabelOpened}"
        (pointerdown)="$event.stopPropagation()"
        (click)="toggleAddLabel(addLabelAnchor); $event.stopPropagation(); false;"><i class="mi-tag-add"></i></button>

<my-drop-menu
  cssClass=""
  [anchor]="addLabelAnchor"
  [(visible)]="addLabelOpened"
  position="start"
  verticalPosition="middle"
  [backgroundVisible]="true"
  backgroundStyle="light"
>
  <ng-container *ngIf="addLabelOpened">
    <div #suggestionMenuAnchor class="addLabelPopup">
      <i class="icon mi-plus"></i>
      <suggesting-input [(text)]="newLabel"
                        [suggestions]="taskListManagerService.labelsSuggestionProvider"
                        [focusOnShow]="'forceMobile'"
                        [placeholder]="'New label' | i18n" (accepted)="labelAddConfirmed($event)"
                        (canceled)="labelChangeCanceled()"
                        [exclude]="taskPlainLabels()"
                        [suggestionMenuAnchor]="suggestionMenuAnchor"
      ></suggesting-input>
      <button class="confirmButton theme-button-confirm-icon-only-smallest" (click)="labelAddConfirmed(newLabel)"><i
        class="mi-check"></i></button>
    </div>
  </ng-container>

</my-drop-menu>
