import {I18nText, None, Option, ScreenId} from "@utils";
import {
  BackgroundsProperties,
  BordersProperties,
  ComponentActionProperties,
  ComponentProperties,
  ComponentValidationRules,
  DefaultPropertyProvider,
  PaddingsProperties,
  ScreenComponentDefinition,
  ScreenComponentRef,
  TextsProperties
} from "@screen-common";
import {
  I18nTextProperty,
  OptionalI18nTextProperty,
  OptionalStringProperty,
  StringProperty
} from "@screen-common";
import {ScreenComponentRefId} from "@shared";
import {ScreenComponentId, TextAlign, TextVerticalAlign} from "@screen-common";

export class ButtonComponentRef extends ScreenComponentRef {
  static className = "ButtonComponentRef";
  className() {
    return ButtonComponentRef.className;
  }

  constructor(
    readonly id: ScreenComponentRefId,
    readonly componentId: ScreenComponentId,
    readonly applicationScreen: Option<ScreenId>,
    readonly properties: ComponentProperties
  ) {
    super();
  }

  static copy(other: ButtonComponentRef): ButtonComponentRef {
    return new ButtonComponentRef(
      ScreenComponentRefId.copy(other.id),
      ScreenComponentId.copy(other.componentId),
      Option.copy(other.applicationScreen, ScreenId.copy),
      ComponentProperties.copy(other.properties)
    );
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.ofFallbacks("ButtonComponentRef", DefaultPropertyProvider.ref);
  override defaultPropertiesProvider = ButtonComponentRef.DEFAULT_PROPERTIES;


  override createDefaultDefinition(id: ScreenComponentId): ScreenComponentDefinition {
    return new ButtonComponentDefinition(id, None(), ComponentProperties.empty(), new ComponentActionProperties([]), new ComponentValidationRules([]));
  }
}

export class ButtonComponentDefinition extends ScreenComponentDefinition {

  static className = "ButtonComponentDefinition";
  className() {
    return ButtonComponentDefinition.className;
  }

  typeName() {
    return "Button";
  }

  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              readonly actionProperties: ComponentActionProperties,
              override readonly validationRules: ComponentValidationRules) {
    super(id, identifier, properties, validationRules, ButtonComponentDefinition.DEFAULT_PROPERTIES);
  }

  textProperties = new TextsProperties(this.properties);
  paddingsProperties = new PaddingsProperties("", this.properties);
  bordersProperties = new BordersProperties(this.properties);
  backgroundsProperties = new BackgroundsProperties("", this.properties);

  get text(): I18nTextProperty {return this.properties.getI18nTextProperty("text", this.defaultPropertiesProvider)};

  get tooltip(): OptionalI18nTextProperty {return this.properties.getOptionalI18nTextProperty("tooltip", this.defaultPropertiesProvider)};

  get icon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("icon", this.defaultPropertiesProvider)};
  get iconPosition(): StringProperty {return this.properties.getStringProperty("iconPosition", this.defaultPropertiesProvider)};

  // deprecated
  get startIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("startIcon", this.defaultPropertiesProvider)};
  // deprecated
  get endIcon(): OptionalStringProperty {return this.properties.getOptionalStringProperty("endIcon", this.defaultPropertiesProvider)};


  static copy(other: ButtonComponentDefinition): ButtonComponentDefinition {
    return new ButtonComponentDefinition(
      ScreenComponentId.copy(other.id),
      Option.copy(other.identifier),
      ComponentProperties.copy(other.properties),
      ComponentActionProperties.copy(other.actionProperties),
      ComponentValidationRules.copy(other.validationRules)
    );
  }

  getModelNames(): Array<string> {
    return [];
  }

  static DEFAULT_PROPERTIES = DefaultPropertyProvider.of("ButtonComponentDefinition",
    (name: string) => {
    switch (name) {
      case "text": return I18nTextProperty.of(I18nText.ofCurrent("?"));  // fills missing text iif deprecated label was set
      case "textAlign": return StringProperty.of(TextAlign.center.name);
      case "textVerticalAlign": return StringProperty.of(TextVerticalAlign.center.name);
      case "startIcon": return OptionalStringProperty.disabled("confirm");
      case "endIcon": return OptionalStringProperty.disabled("confirm");
      case "icon": return OptionalStringProperty.disabled("confirm");
      case "iconPosition": return StringProperty.of("start");
      default: return null;
    }},
    DefaultPropertyProvider.definitionComponent, DefaultPropertyProvider.definitionText, DefaultPropertyProvider.definitionTooltip,
    DefaultPropertyProvider.definitionLabel,
    DefaultPropertyProvider.definitionBackgrounds, DefaultPropertyProvider.definitionBorders,
    DefaultPropertyProvider.definitionPaddings);


  override displayName(): string {
    return (this.text.settingsUnwrapped().toSettingsType().isStaticValue() ? this.text.staticValue.getCurrentWithFallback() : "");
  }
}
