<div *ngIf="viewModel.activeView"
     class="childrenPlain layout_{{viewModel.activeView.layoutClass}} layoutAlign_{{viewModel.activeView.layoutAlignClass}} layoutStretch_{{viewModel.activeView.layoutStretchClass}} layoutWrap_{{viewModel.activeView.layoutWrapClass}} {{viewModel.activeView.childrenPlainCssClasses}}"
     [style]="viewModel.activeView.childrenPlainCss">

  <ng-container *ngFor="let component of viewModel.activeView.children">
    <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
         [class.nonVisibleComponent]="component.nonVisibleComponent"
         [style]="component.positionCss">
      <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
        <!--              SHARED -->
        <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />

        <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />

        <div class="errorsInfo" *ngIf="component.error">
          <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
        </div>

        <div class="inProgressGlass" *ngIf="component.inProgress"></div>
        <!--              SHARED -->
      </div>
    </div>
  </ng-container>
</div>
