import {EventBus} from "@utils";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SessionEventBus extends EventBus {
  userLoggedIn() {}

  userLoggedOut(sessionToken: string) {}
}
